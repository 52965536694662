// Libs
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";

// Local
import * as strings from "../../strings";

interface Props {
  setIsFeedbackPositive: (val: boolean) => void;
  handleClick: (val: boolean) => Promise<void>;
}

export function YesNoButtons(props: Props): JSX.Element {
  const [clickedYes, setClickedYes] = useState<boolean | null>(null);

  const yesColors = "text-green-900 bg-green-100 border-green-900";
  const noColors = "text-red-900 bg-red-100 border-red-900";

  return (
    <div className="mb-12 flex flex-wrap justify-around gap-6 md:gap-14">
      <LocalButton
        colorClasses={`${
          clickedYes ? yesColors : "border-gray-300 bg-white hover:bg-gray-50"
        } focus:ring-green-900`}
        onClick={async () => {
          setClickedYes(true);
          props.setIsFeedbackPositive(true);
          await props.handleClick(true);
        }}
      >
        <HandThumbUpIcon className="mr-4 h-5 md:mr-6 md:h-6" />
        {strings.YES_WITH_EXCLAMATION}
      </LocalButton>

      <LocalButton
        colorClasses={`${
          clickedYes === false
            ? noColors
            : "border-gray-300 bg-white hover:bg-gray-50"
        } focus:ring-red-900`}
        onClick={async () => {
          setClickedYes(false);
          props.setIsFeedbackPositive(false);
          await props.handleClick(false);
        }}
      >
        <HandThumbDownIcon className="mr-4 h-5 md:mr-6 md:h-6" />
        {strings.NOT_REALLY}
      </LocalButton>
    </div>
  );
}

function LocalButton(props: {
  onClick: () => void;
  colorClasses: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <button
      className={`inline-flex h-12 w-full items-center justify-center rounded-md
      border px-4 py-2 text-base font-medium shadow-sm transition-colors
      focus:outline-none focus:ring-2 focus:ring-offset-2 xs:w-40 sm:w-52
      md:flex-1 md:text-lg ${props.colorClasses}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
