import React from "react";

type Props = {
  children: React.ReactNode;
  text?: string;
  inputName?: string;
  admin: boolean;
  required?: boolean;
} & React.ComponentPropsWithRef<"select">;

const BaseInputSelect = React.forwardRef<HTMLSelectElement, Props>(
  (
    { children, text, inputName, admin, className, ...props },
    ref,
  ): JSX.Element => {
    if (admin) {
      return (
        <div className="relative mt-1 sm:mt-0">
          <select
            {...props}
            className={`${className} block w-full rounded border border-primaryLight p-4 text-gray-700 shadow-sm outline-none focus:ring focus:ring-primaryLight sm:text-sm`}
            ref={ref}
            id={inputName}
          >
            {children}
          </select>
          <label
            htmlFor={inputName}
            className="absolute -top-3 left-3 bg-white px-2"
          >
            {text}{" "}
            {props.required ? (
              <span className="text-lg font-medium text-redFail">*</span>
            ) : (
              ""
            )}
          </label>
        </div>
      );
    } else {
      return (
        <div className="relative mt-1 sm:mt-0">
          <select
            {...props}
            className={`${className} block w-full max-w-lg rounded border border-gray-300 p-2 shadow-sm outline-none focus:border-primaryLight focus:ring-1 focus:ring-primaryLight sm:text-sm`}
            ref={ref}
            aria-labelledby={inputName}
          >
            {children}
          </select>
        </div>
      );
    }
  },
);
export default BaseInputSelect;
