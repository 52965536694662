// Local
import { DbWrite } from "../../database";
import { logger } from "../../logging";
import { isWhiteLabel } from "../../white-label-check";
import { whiteLabel } from "../../urls";
import { ExistingStiltInvoice } from "../../models/invoice";
import {
  CreateMultiPaymentUniqueLink,
  CreateUniqueLinkManager,
  Platform,
} from "../../models/unique-link";

/** Get a unique link for paying the given invoices via HPP. Contains try/catch. */
export async function generateMultiPaymentUniqueLink(
  siteKey: string,
  invoiceIDs: ExistingStiltInvoice["id"][],
  platform: Platform,
): Promise<string | undefined> {
  if (!isWhiteLabel(whiteLabel)) {
    logger.error(`Unexpected white label: ${whiteLabel}`);
    return undefined;
  }

  const data: CreateMultiPaymentUniqueLink = {
    siteKey: siteKey,
    invoiceDocumentIDs: invoiceIDs,
    email: null,
    linkExpires: null,
    phone: null,
    whiteLabelURL: null,
    version: whiteLabel,
    platform: platform,
  };

  try {
    const valid = CreateUniqueLinkManager.parseMultiPayment(data);
    const paymentLink =
      await DbWrite.payments.generateMultiPaymentCreditCardLink(valid);
    return paymentLink;
  } catch (error) {
    logger.error("generateMultiPaymentUniqueLink", error);
    return undefined;
  }
}
