// Libs
import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { Fragment, forwardRef, useCallback, useRef } from "react";

// Local
import BaseInputCheckbox from "../BaseInputCheckbox";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { useSiteKeyUsersStore } from "../../store/site-key-users";

const UsersCustomFilter = forwardRef(
  (
    {
      model,
      onModelChange,
      getValue,
    }: CustomFilterProps<any, any, { value: string[] }>,
    _ref,
  ) => {
    const siteKeyUsersList = useSiteKeyUsersStore(
      (state) => state.siteKeyUsersList,
    );
    const filterUserList = siteKeyUsersList
      // we don't want system users to be visible
      .filter((user) => user.systemUser !== true);

    const refInput = useRef<HTMLInputElement>(null);

    const doesFilterPass = useCallback(
      ({ node }: IDoesFilterPassParams) => {
        const nodeValue = getValue(node);
        if (nodeValue == null) return false;
        /* convert nodeValue from string to array */
        const nodeValues = nodeValue.split(",");

        if (model) {
          /* check if any of the selected assignedTo id contained in the model.value array are in the nodeValues array  */
          return model.value.some((r) => nodeValues.includes(r));
        } else return false;
      },
      [model],
    );

    const afterGuiAttached = useCallback(() => {
      window.setTimeout(() => {
        refInput.current?.focus();
      });
    }, []);

    useGridFilter({
      doesFilterPass,
      afterGuiAttached,
    });

    const onChange = (value: string[] | null) => {
      if (value == null) {
        onModelChange(null);
      } else {
        onModelChange({ value });
      }
    };

    return (
      <Fragment>
        <form className="flex w-fit rounded-md">
          <div className="flex flex-col gap-2 whitespace-nowrap  p-3">
            {filterUserList
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map((user) => {
                return (
                  <BaseInputCheckbox
                    key={user.id}
                    label={user.displayName}
                    checked={
                      model != null ? model.value.includes(user.id) : false
                    }
                    onChange={(event) => {
                      const value = event.target.checked;
                      if (value === true) {
                        const modelCopy =
                          model === null ? [] : [...model!.value];
                        onChange([...modelCopy, user.id]);
                      } else {
                        const modelCopy = [...model!.value];
                        const taskStatusIndex = modelCopy.findIndex(
                          (value) => user.id === value,
                        );
                        modelCopy.splice(taskStatusIndex, 1);
                        if (modelCopy.length === 0) {
                          onChange(null);
                        } else {
                          onChange(modelCopy);
                        }
                      }
                    }}
                    ref={refInput}
                    className="form-control"
                  />
                );
              })}
            <BaseButtonSecondary
              type="button"
              className="form-control"
              onClick={() => onChange(null)}
            >
              Reset
            </BaseButtonSecondary>
          </div>
        </form>
      </Fragment>
    );
  },
);

export default UsersCustomFilter;
