import React from "react";

export const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

export const ViewInfoIconWithRef = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithRef<"button">
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className="ml-1 rounded-full text-gray-700 hover:bg-primaryOpacity90"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={styles}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
        <path
          fillRule="evenodd"
          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
});
