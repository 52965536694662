// Libs
import { z, Schema } from "zod";

// Local
import { logger as devLogger } from "../logging";

/**
 * Allows setting a fallback value for a zod schema. Requires the fallback value (U)
 * to conform to the input schema (T).
 *
 * Examples
 * ```
 * const a = zFallback(z.boolean()), false) // OK
 * const b = zFallback(z.boolean()), 'moose') // ERROR
 * ```
 */
export function zFallback<T extends Schema<any>, U extends z.infer<T>>(
  schema: T,
  fallbackValue: U,
  description?: string,
): T | Schema<U> {
  return z.any().transform((val) => {
    const safe = schema.safeParse(val);
    if (safe.success) {
      return safe.data;
    } else {
      devLogger.warn(
        `Expected value to match ${JSON.stringify(
          schema,
          null,
          2,
        )} but received: ${val}. 
${description}`,
      );
      return fallbackValue;
    }
  });
}

export const exampleSchema = z.object({
  name: zFallback(z.string().min(1).max(200), "Unknown"),
  age: z.number().min(1).max(200),
});

// I wanted to check how the inferred type looks with the fallback function applied
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExampleType = z.infer<typeof exampleSchema>;
