import React from "react";

type Props = React.ComponentPropsWithRef<"input">;
type Ref = HTMLInputElement;

export default React.forwardRef<Ref, Props>(
  ({ className, id, ...rest }, ref) => {
    return (
      <div className={className}>
        <input
          ref={ref}
          {...rest}
          id={id}
          type="text"
          className="block w-full min-w-0 flex-1 rounded border border-gray-300 px-2 py-1.5 outline-none focus:border-primaryLight focus:ring-1 focus:ring-primaryLight sm:text-sm"
        />
      </div>
    );
  },
);
