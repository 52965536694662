export const whiteLabelList = [
  "stilt",
  "aimpoint",
  "sunbelt",
  "apache",
] as const;

export type WhiteLabel = (typeof whiteLabelList)[number];

export function isWhiteLabel(value: unknown): value is WhiteLabel {
  return whiteLabelList.includes(value as any);
}
