import { EventTypeValues, OEventTypes } from "../../../models/event-types";

export function getEventIconColor(eventType: EventTypeValues): string {
  switch (eventType) {
    case OEventTypes.NEW_USER_APPROVED:
    case OEventTypes.NEW_USER_APPLIED:
    case OEventTypes.CRAFT_RECORD_CREATED:
    case OEventTypes.NEW_TASK_ADDED:
    case OEventTypes.ADDED_PHOTO:
    case OEventTypes.TASK_RESTORED:
    case OEventTypes.CRAFT_RECORD_RESTORED:
    case OEventTypes.CUSTOMER_CREATED:
    case OEventTypes.CUSTOMER_LOCATION_ADDED:
    case OEventTypes.ACCOUNTING_SYNC_SUCCESS:
    case OEventTypes.SMS_NOTIFICATION_SENT:
    case OEventTypes.INVOICE_EMAIL_SENT_TO_CUSTOMER:
    case OEventTypes.FEEDBACK_EMAIL_SENT_TO_CUSTOMER:
    case OEventTypes.APPOINTMENT_REMINDER_EMAIL_SENT_TO_CUSTOMER:
    case OEventTypes.PAYMENT_CREATED:
    case OEventTypes.ESTIMATE_CONVERTED_TO_INVOICE:
    case OEventTypes.NEW_ESTIMATE_CREATED:
      return "bg-[#66bb6a]";

    case OEventTypes.TASK_STATUS_UPDATED:
    case OEventTypes.TASK_REASSIGNED_COMPANY:
    case OEventTypes.TASK_DESCRIPTION_UPDATED:
    case OEventTypes.TASK_WORK_ORDER_UPDATED:
    case OEventTypes.TASK_DETAILS_UPDATED:
    case OEventTypes.TASK_SCHEDULE_DATE_CHANGED:
    case OEventTypes.UPDATED_TITLE:
    case OEventTypes.UPDATED_DESCRIPTION:
    case OEventTypes.CHANGED_ASSET:
    case OEventTypes.UPDATED_CRAFT_DETAILS:
    case OEventTypes.CHANGED_LOCATION_ID:
    case OEventTypes.UPDATED_LOCATION_ON_MAP:
    case OEventTypes.ADDED_ATTACHMENT:
    case OEventTypes.CUSTOMER_UPDATED:
    case OEventTypes.CUSTOMER_LOCATION_UPDATED:
    case OEventTypes.FEEDBACK_RESPONSE_RECEIVED:
    case OEventTypes.INVOICE_UPDATED:
    case OEventTypes.INVOICE_STATUS_CHANGED:
    case OEventTypes.ESTIMATE_UPDATED:
    case OEventTypes.ESTIMATE_STATUS_CHANGED:
      return "bg-[#ba68c8]";

    case OEventTypes.REMOVED_PHOTO:
    case OEventTypes.REMOVED_ATTACHMENT:
    case OEventTypes.TASK_DELETED:
    case OEventTypes.CRAFT_RECORD_DELETED:
    case OEventTypes.ACCOUNTING_SYNC_ERROR:
    case OEventTypes.PAYMENT_REFUNDED:
      return "bg-[#e57373]";

    default:
      const _exhaustivenessCheck: never = eventType;
      return _exhaustivenessCheck;
  }
}
