// Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

// Local
import BaseModal from "../../components/BaseModal";
import { getReadableCraftType } from "../../models/craft-types";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import BaseButtonSecondary from "../BaseButtonSecondary";
import * as strings from "../../strings";

interface Props {
  company: ExistingSiteKeyCompany;
  isDialogOpen: boolean;
  closeDialog: () => void;
}

export default function ViewCompanyInfo({
  isDialogOpen,
  closeDialog,
  company,
}: Props) {
  const companyInfo = (
    <div className="flex flex-col p-8 text-lg sm:grid sm:grid-cols-2 sm:gap-4 ">
      {/* Company Name */}
      <div className="mb-2 text-gray-500 sm:mb-0">Company Name</div>
      <div className="mb-4 sm:mb-0">{company.name}</div>
      {/* Main Point of Contact */}
      <div className="mb-2 text-gray-500 sm:mb-0">Main Point of Contact</div>
      <div className="mb-4 sm:mb-0">{company.mainPointOfContact}</div>
      {/* Craft Types */}
      <div className="mb-2 text-gray-500 sm:col-span-2 sm:mb-0">
        Craft Types
      </div>
      <ul className="mb-4 list-disc gap-x-4 px-6 sm:col-span-2 sm:mb-0 sm:grid sm:grid-cols-2">
        {company.craftTypes.map((workType, workTypeIdx) => {
          return <li key={workTypeIdx}>{getReadableCraftType(workType)}</li>;
        })}
      </ul>
      {/* Company Logo */}
      <div className="mb-2 text-gray-500 sm:col-span-2 sm:mb-0">
        Company Logo
      </div>
      {company.logoPhotoURL ? (
        <img
          src={company.logoPhotoURL}
          alt="Company Logo"
          className="col-span-2 mb-4 w-24 rounded-md sm:mb-0"
        />
      ) : null}

      <div className="col-span-2 justify-self-end">
        <BaseButtonSecondary
          onClick={() => closeDialog()}
          type="button"
          className="w-full"
        >
          {strings.buttons.CLOSE}
        </BaseButtonSecondary>
      </div>
    </div>
  );

  const companyHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Company details
      </h1>
      <button onClick={() => closeDialog()}>
        <XMarkIcon
          aria-label="close company details"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={closeDialog}
      open={isDialogOpen}
      children={companyInfo}
      title={companyHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    />
  );
}
