//Libs
import { NavLink } from "react-router-dom";
import {
  EllipsisVerticalIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/solid";

//Local
import { signUserOut } from "../init-firebase";
import OverFlowMenu from "./OverflowMenu";
import OverflowMenuItem from "./OverflowMenuItem";
import { CHECKLISTS_URL, LOGIN_URL, TEMPLATES_URL } from "../urls";
import * as strings from "../strings";

//Styles
import styles from "./Navbar.module.css";

interface NavbarProps {
  showNav: boolean;
}

const stylesAlways = `py-4 uppercase transition`;

export default function Navbar(props: NavbarProps) {
  return (
    <nav
      className={`${
        props.showNav ? "grid" : "hidden"
      } -mt-8 mb-8 text-center text-primary ${styles.navGrid}`}
    >
      <NavLink
        to={CHECKLISTS_URL}
        className={({ isActive }) =>
          isActive
            ? `border-b-4 border-primary font-bold ${stylesAlways}`
            : `hover:bg-gray-100 ${stylesAlways}`
        }
      >
        {strings.CHECKLIST_TASKS}
      </NavLink>
      <NavLink
        to={TEMPLATES_URL}
        className={({ isActive }) =>
          isActive
            ? `border-b-4 border-primary font-bold ${stylesAlways}`
            : `hover:bg-gray-100 ${stylesAlways}`
        }
      >
        {strings.TEMPLATES}
      </NavLink>

      <OverFlowMenu
        icon={
          <EllipsisVerticalIcon
            className="fixed right-4 top-4 h-5 w-5"
            aria-hidden="true"
          />
        }
        className="hidden"
        buttonClassName="px-2 py-2 border hover:border-gray-800 hover:bg-gray-200"
      >
        <NavLink to={LOGIN_URL}>
          <OverflowMenuItem
            text="Login Page"
            icon={<ArrowRightOnRectangleIcon aria-label="sign in button" />}
          />
        </NavLink>
        <OverflowMenuItem
          text="Sign out"
          icon={<ArrowRightOnRectangleIcon aria-label="sign out button" />}
          onClick={() => {
            signUserOut();
          }}
        />
      </OverFlowMenu>
    </nav>
  );
}
