// Libs
import { useState } from "react";
import { DateTime } from "luxon";

// Local
import * as strings from "../../strings";
import { ExistingCustomField } from "../../models/custom-field";
import {
  convertDecimalToHoursMinutes,
  convertFSTimestampToLuxonDT,
} from "../../utils";
import { ExistingEvent } from "../../models/event";
import { getEventIcon, getEventIconColor } from "./functions";
import { getEventTypeString } from "../../models/event-types";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ExistingVehicle } from "../../models/vehicle";

const tabClasses =
  "mb-4 flex-1 py-2.5 text-sm uppercase tracking-wider transition-colors hover:bg-gray-100";
const activeTabClasses = "border-b-2 border-primary font-bold text-primary";

export type ECFandAnswer = ExistingCustomField & { answer: any };

interface Props {
  siteKeyCustomFields: ExistingCustomField[];
  details: Record<string, any>;
  userDisplayNamesMap: Record<string, string>;
  events: ExistingEvent[];
  vehicleList: ExistingVehicle[];
}

export default function DetailsAndEventsPanels(props: Props): JSX.Element {
  const [selectedPanel, setSelectedPanel] = useState<"Details" | "Events">(
    "Details",
  );

  const withAnswerList: ECFandAnswer[] = [];
  Object.entries(props.details).forEach(([detailID, answer]) => {
    const foundCF = props.siteKeyCustomFields.find((cf) => cf.id === detailID);

    if (foundCF) {
      // Convert timestamp fields to Luxon DateTimes. (Unless the value is null).
      // Can then convert it to a local timezone easily when we're displaying it.
      if (foundCF.fieldType === "timestamp" && answer != null) {
        const luxonAnswer = convertFSTimestampToLuxonDT(answer);
        withAnswerList.push({ ...foundCF, answer: luxonAnswer });
      } else if (foundCF.id === "assignedVehicleID") {
        const vehicle = props.vehicleList.find((v) => v.id === answer);
        const customField = { ...foundCF, title: "Assigned Vehicle" };
        withAnswerList.push({ ...customField, answer: vehicle?.title ?? null });
      } else {
        withAnswerList.push({ ...foundCF, answer });
      }
    }
  });

  // Sort events by timestamp (...the sort method mutates the given array)
  const events = [...props.events];
  events.sort(
    (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
  );

  return (
    <div>
      {/* TABS */}
      <div className="flex">
        <button
          onClick={() => setSelectedPanel("Details")}
          className={`${tabClasses} ${
            selectedPanel === "Details" ? activeTabClasses : "text-gray-600"
          }`}
        >
          {strings.DETAILS}
        </button>
        <button
          onClick={() => setSelectedPanel("Events")}
          className={`${tabClasses} ${
            selectedPanel === "Events" ? activeTabClasses : "text-gray-600"
          }`}
        >
          {strings.EVENTS} ({props.events.length})
        </button>
      </div>

      {/* DATA */}
      {selectedPanel === "Details" ? (
        <DisplayDetails
          details={withAnswerList}
          userDisplayNamesMap={props.userDisplayNamesMap}
        />
      ) : (
        <DisplayEvents
          events={events}
          userDisplayNamesMap={props.userDisplayNamesMap}
        />
      )}
    </div>
  );
}

const answerStyles = "block text-base text-gray-800";
const titleStyles = "block text-sm text-gray-500";

function DisplayDetails(props: {
  details: ECFandAnswer[];
  userDisplayNamesMap: Record<string, string>;
}): JSX.Element {
  if (props.details.length === 0) {
    return (
      <div className="text-sm text-gray-500">{strings.NO_SPECIFIC_DETAILS}</div>
    );
  }

  const allAnswersAreNull = props.details.every((cf) => cf.answer == null);
  if (allAnswersAreNull) {
    return (
      <div className="text-sm text-gray-500">{strings.NO_SPECIFIC_DETAILS}</div>
    );
  }

  const display = props.details.map((cf) => {
    if (cf.answer == null) {
      return null;
    }

    if (cf.answer == cf.defaultValue) {
      return null;
    }

    if (typeof cf.answer === "string") {
      if (cf.answer.length === 0) {
        return null;
      }
    }
    const type = cf.fieldType;
    switch (type) {
      case "string":
      case "string-textarea":
      case "number":
        return (
          <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
            <span className={answerStyles}>{cf.answer}</span>
            <span className={titleStyles}>{cf.title}</span>
          </div>
        );

      case "selection":
        // in this case, `answer` is the selectionOptions KEY.
        // need to display the value.
        const value = cf.selectionOptions[cf.answer];
        return (
          <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
            <span className={answerStyles}>{value}</span>
            <span className={titleStyles}>{cf.title}</span>
          </div>
        );

      case "currency":
        return (
          <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
            <span className={answerStyles}>${cf.answer}</span>
            <span className={titleStyles}>{cf.title}</span>
          </div>
        );

      case "bool":
        return (
          <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
            <span className={answerStyles}>{cf.answer ? "Yes" : "No"}</span>
            <span className={titleStyles}>{cf.title}</span>
          </div>
        );

      case "timestamp":
        if (cf.answer instanceof DateTime) {
          return (
            <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
              <span className={answerStyles}>
                {cf.answer.toLocal().toFormat("LL/dd/yy hh:mm a")}
              </span>
              <span className={titleStyles}>{cf.title}</span>
            </div>
          );
        } else {
          return null;
        }

      case "uid":
        return (
          <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
            <span className={answerStyles}>
              {props.userDisplayNamesMap[cf.answer]}
            </span>
            <span className={titleStyles}>{cf.title}</span>
          </div>
        );

      case "multiple-uid":
        if (cf.answer.length === 0) return null;
        const userList: string[] = cf.answer.map(
          (uid: string) => props.userDisplayNamesMap[uid],
        );
        return (
          <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
            <span className={answerStyles}>{userList.join(", ")}</span>
            <span className={titleStyles}>{cf.title}</span>
          </div>
        );

      case "string-array":
        if (cf.answer.length === 0) return null;
        return (
          <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
            <span className={answerStyles}>{cf.answer.join(", ")}</span>
            <span className={titleStyles}>{cf.title}</span>
          </div>
        );

      case "hours-minutes":
        if (typeof cf.answer !== "number") return null;
        const { hours, minutes } = convertDecimalToHoursMinutes(cf.answer);
        return (
          <div key={cf.title.replaceAll(" ", "-")} className="mb-2.5">
            <span className={answerStyles}>
              {hours}:{minutes}
            </span>
            <span className={titleStyles}>{cf.title}</span>
          </div>
        );

      default:
        const exhaustivenessCheck: never = type;
        return exhaustivenessCheck;
    }
  });

  return <>{display}</>;
}

const leftCaretClasses =
  "before:absolute before:-left-3 before:top-1/2 before:-translate-y-1/2 before:border-y-[12px] before:border-r-[12px] before:border-y-transparent before:border-r-gray-200 after:absolute after:-left-[10.5px] after:top-1/2 after:-translate-y-1/2 after:border-y-[12px] after:border-r-[12px] after:border-y-transparent after:border-r-white";

function DisplayEvents(props: {
  events: ExistingEvent[];
  userDisplayNamesMap: Record<string, string>;
}): JSX.Element {
  return (
    <div className="ml-4 max-h-96 overflow-y-auto">
      {props.events.map((event) => {
        const displayName = props.userDisplayNamesMap[event.createdBy];

        return (
          // NOTE: task panel's vertical bar will "disappear" without the isolate class.
          <div key={event.id} className="relative isolate px-2 py-4">
            {/* VERTICAL BAR */}
            <span className="block before:absolute before:bottom-0 before:left-7 before:top-0 before:-z-10 before:h-full before:w-0.5 before:bg-gray-200"></span>
            <div className="flex items-center gap-10">
              {/* ICON */}
              <span
                className={`rounded-full p-2 ${getEventIconColor(
                  event.eventType,
                )}`}
              >
                {getEventIcon(event.eventType)}
              </span>

              {/* EVENT CARD */}
              <div className="relative w-full">
                <div
                  className={`${leftCaretClasses} rounded-md border border-gray-200 px-3.5 py-2 text-sm text-gray-400 shadow-md`}
                >
                  <p className="text-base text-gray-900">
                    {getEventTypeString(event.eventType, displayName)}
                  </p>
                  <p className="text-gray-700">{event.title}</p>
                  <p>{convertToReadableTimestamp(event.timestampCreated)}</p>
                  <p>{displayName}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
