//Libs
import React, { useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

type PDFIconProps = {
  onCreate: () => Promise<void>;
} & React.ComponentPropsWithRef<"button">;

export const PDFIconWithSpinner = React.forwardRef<
  HTMLButtonElement,
  PDFIconProps
>(({ onCreate, ...props }, ref): JSX.Element => {
  const [isBusy, setIsBusy] = useState(false);

  return (
    <button
      ref={ref}
      {...props}
      className="mx-1 rounded-full text-gray-700 hover:bg-primaryOpacity90"
      disabled={isBusy}
      onClick={async () => {
        // set animation
        setIsBusy(true);
        try {
          await onCreate();
        } finally {
          // stop animation
          setIsBusy(false);
        }
      }}
    >
      {isBusy ? (
        <LoadingSpinner marginClass="mx-2" />
      ) : (
        <PictureAsPdfIcon aria-label="payment link button" style={styles} />
      )}
    </button>
  );
});
