//Libs
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, PencilIcon } from "@heroicons/react/24/solid";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local
import { guardIsPlainObject } from "../../utils";
import { logger as devLogger } from "../../logging";
import {
  AdminUserCSInterface,
  CompaniesCSInterface,
  WorkTypesCSInterface,
  CustomFieldsCSInterface,
  KpiConfigCSInterface,
  LocationsCSInterface,
  SiteKeyCSInterface,
  getGroupedCustomFields,
  sortTaskTitlesForGroupedCFs,
} from "../../Pages/CreateSite/CreateSiteContainer";
import { SiteKeyUserPermissionsManager } from "../../models/site-key-user-permissions";
import { StyledTooltip } from "../StyledTooltip";
import { DynamicDetail } from "../../models/dynamic-details";
import { TrashButton } from "../TrashButton";

// If you're adding a new review category, start here.
const options = [
  "site",
  "locations",
  "companies",
  "workAndTaskTypes",
  "customFields",
  "administrator",
  "kpiConfig",
] as const;
type Options = (typeof options)[number];
interface Props {
  title: string;
  data:
    | SiteKeyCSInterface
    | LocationsCSInterface[]
    | CompaniesCSInterface[]
    | WorkTypesCSInterface["workAndTaskTypes"]
    | CustomFieldsCSInterface
    | AdminUserCSInterface
    | KpiConfigCSInterface["kpiConfig"];
  edit?: boolean;
  addCustomField?: boolean;
  onHandleBackButton?: () => void;
  options: Options;
  buttonRefs: React.MutableRefObject<(HTMLButtonElement | null)[]>;
  handleDeleteCustomField?: (
    workTypeString: string,
    title: string,
    /** Necessary if deleting a custom field for a task */
    taskTypeString?: string,
  ) => void;
  openAddCustomFieldDialog?: () => void;
}

export default function CreateSiteReview(props: Props) {
  return (
    <div className="mt-4 md:grid md:grid-cols-3 md:items-start md:gap-x-2">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <div className="flex w-full items-center justify-between md:contents">
              <span className="pr-2 text-lg font-semibold capitalize text-primary md:order-1">
                {props.title}
              </span>

              {!open ? (
                <ul className="hidden md:order-1 md:flex">
                  <DisplayPartialData
                    data={props.data}
                    options={props.options}
                  />
                </ul>
              ) : null}

              <div className="row-start-1 flex items-center md:order-3 md:col-start-3 md:justify-self-end">
                {!open ? (
                  <span className="mr-5 hidden font-semibold text-primary md:flex">
                    ... More
                  </span>
                ) : null}

                {/* Add Custom Field Icon */}
                {props.addCustomField ? (
                  <StyledTooltip title={`Add Custom Field`}>
                    <button
                      type="button"
                      onClick={props.openAddCustomFieldDialog}
                      className="mr-5 rounded-md border border-white bg-white p-1 text-primary focus:border-primaryLight focus:outline-none"
                    >
                      <AddBoxIcon
                        aria-label="Add Custom Field"
                        sx={{ fontSize: 45 }}
                      />
                    </button>
                  </StyledTooltip>
                ) : null}

                {/* Edit Icon */}
                {props.edit ? (
                  <StyledTooltip title={`Edit ${props.title}`}>
                    <button
                      type="button"
                      onClick={props.onHandleBackButton}
                      className="mr-5 rounded-md border border-white bg-white p-1 text-primary focus:border-primaryLight focus:outline-none"
                      aria-label={`Edit ${props.title}`}
                    >
                      <PencilIcon
                        className="h-6"
                        aria-label={`Edit ${props.title}`}
                      />
                    </button>
                  </StyledTooltip>
                ) : null}

                <StyledTooltip
                  title={`${open ? "Close" : "Expand"} details for ${
                    props.title
                  }`}
                >
                  <Disclosure.Button
                    className="rounded-md border border-white bg-white focus:border-primaryLight focus:outline-none"
                    // This is for use with collapsing/expanding all review details.
                    ref={(el: HTMLButtonElement) =>
                      props.buttonRefs?.current.push(el)
                    }
                  >
                    <ChevronDownIcon
                      aria-label={`${open ? "Close" : "Expand"} details for ${
                        props.title
                      }`}
                      className={`${
                        open ? "" : "rotate-180 transform"
                      } h-8 w-8 leading-3 text-primary transition`}
                    />
                  </Disclosure.Button>
                </StyledTooltip>
              </div>
            </div>
            <Disclosure.Panel
              unmount={false}
              className="col-span-2 col-start-2 pt-4 font-semibold text-gray-800 md:order-2 md:pt-1"
            >
              <ul className="space-y-2.5">
                <DisplayData
                  data={props.data}
                  title={props.title}
                  options={props.options}
                  buttonRefs={props.buttonRefs}
                  handleDeleteCustomField={props.handleDeleteCustomField}
                />
              </ul>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

function DisplayData(args: {
  options: Options;
  data: Props["data"];
  title: Props["title"];
  buttonRefs: Props["buttonRefs"];
  handleDeleteCustomField?: Props["handleDeleteCustomField"];
}) {
  switch (args.options) {
    case "site":
      const siteData = args.data as SiteKeyCSInterface;
      return <SiteReviewDetails data={siteData} />;
    case "locations":
      const locationsData = args.data as LocationsCSInterface[];
      return <LocationsReviewDetails data={locationsData} />;
    case "companies":
      const companiesData = args.data as CompaniesCSInterface[];
      return <CompaniesReviewDetails data={companiesData} />;
    case "workAndTaskTypes":
      const wtData = args.data as WorkTypesCSInterface["workAndTaskTypes"];
      return (
        <WorkAndTaskTypesReviewDetails
          data={wtData}
          title={args.title}
          buttonRefs={args.buttonRefs}
        />
      );
    case "customFields":
      const customFieldsData = args.data as CustomFieldsCSInterface;
      if (args.handleDeleteCustomField == null) return <div></div>;
      return (
        <CustomFieldsReviewDetails
          data={customFieldsData}
          title={args.title}
          buttonRefs={args.buttonRefs}
          handleDeleteCustomField={args.handleDeleteCustomField}
        />
      );
    case "kpiConfig":
      const kpiData = args.data as KpiConfigCSInterface["kpiConfig"];
      return <KpiReviewDetails data={kpiData} title={args.title} />;
    case "administrator":
      const adminData = args.data as AdminUserCSInterface;
      return (
        <AdminReviewDetails
          data={adminData}
          title={args.title}
          buttonRefs={args.buttonRefs}
        />
      );
    default:
      const _exhaustivenessCheck: never = args.options;
      return _exhaustivenessCheck;
  }
}

function SiteReviewDetails(args: { data: SiteKeyCSInterface }) {
  return (
    <>
      {Object.entries(args.data).map(([key, value], objIndex) => {
        return (
          <li key={objIndex} className="flex flex-col">
            <span className="text-sm font-medium capitalize text-gray-400">
              {key}
            </span>
            {value}
          </li>
        );
      })}
    </>
  );
}

function LocationsReviewDetails(args: { data: LocationsCSInterface[] }) {
  return (
    <>
      {args.data.map((object) => {
        return Object.entries(object).map(([key, value], index) => {
          return (
            <li key={index} className="flex flex-col">
              <span className="text-sm font-medium capitalize text-gray-400">
                {key}
              </span>
              {value}
            </li>
          );
        });
      })}
    </>
  );
}

function CompaniesReviewDetails(args: { data: CompaniesCSInterface[] }) {
  return (
    <>
      {args.data.map((object) => {
        return Object.entries(object).map(([key, value], index) => {
          return (
            <li key={index} className="flex flex-col">
              <span className="text-sm font-medium capitalize text-gray-400">
                {key}
              </span>
              {value}
            </li>
          );
        });
      })}
    </>
  );
}

function WorkAndTaskTypesReviewDetails(args: {
  data: WorkTypesCSInterface["workAndTaskTypes"];
  title: Props["title"];
  buttonRefs: Props["buttonRefs"];
}) {
  if (!args.data) {
    devLogger.warn(`Unexpected data for ${args.title}: ${args.data}`);
    return <span>Unable to display data for {args.title}.</span>;
  }
  // This alphabetizes the work types; task types were alphabetized in `getWorkAndTaskTypes`
  const alphabetizedData = new Map([...args.data.entries()].sort());
  return (
    <>
      {[...alphabetizedData].map(([workTypeStr, taskTypeList]) => {
        return (
          <li key={workTypeStr} className="flex flex-col">
            <Disclosure>
              {({ open }) => (
                <>
                  <div className="flex items-center">
                    <StyledTooltip
                      title={`${
                        open ? "Close" : "Expand"
                      } task types for ${workTypeStr}`}
                    >
                      <Disclosure.Button
                        className="mr-2 rounded-md border border-white bg-white focus:border-gray-500 focus:outline-none"
                        // This is for use with collapsing/expanding all review details.
                        ref={(el: HTMLButtonElement) =>
                          args.buttonRefs?.current.push(el)
                        }
                      >
                        <ChevronDownIcon
                          aria-label={`${
                            open ? "Close" : "Expand"
                          } task types for ${workTypeStr}`}
                          className={`${
                            open ? "" : "-rotate-180 transform"
                          } h-7 w-7 leading-3 transition`}
                        />
                      </Disclosure.Button>
                    </StyledTooltip>
                    <span className="capitalize">{workTypeStr}</span>
                  </div>

                  <Disclosure.Panel className="contents pt-3">
                    {taskTypeList.map((item, index) => {
                      return (
                        <span
                          key={index}
                          className="pl-14 pt-2 font-normal capitalize"
                        >
                          {item}
                        </span>
                      );
                    })}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </li>
        );
      })}
    </>
  );
}

function CustomFieldsReviewDetails(args: {
  data: CustomFieldsCSInterface;
  title: Props["title"];
  buttonRefs: Props["buttonRefs"];
  handleDeleteCustomField: (
    workTypeString: string,
    title: string,
    /** Necessary if deleting a custom field for a task */
    taskTypeString?: string,
  ) => void;
}) {
  if (!args.data.craftDetails || !args.data.taskSpecificDetails) {
    devLogger.warn(`Unexpected data for ${args.title}: ${args.data}`);
    return <span>Unable to display data for {args.title}.</span>;
  }

  const groupedCustomFields = getGroupedCustomFields(args.data);

  const groupedCFsWithSortedTaskTitles =
    sortTaskTitlesForGroupedCFs(groupedCustomFields);

  // Alphabetize the work types
  const alphabetizedCFMap = new Map(
    [...Object.entries(groupedCFsWithSortedTaskTitles)].sort(),
  );

  return (
    <>
      {[...alphabetizedCFMap].map(([workTypeStr, value]) => {
        return (
          <li key={workTypeStr} className="flex flex-col pb-1">
            <Disclosure>
              {({ open }) => (
                <>
                  {/* DISPLAY WORK TYPE & DROPDOWN ARROW */}
                  <div className="flex items-center">
                    <StyledTooltip
                      title={`${
                        open ? "Close" : "Expand"
                      } custom fields for ${workTypeStr}`}
                    >
                      <Disclosure.Button
                        className="mr-2 rounded-md border border-white bg-white focus:border-gray-500 focus:outline-none"
                        // This is for use with collapsing/expanding all review details.
                        ref={(el: HTMLButtonElement) =>
                          args.buttonRefs?.current.push(el)
                        }
                      >
                        <ChevronDownIcon
                          aria-label={`${
                            open ? "Close" : "Expand"
                          } custom fields for ${workTypeStr} work type`}
                          className={`${
                            open ? "" : "-rotate-180 transform"
                          } h-7 w-7 leading-3 transition`}
                        />
                      </Disclosure.Button>
                    </StyledTooltip>
                    <span className="capitalize">{workTypeStr}</span>
                  </div>

                  <Disclosure.Panel className="contents pt-3">
                    {value.map((item, index) => {
                      if (item.title) {
                        return (
                          <div
                            className="flex items-start pl-8 pt-2"
                            key={index}
                          >
                            <DisplayCustomFieldWorkTypes
                              workTypeString={workTypeStr}
                              // Type casting here because the item has a title,
                              // therefore it has to be a DynamicDetail
                              item={item as DynamicDetail}
                              handleDelete={args.handleDeleteCustomField}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div className="flex items-start pl-10" key={index}>
                            <DisplayCustomFieldTasks
                              workTypeString={workTypeStr}
                              // Safe to type cast here - the other possible scenario
                              // is that the item is a DynamicDetail, which isn't
                              // possible in this else branch
                              taskObject={
                                item as Record<string, DynamicDetail[]>
                              }
                              handleDelete={args.handleDeleteCustomField}
                              buttonRefs={args.buttonRefs}
                            />
                          </div>
                        );
                      }
                    })}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </li>
        );
      })}
    </>
  );
}

function DisplayCustomFieldWorkTypes(args: {
  workTypeString: string;
  item: DynamicDetail;
  handleDelete: (
    workTypeString: string,
    title: string,
    /** Necessary if deleting a custom field for a task - AKA, *not* necessary here */
    taskTypeString?: string,
  ) => void;
}): JSX.Element {
  return (
    <>
      <StyledTooltip title={`Delete ${args.item.title}`}>
        <TrashButton
          height="35px"
          width="35px"
          color="text-gray-600"
          onDelete={() =>
            args.handleDelete(args.workTypeString, args.item.title)
          }
        />
      </StyledTooltip>
      <span className="pt-1.5 font-normal capitalize">{args.item.title}</span>
    </>
  );
}

function DisplayCustomFieldTasks(args: {
  workTypeString: string;
  taskObject: Record<string, DynamicDetail[]>;
  handleDelete: (
    workTypeString: string,
    title: string,
    /** Necessary if deleting a custom field for a task - AKA, *necessary* here */
    taskTypeString?: string,
  ) => void;
  buttonRefs: Props["buttonRefs"];
}): JSX.Element {
  return (
    <>
      {Object.entries(args.taskObject).map(([taskTypeStr, ddList]) => (
        <div key={taskTypeStr} className="mt-2 flex flex-col">
          <Disclosure>
            {({ open }) => (
              <>
                {/* DISPLAY TASK TYPE & DROPDOWN ARROW */}
                <div className="flex items-center">
                  <StyledTooltip
                    title={`${
                      open ? "Close" : "Expand"
                    } custom field ${taskTypeStr} tasks`}
                  >
                    <Disclosure.Button
                      className="mr-2 rounded-md border border-white bg-white focus:border-gray-500 focus:outline-none"
                      // This is for use with collapsing/expanding all review details.
                      ref={(el: HTMLButtonElement) =>
                        args.buttonRefs?.current.push(el)
                      }
                    >
                      <ChevronDownIcon
                        aria-label={`${
                          open ? "Close" : "Expand"
                        } custom fields for ${taskTypeStr} tasks`}
                        className={`${
                          open ? "" : "-rotate-180 transform"
                        } h-7 w-7 leading-3 text-gray-500 transition`}
                      />
                    </Disclosure.Button>
                  </StyledTooltip>
                  <span className="capitalize text-gray-500">
                    {taskTypeStr} Tasks
                  </span>
                </div>

                <Disclosure.Panel className="contents pt-3">
                  {ddList.map((item, index) => {
                    return (
                      <div className="flex items-start pl-8 pt-2" key={index}>
                        <StyledTooltip title={`Delete ${item.title}`}>
                          <TrashButton
                            height="35px"
                            width="35px"
                            color="text-gray-600"
                            onDelete={() =>
                              args.handleDelete(
                                args.workTypeString,
                                item.title,
                                taskTypeStr,
                              )
                            }
                          />
                        </StyledTooltip>
                        <span className="pt-1.5 font-normal capitalize">
                          {item.title}
                        </span>
                      </div>
                    );
                  })}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      ))}
    </>
  );
}

function KpiReviewDetails(args: {
  data: KpiConfigCSInterface["kpiConfig"];
  title: Props["title"];
}) {
  if (!args.data) {
    devLogger.warn(`Unexpected data for ${args.title}: ${args.data}`);
    return <span>Unable to display data for {args.title}.</span>;
  }
  return (
    <>
      {args.data.map((value, index) => {
        return (
          <li key={index} className="font-normal">
            {value}
          </li>
        );
      })}
    </>
  );
}

function AdminReviewDetails(args: {
  data: AdminUserCSInterface;
  title: Props["title"];
  buttonRefs: Props["buttonRefs"];
}) {
  return (
    <>
      {Object.entries(args.data).map(([key, value], objIndex) => {
        if (key === "managementSubscriptions" || key === "permissions") {
          return (
            <DisplayNestedAdminData
              nestedKey={key}
              data={value}
              title={args.title}
              key={objIndex}
              buttonRefs={args.buttonRefs}
            />
          );
        } else {
          return (
            <li key={objIndex} className="flex flex-col">
              <span className="text-sm font-medium capitalize text-gray-400">
                {key}
              </span>

              {key === "approved" || key === "inactive"
                ? convertFromBool(value)
                : value}
            </li>
          );
        }
      })}
    </>
  );
}

function DisplayNestedAdminData(args: {
  nestedKey: string;
  data: any; // TODO: get `any` TF out 💀
  title: Props["title"];
  buttonRefs: Props["buttonRefs"];
}): JSX.Element {
  const readableNestedKey = SiteKeyUserPermissionsManager.getReadableKeys(
    args.nestedKey,
  );
  if (guardIsPlainObject(args.data)) {
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex items-center">
              <StyledTooltip
                title={`${
                  open ? "Close" : "Expand"
                } details for ${readableNestedKey}`}
              >
                <Disclosure.Button
                  className="mr-2 rounded-md border border-white bg-white focus:border-gray-500 focus:outline-none"
                  // This is for use with collapsing/expanding all review details.
                  ref={(el: HTMLButtonElement) =>
                    args.buttonRefs?.current.push(el)
                  }
                >
                  <ChevronDownIcon
                    aria-label={`${
                      open ? "Close" : "Expand"
                    } details for ${readableNestedKey}`}
                    className={`${
                      open ? "" : "-rotate-180 transform"
                    } h-7 w-7 leading-3 transition`}
                  />
                </Disclosure.Button>
              </StyledTooltip>
              {readableNestedKey}
            </div>

            <Disclosure.Panel className="contents pt-3">
              {Object.entries(args.data).map(([key, value], index) => {
                return (
                  <p key={index} className="flex flex-col pl-14 pt-2">
                    <span className="text-sm font-medium capitalize text-gray-400">
                      {SiteKeyUserPermissionsManager.getReadableKeys(key)}
                    </span>
                    {convertFromBool(value)}
                  </p>
                );
              })}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  } else {
    // This shouldn't execute
    return <>{args.data}</>;
  }
}

function convertFromBool(value: unknown): string {
  return value === true ? "Yes" : "No";
}

function DisplayPartialData(args: {
  options: Options;
  data: Props["data"];
}): JSX.Element {
  switch (args.options) {
    case "site":
      const siteData = args.data as SiteKeyCSInterface;
      const siteDataKeys = Object.keys(siteData);
      return (
        <li
          key={`${siteData.name}-1`}
          className="flex flex-col font-semibold text-gray-800"
        >
          <span className="text-sm font-medium capitalize text-gray-400">
            {siteDataKeys[0]}
          </span>
          {siteData.name}
        </li>
      );
    case "locations":
      const locationsData = args.data as LocationsCSInterface[];
      const locationsKeys = Object.keys(locationsData[0]);
      return (
        <li
          key={`${locationsData[0].title}-1`}
          className="flex flex-col font-semibold text-gray-800"
        >
          <span className="text-sm font-medium capitalize text-gray-400">
            {locationsKeys[0]}
          </span>
          {locationsData[0].department}
        </li>
      );
    case "companies":
      const companiesData = args.data as CompaniesCSInterface[];
      const companiesKeys = Object.keys(companiesData[0]);
      return (
        <li
          key={`${companiesData[0].name}-1`}
          className="flex flex-col font-semibold text-gray-800"
        >
          <span className="text-sm font-medium capitalize text-gray-400">
            {companiesKeys[0]}
          </span>
          {companiesData[0]["main point of contact"]}
        </li>
      );
    case "workAndTaskTypes":
      const wtData = args.data as WorkTypesCSInterface["workAndTaskTypes"];
      if (!wtData) return <></>;
      const firstWorkTypeKey = [...wtData.keys()].sort()[0];
      return (
        <li key={firstWorkTypeKey} className="mt-0.5 font-semibold capitalize">
          {firstWorkTypeKey}
        </li>
      );
    case "customFields":
      const groupedCustomFields = getGroupedCustomFields(
        args.data as CustomFieldsCSInterface,
      );
      if (!groupedCustomFields) return <></>;
      const groupedCFsWithSortedTaskTitles =
        sortTaskTitlesForGroupedCFs(groupedCustomFields);
      const alphabetizedCFMap = new Map(
        [...Object.entries(groupedCFsWithSortedTaskTitles)].sort(),
      );
      const firstWorkTypeStr = [...alphabetizedCFMap.keys()][0];
      return (
        <li key={firstWorkTypeStr} className="mt-0.5 font-semibold capitalize">
          {firstWorkTypeStr}
        </li>
      );
    case "kpiConfig":
      const kpiData = args.data as KpiConfigCSInterface["kpiConfig"];
      if (!kpiData) return <></>;
      return (
        <li key={kpiData[0]} className="font-normal">
          {kpiData[0]}
        </li>
      );
    case "administrator":
      const adminData = args.data as AdminUserCSInterface;
      const adminDataKeys = Object.keys(adminData);
      return (
        <li
          key={`${adminData.name}-1`}
          className="flex flex-col font-semibold text-gray-800"
        >
          <span className="text-sm font-medium capitalize text-gray-400">
            {adminDataKeys[0]}
          </span>
          {adminData.company}
        </li>
      );
    default:
      const _exhaustivenessCheck: never = args.options;
      return _exhaustivenessCheck;
  }
}
