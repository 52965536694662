//Libs
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  CellContextMenuEvent,
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  RowClickedEvent,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";
import isEqual from "lodash/isEqual";

//Local
import { ExistingEstimate } from "../../models/estimate";
import * as strings from "../../strings";
import { getEstimateStatus } from "../../assets/js/estimateFunctions";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { DollarCurrency } from "../../currency";
import { DbRead } from "../../database";
import SearchBox from "../../components/SearchBox";
import { CUSTOMERS_URL, ESTIMATES_URL_AUTH } from "../../urls";
import {
  getDefaultViewForThisTable,
  getTableViewPreferenceList,
  handleDeleteTableView,
  handleSaveNewTableViewPreference,
  handleSetDefaultView,
  setInitialView,
  TableViewPreference,
} from "../../models/table-view-preferences";
import DropdownTableSavedViews from "../../components/tables/DropdownTableSavedViews";
import EditTableViewsDialog from "../../components/tables/EditTableViewsDialog";
import SaveTableViewPreferenceDialog, {
  SaveTableViewPreferenceFormState,
} from "../../components/tables/SaveTableViewPreferenceDialog";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import { phoneUtils } from "../../utils/phoneUtils";

interface Props {
  estimateList: ExistingEstimate[];
  siteKey: string;
  goToViewEstimate: (estimate: ExistingEstimate) => void;
}

export default function EstimateListPage(props: Props) {
  const [tableViewPreferenceDialogOpen, setTableViewPreferenceDialogOpen] =
    useState<boolean>(false);
  const [editViewDialogsOpen, setEditViewDialogsOpen] =
    useState<boolean>(false);
  const [tableViewPreferenceList, setTableViewPreferenceList] = useState<
    TableViewPreference[]
  >([]);
  const [selectedView, setSelectedView] = useState<TableViewPreference | null>(
    null,
  );
  const [customersList, setCustomers] = useState<ExistingCustomer[]>([]);
  const [customerLocationsList, setCustomerLocations] = useState<
    ExistingCustomerLocation[]
  >([]);

  const gridRef = useRef<any>(null);

  useEffect(() => {
    async function getCustomerAndCustomerLocationForEstimate() {
      const customerPromises: Promise<ExistingCustomer>[] =
        props.estimateList.map((e) => {
          return DbRead.customers.get(props.siteKey, e.customerID);
        });
      const customerLocationPromises: Promise<ExistingCustomerLocation>[] =
        props.estimateList.map((e) => {
          return DbRead.customerLocations.getSingle(
            props.siteKey,
            e.customerLocationID,
          );
        });
      const customers = await Promise.all(customerPromises);
      const customerLocations = await Promise.all(customerLocationPromises);
      setCustomers(customers);
      setCustomerLocations(customerLocations);
    }

    getCustomerAndCustomerLocationForEstimate();
    return;
  }, [props.estimateList, props.siteKey]);

  const actionButtons = (
    <Fragment>
      <BaseButtonPrimary onClick={() => setTableViewPreferenceDialogOpen(true)}>
        {strings.SAVE_TABLE_VIEW}
      </BaseButtonPrimary>
      <BaseButtonSecondary onClick={() => setEditViewDialogsOpen(true)}>
        {strings.EDIT_TABLE_VIEWS}
      </BaseButtonSecondary>
    </Fragment>
  );

  const dropdownSavedViews = tableViewPreferenceList.length > 0 &&
    selectedView && (
      <DropdownTableSavedViews
        savedViewList={tableViewPreferenceList}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      />
    );

  const saveTableViewPreferenceDialog = (
    <SaveTableViewPreferenceDialog
      closeDialog={() => setTableViewPreferenceDialogOpen(false)}
      isDialogOpen={tableViewPreferenceDialogOpen}
      handleSave={(formValues: SaveTableViewPreferenceFormState) =>
        handleSaveNewTableViewPreference(
          formValues,
          "estimateList",
          gridRef,
          setTableViewPreferenceList,
          setSelectedView,
        )
      }
    />
  );

  const editTableViewsDialog = (
    <EditTableViewsDialog
      isDialogOpen={editViewDialogsOpen}
      closeDialog={() => setEditViewDialogsOpen(false)}
      deleteTableView={(viewToDelete: TableViewPreference) =>
        handleDeleteTableView(
          viewToDelete,
          setTableViewPreferenceList,
          setSelectedView,
        )
      }
      handleSetDefaultView={(viewToSetAsDefault: TableViewPreference) =>
        handleSetDefaultView(
          viewToSetAsDefault,
          setTableViewPreferenceList,
          setSelectedView,
        )
      }
      savedViewList={tableViewPreferenceList}
    />
  );

  return (
    <div className="space-y-8">
      <h1 className="text-5xl font-semibold text-primary">
        {strings.ESTIMATES}
      </h1>
      <div className={"ag-theme-alpine isolate h-[600px] pb-14"}>
        <EstimateListTable
          estimateList={props.estimateList}
          goToViewEstimate={props.goToViewEstimate}
          actionButtons={actionButtons}
          dropdownSavedViews={dropdownSavedViews}
          gridRef={gridRef}
          setSelectedView={setSelectedView}
          setTableViewPreferenceList={setTableViewPreferenceList}
          selectedView={selectedView}
          customersList={customersList}
          customerLocationsList={customerLocationsList}
        />
        {saveTableViewPreferenceDialog}
        {editTableViewsDialog}
      </div>
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

interface EstimateListTableProps {
  estimateList: Props["estimateList"];
  goToViewEstimate: Props["goToViewEstimate"];
  actionButtons: React.ReactNode;
  gridRef: React.MutableRefObject<any>;
  setSelectedView: React.Dispatch<
    React.SetStateAction<TableViewPreference | null>
  >;
  setTableViewPreferenceList: React.Dispatch<
    React.SetStateAction<TableViewPreference[]>
  >;
  dropdownSavedViews: React.ReactNode;
  selectedView: TableViewPreference | null;
  customersList: ExistingCustomer[];
  customerLocationsList: ExistingCustomerLocation[];
}

/* TABLE COMPONENT */
const EstimateListTable = memo(
  ({
    estimateList,
    goToViewEstimate,
    actionButtons,
    gridRef,
    setSelectedView,
    setTableViewPreferenceList,
    selectedView,
    dropdownSavedViews,
    customersList,
    customerLocationsList,
  }: EstimateListTableProps) => {
    const [tableGridReady, setTableGridReady] = useState<boolean>(false);

    const defaultColDef = useMemo<ColDef>(() => {
      return {
        // minWidth: 20,
        resizable: true,
        filter: true,
        sortable: true,
        headerClass: headerStyles,
        flex: 1,
      };
    }, []);

    const autoSizeStrategy = useMemo<
      | SizeColumnsToFitGridStrategy
      | SizeColumnsToFitProvidedWidthStrategy
      | SizeColumnsToContentStrategy
    >(() => {
      return {
        type: "fitGridWidth",
        defaultMinWidth: 50,
        columnLimits: [
          {
            colId: "ID",
            minWidth: 50,
          },
        ],
      };
    }, []);

    const onGridReady = useCallback(() => {
      setTableGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && tableGridReady) {
        gridRef.current.api?.sizeColumnsToFit();
        getTableViewPreferenceList("estimateList", setTableViewPreferenceList);
        const defaultView = getDefaultViewForThisTable("estimateList");
        setInitialView(defaultView, gridRef, setSelectedView);
      }
    }, [tableGridReady]);

    useEffect(() => {
      if (selectedView) {
        gridRef.current!.api.applyColumnState({
          state: selectedView.settings,
          applyOrder: true,
        });
        gridRef.current!.api.setFilterModel(selectedView.filter);
        console.log("set column state for", selectedView.name);
      }
    }, [selectedView]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const formattedStatus = (params: ICellRendererParams): JSX.Element => {
      return (
        <div className="capitalize">
          {getEstimateStatus(params.data.status)}
        </div>
      );
    };

    const formattedTimestampSentToCustomer = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return (
        <div className="capitalize">
          {params.data.timestampSentToCustomer
            ? convertToReadableTimestamp(params.data.timestampSentToCustomer)
            : ""}
        </div>
      );
    };

    const formattedTimestampCreated = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return (
        <div className="capitalize">
          {params.data.timestampCreated
            ? convertToReadableTimestamp(params.data.timestampCreated)
            : ""}
        </div>
      );
    };

    const formattedTotalAmount = (params: ICellRendererParams): JSX.Element => {
      return (
        <div>
          {params.data.customData.totalAmount
            ? DollarCurrency.format(params.data.customData.totalAmount)
            : ""}
        </div>
      );
    };

    const renderCustomerName = (params: ICellRendererParams): JSX.Element => {
      const currentCustomer = customersList.find(
        (customer) => customer.id === params.data.customerID,
      );

      return <div className="capitalize">{currentCustomer?.name}</div>;
    };

    const formattedNumber = (params: ICellRendererParams): JSX.Element => {
      return <div>{params.data.estimateNumber ?? ""}</div>;
    };

    const renderCustomerPhone = (params: ICellRendererParams): JSX.Element => {
      const currentCustomer = customersList.find(
        (customer) => customer.id === params.data.customerID,
      );

      return (
        <div className="capitalize">
          {currentCustomer?.phone && currentCustomer.phone.length > 0
            ? phoneUtils.display(currentCustomer.phone[0])
            : null}
        </div>
      );
    };

    const renderCustomerLocation = (
      params: ICellRendererParams,
    ): JSX.Element => {
      const currentCustomerLocation = customerLocationsList.find(
        (customerLocation) =>
          customerLocation.id === params.data.customerLocationID,
      );

      return (
        <div className="capitalize">{currentCustomerLocation?.fullAddress}</div>
      );
    };

    const columnDefs: ColDef[] = [
      {
        field: "estimateNumber",
        headerName: "#",
        cellRenderer: formattedNumber,
        maxWidth: 80,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        tooltipField: "estimateNumber",
      },
      {
        field: "status",
        headerName: "Status",
        cellRenderer: formattedStatus,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        tooltipField: "notes",
      },
      {
        field: "customerID",
        headerName: "Customer",
        cellRenderer: renderCustomerName,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return getNameStringForFilter(params, customersList);
        },
        tooltipField: "notes",
      },
      {
        field: "customerLocationID",
        headerName: "Customer Address",
        cellRenderer: renderCustomerLocation,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return getAddresstringForFilter(params, customerLocationsList);
        },
        tooltipField: "notes",
      },
      {
        field: "customerID",
        headerName: "Customer Phone",
        cellRenderer: renderCustomerPhone,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        tooltipField: "notes",
      },
      {
        field: "timestampSentToCustomer",
        headerName: "Sent to Customer",
        cellRenderer: formattedTimestampSentToCustomer,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        tooltipField: "notes",
      },
      {
        field: "timestampCreated",
        headerName: "Created",
        cellRenderer: formattedTimestampCreated,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        tooltipField: "notes",
      },
      {
        field: "customData.totalAmount",
        headerName: "Total Amount",
        cellRenderer: formattedTotalAmount,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        tooltipField: "notes",
      },
    ];

    return (
      <Fragment>
        <div className="mt-8 flex flex-col justify-between gap-6 sm:flex-row sm:items-end">
          <SearchBox onInput={onFilterTextBoxChanged} />
          <div className="flex items-center gap-4">
            {dropdownSavedViews}
            {actionButtons}
          </div>
        </div>
        <AgGridReact
          reactiveCustomComponents
          ref={gridRef}
          onGridReady={onGridReady}
          tooltipShowDelay={1}
          defaultColDef={defaultColDef}
          className="-z-10 mt-5 shadow"
          rowData={estimateList}
          animateRows={true}
          rowSelection="single"
          rowHeight={50}
          cacheQuickFilter={true}
          autoSizeStrategy={autoSizeStrategy}
          columnDefs={columnDefs}
          onRowClicked={(event: RowClickedEvent) =>
            goToViewEstimate(event.data)
          }
          onCellContextMenu={(event: CellContextMenuEvent<any, any>) => {
            const url = `${CUSTOMERS_URL}${ESTIMATES_URL_AUTH}/${event.data.id}`;
            return window.open(url, "_blank");
          }}
        />
      </Fragment>
    );
  },
  (previous, next) => {
    const isEstimateListTheSame = isEqual(
      previous.estimateList,
      next.estimateList,
    );

    const isDropdownTheSame = isEqual(
      previous.dropdownSavedViews,
      next.dropdownSavedViews,
    );

    const isCustomerListTheSame = isEqual(
      previous.customersList,
      next.customersList,
    );
    const isCustomerLocationsListTheSame = isEqual(
      previous.customerLocationsList,
      next.customerLocationsList,
    );

    return (
      isEstimateListTheSame &&
      isDropdownTheSame &&
      isCustomerListTheSame &&
      isCustomerLocationsListTheSame
    );
  },
);

function getNameStringForFilter(
  params: GetQuickFilterTextParams,
  customerList: ExistingCustomer[],
): string {
  const currentCustomer = customerList.find(
    (customer) => customer.id === params.data.customerID,
  );

  return currentCustomer ? currentCustomer.name : "";
}

function getAddresstringForFilter(
  params: GetQuickFilterTextParams,
  customerLocationList: ExistingCustomerLocation[],
): string {
  const currentCustomerLocation = customerLocationList.find(
    (customerLocation) =>
      customerLocation.id === params.data.customerLocationID,
  );

  return currentCustomerLocation && currentCustomerLocation.fullAddress
    ? currentCustomerLocation.fullAddress
    : "";
}
