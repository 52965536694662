//Libs
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

//Local
import { CraftTypes } from "./craft-types";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";

// #region SECTION: Schemas / Interfaces / Types
/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
export const SiteKeyCompanySchema = z.object({
  canRequestCraftTypes: z.array(z.nativeEnum(CraftTypes)),
  craftTypes: z.array(z.nativeEnum(CraftTypes)),
  isPlantCompany: z.boolean(),
  logoPhotoURL: z.string().nullable(),
  mainPointOfContact: z.string().min(1).max(200).nullable(),
  members: z.array(z.string().min(1).max(200)),
  name: z.string().min(1).max(200),
});

/** For reading from the database */
const SiteKeyCompanySchemaWithFallbacks = z.object({
  canRequestCraftTypes: zFallback(
    z.array(z.nativeEnum(CraftTypes)),
    [],
    "SiteKeyCompanySchemaWithFallbacks: 'canRequestCraftTypes'",
  ),
  craftTypes: z.array(z.nativeEnum(CraftTypes)),
  isPlantCompany: zFallback(
    z.boolean(),
    false,
    "SiteKeyCompanySchemaWithFallbacks: 'isPlantCompany'",
  ),
  logoPhotoURL: zFallback(
    z.string().nullable(),
    null,
    "SiteKeyCompanySchemaWithFallbacks: 'logoPhotoURL'",
  ),
  mainPointOfContact: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "SiteKeyCompanySchemaWithFallbacks: 'mainPointOfContact'",
  ),
  members: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "SiteKeyCompanySchemaWithFallbacks: 'members'",
  ),
  name: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SiteKeyCompanySchemaWithFallbacks: 'name'",
  ),
});

export type SiteKeyCompany = z.infer<typeof SiteKeyCompanySchema>;
// Separate type/interface for 'new' and existing
export interface ExistingSiteKeyCompany extends SiteKeyCompany {
  id: string;
  refPath: string;
}

const EditSiteKeyCompanySchema = SiteKeyCompanySchema.partial();
export type EditSiteKeyCompanyState = z.infer<typeof EditSiteKeyCompanySchema>;
// #endregion

// #region SECTION: Functions
/** Utilities for interacting with SiteKeyCompany objects. */
export const SiteKeyCompanyRecordManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingSiteKeyCompany object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createSiteKeyCompanyRecordFromSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  convertForFirestore: convertSiteKeyCompanyRecordForFirestore,
  /** Validate a SiteKeyCompany doc. Use for writing to the database or reading from the user. */
  parse: validateSiteKeyCompany,
  /** Validate a SiteKeyCompany doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateSiteKeyCompanyWithFallbacks,
  /** Validation. Use for editing a SiteKeyCompany doc. No fallbacks will be used. */
  parseEdit: validateEditSiteKeyCompany,
};

/** Rejects if it doesn't pass validation */
function validateSiteKeyCompany(siteKeyCompany: unknown): SiteKeyCompany {
  if (!guardIsPlainObject(siteKeyCompany)) {
    throw new Error(`SiteKeyCompany is not an object: ${siteKeyCompany}`);
  }

  return SiteKeyCompanySchema.parse(siteKeyCompany);
}

/**
 * Convert the Document Snapshot into a validated ExistingSiteKeyCompany object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createSiteKeyCompanyRecordFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingSiteKeyCompany {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateSiteKeyCompanyWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertSiteKeyCompanyRecordForFirestore(
  siteKeyCompany: ExistingSiteKeyCompany,
): DocumentData {
  const local = Object.assign({}, siteKeyCompany);
  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/** For validating stuff coming in from the database. */
function validateSiteKeyCompanyWithFallbacks(company: unknown): SiteKeyCompany {
  if (!guardIsPlainObject(company)) {
    throw new Error(`SiteKeyCompany is not an object: ${company}`);
  }

  return SiteKeyCompanySchemaWithFallbacks.parse(company);
}

function validateEditSiteKeyCompany(value: unknown): EditSiteKeyCompanyState {
  if (!guardIsPlainObject(value)) {
    throw new Error(`EditSiteKeyCompanyDoc is not an object ${value}`);
  }
  const result = EditSiteKeyCompanySchema.parse(value);
  return result;
}
// #endregion
