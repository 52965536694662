// Libs
import { z } from "zod";
import { DocumentSnapshot, DocumentData } from "firebase/firestore";
import * as sentry from "@sentry/react";

// Local
import { dropUndefined, guardIsPlainObject } from "../utils";
import { ResponseTypes } from "./checklist-response-types";
import { zFallback } from "../utils/zod-fallback";
import { NotFoundError } from "../error-classes";

// #region SECTION: Interfaces / Schemas
export interface ChecklistItem {
  /**
   * The main text of the checklist item or question.
   */
  mainText: string;

  /**
   * Additional notes or information the author wants to include with the item.
   */
  note: string;

  /**
   * Tags are an array of strings used for grouping and sorting various
   * checklist items together.
   */
  tags: string[];

  /**
   * Whether the author considers this checklist item required. Primarily used
   * to filter a list of items to view only those considered required.
   */
  required: boolean;

  /**
   * reponseTypes tell the server and the client devices how to display and
   * validate submissions. And what input components and widgets are needed.
   */
  responseType: ResponseTypes;

  /**
   * passingMax and passingMin apply only to numerical response types. Integer
   * and float at present time. However, one or both may still be `null` if a
   * minimum or maximum does not apply to the checklist item.
   */
  passingMax: number | null;
  passingMin: number | null;

  /**
   * Units is only used to for display on client devices. It should be an empty
   * string if units are not applicable.
   */
  units: string;

  /**Selection options are a set of strings of possible choices. */
  selectionOptions: string[] | null;
  /**
   * Passing options are a subset of selection options that are considered
   * 'passing'.
   */
  passingOptions: string[] | null;

  /**
   * An array of strings (Document IDs) for Craft Records that use this
   * checklist item. If the item was added to the overall list of items without
   * being associated with a craft record, it should be an empty array.
   */
  craftRecordIDs: string[];

  /**
   * A boolean field that must exist on all documents as `false` initially. The
   * value can be set to `true` to indicate a document has been deleted. This
   * system is being used instead of moving the document to a different
   * Firestore collection.
   */
  deleted: boolean;

  customData?: { [key: string]: any };
}

export interface ExistingChecklistItem extends ChecklistItem {
  id: string;
  refPath: string;
}

// #region SECTION: Schemas for each possible responseType
const CommonSchema = z.object({
  mainText: z.string().min(1).max(2000),
  note: z.string().max(2000),
  tags: z.array(z.string().min(1).max(200)),
  required: z.boolean(),
  units: z.string().max(200),
  craftRecordIDs: z.array(z.string().min(1).max(200)),
  deleted: z.boolean(),
  customData: z.record(z.any()).optional(),
});

const CommonSchemaWithFallbacks = z.object({
  mainText: zFallback(
    z.string().min(1).max(2000),
    "unknown",
    "ChecklistItem - CommonSchemaWithFallbacks: 'mainText'",
  ),
  note: zFallback(
    z.string().max(2000),
    "",
    "ChecklistItem - CommonSchemaWithFallbacks: 'note'",
  ),
  tags: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "ChecklistItem - CommonSchemaWithFallbacks: 'tags'",
  ),
  required: zFallback(
    z.boolean(),
    false,
    "ChecklistItem - CommonSchemaWithFallbacks: 'required'",
  ),
  units: zFallback(
    z.string().max(200),
    "unknown",
    "ChecklistItem - CommonSchemaWithFallbacks: 'unit'",
  ),
  craftRecordIDs: z.array(z.string().min(1).max(200)),
  deleted: zFallback(
    z.boolean(),
    false,
    "ChecklistItem - CommonSchemaWithFallbacks: 'deleted'",
  ),
  customData: z.record(z.any()).optional(),
});

// Stuff that has different requirements when responseType is 'string'.
const StringChecklistItemSchema = CommonSchema.extend({
  responseType: z.literal("string"),
  passingMax: z.null(),
  passingMin: z.null(),
  selectionOptions: z.null(),
  passingOptions: z.null(),
});

const StringChecklistItemSchemaWithFallbacks = CommonSchemaWithFallbacks.extend(
  {
    responseType: z.literal("string"),
    passingMax: zFallback(
      z.null(),
      null,
      "StringChecklistItemSchemaWithFallbacks: 'passingMax'",
    ),
    passingMin: zFallback(
      z.null(),
      null,
      "StringChecklistItemSchemaWithFallbacks: 'passingMin'",
    ),
    selectionOptions: zFallback(
      z.null(),
      null,
      "StringChecklistItemSchemaWithFallbacks: 'selectionOptions'",
    ),
    passingOptions: zFallback(
      z.null(),
      null,
      "StringChecklistItemSchemaWithFallbacks: 'passingOptions'",
    ),
  },
);

// Stuff that has different requirements when responseType is 'selection'.
const SelectionChecklistItemSchema = CommonSchema.extend({
  responseType: z.literal("selection"),
  passingMax: z.null(),
  passingMin: z.null(),
  selectionOptions: z.array(z.string().min(1).max(200)).nonempty(),
  passingOptions: z.array(z.string().min(1).max(200)).nonempty(),
});

const SelectionChecklistItemSchemaWithFallbacks =
  CommonSchemaWithFallbacks.extend({
    responseType: z.literal("selection"),
    passingMax: zFallback(
      z.null(),
      null,
      "SelectionChecklistItemSchemaWithFallbacks: 'passingMax'",
    ),
    passingMin: zFallback(
      z.null(),
      null,
      "SelectionChecklistItemSchemaWithFallbacks: 'passingMin'",
    ),
    selectionOptions: z.array(z.string().min(1).max(200)).nonempty(),
    passingOptions: z.array(z.string().min(1).max(200)).nonempty(),
  });

// Stuff that has different requirements when responseType is 'integer'.
const IntegerChecklistItemSchema = CommonSchema.extend({
  responseType: z.literal("integer"),
  passingMax: z.number().int().nullable(),
  passingMin: z.number().int().nullable(),
  selectionOptions: z.null(),
  passingOptions: z.null(),
});

const IntegerChecklistItemSchemaWithFallbacks =
  CommonSchemaWithFallbacks.extend({
    responseType: z.literal("integer"),
    passingMax: zFallback(
      z.number().int().nullable(),
      null,
      "IntegerChecklistItemSchemaWithFallbacks: 'passingMax'",
    ),
    passingMin: zFallback(
      z.number().int().nullable(),
      null,
      "IntegerChecklistItemSchemaWithFallbacks: 'passingMin'",
    ),
    selectionOptions: zFallback(
      z.null(),
      null,
      "IntegerChecklistItemSchemaWithFallbacks: 'selectionOptions'",
    ),
    passingOptions: zFallback(
      z.null(),
      null,
      "IntegerChecklistItemSchemaWithFallbacks: 'passingOptions'",
    ),
  });

// Stuff that has different requirements when responseType is 'float'.
const FloatChecklistItemSchema = CommonSchema.extend({
  responseType: z.literal("float"),
  passingMax: z.number().nullable(),
  passingMin: z.number().nullable(),
  selectionOptions: z.null(),
  passingOptions: z.null(),
});

const FloatChecklistItemSchemaWithFallbacks = CommonSchemaWithFallbacks.extend({
  responseType: z.literal("float"),
  passingMax: zFallback(
    z.number().nullable(),
    null,
    "FloatChecklistItemSchemaWithFallbacks: 'passingMax'",
  ),
  passingMin: zFallback(
    z.number().nullable(),
    null,
    "FloatChecklistItemSchemaWithFallbacks: 'passingMin'",
  ),
  selectionOptions: zFallback(
    z.null(),
    null,
    "FloatChecklistItemSchemaWithFallbacks: 'selectionOptions'",
  ),
  passingOptions: zFallback(
    z.null(),
    null,
    "FloatChecklistItemSchemaWithFallbacks: 'passingOptions'",
  ),
});
// #endregion
// #endregion

// #region SECTION: Functions
/**
 * Collection of functions that interact with ChecklistItems
 */
export const ChecklistItemManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingChecklistItem object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database. Drop undefined values */
  convertForFirestore: convertForFirestore,
  /** Validate a ChecklistItem doc. Use for writing to the database or reading from the user. */
  parse: validateChecklistItem,
  /** Validate a ChecklistItem doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateChecklistItemWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingChecklistItem object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingChecklistItem {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateChecklistItemWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertForFirestore(
  checklistItem: ExistingChecklistItem,
): DocumentData {
  const localItem = Object.assign({}, checklistItem);
  const { id, refPath, ...rest } = localItem;
  return dropUndefined(rest);
}

/** Rejects if it doesn't pass validation */
function validateChecklistItem(item: unknown): ChecklistItem {
  if (!guardIsPlainObject(item)) {
    throw new Error(`ChecklistItem is not an object: ${item}`);
  }

  switch (item.responseType) {
    case "selection": {
      const result = SelectionChecklistItemSchema.parse(item);
      // Extra custom validation.
      const isValid = checkPassingOptionsInSelectionOptions(
        result.selectionOptions,
        result.passingOptions,
      );
      if (isValid === false) {
        throw new Error(`passingOption is not found in selectionOption`);
      }
      return result as ChecklistItem; // casting value because we don't have strict mode enabled yet.
    }
    case "string": {
      const result = StringChecklistItemSchema.parse(item);
      return result as ChecklistItem;
    }
    case "integer": {
      const result = IntegerChecklistItemSchema.parse(item);
      return result as ChecklistItem;
    }
    case "float": {
      const result = FloatChecklistItemSchema.parse(item);
      return result as ChecklistItem;
    }
    default:
      throw new Error(`Unexpected responseType: ${item.responseType}`);
  }
}

/** For validating stuff coming in from the database. */
function validateChecklistItemWithFallbacks(item: unknown): ChecklistItem {
  if (!guardIsPlainObject(item)) {
    throw new Error(`ChecklistItem is not an object: ${item}`);
  }

  switch (item.responseType) {
    case "selection": {
      const result = SelectionChecklistItemSchemaWithFallbacks.parse(item);
      // Extra custom validation.
      checkPassingOptionsInSelectionOptions(
        result.selectionOptions,
        result.passingOptions,
      );

      return result as ChecklistItem;
    }

    case "string": {
      const result = StringChecklistItemSchemaWithFallbacks.parse(item);
      return result as ChecklistItem;
    }

    case "integer": {
      const result = IntegerChecklistItemSchemaWithFallbacks.parse(item);
      return result as ChecklistItem;
    }

    case "float": {
      const result = FloatChecklistItemSchemaWithFallbacks.parse(item);
      return result as ChecklistItem;
    }

    default:
      throw new Error(`Unexpected responseType: ${item.responseType}`);
  }
}

/** Return false & send an alert to sentry if passing options are not found in selection options. */
export function checkPassingOptionsInSelectionOptions(
  selectionOptions: string[],
  passingOptions: string[],
): boolean {
  const result = passingOptions.map((pOption) => {
    if (!selectionOptions.includes(pOption)) {
      sentry.captureException(
        new Error(`Value not found in selection options. Value: ${pOption}`),
      );
      return false;
    } else {
      return true;
    }
  });
  const isValid = result.every((value) => value === true);
  return isValid;
}
// #endregion
