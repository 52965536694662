// Libs
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import { useMemo, useState, useEffect, Fragment } from "react";

// Local
import * as strings from "../../../strings";
import { EstimateStatus, ExistingEstimate } from "../../../models/estimate";
import { TaskTypes, getReadableTaskType } from "../../../models/task-types";
import { ExistingCraftRecord } from "../../../models/craft-record";
import { getReadableCraftType } from "../../../models/craft-types";
import TaskTypeDropdown from "./TaskTypeDropdown";
import TaskTypeCombobox from "./TaskTypeCombobox";
import { ExistingMembershipTemplate } from "../../../models/membership-template";

interface Props {
  existingCraftRecord: ExistingCraftRecord | null;
  estimateStatus?: ExistingEstimate["status"];
  membershipTemplate?: ExistingMembershipTemplate;
  workAndTaskMap: Map<string, string[]>;
  selectedWorkType: string;
  setSelectedWorkType: (workType: string) => void;
  setSelectedTaskType: (taskType: string | null) => void;
}

export function WorkAndTaskTypesSection(props: Props): JSX.Element {
  const justWorkTypes = useMemo(
    () => [...props.workAndTaskMap.keys()],
    [props.workAndTaskMap],
  );

  function getTaskType(
    tasks?: string[],
    estimateStatus?: ExistingEstimate["status"],
  ): string {
    if (estimateStatus === EstimateStatus.APPROVED) {
      return getReadableTaskType(TaskTypes.REPAIR);
    } else if (estimateStatus === EstimateStatus.ON_HOLD) {
      return getReadableTaskType(TaskTypes.PHONE_CALL);
    } else {
      if (tasks && tasks.length === 1) {
        return tasks[0];
      } else {
        return "Select...";
      }
    }
  }

  // Get the task types associated with currentWorkType. Will be undefined if
  // currentWorkType is "Select..."
  const tasks = props.workAndTaskMap.get(props.selectedWorkType ?? "Select...");

  // if the task is created from an existing estimate, we need to preselect a a task type depending on estimateStatus
  // Want to preselect the task type if it's the only one available.
  const initialTTDisplay = getTaskType(tasks, props.estimateStatus);
  const [currentTaskType, setCurrentTaskType] = useState(initialTTDisplay);

  useEffect(() => {
    if (
      props.membershipTemplate &&
      props.membershipTemplate.taskGeneration.length > 0
    ) {
      const craftTypeString = getReadableCraftType(
        props.membershipTemplate.taskGeneration[0].craftType,
      );
      const taskTypeString = getReadableTaskType(
        props.membershipTemplate.taskGeneration[0].taskType,
      );
      props.setSelectedWorkType(craftTypeString);
      setCurrentTaskType(taskTypeString);
      props.setSelectedTaskType(taskTypeString);
    }
  }, [props.membershipTemplate]);

  useEffect(() => {
    // If creating task from existing craftRecord, set that craftRecord's
    // craftType and don't let user change it (until we support multicraft...)
    if (props.existingCraftRecord?.craftType) {
      props.setSelectedWorkType(
        getReadableCraftType(props.existingCraftRecord.craftType),
      );
    } else if (justWorkTypes.length === 1) {
      // If there's only one work type, the user won't select anything in the dropdown,
      // therefore there will be no onChange event, and we need to set this manually.
      props.setSelectedWorkType(justWorkTypes[0]);
    }

    // If there's only one task type, the user won't select anything in the dropdown,
    // therefore there will be no onChange event, and we need to set this manually.
    if (tasks && tasks.length === 1) {
      props.setSelectedTaskType(tasks[0]);
    }
  }, [justWorkTypes, props, tasks]);

  return (
    <div className="mb-12 mt-6 grid gap-6 lg:grid-cols-2">
      <div>
        <label
          htmlFor="selectWorkType"
          className="mb-2 inline-block text-xl text-primary"
        >
          {strings.WORK_TYPE}
          <span className="pl-2 text-xl font-medium text-redFail">*</span>
        </label>
        {/* WORK TYPES DROPDOWN */}
        <div className="relative z-20">
          <div className="w-64 xs:w-96 md:w-fit md:min-w-[24rem]">
            <Listbox
              value={props.selectedWorkType}
              disabled={props.existingCraftRecord?.craftType != null}
              onChange={(event) => {
                // 'event' contains the workType string
                props.setSelectedWorkType(event);
                // Recompute the current task type (reset the task type dropdown)
                const newTasks = props.workAndTaskMap.get(event ?? "Select...");
                setCurrentTaskType(
                  newTasks && newTasks.length === 1 ? newTasks[0] : "Select...",
                );
                // Either set or essentially clear out props.setSelectedTaskType
                if (newTasks && newTasks.length === 1) {
                  props.setSelectedTaskType(newTasks[0]);
                } else {
                  // In the case of the user selecting a different work type and
                  // leaving the task types dropdown with "Select..." in it, we need
                  // to set an invalid task type here.
                  props.setSelectedTaskType(null);
                }
              }}
            >
              <div className="relative mt-1">
                <Listbox.Button
                  id="selectWorkType"
                  className="relative h-10 w-full cursor-pointer rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight"
                >
                  <span className="block truncate">
                    {props.selectedWorkType}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-700"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-32 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {/* <Listbox.Option
                      disabled={true}
                      value={null}
                      className={
                        `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-700`
                      }
                    >
                      <span
                        className={`block truncate font-normal`}
                      >
                        Select...
                      </span>
                    </Listbox.Option> */}
                    {justWorkTypes.map((workType) => (
                      <Listbox.Option
                        key={workType}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active || selected
                              ? "bg-primaryOpacity90 text-primary"
                              : "text-gray-700"
                          }`
                        }
                        value={workType}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {workType}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
      </div>
      {/* TASK TYPES */}
      <div>
        <label
          htmlFor="selectTaskType"
          className="mb-2 inline-block text-xl text-primary"
        >
          {strings.TASK_TYPE}
          <span className="pl-2 text-xl font-medium text-redFail">*</span>
        </label>
        {/* TASK TYPES DROPDOWN */}
        <div className="relative z-10">
          <div className="w-64 xs:w-96 md:w-fit md:min-w-[24rem]">
            {tasks && tasks.length >= 6 ? (
              <TaskTypeCombobox
                currentTaskType={currentTaskType}
                setCurrentTaskType={setCurrentTaskType}
                setSelectedTaskType={props.setSelectedTaskType}
                tasks={tasks}
              />
            ) : (
              <TaskTypeDropdown
                currentTaskType={currentTaskType}
                setCurrentTaskType={setCurrentTaskType}
                setSelectedTaskType={props.setSelectedTaskType}
                tasks={tasks}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
