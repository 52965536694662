// Libs
import * as sentry from "@sentry/react";
// Local
import { Channel } from "./types";

/**
 * Creates a channel for console logging.
 */
export function createSentryChannel(id = "sentry"): Channel {
  const newChannel: Channel = {
    id: id,
    methods: {
      debug(...data) {
        defaultArgsParseLogic(data, "debug");
      },
      info(...data) {
        defaultArgsParseLogic(data, "info");
      },
      warn(...data) {
        defaultArgsParseLogic(data, "warning");
      },
      error(...data) {
        if (data[0] instanceof Error) {
          sentry.captureException(data[0]);
        } else if (typeof data[0] === "string") {
          sentry.captureMessage(data[0], {
            extra: { args: data },
            level: "error",
          });
        } else {
          sentry.captureMessage("An unknown error was logged", {
            extra: { args: data },
            level: "error",
          });
        }
      },
    },
  };

  return newChannel;
}

function arrayToRecord(value: unknown[]): Record<string, unknown> {
  return value.reduce<Record<string, unknown>>((acc, curr, index) => {
    acc[`${index}`] = curr;
    return acc;
  }, {});
}

function defaultArgsParseLogic(
  data: unknown[],
  level: sentry.SeverityLevel,
): void {
  const [message, ...rest] = data;
  const extras = rest ? arrayToRecord(rest) : undefined;
  if (typeof message === "string") {
    sentry.addBreadcrumb({
      level: level,
      message: message,
      data: extras,
    });
  } else {
    sentry.addBreadcrumb({
      level: level,
      data: extras,
    });
  }
}
