//Libs

//Local
import {
  ExistingMembershipTemplate,
  MembershipDiscount,
} from "../../../models/membership-template";
import { ExistingMembership, Membership } from "../../../models/membership";

export function getMembershipDiscountsForCustomer(
  membershipsForCustomer: ExistingMembership[] | Membership[],
  membershipTemplateList: ExistingMembershipTemplate[],
): MembershipDiscount[] {
  if (membershipsForCustomer.length === 0) return [];

  const discounts: (MembershipDiscount | undefined)[] = [];

  for (const membership of membershipsForCustomer) {
    const template = membershipTemplateList.find(
      (template) => template.id === membership.membershipTemplateID,
    );
    if (template != null && membership.status === "active") {
      discounts.push({
        title: template.title,
        discount: template.discount,
        value: false,
      });
    }
  }

  const result = discounts.filter<MembershipDiscount>(
    (membership): membership is MembershipDiscount => membership != null,
  );
  return result;
}
