// Libs
import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";

// Local
import { TaskStatus } from "../../../models/task-status";
import * as strings from "../../../strings";
import { convertFSTimestampToLuxonDT } from "../../../utils";
import { ScheduleByPriorityType } from "../../../components/customers/CreateTask/SchedulingSection";

// Tested ✅
export function getTaskStatusForApprovedTask(
  type: ScheduleByPriorityType | "" | null,
  scheduleDate: Timestamp | null,
): TaskStatus {
  switch (type) {
    case null:
    case "": {
      if (isScheduledForToday(scheduleDate)) {
        return TaskStatus.AWAITING;
      } else {
        return TaskStatus.SCHEDULED;
      }
    }
    case strings.URGENT:
      return TaskStatus.AWAITING;
    case strings.AWAITING_SCHEDULE:
      return TaskStatus.AWAITING_SCHEDULE;
    case strings.NEXT_OPPORTUNITY:
      return TaskStatus.AWAITING;
    case strings.AWAITING_PARTS:
      return TaskStatus.AWAITING_PARTS;
    case strings.AWAITING_ESTIMATE:
      return TaskStatus.AWAITING_ESTIMATE;
    case strings.AWAITING_APPROVAL:
      return TaskStatus.AWAITING_APPROVAL;
    default:
      const _exhaustivenessCheck: never = type;
      return _exhaustivenessCheck;
  }
}

function isScheduledForToday(timestampScheduled: Timestamp | null): boolean {
  if (timestampScheduled) {
    const dt = convertFSTimestampToLuxonDT(timestampScheduled).toLocal();

    return DateTime.now().hasSame(dt, "day");
  } else {
    return false;
  }
}
