//Libs
import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { Fragment, forwardRef, useCallback, useRef } from "react";

//Local
import BaseInputCheckbox from "../BaseInputCheckbox";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";

const SiteKeyLocationCustomFilter = forwardRef(
  (
    {
      model,
      onModelChange,
      getValue,
    }: CustomFilterProps<any, any, { value: string[] }>,
    _ref,
  ) => {
    const siteKeyLocationList = useSiteKeyLocationsStore(
      (state) => state.siteKeyLocationList,
    );

    const refInput = useRef<HTMLInputElement>(null);

    const doesFilterPass = useCallback(
      ({ node }: IDoesFilterPassParams) => {
        const nodeValue = getValue(node);
        if (nodeValue == null) return false;
        if (model) {
          /* check if any of the selected assignedTo id contained in the model.value array are in the nodeValues array  */
          return model.value.some((r) => nodeValue.includes(r));
        } else return false;
      },
      [model],
    );

    const afterGuiAttached = useCallback(() => {
      window.setTimeout(() => {
        refInput.current?.focus();
      });
    }, []);

    useGridFilter({
      doesFilterPass,
      afterGuiAttached,
    });

    const onChange = (value: string[] | null) => {
      if (value == null) {
        onModelChange(null);
      } else {
        onModelChange({ value });
      }
    };

    return (
      <Fragment>
        <form className="flex w-fit rounded-md">
          <div className="flex flex-col gap-2 whitespace-nowrap  p-3">
            {siteKeyLocationList.map((location) => {
              return (
                <BaseInputCheckbox
                  key={location.id}
                  label={location.title}
                  checked={
                    model != null ? model.value.includes(location.id) : false
                  }
                  onChange={(event) => {
                    const value = event.target.checked;
                    if (value === true) {
                      const modelCopy = model === null ? [] : [...model!.value];
                      onChange([...modelCopy, location.id]);
                    } else {
                      const modelCopy = [...model!.value];
                      const taskStatusIndex = modelCopy.findIndex(
                        (value) => location.id === value,
                      );
                      modelCopy.splice(taskStatusIndex, 1);
                      if (modelCopy.length === 0) {
                        onChange(null);
                      } else {
                        onChange(modelCopy);
                      }
                    }
                  }}
                  ref={refInput}
                  className="form-control"
                />
              );
            })}
            <BaseButtonSecondary
              type="button"
              className="form-control"
              onClick={() => onChange(null)}
            >
              Reset
            </BaseButtonSecondary>
          </div>
        </form>
      </Fragment>
    );
  },
);

export default SiteKeyLocationCustomFilter;
