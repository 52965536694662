//Libs
import { useNavigate } from "react-router-dom";

//Local
import MySitesPage from "./MySitesPage";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { ExistingSiteKey } from "../../models/site-key";
import { useMemo } from "react";
import { ADMIN_MY_SITES_URL } from "../../urls";
import { useRootUserSiteKeyListStore } from "../../store/root-user-site-key-list";

export default function MySitesContainer() {
  const navigate = useNavigate();

  function handleViewSite(siteKey: string) {
    navigate(`${ADMIN_MY_SITES_URL}/${siteKey}`);
  }

  const [rootUserSiteKeyList, isLoadingRootUserSiteKeyList] =
    useRootUserSiteKeyListStore((state) => [
      state.rootUserSiteKeyList,
      state.loading,
    ]);

  /* Display the order of the site keys alphabetically */
  const sortedRootUserSiteKeyList = useMemo(
    function (): ExistingSiteKey[] {
      return rootUserSiteKeyList.sort((a, b) => a.id!.localeCompare(b.id!));
    },
    [rootUserSiteKeyList],
  );

  if (isLoadingRootUserSiteKeyList && !sortedRootUserSiteKeyList) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <MySitesPage
      handleViewSite={handleViewSite}
      sites={sortedRootUserSiteKeyList}
      loadingState={isLoadingRootUserSiteKeyList}
    />
  );
}
