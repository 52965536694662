import React from "react";
import { SiQuickbooks } from "react-icons/si";

// Local
import LoadingSpinner from "./LoadingSpinner";
import { CONNECT } from "../strings";

type Ref = HTMLButtonElement;
type Props = {
  isBusy: boolean;
} & React.ComponentPropsWithRef<"button">;

const ButtonConnectQB = React.forwardRef<Ref, Props>(
  ({ isBusy, ...props }, ref): JSX.Element => {
    return (
      <button
        {...props}
        ref={ref}
        disabled={isBusy || props.disabled}
        // ${ props.disabled && !isBusy ? "bg-gray-100 text-gray-500" : "bg-[#2CA01C] text-white hover:bg-[#258818]" }
        className={`inline-flex items-center justify-center gap-2 rounded-md bg-[#2CA01C] px-4 py-2 uppercase tracking-wide text-white shadow-sm transition-colors hover:bg-[#258818] focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2`}
      >
        {isBusy ? (
          <LoadingSpinner marginClass="" />
        ) : (
          <SiQuickbooks className="shrink-0" />
        )}
        {CONNECT}
      </button>
    );
  },
);

export default ButtonConnectQB;
