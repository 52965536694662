// Libs
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

/** Given a JavaScript Date object, returns a Firestore Timestamp. */
export function convertJSDateToFSTimestamp(date: Date): Timestamp {
  const dt = DateTime.fromJSDate(date).toUTC();
  if (!dt.isValid) {
    throw new Error(`Luxon DT object is not valid. Received: ${date}`);
  }

  // Luxon DT -> milliseconds -> Timestamp
  const ms = dt.toMillis();
  const ts = Timestamp.fromMillis(ms);
  if (Number.isNaN(ts.seconds)) {
    throw new Error(`Invalid Timestamp. Received: ${dt}`);
  }
  return ts;
}
