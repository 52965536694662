//Libs
import EditIcon from "@mui/icons-material/Edit";

//Local
import * as strings from "../../strings";
import { StyledTooltip } from "../StyledTooltip";
import {
  ExistingMembership,
  getReadableMembershipStatus,
} from "../../models/membership";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import * as urls from "../../urls";
import Link from "../StiltLink";
import React from "react";

export function MembershipDetails({
  membership,
  openEditMembershipDialog,
}: {
  membership: ExistingMembership;
  openEditMembershipDialog: (() => void) | null;
}) {
  return (
    <div className="mr-2 w-full flex-row pt-4 font-bold text-gray-800 md:pt-1">
      <div className="mb-2 flex w-full flex-row justify-between">
        <div className="flex flex-row space-x-4">
          <ul className="min-w-[400px] space-y-2.5">
            <div className="flex flex-row space-x-4">
              <div className="flex flex-col space-y-2.5">
                <li className="flex flex-col capitalize">
                  <span className="text-sm font-medium text-gray-400">
                    {strings.MEMBERSHIP_START_DATE}
                  </span>
                  {membership.membershipStartDate
                    ? convertToReadableTimestampDate(
                        membership.membershipStartDate,
                      )
                    : "--"}
                </li>
                <li className="flex flex-col capitalize">
                  <span className="text-sm font-medium text-gray-400">
                    {strings.MEMBERSHIP_END_DATE}
                  </span>
                  {membership.membershipEndDate
                    ? convertToReadableTimestampDate(
                        membership.membershipEndDate,
                      )
                    : "--"}
                </li>
              </div>
              <div className="flex flex-col space-y-2.5">
                <li className="flex flex-col capitalize">
                  <span className="text-sm font-medium text-gray-400">
                    {strings.LAST_COMPLETED_TASK_DATE}
                  </span>
                  {membership.lastCompletedTaskDate
                    ? convertToReadableTimestampDate(
                        membership.lastCompletedTaskDate,
                      )
                    : "--"}
                </li>
                <li className="flex flex-col capitalize">
                  <span className="text-sm font-medium text-gray-400">
                    {strings.NEXT_SCHEDULED_TASK_DATE}
                  </span>
                  {membership.nextScheduledTaskDate
                    ? convertToReadableTimestampDate(
                        membership.nextScheduledTaskDate,
                      )
                    : "--"}
                </li>
              </div>
              <div className="flex flex-col space-y-2.5">
                <li className="flex flex-col capitalize">
                  <span className="text-sm font-medium text-gray-400">
                    {strings.LAST_PAYMENT_DATE}
                  </span>
                  {membership.lastPaymentDate
                    ? convertToReadableTimestampDate(membership.lastPaymentDate)
                    : "--"}
                </li>
                <li className="flex flex-col capitalize">
                  <span className="text-sm font-medium text-gray-400">
                    {strings.NEXT_INVOICE_DATE}
                  </span>
                  {membership.nextInvoiceDate
                    ? convertToReadableTimestampDate(membership.nextInvoiceDate)
                    : "--"}
                </li>
              </div>
            </div>
          </ul>
        </div>
        <div className="flex flex-col">
          {openEditMembershipDialog && (
            <StyledTooltip title="Edit Membership">
              <EditIcon
                aria-label="edit membership"
                className="h-9 w-9 hover:text-primaryDark"
                onClick={openEditMembershipDialog}
              />
            </StyledTooltip>
          )}
        </div>
      </div>
      {membership.renewedFromMembershipID && (
        <div className="flex flex-row">
          <Link
            to={`${urls.MEMBERSHIPS}/${membership.renewedFromMembershipID}`}
            className="text-sm text-blue-500 underline"
          >
            {strings.RENEWED_FROM_PRVIOUS_MEMBERSHIP}
          </Link>
        </div>
      )}
      {membership.renewedToMembershipID && (
        <div className="flex flex-row">
          <Link
            to={`${urls.MEMBERSHIPS}/${membership.renewedToMembershipID}`}
            className="text-sm text-blue-500 underline"
          >
            {strings.RENEWED_TO_NEW_MEMBERSHIP}
          </Link>
        </div>
      )}
      <p className="mt-2 flex w-full rounded py-2 text-base text-gray-900">
        <TextSnippetIcon
          className="mr-2 mt-0.5"
          aria-hidden="true"
          style={{ height: "1.2rem", width: "1.2rem" }}
        />
        {membership.notes === null || membership.notes === ""
          ? "No notes"
          : membership.notes}
      </p>
    </div>
  );
}
