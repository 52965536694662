//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { logger as devLogger } from "../logging";
import { useEffect, useState } from "react";

//Local
import BaseModal from "./BaseModal";
import * as strings from "../strings";
import { accountTypes } from "../models/gl-account";
import { ErrorMessage } from "./ErrorMessage";
import BaseInputText from "./BaseInputText";
import StyledMessage from "./StyledMessage";
import BaseInputSelect from "./BaseInputSelect";
import BaseButtonSecondary from "./BaseButtonSecondary";
import BaseButtonPrimary from "./BaseButtonPrimary";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSave: (newGLAccount: GLAccountFormState) => Promise<void>;
}

export const GLAccountFormSchema = z.object({
  name: z.string().min(1).max(300),
  accountType: z.enum(accountTypes),
});
export type GLAccountFormState = z.infer<typeof GLAccountFormSchema>;

const GLAccountFormDefaultValues: GLAccountFormState = {
  name: "",
  accountType: accountTypes[0],
};

export default function AddNewGLAccountDialog({
  closeDialog,
  isDialogOpen,
  handleSave,
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<GLAccountFormState>({
    defaultValues: GLAccountFormDefaultValues,
    resolver: zodResolver(GLAccountFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(GLAccountFormDefaultValues);
  }, [GLAccountFormDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    closeDialog();
  }

  const onSubmit: SubmitHandler<GLAccountFormState> = async (formValues) => {
    try {
      await handleSave(formValues);
      // Close dialog if successful.
      closeAndReset();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  const errorMessage = (
    <ErrorMessage
      message={strings.ERROR_CREATING_GL_ACCOUNT}
      clearMessage={() => setDisplayError(false)}
    />
  );

  const gLAccountHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.NEW_ITEM}
      </h1>
      <button type="button" onClick={() => closeAndReset()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={isDialogOpen}
      title={gLAccountHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          {/* Field: Name */}
          <div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Name"
                  inputName="name"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.name?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.name.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          <div>
            <Controller
              name="accountType"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="accountType"
                  text="Account Type"
                  admin={true}
                  required={true}
                  className="capitalize"
                  {...field}
                  value={field.value}
                  onChange={(event) => field.onChange(event.target.value)}
                >
                  {accountTypes.map((accountType, idx) => {
                    return (
                      <option key={idx} value={accountType}>
                        {accountType}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              )}
            />
            {errors.accountType?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.accountType.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>

        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}
