//Libs

//Local
import { ExistingComplianceRequirement } from "../../models/compliance-requirement";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { getSortedComplianceItemList } from "../../assets/js/getSortedComplianceItemList";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { showReadableRequirementRanges } from "../../assets/js/showReadableRequirementRanges";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface Props {
  requirement: ExistingComplianceRequirement;
  isDialogOpen: boolean;
  closeDialog: () => void;
}

export default function ComplianceRequirementRangesDialog({
  requirement,
  closeDialog,
  isDialogOpen,
}: Props) {
  console.log("requirement", requirement);

  const sortedComplianceItemList = getSortedComplianceItemList(
    requirement.customFields,
  );

  const requirementDetailsHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.REQUIREMENT_DETAILS}
      </h1>
      <button type="button" onClick={closeDialog}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      open={isDialogOpen}
      title={requirementDetailsHeader}
      closeModal={closeDialog}
      parentDivStyles="text-left max-w-sm sm:max-w-xl"
    >
      <div className="relative flex flex-col justify-between space-y-8 px-6 py-4 text-base">
        <div className="flex flex-col text-xl capitalize text-primary lg:text-2xl">
          {requirement.title}
          <span className="text-base text-gray-400 lg:text-lg">
            {requirement.description}
          </span>
        </div>
        <div className="lg:text-lg">
          {sortedComplianceItemList.map((item) =>
            Object.entries(item).map(([key, customField]) => {
              return (
                <div key={key} className="mb-1 flex flex-col items-start">
                  <div className="font-semibold">
                    {customField.text}
                    {customField.required ? (
                      <span className="pl-0.5">*</span>
                    ) : null}
                  </div>
                  <div className="text-base text-gray-400">
                    {showReadableRequirementRanges(customField)}
                  </div>
                </div>
              );
            }),
          )}
        </div>
        <BaseButtonSecondary onClick={closeDialog}>Close</BaseButtonSecondary>
      </div>
    </BaseModal>
  );
}
