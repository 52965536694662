//Libs
import { PencilIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useCallback, useMemo, useEffect } from "react";
import {
  useForm,
  Controller,
  UseFormHandleSubmit,
  UseFormReset,
  FieldErrors,
  SubmitHandler,
  Control,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

//Local
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseInputSelect from "../../components/BaseInputSelect";
import BaseInputText from "../../components/BaseInputText";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import PermissionsSwitches from "../../components/admin/PermissionsSwitches";
import {
  AddOrEditUserFormState,
  AddOrEditUserSchema,
} from "../../models/add-or-edit-user";
import Breadcrumbs from "../../components/Breadcrumbs";
import StyledMessage from "../../components/StyledMessage";
import { logger as devLogger } from "../../logging";
import { ADMIN_EDIT_USER_URL, ADMIN_USER_MANAGEMENT_URL } from "../../urls";
import * as strings from "../../strings";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import { phoneUtils } from "../../utils/phoneUtils";

export interface Props {
  //DATA
  user: ExistingSiteKeyUserDoc;
  permissions: ExistingSiteKeyUserPermissions;
  companiesList: ExistingSiteKeyCompany[];
  siteKeyLocationList: ExistingSiteKeyLocation[];
  saveButtonText: string;
  isComplianceEnabled: boolean | null;
  //FUNCTIONS
  onEditUserAndPermissions: (
    formValues: AddOrEditUserFormState,
  ) => Promise<void>;
  children: {
    userPhotoDialog: React.ReactNode;
    passphraseDialog: React.ReactNode;
    ChangeUserButton: React.ReactNode;
    ActionDropdownButton: React.ReactNode;
    displayErrorMessage: React.ReactNode;
    displaySuccessMessage: React.ReactNode;
  };
}

export default function EditUserPage(props: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const calculateCompanyName = useCallback(
    function (companyID: string): string {
      const companyDoc = props.companiesList.find((company) => {
        return company.id === companyID;
      });
      return companyDoc?.name || " ";
    },
    [props.companiesList],
  );

  const defaultUserFormValues: AddOrEditUserFormState = useMemo(() => {
    return {
      displayName: props.user.displayName,
      email: props.user.email,
      jobTitle: props.user.jobTitle,
      department: props.user.department ? props.user.department : "",
      phone: props.user.phone,
      companyName: props.permissions.companyID
        ? calculateCompanyName(props.permissions.companyID)
        : "",
      defaultLocationID: props.permissions.defaultLocationID,
      // PERMISSIONS
      approved: props.permissions.approved,
      inactive: props.permissions.inactive,

      getsNewTaskNotifications:
        props.permissions.permissions.getsNewTaskNotifications,
      canEditContractorDetails:
        props.permissions.permissions.canEditContractorDetails,
      canCreateTasks: props.permissions.permissions.canCreateTasks,
      canUpdateTasks: props.permissions.permissions.canUpdateTasks,
      canDeleteTasks: props.permissions.permissions.canDeleteTasks,
      canCreateCraftRecords:
        props.permissions.permissions.canCreateCraftRecords,
      canUpdateCraftRecords:
        props.permissions.permissions.canUpdateCraftRecords,
      canDeleteCraftRecords:
        props.permissions.permissions.canDeleteCraftRecords,
      complianceRequirements_create:
        props.permissions.permissions.complianceRequirements_create,
      complianceRequirements_read:
        props.permissions.permissions.complianceRequirements_read,
      complianceRequirements_delete:
        props.permissions.permissions.complianceRequirements_delete,
      complianceResponses_create:
        props.permissions.permissions.complianceResponses_create,
      complianceResponses_read:
        props.permissions.permissions.complianceResponses_read,
      complianceResponses_readAll:
        props.permissions.permissions.complianceResponses_readAll,
      complianceResponses_delete:
        props.permissions.permissions.complianceResponses_delete,
      complianceResponses_review:
        props.permissions.permissions.complianceResponses_review,
      tasks_changeDate: props.permissions.permissions.tasks_changeDate,
      isPlantPersonnel: props.permissions.permissions.isPlantPersonnel,
      isSiteAdmin: props.permissions.permissions.isSiteAdmin,

      newTaskCreated: props.permissions.managementSubscriptions.newTaskCreated,
      allTaskStatusChanged:
        props.permissions.managementSubscriptions.allTaskStatusChanged,
    };
  }, [props.permissions, props.user, calculateCompanyName]);

  // react-hook-form stuffs
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
  } = useForm<AddOrEditUserFormState>({
    defaultValues: defaultUserFormValues,
    resolver: zodResolver(AddOrEditUserSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultUserFormValues);
  }, [defaultUserFormValues, reset]);

  const onSubmit: SubmitHandler<AddOrEditUserFormState> = async (
    formValues: AddOrEditUserFormState,
  ) => {
    try {
      if (props.user.id == null) {
        devLogger.error(`User ID was not found. Value: ${props.user.id}`);
        return;
      }
      // For the save button
      setIsSubmitting(true);
      console.log("formValues", formValues.defaultLocationID);

      let parsed;
      if (formValues.phone !== "") {
        const { PhoneNumber, warning } = phoneUtils.parse(formValues.phone);
        if (warning === true) {
          parsed = formValues.phone;
          addToastMessage({
            id: createToastMessageID(),
            message: strings.PHONE_TOO_SHORT,
            dialog: false,
            type: "warning",
          });
        } else {
          parsed = PhoneNumber.number;
        }
      }

      const valuesToSave: AddOrEditUserFormState = {
        ...formValues,
        phone: parsed === null || parsed === undefined ? "" : parsed.toString(),
      };

      //Container will call DB
      await props.onEditUserAndPermissions(valuesToSave);
    } catch (e) {
      devLogger.error(e);
      addToastMessage({
        id: createToastMessageID(),
        message: "Error updating user. Please contact support.",
        dialog: false,
        type: "error",
      });
    } finally {
      // For the save button
      setIsSubmitting(false);
    }
  };

  const home = {
    name: "List of All Users",
    href: ADMIN_USER_MANAGEMENT_URL,
    current: false,
  };
  const pages = [
    {
      name: "Edit User",
      href: `${ADMIN_EDIT_USER_URL}/${props.user.id}`,
      current: true,
    },
  ];

  return (
    <div className="mx-auto w-full items-center space-y-2 px-2 md:px-14 lg:grid lg:max-w-screen-lg lg:grid-cols-2 lg:items-start lg:gap-x-28 lg:px-2">
      <Breadcrumbs home={home} pages={pages} />
      <TheForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        errors={errors}
        control={control}
        reset={reset}
        companyList={props.companiesList}
        siteKeyLocationList={props.siteKeyLocationList}
        isSubmitting={isSubmitting}
        isDirty={isDirty}
        setValue={setValue}
        saveButtonText={props.saveButtonText}
        isComplianceEnabled={props.isComplianceEnabled}
      >
        {{
          ChangeUserButton: props.children.ChangeUserButton,
          ActionDropdownButton: props.children.ActionDropdownButton,
          userPhotoDialog: props.children.userPhotoDialog,
          passphraseDialog: props.children.passphraseDialog,
          displayErrorMessage: props.children.displayErrorMessage,
          displaySuccessMessage: props.children.displaySuccessMessage,
        }}
      </TheForm>
    </div>
  );
}

// #region SECTION: The Form
interface FormProps {
  handleSubmit: UseFormHandleSubmit<AddOrEditUserFormState>;
  onSubmit: SubmitHandler<AddOrEditUserFormState>;
  errors: FieldErrors;
  control: Control<AddOrEditUserFormState, any>;
  reset: UseFormReset<AddOrEditUserFormState>;
  companyList: ExistingSiteKeyCompany[];
  siteKeyLocationList: ExistingSiteKeyLocation[];
  isSubmitting: boolean;
  isDirty: boolean;
  setValue: UseFormSetValue<AddOrEditUserFormState>;
  saveButtonText: string;
  isComplianceEnabled: boolean | null;
  children: {
    userPhotoDialog: React.ReactNode;
    passphraseDialog: React.ReactNode;
    ChangeUserButton: React.ReactNode;
    ActionDropdownButton: React.ReactNode;
    displayErrorMessage: React.ReactNode;
    displaySuccessMessage: React.ReactNode;
  };
}

// Extracted 'TheForm' because: https://stackoverflow.com/a/67002581
const TheForm = (props: FormProps) => {
  const watchApproved = useWatch({ control: props.control, name: "approved" });
  const watchInactive = useWatch({ control: props.control, name: "inactive" });
  const watchCompanyName = useWatch({
    control: props.control,
    name: "companyName",
  });

  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit(props.onSubmit)}
      className="lg:contents"
    >
      {/* ACTION BUTTONS */}
      <div className="col-span-2 mb-8 flex flex-col items-center justify-between gap-2 lg:my-8 lg:flex-row">
        <div className="flex items-center justify-center lg:flex-row lg:items-end lg:justify-between">
          {props.children.ChangeUserButton}
          {props.children.userPhotoDialog}
          {props.children.passphraseDialog}
        </div>
        <div className="flex flex-col space-y-4">
          {watchApproved !== true &&
          watchInactive !== true &&
          watchCompanyName === "" ? (
            <StyledMessage type="warning">
              {{ message: strings.APPROVE_NEW_USER_MUST_SELECT_COMPANY }}
            </StyledMessage>
          ) : null}
          {props.isDirty ? (
            <StyledMessage type="error">
              {{ message: strings.UNSAVED_CHANGES }}
            </StyledMessage>
          ) : null}
          {props.children.displayErrorMessage}
          {props.children.displaySuccessMessage}
        </div>
        <div className="flex flex-col items-center justify-center gap-2 lg:col-span-2 lg:mb-0 lg:items-end lg:justify-end">
          {props.children.ActionDropdownButton}
          <BaseButtonPrimary
            type="submit"
            disabled={props.isSubmitting}
            isBusy={props.isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className={`w-full ${
              props.saveButtonText === strings.buttons.SAVED
                ? "bg-greenPass"
                : ""
            }`}
          >
            <PencilIcon aria-label="save user" className="mr-4 h-6" />
            {props.saveButtonText}
          </BaseButtonPrimary>
        </div>
      </div>

      {/* div wrapping each input 'area' so the error message stays with the
         associated input element, without having to mess with margins */}
      <div className="mt-2 grid w-full grid-cols-1 gap-8">
        {/* DISPLAY NAME */}
        <div>
          <Controller
            name="displayName"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="displayName"
                text="Display Name"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {props.errors.displayName?.message &&
            typeof props.errors.displayName.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.displayName.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* EMAIL */}
        <div>
          <Controller
            name="email"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="email"
                text="Email"
                admin={true}
                required={true}
                {...field}
                disabled={true}
              />
            )}
          />
          {props.errors.email?.message &&
            typeof props.errors.email.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.email.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* JOB TITLE */}
        <div>
          <Controller
            name="jobTitle"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="jobTitle"
                text="Job Title"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {props.errors.jobTitle?.message &&
            typeof props.errors.jobTitle.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.jobTitle.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* DEPARTMENT */}
        <div>
          <Controller
            name="department"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="department"
                text="Department"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {props.errors.department?.message &&
            typeof props.errors.department.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.department.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* PHONE */}
        <div>
          <Controller
            name="phone"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="phone"
                text="Phone"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {props.errors.phone?.message &&
            typeof props.errors.phone.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.phone.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* COMPANY */}
        <div>
          <Controller
            name="companyName"
            control={props.control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="companyName"
                text="Company"
                admin={true}
                id="companyName"
                required={true}
                {...field}
              >
                {/* The empty string for value tricks validation into failing */}
                <option value="" disabled>
                  Select a company
                </option>
                {props.companyList.map((company) => (
                  <option key={company.id} value={company.name}>
                    {company.name}
                  </option>
                ))}
              </BaseInputSelect>
            )}
          />
          {props.errors.companyName?.message &&
            typeof props.errors.companyName.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.companyName.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* DEFAULT LOCATION */}
        <div>
          <Controller
            name="defaultLocationID"
            control={props.control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="defaultLocationID"
                text="Default Location"
                admin={true}
                id="defaultLocationID"
                {...field}
                value={
                  field.value === null
                    ? ""
                    : field.value /* because value may be null */
                }
                onChange={(event) => {
                  // Store empty values as null instead of undefined
                  field.onChange(
                    event.target.value === "" ? null : event.target.value,
                  );
                }}
              >
                <option value="">No default location</option>
                {props.siteKeyLocationList.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.title}
                  </option>
                ))}
              </BaseInputSelect>
            )}
          />
          {props.errors.defaultLocationID?.message &&
            typeof props.errors.defaultLocationID.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.defaultLocationID.message }}
                </StyledMessage>
              </div>
            )}
        </div>
      </div>

      {/* PERMISSIONS SWITCHES */}
      <PermissionsSwitches
        control={props.control}
        errors={props.errors}
        setValue={props.setValue}
        isComplianceEnabled={props.isComplianceEnabled}
      />
    </form>
  );
};
// #endregion
