import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import React from "react";

// Local
import LoadingSpinner from "./LoadingSpinner";

type Ref = HTMLButtonElement;

type Props = {
  text: string;
  isBusy?: boolean;
  busyText?: string;
  fontSize?: string;
} & React.ComponentPropsWithRef<"button">;

/** As similar as is possible to BaseButtonSecondary */
const ButtonThumbsUp = React.forwardRef<Ref, Props>(
  (
    {
      isBusy = false,
      fontSize = "text-sm",
      busyText = "Processing...",
      text,
      ...props
    },
    ref,
  ): JSX.Element => {
    const LoadingElement: React.ReactNode = (
      <>
        <LoadingSpinner />
        {busyText}
      </>
    );
    return (
      <button
        {...props}
        ref={ref}
        disabled={isBusy || props.disabled}
        className={`${props.className ?? ""} ${
          props.disabled && !isBusy
            ? "bg-white text-gray-300"
            : "bg-green-100 text-green-800 hover:bg-green-200"
        } ${fontSize} inline-flex h-10 items-center justify-center rounded-md border
        border-green-800 px-4 py-2 font-medium shadow-sm transition-colors
        focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2`}
      >
        {isBusy ? (
          LoadingElement
        ) : (
          <>
            <HandThumbUpIcon className="mr-3 h-5" />
            {text}
          </>
        )}
      </button>
    );
  },
);

export default ButtonThumbsUp;
