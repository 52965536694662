// Libs
import { Timestamp } from "firebase/firestore";
import { FieldValues } from "react-hook-form";

// Local
import { ExistingCustomField } from "../../models/custom-field";
import { Json } from "../../models/json-type";
import { IMultipleUid_AssignUser } from "./MultipleUidDialog";
import { logger } from "../../logging";
import { convertHoursMinutesToDecimal } from "../../utils";

// Tested ✅ - only lightly tested. could absolutely use moar tests
/**
 * convert custom field responses to the types expected by our updateTaskSpecificDetails
 * and updateCraftDetails functions, and the types expected by custom field responses
 */
export function convertResponsesToExpectedTypes(args: {
  customFields: ExistingCustomField[];
  values: FieldValues;
  userList: IMultipleUid_AssignUser[];
}): Record<string, Json> {
  const converted = Object.entries(args.values).reduce(
    (acc, [cfID, response]) => {
      let xresponse = response;

      const isTimestamp = xresponse instanceof Timestamp;
      const isDate = xresponse instanceof Date;

      if (!isTimestamp && !isDate) {
        args.customFields.forEach((cf) => {
          // For selection custom field types, we want to send the key instead of the value.
          if (cf.fieldType === "selection") {
            if (cf.id === cfID) {
              // Locate the selectionOption
              const selected = Object.entries(cf.selectionOptions).find(
                ([key, val]) => {
                  return key === xresponse || val === xresponse;
                },
              );

              if (!selected) {
                xresponse = null;
              } else {
                // send the key.
                xresponse = selected[0];
              }
            }
          }

          // For bool custom field types, we want to send true/false instead of Yes/No
          if (cf.fieldType === "bool") {
            if (cf.id === cfID) {
              xresponse = xresponse === "Yes";
            }
          }

          // For multiple-uid custom field types, need to send the uid list instead
          // of user display names
          if (cf.fieldType === "multiple-uid") {
            if (cf.id === cfID) {
              if (typeof xresponse === "string") {
                const selectedList = args.userList.filter(
                  (user) => user.isAssigned,
                );
                const uidList = selectedList.map((user) => user.uid);
                xresponse = uidList;
              }
            }
          }
        });

        // // If the new value is an empty string, convert it to null
        // if (typeof xresponse === "string" && xresponse.length === 0) {
        //   xresponse = null;
        // }
        // ^ seems we DONT wanna do that; got an error when creating a task

        acc = { ...acc, [cfID]: xresponse };
        return acc;
      } else {
        // Want to send an ISO datetime string instead of Timestamp or Date
        if (isTimestamp) {
          const iso = response.toDate().toISOString();
          acc = { ...acc, [cfID]: iso };
          return acc;
        } else {
          const iso = response.toISOString();
          acc = { ...acc, [cfID]: iso };
          return acc;
        }
      }
    },
    {} as Record<string, Json>,
  );

  // handle hours-minutes case
  let replacement: Record<string, number> = {};
  args.customFields.forEach((cf) => {
    if (cf.fieldType === "hours-minutes") {
      const hoursCfId = `${cf.id}_hoursValue`;
      const minutesCfId = `${cf.id}_minutesValue`;

      const hoursPair = Object.entries(converted).find(
        ([key]) => key === hoursCfId,
      );
      const minutesPair = Object.entries(converted).find(
        ([key]) => key === minutesCfId,
      );

      if (hoursPair === undefined && minutesPair === undefined) {
        logger.warn(
          "could not locate hours-minutes custom field within custom field responses",
        );
        return;
      }

      let hours: number;
      if (hoursPair === undefined) {
        hours = 0;
      } else {
        hours = typeof hoursPair[1] === "number" ? hoursPair[1] : 0;
      }

      let minutes: number;
      if (minutesPair === undefined) {
        minutes = 0;
      } else {
        minutes = typeof minutesPair[1] === "number" ? minutesPair[1] : 0;
      }

      const decimal = convertHoursMinutesToDecimal({
        hours,
        minutes,
      });

      replacement = { ...replacement, [cf.id]: decimal };

      delete converted[hoursCfId];
      delete converted[minutesCfId];
    }
  });

  return { ...converted, ...replacement };
}
