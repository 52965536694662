//Libs

//Local
import { convertFromMilitaryTime } from "./convertFromMilitaryTime";

export function convertToDisplayTime(date: Date): string {
  const hour = date.getHours(); // 0 - 23
  const [convertedHour, amPM] = convertFromMilitaryTime(hour);
  const minute = date.getMinutes(); // 0 - 59
  const formattedMinute = minute.toString().padStart(2, "0");

  return `${convertedHour}:${formattedMinute} ${amPM}`;
}
