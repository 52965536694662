// Libs
import { DocumentData } from "firebase/firestore";
import { SubmitHandler } from "react-hook-form";

// Local
import NavButtonBack from "../components/NavButtonBack";
import FormHeading from "../components/FormHeading";
import { CraftRecord } from "../models/craft-record";
import { CraftTypes } from "../models/craft-types";
import { timestampNow } from "../database";
import { ExistingSiteKeyLocation } from "../models/site-key-location";
import ChecklistBodyForm from "../components/ChecklistBodyForm";
import type { FormSchema } from "../components/ChecklistBodyForm";
import PageHeading from "../components/PageHeading";
import { logger as devLogger } from "../logging";

interface Props {
  saveButtonCallback: (
    data: Record<string, any>,
    craftDetailsData: Record<string, any>,
  ) => any;
  uid: string | null;
  locationsList: ExistingSiteKeyLocation[];
  onCancel: () => void;
}

/**
 * Page for entering information when creating a new Checklist Template.
 */
export default function NewTemplatePage(props: Props) {
  const initialFormValues: FormSchema = {
    title: "",
    description: "",
    locationID: "",
    requireApprovals: false,
  };

  /**
   * Handle creating a new Checklist Craft Record from the form data ready
   * to be written to Firestore.
   */
  function createNewChecklistData(args: {
    formValues: FormSchema;
    uid: string;
    siteKeyLocation: ExistingSiteKeyLocation;
  }): DocumentData {
    const checklist: CraftRecord = {
      assetID: null,
      authorizedCompanies: [],
      closedTasks: [],
      closedTaskTypes: [],
      craftDetails: {},
      craftType: CraftTypes.CHECKLISTS,
      createdBy: args.uid,
      description: args.formValues.description,
      lastModifiedBy: args.uid,
      latitude: args.siteKeyLocation.latitude,
      locationID: args.formValues.locationID,
      longitude: args.siteKeyLocation.longitude,
      numClosedTasks: 0,
      numOpenTasks: 0,
      open: true,
      openTasks: [],
      openTaskTypes: [],
      thumbnailURL: null,
      timestampLastModified: timestampNow(),
      timestampRecordClosed: null,
      timestampRecordCreated: timestampNow(),
      title: args.formValues.title,
    };
    return checklist;
  }

  /**
   * Create the craftDetails update object from form values with correct update paths.
   */
  function createCraftDetailsUpdates(args: { formValues: FormSchema }) {
    return {
      requireApprovals: args.formValues.requireApprovals,
    };
  }

  /**
   * On form submit handler function.
   */
  const saveTemplate: SubmitHandler<FormSchema> = async (values) => {
    // Can't proceed if we don't have
    if (props.uid == null) {
      devLogger.error(`User ID was not found. Value: ${props.uid}`);
      return;
    }
    const selectedSiteKeyLocation = props.locationsList.find((element) => {
      return element.id === values.locationID;
    });

    if (selectedSiteKeyLocation == null) {
      throw Error(
        `Unable to find site key location from ID: ${values.locationID}`,
      );
    }

    const newChecklistData = createNewChecklistData({
      formValues: values,
      siteKeyLocation: selectedSiteKeyLocation,
      uid: props.uid,
    });

    const craftDetailsUpdates = createCraftDetailsUpdates({
      formValues: values,
    });

    // Write to Firestore
    await props.saveButtonCallback(newChecklistData, craftDetailsUpdates);
  };

  return (
    <>
      <div className="mx-2">
        <div className="flex items-center">
          <NavButtonBack />
          <PageHeading title="New Template" detailsInfoArea={false} />
        </div>
        <FormHeading label="Create a new checklist template">
          This will be a checklist template that can be used to generate
          checklists automatically or on-demand.
        </FormHeading>
      </div>

      <ChecklistBodyForm
        locationsList={props.locationsList}
        initialFormValues={initialFormValues}
        onFormSubmit={saveTemplate}
        onCancel={props.onCancel}
      />
    </>
  );
}
