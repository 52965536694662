//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { CustomerTypes, customerTypes } from "./customer";
import { zFallback } from "../utils/zod-fallback";
import {
  AccountingSyncStiltDoc,
  AccountingSyncStiltDocSchema,
  AccountingSyncStiltDocSchemaWithFallbacks,
} from "./accounting-sync";

// #region SECTION: Types & Interfaces
export interface CustomerLocation {
  customerID: string;
  billToCustomerID: string | null;
  billToCustomerLocationID: string | null;
  fullAddress: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  streetNumber: string | null;
  street: string | null;
  city: string | null;
  zipCode: string | null;
  state: string | null;
  county: string | null;
  latitude: number | null;
  longitude: number | null;
  googlePlaceID: string | null;
  notes: string | null;
  type: CustomerTypes | null;
  tags: string[];
  customData: Record<string, any>;
  yearBuilt: number | null;
  estimatedValue: number | null;
  squareFootage: number | null;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
  deleted: boolean;
  totalTaxRate: number | null;
  // TODO: get more specific once we know more about what this structure will look like
  taxRates: Array<Record<string, string | number>>;
  customerContacts?: { [key: string]: any };
  accountingSync?: AccountingSyncStiltDoc;
  preferredTechnician?: string;
  locationName?: string;
}

export interface ExistingCustomerLocation extends CustomerLocation {
  id: string;
  refPath: string;
}

/***********************/
// FOR THE API
/***********************/
export type customerLocationWithoutTimestamps = Omit<
  CustomerLocation,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend
export interface CustomerLocation_CreateAPI
  extends customerLocationWithoutTimestamps {
  siteKey: string;
  /** the uuid will become the document id. */
  uuid: string;
}

// timestampLastModified added on the backend (timestampCreated won't change)
export type CustomerLocation_UpdateAPI = Omit<
  Partial<ExistingCustomerLocation>,
  "timestampCreated" | "timestampLastModified"
>;
// #endregion Types & Interfaces

// #region SECTION: Functions
/** Utilities for interacting with CustomerLocation objects  */
export const CustomerLocationManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingCustomerLocation object.
   */
  createFromFirestoreSnapshot: createCustomerLocationFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertCustomerLocationForFirestore,
  /** Validate a CustomerLocation doc. Use for writing to the database or reading from the user. */
  parse: validateCustomerLocation,
  /** Validate a CustomerLocation doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateWithFallbacks,
  /** Validate a new customer location. For the create endpoint. */
  parseCreate: validateCustomerLocation_Create,
  /** Validate an existing customer location. For the update endpoint. */
  parseUpdate: validateCustomerLocation_Update,
};

/**
 * Convert the Document Snapshot into a validated ExistingCustomerLocation object.
 */
function createCustomerLocationFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingCustomerLocation {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertCustomerLocationForFirestore(
  customerLocation: Partial<ExistingCustomerLocation>,
): DocumentData {
  const localItem = Object.assign({}, customerLocation);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = localItem;
  const result = dropUndefined(rest);
  return result;
}

/** Zod validation schemas */
function validateCustomerLocation(value: unknown): CustomerLocation {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = customerLocationSchema.parse(value);
  return result;
}

function validateWithFallbacks(value: unknown): CustomerLocation {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = customerLocationsSchemaWithFallbacks.parse(value);
  return result;
}

function validateCustomerLocation_Create(
  value: unknown,
): CustomerLocation_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return customerLocationSchema_CreateAPI.parse(value);
}

function validateCustomerLocation_Update(
  value: unknown,
): CustomerLocation_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return customerLocationSchema_UpdateAPI.parse(value);
}

// #endregion

// #region SECTION: Schemas
/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
const customerLocationSchema = z.object({
  customerID: z.string().min(1).max(200),
  billToCustomerID: z.string().min(1).max(200).nullable(),
  billToCustomerLocationID: z.string().min(1).max(200).nullable(),
  fullAddress: z.string().max(200).nullable(),
  addressLine1: z.string().max(200).nullable(),
  addressLine2: z.string().max(200).nullable(),
  streetNumber: z.string().max(200).nullable(),
  street: z.string().max(200).nullable(),
  city: z.string().max(200).nullable(),
  zipCode: z.string().max(200).nullable(),
  state: z.string().max(200).nullable(),
  county: z.string().max(200).nullable(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  googlePlaceID: z.string().max(200).nullable(),
  notes: z.string().max(1000).nullable(),
  type: z.enum(customerTypes),
  tags: z.array(z.string().min(1).max(200)),
  customData: z.record(z.any()),
  yearBuilt: z.number().int().nullable(),
  estimatedValue: z.number().int().nullable(),
  squareFootage: z.number().nullable(),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  deleted: z.boolean(),
  totalTaxRate: z.number().nullable(),
  taxRates: z.array(z.record(z.string().or(z.number()))),
  customerContacts: z.record(z.any()).optional(),
  accountingSync: AccountingSyncStiltDocSchema.optional(),
  preferredTechnician: z.string().optional(),
  locationName: z.string().optional(),
});

// Used when reading from the database
const customerLocationsSchemaWithFallbacks = z.object({
  // Can't proceed if we don't get a customerID.
  customerID: z.string().min(1).max(200),
  billToCustomerID: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'billToCustomerID'",
  ),
  billToCustomerLocationID: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'billToCustomerLocationID'",
  ),
  fullAddress: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'fullAddress'",
  ),
  addressLine1: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'addressLine1'",
  ),
  addressLine2: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'addressLine2'",
  ),
  streetNumber: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'streetNumber'",
  ),
  street: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'street'",
  ),
  city: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'city'",
  ),
  zipCode: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'zipCode'",
  ),
  state: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'state'",
  ),
  county: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'county'",
  ),
  latitude: zFallback(
    z.number().nullable(),
    null,
    "customer locations schema fallback used for 'latitude'",
  ),
  longitude: zFallback(
    z.number().nullable(),
    null,
    "customer locations schema fallback used for 'longitude'",
  ),
  googlePlaceID: zFallback(
    z.string().max(200).nullable(),
    null,
    "customer locations schema fallback used for 'googlePlaceID'",
  ),
  notes: zFallback(
    z.string().max(1000).nullable(),
    null,
    "customer locations schema fallback used for 'notes'",
  ),
  type: zFallback(
    z.enum(customerTypes),
    "residential",
    "customer locations schema fallback used for 'type'",
  ),
  customerContacts: zFallback(
    z.record(z.any()).optional(),
    {},
    "CustomerSchemaWithFallbacks: 'customerContacts'",
  ),
  tags: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "customer locations schema fallback used for 'tags'",
  ),
  customData: zFallback(
    z.record(z.any()),
    {},
    "customer locations schema fallback used for 'customData'",
  ),
  yearBuilt: zFallback(
    z.number().int().nullable(),
    null,
    "customer locations schema fallback used for 'yearBuilt'",
  ),
  estimatedValue: zFallback(
    z.number().int().nullable(),
    null,
    "customer locations schema fallback used for 'estimatedValue'",
  ),
  squareFootage: zFallback(
    z.number().nullable(),
    null,
    "customer locations schema fallback used for 'squareFootage'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "customer locations schema fallback used for 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "customer locations schema fallback used for 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "customer locations schema fallback used for 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "customer locations schema fallback used for 'lastModifiedBy'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "customer locations schema fallback used for 'deleted'",
  ),
  totalTaxRate: zFallback(
    z.number().nullable(),
    null,
    "customer locations schema fallback used for 'totalTaxRate'",
  ),
  taxRates: zFallback(
    z.array(z.record(z.string().or(z.number()))),
    [],
    "customer locations schema fallback used for 'taxRates'",
  ),
  accountingSync: AccountingSyncStiltDocSchemaWithFallbacks.optional(),
  preferredTechnician: zFallback(
    z.string().optional(),
    undefined,
    "customer locations schema fallback used for 'preferredTechnician'",
  ),
  locationName: z.string().optional(),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = customerLocationSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const customerLocationSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
  uuid: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint.
const customerLocationSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();

export const existingCustomerLocationSchema = customerLocationSchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
});

// #endregion

export function getReadableLocationAddress(location: ExistingCustomerLocation) {
  let address = "";
  if (location.addressLine1) {
    address += location.addressLine1;
  }
  if (location.addressLine2) {
    address += " " + location.addressLine2;
  }
  if (location.city) {
    address += " " + location.city;
  }
  if (location.state) {
    address += " " + location.state;
  }
  return address ?? location.fullAddress ?? "--";
}
