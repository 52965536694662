//Libs
import { Listbox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

//Local
import { ExistingCustomField } from "../../models/custom-field";
import { getReadableTaskType } from "../../models/task-types";

interface Props {
  fieldTitle: string;
  field: ExistingCustomField[];
  craftRecord: boolean;
  taskType?: string;
}

export default function CustomFieldsDropdownMenuButton({
  fieldTitle,
  field,
  craftRecord,
  taskType,
}: Props): JSX.Element {
  const title = fieldTitle.split(/(?=[A-Z])/).join(" ");

  return (
    <Fragment>
      <Listbox.Option disabled value="">
        <span className="text-sm font-semibold uppercase text-gray-300">
          {craftRecord ? (
            <span>{title}</span>
          ) : (
            <span className="ml-4">
              {" "}
              {getReadableTaskType(parseInt(taskType!))} Tasks
            </span>
          )}
        </span>
      </Listbox.Option>
      {Object.entries(field).map(([key, value], fieldIdx) => {
        return (
          <Listbox.Option
            key={fieldIdx}
            className={({ active, selected }) =>
              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                active || selected
                  ? "bg-primaryOpacity90 text-primary"
                  : "text-gray-700"
              }`
            }
            value={value.title}
          >
            {({ selected }) => (
              <>
                <a
                  href={
                    value.craftRecordOrTask === "craftRecord"
                      ? `#${value.id}`
                      : `#${value.taskType}-${value.id}`
                  }
                  type="button"
                  className={`group flex w-full items-center truncate rounded-md px-6 py-2 text-sm ${
                    selected
                      ? "font-bold text-primary hover:bg-gray-50"
                      : "text-gray-700"
                  }`}
                >
                  {value.title}
                </a>
                {selected ? (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                ) : null}
              </>
            )}
          </Listbox.Option>
        );
      })}
    </Fragment>
  );
}
