//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  title: string;
  /* FUNCTIONS */
  closeDialog: () => void;
  children: {
    TaskCard: React.ReactNode;
    OpenJobButton: React.ReactNode;
    EditTaskDialog: React.ReactNode;
    RescheduleTaskDialog: React.ReactNode;
    TaskStatusChangeDialog: React.ReactNode;
    EstimateListForTaskDialog: React.ReactNode;
  };
}

export default function TaskDetailsDialog(props: Props) {
  const taskDetailsHeader = (
    <div className="flex w-full items-start justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex text-xl font-semibold ">
        {strings.DETAILS} of {props.title}
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={taskDetailsHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="flex flex-col">
        <div className="self-end p-4">{props.children.OpenJobButton}</div>
        <div>{props.children.TaskCard}</div>
      </div>
      {props.children.EditTaskDialog}
      {props.children.RescheduleTaskDialog}
      {props.children.TaskStatusChangeDialog}
      {props.children.EstimateListForTaskDialog}
    </BaseModal>
  );
}
