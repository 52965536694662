//Libs
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DocumentData } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  StorageError,
  uploadBytesResumable,
} from "firebase/storage";
import { useEffect, useRef, useState } from "react";

//Local
import { DbRead, DbWrite } from "../../database";
import { logger as devLogger } from "../../logging";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import { useSiteKeyCompaniesStore } from "../../store/site-key-companies";
import EditUserPage from "./EditUserPage";
import { AddOrEditUserFormState } from "../../models/add-or-edit-user";
import { useAuthStore } from "../../store/firebase-auth";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { diffObjects } from "../../assets/js/object-diff";
import { flattenObj } from "../../assets/js/flatten";
import { validateEditSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import {
  SiteKeyUserDoc,
  SiteKeyUsersManager,
} from "../../models/site-key-users";
import { isWhiteLabel } from "../../white-label-check";
import UserPhotoDialog from "../../components/admin/UserPhotoDialog";
import { useSecondaryActionsButtons } from "./use-edit-user-secondary-action-buttons";
import StyledMessage from "../../components/StyledMessage";
import { whiteLabel } from "../../urls";
import * as strings from "../../strings";
import { isComplianceEnabled } from "../../assets/js/isComplianceEnabled";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import DisplayRegeneratedPassphraseDialog from "../../components/admin/DisplayRegeneratedPassphraseDialog";
import QRDialog from "../../components/admin/QRDialog";

// A reference to the storage service, used to create references in our storage
// bucket. (An instance of the Storage service.)
const storage = getStorage();

interface Props {
  siteKey: string;
}

type DisplayErrorMsgDataType = {
  text: string;
};

export default function EditUserContainer(props: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  type UrlParams = { id: string };
  const { id: userID } = useParams<UrlParams>();

  if (userID == null) {
    navigate("/404");
  }

  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const currentLoggedInUser = firebaseUser?.uid;

  const companyList: ExistingSiteKeyCompany[] = useSiteKeyCompaniesStore(
    (state) => state.siteKeyCompanies,
  );

  const siteKeyLocationList: ExistingSiteKeyLocation[] =
    useSiteKeyLocationsStore((state) => state.siteKeyLocationList);

  // useStates
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [actionsLoading, setActionsLoading] = useState<boolean>(false);
  const [saveButtonText, setSaveButtonText] = useState<string>(
    strings.buttons.SAVE,
  );
  const [displayErrorMessageData, setDisplayErrorMessageData] =
    useState<DisplayErrorMsgDataType | null>(null);
  const [displaySuccessMessageData, setDisplaySuccessMessageData] =
    useState<DisplayErrorMsgDataType | null>(null);
  const [passphraseResponse, setPassphraseResponse] = useState<string | null>(
    null,
  );
  const [isPassphraseError, setIsPassphraseError] = useState(false);
  const [isPassphraseDialogOpen, setPassphraseDialogOpen] = useState(false);
  const [isQRDialogOpen, setQRDialogOpen] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [isDownloadLinkError, setIsDownloadLinkError] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  useEffect(() => {
    if (typeof passphraseResponse === "string") {
      setPassphraseDialogOpen(true);
    }
  }, [passphraseResponse]);

  useEffect(() => {
    if (typeof downloadLink === "string") {
      setQRDialogOpen(true);
    }
  });

  useEffect(() => {
    if (isDownloadLinkError) {
      setDisplayErrorMessageData({
        text: "Unable to generate QR code link.",
      });
    }
  }, [isDownloadLinkError, setDisplayErrorMessageData]);

  const closePassphraseDialog = () => {
    setPassphraseDialogOpen(false);
  };

  const closeQRDialog = () => {
    setQRDialogOpen(false);
    setDownloadLink(null);
  };

  const passphraseDialog = (
    <DisplayRegeneratedPassphraseDialog
      response={passphraseResponse}
      isError={isPassphraseError}
      isDialogOpen={isPassphraseDialogOpen}
      closeDialog={closePassphraseDialog}
    />
  );

  const QrDialog = (
    <QRDialog
      isDialogOpen={isQRDialogOpen}
      closeDialog={closeQRDialog}
      downloadLink={downloadLink}
    />
  );

  function clearError() {
    setDisplayErrorMessageData(null);
  }

  function clearSuccessMsg() {
    setDisplaySuccessMessageData(null);
  }

  // For showing or hiding the compliance-related permissions.
  const complianceEnabled = useRef<boolean | null>(null);
  useEffect(() => {
    async function getComplianceEnabledStatus() {
      complianceEnabled.current = await isComplianceEnabled(props.siteKey);
    }

    getComplianceEnabledStatus();
  }, [props.siteKey]);

  const errorMessage =
    displayErrorMessageData != null ? (
      <StyledMessage type="error" dismissible onDismiss={clearError}>
        {{ message: displayErrorMessageData.text }}
      </StyledMessage>
    ) : null;

  const successMessage =
    displaySuccessMessageData != null ? (
      <StyledMessage type="success" dismissible onDismiss={clearSuccessMsg}>
        {{ message: displaySuccessMessageData.text }}
      </StyledMessage>
    ) : null;

  if (typeof userID !== "string") {
    throw new Error(`userID was not a string: ${userID}`);
  }

  //QUERIES TO GET USER AND PERMISSIONS DOC
  const userQueryKey = ["editUserContainer_user", props.siteKey, userID];
  const { data: queryUserData } = useQuery(userQueryKey, () =>
    DbRead.user.getUserDoc(props.siteKey, userID),
  );
  const permissionsQueryKey = [
    "editUserContainer_permissions",
    props.siteKey,
    userID,
  ];
  const { data: queryPermissionsData } = useQuery(permissionsQueryKey, () =>
    DbRead.user.getSiteKeyPermissions(props.siteKey, userID),
  );

  async function handleResetPasswordByEmail(email: string) {
    setActionsLoading(true);
    if (isWhiteLabel(whiteLabel)) {
      try {
        //Call DB
        await DbWrite.user.sendPasswordResetLink({
          email: email,
          version: whiteLabel,
        });
        devLogger.debug(`Password reset link sent to ${email}`);
        setDisplaySuccessMessageData({
          text: "Password reset email sent.",
        });
      } catch (e) {
        setDisplayErrorMessageData({
          text: "Unable to send password reset link.",
        });
        devLogger.error(e);
      }
    } else {
      throw new Error(`Unexpected white label`);
    }
    setActionsLoading(false);
  }

  async function handleGenerateNewPassword(uid: string, siteKey: string) {
    setActionsLoading(true);
    try {
      //Call DB
      const response = await DbWrite.user.resetUserPassword({
        uid: uid,
        siteKey: siteKey,
      });
      devLogger.debug(`Password has been reset for user ${uid}`);
      setActionsLoading(false);
      const { result, isError } = response;
      setPassphraseResponse(result);
      setIsPassphraseError(isError);
      return { result, isError };
    } catch (error: any) {
      setDisplayErrorMessageData({
        text: "Unable to reset password.",
      });
      devLogger.error(error);
      setPassphraseResponse(error);
      setIsPassphraseError(true);
      setActionsLoading(false);
      return {
        result: `Error resetting user password: ${error.message}`,
        isError: true,
      };
    }
  }

  async function handleSendDownloadLinks() {
    if (typeof userID !== "string") {
      throw new Error(`userID was not a string: ${userID}`);
    }

    setActionsLoading(true);
    if (queryUserData === undefined) return;
    if (isWhiteLabel(whiteLabel)) {
      try {
        //Call DB
        await DbWrite.user.sendAppDownloadLinks(
          props.siteKey,
          whiteLabel,
          queryUserData.email,
          userID,
        );
        devLogger.debug(
          `email sent to ${queryUserData.displayName} at ${queryUserData.email}`,
        );
        setDisplaySuccessMessageData({
          text: "Download links email sent.",
        });
      } catch (e) {
        setDisplayErrorMessageData({ text: "Unable to send download links." });
        devLogger.error(e);
      }
    } else {
      throw new Error("Unexpected white label");
    }
    setActionsLoading(false);
  }

  async function handleEditUserAndPermissions(
    formValues: AddOrEditUserFormState,
  ): Promise<void> {
    if (queryUserData === undefined || queryPermissionsData === undefined) {
      setDisplayErrorMessageData({ text: "Error fetching data." });
      return;
    }

    //separate the user values from the permissions values
    const {
      companyName,
      department,
      displayName,
      email,
      jobTitle,
      phone,
      approved,
      inactive,
      newTaskCreated,
      allTaskStatusChanged,
      defaultLocationID,
      ...permissions
    } = formValues;

    const companyID = companyList.find(
      (company) => company.name === companyName,
    )?.id;

    //create an object with the user values just divided
    const userValues: Omit<SiteKeyUserDoc, "userPhoto_URL"> = {
      companyName: companyName,
      department: department,
      displayName: displayName,
      email: email,
      jobTitle: jobTitle,
      phone: phone,
    };

    // If the admin clears out the optional `department` field, the value we
    // receive here will be an empty string. Replace it with null.
    if (userValues.department === "" || userValues.department === undefined) {
      userValues.department = null;
    }

    //create an object with the initial main values retrieved from DB
    const initialUserValues = {
      companyName: queryUserData.companyName,
      department: queryUserData.department,
      displayName: queryUserData.displayName,
      email: queryUserData.email,
      jobTitle: queryUserData.jobTitle,
      phone: queryUserData.phone,
    };

    //send the two object with user values, initial and current, in a gunction that compare them and return an object with only the different values
    const diffUserValues: DocumentData = diffObjects(
      initialUserValues,
      userValues,
    ).diff;

    const initialPrivateDoc = {
      approved: queryPermissionsData.approved,
      inactive: queryPermissionsData.inactive,
      companyID: queryPermissionsData.companyID,
      defaultLocationID: queryPermissionsData.defaultLocationID,
    };

    const privateDoc = {
      approved: approved,
      inactive: inactive,
      companyID: companyID,
      defaultLocationID: defaultLocationID,
    };

    const diffPrivateDoc: DocumentData = diffObjects(
      initialPrivateDoc,
      privateDoc,
    ).diff;

    const initialManagementSubscriptions = {
      newTaskCreated:
        queryPermissionsData.managementSubscriptions.newTaskCreated,
      allTaskStatusChanged:
        queryPermissionsData.managementSubscriptions.allTaskStatusChanged,
    };

    const managementSubscriptions = {
      newTaskCreated: newTaskCreated,
      allTaskStatusChanged: allTaskStatusChanged,
    };

    const diffManagementSubscriptions: DocumentData = diffObjects(
      initialManagementSubscriptions,
      managementSubscriptions,
    ).diff;

    //create an object with the initial values of the permissions doc
    const initialPermissions = {
      getsNewTaskNotifications:
        queryPermissionsData.permissions.getsNewTaskNotifications,
      canEditContractorDetails:
        queryPermissionsData.permissions.canEditContractorDetails,
      canCreateTasks: queryPermissionsData.permissions.canCreateTasks,
      canUpdateTasks: queryPermissionsData.permissions.canUpdateTasks,
      canDeleteTasks: queryPermissionsData.permissions.canDeleteTasks,
      canCreateCraftRecords:
        queryPermissionsData.permissions.canCreateCraftRecords,
      canUpdateCraftRecords:
        queryPermissionsData.permissions.canUpdateCraftRecords,
      canDeleteCraftRecords:
        queryPermissionsData.permissions.canDeleteCraftRecords,
      isPlantPersonnel: queryPermissionsData.permissions.isPlantPersonnel,
      isSiteAdmin: queryPermissionsData.permissions.isSiteAdmin,
    };

    //compare the initial permissions doc with the current ones and return an object with the values that are changed
    const diffPermissionsValues: DocumentData = diffObjects(
      initialPermissions,
      permissions,
    ).diff;

    if (
      Object.keys(diffUserValues).length === 0 &&
      Object.keys(diffPrivateDoc).length === 0 &&
      Object.keys(diffManagementSubscriptions).length === 0 &&
      Object.keys(diffPermissionsValues).length === 0
    ) {
      setDisplayErrorMessageData({ text: "No changes to save." });
      return devLogger.debug(`Object is empty`);
    } else {
      const nestedObjectPermissionsDoc = {
        ...diffPrivateDoc,
        managementSubscriptions: {
          ...diffManagementSubscriptions,
        },
        permissions: {
          ...diffPermissionsValues,
        },
      };

      const validateSiteKeyUserDoc =
        SiteKeyUsersManager.parseEdit(diffUserValues);
      const validateSiteKeyPermissionsDoc = validateEditSiteKeyUserPermissions(
        nestedObjectPermissionsDoc,
      );

      const flattenSiteKeyPermissionsDoc = flattenObj(
        validateSiteKeyPermissionsDoc,
      );

      await Promise.all([
        mutateUserDoc.mutateAsync(validateSiteKeyUserDoc, {
          onSuccess: async () => {
            await Promise.all([
              queryClient.invalidateQueries(["users", props.siteKey], {
                refetchInactive: true,
              }),
              queryClient.invalidateQueries(userQueryKey),
            ]);
          },
        }),
        mutatePermissionsDoc.mutateAsync(flattenSiteKeyPermissionsDoc, {
          onSuccess: async () => {
            await Promise.all([
              queryClient.invalidateQueries(
                ["editUserContainer_permissions", props.siteKey],
                {
                  refetchInactive: true,
                },
              ),
              queryClient.invalidateQueries(permissionsQueryKey),
            ]);
          },
        }),
      ]);
      if (mutateUserDoc.isError || mutatePermissionsDoc.isError) {
        setDisplayErrorMessageData({ text: "The update has failed." });
        devLogger.error(
          "An error occurred:",
          mutateUserDoc.error,
          mutatePermissionsDoc.error,
        );
      } else {
        setSaveButtonText(strings.buttons.SAVED);
        setTimeout(setSaveButtonText, 5000, strings.buttons.SAVE);
        devLogger.debug("user doc and permissions are updated");
      }
    }
  }

  const mutateUserDoc = useMutation(
    async (validateSiteKeyUserDoc: Record<string, any>) =>
      await DbWrite.user.updateSiteKeyUser(
        props.siteKey,
        validateSiteKeyUserDoc,
        userID,
      ),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(["users", props.siteKey], {
            refetchInactive: true,
          }),
          queryClient.invalidateQueries(userQueryKey),
        ]);
      },
    },
  );

  const mutatePermissionsDoc = useMutation(
    async (flattenSiteKeyPermissionsDoc: Record<string, boolean>) =>
      await DbWrite.user.updateSiteKeyUserPermissions(
        props.siteKey,
        flattenSiteKeyPermissionsDoc,
        userID,
      ),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(
            ["editUserContainer_permissions", props.siteKey],
            {
              refetchInactive: true,
            },
          ),
          queryClient.invalidateQueries(permissionsQueryKey),
        ]);
      },
    },
  );

  const mutateUserPhoto = useMutation(
    async (downloadUrl: string) =>
      await DbWrite.user.updateUserPhoto(downloadUrl, props.siteKey, userID),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(userQueryKey);
      },
    },
  );

  async function handleEditUserPhoto(
    file: File,
    changeFileInformation: (value: string) => void,
    changeIsUploading: (value: boolean) => void,
  ): Promise<void> {
    //use a random string as the file's name
    const newFileName = `${uuidv4()}.${file.type.split("/")[1]}`;

    //upload file to the path starting with 'siteKeys'
    const storageRef = ref(
      storage,
      `siteKeys/${props.siteKey}/siteKeyUsers/${userID}` + newFileName,
    );
    const uploadUserPhoto = uploadBytesResumable(storageRef, file);

    // listen for the state changes, errors and completion of the upload.
    const unsubscribe = uploadUserPhoto.on("state_changed", {
      //the 'next' callback could give progress reports, ie: '79% done'
      next: null,
      error: handleUploadError,
      complete: onUploadComplete,
    });

    //this only gets called if there is an error.
    function handleUploadError(error: StorageError) {
      switch (error.code) {
        case "storage/unauthorized":
          changeFileInformation(
            strings.UPLOAD_ERROR_USER_NOT_PERMITTED_TO_UPLOAD_PHOTO,
          );
          changeIsUploading(false);
          devLogger.error("Unauthorized user attempting to upload an image.");
          break;
        case "storage/quota-exceeded":
          changeFileInformation(strings.UPLOAD_ERROR_QUOTA_EXCEEDED);
          changeIsUploading(false);
          devLogger.error("Quota on Cloud Storage bucket has been exceeded.");
          break;
        case "storage/unknown":
          changeFileInformation(strings.GENERIC_ERROR_MESSAGE);
          changeIsUploading(false);
          devLogger.error(
            "Unknown error occurred, inspect error.serverResponse",
          );
          break;
        default:
          changeFileInformation(strings.UPLOAD_ERROR_UNABLE);
          changeIsUploading(false);
          devLogger.error("Unknown error occurred while uploading image.");
      }
      //if there has been an error during upload, unsubscribe from uploadUserPhoto.on();
      unsubscribe();
    }

    //upload completed successfully
    async function onUploadComplete() {
      try {
        const downloadURL = await getDownloadURL(uploadUserPhoto.snapshot.ref);
        mutateUserPhoto.mutateAsync(downloadURL, {
          onSuccess: () => {
            return queryClient.invalidateQueries(userQueryKey);
          },
        });

        changeFileInformation(strings.UPLOAD_SUCCESS_SERVER_PROCESSING);
        changeIsUploading(false);

        //unsubscribe from uploadUserPhoto.on(), after upload is complete.
        unsubscribe();
        setTimeout(setOpenUserDialog, 2000, false);
        setTimeout(changeFileInformation, 3000, strings.UPLOAD_NO_FILE_CHOSEN);
      } catch (err) {
        //set the text to show the user
        changeFileInformation(strings.UPLOAD_ERROR_UNABLE);
        changeIsUploading(false);
        devLogger.error("Couldn't get download URL -> ", err);
      }
    }
  }

  const { ChangeUserButton, ActionDropdownButton } = useSecondaryActionsButtons(
    {
      user: queryUserData,
      currentUserId: currentLoggedInUser ?? "",
      siteKey: props.siteKey,
      handleResetPasswordByEmail,
      handleGenerateNewPassword,
      handleSendDownloadLinks,
      setOpenUserDialog,
      dropdownIsOpen,
      setDropdownIsOpen,
      actionsLoading: actionsLoading,
      handleAppDownloadClick,
      downloadLink: downloadLink,
      isDownloadLinkError: isDownloadLinkError,
      QRDialog: QrDialog,
    },
  );

  const userPhotoDialog: JSX.Element = (
    <UserPhotoDialog
      closeDialog={() => setOpenUserDialog(false)}
      isDialogOpen={openUserDialog}
      onEditUserPhoto={handleEditUserPhoto}
    />
  );

  async function handleAppDownloadClick(platform: string) {
    setActionsLoading(true);
    try {
      const result = await DbWrite.user.sendAppDownloadLinkToDesktopUser({
        version: whiteLabel,
        platform,
      });

      setDownloadLink(result.downloadLink);
      setIsDownloadLinkError(result.isDownloadLinkError);
    } catch (error) {
      setIsDownloadLinkError(true);
    } finally {
      setActionsLoading(false);
    }
  }

  return firebaseUser && queryUserData && queryPermissionsData ? (
    <EditUserPage
      companiesList={companyList}
      user={queryUserData}
      permissions={queryPermissionsData}
      onEditUserAndPermissions={handleEditUserAndPermissions}
      saveButtonText={saveButtonText}
      isComplianceEnabled={complianceEnabled.current}
      siteKeyLocationList={siteKeyLocationList}
    >
      {{
        userPhotoDialog: userPhotoDialog,
        passphraseDialog: passphraseDialog,
        ChangeUserButton: ChangeUserButton,
        ActionDropdownButton: ActionDropdownButton,
        displayErrorMessage: errorMessage,
        displaySuccessMessage: successMessage,
      }}
    </EditUserPage>
  ) : (
    <div className="flex h-full flex-auto flex-col items-center justify-center">
      <LoadingClipboardAnimation />
    </div>
  );
}
