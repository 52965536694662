import { DateTime } from "luxon";

/**
 * Use this typeguard function to determine whether a timezone is valid or not.
 * @param tz Timezone string you want to check.
 * @returns Boolean.
 */
export function isValidTimezone(tz: unknown): boolean {
  if (typeof tz !== "string") return false;
  return DateTime.local().setZone(tz).isValid;
}
