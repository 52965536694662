// Libs

// Local
import ChecklistTasksTable from "../components/tables/ChecklistTasksTable";
import Breadcrumbs from "../components/Breadcrumbs";
import { ExistingTask } from "../models/task";

import DropdownSelectionTasksList from "../components/DropdownSelectionTasksList";
import { OTaskStatus } from "../models/task-status";
import { HOME_URL, TASKS_URL } from "../urls";

interface Props {
  listOfTasks: ExistingTask[];
  checklistTitle: string;
  onGoToTaskDetails: (taskID: string) => void;
  checklistID: string;
  onSelectionStatus: (taskStatus: number | null) => void;
}

export default function TasksForTemplatePage({
  listOfTasks,
  checklistTitle,
  onGoToTaskDetails,
  checklistID,
  onSelectionStatus,
  ...props
}: Props) {
  const taskStatus = [null, ...Object.values(OTaskStatus)];

  const home = { name: "Template", href: HOME_URL, current: false };
  const pages = [
    {
      name: `${checklistTitle}`,
      href: `${TASKS_URL}/${checklistID}`,
      current: true,
    },
  ];

  return (
    <div className="flex h-full flex-col">
      <Breadcrumbs home={home} pages={pages} />

      <div className="flex flex-col items-end justify-between space-y-2 sm:flex-row sm:space-y-0">
        <span className={"text-xl font-semibold text-gray-700"}>
          Table of Checklists Tasks for {checklistTitle}
        </span>
        <DropdownSelectionTasksList
          taskStatus={taskStatus}
          onSelectionStatus={onSelectionStatus}
        />
      </div>
      <ChecklistTasksTable
        taskList={listOfTasks}
        onGoToTaskDetails={onGoToTaskDetails}
      />
    </div>
  );
}
