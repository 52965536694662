//Libs
import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GetQuickFilterTextParams,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";

//Local
import * as strings from "../../strings";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { DocType, TableData } from "../../models/quickbook";
import { ExistingStiltInvoice } from "../../models/invoice";
import QuickbookDocTypeCustomFilter from "../../components/tables/QuickbookDocTypeCustomFilter";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { ExistingCustomer } from "../../models/customer";
import isEqual from "lodash/isEqual";
import SearchBox from "../../components/SearchBox";
import QuickbooksActionDropdown from "../../components/QuickbooksActionDropdown";
import AccountingSyncStatusCustomFilter from "../../components/tables/AccountingSyncStatusCustomFilter";
import { getReadableAccountingSyncStatus } from "../../models/accounting-sync";
import { hitAcctSync } from "../../database";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";

interface Props {
  tableData: TableData[];
  paymentsInvoices: ExistingStiltInvoice[];
  waitingOnCustomerList: ExistingCustomer[];
  waitingOnPbItemList: ExistingPriceBookItem[];
  openInvoiceDialog: (invoiceID: string) => void;
  goToCustomerPage: (customerID: string) => void;
  onEditPBItem: (priceBookItem: ExistingPriceBookItem) => void;
  children: {
    showDataWontSyncButton: React.ReactNode;
    connectionButtons: React.ReactNode;
  };
}

export default function QuickbooksPage({
  tableData,
  paymentsInvoices,
  waitingOnCustomerList,
  waitingOnPbItemList,
  openInvoiceDialog,
  goToCustomerPage,
  onEditPBItem,
  ...props
}: Props) {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [actionsLoading, setActionsLoading] = useState<boolean>(false);
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  function rowSelectionChanged() {
    setSelectedRows(getSelectedRowData());
  }

  function getSelectedRowData() {
    const selectedData = gridRef.current.api?.getSelectedRows();
    return selectedData;
  }

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value,
    );
  }, []);

  const actionDropdownButton = (
    <QuickbooksActionDropdown
      actionsLoading={actionsLoading}
      quickbooksActionSelected={async (
        actionType: "retrySync",
        refPaths: any[],
      ) => {
        try {
          if (actionsLoading) {
            return;
          }
          setActionsLoading(true);
          const siteKeyID = refPaths[0]["refPath"].split("/")[1];

          const refPathList: string[] = [];
          for (const row of refPaths) {
            refPathList.push(row["refPath"]);
          }
          // const ids: string[] = refPathList.map(
          //   (refPath) => refPath.split("/")[3],
          // );
          // console.log({ refPathList });
          // console.log({ ids });
          const result = await hitAcctSync({ siteKeyID, refPathList });
          if (result && result.length > 0) {
            // Display error stating how many records failed to have a sync job created for them
            addMessage({
              id: createToastMessageID(),
              message: `Failed to create sync job for ${result.length} of ${refPathList.length} records. Please try again later.`,
              type: "error",
              dialog: false,
            });
          } else {
            // Show success message
            addMessage({
              id: createToastMessageID(),
              message: `Successfully created sync job for all ${refPathList.length} records. Please wait a few minutes for the sync to complete.`,
              type: "success",
              dialog: false,
            });
          }
          setActionsLoading(false);
        } catch (error) {
          addMessage({
            id: createToastMessageID(),
            message: strings.ERROR_ATTEMPTING_ACCOUNTING_SYNC,
            type: "error",
            dialog: false,
          });
          setActionsLoading(false);
        }
      }}
      // invoiceActionSelected={props.invoiceActionSelected}
      selectedRows={selectedRows}
    />
  );

  return (
    <div>
      <div className="flex flex-wrap gap-2">
        <span>
          <h1 className="text-5xl font-semibold text-primary">
            {strings.QUICKBOOKS_SYNC_ERRORS}
          </h1>
          <h3>{`${tableData?.length ?? 0} Items Awaiting Sync`}</h3>
        </span>
        <span className="mb-6 ml-auto sm:mb-0 md:mt-1">
          {props.children.connectionButtons}
        </span>
      </div>
      <div className="flex flex-col-reverse flex-wrap gap-4 pt-4 xs:flex-row xs:items-center">
        {actionDropdownButton}
        <SearchBox onInput={onFilterTextBoxChanged} widthClasses="max-w-sm" />
        {props.children.showDataWontSyncButton}
      </div>
      <div className={"ag-theme-alpine flex h-[600px] flex-col"}>
        <QuickbooksListTable
          tableData={tableData}
          paymentsInvoices={paymentsInvoices}
          openInvoiceDialog={openInvoiceDialog}
          goToCustomerPage={goToCustomerPage}
          onEditPBItem={onEditPBItem}
          gridRef={gridRef}
          onGridReady={onGridReady}
          waitingOnCustomerList={waitingOnCustomerList}
          waitingOnPbItemList={waitingOnPbItemList}
          rowSelectionChanged={rowSelectionChanged}
        />
      </div>
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  // minWidth: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  // wrapText: true,
  autoHeight: true,
  headerClass: headerStyles,
  wrapHeaderText: true,
  autoHeaderHeight: true,
};

interface QuickbooksListTableProps {
  tableData: Props["tableData"];
  paymentsInvoices: Props["paymentsInvoices"];
  waitingOnCustomerList: Props["waitingOnCustomerList"];
  waitingOnPbItemList: Props["waitingOnPbItemList"];
  openInvoiceDialog: Props["openInvoiceDialog"];
  goToCustomerPage: Props["goToCustomerPage"];
  onEditPBItem: Props["onEditPBItem"];
  rowSelectionChanged: (event: GridReadyEvent) => void;
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
}

/* TABLE COMPONENT */
const QuickbooksListTable = memo(
  ({
    tableData,
    paymentsInvoices,
    waitingOnCustomerList,
    waitingOnPbItemList,
    openInvoiceDialog,
    goToCustomerPage,
    onEditPBItem,
    rowSelectionChanged,
    gridRef,
    onGridReady,
  }: QuickbooksListTableProps) => {
    const renderName = (params: ICellRendererParams): string => {
      const docType: DocType = params.data.docType;
      switch (docType) {
        case DocType.CUSTOMERS:
          return params.data.name;
        case DocType.PRICEBOOKITEMS:
          return params.data.title;
        case DocType.INVOICES:
          return `Invoice ${params.data.invoiceNumber}`;
        case DocType.PAYMENTS:
          const invoice = paymentsInvoices.find(
            (invoice) => invoice.id === params.data.invoiceID,
          );
          if (invoice) return `Payment for Invoice ${invoice.invoiceNumber}`;
          break;
        default:
          const _exhaustivenessCheck: never = docType;
          return _exhaustivenessCheck;
      }
      return "";
    };

    const renderType = (params: ICellRendererParams): JSX.Element => {
      return <div className="capitalize">{params.data.docType}</div>;
    };

    const renderSyncStatus = (params: ICellRendererParams): string =>
      getReadableAccountingSyncStatus(params.data.accountingSync.syncStatus);

    const renderMessage = (params: ICellRendererParams): JSX.Element => {
      return (
        <div className="text-wrap py-2 leading-snug">
          {params.data.accountingSync.statusMessage}
        </div>
      );
    };

    const renderWaitingOn = (params: ICellRendererParams): string => {
      if (!params.data.accountingSync.waitingOn) return "";

      const waitingOnSplitted = params.data.accountingSync.waitingOn.split("/");
      const collection: DocType = waitingOnSplitted[2];
      const id = waitingOnSplitted[3];

      switch (collection) {
        case DocType.CUSTOMERS:
          const customer = waitingOnCustomerList.find(
            (customer) => customer.id === id,
          );
          if (customer) {
            return `Customer - ${customer.name}`;
          } else {
            return "";
          }
        case DocType.PRICEBOOKITEMS:
          const pbItem = waitingOnPbItemList.find((pbItem) => pbItem.id === id);
          if (pbItem) {
            return `Item - ${pbItem.title}`;
          } else {
            return "";
          }
        case DocType.INVOICES:
          const invoice = paymentsInvoices.find((invoice) => invoice.id === id);
          if (invoice) {
            return `Invoice - ${invoice.invoiceNumber}`;
          } else {
            return "";
          }
        case DocType.PAYMENTS:
          return "";
        default:
          const _exhaustivenessCheck: never = collection;
          return _exhaustivenessCheck;
      }
    };

    function handleClickWaitingOnEvent(data: any) {
      if (!data.accountingSync || !data.accountingSync.waitingOn)
        return undefined;

      const waitingOnSplitted = data.accountingSync.waitingOn.split("/");
      const collection: DocType = waitingOnSplitted[2];
      const id = waitingOnSplitted[3];

      switch (collection) {
        case DocType.CUSTOMERS:
          return goToCustomerPage(id);
        case DocType.PRICEBOOKITEMS:
          const pbItem = waitingOnPbItemList.find((pbItem) => pbItem.id === id);
          if (pbItem) {
            return onEditPBItem(pbItem);
          } else {
            return undefined;
          }
        case DocType.INVOICES:
          return openInvoiceDialog(id);
        case DocType.PAYMENTS:
          return undefined;
        default:
          const _exhaustivenessCheck: never = collection;
          return _exhaustivenessCheck;
      }
    }

    const renderTimestampLastSynced = (params: ICellRendererParams): string => {
      if (params.data.accountingSync.timestampLastSynced) {
        return convertToReadableTimestamp(
          params.data.accountingSync.timestampLastSynced,
        );
      }
      return "";
    };

    const renderTimestampLastSyncAttempt = (
      params: ICellRendererParams,
    ): string => {
      if (params.data.accountingSync.timestampLastSyncAttempt) {
        return convertToReadableTimestamp(
          params.data.accountingSync.timestampLastSyncAttempt,
        );
      }
      return "";
    };

    const renderTimestampNextSyncAttempt = (
      params: ICellRendererParams,
    ): string => {
      if (params.data.accountingSync.timestampNextSyncAttempt) {
        return convertToReadableTimestamp(
          params.data.accountingSync.timestampNextSyncAttempt,
        );
      }
      return "";
    };

    function handleClickEvent(data: any) {
      const docType: DocType = data.docType;
      switch (docType) {
        case DocType.CUSTOMERS:
          return goToCustomerPage(data.id);
        case DocType.PRICEBOOKITEMS:
          return onEditPBItem(data);
        case DocType.INVOICES:
          return openInvoiceDialog(data.id);
        case DocType.PAYMENTS:
          return openInvoiceDialog(data.invoiceID);
        default:
          const _exhaustivenessCheck: never = docType;
          return _exhaustivenessCheck;
      }
    }

    const columnDefs: ColDef[] = [
      {
        sortable: false,
        headerName: "Name",
        cellRenderer: renderName,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          let name;
          const docType: DocType = params.data.docType;
          switch (docType) {
            case DocType.CUSTOMERS:
              name = params.data.name;
              break;
            case DocType.PRICEBOOKITEMS:
              name = params.data.title;
              break;
            case DocType.INVOICES:
              name = params.data.invoiceNumber;
              break;
            case DocType.PAYMENTS:
              const invoice = paymentsInvoices.find(
                (invoice) => invoice.id === params.data.invoiceID,
              );
              if (invoice) {
                name = invoice.invoiceNumber;
              }
              break;
            default:
              const _exhaustivenessCheck: never = docType;
              return _exhaustivenessCheck;
          }

          return name;
        },
      },
      {
        field: "docType",
        headerName: "Type",
        width: 100,
        cellRenderer: renderType,
        filter: QuickbookDocTypeCustomFilter,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (ev) => handleClickEvent(ev.data),
      },
      // {
      //   field: "id",
      //   headerName: "Doc ID",
      //   width: 100,
      //   // cellRenderer: renderType,
      //   // filter: QuickbookDocTypeCustomFilter,
      //   // tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      //   // onCellClicked: (ev) => handleClickEvent(ev.data),
      // },
      {
        field: "accountingSync.syncStatus",
        headerName: "Sync Status",
        filter: AccountingSyncStatusCustomFilter,
        cellRenderer: renderSyncStatus,
        onCellClicked: (ev) => handleClickEvent(ev.data),
      },
      {
        field: "accountingSync.statusMessage",
        headerName: "Message",
        cellRenderer: renderMessage,
        autoHeight: true,
        minWidth: 200,
        flex: 2,
        onCellClicked: (ev) => handleClickEvent(ev.data),
      },
      {
        field: "accountingSync.timestampLastSynced",
        headerName: "Last Synced",
        cellRenderer: renderTimestampLastSynced,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          getTimestampQuickFilterString(
            params.data?.accountingSync?.timestampLastSynced,
          ),
      },
      {
        field: "accountingSync.timestampLastSyncAttempt",
        headerName: "Last Sync Attempt",
        cellRenderer: renderTimestampLastSyncAttempt,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          getTimestampQuickFilterString(
            params.data?.accountingSync?.timestampLastSyncAttempt,
          ),
      },
      {
        field: "accountingSync.timestampNextSyncAttempt",
        headerName: "Next Sync Attempt",
        cellRenderer: renderTimestampNextSyncAttempt,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          getTimestampQuickFilterString(
            params.data?.accountingSync?.timestampNextSyncAttempt,
          ),
      },
      {
        field: "accountingSync.waitingOn",
        headerName: "Waiting On",
        cellRenderer: renderWaitingOn,
        onCellClicked: (ev) => handleClickWaitingOnEvent(ev.data),
      },
    ];

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="mt-5 shadow"
        rowData={tableData}
        animateRows={true}
        rowSelection="multiple"
        enableCellTextSelection={true}
        suppressRowClickSelection={true}
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        onSelectionChanged={(event) => {
          rowSelectionChanged(event);
        }}
      />
    );
  },
  (previous, next) => {
    const isTableDataTheSame = isEqual(previous.tableData, next.tableData);
    const isPaymentsInvoicesTheSame = isEqual(
      previous.paymentsInvoices,
      next.paymentsInvoices,
    );
    const isCustomerListTheSame = isEqual(
      previous.waitingOnCustomerList,
      next.waitingOnCustomerList,
    );
    const isPBItemListTheSame = isEqual(
      previous.waitingOnPbItemList,
      next.waitingOnPbItemList,
    );

    return (
      isTableDataTheSame &&
      isPaymentsInvoicesTheSame &&
      isCustomerListTheSame &&
      isPBItemListTheSame
    );
  },
);

function getTimestampQuickFilterString(timestamp: unknown): string {
  if (timestamp != null) {
    return convertToReadableTimestamp(timestamp);
  } else {
    return "";
  }
}
