// Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { z } from "zod";

// Local
import {
  adminPropDefaultsForCustomField,
  CustomFieldManager,
  NumberCustomField,
} from "../../../models/custom-field";
import {
  NumberSchema_RHF,
  FormComponentInterface,
  isDefinedOnTaskStatus,
  TaskTypeField,
  TitleField,
  NumberDefaultValueField,
  MinField,
  MaxField,
  EditableAndRequiredFields,
  OnTaskStatusField,
  ActionButtons,
  HideOnCRCreationField,
} from "./AddCustomFieldDialog";
import { ErrorMessage } from "../../ErrorMessage";
import { logger as devLogger } from "../../../logging";
import { OTaskTypes } from "../../../models/task-types";

const numberDefaultValues: Partial<NumberInterface> = {
  defaultValue: null,
  min: null,
  max: null,

  title: "",
  editable: false,
  required: false,
  hideOnCraftRecordCreation: false,
};
type NumberInterface = z.infer<typeof NumberSchema_RHF>;

export const NumberForm: React.FunctionComponent<FormComponentInterface> = (
  props,
) => {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<NumberInterface>({
    defaultValues: numberDefaultValues,
    resolver: zodResolver(NumberSchema_RHF),
    mode: "onChange",
  });

  useEffect(() => {
    reset(numberDefaultValues);
  }, [reset]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const watchTaskType = useWatch({ control: control, name: "taskType" });

  const onSave: SubmitHandler<NumberInterface> = async (formValues) => {
    // Save button indicator
    setIsSubmitting(true);

    if (props.workType === undefined) throw Error("workType is undefined");

    // Drop undefined values from the onTaskStatus list.
    // onTaskStatus itself will be undefined if this is a workType.
    const onTaskStatus = formValues.onTaskStatus?.filter(isDefinedOnTaskStatus);

    // Compose the output structure.
    let fullOutput: NumberCustomField;

    const partialOutput: Omit<
      NumberCustomField,
      "taskType" | "craftRecordOrTask"
    > = {
      ...adminPropDefaultsForCustomField(),
      fieldType: "number",
      defaultValue: formValues.defaultValue,
      title: formValues.title,
      editable: formValues.editable,
      required: formValues.required,
      min: formValues.min,
      max: formValues.max,
      craftType: props.workType,
    };

    // If taskType is 0, they selected "None". This means it's a craftRecord, and
    // we don't want to save the taskType to the database.
    if (formValues.taskType === 0) {
      fullOutput = {
        ...partialOutput,
        craftRecordOrTask: "craftRecord",
        hideOnCraftRecordCreation: formValues.hideOnCraftRecordCreation,
      };
    } else {
      fullOutput = {
        ...partialOutput,
        craftRecordOrTask: "task",
        taskType: formValues.taskType,
        onTaskStatus: onTaskStatus,
      };
    }

    // Validate CustomFieldDocData
    const validatedOutput = CustomFieldManager.parse(fullOutput);

    try {
      await props.handleSave(validatedOutput);

      setIsSubmitting(false);

      // Close dialog if successful.
      props.closeDialog();
    } catch (e) {
      setIsSubmitting(false);
      setDisplayError(true);
      devLogger.error(e);
    }
  };

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <form
      className="contents"
      autoComplete="off"
      onSubmit={handleSubmit(onSave)}
    >
      <hr className="mt-4 w-full border border-dashed border-gray-300 sm:col-span-2 lg:col-span-3" />
      <TaskTypeField
        control={control}
        errors={errors}
        workType={props.workType}
      />

      <TitleField control={control} errors={errors} />

      {/* Specific to fieldType number */}
      <NumberDefaultValueField control={control} errors={errors} />
      <MinField control={control} errors={errors} isStringBased={false} />
      <MaxField control={control} errors={errors} isStringBased={false} />

      <EditableAndRequiredFields control={control} />

      {watchTaskType === 0 && <HideOnCRCreationField control={control} />}

      {watchTaskType != null &&
        watchTaskType !== 0 &&
        Object.values(OTaskTypes).includes(watchTaskType) && (
          <OnTaskStatusField
            control={control}
            taskStatusList={props.taskStatusList}
          />
        )}

      <span className="-my-5 h-10 justify-self-center sm:col-span-2 lg:col-span-3">
        {displayError ? errorMessage : null}
      </span>

      <ActionButtons submitting={isSubmitting} onCancel={props.closeDialog} />
    </form>
  );
};
