//Libs
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local
import {
  ComplianceCompany,
  ComplianceResponseStatuses,
} from "../../models/compliance-types-and-statuses";
import {
  getAnswerStatusIcon,
  getResponseStatusIcon,
} from "../../assets/js/getStatusIconForCompliance";
import * as strings from "../../strings";
import { StyledTooltip } from "../StyledTooltip";
import { ExistingComplianceResponse } from "../../models/compliance-response";
import { ExistingComplianceRequirement } from "../../models/compliance-requirement";
import ButtonColored from "../ButtonColored";
import { useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import AlertError from "../AlertError";
import { ExistingAttachment } from "../../models/attachment";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { getSortedComplianceItemList } from "../../assets/js/getSortedComplianceItemList";
import LoadingClipboardAnimation from "../LoadingClipBoardAnimation";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";

interface Props {
  company: ComplianceCompany | null;
  showDeleteResponseButton: boolean;
  showReviewResponseButton: boolean;
  isApprovingID: string | null;
  isRejectingID: string | null;
  attachmentList: ExistingAttachment[];
  onReviewResponse: (
    response: ExistingComplianceResponse,
    status: ComplianceResponseStatuses,
  ) => void;
  getUserDisplayName: (uid: string) => string;
  onOpenResponseDialog: (
    requirement: ExistingComplianceRequirement,
    siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"],
  ) => void;
  onDeleteResponse: (id: ExistingComplianceResponse["id"]) => Promise<void>;
  onResubmitResponse: (
    requirement: ExistingComplianceRequirement,
    siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"],
    rejectedResponseID: ExistingComplianceResponse["id"],
  ) => void;
  onOpenRequirementRanges: (
    requirement: ExistingComplianceRequirement,
    siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"],
  ) => void;
  children: {
    ReviewCommentDialog: React.ReactNode;
  };
}

export default function ComplianceResponsesAccordionList({
  company,
  onOpenResponseDialog,
  showDeleteResponseButton,
  showReviewResponseButton,
  getUserDisplayName,
  isApprovingID,
  isRejectingID,
  attachmentList,
  onReviewResponse,
  onDeleteResponse,
  onResubmitResponse,
  onOpenRequirementRanges,
  children,
}: Props) {
  const [accordionExpanded, setAccordionExpanded] = useState<string | null>(
    null,
  );

  function toggleAccordion(requirementID: string) {
    if (accordionExpanded === null || accordionExpanded !== requirementID) {
      setAccordionExpanded(requirementID);
    } else setAccordionExpanded(null);
  }

  if (company == null) return <LoadingClipboardAnimation />;

  const sortedRequirements = company.requirements.sort((a, b) =>
    a.title.localeCompare(b.title),
  );

  return (
    <div className="space-y-4 rounded-md">
      {sortedRequirements.map((requirement) => {
        // find the response associated to the current requirement by requirement id
        const currentResponse = company.responses.find(
          (response) => response.requirementID === requirement.id,
        );

        const statusIcon = currentResponse
          ? getResponseStatusIcon(currentResponse.status)
          : `${strings.NO_STATUS}`;

        const attachment = attachmentList.find(
          (attachment) =>
            attachment.complianceResponseID === currentResponse?.id,
        );

        return (
          <div key={requirement.id}>
            <Accordion expanded={accordionExpanded === requirement.id}>
              <AccordionSummary>
                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-col text-xl capitalize text-primary lg:text-2xl">
                    <div className="flex gap-4">
                      {requirement.title}
                      <button
                        onClick={() =>
                          onOpenRequirementRanges(
                            requirement,
                            company.siteKeyCompanyID,
                          )
                        }
                      >
                        <StyledTooltip title={strings.REQUIREMENT_DETAILS}>
                          <InformationCircleIcon className="h-5 w-5" />
                        </StyledTooltip>
                      </button>
                    </div>
                    <span className="text-base text-gray-400 lg:text-lg">
                      {requirement.description}
                    </span>
                  </div>
                  <button
                    className="flex gap-2"
                    onClick={() => toggleAccordion(requirement.id)}
                  >
                    {currentResponse != null ? (
                      <span className="ml-2 h-7 w-7 flex-shrink-0">
                        {statusIcon}
                      </span>
                    ) : (
                      <span className="ml-2 text-right">
                        {strings.NO_STATUS}
                      </span>
                    )}
                    <ExpandMoreIcon
                      sx={{
                        transform: `rotate(${
                          accordionExpanded ? "180deg" : "0deg"
                        })`,
                      }}
                    />
                  </button>
                </div>
              </AccordionSummary>
              {/* if currentResponse is not null, map through the responses.customFields and get the customFieldsAnswer status */}
              {currentResponse != null ? (
                <HasResponseAccordion
                  getUserDisplayName={getUserDisplayName}
                  currentResponse={currentResponse}
                  onReviewResponse={onReviewResponse}
                  showDeleteResponseButton={showDeleteResponseButton}
                  showReviewResponseButton={showReviewResponseButton}
                  onDeleteResponse={onDeleteResponse}
                  isApprovingID={isApprovingID}
                  isRejectingID={isRejectingID}
                  attachment={attachment}
                  requirement={requirement}
                  siteKeyCompanyID={company.siteKeyCompanyID}
                  onOpenResponseDialog={onOpenResponseDialog}
                  onResubmitResponse={onResubmitResponse}
                />
              ) : (
                <NoResponseAccordion
                  requirement={requirement}
                  siteKeyCompanyID={company.siteKeyCompanyID}
                  onOpenResponseDialog={onOpenResponseDialog}
                />
              )}
            </Accordion>
          </div>
        );
      })}
      {children.ReviewCommentDialog}
    </div>
  );
}

type HasResponseProps = {
  currentResponse: ExistingComplianceResponse;
  showDeleteResponseButton: boolean;
  showReviewResponseButton: boolean;
  isApprovingID: string | null;
  isRejectingID: string | null;
  attachment: ExistingAttachment | undefined;
  onReviewResponse: (
    response: ExistingComplianceResponse,
    status: ComplianceResponseStatuses,
  ) => void;
  onDeleteResponse: (id: ExistingComplianceResponse["id"]) => Promise<void>;
  getUserDisplayName: (uid: string) => string;
  onResubmitResponse: (
    requirement: ExistingComplianceRequirement,
    siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"],
    rejectedResponseID: ExistingComplianceResponse["id"],
  ) => void;
} & NoResponseProps;

function HasResponseAccordion(props: HasResponseProps): JSX.Element {
  const addToastError = useToastMessageStore((state) => state.addToastMessage);

  const [isDeleting, setIsDeleting] = useState(false);
  const displayCreatedBy = props.getUserDisplayName(
    props.currentResponse.createdBy,
  );
  const displayReviewedBy = props.currentResponse.reviewedBy
    ? props.getUserDisplayName(props.currentResponse.reviewedBy)
    : null;

  const rejectedComment =
    props.currentResponse.status === "rejected" &&
    props.currentResponse.reviewComments != null ? (
      <div className="mt-4">
        <AlertError
          title={strings.REJECT_COMMENT}
          description={props.currentResponse.reviewComments}
        />
      </div>
    ) : null;

  const attachmentIsPDF = checkAttachmentIsPDF(props.attachment);

  const sortedComplianceItemList = getSortedComplianceItemList(
    props.currentResponse.customFields,
  );

  return (
    <AccordionDetails className="grid gap-4 text-gray-700 md:grid-cols-3 lg:gap-14">
      {/* Attachment */}
      {!props.attachment ? (
        <div className="md:col-span-1">
          {<p>{strings.SERVER_PROCESSING_ATTACHMENT}</p>}
        </div>
      ) : null}
      {props.attachment && !attachmentIsPDF ? (
        <div className="md:col-span-1">
          <img
            alt="response attachment"
            src={props.attachment.url}
            className="rounded"
          />
          <a href={props.attachment.url} target="_blank" rel="noreferrer">
            <BaseButtonPrimary
              type="button"
              className="mt-2 w-48 md:w-auto lg:w-48"
            >
              {strings.VIEW_FULL_SIZE}
            </BaseButtonPrimary>
          </a>
        </div>
      ) : null}
      {props.attachment && attachmentIsPDF ? (
        <div className="md:col-span-1">
          <embed
            src={props.attachment.url}
            type="application/pdf"
            className="h-[12.9rem] w-40 md:h-[10.3rem] md:w-32 lg:h-[12.9rem] lg:w-40"
            aria-label="response PDF"
          />
          <a href={props.attachment.url} target="_blank" rel="noreferrer">
            <BaseButtonPrimary
              type="button"
              className="mt-2 w-48 md:w-auto lg:w-48"
            >
              {strings.VIEW_PDF}
            </BaseButtonPrimary>
          </a>
        </div>
      ) : null}
      {/* Custom Fields */}
      <div className="md:col-span-2 lg:text-lg">
        {sortedComplianceItemList.map((item) =>
          Object.entries(item).map(([customFieldKey, value]) => {
            return (
              <div key={customFieldKey} className="mb-2">
                <div className="flex items-start font-semibold">
                  <span className="mr-2 mt-1 h-5 w-5 flex-shrink-0">
                    {getAnswerStatusIcon(
                      props.currentResponse.customFields[customFieldKey],
                      props.currentResponse.customFieldsAnswers[customFieldKey],
                    )}
                  </span>
                  {value.text}
                </div>
                <span className="mb-2 ml-8 block text-gray-600 lg:text-base">
                  {props.currentResponse.customFieldsAnswers[customFieldKey]}
                </span>
              </div>
            );
          }),
        )}
        <div className="mt-6 flex items-center justify-between">
          <div>
            <div className="text-sm">
              Submitted by {displayCreatedBy} on{" "}
              {props.currentResponse.timestampCreated.toLocaleString()}
            </div>
            {props.currentResponse.reviewedBy ? (
              <div className="mt-2 text-sm md:mt-0.5">
                Reviewed by {displayReviewedBy} on{" "}
                {props.currentResponse.timestampReviewed?.toLocaleString()}
              </div>
            ) : null}
          </div>

          {props.showDeleteResponseButton &&
          props.currentResponse.status !== "rejected" ? (
            <button
              className={`flex-shrink-0 justify-self-end rounded-full ${
                isDeleting
                  ? "cursor-auto hover:bg-transparent"
                  : "hover:bg-primaryOpacity90"
              }`}
              disabled={isDeleting}
              onClick={async () => {
                setIsDeleting(true);
                try {
                  await props.onDeleteResponse(props.currentResponse.id);
                } catch (e) {
                  logger.error(
                    "error deleting compliance response",
                    `(id: ${props.currentResponse.id})`,
                    e,
                  );
                  addToastError({
                    id: createToastMessageID(),
                    message: strings.ERR_DELETE_COMPLIANCE_RESPONSE,
                    dialog: false,
                    type: "error",
                  });
                  setIsDeleting(false);
                }
              }}
            >
              {isDeleting ? (
                <span className="block w-10 p-2">
                  <LoadingSpinner marginClass="mx-2" />
                </span>
              ) : (
                <StyledTooltip title={strings.DELETE_RESPONSE}>
                  <TrashIcon
                    aria-label="delete response"
                    className="w-10 fill-current p-2 text-gray-700"
                  />
                </StyledTooltip>
              )}
            </button>
          ) : null}

          {props.currentResponse.status === "rejected" ? (
            <button
              className="self-end sm:ml-2 sm:self-start"
              onClick={() =>
                props.onResubmitResponse(
                  props.requirement,
                  props.siteKeyCompanyID,
                  props.currentResponse.id,
                )
              }
            >
              <StyledTooltip title="Add response">
                <AddBoxIcon
                  aria-label={`add response for "${props.requirement.title}" requirement`}
                  sx={{ fontSize: 45 }}
                />
              </StyledTooltip>
            </button>
          ) : null}
        </div>
        {/* Action Buttons */}
        {props.showReviewResponseButton &&
        props.currentResponse.status !== "approved" &&
        props.currentResponse.status !== "rejected" ? (
          <div className="mt-4 flex gap-4">
            <ButtonColored
              className="w-full"
              disabled={props.isApprovingID === props.currentResponse.id}
              isBusy={props.isApprovingID === props.currentResponse.id}
              busyText={strings.buttons.BUSY_APPROVING}
              kind="info"
              onClick={() =>
                props.onReviewResponse(props.currentResponse, "approved")
              }
            >
              {strings.buttons.APPROVE}
            </ButtonColored>
            <ButtonColored
              className="w-full"
              disabled={props.isRejectingID === props.currentResponse.id}
              isBusy={props.isRejectingID === props.currentResponse.id}
              busyText={strings.buttons.BUSY_REJECTING}
              kind="danger"
              onClick={() =>
                props.onReviewResponse(props.currentResponse, "rejected")
              }
            >
              {strings.buttons.REJECT}
            </ButtonColored>
          </div>
        ) : null}
        {/* RejectedComment */}
        {rejectedComment}
      </div>
    </AccordionDetails>
  );
}

type NoResponseProps = {
  requirement: ExistingComplianceRequirement;
  siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"];
  onOpenResponseDialog: (
    requirement: ExistingComplianceRequirement,
    siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"],
  ) => void;
};
function NoResponseAccordion(props: NoResponseProps): JSX.Element {
  const sortedComplianceItemList = getSortedComplianceItemList(
    props.requirement.customFields,
  );
  return (
    <AccordionDetails className="flex flex-col text-gray-700 sm:flex-row-reverse sm:justify-between">
      <button
        className="self-end sm:ml-2 sm:self-start"
        onClick={() =>
          props.onOpenResponseDialog(props.requirement, props.siteKeyCompanyID)
        }
      >
        <StyledTooltip title="Add response">
          <AddBoxIcon
            aria-label={`add response for "${props.requirement.title}" requirement`}
            sx={{ fontSize: 45 }}
          />
        </StyledTooltip>
      </button>
      <div className="lg:text-lg">
        {sortedComplianceItemList.map((item) =>
          Object.entries(item).map(([key, customField]) => {
            return (
              <div key={key} className="mb-1 flex items-start font-semibold">
                <span className="mr-2 mt-1 h-5 w-5 flex-shrink-0">
                  <StyledTooltip title={strings.NO_STATUS}>
                    <QuestionMarkCircleIcon className=" text-gray-500" />
                  </StyledTooltip>
                </span>
                {customField.text}
              </div>
            );
          }),
        )}
      </div>
    </AccordionDetails>
  );
}

function checkAttachmentIsPDF(
  attachment: ExistingAttachment | undefined,
): boolean {
  if (attachment === undefined) return false;

  return attachment.url.includes("pdf");
}
