// Libs
import { Control, Controller, FieldValues } from "react-hook-form";

// Local
import { BooleanCustomField } from "../../models/custom-field";
import BaseInputRadio from "../BaseInputRadio";
import { Json } from "../../models/json-type";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";

interface Props {
  customField: BooleanCustomField & { id: string };
  control: Control<FieldValues, any>;
  defaultValue: Json | Date;
  isRequired: boolean;
}

export default function FieldTypeBoolean(props: Props): JSX.Element {
  return (
    <Controller
      defaultValue={props.defaultValue}
      name={props.customField.id}
      control={props.control}
      rules={{ required: props.isRequired }}
      render={({ field, fieldState }) => {
        return (
          <>
            <span>
              {props.customField.title}{" "}
              {props.isRequired && (
                <span className="text-lg font-medium text-redFail">*</span>
              )}
            </span>
            <div className="mt-2 grid grid-cols-2">
              <BaseInputRadio
                key="Yes"
                id={`yes_${props.customField.id}`}
                label="Yes"
                {...field}
                defaultChecked={"Yes" === field.value}
              />
              <BaseInputRadio
                key="No"
                id={`no_${props.customField.id}`}
                label="No"
                {...field}
                defaultChecked={"No" === field.value}
              />
            </div>
            {fieldState.error && fieldState.error.type === "required" && (
              <div className="mt-2">
                <StyledMessage type="error">
                  {{ message: strings.REQUIRED }}
                </StyledMessage>
              </div>
            )}
          </>
        );
      }}
    />
  );
}
