// Libs
import { Control, Controller, FieldValues } from "react-hook-form";

// Local
import { SelectionCustomField } from "../../models/custom-field";
import BaseInputCheckbox from "../BaseInputCheckbox";
import { Json } from "../../models/json-type";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";

interface Props {
  customField: SelectionCustomField & { id: string };
  control: Control<FieldValues, any>;
  defaultValue: Json | Date;
  isRequired: boolean;
}

export default function FieldTypeSelectionCheckbox(props: Props): JSX.Element {
  return (
    <Controller
      defaultValue={props.defaultValue}
      name={props.customField.id}
      control={props.control}
      rules={{ required: props.isRequired }}
      render={({ field, fieldState }) => {
        return (
          <div className="grid w-full grid-cols-1 px-2">
            <span>
              {props.customField.title}{" "}
              {props.isRequired && (
                <span className="text-lg font-medium text-redFail">*</span>
              )}
            </span>
            <BaseInputCheckbox
              label={Object.values(props.customField.selectionOptions)[0]}
              {...field}
              id={props.customField.id + props.customField.title}
            />
            {fieldState.error && fieldState.error.type === "required" && (
              <div className="mt-2">
                <StyledMessage type="error">
                  {{ message: strings.REQUIRED }}
                </StyledMessage>
              </div>
            )}
          </div>
        );
      }}
    />
  );
}
