//Libs
import { Fragment, useEffect, useMemo, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { logger as devLogger } from "../../logging";
import { ErrorMessage } from "../ErrorMessage";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  handleSave: (formValues: InventoryLocationState) => Promise<void>;
}

const InventoryLocationSchema = z.object({
  title: z.string().min(1).max(500),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
});
export type InventoryLocationState = z.infer<typeof InventoryLocationSchema>;

export default function AddInventoryLocationDialog({
  closeDialog,
  handleSave,
  isDialogOpen,
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const inventoryLocationDefaultValues: InventoryLocationState = useMemo(() => {
    return {
      title: "",
      latitude: null,
      longitude: null,
    };
  }, []);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<InventoryLocationState>({
    defaultValues: inventoryLocationDefaultValues,
    resolver: zodResolver(InventoryLocationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(inventoryLocationDefaultValues);
  }, [inventoryLocationDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function onCloseDialog() {
    closeDialog();
    reset();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit: SubmitHandler<InventoryLocationState> = async (
    formValues,
  ) => {
    try {
      await handleSave(formValues);
      // Close dialog if successful.
      onCloseDialog();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.NEW_INVENTORY_LOCATION}
      </h1>
      <button type="button" onClick={() => onCloseDialog()}>
        <XMarkIcon
          aria-label="close location form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = (
    <ErrorMessage
      message={strings.ERR_CREATE_NEW_INVENTORY_LOCATION}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <BaseModal
      closeModal={onCloseDialog}
      open={isDialogOpen}
      title={header}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <Fragment>
        <TheForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          control={control}
          isSubmitting={isSubmitting}
          handleCancel={onCloseDialog}
        />
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </Fragment>
    </BaseModal>
  );
}

interface FormProps {
  handleSubmit: UseFormHandleSubmit<InventoryLocationState>;
  onSubmit: SubmitHandler<InventoryLocationState>;
  errors: FieldErrors;
  control: Control<InventoryLocationState, any>;
  isSubmitting: boolean;
  handleCancel: () => void;
}

// Extracted 'TheForm' because: https://stackoverflow.com/a/67002581
const TheForm = (props: FormProps) => {
  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit(props.onSubmit)}
      className="flex flex-col space-y-8 p-8 text-lg"
    >
      {/* Field: Title */}
      <div>
        <Controller
          name="title"
          control={props.control}
          render={({ field }) => (
            <BaseInputText
              text="Title"
              inputName="title"
              admin={true}
              required={true}
              {...field}
            />
          )}
        />
        {props.errors.title?.message &&
          typeof props.errors.title.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.title.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Action Buttons */}
      <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={props.handleCancel}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          disabled={props.isSubmitting}
          isBusy={props.isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
    </form>
  );
};
