//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import {
  convertFSTimestampToLuxonDT,
  convertLuxonDTToFSTimestamp,
  dropUndefined,
  guardIsPlainObject,
} from "../utils";
import { zFallback } from "../utils/zod-fallback";
import {
  ExistingMembershipTemplate,
  InvoiceFrequency,
  invoiceFrequency,
} from "./membership-template";
import { Task } from "./task";

export const membershipStatus = [
  "active",
  "draft",
  "awaitingPayment",
  "canceled",
  "expired",
  "suspended",
  "renewed",
] as const;
// Create the types from the TS array 'membershipStatus'.
export type MembershipStatusValues = (typeof membershipStatus)[number];

export enum MembershipStatus {
  Active = "active",
  Draft = "draft",
  AwaitingPayment = "awaitingPayment",
  Canceled = "canceled",
  Expired = "expired",
  Suspended = "suspended",
  Renewed = "renewed",
}

export function getReadableMembershipStatus(value: string): string {
  switch (value) {
    case "draft":
      return "Draft";
    case "active":
      return "Active";
    case "awaitingPayment":
      return "Awaiting Payment";
    case "canceled":
      return "Canceled";
    case "expired":
      return "Expired";
    case "suspended":
      return "Suspended";
    case "renewed":
      return "Renewed";
    default:
      return "UNKNOWN";
  }
}

export interface Membership {
  createdBy: string;
  customerID: string;
  customerLocationID: string | null;
  customerName: string;
  lastModifiedBy: string;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  locationID: string | null;

  lastCompletedTaskDate: Timestamp | null;
  lastPaymentAmount: number | null;
  lastPaymentDate: Timestamp | null;
  membershipEndDate: Timestamp | null;
  membershipStartDate: Timestamp | null;
  membershipTemplateID: string;
  nextInvoiceDate: Timestamp | null;
  nextScheduledTaskDate: Timestamp | null;
  notes: string | null;
  status: MembershipStatusValues;
  assetIDs?: string[];
  tasks?: Record<string, Task>;
  automaticallyGenerateTasks?: boolean;
  renewedToMembershipID?: string;
  renewedFromMembershipID?: string;
}

export interface ExistingMembership extends Membership {
  id: string;
  refPath: string;
}

export interface EstimateItemMembershipData {
  membershipTemplateID: string;
  isSameTask: boolean;
  notes: string | null;
  selectedStartDate: Timestamp | null;
  renewedFromMembershipID?: string;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  Membership,
  | "timestampCreated"
  | "timestampLastModified"
  | "membershipStartDate"
  | "lastTaskDate"
  | "nextTaskDate"
  | "lastRenewalAmount"
>;

// Timestamps are added on the backend.
export interface Membership_CreateAPI extends withoutTimestamps {
  siteKey: string;
  frequency: InvoiceFrequency;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type Membership_UpdateAPI = Omit<
  Partial<ExistingMembership>,
  "timestampCreated" | "timestampLastModified"
>;

export type Membership_DeleteAPI = ExistingMembership["id"];

/** Utilities for interacting with Membership objects  */
export const MembershipManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingMembership object.
   */
  createFromFirestoreSnapshot: createMembershipFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertMembershipForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateMembership,
  /** Validate a Membership doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateMembershipWithFallbacks,
  /** Validate a new customer location. For the create endpoint. */
  parseCreate: validateMembership_Create,
};

/**
 * Convert the Document Snapshot into a validated ExistingMembership object.
 */
function createMembershipFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingMembership {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateMembershipWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertMembershipForFirestore(membership: Membership): DocumentData {
  const localItem = Object.assign({}, membership);
  const { ...rest } = localItem;
  const result = dropUndefined(rest);
  return result;
}

/* Zod validation schemas */
function validateMembershipWithFallbacks(value: unknown): Membership {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = membershipSchemaWithFallbacks.parse(value);
  return result;
}

function validateMembership(value: unknown): Membership {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = membershipSchema.parse(value);
  return result;
}

function validateMembership_Create(value: unknown): Membership_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return membershipSchema_CreateAPI.parse(value);
}

const membershipSchemaWithFallbacks = z.object({
  customerID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'customerID'",
  ),
  customerLocationID: zFallback(
    z.string().min(1).max(200).nullable(),
    "unknown",
    "membershipSchemaWithFallbacks: 'customerLocationID'",
  ),
  customerName: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'customerName'",
  ),
  locationID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'locationID'",
  ),
  membershipTemplateID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'membershipTemplateID'",
  ),
  status: zFallback(
    z.enum(membershipStatus),
    "canceled",
    "membershipSchemaWithFallbacks: 'status'",
  ),
  notes: zFallback(
    z.string().max(1000).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'notes'",
  ),
  lastRenewalDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'lastRenewalDate'",
  ),
  lastCompletedTaskDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'lastCompletedTaskDate'",
  ),
  lastPaymentDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'lastPaymentDate'",
  ),
  lastPaymentAmount: zFallback(
    z.number().nullable(),
    null,
    "membershipSchemaWithFallbacks: 'lastPaymentAmount'",
  ),
  nextInvoiceDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'nextInvoiceDate'",
  ),
  nextScheduledTaskDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'nextScheduledTaskDate'",
  ),
  membershipStartDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'membershipStartDate'",
  ),
  membershipEndDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'membershipEndDate'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "membershipSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "membershipSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  assetIDs: zFallback(
    z.array(z.string()).optional(),
    [],
    "membershipSchemaWithFallbacks: 'assetIDs'",
  ),
  tasks: zFallback(
    z.any().optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'assetIDs'",
  ),
  automaticallyGenerateTasks: z.boolean().optional(),
  renewedToMembershipID: z.string().optional(),
  renewedFromMembershipID: z.string().optional(),
});

const membershipSchema = z.object({
  createdBy: z.string().min(1).max(200),
  customerID: z.string().min(1).max(200),
  customerLocationID: z.string().min(1).max(200).nullable(),
  customerName: z.string().min(1).max(200),
  lastCompletedTaskDate: z.instanceof(Timestamp).nullable(),
  lastModifiedBy: z.string().min(1).max(200),
  lastPaymentAmount: z.number().nullable(),
  lastPaymentDate: z.instanceof(Timestamp).nullable(),
  locationID: z.string().min(1).max(200).nullable(),
  membershipEndDate: z.instanceof(Timestamp).nullable(),
  membershipStartDate: z.instanceof(Timestamp).nullable(),
  membershipTemplateID: z.string().min(1).max(200),
  nextInvoiceDate: z.instanceof(Timestamp).nullable(),
  nextScheduledTaskDate: z.instanceof(Timestamp).nullable(),
  notes: z.string().max(1000).nullable(),
  status: z.enum(membershipStatus),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  assetIDs: z.array(z.string()).optional(),
  tasks: z.any().optional(),
  automaticallyGenerateTasks: z.boolean().optional(),
  renewedToMembershipID: z.string().optional(),
  renewedFromMembershipID: z.string().optional(),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = membershipSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
  membershipStartDate: true,
});

// Used for interacting with the Create endpoint.
const membershipSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
  frequency: z.enum(invoiceFrequency),
});

export function generateNextRenewalDate(
  membershipTemplate: ExistingMembershipTemplate,
  fromTimestamp: Timestamp,
): Timestamp | null {
  switch (membershipTemplate.invoiceFrequency) {
    case "annual": {
      const nextRenewalTimestamp = convertFSTimestampToLuxonDT(
        fromTimestamp,
      ).plus({
        years: 1,
      });
      return convertLuxonDTToFSTimestamp(nextRenewalTimestamp);
    }
    case "semiannual": {
      const nextRenewalTimestamp = convertFSTimestampToLuxonDT(
        fromTimestamp,
      ).plus({
        months: 6,
      });
      return convertLuxonDTToFSTimestamp(nextRenewalTimestamp);
    }
    case "quarterly": {
      const nextRenewalTimestamp = convertFSTimestampToLuxonDT(
        fromTimestamp,
      ).plus({
        months: 3,
      });
      return convertLuxonDTToFSTimestamp(nextRenewalTimestamp);
    }
    case "monthly": {
      const nextRenewalTimestamp = convertFSTimestampToLuxonDT(
        fromTimestamp,
      ).plus({
        months: 1,
      });
      return convertLuxonDTToFSTimestamp(nextRenewalTimestamp);
    }
    case "indefinite": {
      const nextRenewalTimestamp = null;
      return nextRenewalTimestamp;
    }
    case null: {
      const nextRenewalTimestamp = null;
      return nextRenewalTimestamp;
    }
    default: {
      // 👁️ 🧡 Typescript
      const _exhaustiveCheck: never = membershipTemplate.invoiceFrequency;
      return _exhaustiveCheck;
    }
  }
}
