// Libs
import { forwardRef } from "react";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

// Local
import * as strings from "../../strings";
import { ExistingFeedback } from "../../models/feedback";
import ButtonThumbsDown from "../ButtonThumbsDown";
import ButtonThumbsUp from "../ButtonThumbsUp";
import BaseButtonSecondary from "../BaseButtonSecondary";

type Ref = HTMLButtonElement;
type Props = {
  customerPleased: ExistingFeedback["customerPleased"];
} & React.ComponentPropsWithRef<"button">;

const FeedbackButton = forwardRef<Ref, Props>((props, ref): JSX.Element => {
  const { customerPleased: opinion, ...rest } = props;
  switch (opinion) {
    case true: {
      return (
        <ButtonThumbsUp
          {...rest}
          ref={ref}
          text={strings.feedback_status.POSITIVE}
          className="uppercase"
        />
      );
    }
    case false: {
      return (
        <ButtonThumbsDown
          {...rest}
          ref={ref}
          text={strings.feedback_status.NEGATIVE}
          className="uppercase"
        />
      );
    }
    case null: {
      return (
        <BaseButtonSecondary className="uppercase" {...rest} ref={ref}>
          <HourglassBottomIcon
            style={{
              width: "20px",
              height: "20px",
              marginRight: "12px",
            }}
          />
          {strings.feedback_status.AWAITING}
        </BaseButtonSecondary>
      );
    }
    default: {
      const _exhaustivenessCheck: never = opinion;
      return _exhaustivenessCheck;
    }
  }
});

export default FeedbackButton;
