import isEqual from "lodash/isEqual";
import isPlainObject from "lodash/isPlainObject";
import omitBy from "lodash/omitBy";

/**
 * Compare an "before" object to a "after" object. Generate a string report of the differences
 * and an object with changed properties and the new values of the after object.
 *
 * This does NOT compare differences of nested objects.
 * @param beforeObject the original object
 * @param afterObject the "new" object
 */
export function diffObjects(
  beforeObject: Record<string, any>,
  afterObject: Record<string, any>,
): { report: Record<string, any>; diff: Record<string, any> } {
  const diff = recursiveDiff(beforeObject, afterObject);

  const report = recursiveReport(diff, beforeObject, afterObject);

  return {
    report,
    diff,
  };
}

/**
 * Remove properties from afterObject that are deeply equal to properties
 * in beforeObject.
 */
function recursiveDiff(
  beforeObject: Record<string, any>,
  afterObject: Record<string, any>,
): Record<string, any> {
  const output = omitBy(afterObject, (value, key) => {
    return isEqual(value, beforeObject[key]);
  });

  // If changed and before and after values are both plain objects, do another
  // deep diff.
  Object.keys(output).forEach((key) => {
    if (isPlainObject(beforeObject[key]) && isPlainObject(afterObject[key])) {
      // Recursive Operation
      output[key] = recursiveDiff(beforeObject[key], afterObject[key]);
    }
  });

  return output;
}

/**
 * Recursive compare values of change keys (from diff) and create a report-like
 * object typeically for logging.
 */
function recursiveReport(
  diff: Record<string, any>,
  beforeObject: Record<string, any>,
  afterObject: Record<string, any>,
): Record<string, any> {
  const output: Record<string, any> = {};
  Object.keys(diff).forEach((key) => {
    if (isPlainObject(beforeObject[key]) && isPlainObject(afterObject[key])) {
      output[key] = recursiveReport(
        diff[key],
        beforeObject[key],
        afterObject[key],
      );
    } else {
      output[key] = `${JSON.stringify(
        beforeObject[key],
        null,
        2,
      )} -> ${JSON.stringify(afterObject[key], null, 2)}`;
    }
  });
  return output;
}
