//Libs
import create from "zustand";

//Local
import { ExistingMembershipTemplate } from "../models/membership-template";
import { ExistingSiteKey } from "../models/site-key";
import { SiteKeyUserPermissions } from "../models/site-key-user-permissions";
import { NotFoundError } from "../error-classes";
import { logger as devLogger } from "../logging";
import { DbRead } from "../database";

//Define the property types
interface MembershipTemplatesStore {
  membershipTemplates: ExistingMembershipTemplate[];
  loading: boolean;
  fetch: (
    siteKeyDoc: ExistingSiteKey,
    userPermission: SiteKeyUserPermissions,
  ) => void;
}

//Export a hook to access data globally and define initial values.
export const useMembershipTemplatesStore = create<MembershipTemplatesStore>(
  (set) => {
    return {
      membershipTemplates: [],
      loading: false,
      fetch: async (siteKeyDoc, userPermission) => {
        if (typeof siteKeyDoc.id !== "string") {
          return;
        }
        set({ loading: true });
        try {
          if (
            userPermission.permissions.isSiteAdmin ||
            userPermission.permissions.isPlantPersonnel
          ) {
            const allMembershipTemplates =
              await DbRead.membershipTemplates.getAll(siteKeyDoc.id);
            set({
              membershipTemplates: allMembershipTemplates,
              loading: false,
            });
          }
        } catch (err) {
          set({ membershipTemplates: [], loading: false });
          if (err instanceof NotFoundError) {
            devLogger.error(err);
          } else {
            devLogger.error(err);
          }
        }
      },
    };
  },
);
