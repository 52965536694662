// rschedule.ts

import "@rschedule/luxon-date-adapter/v2/setup";
import "@rschedule/json-tools/setup"; // <-- optional json support
import "@rschedule/json-tools/Schedule";
import { RuleOption } from "@rschedule/core";
// import { RScheduleConfig } from "@rschedule/rschedule";
// import { LuxonDateAdapter } from "@rschedule/luxon-date-adapter/v2";

// export * from "@rschedule/json-tools/Schedule";
export * from "@rschedule/luxon-date-adapter/v2";
export * from "@rschedule/core";
export * from "@rschedule/core/generators";
// export * from '@rschedule/ical-tools' <-- optional ical support

// RScheduleConfig.defaultDateAdapter = LuxonDateAdapter;

const validHours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const validMinutes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];

/** TypeGuard for checking parsed strings return a valid hour */
export function isValidHour(value: unknown): value is RuleOption.ByHourOfDay {
  return validHours.includes(value as any);
}
/** TypeGuard for checking parsed strings return a valid minute */
export function isValidMinute(
  value: unknown,
): value is RuleOption.ByMinuteOfHour {
  return validMinutes.includes(value as any);
}

export function getSystemTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
