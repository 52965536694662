//Local
import { ExistingCustomerLocation } from "../../models/customer-location";
import * as strings from "../../strings";

export function CustomerAddress({
  customerLocations,
}: {
  customerLocations?: ExistingCustomerLocation[];
}) {
  return (
    <div className="mb-2 mr-2 pt-4 font-bold text-gray-800 md:pt-1">
      <div className="pb-4 text-xl font-bold text-primary">
        {strings.LOCATION_DETAILS}
      </div>
      {customerLocations != null && customerLocations.length > 1 && (
        <li className="flex flex-col">Multiple Addresses</li>
      )}
      {customerLocations != null && customerLocations.length === 1 && (
        <ul className="space-y-2.5">
          {customerLocations[0].fullAddress && (
            <li className="flex flex-col">
              <span className="text-sm font-medium text-gray-400">
                {strings.FULL_ADDRESS}
              </span>
              {customerLocations[0].fullAddress}
            </li>
          )}
          {customerLocations[0].addressLine1 && (
            <li className="flex flex-col">
              <span className="text-sm font-medium text-gray-400">
                {strings.ADDRESS_LINE_1}
              </span>
              {customerLocations[0].addressLine1}
            </li>
          )}
          {customerLocations[0].addressLine2 && (
            <li className="flex flex-col">
              <span className="text-sm font-medium text-gray-400">
                {strings.ADDRESS_LINE_2}
              </span>
              {customerLocations[0].addressLine2}
            </li>
          )}
          <li className="flex flex-col">
            <span className="text-sm font-medium capitalize text-gray-400">
              {strings.TYPE_RESIDENTIAL_COMMERCIAL}
            </span>
            <span className="capitalize">{customerLocations[0].type}</span>
          </li>
          {customerLocations[0].notes && (
            <li className="flex flex-col">
              <span className="text-sm font-medium text-gray-400">
                {strings.NOTES}
              </span>
              <p>{customerLocations[0].notes}</p>
            </li>
          )}
        </ul>
      )}
      {!customerLocations && <div>No address associated</div>}
    </div>
  );
}
