// Libs
import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

// Local
import { signIn, signUserOut } from "../init-firebase";
import { HOME_URL } from "../urls";
import { logger as devLogger } from "../logging";
import new_stilt_logo from "../images/new_stilt_logo.png";
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import * as strings from "../strings";
import { useToastMessageStore } from "../store/toast-messages";
import { createToastMessageID } from "../utils";
import { FORGOT_PASSWORD_URL } from "../urls";
import { FirebaseError } from "firebase/app";

export default function LogIn() {
  // Initial state for the email and password form fields.
  const [{ email }, setEmail] = useState({ email: "" });
  const [{ password }, setPassword] = useState({ password: "" });
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  // React router for navigation.
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || HOME_URL;

  return (
    <div className="flex h-full items-start justify-center bg-white px-4 py-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <div className="rounded-lg bg-black py-20">
            <img
              src={new_stilt_logo}
              alt="Stilt Logo"
              className="mx-auto block px-12 lg:px-4"
            />
          </div>

          <h2 className="pt-14 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primaryLight focus:outline-none focus:ring-1 focus:ring-primaryLight sm:text-sm"
                placeholder="Email address"
                onChange={(event: React.FormEvent<HTMLInputElement>) =>
                  setEmail({ email: event.currentTarget.value })
                }
                value={email}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleLogin(event);
                  }
                }}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primaryLight focus:outline-none focus:ring-primaryLight sm:text-sm"
                placeholder="Password"
                onChange={(event: React.FormEvent<HTMLInputElement>) =>
                  setPassword({ password: event.currentTarget.value })
                }
                value={password}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleLogin(event);
                  }
                }}
              />
            </div>
          </div>

          {/* Forgot Password section */}
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to={FORGOT_PASSWORD_URL}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <BaseButtonPrimary
              type="button"
              onClick={handleLogin}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleLogin(event);
                }
              }}
              isBusy={isBusy}
              busyText={strings.buttons.BUSY_SUBMITTING}
              className="w-full justify-center"
              data-testid="login-button"
            >
              Sign In
            </BaseButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  );

  async function handleLogout(event: React.FormEvent) {
    event.preventDefault();
    await signUserOut();
  }

  async function handleLogin(event: React.FormEvent) {
    event.preventDefault();
    setIsBusy(true);
    try {
      await signIn(email, password);

      setEmail({ email: "" });

      setPassword({ password: "" });
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.
      navigate(from);
    } catch (err) {
      devLogger.error(err);

      if (err instanceof FirebaseError) {
        addToastMessage({
          id: createToastMessageID(),
          message: err.message,
          dialog: false,
          type: "error",
        });
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: JSON.stringify(err),
          dialog: false,
          type: "error",
        });
      }
    } finally {
      setIsBusy(false);
    }
  }
}
