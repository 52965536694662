export default function FormLabel(props: {
  htmlFor: string;
  label: string;
  className?: string;
  subtitle?: string;
  removePadding?: boolean;
}): JSX.Element {
  return (
    <label
      htmlFor={props.htmlFor}
      className={`${
        props.className
      } block text-sm font-medium text-gray-700 sm:mt-px 
      ${props.removePadding === true ? "" : "sm:pt-2"}`}
    >
      {props.label}
      <br />
      <small>{props.subtitle}</small>
    </label>
  );
}
