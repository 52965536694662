//Libs

//Local
import { ExistingCustomer } from "../models/customer";
import { CustomerContact } from "../models/customer-contact";
import { ExistingStiltInvoice } from "../models/invoice";

export function getEmailList(
  invoice: ExistingStiltInvoice | null,
  customerDoc: ExistingCustomer | null,
): string[] {
  const emailList: string[] = [];
  if (invoice && invoice.email) {
    emailList.push(invoice.email);
  }
  if (customerDoc) {
    if (customerDoc.email) {
      emailList.push(...customerDoc.email);
    }
    if (customerDoc.billingInfo) {
      emailList.push(customerDoc.billingInfo.email);
    }
    if (customerDoc.customerContacts) {
      const contacts = Object.values(
        customerDoc.customerContacts as CustomerContact[],
      )
        .filter((contact) => contact.type === "email")
        .map((object) => object.value);
      emailList.push(...contacts);
    }
  }

  const uniqueEmailList = Array.from(new Set(emailList));

  return uniqueEmailList;
}
