import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

/**
 * Styled tooltip that mimicks the tooltip styles in the main app.
 */
export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    classes={{ popper: className }}
    enterDelay={500}
    enterNextDelay={400}
    placement="top"
    {...props}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(17, 24, 39, 1.0)",
    color: "#ffffff",
    padding: "6px 8px",
    letterSpacing: "0.4px",
    fontSize: 13,
  },
}));
