//Libs
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DbRead } from "../../database";

//Local
import AssetsEquipmentPage from "./AssetsEquipmentPage";
import { CUSTOMERS_URL, WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { ExistingStiltInvoice } from "../../models/invoice";
import { ExistingAsset } from "../../models/asset";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as strings from "../../strings";
import AddAssetDialog from "../../components/AssetsEquipment/AddAssetDialog";

interface Props {
  siteKey: string;
}

export default function AssetsEquipmentContainer({ siteKey }: Props) {
  const [assetDocs, setAssetDocs] = useState<ExistingAsset[]>([]);

  const [selectedAssetID, setSelectedAssetID] = useState<string | null>(null);
  const [selectedAsset, setSelectedAsset] = useState<ExistingAsset | null>(
    null,
  );

  const [assetDetailsDialogOpen, setAssetDetailsDialogOpen] =
    useState<boolean>(false);
  /* QUERIES */

  // Fetch the list of invoices when this component loads.
  useEffect(() => {
    function getAssets() {
      return DbRead.assets.subscribeAll({
        siteKey: siteKey,
        onChange: setAssetDocs,
      });
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getAssets();
    return () => unsubscribePromise && unsubscribePromise();
  }, [siteKey]);

  /* HISTORY */
  const navigate = useNavigate();

  function goToCustomerPage(customerId: string) {
    navigate(`${CUSTOMERS_URL}/${customerId}`);
  }

  function goToWorkRecordAndTasksPage(
    craftRecordID: ExistingStiltInvoice["craftRecordID"],
  ) {
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  function handleOpenViewAssetDetailsDialog(asset: ExistingAsset): void {
    setSelectedAsset(asset);
    setSelectedAssetID(asset.id);
    setAssetDetailsDialogOpen(true);
  }

  const createAssetEquipmentButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => {
        setAssetDetailsDialogOpen(true);
        setSelectedAssetID(null);
      }}
      className="col-span-2 w-fit justify-self-end uppercase tracking-wider text-primary"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.CREATE_ASSET_EQUIPMENT}
    </BaseButtonPrimary>
  );

  const assetDialog = (
    <AddAssetDialog
      asset={selectedAsset}
      isDialogOpen={assetDetailsDialogOpen}
      customer={null}
      customerLocation={null}
      customerLocationOptions={null}
      locationID={null}
      siteKey={siteKey}
      closeDialog={() => setAssetDetailsDialogOpen(false)}
      isSubmitting={false}
    ></AddAssetDialog>
  );

  return (
    <Fragment>
      <AssetsEquipmentPage
        assets={assetDocs}
        CreateAssetEquipmentButton={createAssetEquipmentButton}
        // dateRange={range}
        goToCustomerPage={goToCustomerPage}
        goToWorkRecordAndTasksPage={goToWorkRecordAndTasksPage}
        handleOpenViewAssetDetailsDialog={() => {}}
      >
        {{}}
      </AssetsEquipmentPage>
      {/*{handlePaymentLinkDialog}*/}
      {/*{manualPaymentDialog}*/}
      {assetDialog}
    </Fragment>
  );
}
