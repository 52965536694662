// Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

// Local
import { Customer } from "../../models/customer";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import AddEditLocationManuallyForm from "./AddEditLocationManuallyForm";
import AddEditLocationWithAutocompleteForm from "./AddEditLocationWithAutocompleteForm";
import { TemporaryLocation } from "./AddEditCustomerLocationDialog";
import {
  customerLocationWithoutTimestamps,
  ExistingCustomerLocation,
} from "../../models/customer-location";

interface Props {
  // DIALOG BASICS
  isDialogOpen: boolean;
  closeDialog: () => void;
  // DATA
  geocoderResult: google.maps.GeocoderResult;
  customerType: Customer["type"];
  customerLocationDoc: ExistingCustomerLocation | null;
  isSubmitting: boolean;
  // FUNCTIONS
  handleSaveCustomerLocationWithAutocomplete: (
    newLocation: TemporaryLocation,
  ) => void;
  handleSaveManualCustomerLocation: (newLocation: TemporaryLocation) => void;
  handleEditCustomerLocation: (
    updateLocation: customerLocationWithoutTimestamps,
  ) => Promise<void>;
  showManualEntryForm: boolean;
  // COMPONENTS
  children: {
    AddressField: React.ReactNode;
    ConfirmUpdateCustomerLocation: React.ReactNode;
  };
}

/** Use this dialog when it's not being shown inside the add customer dialog */
export default function StandaloneLocationDialog(props: Props) {
  const dialogTitle = (
    <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
      <h1>{strings.NEW_LOCATION}</h1>
      <button
        onClick={props.closeDialog}
        className="rounded focus-visible:outline focus-visible:outline-white"
      >
        <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={dialogTitle}
      parentDivStyles="text-left max-w-lg md:max-w-2xl"
    >
      <div className="m-6 space-y-8 md:m-8">
        {props.showManualEntryForm ? (
          <AddEditLocationManuallyForm
            customerLocationDoc={props.customerLocationDoc}
            customerType={props.customerType}
            setAddEditCustomerLocationFormOpen={props.closeDialog}
            isSubmitting={props.isSubmitting}
            handleSaveManualCustomerLocation={
              props.handleSaveManualCustomerLocation
            }
            handleEditCustomerLocation={props.handleEditCustomerLocation}
          />
        ) : (
          <AddEditLocationWithAutocompleteForm
            geocoderResult={props.geocoderResult}
            customerLocationDoc={props.customerLocationDoc}
            customerType={props.customerType}
            handleSaveCustomerLocationWithAutocomplete={
              props.handleSaveCustomerLocationWithAutocomplete
            }
            isSubmitting={props.isSubmitting}
            setAddEditCustomerLocationFormOpen={props.closeDialog}
            handleEditCustomerLocation={props.handleEditCustomerLocation}
          >
            {{
              AddressField: props.children.AddressField,
            }}
          </AddEditLocationWithAutocompleteForm>
        )}
      </div>
      {props.children.ConfirmUpdateCustomerLocation}
    </BaseModal>
  );
}
