//Libs
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local

interface CustomFieldCardProps {
  //DATA
  taskType?: string;
  craftRecord: boolean;
  sectionTitle: string;
  //FUNCTION
  openAddCustomFieldDialog: () => void;
  //CHILDREN
  children: {
    CardsMapping?: React.ReactNode;
  };
}

export default function CustomFieldSection(props: CustomFieldCardProps) {
  const title = props.sectionTitle.split(/(?=[A-Z])/).join(" ");

  return (
    <div
      className={`${
        props.craftRecord
          ? "my-8 rounded bg-gray-100 px-2 pb-6 pt-4 sm:px-5 sm:pb-10"
          : "my-8 px-2 pb-6 pt-4 sm:px-5 sm:pb-10"
      }  grid grid-cols-1 justify-items-center sm:my-5 sm:justify-items-stretch`}
    >
      <div className="flex items-center justify-between ">
        <h2 className="text-lg font-bold sm:text-3xl">
          {props.craftRecord ? (
            <span className="capitalize">{title}</span>
          ) : (
            `${props.taskType} Tasks`
          )}
        </h2>
        <button
          type="button"
          className="text-primary"
          onClick={props.openAddCustomFieldDialog}
        >
          <AddBoxIcon aria-label="Add Custom Field" sx={{ fontSize: 45 }} />
        </button>
      </div>
      <div className="mt-4 grid min-w-full grid-cols-1 gap-3 sm:mt-10 md:grid-cols-2 md:place-items-stretch lg:grid-cols-3">
        {props.children.CardsMapping}
      </div>
    </div>
  );
}
