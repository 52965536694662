// Libs
import { z } from "zod";
import { DocumentSnapshot } from "firebase/firestore";

// Local
import {
  OCraftTypes,
  CraftTypeValues,
  getCraftTypeFromRecordString,
} from "./craft-types";
import { OTaskStatus, TaskStatusValues } from "./task-status";
import {
  getTaskTypeFromString,
  OTaskTypes,
  TaskTypesValues,
} from "./task-types";
import { guardIsPlainObject } from "../utils/isPlainObject";
import { NotFoundError } from "../error-classes";
import { dropUndefined } from "../utils";
import { zFallback } from "../utils/zod-fallback";
import { Json } from "./json-type";

// Tells us if the given value (customField.fieldType) is present in the
// customFieldTypes array or not. Narrows the types for our
// validateCustomField ƒn. Lets us use exhaustiveness checking.
export function isCustomFieldType(value: unknown): value is CustomFieldTypes {
  return customFieldTypes.includes(value as any);
}

export const customFieldTypes = [
  "bool",
  "number",
  "string",
  "string-textarea",
  "timestamp",
  "selection",
  "uid",
  "multiple-uid",
  "currency",
  "string-array",
  "hours-minutes",
] as const;
// Create the types from the TS array 'customFieldTypes'.
export type CustomFieldTypes = (typeof customFieldTypes)[number];

/** For the muggles that use our app. */
export function getReadableCustomFieldType(value: unknown): string {
  const readable: string | undefined =
    readableCustomFieldTypeMap[value as CustomFieldTypes];
  if (!readable) return "UNKNOWN";
  return readable;
}
const readableCustomFieldTypeMap: Record<CustomFieldTypes, string> = {
  bool: "Yes/No",
  number: "Number",
  string: "Text",
  "string-textarea": "Substantial Amount of Text",
  timestamp: "Timestamp",
  selection: "Selection",
  uid: "User",
  "multiple-uid": "Multiple Users",
  currency: "Currency",
  "string-array": "Text List",
  "hours-minutes": "Hours:Minutes",
};

export function getFieldTypeFromString(
  value: unknown,
): CustomFieldTypes | undefined {
  switch (value) {
    case "Yes/No":
      return "bool";
    case "Number":
      return "number";
    case "Text":
      return "string";
    case "Substantial Amount of Text":
      return "string-textarea";
    case "Timestamp":
      return "timestamp";
    case "Selection":
      return "selection";
    case "User":
      return "uid";
    case "Multiple Users":
      return "multiple-uid";
    case "Currency":
      return "currency";
    case "Text List":
      return "string-array";
    case "Hours:Minutes":
      return "hours-minutes";
    default:
      return undefined;
  }
}

// We want to disable the string-array form for addCustomField, at least for now.
export type CustomFieldTypesExcludingStrArr = Exclude<
  CustomFieldTypes,
  "string-array"
>;
// Also using this in the addCustomFieldDialog stuff.
export const customFieldTypesExcludingStrArr = [
  "bool",
  "number",
  "string",
  "string-textarea",
  "timestamp",
  "selection",
  "uid",
  "multiple-uid",
  "currency",
  "hours-minutes",
] as const;

// Using this to determine if we add min/max when reading from site customizations map.
const fieldsThatHaveMinMax: CustomFieldTypes[] = [
  "string",
  "string-textarea",
  "number",
  "uid",
  "string-array",
  "hours-minutes",
];

type TaskFieldParts = {
  craftRecordOrTask: "task";
  craftType: CraftTypeValues;
  taskType: TaskTypesValues;
};
type CraftRecordFieldParts = {
  craftRecordOrTask: "craftRecord";
  craftType: CraftTypeValues;
};

/**
 * These properties are common to every custom field.
 */
type CustomFieldCommon = {
  deleted: boolean;

  defaultValue: boolean | number | string | string[] | null;
  fieldType: CustomFieldTypes;
  title: string;
  editable: boolean;
  required: boolean;
  onTaskStatus?: TaskStatusValues[];
  hideOnCraftRecordCreation?: boolean;

  // for 'smartFields', that the client or servers rely on.
  /** Admins can adjust certain fields such as min, max, title, etc. */
  adminAdjustable: boolean;
  /** Admins are allowed to delete this field from the database. */
  adminRemovable: boolean;
} & (TaskFieldParts | CraftRecordFieldParts);

export type CustomFieldResponse = Record<string, Json>;

/**
 * Returns the default values for the common properties will go on every
 * new CustomField. These are unlikely to change on user added fields. Defining
 * them in this function allows to edit them from a single source.
 */
export function adminPropDefaultsForCustomField(): Pick<
  CustomFieldCommon,
  "adminAdjustable" | "adminRemovable" | "deleted"
> {
  return {
    adminAdjustable: true,
    adminRemovable: true,
    deleted: false,
  };
}

/** Expected properties for deleting a custom field. */
export type CustomFieldToDelete = TaskFieldParts | CraftRecordFieldParts;

// #region SECTION: Specific types, for each possible 'fieldType'.
export type BooleanCustomField = CustomFieldCommon & {
  fieldType: "bool";
  defaultValue: boolean;
};

export type NumberCustomField = CustomFieldCommon & {
  fieldType: "number";
  defaultValue: number | null;
  min: number | null;
  max: number | null;
};

export type StringCustomField = CustomFieldCommon & {
  fieldType: "string";
  defaultValue: string | null;
  min: number | null;
  max: number | null;
};

export type StringTextareaCustomField = CustomFieldCommon & {
  fieldType: "string-textarea";
  defaultValue: string | null;
  min: number | null;
  max: number | null;
};

export type TimestampCustomField = CustomFieldCommon & {
  fieldType: "timestamp";
  defaultValue: null;
};

export type SelectionCustomField = CustomFieldCommon & {
  fieldType: "selection";
  defaultValue: string | null;
  selectionOptions: { [p: string]: string };
};

export type UidCustomField = CustomFieldCommon & {
  fieldType: "uid";
  defaultValue: string | null;
  min: number | null;
  max: number | null;
};

export type MultipleUidCustomField = CustomFieldCommon & {
  fieldType: "multiple-uid";
  defaultValue: string[] | null;
};

export type CurrencyCustomField = CustomFieldCommon & {
  fieldType: "currency";
  defaultValue: number | null;
};

export type StringArrayCustomField = CustomFieldCommon & {
  fieldType: "string-array";
  defaultValue: string[] | null;
  min: number | null;
  max: number | null;
};

export type HoursMinutesCustomField = CustomFieldCommon & {
  fieldType: "hours-minutes";
  defaultValue: number | null;
  min: number | null;
  max: number | null;
};
// #endregion Specific types, for each possible 'fieldType'.

/**
 * Separate interface for properties from firestore.
 */
export interface FieldsFromFirestore {
  id: string;
  refPath: string;
  exists: boolean;
}

// This type is a composition of all of the types (excluding
// FieldsFromFirestore).
export type CustomFieldDocData =
  | BooleanCustomField
  | NumberCustomField
  | StringCustomField
  | StringTextareaCustomField
  | TimestampCustomField
  | SelectionCustomField
  | UidCustomField
  | MultipleUidCustomField
  | CurrencyCustomField
  | StringArrayCustomField
  | HoursMinutesCustomField;

// This intersection type denotes a custom field that has already been
// saved to the database.
export type ExistingCustomField = FieldsFromFirestore & CustomFieldDocData;

// #region SECTION: ZOD SCHEMAS

// Used for throwing specific zod errors.
function taskFieldHasCraftTypeAndTaskType(value: any): boolean {
  if (value.craftRecordOrTask === "task") {
    return value.craftType != null && value.taskType != null;
  } else {
    return true;
  }
}
const messageTaskTypeMissing = {
  message: "custom fields for tasks must contain a taskType and a craftType",
};
// Used for throwing specific zod errors.
function craftRecordFieldHasCraftType(value: any): boolean {
  if (value.craftRecordOrTask === "craftRecord") {
    return value.craftType != null;
  } else {
    return true;
  }
}
const messageCraftTypeMissing = {
  message: "custom fields for craftRecords must contain a craftType",
};

// Schemas for validating a new custom field.
const commonSchema = z.object({
  /** @deprecated server field is no longer in use */
  server: z.boolean().optional(),
  craftRecordOrTask: z.enum(["craftRecord", "task"]),
  craftType: z.nativeEnum(OCraftTypes),
  taskType: z.nativeEnum(OTaskTypes).optional(),
  title: z.string({ required_error: "Title is required" }).min(1).max(200),
  editable: z.boolean(),
  required: zFallback(
    z.boolean(),
    false,
    "customField commonSchema: 'required'",
  ),
  onTaskStatus: z.nativeEnum(OTaskStatus).array().optional(),
  hideOnCraftRecordCreation: z.boolean().optional(),
  deleted: z.boolean(),

  // for 'smartFields'
  adminAdjustable: zFallback(z.boolean(), true),
  adminRemovable: zFallback(z.boolean(), true),
});

const booleanSchema = commonSchema
  .extend({
    fieldType: z.literal("bool"),
    defaultValue: z.boolean(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const numberSchema = commonSchema
  .extend({
    fieldType: z.literal("number"),
    defaultValue: z.number().nullable(),
    min: z.number().nullable(),
    max: z.number().nullable(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const stringSchema = commonSchema
  .extend({
    fieldType: z.literal("string"),
    defaultValue: z.string().nullable(),
    min: z.number().nullable(),
    max: z.number().nullable(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const stringTextareaSchema = commonSchema
  .extend({
    fieldType: z.literal("string-textarea"),
    defaultValue: z.string().nullable(),
    min: z.number().nullable(),
    max: z.number().nullable(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const timestampSchema = commonSchema
  .extend({
    fieldType: z.literal("timestamp"),
    defaultValue: z.null(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const selectionSchema = commonSchema
  .extend({
    fieldType: z.literal("selection"),
    defaultValue: z.string().nullable(),
    selectionOptions: z.record(z.string()),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const uidSchema = commonSchema
  .extend({
    fieldType: z.literal("uid"),
    defaultValue: z.string().nullable(),
    min: z.number().nullable(),
    max: z.number().nullable(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const multipleUidSchema = commonSchema
  .extend({
    fieldType: z.literal("multiple-uid"),
    defaultValue: z.string().array().nullable(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const currencySchema = commonSchema
  .extend({
    fieldType: z.literal("currency"),
    defaultValue: z.number().nullable(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const stringArraySchema = commonSchema
  .extend({
    fieldType: z.literal("string-array"),
    defaultValue: z.string().array().nullable(),
    min: z.number().nullable(),
    max: z.number().nullable(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

const hoursMinutesSchema = commonSchema
  .extend({
    fieldType: z.literal("hours-minutes"),
    defaultValue: z.number().nullable(),
    min: z.number().nullable(),
    max: z.number().nullable(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);

// Schema for validating custom field before deletion.
const deleteCustomFieldSchema = z
  .object({
    craftRecordOrTask: z.enum(["craftRecord", "task"]),
    craftType: z.nativeEnum(OCraftTypes),
    taskType: z.nativeEnum(OTaskTypes).optional(),
  })
  .refine(craftRecordFieldHasCraftType, messageCraftTypeMissing)
  .refine(taskFieldHasCraftTypeAndTaskType, messageTaskTypeMissing);
// #endregion ZOD SCHEMAS

// #region SECTION: FUNCTIONS
export const CustomFieldManager = {
  createFromFirestoreSnapshot: createExistingCustomFieldFromFirestoreSnapshot,
  fromSiteCustomization: createExistingCustomFieldFromSiteCustomization,
  parse: validateCustomField,
  parseToDelete: validateCustomFieldToDelete,
};

// Convert a dynamic detail from the site key customizations into a CustomField type
// so it can be more readily used throughout the application.
// TODO: could use some cleanup.
function createExistingCustomFieldFromSiteCustomization(args: {
  craftRecordString: string;
  taskTypeString?: string;
  fieldID: string;
  fieldData: unknown;
}): ExistingCustomField {
  if (!guardIsPlainObject(args.fieldData)) {
    throw new Error("customization data was not an object");
  }

  const fieldType = args.fieldData.type;
  if (!isCustomFieldType(fieldType)) {
    throw new Error(`unknown custom field type ${fieldType}`);
  }

  // Determine craft and task type codes.
  const craftType = getCraftTypeFromRecordString(args.craftRecordString);
  if (craftType == null)
    throw new Error("Unable to determine craft type from record string");

  let taskType: number | undefined;
  if (args.taskTypeString === undefined) {
    taskType = undefined;
  } else {
    taskType = getTaskTypeFromString(args.taskTypeString);
    if (taskType === undefined) {
      throw new Error("Unable to determine task type from task type string");
    }
  }

  let min: number | null | undefined;
  let max: number | null | undefined;

  if (fieldsThatHaveMinMax.includes(fieldType)) {
    const minValue = args.fieldData.minValue;
    const maxValue = args.fieldData.maxValue;

    const minLength = args.fieldData.minLength;
    const maxLength = args.fieldData.maxLength;

    if (typeof minValue === "number") {
      min = minValue;
    } else if (typeof minLength === "number") {
      min = minLength;
    } else {
      min = null;
    }
    if (typeof maxValue === "number") {
      max = maxValue;
    } else if (typeof maxLength === "number") {
      max = maxLength;
    } else {
      max = null;
    }
  }

  const newObject: Record<string, any> = {
    // Fields we checked ahead of time.
    fieldType: fieldType,
    // Weird fields.
    craftRecordOrTask: taskType != null ? "task" : "craftRecord",
    craftType: craftType,
    selectionOptions: args.fieldData.selectionOptions,

    // Normal fields.
    deleted: false,
    defaultValue: args.fieldData.defaultValue,
    title: args.fieldData.title,
    editable: args.fieldData.editable,
    required: args.fieldData.required,
    onTaskStatus: args.fieldData.onTaskStatus,
    hideOnCraftRecordCreation: args.fieldData.hideOnCraftRecordCreation,

    // 'admin' fields
    adminAdjustable: args.fieldData.adminAdjustable,
    adminRemovable: args.fieldData.adminRemovable,
  };
  // Attach optional properties
  if (taskType != null) {
    newObject["taskType"] = taskType;
  }
  if (min !== undefined) {
    newObject["min"] = min;
  }
  if (max !== undefined) {
    newObject["max"] = max;
  }

  // Drop keys for any properties that have values of undefined.
  const droppedObject = dropUndefined(newObject);
  const validData = CustomFieldManager.parse(droppedObject);
  return {
    id: args.fieldID,
    refPath: "NotApplicable",
    exists: true,
    ...validData,
  };
}

function createExistingCustomFieldFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingCustomField {
  const snapshotData = snapshot.data();
  if (snapshot.exists() === false) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  return {
    id: snapshot.id,
    exists: snapshot.exists(),
    refPath: snapshot.ref.path,
    ...validateCustomField(snapshotData),
  };
}

/**
 * Throws an error if it doesn't pass validation
 */
export function validateCustomField(customField: unknown): CustomFieldDocData {
  if (!guardIsPlainObject(customField)) {
    throw new Error(`customField not an object: ${customField}`);
  }
  if (!isCustomFieldType(customField.fieldType)) {
    throw new Error(
      `customField.fieldType is not recognized: ${customField.fieldType}`,
    );
  }

  switch (customField.fieldType) {
    case "bool": {
      const result = booleanSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "number": {
      const result = numberSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "string": {
      const result = stringSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "string-textarea": {
      const result = stringTextareaSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "timestamp": {
      const result = timestampSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "selection": {
      const result = selectionSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "uid": {
      const result = uidSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "multiple-uid": {
      const result = multipleUidSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "currency": {
      const result = currencySchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "string-array": {
      const result = stringArraySchema.parse(customField);
      return result as CustomFieldDocData;
    }
    case "hours-minutes": {
      const result = hoursMinutesSchema.parse(customField);
      return result as CustomFieldDocData;
    }
    // This is how TypeScript keeps future you safe. 🤗
    default: {
      const _exhaustiveCheck: never = customField.fieldType;
      return _exhaustiveCheck;
    }
  }
}

/**
 * Throws an error if it doesn't pass validation
 */
function validateCustomFieldToDelete(
  customFieldParts: unknown,
): CustomFieldToDelete {
  const result = deleteCustomFieldSchema.parse(customFieldParts);
  return result as CustomFieldToDelete;
}
// #endregion FUNCTIONS
