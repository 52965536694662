//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmUpdatingCL: () => Promise<void>;
}

export default function ConfirmUpdateCustomerLocation(props: Props) {
  return (
    <BaseModal
      open={props.isOpen}
      closeModal={props.onClose}
      allowOverflowY={true}
      title={
        <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
          <h1 className="inline-flex items-center text-xl font-semibold ">
            {strings.CONFIRM_UPDATE_CL}
          </h1>
          <button type="button" onClick={() => props.onClose()}>
            <XMarkIcon
              aria-label="close new requirement form"
              className="h-6 text-white"
            />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase xs:w-52"
            onClick={props.onClose}
          >
            {strings.buttons.NO}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            onClick={() => props.handleConfirmUpdatingCL()}
            className="w-full justify-center uppercase xs:w-52"
          >
            {strings.buttons.YES}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
