// Libs
import { DateTime } from "luxon";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

// Local
import {
  ReportConfig,
  reportConfigSchema,
  reportConfigSchemaFallbacks,
} from "./report-config";
import {
  ReportSpec,
  reportSpecSchema,
  reportSpecSchemaWithFallbacks,
} from "./report-spec";
import {
  dropUndefined,
  guardIsPlainObject,
  isValidISO8601,
  msgInvalidISO8601,
  zFallback,
} from "../../utils";
import { NotFoundError } from "../../error-classes";

// NOTE: `siteKeys/{siteKeyID}/reportData/{reportDataID}`

/**
 * ReportData is an object that contains the data generated for a report
 * from a specific configuration.
 */
export interface ReportData {
  id?: string;
  downloadURL?: string;
  type: string;
  user: string;
  timestampCreated: string;
  data: { [k: string]: unknown };
  reportSpec: ReportSpec;
  reportConfig: ReportConfig;
}

/** Utilities for interacting with ReportData objects. */
export const ReportDataManager = {
  /** Used when writing to the database. @throws if `value` is not an object. */
  parse: validateReportData,
  /** Used when reading from the database. @throws if `value` is not an object. */
  parseWithFallbacks: validateReportDataWithFallbacks,
  /**
   * Convert document snapshot into a ReportData object. Validates data using
   * the schema with fallbacks. @throws NotFoundError if document does not exist
   */
  fromSnapshot: createFromSnapshot,
  /** Does NOT validate data. Drops undefined, drops `id` property. */
  convertForFirestore: convertForFirestore,
};

// #region Functions
function validateReportData(value: unknown): ReportData {
  if (!guardIsPlainObject(value)) {
    throw new Error(`reportData not an object: ${value}`);
  }
  return reportDataSchema.parse(value);
}

function validateReportDataWithFallbacks(value: unknown): ReportData {
  if (!guardIsPlainObject(value)) {
    throw new Error(`reportData not an object: ${value}`);
  }
  return reportDataSchemaFallbacks.parse(value);
}

function createFromSnapshot(snapshot: DocumentSnapshot): ReportData {
  if (!snapshot.exists) throw new NotFoundError(snapshot.ref.path);
  return {
    id: snapshot.id,
    ...validateReportDataWithFallbacks(snapshot.data()),
  };
}

function convertForFirestore(data: ReportData): DocumentData {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = data;
  return dropUndefined(rest);
}
// #endregion Functions

// #region Schemas
/** For validating user input; for writing to the DB */
const reportDataSchema = z.object({
  id: z.string().min(1).max(200).optional(),
  downloadURL: z.string().min(1).max(1000).optional(),
  type: z.string().min(1).max(200),
  user: z.string().min(1).max(200),
  timestampCreated: z.string().refine(isValidISO8601, msgInvalidISO8601),
  data: z.record(z.any()),
  reportSpec: reportSpecSchema,
  reportConfig: reportConfigSchema,
});

/** For reading from the database */
const reportDataSchemaFallbacks = z.object({
  id: zFallback(
    z.string().min(1).max(200).optional(),
    undefined,
    "reportData.id",
  ),
  downloadURL: zFallback(
    z.string().min(1).max(1000).optional(),
    undefined,
    "reportData.downloadURL",
  ),
  type: z.string().min(1).max(200),
  user: zFallback(z.string().min(1).max(200), "Unknown", "reportData.user"),
  timestampCreated: zFallback(
    z.string().refine(isValidISO8601, msgInvalidISO8601),
    DateTime.now().toUTC().toISO(), // fallback to current time
    "reportData.timestampCreated",
  ),
  data: zFallback(z.record(z.any()), {}, "reportData.data"),
  reportSpec: reportSpecSchemaWithFallbacks,
  reportConfig: reportConfigSchemaFallbacks,
});
// #endregion Schemas
