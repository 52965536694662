//Libs
import { useDebugValue, useMemo, useState } from "react";

//Local
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import FilterTabs, { Tabs } from "../../components/FilterTabs";

export function useFilterTabs(
  listOfAllUsers: ExistingSiteKeyUserDoc[],
  usersPermissions: Record<string, ExistingSiteKeyUserPermissions>,
) {
  useDebugValue("useFilterTabs");

  const [selectedTab, setSelectedTab] = useState(Tabs.APPROVED);

  const notSystemUserList = listOfAllUsers.filter(
    (user) => user.systemUser !== true,
  );

  /** Users with the inactive field set to true */
  const inactiveList = useMemo(() => {
    return notSystemUserList.filter((user) => {
      if (typeof user.id === "string") {
        return usersPermissions[user.id]?.inactive === true;
      } else {
        return false;
      }
    });
  }, [notSystemUserList, usersPermissions]);

  /** Users with approved set to true */
  const approvedUsersList = useMemo(() => {
    return notSystemUserList.filter((user) => {
      if (typeof user.id === "string") {
        return usersPermissions[user.id]?.approved === true;
      } else {
        return false;
      }
    });
  }, [notSystemUserList, usersPermissions]);

  /** Users with approved and inactive set to false*/
  const needsApprovalUsersList = useMemo(() => {
    return notSystemUserList.filter((user) => {
      if (typeof user.id === "string") {
        return (
          usersPermissions[user.id]?.approved !== true &&
          usersPermissions[user.id]?.inactive !== true
        );
      } else {
        return false;
      }
    });
  }, [notSystemUserList, usersPermissions]);

  const filterMap: Record<Tabs, ExistingSiteKeyUserDoc[]> = {
    All: notSystemUserList ? notSystemUserList : [],
    Approved: approvedUsersList,
    Inactive: inactiveList,
    "Needs Approval": needsApprovalUsersList,
  };

  const filteredListOfUsers = filterMap[selectedTab];

  const filterTabs = (
    <FilterTabs
      activeTab={selectedTab}
      handleFilterSelection={(filter) => setSelectedTab(filter)}
      filterMap={filterMap}
    />
  );

  return {
    filteredListOfUsers,
    filterTabs,
  };
}
