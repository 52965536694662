import React from "react";
import { twMerge } from "tailwind-merge";

const alertTypes = ["success", "error", "info", "warning"] as const;
type AlertType = (typeof alertTypes)[number];

/**
 * Classes for the different parts of the alert.
 */
type AlertTheme = {
  actionButton: string;
};

const alertTypeClasses: Record<AlertType, AlertTheme> = {
  success: {
    actionButton:
      "rounded-md bg-green-50 px-2 py-1.5 text-sm font-semibold text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50",
  },
  error: {
    actionButton:
      "rounded-md bg-red-50 px-2 py-1.5 text-sm font-semibold text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50",
  },
  info: {
    actionButton:
      "rounded-md bg-blue-50 px-2 py-1.5 text-sm font-semibold text-blue-800 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50",
  },
  warning: {
    actionButton:
      "rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-semibold text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50",
  },
};

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  variant: AlertType;
} & React.ComponentPropsWithRef<"button">;

/**
 * This component uses tailwind-merge to resolve classes passed in as props
 * without replace all of the default classes.
 *
 * See the [tailwind-merge docs](https://github.com/dcastil/tailwind-merge) for more information.
 */
const AlertV2Button = React.forwardRef<HTMLButtonElement, Props>(
  function AlertButton(
    { children, onClick, variant, className, ...rest },
    ref,
  ) {
    const theme = alertTypeClasses[variant];
    return (
      <button
        type="button"
        ref={ref}
        onClick={onClick}
        className={twMerge(`${theme.actionButton}`, className)}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

export default AlertV2Button;
