import { guardIsPlainObject } from ".";
/**
 * Remove undefined properties/members from the class instance.
 */
export function dropUndefined<T extends Record<string, any>>(
  typedObject: T,
): T {
  const newObject: T = { ...typedObject };
  Object.keys(newObject).forEach((key: keyof T) => {
    const innerValue = newObject[key];
    if (innerValue === undefined) {
      delete newObject[key];
    } else if (guardIsPlainObject(newObject[key])) {
      // Recursive.
      newObject[key] = dropUndefined(innerValue);
    }
  });
  return newObject;
}
