import React from "react";
import BaseInputNumber, {
  Props as BaseInputNumberProps,
} from "./BaseInputNumber";

const BaseInputNumberInteger = React.forwardRef<
  HTMLInputElement,
  BaseInputNumberProps
>((props, ref) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const parsedValue = parseInt(value, 10);
    const isNumeric = !isNaN(parsedValue);

    if (isNumeric) {
      event.target.value = parsedValue.toString();
    }

    props.onChange && props.onChange(event);
  };

  return <BaseInputNumber ref={ref} {...props} onChange={handleChange} />;
});

export default BaseInputNumberInteger;
