// Libs
import { PencilIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useEffect } from "react";
import {
  useForm,
  Controller,
  UseFormHandleSubmit,
  UseFormReset,
  FieldErrors,
  SubmitHandler,
  Control,
  UseFormSetValue,
} from "react-hook-form";

// Local
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseInputSelect from "../../components/BaseInputSelect";
import BaseInputText from "../../components/BaseInputText";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import PermissionsSwitches from "../../components/admin/PermissionsSwitches";
import {
  AddOrEditUserFormState,
  AddOrEditUserSchema,
} from "../../models/add-or-edit-user";
import StyledMessage from "../../components/StyledMessage";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ADMIN_ADD_USER_URL, ADMIN_USER_MANAGEMENT_URL } from "../../urls";
import * as strings from "../../strings";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import DisplayNewUserPassphraseDialog from "../../components/admin/DisplayNewUserPassphraseDialog";

interface Props {
  goToListAllPage: () => void;
  companyList: ExistingSiteKeyCompany[];
  siteKeyLocationList: ExistingSiteKeyLocation[];
  onSaveUser: (formValues: AddOrEditUserFormState) => Promise<string | null>;
  isComplianceEnabled: boolean | null;
  onAppDownload: (platform: string) => Promise<void>;
  downloadLink: string | null;
  setDownloadLink: (link: string | null) => void;
  isDownloadLinkError: boolean;
  isAndroidSubmitting: boolean;
  isIosSubmitting: boolean;
  children: {
    displayResponseMessage: React.ReactNode;
  };
}

export default function AddUserPage(props: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passphrase, setPassphrase] = useState<string | null>(null);
  const [isPassphraseDialogOpen, setPassphraseDialogOpen] = useState(false);

  useEffect(() => {
    if (typeof passphrase === "string") {
      setPassphraseDialogOpen(true);
    }
  }, [passphrase]);

  const closePassphraseDialog = () => {
    setPassphraseDialogOpen(false);
    props.setDownloadLink(null);
    setPassphrase(null);
  };

  // For breadcrumbs
  const home = {
    name: "List of All Users",
    href: ADMIN_USER_MANAGEMENT_URL,
    current: false,
  };
  const pages = [{ name: "Add User", href: ADMIN_ADD_USER_URL, current: true }];

  const passphraseDialog = (
    <DisplayNewUserPassphraseDialog
      passphrase={passphrase}
      isDialogOpen={isPassphraseDialogOpen}
      closeDialog={closePassphraseDialog}
      onAppDownload={props.onAppDownload}
      downloadLink={props.downloadLink}
      isDownloadLinkError={props.isDownloadLinkError}
      isAndroidSubmitting={props.isAndroidSubmitting}
      isIosSubmitting={props.isIosSubmitting}
    />
  );

  // react-hook-form stuffs
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<AddOrEditUserFormState>({
    defaultValues: {
      displayName: "",
      email: "",
      jobTitle: "",
      department: "",
      phone: "",
      companyName: "",
      defaultLocationID: null,

      // PERMISSIONS
      approved: true,
      inactive: false,

      getsNewTaskNotifications: false,
      canEditContractorDetails: false,
      canCreateTasks: false,
      canUpdateTasks: false,
      canDeleteTasks: false,
      canCreateCraftRecords: false,
      canUpdateCraftRecords: false,
      canDeleteCraftRecords: false,
      isPlantPersonnel: false,
      isSiteAdmin: false,

      newTaskCreated: false,
      allTaskStatusChanged: false,

      // Compliance Permissions
      complianceRequirements_create: false,
      complianceRequirements_read: false,
      complianceRequirements_delete: false,
      complianceResponses_create: false,
      complianceResponses_read: false,
      complianceResponses_readAll: false,
      complianceResponses_delete: false,
      complianceResponses_review: false,
      tasks_changeDate: false,
    },
    resolver: zodResolver(AddOrEditUserSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<AddOrEditUserFormState> = async (
    formValues,
  ) => {
    // For the save button
    setIsSubmitting(true);
    try {
      // Container will call DB
      const newPassphrase = await props.onSaveUser(formValues);
      setPassphrase(newPassphrase);

      // Reset form fields
      reset();
    } finally {
      // Always want to 'reset' the loading state, whether adding the user succeeded
      // or failed.
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mx-auto w-full items-center px-2 pb-10 md:px-14 lg:grid lg:max-w-screen-lg lg:grid-cols-2 lg:items-start lg:gap-x-28 lg:px-2">
      <Breadcrumbs home={home} pages={pages} />
      <TheForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        errors={errors}
        handleCancel={props.goToListAllPage}
        control={control}
        reset={reset}
        companyList={props.companyList}
        siteKeyLocationList={props.siteKeyLocationList}
        isSubmitting={isSubmitting}
        setValue={setValue}
        isComplianceEnabled={props.isComplianceEnabled}
      >
        {{
          displayResponseMessage: props.children.displayResponseMessage,
        }}
      </TheForm>
      {passphraseDialog}
    </div>
  );
}

// #region SECTION: The Form
interface FormProps {
  handleSubmit: UseFormHandleSubmit<AddOrEditUserFormState>;
  onSubmit: SubmitHandler<AddOrEditUserFormState>;
  errors: FieldErrors;
  handleCancel: () => void;
  control: Control<AddOrEditUserFormState, any>;
  reset: UseFormReset<AddOrEditUserFormState>;
  companyList: ExistingSiteKeyCompany[];
  siteKeyLocationList: ExistingSiteKeyLocation[];
  isSubmitting: boolean;
  setValue: UseFormSetValue<AddOrEditUserFormState>;
  isComplianceEnabled: boolean | null;
  children: {
    displayResponseMessage: React.ReactNode;
  };
}
// Extracted 'TheForm' because: https://stackoverflow.com/a/67002581
const TheForm = (props: FormProps) => {
  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit(props.onSubmit)}
      className="lg:contents"
    >
      <div className="flex flex-col-reverse lg:contents lg:flex-col">
        {/* ACTION BUTTONS */}
        <div className="mb-7 mt-2 text-right lg:mb-0 lg:mt-3.5">
          <BaseButtonPrimary
            className="w-full xs:w-52"
            type="submit"
            disabled={props.isSubmitting}
            isBusy={props.isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
          >
            <PencilIcon aria-label="save user" className="mr-4 h-6" />
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>

        {/* Message will display once we get a response from the backend ƒn. */}
        <div className="flex h-16 items-center text-center lg:col-span-2">
          {props.children.displayResponseMessage}
        </div>
      </div>
      {/* div wrapping each input 'area' so the error message stays with the
         associated input element, without having to mess with margins */}
      <div className="mt-4 grid w-full grid-cols-1 gap-8">
        {/* DISPLAY NAME */}
        <div>
          <Controller
            name="displayName"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="displayName"
                text="Display Name"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {props.errors.displayName?.message &&
            typeof props.errors.displayName.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.displayName.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* EMAIL */}
        <div>
          <Controller
            name="email"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="email"
                text="Email"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {props.errors.email?.message &&
            typeof props.errors.email.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.email.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* JOB TITLE */}
        <div>
          <Controller
            name="jobTitle"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="jobTitle"
                text="Job Title"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {props.errors.jobTitle?.message &&
            typeof props.errors.jobTitle.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.jobTitle.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* DEPARTMENT */}
        <div>
          <Controller
            name="department"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="department"
                text="Department"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {props.errors.department?.message &&
            typeof props.errors.department.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.department.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* PHONE */}
        <div>
          <Controller
            name="phone"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                inputName="phone"
                text="Phone"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {props.errors.phone?.message &&
            typeof props.errors.phone.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.phone.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* COMPANY */}
        <div>
          <Controller
            name="companyName"
            control={props.control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="company"
                text="Company"
                admin={true}
                required={true}
                id="company"
                {...field}
              >
                {/* The empty string for value tricks validation into failing */}
                <option value="" disabled>
                  Select a company
                </option>

                {props.companyList.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </BaseInputSelect>
            )}
          />
          {props.errors.companyName?.message &&
            typeof props.errors.companyName.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.companyName.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* DEFAULT LOCATION */}
        <div>
          <Controller
            name="defaultLocationID"
            control={props.control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="defaultLocationID"
                text="Default Location"
                admin={true}
                id="defaultLocationID"
                {...field}
                value={
                  field.value === null
                    ? ""
                    : field.value /* because value may be null */
                }
                onChange={(event) => {
                  // Store empty values as null instead of undefined
                  field.onChange(
                    event.target.value === "" ? null : event.target.value,
                  );
                }}
              >
                <option value="">No default location</option>
                {props.siteKeyLocationList.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.title}
                  </option>
                ))}
              </BaseInputSelect>
            )}
          />
          {props.errors.defaultLocationID?.message &&
            typeof props.errors.defaultLocationID.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.defaultLocationID.message }}
                </StyledMessage>
              </div>
            )}
        </div>
      </div>

      {/* PERMISSIONS SWITCHES */}
      <PermissionsSwitches
        control={props.control}
        errors={props.errors}
        setValue={props.setValue}
        isComplianceEnabled={props.isComplianceEnabled}
      />
    </form>
  );
};
// #endregion
