import React from "react";

type Ref = HTMLInputElement;

type Props = {
  id: string;
  label: string;
  name: string;
} & React.ComponentPropsWithRef<"input">;

const BaseRadioSelect = React.forwardRef<Ref, Props>(
  ({ id, label, name, ...props }, ref): JSX.Element => {
    return (
      <div className="flex items-center">
        <input
          ref={ref}
          {...props}
          id={id}
          name={name}
          type="radio"
          className="h-4 w-4 accent-primary focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
        />
        <label
          htmlFor={id}
          className="ml-3 block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      </div>
    );
  },
);
export default BaseRadioSelect;
