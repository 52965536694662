//Libs
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

//Local
import OverflowMenu from "../OverflowMenu";
import OverflowMenuItemCustomFieldCard from "./OverflowMenuItemCustomFieldCard";
import {
  ExistingCustomField,
  getReadableCustomFieldType,
} from "../../models/custom-field";
import {
  getReadableTaskStatus,
  TaskStatusValues,
} from "../../models/task-status";

interface Props {
  field: ExistingCustomField;
  handleDeleteCustomField: (field: ExistingCustomField) => void;
}

export default function CustomFieldCard({
  field,
  handleDeleteCustomField,
}: Props) {
  return (
    <div
      className="flex w-full flex-col justify-between rounded-2xl border border-gray-200 bg-white p-4 focus:outline-none focus:ring focus:ring-primary sm:max-w-sm"
      id={
        field.craftRecordOrTask === "craftRecord"
          ? field.id
          : `${field.taskType}-${field.id}`
      }
      tabIndex={0}
    >
      <div>
        <div className="flex items-center justify-between">
          {/* "hyphenate" is our class (tailwind-input.css) */}
          {/* must specify lang attribute when using it. `en` does not work. */}
          <h3
            lang="de"
            className="hyphenate text-sm font-bold uppercase leading-4 text-primary"
          >
            {field.title}
          </h3>
          <OverflowMenu
            icon={
              <EllipsisVerticalIcon
                aria-label="open custom field details"
                className="ml-2 h-5 rounded border border-gray-200 transition hover:border-primary hover:bg-primary hover:text-white"
              />
            }
          >
            <OverflowMenuItemCustomFieldCard
              field={field}
              handleDeleteCustomField={handleDeleteCustomField}
            />
          </OverflowMenu>
        </div>
        <div
          className={`${
            field.craftRecordOrTask === "task" ? "mb-4" : ""
          } text-xs font-bold uppercase`}
        >
          <span className="text-gray-400">
            {getReadableCustomFieldType(field.fieldType)}
          </span>
          {field.required && (
            <span className="ml-3 text-red-400">Required</span>
          )}
        </div>
      </div>
      {field.craftRecordOrTask === "task" && (
        <div className="flex flex-col text-sm font-medium leading-5 text-gray-900">
          Shown when task status changes to:
          <DisplayTaskStatus statusList={field.onTaskStatus} />
        </div>
      )}

      {field.craftRecordOrTask === "craftRecord" &&
        field.hideOnCraftRecordCreation === true && (
          <div className="mt-2 text-sm font-medium leading-4 text-gray-900">
            Hidden on craft record creation
          </div>
        )}
    </div>
  );
}

function DisplayTaskStatus(args: {
  statusList: TaskStatusValues[] | undefined;
}): JSX.Element {
  let stringOutput;
  if (!args.statusList || args.statusList.length === 0) {
    stringOutput = "N/A";
  } else {
    stringOutput = args.statusList
      .map((status) => getReadableTaskStatus(status))
      .join(", ");
  }
  return <span className="text-base text-gray-500">{stringOutput}</span>;
}
