// it hurts my soul just a little, but...
/* eslint-disable no-nested-ternary */
//Libs
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import PaymentIcon from "@mui/icons-material/Payment";
import EmailIcon from "@mui/icons-material/Email";
import AddCardIcon from "@mui/icons-material/AddCard";
import { SetStateAction, useMemo, useState } from "react";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import BaseButtonSecondary from "../BaseButtonSecondary";
import {
  ExistingStiltInvoice,
  StiltInvoiceStatus,
  StiltInvoiceStatusValues,
} from "../../models/invoice";
import { CardOnFile, ExistingCustomer } from "../../models/customer";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { InputNumber } from "../FormInputs";
import currencyFormatter from "../../assets/js/currencyFormatter";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { ErrorMessage } from "../ErrorMessage";
import MultipleEmailSelector2 from "../MultipleEmailSelector2";
import StyledMessage from "../StyledMessage";
import BaseInputTextArea from "../BaseInputTextArea";
import BaseInputSelect from "../BaseInputSelect";
import BaseInputText from "../BaseInputText";
import {
  NewManualPayment,
  RecordManualPaymentFormSchema,
  RecordManualPaymentFormState,
} from "./RecordManualPaymentDialog";
import BaseInputNumber from "../BaseInputNumber";
import {
  cardTypes,
  getReadablePaymentMethod,
  PaymentMethodsValues,
} from "../../models/stilt-payment";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  userIsSiteAdmin: boolean;
  customer: ExistingCustomer;
  invoiceID: string;
  invoiceStatus: StiltInvoiceStatusValues;
  invoiceAmount: ExistingStiltInvoice["amountDue"];
  defaultIncludePhotos: boolean;
  emailList: string[];
  invoiceSentToCustomer: ExistingStiltInvoice["timestampSentToCustomer"];
  paymentMethods: PaymentMethodsValues[];
  /* FUNCTIONS */
  closeDialog: () => void;
  goToPaymentPage: () => Promise<void>;
  payWithCardOnFile: (args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }) => Promise<void>;
  sendEmail: (
    selectedEmails: string[],
    shouldIncludePhotos: boolean,
  ) => Promise<void>;
  applyManualPayment: (formValues: NewManualPayment) => Promise<void>;

  children: {
    DatePicker: React.ReactNode;
  };
}

const busyOptions = ["hpp", "card_on_file", "email", "manual"] as const;
type BusyOptions = (typeof busyOptions)[number];

const panelOptions = ["card_on_file", "email", "manual"] as const;
type PanelOptions = (typeof panelOptions)[number];

interface FormStateCardOnFile {
  token: string;
  amount: number | null;
}

export default function HandlePaymentDialog(props: Props) {
  // if user is not site admin, gray out "pay with card". if there are no
  // cards on file, gray out "pay with card". when "pay with card" is grayed
  // out, default to displaying "email" as the selected panel.
  const customerHasCardsOnFile = (props.customer.cardsOnFile?.length ?? 0) > 0;
  const disableCardOnFileButton =
    !props.userIsSiteAdmin ||
    !customerHasCardsOnFile ||
    props.invoiceAmount <= 0;
  const disablePayNowButton = props.invoiceAmount <= 0;
  const disableAllButtons = props.invoiceStatus === StiltInvoiceStatus.CANCELED;

  // selectedPanel is null if invoice has been canceled
  const [selectedPanel, setSelectedPanel] = useState<PanelOptions | null>(
    getInitialPanel(disableCardOnFileButton, props.invoiceStatus),
  );

  const [isBusy, setIsBusy] = useState<BusyOptions | null>(null);

  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const [formStateCardOnFile, setFormStateCardOnFile] =
    useState<FormStateCardOnFile>({
      token: getInitiallySelectedCard(props.customer.cardsOnFile),
      amount: props.invoiceAmount,
    });

  function closeDialog() {
    setIsBusy(null);
    setFormStateCardOnFile({ token: "", amount: 0 });
    setSelectedPanel(disableCardOnFileButton ? "email" : "card_on_file");

    props.closeDialog();
  }

  async function submitCardOnFile() {
    if (formStateCardOnFile.amount === null) {
      addToastMessage({
        id: createToastMessageID(),
        message: "Amount is required",
        type: "error",
        dialog: true,
      });
      return;
    }
    if (formStateCardOnFile.amount <= 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: "Payment amount must be greater than zero",
        type: "error",
        dialog: true,
      });
      return;
    }
    if (formStateCardOnFile.amount > props.invoiceAmount) {
      addToastMessage({
        id: createToastMessageID(),
        message: "Payment amount cannot exceed the amount due of the invoice",
        type: "error",
        dialog: true,
      });
      return;
    }

    if (formStateCardOnFile.token === "") return;
    const found = props.customer.cardsOnFile?.find(
      (card) => card.token === formStateCardOnFile.token,
    );
    if (!found) return;

    setIsBusy("card_on_file");
    try {
      await props.payWithCardOnFile({
        invoiceID: props.invoiceID,
        expiry: found.expiry,
        lastFour: found.lastFour,
        amount: formStateCardOnFile.amount,
      });
      closeDialog();
      addToastMessage({
        id: createToastMessageID(),
        message: strings.PAY_WITH_CARD_ON_FILE_SUCCESS,
        type: "success",
        dialog: false,
      });
    } catch (e) {
      logger.error(e);
      setIsBusy(null);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.PAY_WITH_CARD_ON_FILE_ERROR,
        type: "error",
        dialog: true,
      });
    }
  }

  async function submitEmail(args: FormStateEmail): Promise<void> {
    // try/catches and toast notifications are handled higher up
    setIsBusy("email");
    await props.sendEmail(args.selectedEmails, args.shouldIncludePhotos);
    closeDialog();
  }

  async function submitManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    // try/catches and toast notifications are handled higher up
    setIsBusy("manual");
    await props.applyManualPayment(formValues);
    closeDialog();
  }

  const paymentHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.PAYMENT}
      </h1>
      <button type="button" onClick={closeDialog}>
        <XMarkIcon
          aria-label="close handle payment dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={closeDialog}
      open={props.isDialogOpen}
      title={paymentHeader}
      parentDivStyles="text-left max-w-lg lg:max-w-5xl"
      allowOverflowY={true}
    >
      <div className="mx-8 mb-6 lg:grid lg:grid-cols-10 lg:place-items-start">
        <div className="lg:col-span-4">
          {props.invoiceStatus === StiltInvoiceStatus.CANCELED ? (
            <p className="text-xl">Canceled Invoice</p>
          ) : (
            <p className="text-xl font-light lg:mr-4 lg:shrink-0">
              {strings.AMOUNT_DUE}:{" "}
              <span className="text-2xl font-bold">
                {currencyFormatter(props.invoiceAmount)}
              </span>
            </p>
          )}

          {props.invoiceStatus === "draft" && (
            <p className="mt-4 inline-block rounded-md bg-blue-50 px-4 py-2 text-blue-700 lg:mt-0">
              <span className="flex items-center gap-4">
                <span className={`flex-shrink-0 text-blue-700`}>
                  <InformationCircleIcon
                    aria-label="info message"
                    className="h-5 w-5"
                  />
                </span>
                <span>
                  This invoice is currently in Draft status. Any payments
                  applied will credit the customer's balance. The invoice will
                  remain in Draft status.
                </span>
              </span>
            </p>
          )}

          <div className="grid grid-cols-1 gap-8 py-8 xs:grid-cols-2">
            {/* GO TO HOSTED PAYMENT PAGE */}
            <BaseButtonSecondary
              disabled={disableAllButtons || disablePayNowButton}
              isBusy={isBusy === "hpp"}
              busyText={""}
              onClick={async () => {
                setIsBusy("hpp");
                await props.goToPaymentPage();
                closeDialog();
              }}
              className="flex h-24 flex-col items-center capitalize"
            >
              <AddCardIcon fontSize="large" className="mb-2" />
              {strings.PAY_WITH_NEW_CARD}
            </BaseButtonSecondary>

            {/* PAY WITH CARD ON FILE */}
            <BaseButtonSecondary
              type="button"
              isSelected={selectedPanel === "card_on_file"}
              disabled={disableAllButtons || disableCardOnFileButton}
              // overrideLoadingElement={
              //   <>
              //     <LoadingSpinner marginClass="ml-1 my-3" sizeClass="h-6 w-6" />
              //     {strings.PAY_WITH_CARD_ON_FILE}
              //   </>
              // }
              onClick={() => {
                if (
                  !props.customer.cardsOnFile ||
                  props.customer.cardsOnFile.length === 0
                ) {
                  throw new Error("No cards on file for customer");
                }
                setSelectedPanel("card_on_file");
              }}
              className={`flex h-24 flex-col items-center capitalize ${selectedPanel === "card_on_file" ? "text-gray-950" : ""}`}
            >
              <PaymentIcon fontSize="large" className="mb-2" />
              {strings.PAY_WITH_CARD_ON_FILE}
            </BaseButtonSecondary>

            {/* SEND RECEIPT/INVOICE VIA EMAIL */}
            <BaseButtonSecondary
              onClick={() => setSelectedPanel("email")}
              isSelected={selectedPanel === "email"}
              className={`flex h-24 flex-col items-center capitalize ${selectedPanel === "email" ? "text-gray-950" : ""}`}
              disabled={disableAllButtons}
            >
              <EmailIcon fontSize="large" className="mb-2" />
              {props.invoiceAmount > 0
                ? strings.EMAIL_CUSTOMER_LINK // ? strings.EMAIL_INVOICE
                : strings.EMAIL_RECEIPT}
            </BaseButtonSecondary>

            {/* MANUALLY APPLY A PAYMENT */}
            <BaseButtonSecondary
              onClick={() => setSelectedPanel("manual")}
              isSelected={selectedPanel === "manual"}
              className={`flex h-24 flex-col items-center capitalize ${selectedPanel === "manual" ? "text-gray-950" : ""}`}
              disabled={disableAllButtons}
            >
              <BanknotesIcon className="mb-2 h-9 w-9" />
              {strings.RECORD_MANUAL_PAYMENT}
            </BaseButtonSecondary>
          </div>
        </div>

        {/* HORIZONTAL LINE */}
        {props.invoiceStatus === StiltInvoiceStatus.CANCELED ? null : (
          <div className="mb-8 mt-2 h-0.5 w-full bg-gray-200 lg:hidden"></div>
        )}
        {/* VERTICAL LINE - 1024px+ */}
        {props.invoiceStatus === StiltInvoiceStatus.CANCELED ? null : (
          <div className="hidden h-full w-0.5 justify-self-center bg-gray-200 lg:block"></div>
        )}

        {/* DISPLAY SELECTED PANEL  */}
        <div className="mx-auto mb-10 space-y-6 lg:col-span-5 lg:col-start-6 lg:w-full">
          {selectedPanel === "card_on_file" ? (
            <PanelCardOnFile
              cardsOnFile={props.customer.cardsOnFile}
              formState={formStateCardOnFile}
              setFormState={setFormStateCardOnFile}
              isBusy={isBusy}
              handleSubmit={submitCardOnFile}
            />
          ) : selectedPanel === "email" ? (
            <PanelEmail
              amountDue={props.invoiceAmount}
              defaultIncludePhotos={props.defaultIncludePhotos}
              emailList={props.emailList}
              invoiceSentToCustomer={props.invoiceSentToCustomer}
              isBusy={isBusy}
              handleSubmit={submitEmail}
            />
          ) : selectedPanel === "manual" ? (
            <PanelManual
              isBusy={isBusy}
              amountDue={props.invoiceAmount}
              paymentMethods={props.paymentMethods}
              handleSubmit={submitManualPayment}
            >
              {{ DatePicker: props.children.DatePicker }}
            </PanelManual>
          ) : null}
        </div>
      </div>
    </BaseModal>
  );
}

/** if there's one card on file, display that one. otherwise, make them choose */
function getInitiallySelectedCard(
  cardsOnFile: CardOnFile[] | undefined,
): string {
  if (!cardsOnFile || cardsOnFile.length === 0) return "";
  if (cardsOnFile.length === 1) return cardsOnFile[0].token;
  return "";
}

function getInitialPanel(
  disableCardOnFileButton: boolean,
  status: StiltInvoiceStatusValues,
): PanelOptions | null {
  if (status === StiltInvoiceStatus.CANCELED) return null;
  return disableCardOnFileButton ? "email" : "card_on_file";
}

function PanelCardOnFile(props: {
  cardsOnFile: CardOnFile[] | undefined;
  formState: FormStateCardOnFile;
  setFormState: React.Dispatch<SetStateAction<FormStateCardOnFile>>;
  isBusy: BusyOptions | null;
  handleSubmit: () => Promise<void>;
}): JSX.Element {
  if (!props.cardsOnFile || props.cardsOnFile.length === 0) return <></>;
  return (
    <>
      <h2 className="mb-2 text-center text-2xl font-bold capitalize italic text-primary lg:mb-4 lg:mt-1.5 lg:text-left lg:font-normal">
        {strings.PAY_WITH_CARD_ON_FILE}
      </h2>
      <FormControl
        size="small"
        sx={{
          width: "100%",
          outline: "none",
          borderColor: "#111827",
          // "&:focus": {
          //   borderColor: "#111827",
          //   outlineColor: "#111827",
          // },
          // "& .MuiSelect-outlined": {
          //   borderColor: "red",
          // },

          "& .Mui-focused": {
            color: "#111827",
            fontSize: "1.15rem",
            borderColor: "#111827",
            outlineColor: "#111827",
          },
          // "& .MuiOutlinedInput-notchedOutline:focus": {
          //   borderColor: "#111827",
          //   outline: "1px solid #111827",
          //   // outline: "none !important",
          //   // border: "1px solid #111827",
          // },
          // "& .MuiSelect-outlined": {
          //   borderColor: "#111827",
          // },
          "& .MuiSelect-select": {
            fontSize: "1rem",
          },
        }}
      >
        <InputLabel htmlFor="select-payment-card">
          {strings.SELECT_CARD}
        </InputLabel>
        <Select
          labelId="select-payment-card"
          id="select-card"
          value={props.formState.token}
          label={strings.SELECT_CARD}
          onChange={(event) =>
            props.setFormState((prev) => ({
              ...prev,
              token: event.target.value,
            }))
          }
        >
          {props.cardsOnFile.map((option) => (
            <MenuItem key={option.expiry} value={option.token}>
              {option.name} - {option.type} - {option.expiry} -{" "}
              {option.lastFour.toString().padStart(4, "0")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputNumber
          text="Amount"
          inputName="amount"
          required={true}
          value={props.formState.amount === null ? "" : props.formState.amount}
          onChange={(event) => {
            const val = event.target.valueAsNumber;
            props.setFormState((prev) => ({
              ...prev,
              amount: !isNaN(val) ? val : null,
            }));
          }}
        />
      </FormControl>
      <BaseButtonPrimary
        isBusy={props.isBusy === "card_on_file"}
        busyText={strings.buttons.BUSY_CHARGING_CARD}
        onClick={props.handleSubmit}
        className="w-full uppercase"
      >
        {strings.buttons.CHARGE_CARD}
      </BaseButtonPrimary>
    </>
  );
}

interface FormStateEmail {
  selectedEmails: string[];
  shouldIncludePhotos: boolean;
}

function PanelEmail(props: {
  isBusy: BusyOptions | null;
  amountDue: Props["invoiceAmount"];
  defaultIncludePhotos: boolean;
  emailList: string[];
  invoiceSentToCustomer: ExistingStiltInvoice["timestampSentToCustomer"];
  handleSubmit: (args: FormStateEmail) => Promise<void>;
}): JSX.Element {
  const [errorStr, setErrorStr] = useState<string | null>(null);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [includePhotos, setIncludePhotos] = useState(
    props.defaultIncludePhotos,
  );

  function reset() {
    setSelectedEmails([]);
    setIncludePhotos(props.defaultIncludePhotos);
    setErrorStr(null);
  }

  const sendEmailButton = (
    <BaseButtonPrimary
      type="button"
      isBusy={props.isBusy === "email"}
      busyText={strings.buttons.BUSY_SENDING}
      onClick={async () => {
        if (selectedEmails.length === 0) {
          setErrorStr(strings.ERROR_EMAIL);
          return;
        }
        await props.handleSubmit({
          selectedEmails,
          shouldIncludePhotos: includePhotos,
        });
        reset();
      }}
      className="w-full uppercase"
    >
      {strings.buttons.SEND_NOW}
    </BaseButtonPrimary>
  );

  const includeJobPhotosSwitch = (
    <StyledSwitchGroup
      readableName={strings.INCLUDE_JOB_PHOTOS}
      onBlur={() => {}}
      onChange={() => setIncludePhotos(!includePhotos)}
      checked={includePhotos}
      id="includeJobPhotos"
      name="includeJobPhotos"
    />
  );

  const displayError = errorStr && (
    <span className="mx-auto mt-4 block w-fit max-w-full">
      <ErrorMessage message={errorStr} clearMessage={() => setErrorStr(null)} />
    </span>
  );

  return (
    <>
      <h2 className="mb-2 text-center text-2xl font-bold capitalize italic text-primary lg:mt-1.5 lg:text-left lg:font-normal">
        {props.amountDue > 0 ? strings.EMAIL_INVOICE : strings.EMAIL_RECEIPT}
      </h2>
      <MultipleEmailSelector2
        selectedEmailList={selectedEmails}
        setSelectedEmailList={setSelectedEmails}
        emailList={props.emailList}
        setErrorString={setErrorStr}
        invoiceSentToCustomer={props.invoiceSentToCustomer}
      >
        {{
          sendEmailButton: sendEmailButton,
          includeJobPhotosSwitch:
            props.amountDue > 0 ? includeJobPhotosSwitch : null,
        }}
      </MultipleEmailSelector2>

      {displayError}
    </>
  );
}

function PanelManual(props: {
  isBusy: BusyOptions | null;
  amountDue: number;
  paymentMethods: PaymentMethodsValues[];
  handleSubmit: (formValues: NewManualPayment) => Promise<void>;
  children: {
    DatePicker: React.ReactNode;
  };
}): JSX.Element {
  const defaultValues: RecordManualPaymentFormState = useMemo(() => {
    return {
      amount: props.amountDue,
      paymentMethod: props.paymentMethods[0],
      paymentSource: "manual",
      checkNumber: null,
      cardType: "",
      lastFour: null,
      nameOnCard: null,
      memo: null,
    };
  }, [props.amountDue, props.paymentMethods]);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<RecordManualPaymentFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(RecordManualPaymentFormSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<RecordManualPaymentFormState> = async (
    formValues,
  ) => {
    const vals: NewManualPayment = {
      ...formValues,
      amount: typeof formValues.amount === "string" ? 0 : formValues.amount,
      cardType: formValues.cardType === "" ? null : formValues.cardType,
    };
    if (vals.amount === 0) return;

    await props.handleSubmit(vals);
  };

  const watchPaymentMethod = useWatch({
    control: control,
    name: "paymentMethod",
  });

  return (
    <>
      <h2 className="mb-2 text-center text-2xl font-bold capitalize italic text-primary lg:mt-1.5 lg:text-left lg:font-normal">
        {strings.RECORD_MANUAL_PAYMENT}
      </h2>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-6 xs:grid xs:grid-cols-2 xs:gap-8 xs:space-y-0"
      >
        {/* Field: Amount */}
        <div>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <BaseInputNumber
                text="Amount"
                inputName="amount"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  field.onChange(isNaN(numberValue) ? null : numberValue);
                }}
              />
            )}
          />
          {errors.amount?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.amount.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Payment Method */}
        <div>
          <Controller
            name="paymentMethod"
            control={control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="method"
                text="Method"
                admin={true}
                required={true}
                {...field}
              >
                {props.paymentMethods.flatMap((method, index) => {
                  // filter out saved card and saved check???
                  const pm = getReadablePaymentMethod(method);
                  if (pm.toLowerCase() === "unknown") return [];
                  return (
                    <option key={index} value={method}>
                      {pm}
                    </option>
                  );
                })}
              </BaseInputSelect>
            )}
          />
          {errors.paymentMethod?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.paymentMethod.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Check Number */}
        {watchPaymentMethod === "check" ? (
          <div>
            <Controller
              name="checkNumber"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Check Number"
                  inputName="checkNumber"
                  admin={true}
                  required={true}
                  {...field}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value !== "" ? e.target.value : null,
                    )
                  }
                  value={field.value ?? ""}
                />
              )}
            />
            {errors.checkNumber?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.checkNumber.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        ) : null}

        {watchPaymentMethod === "credit_card" ? (
          <div>
            <Controller
              name="lastFour"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Last Four"
                  inputName="lastFour"
                  admin={true}
                  required={true}
                  {...field}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value !== "" ? e.target.value : null,
                    )
                  }
                  value={field.value ?? ""}
                />
              )}
            />
            {errors.lastFour?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.lastFour.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        ) : null}

        {watchPaymentMethod === "credit_card" ? (
          <div>
            <Controller
              name="cardType"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="cardType"
                  text="Card Type"
                  admin={true}
                  required={true}
                  {...field}
                >
                  <option value="" disabled>
                    Select...
                  </option>
                  {cardTypes.map((cardType, index) => (
                    <option key={index} value={cardType}>
                      {cardType}
                    </option>
                  ))}
                </BaseInputSelect>
              )}
            />
            {errors.cardType?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.cardType.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        ) : null}

        {watchPaymentMethod === "credit_card" ? (
          <div className="col-span-2">
            <Controller
              name="nameOnCard"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Name on Card"
                  inputName="nameOnCard"
                  admin={true}
                  required={true}
                  {...field}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value !== "" ? e.target.value : null,
                    )
                  }
                  value={field.value ?? ""}
                />
              )}
            />
            {errors.nameOnCard?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.nameOnCard.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        ) : null}

        {/* Memo */}
        <div className="col-span-2">
          <Controller
            name="memo"
            control={control}
            render={({ field }) => (
              <BaseInputTextArea
                text="Memo"
                inputName="memo"
                admin={true}
                required={false}
                rows={3}
                {...field}
                value={field.value == null ? "" : field.value}
              />
            )}
          />
          {errors.memo?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.memo.message }}
              </StyledMessage>
            </div>
          )}
        </div>
      </form>

      {/* Payment Date */}
      <div className="relative z-30 flex flex-wrap items-center gap-4">
        {props.children.DatePicker}
      </div>

      <BaseButtonPrimary
        type="submit"
        formNoValidate
        disabled={isSubmitting}
        isBusy={isSubmitting}
        busyText={strings.buttons.BUSY_SAVING}
        className="w-full justify-center uppercase"
        onClick={handleSubmit(onSubmit)}
      >
        {strings.buttons.SAVE}
      </BaseButtonPrimary>
    </>
  );
}
