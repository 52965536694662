import { Link } from "react-router-dom";

interface StiltLink {
  to: string;
  className?: string;
  children: React.ReactNode;
}

/**
 * Can replace internals if not using react-router-dom.
 */
function ReactRouterLink(
  props: StiltLink & { children: React.ReactNode },
): JSX.Element {
  return (
    <Link to={props.to} className={props.className}>
      {props.children}
    </Link>
  );
}

// function NormalLink(
//   props: StiltLink & { children: React.ReactNode },
// ): JSX.Element {
//   return (
//     <a href={props.to} className={props.className}>
//       {props.children}
//     </a>
//   );
// }

/**
 * Can switch between ReactRouterLink and NormalLink if not using react-router-dom.
 */
export default ReactRouterLink;
