//Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useMemo, useEffect } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { z } from "zod";

//Local
import { logger as devLogger } from "../../logging";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputSelect from "../BaseInputSelect";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import StyledSwitchGroup from "../StyledSwitchGroup";
import {
  CustomerContact,
  customerContactTypes,
  ExistingCustomerContact,
} from "../../models/customer-contact";
import { phoneUtils } from "../../utils/phoneUtils";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";

interface Props {
  className: string;
  selectedCustomerContact: ExistingCustomerContact | null;
  handleSave: (
    formValues: Omit<
      CustomerContact,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    >,
  ) => Promise<void>;
  handleEdit: (
    formValues: Omit<
      CustomerContact,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    >,
  ) => Promise<void>;
  setAddCustomerContactFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// TODO: Add superRefine for email and phone validation
export const CustomerContactFormSchema = z.object({
  type: z.enum(customerContactTypes),
  value: z.string().max(200),
  notes: z.string().max(1000),
  optIn: z.boolean(),
  deleted: z.boolean(),
  customData: z.record(z.any()),
});

export type CustomerContactFormState = z.infer<
  typeof CustomerContactFormSchema
>;

export default function AddEditCustomerContactForm({
  handleSave,
  handleEdit,
  setAddCustomerContactFormOpen,
  ...props
}: Props) {
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const [displayError, setDisplayError] = useState<boolean>(false);

  const customerContactFormDefaultValues: CustomerContactFormState =
    useMemo(() => {
      if (props.selectedCustomerContact) {
        return {
          type: props.selectedCustomerContact.type,
          value: props.selectedCustomerContact.value,
          notes: props.selectedCustomerContact.notes,
          optIn: props.selectedCustomerContact.optIn,
          deleted: false,
          customData: props.selectedCustomerContact.customData,
        };
      } else {
        return {
          type: "phone",
          value: "",
          notes: "",
          optIn: true,
          deleted: false,
          customData: {},
        };
      }
    }, [props.selectedCustomerContact]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<CustomerContactFormState>({
    defaultValues: customerContactFormDefaultValues,
    resolver: zodResolver(CustomerContactFormSchema),
    mode: "onChange",
  });

  const watchType = useWatch({
    control: control,
    name: "type",
  });

  useEffect(() => {
    reset(customerContactFormDefaultValues);
  }, [customerContactFormDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    setAddCustomerContactFormOpen(false);
  }

  const onSubmit: SubmitHandler<CustomerContactFormState> = async (
    formValues,
  ) => {
    let parsed;
    if (formValues.type === "phone" || formValues.type === "mobilePhone") {
      const { PhoneNumber, warning } = phoneUtils.parse(formValues.value);
      if (warning === true) {
        parsed = formValues.value;
        addToastMessage({
          id: createToastMessageID(),
          message: strings.PHONE_TOO_SHORT,
          dialog: false,
          type: "warning",
        });
      } else {
        parsed = PhoneNumber.number;
      }
    } else {
      parsed = formValues.value;
    }

    if (props.selectedCustomerContact) {
      const partialCustomerContactValue: Omit<
        CustomerContact,
        | "timestampCreated"
        | "timestampLastModified"
        | "createdBy"
        | "lastModifiedBy"
      > = {
        type: formValues.type,
        value: parsed,
        notes: formValues.notes,
        optIn: formValues.optIn,
        customData: formValues.customData,
        customerID: props.selectedCustomerContact.customerID,
        customerLocationID: props.selectedCustomerContact.customerLocationID,
        deleted: false,
      };

      try {
        await handleEdit(partialCustomerContactValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    } else {
      const partialCustomerContactValue: Omit<
        CustomerContact,
        | "timestampCreated"
        | "timestampLastModified"
        | "createdBy"
        | "lastModifiedBy"
      > = {
        type: formValues.type,
        value: parsed,
        notes: formValues.notes,
        optIn: formValues.optIn,
        customData: {},
        customerID: "",
        customerLocationID: "",
        deleted: false,
      };

      try {
        await handleSave(partialCustomerContactValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    }
  };

  const errorMessage = (
    <ErrorMessage
      message={strings.ERR_CREATE_NEW_CUSTOMER_CONTACT}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className={props.className}
    >
      {/* Field: Type */}
      <div>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <BaseInputSelect
              inputName="type"
              text="Type"
              admin={true}
              required={true}
              className="capitalize"
              {...field}
            >
              {/* The empty string for value tricks validation into failing */}
              <option value="" disabled>
                Select a type
              </option>
              {customerContactTypes.map((type) => {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                );
              })}
            </BaseInputSelect>
          )}
        />
        {errors.type?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.type.message }}
            </StyledMessage>
          </div>
        )}
      </div>
      {watchType === "email" ? (
        /* Field: Value */
        <div>
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Email Address"
                inputName="value"
                admin={true}
                required={true}
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
          {errors.value?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.value.message }}
              </StyledMessage>
            </div>
          )}
        </div>
      ) : null}
      {watchType === "phone" || watchType === "mobilePhone" ? (
        /* Field: Value */
        <div>
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Phone Number"
                inputName="value"
                admin={true}
                required={true}
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
          {errors.value?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.value.message }}
              </StyledMessage>
            </div>
          )}
        </div>
      ) : null}
      {/* Field: Notes */}
      <div>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Notes"
              inputName="notes"
              admin={true}
              required={false}
              {...field}
            />
          )}
        />
        {errors.notes?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.notes.message }}
            </StyledMessage>
          </div>
        )}
      </div>
      {/* SWITCH GROUP */}
      <div className="grid grid-cols-1 gap-4">
        {/* Field: Opt-In */}
        <div>
          <Controller
            name="optIn"
            control={control}
            render={({ field }) => (
              <StyledSwitchGroup
                readableName={strings.OPT_IN}
                onBlur={field.onBlur}
                onChange={field.onChange}
                ref={field.ref}
                checked={field.value}
                id="isTaxExempt"
                name={field.name}
              />
            )}
          />
        </div>
      </div>
      {/* Action Buttons */}
      <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row sm:col-span-3 sm:col-start-1">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={closeAndReset}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          disabled={isSubmitting}
          isBusy={isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
      {displayError ? (
        <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
          {errorMessage}
        </span>
      ) : null}
    </form>
  );
}
