//Libs
import { Fragment } from "react";

//Local
import * as strings from "../../strings";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { JobCommissionsForDisplay } from "../../models/estimate";

interface Props {
  selectedCustomerLocation: ExistingCustomerLocation | null;
  membershipDiscountTotal: number | null;
  totals: {
    totalEstimate: string;
    subtotal: string;
    subtotalWithDiscount: string | null;
    discount: number | null;
    totalTaxAmount: string;
    taxRate: string;
  };
  commissions: JobCommissionsForDisplay | null;
  /* COMPONENTS */
  removeAllItemsButton: React.ReactNode;
  displaySelectedEstimateItems: React.ReactNode;
  addNewEstimateItemLine: React.ReactNode;
  /* CHILDREN */
  children: {
    NotesField: React.ReactNode;
    InternalNotesField: React.ReactNode;
    MembershipDiscounts: React.ReactNode;
    InventoryTransactions: React.ReactNode;
    DiscountField: React.ReactNode;
  };
}

export default function CreateEstimateDetails(props: Props) {
  return (
    <Fragment>
      <div className="flex flex-row gap-4 sm:grid  sm:grid-cols-1 md:grid-cols-2">
        {/* NOTES */}
        <div className="mb-2 mt-2 flex-1 flex-col gap-4">
          <div className="pr-2 text-2xl capitalize text-primary">
            {strings.NOTES}
          </div>
          {props.children.NotesField}
        </div>

        {/*<hr className="mt-2 mb-2 block w-full border border-gray-200" />*/}

        {/* INTERNAL NOTES */}
        <div className="mb-2 mt-2 flex-1 flex-col gap-4">
          <div className="pr-2 text-2xl capitalize text-primary">
            {strings.INTERNAL_NOTES}
          </div>
          {props.children.InternalNotesField}
        </div>
      </div>

      <hr className="mb-8 mt-2 block w-full border border-gray-200" />
      {/* SELECT PRICE BOOK ITEM */}
      <div className="mb-4 mt-4 flex flex-col justify-between gap-4 sm:flex-row sm:items-start sm:gap-x-2">
        <div className="pr-2 text-2xl capitalize text-primary">
          {strings.LINE_ITEMS}
        </div>
        <div>{props.removeAllItemsButton}</div>
      </div>
      {props.addNewEstimateItemLine}
      <hr className="mb-4 mt-6 block w-full border border-gray-200" />
      {props.displaySelectedEstimateItems}
      {/* MEMBERSHIPS */}
      {props.children.MembershipDiscounts}
      {/* INVENTORY */}
      {props.children.InventoryTransactions}

      {/* TOTAL ESTIMATE */}
      <div className="-mt-4  grid gap-4 rounded-sm bg-gray-100 px-2 py-4 md:grid-cols-3 md:items-start md:gap-x-2">
        <div className="pr-2 text-2xl capitalize text-primary">Subtotal:</div>
        <div className="col-start-3 text-right text-2xl ">
          {props.totals.subtotal}
        </div>

        <div className="pr-2 text-2xl capitalize text-primary">Discount:</div>
        <div className="col-start-3 justify-self-end text-2xl ">
          {props.children.DiscountField}
        </div>

        {props.membershipDiscountTotal != null ? (
          <Fragment>
            <div className="pr-2 text-2xl capitalize text-primary">
              Membership Discount:
            </div>
            <div className="col-start-3 text-right text-2xl ">
              {props.membershipDiscountTotal.toFixed(2)} %
            </div>
          </Fragment>
        ) : null}

        {props.totals.subtotalWithDiscount != null ? (
          <Fragment>
            <div className="pr-2 text-2xl capitalize text-primary">
              Subtotal With Discount:
            </div>
            <div className="col-start-3 text-right text-2xl ">
              {props.totals.subtotalWithDiscount}
            </div>
          </Fragment>
        ) : null}

        <div className="pr-2 text-2xl capitalize text-primary">Tax amount:</div>
        <div className="col-start-3 text-right text-2xl ">
          {props.totals.totalTaxAmount} ({props.totals.taxRate})
        </div>

        <div className="pr-2 text-3xl font-bold capitalize text-primary">
          Total Estimate:
        </div>
        <div className="col-start-3 text-right text-3xl font-bold ">
          {props.totals.totalEstimate}
        </div>
      </div>

      {/* JOB COMMISSIONS */}
      {props.commissions && (
        <div className="mb-4 mt-4 flex flex-col justify-end gap-4 sm:flex-row sm:items-start sm:gap-x-2">
          <div className="pr-2 text-xl capitalize text-primary">
            Total Commission: {props.commissions.baseAndFieldCommissions}
          </div>
        </div>
      )}
    </Fragment>
  );
}
