// Libs
import React, { useCallback, useLayoutEffect, useState } from "react";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  ITooltipParams,
} from "ag-grid-community";
import isEqual from "lodash/isEqual";
import AddBoxIcon from "@mui/icons-material/AddBox";

// Local
import { StyledTooltip } from "../../components/StyledTooltip";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import { PencilIconWithRef } from "../../components/PencilEditButton";
import { ViewInfoIconWithRef } from "../../components/admin/ViewInfoButton";
import SearchBox from "../../components/SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

interface Props {
  //DATA
  companies: ExistingSiteKeyCompany[];
  //FUNCTIONS
  onEditCompany: (company: ExistingSiteKeyCompany) => void;
  onViewCompanyInfo: (company: ExistingSiteKeyCompany) => void;
  onAddNewCompany: () => void;
  //CHILDREN
  children: {
    Breadcrumbs: React.ReactNode;
    ViewCompanyInfoDialog: React.ReactNode;
    AddOrEditCompany: React.ReactNode;
  };
}

const CompaniesListTable = React.memo(
  function ({
    companies,
    onEditCompany,
    onViewCompanyInfo,
    onAddNewCompany,
    children,
  }: Props): JSX.Element {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const renderIconCell = useCallback(
      function (params: ICellRendererParams) {
        return (
          <div className="flex items-center">
            <StyledTooltip title="View Company Details">
              <ViewInfoIconWithRef
                onClick={() => onViewCompanyInfo(params.data)}
              />
            </StyledTooltip>

            <StyledTooltip title="Edit">
              <PencilIconWithRef onClick={() => onEditCompany(params.data)} />
            </StyledTooltip>
          </div>
        );
      },
      [onEditCompany, onViewCompanyInfo],
    );

    const renderCompanyLogo = (params: ICellRendererParams): JSX.Element => {
      return (
        <div className="m-1 flex h-10 w-10 justify-center overflow-hidden rounded-md">
          {params.data.logoPhotoURL ? (
            <img
              src={params.data.logoPhotoURL}
              alt="Company Logo"
              className="w-full object-cover"
            />
          ) : null}
        </div>
      );
    };

    // SECTION:
    return (
      <div className={"ag-theme-alpine flex h-full min-h-[600px] flex-col"}>
        {children.Breadcrumbs}
        <div className="mt-6 flex items-end justify-between gap-4 space-y-4 sm:space-y-0">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full max-w-md sm:w-96"
          />
          <button
            type="button"
            onClick={onAddNewCompany}
            className="text-primary"
          >
            <AddBoxIcon aria-label="Add Company" sx={{ fontSize: 45 }} />
          </button>
          {children.ViewCompanyInfoDialog}
          {children.AddOrEditCompany}
        </div>
        <TheTable
          onGridReady={onGridReady}
          gridRef={gridRef}
          companies={companies}
          renderIconCell={renderIconCell}
          renderCompanyLogo={renderCompanyLogo}
        />
      </div>
    );
  },
  (previous, next) => {
    const isCompaniesTheSame = isEqual(previous.companies, next.companies);
    const isOnHandleEditCompanyTheSame =
      previous.onEditCompany === next.onEditCompany;
    const isOnViewCompanyInfoTheSame =
      previous.onViewCompanyInfo === next.onViewCompanyInfo;

    return (
      isCompaniesTheSame &&
      isOnHandleEditCompanyTheSame &&
      isOnViewCompanyInfoTheSame
    );
  },
);

export default CompaniesListTable;

/* The Table */

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

/**
 * These properties are added to every column. They can be overridden on a per
 * column basis.
 */
const commonColProps = {
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  tooltipValueGetter: (params: ITooltipParams) =>
    params.valueFormatted ?? params.value,
};

interface TableProps {
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
  companies: Props["companies"];
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  renderCompanyLogo: (params: ICellRendererParams) => JSX.Element;
}

const TheTable = React.memo(
  ({
    gridRef,
    onGridReady,
    companies,
    renderIconCell,
    renderCompanyLogo,
  }: TableProps) => {
    const columnDefs: ColDef[] = [
      {
        field: "name",
        headerName: "Company Name",
        cellClass: "font-semibold text-gray-700 ",
        initialWidth: 400,
      },
      {
        field: "logoPhotoURL",
        cellRenderer: renderCompanyLogo,
        headerName: "Company Logo",
      },
      {
        field: "mainPointOfContact",
        headerName: "Main Point of Contact",
      },
      {
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        suppressAutoSize: true,
        suppressSizeToFit: true,
        sortable: false,
      },
    ];

    return (
      <div className={`ag-theme-alpine flex h-full min-h-[600px] flex-col`}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={companies}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
        />
      </div>
    );
  },
  (previous, next) => {
    const isCompaniesTheSame = isEqual(previous.companies, next.companies);
    const isGridRefTheSame = previous.gridRef === next.gridRef;
    const isRenderIconCellTheSame =
      previous.renderIconCell === next.renderIconCell;
    const isRenderCompanyLogoTheSame =
      previous.renderCompanyLogo === next.renderCompanyLogo;

    return (
      isCompaniesTheSame &&
      isGridRefTheSame &&
      isRenderIconCellTheSame &&
      isRenderCompanyLogoTheSame
    );
  },
);
