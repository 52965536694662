//Libs
import { DateTime } from "luxon";

//Local
import { CalendarEvent } from "../../../models/scheduling-types";
import { ExistingStiltCalendarEvent } from "../../../models/stilt-calendar-event";

export function generateStiltCalendarEvents(args: {
  stiltCalendarEvents: ExistingStiltCalendarEvent[];
  /** defaults to false */
  moveDisabled?: boolean;
  /** defaults to false */
  resizeDisabled?: boolean;
}): CalendarEvent[] {
  const calendarEvents: CalendarEvent[] = [];

  for (const event of args.stiltCalendarEvents) {
    const startDateString = DateTime.fromMillis(event.start.toMillis()).toISO({
      includeOffset: false,
      suppressMilliseconds: true,
    });

    const endDateString = DateTime.fromMillis(event.end.toMillis()).toISO({
      includeOffset: false,
      suppressMilliseconds: true,
    });

    const calendarEvent: Omit<CalendarEvent, "id" | "resource" | "tags"> = {
      start: startDateString,
      end: endDateString,
      text:
        event.description === ""
          ? `${event.title}`
          : `${event.title} - ${event.description}`,
      backColor: "#e1e1e1",
      barColor: "#e1e1e1",
      moveDisabled: args.moveDisabled ?? false,
      resizeDisabled: args.resizeDisabled ?? false,
    };

    for (const userId of event.assignedTo) {
      const fullEvent: CalendarEvent = {
        ...calendarEvent,
        id: 10000 + calendarEvents.length + 1,
        resource: userId,
        tags: {
          stiltEventID: event.id,
          resourceID: userId,
        },
      };
      calendarEvents.push(fullEvent);
    }

    if (event.assignedVehicleIDs) {
      for (const vid of event.assignedVehicleIDs) {
        const fullEvent: CalendarEvent = {
          ...calendarEvent,
          id: 20000 + calendarEvents.length + 1,
          resource: vid,
          tags: {
            stiltEventID: event.id,
            resourceID: vid,
          },
        };
        calendarEvents.push(fullEvent);
      }
    }
  }

  return calendarEvents;
}
