// Libs
import { DocumentSnapshot, DocumentData, Timestamp } from "firebase/firestore";
import { z } from "zod";

// Local
import {
  ResponseTypes,
  ResponsePassingTypes,
  responseTypes,
  responsePassingTypes,
} from "./checklist-response-types";
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

// #region SECTION: Schemas / Interfaces / Types
export interface ChecklistResponse {
  // TODO: may need stronger validation.

  // Copied from ChecklistItem by CF
  mainText: string;
  note: string;
  passingMax: number | null; // only applies if responseType is "integer" or "float"
  passingMin: number | null; // only applies if responseType is "integer" or "float"
  units: string;
  selectionOptions: string[] | null; // only applies if responseType is "selection"
  passingOptions: string[] | null; // only applies if responseType is "selection"
  responseType: ResponseTypes;
  required: boolean;
  tags: string[];
  checklistItemID: string;

  // Added by CF
  taskID: string;
  craftRecordID: string;

  // Data updated by user, Added by CF initially;
  responseValue: string | number | null;
  timestamp: Timestamp | null;
  uid: string | null;
  comments: string;
  deviceLat?: number;
  deviceLong?: number;

  // Cloud Function writes
  responsePassing: ResponsePassingTypes | null;
  orderNum: number;

  deleted: boolean;
  customData?: { [key: string]: any };
}

export interface ExistingChecklistResponse extends ChecklistResponse {
  id: string;
  refPath: string;
}

/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
const ChecklistResponseSchema = z.object({
  mainText: z.string().max(200),
  note: z.string().max(2000),
  passingMax: z.number().nullable(),
  passingMin: z.number().nullable(),
  units: z.string().max(200),
  selectionOptions: z.array(z.string().max(200)).nullable(),
  passingOptions: z.array(z.string().max(200)).nullable(),
  responseType: z.enum(responseTypes),
  required: z.boolean(),
  tags: z.array(z.string().max(200)),
  checklistItemID: z.string().min(1).max(200),

  taskID: z.string().min(1).max(200),
  craftRecordID: z.string().min(1).max(200),

  responseValue: z.union([z.string().max(2000), z.number(), z.null()]),
  timestamp: z.instanceof(Timestamp).nullable(),
  uid: z.string().min(1).max(200).nullable(),
  comments: z.string().max(2000),
  deviceLat: z.number().optional(),
  deviceLong: z.number().optional(),

  responsePassing: z.enum(responsePassingTypes).nullable(),
  orderNum: z.number(),

  deleted: z.boolean(),
  customData: z.record(z.any()).optional(),
});

/** For reading from the database */
const ChecklistResponseSchemaWithFallbacks = z.object({
  mainText: zFallback(
    z.string().max(200),
    "unknown",
    "ChecklistResponseSchemaWithFallbacks: 'mainText'",
  ),
  note: zFallback(
    z.string().max(2000),
    "",
    "ChecklistResponseSchemaWithFallbacks: 'note'",
  ),
  passingMax: zFallback(
    z.number().nullable(),
    null,
    "ChecklistResponseSchemaWithFallbacks: 'passingMax'",
  ),
  passingMin: zFallback(
    z.number().nullable(),
    null,
    "ChecklistResponseSchemaWithFallbacks: 'passingMin'",
  ),
  units: zFallback(
    z.string().max(200),
    "",
    "ChecklistResponseSchemaWithFallbacks: 'units'",
  ),
  selectionOptions: zFallback(
    z.array(z.string().max(200)).nullable(),
    null,
    "ChecklistResponseSchemaWithFallbacks: 'selectionOptions'",
  ),
  passingOptions: zFallback(
    z.array(z.string().max(200)).nullable(),
    null,
    "ChecklistResponseSchemaWithFallbacks: 'passingOptions'",
  ),
  responseType: z.enum(responseTypes),
  required: zFallback(
    z.boolean(),
    false,
    "ChecklistResponseSchemaWithFallbacks: 'required'",
  ),
  tags: zFallback(
    z.array(z.string().max(200)),
    [],
    "ChecklistResponseSchemaWithFallbacks: 'tags'",
  ),
  checklistItemID: z.string().min(1).max(200),

  taskID: z.string().min(1).max(200),
  craftRecordID: z.string().min(1).max(200),

  responseValue: z.union([z.string().max(2000), z.number(), z.null()]),
  timestamp: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "ChecklistResponseSchemaWithFallbacks: 'timestamp'",
  ),
  uid: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "ChecklistResponseSchemaWithFallbacks: 'uid'",
  ),
  comments: zFallback(
    z.string().max(2000),
    "",
    "ChecklistResponseSchemaWithFallbacks: 'comments'",
  ),
  deviceLat: zFallback(
    z.number().optional(),
    undefined,
    "ChecklistResponseSchemaWithFallbacks: 'deviceLat'",
  ),
  deviceLong: zFallback(
    z.number().optional(),
    undefined,
    "ChecklistResponseSchemaWithFallbacks: 'deviceLong'",
  ),

  responsePassing: zFallback(
    z.enum(responsePassingTypes).nullable(),
    null,
    "ChecklistResponseSchemaWithFallbacks: 'responsePassing'",
  ),
  orderNum: zFallback(
    z.number(),
    0,
    "ChecklistResponseSchemaWithFallbacks: 'orderNum'",
  ),

  deleted: zFallback(
    z.boolean(),
    false,
    "ChecklistResponseSchemaWithFallbacks: 'deleted'",
  ),
  customData: z.record(z.any()).optional(),
});
// #endregion

// #region SECTION: Functions
/**
 * Collection of functions that interact with ChecklistResponse Documents
 */
export const ChecklistResponseManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingChecklistResponse object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  convertForFirestore: convertForFirestore,
  createFromJSON: createFromJSON,
  /** Validate a ChecklistResponse doc. Use for writing to the database or reading from the user. */
  parse: validateChecklistResponse,
  /** Validate a ChecklistResponse doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateChecklistResponseWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingChecklistResponse object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingChecklistResponse {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateChecklistResponseWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertForFirestore(item: ExistingChecklistResponse): DocumentData {
  const local = Object.assign({}, item);

  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/** Rejects if it doesn't pass validation */
function validateChecklistResponse(response: unknown): ChecklistResponse {
  if (!guardIsPlainObject(response)) {
    throw new Error(`ChecklistResponse is not an object: ${response}`);
  }
  return ChecklistResponseSchema.parse(response);
}

/** For validating stuff coming in from the database. */
function validateChecklistResponseWithFallbacks(
  response: unknown,
): ChecklistResponse {
  if (!guardIsPlainObject(response)) {
    throw new Error(`ChecklistResponse is not an object: ${response}`);
  }
  return ChecklistResponseSchemaWithFallbacks.parse(response);
}

/** Create ChecklistResponse object from JSON. */
function createFromJSON(
  jsonObj: Record<string, any>,
): ExistingChecklistResponse {
  let timestamp: Timestamp | null = null;

  if (jsonObj.timestamp !== null) {
    timestamp = new Timestamp(
      jsonObj.timestamp._seconds,
      jsonObj.timestamp._nanoseconds,
    );
  }

  return {
    id: jsonObj.id,
    refPath: jsonObj.refPath,
    mainText: jsonObj.mainText,
    note: jsonObj.note,
    passingMax: jsonObj.passingMax,
    passingMin: jsonObj.passingMin,
    units: jsonObj.units,
    selectionOptions: jsonObj.selectionOptions,
    passingOptions: jsonObj.passingOptions,
    responseType: jsonObj.responseType,
    required: jsonObj.required,
    tags: jsonObj.tags,
    checklistItemID: jsonObj.checklistItemID,
    taskID: jsonObj.taskID,
    craftRecordID: jsonObj.craftRecordID,
    orderNum: jsonObj.orderNum,
    responseValue: jsonObj.responseValue,
    timestamp: timestamp,
    uid: jsonObj.uid,
    comments: jsonObj.comments,
    deviceLat: jsonObj.deviceLat,
    deviceLong: jsonObj.deviceLong,
    responsePassing: jsonObj.responsePassing,
    deleted: jsonObj.deleted,
  };
}

// #endregion
