// Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";

// Local
import {
  adminPropDefaultsForCustomField,
  CustomFieldManager,
  TimestampCustomField,
} from "../../../models/custom-field";
import {
  TitleField,
  OnTaskStatusField,
  isDefinedOnTaskStatus,
  HideOnCRCreationField,
} from "../../admin/AddCustomFieldDialog/AddCustomFieldDialog";
import { ErrorMessage } from "../../ErrorMessage";
import { OTaskTypes } from "../../../models/task-types";
import {
  ActionButtons,
  CreateSiteCustomFieldFormInterface,
  RequiredField,
  TimestampSchema_CSCF,
} from "./AddCreateSiteCustomFieldDialog";

const timestampDefaultValues: Partial<TimestampInterface> = {
  title: "",
  editable: true,
  required: false,
  hideOnCraftRecordCreation: false,
};
type TimestampInterface = z.infer<typeof TimestampSchema_CSCF>;

export const TimestampCreateSiteCustomFieldForm: React.FunctionComponent<
  CreateSiteCustomFieldFormInterface
> = (props) => {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<TimestampInterface>({
    defaultValues: timestampDefaultValues,
    resolver: zodResolver(TimestampSchema_CSCF),
    mode: "onChange",
  });

  useEffect(() => {
    reset(timestampDefaultValues);
  }, [reset]);

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [displayWorkTypeError, setDisplayWorkTypeError] =
    useState<boolean>(false);

  const onSave: SubmitHandler<TimestampInterface> = (formValues) => {
    if (props.workType === undefined) {
      setDisplayWorkTypeError(true);
      return;
    }

    // Drop undefined values from the onTaskStatus list.
    // onTaskStatus itself will be undefined if this is a workType.
    const onTaskStatus = formValues.onTaskStatus?.filter(isDefinedOnTaskStatus);

    // Compose the output structure.
    let fullOutput: TimestampCustomField;

    const partialOutput: Omit<
      TimestampCustomField,
      "taskType" | "craftRecordOrTask"
    > = {
      ...adminPropDefaultsForCustomField(),
      fieldType: "timestamp",
      defaultValue: null,
      title: formValues.title,
      editable: true,
      required: formValues.required,
      craftType: props.workType,
    };

    // If taskType is null this means it's a craftRecord, and
    // we don't want to save the taskType to the database.
    if (props.taskType == null) {
      fullOutput = {
        ...partialOutput,
        craftRecordOrTask: "craftRecord",
        hideOnCraftRecordCreation: formValues.hideOnCraftRecordCreation,
      };
    } else {
      fullOutput = {
        ...partialOutput,
        craftRecordOrTask: "task",
        taskType: props.taskType,
        onTaskStatus: onTaskStatus,
      };
    }

    // Validate CustomFieldDocData
    const validatedOutput = CustomFieldManager.parse(fullOutput);
    props.handleSave(validatedOutput);
    props.closeDialog();
  };

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  const undefinedWorkTypeErrorMessage = (
    <ErrorMessage
      message="Work Type must be selected"
      clearMessage={() => setDisplayWorkTypeError(false)}
    />
  );

  return (
    <form
      className="contents"
      autoComplete="off"
      onSubmit={handleSubmit(onSave)}
    >
      {props.taskType != null ? (
        <div></div>
      ) : (
        <HideOnCRCreationField control={control} />
      )}

      <div className="col-span-1 sm:col-span-2">
        <TitleField control={control} errors={errors} />
      </div>

      {/* No fields are specific to fieldType timestamp */}
      <RequiredField control={control} />

      {props.taskType != null &&
        Object.values(OTaskTypes).includes(props.taskType) && (
          <OnTaskStatusField
            taskStatusLabel="Prompt On Task Status"
            control={control}
            taskStatusList={props.taskStatusList}
          />
        )}

      <span className="-my-5 h-10 justify-self-center sm:col-span-2">
        {displayError ? errorMessage : null}
        {displayWorkTypeError ? undefinedWorkTypeErrorMessage : null}
      </span>

      <ActionButtons onCancel={props.closeDialog} />
    </form>
  );
};
