// Libs
import { Menu } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

// Local
import { showReadableCustomFieldDefaultValue } from "../../assets/js/showReadableCustomFieldDefaultValue";
import {
  ExistingCustomField,
  getReadableCustomFieldType,
} from "../../models/custom-field";
import { StyledTooltip } from "../../components/StyledTooltip";
import { CUSTOM_FIELD_DELETE_DISABLED } from "../../strings";

interface Props {
  field: ExistingCustomField;
  handleDeleteCustomField: (field: ExistingCustomField) => void;
}

const styles = {
  default:
    "rounded border border-primary focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary",
  disabled: "opacity-50",
};

export default function OverflowMenuItemCustomFieldCard({
  field,
  handleDeleteCustomField,
}: Props): JSX.Element {
  return (
    <Menu.Item>
      {() => (
        <div className="max-w-sm">
          <div className="grid grid-cols-3 gap-2 rounded-t-md bg-primary p-4 sm:gap-5">
            <div className="flex items-center">
              <StyledTooltip
                title={field.adminRemovable ? "" : CUSTOM_FIELD_DELETE_DISABLED}
              >
                {/* This span lets MUI tooltip see events when button is disabled */}
                <span>
                  <button
                    data-testid="deleteButton"
                    disabled={field.adminRemovable ? false : true}
                    className={
                      field.adminRemovable ? styles.default : styles.disabled
                    }
                    onClick={() => handleDeleteCustomField(field)}
                  >
                    <TrashIcon
                      aria-label="delete custom field"
                      className="inline-block w-7 rounded bg-white p-1 text-primary"
                    />
                    <span className="ml-2 text-xs font-bold text-white">
                      Delete
                    </span>
                  </button>
                </span>
              </StyledTooltip>
            </div>
          </div>
          <div className="flex flex-col gap-5 whitespace-normal p-4">
            <span className="capitalize">
              {getReadableCustomFieldType(field.fieldType)}
            </span>
            {field.fieldType === "number" ||
            field.fieldType === "string" ||
            field.fieldType === "string-array" ||
            field.fieldType === "uid" ? (
              <Fragment>
                <span>Min: {field.min ?? "N/A"}</span>
                <span>Max: {field.max ?? "N/A"}</span>
              </Fragment>
            ) : field.fieldType === "selection" ? (
              <Fragment>
                <span>
                  Options: {Object.keys(field.selectionOptions).join(", ")}
                </span>
              </Fragment>
            ) : null}
            <span className="">
              Default Value:{" "}
              {showReadableCustomFieldDefaultValue(field.defaultValue)}
            </span>
          </div>
        </div>
      )}
    </Menu.Item>
  );
}
