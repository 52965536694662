//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { useForm, SubmitHandler, Controller, useWatch } from "react-hook-form";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseInputNumber from "../BaseInputNumber";
import StyledMessage from "../StyledMessage";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputSelect from "../BaseInputSelect";
import {
  StiltPayment,
  getReadablePaymentMethod,
  paymentMethods,
  paymentSources,
  cardTypes,
  PaymentMethodsValues,
} from "../../models/stilt-payment";
import { DollarCurrency } from "../../currency";
import BaseInputTextArea from "../BaseInputTextArea";
import BaseInputText from "../BaseInputText";
import { ExistingSiteKey } from "../../models/site-key";

export type NewManualPayment = Pick<
  StiltPayment,
  | "amount"
  | "paymentMethod"
  | "paymentSource"
  | "checkNumber"
  | "memo"
  | "cardType"
  | "lastFour"
  | "nameOnCard"
>;

export interface RecordManualPaymentDialogProps {
  /* DATA */
  isDialogOpen: boolean;
  amountDue: number;
  siteKeyDoc: ExistingSiteKey;
  /* FUNCTIONS */
  closeDialog: () => void;
  onRecordManualPayment: (formValues: NewManualPayment) => Promise<void>;
  children: {
    datePicker: React.ReactNode;
    multiInvoiceList: React.ReactNode;
    multiInvoiceMessage: React.ReactNode;
  };
}

export const RecordManualPaymentFormSchema = z
  .object({
    amount: z.union([
      z.number({ message: "Amount is required" }),
      z.literal(""),
    ]),
    paymentMethod: z.enum(paymentMethods),
    paymentSource: z.enum(paymentSources),
    checkNumber: z.string().nullable(),
    cardType: z.string(),
    lastFour: z.string().nullable().optional(),
    nameOnCard: z.string().nullable().optional(),
    memo: z.string().nullable(),
  })
  .superRefine((paymentData, ctx) => {
    if (paymentData.paymentMethod === "credit_card") {
      if (paymentData.lastFour == null || paymentData.lastFour.length !== 4) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          type: "string",
          inclusive: true,
          message: "Last four numbers are required",
          path: ["lastFour"],
        });
      }

      if (paymentData.cardType == "") {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          type: "string",
          inclusive: true,
          message: "Card type is required",
          path: ["cardType"],
        });
      }

      if (
        paymentData.nameOnCard == null ||
        paymentData.nameOnCard.length === 0
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          type: "string",
          inclusive: true,
          message: "Name on card is required",
          path: ["nameOnCard"],
        });
      }
    }
  });

export type RecordManualPaymentFormState = z.infer<
  typeof RecordManualPaymentFormSchema
>;

export default function RecordManualPaymentDialog(
  props: RecordManualPaymentDialogProps,
) {
  const validPaymentMethods: PaymentMethodsValues[] =
    props.siteKeyDoc.customizations.manualPaymentMethods ?? paymentMethods;

  const recordManualPaymentFormDefaultValues: RecordManualPaymentFormState =
    useMemo(() => {
      return {
        amount: props.amountDue,
        paymentMethod: validPaymentMethods[0],
        paymentSource: "manual",
        checkNumber: null,
        cardType: "",
        lastFour: null,
        nameOnCard: null,
        memo: null,
      };
    }, [props.amountDue, validPaymentMethods]);

  const recordManualPaymentDialogHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.RECORD_MANUAL_PAYMENT}
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<RecordManualPaymentFormState>({
    defaultValues: recordManualPaymentFormDefaultValues,
    resolver: zodResolver(RecordManualPaymentFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(recordManualPaymentFormDefaultValues);
  }, [recordManualPaymentFormDefaultValues, reset]);

  function closeAndReset() {
    reset();
    props.closeDialog();
  }

  const onSubmit: SubmitHandler<RecordManualPaymentFormState> = async (
    formValues,
  ) => {
    const valuesToSave: NewManualPayment = {
      ...formValues,
      amount: typeof formValues.amount === "string" ? 0 : formValues.amount,
      cardType: formValues.cardType === "" ? null : formValues.cardType,
    };
    if (valuesToSave.amount === 0) return;

    await props.onRecordManualPayment(valuesToSave);
    closeAndReset();
  };

  const watchPaymentMethod = useWatch({
    control: control,
    name: "paymentMethod",
  });

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={props.isDialogOpen}
      title={recordManualPaymentDialogHeader}
      parentDivStyles="transform max-w-screen-md rounded-lg bg-white text-left align-middle shadow-xl transition-all overflow-visible"
    >
      <div className="relative flex flex-col space-y-8 px-8 pb-8 pt-2 text-lg">
        <div className="text-xl font-light">
          Amount Due:{" "}
          <span className="text-2xl font-bold">
            {DollarCurrency.format(props.amountDue)}
          </span>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-2 gap-8"
        >
          {/* Field: Amount */}
          <div>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <BaseInputNumber
                  text="Amount"
                  inputName="amount"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value === null ? "" : field.value}
                  onChange={(event) => {
                    const numberValue = event.target.valueAsNumber;
                    field.onChange(isNaN(numberValue) ? null : numberValue);
                  }}
                />
              )}
            />
            {errors.amount?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.amount.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Payment Method */}
          <div>
            <Controller
              name="paymentMethod"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="method"
                  text="Method"
                  admin={true}
                  required={true}
                  {...field}
                >
                  {validPaymentMethods.map((paymentMethod, index) => (
                    <option key={index} value={paymentMethod}>
                      {/* filter out saved card and saved check??? */}
                      {getReadablePaymentMethod(paymentMethod)}
                    </option>
                  ))}
                </BaseInputSelect>
              )}
            />
            {errors.paymentMethod?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.paymentMethod.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Check Number */}
          {watchPaymentMethod === "check" ? (
            <div>
              <Controller
                name="checkNumber"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Check Number"
                    inputName="checkNumber"
                    admin={true}
                    required={true}
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value !== "" ? e.target.value : null,
                      )
                    }
                    value={field.value ?? ""}
                  />
                )}
              />
              {errors.checkNumber?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.checkNumber.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          ) : null}

          {watchPaymentMethod === "credit_card" ? (
            <div>
              <Controller
                name="lastFour"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Last Four on Card"
                    inputName="lastFour"
                    admin={true}
                    required={true}
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value !== "" ? e.target.value : null,
                      )
                    }
                    value={field.value ?? ""}
                  />
                )}
              />
              {errors.lastFour?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.lastFour.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          ) : null}

          {watchPaymentMethod === "credit_card" ? (
            <div>
              <Controller
                name="nameOnCard"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Name on Card"
                    inputName="nameOnCard"
                    admin={true}
                    required={true}
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value !== "" ? e.target.value : null,
                      )
                    }
                    value={field.value ?? ""}
                  />
                )}
              />
              {errors.nameOnCard?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.nameOnCard.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          ) : null}

          {watchPaymentMethod === "credit_card" ? (
            <div>
              <Controller
                name="cardType"
                control={control}
                render={({ field }) => (
                  <BaseInputSelect
                    inputName="cardType"
                    text="Card Type"
                    admin={true}
                    required={true}
                    {...field}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {cardTypes.map((cardType, index) => (
                      <option key={index} value={cardType}>
                        {cardType}
                      </option>
                    ))}
                  </BaseInputSelect>
                )}
              />
              {errors.cardType?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.cardType.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          ) : null}

          {/* Memo */}
          <div className="col-span-2">
            <Controller
              name="memo"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Memo"
                  inputName="memo"
                  admin={true}
                  required={false}
                  rows={3}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              )}
            />
            {errors.memo?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.memo.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        </form>

        {/* Payment Date */}
        <div className="relative z-30 flex flex-wrap items-center gap-4">
          {props.children.datePicker}
        </div>

        {/* Invoices */}
        <div className="relative flex flex-wrap items-center gap-4">
          {props.children.multiInvoiceList}
          {props.children.multiInvoiceMessage}
        </div>

        {/* Action Buttons */}
        <div className="col-span-2 col-start-1 flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={closeAndReset}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
            onClick={handleSubmit(onSubmit)}
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
