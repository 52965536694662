// Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

// Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { TableViewPreference } from "../../models/table-view-preferences";
import BaseButtonSecondary from "../BaseButtonSecondary";
import ButtonColored from "../ButtonColored";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  savedViewList: TableViewPreference[];
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSetDefaultView: (viewToSetAsDefault: TableViewPreference) => void;
  deleteTableView: (viewToDelete: TableViewPreference) => void;
}

export default function EditTableViewsDialog(props: Props) {
  const editTableViewsHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.EDIT_TABLE_VIEWS}
      </h1>
      <button type="button" onClick={props.closeDialog}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={editTableViewsHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-md rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="-mt-4 flex flex-col space-y-4 p-8 text-lg">
        {props.savedViewList.map((view) => {
          return (
            <div
              key={view.id}
              className="grid grid-cols-3 items-center gap-4 capitalize"
            >
              {view.default === true ? `${view.name} - default` : view.name}
              <BaseButtonSecondary
                onClick={() => props.handleSetDefaultView(view)}
              >
                {strings.SET_DEFAULT_VIEW}
              </BaseButtonSecondary>
              <ButtonColored
                kind={"danger"}
                onClick={() => props.deleteTableView(view)}
              >
                {strings.DELETE_TABLE_VIEW}
              </ButtonColored>
            </div>
          );
        })}
      </div>
    </BaseModal>
  );
}
