import { ExistingChecklistPhoto } from "../../models/checklist-photo";

type HasTimestampCreated = {
  timestampCreated: ExistingChecklistPhoto["timestampCreated"];
};
export function sortResponsePhotos<T extends HasTimestampCreated>(
  responsePhotos: T[],
): T[] {
  return responsePhotos.sort(
    (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
  );
}
