//Libs

//Local
import { ExistingTask } from "../../../models/task";
import { TaskStatus } from "../../../models/task-status";

export function getBarColor(task: ExistingTask): string {
  switch (task.taskStatus) {
    case TaskStatus.AWAITING_ESTIMATE:
      return "#d97706"; // amber-700
    case TaskStatus.AWAITING_REVIEW:
      return "#6b21a8"; // purple-700
    case TaskStatus.AWAITING_APPROVAL:
      return "#6b21a8"; // purple-700
    case TaskStatus.AWAITING_SCHEDULE:
      return "#0ea5e9"; // sky-500
    case TaskStatus.AWAITING_PARTS:
      return "#7e22ce"; // purple-600
    case TaskStatus.PARTS_ORDERED:
      return "#9333ea"; // purple-500
    case TaskStatus.SCHEDULED:
      return "#000000"; // black
    case TaskStatus.AWAITING:
      return "#facc15"; // yellow-400
    case TaskStatus.IN_ROUTE:
      return "#38bdf8"; // sky-300
    case TaskStatus.AWAITING_PAYMENT:
      return "#ea580c"; // orange-600
    case TaskStatus.IN_PROGRESS:
      return "#ec4899"; // pink-400
    case TaskStatus.ON_HOLD:
      return "#f59e0b"; // amber-500
    case TaskStatus.COMPLETE:
      return "#15803d"; // green-700
    case TaskStatus.CANCELED:
      return "#374151"; // grey-700
    default:
      const _exhaustivenessCheck: never = task.taskStatus;
      return _exhaustivenessCheck;
  }
}
