//Local
import { Customer } from "../../models/customer";

interface FunctionProp {
  type: Customer["type"];
  name: Customer["name"];
  firstName: Customer["firstName"];
  lastName: Customer["lastName"];
}

export function formatCustomerName({
  type,
  name,
  firstName,
  lastName,
}: FunctionProp): string {
  if (type === "residential") {
    if (firstName === "" && lastName === "") {
      return `${name}`;
    } else if (firstName === "" && lastName !== "") {
      return `${lastName}`;
    } else if (firstName !== "" && lastName === "") {
      return `${firstName}`;
    } else {
      return `${firstName} ${lastName}`;
    }
  } else {
    return `${name}`;
  }
}
