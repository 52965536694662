//Libs
import { Fragment } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

//Local
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import MembershipTemplateListPage from "./MembershipTemplateListPage";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import * as strings from "../../strings";
import { useNavToCreateEditMembershipTemplate } from "../../navigation";
import { ExistingMembershipTemplate } from "../../models/membership-template";

export default function MembershipTemplateListContainer() {
  const navToCreateEditTemplate = useNavToCreateEditMembershipTemplate();

  /* STORES */
  const [membershipTemplateList, membershipTemplateListIsLoading] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  function goToEditMembershipTemplate(
    membershipTemplate: ExistingMembershipTemplate,
  ) {
    navToCreateEditTemplate(membershipTemplate.id, membershipTemplate);
  }

  /* COMPONENTS */
  const addNewMembershipTemplateButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => navToCreateEditTemplate()}
      className="w-full text-primary sm:w-auto"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.ADD_NEW_TEMPLATE}
    </BaseButtonPrimary>
  );

  /* RENDER LOADING */
  if (membershipTemplateListIsLoading || siteKeyDocIsLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <Fragment>
      <MembershipTemplateListPage
        membershipTemplateList={membershipTemplateList}
        currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
        addNewMembershipTemplateButton={addNewMembershipTemplateButton}
        onEditMambershipTemplate={goToEditMembershipTemplate}
      />
    </Fragment>
  );
}
