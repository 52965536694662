// Libs
import {
  Control,
  Controller,
  FieldErrors,
  useWatch,
  UseFormSetValue,
} from "react-hook-form";

// Local
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import { AddOrEditUserFormState } from "../../models/add-or-edit-user";
import { logger as devLogger } from "../../logging";
import { SiteKeyUserPermissionsManager } from "../../models/site-key-user-permissions";
import * as strings from "../../strings";

interface Props {
  control: Control<AddOrEditUserFormState, any>;
  errors: FieldErrors;
  setValue: UseFormSetValue<AddOrEditUserFormState>;
  isComplianceEnabled: boolean | null;
}

export default function PermissionsSwitches(props: Props): JSX.Element {
  const watchSiteAdmin = useWatch({
    control: props.control,
    name: "isSiteAdmin",
  });
  const watchPlantPersonnel = useWatch({
    control: props.control,
    name: "isPlantPersonnel",
  });

  return (
    <div className="mx-auto mb-8 w-full">
      <div className="grid gap-3">
        <h2 className="mt-10 text-xl font-medium text-primary sm:text-2xl lg:mt-0">
          {strings.SITE_APPROVAL}:
        </h2>
        {/* APPROVED */}
        <div className="flex items-center justify-between">
          <Controller
            name="approved"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName="Approved"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                  id="approved"
                />
              );
            }}
          />
        </div>

        {/* INACTIVE */}
        <div className="flex items-center justify-between">
          <Controller
            name="inactive"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName="Inactive"
                  name={name}
                  onBlur={onBlur}
                  onChange={(newValue) => {
                    if (newValue === false) {
                      props.setValue && props.setValue("approved", true);
                    } else if (newValue === true) {
                      props.setValue && props.setValue("approved", false);
                    } else {
                      devLogger.warn(
                        "Expected boolean value for newValue from 'inactive' switch",
                      );
                    }
                    onChange(newValue);
                  }}
                  ref={ref}
                  checked={value ?? false}
                />
              );
            }}
          />
        </div>
      </div>

      {/* SECTION: */}
      <div className="grid gap-3">
        <h2 className="mt-8 text-xl font-medium text-primary sm:text-2xl">
          {strings.USER_PERMISSIONS}:
        </h2>

        <h3 className="text-lg font-medium text-primary sm:text-xl">
          {strings.TASKS}
        </h3>
        {/* GETS NEW TASK NOTIFICATIONS */}
        <div className="flex items-center justify-between">
          <Controller
            name="getsNewTaskNotifications"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        {/* CAN CREATE TASKS */}
        <div className="flex items-center justify-between">
          <Controller
            name="canCreateTasks"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        {/* CAN UPDATE TASKS */}
        <div className="flex items-center justify-between">
          <Controller
            name="canUpdateTasks"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        {/* CAN UPDATE TASKS */}
        <div className="flex items-center justify-between">
          <Controller
            name="tasks_changeDate"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value ?? false}
                />
              );
            }}
          />
        </div>

        {/* CAN DELETE TASKS */}
        <div className="flex items-center justify-between">
          <Controller
            name="canDeleteTasks"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        <h3 className="mt-4 text-lg font-medium text-primary sm:text-xl">
          {strings.CRAFT_RECORDS}
        </h3>
        {/* CAN CREATE CRAFT RECORDS */}
        <div className="flex items-center justify-between">
          <Controller
            name="canCreateCraftRecords"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        {/* CAN UPDATE CRAFT RECORDS */}
        <div className="flex items-center justify-between">
          <Controller
            name="canUpdateCraftRecords"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        {/* CAN DELETE CRAFT RECORDS */}
        <div className="flex items-center justify-between">
          <Controller
            name="canDeleteCraftRecords"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        {/* Display compliance related permissions if the site has the compliance feature enabled. */}
        {props.isComplianceEnabled && (
          <CompliancePermissions control={props.control} />
        )}

        <h3 className="mt-4 text-lg font-medium text-primary sm:text-xl">
          {strings.GENERAL}
        </h3>
        {/* IS PLANT PERSONNEL */}
        <div className="flex items-center justify-between">
          <Controller
            name="isPlantPersonnel"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        {/* IS SITE ADMIN */}
        <div className="flex items-center justify-between">
          <Controller
            name="isSiteAdmin"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>

        {/* CAN EDIT CONTRACTOR DETAILS */}
        <div className="flex items-center justify-between">
          <Controller
            name="canEditContractorDetails"
            control={props.control}
            render={({ field }) => {
              const { name, onBlur, onChange, ref, value } = field;
              return (
                <StyledSwitchGroup
                  readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                    name,
                  )}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                />
              );
            }}
          />
        </div>
      </div>

      {/* SECTION: */}
      {watchSiteAdmin || watchPlantPersonnel ? (
        <ManagementNotifications control={props.control} />
      ) : (
        ""
      )}
    </div>
  );
}

function ManagementNotifications(props: {
  control: Control<AddOrEditUserFormState, any>;
}) {
  return (
    <div className="grid gap-3">
      <h2 className="mt-8 text-xl font-medium text-primary sm:text-2xl">
        {strings.MANAGEMENT_NOTIFICATIONS}:
      </h2>
      {/* NEW TASK CREATED */}
      <div className="flex items-center justify-between">
        <Controller
          name="newTaskCreated"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>

      {/* ALL TASK STATUS CHANGED */}
      <div className="flex items-center justify-between">
        <Controller
          name="allTaskStatusChanged"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>
    </div>
  );
}

function CompliancePermissions(props: {
  control: Control<AddOrEditUserFormState, any>;
}): JSX.Element {
  return (
    <>
      <h3 className="mt-4 text-lg font-medium text-primary sm:text-xl">
        {strings.COMPLIANCE}
      </h3>
      {/* Create Compliance Requirements */}
      <div className="flex items-center justify-between">
        <Controller
          name="complianceRequirements_create"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>

      {/* Read Compliance Requirements */}
      <div className="flex items-center justify-between">
        <Controller
          name="complianceRequirements_read"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>

      {/* Delete Compliance Requirements */}
      <div className="flex items-center justify-between">
        <Controller
          name="complianceRequirements_delete"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>

      {/* Create Compliance Responses */}
      <div className="flex items-center justify-between">
        <Controller
          name="complianceResponses_create"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>

      {/* Read Compliance Responses */}
      <div className="flex items-center justify-between">
        <Controller
          name="complianceResponses_read"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>

      {/* Read All Compliance Responses */}
      <div className="flex items-center justify-between">
        <Controller
          name="complianceResponses_readAll"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>

      {/* Delete Compliance Responses */}
      <div className="flex items-center justify-between">
        <Controller
          name="complianceResponses_delete"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>

      {/* Review Compliance Responses */}
      <div className="flex items-center justify-between">
        <Controller
          name="complianceResponses_review"
          control={props.control}
          render={({ field }) => {
            const { name, onBlur, onChange, ref, value } = field;
            return (
              <StyledSwitchGroup
                readableName={SiteKeyUserPermissionsManager.getReadableKeys(
                  name,
                )}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={value}
              />
            );
          }}
        />
      </div>
    </>
  );
}
