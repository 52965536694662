import { XMarkIcon } from "@heroicons/react/24/solid";
import { useRef, useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import { StyledTooltip } from "./StyledTooltip";

export interface Props {
  onDelete: () => Promise<void> | void;
}

/**
 * Small X button for deletion. Has built in "Delete" tooltip and busy state.
 */
export default function DeleteButtonSmallX(props: Props): JSX.Element {
  const ref = useRef<HTMLButtonElement>(null);
  const [isBusy, setIsBusy] = useState(false);
  return (
    <StyledTooltip title="Delete">
      <button
        ref={ref}
        disabled={isBusy}
        className={"flex items-center rounded-full p-1 hover:bg-gray-200"}
        onClick={async () => {
          // set animation
          setIsBusy(true);
          try {
            await props.onDelete();
          } finally {
            // stop animation
            if (ref.current) {
              setIsBusy(false);
            }
          }
        }}
      >
        {isBusy ? (
          <LoadingSpinner sizeClass={"w-4 h-4"} marginClass={""} />
        ) : (
          <XMarkIcon aria-label="delete button" className={"h-4 w-4"} />
        )}
      </button>
    </StyledTooltip>
  );
}
