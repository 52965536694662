//Libs
import React from "react";

//Local
import { ExistingTask } from "../models/task";
import StyledMessage from "./StyledMessage";

export interface Props {
  responsesCompleted: number;
  task: ExistingTask;
  showNotification: boolean;
  showAgreement: boolean;
  agreementMessage: string;
  children: {
    content: React.ReactNode;
    filterButton: JSX.Element;
    agreementButtons: React.ReactNode;
    submitButton: React.ReactNode;
    listOfResponsesButton: React.ReactNode;
  };
}

export default function ResponseCardsList(props: Props) {
  const totalNumberOfResponses =
    typeof props.task.taskSpecificDetails.totalNumberOfResponses === "number" ||
    typeof props.task.taskSpecificDetails.totalNumberOfResponses === "string"
      ? props.task.taskSpecificDetails.totalNumberOfResponses
      : "UNKNOWN";

  return (
    <div className="mx-2 rounded-md">
      <div className="py-3">
        <div className="flex items-center justify-between px-2 py-2">
          <span className="text-2xl font-medium text-primary sm:hidden">
            {props.responsesCompleted} / {totalNumberOfResponses} completed
          </span>
        </div>
        <div className="flex justify-between">
          <div>{props.children.filterButton}</div>
          <div>{props.children.listOfResponsesButton}</div>
        </div>
      </div>
      {/* Mapping the list of responses in their own cards */}
      <div>{props.children.content}</div>
      {props.showAgreement === true ? (
        <div>
          <div className="ml-1 mt-3 flex justify-center text-sm">
            <StyledMessage type="error" dismissible={false}>
              {{ message: props.agreementMessage }}
            </StyledMessage>
          </div>
          <div className="flex justify-center space-x-6 py-4">
            {props.children.agreementButtons}
          </div>
        </div>
      ) : (
        <div className="flex justify-center py-4">
          {props.children.submitButton}
        </div>
      )}
    </div>
  );
}
