//Libs
import { DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject } from "../utils";

export interface AssignedRoute {
  vehicleUsersMap: Record<string, string[]>; //where the first string is the vehicleID & the string[] is the list of users for that vehicle for that day
}

export interface ExistingAssignedRoute extends AssignedRoute {
  id: string; //this is a date eg.2024-01-31
  refPath: string;
}

export interface AssignedRoute_UpdateAPI extends AssignedRoute {
  siteKey: string;
  assignedRouteID: string;
}

// example of assignedRoute doc
//id: 2024-01-31
//refPath: something,
//vehicleUsersMap: {
// 'vehicleID1': ['userID1', 'userID2'],
// 'vehicleID2': ['userID2', 'userID3'],
// }

//needs to pay attention that user already assigned to another vehicle show some sort of error, ask for confirmation when in conflict maybe? if confirm, add to the current vehicle & remove for the vehicle where it was before

export const AssignedRouteManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingAssignedRoute object.
   */
  createFromFirestoreSnapshot: createAssignedRouteFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateAssignedRoute,
  /** Validate an existing assigned route. For the update endpoint. */
  parseUpdate: validateAssignedRoute_Update,
};

/**
 * Convert the Document Snapshot into a validated ExistingCustomer object.
 */
function createAssignedRouteFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingAssignedRoute {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateAssignedRoute({
      ...snapshotData,
    }),
  };
}

function validateAssignedRoute(value: unknown): AssignedRoute {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = assignedRouteSchema.parse(value);
  return result;
}

function validateAssignedRoute_Update(value: unknown): AssignedRoute_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return assignedRouteSchema_UpdateAPI.parse(value);
}

// Used when writing to the DB or reading from the user.
const assignedRouteSchema = z.object({
  vehicleUsersMap: z.record(z.array(z.string())),
});

const assignedRouteSchema_UpdateAPI = assignedRouteSchema.extend({
  siteKey: z.string().min(1).max(400),
  assignedRouteID: z.string().min(1).max(400),
});
