// Libs
import { Transition } from "@headlessui/react";
import { Fragment } from "react";

// Local
import { TheHeaderArea } from "../../components/ReviewRequest";

interface Props {
  merchantLogoURL: string | null;
  introText: string;
  feedbackIsPositive: boolean;
  feedbackIsNegative: boolean;

  // COMPONENTS
  children: {
    yesNoButtons: React.ReactNode;
    happyPanel: React.ReactNode;
    sadPanel: React.ReactNode;
  };
}

export default function ReviewRequestPage(props: Props): JSX.Element {
  return (
    <div className="mx-auto max-w-screen-lg px-4 pb-8 md:pb-12">
      <TheHeaderArea merchantLogoURL={props.merchantLogoURL} />

      <div className="mx-auto max-w-screen-md">
        <p className="mb-4 text-lg text-gray-700 md:mb-8">{props.introText}</p>
        {props.children.yesNoButtons}
      </div>

      <Transition
        as={Fragment}
        show={props.feedbackIsPositive}
        enter="ease-out duration-500"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
      >
        {props.children.happyPanel}
      </Transition>

      <Transition
        as={Fragment}
        show={props.feedbackIsNegative}
        enter="ease-out duration-500"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
      >
        {props.children.sadPanel}
      </Transition>
    </div>
  );
}
