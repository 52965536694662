//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { CraftTypeValues, OCraftTypes } from "./craft-types";
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";
import {
  InventoryLocationQuantity,
  inventoryLocationQuantitySchema,
} from "./inventory-location-quantity";

export enum InventoryObjStatusEnum {
  ACTIVE = 10,
  INACTIVE = 90,
}

export const inventoryObjectStatus = {
  ACTIVE: 10,
  INACTIVE: 90,
} as const;

export type InventoryObjectStatus =
  (typeof inventoryObjectStatus)[keyof typeof inventoryObjectStatus];

/** Typeguard ƒn */
export function isValidInventoryObjectStatus(
  val: unknown,
): val is InventoryObjectStatus {
  return Object.values(inventoryObjectStatus).includes(val as any);
}

export function getReadableInventoryObjectStatus(val: unknown): string {
  const inventoryObjectString: string | undefined =
    readableInventoryObjectStatus[val as InventoryObjectStatus];
  if (!inventoryObjectString) return "UNKNOWN";
  return inventoryObjectString;
}

/** Map craft integers to their human readable strings. */
export const readableInventoryObjectStatus: Record<
  InventoryObjectStatus,
  string
> = {
  [InventoryObjStatusEnum.ACTIVE]: "Active",
  [InventoryObjStatusEnum.INACTIVE]: "Inactive",
};

export interface InventoryObject {
  title: string;
  authorizedCompanies: string[];
  craftTypes: CraftTypeValues[];
  status: InventoryObjectStatus;
  quantities: Record<string, InventoryLocationQuantity>;
  priceBookItemID?: string;

  // Common fields
  timestampCreated: Timestamp;
  createdBy: string;
  timestampLastModified: Timestamp;
  lastModifiedBy: string;
  deleted: boolean;
}

export interface ExistingInventoryObject extends InventoryObject {
  id: string;
  refPath: string;
}

export interface ExistingInventoryObjectForTable {
  id: string;
  refPath: string;
  title: string;
  status: InventoryObjectStatus;
  inventoryLocationID: string;
  available: number;
  reserved: number;
  inUse: number;
  awaitingPickup: number;
  lowQuantityThreshold: number;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  InventoryObject,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface InventoryObject_CreateAPI extends withoutTimestamps {
  siteKey: string;
}

// #region SECTION: Functions
/** Utilities for interacting with InventoryObject objects  */
export const InventoryObjectManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingInventoryObject object.
   */
  createFromFirestoreSnapshot: createInventoryObjectFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateInventoryObject,
  /** Validate an InventoryObject doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateInventoryObjectWithFallbacks,
  /** Validate a new inventory object. For the create endpoint. */
  parseCreate: validateInventoryObject_Create,
};

/**
 * Convert the Document Snapshot into a validated ExistingInventoryObject object.
 */
function createInventoryObjectFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingInventoryObject {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateInventoryObjectWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateInventoryObjectWithFallbacks(value: unknown): InventoryObject {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = inventoryObjectSchemaWithFallbacks.parse(value);
  return result;
}

function validateInventoryObject(value: unknown): InventoryObject {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = inventoryObjectSchema.parse(value);
  return result;
}

function validateInventoryObject_Create(
  value: unknown,
): InventoryObject_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return inventoryObjectSchema_CreateAPI.parse(value);
}

// Used when writing to the DB or reading from the user.
const inventoryObjectSchema = z.object({
  title: z.string().min(1).max(500),
  authorizedCompanies: z.array(z.string().min(1).max(200)),
  craftTypes: z.nativeEnum(OCraftTypes).array(),
  status: z.nativeEnum(inventoryObjectStatus),
  quantities: z.record(inventoryLocationQuantitySchema),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  deleted: z.boolean(),
  priceBookItemID: z.string().optional(),
});

const inventoryObjectSchemaWithFallbacks = z.object({
  title: zFallback(
    z.string().min(1).max(500),
    "unknown",
    "inventoryObjectSchemaWithFallbacks: 'title",
  ),
  authorizedCompanies: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "inventoryObjectSchemaWithFallbacks: 'authorizedCompanies",
  ),
  craftTypes: zFallback(
    z.nativeEnum(OCraftTypes).array(),
    [],
    "inventoryObjectSchemaWithFallbacks: 'craftTypes'",
  ),
  status: zFallback(
    z.nativeEnum(inventoryObjectStatus),
    InventoryObjStatusEnum.ACTIVE,
    "inventoryObjectSchemaWithFallbacks: 'status'",
  ),
  quantities: zFallback(
    z.record(inventoryLocationQuantitySchema),
    {},
    "inventoryObjectSchemaWithFallbacks: 'quantities'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "inventoryObjectSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "inventoryObjectSchemaWithFallbacks: 'timestampLastModified'",
  ),
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "inventoryObjectSchemaWithFallbacks: 'createdBy'",
  ),
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "inventoryObjectSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "inventoryObjectSchemaWithFallbacks: 'deleted'",
  ),
  priceBookItemID: zFallback(
    z.string().optional(),
    undefined,
    "inventoryObjectSchemaWithFallbacks: 'priceBookItemID'",
  ),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = inventoryObjectSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const inventoryObjectSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
});
