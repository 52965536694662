// Libs
import { DateTime } from "luxon";
import { DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

// Local
import {
  ComplianceItemCommon,
  ComplianceItem_UnionSchema,
} from "./compliance-item";
import {
  complianceRequirementTypes,
  ComplianceRequirementTypes,
} from "./compliance-types-and-statuses";
import { guardIsPlainObject, convertFSTimestampToLuxonDT } from "../utils";
import { NotFoundError } from "../error-classes";

// siteKeys/{siteKey}/complianceRequirements
// #region SECTION: Types & Schemas
export type ComplianceRequirement = {
  // Interesting bits
  title: string;
  description: string;
  type: ComplianceRequirementTypes;
  customFields: Record<string, ComplianceItemCommon>; // string is essentially the ComplianceItem ID.

  // Common properties every document wants
  /**
   * In the app, this is a Luxon DateTime object. When storing to the DB, we convert
   * it to a Firestore Timestamp. When calling the API, we send an ISO datetime string.
   */
  timestampCreated: DateTime;
  /**
   * In the app, this is a Luxon DateTime object. When storing to the DB, we convert
   * it to a Firestore Timestamp. When calling the API, we send an ISO datetime string.
   */
  timestampLastModified: DateTime;
  createdBy: string; // uid
  lastModifiedBy: string; // uid
  deleted: boolean;
};

type FirestoreAttributes = {
  id: string;
  refPath: string;
};

export type ExistingComplianceRequirement = ComplianceRequirement &
  FirestoreAttributes;

const CommonSchema = z.object({
  title: z.string().min(1).max(2000),
  description: z.string().min(1).max(2000),
  type: z.enum(complianceRequirementTypes),
  customFields: z.record(ComplianceItem_UnionSchema),

  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  deleted: z.boolean(),
});

/** The schema used within the app. */
const RequirementSchema = CommonSchema.extend({
  // z.any() as ZodType<DateTime>; // this would work too (supposedly)
  timestampCreated: z.custom<DateTime>(),
  timestampLastModified: z.custom<DateTime>(),
});

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  ComplianceRequirement,
  "timestampCreated" | "timestampLastModified"
>;
export interface ComplianceRequirement_CreateAPI extends withoutTimestamps {
  siteKey: string;
  /** ISO datetime string */
  timestampCreated: string;
  /** ISO datetime string */
  timestampLastModified: string;
}
/** The schema used for interacting with the Create endpoint */
const RequirementSchema_CreateAPI = CommonSchema.extend({
  siteKey: z.string().min(1).max(2000),
  timestampCreated: z.string().min(1).max(200),
  timestampLastModified: z.string().min(1).max(200),
});

export interface ComplianceRequirement_DeleteAPI {
  siteKey: string;
  requirementID: string;
}
const RequirementSchema_DeleteAPI = z.object({
  siteKey: z.string().min(1).max(2000),
  requirementID: z.string().min(1).max(2000),
});
// #endregion Types & Schemas

// #region SECTION: Functions
/** Collection of functions that interact with ComplianceRequirement docs */
export const ComplianceRequirementManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingComplianceRequirement
   * object. Converts timestamp fields from Firestore Timestamps to Luxon DateTime
   * objects. @throws NotFoundError if the snapshot does not exist.
   */
  createFromSnapshot: createRequirementFromSnapshot,
  /** Validate a ComplianceRequirement doc. */
  parse: validateComplianceRequirement,
  /**
   * Validate a ComplianceRequirement doc that's coming from/going to the create
   * endpoint - timestamp fields are ISO datetime strings. Also need the siteKey,
   * which doesn't exist on the 'normal' ComplianceRequirement doc.
   */
  parseCreateAPI: validateCreateAPI,
  /** Ensure there's a siteKey and complianceRequirementID to send to the backend */
  parseDeleteAPI: validateDeleteAPI,
};

// Tested ✅
function createRequirementFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingComplianceRequirement {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapData = snapshot.data();
  // Must convert Firestore Timestamps to Luxon DateTime objects before validating.
  if (snapData != null) {
    snapData.timestampCreated = convertFSTimestampToLuxonDT(
      snapData.timestampCreated,
    );
    snapData.timestampLastModified = convertFSTimestampToLuxonDT(
      snapData.timestampLastModified,
    );
  }

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateComplianceRequirement(snapData),
  };
}

function validateComplianceRequirement(doc: unknown): ComplianceRequirement {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`ComplianceRequirement is not an object: ${doc}`);
  }
  return RequirementSchema.parse(doc);
}

function validateCreateAPI(doc: unknown): ComplianceRequirement_CreateAPI {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`ComplianceRequirement is not an object: ${doc}`);
  }
  return RequirementSchema_CreateAPI.parse(doc);
}

function validateDeleteAPI(
  deleteData: unknown,
): ComplianceRequirement_DeleteAPI {
  if (!guardIsPlainObject(deleteData)) {
    throw new Error(`Delete requirement is not an object: ${deleteData}`);
  }
  return RequirementSchema_DeleteAPI.parse(deleteData);
}
// #endregion Functions
