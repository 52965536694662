// libs
import { MdInfo } from "react-icons/md";

// local
import { ReportConfig, ReportData, ReportSpec } from "../../models/reports";
import StyledMessage from "../../components/StyledMessage";
import ReportsPageHeader from "../../components/reports/ReportsPageHeader";
import ReportsTemplateListItem from "../../components/reports/ReportsTemplateListItem";
import ReportsGeneratedItem from "../../components/reports/ReportsGeneratedItem";
import ReportsSavedItem from "../../components/reports/ReportsSavedItem";
import * as strings from "../../strings";

interface Props {
  siteKey: string;
  reportSpecs: Record<string, ReportSpec>;
  savedReports: ReportConfig[];
  generatedReports: ReportData[];
  reportTypesLoading: boolean;
  downloadReport: (siteKeyID: string, reportConfigID: string) => Promise<void>;
  emailReport: (siteKeyID: string, reportConfigID: string) => Promise<void>;
  deleteReport: (siteKeyID: string, reportDataID: string) => Promise<void>;
  handleNavToReportSpecPage: (
    reportType: string,
    reportSpec: ReportSpec,
    reportConfig?: ReportConfig,
  ) => void;
}

// TODO: fix unique keys warning
export default function ReportsListLayout(props: Props) {
  return (
    <div className="flex flex-col items-center">
      <div className="w-full sm:w-[800px]">
        <div className="pb-6">
          <ReportsPageHeader title={strings.REPORTS} backButtonHref="/" />
        </div>
        <StyledMessage type="info" dismissible={false}>
          {{
            message:
              "Select a Report Template below to run an on-demand report or subscribe to automated report emails",
            icon: <MdInfo />,
          }}
        </StyledMessage>

        <div className="mt-12 rounded-md border border-gray-300">
          <div className="rounded-t-md border-b border-gray-300 bg-gray-50">
            <span className="block px-6 py-5">
              <h3 className="text-xl font-semibold capitalize">
                {strings.CREATE_REPORT_FROM_TEMPLATE}
              </h3>
              <p className="mt-0.5 text-sm text-gray-400">
                Select from one of the templates below to create a new report
                configuration with your preferred settings
              </p>
            </span>
          </div>
          {props.reportTypesLoading ? (
            <p className="animate-pulse py-3 text-center">
              {strings.buttons.BUSY_LOADING}
            </p>
          ) : (
            Object.keys(props.reportSpecs)
              .sort((a, b) => a.localeCompare(b))
              .map((reportType) => (
                <ReportsTemplateListItem
                  key={reportType}
                  reportSpec={props.reportSpecs[reportType]}
                  reportSpecID={reportType}
                  handleNavToReportSpecPage={props.handleNavToReportSpecPage}
                />
              ))
          )}
        </div>

        <div className="my-12 rounded-md border border-gray-300">
          <div className="rounded-t-md border-b border-gray-300 bg-gray-50 px-6 py-5">
            <h3 className="text-xl font-semibold capitalize">
              {strings.SAVED_REPORTS}
            </h3>
            <p className="mt-0.5 text-sm text-gray-400">
              Edit your saved report configurations or generate an on-demand
              report via download or email
            </p>
          </div>
          <div className="px-6 py-2">
            {props.savedReports.map((report, i) => {
              const reportSpec = props.reportSpecs[report.type];
              return !reportSpec ? (
                <></>
              ) : (
                <ReportsSavedItem
                  key={`${report.timestampLastModified}-${i}`}
                  siteKeyID={props.siteKey}
                  savedReport={report}
                  reportSpec={reportSpec}
                  downloadReport={props.downloadReport}
                  emailReport={props.emailReport}
                  handleNavToReportSpecPage={props.handleNavToReportSpecPage}
                />
              );
            })}
          </div>
        </div>

        <div className="my-12 rounded-md border border-gray-300">
          <div className="rounded-t-md border-b border-gray-300 bg-gray-50 px-6 py-5">
            <h3 className="text-xl font-semibold capitalize">
              {strings.GENERATED_REPORTS}
            </h3>
            <p className="mt-0.5 text-sm text-gray-400">
              Download a previously generated report
            </p>
          </div>
          <div className="px-6 py-2">
            {props.generatedReports.map((generatedReport) =>
              !generatedReport.id || !generatedReport.reportConfig.id ? (
                <></>
              ) : (
                <ReportsGeneratedItem
                  siteKeyID={props.siteKey}
                  key={generatedReport.id}
                  reportDataID={generatedReport.id}
                  reportConfigID={generatedReport.reportConfig.id}
                  reportConfigName={generatedReport.reportConfig.configName}
                  createdAtISO={generatedReport.timestampCreated}
                  reportSpecName={generatedReport.reportSpec.reportName}
                  downloadReport={props.downloadReport}
                  emailReport={props.emailReport}
                  deleteReport={props.deleteReport}
                />
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
