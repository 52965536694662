// Libs
import { PencilIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import {
  Controller,
  Control,
  FieldErrors,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { z } from "zod";

// Local
import AdminModalSecondary from "../../AdminModalSecondary";
import BaseButtonPrimary from "../../BaseButtonPrimary";
import BaseInputSelect from "../../BaseInputSelect";
import BaseInputText from "../../BaseInputText";
import StyledMessage from "../../StyledMessage";
import {
  CustomFieldDocData,
  CustomFieldTypes,
  customFieldTypes,
  customFieldTypesExcludingStrArr,
  CustomFieldTypesExcludingStrArr,
  getReadableCustomFieldType,
} from "../../../models/custom-field";
import BaseInputCheckbox from "../../BaseInputCheckbox";
import BaseInputNumber from "../../BaseInputNumber";
import BaseRadioSelect from "../../BaseRadioSelect";
import {
  CraftTypeValues,
  getReadableCraftType,
  getValidTaskTypes,
} from "../../../models/craft-types";
import { getReadableTaskType, OTaskTypes } from "../../../models/task-types";
import BaseButtonSecondary from "../../BaseButtonSecondary";
import {
  getReadableTaskStatus,
  OTaskStatus,
  TaskStatusValues,
} from "../../../models/task-status";
import { StringForm } from "./StringForm";
import { BooleanForm } from "./BooleanForm";
import { NumberForm } from "./NumberForm";
import { TimestampForm } from "./TimestampForm";
import { SelectionForm } from "./SelectionForm";
import { UidForm } from "./UidForm";
import { MultipleUidForm } from "./MultipleUidForm";
import { CurrencyForm } from "./CurrencyForm";
import StyledSwitchGroup from "../../StyledSwitchGroup";
import * as strings from "../../../strings";
import { HoursMinutesForm } from "./HoursMinutesForm";
import BaseInputTextArea from "../../BaseInputTextArea";
import { StringTextareaForm } from "./StringTextareaForm";

// #region SECTION: Schemas for react-hook-form resolver: Different than
// what we validate against before saving to the database.
const OptionZero = { None: 0 } as const;
const CommonSchema_RHF = z.object({
  taskType: z.nativeEnum(
    { ...OTaskTypes, ...OptionZero },
    {
      errorMap: (_issue, _ctx) => {
        return { message: "Required" };
      },
    },
  ),

  title: z.string({ required_error: "Title is required" }).min(1).max(200),
  editable: z.boolean(),
  required: z.boolean(),
  hideOnCraftRecordCreation: z.boolean(),
  onTaskStatus: z
    .array(z.nativeEnum(OTaskStatus).or(z.literal(undefined)))
    .optional(),
});

export const BooleanSchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z
    .enum(["Yes", "No", ""])
    .refine((val) => (val === "" ? false : true), {
      message: "Default Value is required",
    }),
});

export const NumberSchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z.number().nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const HoursMinutesSchema_RHF = CommonSchema_RHF.extend({
  defaultValueHours: z.number().nonnegative().nullable(),
  defaultValueMinutes: z
    .number()
    .nonnegative()
    .max(59, { message: strings.ERR_MINUTES_BTWN_0_59 })
    .nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const StringSchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z.string(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const StringTextareaSchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z.string(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const TimestampSchema_RHF = CommonSchema_RHF;

export const SelectionSchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z.string(),
  selectionOptions: z.array(z.record(z.string(), z.string().min(1))),
});

export const UidSchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z.string().optional(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const MultipleUidSchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z.array(z.record(z.string())).optional().nullable(),
});

export const CurrencySchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z.number().nullable(),
});

export const StringArraySchema_RHF = CommonSchema_RHF.extend({
  defaultValue: z.array(z.record(z.string())).optional().nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});
// #endregion

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  workType: CraftTypeValues | undefined;
  handleSave: (data: CustomFieldDocData) => Promise<void>;
  taskStatusList: TaskStatusValues[];
}

export default function AddCustomFieldDialog(props: Props) {
  // Display the relevant form after the user has selected a field type.
  const [selectedFieldType, setSelectedFieldType] =
    useState<null | CustomFieldTypesExcludingStrArr>(null);

  let SelectedForm: null | React.FunctionComponent<FormComponentInterface> =
    null;
  if (selectedFieldType) {
    SelectedForm = formMap[selectedFieldType];
  }

  return (
    <AdminModalSecondary
      edit={false}
      open={props.isDialogOpen}
      closeModal={() => {
        props.closeDialog();
        setSelectedFieldType(null);
      }}
      workType={getReadableCraftType(props.workType)}
    >
      <div className="mt-8 px-4 md:mt-10 md:px-6">
        {/* FIELD TYPE INPUT (Dropdown) */}
        <div className="mb-5 sm:w-1/2 sm:pr-4">
          <BaseInputSelect
            text="Field Type"
            inputName="fieldType"
            admin={true}
            id="fieldType"
            onChange={(event) => {
              if (
                isValidCustomFieldType(event.target.value) &&
                event.target.value !== "string-array"
              ) {
                setSelectedFieldType(event.target.value);
              }
            }}
          >
            <option value="" disabled>
              Select
            </option>
            {customFieldTypesExcludingStrArr.map((type) => (
              <option key={type} value={type}>
                {getReadableCustomFieldType(type)}
              </option>
            ))}
          </BaseInputSelect>
        </div>
        <div className="grid gap-x-4 gap-y-9 sm:grid-cols-2 md:gap-x-7 lg:grid-cols-3">
          {SelectedForm && (
            <SelectedForm
              workType={props.workType}
              handleSave={props.handleSave}
              closeDialog={() => {
                props.closeDialog();
                setSelectedFieldType(null);
              }}
              taskStatusList={props.taskStatusList}
            />
          )}
        </div>
      </div>
    </AdminModalSecondary>
  );
}

// Typeguards
function isValidCustomFieldType(val: unknown): val is CustomFieldTypes {
  return customFieldTypes.includes(val as any);
}
// Used with the individual forms, to get rid of undefined onTaskStatus values.
export function isDefinedOnTaskStatus(
  val: TaskStatusValues | undefined,
): val is TaskStatusValues {
  return val !== undefined;
}

// Interface for the individual forms.
export interface FormComponentInterface {
  workType: CraftTypeValues | undefined;
  handleSave: (data: CustomFieldDocData) => Promise<void>;
  closeDialog: () => void;
  taskStatusList: TaskStatusValues[];
}

const formMap: Record<
  CustomFieldTypesExcludingStrArr,
  React.FunctionComponent<FormComponentInterface>
> = {
  string: StringForm,
  "string-textarea": StringTextareaForm,
  bool: BooleanForm,
  number: NumberForm,
  timestamp: TimestampForm,
  selection: SelectionForm,
  uid: UidForm,
  "multiple-uid": MultipleUidForm,
  currency: CurrencyForm,
  // "string-array": StringArrayForm,
  "hours-minutes": HoursMinutesForm,
};

export function ActionButtons(props: {
  submitting: boolean;
  onCancel: () => void;
}): JSX.Element {
  return (
    <div className="text-right xs:flex xs:flex-row-reverse xs:justify-between sm:col-span-2 sm:justify-start lg:col-span-3">
      <BaseButtonPrimary
        className="w-full xs:w-52"
        type="submit"
        disabled={props.submitting}
        isBusy={props.submitting}
        busyText={strings.buttons.BUSY_SAVING}
      >
        <PencilIcon aria-label="save custom field" className="mr-4 h-6" />
        {strings.buttons.SAVE}
      </BaseButtonPrimary>
      <BaseButtonSecondary
        onClick={props.onCancel}
        className="mt-6 w-full xs:mt-0 xs:w-52 sm:mr-6"
        type="button"
      >
        <XCircleIcon aria-label="cancel custom field" className="mr-4 h-6" />
        {strings.buttons.CANCEL}
      </BaseButtonSecondary>
    </div>
  );
}

// #region SECTION: Each form field
export interface FormFieldInterface {
  control: Control<any, any>;
  errors: FieldErrors;
}

export function TitleField(props: FormFieldInterface): JSX.Element {
  return (
    <div>
      <Controller
        name="title"
        control={props.control}
        render={({ field }) => (
          <BaseInputText
            text="Title"
            inputName="title"
            admin={true}
            {...field}
          />
        )}
      />
      {props.errors.title?.message &&
        typeof props.errors.title.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.title.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}

export function TaskTypeField(
  props: FormFieldInterface & { workType: CraftTypeValues | undefined },
): JSX.Element {
  const taskTypeList = getValidTaskTypes(props.workType);

  return (
    <div>
      <Controller
        name="taskType"
        control={props.control}
        render={({ field }) => (
          <BaseInputSelect
            text="Task Type"
            inputName="taskType"
            admin={true}
            id="taskType"
            {...field}
            onChange={(event) => {
              const numberValue = parseInt(event.target.value);
              field.onChange(isNaN(numberValue) ? null : numberValue);
            }}
            defaultValue={-2}
          >
            {/* Because of the onChange logic, use -2 instead of an empty string */}
            <option value={-2} disabled>
              Select
            </option>
            <option value={0}>None</option>
            {taskTypeList.map((type) => (
              <option key={type} value={type}>
                {getReadableTaskType(type)}
              </option>
            ))}
          </BaseInputSelect>
        )}
      />
      {props.errors.taskType?.message &&
        typeof props.errors.taskType.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.taskType.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}

export function OnTaskStatusField({
  taskStatusLabel = "On Task Status",
  ...props
}: Omit<FormFieldInterface, "errors"> & {
  taskStatusList: TaskStatusValues[];
  taskStatusLabel?: string;
}): JSX.Element {
  return (
    <div className="relative grid grid-cols-1 rounded border border-black p-4 xs:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 md:pl-2 md:pr-0 lg:col-span-2 lg:px-4">
      <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
        {taskStatusLabel}
      </label>
      <span className="contents sm:col-span-1">
        {props.taskStatusList.map((status, index) => (
          <Controller
            key={status}
            control={props.control}
            name={`onTaskStatus.${index}`}
            render={({ field }) => (
              <BaseInputCheckbox
                id={getReadableTaskStatus(status)}
                label={getReadableTaskStatus(status)}
                {...field}
                value={status}
                checked={field.value === status}
                onChange={(event) => {
                  field.onChange(event.target.checked ? status : undefined);
                }}
              />
            )}
          />
        ))}
      </span>
    </div>
  );
}

export function MinField(
  props: FormFieldInterface & { isStringBased: boolean },
): JSX.Element {
  return (
    <div>
      <Controller
        name="min"
        control={props.control}
        render={({ field }) => (
          <BaseInputNumber
            text={props.isStringBased ? "Min Length" : "Min"}
            inputName="min"
            admin={true}
            {...field}
            value={field.value === null ? "" : field.value}
            onChange={(event) => {
              const numberValue = event.target.valueAsNumber;
              field.onChange(isNaN(numberValue) ? null : numberValue);
            }}
          />
        )}
      />
      {props.errors.min?.message &&
        typeof props.errors.min.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.min.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}

export function MaxField(
  props: FormFieldInterface & { isStringBased: boolean },
): JSX.Element {
  return (
    <div>
      <Controller
        name="max"
        control={props.control}
        render={({ field }) => (
          <BaseInputNumber
            text={props.isStringBased ? "Max Length" : "Max"}
            inputName="max"
            admin={true}
            {...field}
            value={field.value === null ? "" : field.value}
            onChange={(event) => {
              const numberValue = event.target.valueAsNumber;
              field.onChange(isNaN(numberValue) ? null : numberValue);
            }}
          />
        )}
      />
      {props.errors.max?.message &&
        typeof props.errors.max.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.max.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}

export function BooleanDefaultValueField(
  props: FormFieldInterface,
): JSX.Element {
  return (
    <div className="flex flex-col justify-center">
      <p>Default Value</p>
      <div className="mt-1 flex w-36 justify-between">
        <Controller
          name="defaultValue"
          control={props.control}
          render={({ field }) => (
            <BaseRadioSelect id="true" label="Yes" {...field} value="Yes" />
          )}
        />
        <Controller
          name="defaultValue"
          control={props.control}
          render={({ field }) => (
            <BaseRadioSelect id="false" label="No" {...field} value="No" />
          )}
        />
      </div>
      {props.errors.defaultValue?.message &&
        typeof props.errors.defaultValue.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.defaultValue.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}

export function NumberDefaultValueField(
  props: FormFieldInterface,
): JSX.Element {
  return (
    <div>
      <Controller
        name="defaultValue"
        control={props.control}
        render={({ field }) => (
          <BaseInputNumber
            text="Default Value"
            inputName="defaultValue"
            admin={true}
            {...field}
            value={field.value === null ? "" : field.value}
            onChange={(event) => {
              const numberValue = event.target.valueAsNumber;
              field.onChange(isNaN(numberValue) ? null : numberValue);
            }}
          />
        )}
      />
      {props.errors.defaultValue?.message &&
        typeof props.errors.defaultValue.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.defaultValue.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}

export function StringDefaultValueField(
  props: FormFieldInterface,
): JSX.Element {
  return (
    <div>
      <Controller
        name="defaultValue"
        control={props.control}
        render={({ field }) => (
          <BaseInputText
            text="Default Value"
            inputName="defaultValue"
            admin={true}
            {...field}
          />
        )}
      />
      {props.errors.defaultValue && !Array.isArray(props.errors.defaultValue)
        ? props.errors.defaultValue.message &&
          typeof props.errors.defaultValue.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.defaultValue.message }}
              </StyledMessage>
            </div>
          )
        : ""}
      {props.errors.defaultValue && Array.isArray(props.errors.defaultValue)
        ? props.errors.defaultValue[0].message &&
          typeof props.errors.defaultValue.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.defaultValue[0].message }}
              </StyledMessage>
            </div>
          )
        : ""}
    </div>
  );
}

export function StringTextareaDefaultValueField(
  props: FormFieldInterface,
): JSX.Element {
  return (
    <div>
      <Controller
        name="defaultValue"
        control={props.control}
        render={({ field }) => (
          <BaseInputTextArea
            rows={2}
            text="Default Value"
            inputName="defaultValue"
            admin={true}
            {...field}
          />
        )}
      />
      {props.errors.defaultValue && !Array.isArray(props.errors.defaultValue)
        ? props.errors.defaultValue.message &&
          typeof props.errors.defaultValue.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.defaultValue.message }}
              </StyledMessage>
            </div>
          )
        : ""}
      {props.errors.defaultValue && Array.isArray(props.errors.defaultValue)
        ? props.errors.defaultValue[0].message &&
          typeof props.errors.defaultValue.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.defaultValue[0].message }}
              </StyledMessage>
            </div>
          )
        : ""}
    </div>
  );
}

export function StringListDefaultValueField(
  props: Omit<FormFieldInterface, "control">,
): JSX.Element {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "defaultValue",
  });

  // Adds another input field
  const handleAppend = (arg: { addDV: "" }) => {
    append(arg.addDV);
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <div className="flex flex-col justify-center">
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id} className="mb-4 flex justify-between">
              <Controller
                name={`defaultValue[${index}].addDV`}
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    inputName={`Default Value ${index + 1}`}
                    text={`Default Value ${index + 1}`}
                    admin={true}
                    {...field}
                    className="mr-2 w-full"
                  />
                )}
              />
              <button type="button" onClick={() => handleRemove(index)}>
                <XMarkIcon aria-label="remove default value" className="h-5" />
              </button>
            </li>
          );
        })}
      </ul>

      <div className="justify-self-center">
        <BaseButtonSecondary
          type="button"
          onClick={() => handleAppend({ addDV: "" })}
        >
          {strings.buttons.ADD_DEFAULT_VALUE}
        </BaseButtonSecondary>
      </div>

      {props.errors.defaultValue && Array.isArray(props.errors.defaultValue)
        ? props.errors.defaultValue[0].message &&
          typeof props.errors.defaultValue.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.defaultValue[0].message }}
              </StyledMessage>
            </div>
          )
        : ""}
    </div>
  );
}

export function SelectionDefaultValueField(
  props: FormFieldInterface & { selectionOptions?: Record<string, string>[] },
): JSX.Element {
  return (
    <div>
      <Controller
        name="defaultValue"
        control={props.control}
        render={({ field }) => (
          <BaseInputSelect
            text="Default Value"
            inputName="defaultValue"
            admin={true}
            id="defaultValue"
            {...field}
          >
            <option value="" disabled>
              Select
            </option>

            {props.selectionOptions ? (
              props.selectionOptions.map((object) =>
                Object.values(object)
                  // Filter out empty strings before mapping the JSX elements
                  .filter((opt) => opt !== "")
                  .map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  )),
              )
            ) : (
              <option disabled>Add a selection option first.</option>
            )}
          </BaseInputSelect>
        )}
      />
      {props.errors.defaultValue
        ? props.errors.defaultValue.message &&
          typeof props.errors.defaultValue.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.defaultValue.message }}
              </StyledMessage>
            </div>
          )
        : ""}
    </div>
  );
}

export function HoursDefaultValueField(props: FormFieldInterface): JSX.Element {
  return (
    <div>
      <Controller
        name="defaultValueHours"
        control={props.control}
        render={({ field }) => (
          <BaseInputNumber
            text="Default Value - Hours"
            inputName="defaultValueHours"
            admin={true}
            {...field}
            value={field.value === null ? "" : field.value}
            onChange={(event) => {
              const numberValue = event.target.valueAsNumber;
              field.onChange(isNaN(numberValue) ? null : numberValue);
            }}
          />
        )}
      />
      {props.errors.defaultValueHours?.message &&
        typeof props.errors.defaultValueHours.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.defaultValueHours.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}
export function MinutesDefaultValueField(
  props: FormFieldInterface,
): JSX.Element {
  return (
    <div>
      <Controller
        name="defaultValueMinutes"
        control={props.control}
        render={({ field }) => (
          <BaseInputNumber
            text="Default Value - Minutes"
            inputName="defaultValueMinutes"
            admin={true}
            {...field}
            value={field.value === null ? "" : field.value}
            onChange={(event) => {
              const numberValue = event.target.valueAsNumber;
              field.onChange(isNaN(numberValue) ? null : numberValue);
            }}
          />
        )}
      />
      {props.errors.defaultValueMinutes?.message &&
        typeof props.errors.defaultValueMinutes.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.defaultValueMinutes.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}

export function SelectionOptionsField(
  props: Omit<FormFieldInterface, "control">,
): JSX.Element {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "selectionOptions",
  });

  // Adds another input field
  const handleAppend = (arg: { addSO: "" }) => {
    append(arg.addSO);
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <div className="flex flex-col justify-center">
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id} className="mb-4 flex justify-between">
              <Controller
                name={`selectionOptions[${index}].addSO`}
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    inputName={`Option ${index + 1}`}
                    text={`Option ${index + 1}`}
                    admin={true}
                    {...field}
                    className="mr-2 w-full"
                  />
                )}
              />
              <button type="button" onClick={() => handleRemove(index)}>
                <XMarkIcon className="h-5" />
              </button>
            </li>
          );
        })}
      </ul>

      <div className="justify-self-center">
        <BaseButtonSecondary
          type="button"
          onClick={() => handleAppend({ addSO: "" })}
        >
          {strings.buttons.ADD_SELECTION_OPTION}
        </BaseButtonSecondary>
      </div>

      {props.errors.selectionOptions?.message &&
        typeof props.errors.selectionOptions.message === "string" && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: props.errors.selectionOptions.message }}
            </StyledMessage>
          </div>
        )}
    </div>
  );
}

export function EditableAndRequiredFields(
  props: Pick<FormFieldInterface, "control">,
): JSX.Element {
  return (
    <>
      <div className="mx-0.5 flex items-center justify-between">
        <Controller
          name="editable"
          control={props.control}
          render={({ field }) => (
            <StyledSwitchGroup
              id="Editable"
              readableName="Editable"
              checked={field.value}
              {...field}
            />
          )}
        />
      </div>

      <div className="mx-0.5 flex items-center justify-between">
        <Controller
          name="required"
          control={props.control}
          render={({ field }) => (
            <StyledSwitchGroup
              id="Required"
              readableName="Required"
              checked={field.value}
              {...field}
            />
          )}
        />
      </div>
    </>
  );
}

export function HideOnCRCreationField(
  props: Pick<FormFieldInterface, "control">,
): JSX.Element {
  return (
    <div className="mx-0.5 flex items-center justify-between">
      <Controller
        name="hideOnCraftRecordCreation"
        control={props.control}
        render={({ field }) => (
          <StyledSwitchGroup
            id="hideOnCraftRecordCreation"
            readableName="Hide On Craft Record Creation"
            checked={field.value}
            {...field}
          />
        )}
      />
    </div>
  );
}
// #endregion  Each form field
