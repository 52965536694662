//Libs
import { z } from "zod";
import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import { ExistingStiltInvoice } from "../../models/invoice";
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputText from "../BaseInputText";
import BaseModal from "../BaseModal";
import BaseInputTextArea from "../BaseInputTextArea";

interface Props {
  //DATA
  isDialogOpen: boolean;
  invoiceDoc: ExistingStiltInvoice;
  issueDate: React.ReactNode;
  dueDate: React.ReactNode;
  paymentTerms: React.ReactNode;
  closeDialog: () => void;
  //FUNCTIONS
  handleSave: (formValues: EditInvoiceFormState) => Promise<void>;
}

export const EditInvoiceFormSchema = z.object({
  note: z.string().min(0).max(8000),
  internalNotes: z.string().max(8000).nullable(),
  poNumber: z.string().min(1).max(200).nullable(),
  email: z.string().min(0).max(200).nullable(),
});

export type EditInvoiceFormState = z.infer<typeof EditInvoiceFormSchema>;

export default function EditInvoiceDialog(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const editInvoiceFormDefaultValue: EditInvoiceFormState = useMemo(() => {
    return {
      poNumber: props.invoiceDoc.poNumber,
      internalNotes: props.invoiceDoc.internalNotes,
      note: props.invoiceDoc.note,
      email: props.invoiceDoc.email,
    };
  }, [props.invoiceDoc]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<EditInvoiceFormState>({
    defaultValues: editInvoiceFormDefaultValue,
    resolver: zodResolver(EditInvoiceFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(editInvoiceFormDefaultValue);
  }, [editInvoiceFormDefaultValue, reset]);

  function closeAndReset() {
    reset();
    setDisplayError(false);
    props.closeDialog();
  }

  const errorMessage = (
    <ErrorMessage
      message="Value hasn't changed."
      clearMessage={() => setDisplayError(false)}
    />
  );

  const onSubmit: SubmitHandler<EditInvoiceFormState> = async (formValues) => {
    const valuesToSend: EditInvoiceFormState = {
      ...formValues,
      internalNotes:
        formValues.internalNotes === "" ? null : formValues.internalNotes,
      poNumber: formValues.poNumber === "" ? null : formValues.poNumber,
      email: formValues.email === "" ? null : formValues.email,
    };

    await props.handleSave(valuesToSend);
    // Close dialog if successful.
    closeAndReset();
  };

  const editInvoiceHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.buttons.EDIT_INVOICE}
      </h1>
      <button type="button" onClick={() => closeAndReset()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={props.isDialogOpen}
      title={editInvoiceHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col p-8 text-lg">
        <div className="mb-8 flex items-center justify-between">
          {props.issueDate}
          {props.dueDate}
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          {/* Field: PO Number */}
          <div>
            <Controller
              name="poNumber"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="PO Number"
                  inputName="poNumber"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.poNumber?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.poNumber.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Payment Terms Type */}
          {props.paymentTerms}

          {/* Field: Note */}
          <div>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Note"
                  placeholder={strings.PLACEHOLDER_VISIBLE_NOTE}
                  inputName="note"
                  admin={true}
                  required={false}
                  rows={3}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              )}
            />
            {errors.note?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.note.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Email */}
          <div>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Email"
                  inputName="email"
                  admin={true}
                  required={false}
                  rows={1}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              )}
            />
            {errors.email?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.email.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Internal Notes */}
          <div>
            <Controller
              name="internalNotes"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Internal Notes"
                  placeholder={strings.PLACEHOLDER_INTERNAL_NOTE}
                  inputName="internalNotes"
                  admin={true}
                  required={false}
                  rows={3}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              )}
            />
            {errors.internalNotes?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.internalNotes.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row sm:col-span-3 sm:col-start-1">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}
