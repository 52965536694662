//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormReset,
  useWatch,
  UseFormResetField,
} from "react-hook-form";
import { useMemo, useEffect, Fragment } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseModal from "../BaseModal";
import BaseInputText from "../BaseInputText";
import BaseInputCheckbox from "../BaseInputCheckbox";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseInputSelect from "../BaseInputSelect";
import StyledMessage from "../StyledMessage";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import * as strings from "../../strings";
import BaseInputTextArea from "../BaseInputTextArea";
import { StyledTooltip } from "../StyledTooltip";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { phoneUtils } from "../../utils/phoneUtils";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";

interface Props {
  locationDoc: ExistingSiteKeyLocation;
  departments: string[];
  isDialogOpen: boolean;
  invoicesEnabled: boolean;
  estimatesEnabled: boolean;
  inRouteEnabled: boolean;
  closeDialog: () => void;
  handleEdit: (formValues: LocationState) => Promise<void>;
  children: {
    Map: React.ReactNode;
  };
}

const LocationSchema = z.object({
  title: z.string().min(1).max(200),
  department: z.string().min(1).max(200),
  newDepartment: z.boolean(),
  invoiceTitle: z.string().min(0).max(500).optional(),
  invoiceHeader: z.string().min(0).max(1000).optional(),
  invoiceMessage: z.string().min(0).max(1000).optional(),
  invoiceSignatureText: z.string().min(0).max(20000).optional(),
  estimateSignatureText: z.string().min(0).max(20000).optional(),
  workAuthorizationSignatureText: z.string().min(0).max(20000).optional(),
  receiptEmailAdditionalText: z.string().min(0).max(20000).optional(),
  invoiceEmailAdditionalText: z.string().min(0).max(20000).optional(),
  inRouteSMSText: z.string().min(0).max(2000).optional(),
  sendPhotoWithInRouteText: z.boolean().optional(),
  email: z.string().email().or(z.literal("")).optional(),
  phone: z.string().min(0).max(200).optional(),
});
export type LocationState = z.infer<typeof LocationSchema>;

export default function EditLocationDialog(props: Props) {
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const locationDefaultValues: LocationState = useMemo(() => {
    return {
      title: props.locationDoc.title,
      department: props.locationDoc.department,
      newDepartment: false,
      invoiceTitle: props.locationDoc.invoiceTitle ?? "",
      invoiceHeader: props.locationDoc.invoiceHeader ?? "",
      invoiceMessage: props.locationDoc.invoiceMessage ?? "",
      invoiceSignatureText: props.locationDoc.invoiceSignatureText ?? "",
      estimateSignatureText: props.locationDoc.estimateSignatureText ?? "",
      workAuthorizationSignatureText:
        props.locationDoc.workAuthorizationSignatureText ?? "",
      receiptEmailAdditionalText:
        props.locationDoc.receiptEmailAdditionalText ?? "",
      invoiceEmailAdditionalText:
        props.locationDoc.invoiceEmailAdditionalText ?? "",
      inRouteSMSText: props.locationDoc.inRouteSMSText ?? "",
      sendPhotoWithInRouteText:
        props.locationDoc.sendPhotoWithInRouteText ?? false,
      email: props.locationDoc.email ?? "",
      phone: props.locationDoc.phone ?? "",
    };
  }, [props.locationDoc]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    resetField,
  } = useForm<LocationState>({
    defaultValues: locationDefaultValues,
    resolver: zodResolver(LocationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(locationDefaultValues);
  }, [locationDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function onCloseDialog() {
    props.closeDialog();
    reset();
  }

  const onSubmit: SubmitHandler<LocationState> = async (formValues) => {
    let parsed;
    if (formValues.phone != null && formValues.phone !== "") {
      const { PhoneNumber, warning } = phoneUtils.parse(formValues.phone);
      if (warning === true) {
        parsed = formValues.phone;
        addToastMessage({
          id: createToastMessageID(),
          message: strings.PHONE_TOO_SHORT,
          dialog: false,
          type: "warning",
        });
      } else {
        parsed = PhoneNumber.number;
      }
    }
    const location: LocationState = {
      ...formValues,
      phone: parsed === null || parsed === undefined ? "" : parsed.toString(),
    };

    await props.handleEdit(location);

    // Close dialog if successful.
    onCloseDialog();
  };

  const locationHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Edit location
      </h1>
      <button type="button" onClick={() => onCloseDialog()}>
        <XMarkIcon
          aria-label="close location form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={onCloseDialog}
      open={props.isDialogOpen}
      title={locationHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <TheForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        errors={errors}
        handleCancel={onCloseDialog}
        control={control}
        reset={reset}
        departments={props.departments}
        isSubmitting={isSubmitting}
        resetField={resetField}
        locationDoc={props.locationDoc}
        invoicesEnabled={props.invoicesEnabled}
        inRouteEnabled={props.inRouteEnabled}
        estimatesEnabled={props.estimatesEnabled}
      >
        {{
          Map: props.children.Map,
        }}
      </TheForm>
    </BaseModal>
  );
}

// #region SECTION: The Form
interface FormProps {
  handleSubmit: UseFormHandleSubmit<LocationState>;
  onSubmit: SubmitHandler<LocationState>;
  errors: FieldErrors;
  handleCancel: () => void;
  control: Control<LocationState, any>;
  reset: UseFormReset<LocationState>;
  departments: string[];
  isSubmitting: boolean;
  resetField: UseFormResetField<LocationState>;
  locationDoc: ExistingSiteKeyLocation;
  invoicesEnabled: Props["invoicesEnabled"];
  estimatesEnabled: Props["estimatesEnabled"];
  inRouteEnabled: Props["inRouteEnabled"];
  children: {
    Map: React.ReactNode;
  };
}

// Extracted 'TheForm' because: https://stackoverflow.com/a/67002581
const TheForm = (props: FormProps) => {
  const watchNewDepartment = useWatch({
    control: props.control,
    name: "newDepartment",
  });

  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit(props.onSubmit)}
      className="flex flex-col space-y-8 p-8 text-lg"
    >
      {/* Field: Location Name */}
      <div>
        <Controller
          name="title"
          control={props.control}
          render={({ field }) => (
            <BaseInputText
              text="Location Name"
              inputName="title"
              admin={true}
              required={true}
              {...field}
            />
          )}
        />
        {props.errors.title?.message &&
          typeof props.errors.title.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.title.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Field: New Department */}
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:items-center sm:gap-4">
        <div className="mb-5 sm:mb-0">
          <Controller
            control={props.control}
            name="newDepartment"
            render={({ field }) => {
              return (
                <BaseInputCheckbox
                  label="New Department ?"
                  checked={field.value}
                  {...field}
                  value={""}
                  onChange={(event) => {
                    /* resetField is used to reset ' departmentField' when passing from 'newDepartment : false' dropdown to 'newDepartment: true' input text field and viceversa  */
                    event.target.checked
                      ? props.resetField("department", { defaultValue: "" })
                      : props.resetField("department", {
                          defaultValue: props.locationDoc.department,
                        });
                    field.onChange(event.target.checked);
                  }}
                />
              );
            }}
          />
        </div>

        {/* Field: Department */}
        {watchNewDepartment ? (
          <div>
            <Controller
              name="department"
              control={props.control}
              render={({ field }) => (
                <BaseInputText
                  text="New Department"
                  inputName="department"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {props.errors.department?.message &&
              typeof props.errors.department.message === "string" && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: props.errors.department.message }}
                  </StyledMessage>
                </div>
              )}
          </div>
        ) : (
          <div>
            <Controller
              name="department"
              control={props.control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="department"
                  text="Department"
                  admin={true}
                  required={true}
                  {...field}
                >
                  {/* The empty string for value tricks validation into failing */}
                  <option value="" disabled>
                    Select a department
                  </option>

                  {props.departments.map((department, departmentIdx) => (
                    <option key={departmentIdx} value={department}>
                      {department}
                    </option>
                  ))}
                </BaseInputSelect>
              )}
            />
            {props.errors.department?.message &&
              typeof props.errors.department.message === "string" && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: props.errors.department.message }}
                  </StyledMessage>
                </div>
              )}
          </div>
        )}
      </div>

      <Fragment>
        {/* Field: Email */}
        <div>
          <Controller
            name="email"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                text="Email"
                inputName="email"
                admin={true}
                required={false}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {props.errors.email?.message &&
            typeof props.errors.email.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.email.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* Field: Phone */}
        <div>
          <Controller
            name="phone"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                text="Phone"
                inputName="phone"
                admin={true}
                required={false}
                {...field}
              />
            )}
          />
          {props.errors.phone?.message &&
            typeof props.errors.phone.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.phone.message }}
                </StyledMessage>
              </div>
            )}
        </div>
      </Fragment>

      {
        <Fragment>
          {/* INVOICE SIGNATURE TEXT */}
          <StyledTooltip
            title={`If you would like to have a customer acknowledge a generic work authorization statement with a signature (ie, prior to entering the location), this text will be displayed above the signature block`}
          >
            <div>
              <Controller
                name="workAuthorizationSignatureText"
                control={props.control}
                render={({ field }) => (
                  <BaseInputTextArea
                    text="Work Authorization Signature Text"
                    inputName="workAuthorizationSignatureText"
                    admin={true}
                    required={false}
                    rows={3}
                    {...field}
                  />
                )}
              />
              {props.errors.workAuthorizationSignatureText?.message &&
                typeof props.errors.workAuthorizationSignatureText.message ===
                  "string" && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{
                        message:
                          props.errors.workAuthorizationSignatureText.message,
                      }}
                    </StyledMessage>
                  </div>
                )}
            </div>
          </StyledTooltip>
        </Fragment>
      }

      {props.estimatesEnabled && (
        <Fragment>
          {/* ESTIMATE SIGNATURE TEXT */}
          <StyledTooltip
            title={`If a customer is asked to sign an estimate for approval, this text will be displayed above the signature block`}
          >
            <div>
              <Controller
                name="estimateSignatureText"
                control={props.control}
                render={({ field }) => (
                  <BaseInputTextArea
                    text="Estimate Acceptance Signature Text"
                    inputName="estimateSignatureText"
                    admin={true}
                    required={false}
                    rows={3}
                    {...field}
                  />
                )}
              />
              {props.errors.estimateSignatureText?.message &&
                typeof props.errors.estimateSignatureText.message ===
                  "string" && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: props.errors.estimateSignatureText.message }}
                    </StyledMessage>
                  </div>
                )}
            </div>
          </StyledTooltip>
        </Fragment>
      )}

      {props.invoicesEnabled && (
        <Fragment>
          {/* INVOICE TITLE */}
          <div>
            <Controller
              name="invoiceTitle"
              control={props.control}
              render={({ field }) => (
                <BaseInputText
                  text="Invoice Title"
                  inputName="invoiceTitle"
                  admin={true}
                  required={false}
                  {...field}
                />
              )}
            />
            {props.errors.invoiceTitle?.message &&
              typeof props.errors.invoiceTitle.message === "string" && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: props.errors.invoiceTitle.message }}
                  </StyledMessage>
                </div>
              )}
          </div>
          {/* INVOICE HEADER */}
          <div>
            <Controller
              name="invoiceHeader"
              control={props.control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Invoice Header"
                  inputName="invoiceHeader"
                  admin={true}
                  required={false}
                  rows={3}
                  {...field}
                />
              )}
            />
            {props.errors.invoiceHeader?.message &&
              typeof props.errors.invoiceHeader.message === "string" && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: props.errors.invoiceHeader.message }}
                  </StyledMessage>
                </div>
              )}
          </div>
          {/* INVOICE MESSAGE */}
          <StyledTooltip
            title={`This text will be displayed at the bottom of the invoice`}
          >
            <div>
              <Controller
                name="invoiceMessage"
                control={props.control}
                render={({ field }) => (
                  <BaseInputTextArea
                    text="Invoice Message"
                    inputName="invoiceMessage"
                    admin={true}
                    required={false}
                    rows={3}
                    {...field}
                  />
                )}
              />
              {props.errors.invoiceMessage?.message &&
                typeof props.errors.invoiceMessage.message === "string" && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: props.errors.invoiceMessage.message }}
                    </StyledMessage>
                  </div>
                )}
            </div>
          </StyledTooltip>
          <StyledTooltip
            title={`This text will be added to the body of emailed invoices`}
          >
            <div>
              <Controller
                name="invoiceEmailAdditionalText"
                control={props.control}
                render={({ field }) => (
                  <BaseInputTextArea
                    text="Invoice Email Message"
                    inputName="invoiceEmailAdditionalText"
                    admin={true}
                    required={false}
                    rows={3}
                    {...field}
                  />
                )}
              />
              {props.errors.invoiceEmailAdditionalText?.message &&
                typeof props.errors.invoiceEmailAdditionalText.message ===
                  "string" && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{
                        message:
                          props.errors.invoiceEmailAdditionalText.message,
                      }}
                    </StyledMessage>
                  </div>
                )}
            </div>
          </StyledTooltip>
          <StyledTooltip
            title={`This text will be added to the body of emailed receipts`}
          >
            <div>
              <Controller
                name="receiptEmailAdditionalText"
                control={props.control}
                render={({ field }) => (
                  <BaseInputTextArea
                    text="Receipt Email Message"
                    inputName="receiptEmailAdditionalText"
                    admin={true}
                    required={false}
                    rows={3}
                    {...field}
                  />
                )}
              />
              {props.errors.receiptEmailAdditionalText?.message &&
                typeof props.errors.receiptEmailAdditionalText.message ===
                  "string" && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{
                        message:
                          props.errors.receiptEmailAdditionalText.message,
                      }}
                    </StyledMessage>
                  </div>
                )}
            </div>
          </StyledTooltip>
          {/* INVOICE SIGNATURE TEXT */}
          <StyledTooltip
            title={`If a customer is asked to sign an invoice after it has been generated, this text will be displayed above the signature block`}
          >
            <div>
              <Controller
                name="invoiceSignatureText"
                control={props.control}
                render={({ field }) => (
                  <BaseInputTextArea
                    text="Invoice Signature Text"
                    inputName="invoiceSignatureText"
                    admin={true}
                    required={false}
                    rows={3}
                    {...field}
                  />
                )}
              />
              {props.errors.invoiceSignatureText?.message &&
                typeof props.errors.invoiceSignatureText.message ===
                  "string" && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: props.errors.invoiceSignatureText.message }}
                    </StyledMessage>
                  </div>
                )}
            </div>
          </StyledTooltip>
        </Fragment>
      )}

      {props.inRouteEnabled && (
        <Fragment>
          <StyledTooltip
            title={`Build a template to be used when a technician sends an In Route text message to the customer. Use placeholders {CUSTOMER_NAME}, {SERVICE_ADDRESS}, {TECHNICIAN_NAME}, and {OFFICE_PHONE_NUMBER}`}
          >
            <div>
              <Controller
                name="inRouteSMSText"
                control={props.control}
                render={({ field }) => (
                  <BaseInputText
                    text="In Route SMS Text"
                    inputName="inRouteSMSText"
                    admin={true}
                    required={false}
                    {...field}
                  />
                )}
              />
              {props.errors.inRouteSMSText?.message &&
                typeof props.errors.inRouteSMSText.message === "string" && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: props.errors.inRouteSMSText.message }}
                    </StyledMessage>
                  </div>
                )}
            </div>
          </StyledTooltip>
          <div className="flex items-center justify-between">
            <Controller
              name="sendPhotoWithInRouteText"
              control={props.control}
              render={({ field }) => {
                return (
                  <StyledSwitchGroup
                    readableName="Send technician photo with in-route SMS"
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    checked={field.value ?? false}
                    id="sendPhotoWithInRouteText"
                    name={field.name}
                  />
                );
              }}
            />
          </div>
        </Fragment>
      )}

      {/* Map */}
      {props.children.Map}

      {/* Action Buttons */}
      <div className="mt-4 flex w-full items-center justify-between gap-4">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={() => {
            props.handleCancel();
          }}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          disabled={props.isSubmitting}
          isBusy={props.isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
    </form>
  );
};
// #endregion
