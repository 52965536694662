//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

export const OInventoryTransactionTypes = {
  // This will decrease quantityAvailable
  CONSUMED_FROM_STOCK: 11,

  // For planning a job, you can reserve parts ahead of time
  // This will increase quantityReserved and decrease quantityAvailable
  RESERVE: 5,

  // For parts or consumables that were used on a task and will not be returning to stock
  // This will decrease quantityReserved
  CONSUMED_FROM_RESERVE: 10,

  // This will decrease quantityReserved and increase quantityAvailable
  UNRESERVE: 6,

  // TODO: Implement rental types
  // For rental equipment like scaffolding or equipment
  // This will decrease quantityReserved and increase quantityInUse
  RESERVED_TO_CR_RENTAL: 20,

  // This will decrease available and increase inUse
  STOCK_TO_CR_RENTAL: 21,

  // This will decrease quantityInUse and increase quantityAvailable
  CR_RENTAL_TO_STOCK: 22,

  // This will not change any quantities
  CR_RENTAL_TO_CR_RENTAL: 23,

  // Field can be thought of as another "stock" location
  // Will leave these commented out for now until needed... less cognitive load
  // CR_TO_FIELD: 40,
  // FIELD_TO_STOCK: 50,
  // FIELD_TO_CR: 60,
  // FIELD_TO_FIELD: 70,

  // Corrections
  CORRECTION_AVAILABLE: 90,
  CORRECTION_AWAITING_PICKUP: 91,
  CORRECTION_IN_USE: 92,
  CORRECTION_RESERVED: 93,

  // Moves between inventoryLocations
  SENT_TO_LOCATION: 94,
  RECEIVED_FROM_LOCATION: 95,
} as const;

export type InventoryTransactionValues =
  (typeof OInventoryTransactionTypes)[keyof typeof OInventoryTransactionTypes];

/** Typeguard ƒn */
// export function isValidInventoryTransactionTypeValue(
//   value: number,
// ): value is InventoryTransactionTypesValues {
//   const transactionValues = Object.values(OInventoryTransactionTypes);
//   return transactionValues.includes(value as any);
// }

// export function getReadableInventoryTransactionType(val: number): string {
//   const inventoryTransactionString: string | undefined =
//     readableInventoryTransactionTypeMap[val as InventoryTransactionTypesValues];
//   if (!inventoryTransactionString) return "UNKNOWN";
//   return inventoryTransactionString;
// }

/** Map craft integers to their human readable strings. */
export const readableInventoryTransactionType: Record<number, string> = {
  [OInventoryTransactionTypes.RESERVE]: "Reserved (from stock)",
  [OInventoryTransactionTypes.UNRESERVE]: "Return to stock",
  [OInventoryTransactionTypes.CONSUMED_FROM_RESERVE]:
    "Consumed (from reserved)",
  [OInventoryTransactionTypes.CONSUMED_FROM_STOCK]: "Consumed (from stock)",
  [OInventoryTransactionTypes.RESERVED_TO_CR_RENTAL]: "On Rent (from reserved)",
  [OInventoryTransactionTypes.STOCK_TO_CR_RENTAL]: "On Rent (from stock)",
  [OInventoryTransactionTypes.CR_RENTAL_TO_STOCK]: "Off Rent (to Stock)",
  [OInventoryTransactionTypes.CR_RENTAL_TO_CR_RENTAL]: "On Rent to On Rent",
  [OInventoryTransactionTypes.CORRECTION_AVAILABLE]: "Correction (Available)",
  [OInventoryTransactionTypes.CORRECTION_AWAITING_PICKUP]:
    "Correction (Awaiting Pickup)",
  [OInventoryTransactionTypes.CORRECTION_IN_USE]: "Correction (In Use)",
  [OInventoryTransactionTypes.CORRECTION_RESERVED]: "Correction (Reserved)",
  [OInventoryTransactionTypes.SENT_TO_LOCATION]: "Sent to Location",
  [OInventoryTransactionTypes.RECEIVED_FROM_LOCATION]: "Received from Location",
};

export interface InventoryTransaction {
  parentRecordID: string | null;
  taskID: string | null;
  invoiceID?: string;
  estimateItemID?: string;
  timestampCreated: Timestamp;
  createdBy: string;
  companyID: string;
  inventoryObjectID: string;
  inventoryLocationID: string;
  referenceTransactionID: string | null;
  type: InventoryTransactionValues;
  value: number;
  // Common fields
  // timestampLastModified: Timestamp;
  // lastModifiedBy: string;
  // deleted: boolean;
}

export interface ExistingInventoryTransaction extends InventoryTransaction {
  id: string;
  refPath: string;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<InventoryTransaction, "timestampCreated">;

// Timestamps are added on the backend.
export interface InventoryTransaction_CreateAPI extends withoutTimestamps {
  siteKey: string;
}

// #region SECTION: Functions
/** Utilities for interacting with InventoryTransaction objects  */
export const InventoryTransactionManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingInventoryTransaction object.
   */
  createFromFirestoreSnapshot: createInventoryTransactionFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateInventoryTransaction,
  /** Validate an InventoryTransaction doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateInventoryTransactionWithFallbacks,
  /** Validate a new inventory transaction. For the create endpoint. */
  // parseCreate: validateInventoryTransaction_Create,
};

/**
 * Convert the Document Snapshot into a validated ExistingInventoryTransaction object.
 */
function createInventoryTransactionFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingInventoryTransaction {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateInventoryTransactionWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateInventoryTransactionWithFallbacks(
  value: unknown,
): InventoryTransaction {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = inventoryTransactionSchemaWithFallbacks.parse(value);
  return result;
}

function validateInventoryTransaction(value: unknown): InventoryTransaction {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = inventoryTransactionSchema.parse(value);
  return result;
}

// function validateInventoryTransaction_Create(
//   value: unknown,
// ): InventoryTransaction_CreateAPI {
//   if (!guardIsPlainObject(value)) {
//     throw new Error(`value not an object: ${value}`);
//   }
//   return inventoryTransactionSchema_CreateAPI.parse(value);
// }

// Used when writing to the DB or reading from the user.
const inventoryTransactionSchema = z.object({
  companyID: z.string().min(1).max(200),
  parentRecordID: z.string().min(1).max(200).nullable(),
  taskID: z.string().min(1).max(200).nullable(),
  invoiceID: z.string().min(1).max(200).optional(),
  estimateItemID: z.string().min(1).max(200).optional(),
  inventoryObjectID: z.string().min(1).max(200),
  type: z.nativeEnum(OInventoryTransactionTypes),
  value: z.number(),
  timestampCreated: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  inventoryLocationID: z.string().min(1).max(200),
  referenceTransactionID: z.string().min(1).max(200).nullable(),
});

const inventoryTransactionSchemaWithFallbacks = z.object({
  companyID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "inventoryTransactionSchemaWithFallbacks: 'companyID",
  ),
  inventoryObjectID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "inventoryTransactionSchemaWithFallbacks: 'inventoryObjectID",
  ),
  parentRecordID: zFallback(
    z.string().min(1).max(200).nullable(),
    "unknown",
    "inventoryTransactionSchemaWithFallbacks: 'parentRecordID",
  ),
  taskID: zFallback(
    z.string().min(1).max(200).nullable(),
    "unknown",
    "inventoryTransactionSchemaWithFallbacks: 'taskID",
  ),
  invoiceID: zFallback(
    z.string().min(1).max(200).optional(),
    undefined,
    "inventoryTransactionSchemaWithFallbacks: 'invoiceID",
  ),
  estimateItemID: zFallback(
    z.string().min(1).max(200).optional(),
    undefined,
    "inventoryTransactionSchemaWithFallbacks: 'estimateItemID",
  ),
  type: z.nativeEnum(OInventoryTransactionTypes),
  value: zFallback(
    z.number(),
    0,
    "inventoryTransactionSchemaWithFallbacks: 'value",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "inventoryTransactionSchemaWithFallbacks: 'timestampCreated'",
  ),
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "inventoryTransactionSchemaWithFallbacks: 'createdBy'",
  ),
  inventoryLocationID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "inventoryTransactionSchemaWithFallbacks: 'inventoryLocationID'",
  ),
  referenceTransactionID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "inventoryTransactionSchemaWithFallbacks: 'referenceTransactionID'",
  ),
});

/***********************/
// FOR THE API
/***********************/
// const withoutTimestampsSchema = inventoryTransactionSchema.omit({
//   timestampCreated: true,
// });
//
// // Used for interacting with the Create endpoint.
// const inventoryTransactionSchema_CreateAPI = inventoryTransactionSchema.extend({
//   siteKey: z.string().min(1).max(400),
// });
