// Libs
import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { Fragment, forwardRef, useCallback, useRef } from "react";

// Local
import BaseInputCheckbox from "../BaseInputCheckbox";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import BaseButtonSecondary from "../BaseButtonSecondary";
import {
  getReadableTaskType,
  isValidTaskType,
  TaskTypes,
} from "../../models/task-types";

const TaskTypeCustomFilter = forwardRef(
  (
    {
      model,
      onModelChange,
      getValue,
    }: CustomFilterProps<any, any, { value: string[] }>,
    ref,
  ) => {
    const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

    const refInput = useRef<HTMLInputElement>(null);

    const doesFilterPass = useCallback(
      ({ node }: IDoesFilterPassParams) => {
        const nodeValue = getValue(node);
        if (model && isValidTaskType(nodeValue)) {
          return model.value.includes(String(nodeValue));
        } else return false;
      },
      [model],
    );

    const afterGuiAttached = useCallback(() => {
      window.setTimeout(() => {
        refInput.current?.focus();
      });
    }, []);

    useGridFilter({
      doesFilterPass,
      afterGuiAttached,
    });

    const onChange = (value: string[] | null) => {
      if (value == null) {
        onModelChange(null);
      } else {
        onModelChange({ value });
      }
    };

    return (
      <Fragment>
        <form className="flex h-full max-h-96 w-fit flex-col gap-2 rounded-md p-3">
          {Object.keys(TaskTypes).map((taskType) => {
            return (
              isValidTaskType(Number(taskType)) &&
              siteKeyDoc?.validTaskTypes.includes(Number(taskType)) && (
                <BaseInputCheckbox
                  key={taskType}
                  label={getReadableTaskType(Number(taskType))}
                  checked={
                    model != null ? model.value.includes(taskType) : false
                  }
                  onChange={(event) => {
                    const value = event.target.checked;
                    if (value === true) {
                      const modelCopy = model === null ? [] : [...model!.value];
                      onChange([...modelCopy, taskType]);
                    } else {
                      const modelCopy = [...model!.value];
                      const taskTypeIndex = modelCopy.findIndex(
                        (value) => taskType === value,
                      );
                      modelCopy.splice(taskTypeIndex, 1);
                      if (modelCopy.length === 0) {
                        onChange(null);
                      } else {
                        onChange(modelCopy);
                      }
                    }
                  }}
                  ref={refInput}
                  className="form-control"
                />
              )
            );
          })}
          <BaseButtonSecondary
            type="button"
            className="form-control"
            onClick={() => onChange(null)}
          >
            Reset
          </BaseButtonSecondary>
        </form>
      </Fragment>
    );
  },
);

export default TaskTypeCustomFilter;
