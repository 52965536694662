// Local
import { guardIsPlainObject } from "../utils";
import {
  CustomFieldDocData,
  CustomFieldTypes,
  isCustomFieldType,
} from "./custom-field";
import { TaskStatusValues } from "./task-status";

export interface DynamicDetail {
  type: CustomFieldTypes;
  defaultValue: boolean | number | string | string[] | null;
  title: string;
  editable: boolean;
  required: boolean;
  minLength?: number;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  onTaskStatus?: TaskStatusValues[];
  hideOnCraftRecordCreation?: boolean;
  selectionOptions?: { [p: string]: string };
}

export interface SiteCustomizations {
  [key: string]: any;

  craftDetails: {
    // Craft Type String
    [key: string]: {
      // Dynamic Detail Name String
      [key: string]: DynamicDetail;
    };
  };
  taskSpecificDetails: {
    // Craft Type String
    [key: string]: {
      // Task Type String
      [key: string]: {
        // Dynamic Detail Name String
        [key: string]: DynamicDetail;
      };
    };
  };
}

type Literal = boolean | null | number | string;

/** A recursive type for JSON objects. */
export type JsonType = Literal | { [key: string]: JsonType } | JsonType[];

export function convertFromCustomFieldToDynamicDetails(
  fieldData: CustomFieldDocData,
): DynamicDetail {
  if (!guardIsPlainObject(fieldData)) {
    throw new Error("create site custom field data is not an object");
  }

  const fieldType = fieldData.fieldType;
  if (!isCustomFieldType(fieldType)) {
    throw new Error(`unknown custom field type ${fieldType}`);
  }

  const output: DynamicDetail = {
    type: fieldType,
    defaultValue: fieldData.defaultValue,
    title: fieldData.title,
    editable: fieldData.editable,
    required: fieldData.required,
  };

  if (fieldData.craftRecordOrTask === "craftRecord") {
    output["hideOnCraftRecordCreation"] = fieldData.hideOnCraftRecordCreation
      ? fieldData.hideOnCraftRecordCreation
      : undefined;
  } else {
    output["onTaskStatus"] = fieldData.onTaskStatus
      ? fieldData.onTaskStatus
      : undefined;
  }

  if (
    fieldType === "string" ||
    fieldType === "uid" ||
    fieldType === "string-textarea"
  ) {
    if (typeof fieldData.min === "number") {
      output["minLength"] = fieldData.min;
    }
    if (typeof fieldData.max === "number") {
      output["maxLength"] = fieldData.max;
    }
  } else if (fieldType === "number" || fieldType === "hours-minutes") {
    if (typeof fieldData.min === "number") {
      output["minValue"] = fieldData.min;
    }
    if (typeof fieldData.max === "number") {
      output["maxValue"] = fieldData.max;
    }
  } else if (fieldType === "selection") {
    output["selectionOptions"] = fieldData.selectionOptions;
  }

  return output;
}
