// Local
import { CREATE_SITE_PROGRESS_HEADING } from "../../strings";
interface Props {
  children: {
    Stepper: React.ReactNode;
    ActionButton: React.ReactNode;
    ApiResponseMessage: React.ReactNode;
    ProgressAnimation: React.ReactNode;
  };
}

export default function ProgressPage(props: Props) {
  return (
    <div className="mx-auto w-full items-center px-2 pb-10 md:px-14 lg:max-w-screen-lg lg:gap-x-28 lg:px-2">
      <div className="flex flex-col space-y-8">
        {props.children.Stepper}
        <div className="pt-8 text-5xl font-semibold text-primary">
          {CREATE_SITE_PROGRESS_HEADING}
        </div>
        <div className="flex flex-col space-y-6 pl-1 text-lg font-medium text-gray-700 xs:pl-2">
          {props.children.ProgressAnimation}
        </div>
        <div className="text-lg">{props.children.ApiResponseMessage}</div>
        {props.children.ActionButton}
      </div>
    </div>
  );
}
