// Libs
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Local
import FeedbackListPage from "./FeedbackListPage";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import ViewFeedbackDialog from "../../components/ViewFeedbackDialog";
import { DbRead } from "../../database";
import { logger } from "../../logging";
import { ExistingCustomer } from "../../models/customer";
import { ExistingFeedback } from "../../models/feedback";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";
import { CUSTOMERS_URL, WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { createToastMessageID } from "../../utils";

export type FeedbackPlusExtraType = ExistingFeedback & {
  customerName: string;
  customerPhone: string;
};

export default function FeedbackListContainer(props: {
  siteKey: string;
}): JSX.Element {
  // #region SECTION: Setup -- zustand, history (navigate), useState
  const addToastMsg = useToastMessageStore((state) => state.addToastMessage);

  const [feedbackList, setFeedbackList] = useState<ExistingFeedback[] | null>(
    null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] =
    useState<FeedbackPlusExtraType | null>(null);
  const [feedbackPlusExtraList, setFeedbackPlusExtraList] = useState<
    FeedbackPlusExtraType[] | undefined
  >(undefined);

  const navigate = useNavigate();

  function viewCustomer(id: string) {
    navigate(`${CUSTOMERS_URL}/${id}`);
  }

  function viewTask(workRecID: string) {
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${workRecID}`);
  }

  // #endregion Setup -- zustand, history (navigate), useState

  // #region SECTION: Meat 🥩
  /** customer name is added to each feedback doc */
  useEffect(() => {
    async function getFeedbackPlusExtraList() {
      if (!feedbackList) return;
      const data = await Promise.all(
        feedbackList.map(async (feedbackDoc) => {
          const customerMatch = await DbRead.customers.get(
            props.siteKey,
            feedbackDoc.customerID,
          );
          if (customerMatch) {
            const name = getCustomerName(
              customerMatch.name,
              customerMatch.firstName,
              customerMatch.lastName,
            );
            return {
              ...feedbackDoc,
              customerName: name,
              customerPhone:
                customerMatch.phone === null || customerMatch.phone.length === 0
                  ? "--"
                  : customerMatch.phone[0],
            };
          } else {
            return {
              ...feedbackDoc,
              customerName: "",
              customerPhone: "",
            };
          }
        }),
      );
      setFeedbackPlusExtraList(data);
    }

    getFeedbackPlusExtraList();
  }, [feedbackList, props.siteKey]);

  // Subscribe to realtime updates from the feedbacks collection.
  useEffect(() => {
    function getFeedbackList() {
      const unsubscribe = DbRead.feedbacks.subscribeAll({
        siteKeyID: props.siteKey,
        onChange: setFeedbackList,
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getFeedbackList();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey]);

  function handleOpenModal(id: string) {
    if (!feedbackPlusExtraList) return;

    const feedbackMatch = feedbackPlusExtraList.find(
      (feedback) => feedback.id === id,
    );
    if (!feedbackMatch) {
      logger.error(
        `Can't open dialog -- couldn't find matching feedback doc. given ID: ${id}. (siteKey: ${props.siteKey})`,
      );
      addToastMsg({
        id: createToastMessageID(),
        message: strings.ERR_LOCATE_FEEDBACK_NOTIFIED,
        dialog: false,
        type: "error",
      });
      return;
    }

    setSelectedFeedback(feedbackMatch);
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setSelectedFeedback(null);
    setIsModalOpen(false);
  }

  // #endregion Meat

  // SECTION: Components
  const feedbackModal = selectedFeedback && (
    <ViewFeedbackDialog
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      feedback={selectedFeedback}
    />
  );

  if (!feedbackPlusExtraList) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <>
      <FeedbackListPage
        feedbackPlusExtraList={feedbackPlusExtraList}
        viewCustomer={viewCustomer}
        viewTask={viewTask}
        handleOpenModal={(id) => handleOpenModal(id)}
      />
      {feedbackModal}
    </>
  );
}

// SECTION: Helpers

/** returns firstName + lastName if both are present; otherwise returns name */
export function getCustomerName(
  fullName: ExistingCustomer["name"],
  firstName: ExistingCustomer["firstName"],
  lastName: ExistingCustomer["lastName"],
): string {
  const hasFirstAndLast =
    firstName && firstName.length > 0 && lastName && lastName.length > 0;

  if (hasFirstAndLast) return `${firstName} ${lastName}`;
  return fullName;
}
