import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

interface Props {
  children?: React.ReactNode;
  icon: React.ReactNode;
  buttonClassName?: string;
}

export default function OverflowMenu({
  icon,
  children,
  className,
  buttonClassName,
  ...rest
}: Props & React.ComponentPropsWithoutRef<"div">): JSX.Element {
  return (
    <div className={`${className}`} {...rest}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            aria-label="overflowMenu"
            className={`${buttonClassName} z-0 inline-flex w-full justify-center rounded`}
          >
            {icon}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute -right-4 z-10 mt-2 min-w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none sm:right-0">
            {children}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
