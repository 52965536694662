interface Args {
  hours: number;
  minutes: number;
}

// Tested ✅
/** Will throw if given negative numbers */
export function convertHoursMinutesToDecimal(args: Args): number {
  if (args.hours < 0 || args.minutes < 0) {
    throw new Error(
      `Received a negative argument in ${
        convertHoursMinutesToDecimal.name
      }. Args: ${JSON.stringify(args, null, 2)}`,
    );
  }
  // Calculate the decimal value by adding the fractional part of minutes
  const decimal = args.hours + args.minutes / 60;

  // Round the decimal value to two decimal places
  const rounded = Math.round(decimal * 100) / 100;

  return rounded;
}
