//Libs
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local
import AllComplianceCountsCard from "../../components/compliance/AllComplianceCountCard";
import { ExistingComplianceRequirement } from "../../models/compliance-requirement";
import { ComplianceSummary } from "../../models/compliance-types-and-statuses";
import * as strings from "../../strings";
import { StyledTooltip } from "../../components/StyledTooltip";
import ComplianceRequirementsAccordionList from "./ComplianceRequirementsAccordionList";

interface Props {
  //DATA
  percentageOverallCompliance: number;
  totalAwaitingApproval: number;
  complianceSummaryList: ComplianceSummary[];
  showDeleteRequirementButton: boolean;
  showAddRequirementButton: boolean;
  showMobileSidebar: boolean;
  //FUNCTIONS
  onAddNewRequirement: () => void;
  handleDeleteRequirement: (
    id: ExistingComplianceRequirement["id"],
  ) => Promise<void>;
  // CHILDREN
  children: {
    MobileSidebarButton: React.ReactNode;
  };
}

/* TODO: when happy on how it looks and works, refactor the component, moving everything at container level */
export default function MainDashboard(props: Props) {
  return (
    <div
      className={`w-full space-y-9 md:col-span-3 ${
        props.showMobileSidebar ? "pt-16" : "py-16"
      }`}
    >
      {/* Title */}
      <h1 className="text-2xl font-semibold text-primary sm:text-3xl">
        {strings.COMPLIANCE_DASHBOARD}
      </h1>
      {/* Button to toggle the company sidebar on medium & below viewports */}
      <div className="self-start md:hidden">
        {props.children.MobileSidebarButton}
      </div>
      {/* If showMobileSidebar is false, show the requirements page / the normal MainDashboard */}
      {!props.showMobileSidebar && (
        <>
          {/* Counts */}
          <div className="flex space-x-4">
            <AllComplianceCountsCard
              description="overall compliance"
              total={`${
                isNaN(props.percentageOverallCompliance)
                  ? 0
                  : props.percentageOverallCompliance
              }%`}
            />
            <AllComplianceCountsCard
              description={strings.AWAITING_APPROVAL_STATUS}
              total={`${
                isNaN(props.totalAwaitingApproval)
                  ? 0
                  : props.totalAwaitingApproval
              }`}
            />
          </div>
          {/* Requirements Section */}
          <div className="flex items-center justify-between">
            <h2 className="inline-block text-2xl font-bold text-gray-700 sm:text-3xl">
              {strings.COMPLIANCE_REQUIREMENTS}
            </h2>
            {/* Add new requirement button */}
            {props.showAddRequirementButton ? (
              <button
                type="button"
                onClick={props.onAddNewRequirement}
                className="text-primary"
              >
                <StyledTooltip title={strings.ADD_COMPLIANCE_REQUIREMENT}>
                  <AddBoxIcon
                    aria-label="add a requirement"
                    sx={{ fontSize: 45 }}
                  />
                </StyledTooltip>
              </button>
            ) : null}
          </div>
          {/* Requirements summary cards */}
          <div className="space-y-4">
            <ComplianceRequirementsAccordionList
              handleDeleteRequirement={props.handleDeleteRequirement}
              showDeleteRequirementButton={props.showDeleteRequirementButton}
              complianceSummaryList={props.complianceSummaryList}
            />
          </div>
        </>
      )}
    </div>
  );
}
