//Libs
import { DocumentSnapshot, Timestamp, DocumentData } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils";

// #region SECTION: Types & Interfaces
// Tell us if the given value (customer.type) is present in the
// customerTypes array or not.
export function isCustomerContactType(
  value: unknown,
): value is CustomerContactTypes {
  return customerContactTypes.includes(value as any);
}

export const customerContactTypes = ["phone", "email", "mobilePhone"] as const;
// Create the types from the TS array 'customerTypes'.
export type CustomerContactTypes = (typeof customerContactTypes)[number];

export interface CustomerContact {
  type: CustomerContactTypes;
  value: string;
  notes: string;
  optIn: boolean;
  customerID: string;
  customerLocationID: string | null;
  deleted: boolean;
  customData: { [key: string]: any };
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
}

export interface ExistingCustomerContact extends CustomerContact {
  id: string;
  refPath: string;
}

export interface ExistingCustomerContactUpdate
  extends Partial<ExistingCustomerContact> {
  id: string;
  refPath: string;
  timestampLastModified: Timestamp;
  lastModifiedBy: string;
}

/***********************/
// FOR THE API
/***********************/
export type customerContactWithoutTimestamps = Omit<
  CustomerContact,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface CustomerContact_CreateAPI
  extends customerContactWithoutTimestamps {
  siteKey: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type CustomerContact_UpdateAPI = Omit<
  Partial<ExistingCustomerContact>,
  "timestampCreated" | "timestampLastModified"
>;
// #endregion Types & Interfaces

// #region SECTION: Functions
/** Utilities for interacting with Customer objects  */
export const CustomerContactManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingCustomer object.
   */
  createFromFirestoreSnapshot: createCustomerContactFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateCustomerContact,
  /** Validate a Customer doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateCustomerContactWithFallbacks,
  /** Validate a new customer. For the create endpoint. */
  parseCreate: validateCustomerContact_Create,
  /** Validate an existing customer. For the update endpoint. */
  parseUpdate: validateCustomerContact_Update,
  convertUpdateForFirestore: convertCustomerContactUpdateForFirestore,
};

/**
 * Convert the Document Snapshot into a validated ExistingCustomer object.
 */
function createCustomerContactFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingCustomerContact {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateCustomerContactWithFallbacks({
      ...snapshotData,
    }),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertCustomerContactUpdateForFirestore(
  customerContact: ExistingCustomerContactUpdate,
): DocumentData {
  // Copy before modifying. Don't want to modify original object.
  const local = Object.assign({}, customerContact);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/** Zod validation schemas */
function validateCustomerContactWithFallbacks(value: unknown): CustomerContact {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = customerContactSchemaWithFallbacks.parse(value);
  return result;
}

function validateCustomerContact(value: unknown): CustomerContact {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = customerContactSchema.parse(value);
  return result;
}

function validateCustomerContact_Create(
  value: unknown,
): CustomerContact_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return customerContactSchema_CreateAPI.parse(value);
}

function validateCustomerContact_Update(
  value: unknown,
): CustomerContact_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return customerContactSchema_UpdateAPI.parse(value);
}

// #endregion

// #region SECTION: Schemas
// Used when validating data coming from the database.
const customerContactSchemaWithFallbacks = z.object({
  type: zFallback(
    z.enum(customerContactTypes),
    "phone",
    "CustomerContactSchemaWithFallbacks: 'type'",
  ),
  value: zFallback(
    z.string().max(200),
    "unknown",
    "CustomerContactSchemaWithFallbacks: 'value'",
  ),
  notes: zFallback(
    z.string().max(1000),
    "",
    "CustomerContactSchemaWithFallbacks: 'notes'",
  ),
  optIn: zFallback(
    z.boolean(),
    true,
    "CustomerContactSchemaWithFallbacks: 'optIn'",
  ),
  customerID: zFallback(
    z.string().max(200),
    "unknown",
    "CustomerContactSchemaWithFallbacks: 'customerID'",
  ),
  customerLocationID: zFallback(
    z.string().max(200).nullable(),
    null,
    "CustomerContactSchemaWithFallbacks: 'customerLocationID'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "CustomerContactSchemaWithFallbacks: 'deleted'",
  ),
  customData: zFallback(
    z.record(z.any()),
    {},
    "CustomerContactSchemaWithFallbacks: 'customData'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "CustomerContactSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "CustomerContactSchemaWithFallbacks: 'timestampLastModified'",
  ),
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "CustomerContactSchemaWithFallbacks: 'createdBy'",
  ),
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "CustomerContactSchemaWithFallbacks'lastModifiedBy: )'",
  ),
});

// Used when writing to the DB or reading from the user.
const customerContactSchema = z.object({
  type: z.enum(customerContactTypes),
  value: z.string().max(200),
  notes: z.string().max(1000),
  optIn: z.boolean(),
  customerID: z.string().max(200),
  customerLocationID: z.string().max(200).nullable(),
  deleted: z.boolean(),
  customData: z.record(z.any()),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = customerContactSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const customerContactSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
  uuid: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const customerContactSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();

export const existingCustomerContactSchema = customerContactSchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
});

// #endregion
