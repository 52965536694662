// Libs
import cloneDeep from "lodash/cloneDeep";

// Local
import { ExistingSiteKey } from "../../../models/site-key";
import { ExistingSiteKeyUserPermissions } from "../../../models/site-key-user-permissions";
import { ExistingTask } from "../../../models/task";
import { taskStatusChanged } from "./taskStatusChanged";
import { getTaskStatus } from "./getTaskStatus";
import { ScheduleByPriorityType } from "../../../components/customers/CreateTask/SchedulingSection";

export async function updateTaskFromScheduleDateChange(args: {
  task: ExistingTask;
  scheduleVal: ScheduleByPriorityType | null;
  newTaskCreation: boolean;
  siteKey: ExistingSiteKey;
  siteKeyUserPermissions: ExistingSiteKeyUserPermissions;
}): Promise<ExistingTask> {
  let _task: ExistingTask = cloneDeep(args.task);

  _task.taskStatus = getTaskStatus({
    task: _task,
    scheduleVal: args.scheduleVal,
    newTaskCreation: args.newTaskCreation,
    siteKey: args.siteKey,
    siteKeyUserPermissions: args.siteKeyUserPermissions,
  });

  if (_task.taskStatus !== args.task.taskStatus) {
    // Task status changed!
    _task = await taskStatusChanged(args.task.taskStatus, _task);
  }

  return _task;
}
