// libs
import { useState } from "react";
import { MdFileDownload, MdEmail, MdModeEdit } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";
import { TbClockFilled } from "react-icons/tb";
import { BiSolidFile } from "react-icons/bi";

// local
import { StyledTooltip } from "../StyledTooltip";
import { ReportConfig, ReportSpec } from "../../models/reports";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import LoadingSpinner from "../LoadingSpinner";
import { logger } from "../../logging";
import * as strings from "../../strings";
import { reportsFormatDateTime } from "../../Pages/Reports/ReportsContainer";

interface Props {
  siteKeyID: string;
  savedReport: ReportConfig;
  reportSpec: ReportSpec;
  downloadReport: (siteKeyID: string, reportConfigID: string) => Promise<void>;
  emailReport: (siteKeyID: string, reportConfigID: string) => Promise<void>;
  handleNavToReportSpecPage: (
    reportType: string,
    reportSpec: ReportSpec,
    reportConfig: ReportConfig,
  ) => void;
}

const actionOptions = ["download", "email"] as const;
type ActionOptions = (typeof actionOptions)[number];

export default function ReportsSavedItem(props: Props): JSX.Element {
  const [isBusy, setIsBusy] = useState<ActionOptions | null>(null);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const handleEdit = () => {
    props.handleNavToReportSpecPage(
      props.savedReport.type,
      props.reportSpec,
      props.savedReport,
    );
  };

  async function handleDownload(): Promise<void> {
    if (!props.savedReport.id) {
      logger.error("ReportsSavedItem, handleDownload: missing reportConfigID");
      return;
    }

    setIsBusy("download");
    try {
      await props.downloadReport(props.siteKeyID, props.savedReport.id);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Download complete.",
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to download the report. Please try again later.",
        type: "error",
        dialog: false,
      });
    }
  }

  async function handleEmail(): Promise<void> {
    if (!props.savedReport.id) {
      logger.error("ReportsSavedItem, handleEmail: missing reportConfigID");
      return;
    }

    setIsBusy("email");
    try {
      await props.emailReport(props.siteKeyID, props.savedReport.id);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Email sent.",
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to email the report. Please try again later.",
        type: "error",
        dialog: false,
      });
    }
  }

  return (
    <div className="flex w-full items-center py-5 sm:py-4">
      <div className="mr-auto">
        <h4 className="pb-0.5">{props.savedReport.configName}</h4>

        <div className="flex flex-wrap gap-2 text-sm text-gray-500 sm:gap-4">
          {props.savedReport.subscribed && props.savedReport.frequency && (
            <div className="flex h-7 items-center justify-center gap-2 rounded-3xl bg-emerald-200 px-2.5 py-0.5 text-teal-800">
              <LuCalendarClock className="stroke-teal-800" />
              <span className="capitalize">{props.savedReport.frequency}</span>
            </div>
          )}

          <div className="flex items-center justify-center gap-2">
            <BiSolidFile className="stroke-gray-500" />
            {props.reportSpec.reportName}
          </div>

          {props.savedReport.timestampLastModified && (
            <div className="flex items-center justify-center gap-2">
              <TbClockFilled className="stroke-gray-500" />
              {`Last saved: ${reportsFormatDateTime(props.savedReport.timestampLastModified)}`}
            </div>
          )}
        </div>
      </div>

      <StyledTooltip title={strings.EDIT}>
        <button
          className="rounded-full p-2 text-2xl text-black hover:cursor-pointer hover:bg-gray-100 hover:text-amber-400"
          onClick={handleEdit}
        >
          <MdModeEdit />
        </button>
      </StyledTooltip>

      <StyledTooltip title={strings.DOWNLOAD}>
        <button
          className="rounded-full p-2 text-2xl text-black hover:cursor-pointer hover:bg-gray-100 hover:text-amber-400"
          onClick={handleDownload}
        >
          {isBusy === "download" ? (
            <LoadingSpinner marginClass="ml-1" />
          ) : (
            <MdFileDownload />
          )}
        </button>
      </StyledTooltip>

      <StyledTooltip title={strings.SEND_EMAIL}>
        <button
          className="rounded-full p-2 text-2xl text-black hover:cursor-pointer hover:bg-gray-100 hover:text-amber-400"
          onClick={handleEmail}
        >
          {isBusy === "email" ? (
            <LoadingSpinner marginClass="mr-1" />
          ) : (
            <MdEmail />
          )}
        </button>
      </StyledTooltip>
    </div>
  );
}
