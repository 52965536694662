//Libs

//Local
import JobCard, { JobCardStructure } from "../customers/JobCard";

interface Props {
  jobList: JobCardStructure[];
}

export default function WorkStatusPanel(props: Props) {
  return (
    <div className="col-span-2 mb-4 justify-items-center rounded bg-gray-100 p-4 sm:justify-items-start">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-bold sm:text-3xl">
          <span className="capitalize">Work Status</span>
        </h2>
      </div>
      <hr className="mb-4 mt-2 block w-full border border-gray-300" />
      <div className="grid grid-cols-1 gap-4 xs:grid-cols-2">
        {props.jobList.map((job, index) => (
          <JobCard key={index} job={job} onClick={job.onClick} />
        ))}
      </div>
    </div>
  );
}
