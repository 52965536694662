//Libs
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//Local
import EstimateListPage from "./EstimateListPage";
import { DbRead } from "../../database";
import { EstimateStatus, ExistingEstimate } from "../../models/estimate";
import { useNavToViewEstimate } from "../../navigation";

interface Props {
  siteKey: string;
}

export default function EstimateListContainer({ siteKey }: Props) {
  type UrlParams = { estimateStatusParams: string };
  const { estimateStatusParams } = useParams<UrlParams>();

  const navToViewEstimate = useNavToViewEstimate();

  const [estimateList, setEstimateList] = useState<ExistingEstimate[]>([]);

  useEffect(() => {
    function getEstimates() {
      let unsubscribe = undefined;
      switch (estimateStatusParams) {
        case "All":
          unsubscribe = DbRead.estimates.subscribeAll(siteKey, setEstimateList);
          return unsubscribe;
        case "Approved":
          unsubscribe = DbRead.estimates.subscribeAllWithStatus(
            siteKey,
            [EstimateStatus.APPROVED],
            setEstimateList,
          );
          return unsubscribe;
        case "Awaiting Approval":
          unsubscribe = DbRead.estimates.subscribeAllWithStatus(
            siteKey,
            [EstimateStatus.AWAITING_APPROVAL],
            setEstimateList,
          );
          return unsubscribe;
        case "Draft":
          unsubscribe = DbRead.estimates.subscribeAllWithStatus(
            siteKey,
            [EstimateStatus.DRAFT],
            setEstimateList,
          );
          return unsubscribe;
        case "On Hold":
          unsubscribe = DbRead.estimates.subscribeAllWithStatus(
            siteKey,
            [EstimateStatus.ON_HOLD],
            setEstimateList,
          );
          return unsubscribe;
        case "Rejected":
          unsubscribe = DbRead.estimates.subscribeAllWithStatus(
            siteKey,
            [EstimateStatus.REJECTED],
            setEstimateList,
          );
          return unsubscribe;
        case "Locked":
          unsubscribe = DbRead.estimates.subscribeAllWithStatus(
            siteKey,
            [EstimateStatus.LOCKED],
            setEstimateList,
          );
          return unsubscribe;
        default:
          return unsubscribe;
      }
    }

    const unsubscribeFn = getEstimates();
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey]);

  /* FUNCTIONS */
  async function goToViewEstimate(estimate: ExistingEstimate) {
    const estimateCustomer = await DbRead.customers.get(
      siteKey,
      estimate.customerID,
    );

    const estimateCustomerLocation = await DbRead.customerLocations.getSingle(
      siteKey,
      estimate.customerLocationID,
    );
    if (!estimateCustomerLocation) {
      return;
    }
    const invoice = await DbRead.invoices.getByEstimateId(siteKey, estimate.id);

    if (!estimateCustomer || !estimateCustomerLocation) return;
    navToViewEstimate(
      estimate.id,
      estimateCustomer,
      estimateCustomerLocation,
      invoice[0] ?? null,
    );
  }

  return (
    <EstimateListPage
      estimateList={estimateList}
      siteKey={siteKey}
      goToViewEstimate={goToViewEstimate}
    />
  );
}
