// Libs
import React, { useState } from "react";
import { Link } from "react-router-dom";

// Local
import { DbWrite } from "../database";
import { logger as devLogger } from "../logging";
import new_stilt_logo from "../images/new_stilt_logo.png";
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import * as strings from "../strings";
import { whiteLabel } from "../urls";
import { isWhiteLabel } from "../white-label-check";
import { useToastMessageStore } from "../store/toast-messages";
import { createToastMessageID } from "../utils";
import { LOGIN_URL } from "../urls";

export default function ForgotPassword() {
  const [{ email }, setEmail] = useState({ email: "" });

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  return (
    <div className="flex h-full items-start justify-center bg-white px-4 py-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <div className="rounded-lg bg-black py-20">
            <img
              src={new_stilt_logo}
              alt="Stilt Logo"
              className="mx-auto block px-12 lg:px-4"
            />
          </div>

          <h2 className="pt-14 text-center text-3xl font-extrabold text-gray-900">
            Send a password reset link
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primaryLight focus:outline-none focus:ring-1 focus:ring-primaryLight sm:text-sm"
                placeholder="Email address"
                onChange={(event: React.FormEvent<HTMLInputElement>) =>
                  setEmail({ email: event.currentTarget.value })
                }
                value={email}
              />
            </div>
          </div>

          {/* Back to login */}
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to={LOGIN_URL}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Back to Login Page
              </Link>
            </div>
          </div>

          <div>
            <BaseButtonPrimary
              type="button"
              onClick={handleResetPassword}
              isBusy={isBusy}
              busyText={strings.buttons.BUSY_SUBMITTING}
              className="w-full justify-center"
              data-testid="password-reset-button"
            >
              Submit
            </BaseButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  );

  async function handleResetPassword(event: React.FormEvent) {
    event.preventDefault();
    setIsBusy(true);
    try {
      if (isWhiteLabel(whiteLabel)) {
        try {
          await DbWrite.user.sendPasswordResetLink({
            email: email,
            version: whiteLabel,
          });
          devLogger.debug(`Password reset link sent to ${email}`);
          addToastMessage({
            id: createToastMessageID(),
            message: `Password reset link sent to ${email}`,
            dialog: false,
            type: "success",
          });
        } catch (e) {
          devLogger.error(e);
          addToastMessage({
            id: createToastMessageID(),
            message: "Something went wrong. Please try again later.",
            dialog: false,
            type: "error",
          });
        }
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: "Missing required information.",
          dialog: false,
          type: "error",
        });
        throw new Error(`Unexpected white label`);
      }
    } finally {
      setIsBusy(false);
    }
  }
}
