//Libs

//Local
import { ExistingTaskWithCustomerLocation } from "../../../models/task";
import { ExistingZone } from "../../../models/zone";

export function getBackColor(
  task: ExistingTaskWithCustomerLocation,
  zoneList: ExistingZone[],
) {
  let backColor = "#f6f6f6";

  /* if there's no color mapping, return a gray color as default */
  if (zoneList.length === 0) return backColor;

  zoneList.forEach((zone) => {
    if (zone.element === "dot") return;

    if (zone.property === "zipCode") {
      const taskZipCode = task.customerLocation?.zipCode;

      if (typeof taskZipCode !== "string") return;

      if (zone.values.includes(taskZipCode)) {
        backColor = zone.color;
      }
    } else {
      zone.values.forEach((zoneValue) => {
        const lowerCaseValue = zoneValue.toLowerCase();
        const lowerCaseTaskTitle = task.title.toLowerCase();
        if ((lowerCaseTaskTitle as string).includes(lowerCaseValue)) {
          backColor = zone.color;
        }
      });
    }
  });
  return backColor;
}
