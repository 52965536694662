// Libs
import create from "zustand";

// Local
import { ExistingSiteKeyLocation } from "../models/site-key-location";
import { DbRead } from "../database";

// Define the property types.
interface SiteKeyLocationStore {
  siteKeyLocationList: ExistingSiteKeyLocation[];
  loading: boolean;
  fetch: (siteKey: string) => void;
  getLocationTitle: (id: string) => string;
  getSortedLocationList: () => ExistingSiteKeyLocation[];
}

// Export a hook to access data globally and define initial values..
export const useSiteKeyLocationsStore = create<SiteKeyLocationStore>(
  (set, get) => {
    return {
      siteKeyLocationList: [],
      loading: false,
      fetch: async (siteKey: string) => {
        set({ loading: true });
        const locationsList = await DbRead.siteKeyLocations.getAll(siteKey);
        set({ siteKeyLocationList: locationsList, loading: false });
      },
      getLocationTitle: (id: string) => {
        const locationDoc = get().siteKeyLocationList.find(
          (location) => location.id === id,
        );

        if (locationDoc !== undefined) {
          return locationDoc.title;
        } else {
          return "";
        }
      },
      getSortedLocationList: () => {
        const sortedLocationList = get().siteKeyLocationList.sort(
          (locationA, locationB) =>
            locationA.title.localeCompare(locationB.title),
        );
        return sortedLocationList;
      },
    };
  },
);
