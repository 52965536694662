//Libs

//Local
import { convertToReadableTimestamp } from "../assets/js/convertToReadableTimestamp";
import ChipTag from "./ChipTag";
import { ExistingChecklistResponse } from "../models/checklist-response";

export interface ResponseCardBaseProps {
  response: ExistingChecklistResponse;
  children: {
    input: React.ReactNode;
    iconRow: React.ReactNode;
  };
}

export default function ResponseCardBase(props: ResponseCardBaseProps) {
  return (
    <div className="mb-3 grid grid-cols-1 rounded-md bg-white px-3 py-2 text-gray-700 shadow-md sm:mb-4 sm:grid-cols-2">
      {/* TAGS */}
      <div className="col-span-2 mb-1 inline-flex items-center justify-end sm:mb-0 sm:hidden">
        {props.response.tags.map((tag) => {
          return <ChipTag tag={tag} key={tag} />;
        })}
      </div>
      <div className="grid grid-cols-1 sm:mr-4 ">
        <div>
          <label htmlFor={props.response.id} className="text-base">
            {props.response.mainText}{" "}
            {props.response.required === true ? (
              <span className="text-lg font-medium text-redFail">*</span>
            ) : (
              ""
            )}
          </label>

          {/* NOTE */}
          <p className="my-2 text-sm font-medium text-gray-500">
            {props.response.note}
          </p>
        </div>
        {props.children.input}
      </div>

      <div className="sm:max-w-240px col-span-2 sm:col-auto sm:flex sm:flex-shrink-0 sm:flex-col sm:justify-between sm:text-right">
        {/* TAGS */}
        <div className="hidden items-center sm:inline-flex sm:justify-end">
          {props.response.tags.map((tag) => {
            return <ChipTag tag={tag} key={tag} />;
          })}
        </div>

        <div>
          {/* ICON ROW */}
          {props.children.iconRow}
        </div>
      </div>
      <div className="col-span-2 flex justify-end">
        {/* TIMESTAMP SUBMITTED */}
        {props.response.timestamp == null ? (
          <p className="text-sm">&nbsp;</p>
        ) : (
          <p className="text-sm">
            Submitted: {convertToReadableTimestamp(props.response.timestamp)}
          </p>
        )}
      </div>
    </div>
  );
}
