// Libs
import { DateTime } from "luxon";

/** Given an ISO datetime string, returns a Luxon DateTime object. */
export function convertISOToLuxonDT(iso: unknown): DateTime {
  if (typeof iso !== "string") {
    throw new Error(`Input was not a string. Received: ${iso}`);
  }
  const dt = DateTime.fromISO(iso).toUTC();
  if (!dt.isValid) {
    throw new Error(`Luxon DT object is not valid. Received: ${iso}`);
  }
  return dt;
}
