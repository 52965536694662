// Libs
import cloneDeep from "lodash/cloneDeep";
import { Timestamp } from "firebase/firestore";

// Local
import { ExistingTask } from "../../../models/task";
import { TaskStatus } from "../../../models/task-status";

export async function taskStatusChanged(
  previousTaskStatus: number,
  updatedTaskData: ExistingTask,
): Promise<ExistingTask> {
  const _task: ExistingTask = cloneDeep(updatedTaskData);

  if (
    _task.taskStatus === TaskStatus.IN_PROGRESS &&
    previousTaskStatus !== TaskStatus.ON_HOLD
  ) {
    _task.timestampTaskStarted = Timestamp.now();
  }

  if (
    _task.taskStatus === TaskStatus.COMPLETE ||
    (previousTaskStatus === TaskStatus.IN_PROGRESS &&
      updatedTaskData.taskStatus === TaskStatus.AWAITING_REVIEW &&
      updatedTaskData.timestampTaskCompleted === null)
  ) {
    _task.timestampTaskCompleted = Timestamp.now();
  }

  if (
    _task.taskStatus === TaskStatus.AWAITING &&
    previousTaskStatus !== TaskStatus.AWAITING
  ) {
    _task.timestampAwaitingStart = Timestamp.now();
  }

  return _task;
}
