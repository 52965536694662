//Libs
import create from "zustand";

//Local
import { ExistingSiteKey, CustomizationFlag } from "../models/site-key";
import { DbRead } from "../database";

//Define the property types
interface SiteKeyDocStore {
  siteKeyDoc: ExistingSiteKey | null;
  loading: boolean;
  fetch: (siteKey: string) => void;
  /**
   * A place to consolidate all the customization flags for the site key and
   * the logic for determining if they are enabled.
   */
  flags: Record<CustomizationFlag, () => boolean>;
}

//Export a hook to access data globally and define initial values.
export const useSiteKeyDocStore = create<SiteKeyDocStore>((set, get) => {
  return {
    siteKeyDoc: null,
    loading: false,
    fetch: async (siteKey: string) => {
      set({ loading: true });
      DbRead.siteKey.subscribe({
        siteKey: siteKey,
        onChange: (siteKeyDoc) => {
          set({ siteKeyDoc: siteKeyDoc, loading: false });
        },
      });
    },
    flags: {
      reportTemplatesEnabled: () =>
        get().siteKeyDoc?.customizations?.reportTemplatesEnabled === true,
      reportBuilderEnabled: () =>
        get().siteKeyDoc?.customizations?.reportBuilderEnabled === true,
    },
  };
});
