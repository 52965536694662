// Libs
import create from "zustand";

// Local
import { ExistingSiteKeyUserPermissions } from "../models/site-key-user-permissions";
import { DbRead } from "../database";
import { NotFoundError } from "../error-classes";
import { logger as devLogger } from "../logging";

// Define the property types.
interface UserPermissionsStore {
  siteKeyUserPermissions: ExistingSiteKeyUserPermissions | null;
  // todo: use react-query to handle caching?
  fetch: (siteKey: string, uid: string) => void;
}

// Export a hook to access data globally and define initial values..
export const useUserPermissionsStore = create<UserPermissionsStore>(
  (set, get) => {
    return {
      siteKeyUserPermissions: null,
      fetch: async (siteKey, uid) => {
        try {
          const userPermissions = await DbRead.user.getSiteKeyPermissions(
            siteKey,
            uid,
          );
          set({ siteKeyUserPermissions: userPermissions });
        } catch (err) {
          set({ siteKeyUserPermissions: null });
          if (err instanceof NotFoundError) {
            devLogger.error(err);
          } else {
            devLogger.error(err);
          }
        }
      },
    };
  },
);
