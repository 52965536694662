//Libs
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";

type Props = {
  placeholder: string;
  name: string;
  id: string;
  onButtonClick: (inputData: string) => void;
} & React.ComponentPropsWithRef<"input">;

const InputTextWithButton = React.forwardRef<HTMLInputElement, Props>(
  ({ ...props }, ref): JSX.Element => {
    const [inputValue, setInputValue] = useState<string>("");
    const { onButtonClick } = props;

    function handleSelectionOptions() {
      if (inputValue !== "") {
        onButtonClick(inputValue.trim());
        setInputValue("");
      } else {
        setInputValue("");
      }
    }

    return (
      <div>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <input
              ref={ref}
              type="text"
              name={props.name}
              id={props.id}
              className="block w-full rounded-none rounded-l-md border border-gray-300 px-2 py-1.5 outline-none focus:border-primaryLight focus:ring-1 focus:ring-primaryLight sm:text-sm"
              placeholder={props.placeholder}
              value={inputValue}
              onChange={(event) => {
                const options = event.target.value;
                setInputValue(options);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  handleSelectionOptions();
                }
              }}
            />
          </div>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primaryLight focus:outline-none focus:ring-1 focus:ring-primaryLight"
            onClick={(event) => {
              event.preventDefault();
              handleSelectionOptions();
            }}
          >
            <PlusCircleIcon
              aria-label="add option"
              className="h-5 w-5 text-primary"
              aria-hidden="true"
            />
            <span>Add</span>
          </button>
        </div>
      </div>
    );
  },
);
export default InputTextWithButton;
