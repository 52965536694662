import { TaskStatus } from "../../../models/task-status";

export function getTaskStatusClasses(taskStatus: TaskStatus): string {
  switch (taskStatus) {
    case TaskStatus.AWAITING_ESTIMATE:
      return "text-amber-700";
    case TaskStatus.AWAITING_REVIEW:
      return "text-purple-700";
    case TaskStatus.AWAITING_APPROVAL:
      return "text-purple-700";
    case TaskStatus.AWAITING_SCHEDULE:
      return "text-sky-500";
    case TaskStatus.AWAITING_PARTS:
      return "text-purple-600";
    case TaskStatus.PARTS_ORDERED:
      return "text-purple-500";
    case TaskStatus.SCHEDULED:
      return "text-white";
    case TaskStatus.AWAITING:
      return "text-yellow-400";
    case TaskStatus.IN_ROUTE:
      return "text-sky-300";
    case TaskStatus.AWAITING_PAYMENT:
      return "text-orange-600";
    case TaskStatus.IN_PROGRESS:
      return "text-pink-400";
    case TaskStatus.ON_HOLD:
      return "text-amber-500";
    case TaskStatus.COMPLETE:
      return "text-green-700";
    case TaskStatus.CANCELED:
      return "text-grey-700";
    default:
      const _exhaustivenessCheck: never = taskStatus;
      return _exhaustivenessCheck;
  }
}

export function getTaskStatusPillClasses(taskStatus: TaskStatus): string {
  switch (taskStatus) {
    case TaskStatus.AWAITING_ESTIMATE:
      return "bg-amber-700 text-white";
    case TaskStatus.AWAITING_REVIEW:
      return "bg-purple-700 text-white";
    case TaskStatus.AWAITING_APPROVAL:
      return "bg-purple-700 text-white";
    case TaskStatus.AWAITING_SCHEDULE:
      return "bg-sky-500 text-white";
    case TaskStatus.AWAITING_PARTS:
      return "bg-purple-600 text-white";
    case TaskStatus.PARTS_ORDERED:
      return "bg-purple-500 text-white";
    case TaskStatus.SCHEDULED:
      return "bg-black text-white";
    case TaskStatus.AWAITING:
      return "bg-yellow-400 text-white";
    case TaskStatus.IN_ROUTE:
      return "bg-sky-300 text-white";
    case TaskStatus.AWAITING_PAYMENT:
      return "bg-blue-300 text-white";
    case TaskStatus.IN_PROGRESS:
      return "bg-pink-400 text-white";
    case TaskStatus.ON_HOLD:
      return "bg-amber-500 text-white";
    case TaskStatus.COMPLETE:
      return "bg-green-700 text-white";
    case TaskStatus.CANCELED:
      return "bg-gray-200 text-black";

    default:
      const _exhaustivenessCheck: never = taskStatus;
      return _exhaustivenessCheck;
  }
}
