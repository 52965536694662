// Libs
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore";

// Local
import {
  FeedbackFormData,
  FeedbackManager,
  FeedbackUpdate,
} from "../../models/feedback";
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import ReviewRequestPage from "./ReviewRequestPage";
import {
  convertFSTimestampToLuxonDT,
  convertISOToLuxonDT,
  createToastMessageID,
} from "../../utils";
import {
  YesNoButtons,
  SocialsPanel,
  FeedbackPanel,
  FeedbackFormState,
} from "../../components/ReviewRequest";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";
import NotFound404 from "../NotFound404";

export default function ReviewRequestContainer(): JSX.Element {
  // Extract review request doc ID from the URL
  // (review request doc ID = uniqueLinks/uniqueLinks/feedbacks/feedbackID)
  type UrlParams = { id: string };
  const data = useParams<UrlParams>();
  const id = data.id;
  if (typeof id !== "string") {
    throw new Error(`id was not a string: ${id}`);
  }

  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  // null is leveraged for the loading state. undefined for showing a 404.
  const [formData, setFormData] = useState<FeedbackFormData | null | undefined>(
    null,
  );

  const [isFeedbackPositive, setIsFeedbackPositive] = useState<boolean | null>(
    null,
  );
  const feedbackIsNegative = isFeedbackPositive === false;
  const feedbackIsPositive = isFeedbackPositive === true;

  useEffect(() => {
    async function getData() {
      if (typeof id !== "string") {
        throw new Error(`id was not a string: ${id}`);
      }

      try {
        const doc = await DbRead.feedbacks.getFormData(id);
        setFormData(doc);
      } catch (_) {
        // show 404 page instead of it perpetually loading
        setFormData(undefined);
      }
    }
    getData();
  }, [id]);

  // update timestampCustomerOpened
  useEffect(() => {
    if (!formData) return;
    async function update() {
      if (!formData) return; // TS complains without having this check here
      const isoNow = convertFSTimestampToLuxonDT(Timestamp.now()).toISO();

      const updateData: Partial<FeedbackUpdate> = {
        timestampCustomerOpened: isoNow,
        timestampLastModified: isoNow,
      };
      const validUpdate = FeedbackManager.parseUpdate(updateData);

      try {
        await DbWrite.feedbacks.handleResponse({
          siteKeyID: formData.siteKeyID,
          feedbackID: formData.feedbackID,
          updateData: validUpdate,
        });
        logger.debug("updated feedback doc");
      } catch (e) {
        logger.error(
          `error updating feedback doc (${formData.feedbackID}):`,
          e,
        );
      }
    }
    update();
  }, [formData]);

  // SECTION: Functions
  async function handleYesNoResponse(val: boolean): Promise<void> {
    if (!formData) {
      logger.error("formData is null; should never happen");
      return;
    }

    const updateData: Partial<FeedbackUpdate> = {
      customerPleased: val,
      timestampLastModified: convertFSTimestampToLuxonDT(
        Timestamp.now(),
      ).toISO(),
    };
    const validUpdate = FeedbackManager.parseUpdate(updateData);

    try {
      await DbWrite.feedbacks.handleResponse({
        siteKeyID: formData.siteKeyID,
        feedbackID: formData.feedbackID,
        updateData: validUpdate,
      });
      // no need to show a toast message if it was successful
      logger.debug("updated feedback doc");
    } catch (e) {
      logger.error(`error updating feedback doc (${formData.feedbackID}):`, e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_SUBMIT_RESPONSE_NOTIFIED,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleTrackSocialsClick(val: string): Promise<void> {
    if (!formData) {
      logger.error("formData is null; should never happen");
      return;
    }

    const isoNow = convertFSTimestampToLuxonDT(Timestamp.now()).toISO();

    const updateData: Partial<FeedbackUpdate> = {
      socialsTapped: { [val]: isoNow },
      timestampLastModified: isoNow,
    };
    const validUpdate = FeedbackManager.parseUpdate(updateData);

    try {
      await DbWrite.feedbacks.handleResponse({
        siteKeyID: formData.siteKeyID,
        feedbackID: formData.feedbackID,
        updateData: validUpdate,
      });
      logger.debug("updated feedback doc");
    } catch (e) {
      logger.error(`error updating feedback doc (${formData.feedbackID}):`, e);
    }
  }

  async function handleSubmitFeedback(
    formValues: FeedbackFormState,
  ): Promise<void> {
    if (!formData) {
      logger.error("formData is null; should never happen");
      return;
    }

    const data = Object.entries(formValues).reduce(
      (acc, [currKey, currValue]) => {
        if (currValue === 0 || currValue === "" || !currValue) {
          acc[currKey] = null;
          return acc;
        } else {
          acc[currKey] = currValue;
          return acc;
        }
      },
      {} as Record<string, string | number | null>,
    );

    const updateData: Partial<FeedbackUpdate> = {
      feedbackData: data,
      timestampLastModified: convertFSTimestampToLuxonDT(
        Timestamp.now(),
      ).toISO(),
    };
    const validUpdate = FeedbackManager.parseUpdate(updateData);

    try {
      await DbWrite.feedbacks.handleResponse({
        siteKeyID: formData.siteKeyID,
        feedbackID: formData.feedbackID,
        updateData: validUpdate,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.THANKS_FOR_FEEDBACK,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(`error updating feedback doc (${formData.feedbackID}):`, e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_SUBMIT_RESPONSE_NOTIFIED,
        dialog: false,
        type: "error",
      });
    }
  }

  // SECTION: Components / UI pieces
  const yesNoButtons = (
    <YesNoButtons
      setIsFeedbackPositive={setIsFeedbackPositive}
      handleClick={handleYesNoResponse}
    />
  );
  const happyPanel = formData && (
    <SocialsPanel
      urlMap={formData.reviewLinks}
      handleClick={handleTrackSocialsClick}
    />
  );
  const sadPanel = formData && (
    <FeedbackPanel handleSubmitForm={handleSubmitFeedback} />
  );

  if (formData === null) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  if (formData === undefined) {
    return <NotFound404 />;
  }

  // man i suck at naming things
  const introText = getIntroText(
    formData.merchantName,
    formData.datetimeCompleted,
  );

  return (
    <ReviewRequestPage
      merchantLogoURL={formData.merchantLogoURL}
      introText={introText}
      feedbackIsPositive={feedbackIsPositive}
      feedbackIsNegative={feedbackIsNegative}
    >
      {{ yesNoButtons, happyPanel, sadPanel }}
    </ReviewRequestPage>
  );
}

// SECTION: HELPERS
function getIntroText(
  merchantName: string,
  datetimeCompleted: string | null,
): string {
  return `${merchantName} thanks you for being a customer. Were you pleased with our service that was recently ${
    datetimeCompleted
      ? `completed on ${getFormattedDate(datetimeCompleted)}?`
      : "completed?"
  }`;

  // if (!datetimeCompleted) {
  //   return `${merchantName} thanks you for being a customer. Were you pleased with our service that was recently completed?`;
  // } else {
  //   const lux = convertISOToLuxonDT(datetimeCompleted);
  //   const formatted = lux.toFormat("LL/dd/yy");
  //   return `${merchantName} thanks you for being a customer. Were you pleased with our service that was recently completed on ${formatted}?`;
  // }
}
function getFormattedDate(iso: string): string {
  return convertISOToLuxonDT(iso).toFormat("LL/dd/yy");
}
