// libs
import React from "react";
import { useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { FaAndroid, FaApple } from "react-icons/fa";

// local
import BaseButtonRounded from "../components/BaseButtonRounded";
import { ChatBubble } from "@mui/icons-material";
import ChatRooms from "./chat/ChatRooms";

interface SideNavProps {
  className?: string;
  sidebarData: SidebarDataType;
  siteKey?: string;
}

export type SubNavItem = {
  title: string;
  path: string;
  icon: JSX.Element;
  component?: React.ComponentType<any>;
  id: string;
};

export type NavItem = {
  title: string | [JSX.Element, JSX.Element];
  path?: string;
  icon: JSX.Element;
  component?: React.ComponentType<any>;
  subNav?: SubNavItem[];
  id: string;
};

export type SidebarDataType = NavItem[];
type SubnavKey = string;

export default function SideNav({
  className = "",
  sidebarData,
  sidebar,
  siteKey,
}: SideNavProps & { sidebar: boolean }) {
  const getTitleKey = (title: string | [JSX.Element, JSX.Element]): string => {
    return Array.isArray(title) ? title[0].props.children : title;
  };

  const [activeSubnav, setActiveSubnav] = useState("");
  const [selectedItem, setSelectedItem] = useState<NavItem | null>(null);
  const [activeSubNavItem, setActiveSubNavItem] = useState<SubNavItem | null>(
    null,
  );
  const [showChat, setShowChat] = useState<boolean>(false);

  const showSubnav = useCallback(
    (submenu: SubnavKey) => {
      if (activeSubnav === submenu) {
        setActiveSubnav("");
      } else {
        setActiveSubnav(submenu);
      }
    },
    [activeSubnav],
  );

  const boldSideItem = (item: NavItem) => {
    setSelectedItem(item);
    setActiveSubNavItem(null);
  };

  const boldSubNavItem = (subItem: SubNavItem) => {
    setActiveSubNavItem(subItem);
    setSelectedItem(null);
  };

  const HELP_CENTER_LINK =
    "https://monomersoftware.notion.site/Stilt-Help-Center-8e2a59d5c8db42749ba67a143581524b?pvs=4";
  const MOBILE_APPS_LINK = "https://jovial-elion-a0d1d6.netlify.app/download";

  return (
    <div className={`${className}`}>
      <nav
        className={`fixed top-0 transform bg-white transition-transform duration-300 ease-in-out ${
          sidebar ? "translate-x-0" : "-translate-x-full"
        } m-0 flex h-screen w-60 flex-col justify-between border-r-2 border-[#e0e0f0] p-0`}
      >
        {!showChat && (
          <ul className="mt-[70px] w-full overflow-y-auto text-textDarkGray">
            {sidebarData.map((item) => {
              const itemKey = `main-${item.id}`;
              const isSubmenuShown = activeSubnav === getTitleKey(item.title);
              // console.log("Main nav item:", item, "Key:", itemKey);
              return (
                <>
                  <li
                    key={itemKey}
                    onClick={() => {
                      boldSideItem(item);
                    }}
                    className={`flex h-12 items-center justify-center hover:bg-lightGrayBgHover ${
                      selectedItem === item ? "border-r-4 border-black" : ""
                    }`}
                  >
                    {item.path ? (
                      <NavLink
                        to={item.path}
                        className={`group flex h-full w-full items-center px-4 text-xl ${
                          selectedItem === item ? "font-black" : ""
                        }`}
                      >
                        <span
                          className={`${selectedItem === item ? "text-textDarkGray" : "text-[#757575]"} text-2xl transition-colors group-hover:text-textDarkGray`}
                        >
                          {item.icon}
                        </span>
                        <span className="ml-4 flex w-full items-center justify-between">
                          {item.title}
                        </span>
                      </NavLink>
                    ) : (
                      <button
                        onClick={() => showSubnav(getTitleKey(item.title))}
                        className="group flex h-full w-full items-center px-4 text-xl"
                      >
                        <span
                          className={`${selectedItem === item ? "text-textDarkGray" : "text-[#757575]"} text-2xl transition-colors group-hover:text-textDarkGray`}
                        >
                          {item.icon}
                        </span>
                        <span className="ml-4 flex w-full items-center justify-between">
                          {item.title}
                        </span>
                      </button>
                    )}
                  </li>

                  {item.subNav &&
                    item.subNav.map((subItem) => {
                      const subItemKey = `sub-${subItem.id}`;
                      // console.log("Sub nav item:", subItem, "Key:", subItemKey);
                      return (
                        <li
                          onClick={() => {
                            boldSubNavItem(subItem);
                          }}
                          key={subItemKey}
                          className={`flex h-10 items-center justify-center pl-4 hover:bg-lightGrayBgHover ${
                            isSubmenuShown ? "block" : "hidden"
                          } ${
                            activeSubNavItem === subItem
                              ? "border-r-4 border-black"
                              : ""
                          }`}
                        >
                          <NavLink
                            to={subItem.path}
                            className={`group flex h-full w-full items-center px-4 text-lg ${
                              activeSubNavItem === subItem ? "font-black" : ""
                            }`}
                          >
                            <span
                              className={`${activeSubNavItem === subItem ? "text-textDarkGray" : "text-[#757575]"} text-2xl transition-colors group-hover:text-textDarkGray`}
                            >
                              {subItem.icon}
                            </span>
                            <span className="ml-4 text-[15.5px]">
                              {subItem.title}
                            </span>
                          </NavLink>
                        </li>
                      );
                    })}
                </>
              );
            })}
          </ul>
        )}

        {/*{showChat && siteKey && (*/}
        {/*  <div className="flex h-full w-full flex-col items-start justify-start py-20">*/}
        {/*    <ChatRooms siteKey={siteKey}></ChatRooms>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*<ul className="flex w-full flex-col items-center space-y-2 pb-4">*/}
        {/*  <BaseButtonRounded*/}
        {/*    className="flex w-[220px] items-center justify-center space-x-2 px-6 font-medium text-primary"*/}
        {/*    style={{*/}
        {/*      cursor: "pointer",*/}
        {/*      transition: "color 0.3s",*/}
        {/*    }}*/}
        {/*    onMouseEnter={() => setIsHovered(true)}*/}
        {/*    onMouseLeave={() => setIsHovered(false)}*/}
        {/*    onClick={() => setShowChat(!showChat)}*/}
        {/*  >*/}
        {/*    <a*/}
        {/*      target="_blank"*/}
        {/*      rel="noopener noreferrer"*/}
        {/*      className="flex items-center space-x-2"*/}
        {/*      style={{*/}
        {/*        display: "flex",*/}
        {/*        alignItems: "center",*/}
        {/*        width: "100%",*/}
        {/*        height: "100%",*/}
        {/*        textDecoration: "none",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <ChatBubble className="iconStyle text-lg" />*/}
        {/*      <span className="pl-1 tracking-wider">*/}
        {/*        {showChat ? "HIDE CHAT" : "SHOW CHAT"}*/}
        {/*      </span>*/}
        {/*    </a>*/}
        {/*  </BaseButtonRounded>*/}
        {/*</ul>*/}

        <ul className="flex flex-col items-center space-y-2 pb-4 pt-2">
          <li className="text-sm">
            <a
              href={HELP_CENTER_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              Help Center and Support
            </a>
          </li>
          <li>
            <BaseButtonRounded className="w-[220px]">
              <a
                href={MOBILE_APPS_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2"
              >
                <FaAndroid className="text-lg" />
                <FaApple className="text-lg" />
                <span className="pl-1 tracking-wider">MOBILE APPS</span>
              </a>
            </BaseButtonRounded>
          </li>
        </ul>
      </nav>
    </div>
  );
}
