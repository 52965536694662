/* eslint-disable no-nested-ternary */
//Libs
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Combobox, Transition } from "@headlessui/react";
import { Dispatch, Fragment, useRef, useState } from "react";

//Local

interface Props {
  currentTaskType: string;
  setCurrentTaskType: Dispatch<React.SetStateAction<string>>;
  setSelectedTaskType: (taskType: string | null) => void;
  tasks: string[] | undefined;
}

export default function TaskTypeCombobox({
  currentTaskType,
  setCurrentTaskType,
  setSelectedTaskType,
  tasks,
}: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [query, setQuery] = useState<string>("");

  const filteredTaskTypesList =
    query === ""
      ? tasks
      : tasks?.filter((taskType) =>
          taskType
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  return (
    <Combobox
      value={currentTaskType}
      onChange={(event) => {
        // 'event' contains the taskType string
        setCurrentTaskType(event);
        setSelectedTaskType(event);
      }}
    >
      <div className="relative mt-1">
        <div className="relative">
          <Combobox.Input
            className="relative h-10 w-full cursor-pointer rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(taskType: string) => taskType}
            autoComplete="off"
            onClick={() => {
              buttonRef.current?.click();
              setQuery("");
              setCurrentTaskType("");
              setSelectedTaskType(null);
            }}
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center pr-2"
            ref={buttonRef}
          >
            <ChevronDownIcon
              className="h-5 w-5 text-gray-700"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options className="absolute mt-1 max-h-32 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {!tasks ? (
              <Combobox.Option
                value="select work type first"
                className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-700"
                disabled={true}
              >
                Select a work type first
              </Combobox.Option>
            ) : filteredTaskTypesList &&
              filteredTaskTypesList.length === 0 &&
              query !== "" ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredTaskTypesList &&
              filteredTaskTypesList.map((taskType) => (
                <Combobox.Option
                  key={taskType}
                  className={({ active, selected }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active || selected
                        ? "bg-primaryOpacity90 text-primary"
                        : "text-gray-700"
                    }`
                  }
                  value={taskType}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {taskType}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
