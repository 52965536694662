//Libs
import { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

//Local
import {
  AddNewMembershipTemplate,
  ExistingMembershipTemplate,
  getReadableInvoiceMethod,
  invoiceFrequency,
  invoiceMethod,
} from "../../models/membership-template";
import * as strings from "../../strings";
import { ErrorMessage } from "../../components/ErrorMessage";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import BaseInputText from "../../components/BaseInputText";
import StyledMessage from "../../components/StyledMessage";
import BaseInputNumber from "../../components/BaseInputNumber";
import BaseInputSelect from "../../components/BaseInputSelect";
import { logger as devLogger } from "../../logging";

interface Props {
  /* DATA */
  membershipTemplateDoc: ExistingMembershipTemplate | null;
  /* COMPONENTS */
  selectPricebookItemIDSection: React.ReactNode;
  selectRenewalPricebookItemIDSection: React.ReactNode;
  addTaskGenerationSection: React.ReactNode;
  /* FUNCTIONS */
  handleSaveNewMembershipTemplate: (
    newMembershipTemplate: AddNewMembershipTemplate,
  ) => Promise<void>;
  handleEditMembershipTemplate: (
    updateMembershipTemplate: Partial<ExistingMembershipTemplate>,
  ) => Promise<void>;
  handleGoToMembershipTemplateListPage: () => void;
}

export const MembershipTemplateFormSchema = z.object({
  title: z.string().min(1).max(500),
  description: z.string().max(2000),
  price: z.union([z.number(), z.literal("")]),
  discount: z.union([z.number(), z.literal("")]),
  invoiceMethod: z.enum(invoiceMethod),
  invoiceFrequency: z.enum(invoiceFrequency),
  delayedStartDaysAllowed: z.union([z.number(), z.literal("")]),
});

export type MembershipTemplateFormState = z.infer<
  typeof MembershipTemplateFormSchema
>;

export default function AddEditMembershipTemplatePage({
  membershipTemplateDoc,
  selectPricebookItemIDSection,
  selectRenewalPricebookItemIDSection,
  addTaskGenerationSection,
  handleEditMembershipTemplate,
  handleSaveNewMembershipTemplate,
  handleGoToMembershipTemplateListPage,
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);
  const MembershipTemplateDefaultValues: MembershipTemplateFormState =
    useMemo(() => {
      if (membershipTemplateDoc != null) {
        return {
          title: membershipTemplateDoc.title,
          description: membershipTemplateDoc.description,
          price: membershipTemplateDoc.price,
          discount: membershipTemplateDoc.discount,
          invoiceMethod: membershipTemplateDoc.invoiceMethod,
          invoiceFrequency: membershipTemplateDoc.invoiceFrequency,
          delayedStartDaysAllowed:
            membershipTemplateDoc.delayedStartDaysAllowed,
        };
      } else {
        return {
          title: "",
          description: "",
          price: "",
          discount: "",
          invoiceMethod: invoiceMethod[0],
          invoiceFrequency: invoiceFrequency[0],
          delayedStartDaysAllowed: "",
        };
      }
    }, [membershipTemplateDoc]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<MembershipTemplateFormState>({
    defaultValues: MembershipTemplateDefaultValues,
    resolver: zodResolver(MembershipTemplateFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(MembershipTemplateDefaultValues);
  }, [MembershipTemplateDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    handleGoToMembershipTemplateListPage();
  }

  const onSubmit: SubmitHandler<MembershipTemplateFormState> = async (
    formValues,
  ) => {
    if (membershipTemplateDoc != null) {
      const partialMembershipTemplateValue: Partial<ExistingMembershipTemplate> =
        {
          title: formValues.title,
          description: formValues.description,
          price: formValues.price === "" ? 0 : formValues.price,
          discount: formValues.discount === "" ? 0 : formValues.discount,
          invoiceMethod: formValues.invoiceMethod,
          invoiceFrequency: formValues.invoiceFrequency,
          delayedStartDaysAllowed:
            formValues.delayedStartDaysAllowed === ""
              ? 0
              : formValues.delayedStartDaysAllowed,
        };

      try {
        await handleEditMembershipTemplate(partialMembershipTemplateValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    } else {
      const partialMembershipTemplateValue: AddNewMembershipTemplate = {
        title: formValues.title,
        description: formValues.description,
        price: formValues.price === "" ? 0 : formValues.price,
        discount: formValues.discount === "" ? 0 : formValues.discount,
        invoiceMethod: formValues.invoiceMethod,
        invoiceFrequency: formValues.invoiceFrequency,
        delayedStartDaysAllowed:
          formValues.delayedStartDaysAllowed === ""
            ? 0
            : formValues.delayedStartDaysAllowed,
        priceBookItemID: null,
        renewalPriceBookItemID: null,
        taskGeneration: [],
        deleted: false,
      };

      try {
        await handleSaveNewMembershipTemplate(partialMembershipTemplateValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    }
  };

  const errorMessage = membershipTemplateDoc ? (
    <ErrorMessage
      message={strings.ERROR_EDITING_MEMBERSHIP_TEMPLATE}
      clearMessage={() => setDisplayError(false)}
    />
  ) : (
    <ErrorMessage
      message={strings.ERROR_CREATING_MEMBERSHIP_TEMPLATE}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <div className="relative max-w-fit p-8 text-lg md:max-w-full">
      <h1 className="mb-8 text-5xl font-semibold text-primary">
        {membershipTemplateDoc
          ? strings.EDIT_TEMPLATE
          : strings.ADD_NEW_TEMPLATE}
      </h1>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-8 md:grid md:grid-cols-2"
      >
        {/* Field: Title */}
        <div>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Title"
                inputName="title"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {errors.title?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.title.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Description */}
        <div>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Description"
                inputName="description"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.description?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.description.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Price */}
        <div>
          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <BaseInputNumber
                text="Price"
                inputName="price"
                admin={true}
                required={true}
                {...field}
                value={field.value}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  if (displayError === true && numberValue > 0) {
                    setDisplayError(false);
                  }
                  field.onChange(isNaN(numberValue) ? "" : numberValue);
                }}
              />
            )}
          />
          {errors.price?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.price.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Discount */}
        <div>
          <Controller
            name="discount"
            control={control}
            render={({ field }) => (
              <BaseInputNumber
                text="Discount"
                inputName="discount"
                admin={true}
                required={false}
                {...field}
                value={field.value}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  if (displayError === true && numberValue > 0) {
                    setDisplayError(false);
                  }
                  field.onChange(isNaN(numberValue) ? "" : numberValue);
                }}
              />
            )}
          />
          {errors.discount?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.discount.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Invoice Method */}
        <div>
          <Controller
            name="invoiceMethod"
            control={control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="invoiceMethod"
                text="Invoice Method"
                admin={true}
                required={true}
                className="capitalize"
                {...field}
                value={field.value === null ? "" : field.value}
                onChange={(event) =>
                  field.onChange(
                    event.target.value === "" ? null : event.target.value,
                  )
                }
              >
                {invoiceMethod.map((method, idx) => {
                  return (
                    <option key={idx} value={method}>
                      {getReadableInvoiceMethod(method)}
                    </option>
                  );
                })}
              </BaseInputSelect>
            )}
          />
          {errors.invoiceMethod?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.invoiceMethod.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Invoice Frequency */}
        <div>
          <Controller
            name="invoiceFrequency"
            control={control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="invoiceFrequency"
                text="Invoice Frequency"
                admin={true}
                required={true}
                className="capitalize"
                {...field}
                value={field.value === null ? "" : field.value}
                onChange={(event) =>
                  field.onChange(
                    event.target.value === "" ? null : event.target.value,
                  )
                }
              >
                {invoiceFrequency.map((frequency, idx) => {
                  return (
                    <option key={idx} value={frequency} className="capitalize">
                      {frequency}
                    </option>
                  );
                })}
              </BaseInputSelect>
            )}
          />
          {errors.invoiceMethod?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.invoiceMethod.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Delayed Start Days Allowed */}
        <div>
          <Controller
            name="delayedStartDaysAllowed"
            control={control}
            render={({ field }) => (
              <BaseInputNumber
                text="Delayed Start Days Allowed"
                inputName="delayedStartDaysAllowed"
                admin={true}
                required={false}
                {...field}
                value={field.value}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  if (displayError === true && numberValue > 0) {
                    setDisplayError(false);
                  }
                  field.onChange(isNaN(numberValue) ? "" : numberValue);
                }}
              />
            )}
          />
          {errors.delayedStartDaysAllowed?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.delayedStartDaysAllowed.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* SECTION: SELECT PB ITEM ID & RENEWAL PBITEM ID */}
        <div className="flex flex-col gap-4 md:col-span-2 md:grid md:grid-cols-2">
          {selectPricebookItemIDSection}
          {selectRenewalPricebookItemIDSection}
          {addTaskGenerationSection}
        </div>

        {/* Action Buttons */}
        <div className="col-span-2 flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-fit justify-center uppercase"
            onClick={closeAndReset}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-fit justify-center uppercase"
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </form>
      {displayError ? (
        <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
}
