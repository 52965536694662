import { z } from "zod";

/**
 * From zod documentation:
 * https://github.com/colinhacks/zod#json-type
 */
export type Literal = boolean | null | number | string;
export type Json = Literal | { [key: string]: Json } | Json[];
export const literalSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.null(),
]);
export const jsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
);
