// Libs
import { DocumentSnapshot } from "firebase/firestore";
import { DateTime } from "luxon";
import { z } from "zod";

// Local
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject, convertFSTimestampToLuxonDT } from "../utils";
import {
  ComplianceItemCommon,
  ComplianceItem_UnionSchema,
} from "./compliance-item";
import {
  complianceRequirementTypes,
  ComplianceRequirementTypes,
  complianceResponseStatuses,
  ComplianceResponseStatuses,
  isComplianceResponseStatus,
} from "./compliance-types-and-statuses";

// siteKeys/{siteKey}/complianceResponses
// #region SECTION: Types & Schemas
export type ComplianceResponse = {
  // Interesting bits
  requirementID: string; // Foreign Key
  customFieldsAnswers: Record<string, ComplianceItemAnswers>; // ID and answer key value pairs
  siteKeyCompanyID: string;
  status: ComplianceResponseStatuses;

  /**
   * In the app, this is a Luxon DateTime object. When storing to the DB, we convert
   * it to a Firestore Timestamp. When calling the API, we send an ISO datetime string.
   */
  timestampReviewed: DateTime | null;
  reviewedBy: string | null; // uid

  // reviewComments is only added by the reviewer when approved or rejected.
  reviewComments: string | null;

  // Duplicated data from Requirement
  type: ComplianceRequirementTypes;
  title: string;
  description: string;
  customFields: Record<string, ComplianceItemCommon>;

  // Common properties every document wants
  /**
   * In the app, this is a Luxon DateTime object. When storing to the DB, we convert
   * it to a Firestore Timestamp. When calling the API, we send an ISO datetime string.
   */
  timestampCreated: DateTime; // When contractor submits requirement response
  /**
   * In the app, this is a Luxon DateTime object. When storing to the DB, we convert
   * it to a Firestore Timestamp. When calling the API, we send an ISO datetime string.
   */
  timestampLastModified: DateTime;
  createdBy: string; // uid
  lastModifiedBy: string; // uid
  deleted: boolean;
};

export type ComplianceItemAnswers = string | number | null;

type FirestoreAttributes = {
  id: string;
  refPath: string;
};

export type ExistingComplianceResponse = ComplianceResponse &
  FirestoreAttributes;

const CommonSchema = z.object({
  requirementID: z.string().min(1).max(200),
  customFieldsAnswers: z.record(z.union([z.string(), z.number(), z.null()])),
  siteKeyCompanyID: z.string().min(1).max(200),
  status: z.enum(complianceResponseStatuses),

  reviewedBy: z.string().min(1).max(200).nullable(),
  reviewComments: z.string().nullable(),

  title: z.string().min(1).max(2000),
  description: z.string().min(1).max(2000),
  type: z.enum(complianceRequirementTypes),
  customFields: z.record(ComplianceItem_UnionSchema),

  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  deleted: z.boolean(),
});

/** The schema used within the app */
const ResponseSchema = CommonSchema.extend({
  timestampReviewed: z.custom<DateTime>().nullable(),
  timestampCreated: z.custom<DateTime>(),
  timestampLastModified: z.custom<DateTime>(),
});

/***********************/
// FOR THE API
/***********************/
// FOR THE CREATE ENDPOINT
type withoutTimestamps = Omit<
  ComplianceResponse,
  "timestampCreated" | "timestampLastModified" | "timestampReviewed"
>;
export interface ComplianceResponse_CreateAPI extends withoutTimestamps {
  siteKey: string;
  /** ISO datetime string */
  timestampReviewed: string | null;
  /** ISO datetime string */
  timestampCreated: string;
  /** ISO datetime string */
  timestampLastModified: string;
}
/** The schema used for interacting with the Create endpoint */
const ResponseSchema_CreateAPI = CommonSchema.extend({
  siteKey: z.string().min(1).max(200),
  timestampReviewed: z.string().min(1).max(200).nullable(),
  timestampCreated: z.string().min(1).max(200),
  timestampLastModified: z.string().min(1).max(200),
});

// FOR THE REVIEW ENDPOINT
interface ReviewAPI_Reject {
  status: "rejected";
  reviewComments: string;
}
interface ReviewAPI_Approve {
  status: "approved";
  reviewComments: null;
}
// Discriminated union.
type ReviewAPI_StatusUnion = ReviewAPI_Approve | ReviewAPI_Reject;

export type ComplianceResponse_ReviewAPI = {
  reviewedBy: string;
  /** ISO datetime string */
  timestampReviewed: string;
} & FirestoreAttributes &
  Pick<ComplianceResponse, "lastModifiedBy"> &
  Pick<ComplianceResponse_CreateAPI, "timestampLastModified"> &
  ReviewAPI_StatusUnion;

/**
 * The base schema used for interacting with the Review endpoint. The ReviewResponseSchema_Approve
 * and ReviewResponseSchema_Reject schemas are what we're actually validating against.
 */
const ReviewSchema_Common = z.object({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
  reviewedBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  timestampReviewed: z.string().min(1).max(200),
  timestampLastModified: z.string().min(1).max(200),
});

const ReviewResponseSchema_Approve = ReviewSchema_Common.extend({
  status: z.literal("approved"),
  reviewComments: z.null(),
});

const ReviewResponseSchema_Reject = ReviewSchema_Common.extend({
  status: z.literal("rejected"),
  reviewComments: z.string().max(2000),
});

// FOR THE DELETE ENDPOINT
export interface ComplianceResponse_DeleteAPI {
  siteKey: string;
  responseID: string;
}
const ResponseSchema_DeleteAPI = z.object({
  siteKey: z.string().min(1).max(2000),
  responseID: z.string().min(1).max(2000),
});
// #endregion Types & Schemas

// #region SECTION: Functions
/** Collection of functions that interact with ComplianceResponse docs. */
export const ComplianceResponseManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingComplianceResponse
   * object. Converts timestamp fields from Firestore Timestamps to Luxon DateTime
   * objects. @throws NotFoundError if the snapshot does not exist.
   */
  createFromSnapshot: createResponseFromSnapshot,
  /** Validate a ComplianceResponse doc. */
  parse: validateComplianceResponse,
  /**
   * Validate a ComplianceResponse doc that's coming from/going to the create
   * endpoint - timestamp fields are ISO datetime strings. Also need the siteKey,
   * which doesn't exist on the 'normal' ComplianceResponse doc.
   */
  parseCreateAPI: validateCreateAPI,
  /** Validate a compliance response for the API review endpoint */
  parseReviewAPI: validateReviewAPI,
  /** Ensure there's a siteKey and complianceResponseID to send to the backend */
  parseDeleteAPI: validateDeleteAPI,
};

function createResponseFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingComplianceResponse {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapData = snapshot.data();
  // Must convert Firestore Timestamps to Luxon DateTime objects before validating.
  if (snapData.timestampReviewed !== null) {
    snapData.timestampReviewed = convertFSTimestampToLuxonDT(
      snapData.timestampReviewed,
    );
  }
  snapData.timestampCreated = convertFSTimestampToLuxonDT(
    snapData.timestampCreated,
  );
  snapData.timestampLastModified = convertFSTimestampToLuxonDT(
    snapData.timestampLastModified,
  );

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateComplianceResponse(snapData),
  };
}

function validateComplianceResponse(doc: unknown): ComplianceResponse {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`ComplianceResponse is not an object: ${doc}`);
  }
  return ResponseSchema.parse(doc);
}

function validateCreateAPI(doc: unknown): ComplianceResponse_CreateAPI {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`APIComplianceResponse is not an object: ${doc}`);
  }
  return ResponseSchema_CreateAPI.parse(doc);
}

function validateReviewAPI(doc: unknown): ComplianceResponse_ReviewAPI {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`Compliance response data is not an object: ${doc}`);
  }
  if (!isComplianceResponseStatus(doc.status)) {
    throw new Error(
      `compliance response status is not recognized: ${doc.status}`,
    );
  }

  switch (doc.status) {
    case "approved": {
      return ReviewResponseSchema_Approve.parse(doc);
    }
    case "rejected": {
      return ReviewResponseSchema_Reject.parse(doc);
    }
    case "awaiting approval": {
      throw new Error(
        "compliance response status should not be awaiting approval",
      );
    }
    default: {
      // 👁️ 🧡 Typescript
      const _exhaustiveCheck: never = doc.status;
      return _exhaustiveCheck;
    }
  }
}

function validateDeleteAPI(deleteData: unknown): ComplianceResponse_DeleteAPI {
  if (!guardIsPlainObject(deleteData)) {
    throw new Error(`Delete response is not an object: ${deleteData}`);
  }
  return ResponseSchema_DeleteAPI.parse(deleteData);
}
// #endregion Functions
