// Libs
import { DocumentSnapshot, DocumentData, Timestamp } from "firebase/firestore";
import { z } from "zod";

// Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

// #region SECTION: Schemas / Interfaces / Types
export type RootUsersMapParams = Pick<
  RootUser,
  "appLastOpenedTimestamp" | "currentAppVersion" | "currentBundleID"
>;

/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
const RootUserSchema = z.object({
  displayName: z.string().min(1).max(200),
  companyName: z.string().min(1).max(200),
  jobTitle: z.string().min(1).max(200),
  department: z.string().min(1).max(200),
  phone: z.string().max(200),
  email: z.string().max(200),
  appLastOpenedTimestamp: z.instanceof(Timestamp).nullable(),
  currentBundleID: z.string().max(200).nullable(),
  currentAppVersion: z.number().nullable(),
  defaultSiteKey: z.string().nullable(),
  receiveNotifications: z.boolean(), // TODO(Dan): need to check if this is nullable in firestore rules
});

/** For reading from the database. */
const RootUserSchemaWithFallbacks = z.object({
  displayName: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "RootUserSchemaWithFallbacks: 'displayName'",
  ),
  companyName: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "RootUserSchemaWithFallbacks: 'companyName'",
  ),
  jobTitle: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "RootUserSchemaWithFallbacks: 'jobTitle'",
  ),
  department: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "RootUserSchemaWithFallbacks: 'department'",
  ),
  phone: zFallback(
    z.string().max(200),
    "unknown",
    "RootUserSchemaWithFallbacks: 'phone'",
  ),
  email: z.string().max(200),
  appLastOpenedTimestamp: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "RootUserSchemaWithFallbacks: 'appLastOpenedTimestamp'",
  ),
  currentBundleID: zFallback(
    z.string().max(200).nullable(),
    null,
    "RootUserSchemaWithFallbacks: 'currentBundleID'",
  ),
  currentAppVersion: zFallback(
    z.number().nullable(),
    null,
    "RootUserSchemaWithFallbacks: 'currentAppVersion'",
  ),
  defaultSiteKey: zFallback(
    z.string().nullable(),
    null,
    "RootUserSchemaWithFallbacks: 'defaultSiteKey'",
  ),
  receiveNotifications: zFallback(
    z.boolean(),
    true,
    "RootUserSchemaWithFallbacks: 'receiveNotifications'",
  ),
});

export type RootUser = z.infer<typeof RootUserSchema>;
// Separate type/interface for 'new' and existing
export interface ExistingRootUser extends RootUser {
  id: string;
  refPath: string;
}
// #endregion

// #region SECTION: Functions
/** Utilities for interacting with RootUser objects. */
export const RootUserManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingRootUser object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createRootUserFromSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  convertForFirestore: convertRootUserForFirestore,
  /** Validate a RootUser doc. Use for writing to the database or reading from the user. */
  parse: validateRootUser,
  /** Validate a RootUser doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateRootUserWithFallbacks,
  parseNewAccount: validateNewAccount,
};

/**
 * Convert the Document Snapshot into a validated ExistingRootUser object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createRootUserFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingRootUser {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.exists()}`,
    );
  }
  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateRootUserWithFallbacks(snapshotData),
  };
}

const createAccountSchema = RootUserSchema.pick({
  displayName: true,
  companyName: true,
  jobTitle: true,
  department: true,
  phone: true,
  email: true,
});
export type CreateAccountState = z.infer<typeof createAccountSchema>;

function validateNewAccount(value: unknown): CreateAccountState {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = createAccountSchema.parse(value);
  return result;
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertRootUserForFirestore(rootUser: ExistingRootUser): DocumentData {
  // Copy before modifying. Don't want to modify original object.
  const local = Object.assign({}, rootUser);
  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/**
 * Throws an error if it doesn't pass validation
 */
function validateRootUser(rootUser: unknown): RootUser {
  if (!guardIsPlainObject(rootUser)) {
    throw new Error(`rootUser is not an object: ${rootUser}`);
  }

  return RootUserSchema.parse(rootUser);
}

/** For validating stuff coming in from the database. */
function validateRootUserWithFallbacks(rootUser: unknown): RootUser {
  if (!guardIsPlainObject(rootUser)) {
    throw new Error(`rootUser is not an object: ${rootUser}`);
  }

  return RootUserSchemaWithFallbacks.parse(rootUser);
}
// #endregion
