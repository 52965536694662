//Libs
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/react/24/solid";

//Local
import { ComplianceItemCommon } from "../../models/compliance-item";
import { ComplianceItemAnswers } from "../../models/compliance-response";
import { StyledTooltip } from "../../components/StyledTooltip";
import * as strings from "../../strings";
import { ComplianceResponseStatuses } from "../../models/compliance-types-and-statuses";

export function getCompanyStatusIcon(
  status: ComplianceResponseStatuses,
): JSX.Element {
  //default status set to "awaiting approval"
  let companyStatusIcon: JSX.Element;
  switch (status) {
    case "approved":
      companyStatusIcon = (
        <StyledTooltip title={strings.COMPANY_STATUS_APPROVED}>
          <CheckCircleIcon className=" text-greenPass" />
        </StyledTooltip>
      );
      break;
    case "rejected":
      companyStatusIcon = (
        <StyledTooltip title={strings.COMPANY_STATUS_REJECTED}>
          <ExclamationCircleIcon className=" text-redFail" />
        </StyledTooltip>
      );
      break;
    case "awaiting approval":
      companyStatusIcon = (
        <StyledTooltip title={strings.AWAITING_APPROVAL_STATUS}>
          <EllipsisHorizontalCircleIcon className="text-yellow-300" />
        </StyledTooltip>
      );
      break;
    default:
      const _exhaustivenessCheck: never = status;
      return _exhaustivenessCheck;
  }
  return companyStatusIcon;
}

export function getResponseStatusIcon(
  status: ComplianceResponseStatuses,
): JSX.Element {
  //default status set to "awaiting approval"
  let responseStatusIcon: JSX.Element;
  switch (status) {
    case "approved":
      responseStatusIcon = (
        <StyledTooltip title={strings.RESPONSE_STATUS_APPROVED}>
          <CheckCircleIcon className=" text-greenPass" />
        </StyledTooltip>
      );
      break;
    case "rejected":
      responseStatusIcon = (
        <StyledTooltip title={strings.RESPONSE_STATUS_REJECTED}>
          <XCircleIcon className=" text-redFail" />
        </StyledTooltip>
      );
      break;
    case "awaiting approval":
      responseStatusIcon = (
        <StyledTooltip title={strings.AWAITING_APPROVAL_STATUS}>
          <EllipsisHorizontalCircleIcon className="text-yellow-300" />
        </StyledTooltip>
      );
      break;
    default:
      const _exhaustivenessCheck: never = status;
      return _exhaustivenessCheck;
  }
  return responseStatusIcon;
}

export function getAnswerStatusIcon(
  complianceItem: ComplianceItemCommon,
  complianceItemAnswer: ComplianceItemAnswers,
): JSX.Element | null {
  let answerStatusIcon: JSX.Element | null;

  if (complianceItemAnswer == null) {
    answerStatusIcon = (
      <StyledTooltip title={strings.NOT_PASSING_RESPONSE_STATUS}>
        <XCircleIcon className=" text-redFail" />
      </StyledTooltip>
    );
    return answerStatusIcon;
  }

  switch (complianceItem.responseType) {
    case "string":
      if (
        (complianceItemAnswer === "" || complianceItemAnswer == null) &&
        complianceItem.required === true
      ) {
        answerStatusIcon = (
          <StyledTooltip title={strings.NOT_PASSING_RESPONSE_STATUS}>
            <XCircleIcon className=" text-redFail" />
          </StyledTooltip>
        );
      } else {
        answerStatusIcon = null;
      }
      break;
    case "selection":
      if (
        complianceItem.passingOptions?.includes(complianceItemAnswer as any)
      ) {
        answerStatusIcon = (
          <StyledTooltip title={strings.PASSING_RESPONSE_STATUS}>
            <CheckCircleIcon className=" text-greenPass" />
          </StyledTooltip>
        );
      } else {
        answerStatusIcon = (
          <StyledTooltip title={strings.NOT_PASSING_RESPONSE_STATUS}>
            <XCircleIcon className=" text-redFail" />
          </StyledTooltip>
        );
      }
      break;
    case "float":
    case "integer":
      if (
        complianceItem.passingMin != null &&
        complianceItem.passingMax != null
      ) {
        if (
          (complianceItemAnswer as number) >= complianceItem.passingMin &&
          (complianceItemAnswer as number) <= complianceItem.passingMax
        ) {
          answerStatusIcon = (
            <StyledTooltip title={strings.PASSING_RESPONSE_STATUS}>
              <CheckCircleIcon className=" text-greenPass" />
            </StyledTooltip>
          );
        } else {
          answerStatusIcon = (
            <StyledTooltip title={strings.NOT_PASSING_RESPONSE_STATUS}>
              <XCircleIcon className=" text-redFail" />
            </StyledTooltip>
          );
        }
      } else if (
        complianceItem.passingMin == null &&
        complianceItem.passingMax != null
      ) {
        if ((complianceItemAnswer as number) <= complianceItem.passingMax) {
          answerStatusIcon = (
            <StyledTooltip title={strings.PASSING_RESPONSE_STATUS}>
              <CheckCircleIcon className=" text-greenPass" />
            </StyledTooltip>
          );
        } else {
          answerStatusIcon = (
            <StyledTooltip title={strings.NOT_PASSING_RESPONSE_STATUS}>
              <XCircleIcon className=" text-redFail" />
            </StyledTooltip>
          );
        }
      } else if (
        complianceItem.passingMin != null &&
        complianceItem.passingMax == null
      ) {
        if ((complianceItemAnswer as number) >= complianceItem.passingMin) {
          answerStatusIcon = (
            <StyledTooltip title={strings.PASSING_RESPONSE_STATUS}>
              <CheckCircleIcon className=" text-greenPass" />
            </StyledTooltip>
          );
        } else {
          answerStatusIcon = (
            <StyledTooltip title={strings.NOT_PASSING_RESPONSE_STATUS}>
              <XCircleIcon className=" text-redFail" />
            </StyledTooltip>
          );
        }
      } else {
        answerStatusIcon = (
          <StyledTooltip title={strings.NO_STATUS}>
            <QuestionMarkCircleIcon className=" text-gray-500" />
          </StyledTooltip>
        );
      }
      break;
    default:
      const _exhaustivenessCheck: never = complianceItem.responseType;
      return _exhaustivenessCheck;
  }
  return answerStatusIcon;
}
