import {
  ChartBarIcon,
  ChatBubbleLeftEllipsisIcon,
  CameraIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import { StyledTooltip } from "./StyledTooltip";

interface Props {
  showPhotoNotification: boolean;
  responseComment: string | null;
  onClickHistoryIcon: () => void;
  onClickCameraIcon: () => void;
  onClickCommentIcon: () => void;
}
export const iconStyles =
  "h-10 w-10 fill-current cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 xs:h-11 xs:w-11 text-primary hover:text-primaryDark focus:ring-primaryLight";
export const notificationStyles =
  "absolute -top-px right-px text-sm h-5 w-5 rounded-[50%] font-bold flex justify-center items-center text-white xs:top-px";

export default function ResponseCardIconRow({
  showPhotoNotification,
  responseComment,
  onClickHistoryIcon,
  onClickCameraIcon,
  onClickCommentIcon,
}: Props) {
  return (
    <div className="flex justify-end">
      <StyledTooltip title="Photos">
        <PhotoIconWithRef
          onClick={onClickCameraIcon}
          showPhotoNotification={showPhotoNotification}
        />
      </StyledTooltip>

      <StyledTooltip title="Comment">
        <CommentIconWithRef
          onClick={onClickCommentIcon}
          responseComment={responseComment}
        />
      </StyledTooltip>

      <StyledTooltip title="View History">
        <HistoryIconWithRef onClick={onClickHistoryIcon} />
      </StyledTooltip>
    </div>
  );
}

type PhotoProps = {
  showPhotoNotification: boolean;
} & React.ComponentPropsWithRef<"button">;

const PhotoIconWithRef = React.forwardRef<HTMLButtonElement, PhotoProps>(
  ({ showPhotoNotification, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className="relative mr-1 rounded-sm p-1 focus:outline-none focus:ring-2 focus:ring-primaryLight xs:mr-3"
      >
        <CameraIcon aria-label="view photos" className={iconStyles} />
        {showPhotoNotification ? (
          <span className={`${notificationStyles} bg-amber-600`} />
        ) : null}
      </button>
    );
  },
);

type CommentProps = {
  responseComment: string | null;
} & React.ComponentPropsWithRef<"button">;

const CommentIconWithRef = React.forwardRef<HTMLButtonElement, CommentProps>(
  ({ responseComment, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className="relative mr-1 rounded-sm p-1 focus:outline-none focus:ring-2 focus:ring-primaryLight xs:mr-3"
      >
        <ChatBubbleLeftEllipsisIcon
          aria-label="view comment"
          className={iconStyles}
        />
        {!responseComment ? null : (
          <span className={`${notificationStyles} bg-amber-600`} />
        )}
      </button>
    );
  },
);

const HistoryIconWithRef = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithRef<"button">
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className="rounded-sm p-1 focus:outline-none focus:ring-2 focus:ring-primaryLight"
    >
      <ChartBarIcon aria-label="view history" className={iconStyles} />
    </button>
  );
});
