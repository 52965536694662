//Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import {
  SubmitHandler,
  useForm,
  Controller,
  UseFormHandleSubmit,
  UseFormReset,
  FieldErrors,
  Control,
} from "react-hook-form";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { z } from "zod";

//Local
import * as strings from "../strings";
import BaseInputText from "../components/BaseInputText";
import StyledMessage from "../components/StyledMessage";
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import BaseButtonSecondary from "../components/BaseButtonSecondary";

interface Props {
  handleSave: (formValues: CreateAccountFormState) => Promise<void>;
  goToLoginPage: () => void;
  logo: string;
}

//Schema
export const CreateAccountFormSchema = z
  .object({
    displayName: z
      .string()
      .min(1, { message: strings.REQUIRED_FIELD })
      .max(200),
    companyName: z
      .string()
      .min(1, { message: strings.REQUIRED_FIELD })
      .max(200),
    jobTitle: z.string().min(1, { message: strings.REQUIRED_FIELD }).max(200),
    department: z.string().min(1, { message: strings.REQUIRED_FIELD }).max(200),
    phone: z.string().min(1, { message: strings.REQUIRED_FIELD }).max(200),
    email: z.string().min(1, { message: strings.REQUIRED_FIELD }).max(200),
    password: z
      .string()
      .min(6, { message: strings.passwordFieldLengthRequired(6) })
      .max(200),
    confirmPassword: z
      .string()
      .min(1, { message: strings.REQUIRED_FIELD })
      .max(200),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"], // path of error
  });

//Inferred type
export type CreateAccountFormState = z.infer<typeof CreateAccountFormSchema>;

export default function CreateAccountPage(props: Props) {
  const createAccountDefaultValues = {
    displayName: "",
    email: "",
    jobTitle: "",
    department: "",
    phone: "",
    companyName: "",
    password: "",
    confirmPassword: "",
  };

  //react-hook-form
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CreateAccountFormState>({
    defaultValues: createAccountDefaultValues,
    resolver: zodResolver(CreateAccountFormSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CreateAccountFormState> = async (
    formValues: CreateAccountFormState,
  ) => {
    await props.handleSave(formValues);
  };

  return (
    <div className="mx-auto flex w-full flex-col items-center space-y-8 px-2 pb-10 lg:max-w-screen-lg lg:px-2">
      <div className="mb-8 flex flex-col items-center space-y-8 rounded-md bg-bgLogo p-8">
        <img src={props.logo} alt="company logo" className="w-96" />
        <div className="text-center text-3xl font-bold text-textLogo">
          Industrial Work Management Software
        </div>
      </div>

      <TheForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        errors={errors}
        control={control}
        reset={reset}
        isSubmitting={isSubmitting}
      />
      <div className="flex w-full max-w-xs flex-col space-y-2">
        <span className="text-center font-semibold text-gray-400">
          {strings.buttons.ALREADY_HAVE_ACCOUNT}
        </span>
        <BaseButtonSecondary
          type="button"
          onClick={props.goToLoginPage}
          className="w-full justify-center uppercase"
        >
          {strings.buttons.LOGIN}
        </BaseButtonSecondary>
      </div>
    </div>
  );
}

// #region SECTION: The Form
interface FormProps {
  handleSubmit: UseFormHandleSubmit<CreateAccountFormState>;
  onSubmit: SubmitHandler<CreateAccountFormState>;
  errors: FieldErrors;
  control: Control<CreateAccountFormState, any>;
  reset: UseFormReset<CreateAccountFormState>;
  isSubmitting: boolean;
}

// Extracted 'TheForm' because: https://stackoverflow.com/a/67002581
const TheForm = (props: FormProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit(props.onSubmit)}
      className="contents space-y-8"
    >
      <div className="mt-4 flex w-full max-w-sm flex-col space-y-8 lg:grid lg:max-w-full lg:grid-cols-2 lg:gap-8 lg:space-y-0">
        {/* FULL NAME */}
        <div>
          <Controller
            name="displayName"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                required
                inputName="displayName"
                text="Full Name"
                admin={true}
                {...field}
              />
            )}
          />
          {props.errors.displayName?.message &&
            typeof props.errors.displayName.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.displayName.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* EMAIL */}
        <div>
          <Controller
            name="email"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                required
                inputName="email"
                text="Email"
                admin={true}
                {...field}
              />
            )}
          />
          {props.errors.email?.message &&
            typeof props.errors.email.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.email.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* COMPANY */}
        <div>
          <Controller
            name="companyName"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                required
                inputName="companyName"
                text="Company Name"
                admin={true}
                {...field}
              />
            )}
          />
          {props.errors.companyName?.message &&
            typeof props.errors.companyName.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.companyName.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* DEPARTMENT */}
        <div>
          <Controller
            name="department"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                required
                inputName="department"
                text="Department"
                admin={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {props.errors.department?.message &&
            typeof props.errors.department.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.department.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* JOB TITLE */}
        <div>
          <Controller
            name="jobTitle"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                required
                inputName="jobTitle"
                text="Job Title"
                admin={true}
                {...field}
              />
            )}
          />
          {props.errors.jobTitle?.message &&
            typeof props.errors.jobTitle.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.jobTitle.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* PHONE */}
        <div>
          <Controller
            name="phone"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                required
                inputName="phone"
                text="Phone"
                admin={true}
                {...field}
              />
            )}
          />
          {props.errors.phone?.message &&
            typeof props.errors.phone.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.phone.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* PASSWORD */}
        <div>
          <Controller
            name="password"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                required
                type={showPassword ? "text" : "password"}
                inputName="password"
                text="Password"
                admin={true}
                {...field}
              >
                <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-500"
                      aria-label="show password"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-500"
                      aria-label="hide password"
                    />
                  )}
                </button>
              </BaseInputText>
            )}
          />
          {props.errors.password?.message &&
            typeof props.errors.password.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.password.message }}
                </StyledMessage>
              </div>
            )}
        </div>

        {/* CONFIRM PASSWORD */}
        <div>
          <Controller
            name="confirmPassword"
            control={props.control}
            render={({ field }) => (
              <BaseInputText
                required
                type={showConfirmPassword ? "text" : "password"}
                inputName="passwordConfirm"
                text="Confirm Password"
                admin={true}
                {...field}
              >
                <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  {showConfirmPassword ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </BaseInputText>
            )}
          />
          {props.errors.confirmPassword?.message &&
            typeof props.errors.confirmPassword.message === "string" && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: props.errors.confirmPassword.message }}
                </StyledMessage>
              </div>
            )}
        </div>
      </div>
      <BaseButtonPrimary
        type="submit"
        formNoValidate
        disabled={props.isSubmitting}
        isBusy={props.isSubmitting}
        busyText={strings.buttons.BUSY_CREATING}
        className="w-full max-w-xs justify-center uppercase"
      >
        {strings.buttons.CREATE_ACCOUNT}
      </BaseButtonPrimary>
    </form>
  );
};

//#endregion
