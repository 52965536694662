export enum EventTypes {
  NEW_USER_APPROVED = 10,
  NEW_USER_APPLIED = 11,
  TASK_STATUS_UPDATED = 31,
  TASK_REASSIGNED_COMPANY = 32,
  TASK_DESCRIPTION_UPDATED = 33,
  TASK_WORK_ORDER_UPDATED = 34,
  TASK_DETAILS_UPDATED = 36,
  TASK_SCHEDULE_DATE_CHANGED = 96,
  TASK_DELETED = 98,
  TASK_RESTORED = 198,
  NEW_TASK_ADDED = 30,
  UPDATED_TITLE = 40,
  UPDATED_DESCRIPTION = 41,
  ADDED_PHOTO = 42,
  REMOVED_PHOTO = 43,
  CHANGED_ASSET = 44,
  UPDATED_CRAFT_DETAILS = 45,
  CHANGED_LOCATION_ID = 46,
  UPDATED_LOCATION_ON_MAP = 47,
  ADDED_ATTACHMENT = 48,
  REMOVED_ATTACHMENT = 49,
  CRAFT_RECORD_CREATED = 20,
  CRAFT_RECORD_DELETED = 99,
  CRAFT_RECORD_RESTORED = 199,
  SMS_NOTIFICATION_SENT = 300,
  NEW_ESTIMATE_CREATED = 350,
  ESTIMATE_UPDATED = 351,
  ESTIMATE_STATUS_CHANGED = 352,
  ESTIMATE_CONVERTED_TO_INVOICE = 360,
  INVOICE_UPDATED = 361,
  INVOICE_STATUS_CHANGED = 362,
  PAYMENT_CREATED = 365,
  PAYMENT_REFUNDED = 366,
  CUSTOMER_CREATED = 370,
  CUSTOMER_UPDATED = 371,
  CUSTOMER_LOCATION_ADDED = 372,
  CUSTOMER_LOCATION_UPDATED = 373,
  ACCOUNTING_SYNC_SUCCESS = 380,
  ACCOUNTING_SYNC_ERROR = 381,
  FEEDBACK_EMAIL_SENT_TO_CUSTOMER = 390,
  APPOINTMENT_REMINDER_EMAIL_SENT_TO_CUSTOMER = 391,
  INVOICE_EMAIL_SENT_TO_CUSTOMER = 392,
  FEEDBACK_RESPONSE_RECEIVED = 393,
}

export const OEventTypes = {
  NEW_USER_APPROVED: 10,
  NEW_USER_APPLIED: 11,
  TASK_STATUS_UPDATED: 31,
  TASK_REASSIGNED_COMPANY: 32,
  TASK_DESCRIPTION_UPDATED: 33,
  TASK_WORK_ORDER_UPDATED: 34,
  TASK_DETAILS_UPDATED: 36,
  TASK_SCHEDULE_DATE_CHANGED: 96,
  TASK_DELETED: 98,
  TASK_RESTORED: 198,
  NEW_TASK_ADDED: 30,
  UPDATED_TITLE: 40,
  UPDATED_DESCRIPTION: 41,
  ADDED_PHOTO: 42,
  REMOVED_PHOTO: 43,
  CHANGED_ASSET: 44,
  UPDATED_CRAFT_DETAILS: 45,
  CHANGED_LOCATION_ID: 46,
  UPDATED_LOCATION_ON_MAP: 47,
  ADDED_ATTACHMENT: 48,
  REMOVED_ATTACHMENT: 49,
  CRAFT_RECORD_CREATED: 20,
  CRAFT_RECORD_DELETED: 99,
  CRAFT_RECORD_RESTORED: 199,
  SMS_NOTIFICATION_SENT: 300,
  NEW_ESTIMATE_CREATED: 350,
  ESTIMATE_UPDATED: 351,
  ESTIMATE_STATUS_CHANGED: 352,
  ESTIMATE_CONVERTED_TO_INVOICE: 360,
  INVOICE_UPDATED: 361,
  INVOICE_STATUS_CHANGED: 362,
  PAYMENT_CREATED: 365,
  PAYMENT_REFUNDED: 366,
  CUSTOMER_CREATED: 370,
  CUSTOMER_UPDATED: 371,
  CUSTOMER_LOCATION_ADDED: 372,
  CUSTOMER_LOCATION_UPDATED: 373,
  ACCOUNTING_SYNC_SUCCESS: 380,
  ACCOUNTING_SYNC_ERROR: 381,
  FEEDBACK_EMAIL_SENT_TO_CUSTOMER: 390,
  APPOINTMENT_REMINDER_EMAIL_SENT_TO_CUSTOMER: 391,
  INVOICE_EMAIL_SENT_TO_CUSTOMER: 392,
  FEEDBACK_RESPONSE_RECEIVED: 393,
} as const;

export type EventTypeValues = (typeof OEventTypes)[keyof typeof OEventTypes];

/** Typeguard */
export function isValidEventType(val: unknown): val is EventTypeValues {
  return Object.values(OEventTypes).includes(val as any);
}

export function getEventTypeString(
  eventType: EventTypeValues,
  displayName: string,
): string {
  switch (eventType) {
    case OEventTypes.NEW_USER_APPROVED:
      return "New user approved";
    case OEventTypes.NEW_USER_APPLIED:
      return `${displayName} has requested approval for this site`;
    case OEventTypes.CRAFT_RECORD_CREATED:
      return "Work record created";
    case OEventTypes.NEW_TASK_ADDED:
      return "New task added";
    case OEventTypes.TASK_STATUS_UPDATED:
      return "Task status updated";
    case OEventTypes.TASK_REASSIGNED_COMPANY:
      return "Task reassigned to a different company";
    case OEventTypes.TASK_DESCRIPTION_UPDATED:
      return "Task description changed";
    case OEventTypes.TASK_WORK_ORDER_UPDATED:
      return "Task work order updated";
    case OEventTypes.TASK_DETAILS_UPDATED:
      return "Task details updated";
    case OEventTypes.TASK_SCHEDULE_DATE_CHANGED:
      return "Task Schedule Date Changed";
    case OEventTypes.UPDATED_TITLE:
      return "Updated title";
    case OEventTypes.UPDATED_DESCRIPTION:
      return "Updated description";
    case OEventTypes.ADDED_PHOTO:
      return "Added photo";
    case OEventTypes.REMOVED_PHOTO:
      return "Removed photo";
    case OEventTypes.CHANGED_ASSET:
      return "Changed asset";
    case OEventTypes.UPDATED_CRAFT_DETAILS:
      return "Work Record details updated";
    case OEventTypes.CHANGED_LOCATION_ID:
      return "Changed location";
    case OEventTypes.UPDATED_LOCATION_ON_MAP:
      return "Updated location on map";
    case OEventTypes.ADDED_ATTACHMENT:
      return "Added attachment";
    case OEventTypes.REMOVED_ATTACHMENT:
      return "Removed attachment";
    case OEventTypes.TASK_DELETED:
      return "Task deleted";
    case OEventTypes.CRAFT_RECORD_DELETED:
      return "Work record deleted";
    case OEventTypes.CRAFT_RECORD_RESTORED:
      return "Work record restored";
    case OEventTypes.TASK_RESTORED:
      return "Task restored";
    case OEventTypes.SMS_NOTIFICATION_SENT:
      return "SMS Notification sent";
    case OEventTypes.NEW_ESTIMATE_CREATED:
      return "New estimate created";
    case OEventTypes.ESTIMATE_UPDATED:
      return "Estimate updated";
    case OEventTypes.ESTIMATE_STATUS_CHANGED:
      return "Estimate status changed";
    case OEventTypes.ESTIMATE_CONVERTED_TO_INVOICE:
      return "Estimate converted to invoice";
    case OEventTypes.INVOICE_UPDATED:
      return "Invoice updated";
    case OEventTypes.INVOICE_STATUS_CHANGED:
      return "Invoice status changed";
    case OEventTypes.PAYMENT_CREATED:
      return "Payment created";
    case OEventTypes.PAYMENT_REFUNDED:
      return "Payment refunded";
    case OEventTypes.CUSTOMER_CREATED:
      return "Customer created";
    case OEventTypes.CUSTOMER_UPDATED:
      return "Customer updated";
    case OEventTypes.CUSTOMER_LOCATION_ADDED:
      return "Customer location added";
    case OEventTypes.CUSTOMER_LOCATION_UPDATED:
      return "Customer location updated";
    case OEventTypes.ACCOUNTING_SYNC_SUCCESS:
      return "Accounting sync success";
    case OEventTypes.ACCOUNTING_SYNC_ERROR:
      return "Accounting sync error";
    case OEventTypes.FEEDBACK_EMAIL_SENT_TO_CUSTOMER:
      return "Feedback email sent to customer";
    case OEventTypes.APPOINTMENT_REMINDER_EMAIL_SENT_TO_CUSTOMER:
      return "Appointment reminder email sent to customer";
    case OEventTypes.INVOICE_EMAIL_SENT_TO_CUSTOMER:
      return "Invoice email sent to customer";
    case OEventTypes.FEEDBACK_RESPONSE_RECEIVED:
      return "Feedback response received";
    default:
      const _exhaustivenessCheck: never = eventType;
      return _exhaustivenessCheck;
  }
}
