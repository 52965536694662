//Libs

//Local
import { ExistingCustomer } from "./customer";
import { ExistingStiltInvoice } from "./invoice";
import { ExistingPriceBookItem } from "./price-book-item";
import { ExistingStiltPayment } from "./stilt-payment";

export enum DocType {
  CUSTOMERS = "customers",
  PRICEBOOKITEMS = "pricebookItems",
  INVOICES = "invoices",
  PAYMENTS = "payments",
}

export interface ExistingCustomerAccountingSyncTableData
  extends ExistingCustomer {
  docType: DocType.CUSTOMERS;
}
export interface ExistingPBItemAccountingSyncTableData
  extends ExistingPriceBookItem {
  docType: DocType.PRICEBOOKITEMS;
}
export interface ExistingStiltInvoiceAccountingSyncTableData
  extends ExistingStiltInvoice {
  docType: DocType.INVOICES;
}
export interface ExistingStiltPaymentAccountingSyncTableData
  extends ExistingStiltPayment {
  docType: DocType.PAYMENTS;
}

export type TableData =
  | ExistingCustomerAccountingSyncTableData
  | ExistingPBItemAccountingSyncTableData
  | ExistingStiltInvoiceAccountingSyncTableData
  | ExistingStiltPaymentAccountingSyncTableData;
