// libs
import { useState } from "react";
import { BiSolidFile } from "react-icons/bi";
import { MdDelete, MdFileDownload, MdEmail } from "react-icons/md";
import { IoMdCalendar } from "react-icons/io";

// local
import { StyledTooltip } from "../StyledTooltip";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import LoadingSpinner from "../LoadingSpinner";
import { logger } from "../../logging";
import * as strings from "../../strings";
import { reportsFormatDateTime } from "../../Pages/Reports/ReportsContainer";

interface Props {
  siteKeyID: string;
  reportDataID: string;
  reportConfigID: string;
  reportConfigName: string;
  createdAtISO: string;
  reportSpecName: string;
  downloadReport: (siteKeyID: string, reportConfigID: string) => Promise<void>;
  emailReport: (siteKeyID: string, reportConfigID: string) => Promise<void>;
  deleteReport: (siteKeyID: string, reportDataID: string) => Promise<void>;
}

const actionOptions = ["download", "email", "delete"] as const;
type ActionOptions = (typeof actionOptions)[number];

export default function ReportsGeneratedItem(props: Props) {
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const [isBusy, setIsBusy] = useState<ActionOptions | null>(null);

  async function handleDownload(): Promise<void> {
    setIsBusy("download");
    try {
      await props.downloadReport(props.siteKeyID, props.reportConfigID);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Download complete.",
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to download the report. Please try again later.",
        type: "error",
        dialog: false,
      });
    }
  }

  async function handleEmail(): Promise<void> {
    setIsBusy("email");
    try {
      await props.emailReport(props.siteKeyID, props.reportConfigID);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Email sent.",
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to email the report. Please try again later.",
        type: "error",
        dialog: false,
      });
    }
  }

  async function handleDelete(): Promise<void> {
    setIsBusy("delete");

    try {
      await props.deleteReport(props.siteKeyID, props.reportDataID);

      setIsBusy(null);
      addToastMessage({
        id: createToastMessageID(),
        message: "Report deleted.",
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to delete the report. Please try again later.",
        dialog: false,
        type: "error",
      });
    }
  }

  return (
    <div className="flex w-full items-center py-5 sm:py-4">
      <div className="mr-auto">
        <h4 className="pb-0.5">{props.reportConfigName}</h4>

        <div className="flex flex-wrap gap-2 text-sm text-gray-500 sm:gap-4">
          <div className="flex items-center justify-center gap-2">
            <BiSolidFile className="stroke-gray-500" />
            {props.reportSpecName}
          </div>

          <div className="flex items-center justify-center gap-2">
            <IoMdCalendar className="stroke-gray-500" />
            {`Created: ${reportsFormatDateTime(props.createdAtISO)}`}
          </div>
        </div>
      </div>

      <StyledTooltip title={strings.DOWNLOAD}>
        <button
          className="rounded-full p-2 text-2xl text-black hover:cursor-pointer hover:bg-gray-100 hover:text-amber-400"
          onClick={handleDownload}
        >
          {isBusy === "download" ? (
            <LoadingSpinner marginClass="" />
          ) : (
            <MdFileDownload />
          )}
        </button>
      </StyledTooltip>

      <StyledTooltip title={strings.SEND_EMAIL}>
        <button
          className="rounded-full p-2 text-2xl text-black hover:cursor-pointer hover:bg-gray-100 hover:text-amber-400"
          onClick={handleEmail}
        >
          {isBusy === "email" ? (
            <LoadingSpinner marginClass="mr-1" />
          ) : (
            <MdEmail />
          )}
        </button>
      </StyledTooltip>

      <StyledTooltip title={strings.buttons.DELETE}>
        <button
          className="rounded-full p-2 text-2xl text-red-700 hover:cursor-pointer hover:bg-gray-100"
          onClick={handleDelete}
        >
          {isBusy === "delete" ? (
            <LoadingSpinner marginClass="mr-1" />
          ) : (
            <MdDelete />
          )}
        </button>
      </StyledTooltip>
    </div>
  );
}
