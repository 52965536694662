//Libs
import { z } from "zod";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";
import CheckCircle from "@mui/icons-material/CheckCircle";
import DatePicker from "react-datepicker";
import { Listbox, Transition } from "@headlessui/react";

//Local
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseModal from "../BaseModal";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import CreateTaskCustomerTableContainer from "../../Pages/Customers/CreateTaskCustomerTableContainer";
import { CustomerDetails } from "../estimates/CustomerDetails";
import { CustomerLocationSection } from "../customers/CreateTask";
import { DbRead, DbWrite } from "../../database";
import {
  ExistingMembership,
  getReadableMembershipStatus,
  Membership,
  MembershipManager,
  MembershipStatus,
  membershipStatus,
  MembershipStatusValues,
} from "../../models/membership";
import { useAuthStore } from "../../store/firebase-auth";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { CustomDateInput } from "../DatePickerWithManualEntry";
import BaseInputTextArea from "../BaseInputTextArea";
import { SELECT_ASSET } from "../../strings";
import AssetEquipmentListCheckboxes from "./AssetEquipmentListCheckboxes";
import { ExistingAsset } from "../../models/asset";
import { logger } from "../../logging";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";

interface Props {
  //DATA
  isDialogOpen: boolean;
  assetsEnabled: boolean;
  customer: ExistingCustomer | null;
  customerLocation: ExistingCustomerLocation | null;
  customerLocationOptions: ExistingCustomerLocation[] | null;
  locationID: string | null;
  membership: ExistingMembership | null;
  membershipTemplate: ExistingMembershipTemplate | null;
  siteKey: string;
  closeDialog: () => void;
  isSubmitting: boolean;
}

const MembershipFormSchema = z.object({
  notes: z.string().max(1000).nullable(),
  automaticallyGenerateTasks: z.boolean().optional(),
  membershipEndDate: z.instanceof(Timestamp).nullable(),
  membershipStartDate: z.instanceof(Timestamp).nullable(),
  lastCompletedTaskDate: z.instanceof(Timestamp).nullable(),
  nextScheduledTaskDate: z.instanceof(Timestamp).nullable(),
  lastPaymentDate: z.instanceof(Timestamp).nullable(),
  nextInvoiceDate: z.instanceof(Timestamp).nullable(),
});
export type MembershipFormState = z.infer<typeof MembershipFormSchema>;

export default function AddEditMembershipDialog(props: Props) {
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  const siteKeyLocationList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );

  const [selectedCustomer, setSelectedCustomer] =
    useState<ExistingCustomer | null>(props.customer);
  const [customerLocationOptions, setCustomerLocationOptions] = useState<
    ExistingCustomerLocation[] | null
  >(props.customerLocationOptions);
  const [selectedCustomerLocation, setSelectedCustomerLocation] =
    useState<ExistingCustomerLocation | null>(null);
  const [selectedSiteKeyLocation, setSelectedSiteKeyLocation] =
    useState<ExistingSiteKeyLocation | null>(null);
  const [selectedCustomerLocationID, setSelectedCustomerLocationID] = useState<
    string | null
  >(props.customerLocation?.id ?? null);
  const [selectedMembershipTemplate, setSelectedMembershipTemplate] =
    useState<ExistingMembershipTemplate | null>(
      props.membershipTemplate ?? null,
    );

  const [selectedMembershipStatus, setSelectedMembershipStatus] =
    useState<MembershipStatusValues | null>(props.membership?.status ?? null);

  const membershipTemplateList = useMembershipTemplatesStore(
    (state) => state.membershipTemplates,
  );

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Unkown Error");
  const [customerDialogStates, setCustomerDialogStates] = useState<
    Record<string, boolean>
  >({
    customerDialogOpen: false,
    selectCustomerDialogOpen: false,
  });
  const [assetsForCustomer, setAssetsForCustomer] = useState<ExistingAsset[]>(
    [],
  );
  const [assetIDs, setAssetIDs] = useState<string[] | undefined>(
    props.membership?.assetIDs ?? [],
  );

  // Fetch the asset documents for a customer
  useEffect(() => {
    async function getAssetsForCustomerLocation() {
      if (!props.membership?.customerLocationID) return undefined;

      logger.debug("getAssetsForCustomer useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribeAllAssetsByCustomerLocationID =
        DbRead.assets.subscribeByCustomerLocationID({
          siteKey: props.siteKey,
          customerLocationID: props.membership.customerLocationID,
          onChange: setAssetsForCustomer,
          onError: (error) =>
            logger.error(`Error in getAssetsForCustomer: ${error.message}`),
        });
      return unsubscribeAllAssetsByCustomerLocationID;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getAssetsForCustomerLocation();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, []);

  // set selectedSiteKeyLocation if there's only one location in the siteKeyLocationslist using useEffect
  useEffect(() => {
    if (siteKeyLocationList.length === 1) {
      setSelectedSiteKeyLocation(siteKeyLocationList[0]);
    }
  }, [siteKeyLocationList]);

  useEffect(() => {
    function getSelectedCustomerLocations() {
      if (!selectedCustomer) return undefined;

      const unsubscribe = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: selectedCustomer.id,
        onChange: setCustomerLocationOptions,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getSelectedCustomerLocations();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, selectedCustomer]);

  useEffect(() => {
    if (props.customer != null && props.isDialogOpen === true) {
      setSelectedCustomer(props.customer);
    }
  }, [props.isDialogOpen, props.customer]);

  const selectCustomerDialog = (
    <BaseModal
      open={customerDialogStates.selectCustomerDialogOpen}
      closeModal={() =>
        setCustomerDialogStates({
          ...customerDialogStates,
          selectCustomerDialogOpen: false,
        })
      }
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.SELECT_EXISTING_CUSTOMER}</h1>
          <button
            onClick={() =>
              setCustomerDialogStates({
                ...customerDialogStates,
                selectCustomerDialogOpen: false,
              })
            }
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left max-w-md md:max-w-5xl"
    >
      <div className="relative flex flex-col space-y-8 px-2 text-lg">
        <CreateTaskCustomerTableContainer
          siteKey={props.siteKey}
          onSelectCustomer={(c: ExistingCustomer) => {
            setSelectedCustomer(c);
            setCustomerDialogStates({
              ...customerDialogStates,
              selectCustomerDialogOpen: false,
            });
          }}
          addCustomerButton={null}
        />
      </div>
    </BaseModal>
  );

  const membershipDefaultValues: Pick<
    Membership,
    | "lastCompletedTaskDate"
    | "lastPaymentAmount"
    | "lastPaymentDate"
    | "membershipEndDate"
    | "membershipStartDate"
    | "nextInvoiceDate"
    | "nextScheduledTaskDate"
    | "notes"
    | "locationID"
    | "status"
  > = useMemo(() => {
    return {
      lastCompletedTaskDate: props.membership?.lastCompletedTaskDate ?? null,
      lastPaymentAmount: props.membership?.lastPaymentAmount ?? null,
      lastPaymentDate: props.membership?.lastPaymentDate ?? null,
      membershipEndDate: props.membership?.membershipEndDate ?? null,
      membershipStartDate: props.membership?.membershipStartDate ?? null,
      nextInvoiceDate: props.membership?.nextInvoiceDate ?? null,
      nextScheduledTaskDate: props.membership?.nextScheduledTaskDate ?? null,
      locationID: props.membership?.locationID ?? null,
      notes: props.membership?.notes ?? "",
      status: props.membership?.status ?? "draft",
      automaticallyGenerateTasks:
        props.membership?.automaticallyGenerateTasks ?? false,
    };
  }, [props.membership]);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<
    Pick<
      Membership,
      | "lastCompletedTaskDate"
      | "lastPaymentAmount"
      | "lastPaymentDate"
      | "membershipEndDate"
      | "membershipStartDate"
      | "nextInvoiceDate"
      | "nextScheduledTaskDate"
      | "notes"
      | "status"
      | "automaticallyGenerateTasks"
    >
  >({
    defaultValues: membershipDefaultValues,
    resolver: zodResolver(MembershipFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(membershipDefaultValues);
  }, [membershipDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the section is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    setSelectedCustomerLocation(null);
    setSelectedCustomerLocationID(null);
    setSelectedCustomer(null);
    setSelectedMembershipTemplate(null);
    props.closeDialog();
  }

  const errorMessageComponent = (
    <ErrorMessage
      message={errorMessage}
      clearMessage={() => setDisplayError(false)}
    />
  );

  const selectCustomerButton = (
    <BaseButtonSecondary
      type="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.currentTarget.blur();
        setCustomerDialogStates({
          ...customerDialogStates,
          selectCustomerDialogOpen: true,
        });
      }}
      className="w-full text-primary xs:w-auto"
    >
      <CheckCircle fontSize="small" className="mr-2" />
      {strings.SELECT_EXISTING_CUSTOMER}
    </BaseButtonSecondary>
  );

  const customerLocationSection = (
    <div className="relative z-20">
      <h6 className="text-md mb-0 max-w-fit font-extrabold text-primary">
        {strings.SELECT_LOCATION}
      </h6>
      {customerLocationOptions === null ||
      customerLocationOptions.length === 0 ? (
        <div>
          No locations to select from, please select a customer or add a
          location for this customer.
        </div>
      ) : (
        <CustomerLocationSection
          allowSelection={true}
          selectedCustomer={selectedCustomer}
          customerLocationOptions={customerLocationOptions}
          selectedCustomerLocation={selectedCustomerLocation}
          setSelectedCustomerLocation={(location) => {
            setSelectedCustomerLocation(location);
            setSelectedCustomerLocationID(location?.id ?? null);
          }}
          openEditExistingCustomerLocation={undefined}
          membershipTemplateList={[]}
          customerLocationMemberships={[]}
        />
      )}
    </div>
  );

  const onSubmit: SubmitHandler<
    Pick<
      Membership,
      | "lastCompletedTaskDate"
      | "lastPaymentDate"
      | "membershipEndDate"
      | "membershipStartDate"
      | "nextInvoiceDate"
      | "nextScheduledTaskDate"
      | "notes"
      | "automaticallyGenerateTasks"
    >
  > = async (formValues) => {
    if (!firebaseUser) {
      setErrorMessage("User Error.");
      setDisplayError(true);
      return;
    }

    if (!selectedCustomer) {
      setErrorMessage("Customer Not Selected.");
      setDisplayError(true);
      return;
    }

    if (!selectedSiteKeyLocation) {
      setErrorMessage("Site Location Not Selected.");
      setDisplayError(true);
      return;
    }

    if (!selectedCustomerLocationID) {
      setErrorMessage("Customer Location Not Selected.");
      setDisplayError(true);
      return;
    }

    const membershipTemplateID =
      selectedMembershipTemplate?.id ?? props.membership?.membershipTemplateID;
    if (!membershipTemplateID) {
      setErrorMessage("Membership Type Not Selected.");
      setDisplayError(true);
      return;
    }

    if (formValues.membershipStartDate === null) {
      setErrorMessage("Membership Start Date Not Selected.");
      setDisplayError(true);
      return;
    }

    const membership: Membership = {
      createdBy: props.membership?.createdBy ?? firebaseUser.uid,
      customerID: selectedCustomer.id,
      customerLocationID: selectedCustomerLocationID,
      customerName:
        props.membership?.customerName ?? selectedCustomer?.name ?? "--",
      lastCompletedTaskDate: formValues.lastCompletedTaskDate,
      lastModifiedBy: firebaseUser?.uid,
      lastPaymentAmount: props.membership?.lastPaymentAmount ?? null,
      lastPaymentDate: formValues.lastPaymentDate,
      locationID: selectedSiteKeyLocation.id,
      membershipEndDate: formValues.membershipEndDate,
      membershipStartDate: formValues.membershipStartDate,
      membershipTemplateID: membershipTemplateID,
      nextInvoiceDate: formValues.nextInvoiceDate,
      nextScheduledTaskDate: formValues.nextScheduledTaskDate,
      automaticallyGenerateTasks: formValues.automaticallyGenerateTasks ?? true,
      notes: formValues.notes,
      status: selectedMembershipStatus ?? MembershipStatus.Draft,
      timestampCreated: props.membership?.timestampCreated ?? Timestamp.now(),
      timestampLastModified: Timestamp.now(),
    };

    try {
      if (props.membership) {
        const validatedData = MembershipManager.convertForFirestore(membership);
        await DbWrite.memberships.update(props.siteKey, {
          id: props.membership.id,
          refPath: props.membership.refPath,
          ...validatedData,
        });
        addMessage({
          id: createToastMessageID(),
          message: strings.successfulUpdate("Membership"),
          dialog: false,
          type: "success",
        });
        closeAndReset();
      } else {
        console.log(membership);
        await DbWrite.memberships.create(
          props.siteKey,
          MembershipManager.convertForFirestore(membership),
        );
        addMessage({
          id: createToastMessageID(),
          message: strings.successfulAdd("New Membership"),
          dialog: false,
          type: "success",
        });
        closeAndReset();
      }
    } catch (error) {
      console.log(error);
      setDisplayError(true);
    }
  };

  const membershipHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {props.membership ? strings.EDIT_MEMBERSHIP : strings.NEW_MEMBERSHIP}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close new attribute form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <>
      <BaseModal
        closeModal={() => {}}
        open={props.isDialogOpen}
        title={membershipHeader}
        parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
      >
        <div className="space-y-8 p-4">
          {selectedCustomer === null && selectCustomerButton}
          {selectedCustomer && (
            <CustomerDetails
              customer={selectedCustomer}
              openEditCustomerDialog={null}
              openCallDialog={null}
            />
          )}
          {customerLocationSection}
          {!props.membership && (
            <div className="z-15 relative w-full">
              <Listbox
                value={selectedMembershipTemplate?.id ?? null}
                onChange={(event) => {
                  setSelectedMembershipTemplate(
                    membershipTemplateList.find((m) => m.id === event) ?? null,
                  );
                }}
              >
                <div className="relative">
                  <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight sm:text-sm">
                    <span className="block truncate">
                      {" "}
                      {selectedMembershipTemplate !== null
                        ? membershipTemplateList.find(
                            (m) => m.id === selectedMembershipTemplate.id,
                          )?.title ?? "Select a Membership Type"
                        : "Select a Membership Type"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-700"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute isolate z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {membershipTemplateList.map(
                        (membershipTemplate, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active, selected }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active || selected
                                  ? "bg-primaryOpacity90 text-primary"
                                  : "text-gray-700"
                              }`
                            }
                            value={membershipTemplate.id}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {membershipTemplate !== null
                                    ? membershipTemplate.title
                                    : "Select a Membership Type"}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ),
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          )}
          {siteKeyLocationList.length > 1 && (
            <div className="z-15 relative w-full">
              <Listbox
                value={selectedSiteKeyLocation?.id ?? null}
                onChange={(event) => {
                  setSelectedSiteKeyLocation(
                    siteKeyLocationList.find((m) => m.id === event) ?? null,
                  );
                }}
              >
                <div className="relative">
                  <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight sm:text-sm">
                    <span className="block truncate">
                      {" "}
                      {selectedSiteKeyLocation !== null
                        ? siteKeyLocationList.find(
                            (m) => m.id === selectedSiteKeyLocation.id,
                          )?.title ?? "Select Site Location"
                        : "Select Site Location"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-700"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute isolate z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {siteKeyLocationList.map((skl, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active, selected }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active || selected
                                ? "bg-primaryOpacity90 text-primary"
                                : "text-gray-700"
                            }`
                          }
                          value={skl.id}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {skl !== null
                                  ? skl.title
                                  : "Select a Membership Type"}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          )}
          <div className="relative z-10 w-full">
            <h6 className="text-md mb-0 max-w-fit font-extrabold text-primary">
              {strings.SELECT_STATUS}
            </h6>
            <Listbox
              value={selectedMembershipStatus}
              onChange={(event) => {
                setSelectedMembershipStatus(event);
              }}
            >
              <div className="relative">
                <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight sm:text-sm">
                  <span className="block truncate">
                    {" "}
                    {selectedMembershipStatus !== null
                      ? getReadableMembershipStatus(selectedMembershipStatus) ??
                        "Select a Status"
                      : "Select a Status"}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-700"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {membershipStatus.map((status, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active || selected
                              ? "bg-primaryOpacity90 text-primary"
                              : "text-gray-700"
                          }`
                        }
                        value={status}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {getReadableMembershipStatus(status)}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8"
          >
            <div className="z-5 relative">
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <BaseInputTextArea
                    text="Notes"
                    rows={4}
                    inputName="notes"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.notes?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.notes.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
            {props.assetsEnabled && (
              // Allow user to checkbox multiple memberships that might apply to this task
              <div className="space-y-4">
                <h2 className="text-lg font-semibold text-primary">
                  {SELECT_ASSET}:
                </h2>
                <AssetEquipmentListCheckboxes
                  assetsForCustomer={assetsForCustomer}
                  assetIDs={assetIDs}
                  setAssetIDs={setAssetIDs}
                />
              </div>
            )}
            {selectedMembershipTemplate?.taskGeneration &&
              selectedMembershipTemplate?.taskGeneration.length > 0 && (
                // Allow user to checkbox multiple memberships that might apply to this task
                <div className="space-y-4">
                  <Controller
                    name="automaticallyGenerateTasks"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <StyledSwitchGroup
                          readableName="Automatically Generate Tasks"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          ref={field.ref}
                          checked={field.value ?? false}
                          id="automaticallyGenerateTasks"
                          name={field.name}
                        />
                        {error && (
                          <p className="text-red-600">{error.message}</p>
                        )}
                      </>
                    )}
                  />
                </div>
              )}
            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  {strings.MEMBERSHIP_START_DATE}
                </label>
              </div>
              <Controller
                key="membershipStartDate"
                name="membershipStartDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      required={true}
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  {strings.MEMBERSHIP_END_DATE}
                </label>
              </div>
              <Controller
                key="membershipEndDate"
                name="membershipEndDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  {strings.LAST_COMPLETED_TASK_DATE}
                </label>
              </div>
              <Controller
                key="lastCompletedTaskDate"
                name="lastCompletedTaskDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  {strings.NEXT_SCHEDULED_TASK_DATE}
                </label>
              </div>
              <Controller
                key="nextScheduledTaskDate"
                name="nextScheduledTaskDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  {strings.LAST_PAYMENT_DATE}
                </label>
              </div>
              <Controller
                key="lastPaymentDate"
                name="lastPaymentDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  {strings.NEXT_INVOICE_DATE}
                </label>
              </div>
              <Controller
                key="nextInvoiceDate"
                name="nextInvoiceDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>

            <div className="mt-4 flex flex-col items-end">
              {displayError ? errorMessageComponent : null}
            </div>

            {/* Action Buttons */}
            <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
              <BaseButtonSecondary
                type="button"
                className="w-full justify-center uppercase"
                onClick={closeAndReset}
              >
                {strings.buttons.CANCEL}
              </BaseButtonSecondary>

              <BaseButtonPrimary
                type="submit"
                formNoValidate
                disabled={props.isSubmitting}
                isBusy={props.isSubmitting}
                busyText={strings.buttons.BUSY_SAVING}
                className="w-full justify-center uppercase"
                data-testid="save item"
              >
                {strings.buttons.SAVE}
              </BaseButtonPrimary>
            </div>
          </form>
        </div>
      </BaseModal>
      {selectCustomerDialog}
    </>
  );
}
