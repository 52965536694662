// Libs
import { StarIcon } from "@heroicons/react/24/outline";
import { forwardRef, useState } from "react";

// Local
import styles from "../../assets/css/FeedbackPanelStars.module.css";

type Ref = HTMLDivElement;
type Props = {
  id: string;
  handleClick: (star: number, id: string) => void;
} & React.ComponentPropsWithRef<"div">;

const FeedbackPanelStars = forwardRef<Ref, Props>(
  ({ id, handleClick, ...props }, ref): JSX.Element => {
    const [fillStars, setFillStars] = useState(0);

    function onClick(starNum: number) {
      setFillStars(starNum);
      handleClick(starNum, id);
    }

    return (
      <div
        className={`mx-auto mt-1 flex max-w-fit ${styles.container}`}
        ref={ref}
        {...props}
      >
        <StarIcon
          className={`mr-2 ${styles.star} ${
            fillStars >= 1 ? styles.selected : ""
          }`}
          onClick={() => onClick(1)}
        />
        <StarIcon
          className={`mr-2 ${styles.star} ${
            fillStars >= 2 ? styles.selected : ""
          }`}
          onClick={() => onClick(2)}
        />
        <StarIcon
          className={`mr-2 ${styles.star} ${
            fillStars >= 3 ? styles.selected : ""
          }`}
          onClick={() => onClick(3)}
        />
        <StarIcon
          className={`mr-2 ${styles.star} ${
            fillStars >= 4 ? styles.selected : ""
          }`}
          onClick={() => onClick(4)}
        />
        <StarIcon
          className={`${styles.star} ${fillStars >= 5 ? styles.selected : ""}`}
          onClick={() => onClick(5)}
        />
      </div>
    );
  },
);
export default FeedbackPanelStars;
