// Libs
import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import { forwardRef } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Local
import { HoursMinutesCustomField } from "../../models/custom-field";
import { Json } from "../../models/json-type";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";
import { convertDecimalToHoursMinutes } from "../../utils";

import styles from "../../assets/css/InputNumber.module.css";

interface Props {
  customField: HoursMinutesCustomField & { id: string };
  control: Control<FieldValues, any>;
  defaultValue: Json | Date;
  isRequired: boolean;
}

export default function FieldTypeHoursMinutes(props: Props) {
  let hoursDefaultValue: number | "" = "";
  let minutesDefaultValue: number | "" = "";
  if (typeof props.defaultValue === "number") {
    const result = convertDecimalToHoursMinutes(props.defaultValue);
    hoursDefaultValue = result.hours;
    minutesDefaultValue = result.minutes;
  }

  const hoursID = `${props.customField.id}_hoursValue`;
  const minutesID = `${props.customField.id}_minutesValue`;

  return (
    <>
      <label htmlFor={props.customField.id} className="block">
        {props.customField.title}{" "}
        {props.isRequired && (
          <span className="text-lg font-medium text-redFail">*</span>
        )}
      </label>
      <AccessTimeIcon className="mr-2 text-gray-600" />
      {/* HOURS INPUT */}
      <Controller
        defaultValue={hoursDefaultValue}
        name={hoursID}
        control={props.control}
        rules={{ required: props.isRequired, min: 0 }}
        render={({ field, fieldState }) => {
          return (
            <>
              <InputHours
                id={hoursID}
                label={props.customField.id}
                error={fieldState.error}
                {...field}
                onChange={(e) =>
                  field.onChange(
                    e.target.value !== "" ? parseInt(e.target.value) : "",
                  )
                }
              />
            </>
          );
        }}
      />
      <span className="text-xl font-black">{" : "}</span>
      {/* MINUTES INPUT */}
      <Controller
        defaultValue={minutesDefaultValue}
        name={minutesID}
        control={props.control}
        rules={{ required: props.isRequired, max: 59, min: 0 }}
        render={({ field, fieldState }) => {
          return (
            <>
              <InputMinutes
                id={minutesID}
                label={props.customField.id}
                error={fieldState.error}
                {...field}
                onChange={(e) =>
                  field.onChange(
                    e.target.value !== "" ? parseInt(e.target.value) : "",
                  )
                }
              />
              {fieldState.error &&
                (fieldState.error.type === "max" ||
                  fieldState.error.type === "min") && (
                  <div className="mt-2">
                    <StyledMessage type="error">
                      {{ message: strings.ERR_MINUTES_BTWN_0_59 }}
                    </StyledMessage>
                  </div>
                )}
            </>
          );
        }}
      />
    </>
  );
}

type Ref = HTMLInputElement;

type PropsInputHoursMinutes = {
  label: string;
  error: FieldError | undefined;
} & React.ComponentPropsWithRef<"input">;

const InputHours = forwardRef<Ref, PropsInputHoursMinutes>(
  (props, ref): JSX.Element => {
    // let ANY error trigger the background/border color change. this way we don't
    // get the awkward hours:minutes inputs spanning more than one horizontal "line"
    const isError = props.error;
    return (
      <input
        ref={ref}
        {...props}
        type="number"
        id={props.label}
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        className={`${styles.noSpinner} ${
          isError
            ? "border-2 border-red-700 bg-red-100"
            : "border border-gray-400 bg-white"
        } mt-1 inline-block w-12 rounded-md px-3 py-2 text-right text-base text-gray-800 transition-colors hover:border-gray-800 focus:outline-none focus:ring-1 focus:ring-primaryLight`}
      />
    );
  },
);

const InputMinutes = forwardRef<Ref, PropsInputHoursMinutes>(
  (props, ref): JSX.Element => {
    const isRequiredError = props.error && props.error.type === "required";
    return (
      <input
        ref={ref}
        {...props}
        type="number"
        id={props.label}
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        className={`${styles.noSpinner} ${
          isRequiredError
            ? "border-2 border-red-700 bg-red-100"
            : "border border-gray-400 bg-white"
        } mt-1 inline-block w-12 rounded-md px-3 py-2 text-right text-base text-gray-800 transition-colors hover:border-gray-800 focus:outline-none focus:ring-1 focus:ring-primaryLight`}
      />
    );
  },
);
