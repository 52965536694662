// Libs
import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FunnelIcon } from "@heroicons/react/24/solid";
import _intersection from "lodash/intersection";
import _isEqual from "lodash/isEqual";

// Local
import { notificationStyles } from "../../components/ResponseCardIconRow";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import BaseInputCheckbox from "../../components/BaseInputCheckbox";
import FilterForResponsesCards from "../../components/FilterForResponsesCards";
import { ExistingChecklistResponse } from "../../models/checklist-response";
import { arrayEvery } from "../../utils/arrayEvery";
import { ResponseStatus } from "../../models/checklist-response-status";
import FilterDialog from "../../components/FilterModal";
import * as strings from "../../strings";
import { StyledTooltip } from "../../components/StyledTooltip";

type TagsChecked = Record<string, boolean>;

export function useFilterModal(responseList: ExistingChecklistResponse[]) {
  useDebugValue("useFilterModal");

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(ResponseStatus.ALL);

  // Shows if a filter is being applied
  const isResponseStatusFiltered = selectedFilter !== ResponseStatus.ALL;
  // Going to be an empty object on initial page load anyway.
  const [tagsChecked, setTagsChecked] = useState<TagsChecked>({});
  const [allTagsChecked, setAllTagsChecked] = useState<boolean>(false);

  const uniqueTagsValues = useMemo(() => {
    const tags: string[] = [];
    responseList.forEach((response) => {
      tags.push(...response.tags);
    });
    return [...new Set(tags)].sort();
  }, [responseList]);

  /**
   * Set the tagsChecked object once there are values in the response list. It's
   * important that this only runs if the values of uniqueTagsValues change, not
   * just the array identity. We will use useRef and lodash deepEqual to compare.
   */
  const uniqueTagsRef = useRef<string[]>();

  const getInitialTagsChecked = useCallback(
    (uniqueTagsValues: string[]): TagsChecked => {
      return uniqueTagsValues.reduce<TagsChecked>((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
    },
    [],
  );

  useEffect(() => {
    // If values are unchanged, exit the function early.
    if (_isEqual(uniqueTagsRef.current, uniqueTagsValues)) return;

    // Update the ref
    uniqueTagsRef.current = uniqueTagsValues;

    const initialTagsChecked = getInitialTagsChecked(uniqueTagsValues);
    setTagsChecked(initialTagsChecked);
  }, [getInitialTagsChecked, uniqueTagsValues]);

  // If any value is true in tagsChecked, consider the list filtered.
  const isTagFiltered = Object.values(tagsChecked).some((val) => val === true);
  const showNotificationDot = isResponseStatusFiltered || isTagFiltered;

  /* This useEffect is for check or uncheck all the tags */
  useEffect(() => {
    const tagValues = Object.values(tagsChecked);
    const allChecked = arrayEvery(tagValues, (val) => val === true);
    if (allChecked) {
      setAllTagsChecked(true);
    } else {
      setAllTagsChecked(false);
    }
  }, [tagsChecked]);

  /*
   * Function for filter the responses depending on tags selection.
   */
  function filterResponseCardsByTagsSelection(
    responseList: ExistingChecklistResponse[],
    checkedTags: Record<string, boolean>,
  ): ExistingChecklistResponse[] {
    let filteredList: ExistingChecklistResponse[];
    // Get an array of tag keys where the value was true.
    const checkedTagsList = Object.keys(checkedTags).filter(
      (tag) => checkedTags[tag] === true,
    );
    // If no tags are checked, do not apply any filters.
    if (checkedTagsList.length === 0) {
      filteredList = responseList;
    } else {
      filteredList = responseList.filter((response) => {
        // Select responses that have tags in checkedTags array
        const result = _intersection(response.tags, checkedTagsList);
        return result.length > 0;
      });
    }
    return filteredList;
  }

  /**
   * Filters based on selected response passing status.
   */
  function filterResponseCardsByFilterSelection(
    filter: string,
    listOfResponses: ExistingChecklistResponse[],
  ): ExistingChecklistResponse[] {
    let filteredList: ExistingChecklistResponse[] = [];
    switch (filter) {
      case ResponseStatus.NOT_PASSING:
        filteredList = listOfResponses.filter((response) => {
          const filtering = response.responsePassing === "no";
          return filtering;
        });
        return filteredList;
      case ResponseStatus.INCOMPLETE:
        filteredList = listOfResponses.filter((response) => {
          const filtering = response.responsePassing === null;
          return filtering;
        });
        return filteredList;
      case ResponseStatus.COMPLETE:
        filteredList = listOfResponses.filter((response) => {
          const filtering = response.responsePassing === "yes";
          return filtering;
        });
        return filteredList;
      default:
        filteredList = listOfResponses;
    }
    return filteredList;
  }

  const FilterButton = (
    <StyledTooltip title="Filter items">
      <button
        type="button"
        className={"relative"}
        onClick={() => setShowFilterModal(true)}
      >
        <FunnelIcon
          aria-label="show filters"
          className="h-10 w-10 text-primary"
        />
        <span
          className={`${notificationStyles} ${
            showNotificationDot ? "" : "hidden"
          } bg-amber-600`}
        ></span>
      </button>
    </StyledTooltip>
  );

  /* adding/removing tags from the 'checked' array */
  const toggleCheck = (tag: string) => {
    setTagsChecked((prevState) => {
      const newState = { ...prevState };
      newState[tag] = !prevState[tag];
      return newState;
    });
  };

  /* function to check/uncheck all the tags */
  const selectAll = (value: boolean) => {
    setAllTagsChecked(value);
    setTagsChecked((prevState) => {
      const newState = { ...prevState };
      for (const tag in newState) {
        newState[tag] = value;
      }
      return newState;
    });
  };

  function handleFilter(filterSelection: ResponseStatus) {
    setSelectedFilter(filterSelection);
  }

  /* function for the button 'Clear' where we reset the value to their initial state, before filtering */
  function clearFilters() {
    setSelectedFilter(ResponseStatus.ALL);

    const initialState = getInitialTagsChecked(uniqueTagsValues);
    setTagsChecked(initialState);
  }

  // TAG FILTER FORM

  const selectAllCheckBox = (
    <BaseInputCheckbox
      onChange={(event) => {
        const checked = event.target.checked;
        if (checked) {
          selectAll(true);
        } else {
          selectAll(false);
        }
      }}
      checked={allTagsChecked}
      label=""
    />
  );

  const tagsCheckboxes = uniqueTagsValues.map((tag) => {
    const checked = tagsChecked[tag] === true;
    return (
      <div key={tag} className="flex justify-between">
        <div>{tag}</div>
        <BaseInputCheckbox
          name={tag}
          checked={checked}
          onChange={() => toggleCheck(tag)}
          label=""
        />
      </div>
    );
  });

  const actionButtons = (
    <>
      <BaseButtonSecondary
        className="px-10"
        onClick={() => {
          setShowFilterModal(false);
          clearFilters();
        }}
      >
        {strings.buttons.CLEAR}
      </BaseButtonSecondary>
      {/*     <BaseButtonPrimary
        className="rounded-l-full rounded-r-full px-10"
        onClick={() => {
          setShowFilterModal(false);
        }}
      >
        Apply
      </BaseButtonPrimary> */}
    </>
  );

  const FilterModal = (
    <FilterDialog
      open={showFilterModal}
      closeModal={() => setShowFilterModal(false)}
      title="Filter Options"
    >
      <FilterForResponsesCards
        onHandleFilter={handleFilter}
        selectedFilter={selectedFilter}
      >
        {{
          selectAllCheckBox: selectAllCheckBox,
          tagsCheckboxes: tagsCheckboxes,
          actionButtons: actionButtons,
        }}
      </FilterForResponsesCards>
    </FilterDialog>
  );

  /**
   * Incoming response list is filtered by the chosen response status filter and
   * then the selected tags. Ordering of responses happens elsewhere.
   */
  const filteredResponseList = useMemo(() => {
    const tempList = filterResponseCardsByFilterSelection(
      selectedFilter,
      responseList,
    );

    return filterResponseCardsByTagsSelection(tempList, tagsChecked);
  }, [responseList, selectedFilter, tagsChecked]);

  return {
    showFilterModal,
    setShowFilterModal,
    FilterModal,
    FilterButton,
    selectedFilter,
    showNotificationDot,

    /**
     * Incoming response list is filtered by the chosen response status filter and
     * then the selected tags. Ordering of responses happens elsewhere.
     */
    filteredResponseList,
  };
}
