import BaseModal from "./BaseModal";

function displayFilterDialog(title: string): JSX.Element {
  return (
    <div className="mb-4 flex items-center justify-center ">
      <h1 className="inline-flex items-center text-4xl font-semibold text-primary ">
        {title}
      </h1>
    </div>
  );
}

const parentDivStyles = "p-6 xs:w-96 sm:w-full sm:max-w-screen-sm max-h-full";

interface Props {
  children: React.ReactElement;
  title: string;
  closeModal: () => void;
  open: boolean;
}
export default function FilterModal(props: Props): JSX.Element {
  const titleComponent = displayFilterDialog(props.title);
  return (
    <BaseModal
      open={props.open}
      title={titleComponent}
      closeModal={props.closeModal}
      parentDivStyles={parentDivStyles}
    >
      {props.children}
    </BaseModal>
  );
}
