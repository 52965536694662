// Libs
import { Dialog } from "@headlessui/react";
import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

// TODO: add some sort of transition animation

interface Props {
  children: React.ReactElement;
  edit: boolean;
  closeModal: () => void;
  open: boolean;
  workType: string;
}
/** This modal will not close if the user clicks outside of the dialog. */
export default function AdminModalSecondary(props: Props): JSX.Element {
  const title = displayAdminDialog(
    props.edit,
    props.workType,
    props.closeModal,
  );
  return (
    <Dialog
      onClose={() => null}
      open={props.open}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="min-h-screen px-1 text-center xxs:px-4">
        <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm" />

        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        ></span>
        <div className="inline-block w-full transform overflow-hidden rounded-2xl border-2 border-gray-300 bg-white pb-7 text-left align-middle shadow-xl transition-all lg:w-10/12 lg:max-w-4xl">
          <Dialog.Title>{title}</Dialog.Title>
          {props.children}
        </div>
      </div>
    </Dialog>
  );
}

function displayAdminDialog(
  edit: boolean,
  workType: string,
  closeModal: () => void,
): JSX.Element {
  return (
    <div className="grid grid-flow-col bg-primary px-5 py-6 sm:px-10">
      <div className="text-left">
        <h1 className="text-xl font-bold text-white sm:text-3xl">
          {edit ? "Edit Field" : "Add New Field"}
        </h1>
        <p className="text-base font-light capitalize text-white">
          {edit ? `Edit ${workType} Record` : `Add New ${workType} Record`}
        </p>
      </div>
      <div className="flex justify-end">
        <XMarkIcon
          aria-label="close button"
          onClick={closeModal}
          className="h-6 w-6 cursor-pointer text-white"
        />
      </div>
    </div>
  );
}
