//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import { ExistingEstimate } from "../../models/estimate";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  estimate: ExistingEstimate;
  children: {
    ViewEstimatePage: React.ReactNode;
  };
}

export default function ViewEstimateDialog(props: Props) {
  return (
    <BaseModal
      open={props.isOpen}
      closeModal={props.onClose}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.ESTIMATE_DETAILS}</h1>
          <button
            onClick={props.onClose}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left max-w-md md:max-w-4xl"
    >
      <div className="relative flex flex-col space-y-8 px-2 text-lg ">
        {props.children.ViewEstimatePage}
      </div>
    </BaseModal>
  );
}
