//Libs

//Local
import { convertToReadableTimestamp } from "../assets/js/convertToReadableTimestamp";
import placeholder_avatar from "../images/placeholder_avatar.svg";
import { ExistingTask } from "../models/task";

interface Props {
  task: ExistingTask;
  userDisplayNamesMap: Record<string, string>;
  responsesCompleted: number;
  totalResponses: number;
  percentageResponsesPassing: number;

  children: {
    TaskButton: React.ReactNode;
  };
}

export default function TaskDetailsInfoArea({
  task,
  userDisplayNamesMap,
  totalResponses,
  responsesCompleted,
  percentageResponsesPassing,
  children,
}: Props) {
  /* this should be the name for the person that submitted the task but it's not existing yet */
  const displayName = userDisplayNamesMap[task.lastModifiedBy];

  /* TODO: some of the timestamps are not existing yet, so needs to update this section with correct timestamps */

  return (
    <div className="grid grid-cols-1 space-y-4 rounded-md bg-gray-50 p-4 shadow-md lg:flex lg:justify-between lg:space-x-10 lg:space-y-0">
      <div className="flex flex-col justify-between sm:space-y-2">
        <div className="text-3xl font-medium text-gray-700 sm:text-2xl sm:text-blue-400">
          This Task
        </div>
        <div className="hidden sm:flex sm:items-center">
          <img
            src={placeholder_avatar}
            alt="User avatar"
            className="h-10 w-10 rounded-full"
          />
          <div className={`flex flex-col px-3 py-2 text-sm `}>
            <div>
              Submitted by{" "}
              <span className="font-medium text-blue-400">{displayName}</span>
            </div>
            <span>
              {task.timestampTaskCompleted !== null
                ? convertToReadableTimestamp(task.timestampTaskCompleted)
                : "--"}
            </span>
          </div>
        </div>
        <div className="hidden sm:flex">{children.TaskButton}</div>
      </div>
      <div className="justify-items-center space-y-2 sm:grid sm:grid-cols-2 sm:space-y-0 lg:flex lg:flex-col lg:space-y-2">
        <div className="flex flex-col leading-4">
          <span className="font-medium text-gray-700 sm:font-normal sm:text-gray-400">
            {" "}
            Scheduled:{" "}
          </span>
          <span className="text-gray-700">
            {" "}
            {task.timestampScheduled
              ? convertToReadableTimestamp(task.timestampScheduled)
              : "--"}{" "}
          </span>
        </div>
        <div className="flex flex-col leading-4">
          <span className="font-medium text-gray-700 sm:font-normal sm:text-gray-400">
            {" "}
            Started:{" "}
          </span>
          <span className="text-gray-700">
            {" "}
            {task.timestampTaskStarted
              ? convertToReadableTimestamp(task.timestampTaskStarted)
              : "--"}{" "}
          </span>
        </div>
        <div className="hidden flex-col leading-4">
          <span className="text-gray-400"> Submitted: </span>
          <span className="text-gray-700">
            {" "}
            {task.timestampTaskStarted
              ? convertToReadableTimestamp(task.timestampTaskStarted)
              : "--"}{" "}
          </span>
        </div>
        <div className="hidden flex-col leading-4">
          <span className="text-gray-400"> Approved: </span>
          <span className="text-gray-700">
            {" "}
            {task.timestampTaskStarted
              ? convertToReadableTimestamp(task.timestampTaskStarted)
              : "--"}{" "}
          </span>
        </div>
      </div>
      <div className="flex sm:hidden">{children.TaskButton}</div>
      <div className="hidden flex-row justify-center space-x-8 sm:flex lg:flex-col lg:space-x-0 lg:space-y-2">
        <div className="flex flex-col text-center">
          <span
            className={`font-medium ${
              percentageResponsesPassing < 100 ||
              isNaN(percentageResponsesPassing)
                ? "text-yellow-400"
                : "text-green-400"
            }`}
          >
            {isNaN(percentageResponsesPassing) ? 0 : percentageResponsesPassing}
            %
          </span>
          <span className="text-xs text-gray-400">
            {" "}
            Passing
            <br /> Responses{" "}
          </span>
        </div>
        <div className="flex flex-col text-center">
          <span
            className={`font-medium ${
              responsesCompleted !== totalResponses
                ? "text-yellow-400"
                : "text-green-400"
            }`}
          >
            {" "}
            {responsesCompleted}/{totalResponses}
          </span>
          <span className="text-xs text-gray-400">
            {" "}
            Responses
            <br /> Answered{" "}
          </span>
        </div>
      </div>
    </div>
  );
}
