//Libs
import React from "react";

//Local

type Ref = HTMLInputElement;

type Props = {
  text?: string;
  inputName?: string;
  value?: string;
  admin: boolean;
  className?: string;
  required?: boolean;
  children?: React.ReactNode;
} & React.ComponentPropsWithRef<"input">;

const BaseInputText = React.forwardRef<Ref, Props>(
  (
    { text, inputName, value, admin, children, className, ...props },
    ref,
  ): JSX.Element => {
    const adminStyle =
      admin === true
        ? "border-black p-4 text-gray-700 focus:ring"
        : "px-2 py-1.5 border-gray-300 focus:ring-1";

    return (
      <div className={`${className} relative`}>
        <input
          autoComplete="off"
          ref={ref}
          type="text"
          id={inputName}
          value={value}
          className={`block w-full min-w-0 flex-1 rounded border outline-none focus:border-primaryLight focus:ring-primaryLight sm:text-sm ${adminStyle}`}
          {...props}
        />
        <span className="absolute right-3 top-4 bg-white px-2">{children}</span>
        {admin === true ? (
          <label
            htmlFor={inputName}
            className="absolute -top-3 left-3 bg-white px-2"
          >
            {text}{" "}
            {props.required ? (
              <span className="text-lg font-medium text-redFail">*</span>
            ) : (
              ""
            )}
          </label>
        ) : (
          ""
        )}
      </div>
    );
  },
);

export default BaseInputText;
