// Local
import {
  isValidCraftType,
  getValidTaskTypes,
  getReadableCraftType,
} from "../../models/craft-types";
import { TaskTypesValues, getReadableTaskType } from "../../models/task-types";

/**
 * Attaches the given task types to the given, respective work types.
 * Gets human readable strings. Alphabetizes work and task types.
 * @returns **Example return value:**
Map {
  0: {"Insulation" => Array(2)}
    key: "Insulation"
    value: (2) ['Abatement', 'Insulation']
  1: {"Scaffolding" => Array(4)}
    key: "Scaffolding"
    value: (4) ['Inspection', 'Installation', 'Modification', 'Removal']
  2: ...
}
 * 
 * Tested ✅
 */
export default function getWorkAndTaskTypesIntersection(args: {
  validWorkTypes: number[];
  validTaskTypes: number[];
}): Map<string, string[]> {
  const result = args.validWorkTypes.reduce<Map<string, string[]>>(
    (acc, workType): Map<string, string[]> => {
      // For each workType, get the possible taskTypes.
      let tasksBelongingToWorkType: TaskTypesValues[];
      if (isValidCraftType(workType)) {
        tasksBelongingToWorkType = getValidTaskTypes(workType);
      } else {
        // If workType isn't valid, break out of this iteration.
        return acc;
      }

      // Intersection of validTaskTypes and each workType's applicable taskTypes.
      const tasksDividedByWorkType = args.validTaskTypes.filter((task) =>
        tasksBelongingToWorkType.includes(task as TaskTypesValues),
      );

      // Get the human readable strings.
      const taskStrings = tasksDividedByWorkType
        .map((task) => getReadableTaskType(task))
        .sort();
      const workTypeString = getReadableCraftType(workType);

      acc.set(workTypeString, taskStrings);
      return acc;
    },
    new Map(),
  );

  const alphabetizeWorkTypes = new Map([...result.entries()].sort());

  return alphabetizeWorkTypes;
}
