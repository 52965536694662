export const LogLevel = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
  SILENT: 4,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LogLevel = (typeof LogLevel)[keyof typeof LogLevel];

// These log methods are consistent between firebase functions, node and browser consoles.
export const LogMethods = ["debug", "info", "warn", "error"] as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type LogMethods = (typeof LogMethods)[number];

// function isLogmethod(value: unknown): value is LogMethods {
//   return LogMethods.includes(value as any);
// }

/**
 * Defines the common set of log methods for the Logger class. If we
 * add a new method to the LogMethods array, TS will make sure we implement
 * that method.
 */
export type CommonLogMethods = Record<LogMethods, (...data: unknown[]) => void>;

export interface Channel {
  id: string;
  methods: CommonLogMethods;
}
