// Libs
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useAuthStore } from "../store/firebase-auth";
import { DocumentData } from "firebase/firestore";

//Local
import { DbRead, DbWrite } from "../database";
import EditChecklistPage from "./EditChecklistPage";
import LoadingClipboardAnimation from "../components/LoadingClipBoardAnimation";
import { useSiteKeyLocationsStore } from "../store/site-key-locations";

interface Props {
  siteKey: string;
}

export default function EditChecklistContainer({
  siteKey,
}: Props): JSX.Element {
  const locationsList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );
  type UrlParams = { id: string };
  const { id: checklistID } = useParams<UrlParams>();
  if (typeof checklistID !== "string") {
    throw new Error(`checklistID was not a string: ${checklistID}`);
  }

  const navigate = useNavigate();
  if (checklistID == null) {
    navigate("/404");
  }

  function handleGoBack() {
    navigate(-1);
  }

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  async function handleEditChecklist(
    data: DocumentData,
    editCraftDetails: DocumentData,
  ) {
    if (typeof checklistID !== "string") {
      throw new Error(`checklistID was not a string: ${checklistID}`);
    }
    const ref = await DbWrite.checklist.update(siteKey, data, checklistID);
    await DbWrite.craftDetails.update(ref.path, editCraftDetails);
  }

  // QUERIES
  const queryTemplate = useQuery(
    ["editChecklistContainer_template", siteKey, checklistID],
    () => DbRead.checklist.get(siteKey, checklistID),
  );

  return firebaseUser && queryTemplate.data ? (
    <EditChecklistPage
      siteKey={siteKey}
      checklist={queryTemplate.data}
      locationsList={locationsList}
      onEditChecklist={handleEditChecklist}
      uid={firebaseUser.uid}
      onGoBack={handleGoBack}
    />
  ) : (
    <div className="flex h-full flex-auto flex-col items-center justify-center">
      <LoadingClipboardAnimation />
    </div>
  );
}
