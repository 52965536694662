//Local
import { ComplianceItemCommon } from "../../models/compliance-item";

// Tested ✅
export function getSortedComplianceItemList(
  complianceItems: Record<string, ComplianceItemCommon>,
): Record<string, ComplianceItemCommon>[] {
  const list = Object.entries(complianceItems).map(([key, value]) => ({
    key,
    ...value,
  }));
  // Operate on a shallow copy of `list`
  const sortedList = [...list].sort((a, b) => {
    if (a.order > b.order) return 1;
    if (a.order < b.order) return -1;
    // Leave order intact if the order property is equal
    return 0;
  });
  const sorted = sortedList.reduce(
    (acc, curr) => {
      const { key, ...rest } = curr;
      acc.push({ [key]: rest });
      return acc;
    },
    [] as Record<string, ComplianceItemCommon>[],
  );
  return sorted;
}
