import { useState } from "react";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import * as strings from "../../strings";
import { logger as devLogger } from "../../logging";

export function useAgreementButtons(args: {
  requireApprovals: boolean | undefined;
  craftRecordID: string | undefined;
  onSubmitCallback: (
    requiredApprovals: boolean,
    checklistTaskID: string,
  ) => void;
  unansweredResponses: number;
}) {
  const { requireApprovals = false } = args;

  const buttonText =
    args.requireApprovals === true
      ? strings.buttons.SUBMIT_FOR_APPROVAL
      : strings.buttons.COMPLETE;

  const agreementMessage = `${args.unansweredResponses} required ${
    args.unansweredResponses === 1 ? "question is" : "questions are"
  } not answered. Are you
  sure you want to change the status to ${buttonText}?`;

  const [showAgreement, setShowAgreement] = useState<boolean>(false);

  /* this function is to check the unanswered responses before submit */
  function handleSubmit(
    checklistRequireApprovals: boolean,
    checklistID: string,
  ) {
    if (args.unansweredResponses !== 0) {
      setShowAgreement(true);
    } else {
      args.onSubmitCallback(checklistRequireApprovals, checklistID);
    }
  }

  /* this function handle the agreement message */
  function handleAgreement(
    bool: boolean,
    checklistRequireApprovals: boolean,
    checklistID: string,
  ) {
    setShowAgreement(false);
    if (bool === true) {
      args.onSubmitCallback(checklistRequireApprovals, checklistID);
    }
  }
  const agreementButtons = (
    <>
      <BaseButtonPrimary
        type="button"
        onClick={() =>
          args.craftRecordID
            ? handleAgreement(true, requireApprovals, args.craftRecordID)
            : devLogger.error("craftRecordID is undefined")
        }
      >
        {strings.buttons.YES}
      </BaseButtonPrimary>
      <BaseButtonSecondary
        type="button"
        onClick={() =>
          args.craftRecordID
            ? handleAgreement(false, requireApprovals, args.craftRecordID)
            : devLogger.error("craftRecordID is undefined")
        }
      >
        {strings.buttons.NO}
      </BaseButtonSecondary>
    </>
  );

  const SubmitButton = (props: { disabled?: boolean }) => {
    const { disabled = false } = props;
    return (
      <BaseButtonPrimary
        disabled={disabled}
        className={disabled ? "hidden" : ""}
        onClick={() =>
          args.craftRecordID
            ? handleSubmit(requireApprovals, args.craftRecordID)
            : devLogger.error("craftRecordID is undefined")
        }
      >
        {buttonText}
      </BaseButtonPrimary>
    );
  };

  return {
    agreementButtons,
    agreementMessage,
    showAgreement,
    SubmitButton,
  };
}
