// Local
import {
  ExistingCustomField,
  SelectionCustomField,
} from "../../models/custom-field";
import { Json } from "../../models/json-type";
import { convertDecimalToHoursMinutes } from "../../utils";

/**
 * Used for retrieving the custom field's defaultValue. (As opposed to getting the
 * custom field *response*.)
 */
export function getDefaultValuesForNewResponse(args: {
  type: ExistingCustomField["fieldType"];
  defaultValue: ExistingCustomField["defaultValue"];
  selectionOptions: SelectionCustomField["selectionOptions"] | null;
  userDisplayNamesMap: Record<string, string>;
}): Json {
  switch (args.type) {
    case "string":
    case "string-textarea": {
      return typeof args.defaultValue === "string" ? args.defaultValue : "";
    }
    case "number":
    case "currency": {
      return typeof args.defaultValue === "number" ? args.defaultValue : "";
    }
    case "bool": {
      return args.defaultValue === true ? "Yes" : "No";
    }
    case "timestamp": {
      return null;
    }
    case "selection": {
      // NOTE: if defaultValue isn't `null`, it's a KEY from the selectionOptions object.
      if (args.selectionOptions !== null) {
        if (Object.keys(args.selectionOptions).length === 1) {
          // we will be displaying a checkbox
          return typeof args.defaultValue === "string" ? true : false;
        } else if (Object.keys(args.selectionOptions).length === 2) {
          // we will be displaying radio buttons
          return typeof args.defaultValue === "string"
            ? args.selectionOptions[args.defaultValue]
            : null;
          // ^ this WAS undefined in the prior setup IMPORTANT:
        } else {
          // we will be displaying a dropdown
          return typeof args.defaultValue === "string"
            ? args.selectionOptions[args.defaultValue]
            : "";
        }
      } else {
        return null;
      }
    }
    case "uid": {
      if (typeof args.defaultValue === "string") {
        return args.userDisplayNamesMap[args.defaultValue];
      } else {
        return "";
      }
    }
    case "multiple-uid": {
      // handle transforming defaultValue within the Input component.
      return args.defaultValue;
    }
    case "string-array": {
      if (
        // non-empty array
        Array.isArray(args.defaultValue) &&
        args.defaultValue.length > 0
      ) {
        if (args.defaultValue.every((val) => typeof val === "string")) {
          return args.defaultValue;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
    case "hours-minutes": {
      if (typeof args.defaultValue === "number") {
        const { hours, minutes } = convertDecimalToHoursMinutes(
          args.defaultValue,
        );
        return [hours, minutes];
      } else {
        return ["", ""];
      }
    }
    default: {
      const _exhaustivenessCheck: never = args.type;
      return _exhaustivenessCheck;
    }
  }
}
