import isPlainObject from "lodash/isPlainObject";
/**
 * A typeguard function that checks if value is a Plain JavaScript object.
 * One that isn't based on a class (i.e. uses the 'new' keyword to construct.)
 */
export function guardIsPlainObject(
  value: unknown,
): value is Record<string, unknown> {
  return isPlainObject(value);
}
