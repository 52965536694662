//Libs
import { useEffect, useState } from "react";
import { CircleStackIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

//Local
import {
  ExistingStiltInvoice,
  getReadableStiltInvoiceStatus,
} from "../../models/invoice";
import { DbRead } from "../../database";
import { ExistingTask } from "../../models/task";
import { getEventIconColor } from "../WorkRecordAndTasks/functions";
import { EventTypes } from "../../models/event-types";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { ExistingEstimate } from "../../models/estimate";
import { ExistingStiltPayment } from "../../models/stilt-payment";
import { getEstimateStatus } from "../../assets/js/estimateFunctions";
import { getReadableTaskStatus } from "../../models/task-status";
import {
  CUSTOMERS_URL,
  ESTIMATES_URL_AUTH,
  WORK_RECORD_AND_TASKS_URL,
} from "../../urls";

interface Props {
  //DATA
  customerID: string;
  siteKey: string;
  closeDialog: () => void;
  photos: any[];
  handleOpenViewPaymentDialog: (invoiceID: string) => void;
}

export default function CustomerHistory(props: Props) {
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const [tasksForCustomer, setTasksForCustomer] = useState<ExistingTask[]>([]);
  const [invoicesForCustomer, setInvoicesForCustomer] = useState<
    ExistingStiltInvoice[]
  >([]);
  const [estimatesForCustomer, setEstimatesForCustomer] = useState<
    ExistingEstimate[]
  >([]);
  const [paymentsForCustomer, setPaymentsForCustomer] = useState<
    ExistingStiltPayment[]
  >([]);

  const [allData, setAllData] = useState<
    (
      | ExistingTask
      | ExistingEstimate
      | ExistingStiltInvoice
      | ExistingStiltPayment
    )[]
  >([]);

  useEffect(() => {
    const allData: (
      | ExistingTask
      | ExistingEstimate
      | ExistingStiltInvoice
      | ExistingStiltPayment
    )[] = [];
    allData.push(...tasksForCustomer);
    allData.push(...invoicesForCustomer);
    allData.push(...estimatesForCustomer);
    allData.push(...paymentsForCustomer);
    allData.sort(
      (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
    );
    setAllData(allData);
  }, [
    tasksForCustomer,
    invoicesForCustomer,
    paymentsForCustomer,
    estimatesForCustomer,
  ]);

  useEffect(() => {
    async function getInvoices() {
      const invoices = await DbRead.invoices.getByCustomerId(
        props.siteKey,
        props.customerID,
      );
      setInvoicesForCustomer(invoices);
    }

    getInvoices();
  }, []);

  useEffect(() => {
    async function getPayments() {
      const payments = await DbRead.payments.getAllByCustomerID(
        props.siteKey,
        props.customerID,
      );
      setPaymentsForCustomer(payments);
    }

    getPayments();
  }, []);

  useEffect(() => {
    async function getEstimates() {
      const estimates = await DbRead.estimates.getByCustomerId(
        props.siteKey,
        props.customerID,
      );
      setEstimatesForCustomer(estimates);
    }

    getEstimates();
  }, []);

  useEffect(() => {
    async function getTasks() {
      if (!userPermissions) return;
      const tasks = await DbRead.tasks.getSingleCustomerTasks({
        permissions: userPermissions,
        customerID: props.customerID,
        siteKey: props.siteKey,
      });
      const sortedTasks = tasks.sort((a, b) => {
        if (!!a.timestampScheduled && b.timestampScheduled) {
          return (
            b.timestampScheduled?.toMillis() - a.timestampScheduled.toMillis()
          );
        } else {
          return 0;
        }
      });
      setTasksForCustomer(sortedTasks);
    }

    getTasks();
  }, []);

  const leftCaretClasses =
    "before:absolute before:-left-3 before:top-1/2 before:-translate-y-1/2 before:border-y-[12px] before:border-r-[12px] before:border-y-transparent before:border-r-gray-200 after:absolute after:-left-[10.5px] after:top-1/2 after:-translate-y-1/2 after:border-y-[12px] after:border-r-[12px] after:border-y-transparent after:border-r-white";

  const isTask = (
    object:
      | ExistingTask
      | ExistingEstimate
      | ExistingStiltInvoice
      | ExistingStiltPayment,
  ): object is ExistingTask => {
    return "taskSpecificDetails" in object;
  };
  const isEstimate = (
    object:
      | ExistingTask
      | ExistingEstimate
      | ExistingStiltInvoice
      | ExistingStiltPayment,
  ): object is ExistingEstimate => {
    return "estimatePackageID" in object;
  };
  const isInvoice = (
    object:
      | ExistingTask
      | ExistingEstimate
      | ExistingStiltInvoice
      | ExistingStiltPayment,
  ): object is ExistingStiltInvoice => {
    return "invoiceNumber" in object;
  };
  const isPayment = (
    object:
      | ExistingTask
      | ExistingEstimate
      | ExistingStiltInvoice
      | ExistingStiltPayment,
  ): object is ExistingStiltPayment => {
    return "paymentMethod" in object;
  };

  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col justify-between space-y-2 p-2 text-lg sm:flex-row-reverse">
      <div className="relative max-h-[700px] flex-1 space-y-2 overflow-auto p-2 text-lg">
        <div className="h-[ 300px]  ml-2 overflow-y-auto">
          {allData.length === 0 && (
            <div className="flex flex-row items-end space-x-4">
              <CircleStackIcon
                aria-label="view history data"
                className="mt-2 h-9 w-9 text-gray-600"
              />
              <p className="my-1.5 text-sm text-gray-600 sm:text-base">
                No customer history data to display.
              </p>
            </div>
          )}
          {allData.map((item) => {
            let mainString = "";
            let subString = "";
            let timestampString = "";
            let onClick = () => {};
            let icon = <PersonAddAlt1Icon className="text-gray-50" />;
            if (isTask(item)) {
              mainString = `Task [${getReadableTaskStatus(item.taskStatus)}]`;
              subString = item.title;
              timestampString = convertToReadableTimestamp(
                item.timestampCreated,
              );
              icon = <AssignmentTurnedInIcon className="text-gray-50" />;
              onClick = () => {
                navigate(
                  `${WORK_RECORD_AND_TASKS_URL}/${
                    item.craftRecordID.split("/")[3]
                  }`,
                );
              };
            }
            if (isInvoice(item)) {
              mainString = `Invoice [${getReadableStiltInvoiceStatus(
                item.status,
              )}]`;
              subString = `Total: ${item.totalAmount.toFixed(
                2,
              )} - Due: ${item.amountDue.toFixed(2)}`;
              timestampString = convertToReadableTimestamp(
                item.timestampCreated,
              );
              icon = <MdIcons.MdAttachMoney className="text-gray-50" />;
              onClick = () => {
                props.handleOpenViewPaymentDialog(item.id);
              };
            }
            if (isEstimate(item)) {
              mainString = `Estimate [${getEstimateStatus(item.status)}]`;
              subString = item.notes ?? "--";
              timestampString = convertToReadableTimestamp(
                item.timestampCreated,
              );
              icon = <MdIcons.MdOutlineReceipt className="text-gray-50" />;
              onClick = () => {
                navigate(`${CUSTOMERS_URL}${ESTIMATES_URL_AUTH}/${item.id}`);
              };
            }
            if (isPayment(item)) {
              mainString = `Payment`;
              subString = item.amount.toFixed(2) ?? "--";
              timestampString = convertToReadableTimestamp(
                item.timestampCreated,
              );
              icon = <MdIcons.MdOutlineAttachMoney className="text-gray-50" />;
              onClick = () => {
                props.handleOpenViewPaymentDialog(item.invoiceID);
              };
            }

            return (
              // NOTE: task panel's vertical bar will "disappear" without the isolate class.
              <div
                key={item.id}
                className="relative isolate cursor-pointer px-1 py-1"
                onClick={onClick}
              >
                {/* VERTICAL BAR */}
                <span className="block before:absolute before:bottom-0 before:left-7 before:top-0 before:-z-10 before:h-full before:w-0.5 before:bg-gray-200"></span>
                <div className="flex items-center gap-2">
                  {/* ICON */}
                  <span
                    className={`rounded-full p-2 ${getEventIconColor(
                      EventTypes.NEW_TASK_ADDED,
                    )}`}
                  >
                    {icon}
                  </span>

                  {/* EVENT CARD */}
                  <div className="relative w-full hover:bg-gray-100">
                    <div
                      className={`${leftCaretClasses} rounded-md border border-gray-200 px-3.5 py-2 text-sm text-gray-400 shadow-md`}
                    >
                      <p className="text-base text-gray-900">{mainString}</p>
                      <p className="text-gray-700">{subString}</p>
                      <p>Created: {timestampString}</p>
                      {/*<p>{displayName}</p>*/}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
