//Libs
//Local

interface Props {
  title: string;
  children?: string;
  className?: string;
  detailsInfoArea: boolean;
}

export default function PageHeading({
  title,
  children,
  className,
  detailsInfoArea,
}: Props) {
  const textColor = detailsInfoArea ? "text-white" : "text-primary";

  return (
    <div>
      <h1
        className={`inline-block text-3xl font-semibold ${textColor} sm:text-4xl`}
      >
        {title}
      </h1>
      <div className={className}>{children}</div>
    </div>
  );
}
