//Libs
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import BaseInputNumber from "./BaseInputNumber";
import { ShowRightStringForPassingMinMaxUnits } from "../assets/js/ShowRightStringForPassingMinMaxUnits";
import { ExistingChecklistResponse } from "../models/checklist-response";
import ResponseCardBase, {
  ResponseCardBaseProps,
} from "../components/ResponseCardBase";
import { logger as devLogger } from "../logging";
import { convertPassingNumberValues } from "../assets/js/PassingNumberValues";

interface Props {
  response: ExistingChecklistResponse;
  onSaveValue: (value: number | null, responseID: string) => Promise<void>;
  /**
   * Takes IconRow as children.
   */
  children: React.ReactNode;
}

export default function ResponseCardFloatType(
  props: Props & Omit<ResponseCardBaseProps, "children">,
) {
  const ResponseFloatSchema = z.object({
    responseFloatValue: z.union([z.number(), z.literal("")]),
  });
  type ResponseFloatState = z.infer<typeof ResponseFloatSchema>;

  async function onSubmit(data: ResponseFloatState) {
    if (props.response.id == null) {
      devLogger.error(`ResponseID was not found. Value: ${props.response.id}`);
      return;
    }

    if (
      data.responseFloatValue === "" &&
      props.response.responseValue === null
    ) {
      devLogger.debug("The value hasn't changed");
      return;
    }

    if (typeof props.response.responseValue === "number") {
      if (data.responseFloatValue === props.response.responseValue) {
        devLogger.debug("The value hasn't changed");
        return;
      }
    }

    const valueToSend = convertPassingNumberValues(data.responseFloatValue);

    await props.onSaveValue(valueToSend, props.response.id);
    devLogger.debug("Response value was successfully recorded");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResponseFloatState>({
    defaultValues: {
      responseFloatValue:
        typeof props.response.responseValue === "number"
          ? props.response.responseValue
          : "",
    },
    resolver: zodResolver(ResponseFloatSchema),
  });

  const inputForm = (
    <form
      autoComplete="off"
      onBlur={handleSubmit(onSubmit)}
      onKeyDown={(event) =>
        event.key === "Enter" ? event.preventDefault() : null
      }
      className="mb-2 mt-3 block w-full sm:w-72"
    >
      <p className="text-right text-xs font-medium text-greenPass">
        {ShowRightStringForPassingMinMaxUnits(props.response)}
      </p>
      <BaseInputNumber
        id={props.response.id}
        {...register("responseFloatValue", {
          setValueAs: (value) => (value !== "" ? parseFloat(value) : ""),
        })}
      />
      {errors.responseFloatValue?.message && (
        <div className="ml-1 mt-2 text-xs text-red-400">
          {errors.responseFloatValue?.message}
        </div>
      )}
    </form>
  );

  return (
    <ResponseCardBase response={props.response}>
      {{
        input: inputForm,
        iconRow: props.children,
      }}
    </ResponseCardBase>
  );
}
