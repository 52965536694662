import React from "react";
import List from "@mui/material/List";

// interface ListItemProps {
//     icon: SvgIconComponent,
//     heading: string,
//     subheading: string,
//     onClick: () => void,
//     isAdmin: boolean
// }
export interface InfoListProps {
  children: React.ReactNode;
  className?: string;
}
export function UserInfoList(props: InfoListProps) {
  return (
    <section className={props.className}>
      <List
        sx={{
          // width: "100%",
          // minWidth: "100%",
          bgcolor: "background.paper",
          // padding: 0,
          // margin: 0,
        }}
      >
        {props.children}
      </List>
    </section>
  );
}
