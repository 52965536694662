//Libs
import { useMutation } from "react-query";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Fragment, useEffect, useState } from "react";
import { User } from "firebase/auth";

//Local
import { DbRead, DbWrite } from "../../database";
import * as strings from "../../strings";
// import PriceBookItemListPage from "./PriceBookItemListPage";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { useAuthStore } from "../../store/firebase-auth";
import { logger } from "../../logging";
import {
  // AddNewPBItem,
  ExistingPriceBookItem,
  // PBItem_CreateAPI,
  // PBItem_UpdateAPI,
  // PriceBookItemManager,
} from "../../models/price-book-item";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import { diffObjects } from "../../assets/js/object-diff";
import { DocumentData } from "firebase/firestore";
// import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
// import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { useUserPermissionsStore } from "../../store/user-permissions";
// import { useTypesenseStore } from "../../store/typesense";
// import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
// import AddEditPriceBookItemDialog from "../../components/estimates/AddEditPriceBookItemDialog";
// import {
//   PRICEBOOK_CATEGORIES_URL,
//   WORK_RECORD_AND_TASKS_URL,
// } from "../../urls";
// import { useNavigate } from "react-router-dom";
import {
  AddNewPBItemCategory,
  ExistingPriceBookItemCategory,
  PBItemCategory_CreateAPI,
  PBItemCategory_UpdateAPI,
  PriceBookItemCategoryManager,
} from "../../models/price-book-item-category";
import AddEditPriceBookItemCategoryDialog from "../../components/estimates/AddEditPriceBookItemCategoryDialog";
import PriceBookItemCategoryListPage from "./PriceBookItemCategoryListPage";

interface Props {
  siteKey: string;
}

export default function PriceBookItemCategoriesListContainer({
  siteKey,
}: Props) {
  // const navigate = useNavigate();

  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  // const siteKeyLocationList = useSiteKeyLocationsStore(
  //   (state) => state.siteKeyLocationList,
  // );
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  /* USE STATES */
  const [addEditPBItemCategoryDialogOpen, setAddEditPBItemCategoryDialogOpen] =
    useState<boolean>(false);
  const [pBItemCategoryDoc, setPBItemCategoryDoc] =
    useState<ExistingPriceBookItemCategory | null>(null);
  const [priceBookItemCategories, setPriceBookItemCategories] = useState<
    ExistingPriceBookItemCategory[]
  >([]);

  /* USE EFFECTS */
  useEffect(() => {
    if (siteKey == null) {
      logger.error("siteKey is null");
      return;
    }
    // Subscribe to all priceBookItemCategories
    const unsubscribe = DbRead.priceBookItemCategories.subscribeAll({
      siteKey: siteKey,
      onChange: setPriceBookItemCategories,
      onError: (error) => {
        logger.error(
          "Error while subscribing to priceBookItemCategories",
          error,
        );
      },
    });
  }, [siteKey]);

  /* MUTATIONS */
  /**
   * For adding a price book item on DB
   */
  const mutateAddPBItemCategory = useMutation(
    async (args: { validPBItemCategory: PBItemCategory_CreateAPI }) => {
      await DbWrite.priceBookItemCategories.create(args.validPBItemCategory);
    },
  );

  /**
   * For edit an existing price book item on the DB
   */
  const mutateEditPBItemCategory = useMutation(
    async (args: { editPBItemCategory: PBItemCategory_UpdateAPI }) => {
      await DbWrite.priceBookItemCategories.update(args.editPBItemCategory);
    },
  );

  /* FUNCTIONS */
  async function handleSaveNewPBItemCategory(formValues: AddNewPBItemCategory) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    const newPBItemCategory: PBItemCategory_CreateAPI = {
      ...formValues,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKey,
    };
    console.log(newPBItemCategory);

    //Validate
    const validatedPBItemCategory =
      PriceBookItemCategoryManager.parseCreate(newPBItemCategory);
    logger.info("Validated Price Book Item Category:", validatedPBItemCategory);

    console.log(validatedPBItemCategory);
    //DB
    try {
      await mutateAddPBItemCategory.mutateAsync({
        validPBItemCategory: validatedPBItemCategory,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(validatedPBItemCategory.name),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewPBItem`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleEditPBItemCategory(
    updatePBItem: Partial<ExistingPriceBookItem>,
  ) {
    if (pBItemCategoryDoc == null) {
      return;
    }

    updatePBItem["lastModifiedBy"] = firebaseUser.uid;

    const diffPBItemCategoryValues: DocumentData = diffObjects(
      pBItemCategoryDoc,
      updatePBItem,
    ).diff;

    if (Object.keys(diffPBItemCategoryValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    diffPBItemCategoryValues["id"] = pBItemCategoryDoc.id;
    diffPBItemCategoryValues["refPath"] = pBItemCategoryDoc.refPath;

    /* validate values from the form */
    const validateEditPBItemCategory = PriceBookItemCategoryManager.parseUpdate(
      diffPBItemCategoryValues,
    );

    try {
      await mutateEditPBItemCategory.mutateAsync({
        editPBItemCategory: validateEditPBItemCategory,
      });
      logger.debug("Pricebook item category has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(pBItemCategoryDoc.name),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleEditPBItemCategory`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  function openEditPBItemCategoryDialog(
    priceBookItemCategory: ExistingPriceBookItemCategory,
  ) {
    setPBItemCategoryDoc(priceBookItemCategory);
    setAddEditPBItemCategoryDialogOpen(true);
  }

  /* COMPONENTS */
  const addNewPriceBookItemCategoryButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => setAddEditPBItemCategoryDialogOpen(true)}
      className="w-full text-primary sm:w-auto"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.ADD_NEW_PRICEBOOKITEM_CATEGORY}
    </BaseButtonPrimary>
  );

  const addEditPBItemCategoryDialog = userPermissions && (
    <AddEditPriceBookItemCategoryDialog
      isDialogOpen={addEditPBItemCategoryDialogOpen}
      closeDialog={() => {
        setAddEditPBItemCategoryDialogOpen(false);
        setPBItemCategoryDoc(null);
      }}
      handleSaveNewPBItemCategory={handleSaveNewPBItemCategory}
      handleEditPBItemCategory={handleEditPBItemCategory}
      userPermissions={userPermissions}
      allowEditingAccountNumbers={
        !siteKeyDoc?.customizations?.accounting?.codatConnectionID
      }
      priceBookItemCategoryDoc={pBItemCategoryDoc}
    />
  );

  return (
    <Fragment>
      <PriceBookItemCategoryListPage
        priceBookItemCategoryList={priceBookItemCategories}
        addNewPriceBookItemCategoryButton={addNewPriceBookItemCategoryButton}
        onEditPBItemCategory={openEditPBItemCategoryDialog}
      />
      {addEditPBItemCategoryDialog}
    </Fragment>
  );
}
