// #region Imports
//Libs
import axios from "axios";
import { SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  CheckCircleIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { Wrapper } from "@googlemaps/react-wrapper";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { WindupChildren, Pause } from "windups";
import ArchiveIcon from "@mui/icons-material/Archive";

//Local
import * as strings from "../../strings";
import { getTimezoneList } from "../Admin/SiteDetailsContainer";
import { DbRead, DbWrite } from "../../database";
import { guardIsPlainObject } from "../../utils";
import { logger as devLogger } from "../../logging";
import { BASE_APP_URL } from "../../urls";

/* components */
import CreateSiteDetails, {
  CreateSiteDetailsState,
} from "../../components/createSite/CreateSiteDetails";
import HorizontalLinearStepper from "../../components/createSite/HorizontalLinearStepper";
import SiteMap from "../../components/SiteMap";
import Marker from "../../components/admin/Marker";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import CreateSiteReview from "../../components/createSite/CreateSiteReview";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { StyledTooltip } from "../../components/StyledTooltip";
import LoadingSpinner from "../../components/LoadingSpinner";
import StyledMessage from "../../components/StyledMessage";
import BaseInputText from "../../components/BaseInputText";
import AddCreateSiteCustomFieldDialog from "../../components/createSite/AddCreateSiteCustomFieldDialog/AddCreateSiteCustomFieldDialog";
import DropdownSelectionTemplate from "../../components/createSite/DropdownSelectionTemplate";

/* pages */
import CreateSitePage from "./CreateSitePage";
import NotFound404 from "../NotFound404";
import SiteReviewPage from "./SiteReviewPage";
import ProgressPage from "./ProgressPage";

/* models */
import {
  CraftTypeValues,
  getCraftTypeFromRecordString,
  getCraftTypeFromString,
  getCraftTypeRecordString,
  getCraftTypeStringFromReadableString,
  getReadableCraftType,
  getValidTaskTypes,
  isValidCraftType,
} from "../../models/craft-types";
import {
  getReadableTaskType,
  getTaskSpecificDetailsString,
  getTaskTypeFromString,
  getTaskTypeValueFromReadableStr,
  TaskTypesValues,
} from "../../models/task-types";
import { SiteKey, SiteKeyManager } from "../../models/site-key";
import {
  DynamicDetail,
  SiteCustomizations,
  convertFromCustomFieldToDynamicDetails,
} from "../../models/dynamic-details";
import {
  SiteKeyLocation,
  SiteKeyLocationManager,
} from "../../models/site-key-location";
import {
  SiteKeyCompany,
  SiteKeyCompanyRecordManager,
} from "../../models/site-key-companies";
import { NewSiteConfigState } from "../../models/new-site-config";
import { SiteKeyUserDoc } from "../../models/site-key-users";
import { SiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import {
  TaskStatusValues,
  isValidTaskStatusValue,
} from "../../models/task-status";
import { CustomFieldDocData } from "../../models/custom-field";

/* stores */
import { useRootUserStore } from "../../store/root-user";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import AddTemplateDetailsDialog from "../../components/createSite/AddTemplateDetailsDialog";
// #endregion Imports

// #region Interfaces & Types
export interface SiteKeyCSInterface {
  name: SiteKey["name"];
  timezone: SiteKey["timezone"];
  address: SiteKey["address"];
  latitude: SiteKey["latitude"];
  longitude: SiteKey["longitude"];
}
export interface LocationsCSInterface {
  department: SiteKeyLocation["department"];
  title: SiteKeyLocation["title"];
}

export interface CompaniesCSInterface {
  "main point of contact": SiteKeyCompany["mainPointOfContact"];
  name: SiteKeyCompany["name"];
}
export interface WorkTypesCSInterface {
  workAndTaskTypes: Map<string, string[]> | undefined;
}
export interface CustomFieldsCSInterface {
  craftDetails: SiteCustomizations["craftDetails"] | undefined;
  taskSpecificDetails: SiteCustomizations["taskSpecificDetails"] | undefined;
}
export interface KpiConfigCSInterface {
  kpiConfig: string[] | undefined;
}

export type ApiResponseType = {
  /** success -> siteKeyID. failure -> null. */
  message: string | null;
  status: number;
};

export type TrackAnimationType = "not started" | "in progress" | "done";

export interface AdminUserCSInterface {
  company: SiteKeyUserDoc["companyName"];
  department: SiteKeyUserDoc["department"];
  name: SiteKeyUserDoc["displayName"];
  email: SiteKeyUserDoc["email"];
  "job title": SiteKeyUserDoc["jobTitle"];
  phone: SiteKeyUserDoc["phone"];
  approved: SiteKeyUserPermissions["approved"];
  inactive: SiteKeyUserPermissions["inactive"];
  managementSubscriptions: Pick<
    SiteKeyUserPermissions["managementSubscriptions"],
    "newTaskCreated" | "allTaskStatusChanged"
  >;
  permissions: SiteKeyUserPermissions["permissions"];
}

export interface TemplateDetails {
  templateName: string;
  validCraftTypes: NewSiteConfigState["validCraftTypes"];
  validTaskStatusCodes: NewSiteConfigState["validTaskStatusCodes"];
  validTaskTypes: NewSiteConfigState["validTaskTypes"];
  kpiConfig: NewSiteConfigState["kpiConfig"];
  customizations: NewSiteConfigState["customizations"];
}
// #endregion Interfaces & Types

export default function CreateSiteContainer() {
  function goToVueMainPage() {
    window.location.href = BASE_APP_URL;
  }

  const rootUser = useRootUserStore((state) => state.rootUser);
  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;

  // Retrieve the list of template doc from the newSiteConfig collection.
  const { data: templateList = [], isLoading: templateListLoading } = useQuery(
    ["createSiteContainer_templateList"],
    () => DbRead.newSiteConfig.getAll(),
  );

  const [saveTemplateButtonText, setSaveTemplateButtonText] = useState<string>(
    strings.buttons.SAVE_TEMPLATE,
  );

  const [selectedSiteConfig, setSelectedSiteConfig] =
    useState<NewSiteConfigState | null>(null);

  useEffect(() => {
    setSelectedSiteConfig(templateList[0]);
  }, [templateList]);

  const sortedTemplateList: NewSiteConfigState[] = templateList.sort((a, b) =>
    a.templateName.localeCompare(b.templateName),
  );

  // make a list of all the template IDs available on the list
  const templateNameList = sortedTemplateList.map(
    (template) => template["templateName"],
  );

  /** For loading state of the button on site creation progress page */
  const [isButtonBusy, setIsButtonBusy] = useState(false);

  /** API response determines whether or not we want to display it. */
  const [showDashboardBtn, setShowDashboardBtn] = useState(true);

  /**
   * Contains the status code returned from the createSite cloud function. If the
   * operation was successful, also holds the siteKeyID (in the message property).
   */
  const [apiResponse, setApiResponse] = useState<ApiResponseType | null>(null);

  /** To begin the progress screen's animation. NOT used to stop it. */
  const [startAnimation, setStartAnimation] = useState<true | null>(null);

  /** Track the progress screen's animation. */
  const [trackAnimation, setTrackAnimation] =
    useState<TrackAnimationType>("not started");

  const [displayAddressError, setDisplayAddressError] =
    useState<boolean>(false);

  /** For collapsing and expanding the Disclosure components on the Review screen. */
  const disclosureBtnRefs = useRef<Array<HTMLButtonElement | null>>([]);

  const steps = [1, 2, 3];
  const [address, setAddress] = useState<string>("");
  //at first render the position is set on Tampa, Florida
  const [position, setPosition] = useState<google.maps.LatLngLiteral>({
    lat: 27.950575,
    lng: -82.4571776,
  });
  const [zoom, setZoom] = useState<number>(10);

  const [isAddCFDialogOpen, setIsAddCFDialogOpen] = useState(false);

  const [isAddTemplateDetailsDialogOpen, setIsAddTemplateDetailsDialogOpen] =
    useState<boolean>(false);

  //#region temporary data useState
  const [siteKeyCS, setSiteKeyCS] = useState<SiteKeyCSInterface>({
    name: "",
    timezone: "America/Adak",
    address: address,
    latitude: position.lat,
    longitude: position.lng,
  });

  const locationsCS: LocationsCSInterface[] = [
    {
      department: rootUser?.department ?? "",
      title: "Location One",
    },
  ];

  const companiesCS: CompaniesCSInterface[] = [
    {
      "main point of contact": rootUser?.displayName ?? null,
      name: rootUser?.companyName ?? "",
    },
  ];

  const workTypesCS: WorkTypesCSInterface = {
    workAndTaskTypes: getWorkAndTaskTypes({
      configWorkTypes: selectedSiteConfig?.validCraftTypes,
      configTaskTypes: selectedSiteConfig?.validTaskTypes,
    }),
  };

  const [customFieldsCS, setCustomFieldsCS] = useState<CustomFieldsCSInterface>(
    { craftDetails: undefined, taskSpecificDetails: undefined },
  );

  const validCraftTypeList: CraftTypeValues[] | undefined =
    selectedSiteConfig?.validCraftTypes.flatMap((code) =>
      isValidCraftType(code) ? code : [],
    );

  const validTaskTypeList: TaskTypesValues[] | undefined =
    validCraftTypeList?.flatMap((code) => {
      const result = getValidTaskTypes(code);
      return result;
    });

  const validTaskStatusList: TaskStatusValues[] | undefined =
    selectedSiteConfig?.validTaskStatusCodes.flatMap((code) =>
      isValidTaskStatusValue(code) ? code : [],
    );

  useEffect(() => {
    if (!selectedSiteConfig) return;
    const { craft, task } = separateCustomFields(
      selectedSiteConfig.customizations,
    );
    setCustomFieldsCS({ craftDetails: craft, taskSpecificDetails: task });
  }, [selectedSiteConfig]);

  const kpiConfigCS: KpiConfigCSInterface = {
    kpiConfig: getKpiTitles(selectedSiteConfig?.kpiConfig),
  };

  const adminUserCS: AdminUserCSInterface = {
    company: rootUser?.companyName ?? "",
    department: rootUser?.department,
    name: rootUser?.displayName ?? "",
    email: rootUser?.email ?? "",
    "job title": rootUser?.jobTitle ?? "",
    phone: rootUser?.phone ?? "",
    approved: true,
    inactive: false,
    managementSubscriptions: {
      newTaskCreated: false,
      allTaskStatusChanged: false,
    },
    permissions: {
      getsNewTaskNotifications: true,
      canEditContractorDetails: true,
      canCreateTasks: true,
      canUpdateTasks: true,
      canDeleteTasks: true,
      canCreateCraftRecords: true,
      canUpdateCraftRecords: true,
      canDeleteCraftRecords: true,
      isPlantPersonnel: true,
      isSiteAdmin: true,
      complianceRequirements_create: true,
      complianceRequirements_read: true,
      complianceRequirements_delete: true,
      complianceResponses_create: true,
      complianceResponses_read: true,
      complianceResponses_readAll: true,
      complianceResponses_delete: true,
      complianceResponses_review: true,
      tasks_changeDate: true,
    },
  };
  //#endregion

  const timezoneList = getTimezoneList(siteKeyCS ? siteKeyCS.timezone : "");

  const [activeStep, setActiveStep] = useState(0);

  const handleSaveTemporaryData = (formValues: CreateSiteDetailsState) => {
    setSiteKeyCS({
      name: formValues.siteName,
      timezone: formValues.timezone,
      address: address,
      latitude: position.lat,
      longitude: position.lng,
    });
    setZoom(10);
  };

  /** Shows an error if the address has not been filled in. Otherwise, proceed to next step */
  const handleNextButton = (args: { address: string | undefined }) => {
    if (args.address === "" || args.address === undefined) {
      setDisplayAddressError(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBackButton = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function handleSelectAddress(selectedAddress: string) {
    if (selectedAddress === "") {
      setDisplayAddressError(true);
    } else {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddress(selectedAddress);
      setPosition(latLng);
    }
  }

  async function handleSaveTemplate(templateName: string) {
    if (selectedSiteConfig == null) return;

    const { craftDetails, taskSpecificDetails } = customFieldsCS;

    const { syncedFields } = selectedSiteConfig.customizations;

    const templateObject: TemplateDetails = {
      templateName: startCase(templateName),
      validCraftTypes: selectedSiteConfig.validCraftTypes,
      validTaskStatusCodes: selectedSiteConfig.validTaskStatusCodes,
      validTaskTypes: selectedSiteConfig.validTaskTypes,
      kpiConfig: selectedSiteConfig.kpiConfig,
      customizations: {
        syncedFields: syncedFields,
        craftDetails: craftDetails,
        taskSpecificDetails: taskSpecificDetails,
      },
    };

    try {
      // DB Write 🔥
      await DbWrite.newSiteConfig.create(templateObject);
      setSaveTemplateButtonText(strings.buttons.SUCCESS);
      setTimeout(
        setSaveTemplateButtonText,
        5000,
        strings.buttons.SAVE_TEMPLATE,
      );
    } catch (error) {
      console.log("error saving template", error);
      setSaveTemplateButtonText(strings.buttons.FAIL);
      setTimeout(
        setSaveTemplateButtonText,
        5000,
        strings.buttons.SAVE_TEMPLATE,
      );
    }
  }

  function handleSelectionTemplate(
    templateName: NewSiteConfigState["templateName"],
  ) {
    const selectedTemplate = sortedTemplateList.find(
      (template) => template.templateName === templateName,
    );
    if (selectedTemplate != null) {
      setSelectedSiteConfig(selectedTemplate);
    } else {
      setSelectedSiteConfig(sortedTemplateList[0]);
    }
  }

  function handleAddNewCustomField(formValues: CustomFieldDocData) {
    if (!customFieldsCS.craftDetails || !customFieldsCS.taskSpecificDetails) {
      return;
    }

    if (formValues.craftRecordOrTask === "task") {
      /* this is the logic in case is a task specific details */
      addTaskTypeCustomField(formValues, customFieldsCS);
    } else {
      /* this is the logic in case is a craft record */
      addWorkTypeCustomField(formValues, customFieldsCS);
    }
  }

  function handleDeleteCustomField(
    workTypeString: string,
    title: string,
    /** Necessary if deleting a custom field for a task */
    taskTypeString?: string,
  ): void {
    if (!customFieldsCS.craftDetails || !customFieldsCS.taskSpecificDetails) {
      return;
    }

    // Convert workTypeString so it's compatible with what's in customFieldsCS
    const xworkTypeStr = getCraftTypeStringFromReadableString(workTypeString);
    const workEnum = getCraftTypeFromString(xworkTypeStr);
    if (!workEnum) return;
    /**
     * Converted to what it looks like in the DB (`customizations.craftDetails`).
     * The item they clicked belongs to this work type
     */
    const workTypeStrDBVersion = getCraftTypeRecordString(workEnum);

    // Deleting a custom field that belongs to a work type.
    if (!taskTypeString) {
      deleteWorkTypeCustomField({
        craftDetailsState: customFieldsCS.craftDetails,
        clickedWorkType: workTypeStrDBVersion,
        clickedTitle: title,
        setCustomFieldsCS: setCustomFieldsCS,
      });
    } else {
      // Deleting a custom field that belongs to a task.

      const taskEnum = getTaskTypeValueFromReadableStr(taskTypeString);
      if (!taskEnum) return;
      /**
       * Converted to what it looks like in the DB (`customizations.taskSpecificDetails`).
       * The item they clicked belongs to this task type
       */
      const taskTypeStrDBVersion = getTaskSpecificDetailsString(taskEnum);

      deleteTaskTypeCustomField({
        taskState: customFieldsCS.taskSpecificDetails,
        clickedWorkType: workTypeStrDBVersion,
        clickedTaskType: taskTypeStrDBVersion,
        clickedTitle: title,
        setCustomFieldsCS: setCustomFieldsCS,
      });
    }
  }

  const addressError = (
    <div className="mt-2 text-sm">
      <StyledMessage type="error">
        {{ message: strings.REQUIRED_FIELD }}
      </StyledMessage>
    </div>
  );

  /** Site creation success/failure message. */
  const messageJSX = useMemo(() => {
    const errorCodes = [401, 404, 503, 400];
    return getMessageJSX({
      apiResponse,
      errorCodes,
      trackAnimation,
    });
  }, [apiResponse, trackAnimation]);

  /** Controls whether or not to display the "continue to dashboard" button. */
  const displayDashboardBtn: boolean = useMemo(() => {
    if (showDashboardBtn || trackAnimation !== "done") return true;
    return false;
  }, [showDashboardBtn, trackAnimation]);

  // #region SECTION: Components
  const addressField = (
    <PlacesAutocomplete
      value={address}
      onChange={(newAddress: string) => {
        if (newAddress === "") {
          setDisplayAddressError(true);
          setAddress(newAddress);
        } else {
          setAddress(newAddress);
          setDisplayAddressError(false);
        }
      }}
      onSelect={handleSelectAddress}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <BaseInputText
            {...getInputProps({
              admin: true,
              required: true,
              inputName: "address",
              text: "Address",
            })}
          />
          <div
            className={
              suggestions.length > 0 || loading
                ? "absolute z-10 max-w-sm rounded border bg-white p-2"
                : ""
            }
          >
            {loading ? (
              <div className="p-2 text-primary">
                <LoadingSpinner />
              </div>
            ) : null}

            {suggestions.map((suggestion, suggestionIdx) => {
              const style = {
                backgroundColor: suggestion.active
                  ? "var(--primary-opacity-90)"
                  : "#fff",
                borderRadius: "0.25rem",
                padding: "5px",
              };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, { style })}
                  key={suggestionIdx}
                >
                  {suggestion.description}
                </div>
              );
            })}
          </div>
          {displayAddressError ? addressError : null}
        </div>
      )}
    </PlacesAutocomplete>
  );

  const stepperComponent = (
    <HorizontalLinearStepper activeStep={activeStep} steps={steps} />
  );

  /* Map */
  const siteMapComponent = (
    <div className="h-96 w-full overflow-hidden rounded-md">
      <SiteMap center={position} zoom={zoom}>
        <Marker
          position={position}
          getNewPosition={(currentPosition: google.maps.LatLngLiteral) =>
            setPosition(currentPosition)
          }
          getNewZoom={(currentZoom: number) => setZoom(currentZoom)}
        />
      </SiteMap>
    </div>
  );

  const siteDetailsComponent = (
    <Wrapper apiKey={apiKey ?? ""} libraries={["places"]}>
      <CreateSiteDetails
        siteName={siteKeyCS.name}
        timezone={timezoneList}
        onHandleNextButton={(formValues: CreateSiteDetailsState) => {
          handleSaveTemporaryData(formValues);
          handleNextButton({ address: address });
        }}
      >
        {{
          Map: siteMapComponent,
          AddressField: addressField,
        }}
      </CreateSiteDetails>
    </Wrapper>
  );

  /* Add Custom Field Dialog */
  const addCustomFieldDialog = (
    <AddCreateSiteCustomFieldDialog
      isDialogOpen={isAddCFDialogOpen}
      closeDialog={() => setIsAddCFDialogOpen(false)}
      workType={validCraftTypeList ?? []}
      taskTypes={validTaskTypeList ?? []}
      handleSave={handleAddNewCustomField}
      taskStatusList={validTaskStatusList ?? []}
    />
  );

  /* Add Template Details Dialog */
  const addTemplateDetailsDialog = (
    <AddTemplateDetailsDialog
      isDialogOpen={isAddTemplateDetailsDialogOpen}
      closeDialog={() => setIsAddTemplateDetailsDialogOpen(false)}
      onSaveTemplate={handleSaveTemplate}
    />
  );

  const createSiteReviewComponent = (
    <div className="mb-4">
      <CreateSiteReview
        data={siteKeyCS}
        title="site"
        edit={true}
        onHandleBackButton={handleBackButton}
        options="site"
        buttonRefs={disclosureBtnRefs}
      />
      <hr className="my-7 block w-full border border-gray-200" />
      <CreateSiteReview
        data={locationsCS}
        title="locations"
        options="locations"
        buttonRefs={disclosureBtnRefs}
      />
      <hr className="my-7 block w-full border border-gray-200" />
      <CreateSiteReview
        data={companiesCS}
        title="companies"
        options="companies"
        buttonRefs={disclosureBtnRefs}
      />
      <hr className="my-7 block w-full border border-gray-200" />
      <CreateSiteReview
        data={adminUserCS}
        title="administrator"
        options="administrator"
        buttonRefs={disclosureBtnRefs}
      />
      <hr className="my-7 block w-full border border-gray-200" />
      <CreateSiteReview
        data={workTypesCS.workAndTaskTypes}
        title="work types and task types"
        options="workAndTaskTypes"
        buttonRefs={disclosureBtnRefs}
      />
      <hr className="my-7 block w-full border border-gray-200" />
      <CreateSiteReview
        data={customFieldsCS}
        addCustomField={true}
        title="custom fields"
        options="customFields"
        buttonRefs={disclosureBtnRefs}
        handleDeleteCustomField={handleDeleteCustomField}
        openAddCustomFieldDialog={() => setIsAddCFDialogOpen(true)}
      />
      <hr className="my-7 block w-full border border-gray-200" />
      <CreateSiteReview
        data={kpiConfigCS.kpiConfig}
        title="key performance indicators (KPIs)"
        options="kpiConfig"
        buttonRefs={disclosureBtnRefs}
      />
      {addCustomFieldDialog}
      {addTemplateDetailsDialog}
    </div>
  );

  const reviewActionButtonsComponent = (
    <div className="flex flex-col space-y-4 xs:items-end md:col-span-2 md:justify-end">
      <BaseButtonSecondary
        className="w-full xs:w-52"
        type="button"
        onClick={handleBackButton}
      >
        {strings.buttons.BACK}
      </BaseButtonSecondary>
      {/* TODO: disable the save template button if custom fields are unchanged. How to check if the objects are equal? */}
      <BaseButtonSecondary
        className={`w-full xs:w-52 ${
          saveTemplateButtonText === strings.buttons.SUCCESS
            ? "text-greenPass"
            : saveTemplateButtonText === strings.buttons.FAIL
              ? "text-redFail"
              : ""
        } `}
        type="button"
        onClick={() => setIsAddTemplateDetailsDialogOpen(true)}
      >
        <ArchiveIcon className="mr-4 h-5" />
        {saveTemplateButtonText}
      </BaseButtonSecondary>
      <BaseButtonPrimary
        className="w-full xs:w-52"
        type="button"
        onClick={async () => {
          handleNextButton({ address: address });

          // Type narrowing so we don't have to invoke handleCreateSite with
          // newSiteConfig possibly being undefined.
          if (selectedSiteConfig == null) {
            devLogger.error(
              `Error reading default siteKey configuration document.`,
            );
            return;
          }
          setIsButtonBusy(true);
          setStartAnimation(true);
          setTrackAnimation("in progress");

          try {
            const result = await handleCreateSite({
              newSiteConfig: selectedSiteConfig,
              site: siteKeyCS,
              locationList: locationsCS,
              companyList: companiesCS,
              position: position,
              customFields: customFieldsCS,
            });
            setApiResponse(result);
          } catch (e) {
            setShowDashboardBtn(false);

            if (axios.isAxiosError(e) && e.response) {
              setApiResponse({ status: e.response.status, message: null });
            } else {
              setApiResponse({ status: 400, message: null });
            }
          } finally {
            setIsButtonBusy(false);
          }
        }}
      >
        <PlusCircleIcon className="mr-4 h-5" />
        {strings.buttons.ADD_SITE}
      </BaseButtonPrimary>
    </div>
  );

  const collapseExpandReviewButtons = (
    <div className="my-8 flex items-end sm:my-0 md:my-8">
      <div className="mr-auto">
        <h3 className="font-medium text-gray-400">
          {strings.CREATE_SITE_REVIEW_DROPDOWN}
        </h3>
        <DropdownSelectionTemplate
          templateNameList={templateNameList}
          onSelectionTemplate={handleSelectionTemplate}
        />
      </div>
      <StyledTooltip title="Expand All">
        <button
          className="mr-4 rounded-md border border-white bg-white p-1 transition-colors focus:border-primaryLight focus:outline-none focus:ring-1 focus:ring-primaryLight"
          type="button"
          onClick={() => expandAll(disclosureBtnRefs)}
        >
          <svg className="h-8 w-8 text-primary" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M18,8H8V18H6V8A2,2 0 0,1 8,6H18V8M14,2H4A2,2 0 0,0 2,4V14H4V4H14V2M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H17V12H15V15H12V17H15V20H17V17H20V15Z"
            />
          </svg>
        </button>
      </StyledTooltip>

      <StyledTooltip title="Collapse All">
        <button
          className="rounded-md border border-white bg-white p-1 transition-colors focus:border-primaryLight focus:outline-none focus:ring-1 focus:ring-primaryLight"
          type="button"
          onClick={() => collapseAll(disclosureBtnRefs)}
        >
          <svg className="h-8 w-8 text-primary" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M14,4H4V14H2V4A2,2 0 0,1 4,2H14V4M18,6H8A2,2 0 0,0 6,8V18H8V8H18V6M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H12V17H20V15Z"
            />
          </svg>
        </button>
      </StyledTooltip>
    </div>
  );

  const progressAnimationJSX = startAnimation && (
    <WindupChildren onFinished={() => setTrackAnimation("done")}>
      <span>{strings.PROGRESS_CREATING_SITE}</span>
      <Pause ms={600} />

      <span>{strings.progressCreatingLocation(locationsCS.length)}</span>
      <Pause ms={600} />

      <span>{strings.progressCreatingCompany(companiesCS.length)}</span>
      <Pause ms={600} />

      <span>{strings.PROGRESS_CREATING_WORK_AND_TASK_TYPES}</span>
      <Pause ms={600} />

      <span>{strings.PROGRESS_CREATING_CUSTOM_FIELDS}</span>
      <Pause ms={600} />

      <span>{strings.PROGRESS_CREATING_KPIS}</span>
      <Pause ms={600} />

      <span>{strings.PROGRESS_CREATING_ADMIN_ACCOUNT}</span>
    </WindupChildren>
  );

  const continueToDashboardButton = displayDashboardBtn && (
    <div className="mx-auto flex items-center space-y-4 sm:space-y-0">
      <BaseButtonPrimary
        className="w-full xs:w-52 "
        type="button"
        onClick={goToVueMainPage}
        isBusy={isButtonBusy}
      >
        {strings.buttons.CONTINUE_TO_DASHBOARD}
      </BaseButtonPrimary>
    </div>
  );
  // #endregion Components

  if (!rootUser || templateListLoading) {
    return (
      <div className="flex h-full flex-auto flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  if (activeStep === 0) {
    return (
      <CreateSitePage>
        {{
          Stepper: stepperComponent,
          SiteDetails: siteDetailsComponent,
        }}
      </CreateSitePage>
    );
  } else if (activeStep === 1) {
    return (
      <SiteReviewPage>
        {{
          Stepper: stepperComponent,
          Review: createSiteReviewComponent,
          ActionButtons: reviewActionButtonsComponent,
          CollapseExpandButtons: collapseExpandReviewButtons,
        }}
      </SiteReviewPage>
    );
  } else if (activeStep === 2) {
    return (
      <ProgressPage>
        {{
          Stepper: stepperComponent,
          ActionButton: continueToDashboardButton,
          ApiResponseMessage: messageJSX,
          ProgressAnimation: progressAnimationJSX,
        }}
      </ProgressPage>
    );
  } else {
    devLogger.error(
      `Executing what should be unreachable code. CreateSiteContainer, activeStep is ${activeStep}`,
    );
    return <NotFound404 />;
  }
}

// #region SECTION: helper functions
/**
 * Executes the createSite cloud function.
 */
async function handleCreateSite(args: {
  newSiteConfig: NewSiteConfigState;
  position: google.maps.LatLngLiteral;
  site: SiteKeyCSInterface;
  locationList: LocationsCSInterface[];
  companyList: CompaniesCSInterface[];
  customFields: CustomFieldsCSInterface;
}): Promise<{
  message: string | null;
  status: number;
}> {
  // #region  Construct the necessary documents:
  // SiteKey, SiteKeyLocation[], SiteKeyCompany[]
  const customizationsWithSyncedFields = {
    ...args.customFields,
    syncedFields: args.newSiteConfig.customizations.syncedFields,
  };

  const siteKey: SiteKey = {
    name: args.site.name,
    timezone: args.site.timezone,
    latitude: args.site.latitude,
    longitude: args.site.longitude,
    address: args.site.address,
    customizations: customizationsWithSyncedFields,

    // The ones that aren't obtained from user input
    validTaskStatusCodes: args.newSiteConfig.validTaskStatusCodes,
    validCraftTypes: args.newSiteConfig.validCraftTypes,
    validTaskTypes: args.newSiteConfig.validTaskTypes,
    kpiConfig: args.newSiteConfig.kpiConfig,
    validEventTypes: [],
    unapprovedUsers: [],
    inactiveUsers: [],

    // Cannot determine the managingCompanyID while on the client side. It is
    // assigned the correct value on the backend.
    managingCompanyID: "replace",
  };

  const locationList: SiteKeyLocation[] = args.locationList.map((location) => {
    return {
      department: location.department,
      title: location.title,
      latitude: args.position.lat,
      longitude: args.position.lng,
    };
  });

  const companyList: SiteKeyCompany[] = args.companyList.map((company) => {
    return {
      name: company.name,
      mainPointOfContact: company["main point of contact"],

      // The ones that aren't obtained from user input
      logoPhotoURL: null,
      members: [], // Taken care of on the backend.
      craftTypes: args.newSiteConfig.validCraftTypes,
      isPlantCompany: true,
      canRequestCraftTypes: [],
    };
  });
  // #endregion

  // Validate data
  const validSiteKey = SiteKeyManager.parse(siteKey);
  const validLocationList = locationList.map((location) =>
    SiteKeyLocationManager.parse(location),
  );
  const validCompanyList = companyList.map((company) =>
    SiteKeyCompanyRecordManager.parse(company),
  );

  // DATABASE WRITE 🔥
  return DbWrite.siteKey.create({
    siteKeyDoc: validSiteKey,
    locationDocList: validLocationList,
    companyDocList: validCompanyList,
  });
}

/**
 * Retrieves the workTypes the new site will have access to, and attaches them to
 * the accessible taskTypes -> the taskTypes that are valid when given a particular
 * workType, but only if that taskType is also present on the newSiteConfig object.
 * To be used with creating a site.
 *
 * **Example return value:**
Map {
  0: {"Scaffolding" => Array(4)}
    key: "Scaffolding"
    value: (4) ['Inspection', 'Installation', 'Modification', 'Removal']
  1: {"Insulation" => Array(2)}
    key: "Insulation"
    value: (2) ['Abatement', 'Insulation']
  2: ...
}
 *
 * Tested ✅
 */
export function getWorkAndTaskTypes(args: {
  configWorkTypes: number[] | undefined;
  configTaskTypes: number[] | undefined;
}) {
  const { configWorkTypes, configTaskTypes } = args;
  // Will only be undefined while the component is loading.
  if (configTaskTypes === undefined || configWorkTypes === undefined) {
    return;
  }

  const result = configWorkTypes.reduce<Map<string, string[]>>(
    (acc, workType): Map<string, string[]> => {
      // For each workType, get the possible taskTypes.
      let tasksBelongingToWorkType: TaskTypesValues[];
      if (isValidCraftType(workType)) {
        tasksBelongingToWorkType = getValidTaskTypes(workType);
      } else {
        return acc;
      }

      // Intersection of configTaskTypes and each workType's applicable taskTypes.
      const tasksDividedByWorkType = configTaskTypes.filter((task) =>
        tasksBelongingToWorkType.includes(task as TaskTypesValues),
      );

      // Get the human readable strings.
      const taskStrings = tasksDividedByWorkType
        .map((task) => getReadableTaskType(task))
        .sort();
      const workTypeString = getReadableCraftType(workType);

      acc.set(workTypeString, taskStrings);
      return acc;
    },
    new Map(),
  );

  return result;
}

/**
 * @returns an object containing the craftDetails object and the taskSpecificDetails object.
 */
function separateCustomFields(configCustomizations: Record<string, any>): {
  craft: SiteCustomizations["craftDetails"];
  task: SiteCustomizations["taskSpecificDetails"];
} {
  const configCraft = configCustomizations.craftDetails;
  const configTask = configCustomizations.taskSpecificDetails;

  return { craft: configCraft, task: configTask };
}

/**
 * Retrieves the titles from each kpiConfig object. To be used with creating a site.
 * @returns Alphabetized title list. (Unless the component is loading, in which case,
 * it will return undefined.)
 *
 * Tested ✅
 */
export function getKpiTitles(
  kpiConfig: Record<string, any> | undefined,
): string[] | undefined {
  // Will only be undefined while the component is loading.
  if (kpiConfig === undefined) return;

  const result = Object.entries(kpiConfig)
    .flatMap(([, nestedMap]) => {
      if (typeof nestedMap.title === "string" && nestedMap.title.length !== 0) {
        return nestedMap.title;
      } else {
        return [];
      }
    })
    .sort();
  return result;
}

/**
 * Collapse all create site review details.
 *
 * NOTE: Uses vanilla JS because of how the headlessUI Disclosure component works
 */
export function collapseAll(
  buttonRefs: React.MutableRefObject<(HTMLButtonElement | null)[]>,
) {
  buttonRefs.current.forEach((btn, i) => {
    const toggleIsOpen = btn?.getAttribute("aria-expanded") === "true";

    if (btn != null && toggleIsOpen) {
      btn.click();
    }
  });
}

/**
 * Expand all create site review details.
 *
 * NOTE: Uses vanilla JS because of how the headlessUI Disclosure component works
 */
export function expandAll(
  buttonRefs: React.MutableRefObject<(HTMLButtonElement | null)[]>,
) {
  buttonRefs.current.forEach((btn, i) => {
    const toggleIsClosed = btn?.getAttribute("aria-expanded") === "false";

    if (btn != null && toggleIsClosed) {
      btn.click();
    }
  });
}

/**
 * Given the API's response object, returns JSX to inform the user if the site was
 * created or if there was an error.
 *
 * Returns null in two cases: 1) when the cloud function hasn't returned a response
 * yet, 2) when the CF has returned a response BUT the animation is still running.
 *
 * Tested ✅
 */
export function getMessageJSX(args: {
  apiResponse: ApiResponseType | null;
  errorCodes: number[];
  trackAnimation: TrackAnimationType;
}): JSX.Element | null {
  if (args.apiResponse === null || args.trackAnimation !== "done") {
    return null;
  } else if (args.apiResponse.status === 201) {
    const successMessage = (
      <span>
        {strings.CREATE_SITE_SUCCESS_PARTIAL_STRING}
        <span className="font-semibold">{args.apiResponse.message}</span>
      </span>
    );
    return (
      <StyledMessage type="success">
        {{
          message: successMessage,
          icon: (
            <CheckCircleIcon
              className="h-8 w-8 text-green-800"
              aria-hidden="true"
            />
          ),
        }}
      </StyledMessage>
    );
  } else {
    return (
      <StyledMessage type="error">
        {{
          message: strings.createSiteFailure(args.apiResponse.status),
          icon: (
            <XCircleIcon className="h-8 w-8 text-red-900" aria-hidden="true" />
          ),
        }}
      </StyledMessage>
    );
  }
}

type DeleteCFWorkType = Record<string, DynamicDetail>;
/** Delete a custom field that belongs to a work type. */
function deleteWorkTypeCustomField(args: {
  craftDetailsState: CustomFieldsCSInterface["craftDetails"];
  /** String in the format seen in the DB; ex: scaffoldRecords */
  clickedWorkType: string;
  clickedTitle: string;
  setCustomFieldsCS: (value: SetStateAction<CustomFieldsCSInterface>) => void;
}): void {
  if (!args.craftDetailsState) return;

  /** Custom fields for clicked work type */
  const cfsForClickedWT = args.craftDetailsState[args.clickedWorkType];

  /** Custom fields for clicked work type, excluding the clicked custom field */
  const updatedCustomFields = Object.entries(
    cfsForClickedWT,
  ).reduce<DeleteCFWorkType>((acc, [key, dynamicDetail]): DeleteCFWorkType => {
    if (dynamicDetail.title !== args.clickedTitle) {
      acc[key] = dynamicDetail;
    }
    return acc;
  }, {});

  // Make sure they didn't delete the last custom field of the given work type
  // before storing that work type in the updated customFields state.
  if (Object.keys(updatedCustomFields).length > 0) {
    const updatedObj = {
      craftDetails: { [args.clickedWorkType]: updatedCustomFields },
    };
    args.setCustomFieldsCS((previousValues) => ({
      craftDetails: {
        ...previousValues.craftDetails,
        ...updatedObj.craftDetails,
      },
      taskSpecificDetails: previousValues.taskSpecificDetails,
    }));
  } else {
    // They deleted the last custom field associated with the given work type;
    // additional logic is needed.

    // Drop the clicked work type from the object that contains all of the work
    // types (args.craftDetailsState).
    const updatedWorkTypesList = Object.entries(args.craftDetailsState).filter(
      ([workTypeStr, _]) => workTypeStr !== args.clickedWorkType,
    );

    // Convert back to an object
    const workTypeObj = updatedWorkTypesList.reduce(
      (acc, [wtStr, ddMap]) => ({ ...acc, [wtStr]: ddMap }),
      {},
    );

    // Update state
    args.setCustomFieldsCS((previousValues) => ({
      craftDetails: workTypeObj,
      taskSpecificDetails: previousValues.taskSpecificDetails,
    }));
  }
}

type DeleteCFTaskType = Record<string, Record<string, DynamicDetail>>;
/** Delete a custom field that belongs to a task type. */
function deleteTaskTypeCustomField(args: {
  taskState: CustomFieldsCSInterface["taskSpecificDetails"];
  /** String in the format seen in the DB; ex: scaffoldRecords */
  clickedWorkType: string;
  /** String in the format seen in the DB; ex: installation */
  clickedTaskType: string;
  clickedTitle: string;
  setCustomFieldsCS: (value: SetStateAction<CustomFieldsCSInterface>) => void;
}): void {
  if (!args.taskState) return;

  /** Custom fields for the clicked work type, separated by task type. */
  const cfsForClickedWT = args.taskState[args.clickedWorkType];

  /**
   * Custom fields for the clicked work type, separated by task type. Excludes
   * the clicked custom field.
   */
  const updatedCustomFields = Object.entries(
    cfsForClickedWT,
  ).reduce<DeleteCFTaskType>(
    (acc, [currentTaskTypeStr, dynamicDetailMap]): DeleteCFTaskType => {
      const newTaskMap: Record<string, DynamicDetail> = {};
      Object.entries(dynamicDetailMap).forEach(([key, ddData]) => {
        if (
          ddData.title === args.clickedTitle &&
          currentTaskTypeStr === args.clickedTaskType
        ) {
          // Want to delete =>  1 - titles match. 2 - task types match
          devLogger.info(
            `Deleting "${args.clickedTitle}" belonging to ${args.clickedTaskType} task`,
          );
        } else {
          // Otherwise, keep it
          newTaskMap[key] = ddData;
        }
      });

      // Make sure they didn't delete the last custom field of a given task type
      // before adding that task type to the updatedCustomField object.
      if (Object.keys(newTaskMap).length > 0) {
        acc[currentTaskTypeStr] = newTaskMap;
      }
      return acc;
    },
    {},
  );

  const updatedObj = {
    taskSpecificDetails: { [args.clickedWorkType]: updatedCustomFields },
  };
  args.setCustomFieldsCS((previousValues) => ({
    craftDetails: previousValues.craftDetails,
    taskSpecificDetails: {
      ...previousValues.taskSpecificDetails,
      ...updatedObj.taskSpecificDetails,
    },
  }));
}

// SECTION: getGroupedCustomFields + related ƒns
type GroupedType = Record<
  // Work Type String
  string,
  //            Task Type String ⌄
  Array<DynamicDetail | Record<string, DynamicDetail[]>>
  // ^ a dynamicDetail object at each position, or a nested task object
>;

type CraftMapType = Record<string, DynamicDetail[]>;
type TaskMapType = Record<string, Record<string, DynamicDetail[]>>;

/**
 * @param customFields object containing craftDetails and taskSpecificDetails.
 * @returns Custom fields object grouped by work type and task type (task type if
 * applicable). 
 * 
 * Custom fields (aka dynamic details) are sorted alphabetically, by the title property. 
 * (Work types and task types are not sorted here, since they're object keys.)
 * 
 * EXAMPLE
 * 
{
  Scaffolding: [
    { maxValue: 10000, defaultValue: 0 ... },
    { 
      Removal: [
        { editable: true, title: 'Work Order' ... },
        { type: 'timestamp', required: false ... }
      ]
    }
  ],
  Painting: [
    { editable: true, type: 'bool', ... },
    { minValue: 0, title: 'Linear Footage', ... }
  ],
  Insulation: [
    { 
      Insulation: [
        { defaultValue: false, type: 'bool', ... }
      ] 
    }
  ]
}
 *
 * Tested ✅
 */
export function getGroupedCustomFields(
  customFields: CustomFieldsCSInterface,
): GroupedType {
  const craftDetails = customFields.craftDetails;
  const craftMap = getCraftMap(craftDetails);
  const sortedCraftMap = getSortedCraftMap(craftMap);

  const taskSpecificDetails = customFields.taskSpecificDetails;
  const taskMap = getTaskMap(taskSpecificDetails);
  const sortedTaskMap = getSortedTaskMap(taskMap);

  const groupedMap = Object.entries(sortedTaskMap).reduce<GroupedType>(
    (acc, [workType, taskObj]): GroupedType => {
      Object.entries(taskObj).forEach(([taskType, ddData]) => {
        if (!(workType in acc)) {
          // Work type doesn't exist in the map yet. Initialize it with an empty array.
          acc[workType] = [];
        }

        acc[workType].push({ [taskType]: ddData });
      });
      return acc;
    },
    // initialValue = craftDetails object
    sortedCraftMap,
  );

  return groupedMap;
}

/**
 * @param craftDetails object containing workTypes and dynamicDetails. 
 * DB path = siteKey.customizations.craftDetails
 * @returns EXAMPLE
 * 
  {
    Scaffolding: [
      { editable: true, title: 'Barrier - Site Emergency (minutes)', ... }
    ],
    Painting: [
      { minValue: 0, editable: true, ... },
      { defaultValue: false, type: 'bool', ... }
    ]
  }
 * 
 * Tested by proxy ✅
 */
function getCraftMap(
  craftDetails: SiteCustomizations["craftDetails"] | undefined,
): CraftMapType {
  const craftMap: CraftMapType = {};

  if (guardIsPlainObject(craftDetails)) {
    Object.entries(craftDetails).forEach(([craftTypeStr, dynamicDetailMap]) => {
      if (guardIsPlainObject(dynamicDetailMap)) {
        // Using a for loop so we can use the 'break' keyword
        for (let i = 0; i < Object.keys(dynamicDetailMap).length; i++) {
          const craftEnum = getCraftTypeFromRecordString(craftTypeStr);
          if (!craftEnum) break;
          const readableWorkType = getReadableCraftType(craftEnum);
          const arr = craftMap[readableWorkType];
          if (!Array.isArray(arr)) {
            craftMap[readableWorkType] = [];
          }
          const ddData = Object.values(dynamicDetailMap)[i];
          craftMap[readableWorkType].push(ddData);
        }
      } else {
        devLogger.warn(
          `Expected an object for the dynamicDetailMap for ${craftTypeStr}, got: ${typeof dynamicDetailMap}`,
        );
        return [];
      }
    });
  } else {
    devLogger.warn(
      `Expected craftDetails to be an object, got ${typeof craftDetails}`,
    );
  }

  return craftMap;
}

/**
 * @returns CraftMapType, alphabetized by the dynamic detail's `title` property.
 *
 * Tested by proxy ✅
 */
function getSortedCraftMap(craftMap: CraftMapType): CraftMapType {
  return Object.entries(craftMap).reduce<CraftMapType>(
    (acc, [workType, ddData]) => {
      const sorted = ddData.sort((a, b) => {
        if (a.title > b.title) {
          return 1;
        } else if (a.title === b.title) {
          return 0;
        } else {
          return -1;
        }
      });
      acc[workType] = sorted;
      return acc;
    },
    {},
  );
}

/**
 * @param taskSpecificDetails object containing workTypes, taskTypes, and dynamicDetails. 
 * DB path = siteKey.customizations.taskSpecificDetails
 * @returns EXAMPLE:
 * 
  {
    Insulation: {
      Insulation: [
        { type: 'bool', title: 'Additional hoist or crane support required?', defaultValue: false, ... }
      ] 
    }, 
    Scaffolding: { 
      Removal: [
        { editable: true, title: 'Work Order', ... },
        { defaultValue: null, type: "timestamp", ... }
      ],
      Inspection: [
        { required: false, type: 'bool', ... }
      ]
    }
  }
 *
 * Tested by proxy ✅
 */
function getTaskMap(
  taskSpecificDetails: SiteCustomizations["taskSpecificDetails"] | undefined,
): TaskMapType {
  const taskMap: TaskMapType = {};
  if (guardIsPlainObject(taskSpecificDetails)) {
    Object.entries(taskSpecificDetails).forEach(
      ([craftTypeStr, taskTypeMap]) => {
        if (guardIsPlainObject(taskTypeMap)) {
          Object.entries(taskTypeMap).forEach(
            ([taskTypeStr, dynamicDetailMap]) => {
              if (guardIsPlainObject(dynamicDetailMap)) {
                // Using a for loop so we can use the 'break' keyword
                for (let i = 0; i < Object.keys(taskTypeMap).length; i++) {
                  // Get the readable work type
                  const craftEnum = getCraftTypeFromRecordString(craftTypeStr);
                  const readableWorkType = getReadableCraftType(craftEnum);

                  // If the work type isn't in the taskMap object yet, add it
                  const obj = taskMap[readableWorkType];
                  if (!guardIsPlainObject(obj)) {
                    taskMap[readableWorkType] = {};
                  }

                  // Get the readable task type
                  const taskEnum = getTaskTypeFromString(taskTypeStr);
                  // Break out of this iteration if taskEnum is undefined
                  if (!taskEnum) break;
                  const readableTaskType = getReadableTaskType(taskEnum);

                  // If the task type isn't in the taskMap[workType] object yet, add it
                  const nestedObj = taskMap[readableWorkType][readableTaskType];
                  if (!guardIsPlainObject(nestedObj)) {
                    taskMap[readableWorkType][readableTaskType] = [];
                  }

                  const ddData = Object.values(dynamicDetailMap);
                  taskMap[readableWorkType][readableTaskType] = ddData;
                }
              } else {
                devLogger.warn(
                  `Expected an object for the dynamicDetailMap for ${craftTypeStr}.${taskTypeStr}, got: ${typeof dynamicDetailMap}`,
                );
                return [];
              }
            },
          );
        } else {
          devLogger.warn(
            `Expected an object for the taskTypeMap for ${craftTypeStr}, got: ${typeof taskTypeMap}`,
          );
          return [];
        }
      },
    );
  } else {
    devLogger.warn(
      `Expected taskSpecificDetails to be an object, got ${typeof taskSpecificDetails}`,
    );
  }

  return taskMap;
}

/**
 * @returns TaskMapType, alphabetized by the dynamic detail's `title` property.
 *
 * Tested by proxy ✅
 */
function getSortedTaskMap(taskMap: TaskMapType): TaskMapType {
  return Object.entries(taskMap).reduce<TaskMapType>(
    (acc, [workType, taskObj]) => {
      Object.entries(taskObj).forEach(([taskType, ddData]) => {
        const sorted = ddData.sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          } else if (a.title === b.title) {
            return 0;
          } else {
            return -1;
          }
        });

        //  ...taskObj => don't overwrite previously added values (they belong to other task types)
        acc[workType] = { ...taskObj, [taskType]: sorted };
      });
      return acc;
    },
    {},
  );
}

/**
 * Sorts nested task titles in alphabetical order
 *
 * Tested ✅
 */
export function sortTaskTitlesForGroupedCFs(
  groupedCustomFields: GroupedType,
): GroupedType {
  return Object.entries(groupedCustomFields).reduce<GroupedType>(
    (acc, [workTypeStr, value]): GroupedType => {
      // Temporary storage to sort later
      const ddMapList: Record<string, DynamicDetail[]>[] = [];

      // Add the workType to the accumulator if it's not already present
      if (!(workTypeStr in acc)) {
        acc[workTypeStr] = [];
      }

      value.forEach((ddMapOrData) => {
        if (!ddMapOrData.title) {
          // If there's no title, it's a ddMap.
          Object.entries(ddMapOrData).forEach(([taskString, ddData]) => {
            ddMapList.push({ [taskString]: ddData });
          });
        } else {
          // There's a title, so it's ddData
          acc[workTypeStr].push(ddMapOrData);
        }
      });

      const sortedTaskTitleList = ddMapList.sort((a, b) => {
        const titleA = Object.keys(a)[0];
        const titleB = Object.keys(b)[0];
        if (titleA > titleB) {
          return 1;
        } else if (titleA === titleB) {
          return 0;
        } else {
          return -1;
        }
      });

      acc[workTypeStr].push(...sortedTaskTitleList);

      return acc;
    },
    {},
  );
}
// #endregion helper functions

export function addWorkTypeCustomField(
  data: CustomFieldDocData,
  customFieldsCS: CustomFieldsCSInterface,
) {
  if (!customFieldsCS.craftDetails) return;
  const { craftType } = data;

  /* Converted to what it looks like in the DB ('customization.craftDetails') */
  const workTypeStrDBVersion = getCraftTypeRecordString(craftType);

  const dynamicDetailData = convertFromCustomFieldToDynamicDetails(data);
  const camelCaseWorkTypeTitle = camelCase(dynamicDetailData.title);

  if (customFieldsCS.craftDetails[workTypeStrDBVersion] != null) {
    customFieldsCS.craftDetails = {
      ...customFieldsCS.craftDetails,
      [workTypeStrDBVersion]: {
        ...customFieldsCS.craftDetails[workTypeStrDBVersion],
        [camelCaseWorkTypeTitle]: dynamicDetailData,
      },
    };
  } else {
    customFieldsCS.craftDetails = {
      ...customFieldsCS.craftDetails,
      [workTypeStrDBVersion]: {
        [camelCaseWorkTypeTitle]: dynamicDetailData,
      },
    };
  }
}

export function addTaskTypeCustomField(
  data: CustomFieldDocData,
  customFieldsCS: CustomFieldsCSInterface,
) {
  if (data.craftRecordOrTask !== "task" || !customFieldsCS.taskSpecificDetails)
    return;
  const { craftType, taskType } = data;

  const dynamicDetailData = convertFromCustomFieldToDynamicDetails(data);

  /* Converted to what it looks like in the DB ('customization.craftDetails') */
  const taskTypeStrDBVersion = getTaskSpecificDetailsString(taskType);
  const workTypeStrDBVersion = getCraftTypeRecordString(craftType);
  const camelCaseTaskTitle = camelCase(dynamicDetailData.title);

  if (
    customFieldsCS.taskSpecificDetails[workTypeStrDBVersion] != null &&
    customFieldsCS.taskSpecificDetails[workTypeStrDBVersion][
      taskTypeStrDBVersion
    ] != null
  ) {
    customFieldsCS.taskSpecificDetails = {
      ...customFieldsCS.taskSpecificDetails,
      [workTypeStrDBVersion]: {
        ...customFieldsCS.taskSpecificDetails[workTypeStrDBVersion],
        [taskTypeStrDBVersion]: {
          ...customFieldsCS.taskSpecificDetails[workTypeStrDBVersion][
            taskTypeStrDBVersion
          ],
          [camelCaseTaskTitle]: dynamicDetailData,
        },
      },
    };
  } else if (
    customFieldsCS.taskSpecificDetails[workTypeStrDBVersion] != null &&
    customFieldsCS.taskSpecificDetails[workTypeStrDBVersion][
      taskTypeStrDBVersion
    ] == null
  ) {
    customFieldsCS.taskSpecificDetails = {
      ...customFieldsCS.taskSpecificDetails,
      [workTypeStrDBVersion]: {
        ...customFieldsCS.taskSpecificDetails[workTypeStrDBVersion],
        [taskTypeStrDBVersion]: {
          [camelCaseTaskTitle]: dynamicDetailData,
        },
      },
    };
  } else {
    customFieldsCS.taskSpecificDetails = {
      ...customFieldsCS.taskSpecificDetails,
      [workTypeStrDBVersion]: {
        [taskTypeStrDBVersion]: {
          [camelCaseTaskTitle]: dynamicDetailData,
        },
      },
    };
  }
}
