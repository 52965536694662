// Libs
import { DocumentSnapshot, DocumentData, Timestamp } from "firebase/firestore";
import { z } from "zod";

// Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

// #region SECTION: Schemas / Interfaces / Types
/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
export const SchemaCraftRecord = z.object({
  assetID: z.string().min(1).nullable(),
  authorizedCompanies: z.array(z.string().min(1).max(200)),
  closedTasks: z.array(z.string().min(1).max(200)),
  closedTaskTypes: z.array(z.number().int()),
  craftDetails: z.record(z.any()),
  craftType: z.number().int(),
  createdBy: z.string().min(1).max(200),
  description: z.string().max(10000).nullable(),
  lastModifiedBy: z.string().min(1).max(200),
  latitude: z.number(),
  locationID: z.string().min(1).max(200),
  longitude: z.number(),
  numClosedTasks: z.number().int(),
  numOpenTasks: z.number().int(),
  open: z.boolean(),
  openTasks: z.array(z.string().min(1).max(200)),
  openTaskTypes: z.array(z.number().int()),
  thumbnailURL: z.string().url().nullable(),
  timestampLastModified: z.instanceof(Timestamp),
  timestampRecordClosed: z.instanceof(Timestamp).nullable(),
  timestampRecordCreated: z.instanceof(Timestamp),
  title: z.string().min(1).max(200),

  customerID: z.string().min(0).max(1000).optional(),
  customerLocationID: z.string().min(0).max(1000).optional(),
  qrCode: z.string().min(0).max(7089).optional(),
});

export type CraftRecord = z.infer<typeof SchemaCraftRecord>;
// Separate type/interface for 'new' and Existing
export interface ExistingCraftRecord extends CraftRecord {
  id: string;
  refPath: string;
}

type withoutTimestamps = Omit<
  CraftRecord,
  "timestampLastModified" | "timestampRecordClosed" | "timestampRecordCreated"
>;

export interface CraftRecord_CreateForCustomer extends withoutTimestamps {
  timestampLastModified: number;
  timestampRecordClosed: number | null;
  timestampRecordCreated: number;
}

/** For reading from the database */
const SchemaCraftRecordWithFallbacks = z.object({
  assetID: zFallback(
    z.string().min(1).nullable(),
    null,
    "SchemaCraftRecordWithFallbacks: 'assetID'",
  ),
  authorizedCompanies: z.array(z.string().min(1).max(200)),
  closedTasks: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "SchemaCraftRecordWithFallbacks: 'closedTasks'",
  ),
  closedTaskTypes: zFallback(
    z.array(z.number().int()),
    [],
    "SchemaCraftRecordWithFallbacks: 'closedTaskTypes'",
  ),
  craftDetails: zFallback(
    z.record(z.any()),
    {},
    "SchemaCraftRecordWithFallbacks: 'craftDetails'",
  ),
  craftType: z.number().int(),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SchemaCraftRecordWithFallbacks: 'createdBy'",
  ),
  description: zFallback(
    z.string().max(10000).nullable(),
    null,
    "SchemaCraftRecordWithFallbacks: 'description'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SchemaCraftRecordWithFallbacks: 'lastModifiedBy'",
  ),
  latitude: z.number(),
  locationID: z.string().min(1).max(200),
  longitude: z.number(),
  numClosedTasks: zFallback(
    z.number().int(),
    0,
    "SchemaCraftRecordWithFallbacks: 'numClosedTasks'",
  ),
  numOpenTasks: zFallback(
    z.number().int(),
    0,
    "SchemaCraftRecordWithFallbacks: 'numOpenTasks'",
  ),
  open: z.boolean(),
  openTasks: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "SchemaCraftRecordWithFallbacks: 'openTasks'",
  ),
  openTaskTypes: zFallback(
    z.array(z.number().int()),
    [],
    "SchemaCraftRecordWithFallbacks: 'openTaskTypes'",
  ),
  thumbnailURL: zFallback(
    z.string().url().nullable(),
    null,
    "SchemaCraftRecordWithFallbacks: 'thumbnailURL'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "SchemaCraftRecordWithFallbacks: 'timestampLastModified'",
  ),
  timestampRecordClosed: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "SchemaCraftRecordWithFallbacks: 'timestampRecordClosed'",
  ),
  timestampRecordCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "SchemaCraftRecordWithFallbacks: 'timestampRecordCreated'",
  ),
  title: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SchemaCraftRecordWithFallbacks: 'title'",
  ),
  customerID: zFallback(
    z.string().min(0).max(1000).optional(),
    undefined,
    "SchemaCraftRecordWithFallbacks: 'customerID'",
  ),
  customerLocationID: zFallback(
    z.string().min(0).max(1000).optional(),
    undefined,
    "SchemaCraftRecordWithFallbacks: 'customerLocationID'",
  ),
  qrCode: zFallback(
    z.string().min(0).max(7089).optional(),
    undefined,
    "SchemaCraftRecordWithFallbacks: 'qrCode'",
  ),
});
// #endregion

// #region SECTION: Functions
/** Utilities for interacting with CraftRecord objects. */
export const CraftRecordManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingCraftRecord object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createCraftRecordFromSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  convertForFirestore: convertCraftRecordForFirestore,
  /** Drop undefined values from a new craftRecord doc before database write. */
  convertNewForFirestore: convertNewCraftRecordForFirestore,
  createFromJSON: createCraftRecordFromJSONObject,
  /** Validate a CraftRecord doc. Use for writing to the database or reading from the user. */
  parse: validateCraftRecord,
  /** Validate a CraftRecord doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateCraftRecordWithFallbacks,
  parseCreateForCustomer: validateCraftRecord_CreateForCustomer,
};

/**
 * Convert the Document Snapshot into a validated ExistingCraftRecord object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createCraftRecordFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingCraftRecord {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateCraftRecordWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertCraftRecordForFirestore(
  record: ExistingCraftRecord,
): DocumentData {
  const local = Object.assign({}, record);
  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/** Drop undefined values from a new craftRecord doc before database write. */
function convertNewCraftRecordForFirestore(
  craftRecord: CraftRecord | CraftRecord_CreateForCustomer,
): DocumentData {
  // Copy before modifying. Don't want to modify original object.
  const local = Object.assign({}, craftRecord);
  return dropUndefined(local);
}

function createCraftRecordFromJSONObject(
  jsonObj: Record<string, any>,
): ExistingCraftRecord {
  let timestampRecordClosed: Timestamp | null = null;
  const timestampLastModified: Timestamp = new Timestamp(
    jsonObj.timestampLastModified._seconds,
    jsonObj.timestampLastModified._nanoseconds,
  );
  const timestampRecordCreated: Timestamp = new Timestamp(
    jsonObj.timestampRecordCreated._seconds,
    jsonObj.timestampRecordCreated._nanoseconds,
  );

  if (jsonObj.timestampRecordClosed !== null) {
    timestampRecordClosed = new Timestamp(
      jsonObj.timestampRecordClosed._seconds,
      jsonObj.timestampRecordClosed._nanoseconds,
    );
  }

  return {
    id: jsonObj.id,
    refPath: jsonObj.refPath,
    assetID: jsonObj.assetID,
    authorizedCompanies: jsonObj.authorizedCompanies,
    closedTasks: jsonObj.closedTasks,
    closedTaskTypes: jsonObj.closedTaskTypes,
    craftDetails: jsonObj.craftDetails,
    craftType: jsonObj.craftType,
    createdBy: jsonObj.createdBy,
    description: jsonObj.description,
    lastModifiedBy: jsonObj.lastModifiedBy,
    latitude: jsonObj.latitude,
    locationID: jsonObj.locationID,
    longitude: jsonObj.longitude,
    numClosedTasks: jsonObj.numClosedTasks,
    numOpenTasks: jsonObj.numOpenTasks,
    open: jsonObj.open,
    openTasks: jsonObj.openTasks,
    openTaskTypes: jsonObj.openTaskTypes,
    thumbnailURL: jsonObj.thumbnailURL,
    timestampLastModified: timestampLastModified,
    timestampRecordClosed: timestampRecordClosed,
    timestampRecordCreated: timestampRecordCreated,
    title: jsonObj.title,
  };
}

/** Rejects if it doesn't pass validation */
function validateCraftRecord(record: unknown): CraftRecord {
  if (!guardIsPlainObject(record)) {
    throw new Error(`CraftRecord is not an object: ${record}`);
  }
  return SchemaCraftRecord.parse(record);
}

/** For validating stuff coming in from the database */
function validateCraftRecordWithFallbacks(record: unknown): CraftRecord {
  if (!guardIsPlainObject(record)) {
    throw new Error(`CraftRecord is not an object: ${record}`);
  }
  return SchemaCraftRecordWithFallbacks.parse(record);
}

function validateCraftRecord_CreateForCustomer(
  task: unknown,
): CraftRecord_CreateForCustomer {
  if (!guardIsPlainObject(task)) {
    throw new Error(`Task is not an object: ${task}`);
  }
  return craftRecordSchema_CreateForCustomer.parse(task);
}
// #endregion

const withoutTimestampsSchema = SchemaCraftRecord.omit({
  timestampLastModified: true,
  timestampRecordClosed: true,
  timestampRecordCreated: true,
});

const craftRecordSchema_CreateForCustomer = withoutTimestampsSchema.extend({
  timestampLastModified: z.number(),
  timestampRecordClosed: z.number().nullable(),
  timestampRecordCreated: z.number(),
});
