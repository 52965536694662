//Libs
import EditIcon from "@mui/icons-material/Edit";
import {
  EmailSharp,
  PersonSharp,
  PhoneSharp,
  PinDropSharp,
} from "@mui/icons-material";

//Local
import * as strings from "../../strings";
import { BillingInfo } from "../../models/customer";
import { StyledTooltip } from "../StyledTooltip";
import { phoneUtils } from "../../utils/phoneUtils";

export function BillingInfoSection({
  billingInfo,
  onEditBillingAddress,
}: {
  billingInfo: BillingInfo;
  onEditBillingAddress?: () => void;
}) {
  return (
    <div className="mb-2 mr-2 pt-4 font-bold text-gray-800 md:pt-1">
      <div className="mb-2 flex flex-row justify-start">
        <div className="mr-4 text-xl font-bold text-primary">
          {strings.BILLING_INFO}
        </div>

        {onEditBillingAddress && (
          <StyledTooltip title="Edit Billing Info">
            <EditIcon
              aria-label="add customer contact"
              className="h-9 w-9 hover:text-primaryDark"
              onClick={onEditBillingAddress}
            />
          </StyledTooltip>
        )}
      </div>

      {billingInfo && (
        <ul className="space-y-1">
          <li className="flex flex-row items-start">
            <PersonSharp className="w-9 px-1" />
            {billingInfo.name}
          </li>
          <li className="flex flex-row items-start">
            <PhoneSharp className="w-9 px-1" />
            {billingInfo.phone ? phoneUtils.display(billingInfo.phone) : null}
          </li>
          <li className="flex flex-row items-start">
            <EmailSharp className="w-9 px-1" />
            {billingInfo.email}
          </li>
          <li className="flex flex-row items-start">
            <div>
              <PinDropSharp className="w-9 px-1" />
            </div>
            <div>
              <div className="flex flex-col">
                {billingInfo.addressLine1 ?? ""}
              </div>
              {billingInfo.addressLine2 !== "" && (
                <div className="flex flex-col">
                  {billingInfo.addressLine2 ?? ""}
                </div>
              )}
              <div className="flex flex-col">
                {billingInfo.city ?? ""} {billingInfo.state ?? ""}{" "}
                {billingInfo.zipCode ?? ""}
              </div>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
}
