// Libs
import { DocumentData, Timestamp } from "firebase/firestore";
import omitBy from "lodash/omitBy";
import isEqual from "lodash/isEqual";

// Local
import { ExistingTask } from "../../../models/task";
import { Json } from "../../../models/json-type";
import { TaskStatus } from "../../../models/task-status";
import { dropUndefined } from "../../../utils";
import { DbWrite } from "../../../database";
import { diffObjects } from "../../../assets/js/object-diff";

export async function updateTask(args: {
  originalTask: ExistingTask;
  updatedTaskData: Partial<ExistingTask>;
  originalTSD: Record<string, Json>;
  updatedTSD: Record<string, Json> | null;
  uid: string;

  // Funcs
  handleUpdateTaskStatus: (
    updateData: DocumentData,
    taskID: string,
  ) => Promise<void>;
}) {
  // Update task data
  const taskData = {
    ...args.updatedTaskData,
    timestampLastModified: Timestamp.now(),
    lastModifiedBy: args.uid,
  };
  // Need to ensure original taskSpecificDetails are put back because rules will
  // prevent any changes to these fields directly (we will call the CF right
  // after updating the task document)
  taskData.taskSpecificDetails = args.originalTSD;

  const { id, refPath, ...taskWithoutFirestoreFields } = taskData;
  // Remove undefined values
  const xtaskData = dropUndefined(taskWithoutFirestoreFields);

  // If status changed to awaiting, update the timestampAwaitingStart
  // TODO: Redundant? Can this be removed?
  if (
    xtaskData.taskStatus === TaskStatus.AWAITING &&
    args.originalTask.taskStatus !== TaskStatus.AWAITING
  ) {
    xtaskData.timestampAwaitingStart = Timestamp.now();
  }

  const xtaskUpdateData = diffObjects(args.originalTask, xtaskData).diff;

  // Update task doc
  await args.handleUpdateTaskStatus(xtaskUpdateData, args.originalTask.id);

  if (args.updatedTSD !== null) {
    const originalData = { ...args.originalTSD };
    const diffData = omitBy(args.updatedTSD, function (val, key) {
      return isEqual(val, originalData[key]);
    });

    // Then update taskSpecificDetails
    if (Object.keys(diffData).length > 0) {
      await DbWrite.taskSpecificDetails.update(
        args.originalTask.refPath,
        diffData,
      );
    }
  }
}
