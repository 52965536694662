//Libs
import { Dispatch, useState } from "react";

//Local
import BaseInputCheckbox from "../BaseInputCheckbox";
import {
  ExistingMembership,
  getReadableMembershipStatus,
} from "../../models/membership";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import { ExistingCraftRecord } from "../../models/craft-record";
import { StyledTooltip } from "../StyledTooltip";
import { getReadableTaskStatus } from "../../models/task-status";

interface Props {
  membershipsForCustomer: ExistingMembership[];
  selectedMembershipIDs: string[] | undefined;
  selectedMembershipIDsForRenewal: string[] | undefined;
  setMembershipIDs: Dispatch<React.SetStateAction<string[] | undefined>>;
  setMembershipIDsForRenewal:
    | null
    | ((memberhsipIDs: string[]) => Promise<void> | void);
}

export default function MembershipTaskLinkingSection({
  membershipsForCustomer,
  selectedMembershipIDs,
  selectedMembershipIDsForRenewal,
  setMembershipIDs,
  setMembershipIDsForRenewal,
}: Props) {
  const [showMoreEnabled, setShowMoreEnabled] = useState<boolean>(false);

  const activeMemberships = membershipsForCustomer.filter(
    (m) => m.status === "active",
  );
  const allOtherMemberships = membershipsForCustomer.filter(
    (m) => m.status !== "active",
  );

  /*
   * Returns an array of divs that represent the task breakdown for a membership.
   */
  function getMembershipTaskBreakdown(membership: ExistingMembership) {
    if (membership.tasks) {
      const sortedMembershipTasks = Object.values(membership.tasks).sort(
        (a, b) => a.timestampCreated.toMillis() - b.timestampCreated.toMillis(),
      );
      // return an array of divs that have text showing the task status and the scheduled date or created date (if schedule date is null)
      return sortedMembershipTasks.map((task) => {
        const taskStatus = getReadableTaskStatus(task.taskStatus);
        if (task.timestampTaskCompleted) {
          return (
            <div>
              {taskStatus}: (Completed On:{" "}
              {convertToReadableTimestampDate(task.timestampTaskCompleted)})
            </div>
          );
        } else if (task.timestampScheduled) {
          return (
            <div>
              {taskStatus}: (Scheduled For:{" "}
              {convertToReadableTimestampDate(task.timestampScheduled)})
            </div>
          );
        } else {
          return (
            <div>
              {taskStatus}: (Created On:{" "}
              {convertToReadableTimestampDate(task.timestampCreated)})
            </div>
          );
        }
      });
    }
  }

  function getIndividualMembershipComponent(membership: ExistingMembership) {
    return (
      <div
        key={membership.id}
        className="flex w-full flex-col justify-start gap-2"
      >
        <BaseInputCheckbox
          label={`${getReadableMembershipStatus(membership.status)}: ${convertToReadableTimestampDate(membership.membershipStartDate)} - ${convertToReadableTimestampDate(membership.membershipEndDate)}`}
          value={membership.id}
          defaultChecked={selectedMembershipIDs?.includes(membership.id)}
          onChange={(event) => {
            const checked = event.target.checked;
            if (checked) {
              // Create set of previous IDs and then add the new ID
              const newIDs = new Set(selectedMembershipIDs);
              setMembershipIDs((_prev) =>
                Array.from(newIDs.add(membership.id)),
              );
            } else {
              setMembershipIDs((_prev) =>
                selectedMembershipIDs?.filter((id) => id !== membership.id),
              );
            }
          }}
        />
        <div className="flex flex-col space-y-0 pl-12">
          {getMembershipTaskBreakdown(membership)}
        </div>
        {selectedMembershipIDs?.includes(membership.id) &&
          setMembershipIDsForRenewal && (
            // renew membership button
            <div className="pl-12">
              <StyledTooltip title="Checking this box will add the renewal item for this membership template to the estimate for this task.">
                <BaseInputCheckbox
                  label={`Add Renewal Estimate`}
                  value={membership.id}
                  defaultChecked={selectedMembershipIDsForRenewal?.includes(
                    membership.id,
                  )}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    if (checked) {
                      // Create set of previous IDs and then add the new ID
                      const newIDs = new Set(selectedMembershipIDsForRenewal);
                      setMembershipIDsForRenewal(
                        Array.from(newIDs.add(membership.id)),
                      );
                    } else {
                      setMembershipIDsForRenewal(
                        selectedMembershipIDsForRenewal?.filter(
                          (id) => id !== membership.id,
                        ) ?? [],
                      );
                    }
                  }}
                />
              </StyledTooltip>
            </div>
          )}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {activeMemberships.length === 0 && (
        <div>No active memberships found for this customer</div>
      )}
      {activeMemberships.map((membership) =>
        getIndividualMembershipComponent(membership),
      )}
      {allOtherMemberships.length > 0 && (
        <div
          className="cursor-pointer text-blue-500"
          onClick={() => setShowMoreEnabled(!showMoreEnabled)}
        >
          {showMoreEnabled ? "Show less" : "Show more"}
        </div>
      )}
      {showMoreEnabled &&
        allOtherMemberships.map((membership) =>
          getIndividualMembershipComponent(membership),
        )}
    </div>
  );
}
