//Libs
const axios_logo = new URL("./images/axios_logo.jpg", import.meta.url).href;
const new_stilt_logo = new URL("./images/new_stilt_logo.png", import.meta.url)
  .href;
const sunbelt_logo = new URL("./images/sunbelt_logo.png", import.meta.url).href;
const apache_logo = new URL("./images/apache_logo.png", import.meta.url).href;

interface WhiteLabelData {
  stilt: {
    pageTitle: string;
    logo: string;
  };
  aimpoint: {
    pageTitle: string;
    logo: string;
  };
  sunbelt: {
    pageTitle: string;
    logo: string;
  };
  apache: {
    pageTitle: string;
    logo: string;
  };
}

export const whiteLabelData: WhiteLabelData = {
  stilt: {
    pageTitle: "Stilt - Work Management",
    logo: new_stilt_logo,
  },
  aimpoint: {
    pageTitle: "AIMPOINT - Industrial Work Management",
    logo: axios_logo,
  },
  sunbelt: {
    pageTitle: "Sunbelt - Industrial Work Management",
    logo: sunbelt_logo,
  },
  apache: {
    pageTitle: "Apache - Industrial Work Management",
    logo: apache_logo,
  },
};
