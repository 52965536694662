//Libs
import { CameraIcon } from "@heroicons/react/24/solid";
import { ChangeEvent, useEffect, useState } from "react";

//Local
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";

interface UserPhotoProps {
  isDialogOpen: boolean;
  closeDialog: () => void;
  onEditUserPhoto: (
    file: File,
    changeFileInformation: (value: string) => void,
    changeIsUploading: (value: boolean) => void,
  ) => void;
}

export default function UserPhotoDialog(props: UserPhotoProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(null);
      return;
    }
    const objectURL = URL.createObjectURL(selectedFile);
    setPreview(objectURL);

    //Free memory when component is unmounted
    return () => URL.revokeObjectURL(objectURL);
  }, [selectedFile]);

  //show an error to the user if no file is selected or if the file select surpasses the size limit
  const [responseToUserError, setResponseToUserError] = useState(false);

  //for setting the UI text
  const [fileInformation, setFileInformation] = useState("No file chosen");

  //For setting 'isBusy' on the submit button
  const [isUploading, setIsUploading] = useState(false);

  function onCloseDialog() {
    props.closeDialog();
    setSelectedFile(null);
    setResponseToUserError(false);
    setFileInformation("No file chosen");
  }

  function handleFileSelected(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length === 1) {
      const chosenFile = event.target.files[0];
      //If file is larger than 10MB
      if (chosenFile.size >= 10000000) {
        setResponseToUserError(true);
        setFileInformation("Upload a file under 10MB.");
        return;
      }

      //accepted file types: png, jpeg, jpg
      if (
        chosenFile.type !== "image/png" &&
        chosenFile.type !== "image/jpeg" &&
        chosenFile.type !== "image/jpg"
      ) {
        setResponseToUserError(true);
        setFileInformation("Accepted file types: PNG, JPEG, JPG.");
        return;
      }

      //set file - ready to be sent to submit handlet fn
      setSelectedFile(chosenFile);

      //UI will display the chosen file name
      setFileInformation(chosenFile.name);

      //Reset the response to user error
      if (responseToUserError) setResponseToUserError(false);
    }
  }

  function handleSubmitUserPhoto() {
    if (selectedFile !== null) {
      setIsUploading(true);
      props.onEditUserPhoto(selectedFile, setFileInformation, setIsUploading);

      //Reset preview & file state
      setPreview(null);
      setSelectedFile(null);
    } else {
      setFileInformation("No file chosen");
      setResponseToUserError(true);
    }
  }

  return (
    <BaseModal
      open={props.isDialogOpen}
      closeModal={onCloseDialog}
      title={
        <div className="relative bg-primary px-4 py-2 text-xl font-light text-white">
          User Photo
        </div>
      }
      parentDivStyles="text-left max-w-sm sm:w-96"
    >
      <div className="flex flex-col justify-between px-4 pb-4 text-base">
        <div className="my-3 flex flex-col items-center">
          <label
            aria-label="Tap to add a photo"
            htmlFor="img-upload"
            className="inline-block w-52 cursor-pointer rounded border-4 border-dotted border-transparent bg-white p-4 text-center text-sm text-gray-500 outline-none transition hover:border-gray-200 hover:bg-gray-50 focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
          >
            <CameraIcon
              aria-label="tap to add a photo"
              className="m-auto flex w-9 text-gray-900"
            />
            Tap to add a photo
          </label>
          <input
            id="img-upload"
            type="file"
            onChange={(ev) => handleFileSelected(ev)}
            accept="image/jpg, image/jpeg, image/png"
            className="hidden"
          />
          <span
            className={`mt-3 w-56 rounded px-3 py-1.5 text-center text-sm text-gray-700 sm:w-80 ${
              responseToUserError ? "bg-red-50" : ""
            }`}
          >
            <span
              className={
                responseToUserError ? "text-base font-medium text-red-800" : ""
              }
            >
              {fileInformation}
            </span>
          </span>
          {/* If preview & selectedFile are not null, display image */}
          {selectedFile && preview && (
            <img
              src={preview}
              alt=""
              className="mt-3 h-40 w-40 rounded-full object-cover"
            />
          )}
        </div>

        {/* buttons */}
        <div className="mt-4 flex w-full items-center justify-between gap-4">
          <BaseButtonSecondary
            className="w-full justify-center uppercase"
            onClick={onCloseDialog}
          >
            {strings.buttons.CLOSE}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            className="w-full justify-center uppercase"
            onClick={handleSubmitUserPhoto}
            isBusy={isUploading}
            busyText={strings.buttons.BUSY_UPLOADING}
          >
            {strings.buttons.SUBMIT}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
