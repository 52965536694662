// Libs
import { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

// Local
import { DbRead } from "../database";
import { ExistingCraftRecord } from "../models/craft-record";
import ListTemplatesPage from "./ListTemplatesPage";
import { logger as devLogger } from "../logging";
import { useUserPermissionsStore } from "../store/user-permissions";
import { NEW_TEMPLATE_URL, TEMPLATES_URL } from "../urls";

interface Props {
  siteKey: string;
}

export default function ListTemplatesContainer({ siteKey, ...props }: Props) {
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const navigate = useNavigate();

  function handleAddNewClick() {
    navigate(NEW_TEMPLATE_URL);
  }

  function handleViewItems(checklistID: string) {
    navigate(`${TEMPLATES_URL}/${checklistID}`);
  }
  const [checklistsList, setChecklistsList] = useState<ExistingCraftRecord[]>(
    [],
  );

  useLayoutEffect(() => {
    // It is possible for userPermissions to be null until data is retrieved from the database.
    if (userPermissions == null) {
      devLogger.debug("No user permissions yet.");
      return;
    }
    // Real-time version
    const unsubscribe = DbRead.checklist.subscribeAllSortedTSLastModified({
      siteKey: siteKey,
      permissions: userPermissions,
      onChange: setChecklistsList,
      onError: (error) => devLogger.error(error),
    });
    // A function returned by the useEffect hook will be called to clean up when
    // the component unmounts or before the effect is called again.
    return unsubscribe;
  }, [siteKey, userPermissions]);

  return (
    <ListTemplatesPage
      checklistList={checklistsList}
      onAddNewClick={handleAddNewClick}
      onViewItems={handleViewItems}
    />
  );
}
