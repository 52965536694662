//Libs
import { z } from "zod";

//Local
import { dropUndefined, guardIsPlainObject } from "../utils";
import { NotFoundError } from "../error-classes";
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";

export interface Zone {
  name: string;
  element: "dot" | "background";
  property: "zipCode" | "title-contains";
  values: string[];
  color: string;

  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
}

export interface ExistingZone extends Zone {
  id: string;
  refPath: string;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  Zone,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface Zone_CreateAPI extends withoutTimestamps {
  siteKey: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type Zone_UpdateAPI = Omit<
  Partial<ExistingZone>,
  "timestampCreated" | "timestampLastModified"
>;

export const ZoneManager = {
  /** Drop `id` and `refPath` before saving to the database. Drop undefined */
  convertForFirestore: convertZoneForFirestore,
  createFromFirestoreSnapshot: createFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateZone,
  /** Validate a vehicle. For the create endpoint. */
  parseCreate: validateZone_Create,
  /** Validate an existing vehicle. For the update endpoint. */
  parseUpdate: validateZone_Update,
} as const;

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertZoneForFirestore(
  vehicle: ExistingZone | Zone_UpdateAPI,
): DocumentData {
  const local = Object.assign({}, vehicle);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;

  const result = dropUndefined(rest);
  return { ...result };
}

function createFromFirestoreSnapshot(snapshot: DocumentSnapshot): ExistingZone {
  if (!snapshot.exists) {
    throw new NotFoundError("Document does not exist.");
  }

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateZone(snapshot.data()),
  };
}

/* Zod validation schemas */
function validateZone(value: unknown): Zone {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = vehicleSchema.parse(value);
  return result;
}

function validateZone_Create(value: unknown): Zone_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return vehicleSchema_CreateAPI.parse(value);
}

function validateZone_Update(value: unknown): Zone_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return vehicleSchema_UpdateAPI.parse(value);
}

const vehicleSchema = z.object({
  name: z.string().min(1).max(200),
  element: z.enum(["dot", "background"]),
  property: z.enum(["zipCode", "title-contains"]),
  values: z.array(z.string().min(1).max(200)),
  color: z.string().min(1).max(200),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string(),
  lastModifiedBy: z.string(),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = vehicleSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const vehicleSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const vehicleSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();
// #endregion
