// Libs
import { useCallback, useLayoutEffect, useState } from "react";

// Local
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import CreateTaskCustomerTablePage from "./CreateTaskCustomerTablePage";
import {
  ExistingCustomer,
  ExistingCustomerWithLocations,
} from "../../models/customer";
import { useTypesenseStore } from "../../store/typesense";
import { typesenseCustomersQuery } from "../../utils/typesenseQueries";
import { useMembershipTemplatesStore } from "../../store/membership-templates";

// TODO: eventual refactor => remove this container, use a variation
// of CustomerListContainer. documented in notion card =>
// https://www.notion.so/monomersoftware/refactor-get-rid-of-create-task-s-customer-table-5f5f4bd52bf54b9799f15bd1047dc1b2?pvs=4

export default function CreateTaskCustomerTableContainer(props: {
  siteKey: string;
  onSelectCustomer: (customerDoc: ExistingCustomer) => void;
  addCustomerButton: React.ReactNode;
}): JSX.Element {
  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);
  const [customers, setCustomersFromTypesense] = useState<
    ExistingCustomerWithLocations[]
  >([]);
  const [membershipTemplateList, isLoadingMembershipTemplateList] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);

  useLayoutEffect(() => {
    async function getCustomers() {
      if (!typesenseSearchKey) return;
      const customers = await typesenseCustomersQuery(typesenseSearchKey, "");
      setCustomersFromTypesense(customers);
    }

    getCustomers();
  }, [typesenseSearchKey]);

  const onFilterTextBoxChanged = useCallback(async () => {
    if (!typesenseSearchKey) return;
    const searchTerm = (
      document.getElementById("search-customer") as HTMLInputElement
    ).value;

    const customers = await typesenseCustomersQuery(
      typesenseSearchKey,
      searchTerm,
    );
    setCustomersFromTypesense(customers);
  }, [typesenseSearchKey]);

  if (typesenseLoading || isLoadingMembershipTemplateList) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <CreateTaskCustomerTablePage
      customersWithLocations={customers}
      onSelectCustomer={props.onSelectCustomer}
      onSearch={onFilterTextBoxChanged}
      addCustomerButton={props.addCustomerButton}
    />
  );
}
