// Libs
import { z } from "zod";
import { DocumentSnapshot, DocumentData } from "firebase/firestore";

// Local
import { dropUndefined, guardIsPlainObject } from "../utils";
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";

// #region SECTION: Schemas / Interfaces / Types
/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
export const SiteKeyLocationSchema = z.object({
  department: z.string().max(200),
  latitude: z.number(),
  longitude: z.number(),
  title: z.string().min(1).max(200),
  invoiceTitle: z.string().min(0).max(500).optional(),
  invoiceHeader: z.string().min(0).max(1000).optional(),
  invoiceMessage: z.string().min(0).max(1000).optional(),
  invoiceSignatureText: z.string().min(0).max(20000).optional(),
  estimateSignatureText: z.string().min(0).max(20000).optional(),
  workAuthorizationSignatureText: z.string().min(0).max(20000).optional(),
  receiptEmailAdditionalText: z.string().min(0).max(20000).optional(),
  invoiceEmailAdditionalText: z.string().min(0).max(20000).optional(),
  inRouteSMSText: z.string().min(0).max(2000).optional(),
  sendPhotoWithInRouteText: z.boolean().optional(),
  email: z.string().min(0).max(200).optional(),
  phone: z.string().min(0).max(200).optional(),
});

/** For reading from the database */
const SiteKeyLocationSchemaWithFallbacks = z.object({
  department: zFallback(
    z.string().max(200),
    "unknown",
    "SiteKeyLocationSchemaWithFallbacks: 'department'",
  ),
  latitude: z.number(),
  longitude: z.number(),
  title: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SiteKeyLocationSchemaWithFallbacks: 'title'",
  ),
  invoiceTitle: z.string().min(0).max(500).optional(),
  invoiceHeader: z.string().min(0).max(1000).optional(),
  invoiceMessage: z.string().min(0).max(1000).optional(),
  invoiceSignatureText: z.string().min(0).max(20000).optional(),
  estimateSignatureText: z.string().min(0).max(20000).optional(),
  workAuthorizationSignatureText: z.string().min(0).max(20000).optional(),
  receiptEmailAdditionalText: z.string().min(0).max(20000).optional(),
  invoiceEmailAdditionalText: z.string().min(0).max(20000).optional(),
  inRouteSMSText: z.string().min(0).max(20000).optional(),
  sendPhotoWithInRouteText: z.boolean().optional(),
  email: z.string().min(0).max(200).optional(),
  phone: z.string().min(0).max(200).optional(),
});

export type SiteKeyLocation = z.infer<typeof SiteKeyLocationSchema>;

// Separate interfaces for 'new' and existing
export interface ExistingSiteKeyLocation extends SiteKeyLocation {
  id: string;
  refPath: string;
}

const EditSiteKeyLocationSchema = SiteKeyLocationSchema.partial();
export type EditSiteKeyLocationState = z.infer<
  typeof EditSiteKeyLocationSchema
>;
// #endregion

// #region SECTION: Functions
/** Utilities for interacting with SiteKeyLocation objects. */
export const SiteKeyLocationManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingSiteKeyLocation object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createFromSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  convertForFirestore: convertForFirestore,
  /** Validate a SiteKeyLocation doc. Use for writing to the database or reading from the user. */
  parse: validateSiteKeyLocation,
  /** Validate a SiteKeyLocation doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateSiteKeyLocationWithFallbacks,
  /** Validation. Use for editing a SiteKeyLocation doc. No fallbacks will be used. */
  parseEdit: validateEditSiteKeyLocation,
};

/**
 * Convert the Document Snapshot into a validated ExistingSiteKeyLocation object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingSiteKeyLocation {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateSiteKeyLocationWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertForFirestore(location: ExistingSiteKeyLocation): DocumentData {
  // Copy before modifying. Don't want to modify original object.
  const local = Object.assign({}, location);

  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/** For validating stuff coming in from the database. */
function validateSiteKeyLocationWithFallbacks(
  siteKeyLocation: unknown,
): SiteKeyLocation {
  if (!guardIsPlainObject(siteKeyLocation)) {
    throw new Error(`SiteKeyLocation is not an object: ${siteKeyLocation}`);
  }

  return SiteKeyLocationSchemaWithFallbacks.parse(siteKeyLocation);
}

function validateSiteKeyLocation(siteKeyLocation: unknown): SiteKeyLocation {
  if (!guardIsPlainObject(siteKeyLocation)) {
    throw new Error(`SiteKeyLocationDoc is not an object: ${siteKeyLocation}`);
  }

  return SiteKeyLocationSchema.parse(siteKeyLocation);
}

function validateEditSiteKeyLocation(value: unknown): EditSiteKeyLocationState {
  if (!guardIsPlainObject(value)) {
    throw new Error(`EditSiteKeyLocationDoc is not an object ${value}`);
  }
  const result = EditSiteKeyLocationSchema.parse(value);
  return result;
}

// #endregion
