// Libs
import { Navigate, useLocation } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Local
import { useAuthStore } from "../store/firebase-auth";
import LoadingClipboardAnimation from "./LoadingClipBoardAnimation";
import { LOGIN_URL } from "../urls";
import { useUserPermissionsStore } from "../store/user-permissions";
import PermissionDenied from "../Pages/PermissionDenied";

interface Props {
  defaultSiteKey: string | undefined;
  children: JSX.Element;
}

/**
 * Redirect to the login page if there is not authenticated firebase user.
 * Return Permission Denied page if the authenticated user is not a site admin.
 * Otherwise render children.
 */
export const RouteAdminPermission = (props: Props): JSX.Element => {
  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const userPermissionsDoc = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [loadingUser, setLoadingUser] = useState(true);

  useLayoutEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoadingUser(false);
      } else {
        setLoadingUser(false);
      }
    });
    return unsubscribe;
  }, [firebaseUser]);

  const location = useLocation();

  if (loadingUser === false && firebaseUser === null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={LOGIN_URL} state={{ from: location }} replace />;
  } else if (
    loadingUser === true &&
    firebaseUser === null &&
    props.defaultSiteKey == null &&
    userPermissionsDoc == null
  ) {
    return (
      <div className="flex h-full flex-auto flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  } else if (
    props.defaultSiteKey == null &&
    loadingUser === false &&
    firebaseUser != null &&
    userPermissionsDoc != null
  ) {
    //if defaultSiteKey is still undefined after userDoc is loaded, return a blank page
    return <></>;
  } else if (
    props.defaultSiteKey != null &&
    loadingUser === false &&
    firebaseUser != null &&
    userPermissionsDoc?.permissions.isSiteAdmin === false
  ) {
    //if the user is not a site admin, return access denied page
    return <PermissionDenied path={location.pathname} />;
  } else {
    return props.children;
  }
};
