// Libs
import { Timestamp } from "firebase/firestore";
import { z } from "zod";
// Local
import { guardIsPlainObject } from "../utils";

export const platformList = ["iOS", "android", "web", "email", "pdf"] as const;
export type Platform = (typeof platformList)[number];

// -------------------------
// SECTION: CREATE various unique link objects

export interface CreateEstimateUniqueLink {
  siteKey: string;
  estimateDocumentID: string;
  whiteLabelURL: string | null;
  linkExpires: string | null;
  email: string | null;
  phone: string | null;
}

export interface CreatePaymentUniqueLink {
  siteKey: string;
  invoiceDocumentID: string;
  whiteLabelURL: string | null;
  linkExpires: string | null;
  email: string | null;
  phone: string | null;
  version: string;
  platform: Platform;
}

export interface CreateMultiPaymentUniqueLink {
  siteKey: string;
  invoiceDocumentIDs: string[];
  whiteLabelURL: string | null;
  linkExpires: string | null;
  email: string | null;
  phone: string | null;
  version: string;
  platform: Platform;
}

/**
 * Utility functions for validating various unique link objects,
 * used when creating a uniqueLink or uniqueLinkMulti document.
 */
export const CreateUniqueLinkManager = {
  // NOT CURRENTLY IN USE
  // parseEstimate: validateCreateEstimateUniqueLink,
  parsePayment: validateCreatePaymentUniqueLink,
  parseMultiPayment: validateCreateMultiPaymentUniqueLink,
};

/* NOT CURRENTLY IN USE
function validateCreateEstimateUniqueLink(
  doc: unknown,
): CreateEstimateUniqueLink {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`validateCreateEstimateUniqueLink expected object: ${doc}`);
  }
  return createEstimateSchema.parse(doc);
}
const createEstimateSchema: z.ZodType<CreateEstimateUniqueLink> = z.object({
  siteKey: z.string().min(1).max(200),
  estimateDocumentID: z.string().min(1).max(200),
  whiteLabelURL: z.string().nullable(),
  linkExpires: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
});
*/

function validateCreatePaymentUniqueLink(
  doc: unknown,
): CreatePaymentUniqueLink {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`validateCreatePaymentUniqueLink expected object: ${doc}`);
  }
  return createPaymentSchema.parse(doc);
}
const createPaymentSchema: z.ZodType<CreatePaymentUniqueLink> = z.object({
  siteKey: z.string().min(1).max(200),
  invoiceDocumentID: z.string().min(1).max(200),
  whiteLabelURL: z.string().nullable(),
  linkExpires: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  version: z.string(),
  platform: z.enum(platformList),
});

function validateCreateMultiPaymentUniqueLink(
  doc: unknown,
): CreateMultiPaymentUniqueLink {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`validateCreateEstimateUniqueLink expected object: ${doc}`);
  }
  return createMultiPaymentSchema.parse(doc);
}
const createMultiPaymentSchema: z.ZodType<CreateMultiPaymentUniqueLink> =
  z.object({
    siteKey: z.string().min(1).max(200),
    invoiceDocumentIDs: z.array(z.string().min(1).max(200)),
    whiteLabelURL: z.string().nullable(),
    linkExpires: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    version: z.string(),
    platform: z.enum(platformList),
  });

// SECTION: Actual database structure - UniqueLink and UniqueLinkMulti
// Note -- not needed on the frontend... here for reference
type FirestoreAttributes = {
  id: string;
  refPath: string;
};

type UniqueLink = {
  /** Foreign key -- invoice/estimate/feedback/etc doc ID */
  documentID: string;
  siteKey: string;
  email: string | null;
  linkExpires: Timestamp | null;
  timestampCreated: Timestamp;
  whiteLabel?: string;
  docType?: string;
  uid?: string;
  platform?: Platform;
};

/** lives @ uniqueLinks/uniqueLinks/[docType]/{documentID} */
export type ExistingUniqueLink = UniqueLink & FirestoreAttributes;

type UniqueLinkMulti = {
  /** Foreign key list */
  documentIDs: string[];
  siteKey: string;
  email: string | null;
  linkExpires: Timestamp | null;
  timestampCreated: Timestamp;
  uid?: string;
  platform?: Platform;
};

/** lives @ uniqueLinks/uniqueLinks/multiPayments/{documentID} */
export type ExistingUniqueLinkMulti = UniqueLinkMulti & FirestoreAttributes;

// const uniqueLinkSchema: z.ZodType<UniqueLink> = z.object({
//   documentID: z.string().min(1).max(200),
//   siteKey: z.string().min(1).max(200),
//   email: z.string().min(1).max(200).nullable(),
//   linkExpires: z.instanceof(Timestamp).nullable(),
//   timestampCreated: z.instanceof(Timestamp),
//   whiteLabel: z.string().optional(),
//   docType: z.string().optional(),
//   uid: z.string().min(1).max(200).optional(),
//   platform: z.enum(platformList).optional(),
// });

// const uniqueLinkMultiSchema: z.ZodType<UniqueLinkMulti> = z.object({
//   documentIDs: z.array(z.string().min(1).max(200)),
//   siteKey: z.string().min(1).max(200),
//   email: z.string().min(1).max(200).nullable(),
//   linkExpires: z.instanceof(Timestamp).nullable(),
//   timestampCreated: z.instanceof(Timestamp),
//   uid: z.string().min(1).max(200).optional(),
//   platform: z.enum(platformList).optional(),
// });
