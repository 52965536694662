//Libs
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

//Local

interface Props {
  activeStep: number;
  steps: number[];
}

export default function HorizontalLinearStepper(props: Props) {
  return (
    <div className="">
      <Stepper activeStep={props.activeStep}>
        {props.steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                StepIconProps={{ style: { color: "var(--primary)" } }}
              />
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
