//Libs
import React, { useRef, useState } from "react";

//Local
import BaseButtonSecondary from "../BaseButtonSecondary";
import { CustomerTypes } from "../../models/customer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import * as strings from "../../strings";
import { StyledTooltip } from "../StyledTooltip";

interface Props {
  customerTypeSelected: (customerType: CustomerTypes | null) => void;
  isLoading: boolean;
}

export default function CustomerSchedulesDropdown(props: Props) {
  const dropdown = useRef(null);
  const [isOpen, setOpen] = useState(false);

  function handleResidential() {
    props.customerTypeSelected("residential");
  }

  function handleCommercial() {
    props.customerTypeSelected("commercial");
  }

  function handleAll() {
    props.customerTypeSelected(null);
  }

  return (
    <StyledTooltip title={strings.TOOLTIP_GENERATE_SCHEDULES}>
      <div className="dropdown">
        <BaseButtonSecondary
          onClick={() => setOpen(!isOpen)}
          ref={dropdown}
          isBusy={props.isLoading}
          onBlur={() => setOpen(false)}
          className="w-full"
        >
          <div className="w-full text-left">
            {strings.buttons.CUSTOMER_SCHEDULES}
          </div>
          <PictureAsPdfIcon
            aria-label="payment link button"
            style={{
              display: "block",
              height: "38px",
              width: "38px",
              cursor: "pointer",
              padding: "6px",
            }}
          />
          <svg
            className="ml-2 h-4"
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </BaseButtonSecondary>

        <div
          id="dropdown"
          className={`relative z-10 w-44 divide-y divide-gray-100 rounded-md bg-white shadow ${
            isOpen ? "over" : "hidden"
          }`}
        >
          <ul className="absolute z-10 w-44 divide-y divide-gray-100 rounded-md bg-white shadow ">
            <li>
              <a
                key="i"
                onMouseDown={(e) => e.preventDefault()}
                onClick={handleResidential}
                className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
              >
                Residential
              </a>
            </li>
            <li>
              <a
                key="i"
                onMouseDown={(e) => e.preventDefault()}
                onClick={handleCommercial}
                className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
              >
                Commercial
              </a>
            </li>
            <li>
              <a
                key="i"
                onMouseDown={(e) => e.preventDefault()}
                onClick={handleAll}
                className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
              >
                All
              </a>
            </li>
          </ul>
        </div>
      </div>
    </StyledTooltip>
  );
}
