// Libs
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaDollarSign } from "react-icons/fa";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { CardGiftcard, CreditCard } from "@mui/icons-material";
import { Input } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

// Local
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import NotFound404 from "../NotFound404";
import {
  ExistingStiltPayment,
  StiltPaymentFormData,
} from "../../models/stilt-payment";
import PaymentsPage from "./PaymentsPage";
import InvoiceSummary from "../../components/Payments/InvoiceSummary";
import BaseModal from "../../components/BaseModal";
import * as strings from "../../strings";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { useAuthStore } from "../../store/firebase-auth";
import { createToastMessageID } from "../../utils";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import LoadingSpinner from "../../components/LoadingSpinner";
import MultipleEmailSelector from "../../components/MultipleEmailSelector";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ErrorMessage } from "../../components/ErrorMessage";
import { ExistingStiltInvoice } from "../../models/invoice";
import { ExistingCustomer } from "../../models/customer";
import { getEmailList } from "../../utils/getEmailList";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";

/** For the unauthenticated end-customer */
export default function PaymentsContainer(): JSX.Element {
  // Extract payment doc ID from the URL
  // (payment doc ID = uniqueLinks/uniqueLinks/invoices/invoiceID)
  type UrlParams = { id: string };
  const data = useParams<UrlParams>();
  const id = data.id;
  if (typeof id !== "string") {
    throw new Error(`id was not a string: ${id}`);
  }

  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  // null is leveraged for the loading state. undefined for showing a 404.
  const [paymentData, setPaymentData] = useState<
    StiltPaymentFormData | null | undefined
  >(null);

  const [userIsAuthedSiteAdmin, setUserIsAuthedSiteAdmin] = useState(false);
  const [authedPayments, setAuthedPayments] = useState<
    ExistingStiltPayment[] | null
  >(null);
  const [displayNamesMap, setDisplayNamesMap] = useState<
    Record<string, string>
  >({});
  const [invoice, setInvoice] = useState<ExistingStiltInvoice | null>(null);
  const [customer, setCustomer] = useState<ExistingCustomer | null>(null);

  const [hostedPaymentPageDialogOpen, setHostedPaymentPageDialogOpen] =
    useState(false);
  const [tipAmount, setTipAmount] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [showPartialPaymentFields, setShowPartialPaymentFields] =
    useState(false);

  const [includeJobPhotos, setIncludeJobPhotos] = useState(false);
  const [selectedEmailList, setSelectedEmailList] = useState<string[]>([]);
  const [errorString, setErrorString] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState<string | null>(null);

  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  useEffect(() => {
    async function getData() {
      if (typeof id !== "string") {
        throw new Error(`id was not a string: ${id}`);
      }
      try {
        const data = await DbRead.payments.get(id);
        setPaymentData(data);
      } catch (_) {
        setPaymentData(undefined);
      }
    }

    getData();
  }, [id]);

  useEffect(() => {
    if (paymentData) setAmountDue(paymentData.amountDue);
  }, [paymentData]);

  useEffect(() => {
    if (paymentData) setAmountDue(tipAmount + paymentData.amountDue);
  }, [tipAmount, paymentData]);

  useEffect(() => {
    if (paymentData && paymentData.email) {
      setSelectedEmailList([paymentData.email]);
    }
  }, [paymentData]);

  useEffect(() => {
    setPaymentAmount(amountDue.toString());
  }, [amountDue]);

  // #region To determine if we should display the authenticated invoice version,
  // and to set up that data.
  useEffect(() => {
    async function fetchPermissions() {
      if (!firebaseUser || !siteKeyDoc) return;
      const permissions = await DbRead.user.getSiteKeyPermissions(
        siteKeyDoc.id,
        firebaseUser.uid,
      );

      if (
        permissions.approved === true &&
        permissions.permissions.isSiteAdmin === true
      ) {
        setUserIsAuthedSiteAdmin(true);
      }
    }
    fetchPermissions();
  }, [firebaseUser, siteKeyDoc]);

  useEffect(() => {
    async function fetchStiltPayments() {
      if (userIsAuthedSiteAdmin !== true) return;
      if (!siteKeyDoc) return;
      if (!paymentData || !paymentData.invoiceNumber) return;

      const invoices = await DbRead.invoices.getByInvoiceNumber(
        siteKeyDoc.id,
        paymentData.invoiceNumber,
      );
      if (invoices.length === 0) {
        logger.error(
          `No invoices found with invoiceNumber ${paymentData.invoiceNumber}. Falling back to displaying unauthed version of PaymentsContainer.`,
        );
        return;
      }
      if (invoices.length > 1) {
        logger.error(
          `More than one invoice found with invoiceNumber ${paymentData.invoiceNumber}. Falling back to displaying unauthed version of PaymentsContainer.`,
        );
        return;
      }
      setInvoice(invoices[0]);

      const existingPayments = await DbRead.payments.getAllByInvoiceID(
        siteKeyDoc.id,
        invoices[0].id,
      );
      setAuthedPayments(existingPayments);
    }
    fetchStiltPayments();
  }, [paymentData, siteKeyDoc, userIsAuthedSiteAdmin]);

  useEffect(() => {
    async function fetchCustomer() {
      if (userIsAuthedSiteAdmin !== true) return;
      if (!siteKeyDoc) return;
      if (!invoice) return;

      const customer = await DbRead.customers.get(
        siteKeyDoc.id,
        invoice.customerID,
      );
      setCustomer(customer);
    }
    fetchCustomer();
  }, [invoice, siteKeyDoc, userIsAuthedSiteAdmin]);

  useEffect(() => {
    async function fetchUserDisplayNamesMap() {
      if (userIsAuthedSiteAdmin !== true) return;
      if (!siteKeyDoc) return;
      if (!authedPayments || authedPayments.length === 0) return;

      const namesMap = await DbRead.aggregates.getUserDisplayNames(
        siteKeyDoc.id,
      );
      setDisplayNamesMap(namesMap);
    }
    fetchUserDisplayNamesMap();
  }, [authedPayments, siteKeyDoc, userIsAuthedSiteAdmin]);
  // #endregion

  // Initial value of payment data is null.
  if (paymentData === null) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  // Being explicit and using triple equals is important here.
  if (paymentData === undefined) {
    return <NotFound404 />;
  }

  async function emailPaymentLinkToCustomer(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!siteKeyDoc) return;

    if (email.length === 0 || !email) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      const url = window.location.href;
      // if url has a ?, split it and only keep the first part before the ?
      const urlParts = url.split("?");
      const invoiceURL = urlParts[0];

      await DbWrite.invoices.sendViaEmail({
        siteKey: siteKeyDoc.id as string,
        // current URL of the browser
        invoiceURL: invoiceURL,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
      logger.error("error emailing customer invoice", e);
    }
  }

  const displayEmailError = errorString && (
    <span className="mx-auto mb-4 block w-fit max-w-sm">
      <ErrorMessage
        message={errorString}
        clearMessage={() => setErrorString(null)}
      />
    </span>
  );

  const payaHPPDialogHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.PAY_NOW}
      </h1>
      <button
        type="button"
        onClick={() => {
          setHostedPaymentPageDialogOpen(false);
        }}
      >
        <XMarkIcon className="h-6 text-white" />
      </button>
    </div>
  );

  const iFrame = paymentData.amountDue > 0 && (
    <BaseModal
      closeModal={() => {
        setHostedPaymentPageDialogOpen(false);
      }}
      open={hostedPaymentPageDialogOpen}
      title={payaHPPDialogHeader}
      parentDivStyles="inline-block max-w-6xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <iframe
        title="pay invoice"
        src={paymentData.paymentURL}
        className="w-full"
        height={window.innerHeight - 40}
      ></iframe>
    </BaseModal>
  );

  const tipsSection = (
    <div className=" my-10 w-auto flex-col rounded-2xl bg-blue-50 px-4 py-4">
      <h2 className="text-xl font-semibold">
        <span className="capitalize">Add a Tip:</span>
      </h2>
      <span className="text-gray-500">
        Tip is calculated on the total amount before taxes
      </span>
      <hr className="mb-4 mt-2 block w-full border border-gray-300" />
      <div className="inline-flex w-full rounded-md shadow-sm" role="group">
        <button
          type="button"
          onClick={() => {
            setTipAmount(
              Math.round((paymentData?.amountDue ?? 0) * 0.1 * 100) / 100,
            );
          }}
          className="w-full rounded-s-lg border border-gray-200 bg-white px-4 py-2 text-lg text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:bg-blue-600 focus:text-white"
        >
          10%
        </button>
        <button
          onClick={() => {
            setTipAmount(
              Math.round((paymentData?.amountDue ?? 0) * 0.15 * 100) / 100,
            );
          }}
          type="button"
          className="w-full border-b border-t border-gray-200 bg-white px-4 py-2 text-lg text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:bg-blue-600 focus:text-white"
        >
          15%
        </button>
        <button
          onClick={() => {
            setTipAmount(
              Math.round((paymentData?.amountDue ?? 0) * 0.2 * 100) / 100,
            );
          }}
          type="button"
          className="w-full border-b border-t border-gray-200 bg-white px-4 py-2 text-lg text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:bg-blue-600 focus:text-white"
        >
          20%
        </button>
        <button
          onClick={() => {
            setTipAmount(0);
          }}
          type="button"
          className="w-full rounded-e-lg border border-gray-200 bg-white px-4 py-2 text-lg text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:bg-blue-600 focus:text-white"
        >
          Other
        </button>
      </div>
      <div className="mb-2 mt-4 flex items-center">
        <CardGiftcard className="mx-2"></CardGiftcard>
        <Input
          // admin={false}
          // text="Custom Tip Amount..."
          className="my-1 w-full bg-white px-1 py-1"
          placeholder="Custom amount..."
          value={tipAmount}
          onChange={(event) => {
            setTipAmount(Number(event.target.value));
          }}
        ></Input>
      </div>
    </div>
  );

  let subTotalWithoutGlobalDiscount = 0;
  let allDiscountsApplied = 0;
  paymentData.lineItems.forEach((i) => {
    subTotalWithoutGlobalDiscount += i.subTotal;
    // Add in line discounts
    allDiscountsApplied += i.quantity * i.unitPrice - i.subTotal;
  });
  allDiscountsApplied += subTotalWithoutGlobalDiscount - paymentData.subTotal;

  /** For users that aren't logged in + site admin. */
  const invoiceSummary = (
    <InvoiceSummary
      currency={paymentData.currency}
      timezone={siteKeyDoc?.timezone ?? "America/New_York"}
      items={paymentData.lineItems}
      totalTaxAmount={paymentData.totalTaxAmount}
      totalTaxAmountPST={paymentData.totalTaxAmountPST}
      totalTaxAmountGST={paymentData.totalTaxAmountGST}
      subTotalWithoutGlobalDiscount={subTotalWithoutGlobalDiscount}
      subTotalWithGlobalDiscount={paymentData.subTotal}
      totalAmount={paymentData.totalAmount}
      tipAmount={tipAmount}
      amountDue={amountDue}
      allDiscountsApplied={allDiscountsApplied}
      note={paymentData.note}
      merchantName={paymentData.merchantName}
      discount={paymentData.discount}
      paymentsMade={paymentData.paymentsMade}
      shouldShowAuthedVersion={false}
      tipsEnabled={paymentData.tipsEnabled}
      children={{ tipsSection }}
      totalTaxRate={0}
    />
  );

  /** non-zero length */
  const hasUserDisplayNamesMap = Object.keys(displayNamesMap).length > 0;
  const invoiceSummaryADMIN = userIsAuthedSiteAdmin === true &&
    authedPayments !== null &&
    hasUserDisplayNamesMap && (
      <InvoiceSummary
        shouldShowAuthedVersion={userIsAuthedSiteAdmin}
        handlePayment={null}
        handleRefund={null}
        handleDeletePayment={null}
        currency={paymentData.currency}
        timezone={siteKeyDoc?.timezone ?? "America/New_York"}
        items={paymentData.lineItems}
        totalTaxAmount={paymentData.totalTaxAmount}
        totalTaxAmountPST={paymentData.totalTaxAmountPST}
        totalTaxAmountGST={paymentData.totalTaxAmountGST}
        subTotalWithoutGlobalDiscount={subTotalWithoutGlobalDiscount}
        subTotalWithGlobalDiscount={paymentData.subTotal}
        totalAmount={paymentData.totalAmount}
        tipAmount={tipAmount}
        amountDue={amountDue}
        allDiscountsApplied={allDiscountsApplied}
        note={paymentData.note}
        internalNote={null}
        merchantName={paymentData.merchantName}
        discount={paymentData.discount}
        paymentsMade={authedPayments}
        tipsEnabled={paymentData.tipsEnabled}
        children={{ tipsSection }}
        allowEdit={false}
        lineItemsUpdated={null}
        customer={null}
        siteKey={null}
        siteFilterPbiByLocationID={false}
        totalTaxRate={0}
        isSavingGlobalDiscount={false}
        updateGlobalDiscount={null}
        locationID={null}
        userDisplayNamesMap={displayNamesMap}
      />
    );

  const payInFullButton = (
    <div className="mx-3 flex items-start justify-end">
      {paymentData.amountDue > 0 ? (
        <BaseButtonPrimary
          className="mb-2 w-full xs:w-64"
          type="button"
          isBusy={isBusy === "payInFullButton"}
          onClick={async () => {
            if (paymentData?.paymentURL.includes("zift")) {
              window.open(paymentData?.paymentURL, "_blank");
              return;
            } else {
              setIsBusy("payInFullButton");
              try {
                const data = await DbRead.payments.get(id, tipAmount);
                setPaymentData(data);
                setHostedPaymentPageDialogOpen(true);
                setIsBusy(null);
              } catch (e) {
                logger.error(
                  `Error fetching payment data for ID ${id} @ siteKey ${siteKeyDoc ? siteKeyDoc.id : "[unknown]"}. Attempted to pay in full`,
                  e,
                );
                addToastMessage({
                  id: createToastMessageID(),
                  message: strings.UNEXPECTED_ERROR,
                  dialog: false,
                  type: "error",
                });
                setIsBusy(null);
              }
            }
          }}
        >
          <CreditCard className="mr-4 h-5" />
          {strings.PAY_IN_FULL}
        </BaseButtonPrimary>
      ) : null}
    </div>
  );

  const handleChange = (e: React.ChangeEvent<any>) => {
    setPaymentAmount(e.target.value);
  };

  const payPartialButton = firebaseUser && (
    <div className="mx-3 flex items-start justify-end">
      {paymentData.amountDue > 0 ? (
        <div className="mb-2 w-full xs:w-64">
          <BaseButtonSecondary
            className="mb-2 w-full xs:w-64"
            type="button"
            onClick={() => {
              setShowPartialPaymentFields(!showPartialPaymentFields);
            }}
          >
            <CreditCard className="mr-4 h-5" />
            {strings.PAY_PARTIAL}
          </BaseButtonSecondary>

          {showPartialPaymentFields && (
            <div className="flex justify-end space-x-2">
              <div className="flex items-center">
                <FaDollarSign className="mr-2" />
                <input
                  className="block w-36 rounded border border-gray-300 px-2 py-1.5 outline-none focus:border-primaryLight focus:ring-1 focus:ring-primaryLight sm:text-sm"
                  value={paymentAmount}
                  onChange={handleChange}
                />
              </div>

              <BaseButtonPrimary
                className="w-12"
                type="button"
                isBusy={isBusy === "payPartialButton"}
                overrideLoadingElement={
                  <LoadingSpinner marginClass="" sizeClass="h-5 w-5" />
                }
                onClick={async () => {
                  setIsBusy("payPartialButton");
                  let data: StiltPaymentFormData;
                  try {
                    data = await DbRead.payments.get(
                      id,
                      tipAmount,
                      parseFloat(paymentAmount),
                    );
                    setIsBusy(null);
                  } catch (e) {
                    logger.error(
                      `Error fetching payment data for ID ${id} @ siteKey ${siteKeyDoc ? siteKeyDoc.id : "[unknown]"}. Attempted a partial payment`,
                      e,
                    );
                    addToastMessage({
                      id: createToastMessageID(),
                      message: strings.UNEXPECTED_ERROR,
                      dialog: false,
                      type: "error",
                    });
                    setIsBusy(null);
                    return;
                  }

                  if (paymentData.paymentURL.includes("zift")) {
                    window.open(data.paymentURL, "_blank");
                  } else {
                    setPaymentData(data);
                    setHostedPaymentPageDialogOpen(true);
                  }
                }}
              >
                <CreditCard className="h-5" />
              </BaseButtonPrimary>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );

  const sendEmailButton = (
    <BaseButtonPrimary
      type="button"
      isBusy={isBusy === "sendEmailButton"}
      busyText={strings.buttons.BUSY_SENDING}
      onClick={async () => {
        if (selectedEmailList.length === 0) {
          setErrorString(strings.ERROR_EMAIL);
          return;
        }
        setIsBusy("sendEmailButton");
        await emailPaymentLinkToCustomer(selectedEmailList, false);
        setIsBusy(null);
      }}
      className="uppercase xs:w-44"
    >
      <SendIcon className="mr-4" />
      Send Now
    </BaseButtonPrimary>
  );

  const includeJobPhotosSwitch = (
    <StyledSwitchGroup
      readableName={strings.INCLUDE_JOB_PHOTOS}
      onBlur={() => {}}
      onChange={() => setIncludeJobPhotos(!includeJobPhotos)}
      checked={includeJobPhotos}
      id="includeJobPhotos"
      name={"includeJobPhotos"}
    />
  );

  const emailComponent = userIsAuthedSiteAdmin === true && (
    <div className="mt-8 px-3 xl:mx-auto xl:max-w-5xl">
      {displayEmailError}
      <MultipleEmailSelector
        emailList={getEmailList(invoice, customer)}
        selectedEmailList={selectedEmailList}
        setSelectedEmailList={setSelectedEmailList}
        sendEmailButton={sendEmailButton}
        includeJobPhotosSwitch={includeJobPhotosSwitch}
        setErrorString={setErrorString}
      />
      {invoice && invoice.timestampSentToCustomer && (
        <div className="mt-1 text-right text-sm italic">
          Last Sent to Customer:{" "}
          {convertToReadableTimestamp(invoice.timestampSentToCustomer)}
        </div>
      )}
    </div>
  );

  // Not currently used
  // const jobPhotos = paymentData.jobPhotos && (
  //   <div className="grid grid-cols-4 gap-4">
  //     {paymentData.jobPhotos.map((photo) => {
  //       return <img key={photo.id} src={photo.photoURL_reduced} />;
  //     })}
  //   </div>
  // );

  return (
    <Fragment>
      <style>{`body{background-color: #f8fafb;}`}</style>
      <PaymentsPage payment={paymentData}>
        {{
          iFrame,
          invoiceSummary:
            userIsAuthedSiteAdmin && authedPayments && hasUserDisplayNamesMap
              ? invoiceSummaryADMIN
              : invoiceSummary,
          payInFullButton,
          payPartialButton,
          // jobPhotos,      not currently used
          emailComponent: emailComponent,
        }}
      </PaymentsPage>
    </Fragment>
  );
}
