// Libs
import { Timestamp } from "firebase/firestore";
import { z } from "zod";

// Local
import { zFallback } from "../utils";

/**
 * options for representing a document's current sync status with the
 * stilt-customer's accounting platform.
 */
export const OAccountingSyncStatus = {
  /**
   * denotes that this document is up-to-date with the version found within the
   * stilt-customer's accounting platform. no further action is needed at this time.
   */
  SYNCED: "SYNCED",
  /**
   * denotes that this document is dependent on another document that is not yet
   * synced. once the other document is synced, this one can be synced as well.
   */
  IS_WAITING_ON: "IS_WAITING_ON",
  /**
   * denotes that this document has an active server job, or is about to have
   * an active server job.
   * * the server job will attempt to **create or modify a record** via the appropriate integration platform.
   * * the stilt-customer's accounting platform does not have the up-to-date version yet.
   *
   * "push" because we're pushing data to the integration platform.
   */
  AWAITING_PUSH: "AWAITING_PUSH",
  /**
   * denotes that this document has an active server job. this syncStatus is used
   * for records that are sent to Codat. Codat does not immediately tell us the
   * result of pushing data to the stilt-customer's accounting software; we need
   * to get confirmation from Codat "later."
   */
  AWAITING_CONFIRM: "AWAITING_CONFIRM",
  /**
   * denotes that this document has an active server job. the server job will retry
   * creating/updating the record via the appropriate integration platform.
   *
   * the stilt-customer's accounting platform either does not have the record (in
   * the case of creation), or does not have the most up-to-date version (in the
   * case of modification).
   */
  FAILED: "FAILED",
} as const;

export enum AccountingSyncStatus {
  SYNCED = "SYNCED",
  IS_WAITING_ON = "IS_WAITING_ON",
  AWAITING_PUSH = "AWAITING_PUSH",
  AWAITING_CONFIRM = "AWAITING_CONFIRM",
  FAILED = "FAILED",
}

export type AccountingSyncStatusValues =
  (typeof OAccountingSyncStatus)[keyof typeof OAccountingSyncStatus];

// get readable accounting sync status
export const getReadableAccountingSyncStatus = (
  status: AccountingSyncStatusValues,
): string => {
  switch (status) {
    case OAccountingSyncStatus.SYNCED:
      return "Synced";
    case OAccountingSyncStatus.IS_WAITING_ON:
      return "Waiting on another record to sync";
    case OAccountingSyncStatus.AWAITING_PUSH:
      return "Awaiting sync";
    case OAccountingSyncStatus.AWAITING_CONFIRM:
      return "Awaiting confirmation of sync";
    case OAccountingSyncStatus.FAILED:
      return "Failed";
    default: {
      const _exhaustivenessCheck: never = status;
      return _exhaustivenessCheck;
    }
  }
};

/** Typeguard to determine if the given value is one of the AccountingSyncStatusValues */
export function isValidAccountingSyncStatusValue(
  value: string,
): value is AccountingSyncStatusValues {
  const statusValues = Object.values(OAccountingSyncStatus);
  return statusValues.includes(value as any);
}

/**
 * These (optional) properties are found on Stilt document types that undergo
 * accounting sync operations - Customer, CustomerLocation, StiltInvoice, StiltPayment, PriceBookItem
 */
export type AccountingSyncStiltDoc = {
  /**
   * to be displayed to the stilt-customer. contains an error message about why
   * the document was unable to be synced with their accounting software.
   */
  statusMessage?: string;
  /** refPath to the document that needs to be synced before this doc can be synced. */
  waitingOn?: string;
  timestampLastSynced?: Timestamp;
  timestampLastSyncAttempt?: Timestamp;
  timestampNextSyncAttempt?: Timestamp;
  syncStatus?: AccountingSyncStatus;
  /**
   * flag added when the document enters the accounting sync server job system.
   * switches to false when the document is fully synced.
   */
  isAwaitingSync?: boolean;
  /**
   * for debugging purposes. incremented whenever the document gets updated with
   * a FAILED syncStatus. removed when the doc gets a syncStatus of SYNCED.
   */
  failCount?: number;
};

export const AccountingSyncStiltDocSchema = z.object({
  statusMessage: z.string().min(0).max(3000).optional(),
  waitingOn: z.string().min(0).max(400).optional(),
  timestampLastSynced: z.instanceof(Timestamp).optional(),
  timestampLastSyncAttempt: z.instanceof(Timestamp).optional(),
  timestampNextSyncAttempt: z.instanceof(Timestamp).optional(),
  syncStatus: z.nativeEnum(AccountingSyncStatus).optional(),
  isAwaitingSync: z.boolean().optional(),
  failCount: z.number().optional(),
});

export const AccountingSyncStiltDocSchemaWithFallbacks = z.object({
  statusMessage: zFallback(
    z.string().min(0).max(3000).optional(),
    "",
    "statusMessage - AccountingSyncStiltDoc",
  ),
  waitingOn: zFallback(
    z.string().min(0).max(400).optional(),
    "",
    "waitingOn - AccountingSyncStiltDoc",
  ),
  timestampLastSynced: zFallback(
    z.instanceof(Timestamp).optional(),
    Timestamp.now(),
    "timestampLastSynced - AccountingSyncStiltDoc",
  ),
  timestampLastSyncAttempt: zFallback(
    z.instanceof(Timestamp).optional(),
    Timestamp.now(),
    "timestampLastSyncAttempt - AccountingSyncStiltDoc",
  ),
  timestampNextSyncAttempt: zFallback(
    z.instanceof(Timestamp).optional(),
    Timestamp.now(),
    "timestampNextSyncAttempt - AccountingSyncStiltDoc",
  ),
  syncStatus: z.nativeEnum(AccountingSyncStatus).optional(),
  isAwaitingSync: z.boolean().optional(),
  failCount: zFallback(
    z.number().optional(),
    0,
    "failCount - AccountingSyncStiltDoc",
  ),
});
