//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";

//Local
import {
  CustomerLocation,
  customerLocationWithoutTimestamps,
  ExistingCustomerLocation,
} from "../../models/customer-location";
import BaseModal from "../BaseModal";
import AddEditLocationWithAutocompleteForm from "./AddEditLocationWithAutocompleteForm";
import { Customer } from "../../models/customer";
import AddEditLocationManuallyForm from "./AddEditLocationManuallyForm";
import AddEditCustomerContactDialog from "./AddEditCustomerContactDialog";
import { CustomerContactFormState } from "./AddEditCustomerContactForm";
import { DbWrite } from "../../database";
import { useAuthStore } from "../../store/firebase-auth";
import { User } from "firebase/auth";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { CustomerContacts } from "./CustomerContacts";
import { ExistingCustomerContact } from "../../models/customer-contact";
import { createToastMessageID } from "../../utils";
import { diffObjects } from "../../assets/js/object-diff";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";

export type TemporaryLocation = Omit<
  CustomerLocation,
  | "customerID"
  | "timestampCreated"
  | "timestampLastModified"
  | "createdBy"
  | "lastModifiedBy"
>;

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  geocoderResult: google.maps.GeocoderResult;
  customerType: Customer["type"];
  isSubmitting: boolean;
  showManualEntryForm: boolean;
  customerLocationDoc: ExistingCustomerLocation | null;
  customerLocationContacts?: ExistingCustomerContact[];
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSaveCustomerLocationWithAutocomplete: (
    newLocation: TemporaryLocation,
  ) => void;
  handleSaveManualCustomerLocation: (newLocation: TemporaryLocation) => void;
  handleEditCustomerLocation?: (
    updateLocation: customerLocationWithoutTimestamps,
  ) => Promise<void>;
  /* CHILDREN */
  children: {
    AddressField: React.ReactNode;
    AddMembershipsButton: React.ReactNode;
  };
}

export default function AddEditCustomerLocationDialog(props: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const [
    addEditCustomerContactDialogOpen,
    setAddEditCustomerContactDialogOpen,
  ] = useState<boolean>(false);
  const [selectedCustomerContact, setSelectedCustomerContact] =
    useState<ExistingCustomerContact | null>(null);
  const [locationContacts, setLocationContacts] = useState<
    ExistingCustomerContact[]
  >(props.customerLocationContacts ?? []);

  const addEditLocationHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {props.customerLocationDoc ? `Edit Location` : `New Location`}
      </h1>
      <button type="button" onClick={props.closeDialog}>
        <XMarkIcon
          aria-label="close new attribute form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  useEffect(() => {
    if (props.customerLocationContacts) {
      setLocationContacts(props.customerLocationContacts);
    }
  }, [props.customerLocationContacts]);

  async function handleSaveCustomerContact(
    customerContact: CustomerContactFormState,
  ) {
    if (!siteKeyDoc || !props.customerLocationDoc) return;

    await DbWrite.customerContacts.create({
      ...customerContact,
      createdBy: firebaseUser.uid,
      customerID: props.customerLocationDoc.customerID,
      customerLocationID: props.customerLocationDoc.id,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKeyDoc.id,
    });
  }

  async function handleEditCustomerContact(
    updateCustomerContact: CustomerContactFormState,
  ) {
    if (!siteKeyDoc || !selectedCustomerContact) return;

    const diffCustomerContactValues = diffObjects(
      selectedCustomerContact,
      updateCustomerContact,
    ).diff;

    if (Object.keys(diffCustomerContactValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const customerContactUpdate = {
      ...diffCustomerContactValues,
      lastModifiedBy: firebaseUser.uid,
    };

    try {
      await DbWrite.customerContacts.update(
        siteKeyDoc.id,
        selectedCustomerContact.id,
        customerContactUpdate,
      );
      addToastMessage({
        dialog: false,
        id: createToastMessageID(),
        message: strings.successfulUpdate(selectedCustomerContact.type),
        type: "success",
      });
      setSelectedCustomerContact(null);
    } catch (error) {
      logger.error("error while updating customer contact", error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  const addEditCustomerContactDialog = (
    <AddEditCustomerContactDialog
      closeDialog={() => {
        setAddEditCustomerContactDialogOpen(false);
        setSelectedCustomerContact(null);
      }}
      isDialogOpen={addEditCustomerContactDialogOpen}
      handleSave={handleSaveCustomerContact}
      handleEdit={handleEditCustomerContact}
      selectedCustomerContact={selectedCustomerContact}
    />
  );

  const customerContacts = props.customerLocationDoc && (
    <Fragment>
      <hr />
      <CustomerContacts
        customerContacts={locationContacts}
        customerLocations={[props.customerLocationDoc]}
        onContactDeleted={onDeleteCustomerContact}
        onContactAdded={() => setAddEditCustomerContactDialogOpen(true)}
        onContactEdited={(customerContact: ExistingCustomerContact) => {
          setSelectedCustomerContact(customerContact);
          setAddEditCustomerContactDialogOpen(true);
        }}
      />
      <hr />
    </Fragment>
  );

  async function onDeleteCustomerContact(
    contactToBeDeleted: string,
  ): Promise<void> {
    if (!siteKeyDoc) return;
    await DbWrite.customerContacts.delete(
      siteKeyDoc.id,
      firebaseUser.uid,
      contactToBeDeleted,
    );
  }

  return (
    <BaseModal
      closeModal={() => {}}
      open={props.isDialogOpen}
      title={addEditLocationHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="space-y-8 p-4">
        {props.showManualEntryForm ? (
          <AddEditLocationManuallyForm
            customerType={props.customerType}
            setAddEditCustomerLocationFormOpen={props.closeDialog}
            isSubmitting={props.isSubmitting}
            handleSaveManualCustomerLocation={
              props.handleSaveManualCustomerLocation
            }
            customerLocationDoc={props.customerLocationDoc}
            handleEditCustomerLocation={props.handleEditCustomerLocation}
          >
            {{
              AddMembershipsButton: props.children.AddMembershipsButton,
              CustomerContacts: customerContacts,
            }}
          </AddEditLocationManuallyForm>
        ) : (
          <AddEditLocationWithAutocompleteForm
            geocoderResult={props.geocoderResult}
            customerType={props.customerType}
            handleSaveCustomerLocationWithAutocomplete={
              props.handleSaveCustomerLocationWithAutocomplete
            }
            isSubmitting={props.isSubmitting}
            setAddEditCustomerLocationFormOpen={props.closeDialog}
            customerLocationDoc={props.customerLocationDoc}
            handleEditCustomerLocation={props.handleEditCustomerLocation}
          >
            {{
              AddressField: props.children.AddressField,
              AddMembershipsButton: props.children.AddMembershipsButton,
              CustomerContacts: customerContacts,
            }}
          </AddEditLocationWithAutocompleteForm>
        )}
      </div>
      {addEditCustomerContactDialog}
    </BaseModal>
  );
}
