//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import * as strings from "../../strings";
import BaseInputText from "../BaseInputText";
import BaseModal from "../BaseModal";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { logger as devLogger } from "../../logging";
import { useEffect } from "react";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  onSaveTemplate: (name: string) => Promise<void>;
}

export default function AddTemplateDetailsDialog({
  isDialogOpen,
  closeDialog,
  onSaveTemplate,
}: Props) {
  //#region SECTION: Styling of the header
  const parentDivStyles = "pb-7 w-full lg:w-6/12 lg:max-w-4xl text-left";
  const title = (
    <div className="flex items-center justify-between bg-primary px-5 py-6 sm:px-10">
      <h1 className="text-xl font-bold text-white sm:text-3xl">
        {strings.CREATE_SITE_TEMPLATE_DIALOG}
      </h1>
      <XMarkIcon
        aria-label={`close add custom field dialog`}
        onClick={closeDialog}
        className="h-6 w-6 cursor-pointer text-white"
      />
    </div>
  );
  //#endregion

  const TemplateDetailsSchema = z.object({
    title: z.string().min(1, { message: "This field is required" }).max(200),
  });
  type TemplateDetailsState = z.infer<typeof TemplateDetailsSchema>;

  const { register, handleSubmit, reset, formState } =
    useForm<TemplateDetailsState>({
      defaultValues: { title: "" },
      resolver: zodResolver(TemplateDetailsSchema),
      mode: "onChange",
    });

  const { errors, isSubmitting } = formState;

  async function onSubmit(data: TemplateDetailsState) {
    try {
      await onSaveTemplate(data.title);
      devLogger.debug("template successfully saved");
    } catch (error) {
      devLogger.error(error);
    }
    closeDialog();
  }

  useEffect(() => {
    reset({ title: "" });
  }, [reset, isDialogOpen]);

  return (
    <BaseModal
      open={isDialogOpen}
      title={title}
      closeModal={closeDialog}
      parentDivStyles={parentDivStyles}
    >
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="mt-8 px-4 md:mt-10 md:px-6"
      >
        <BaseInputText
          admin={true}
          inputName="title"
          className="mt-3"
          text="Title"
          {...register("title")}
        />
        {errors.title?.message && (
          <div className="ml-1 mt-2 text-xs text-red-400">
            {errors.title?.message}
          </div>
        )}

        {/* buttons */}
        <div className="mt-4 flex w-full items-center justify-between gap-4">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={() => {
              closeDialog();
            }}
          >
            {strings.buttons.CLOSE}
          </BaseButtonSecondary>
          <BaseButtonPrimary
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SUBMITTING}
            type="submit"
            className="w-full justify-center uppercase"
          >
            {strings.buttons.SAVE_TEMPLATE}
          </BaseButtonPrimary>
        </div>
      </form>
    </BaseModal>
  );
}
