// Libs
import { forwardRef } from "react";

type Ref = HTMLInputElement;

type Props = {
  id: string;
  label: string;
  /** defaults to `accent-gray-700` */
  accentClass?: string;
  className?: string;
} & React.ComponentPropsWithRef<"input">;

/** optional accentClass defaults to `accent-gray-700` */
const BaseInputRadio = forwardRef<Ref, Props>(
  ({ id, label, className, accentClass, ...props }, ref): JSX.Element => {
    return (
      <div className={`flex items-center px-2 ${className}`}>
        <input
          {...props}
          id={id}
          type="radio"
          className={`h-6 w-6 cursor-pointer focus-visible:outline focus-visible:outline-1 focus-visible:outline-primaryLight ${
            accentClass ?? "accent-gray-700"
          }`}
          ref={ref}
          value={label}
        />
        <label
          htmlFor={id}
          className="ml-2 block cursor-pointer text-base font-medium"
        >
          {label}
        </label>
      </div>
    );
  },
);

export default BaseInputRadio;
