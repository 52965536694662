//Libs

//Local
import { ResponseTypes } from "../../models/checklist-response-types";
import { ComplianceItemCommon } from "../../models/compliance-item";
import { ConvertArrayToString } from "./HandleOptions";
import { ShowRightStringForPassingMinMaxUnits } from "./ShowRightStringForPassingMinMaxUnits";

export function showReadableRequirementRanges(
  customField: ComplianceItemCommon,
): string {
  const responseType: ResponseTypes = customField.responseType;

  let readableRange: string = "";
  switch (responseType) {
    case "string":
      readableRange = "";
      break;
    case "float":
    case "integer":
      readableRange = ShowRightStringForPassingMinMaxUnits({
        passingMin: customField.passingMin,
        passingMax: customField.passingMax,
        units: customField.units,
      });
      break;
    case "selection":
      readableRange = Array.isArray(customField.passingOptions)
        ? ConvertArrayToString(customField.passingOptions)
        : "";
      break;
    default:
      const _exhaustiveCheck: never = responseType;
      return _exhaustiveCheck;
  }
  return readableRange;
}
