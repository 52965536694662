//Libs
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";

//Local
import {
  ExistingCustomer,
  ExistingCustomerWithLocations,
  customerWithoutTimestamps,
} from "../../models/customer";
import BaseModal from "../BaseModal";
import { customerTypes } from "../../models/customer";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputSelect from "../BaseInputSelect";
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseInputText from "../BaseInputText";
import { logger as devLogger } from "../../logging";
import { formatCustomerName } from "../../assets/js/formatCustomerName";
import BaseInputTextArea from "../BaseInputTextArea";
import { ConvertArrayToString } from "../../assets/js/HandleOptions";
import { handleTags } from "../../assets/js/HandleTags";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { phoneUtils } from "../../utils/phoneUtils";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  customer: ExistingCustomer;
  addMembershipsButton: React.ReactNode;
  /* FUNCTIONS */
  closeDialog: () => void;
  handleEditCustomer: (
    updateCustomer: customerWithoutTimestamps,
  ) => Promise<void>;
  /* CHILDREN */
  children: {
    AddNewCustomerLocationDialog?: (
      customerType: ExistingCustomerWithLocations["type"],
    ) => void;
  };
}

export const EditCustomerFormSchema = z
  .object({
    name: z.string().max(200),
    firstName: z.string().max(200),
    lastName: z.string().max(200),
    email: z.string().email().nullable().or(z.literal("")),
    phone: z.string().max(200),
    notes: z.string().max(1000),
    type: z.enum(customerTypes),
    tags: z.string().min(0).max(2000),
    isTaxExempt: z.boolean(),
    isTaxExemptGST: z.boolean().optional(),
    isTaxExemptPST: z.boolean().optional(),
    doNotService: z.boolean().optional(),
    templateNotes: z.string().max(1000).optional(),
    defaultSendBookingConfirmationEmail: z.boolean(),
    defaultSendJobReminderEmail: z.boolean(),
  })
  .superRefine((customer, ctx) => {
    if (customer.type === "commercial" && customer.name.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        type: "string",
        inclusive: true,
        message: "Business name is required",
        path: ["name"],
      });
    }

    if (customer.name.length === 0 && customer.firstName.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        type: "string",
        inclusive: true,
        message: "First name is required",
        path: ["firstName"],
      });
    }

    if (customer.name.length === 0 && customer.lastName.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        type: "string",
        inclusive: true,
        message: "Last name is required",
        path: ["lastName"],
      });
    }
  });

export type EditCustomerFormState = z.infer<typeof EditCustomerFormSchema>;

export default function EditCustomerDialog({
  isDialogOpen,
  customer,
  closeDialog,
  handleEditCustomer,
  ...props
}: Props) {
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const [displayError, setDisplayError] = useState<boolean>(false);
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  const editCustomerFormDefaultValues: EditCustomerFormState = useMemo(() => {
    return {
      name: customer.name,
      firstName: customer.firstName ?? "",
      lastName: customer.lastName ?? "",
      email:
        customer.email && customer.email.length > 0 ? customer.email[0] : "",
      phone:
        customer.phone && customer.phone.length > 0 ? customer.phone[0] : "",
      notes: customer.notes ?? "",
      type: customer.type,
      tags: ConvertArrayToString(customer.tags),
      isTaxExempt: customer.isTaxExempt,
      isTaxExemptPST: customer.isTaxExemptPST,
      isTaxExemptGST: customer.isTaxExemptGST,
      doNotService: customer.doNotService,
      templateNotes: customer.customData["templateNotes"],
      defaultSendBookingConfirmationEmail:
        customer.defaultSendBookingConfirmationEmail ?? false,
      defaultSendJobReminderEmail:
        customer.defaultSendJobReminderEmail ?? false,
    };
  }, [customer]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<EditCustomerFormState>({
    defaultValues: editCustomerFormDefaultValues,
    resolver: zodResolver(EditCustomerFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (isDialogOpen === false) {
      reset(editCustomerFormDefaultValues);
    }
  }, [editCustomerFormDefaultValues, isDialogOpen, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    closeDialog();
  }

  const watchType = useWatch({
    control: control,
    name: "type",
  });

  const onSubmit: SubmitHandler<EditCustomerFormState> = async (formValues) => {
    let parsed;
    if (formValues.phone !== "") {
      const { PhoneNumber, warning } = phoneUtils.parse(formValues.phone);
      if (warning === true) {
        parsed = formValues.phone;
        addToastMessage({
          id: createToastMessageID(),
          message: strings.PHONE_TOO_SHORT,
          dialog: false,
          type: "warning",
        });
      } else {
        parsed = PhoneNumber.number;
      }
    }

    const updateCustomer: customerWithoutTimestamps = {
      ...customer,
      name: formatCustomerName({
        type: formValues.type,
        name: formValues.name,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      }),
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email:
        formValues.email === "" || formValues.email === null
          ? null
          : [formValues.email],
      phone:
        parsed === null || parsed === undefined ? null : [parsed.toString()],
      notes: formValues.notes,
      tags: handleTags(formValues.tags),
      isTaxExempt: formValues.isTaxExempt,
      doNotService: formValues.doNotService,
      type: formValues.type,
      defaultSendBookingConfirmationEmail:
        formValues.defaultSendBookingConfirmationEmail ?? false,
      defaultSendJobReminderEmail:
        formValues.defaultSendJobReminderEmail ?? false,
    };

    if (
      siteKeyDoc?.customizations.accounting?.currency === "CAD" ||
      siteKeyDoc?.customizations.accounting?.currency === "cad"
    ) {
      updateCustomer.isTaxExemptGST = formValues.isTaxExemptGST ?? false;
      updateCustomer.isTaxExemptPST = formValues.isTaxExemptPST ?? false;
    }

    updateCustomer.customData["templateNotes"] = formValues.templateNotes;

    try {
      await handleEditCustomer(updateCustomer);
      // Close dialog if successful.
      closeAndReset();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  const editCustomerHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.EDIT_CUSTOMER}
      </h1>
      <button type="button" onClick={() => closeAndReset()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = (
    <ErrorMessage
      message={strings.ERR_EDIT_CUSTOMER}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <Fragment>
      <BaseModal
        closeModal={() => {}}
        open={isDialogOpen}
        title={editCustomerHeader}
        parentDivStyles="inline-block transform overflow-hidden  max-w-screen-md rounded-lg bg-white text-left align-middle shadow-xl transition-all"
      >
        <div className="relative flex flex-col space-y-8 p-8 text-lg">
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8"
          >
            <>
              {/* Field: Type */}
              <div>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <BaseInputSelect
                      inputName="type"
                      text="Type"
                      admin={true}
                      required={true}
                      className="capitalize"
                      {...field}
                    >
                      {/* The empty string for value tricks validation into failing */}
                      <option value="" disabled>
                        Select a type
                      </option>
                      {customerTypes.map((type) => {
                        return (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        );
                      })}
                    </BaseInputSelect>
                  )}
                />
                {errors.type?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.type.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {watchType === "commercial" ? (
                /* Field: Name */
                <div>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <BaseInputText
                        text="Business Name"
                        inputName="name"
                        admin={true}
                        required={true}
                        {...field}
                      />
                    )}
                  />
                  {errors.name?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: errors.name.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              ) : null}

              {/* Field: First Name */}
              <div>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text={
                        watchType === "commercial"
                          ? "Contact First Name"
                          : "First Name"
                      }
                      inputName="firstName"
                      admin={true}
                      required={true}
                      {...field}
                    />
                  )}
                />
                {errors.firstName?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.firstName.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* Field: Last Name */}
              <div>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text={
                        watchType === "commercial"
                          ? "Contact Last Name"
                          : "Last Name"
                      }
                      inputName="lastName"
                      admin={true}
                      required={true}
                      {...field}
                    />
                  )}
                />
                {errors.lastName?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.lastName.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* Field: Email */}
              <div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Email"
                      inputName="email"
                      admin={true}
                      required={false}
                      {...field}
                      value={field.value === null ? "" : field.value}
                    />
                  )}
                />
                {errors.email?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.email.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* Field: Phone */}
              <div>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Phone"
                      inputName="phone"
                      admin={true}
                      required={false}
                      {...field}
                    />
                  )}
                />
                {errors.phone?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.phone.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* Field: Notes */}
              <div>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Notes"
                      inputName="notes"
                      admin={true}
                      required={false}
                      {...field}
                    />
                  )}
                />
                {errors.notes?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.notes.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* Field: Tags */}
              <div>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Tags"
                      inputName="tags"
                      admin={true}
                      required={false}
                      placeholder="Insert your tags, separated by comma"
                      {...field}
                    />
                  )}
                />
                {errors.tags?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.tags.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* Field: Template Notes */}
              {siteKeyDoc?.customizations?.customerCustomizations
                ?.templateNotes === true ? (
                <div>
                  <Controller
                    name="templateNotes"
                    control={control}
                    render={({ field }) => (
                      <BaseInputTextArea
                        text="Job Template Notes"
                        inputName="templateNotes"
                        admin={true}
                        required={false}
                        rows={3}
                        {...field}
                        value={field.value == null ? "" : field.value}
                      />
                    )}
                  />
                  {errors.templateNotes?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: errors.templateNotes.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              ) : null}

              {/* SWITCH GROUP */}
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                <div className="flex flex-col gap-2">
                  {/* Field: Taxable */}
                  {siteKeyDoc?.customizations.accounting?.currency !== "CAD" &&
                    siteKeyDoc?.customizations.accounting?.currency !==
                      "cad" && (
                      <div>
                        <Controller
                          name="isTaxExempt"
                          control={control}
                          render={({ field }) => (
                            <StyledSwitchGroup
                              readableName={strings.TAX_EXEMPT}
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              ref={field.ref}
                              checked={field.value}
                              id="isTaxExempt"
                              name={field.name}
                            />
                          )}
                        />
                      </div>
                    )}
                  {(siteKeyDoc?.customizations.accounting?.currency === "CAD" ||
                    siteKeyDoc?.customizations.accounting?.currency ===
                      "cad") && (
                    <div>
                      <Controller
                        name="isTaxExemptPST"
                        control={control}
                        render={({ field }) => (
                          <StyledSwitchGroup
                            readableName={strings.TAX_EXEMPT_PST}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            ref={field.ref}
                            checked={field.value ?? false}
                            id="isTaxExempt"
                            name={field.name}
                          />
                        )}
                      />
                    </div>
                  )}
                  {(siteKeyDoc?.customizations.accounting?.currency === "CAD" ||
                    siteKeyDoc?.customizations.accounting?.currency ===
                      "cad") && (
                    <div>
                      <Controller
                        name="isTaxExemptGST"
                        control={control}
                        render={({ field }) => (
                          <StyledSwitchGroup
                            readableName={strings.TAX_EXEMPT_GST}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            ref={field.ref}
                            checked={field.value ?? false}
                            id="isTaxExempt"
                            name={field.name}
                          />
                        )}
                      />
                    </div>
                  )}
                  <div>
                    <Controller
                      name="doNotService"
                      control={control}
                      render={({ field }) => (
                        <StyledSwitchGroup
                          readableName={strings.DO_NOT_SERVICE}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          ref={field.ref}
                          checked={field.value ?? false}
                          id="doNotService"
                          name={field.name}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  {/* EMAIL CONFIRMATION - REMINDER */}
                  {siteKeyDoc?.customizations
                    .sendJobBookingConfirmationToCustomers && (
                    <div>
                      <Controller
                        name="defaultSendBookingConfirmationEmail"
                        control={control}
                        render={({ field }) => (
                          <StyledSwitchGroup
                            readableName={
                              strings.DEFAULT_SEND_BOOKING_CONFIRMATION_EMAIL
                            }
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            ref={field.ref}
                            checked={field.value ?? false}
                            id="defaultSendBookingConfirmationEmail"
                            name={field.name}
                          />
                        )}
                      />
                    </div>
                  )}
                  {siteKeyDoc?.customizations.sendTaskReminderToCustomers && (
                    <div>
                      <Controller
                        name="defaultSendJobReminderEmail"
                        control={control}
                        render={({ field }) => (
                          <StyledSwitchGroup
                            readableName={
                              strings.DEFAULT_SEND_24H_REMINDER_EMAIL
                            }
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            ref={field.ref}
                            checked={field.value ?? false}
                            id="defaultSendJobReminderEmail"
                            name={field.name}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>

              {props.addMembershipsButton}

              {props.children.AddNewCustomerLocationDialog &&
                props.children.AddNewCustomerLocationDialog(watchType)}

              {/* Action Buttons */}
              <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
                <BaseButtonSecondary
                  type="button"
                  className="w-full justify-center uppercase"
                  onClick={closeAndReset}
                >
                  {strings.buttons.CANCEL}
                </BaseButtonSecondary>

                <BaseButtonPrimary
                  type="submit"
                  formNoValidate
                  disabled={isSubmitting}
                  isBusy={isSubmitting}
                  busyText={strings.buttons.BUSY_SAVING}
                  className="w-full justify-center uppercase"
                >
                  {strings.buttons.SAVE}
                </BaseButtonPrimary>
              </div>
            </>
          </form>

          {displayError ? (
            <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
              {errorMessage}
            </span>
          ) : null}
        </div>
      </BaseModal>
    </Fragment>
  );
}
