// Libs
import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  useForm,
  Controller,
  Control,
  useFormState,
  UseFormTrigger,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DateTime } from "luxon";

// Local
import {
  Schedule,
  isValidHour,
  isValidMinute,
  getSystemTimezone as getSysTZ,
} from "../rschedule";

// Local
import BaseModal from "../components/BaseModal";
import BaseButtonPrimary from "./BaseButtonPrimary";
import BaseButtonSecondary from "./BaseButtonSecondary";
import { ExistingServerJob, NewServerJob } from "../models/server-job";
import BaseInputCheckbox from "./BaseInputCheckbox";
import { getNextOccurrence } from "../utils";
import LoadingSpinner from "./LoadingSpinner";
import DeleteButtonSmallX from "./DeleteButtonSmallX";
import * as strings from "../strings";

const WeekdayList = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"] as const;
type DayOfWeek = (typeof WeekdayList)[number];

function isDayOfWeek(value: string): value is DayOfWeek {
  return WeekdayList.includes(value as any);
}

const formSchema = z
  .object({
    days: z.object({
      MO: z.boolean(),
      TU: z.boolean(),
      WE: z.boolean(),
      TH: z.boolean(),
      FR: z.boolean(),
      SA: z.boolean(),
      SU: z.boolean(),
    }),
    timeOfDay: z.string().min(1, { message: "Required" }),
  })
  // At least one element must be checked.
  .refine((data) => Object.values(data.days).some((day) => day === true), {
    message: "At least one day must be selected",
    path: ["days"],
  });

type FormInputs = z.infer<typeof formSchema>;

interface PropsDayCheckbox {
  day: DayOfWeek;
  control: Control<FormInputs, any>;
  trigger: UseFormTrigger<FormInputs>;
}

const DayCheckbox = (props: PropsDayCheckbox) => {
  return (
    <Controller
      name={`days.${props.day}`}
      control={props.control}
      defaultValue={false}
      render={({ field }) => (
        <BaseInputCheckbox
          id={props.day}
          label={props.day}
          onChange={(e) => {
            field.onChange(e.target.checked);
            props.trigger("days");
          }}
          checked={field.value}
        />
      )}
    />
  );
};

function ErrorMessage(
  props: React.ComponentPropsWithoutRef<"span">,
): JSX.Element {
  return <div className={"mt-1 text-xs text-red-600"}>{props.children}</div>;
}

export interface Props {
  showDialog: boolean;
  onDialogClose: () => void;
  onFormSubmit: (
    data: Pick<NewServerJob, "performAt" | "schedule">,
  ) => Promise<void>;
  craftRecordID: string;
  /** Should not need to use this unless you want to override the default functionality. */
  getSystemTimezone?: () => string;
  serverJobList: ExistingServerJob[];
  isFetchingJobs: boolean;
  onDeleteRule: (jobID: string) => Promise<void>;
}

/**
 * Dialog component to add schedule rules and display a list of rules and
 * upcoming schedule events. Currently only works for creating checklists.
 */
export default function AddScheduleDialog({
  getSystemTimezone = getSysTZ,
  ...props
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    trigger,
  } = useForm<FormInputs>({ resolver: zodResolver(formSchema) });
  const { isSubmitting } = useFormState({ control: control });

  function closeAndReset() {
    reset();
    props.onDialogClose();
  }

  return (
    <BaseModal
      open={props.showDialog}
      title="Add weekly schedule rule"
      closeModal={() => closeAndReset()}
    >
      <>
        {/* RULE INPUT FORM */}
        <form
          id="schedule-rule-form"
          onSubmit={handleSubmit(async (formValues) => {
            // Converting selected checkboxes to array of weekday strings.
            const daysChosen = Object.entries(formValues.days).reduce<
              DayOfWeek[]
            >((acc, [key, value]) => {
              if (isDayOfWeek(key) && value === true) {
                acc.push(key);
              }
              return acc;
            }, []);
            // todo: possible opportunity to extract this out. Maybe to validation before submit?
            if (daysChosen.length === 0)
              throw Error("At least one day must be selected");
            const timeParts = formValues.timeOfDay.split(":");
            const hour = parseInt(timeParts[0]);
            const minute = parseInt(timeParts[1]);

            if (!(isValidHour(hour) && isValidMinute(minute))) {
              throw Error("Invalid hour or minute");
            }

            const schedule = new Schedule({
              timezone: getSystemTimezone(),
              rrules: [
                {
                  start: DateTime.now(),
                  frequency: "DAILY",
                  byDayOfWeek: daysChosen,
                  byMillisecondOfSecond: [0],
                  bySecondOfMinute: [0],
                  byMinuteOfHour: [minute],
                  byHourOfDay: [hour],
                },
              ],
            });

            const scheduleJSON = schedule.toJSON();

            const nextOccurrence = getNextOccurrence(scheduleJSON);
            if (!nextOccurrence) throw Error("Unable to get next occurrence");

            props.onFormSubmit({
              performAt: nextOccurrence.toISO(),
              schedule: scheduleJSON,
            });
            reset();
          })}
        >
          <fieldset>
            <legend className={"mt-8 block text-sm font-medium text-gray-700"}>
              Which days?
            </legend>
            <div className="mt-2 grid grid-cols-3 gap-2 sm:grid-cols-5">
              <DayCheckbox day="MO" control={control} trigger={trigger} />
              <DayCheckbox day="TU" control={control} trigger={trigger} />
              <DayCheckbox day="WE" control={control} trigger={trigger} />
              <DayCheckbox day="TH" control={control} trigger={trigger} />
              <DayCheckbox day="FR" control={control} trigger={trigger} />
              <DayCheckbox day="SA" control={control} trigger={trigger} />
              <DayCheckbox day="SU" control={control} trigger={trigger} />
            </div>
            <ErrorMessage>{(errors.days as any)?.message}</ErrorMessage>
          </fieldset>

          <label
            htmlFor="timeOfDay"
            className={"mt-8 block text-sm font-medium text-gray-700"}
          >
            What time?
          </label>
          <input
            id="timeOfDay"
            type="time"
            className={
              "mt-2 rounded border border-gray-300 outline-none focus:border-primaryLight focus:ring-1 focus:ring-primaryLight sm:text-sm"
            }
            // todo: must have a value
            {...register("timeOfDay", { required: true })}
          />
          <ErrorMessage>{errors.timeOfDay?.message}</ErrorMessage>

          {/* {errors.exampleRequired && (
          <span className="text-sm text-indigo-500">
            This field is required 😇
          </span>
        )} */}
          {/* ACTION BUTTON ROW */}
          <div className="mt-8 flex items-center gap-4">
            <BaseButtonSecondary
              onClick={() => closeAndReset()}
              type="button"
              className="w-full"
            >
              {strings.buttons.CLOSE}
            </BaseButtonSecondary>
            <BaseButtonPrimary
              type={"submit"}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full"
            >
              {strings.buttons.ADD}
            </BaseButtonPrimary>
          </div>
        </form>

        {/* SCHEDULE RULE LIST */}
        <h3 className="mt-8 font-medium text-gray-700">
          Schedule rules
          {props.isFetchingJobs ? (
            <LoadingSpinner marginClass={"inline-block ml-2"} />
          ) : null}
        </h3>
        {!props.isFetchingJobs && props.serverJobList.length === 0 ? (
          <p className="text-sm text-gray-500">
            Schedule a checklist to see it show up here
          </p>
        ) : null}
        <ol aria-label="schedule rules" className={"mt-2 max-w-lg"}>
          {props.serverJobList.map((job) => {
            if (!job.schedule) return null;
            // Only using one rule per schedule.
            const rruleConfig = job.schedule.rrules[0].config;
            const minute = rruleConfig.byMinuteOfHour?.[0];
            const hour = rruleConfig.byHourOfDay?.[0];
            const tempDt = DateTime.fromObject({
              hour: hour,
              minute: minute,
            });
            const daysOfWeek = rruleConfig.byDayOfWeek;
            const timeOfDay = `${tempDt.toFormat("hh:mm a")}`;
            if (!daysOfWeek) return null;
            return (
              <li
                key={job.id}
                className={
                  "mt-1 grid grid-cols-3 items-center rounded border px-4 py-1 text-sm text-gray-700"
                }
              >
                <span>{daysOfWeek.join(", ")}</span>
                <span className={"text-center"}>{timeOfDay}</span>
                <span className={"self-center justify-self-end "}>
                  {/* Delete rule button */}
                  <DeleteButtonSmallX
                    onDelete={() => props.onDeleteRule(job.id)}
                  />
                </span>
              </li>
            );
          })}
        </ol>

        {/* NEXT EVENTS LIST */}
        <h3 className="mt-6 font-medium text-gray-700">
          Checklist schedule{" "}
          <span className={"ml-2 text-xs text-gray-500"}>next 5 events</span>
          {props.isFetchingJobs ? (
            <LoadingSpinner marginClass={"inline-block ml-2"} />
          ) : null}
        </h3>
        {!props.isFetchingJobs && props.serverJobList.length === 0 ? (
          <p className="text-sm text-gray-500">
            {"You haven't scheduled any checklists yet"}
          </p>
        ) : null}

        <ChecklistSchedule jobList={props.serverJobList} />
      </>
    </BaseModal>
  );
}

function compareLuxonDates(a: DateTime, b: DateTime): number {
  return a.toMillis() - b.toMillis();
}

function ChecklistSchedule({
  jobList,
}: {
  jobList: ExistingServerJob[];
}): JSX.Element {
  const nextDates: DateTime[] = [];

  jobList.forEach((job) => {
    if (!job.schedule) return null;
    const schedule = Schedule.fromJSON(job.schedule);
    const nextOccurences = schedule
      .occurrences({ start: DateTime.now(), take: 5 })
      .toArray();

    nextOccurences.forEach((adapter) => {
      nextDates.push(adapter.date);
    });
  });

  nextDates.sort(compareLuxonDates);

  const listItems = nextDates.map((dt) => {
    const dtString = dt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
    return (
      <li
        key={dtString + uuidv4()}
        className={"mt-1 rounded border px-4 py-1 text-sm text-gray-700"}
      >
        {dtString}
      </li>
    );
  });

  return (
    <ol aria-label={"checklist schedule"} className={"max-w-lg"}>
      {listItems.slice(0, 5)}
    </ol>
  );
}
