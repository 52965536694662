// Local
import { logger } from "../../../logging";
import {
  CraftTypeValues,
  getCraftTypeRecordString,
} from "../../../models/craft-types";
import {
  ExistingCustomField,
  CustomFieldManager,
} from "../../../models/custom-field";
import { ExistingSiteKey } from "../../../models/site-key";
import { guardIsPlainObject } from "../../../utils";

/**
 * Given a siteKey doc and a target workType (aka craftType), returns those custom
 * fields.
 *
 * Tested ✅
 */
export function getCraftCustomFieldList(args: {
  siteKey: ExistingSiteKey;
  targetWorkType: CraftTypeValues;
}): ExistingCustomField[] {
  const craftDetails = args.siteKey.customizations?.craftDetails;

  // convert the integer to the string that's used in the DB
  const workTypeString = getCraftTypeRecordString(args.targetWorkType);

  let cfsForOneCraftType: ExistingCustomField[] = [];
  if (guardIsPlainObject(craftDetails)) {
    cfsForOneCraftType = Object.entries(craftDetails).flatMap(
      ([craftTypeString, dynamicDetailMap]) => {
        if (guardIsPlainObject(dynamicDetailMap)) {
          if (craftTypeString === workTypeString) {
            return Object.entries(dynamicDetailMap).flatMap(
              ([dynamicDetailID, ddData]) => {
                try {
                  return CustomFieldManager.fromSiteCustomization({
                    fieldID: dynamicDetailID,
                    craftRecordString: craftTypeString,
                    fieldData: ddData,
                  });
                } catch (e) {
                  logger.error(
                    `Expected ${args.siteKey.id}.customizations.${craftTypeString}.${dynamicDetailID} to parse, but got an error:`,
                    e,
                  );
                  return [];
                }
              },
            );
          } else {
            // Not the target workType
            return [];
          }
        } else {
          logger.warn(
            `Expected an object for the dynamicDetailMap for ${craftTypeString}, got: ${typeof dynamicDetailMap}`,
          );
          return [];
        }
      },
    );
  } else {
    logger.warn(
      `Expected craftDetails to be an object, got ${typeof craftDetails}`,
    );
  }

  return cfsForOneCraftType;
}
