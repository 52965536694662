// Libs
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { ExistingSiteKey } from "../../models/site-key";

export default function SiteKeyDropdown(args: {
  currentSiteKey: ExistingSiteKey;
  allSiteKeys: ExistingSiteKey[];
  onSelection: (id: string) => void;
  /** Meant for width classes, but can be whatever */
  styles: string;
}): JSX.Element {
  return (
    <div className={args.styles}>
      <Listbox
        value={args.currentSiteKey.name}
        onChange={(event) => {
          args.onSelection(event);
        }}
      >
        <div className={`relative mt-1`}>
          <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight sm:text-sm">
            <span className="block truncate">{args.currentSiteKey.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-700"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`}
            >
              {args.allSiteKeys.map((siteKey) => (
                <Listbox.Option
                  key={siteKey.id}
                  className={({ active, selected }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active || selected
                        ? "bg-primaryOpacity90 text-primary"
                        : "text-gray-700"
                    }`
                  }
                  value={siteKey.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {siteKey.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
