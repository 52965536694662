//Libs
import React, { Fragment } from "react";

//Local
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import {
  EstimateDataForNonAuthUser,
  ExistingEstimate,
  JobCommissionsForDisplay,
} from "../../models/estimate";
import * as strings from "../../strings";
import { CustomerDetails } from "../../components/estimates/CustomerDetails";
import { CustomerAddress } from "../../components/estimates/CustomerAddress";
import { OptionalDocs } from "./ViewEstimateContainer";
import currencyFormatter from "../../currency";
import {
  convertToReadableTimestamp,
  convertToReadableTimestampDate,
} from "../../assets/js/convertToReadableTimestamp";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { PencilIconWithRef } from "../../components/PencilEditButton";
import { StyledTooltip } from "../../components/StyledTooltip";
import { ExistingCommissionAdjustment } from "../../models/commission-adjustment";

// import { ExistingSignature } from "../../models/signature";

interface Props {
  /* DATA */
  optionalDocs?: OptionalDocs;
  customer: ExistingCustomer;
  customerLocation: ExistingCustomerLocation;
  estimateDoc: ExistingEstimate | EstimateDataForNonAuthUser;
  estimateStatus: string;
  merchantLogoURL: string | null;
  totals: {
    totalEstimate: string;
    subtotal: string;
    subtotalWithDiscount: string | null;
    discount: number | null;
    totalTaxAmount: string;
    taxRate: string;
  };
  commissions: JobCommissionsForDisplay | null;
  openEditCustomerDialog: (() => void) | null;
  onEditCommissionAdjustment:
    | ((commissionAdjustment: ExistingCommissionAdjustment) => void)
    | null;
  /* CHILDREN */
  children: {
    CustomerEstimateStatusButton: React.ReactNode;
    EstimateInfo: React.ReactNode;
    EstimateItemLines: React.ReactNode;
    Breadcrumb?: React.ReactNode;
    TaskInfoAndActionButtons: React.ReactNode;
    EditNotes?: React.ReactNode;
    EditInternalNotes?: React.ReactNode;
    EditDiscount?: React.ReactNode;
    EditLineItemsButton?: React.ReactNode;
    Signatures?: React.ReactNode;
  };
}

export default function ViewEstimatePage({
  customer,
  customerLocation,
  estimateDoc,
  estimateStatus,
  optionalDocs,
  onEditCommissionAdjustment,
  ...props
}: Props) {
  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  return (
    <div
      className={`mx-auto w-full px-2 ${
        optionalDocs ? "pb-0" : "pb-10"
      } xxs:px-4 lg:max-w-screen-lg`}
    >
      {props.children.Breadcrumb}
      {props.merchantLogoURL && (
        <img
          src={props.merchantLogoURL}
          alt="merchant logo"
          className="mx-auto mb-10 max-h-[30rem] w-auto md:mb-0 md:ml-0 md:max-h-full md:max-w-[30rem]"
        />
      )}
      {optionalDocs ? null : (
        <div className="mb-4 mr-auto mt-8 flex w-full items-baseline justify-between">
          <h1 className="text-5xl font-semibold text-primary">
            {strings.ESTIMATE_DETAILS}
          </h1>
          {estimateDoc.estimateNumber && (
            <h2 className="text-3xl font-semibold text-primary">
              # {estimateDoc.estimateNumber}
            </h2>
          )}
        </div>
      )}
      {props.children.TaskInfoAndActionButtons}
      {/* ESTIMATE STATUS */}
      <div className="mt-8 flex flex-col items-baseline gap-4 sm:grid sm:grid-cols-3">
        <div className="col-span-3 flex flex-row items-baseline">
          <div className="mr-4 pr-2 text-2xl capitalize text-primary">
            {strings.ESTIMATE_STATUS}
          </div>
          <span>{estimateStatus}</span>
        </div>
        {props.children.CustomerEstimateStatusButton}
      </div>
      {/* ESTIMATE INFO */}
      <div className="mt-8 flex flex-col items-baseline gap-4">
        {props.children.EstimateInfo}
      </div>
      {/* SHOW CUSTOMER INFORMATION */}
      <div>
        {optionalDocs ? null : (
          <Fragment>
            <hr className="mb-8 mt-12 block w-full border border-gray-200" />
            <div className="flex flex-col space-y-6 sm:grid sm:grid-cols-2 sm:space-x-10 sm:space-y-0">
              <CustomerDetails
                customer={customer}
                openEditCustomerDialog={props.openEditCustomerDialog}
                openCallDialog={null}
              />
              <CustomerAddress customerLocations={[customerLocation]} />
            </div>
          </Fragment>
        )}
        <hr className="mb-8 mt-12 block w-full border border-gray-200" />
        {/* ESTIMATE NOTES */}
        {!estimateDoc.notes && !props.children.EditNotes ? null : (
          <>
            <div className="mb-4 mt-4 flex flex-col justify-between gap-4 sm:flex-row sm:items-start sm:gap-x-2">
              <div className="pr-2 text-2xl capitalize text-primary">
                {strings.NOTES}
              </div>
              {props.children.EditNotes}
            </div>
            <p>{estimateDoc.notes}</p>
            <hr className="mb-8 mt-12 block w-full border border-gray-200" />
          </>
        )}
        {/* ESTIMATE INTERNAL NOTES */}
        {!estimateDoc.internalNotes &&
        !props.children.EditInternalNotes ? null : (
          <>
            <div className="mb-4 mt-4 flex flex-col justify-between gap-4 sm:flex-row sm:items-start sm:gap-x-2">
              <div className="pr-2 text-2xl capitalize text-primary">
                {strings.INTERNAL_NOTES}
              </div>
              {props.children.EditInternalNotes}
            </div>
            <p>{estimateDoc.internalNotes}</p>
            <hr className="mb-8 mt-12 block w-full border border-gray-200" />
          </>
        )}
        {/* LINE ITEMS */}
        <div className="mb-4 mt-4 flex flex-col gap-4 sm:flex-row sm:justify-between">
          <div className="pr-2 text-2xl capitalize text-primary">
            {strings.LINE_ITEMS}
          </div>
          {props.children.EditLineItemsButton}
        </div>
        {props.children.EstimateItemLines}
      </div>

      {/* TOTAL ESTIMATE */}
      <div className="-mt-4 grid grid-cols-3 gap-4 rounded-sm bg-gray-100 px-2 py-4 md:items-start md:gap-x-2">
        {props.children.EditDiscount}
        <div className="col-start-1 pr-2 text-2xl capitalize text-primary">
          Subtotal:
        </div>
        <div className="col-start-3 text-right text-2xl">
          {props.totals.subtotal}
        </div>
        {estimateDoc.discount != null && estimateDoc.discount !== 0 ? (
          <Fragment>
            <div className="pr-2 text-2xl capitalize text-primary">
              Discount:
            </div>
            <div className="col-start-3 justify-self-end text-2xl ">
              {estimateDoc.discount.toFixed(2)}
              {" %"}
            </div>
          </Fragment>
        ) : null}
        {props.totals.discount != null && props.totals.discount > 0 ? (
          <Fragment>
            <div className="pr-2 text-2xl capitalize text-primary">
              Subtotal With Discount:
            </div>
            <div className="col-start-3 text-right text-2xl ">
              {props.totals.subtotalWithDiscount}
            </div>
          </Fragment>
        ) : null}

        <div className="pr-2 text-2xl capitalize text-primary">Tax Amount:</div>
        <div className="col-start-3 text-right text-2xl ">
          {props.totals.totalTaxAmount} ({props.totals.taxRate})
        </div>

        <div className="pr-2 text-3xl font-bold capitalize text-primary">
          Total Estimate:
        </div>
        <div className="col-start-3 text-right text-3xl font-bold ">
          {props.totals.totalEstimate}
        </div>
      </div>
      {/* SIGNATURES */}
      {props.children.Signatures}

      {/* JOB COMMISSIONS */}
      {props.commissions && (
        <div className="col-span-1 mb-4 mt-4 flex flex-col items-end justify-end">
          <div className="pr-2 text-xl capitalize text-primary">
            Total Commission: {props.commissions.baseAndFieldCommissions}
          </div>
          <div className="mb-2 pr-2 text-xl capitalize text-primary">
            ({props.commissions.baseCommission} base +{" "}
            {props.commissions.fieldCommission} upsell)
          </div>
          {props.commissions.commissionAdjustments.length > 0 && (
            <div className="pr-2 text-xl italic text-black">
              Commission Adjustments:
            </div>
          )}
          <hr className="mb-1 mt-1 block w-96 border border-gray-200" />
          {props.commissions?.commissionAdjustments.map((adjustment) => (
            <div
              key={adjustment.id}
              className="col-span-1 mb-4 flex flex-col items-start justify-end gap-4"
            >
              <div className="flex w-full flex-row items-center justify-between">
                <div className="flex flex-col items-start text-lg capitalize text-primary">
                  <span className="font-bold">
                    {currencyFormatter(adjustment.amount, "USD")} -{" "}
                    {adjustment.memo}
                  </span>
                  <span className="py-0 text-sm">
                    Applied On:{" "}
                    {convertToReadableTimestampDate(
                      adjustment.timestampApplied,
                    )}
                  </span>
                  <span className="py-0 text-sm">
                    Employee:{" "}
                    {userDisplayNamesMap[adjustment.appliedToUserID] ??
                      "unknown user"}
                  </span>
                  <span className="py-0 text-sm">
                    Created On:{" "}
                    {convertToReadableTimestamp(adjustment.timestampCreated)}
                  </span>
                </div>
                <StyledTooltip title="Edit Commission Adjustment">
                  <PencilIconWithRef
                    onClick={() => {
                      if (onEditCommissionAdjustment) {
                        onEditCommissionAdjustment(adjustment);
                      }
                    }}
                  />
                </StyledTooltip>
              </div>
              <hr className="mb-1 mt-0 block w-96 border border-gray-200" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
