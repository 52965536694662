/* This example requires Tailwind CSS v2.0+ */
import { XCircleIcon } from "@heroicons/react/24/solid";

interface Props {
  title: string;
  description: string;
}

export default function AlertError({ title, description }: Props) {
  return (
    <div className="border-l-4 border-redFail bg-red-50 p-4 text-left">
      <div className="flex w-full items-center">
        <div className="flex-shrink-0">
          <XCircleIcon
            aria-label="error"
            className="h-5 w-5 text-redFail"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 w-full">
          <p className="text-base text-redFail">{title}</p>
          <p className="text-sm text-gray-900">{description}</p>
        </div>
      </div>
    </div>
  );
}
