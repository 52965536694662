//Libs
import { useRef, useLayoutEffect, useCallback, useState, memo } from "react";
import {
  ICellRendererParams,
  GridApi,
  ColDef,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";

//Local
import {
  ExistingCustomerLocation,
  getReadableLocationAddress,
} from "../../models/customer-location";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import SearchBox from "../SearchBox";
import { ExistingMembership } from "../../models/membership";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface CustomerLocationsListTableProps {
  addressTableData: ExistingCustomerLocation[];
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  membershipTemplateList: ExistingMembershipTemplate[];
  customerLocationMemberships: ExistingMembership[];
  children?: {
    AddNewLocationButton: React.ReactNode;
  };
}

const CustomerLocationsListTable = memo(
  ({
    addressTableData,
    renderIconCell,
    membershipTemplateList,
    customerLocationMemberships,
    ...props
  }: CustomerLocationsListTableProps) => {
    addressTableData.sort((a, b) =>
      getReadableLocationAddress(a).localeCompare(
        getReadableLocationAddress(b),
      ),
    );

    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        const api: GridApi = gridRef.current.api;
        api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const headerStyles =
      "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

    const commonColProps = {
      width: 150,
      flex: 1,
      resizable: true,
      filter: true,
      sortable: true,
      headerClass: headerStyles,
    };

    const renderAddressLine2 = (params: ICellRendererParams): JSX.Element => (
      <div>{params.data.addressLine2 ?? ""}</div>
    );

    const renderLocationName = (params: ICellRendererParams): JSX.Element => (
      <div className="capitalize">{params.data.locationName ?? ""}</div>
    );

    const renderMembership = (
      params: ICellRendererParams,
    ): JSX.Element | null => {
      const filteredCustomerLocationMemberships =
        customerLocationMemberships.filter(
          (membership) => membership.customerLocationID === params.data.id,
        );

      if (filteredCustomerLocationMemberships.length === 0) {
        return null;
      } else {
        return (
          <div className="flex items-center">
            {filteredCustomerLocationMemberships.map((membership) => {
              const template = membershipTemplateList.find(
                (template) => template.id === membership.membershipTemplateID,
              );
              const startDate = convertToReadableTimestampDate(
                membership.membershipStartDate,
              );
              const endDate = membership.membershipEndDate
                ? ` to ${convertToReadableTimestampDate(membership.membershipEndDate)}`
                : ` to - -`;

              return (
                <div
                  key={membership.id}
                  className={`m-1 rounded-full bg-sky-300 px-2.5 pb-1 pt-0.5 text-xs font-medium capitalize text-white`}
                >
                  {template?.title} ({membership.status})
                  <span className="block normal-case">
                    {startDate}
                    {endDate}
                  </span>
                </div>
              );
            })}
          </div>
        );
      }
    };

    const addressTableColumnsDefs: ColDef[] = [
      {
        field: "fullAddress",
        headerName: "Full Address",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        minWidth: 500,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "addressLine2",
        headerName: "Addr. 2",
        minWidth: 100,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        cellRenderer: renderAddressLine2,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "locationName",
        headerName: "Name",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        cellRenderer: renderLocationName,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "notes",
        headerName: "Notes",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];
    // Conditionally add Memberships
    if (membershipTemplateList.length > 0) {
      addressTableColumnsDefs.push({
        headerName: "Memberships",
        cellRenderer: renderMembership,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      });
    }
    // Add Select button
    addressTableColumnsDefs.push({
      cellRenderer: renderIconCell,
      suppressMovable: true,
      suppressHeaderMenuButton: true,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      sortable: false,
      flex: 0,
    });

    return (
      <div className="ag-theme-alpine flex h-[300px] flex-col overflow-auto">
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox onInput={onFilterTextBoxChanged} />
          <div>{props.children?.AddNewLocationButton}</div>
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={addressTableData}
          animateRows={true}
          rowSelection="single"
          columnDefs={addressTableColumnsDefs}
        />
      </div>
    );
  },
  (previous, next) => {
    const isAddressListTheSame = isEqual(
      previous.addressTableData,
      next.addressTableData,
    );

    return isAddressListTheSame;
  },
);

export default CustomerLocationsListTable;
