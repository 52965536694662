export function ConvertArrayToString(array: string[]): string {
  return array.join(", ");
}

export function getPassingOptions(
  formSelectionOptions: Record<string, boolean>,
): string[] {
  const newPassingOptions = Object.keys(formSelectionOptions).filter(
    (optionString) => formSelectionOptions[optionString] === true,
  );
  return newPassingOptions;
}

export function showPassingOptions(
  selectionOptions: string[] | null,
  passingOptions: string[] | null,
) {
  const newObject: Record<string, boolean> = {};
  if (selectionOptions !== null && passingOptions !== null) {
    selectionOptions.filter((element: string) =>
      passingOptions.includes(element)
        ? (newObject[`${element}`] = true)
        : (newObject[`${element}`] = false),
    );
    return newObject;
  } else {
    return newObject;
  }
}
