//Libs
import { DayPilot } from "daypilot-pro-react";
import ResourceData = DayPilot.ResourceData;

//Local
import { CalendarColumn } from "../../../models/scheduling-types";
import { ExistingVehicle } from "../../../models/vehicle";
import { ExistingSiteKeyUserDoc } from "../../../models/site-key-users";
import { ExistingSiteKeyLocation } from "../../../models/site-key-location";
import { ExistingSiteKeyUserPermissions } from "../../../models/site-key-user-permissions";
import { phoneUtils } from "../../../utils/phoneUtils";
import { ExistingTask } from "../../../models/task";

export function generateResourcesForDayPilotCalendar(
  // taskList: ExistingTask[],
  vehicleList: ExistingVehicle[],
  usersList: ExistingSiteKeyUserDoc[],
  siteKeyLocationsList: ExistingSiteKeyLocation[],
  mode: string | null,
  permissionsMap: Record<string, ExistingSiteKeyUserPermissions>,
  dailyVehicleUsersMap: Record<string, string[]>,
  currentTask?: ExistingTask,
) {
  const nonSystemUsersList = usersList.filter(
    (user) => user.systemUser !== true,
  );

  const tempResources: Record<string, CalendarColumn> = {};
  // If we just want to show vehicles with tasks
  // taskList.forEach((task) => {
  //   const vehicleID = task.taskSpecificDetails["assignedVehicleID"];
  //   const assignedVehicleID = vehicleList.filter(
  //     (asset) => asset.id === vehicleID
  //   )[0];
  //
  //   tempResources[assignedVehicleID.id] = {
  //     start: "",
  //     name: assignedVehicleID.description ?? "",
  //     id: assignedVehicleID.id,
  //   };
  // });

  // If we want to show all vehicles
  if (mode === "vehicles") {
    let listOfVehicles = vehicleList;

    if (currentTask) {
      const filtredList = vehicleList.filter(
        (vehicle) =>
          vehicle.locationID === currentTask.locationID &&
          (vehicle.taskTypes.includes(currentTask.taskType) ||
            vehicle.taskTypes.length === 0),
      );

      listOfVehicles = filtredList;
    }

    listOfVehicles.forEach((vehicle) => {
      // get user display names
      const userString = "";
      const userNames = [];

      const dailyVehicleUserList = dailyVehicleUsersMap[vehicle.id] ?? [];

      if (dailyVehicleUserList.length > 0) {
        for (const uid of dailyVehicleUserList) {
          const filteredUsers = nonSystemUsersList.filter((u) => u.id === uid);
          if (filteredUsers.length === 1) {
            userNames.push(
              `${filteredUsers[0].displayName} - ${phoneUtils.display(filteredUsers[0].phone)}`,
            );
          }
        }
      }
      // if (vehicle.defaultSiteKeyUserID?.length > 0) {
      //   for (const uid of vehicle.defaultSiteKeyUserID) {
      //     const filteredUsers = nonSystemUsersList.filter((u) => u.id === uid);
      //     if (filteredUsers.length === 1) {
      //       userNames.push(
      //         `${filteredUsers[0].displayName} - ${phoneUtils.display(filteredUsers[0].phone)}`,
      //       );
      //     }
      //   }
      // }

      // If there are multiple siteKeyLocations (offices), prepend with location title
      // if (siteKeyLocationsList.length > 1) {
      //   const filteredLocations = siteKeyLocationsList.filter(
      //     (l) => l.id === vehicle.locationID,
      //   );
      //   if (filteredLocations.length === 1) {
      //     userString = filteredLocations[0].title + "\n";
      //   }
      // }

      tempResources[vehicle.id] = {
        start: "",
        name: userString + (vehicle.title ?? ""),
        id: vehicle.id,
        locationID: vehicle.locationID,
        taskTypes: vehicle.taskTypes,
        toolTip:
          userNames.length > 0
            ? `Assigned technicians: ${userNames.join(", ")}`
            : "No technicians assigned for this vehicle",
      };

      // if (userNames.length > 1) {
      //   userString = userString + userNames.join("\n");
      //   tempResources[vehicle.id] = {
      //     start: "",
      //     name: userString,
      //     id: vehicle.id,
      //   };
      // } else {
      //   tempResources[vehicle.id] = {
      //     start: "",
      //     name: userString + vehicle.description ?? "",
      //     id: vehicle.id,
      //   };
      //   };
    });
  }

  if (mode === "technicians") {
    const locationIDs = siteKeyLocationsList.map((s) => s.id);
    nonSystemUsersList.forEach((user) => {
      const userDefaultLocationID = permissionsMap[user.id]?.defaultLocationID;
      if (userDefaultLocationID !== null && locationIDs.length > 0) {
        if (!locationIDs.includes(userDefaultLocationID)) {
          // This user has a defauly location and it's not in the supplied list
          // Continue to the next user
          return;
        }
      }
      // If there are multiple siteKeyLocations (offices), prepend with location title
      // if (siteKeyLocationsList.length > 1) {
      //   const filteredLocations = siteKeyLocationsList.filter(
      //     (l) => l.id === vehicle.locationID
      //   );
      //   if (filteredLocations.length === 1) {
      //     userString = filteredLocations[0].title + "\n";
      //   }
      // }

      tempResources[user.id] = {
        start: "",
        name: user.displayName,
        id: user.id,
        locationID: userDefaultLocationID ?? "",
      };
    });
  }

  const sortedResources = Object.values(tempResources).sort((a, b) =>
    (a.name ?? "") < (b.name ?? "") ? -1 : 1,
  );
  sortedResources.unshift({
    start: "",
    name: "Unassigned",
    id: "Unassigned",
    locationID: "",
  });
  return sortedResources;
}

export function generateResourcesForDayPilotScheduler(
  // taskList: ExistingTask[],
  vehicleList: ExistingVehicle[],
  usersList: ExistingSiteKeyUserDoc[],
  siteKeyLocationsList: ExistingSiteKeyLocation[],
  mode: string | null,
  permissionsMap: Record<string, ExistingSiteKeyUserPermissions>,
) {
  const nonSystemUsersList = usersList.filter(
    (user) => user.systemUser !== true,
  );

  const tempResources: ResourceData[] = [];

  // // If we want to show all vehicles
  // if (mode === "vehicles") {
  //   vehicleList.forEach((vehicle) => {
  //     // get user display names
  //     let userString = "";
  //     const userNames = [];
  //     if (vehicle.defaultSiteKeyUserID?.length > 0) {
  //       for (const uid of vehicle.defaultSiteKeyUserID) {
  //         const filteredUsers = nonSystemUsersList.filter((u) => u.id === uid);
  //         if (filteredUsers.length === 1) {
  //           userNames.push(filteredUsers[0].displayName);
  //         }
  //       }
  //     }
  //     userString = "";
  //
  //     // If there are multiple siteKeyLocations (offices), prepend with location title
  //     if (siteKeyLocationsList.length > 1) {
  //       const filteredLocations = siteKeyLocationsList.filter(
  //         (l) => l.id === vehicle.locationID
  //       );
  //       if (filteredLocations.length === 1) {
  //         userString = filteredLocations[0].title + "\n";
  //       }
  //     }
  //
  //     tempResources[vehicle.id] = {
  //       start: "",
  //       name: userString + vehicle.description ?? "",
  //       id: vehicle.id
  //     };
  //
  //     // if (userNames.length > 1) {
  //     //   userString = userString + userNames.join("\n");
  //     //   tempResources[vehicle.id] = {
  //     //     start: "",
  //     //     name: userString,
  //     //     id: vehicle.id,
  //     //   };
  //     // } else {
  //     //   tempResources[vehicle.id] = {
  //     //     start: "",
  //     //     name: userString + vehicle.description ?? "",
  //     //     id: vehicle.id,
  //     //   };
  //     // }
  //   });
  // }

  if (mode === "technicians") {
    const locationIDs = siteKeyLocationsList.map((s) => s.id);

    // Build the location resources
    const locationResources: Record<string, ResourceData> = {};
    siteKeyLocationsList.sort((a, b) => a.title.localeCompare(b.title));
    siteKeyLocationsList.forEach((l) => {
      locationResources[l.id] = {
        name: l.title,
        id: l.id,
        expanded: true,
        children: [],
        eventHeight: 25,
      };
    });
    locationResources["noDefaultLocation"] = {
      name: "No Default Location",
      id: "noDefaultLocation",
      expanded: true,
      children: [],
      eventHeight: 25,
    };

    nonSystemUsersList.forEach((user) => {
      const userDefaultLocationID = permissionsMap[user.id]?.defaultLocationID;
      if (userDefaultLocationID === null) {
        // Push this user without nesting under a location resource
        locationResources["noDefaultLocation"].children!.push({
          name: user.displayName,
          id: user.id,
          eventHeight: 50,
        });
      } else {
        if (
          locationIDs.length > 0 &&
          !locationIDs.includes(userDefaultLocationID)
        ) {
          // This user has a default location and it's not in the supplied list
          // Continue to the next user
          return;
        } else {
          if (Object.keys(locationResources).includes(userDefaultLocationID)) {
            locationResources[userDefaultLocationID].children!.push({
              name: user.displayName,
              id: user.id,
              eventHeight: 50,
            });
          }
        }
      }
    });
    const locationResourcesWithChildren: ResourceData[] = [];
    Object.values(locationResources).forEach((l) => {
      if (l.children && l.children.length > 0) {
        locationResourcesWithChildren.push(l);
      }
    });
    // Sort children by displayName
    locationResourcesWithChildren.forEach((l) => {
      l.children = l.children?.sort((a, b) =>
        (a.name ?? "") < (b.name ?? "") ? -1 : 1,
      );
    });
    tempResources.push(...locationResourcesWithChildren);
  }

  // const sortedResources = Object.values(tempResources).sort((a, b) =>
  //   (a.name ?? "") < (b.name ?? "") ? -1 : 1
  // );
  tempResources.unshift({
    name: "Unassigned",
    id: "Unassigned",
  });
  return tempResources;
}
