// Libs
import { Switch } from "@headlessui/react";
import { forwardRef } from "react";

//Local
import { StyledTooltip } from "./StyledTooltip";

type Ref = HTMLSpanElement;
type Props = {
  checked: boolean;
  /** for label's "for" attribute  */
  name: string;
  /** for label's display text & for screen reader */
  readableName: string;
  tooltipText?: string;
  onChange: (value: unknown) => void;
  onBlur: () => void;
} & React.ComponentPropsWithRef<"button">;

const StyledSwitchGroup = forwardRef<Ref, Props>(
  (
    { checked, name, readableName, tooltipText, onChange, onBlur },
    _ref,
  ): JSX.Element => {
    return (
      <StyledTooltip title={tooltipText}>
        <span className="space-x-4">
          <Switch.Group>
            <Switch.Label htmlFor={name}>{readableName}</Switch.Label>
            <Switch
              value={checked.toString()}
              checked={checked}
              onChange={onChange}
              onBlur={onBlur}
              className={`${
                checked ? "bg-greenPass" : "bg-gray-200"
              } relative inline-flex h-6 w-11 flex-shrink-0 items-center rounded-full`}
            >
              <span className="sr-only">
                {checked ? "Disable " : "Enable "}
                {readableName}
              </span>
              <span
                className={`${
                  checked ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
              />
            </Switch>
          </Switch.Group>
        </span>
      </StyledTooltip>
    );
  },
);
export default StyledSwitchGroup;
