// Local
import {
  ExistingCustomField,
  SelectionCustomField,
} from "../../models/custom-field";
import { Json } from "../../models/json-type";
import {
  convertISOToLuxonDT,
  convertFSTimestampToLuxonDT,
  convertDecimalToHoursMinutes,
} from "../../utils";
import { IMultipleUid_AssignUser } from "./MultipleUidDialog";

/**
 * Used for retrieving the custom field's existing response, to use as
 * react-hook-form's defaultValue.
 */
export function getDefaultValuesForExistingResponse(args: {
  type: ExistingCustomField["fieldType"];
  existingResponse: Json;
  selectionOptions: SelectionCustomField["selectionOptions"] | null;
  userDisplayNamesMap: Record<string, string>;
  userList: IMultipleUid_AssignUser[];
}): Json | Date {
  switch (args.type) {
    case "string":
    case "string-textarea": {
      return typeof args.existingResponse === "string"
        ? args.existingResponse
        : "";
    }
    case "number":
    case "currency": {
      return typeof args.existingResponse === "number"
        ? args.existingResponse
        : "";
    }
    case "bool": {
      return args.existingResponse === true ? "Yes" : "No";
    }
    case "timestamp": {
      let timestampDV: Date | null;
      try {
        if (typeof args.existingResponse === "string") {
          const isoToLuxon = convertISOToLuxonDT(args.existingResponse);
          timestampDV = isoToLuxon.toJSDate();
        } else {
          const objToLuxon = convertFSTimestampToLuxonDT(args.existingResponse);
          timestampDV = objToLuxon.toJSDate();
        }
      } catch (e) {
        timestampDV = null;
      }
      return timestampDV;
    }
    case "selection": {
      // NOTE: if existingResponse isn't `null`, it's a KEY from the selectionOptions object.
      if (args.selectionOptions !== null) {
        if (Object.keys(args.selectionOptions).length === 1) {
          // we will be displaying a checkbox
          return typeof args.existingResponse === "string" ? true : false;
        } else if (Object.keys(args.selectionOptions).length === 2) {
          // we will be displaying radio buttons
          return typeof args.existingResponse === "string"
            ? args.selectionOptions[args.existingResponse]
            : null;
          // ^ this WAS undefined in the prior setup IMPORTANT:
        } else {
          // we will be displaying a dropdown
          return typeof args.existingResponse === "string"
            ? args.selectionOptions[args.existingResponse]
            : "";
        }
      } else {
        return null;
      }
    }
    case "uid": {
      if (typeof args.existingResponse === "string") {
        return args.userDisplayNamesMap[args.existingResponse];
      } else {
        return "";
      }
    }
    case "multiple-uid": {
      // handle transforming existingResponse within the Input component.
      return args.existingResponse;
    }
    case "string-array": {
      if (
        // non-empty array
        Array.isArray(args.existingResponse) &&
        args.existingResponse.length > 0
      ) {
        if (args.existingResponse.every((res) => typeof res === "string")) {
          return args.existingResponse;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
    case "hours-minutes": {
      if (typeof args.existingResponse === "number") {
        const { hours, minutes } = convertDecimalToHoursMinutes(
          args.existingResponse,
        );
        return [hours, minutes];
      } else {
        return ["", ""];
      }
    }
    default: {
      const _exhaustivenessCheck: never = args.type;
      return _exhaustivenessCheck;
    }
  }
}
