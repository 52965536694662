//Libs
import {
  Fragment,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  RowClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

//Local
import { ExistingMembershipTemplate } from "../../models/membership-template";
import * as strings from "../../strings";
import SearchBox from "../../components/SearchBox";
import currencyFormatter from "../../currency";

interface Props {
  /* DATA */
  membershipTemplateList: ExistingMembershipTemplate[];
  currency: string;
  /* FUNCTIONS */
  onEditMambershipTemplate: (
    membershipTemplate: ExistingMembershipTemplate,
  ) => void;
  addNewMembershipTemplateButton: React.ReactNode;
}

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

export default function MembershipTemplateListPage({
  membershipTemplateList,
  currency,
  onEditMambershipTemplate,
  addNewMembershipTemplateButton,
}: Props) {
  return (
    <Fragment>
      <h1 className="mb-8 text-5xl font-semibold text-primary">
        {strings.MEMBERSHIP_TEMPLATES}
      </h1>
      <div className={"ag-theme-alpine flex h-full min-h-[600px] flex-col"}>
        <MembershipTemplateListTable
          membershipTemplateList={membershipTemplateList}
          currency={currency}
          addNewMembershipTemplateButton={addNewMembershipTemplateButton}
          onEditMambershipTemplate={onEditMambershipTemplate}
        />
      </div>
    </Fragment>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  flex: 1,
};

interface MembershipTemplateListTableProps {
  membershipTemplateList: Props["membershipTemplateList"];
  currency: Props["currency"];
  onEditMambershipTemplate: Props["onEditMambershipTemplate"];
  addNewMembershipTemplateButton: React.ReactNode;
}

/* TABLE COMPONENT */
const MembershipTemplateListTable = memo(
  ({
    membershipTemplateList,
    currency,
    onEditMambershipTemplate,
    addNewMembershipTemplateButton,
  }: MembershipTemplateListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const renderPrice = useCallback(
      function (params: ICellRendererParams) {
        return <div>{currencyFormatter(params.data.price, currency)}</div>;
      },
      [currency],
    );

    const formattedText = (params: ICellRendererParams): JSX.Element => {
      return <div className="capitalize">{params.value}</div>;
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Title",
        field: "title",
        width: 200,
        cellRenderer: formattedText,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.value;
        },
      },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Price $",
        field: "price",
        cellRenderer: renderPrice,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Discount %",
        field: "discount",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Invoice Frequency",
        field: "invoiceFrequency",
        cellRenderer: formattedText,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <Fragment>
        <div className="flex flex-col justify-between gap-6 sm:flex-row sm:items-end">
          <SearchBox onInput={onFilterTextBoxChanged} />
          <div className="flex flex-col gap-6">
            {addNewMembershipTemplateButton}
          </div>
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={membershipTemplateList}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
          onRowClicked={(event: RowClickedEvent) =>
            onEditMambershipTemplate(event.data)
          }
        />
      </Fragment>
    );
  },
);
