//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import BaseModal from "./BaseModal";
import BaseButtonSecondary from "./BaseButtonSecondary";
import { buttons, CONFIRM_DELETE_PAYMENT, DELETE_PAYMENT } from "../strings";
import BaseButtonPrimary from "./BaseButtonPrimary";
import { ExistingStiltPayment } from "../models/stilt-payment";
import currencyFormatter from "../currency";

export interface DeletePaymentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDelete: () => Promise<void>;
  isSubmitting: boolean;
  payment: ExistingStiltPayment;
  currency: string;
}

export default function DeletePaymentDialog(props: DeletePaymentDialogProps) {
  return (
    <BaseModal
      open={props.isOpen}
      closeModal={() => {}}
      allowOverflowY={true}
      title={
        <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
          <h1 className="inline-flex items-center text-xl font-semibold ">
            {DELETE_PAYMENT}
          </h1>
          <button type="button" onClick={() => props.onClose()}>
            <XMarkIcon
              aria-label="confirm delete equipment"
              className="h-6 text-white"
            />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        {props.payment.amount < 0 && (
          <div className="font-light italic">{`This payment record is for a refund. When it is deleted, the Amount Due on this invoice will decrease by ${currencyFormatter(-props.payment.amount, props.currency)}.`}</div>
        )}
        {props.payment.amount > 0 && (
          <div className="font-light italic">{`When this payment record is deleted, the Amount Due on this invoice will increase by ${currencyFormatter(props.payment.amount, props.currency)}.`}</div>
        )}
        <div className="font-bold">{CONFIRM_DELETE_PAYMENT}</div>

        <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase xs:w-52"
            onClick={props.onClose}
          >
            {buttons.NO}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            disabled={props.isSubmitting}
            isBusy={props.isSubmitting}
            onClick={props.handleConfirmDelete}
            className="w-full justify-center uppercase xs:w-52"
          >
            {buttons.YES}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
