//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

export interface MembershipTemplate {
  // a custom title for the name of the membership eg. "comfort club yearly subscription"
  title: string;
  description: string;
  price: number; // from priceBookItem

  // If a customer has this membership, this is the discount that applies to
  // other jobs and work
  discount: number;

  // How frequently are we sending renewal invoices?
  // Are they part of one of the tasks or separate?
  //
  // withLastTaskBeforeRenewal: means the renewal invoice will be put on the last task before the membership is up
  //     For example, if it's a monthly PM and the invoice is sent annually, then the 12th month's task will have an
  //     invoice attached to it for the next annual renewal
  // separately: means the renewal invoice will be sent at the invoiceRenewalFrequency regardless of the status and scheduling of the tasks
  // manual: means the invoices will not be generated and sent with any automation
  invoiceMethod: InvoiceMethod;
  invoiceFrequency: InvoiceFrequency;
  delayedStartDaysAllowed: number;

  // Logic to tell the CF how to create automatic tasks, which might be at a
  // different frequency from the invoiceFrequency
  taskGeneration: TaskGeneration[];

  // Do we still invoice even if all tasks haven't been completed?
  // How strict are we if the invoice hasn't been paid? Does the discount still apply?
  // Need corresponding PriceBookItem to use for invoices
  priceBookItemID: string | null;
  // If this is not null, then this priceBookItem will be used for renewal
  // invoices. Sometimes customer have the renewals at a discounted rate
  renewalPriceBookItemID: string | null;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
  deleted: boolean;
}

export interface ExistingMembershipTemplate extends MembershipTemplate {
  id: string;
  refPath: string;
}

export const invoiceFrequency = [
  "annual",
  "semiannual",
  "quarterly",
  "monthly",
  // "biweekly",
  // "weekly",
  "indefinite",
] as const;

export type InvoiceFrequency = (typeof invoiceFrequency)[number];

export const invoiceMethod = ["withTask", "automatic", "manual"] as const;
export type InvoiceMethod = (typeof invoiceMethod)[number];

export function getReadableInvoiceMethod(value: unknown): string {
  const readable: string | undefined =
    readableInvoiceMethodMap[value as InvoiceMethod];
  if (!readable) return "UNKNOWN";
  return readable;
}

const readableInvoiceMethodMap: Record<InvoiceMethod, string> = {
  automatic: "Automatic",
  manual: "Manual",
  withTask: "With Task",
};
export interface TaskGeneration {
  // Data needed for creating the craftRecord and task
  craftType: number;
  taskType: number;
  taskStatus: number;
  description: string;
  locationID: string;
  craftDetails: { [key: string]: any };
  taskSpecificDetails: { [key: string]: any };

  // For serverJobs to know when and how often to create tasks
  daysAfterRenewal: number | null;

  options: { [key: string]: any }; // to relay any other custom logic to the CF
}

export type MembershipDiscount = Pick<
  MembershipTemplate,
  "title" | "discount"
> & {
  value: boolean;
};

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  MembershipTemplate,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface MembershipTemplate_CreateAPI extends withoutTimestamps {
  siteKey: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type MembershipTemplate_UpdateAPI = Omit<
  Partial<ExistingMembershipTemplate>,
  "timestampCreated" | "timestampLastModified"
>;

export type AddNewMembershipTemplate = Omit<
  MembershipTemplate,
  "timestampCreated" | "timestampLastModified" | "createdBy" | "lastModifiedBy"
>;

/** Utilities for interacting with MembershipTemplate objects  */
export const MembershipTemplateManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingMembershipTemplate object.
   */
  createFromFirestoreSnapshot: createMembershipTemplateFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateMembershipTemplate,
  /** Validate a MembershipTemplate doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateMembershipTemplateWithFallbacks,
  /** Validate a new membership template. For the create endpoint. */
  parseCreate: validateMembershipTemplate_Create,
  /** Validate an existing membership template. For the update endpoint. */
  parseUpdate: validateMembershipTemplate_Update,
};

/**
 * Convert the Document Snapshot into a validated ExistingMembershipTemplate object.
 */
function createMembershipTemplateFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingMembershipTemplate {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateMembershipTemplateWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateMembershipTemplateWithFallbacks(
  value: unknown,
): MembershipTemplate {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = membershipTemplateSchemaWithFallbacks.parse(value);
  return result;
}

function validateMembershipTemplate(value: unknown): MembershipTemplate {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = membershipTemplateSchema.parse(value);
  return result;
}

function validateMembershipTemplate_Create(
  value: unknown,
): MembershipTemplate_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return membershipTemplateSchema_CreateAPI.parse(value);
}

function validateMembershipTemplate_Update(
  value: unknown,
): MembershipTemplate_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return membershipTemplateSchema_UpdateAPI.parse(value);
}

// #region SECTION: Schemas
export const taskGenerationSchema = z.object({
  craftType: z.number(),
  taskType: z.number(),
  taskStatus: z.number(),
  description: z.string().min(1).max(2000),
  locationID: z.string().min(1).max(200),
  daysAfterRenewal: z.number().nullable(),
  options: z.record(z.any()),
  taskSpecificDetails: z.record(z.any()),
  craftDetails: z.record(z.any()),
});

const membershipTemplateSchema = z.object({
  title: z.string().min(1).max(200),
  description: z.string().min(1).max(4000),
  discount: z.number(),
  invoiceMethod: z.enum(invoiceMethod),
  invoiceFrequency: z.enum(invoiceFrequency),
  delayedStartDaysAllowed: z.number(),
  priceBookItemID: z.string().min(1).max(200).nullable(),
  renewalPriceBookItemID: z.string().min(1).max(200).nullable(),
  price: z.number(),
  taskGeneration: z.array(taskGenerationSchema),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  deleted: z.boolean(),
});

const membershipTemplateSchemaWithFallbacks = z.object({
  title: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'title'",
  ),
  description: zFallback(
    z.string().min(1).max(4000),
    "unknown",
    "membershipSchemaWithFallbacks: 'description'",
  ),
  discount: zFallback(
    z.number(),
    0,
    "membershipSchemaWithFallbacks: 'discount'",
  ),
  invoiceMethod: zFallback(
    z.enum(invoiceMethod),
    "manual",
    "membershipSchemaWithFallbacks: 'invoiceMethod'",
  ),
  invoiceFrequency: zFallback(
    z.enum(invoiceFrequency),
    "indefinite",
    "membershipSchemaWithFallbacks: 'invoiceFrequency'",
  ),
  delayedStartDaysAllowed: zFallback(
    z.number(),
    0,
    "membershipSchemaWithFallbacks: 'delayedStartDaysAllowed'",
  ),
  priceBookItemID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'priceBookItemID'",
  ),
  renewalPriceBookItemID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'priceBookItemID'",
  ),
  price: zFallback(z.number(), 0, "membershipSchemaWithFallbacks: 'price'"),
  taskGeneration: zFallback(
    z.array(taskGenerationSchema),
    [],
    "membershipSchemaWithFallbacks: 'taskGeneration'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "membershipSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "membershipSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "membershipSchemaWithFallbacks: 'deleted'",
  ),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = membershipTemplateSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const membershipTemplateSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const membershipTemplateSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();

// #endregion
