// Libs
import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";

// Local
import {
  ExistingCustomer,
  ExistingCustomerWithLocations,
} from "../../models/customer";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import * as strings from "../../strings";
import ChipTag from "../../components/ChipTag";
import SearchBox from "../../components/SearchBox";
import { customerWithLocationsTableCell } from "../../components/customers/CustomerComponents";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface Props {
  onSearch: React.FormEventHandler<HTMLInputElement>;
  customersWithLocations: ExistingCustomerWithLocations[];
  onSelectCustomer: (customerDoc: ExistingCustomer) => void;
  addCustomerButton?: React.ReactNode;
}

export default function CreateTaskCustomerTablePage(props: Props) {
  const onSelectCustomer = props.onSelectCustomer;

  const renderSelectCell = useCallback(
    function (params: ICellRendererParams) {
      return (
        <div className="flex justify-center">
          <BaseButtonSecondary
            onClick={() => onSelectCustomer(params.data)}
            className="mt-0.5 min-w-fit sm:w-36"
            data-testid={`select-customer-${params.data.id}`}
          >
            {strings.SELECT}
          </BaseButtonSecondary>
        </div>
      );
    },
    [onSelectCustomer],
  );

  return (
    <div className="ag-theme-alpine my-8 flex h-[600px] flex-col">
      <CustomerTable
        onSearch={props.onSearch}
        customerList={props.customersWithLocations}
        renderSelectCell={renderSelectCell}
        addCustomerButton={props.addCustomerButton}
      />
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  minWidth: 150,
  flex: 1,
};

interface CustomerTableProps {
  onSearch: Props["onSearch"];
  customerList: Props["customersWithLocations"];
  renderSelectCell: (params: ICellRendererParams) => JSX.Element;
  addCustomerButton?: Props["addCustomerButton"];
}

// Memoized table
export const CustomerTable = React.memo(
  (props: CustomerTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    function renderTagsCell(params: ICellRendererParams): JSX.Element | null {
      const tagsList: ExistingCustomer["tags"] = params.value;
      if (Array.isArray(tagsList)) {
        return (
          <div className="-ml-2 flex h-10 items-center">
            {tagsList.map((tag) => {
              if (typeof tag === "string") {
                const key = tag + params.node.data.id;
                return <ChipTag tag={tag} key={key} />;
              } else {
                return null;
              }
            })}
          </div>
        );
      } else {
        return null;
      }
    }

    const renderCustomer = (params: ICellRendererParams): JSX.Element => {
      return customerWithLocationsTableCell(params);
    };

    const columnDefs: ColDef[] = [
      {
        cellRenderer: props.renderSelectCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        suppressAutoSize: true,
        suppressSizeToFit: true,
        sortable: false,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        minWidth: 200,
      },
      {
        headerName: "Customer",
        cellRenderer: renderCustomer,
        tooltipValueGetter: () => "Show Customer",
        autoHeight: true,
        minWidth: 400,
        flex: 2,
      },
      {
        headerName: "Email",
        field: "email",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Phone #",
        field: "phone",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Tags",
        field: "tags",
        cellRenderer: renderTagsCell,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Notes",
        field: "notes",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <Fragment>
        <div className="flex items-center justify-between">
          <SearchBox onInput={props.onSearch} id="search-customer" />
          {props.addCustomerButton}
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={props.customerList}
          defaultColDef={commonColProps}
          columnDefs={columnDefs}
          className="mt-5 shadow"
          animateRows={true}
          rowSelection="single"
          rowHeight={50}
          cacheQuickFilter={true}
        />
      </Fragment>
    );
  },
  (previous, next) => {
    const isCustomerListTheSame = isEqual(
      previous.customerList,
      next.customerList,
    );
    const isRenderSelectCellTheSame =
      previous.renderSelectCell === next.renderSelectCell;
    // fixes a bug where it would not update the UI if click tabs between two filters where both have 0 results.
    const bothListAreEmpty =
      previous.customerList.length === 0 && next.customerList.length === 0;

    return (
      isCustomerListTheSame && !bothListAreEmpty && isRenderSelectCellTheSame
    );
  },
);
