//Libs

//Local

interface CustomFieldsProps {
  //CHILDRENS
  children: {
    Breadcrumbs: React.ReactNode;
    SiteKeyDropdown: React.ReactNode;
    WorkTypeDropdown: React.ReactNode;
    SidebarMenu: React.ReactNode;
    DropdownMenu: React.ReactNode;
    CustomFieldsCardsSection: React.ReactNode;
    AddCustomFieldDialog: React.ReactNode;
    TaskTypeCardsSection: React.ReactNode;
    WorkTypeIcon: React.ReactNode;
  };
}

export default function CustomFieldsPage(props: CustomFieldsProps) {
  return (
    <div className="md:flex">
      {/* sidebar menu bigger than a smartphone viewport */}
      {props.children.SidebarMenu}
      <div className="grid w-full max-w-screen-xl pb-16 sm:px-8 md:pl-5 md:pr-2 lg:max-w-screen-2xl lg:pr-0 xl:pl-8">
        <div className="grid grid-cols-1">
          {props.children.Breadcrumbs}

          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="mt-3 flex items-center justify-start text-lg font-bold sm:text-3xl">
              {props.children.WorkTypeIcon}
              <h1>Custom Fields</h1>
            </div>
            <div className="my-4 grid grid-cols-1 justify-end gap-3 sm:flex md:mb-0">
              {/* Site Key Dropdown */}
              <div className="z-40">{props.children.SiteKeyDropdown}</div>
              {/* Work Type dropdown */}
              <div className="z-30">{props.children.WorkTypeDropdown}</div>
            </div>
          </div>
        </div>
        {/* Dropdown menu on smartphone viewport */}
        <div className="md:hidden">{props.children.DropdownMenu}</div>
        {/* Custom Fields Cards Section */}
        {props.children.CustomFieldsCardsSection}
        {/* Task Types Section */}
        {props.children.TaskTypeCardsSection}
      </div>
      {props.children.AddCustomFieldDialog}
    </div>
  );
}
