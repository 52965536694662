export function handleTags(tags: string): string[] {
  let element = tags.split(",").map((item) => {
    return item.trim();
  });
  // "squirrels, penguines...  {tab}"
  // ["squirrels", " penguins", " puppies", " cats"]
  element = element.filter((item) => {
    const isNotEmpty = item.length > 0;
    return isNotEmpty;
  });
  return element;
}
