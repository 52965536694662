// Dialog to send a custom email to a customer
import { DbWrite } from "../../database";
import { logger } from "../../logging";
import BaseModal from "../BaseModal";
import MultipleEmailSelector from "../MultipleEmailSelector";
import { useEffect, useState } from "react";
import BaseInputText from "../BaseInputText";
import BaseInputTextArea from "../BaseInputTextArea";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ErrorMessage } from "../ErrorMessage";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import SendIcon from "@mui/icons-material/Send";

interface Props {
  siteKey: string;
  isDialogOpen: boolean;
  closeDialog: () => void;
  emailBody?: string | null;
  emailSubject?: string | null;
  title: string;
  customerID: string;
  customerEmailList: string[] | null;
}

export default function SendCustomEmailDialog(props: Props): JSX.Element {
  const [selectedEmailList, setSelectedEmailList] = useState<string[]>([]);
  const [errorString, setErrorString] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState(false);

  // these are used when sending Estimate or Parts Info via email
  const [emailBody, setEmailBody] = useState<string>(props.emailBody ?? "");
  const [emailSubject, setEmailSubject] = useState<string>(
    props.emailSubject ?? "",
  );

  useEffect(() => {
    if (props.emailBody) {
      setEmailBody(props.emailBody);
    }
    if (props.emailSubject) {
      setEmailSubject(props.emailSubject);
    }
  }, [props.emailBody, props.emailSubject]);

  function closeAndReset() {
    setSelectedEmailList([]);
    setErrorString(null);
    props.closeDialog();
  }

  const dialogHeader = (
    <div className="flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {props.title}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon aria-label="close email dialog" className="h-6 text-white" />
      </button>
    </div>
  );

  const displayError = errorString && (
    <span className="mx-auto mt-4">
      <ErrorMessage
        message={errorString}
        clearMessage={() => setErrorString(null)}
      />
    </span>
  );

  const sendEmailButton = (
    <BaseButtonPrimary
      type="button"
      busyText={strings.buttons.BUSY_SENDING}
      isBusy={isBusy}
      onClick={async () => {
        if (selectedEmailList.length === 0) {
          setErrorString(strings.ERROR_EMAIL);
          return;
        }

        setIsBusy(true);

        await sendEmail(selectedEmailList, emailBody, emailSubject);

        setIsBusy(false);
        setSelectedEmailList([]);
        closeAndReset();
      }}
    >
      <SendIcon className="h-full" />
    </BaseButtonPrimary>
  );

  async function sendEmail(
    emailAddressList: string[],
    emailBody: string,
    emailSubject: string,
  ) {
    try {
      await DbWrite.emails.sendCustomEmail({
        customerEmailList: emailAddressList,
        emailBody,
        emailSubject,
        customerID: props.customerID,
        siteKey: props.siteKey,
      });
    } catch (error) {
      logger.error("sendHandlePartsEmail error:", error);
    }
  }

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={props.isDialogOpen}
      title={dialogHeader}
      parentDivStyles="inline-block transform max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all overflow-visible"
    >
      <div className="relative flex flex-col justify-center space-y-4 p-8 text-lg sm:gap-8 sm:space-y-0">
        <MultipleEmailSelector
          sendEmailButton={sendEmailButton}
          selectedEmailList={selectedEmailList}
          setSelectedEmailList={setSelectedEmailList}
          emailList={props.customerEmailList ? props.customerEmailList : []}
          setErrorString={setErrorString}
          includeJobPhotosSwitch={false}
        />
        <BaseInputText
          text="Email Subject"
          admin={true}
          required={true}
          value={emailSubject ?? ""}
          onChange={(event) => setEmailSubject(event.target.value)}
          className="w-full"
        />
        <BaseInputTextArea
          text="Email Body"
          admin={true}
          required={true}
          value={emailBody ?? ""}
          onChange={(event) => setEmailBody(event.target.value)}
          className="w-full"
          rows={6}
        ></BaseInputTextArea>
        <BaseButtonSecondary
          type="button"
          onClick={closeAndReset}
          className="w-full"
        >
          CLOSE WITHOUT SENDING
        </BaseButtonSecondary>
        {displayError}
      </div>
    </BaseModal>
  );
}
