//Libs
import React from "react";
import { ResponseStatus } from "../models/checklist-response-status";

//Local

interface Props {
  onHandleFilter: (filter: ResponseStatus) => void;
  selectedFilter: string;
  children: {
    selectAllCheckBox: React.ReactNode;
    tagsCheckboxes: React.ReactNode;
    actionButtons: React.ReactNode;
  };
}

export default function FilterForResponsesCards(props: Props) {
  return (
    <div className="flex flex-col space-y-2">
      <div className="py-2 text-left text-lg font-medium text-primary">
        Status
      </div>
      {/* Filter Response Cards depending on the Response Passing value */}
      <div className="grid cursor-pointer grid-cols-2 items-center justify-center gap-2 text-primary sm:float-left sm:flex sm:w-full sm:gap-0 sm:rounded-l-full sm:rounded-r-full sm:border ">
        <div
          onClick={() => {
            props.onHandleFilter(ResponseStatus.ALL);
          }}
          className={`w-32 rounded-full border py-2 text-sm hover:bg-primary hover:text-white sm:w-40 sm:rounded-l-full sm:rounded-r-none sm:border-0 sm:border-r sm:px-4 ${
            props.selectedFilter === ResponseStatus.ALL
              ? "bg-primary text-white"
              : ""
          }`}
        >
          {ResponseStatus.ALL}
        </div>
        <div
          onClick={() => props.onHandleFilter(ResponseStatus.NOT_PASSING)}
          className={`w-32 rounded-full border py-2 text-sm hover:bg-primary hover:text-white sm:w-40 sm:rounded-none sm:border-0 sm:border-r sm:px-8 ${
            props.selectedFilter === ResponseStatus.NOT_PASSING
              ? "bg-primary text-white"
              : ""
          }`}
        >
          {ResponseStatus.NOT_PASSING}
        </div>
        <div
          onClick={() => props.onHandleFilter(ResponseStatus.INCOMPLETE)}
          className={`w-32 rounded-full border py-2 text-sm hover:bg-primary hover:text-white sm:w-40 sm:rounded-none sm:border-0 sm:border-r sm:px-8 ${
            props.selectedFilter === ResponseStatus.INCOMPLETE
              ? "bg-primary text-white"
              : ""
          }`}
        >
          {ResponseStatus.INCOMPLETE}
        </div>
        <div
          onClick={() => props.onHandleFilter(ResponseStatus.COMPLETE)}
          className={`w-32 rounded-full border px-4 py-2 text-sm hover:bg-primary hover:text-white sm:w-40 sm:rounded-l-none sm:rounded-r-full sm:border-0 ${
            props.selectedFilter === ResponseStatus.COMPLETE
              ? "bg-primary text-white"
              : ""
          }`}
        >
          {ResponseStatus.COMPLETE}
        </div>
      </div>
      <div className="py-2 text-left text-lg font-medium text-primary">
        Tags
      </div>
      <div className="flex justify-between">
        <div className="pl-4">Select All</div>
        {props.children.selectAllCheckBox}
      </div>
      <div className="mb-4 max-h-80 overflow-y-auto rounded-lg border py-4 pl-4">
        {props.children.tagsCheckboxes}
      </div>
      <div className="flex justify-between pt-4">
        {props.children.actionButtons}
      </div>
    </div>
  );
}
