//Libs
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseInputNumber from "../BaseInputNumber";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { logger as devLogger } from "../../logging";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import ButtonColored from "../ButtonColored";
import { Timestamp } from "firebase/firestore";
import {
  CreateCommissionAdjustment,
  ExistingCommissionAdjustment,
} from "../../models/commission-adjustment";
import DatePicker from "react-datepicker";
import { CustomDateInput } from "../DatePickerWithManualEntry";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";

type TextareaProps = {
  text: string;
  value?: string;
} & React.ComponentPropsWithRef<"textarea">;
type Ref = HTMLTextAreaElement;
const LocalTextarea = forwardRef<Ref, TextareaProps>(
  (props, ref): JSX.Element => {
    return (
      <div className="relative">
        <textarea
          {...props}
          ref={ref}
          id="description"
          rows={2}
          className="block w-full rounded border border-black p-4 text-gray-700 focus:border-primaryLight focus:outline-none focus:ring focus:ring-primaryLight sm:text-sm"
          value={props.value}
        />
        <label
          htmlFor="description"
          className="absolute -top-3 left-3 bg-white px-2"
        >
          {props.text}
        </label>
      </div>
    );
  },
);

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  userPermissions: ExistingSiteKeyUserPermissions;
  commissionAdjustmentDoc: ExistingCommissionAdjustment | null;
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSaveNewCommissionAdjustment: (
    newCommissionAdjustment: CreateCommissionAdjustment,
  ) => Promise<void>;
  // handleDeleteCommissionAdjustment: (
  //   commissionAdjustmentID: string,
  // ) => Promise<void>;
  handleEditCommissionAdjustment: (
    updateCommissionAdjustment: Partial<ExistingCommissionAdjustment>,
  ) => Promise<void>;
}

export const CommissionAdjustmentFormSchema = z.object({
  amount: z.union([z.number(), z.literal("")]),
  timestampApplied: z.instanceof(Date),
  appliedToUserID: z.string(),
  memo: z.string(),
});

export type CommissionAdjustmentFormState = z.infer<
  typeof CommissionAdjustmentFormSchema
>;

export default function AddEditCommissionAdjustmentDialog({
  commissionAdjustmentDoc,
  userPermissions,
  closeDialog,
  handleSaveNewCommissionAdjustment,
  isDialogOpen,
  handleEditCommissionAdjustment,
  // handleDeleteCommissionAdjustment,
}: Props) {
  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] =
    useState<ExistingSiteKeyUserDoc | null>(null);
  const [userSearchString, setUserSearchString] = useState<string>("");

  const buttonRef = useRef<HTMLButtonElement>(null);

  const CommissionAdjustmentFormDefaultValues: CommissionAdjustmentFormState =
    useMemo(() => {
      if (commissionAdjustmentDoc != null) {
        return {
          amount: commissionAdjustmentDoc.amount,
          timestampApplied: commissionAdjustmentDoc.timestampApplied.toDate(),
          appliedToUserID: commissionAdjustmentDoc.appliedToUserID,
          memo: commissionAdjustmentDoc.memo,
        };
      } else {
        return {
          amount: "",
          timestampApplied: Timestamp.now().toDate(),
          appliedToUserID: "",
          memo: "",
        };
      }
    }, [commissionAdjustmentDoc, userPermissions]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<CommissionAdjustmentFormState>({
    defaultValues: CommissionAdjustmentFormDefaultValues,
    resolver: zodResolver(CommissionAdjustmentFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(CommissionAdjustmentFormDefaultValues);
  }, [CommissionAdjustmentFormDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    closeDialog();
  }

  const onSubmit: SubmitHandler<CommissionAdjustmentFormState> = async (
    formValues,
  ) => {
    if (commissionAdjustmentDoc != null) {
      const partialCommissionAdjustmentValue: Partial<ExistingCommissionAdjustment> =
        {
          amount: formValues.amount === "" ? 0 : formValues.amount,
          timestampApplied: Timestamp.fromDate(formValues.timestampApplied),
          appliedToUserID:
            selectedUser?.id ?? commissionAdjustmentDoc.appliedToUserID,
          memo: formValues.memo,
        };

      partialCommissionAdjustmentValue.id = commissionAdjustmentDoc.id;
      partialCommissionAdjustmentValue.refPath =
        commissionAdjustmentDoc.refPath;

      try {
        await handleEditCommissionAdjustment(partialCommissionAdjustmentValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    } else {
      if (selectedUser == null) {
        setDisplayError(true);
        return;
      }

      const partialCommissionAdjustmentValue: CreateCommissionAdjustment = {
        amount: formValues.amount === "" ? 0 : formValues.amount,
        timestampApplied: Timestamp.fromDate(formValues.timestampApplied),
        appliedToUserID: selectedUser.id,
        memo: formValues.memo,
      };

      try {
        await handleSaveNewCommissionAdjustment(
          partialCommissionAdjustmentValue,
        );
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    }
  };

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {commissionAdjustmentDoc
          ? strings.EDIT_COMMISSION_ADJUSTMENT
          : strings.NEW_COMMISSION_ADJUSTMENT}
      </h1>
      <button type="button" onClick={() => closeAndReset()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = commissionAdjustmentDoc ? (
    <ErrorMessage
      message={strings.ERROR_EDIT_COMMISSION_ADJUSTMENT}
      clearMessage={() => setDisplayError(false)}
    />
  ) : (
    <ErrorMessage
      message={strings.ERROR_NEW_COMMISSION_ADJUSTMENT}
      clearMessage={() => setDisplayError(false)}
    />
  );

  function getSelectedUser() {
    if (selectedUser) {
      return selectedUser;
    }
    if (commissionAdjustmentDoc) {
      const user = siteKeyUsersList.find(
        (u) => u.id === commissionAdjustmentDoc.appliedToUserID,
      );
      if (user) {
        return user;
      }
    }
    return null;
  }

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={isDialogOpen}
      title={header}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          {/* Field: Memo */}
          <div>
            <Controller
              name="memo"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Memo"
                  inputName="memo"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.memo?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.memo.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Amount */}
          <div>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <BaseInputNumber
                  text="Amount"
                  inputName="amount"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value}
                  onChange={(event) => {
                    const numberValue = event.target.valueAsNumber;
                    if (displayError === true && numberValue > 0) {
                      setDisplayError(false);
                    }
                    field.onChange(isNaN(numberValue) ? "" : numberValue);
                  }}
                />
              )}
            />
            {errors.amount?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.amount.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Combobox to select a siteKeyUser */}
          <div className="z-20 bg-white">
            <Combobox
              value={getSelectedUser()}
              onChange={(value) => setSelectedUser(value)}
            >
              <div className="relative mt-1">
                <div className="relative">
                  <Combobox.Input
                    className={`relative mt-2 block w-[500px] min-w-0 flex-1 rounded border border-black p-4 text-gray-700 outline-none focus:border-primaryLight focus:ring focus:ring-primaryLight sm:text-sm`}
                    onChange={(event) => {
                      setUserSearchString(event.target.value);
                    }}
                    // onKeyDown={(event) => {
                    //   handleKeyDown(event);
                    // }}
                    value={getSelectedUser()?.displayName ?? ""}
                    onClick={() => setUserSearchString("")}
                    autoComplete="off"
                    id="userSelector"
                  />
                  <label
                    htmlFor="userSelector"
                    className="absolute -top-3 left-3 bg-white px-2"
                  >
                    Select User
                  </label>
                  <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center pr-2"
                    ref={buttonRef}
                  >
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => {
                    const theUser = getSelectedUser();
                    setUserSearchString(theUser ? theUser.displayName : "");
                  }}
                >
                  <Combobox.Options className="absolute z-40 mt-1 max-h-32 w-[500px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {siteKeyUsersList
                      .filter((u) => u.systemUser !== true)
                      .sort((a, b) =>
                        a.displayName.localeCompare(b.displayName),
                      )
                      .map((skUser) => (
                        <Combobox.Option
                          key={skUser.id}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-gray-600 text-white"
                                : "text-gray-900"
                            }`
                          }
                          value={skUser}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {skUser.displayName}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? "text-white" : "text-gray-600"
                                  }`}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>

          <div className="flex flex-row space-x-2">
            {"Date Applied: "}
            <div className="mx-2">
              <Controller
                name="timestampApplied"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      className="z-10"
                      selected={field.value || new Date()}
                      onChange={(date) => field.onChange(date)}
                      customInput={<CustomDateInput className="z-20" />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
            {userPermissions.permissions.isSiteAdmin &&
              commissionAdjustmentDoc?.id && (
                <ButtonColored
                  className="w-full justify-center uppercase"
                  kind="danger"
                  type="button"
                  onClick={async () => {
                    if (!commissionAdjustmentDoc) return;
                    commissionAdjustmentDoc.deleted = true;
                    await handleEditCommissionAdjustment(
                      commissionAdjustmentDoc,
                    );
                  }}
                >
                  {strings.DELETE_COMMISSION_ADJUSTMENT}
                </ButtonColored>
              )}

            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}
