//Libs
import React from "react";

//Local

type Ref = HTMLInputElement;

type Props = {
  id: string;
  label: string;
  className?: string;
  pOptionIsInSOptions: boolean;
} & React.ComponentPropsWithRef<"input">;

const ResponseRadioSelect = React.forwardRef<Ref, Props>(
  (
    { id, label, className, pOptionIsInSOptions, ...props },
    ref,
  ): JSX.Element => {
    const radioColor =
      pOptionIsInSOptions === false
        ? "accent-redFail focus-visible:outline-redFail"
        : "accent-green-600 focus-visible:outline-green-600";
    return (
      <div className={`flex items-center px-2 ${className}`}>
        <input
          {...props}
          id={id}
          type="radio"
          className={`h-6 w-6 cursor-pointer focus-visible:outline focus-visible:outline-1 ${radioColor}`}
          ref={ref}
          value={label}
        />
        <label
          htmlFor={id}
          className="ml-2 block cursor-pointer text-base font-medium"
        >
          {label}
        </label>
      </div>
    );
  },
);

export default ResponseRadioSelect;
