// Libs
import { z } from "zod";

//Local
import {
  ScheduleByPriorityType,
  scheduleByPrioritySchema,
} from "../components/customers/CreateTask/SchedulingSection";
import { guardIsPlainObject } from "../utils";

export interface DuplicateJob {
  taskIDs: string[];
  duplicateEstimates: boolean;
  duplicatePhotos: boolean;
  duplicateAttachments: boolean;
  craftRecordID: string;
  customerID: string;
  customerLocationIDs: string[];
  timestampScheduleDate: string | null;
  serviceWindowDuration: number;
  scheduledByPriority: ScheduleByPriorityType | null;
  siteKey: string;
  siteLocationID: string;
  assignedTo: string[] | null;
  sendJobReminderEmail: boolean;
}

export const duplicateJobSchema = z.object({
  taskIDs: z.array(z.string().min(1).max(200)),
  duplicateEstimates: z.boolean(),
  duplicatePhotos: z.boolean(),
  duplicateAttachments: z.boolean(),
  craftRecordID: z.string().min(1).max(200),
  customerID: z.string().min(1).max(200),
  customerLocationIDs: z.array(z.string().min(1).max(200)),
  timestampScheduleDate: z.string().nullable(),
  scheduledByPriority: scheduleByPrioritySchema.nullable(),
  serviceWindowDuration: z.number(),
  siteKey: z.string().min(1).max(200),
  siteLocationID: z.string().min(1).max(200),
  assignedTo: z.array(z.string().min(1).max(200)).nullable(),
  sendJobReminderEmail: z.boolean(),
});

export const DuplicateJobManager = {
  parse: validateDuplicateJob,
};

function validateDuplicateJob(duplicateJob: unknown): DuplicateJob {
  if (!guardIsPlainObject(duplicateJob)) {
    throw new Error(`Invalid duplicate job structure: ${duplicateJob}`);
  }
  return duplicateJobSchema.parse(duplicateJob);
}
