import React, { Fragment } from "react";
import LoadingSpinner from "./LoadingSpinner";

type Ref = HTMLButtonElement;

type ButtonTypes = "info" | "warning" | "danger" | "gray";

type Props = {
  children: React.ReactNode;
  kind: ButtonTypes;
  isBusy?: boolean;
  busyText?: string;
} & React.ComponentPropsWithRef<"button">;

/**
 * A button component that can be colored and is not tied to the primary or
 * secondary theme variables.
 */
const ButtonColored = React.forwardRef<Ref, Props>(
  (
    { children, kind, isBusy = false, busyText = "Processing...", ...props },
    ref,
  ) => {
    const colorStyles: Record<ButtonTypes, string> = {
      info: "hover:bg-green-50 border-green-300 text-green-700 focus:ring-green-300",
      warning:
        "hover:bg-yellow-50 border-yellow-300 text-yellow-600 focus:ring-yellow-300",
      danger: "hover:bg-red-50 border-red-300 text-red-700 focus:ring-red-300",
      gray: "hover:bg-gray-50 border-gray-300 text-gray-700 focus:ring-gray-300",
    };

    const LoadingElement: React.ReactNode = (
      <Fragment>
        <LoadingSpinner sizeClass="h-5 w-5" marginClass="-ml-1 mr-4 my-0.5" />
        {busyText}
      </Fragment>
    );

    return (
      <button
        {...props}
        ref={ref}
        disabled={isBusy || props.disabled}
        className={`${props.className ?? ""} ${
          colorStyles[kind]
        } inline-flex h-10 items-center justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
      >
        {isBusy ? LoadingElement : children}
      </button>
    );
  },
);

export default ButtonColored;
