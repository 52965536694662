interface Props {
  children: React.ReactNode;
  type: "submit" | "reset" | "button";
  className?: string;
}

export default function ResponseButtonSecondary({
  children,
  ...props
}: Props & React.ComponentPropsWithoutRef<"button">): JSX.Element {
  return (
    <button
      {...props}
      type={props.type}
      className={`inline-flex items-center justify-center rounded-xl border border-primary bg-primary px-5 py-1 text-sm font-medium tracking-wide text-white shadow-sm hover:bg-primaryDark hover:text-secondaryButtonText focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2 ${props.className}`}
    >
      {children}
    </button>
  );
}
