//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import BaseModal from "../BaseModal";

interface Props {
  response: string | null;
  isError: boolean;
  isDialogOpen: boolean;
  closeDialog: () => void;
}

export default function DisplayRegeneratedPassphraseDialog(props: Props) {
  const userPassphraseHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {props.isError ? "Error" : "New Password"}
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close password dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={userPassphraseHeader}
      parentDivStyles="max-w-lg inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <>
        {!props.isError && (
          <h3 className="px-6 py-4">
            Please save this password for future reference.
          </h3>
        )}
        <h3
          className={`px-6 pb-8 font-semibold ${
            props.isError ? "text-red-500" : "text-blue-600"
          }`}
        >
          {props.response}
        </h3>
      </>
    </BaseModal>
  );
}
