// Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import { z } from "zod";
import { v4 as uuid4 } from "uuid";

// Local
import {
  adminPropDefaultsForCustomField,
  CustomFieldManager,
  SelectionCustomField,
} from "../../../models/custom-field";
import {
  TitleField,
  OnTaskStatusField,
  isDefinedOnTaskStatus,
  HideOnCRCreationField,
  SelectionOptionsField,
  SelectionDefaultValueField,
} from "../../admin/AddCustomFieldDialog/AddCustomFieldDialog";
import { ErrorMessage } from "../../ErrorMessage";
import { OTaskTypes } from "../../../models/task-types";
import {
  ActionButtons,
  CreateSiteCustomFieldFormInterface,
  RequiredField,
  SelectionSchema_CSCF,
} from "./AddCreateSiteCustomFieldDialog";

const selectionDefaultValues: Partial<SelectionInterface> = {
  defaultValue: "",

  title: "",
  editable: true,
  required: false,
  hideOnCraftRecordCreation: false,
};
type SelectionInterface = z.infer<typeof SelectionSchema_CSCF>;

export const SelectionCreateSiteCustomFieldForm: React.FunctionComponent<
  CreateSiteCustomFieldFormInterface
> = (props) => {
  const methods = useForm<SelectionInterface>({
    defaultValues: selectionDefaultValues,
    resolver: zodResolver(SelectionSchema_CSCF),
    mode: "onChange",
  });

  const watchOptions = useWatch({
    control: methods.control,
    name: "selectionOptions",
  });

  useEffect(() => {
    methods.reset(selectionDefaultValues);
  }, [methods]);

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [displayWorkTypeError, setDisplayWorkTypeError] =
    useState<boolean>(false);

  const onSave: SubmitHandler<SelectionInterface> = (formValues) => {
    if (props.workType === undefined) {
      setDisplayWorkTypeError(true);
      return;
    }

    // If it's an empty string, they didn't input anything. Save value as null.
    const defaultValue =
      formValues.defaultValue.length === 0 ? null : formValues.defaultValue;

    // Drop undefined values from the onTaskStatus list.
    // onTaskStatus itself will be undefined if this is a workType.
    const onTaskStatus = formValues.onTaskStatus?.filter(isDefinedOnTaskStatus);

    const selectionOptions = formValues.selectionOptions.reduce((acc, curr) => {
      const currentValue = Object.values(curr)[0];
      // Don't add empty strings to the output data.
      if (currentValue.length === 0) return acc;
      const newKey = uuid4();
      acc[newKey] = currentValue;
      return acc;
    }, {});

    // Compose the output structure.
    let fullOutput: SelectionCustomField;

    const partialOutput: Omit<
      SelectionCustomField,
      "taskType" | "craftRecordOrTask"
    > = {
      ...adminPropDefaultsForCustomField(),
      fieldType: "selection",
      defaultValue: defaultValue,
      title: formValues.title,
      editable: true,
      required: formValues.required,
      selectionOptions: selectionOptions,
      craftType: props.workType,
    };

    // If taskType is null this means it's a craftRecord, and
    // we don't want to save the taskType to the database.
    if (props.taskType == null) {
      fullOutput = {
        ...partialOutput,
        craftRecordOrTask: "craftRecord",
        hideOnCraftRecordCreation: formValues.hideOnCraftRecordCreation,
      };
    } else {
      fullOutput = {
        ...partialOutput,
        craftRecordOrTask: "task",
        taskType: props.taskType,
        onTaskStatus: onTaskStatus,
      };
    }

    // Validate CustomFieldDocData
    const validatedOutput = CustomFieldManager.parse(fullOutput);
    props.handleSave(validatedOutput);
    props.closeDialog();
  };

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  const undefinedWorkTypeErrorMessage = (
    <ErrorMessage
      message="Work Type must be selected"
      clearMessage={() => setDisplayWorkTypeError(false)}
    />
  );

  return (
    <FormProvider {...methods}>
      <form
        className="contents"
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSave)}
      >
        {props.taskType != null ? (
          <div></div>
        ) : (
          <HideOnCRCreationField control={methods.control} />
        )}

        <div className="col-span-1 sm:col-span-2">
          <TitleField
            control={methods.control}
            errors={methods.formState.errors}
          />
        </div>

        {/* Specific to fieldType selection */}
        <SelectionOptionsField errors={methods.formState.errors} />
        <SelectionDefaultValueField
          control={methods.control}
          errors={methods.formState.errors}
          selectionOptions={watchOptions}
        />

        <RequiredField control={methods.control} />

        {props.taskType != null &&
          Object.values(OTaskTypes).includes(props.taskType) && (
            <OnTaskStatusField
              taskStatusLabel="Prompt On Task Status"
              control={methods.control}
              taskStatusList={props.taskStatusList}
            />
          )}

        <span className="-my-5 h-10 justify-self-center sm:col-span-2 ">
          {displayError ? errorMessage : null}
          {displayWorkTypeError ? undefinedWorkTypeErrorMessage : null}
        </span>

        <ActionButtons onCancel={props.closeDialog} />
      </form>
    </FormProvider>
  );
};
