//Libs
import { Fragment } from "react/jsx-runtime";
import { Listbox, Transition } from "@headlessui/react";
import { Email, Phone, PhoneAndroid } from "@mui/icons-material";
import {
  CheckIcon,
  ChevronUpDownIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";

//Local
import { ExistingCustomerContact } from "../../models/customer-contact";
import { phoneUtils } from "../../utils/phoneUtils";
import { StyledTooltip } from "../StyledTooltip";
import * as strings from "../../strings";

interface Props {
  customerContacts: ExistingCustomerContact[];
  primaryContactPhone: ExistingCustomerContact | null;
  setPrimaryContactPhone: React.Dispatch<
    React.SetStateAction<ExistingCustomerContact | null>
  >;
  primaryContactEmail: ExistingCustomerContact | null;
  setPrimaryContactEmail: React.Dispatch<
    React.SetStateAction<ExistingCustomerContact | null>
  >;
  onContactAdded: () => void;
}

export default function CustomerContactSection({
  customerContacts,
  primaryContactPhone,
  setPrimaryContactPhone,
  primaryContactEmail,
  setPrimaryContactEmail,
  onContactAdded,
}: Props) {
  const phoneCustomerContacts: ExistingCustomerContact[] =
    customerContacts.filter((contact) => contact.type !== "email");

  const emailCustomerContacts: ExistingCustomerContact[] =
    customerContacts.filter((contact) => contact.type === "email");

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-2 flex flex-row justify-start">
        <div className="mr-4 text-xl font-bold text-primary">
          Primary Contact
        </div>
        <StyledTooltip title={strings.CUSTOMER_CONTACTS}>
          <PlusCircleIcon
            aria-label="add customer contact"
            className="h-9 w-9 hover:text-primaryDark"
            onClick={() => {
              onContactAdded();
            }}
          />
        </StyledTooltip>
      </div>
      <ContactDropdown
        customerContactList={phoneCustomerContacts}
        selectedContact={primaryContactPhone}
        setSelectedContact={setPrimaryContactPhone}
        label="Phone"
      />
      <ContactDropdown
        customerContactList={emailCustomerContacts}
        selectedContact={primaryContactEmail}
        setSelectedContact={setPrimaryContactEmail}
        label="Email"
      />
    </div>
  );
}

interface ContactDropdownProps {
  customerContactList: ExistingCustomerContact[];
  selectedContact: ExistingCustomerContact | null;
  setSelectedContact: React.Dispatch<
    React.SetStateAction<ExistingCustomerContact | null>
  >;
  label: string;
}

export function ContactDropdown({
  customerContactList,
  label,
  selectedContact,
  setSelectedContact,
}: ContactDropdownProps) {
  return (
    <div>
      <label htmlFor="selectWorkType" className="inline-block text-primary">
        {label}
      </label>
      <div className="relative w-72">
        <Listbox value={selectedContact} onChange={setSelectedContact}>
          <div className="relative mt-1">
            <Listbox.Button className="relative h-10 w-full cursor-pointer rounded-md border border-primary bg-white py-2 pl-3 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight">
              <span className="block truncate">
                {selectedContact ? (
                  <span>
                    {selectedContact.type === "email" ? (
                      <span>
                        <Email /> {selectedContact.value}
                      </span>
                    ) : (
                      <span className="flex gap-2">
                        {selectedContact.type === "phone" && <Phone />}
                        {selectedContact.type === "mobilePhone" && (
                          <PhoneAndroid />
                        )}
                        {phoneUtils.display(selectedContact.value)}
                      </span>
                    )}
                  </span>
                ) : (
                  "Select..."
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute isolate z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
                {customerContactList.map((contact) => (
                  <Listbox.Option
                    key={contact.id}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active || selected
                          ? "bg-primaryOpacity90 text-primary"
                          : "text-gray-700"
                      }`
                    }
                    value={contact}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {contact.notes && (
                            <span className="text-md block whitespace-normal leading-5">
                              {contact.notes}
                            </span>
                          )}
                          {contact.type === "email" ? (
                            <span className="text-sm">
                              <Email /> {contact.value}
                            </span>
                          ) : (
                            <span className="flex gap-2 text-sm">
                              {contact.type === "phone" && <Phone />}
                              {contact.type === "mobilePhone" && (
                                <PhoneAndroid />
                              )}
                              {phoneUtils.display(contact.value)}
                            </span>
                          )}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
}
