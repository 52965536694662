// Libs
import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { DocumentData } from "firebase/firestore";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

// Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import { logger } from "../../logging";
import { ErrorMessage } from "../ErrorMessage";
import {
  getEditableTSDForNextTaskStatus,
  handleSubmitTaskStatusChange,
} from "./functions";
import { CraftRecordPersistenceTypes, ExistingTask } from "../../models/task";
import { ExistingCustomField } from "../../models/custom-field";
import { OTaskStatus, TaskStatus } from "../../models/task-status";
import { IMultipleUid_AssignUser } from "../CustomFields/MultipleUidDialog";
import { Json } from "../../models/json-type";
import {
  Input,
  convertResponsesToExpectedTypes,
  dropUnchangedOptionalFields,
  getDefaultValuesForExistingResponse,
} from "../CustomFields";
import HandleSendEmailDialog from "../estimates/HandleSendEmailDialog";
import { DbWrite } from "../../database";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { ExistingStiltInvoice } from "../../models/invoice";
import StyledMessage from "../StyledMessage";

interface Props {
  // DIALOG BASICS
  open: boolean;
  onClose: () => void;

  // DATA
  task: ExistingTask;
  originalTask: ExistingTask;
  showCraftPersistence: boolean;
  workRecordTitle: string;
  siteKeyCustomFields: ExistingCustomField[];
  invoices: ExistingStiltInvoice[];
  /**
   * Won't be null if there are TSD that contain onTaskStatus[nextTaskStatus].
   * (will only be null if the dialog opens solely because of craft record persistence)
   */
  nextTaskStatus: TaskStatus | null;
  userList: IMultipleUid_AssignUser[];
  userDisplayNamesMap: Record<string, string>;
  uid: string;
  /** will keep tsd.scheduledServiceWindowStart and tsd.scheduledServiceWindowEnd */
  isReschedulingTask: boolean;
  customerLocation: ExistingCustomerLocation;
  emailList: string[];
  // FUNCTIONS
  handleUpdateTask: (updateData: DocumentData, taskID: string) => Promise<void>;
  handleUpdateTSD: (updateData: DocumentData, refPath: string) => Promise<void>;
  createFollowUpTask: (
    task: ExistingTask,
    followUpTaskStatus: TaskStatus | null,
  ) => Promise<void>;
}

const buttonPrimaryColors =
  "bg-primary text-primaryButtonText hover:bg-primaryDark hover:text-secondaryButtonText";
const buttonSecondaryColors =
  "border border-gray-300 bg-white text-gray-700 hover:bg-gray-50";

/**
 * Shown when the task status is changing to a status that is included in the
 * onTaskStatus property of one or more custom fields. (Requires user input)
 *
 * Also shown when task status is being changed to "complete" and the
 * craftRecordPersistence property is set to "prompt".
 */
export default function TaskStatusChangeDialog(props: Props): JSX.Element {
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  // const siteKeyLocationList = useSiteKeyLocationsStore(
  //   (state) => state.siteKeyLocationList,
  // );
  const xtask = cloneDeep(props.task);

  const originalAssignedTo =
    (props.originalTask.taskSpecificDetails["assignedTo"] as string[]) ?? [];
  const updateAssignedTo =
    (xtask.taskSpecificDetails["assignedTo"] as string[]) ?? [];

  const [clickedPersistenceKeep, setClickedPersistenceKeep] = useState(false);
  const [clickedPersistenceClose, setClickedPersistenceClose] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreatingFollowUpTask, setIsCreatingFollowUpTask] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [userInputErrorString, setUserInputErrorString] = useState<
    string | null
  >(null);

  const [handleEmailDialogOpen, setHandleEmailDialogOpen] =
    useState<boolean>(false);
  const [fieldValues, setFieldValues] = useState<FieldValues | null>(null);
  const [emailBody, setEmailBody] = useState<string | null>(null);
  const [emailSubject, setEmailSubject] = useState<string | null>(null);

  // Filter out serviceWindow fields
  const filteredCustomFields = props.siteKeyCustomFields.filter(
    (cf) =>
      cf.id !== "scheduledServiceWindowStart" &&
      cf.id !== "scheduledServiceWindowEnd",
  );

  const customFieldsToDisplay = getEditableTSDForNextTaskStatus({
    nextTaskStatus: props.nextTaskStatus,
    siteKeyCustomFields: filteredCustomFields,
  });

  const defaultValues: Record<string, Json | Date> = {};
  customFieldsToDisplay.forEach((cf) => {
    const dv = getDefaultValuesForExistingResponse({
      type: cf.fieldType,
      existingResponse: xtask.taskSpecificDetails[cf.id],
      userDisplayNamesMap: props.userDisplayNamesMap,
      userList: props.userList,
      selectionOptions:
        cf.fieldType === "selection" ? cf.selectionOptions : null,
    });

    if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
      defaultValues[`${cf.id}_hoursValue`] = dv[0];
      defaultValues[`${cf.id}_minutesValue`] = dv[1];
    } else {
      defaultValues[cf.id] = dv;
    }
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useForm({
    shouldFocusError: false,
    defaultValues: defaultValues as Record<string, any>,
  });

  // #region Messages to the user
  // Success case (or task update success + tsd failure)
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  // Failure case - NOT due to user input error. Catch block has executed.
  const errorMessage = (
    <ErrorMessage
      message={strings.failedUpdate("task")}
      clearMessage={() => setDisplayError(false)}
    />
  );
  // Failure case due to user input error
  const userInputErrorMessage = userInputErrorString && (
    <ErrorMessage
      message={userInputErrorString}
      clearMessage={() => setUserInputErrorString(null)}
    />
  );
  // #endregion Messages to the user

  // #region Functions
  function onCloseDialog() {
    setDisplayError(false);
    setUserInputErrorString(null);
    setClickedPersistenceKeep(false);
    setClickedPersistenceClose(false);
    setFieldValues(null);
    setEmailBody(null);
    setEmailSubject(null);
    reset(defaultValues);
    props.onClose();
  }

  function handlePersistenceKeep() {
    xtask.craftRecordPersistence = CraftRecordPersistenceTypes["KEEP"];
    setClickedPersistenceKeep(true);
    // in case they previously clicked "close"
    setClickedPersistenceClose(false);
  }

  function handlePersistenceClose() {
    xtask.craftRecordPersistence = CraftRecordPersistenceTypes["CLOSE"];
    setClickedPersistenceClose(true);
    // in case they previously clicked "keep open"
    setClickedPersistenceKeep(false);
  }

  /*
  async function handleShowPartsOrderedEmail(values: FieldValues) {
    setHandleEmailDialogOpen(true);

    const formattedDate: string | null =
      values.partsExpectedDate != null
        ? values.partsExpectedDate.toLocaleDateString("en-US")
        : null;

    const partsOrderedEmailBody = `This email is a friendly notification that parts have been ordered for a job at ${props.customerLocation.addressLine1 && props.customerLocation.addressLine2 ? props.customerLocation.addressLine1 + props.customerLocation.addressLine2 : props.customerLocation.fullAddress}. ${formattedDate ? `These parts are expected to arrive by ${formattedDate}.` : ""} Our office will contact you when the parts have arrived to schedule the next visit.`;

    const partsOrderedEmailSubject = `${siteKeyDoc?.name} - Parts Ordered`;

    setEmailBody(partsOrderedEmailBody);
    setEmailSubject(partsOrderedEmailSubject);
    setFieldValues(values);
  }

  async function handleShowPartsArrivedEmail(values: FieldValues) {
    const siteKeyLocation = siteKeyLocationList.find(
      (location) => location.id === props.originalTask.locationID,
    );

    setHandleEmailDialogOpen(true);

    const partsArrivedEmailBody = `This email is a friendly notification that parts have arrived for a job at ${props.customerLocation.addressLine1 && props.customerLocation.addressLine2 ? props.customerLocation.addressLine1 + props.customerLocation.addressLine2 : props.customerLocation.fullAddress}. Our office will contact you shortly to schedule the next visit or you can call us directly at ${siteKeyLocation?.phone}.`;

    const partsArrivedEmailSubject = `${siteKeyDoc?.name} - Parts Arrived`;

    setEmailBody(partsArrivedEmailBody);
    setEmailSubject(partsArrivedEmailSubject);
    setFieldValues(values);
  }

  async function handleCreateFollowUpTask() {
    setIsCreatingFollowUpTask(true);

    try {
      // create a follow up task
      await props.createFollowUpTask(xtask, props.nextTaskStatus);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd("Follow Up Task"),
        dialog: true,
        type: "success",
      });

      if (props.nextTaskStatus === TaskStatus.AWAITING_PARTS) {
        handleShowPartsOrderedEmail(defaultValues);
      } else {
        handleShowPartsArrivedEmail(defaultValues);
      }
    } catch (error) {
      logger.error("handleCreateFollowUpTask error:", error);
    }
  }
  */

  async function updateTaskAndTSD(
    values: FieldValues,
    nextTaskStatus: TaskStatus | null,
  ) {
    const assignedToIsChanged = updateAssignedTo.some(
      (assignedTo) => !originalAssignedTo.includes(assignedTo),
    );

    const xvalues = dropUnchangedOptionalFields({
      dirtyFields: assignedToIsChanged
        ? { ...dirtyFields, assignedTo: true }
        : dirtyFields,
      formValues: values,
      customFields: customFieldsToDisplay,
    });

    const convertedValues = convertResponsesToExpectedTypes({
      customFields: customFieldsToDisplay,
      values: xvalues,
      userList: props.userList,
    });
    logger.info("TSD values", convertedValues);

    setIsSubmitting(true);

    // replace xtask.TSD with updated responses
    if (!props.isReschedulingTask) {
      xtask.taskSpecificDetails = convertedValues;
    } else {
      // we're rescheduling a task => assignedVehicleID and serviceWindow gazz
      // may have changed. want to preserve it.
      const {
        scheduledServiceWindowStart,
        scheduledServiceWindowEnd,
        assignedVehicleID,
      } = xtask.taskSpecificDetails;

      if (assignedVehicleID) {
        xtask.taskSpecificDetails = {
          assignedVehicleID,
          scheduledServiceWindowStart,
          scheduledServiceWindowEnd,
          ...convertedValues,
        };
      } else {
        xtask.taskSpecificDetails = {
          scheduledServiceWindowStart,
          scheduledServiceWindowEnd,
          ...convertedValues,
        };
      }
    }

    try {
      const result = await handleSubmitTaskStatusChange({
        task: xtask,
        originalTask: props.originalTask,
        uid: props.uid,
        customFields: customFieldsToDisplay,
        showCraftPersistence: props.showCraftPersistence,
        nextTaskStatus: nextTaskStatus,
        handleUpdateTask: props.handleUpdateTask,
        handleUpdateTSD: props.handleUpdateTSD,
      });

      setIsSubmitting(false);

      if (typeof result === "string") {
        // If this executes, the user forgot to do something. `result` contains
        // more information.
        setUserInputErrorString(result);
      } else if (result === 0) {
        onCloseDialog();
        addToastMessage({
          id: createToastMessageID(),
          message: strings.successfulUpdate("Task"),
          dialog: false,
          type: "success",
        });
      } else {
        // TSD update has failed; task update succeeded
        onCloseDialog();
        addToastMessage({
          id: createToastMessageID(),
          message: strings.failedUpdate("task details"),
          dialog: false,
          type: "error",
        });
        // We want a sentry notification in this case.
        logger.error(
          `Failed TSD update (taskID: ${xtask.id}), error:`,
          result.error,
        );
      }
    } catch (e) {
      logger.error("handleSubmitTaskStatusChange error:", e);
      // ^ possible future todo = switch for logger.debug.. depending on if error
      // is usually triggered by user error, or by an issue with the code
      setIsSubmitting(false);
      setDisplayError(true);
    }
  }

  async function sendHandlePartsEmail(
    emailAddressList: string[],
    emailBody: string,
    emailSubject: string,
  ) {
    try {
      await DbWrite.emails.sendCustomEmail({
        customerEmailList: emailAddressList,
        emailBody,
        emailSubject,
        customerID: props.originalTask.customerID ?? "",
        siteKey: siteKeyDoc?.id ?? "",
      });
    } catch (error) {
      logger.error("sendHandlePartsEmail error:", error);
    }
  }

  const handleSendEmailDialog = (
    <HandleSendEmailDialog
      isDialogOpen={handleEmailDialogOpen}
      closeDialog={() => {
        setHandleEmailDialogOpen(false);
        if (fieldValues && isCreatingFollowUpTask) {
          // when I successfully created the new task, set the origin task to COMPLETE
          updateTaskAndTSD(defaultValues, TaskStatus.COMPLETE);
          setIsCreatingFollowUpTask(false);
        } else if (fieldValues) {
          updateTaskAndTSD(fieldValues, props.nextTaskStatus);
        }
      }}
      sendEstimateOrPartsInfoViaEmail={sendHandlePartsEmail}
      merchantName={siteKeyDoc?.name ?? ""}
      timestampSentToCustomer={null}
      customerEmailList={props.emailList}
      title={"Send Customer Info About Parts"}
      emailBody={emailBody}
      emailSubject={emailSubject}
      defaultIncludeJobPhotos={
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false
      }
    />
  );

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    await updateTaskAndTSD(values, props.nextTaskStatus);
  };
  // #endregion Functions

  return (
    <BaseModal
      open={props.open}
      closeModal={onCloseDialog}
      allowOverflowY={true}
      title={
        <div className="relative rounded-t-lg bg-primary p-6 text-xl font-semibold text-white lg:px-8">
          {strings.TASK_STATUS_CHANGE}
        </div>
      }
      parentDivStyles={`${
        props.nextTaskStatus && "md:max-w-3xl"
      } text-left max-w-md sm:max-w-lg`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-6 pb-8 pt-6">
          {/* SECTION: CRAFT RECORD PERSISTENCE */}
          {props.showCraftPersistence && (
            <div className="space-y-6">
              <p className="text-gray-600">
                {strings.CLOSE_WORK_REC_OR_LEAVE_OPEN_QUESTION}
              </p>
              <div className="rounded bg-gray-100 px-3 py-2">
                <p className="text-primary">{props.workRecordTitle}</p>
                <p className="mt-1 text-sm text-gray-800">
                  {strings.NO_OPEN_TASKS_REMAINING}
                </p>
              </div>
              <div className="flex flex-col gap-4 xs:flex-row xs:justify-between">
                <button
                  onClick={handlePersistenceKeep}
                  className={`${
                    clickedPersistenceKeep
                      ? buttonPrimaryColors
                      : buttonSecondaryColors
                  } inline-flex h-10 items-center justify-center rounded-md px-4 py-2 text-sm font-medium shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2 xs:w-52`}
                >
                  {strings.buttons.KEEP_OPEN}
                </button>
                <button
                  onClick={handlePersistenceClose}
                  className={`${
                    clickedPersistenceClose
                      ? buttonPrimaryColors
                      : buttonSecondaryColors
                  } inline-flex h-10 items-center justify-center rounded-md px-4 py-2 text-sm font-medium shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2 xs:w-52`}
                >
                  {strings.buttons.CLOSE}
                </button>
              </div>
            </div>
          )}

          {/* SECTION: CUSTOM FIELDS */}
          <div
            className={`${
              props.nextTaskStatus
                ? "space-y-8 md:grid md:grid-cols-2 md:items-center md:gap-x-16 md:gap-y-8 md:space-y-0"
                : ""
            } ${props.nextTaskStatus && props.showCraftPersistence && "pt-9"}`}
          >
            {props.nextTaskStatus === TaskStatus.AWAITING && (
              <div className="col-span-2">
                {strings.CONFIRM_REVERT_TO_AWAITING}
              </div>
            )}

            {customFieldsToDisplay.map((cf) => (
              <span key={cf.id} className="block">
                <Input
                  userList={props.userList}
                  customField={cf}
                  control={control}
                  defaultValue={defaultValues[cf.id]}
                  isRequired={cf.required}
                  handleSubmitMultipleUID={(data) => {}}
                />
              </span>
            ))}
          </div>

          {/* SECTION: INVOICE WARNINGS */}
          {/* display a warning message if no invoices have been created for this task */}
          {props.invoices.length === 0 &&
            props.nextTaskStatus === OTaskStatus.COMPLETE && (
              <div className="mt-10 flex w-full flex-row justify-end">
                <StyledMessage type="error">
                  {{ message: strings.NO_INVOICE_FOR_TASK_WARNING }}
                </StyledMessage>
              </div>
            )}
          {props.invoices.length > 0 &&
            props.nextTaskStatus === OTaskStatus.COMPLETE && (
              <div className="mt-10 flex w-full flex-row justify-end">
                <StyledMessage type="success">
                  {{
                    message: `${props.invoices.length} invoice${props.invoices.length > 1 ? "s have" : " has"} been created for this task`,
                  }}
                </StyledMessage>
              </div>
            )}

          {/* SECTION: BUTTONS */}

          {/* DISPLAY ERRORS */}
          {displayError && (
            <div className="mt-6 flex justify-center">{errorMessage}</div>
          )}
          {userInputErrorString && (
            <div className="mt-6 flex justify-center">
              {userInputErrorMessage}
            </div>
          )}

          {/* ACTION BUTTONS */}
          <div className="mt-4 flex gap-4 xs:justify-end">
            <BaseButtonSecondary
              type="button"
              onClick={onCloseDialog}
              className="flex-1 xs:w-28 xs:flex-initial"
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>
            <BaseButtonPrimary
              type="submit"
              className="flex-1 xs:w-28 xs:flex-initial"
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </div>
      </form>
      {handleSendEmailDialog}
    </BaseModal>
  );
}
