//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseInputCheckbox from "../BaseInputCheckbox";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { logger as devLogger } from "../../logging";
import { ErrorMessage } from "../ErrorMessage";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSave: (formValues: SaveTableViewPreferenceFormState) => void;
}

const SaveTableViewPreferenceFormSchema = z.object({
  name: z.string().min(1).max(200),
  default: z.boolean(),
});

export type SaveTableViewPreferenceFormState = z.infer<
  typeof SaveTableViewPreferenceFormSchema
>;

export default function SaveTableViewPreferenceDialog(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const saveTableViewPreferenceDefaultValues: SaveTableViewPreferenceFormState =
    useMemo(() => {
      return {
        name: "",
        default: false,
      };
    }, []);

  const saveTableViewPreferenceHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.NEW_TABLE_VIEW}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = (
    <ErrorMessage
      message={strings.ERR_SAVE_TABLE_VIEW}
      clearMessage={() => setDisplayError(false)}
    />
  );

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<SaveTableViewPreferenceFormState>({
    defaultValues: saveTableViewPreferenceDefaultValues,
    resolver: zodResolver(SaveTableViewPreferenceFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(saveTableViewPreferenceDefaultValues);
  }, [saveTableViewPreferenceDefaultValues, reset]);

  function closeAndReset() {
    reset();
    props.closeDialog();
  }

  const onSubmit: SubmitHandler<SaveTableViewPreferenceFormState> = async (
    formValues,
  ) => {
    try {
      await props.handleSave(formValues);
      // Close dialog if successful.
      closeAndReset();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={props.isDialogOpen}
      title={saveTableViewPreferenceHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-md rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-8 p-8 text-lg"
      >
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Table View Name"
                inputName="name"
                admin={true}
                required={true}
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
          {errors.name?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.name.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        <div>
          <Controller
            control={control}
            name="default"
            render={({ field }) => {
              return (
                <BaseInputCheckbox
                  label="Set this view as default?"
                  {...field}
                  value={undefined} // don't need a value for this checkbox, just true or false.
                  checked={field.value ?? false}
                  onChange={(event) => field.onChange(event.target.checked)}
                />
              );
            }}
          />
        </div>
        {/* Action Buttons */}
        <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row sm:col-span-3 sm:col-start-1">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={closeAndReset}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </form>
    </BaseModal>
  );
}
