//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { zFallback } from "../utils/zod-fallback";
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject } from "../utils";

export interface StiltCalendarEvent {
  title: string;
  description: string;
  start: Timestamp;
  end: Timestamp;
  assignedTo: string[];
  assignedVehicleIDs?: string[];
}

export interface ExistingStiltCalendarEvent extends StiltCalendarEvent {
  id: string;
  refPath: string;
}

// #region SECTION: Functions
/** Utilities for interacting with StiltCalendarEvent objects  */
export const StiltCalendarEventManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingStiltCalendarEvent object.
   */
  createFromFirestoreSnapshot: createStiltCalendarEventFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateStiltCalendarEvent,
  /** Validate a StiltCalendarEvent doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateStiltCalendarEventWithFallbacks,
  parseUpdate: validateStiltCalendarEvent_Update,
};

/**
 * Convert the Document Snapshot into a validated ExistingPriceBookItem object.
 */
function createStiltCalendarEventFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingStiltCalendarEvent {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateStiltCalendarEventWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateStiltCalendarEventWithFallbacks(
  value: unknown,
): StiltCalendarEvent {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = stiltCalendarEventWithFallbacks.parse(value);
  return result;
}

function validateStiltCalendarEvent(value: unknown): StiltCalendarEvent {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = stiltCalendarEventSchema.parse(value);
  return result;
}

function validateStiltCalendarEvent_Update(
  value: unknown,
): Partial<StiltCalendarEvent> {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return stiltCalendarEventSchema_Update.parse(value);
}
// #endregion Functions

// #region SECTION: Schemas
// Used when validating data coming from the database.
const stiltCalendarEventWithFallbacks = z.object({
  title: zFallback(
    z.string().min(1).max(500),
    "unknown",
    "stiltCalendarEventWithFallbacks: 'title'",
  ),
  description: zFallback(
    z.string().max(2000),
    "",
    "stiltCalendarEventWithFallbacks: 'description'",
  ),
  start: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "stiltCalendarEventWithFallbacks: 'start'",
  ),
  end: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "stiltCalendarEventWithFallbacks: 'end'",
  ),
  assignedTo: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "stiltCalendarEventWithFallbacks: 'assignedTo'",
  ),
  assignedVehicleIDs: zFallback(
    z.array(z.string().min(1).max(200)).optional(),
    [],
    "stiltCalendarEventWithFallbacks: 'assignedVehicleIDs'",
  ),
});

// Used when writing to the DB or reading from the user.
const stiltCalendarEventSchema = z.object({
  title: z.string().min(1).max(500),
  description: z.string().max(2000),
  start: z.instanceof(Timestamp),
  end: z.instanceof(Timestamp),
  assignedTo: z.array(z.string().min(1).max(200)),
  assignedVehicleIDs: z.array(z.string().min(1).max(200)).optional(),
});

const stiltCalendarEventSchema_Update = stiltCalendarEventSchema.partial();
// #endregion Schemas
