import React, { Fragment } from "react";
import LoadingSpinner from "./LoadingSpinner";

type Ref = HTMLButtonElement;
type Props = {
  children: React.ReactNode;
  isBusy?: boolean;
  busyText?: string;
} & React.ComponentPropsWithRef<"button">;

/**
 * Button component with an off-white background, for secondary actions.
 */
const BaseButtonTertiary = React.forwardRef<Ref, Props>(
  (
    { isBusy = false, busyText = "Processing...", children, ...props },
    ref,
  ): JSX.Element => {
    const LoadingElement: React.ReactNode = (
      <Fragment>
        <LoadingSpinner />
        {busyText}
      </Fragment>
    );
    return (
      <button
        {...props}
        ref={ref}
        disabled={isBusy || props.disabled}
        className={`${props.className ?? ""} ${
          props.disabled && !isBusy
            ? "text-gray-300"
            : "text-gray-700 hover:bg-gray-100"
        } inline-flex items-center justify-center rounded-sm bg-gray-50 px-4 py-2 text-sm font-medium shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-gray-300`}
      >
        {isBusy ? LoadingElement : children}
      </button>
    );
  },
);

export default BaseButtonTertiary;
