/**
 * Check if every element in the array meets the predicate. Returns false if the
 * array is empty, unlike default .every() method in JS.
 */
export function arrayEvery<T>(
  input: T[],
  predicateFn: (element: T) => boolean,
): boolean {
  if (input.length === 0) return false;
  return input.every(predicateFn);
}
