//Libs
import { TrashIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Gallery from "react-image-gallery";
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import currencyFormatter from "../../currency";
import { TemporaryEstimateItem } from "../../models/estimate-item";
import { PencilIconWithRef } from "../PencilEditButton";
import { StyledTooltip } from "../StyledTooltip";
import { ExistingStiltPhoto } from "../../models/stilt-photo";

interface Props {
  estimateItem: TemporaryEstimateItem;
  photos: ExistingStiltPhoto[];
  currency: string;
  handleDeleteEstimateItem?: (estimateItem: TemporaryEstimateItem) => void;
  turnItemToBeEditable?: (estimateItem: TemporaryEstimateItem) => void;
  allowEdit: boolean;
}

export default function EstimateItemInline({
  estimateItem,
  photos,
  currency,
  allowEdit,
  handleDeleteEstimateItem,
  turnItemToBeEditable,
}: Props) {
  const {
    title,
    description,
    quantity,
    unitPrice,
    discountable,
    discount,
    editable,
  } = estimateItem;

  const [showReducedPhotoGallery, setShowReducedPhotoGallery] = useState(false);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);

  const subtotalItemWithLineDiscount =
    estimateItem.quantity *
    estimateItem.unitPrice *
    (1 - (estimateItem.discount ?? 0) / 100);
  const totalWithoutDiscount = estimateItem.quantity * estimateItem.unitPrice;

  function openReducedGallery(clickedIndex: number): void {
    setShowReducedPhotoGallery(true);
    setThumbnailIndex(clickedIndex);
  }

  function closeReducedGallery(): void {
    setShowReducedPhotoGallery(false);
  }

  const photoReducedGallery = (
    <Fragment>
      <Transition appear show={showReducedPhotoGallery} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-20 overflow-y-auto"
          onClose={closeReducedGallery}
        >
          <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-filter" />
          <div className="p-4 text-left sm:text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block max-h-fit w-auto max-w-screen-xl transform overflow-x-hidden rounded-lg border border-gray-300 bg-white align-middle shadow-sm transition-all">
                <div className="relative">
                  <Dialog.Title
                    as="h3"
                    className="ml-3 mr-10 py-2 text-xl font-light sm:mx-10"
                  >
                    {estimateItem.title}
                  </Dialog.Title>
                  <button className="absolute right-1 top-1 rounded-sm p-1 focus:outline-none focus:ring-2 focus:ring-primaryLight">
                    <XMarkIcon
                      aria-label="close response photo gallery"
                      className="h-7 w-7"
                      onClick={() => {
                        setShowReducedPhotoGallery(false);
                      }}
                    />
                  </button>
                </div>
                <Gallery
                  items={photos.map((photoDoc) => {
                    return {
                      original: photoDoc.photoURL_reduced,
                      thumbnail:
                        photoDoc.photoURL_thumb ?? photoDoc.photoURL_reduced,
                      originalHeight: 30,
                    };
                  })}
                  // infinite={false}
                  showPlayButton={false}
                  onErrorImageURL="Could not load image."
                  startIndex={thumbnailIndex}
                  showIndex={true}
                  // renderLeftNav={renderLeftArrow}
                  // renderRightNav={renderRightArrow}
                  // renderFullscreenButton={renderFullscreenBtn}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );

  return (
    <>
      <div
        className={`${
          allowEdit
            ? "grid grid-cols-[5fr,2fr,1fr]"
            : "grid grid-cols-[5fr,2fr]"
        }`}
      >
        {/* TITLE AND DESCRIPTION */}
        <div className="flex items-center">
          <div className="flex flex-col space-y-2">
            <span className="block whitespace-normal text-base font-medium leading-5">
              {title}
            </span>
            <p className="text-base text-gray-400">{description}</p>
            {discountable === false && (
              <p className={`text-sm italic`}>* Non-discountable</p>
            )}
          </div>
        </div>
        {/* AMOUNT */}
        <div className="flex justify-self-end">
          <div className="flex flex-col items-end space-y-2">
            {discount && discount > 0 && discountable !== false ? (
              <div className="flex items-center gap-2">
                <span className="line-through">
                  {currencyFormatter(totalWithoutDiscount, currency)}
                </span>
                <span className="text-lg font-bold">
                  {currencyFormatter(subtotalItemWithLineDiscount, currency)}
                </span>
              </div>
            ) : (
              <div className="text-lg font-bold">
                {currencyFormatter(totalWithoutDiscount, currency)}
              </div>
            )}
            {discount && discount > 0 && discountable !== false ? (
              <div className="mt-2 text-sm text-gray-600">
                {quantity} @ {currencyFormatter(unitPrice, currency)} +{" "}
                {discount}% discount
              </div>
            ) : (
              <div className="mt-2 text-sm text-gray-600">
                {quantity} @ {currencyFormatter(unitPrice, currency)}
              </div>
            )}
          </div>
        </div>
        {/* ACTION BUTTONS */}
        {allowEdit && handleDeleteEstimateItem && turnItemToBeEditable && (
          <div className="flex flex-row items-center justify-self-end">
            {editable && (
              <StyledTooltip title="Edit Line Items">
                <PencilIconWithRef
                  onClick={() => turnItemToBeEditable(estimateItem)}
                />
              </StyledTooltip>
            )}
            <button
              className="mx-1 rounded-full hover:bg-red-50"
              onClick={() => handleDeleteEstimateItem(estimateItem)}
            >
              <TrashIcon
                aria-label="delete button"
                className="block h-10 w-10 cursor-pointer p-2 text-red-700"
              />
            </button>
          </div>
        )}
        {/* PHOTOS SECTION */}
        {photos.length > 0 && (
          <div className="grid max-h-60 grid-cols-2 gap-1 overflow-auto sm:grid-cols-4">
            <Fragment>
              {photos.map(({ photoURL }, i) => {
                return (
                  <div key={photoURL} className="relative">
                    <img
                      alt=""
                      src={photoURL}
                      className="m-2 h-28 w-28 cursor-pointer rounded-md object-cover sm:h-32 sm:w-40"
                      onClick={() => openReducedGallery(i)}
                    />
                  </div>
                );
              })}
            </Fragment>
          </div>
        )}
      </div>
      {photoReducedGallery}
    </>
  );
}
