// TESTED under RHDCreateResponseFormat umbrella.
// Libs
import {
  XMarkIcon,
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

// Local
import { ExistingChecklistResponse } from "../../models/checklist-response";
import {
  responsePassingTypes,
  ResponsePassingTypes,
} from "../../models/checklist-response-types";

export default function getRHDIcons(
  responsePassing: ExistingChecklistResponse["responsePassing"],
): JSX.Element {
  const sharedClasses = "h-5 w-5 mt-0.5 sm:mt-1";

  const iconOptions: Record<ResponsePassingTypes, JSX.Element> = {
    yes: (
      <CheckIcon
        aria-label="response passing"
        className={`${sharedClasses} text-greenPass`}
      />
    ),
    no: (
      <XMarkIcon
        aria-label="response not passing"
        className={`${sharedClasses} text-redFail`}
      />
    ),
    error: (
      <ExclamationTriangleIcon
        aria-label="response error"
        className={`${sharedClasses} text-orangeError`}
      />
    ),
  };
  const fallback = <span className="mr-5"></span>;

  const output: JSX.Element = isValidResponsePassingType(responsePassing)
    ? iconOptions[responsePassing]
    : fallback;

  return output;
}

function isValidResponsePassingType(
  value: unknown,
): value is ResponsePassingTypes {
  return responsePassingTypes.includes(value as any);
}
