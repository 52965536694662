//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import BaseModal from "../components/BaseModal";
import { ExistingSiteKey } from "../models/site-key";
import BaseInputRadio from "../components/BaseInputRadio";

interface Props {
  isDialogOpen: boolean;
  defaultSiteKey: string | null;
  siteKeyList: ExistingSiteKey[];
  closeDialog: () => void;
  handleSelectionNewSiteKey: (siteKey: string) => Promise<void>;
}

export default function SelectDefaultSiteKeyDialog(props: Props) {
  const defaultSiteKeyHeader = (
    <div className="mb-4 flex w-full items-center justify-between gap-4 rounded-t-lg bg-primary p-8 text-left text-white">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Select Default Site Key
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon aria-label="close phone form" className="h-6 text-white" />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={defaultSiteKeyHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-fit rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="space-y-4 px-6 pb-8 pt-6 capitalize">
        {props.siteKeyList
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((siteKey) => (
            <BaseInputRadio
              name="siteKeySelection"
              key={siteKey.id}
              id={siteKey.id}
              label={siteKey.name}
              defaultChecked={siteKey.id === props.defaultSiteKey}
              onClick={async () => {
                await props.handleSelectionNewSiteKey(siteKey.id);
                props.closeDialog();
              }}
            />
          ))}
      </div>
    </BaseModal>
  );
}
