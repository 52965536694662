//Libs
import React, { Fragment, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { DateTime } from "luxon";
import { User } from "firebase/auth";
import { DocumentData } from "firebase/firestore";
import DatePicker from "react-datepicker";

//Local
import LightspeedPage from "./LightspeedPage";
import {
  DbRead,
  DbWrite,
  manuallyPushLightspeedTransactions,
} from "../../database";
import { ExistingStiltInvoiceAccountingSyncTableData } from "../../models/quickbook";
import {
  ExistingStiltInvoice,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { NewManualPayment } from "../../components/Invoices/RecordManualPaymentDialog";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import { SchedulingButton } from "../Scheduling/SchedulingContainer";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import { logger } from "../../logging";
import {
  APIPaymentSavedCard,
  paymentMethods,
  StiltPayment_CreateAPI,
  StiltPaymentManager,
} from "../../models/stilt-payment";
import { useAuthStore } from "../../store/firebase-auth";
import { diffObjects } from "../../assets/js/object-diff";
import BaseInputSelect from "../../components/BaseInputSelect";
import {
  AddNewPBItem,
  ExistingPriceBookItem,
  PBItem_CreateAPI,
  PBItem_UpdateAPI,
  PriceBookItemManager,
} from "../../models/price-book-item";
import AddEditPriceBookItemDialog from "../../components/estimates/AddEditPriceBookItemDialog";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { ExistingCustomer } from "../../models/customer";
import { ExistingPriceBookItemCategory } from "../../models/price-book-item-category";
import { getEmailList } from "../../utils/getEmailList";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { ExistingLightspeedTransaction } from "../../models/lightspeed";
import { flattenObj } from "../../assets/js/flatten";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { Column, Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { StyledTooltip } from "../../components/StyledTooltip";
import LoadingSpinner from "../../components/LoadingSpinner";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import LightspeedActionDropdown from "../../components/LightspeedActionDropdown";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { RangeValue } from "@react-types/shared";
import {
  CalendarDate,
  CalendarDateTime,
  getLocalTimeZone,
  toCalendarDate,
  today,
  ZonedDateTime,
} from "@internationalized/date";
import RADateRangePicker from "../../components/DateRangePicker/RADateRangePicker";

interface Props {
  siteKey: string;
}

export default function LightspeedContainer({ siteKey }: Props) {
  const queryClient = useQueryClient();
  /* STORES */
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const siteKeyLocationList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );
  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);

  /* NAVIGATE */

  // const navigate = useNavigate();

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  // function handleGoToCustomerPage(customerId: string) {
  //   navigate(`${CUSTOMERS_URL}/${customerId}`);
  // }

  /* STATES */
  const [invoices, setInvoices] = useState<
    ExistingStiltInvoiceAccountingSyncTableData[]
  >([]);
  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] = useState(false);
  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);
  const [isInvoiceDialogOpen, setIsInvoiceDialogOpen] = useState(false);
  const [stiltInvoiceID, setStiltInvoiceID] = useState<
    ExistingStiltInvoice["id"] | null
  >(null);
  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
    DateTime.now(),
  );
  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);
  const [addEditPBItemDialogOpen, setAddEditPBItemDialogOpen] =
    useState<boolean>(false);
  const [pBItemDoc, setPBItemDoc] = useState<ExistingPriceBookItem | null>(
    null,
  );
  const [priceBookItemCategories, setPriceBookItemCategories] = useState<
    ExistingPriceBookItemCategory[]
  >([]);
  const [currentCustomer, setCurrentCustomer] =
    useState<ExistingCustomer | null>(null);
  const [lightspeedTransactions, setLightspeedTransactions] = useState<
    ExistingLightspeedTransaction[]
  >([]);
  const [isBusy, setIsBusy] = useState<"sync" | "export" | null>(null);

  const [pendingOrSubmitted, setPendingOrSubmitted] = useState<
    "pending" | "submitted"
  >("pending");

  const [actionsLoading, setActionsLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [range, setRange] = useState<RangeValue<CalendarDate>>({
    start: today(getLocalTimeZone()).subtract({
      days: siteKeyDoc?.customizations?.defaultInvoicePageDateRangeDays ?? 30,
    }),
    end: today(getLocalTimeZone()),
  });

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  const selectedInvoice = invoices.find(
    (invoice) => invoice.id === stiltInvoiceID,
  );

  /* USE EFFECT */

  useEffect(() => {
    if (siteKey == null) {
      logger.error("siteKey is null");
      return undefined;
    }

    function getCategories() {
      // Subscribe to all priceBookItemCategories
      const unsubscribe = DbRead.priceBookItemCategories.subscribeAll({
        siteKey: siteKey,
        onChange: setPriceBookItemCategories,
        onError: (error) => {
          logger.error(
            "Error while subscribing to priceBookItemCategories",
            error,
          );
        },
      });
      return unsubscribe;
    }

    const func = getCategories();
    return () => func && func();
  }, [siteKey]);

  // Retrieve customer from selected invoice. For paying with a saved card.
  useEffect(() => {
    async function getCustomer() {
      if (!selectedInvoice) return;

      const customer = await DbRead.customers.get(
        siteKey,
        selectedInvoice.customerID,
      );
      setCurrentCustomer(customer);
    }

    getCustomer();
  }, [selectedInvoice, siteKey]);

  useEffect(() => {
    async function getLightspeedTransactions() {
      if (pendingOrSubmitted === "pending") {
        // Query zones via realtime updates. Set the list of zones.
        return DbRead.lightspeedTransactions.subscribeAllPending({
          siteKey: siteKey,
          onChange: (txnList: ExistingLightspeedTransaction[]) => {
            setLightspeedTransactions(
              txnList.sort(
                (a, b) =>
                  b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
              ),
            );
          },
          onError: (error) =>
            logger.error(
              `Error in getPendingLightspeedTransactions: ${error.message}`,
            ),
        });
      } else {
        return DbRead.lightspeedTransactions.subscribeAllPushed({
          siteKey: siteKey,
          startDate: range.start.toDate(siteKeyDoc?.timezone ?? "utc"),
          endDate: new Date(
            range.end
              .toDate(siteKeyDoc?.timezone ?? "utc")
              .setHours(23, 59, 59),
          ),
          onChange: (txnList: ExistingLightspeedTransaction[]) => {
            setLightspeedTransactions(
              txnList.sort(
                (a, b) =>
                  b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
              ),
            );
          },
          onError: (error) =>
            logger.error(
              `Error in getPendingLightspeedTransactions: ${error.message}`,
            ),
        });
      }
    }

    const unsubscribePromise = getLightspeedTransactions();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          logger.debug("Now unsubscribing from zones.");
          unsubscribe();
        }
      });
    };
  }, [siteKey, pendingOrSubmitted, range]);

  /* MUTATIONS */
  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries("quickbooks_invoices"),
    },
  );

  const mutateEditPBItem = useMutation(
    async (args: { editPBItem: PBItem_UpdateAPI }) => {
      await DbWrite.priceBookItems.update(args.editPBItem);
    },
  );

  const mutateAddPBItem = useMutation(
    async (args: { validPBItem: PBItem_CreateAPI }) => {
      await DbWrite.priceBookItems.create(args.validPBItem);
    },
  );

  /* FUNCTIONS */
  /**
   * Build an xlsx spreadsheet.
   */
  async function saveSpreadSheet() {
    setIsBusy("export");

    // const allPriceBookItems = await DbRead.priceBookItems.getAll(siteKey);
    const fileName = strings.LIGHTSPEED_TRANSACTIONS;

    const columns = [
      {
        key: "priceBookItemTitle",
        header: "Item",
      },
      {
        key: "invoiceNumber",
        header: "Invoice Number",
      },
      {
        key: "quantity",
        header: "Quantity",
      },
      {
        key: "locationID",
        header: "Location",
      },
      {
        key: "previousInventoryLevel",
        header: "Previous Inventory Level",
      },
      {
        key: "adjustedInventoryLevel",
        header: "Adjusted Inventory Level",
      },
      {
        key: "timestampCreated",
        header: "Timestamp Created",
      },
      {
        key: "createdBy",
        header: "Created By",
      },
      {
        key: "timestampPushed",
        header: "Timestamp Pushed",
      },
    ] as Column[];

    const wb = new Workbook();
    const sheet = wb.addWorksheet(fileName);

    sheet.columns = columns;

    const formattedData = await formatDataForExcelExport(
      lightspeedTransactions,
      userDisplayNamesMap,
      siteKeyLocationList,
    );

    sheet.addRows(formattedData);

    // Create title which will be Price Book - todaysDate
    const customTitle = `${fileName} - ${new Date().toLocaleDateString()}`;
    sheet.insertRow(1, [customTitle]);

    // updated the font for first row.
    sheet.getRow(1).font = { bold: true };
    sheet.getRow(2).font = { bold: true };

    try {
      // write the content using writeBuffer
      const buf = await wb.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${customTitle}.xlsx`);
    } catch (error) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.GENERIC_ERROR_MESSAGE,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsBusy(null);
      // removing worksheet's instance to create new one
      wb.removeWorksheet(fileName);
    }
  }

  function openViewInvoiceDialog(invoiceID: string): void {
    if (!siteKeyDoc) return;

    setViewInvoiceDialogProps({
      siteKeyID: siteKey,
      invoiceID,
      merchantName: siteKeyDoc.name,
      merchantLogoURL:
        siteKeyDoc.customizations.accounting?.merchantLogoURL ?? null,
    });
    setIsInvoiceDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  function closeViewInvoiceDialog(): void {
    setIsInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    // try/catch is in the InvoiceSummary component; don't want it here
    return DbWrite.payments.issueRefund(siteKey, paymentID, refundAmount);
  }

  function onClickHandlePaymentButton(invoiceID: ExistingStiltInvoice["id"]) {
    setIsHandlePaymentDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  async function handleEditInvoiceDialogOpen() {
    if (selectedInvoice) {
      setEditInvoiceDialogOpen(true);
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
      return;
    }
    if (email.length === 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "email",
    );
    if (!paymentLink) {
      logger.error("emailInvoice: missing invoice link");
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_GEN_INVOICE_LINK,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
      setStiltInvoiceID(null);
      closeViewInvoiceDialog();
    } catch (e) {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
      logger.error("error emailing customer invoice", e);
      setStiltInvoiceID(null);
    }
  }

  async function emailReceipt(emailList: string[]): Promise<void> {
    if (!selectedInvoice) return;
    if (emailList.length === 0) {
      throw Error("emailReceipt given empty email list");
    }
    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: siteKey,
        invoiceID: selectedInvoice.id,
        customerEmailList: emailList,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      // logger.error("emailReceipt failure", e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT,
        dialog: false,
        type: "error",
      });
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!selectedInvoice) return;
    if (selectedInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await emailReceipt(emails);
    }
  }

  async function handleDeleteInvoice(
    siteKeyID: string,
    invoiceID: string,
  ): Promise<void> {
    return DbWrite.invoices.delete(siteKeyID, invoiceID);
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (!stiltInvoiceID) return;
    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "web",
    );
    if (paymentLink) {
      goToPaymentPage(paymentLink);
    } else {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERROR_PAYMENT_LINK,
        type: "error",
      });
    }
  }

  async function applyManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: isInvoiceDialogOpen,
        type: "error",
      });
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: selectedDateTime.toISO(),
      customerID: selectedInvoice.customerID,
      billToCustomerID: selectedInvoice.billToCustomerID,
      locationID: selectedInvoice.locationID,
      invoiceID: stiltInvoiceID,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: siteKey,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    logger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      setStiltInvoiceID(null);
      if (
        validPaymentData.amount === selectedInvoice.amountDue &&
        selectedInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (selectedInvoice.email !== null) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: isInvoiceDialogOpen,
            type: "success",
          });
        } else {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: isInvoiceDialogOpen,
            type: "info",
          });
        }
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: isInvoiceDialogOpen,
          type: "success",
        });
      }
    } catch (err) {
      logger.error("handleRecordManualPayment", err);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: isInvoiceDialogOpen,
        type: "error",
      });
      return;
    }
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!selectedInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      selectedInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: selectedInvoice.id,
      refPath: selectedInvoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    logger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      logger.debug("Invoice has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.successfulUpdate(
          `Payment terms for Invoice #${selectedInvoice.invoiceNumber}`,
        ),
        type: "success",
      });
      setIsInvoiceDialogOpen(false);
    } catch (error) {
      logger.error(`An error occurred during handleSaveUpdatedInvoice`, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }

    setStiltInvoiceID(null);
  }

  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function handleSaveNewPBItem(formValues: AddNewPBItem) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    const newPBItem: PBItem_CreateAPI = {
      ...formValues,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKey,
    };

    //Validate
    const validatedPBItem = PriceBookItemManager.parseCreate(newPBItem);
    logger.info("Validated Price Book Item:", validatedPBItem);

    //DB
    try {
      await mutateAddPBItem.mutateAsync({
        validPBItem: validatedPBItem,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(validatedPBItem.title),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewPBItem`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleEditPBItem(
    updatePBItem: Partial<ExistingPriceBookItem>,
  ) {
    if (pBItemDoc == null) {
      return;
    }

    updatePBItem["lastModifiedBy"] = firebaseUser.uid;

    const diffPBItemValues: DocumentData = diffObjects(
      pBItemDoc,
      updatePBItem,
    ).diff;

    if (Object.keys(diffPBItemValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    diffPBItemValues["id"] = pBItemDoc.id;
    diffPBItemValues["refPath"] = pBItemDoc.refPath;

    /* validate values from the form */
    const validateEditPBItem =
      PriceBookItemManager.parseUpdate(diffPBItemValues);

    try {
      await mutateEditPBItem.mutateAsync({
        editPBItem: validateEditPBItem,
      });
      logger.debug("Customer has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(pBItemDoc.title),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleEditPBItem`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleDeletePBItem(pbItemID: string) {
    if (pBItemDoc == null) {
      return;
    }

    try {
      await DbWrite.priceBookItems.delete(siteKey, pbItemID);
      logger.debug("Pricebook item deleted successfully.");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulDelete(pBItemDoc.title),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleEditPBItem`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKey,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  /* COMPONENTS */
  const addEditPBItemDialog = userPermissions && (
    <AddEditPriceBookItemDialog
      isDialogOpen={addEditPBItemDialogOpen}
      siteKeyLocationList={siteKeyLocationList}
      closeDialog={() => {
        setAddEditPBItemDialogOpen(false);
        setPBItemDoc(null);
      }}
      handleSaveNewPBItem={handleSaveNewPBItem}
      handleEditPBItem={handleEditPBItem}
      userPermissions={userPermissions}
      handleDeletePBItem={handleDeletePBItem}
      priceBookCategories={priceBookItemCategories}
      inventoryEnabled={siteKeyDoc?.customizations.inventoryEnabled ?? false}
      allowEditingAccountNumbers={
        !siteKeyDoc?.customizations?.accounting?.codatConnectionID
      }
      allowEditingCommissions={siteKeyDoc?.customizations.commissions}
      priceBookItemDoc={pBItemDoc}
    />
  );

  const dueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="flex items-center gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <DatePicker
        selected={
          selectedIssueDate
            ? selectedIssueDate.toJSDate()
            : DateTime.now().toJSDate()
        }
        onChange={(date: Date) => {
          const luxonDate = DateTime.fromJSDate(date);
          setSelectedIssueDate(luxonDate);
          setPaymentTerms(null);
        }}
        customInput={<SchedulingButton />}
      />
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = selectedInvoice && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={selectedInvoice}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  const datePicker = selectedInvoice && (
    <DatePicker
      selected={selectedDateTime.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setSelectedDateTime(luxonDate);
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    siteKeyDoc &&
    userPermissions &&
    selectedInvoice &&
    currentCustomer && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        closeDialog={() => setIsHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={currentCustomer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, currentCustomer)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  const viewInvoiceDialog = isInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        isOpen={isInvoiceDialogOpen}
        onClose={closeViewInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        handlePayment={onClickHandlePaymentButton}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        editInvoice={handleEditInvoiceDialogOpen}
        sendEmail={emailInvoice}
        deleteInvoice={handleDeleteInvoice}
        userDisplayNamesMap={userDisplayNamesMap}
        handleDeletePayment={handleDeletePayment}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  const excelExportButton = (
    <StyledTooltip title={`Export to Excel`}>
      <button
        onClick={saveSpreadSheet}
        className="h-10 rounded-md bg-gray-200 p-2 transition-colors hover:bg-gray-300"
        disabled={isBusy === "export"}
      >
        {isBusy === "export" ? (
          <LoadingSpinner marginClass="m-0.5" />
        ) : (
          <FileDownloadIcon fontSize="medium" className="shrink-0" />
        )}
      </button>
    </StyledTooltip>
  );

  const pendingOrSubmittedToggle = (
    <BaseButtonSecondary
      className="w-96"
      onSubmit={() => {
        setPendingOrSubmitted(
          pendingOrSubmitted === "pending" ? "submitted" : "pending",
        );
      }}
      onClick={() => {
        setPendingOrSubmitted(
          pendingOrSubmitted === "pending" ? "submitted" : "pending",
        );
      }}
    >
      {pendingOrSubmitted === "pending" ? "Show Submitted" : "Show Pending"}
    </BaseButtonSecondary>
  );

  /* FUNCTIONS */
  function handleDateRangeChange(
    value: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>,
  ) {
    logger.debug(
      `Invoked handleDateRangeChange for range: ${value.start.month}-${value.start.day} to ${value.end.month}-${value.end.day}`,
    );
    try {
      const convertedStart = toCalendarDate(value.start);
      const convertedEnd = toCalendarDate(value.end);
      const converted = { start: convertedStart, end: convertedEnd };
      setRange(converted);
      logger.debug(
        `set range to ${converted.start.month}-${converted.start.day} to ${converted.end.month}-${converted.end.day}`,
      );
    } catch (e) {
      // TODO: Handle this error better
      if (e instanceof Error) {
        console.log("Error in handleDateRangeChange", e);
        logger.error("Error in handleDateRangeChange", e);
      }
    }
  }

  const dateRangePicker = (
    <div className="flex w-full flex-row items-center justify-end">
      <span className="pr-2 text-right">{strings.DATE_PUSHED}</span>
      <RADateRangePicker value={range} onChange={handleDateRangeChange} />
    </div>
  );

  const actionDropdownButton = pendingOrSubmitted === "pending" && (
    <LightspeedActionDropdown
      actionsLoading={actionsLoading}
      lightspeedActionSelected={async (
        actionType: "pushTransactions",
        refPaths: any[],
      ) => {
        try {
          if (actionsLoading) {
            return;
          }
          setActionsLoading(true);
          const siteKeyID = refPaths[0]["refPath"].split("/")[1];

          const refPathList: string[] = [];
          for (const row of refPaths) {
            refPathList.push(row["refPath"]);
          }

          const result = await manuallyPushLightspeedTransactions({
            siteKeyID,
            refPathList,
          });

          const failedRecords = result.data;

          if (!Array.isArray(failedRecords)) {
            addToastMessage({
              id: createToastMessageID(),
              message: strings.ERROR_ATTEMPTING_PUSH,
              type: "error",
              dialog: false,
            });
            setActionsLoading(false);
            return;
          }

          if (failedRecords.length > 0) {
            // Display error stating how many records failed to have a sync job created for them
            addToastMessage({
              id: createToastMessageID(),
              message: `Failed to push ${failedRecords.length} of ${refPathList.length} records. Please try again later.`,
              type: "error",
              dialog: false,
            });
          } else {
            // Show success message
            addToastMessage({
              id: createToastMessageID(),
              message: `Successfully pushed all ${refPathList.length} records.`,
              type: "success",
              dialog: false,
            });
          }
          setActionsLoading(false);
        } catch (error) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.ERROR_ATTEMPTING_PUSH,
            type: "error",
            dialog: false,
          });
          setActionsLoading(false);
        }
      }}
      // invoiceActionSelected={props.invoiceActionSelected}
      selectedRows={selectedRows}
    />
  );

  /* RENDER LOADING */
  if (!siteKeyDoc || siteKeyDocIsLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  /* RENDER CONTENT */
  return (
    <Fragment>
      <LightspeedPage
        tableData={lightspeedTransactions}
        openInvoiceDialog={openViewInvoiceDialog}
        exportToExcelButton={excelExportButton}
        actionDropdownButton={actionDropdownButton}
        setSelectedRows={setSelectedRows}
        pendingOrSubmitted={pendingOrSubmitted}
        pendingOrSubmittedToggle={pendingOrSubmittedToggle}
        dateRangePicker={dateRangePicker}
      />
      {viewInvoiceDialog}
      {addEditPBItemDialog}
    </Fragment>
  );
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}

async function formatDataForExcelExport(
  lightspeedTransactions: ExistingLightspeedTransaction[],
  userDisplayNamesMap: Record<string, string>,
  siteKeyLocationList: ExistingSiteKeyLocation[],
): Promise<Record<string, any>[]> {
  const result: Record<string, any>[] = [];
  for (const txn of lightspeedTransactions) {
    const data = {
      ...flattenObj(txn),
      locationID:
        siteKeyLocationList.find((location) => location.id === txn.locationID)
          ?.title ?? "Unknown Location",
      createdBy: userDisplayNamesMap[txn.createdBy],
      timestampCreated: txn.timestampCreated
        ? convertToReadableTimestamp(txn.timestampCreated)
        : "",
      timestampPushed: txn.timestampPushed
        ? convertToReadableTimestamp(txn.timestampPushed)
        : "",
    };
    result.push(data);
  }

  return result;
}
