import BaseModal from "./BaseModal";

function displayVariantRHD(): JSX.Element {
  return (
    <div className="mb-2 w-full bg-primary px-4 pb-1.5 pt-2 text-center xs:mb-5 md:pb-2.5 md:pt-3">
      <h1 className="m-0 text-lg font-light text-white sm:text-xl">
        Previous Values
      </h1>
    </div>
  );
}

const parentDivStyles = "pb-6 xs:w-96 sm:w-full sm:max-w-screen-sm";

interface Props {
  children: React.ReactElement;
  closeModal: () => void;
  open: boolean;
}

export default function RHDModal(props: Props): JSX.Element {
  const titleComponent = displayVariantRHD();
  return (
    <BaseModal
      open={props.open}
      title={titleComponent}
      closeModal={props.closeModal}
      parentDivStyles={parentDivStyles}
    >
      {props.children}
    </BaseModal>
  );
}
