// Libs
import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { DocumentData } from "firebase/firestore";
import { useAuthStore } from "../store/firebase-auth";

// Local
import { DbRead, DbWrite } from "../database";
import EditItemPage from "./EditItemPage";
import { ExistingChecklistItem } from "../models/checklist-item";
import { logger as devLogger } from "../logging";
import LoadingClipboardAnimation from "../components/LoadingClipBoardAnimation";

interface Props {
  siteKey: string;
}

export default function EditItemContainer({
  siteKey,
  ...props
}: Props): JSX.Element {
  const [checklistItem, setChecklistItem] = useState<ExistingChecklistItem>();

  type UrlParams = { id: string };
  const { id: itemID } = useParams<UrlParams>();

  const navigate = useNavigate();
  const location = useLocation();
  if (itemID == null) {
    navigate("/404");
  }
  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  // Get the craftRecordID that has been stored in the history
  // object. Use it to update the checklist item document.
  const historyObject = location.state as { craftRecordID: string };

  // When closing the modal or clicking Cancel button, go back to previous
  // page.
  function handleCancel() {
    navigate(-1);
  }

  // Handle fetching a list of example checklist items on the database when this
  // component loads.
  useEffect(() => {
    async function getSingleItem() {
      if (typeof siteKey === "string") {
        if (typeof itemID === "string") {
          const item = await DbRead.checklistItems.getItem(siteKey, itemID);
          if (item !== undefined) {
            setChecklistItem(item);
          } else {
            devLogger.error("Item is undefined");
          }
        } else {
          devLogger.error(`itemID was not a string ${itemID}`);
        }
      } else {
        devLogger.error(`Site key was not a string ${siteKey}`);
      }
    }
    getSingleItem();
  }, [siteKey, itemID]);

  async function handleOnEditItem(data: DocumentData) {
    if (typeof itemID !== "string") {
      throw new Error(`itemID was not a string: ${itemID}`);
    }
    await DbWrite.checklistItems.update(
      siteKey,
      historyObject.craftRecordID,
      data,
      itemID,
    );
  }

  return checklistItem && firebaseUser ? (
    <EditItemPage
      item={checklistItem}
      onEditItem={handleOnEditItem}
      onCancel={handleCancel}
      uid={firebaseUser.uid}
    />
  ) : (
    <LoadingClipboardAnimation />
  );
}
