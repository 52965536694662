interface Props {
  text: string;
  color: "green" | "red" | "orange" | "blue" | "gray";
  margin?: string;
}

export default function PillStatus(props: Props) {
  const colors = getColorClasses(props.color);
  return (
    <div
      className={`${colors.background} ${props.margin} flex max-w-fit items-center justify-center rounded-full py-1 pl-3 pr-3 text-sm font-medium text-gray-900`}
    >
      {props.text}
    </div>
  );
}

function getColorClasses(color: Props["color"]): Record<string, string> {
  let styles: Record<string, string> = {};
  switch (color) {
    case "blue": {
      styles = {
        background: "bg-blue-200",
      };
      return styles;
    }

    case "green": {
      styles = {
        background: "bg-green-300",
      };
      return styles;
    }

    case "orange": {
      styles = {
        background: "bg-orange-200",
      };
      return styles;
    }

    case "red": {
      styles = {
        background: "bg-red-200",
      };
      return styles;
    }

    case "gray": {
      styles = {
        background: "bg-gray-200",
      };
      return styles;
    }
  }
}
