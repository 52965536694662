//Libs
import { useEffect, useState } from "react";

//Local
import { logger as devLogger } from "../../logging";

interface MarkerProps {
  position: google.maps.LatLngLiteral;
  map?: google.maps.Map;
  getNewPosition: (position: google.maps.LatLngLiteral) => void;
  getNewZoom: (currentZoom: number) => void;
}

export default function Marker({
  position,
  map,
  getNewPosition,
  getNewZoom,
}: MarkerProps) {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  if (marker != null && map != null) {
    marker.setMap(map);
    marker.setPosition(position);
    marker.setDraggable(true);

    marker.addListener("dragend", function (event: any) {
      const currentPosition = marker.getPosition()?.toJSON();
      const currentZoom = map.getZoom();
      if (currentPosition != null) {
        getNewPosition(currentPosition);
      } else {
        devLogger.error(
          "Something went wrong during get position from marker on map",
        );
      }
      if (currentZoom != null) {
        getNewZoom(currentZoom);
      } else {
        devLogger.error(
          "Something went wrong during get position from marker on map",
        );
      }
    });
  }

  return null;
}
