// Local
import { convertISOToLuxonDT } from "./convertISOToLuxonDT";
import { convertFSTimestampToLuxonDT } from "./convertFSTimestampToLuxonDT";

interface Args {
  /** Should be a Firestore Timestamp or ISO string */
  startDate: unknown;
  /**  Should be a Firestore Timestamp or ISO string */
  endDate: unknown;
}

/**
 * Given two Firestore Timestamps or two ISO strings, will calculate the difference
 * in hours between them.
 *
 * @returns Number: difference in hours
 *
 * Tested ✅
 */
export function getTimeDifferenceInHours(args: Args): number {
  if (typeof args.startDate === "string" && typeof args.endDate === "string") {
    const start = convertISOToLuxonDT(args.startDate);
    const end = convertISOToLuxonDT(args.endDate);

    const hoursObj = end.diff(start, "hours").toObject();
    if (!hoursObj.hours) {
      throw new Error("unable to get difference in hours");
    }

    return hoursObj.hours;
  } else {
    const start = convertFSTimestampToLuxonDT(args.startDate);
    const end = convertFSTimestampToLuxonDT(args.endDate);

    const hoursObj = end.diff(start, "hours").toObject();
    if (!hoursObj.hours) {
      throw new Error("unable to get difference in hours");
    }

    return hoursObj.hours;
  }
}
