//Libs
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { User } from "firebase/auth";
import { DateTime } from "luxon";

//Local
import CreateEstimatePage from "./CreateEstimatePage";
import * as strings from "../../strings";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  EstimateItemManager,
  EstimateItem_CreateAPI,
  TemporaryEstimateItem,
} from "../../models/estimate-item";
import BaseInputTextArea from "../../components/BaseInputTextArea";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import {
  Estimate,
  EstimateManager,
  EstimateStatus,
  Estimate_CreateAPI,
} from "../../models/estimate";
import { useAuthStore } from "../../store/firebase-auth";
import { logger } from "../../logging";
import BaseInputNumber from "../../components/BaseInputNumber";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import ButtonColored from "../../components/ButtonColored";
import { convertFSTimestampToLuxonDT, createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import {
  EstimatePackageManager,
  EstimatePackage_CreateAPI,
} from "../../models/estimate-package";
import { getEstimateTotals } from "../../assets/js/estimateFunctions";
import { useNavToViewEstimate } from "../../navigation";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import {
  ExistingMembershipTemplate,
  MembershipDiscount,
} from "../../models/membership-template";
import { ExistingTask } from "../../models/task";
import { ExistingCraftRecord } from "../../models/craft-record";
import BaseInputCheckbox from "../../components/BaseInputCheckbox";
import { getTotalDiscounts } from "../../assets/js/memberships/getTotalDiscounts";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { SiteKeyLocationSection } from "../../components/SiteKeyLocationSection";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { useTypesenseStore } from "../../store/typesense";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import CreateEstimateDetails from "../../components/estimates/CreateEstimateDetails";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import AddNewEstimateItemSelection from "../../components/estimates/AddNewEstimateItemSelection";
import EditEstimateItemLine from "../../components/estimates/EditEstimateItemLine";
import EstimateItemInline from "../../components/estimates/EstimateItemInline";
import EditCustomerDialog from "../../components/customers/EditCustomerDialog";
import { getTemporaryDataForEditMemberships } from "../../assets/js/memberships/getTemporaryDataForEditMemberships";
import cloneDeep from "lodash/cloneDeep";
import { getMembershipIdsCount } from "../../assets/js/memberships/getMembershipIdsCount";
import {
  customerWithoutTimestamps,
  ExistingCustomer,
  ExistingCustomerUpdate,
} from "../../models/customer";
import { DocumentData, Timestamp } from "firebase/firestore";
import { diffObjects } from "../../assets/js/object-diff";
import { getMembershipDiscountsForCustomer } from "../../assets/js/memberships/getDiscountList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CreateTaskSecondaryHeading } from "../Customers/CreateTaskForCustomerPage";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import DropdownInventoryTransactionTypes from "../../components/inventory/DropdownInventoryTransactionTypes";
import {
  InventoryTransaction,
  InventoryTransactionValues,
  OInventoryTransactionTypes,
} from "../../models/inventory-transaction";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import { ExistingInventoryObject } from "../../models/inventory-object";

interface Props {
  siteKey: string;
}

export default function CreateEstimateContainer({ siteKey }: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const membershipTemplateList: ExistingMembershipTemplate[] =
    useMembershipTemplatesStore((state) => state.membershipTemplates);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const siteKeyLocations = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );

  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);

  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  const location = useLocation();
  const { state } = location;

  const customerDocFromState = state.customerDoc;
  const taskDoc = state.taskDoc;

  const navToViewEstimate = useNavToViewEstimate();

  /* STATES */
  const [customerDoc, setCustomerDoc] =
    useState<ExistingCustomer>(customerDocFromState);
  const [selectedCustomerLocation, setSelectedCustomerLocation] =
    useState<ExistingCustomerLocation | null>(null);
  const [estimateItemList, setEstimateItemList] = useState<
    TemporaryEstimateItem[]
  >([]);
  const [editCustomerDialogOpen, setEditCustomerDialogOpen] =
    useState<boolean>(false);
  const [globalDiscount, setGlobalDiscount] =
    useState<Estimate["discount"]>(null);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [workRecord, setWorkRecord] = useState<ExistingCraftRecord | null>(
    null,
  );
  const [
    priceBookItemsWithCommissionOverrides,
    setPriceBookItemsWithCommissionOverrides,
  ] = useState<ExistingPriceBookItem[]>([]);

  const [customerTemporaryMemberships, setCustomerTemporaryMemberships] =
    useState<Record<string, any>[]>([]);
  const [customerMembershipIds, setCustomerMembershipIds] = useState<string[]>(
    [],
  );

  const [selectedSiteKeyLocationID, setSelectedSiteKeyLocationID] = useState<
    ExistingSiteKeyLocation["id"] | null
  >(null);

  const [inventoryLocations, setInventoryLocations] = useState<
    ExistingInventoryLocation[]
  >([]);
  const [inventoryObjects, setInventoryObjects] = useState<
    ExistingInventoryObject[]
  >([]);

  /* QUERIES */
  // Query to get the `customerLocation` docs related to the customer
  const customerLocationQueryKey = [
    "createEstimateContainer_CustomerLocations",
    siteKey,
  ];
  const {
    data: customerLocationOptions = [],
    isLoading: customerLocationLoading,
  } = useQuery(customerLocationQueryKey, () =>
    DbRead.customerLocations.getByCustomerId(siteKey, customerDoc.id),
  );

  const [inventorySectionIsExpanded, setInventorySectionIsExpanded] =
    useState<boolean>(false);
  const [pendingInventoryTransactions, setPendingInventoryTransactions] =
    useState<InventoryTransaction[]>([]);

  const [estimateNotes, setEstimateNotes] = useState<Estimate["notes"]>(null);
  const [estimateInternalNotes, setEstimateInternalNotes] =
    useState<Estimate["internalNotes"]>(null);

  const [customerDiscount, setCustomerDiscount] = useState<
    MembershipDiscount[]
  >([]);
  const [selectedLocationDiscount, setSelectedLocationDiscount] = useState<
    MembershipDiscount[]
  >([]);

  const totalSelectedDiscounts = getTotalDiscounts(
    customerDiscount,
    selectedLocationDiscount,
  );

  const [pbItemsFromTypesense, setPbItemsFromTypesense] = useState<
    ExistingPriceBookItem[]
  >([]);

  const customerMembershipIdsCount = getMembershipIdsCount(
    customerMembershipIds,
  );

  // Query to get customer memberships docs
  const membershipQueryKey = [
    "showSingleCustomer_Membership",
    siteKey,
    customerDoc.id,
  ];
  const { data: memberships = [], isLoading: membershipsIsLoading } = useQuery(
    membershipQueryKey,
    () => DbRead.memberships.getByCustomerId(siteKey, customerDoc.id),
  );

  const customerLocationMemberships = memberships.filter(
    (membership) => membership.customerLocationID !== null,
  );

  const existingCustomerMembershipIds: string[] | undefined =
    customerDoc?.customData["membershipTemplateIDs"];

  /* USE EFFECT */
  useEffect(() => {
    async function getPBItems() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );
      if (
        siteKeyDoc?.customizations.filterPriceBookItemsByLocationID === true &&
        selectedSiteKeyLocationID != null
      ) {
        const filterPBItems = pbItems.filter(
          (item) =>
            item.locationID === selectedSiteKeyLocationID ||
            item.locationID === null,
        );

        setPbItemsFromTypesense(filterPBItems);
      } else {
        setPbItemsFromTypesense(pbItems);
      }
    }

    getPBItems();
  }, [typesenseSearchKey, selectedSiteKeyLocationID]);

  useEffect(() => {
    function getCustomer() {
      if (!customerDocFromState) return undefined;
      const unsubscribe = DbRead.customers.subscribe({
        customerID: customerDocFromState.id,
        siteKey: siteKey,
        onChange: setCustomerDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomer();
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerDocFromState]);

  useEffect(() => {
    const pendingInvTrans: InventoryTransaction[] = [];
    estimateItemList.forEach((eI) => {
      if (inventoryObjects.find((iO) => iO.id === eI.priceBookItemID)) {
        pendingInvTrans.push({
          companyID: userPermissions?.companyID as string,
          inventoryObjectID: eI.priceBookItemID,
          parentRecordID: workRecord?.id ?? null,
          taskID: taskDoc?.id ?? null,
          type: OInventoryTransactionTypes.RESERVE,
          value: eI.quantity,
          timestampCreated: Timestamp.now(),
          createdBy: firebaseUser.uid,
          referenceTransactionID: null,
          inventoryLocationID: inventoryLocations[0].id,
        });
      }
    });
    setPendingInventoryTransactions(pendingInvTrans);
  }, [estimateItemList]);

  useEffect(() => {
    async function getPriceBookItemsWithCommissionOverrides() {
      if (!siteKeyDoc) return;

      if (!siteKeyDoc.customizations.commissions) return;

      const pbItems = await DbRead.priceBookItems.getAll(siteKeyDoc.id);
      setPriceBookItemsWithCommissionOverrides(
        pbItems.filter((pbItem) => pbItem.commissions),
      );
    }

    getPriceBookItemsWithCommissionOverrides();
  }, [siteKeyDoc]);

  const onFilterTextBoxChanged = useCallback(
    async (searchTerm: string) => {
      if (!typesenseSearchKey) return;

      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        searchTerm,
      );
      if (
        siteKeyDoc?.customizations.filterPriceBookItemsByLocationID === true &&
        selectedSiteKeyLocationID != null
      ) {
        const filterPBItems = pbItems.filter(
          (item) =>
            item.locationID === selectedSiteKeyLocationID ||
            item.locationID === null,
        );
        setPbItemsFromTypesense(filterPBItems);
      } else {
        setPbItemsFromTypesense(pbItems);
      }
    },
    [typesenseSearchKey, selectedSiteKeyLocationID],
  );

  useEffect(() => {
    setCustomerDiscount(
      getMembershipDiscountsForCustomer(
        memberships.filter((m) => m.customerLocationID === null),
        membershipTemplateList,
      ),
    );
  }, [customerDoc, membershipTemplateList]);

  useEffect(() => {
    setSelectedLocationDiscount(
      getMembershipDiscountsForCustomer(
        customerLocationMemberships,
        membershipTemplateList,
      ),
    );
  }, [membershipTemplateList, selectedCustomerLocation]);

  useEffect(() => {
    async function getWorkRecord() {
      if (taskDoc) {
        const result = await DbRead.parentRecords.getByRefPath(
          taskDoc.craftRecordID,
        );
        setWorkRecord(result);
      }
    }

    getWorkRecord();
  }, [taskDoc]);

  useEffect(() => {
    async function addDefaultPBItems(addToAllEstimates: string[]) {
      if (!siteKeyDoc || !siteKeyDoc.id) {
        return;
      }
      const baseEstimateItems: TemporaryEstimateItem[] = [];
      for (const pbItemID of siteKeyDoc.customizations.addToAllEstimates) {
        const pbItem = await DbRead.priceBookItems.get(siteKeyDoc.id, pbItemID);
        if (
          pbItem &&
          (pbItem.locationID === null ||
            pbItem.locationID === selectedSiteKeyLocationID)
        ) {
          const newEstimateItem: TemporaryEstimateItem = {
            priceBookItemID: pbItem.id,
            title: pbItem.title,
            description: pbItem.description,
            units: pbItem.units,
            unitPrice: pbItem.unitPrice,
            cost: pbItem.cost,
            locationID: "",
            discountableForMemberships: pbItem.discountableForMemberships,
            type: pbItem.type ?? null,
            tags: pbItem.tags,
            customData: pbItem.customData,
            taxable: pbItem.taxable,
            editable: pbItem.editable,
            discountable: pbItem.discountable,
            quantity: 1,
            discount: 0,
            deleted: false,
            toBeEdited: pbItem.editable,
          };
          newEstimateItem.id = DbRead.randomDocID.get();
          baseEstimateItems.push(newEstimateItem);
        }
      }
      setEstimateItemList(baseEstimateItems);
    }

    if (siteKeyDoc?.customizations.addToAllEstimates) {
      addDefaultPBItems(siteKeyDoc?.customizations.addToAllEstimates);
    }
  }, [siteKeyDoc, selectedSiteKeyLocationID]);

  useEffect(() => {
    const initialNotesFromTask =
      taskDoc && workRecord
        ? getInitialNotesFromTask(taskDoc, workRecord)
        : null;
    setEstimateNotes(initialNotesFromTask);
  }, [taskDoc, workRecord]);

  useEffect(() => {
    if (customerLocationOptions != null) {
      const selectedLocation = customerLocationOptions.find(
        (location) => location.id === taskDoc?.customerLocationID,
      );
      if (selectedLocation != null) {
        setSelectedCustomerLocation(selectedLocation);
      }
    }
  }, [customerLocationOptions, taskDoc?.customerLocationID]);

  useEffect(() => {
    if (taskDoc) return;
    if (userPermissions && userPermissions.defaultLocationID) {
      setSelectedSiteKeyLocationID(userPermissions.defaultLocationID);
    } else if (siteKeyLocations.length === 1) {
      setSelectedSiteKeyLocationID(siteKeyLocations[0].id);
    }
  }, [siteKeyLocations, userPermissions]);

  useEffect(() => {
    if (!taskDoc) return;
    setSelectedSiteKeyLocationID(taskDoc.locationID);
  }, [taskDoc]);

  useEffect(() => {
    const unsubscribe = DbRead.inventoryLocations.subscribeAll({
      siteKey,
      onChange: (locations) => {
        setInventoryLocations(locations);
      },
    });

    return unsubscribe;
  }, [siteKeyDoc]);

  useEffect(() => {
    const unsubscribe = DbRead.inventoryObjects.subscribeAll({
      siteKey,
      onChange: (objects) => {
        setInventoryObjects(objects);
      },
    });

    return unsubscribe;
  }, [siteKeyDoc]);

  /* MUTATIONS */
  /**
   * For adding estimate to DB
   */

  const mutateEditCustomer = useMutation(
    async (args: { editCustomer: ExistingCustomerUpdate }) => {
      await DbWrite.customers.update(args.editCustomer);
    },
  );

  // const mutateAddMemberships = useMutation(
  //   async (args: { validMembershipList: Membership_CreateAPI[] }) => {
  //     await DbWrite.memberships.create(args.validMembershipList);
  //   },
  // );
  // const mutateDeleteMemberships = useMutation(
  //   async (args: { membershipIdsToDelete: Membership_DeleteAPI[] }) => {
  //     if (siteKeyDoc) {
  //       args.membershipIdsToDelete.forEach(async (membershipID) => {
  //         await DbWrite.memberships.delete(siteKeyDoc.id, membershipID);
  //       });
  //     }
  //   },
  // );
  const mutateAddEstimate = useMutation(
    async (args: {
      validEstimatePackage: EstimatePackage_CreateAPI;
      validEstimate: Estimate_CreateAPI;
      validEstimateItemList: EstimateItem_CreateAPI[];
    }) => {
      await DbWrite.estimates.create(
        args.validEstimate,
        args.validEstimatePackage,
        args.validEstimateItemList,
      );
      /* after the estimate & estimate items are saved, make the estimate item object empty */
      setEstimateItemList([]);
    },
  );

  /* FUNCTIONS */

  //TODO: dry this up from elsewhere
  async function handleEditCustomer(updateCustomer: customerWithoutTimestamps) {
    if (customerDoc == null) {
      return;
    }

    const initialValuesForCustomer = cloneDeep(customerDoc);

    /* check the difference between the initial customer doc and the updated one */
    const diffCustomerValues: DocumentData = diffObjects(
      initialValuesForCustomer,
      updateCustomer,
    ).diff;

    if (Object.keys(diffCustomerValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const validateEditCustomer: ExistingCustomerUpdate = {
      ...diffCustomerValues,
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };

    // const { newMembershipIds, deletedMembershipIds } = getUpdatesOnMemberships(
    //   initialValuesForCustomer.customData["membershipTemplateIDs"],
    //   customerMembershipIds,
    // );
    //
    // const validatedMembershipList: Membership_CreateAPI[] = [];
    //
    // /* for newMembershipIds array, needs to write the new docs to DB */
    // if (newMembershipIds.length > 0 && siteKeyDoc) {
    //   const newMembershipDocList: Membership_CreateAPI[] = newMembershipIds.map(
    //     (newMembershipId: string) => {
    //       const currentMembershipTemplate = membershipTemplateList.find(
    //         (membershipTemplate) => membershipTemplate.id === newMembershipId,
    //       );
    //
    //       return {
    //         customerID: customerDoc.id,
    //         customerLocationID: null,
    //         membershipTemplateID: newMembershipId,
    //         status: "awaitingPayment",
    //         notes: null,
    //
    //         createdBy: firebaseUser.uid,
    //         lastModifiedBy: firebaseUser.uid,
    //         siteKey: siteKeyDoc.id,
    //         frequency: currentMembershipTemplate
    //           ? currentMembershipTemplate.frequency
    //           : "indefinite",
    //       };
    //     },
    //   );
    //   const validObjectList = newMembershipDocList.map((membership) =>
    //     MembershipManager.parseCreate(membership),
    //   );
    //   validatedMembershipList.push(...validObjectList);
    // }
    //
    // /* for deletedMembershipIds array, needs to get the membershipID */
    // const membershipIdsToDelete: string[] = [];
    // if (deletedMembershipIds.length !== 0 && siteKeyDoc) {
    //   const deletedIdsCount = getMembershipIdsCount(deletedMembershipIds);
    //   for (const [templateId, quantity] of Object.entries(deletedIdsCount)) {
    //     const membershipDocs = await DbRead.memberships.getByTemplateId(
    //       siteKeyDoc.id,
    //       customerDoc.id,
    //       null,
    //       templateId,
    //     );
    //     if (membershipDocs.length > 0) {
    //       for (let i = 0; i < quantity; i++) {
    //         membershipIdsToDelete.push(membershipDocs[i].id);
    //       }
    //     }
    //   }
    // }

    try {
      // if (
      //   validatedMembershipList.length !== 0 &&
      //   membershipIdsToDelete.length !== 0
      // ) {
      //   /* case: edit customer, add new membership docs & delete membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     mutateAddMemberships.mutateAsync({
      //       validMembershipList: validatedMembershipList,
      //     }),
      //     mutateDeleteMemberships.mutateAsync({
      //       membershipIdsToDelete: membershipIdsToDelete,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else if (
      //   validatedMembershipList.length === 0 &&
      //   membershipIdsToDelete.length !== 0
      // ) {
      //   /* case: edit customer & delete membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     mutateDeleteMemberships.mutateAsync({
      //       membershipIdsToDelete: membershipIdsToDelete,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else if (
      //   validatedMembershipList.length !== 0 &&
      //   membershipIdsToDelete.length === 0
      // ) {
      //   /* case: edit customer & add membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     mutateAddMemberships.mutateAsync({
      //       validMembershipList: validatedMembershipList,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else {
      //
      // }
      /* case: edit customer */
      await mutateEditCustomer.mutateAsync({
        editCustomer: validateEditCustomer,
      });
      logger.debug("Customer has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          updateCustomer.name ?? initialValuesForCustomer.name,
        ),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewCustomer`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  function openEditCustomerDialog() {
    setEditCustomerDialogOpen(true);
    // if (existingCustomerMembershipIds != null) {
    //   setCustomerMembershipIds(existingCustomerMembershipIds);
    //   const idsCount = getMembershipIdsCount(existingCustomerMembershipIds);
    //   const tempData = getTemporaryDataForEditMemberships(idsCount);
    //   setCustomerTemporaryMemberships(tempData);
    // }
  }

  async function handleSaveNewEstimate() {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (selectedSiteKeyLocationID == null) {
      logger.error("selectedSiteKeyLocationID is null");
      return;
    }

    if (customerDoc == null) {
      logger.error("customer is null");
      return;
    }

    if (selectedCustomerLocation == null) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_CUSTOMER_LOCATION_SELECTED,
        dialog: false,
        type: "error",
      });
      return;
    }

    if (estimateItemList.length === 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_ESTIMATE_ITEM_SELECTED,
        dialog: false,
        type: "error",
      });
      return;
    }

    setIsBusy(true);

    // Create inventory transactions from pendingTransactions
    if (pendingInventoryTransactions.length > 0) {
      await DbWrite.inventoryTransactions.createMultiple(
        siteKey,
        pendingInventoryTransactions,
      );
    }

    const estimateID = DbRead.randomDocID.get();
    const estimatePackageID = DbRead.randomDocID.get();

    const newEstimatePackage: EstimatePackage_CreateAPI = {
      notes: null,
      customerID: customerDoc.id,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKey,
      uuid: estimatePackageID,
    };

    // Validate estimate package
    const validatedEstimatePackage =
      EstimatePackageManager.parseCreate(newEstimatePackage);
    logger.info("Validated estimate package:", validatedEstimatePackage);

    let totalDiscount = 0;

    if (globalDiscount !== null) {
      totalDiscount += globalDiscount;
    }

    if (totalSelectedDiscounts != null) {
      totalDiscount += totalSelectedDiscounts;
    }

    const newEstimate: Estimate_CreateAPI = {
      status: EstimateStatus.DRAFT, // when is new, this is the default status. it will be update later on view estimate page
      notes: estimateNotes,
      internalNotes: estimateInternalNotes,
      customerID: customerDoc.id,
      customerLocationID: selectedCustomerLocation.id,

      // TODO: change this logic when we allow more estimates inside estimatePackages.
      // right now is only one estimate per package
      estimatePackageID: estimatePackageID,
      taskID: taskDoc?.id ?? null,
      craftRecordID: taskDoc?.craftRecordID.split("/")[3] ?? null,
      locationID: selectedSiteKeyLocationID,

      customData: {},
      tags: [],
      discount: totalDiscount !== 0 ? totalDiscount : null,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,

      timestampSentToCustomer: null, // is set to null when create new estimate, it will be update with the "send to customer button" on view estimate page
      timestampApprovedByCustomer: null, // is set to null when create new estimate, will be update later on view estimate page
      timestampExpiration: null, //for now is null, will be updated after MVP, when we will add the expiration field

      deleted: false,
      siteKey: siteKey,
      uuid: estimateID,
    };

    //Validate estimate
    const validatedEstimate = EstimateManager.parseCreate(newEstimate);
    logger.info("Validated estimate:", validatedEstimate);

    let validatedEstimateItemList = [];
    const newEstimateItemList: EstimateItem_CreateAPI[] = estimateItemList.map(
      (estimateItem) => {
        const { toBeEdited, ...rest } = estimateItem;
        return {
          ...rest,
          locationID: selectedSiteKeyLocationID,
          createdBy: firebaseUser.uid,
          lastModifiedBy: firebaseUser.uid,
          estimateID: estimateID,
          siteKey: siteKey,
        };
      },
    );
    validatedEstimateItemList = newEstimateItemList.map((estimateItem) =>
      EstimateItemManager.parseCreate(estimateItem),
    );
    logger.info("Validated estimate item list:", validatedEstimateItemList);

    //DB
    try {
      await mutateAddEstimate.mutateAsync({
        validEstimatePackage: validatedEstimatePackage,
        validEstimate: validatedEstimate,
        validEstimateItemList: validatedEstimateItemList,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(`estimate for ${customerDoc.name}`),
        dialog: false,
        type: "success",
      });
      navToViewEstimate(
        estimateID,
        customerDoc,
        selectedCustomerLocation,
        null,
      );
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewEstimate`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
    setIsBusy(false);
  }

  function removeEstimateItemFromList(
    estimateItemToBeDeleted: TemporaryEstimateItem,
  ): void {
    const filteredList = estimateItemList.filter(
      (estimateItem) => estimateItem.id !== estimateItemToBeDeleted.id,
    );
    setEstimateItemList(filteredList);
  }

  function editEstimateItemFromList(
    editedEstimateItem: TemporaryEstimateItem,
  ): void {
    const indexOfEstimateItem = estimateItemList.findIndex(
      (estimateItem) => estimateItem.id === editedEstimateItem.id,
    );

    if (indexOfEstimateItem >= 0) {
      setEstimateItemList((oldEstimateList) => {
        oldEstimateList[indexOfEstimateItem] = editedEstimateItem;
        return [...oldEstimateList];
      });
    }
  }

  function turnItemToBeEditable(itemToBeEdited: TemporaryEstimateItem): void {
    const indexOfEstimateItem = estimateItemList.findIndex(
      (estimateItem) => estimateItem.id === itemToBeEdited.id,
    );
    if (indexOfEstimateItem >= 0) {
      setEstimateItemList((oldEstimateList) => {
        oldEstimateList[indexOfEstimateItem].toBeEdited = true;
        return [...oldEstimateList];
      });
    }
  }

  function handleAddEstimateItem(tempEstimateItem: TemporaryEstimateItem) {
    estimateItemList.push(tempEstimateItem);
    setEstimateItemList(estimateItemList);
  }

  /* COMPONENTS */
  // const addCustomerMembershipButton = membershipTemplateList.length > 0 && (
  //   <Fragment>
  //     <BaseButtonSecondary
  //       type="button"
  //       onClick={() => setAddCustomerMembershipsDialogOpen(true)}
  //       className="w-full text-primary sm:w-48"
  //     >
  //       <AddCircleIcon fontSize="small" className="mr-2" />
  //       {strings.ADD_MEMBERSHIPS}
  //     </BaseButtonSecondary>
  //     {Object.keys(customerMembershipIdsCount).length > 0 ? (
  //       <div className="grid grid-cols-2 gap-4">
  //         {Object.entries(customerMembershipIdsCount).map(([key, value]) => {
  //           const currentTemplate = membershipTemplateList.find(
  //             (template) => template.id === key,
  //           );
  //           return (
  //             <div
  //               key={key}
  //               className="rounded-md border p-4 text-base font-bold capitalize text-primary"
  //             >
  //               {value} x {currentTemplate?.title}
  //             </div>
  //           );
  //         })}
  //       </div>
  //     ) : null}
  //   </Fragment>
  // );

  const editCustomerDialog = customerDoc && (
    <EditCustomerDialog
      isDialogOpen={editCustomerDialogOpen}
      addMembershipsButton={null}
      closeDialog={() => {
        const tempData = getTemporaryDataForEditMemberships(
          customerMembershipIdsCount,
        );
        setCustomerTemporaryMemberships(tempData);
        setCustomerMembershipIds(existingCustomerMembershipIds ?? []);
        setEditCustomerDialogOpen(false);
      }}
      handleEditCustomer={handleEditCustomer}
      customer={cloneDeep(customerDoc)}
    >
      {{}}
    </EditCustomerDialog>
  );

  const removeAllItemsBtn = estimateItemList.length !== 0 && (
    <ButtonColored
      className={`w-full xs:w-auto`}
      kind="danger"
      onClick={() => setEstimateItemList([])}
    >
      {strings.buttons.REMOVE_ALL_ITEMS}
    </ButtonColored>
  );

  const displayTemporaryEstimateItems =
    estimateItemList.length !== 0 &&
    selectedSiteKeyLocationID &&
    estimateItemList.map((estimateItem) => {
      if (estimateItem.toBeEdited) {
        return (
          <div key={estimateItem.id}>
            <EditEstimateItemLine
              PBItemQueryResultList={pbItemsFromTypesense}
              initialEstimateItemMembershipData={
                estimateItem.customData?.membershipData ?? null
              }
              estimateItem={estimateItem}
              handleEditEstimateItem={editEstimateItemFromList}
              onSearch={onFilterTextBoxChanged}
              handleDeleteEstimateItem={removeEstimateItemFromList}
              currency={
                siteKeyDoc?.customizations.accounting?.currency ?? "USD"
              }
            />
            <hr className="my-8 block w-full border border-gray-200" />
          </div>
        );
      } else {
        return (
          <div key={estimateItem.id}>
            <EstimateItemInline
              estimateItem={estimateItem}
              currency={
                siteKeyDoc?.customizations.accounting?.currency ?? "USD"
              }
              photos={[]}
              handleDeleteEstimateItem={removeEstimateItemFromList}
              turnItemToBeEditable={turnItemToBeEditable}
              allowEdit={true}
            />
            <hr className="my-4 block w-full border border-gray-200" />
          </div>
        );
      }
    });

  const addNewEstimateItemLine = selectedSiteKeyLocationID ? (
    <AddNewEstimateItemSelection
      onSearch={onFilterTextBoxChanged}
      PBItemQueryResultList={pbItemsFromTypesense}
      handleAddEstimateItem={handleAddEstimateItem}
      currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
    />
  ) : (
    <div className="text-lg font-bold text-red-600">
      Select a location first
    </div>
  );

  const notesField = (
    <BaseInputTextArea
      admin={false}
      rows={4}
      value={estimateNotes !== null ? estimateNotes : undefined}
      onChange={(event) => setEstimateNotes(event.target.value)}
      // added classes to make the border color match the other inputs on this UI
      className="col-span-2 w-full rounded-md border border-gray-600 leading-none"
      placeholder={strings.PLACEHOLDER_VISIBLE_NOTE}
    />
  );

  const internalNotesField = (
    <BaseInputTextArea
      admin={false}
      rows={4}
      value={estimateInternalNotes !== null ? estimateInternalNotes : undefined}
      onChange={(event) => setEstimateInternalNotes(event.target.value)}
      // added classes to make the border color match the other inputs on this UI
      className="col-span-2 w-full rounded-md border border-gray-600 leading-none"
      placeholder={strings.PLACEHOLDER_INTERNAL_NOTE}
    />
  );

  const discountField = (
    <form autoComplete="off" className="flex items-center gap-4 self-end">
      <BaseInputNumber
        type="number"
        step="0.01"
        className={`block w-20 rounded outline-none focus:ring-1 focus:ring-primaryLight sm:text-sm`}
        defaultValue={globalDiscount == null ? "" : globalDiscount}
        onBlur={(event) => {
          const discount = parseFloat(event.target.value);
          if (discount !== 0 && !isNaN(discount)) {
            setGlobalDiscount(discount);
          } else {
            setGlobalDiscount(null);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            const discount = parseFloat(
              (event.target as HTMLInputElement).value,
            );
            if (discount !== 0 && !isNaN(discount)) {
              setGlobalDiscount(discount);
            } else {
              setGlobalDiscount(null);
            }
          }
        }}
      />
      <span className="text-lg"> %</span>
    </form>
  );

  function handleTransactionType(
    inventoryTransactionType: InventoryTransactionValues,
    currentInventoryTransaction: InventoryTransaction,
  ) {
    const indexOfInventoryTransaction = pendingInventoryTransactions.findIndex(
      (inventoryTransaction) =>
        inventoryTransaction.inventoryObjectID ===
          currentInventoryTransaction.inventoryObjectID &&
        inventoryTransaction.inventoryLocationID ===
          currentInventoryTransaction.inventoryLocationID,
    );

    if (indexOfInventoryTransaction >= 0) {
      setPendingInventoryTransactions((oldInventoryTransactionList) => {
        oldInventoryTransactionList[indexOfInventoryTransaction].type =
          inventoryTransactionType;
        return [...oldInventoryTransactionList];
      });
    }
  }

  const inventoryTransactionCardsSection = pendingInventoryTransactions.map(
    (inventoryTransaction, idx) => {
      const inventoryObject = inventoryObjects.find(
        (inventoryObject) =>
          inventoryObject.id === inventoryTransaction.inventoryObjectID,
      );
      const currentLocation = inventoryLocations.find(
        (inventoryLocation) =>
          inventoryLocation.id === inventoryTransaction.inventoryLocationID,
      );
      return (
        <div key={idx} className="grid grid-cols-2 rounded-md border px-4 py-3">
          <div className="flex flex-col">
            <span className="text-greenPass">
              {inventoryObject?.title ?? "--"}
            </span>
            {currentLocation ? (
              <span className="text-sm text-cyan-500">
                Location: {currentLocation?.title}
              </span>
            ) : null}
          </div>
          <div className="justify-self-end">
            <div className="flex flex-row items-center justify-end space-x-4">
              <span>Qty: {inventoryTransaction.value}</span>
              <DropdownInventoryTransactionTypes
                initialInventoryTransactionType={inventoryTransaction.type}
                inventoryTransactionTypes={[
                  ...Object.values(OInventoryTransactionTypes),
                ]}
                onSelectionInventoryTransactionType={(
                  inventoryTransactionType,
                ) =>
                  handleTransactionType(
                    inventoryTransactionType,
                    inventoryTransaction,
                  )
                }
              />
            </div>
          </div>
        </div>
      );
    },
  );

  const inventoryTransactions = siteKeyDoc?.customizations?.inventoryEnabled ===
    true && (
    <Accordion elevation={0} expanded={inventorySectionIsExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className="transition-colors"
        onClick={() => {
          setInventorySectionIsExpanded(!inventorySectionIsExpanded);
        }}
      >
        <div className="flex w-full flex-row items-center justify-between">
          <CreateTaskSecondaryHeading text={strings.INVENTORY_TRANSACTION} />
          {pendingInventoryTransactions.length > 0 && (
            <span className="mx-4 pb-2 text-blue-600">
              {`${pendingInventoryTransactions.length} pending inventory transaction${pendingInventoryTransactions.length > 1 ? "s" : ""} from this estimate`}
            </span>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Fragment>{inventoryTransactionCardsSection}</Fragment>
      </AccordionDetails>
    </Accordion>
  );

  const membershipDiscounts = (customerDiscount.length !== 0 ||
    selectedLocationDiscount.length !== 0) && (
    <Fragment>
      <div className="flex flex-col   gap-4 sm:grid-cols-1 md:grid-cols-2">
        <div className="mb-4 mt-4 flex flex-col justify-between gap-4 sm:flex-row sm:items-start sm:gap-x-2">
          <div className="pr-2 text-2xl capitalize text-primary">
            {strings.MEMBERSHIPS}
          </div>
        </div>
        <div className="flex flex-row gap-4">
          {/* CUSTOMER */}
          {customerDiscount.length !== 0 ? (
            <div>
              <div className="pb-4 text-xl font-bold text-primary">
                {strings.CUSTOMER} Discounts
              </div>
              <div className="grid rounded-md border p-4">
                {customerDiscount.map((template, index) => (
                  <div
                    key={`customer.${index}`}
                    className="flex flex-col gap-4"
                  >
                    <BaseInputCheckbox
                      label={`${template.discount.toFixed(2)}% - ${
                        template.title
                      }`}
                      value={template.discount.toFixed(2)}
                      onChange={(event) => {
                        if (event.target.checked === true) {
                          setCustomerDiscount((oldCustomerDiscount) => {
                            oldCustomerDiscount[index].value = true;
                            return [...oldCustomerDiscount];
                          });
                        } else {
                          setCustomerDiscount((oldCustomerDiscount) => {
                            oldCustomerDiscount[index].value = false;
                            return [...oldCustomerDiscount];
                          });
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* SELECTED CUSTOMER LOCATION */}
          {selectedLocationDiscount.length !== 0 ? (
            <div>
              <div className="pb-4 text-xl font-bold text-primary">
                {strings.CUSTOMER_LOCATION} Discounts
              </div>
              <div className="grid   rounded-md border p-4">
                {selectedLocationDiscount.map((template, index) => (
                  <div
                    key={`location.${index}`}
                    className="flex flex-col gap-4"
                  >
                    <BaseInputCheckbox
                      label={`${template.discount.toFixed(2)}% - ${
                        template.title
                      }`}
                      value={template.discount.toFixed(2)}
                      onChange={(event) => {
                        if (event.target.checked === true) {
                          setSelectedLocationDiscount(
                            (oldSelectedLocationDiscount) => {
                              oldSelectedLocationDiscount[index].value = true;
                              return [...oldSelectedLocationDiscount];
                            },
                          );
                        } else {
                          setSelectedLocationDiscount(
                            (oldSelectedLocationDiscount) => {
                              oldSelectedLocationDiscount[index].value = false;
                              return [...oldSelectedLocationDiscount];
                            },
                          );
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <hr className="mb-8 mt-12 block w-full border border-gray-200" />
    </Fragment>
  );

  const actionButtons = (
    <BaseButtonPrimary
      type="button"
      onClick={handleSaveNewEstimate}
      className="w-full max-w-sm text-primary"
      isBusy={isBusy}
      busyText={strings.buttons.BUSY_SAVING}
    >
      {strings.buttons.SAVE_ESTIMATE}
    </BaseButtonPrimary>
  );

  const siteKeyLocationSection = userPermissions &&
    siteKeyLocations.length > 1 &&
    !taskDoc && (
      <Fragment>
        <div
          className={`mt-10 flex flex-col ${
            siteKeyLocations.length < 5
              ? "items-center sm:grid sm:grid-cols-3"
              : ""
          } `}
        >
          {userPermissions.defaultLocationID ? (
            <Fragment>
              <span className="pr-2 text-2xl capitalize text-primary">
                Default Site Location:
              </span>
              <span>
                {getSiteLocationTitle(
                  siteKeyLocations,
                  userPermissions.defaultLocationID,
                )}
              </span>
            </Fragment>
          ) : (
            <Fragment>
              {siteKeyLocations.length < 5 ? (
                <span className="pr-2 text-2xl capitalize text-primary">
                  Default Site Location:
                </span>
              ) : null}
              <SiteKeyLocationSection
                siteKeyLocationList={siteKeyLocations}
                selectedSiteKeyLocation={selectedSiteKeyLocationID}
                setSelectedSiteKeyLocation={setSelectedSiteKeyLocationID}
              />
            </Fragment>
          )}
        </div>
        <hr className="mb-8 mt-12 block w-full border border-gray-200" />
      </Fragment>
    );

  /* RENDER LOADING */
  if (
    customerLocationLoading ||
    typesenseLoading ||
    !siteKeyDoc ||
    membershipsIsLoading
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const createEstimateDetails = (
    <CreateEstimateDetails
      selectedCustomerLocation={selectedCustomerLocation}
      membershipDiscountTotal={totalSelectedDiscounts}
      totals={getEstimateTotals(
        estimateItemList,
        customerDoc,
        selectedCustomerLocation,
        (globalDiscount ?? 0) + (totalSelectedDiscounts ?? 0),
        siteKeyDoc?.customizations.accounting?.currency ?? "USD",
      )}
      commissions={
        siteKeyDoc?.customizations?.commissions
          ? EstimateManager.getJobCommissionsForDisplay(
              estimateItemList,
              estimateItemList,
              priceBookItemsWithCommissionOverrides,
              siteKeyDoc,
              customerDoc.type,
              globalDiscount ?? 0,
              taskDoc?.taskSpecificDetails?.allItemsUpsellCommissions === true,
              taskDoc?.taskSpecificDetails?.sameDayJob === true,
              [],
            )
          : null
      }
      removeAllItemsButton={removeAllItemsBtn}
      addNewEstimateItemLine={addNewEstimateItemLine}
      displaySelectedEstimateItems={displayTemporaryEstimateItems}
    >
      {{
        NotesField: notesField,
        InternalNotesField: internalNotesField,
        DiscountField: discountField,
        MembershipDiscounts: membershipDiscounts,
        InventoryTransactions: inventoryTransactions,
      }}
    </CreateEstimateDetails>
  );

  /* RENDER COMPONENT */
  return (
    <Fragment>
      <CreateEstimatePage
        customer={customerDoc}
        customerLocationOptions={customerLocationOptions}
        selectedCustomerLocation={selectedCustomerLocation}
        setSelectedCustomerLocation={setSelectedCustomerLocation}
        membershipTemplateList={membershipTemplateList}
        openEditCustomerDialog={openEditCustomerDialog}
        customerLocationMemberships={customerLocationMemberships}
      >
        {{
          ActionButtons: actionButtons,
          SiteKeyLocationSection: siteKeyLocationSection,
          CreateEstimateDetails: createEstimateDetails,
          EditCustomerDialog: editCustomerDialog,
        }}
      </CreateEstimatePage>
    </Fragment>
  );
}

function getInitialNotesFromTask(
  taskDoc: ExistingTask,
  workRecord: ExistingCraftRecord,
) {
  const wRDescription = workRecord.description
    ? `${workRecord.description}.`
    : "";
  const taskDescription = taskDoc.description ? `${taskDoc.description}.` : "";
  const scheduledTask = taskDoc.timestampScheduled
    ? `Scheduled for ${convertFSTimestampToLuxonDT(
        taskDoc.timestampScheduled,
      ).toLocaleString(DateTime.DATETIME_SHORT)}`
    : "";

  return `${wRDescription} ${taskDescription} ${scheduledTask}`;
}

export function getSiteLocationTitle(
  siteKeyLocationList: ExistingSiteKeyLocation[],
  defaultLocationID: string,
): string {
  const siteKeyLocation = siteKeyLocationList.find(
    (location) => location.id === defaultLocationID,
  );
  return siteKeyLocation?.title ?? "";
}
