import { TbChevronRight } from "react-icons/tb";

// Local
import { ReportSpec } from "../../models/reports";

interface Props {
  reportSpec: ReportSpec;
  reportSpecID: string;
  handleNavToReportSpecPage: (
    reportType: string,
    reportSpec: ReportSpec,
  ) => void;
}

export default function ReportsTemplateListItem(props: Props) {
  return (
    <article
      className="flex cursor-pointer items-start px-6 py-3 hover:bg-gray-100"
      onClick={() => {
        props.handleNavToReportSpecPage(props.reportSpecID, props.reportSpec);
      }}
    >
      <div className="mr-auto">
        {props.reportSpec.reportName}
        {props.reportSpec.description && (
          <p className="text-sm text-gray-400">
            {props.reportSpec.description}
          </p>
        )}
      </div>

      <TbChevronRight className="ml-2 shrink-0 stroke-gray-400 text-2xl" />
    </article>
  );
}
