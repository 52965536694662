//Libs
import { useParams } from "react-router-dom";
import { Fragment, useCallback, useEffect, useState } from "react";

//Local
import {
  getEstimateStatus,
  getEstimateTotals,
} from "../../assets/js/estimateFunctions";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import TaskDetailsInfoForEstimate from "../../components/estimates/TaskDetailsInfoForEstimate";
import ViewEstimatePage from "./ViewEstimatePage";
import {
  EstimateDataForNonAuthUser,
  EstimateStatus,
} from "../../models/estimate";
import { DbRead, DbWrite } from "../../database";
import NotFound404 from "../NotFound404";
import ButtonColored from "../../components/ButtonColored";
import * as strings from "../../strings";
import { logger as devLogger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import EstimateItemInline from "../../components/estimates/EstimateItemInline";

export default function NonAuthUserViewEstimateContainer() {
  // Extract estimate doc ID from the URL
  type UrlParams = { id: string };
  const data = useParams<UrlParams>();
  const id = data.id;
  if (typeof id !== "string") {
    throw new Error(`id was not a string: ${id}`);
  }

  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  // null is leveraged for the loading state. undefined for showing a 404.
  const [viewEstimateData, setViewEstimateData] = useState<
    EstimateDataForNonAuthUser | null | undefined
  >(null);
  const [updateStatus, setUpdateStatus] = useState<EstimateStatus | null>(null);

  const readableStatus = viewEstimateData
    ? getEstimateStatus(viewEstimateData.status)
    : "";

  /* FUNCTIONS */
  const getData = useCallback(async () => {
    const validNonAuthEstimateData = await DbRead.estimates.getEstimateData(id);
    setViewEstimateData(validNonAuthEstimateData);
  }, [id]);

  async function handleUpdateStatus(status: EstimateStatus) {
    if (!viewEstimateData) return;
    setUpdateStatus(status);
    try {
      await DbWrite.estimates.customerUpdate({
        siteKey: viewEstimateData.siteKey,
        estimateID: viewEstimateData.id,
        newStatus: status,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate("Status"),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      devLogger.error(`An error occurred during handleDeleteEstimate`, error);
      addMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    } finally {
      setUpdateStatus(null);
      getData();
    }
  }

  /* USE EFFECT */
  useEffect(() => {
    getData();
  }, [getData]);

  /* COMPONENTS */
  const displayEstimateItems =
    viewEstimateData &&
    viewEstimateData.estimateItemList.map((estimateItem) => {
      return (
        <div key={estimateItem.id}>
          <EstimateItemInline
            estimateItem={estimateItem}
            photos={viewEstimateData.photos.filter(
              (p) => p.estimateItemID === estimateItem.id,
            )}
            currency={viewEstimateData.currency}
            allowEdit={false}
          />
          <hr className="my-4 block w-full border border-gray-200" />
        </div>
      );
    });

  const taskInfo =
    viewEstimateData && viewEstimateData.taskDoc ? (
      <TaskDetailsInfoForEstimate
        taskDoc={viewEstimateData.taskDoc}
        displayName={""}
      />
    ) : null;

  const customerEstimateStatusButtons = (
    <Fragment>
      {/* APPROVE ESTIMATE */}
      <ButtonColored
        disabled={viewEstimateData?.invoiceExists}
        isBusy={updateStatus === EstimateStatus.APPROVED}
        busyText={strings.buttons.BUSY_UPDATING}
        className={`w-full ${
          viewEstimateData?.status === EstimateStatus.APPROVED
            ? "bg-green-50"
            : ""
        }`}
        kind="info"
        onClick={() => handleUpdateStatus(EstimateStatus.APPROVED)}
      >
        {strings.buttons.APPROVE_ESTIMATE}
      </ButtonColored>

      {/* REJECT ESTIMATE */}
      <ButtonColored
        disabled={viewEstimateData?.invoiceExists}
        isBusy={updateStatus === EstimateStatus.REJECTED}
        busyText={strings.buttons.BUSY_UPDATING}
        className={`w-full ${
          viewEstimateData?.status === EstimateStatus.REJECTED
            ? "bg-red-50"
            : ""
        }`}
        kind="danger"
        onClick={() => handleUpdateStatus(EstimateStatus.REJECTED)}
      >
        {strings.buttons.REJECT_ESTIMATE}
      </ButtonColored>
    </Fragment>
  );

  /* RENDER LOADING */
  // Initial value of payment is null.
  if (viewEstimateData === null) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  // Being explicit and using triple equals is important here.
  if (viewEstimateData === undefined) {
    return <NotFound404 />;
  }

  return (
    <section className="mx-auto w-full items-center px-2 pb-10 lg:max-w-5xl">
      <ViewEstimatePage
        merchantLogoURL={viewEstimateData.merchantLogoURL}
        customer={viewEstimateData.customer}
        customerLocation={viewEstimateData.customerLocation}
        estimateDoc={viewEstimateData}
        estimateStatus={readableStatus}
        openEditCustomerDialog={null}
        onEditCommissionAdjustment={null}
        totals={getEstimateTotals(
          viewEstimateData.estimateItemList,
          viewEstimateData.customer,
          viewEstimateData.customerLocation,
          viewEstimateData.discount ?? 0,
          viewEstimateData.currency,
        )}
        commissions={null}
      >
        {{
          EstimateInfo: null,
          CustomerEstimateStatusButton: customerEstimateStatusButtons,
          EstimateItemLines: displayEstimateItems,
          TaskInfoAndActionButtons: taskInfo,
        }}
      </ViewEstimatePage>
    </section>
  );
}
