// Libs
import { ICellRendererParams } from "ag-grid-community";
import { useCallback, useLayoutEffect, useState } from "react";

// Local
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  ExistingCustomer,
  ExistingCustomerWithLocations,
} from "../../models/customer";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import * as strings from "../../strings";
import { CustomerTable } from "./CreateTaskCustomerTablePage";
import { useTypesenseStore } from "../../store/typesense";
import { typesenseCustomersQuery } from "../../utils/typesenseQueries";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

export default function SelectCustomerTableContainer(props: {
  siteKey: string;
  customerIDToExclude?: string;
  setSelectedCustomer: React.Dispatch<
    React.SetStateAction<ExistingCustomer | null>
  >;
  onClose: () => void;
  addCustomerButton?: React.ReactNode;
}): JSX.Element {
  // const [membershipTemplateList, isLoadingMembershipTemplateList] =
  //   useMembershipTemplatesStore((state) => [
  //     state.membershipTemplates,
  //     state.loading
  //   ]);
  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);

  const [customersFromTypesense, setCustomersFromTypesense] = useState<
    ExistingCustomerWithLocations[]
  >([]);

  useLayoutEffect(() => {
    async function getCustomers() {
      if (!typesenseSearchKey) return;
      const customers = await typesenseCustomersQuery(typesenseSearchKey, "");
      setCustomersFromTypesense(
        customers.filter((c) => c.id !== props.customerIDToExclude),
      );
    }
    getCustomers();
  }, [typesenseSearchKey]);

  const onFilterTextBoxChanged = useCallback(async () => {
    if (!typesenseSearchKey) return;
    const searchTerm = (
      document.getElementById("search-customer") as HTMLInputElement
    ).value;
    const customers = await typesenseCustomersQuery(
      typesenseSearchKey,
      searchTerm,
    );
    setCustomersFromTypesense(
      customers.filter((c) => c.id !== props.customerIDToExclude),
    );
  }, [typesenseSearchKey]);

  function handleSelectCustomer(customerID: string) {
    const selectedCustomer = customersFromTypesense.find(
      (customer) => customer.id === customerID,
    );
    if (selectedCustomer) {
      props.setSelectedCustomer(selectedCustomer);
      props.onClose();
    }
  }

  function renderSelectCell(params: ICellRendererParams) {
    return (
      <div className="flex justify-center">
        <BaseButtonSecondary
          onClick={() => handleSelectCustomer(params.data.id)}
          className="mt-0.5 min-w-fit sm:w-36"
          data-testid={`select-customer-${params.data.id}`}
        >
          {strings.SELECT}
        </BaseButtonSecondary>
      </div>
    );
  }

  if (
    typesenseLoading
    // ||
    // isLoadingMembershipTemplateList
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <div className="ag-theme-alpine mt-4 flex h-screen flex-col p-4">
      <CustomerTable
        onSearch={onFilterTextBoxChanged}
        customerList={customersFromTypesense}
        renderSelectCell={renderSelectCell}
        addCustomerButton={props.addCustomerButton}
      />
    </div>
  );
}
