// Libs
import React, {
  useRef,
  Fragment,
  useLayoutEffect,
  useCallback,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  RowClickedEvent,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueGetterFunc,
} from "ag-grid-community";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

// Local
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { getReadableTaskStatus } from "../../models/task-status";
import { getReadableTaskType } from "../../models/task-types";
import { ExistingTask } from "../../models/task";
import TaskStatusCustomFilter from "./TaskStatusCustomFilter";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "../../assets/css/ag-grid-custom.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

// #region SECTION: Cell display related
const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

/**
 * These properties are added to every column. They can be overridden on a per
 * column basis.
 */
const commonColProps = {
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  tooltipValueGetter: (params: ITooltipParams) =>
    params.valueFormatted ?? params.value,
};

const readableTaskStatus = (params: ICellRendererParams): JSX.Element => {
  return (
    <div className="flex items-center">
      {getReadableTaskStatus(params.data.taskStatus)}
    </div>
  );
};

const readableTaskType: ValueGetterFunc = (params) => {
  return getReadableTaskType(params.data.taskType);
};

const getDateValue: ValueGetterFunc = (params) => {
  const timestampScheduled = params.data.timestampScheduled;
  if (timestampScheduled instanceof Timestamp) {
    return DateTime.fromSeconds(timestampScheduled.seconds).toJSDate();
  } else {
    return null;
  }
};
const getDateDisplay: ValueFormatterFunc = (params): string => {
  const timestampScheduled = params.data.timestampScheduled;
  const result = convertToReadableTimestamp(timestampScheduled);
  // Want to display empty string instead of Unknown Timestamp on this table.
  if (result.match(/unknown/gi)) {
    return "";
  } else {
    return result;
  }
};

const getPercentPassingDisplay: ValueFormatterFunc = (params) => {
  const numCompleted =
    params.data.taskSpecificDetails?.numberOfResponsesCompleted;
  const numPassing = params.data.taskSpecificDetails?.numberOfResponsesPassing;
  const percentage = Math.round((100 * numPassing) / numCompleted);
  if (isNaN(percentage)) {
    return "0 %";
  } else {
    return `${percentage} %`;
  }
};

const getPercentPassingValue: ValueGetterFunc = (params) => {
  const numCompleted =
    params.data.taskSpecificDetails?.numberOfResponsesCompleted;
  const numPassing = params.data.taskSpecificDetails?.numberOfResponsesPassing;
  const percentage = Math.round((100 * numPassing) / numCompleted);
  if (isNaN(percentage)) {
    return 0;
  } else {
    return percentage;
  }
};

const getPercentCompletedDisplay: ValueFormatterFunc = (params) => {
  const numCompleted =
    params.data.taskSpecificDetails?.numberOfResponsesCompleted;
  const numTotal = params.data.taskSpecificDetails?.totalNumberOfResponses;
  const percentage = Math.round((100 * numCompleted) / numTotal);
  if (isNaN(percentage)) {
    return "0 %";
  } else {
    return `${percentage} %`;
  }
};

const getPercentCompletedValue: ValueGetterFunc = (params) => {
  const numCompleted =
    params.data.taskSpecificDetails?.numberOfResponsesCompleted;
  const numTotal = params.data.taskSpecificDetails?.totalNumberOfResponses;
  const percentage = Math.round((100 * numCompleted) / numTotal);
  if (isNaN(percentage)) {
    return 0;
  } else {
    return percentage;
  }
};

// #endregion

interface Props {
  taskList: ExistingTask[];
  onGoToTaskDetails: (taskID: string) => void;
  showTaskTitles?: boolean;
}

export default function ChecklistTasksTable({
  taskList,
  onGoToTaskDetails,
  showTaskTitles = false,
}: Props): JSX.Element {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  const columnDefs: ColDef[] = [
    {
      field: "title",
      headerClass: headerStyles,
      cellClass: "font-semibold text-gray-700",
      hide: !showTaskTitles,
    },
    {
      field: "timestampScheduled",
      headerName: "DATE SCHEDULED",
      valueGetter: getDateValue,
      valueFormatter: getDateDisplay,
    },
    {
      headerName: "% Completed",
      valueGetter: getPercentCompletedValue,
      valueFormatter: getPercentCompletedDisplay,
    },
    {
      headerName: "% Passing",
      valueGetter: getPercentPassingValue,
      valueFormatter: getPercentPassingDisplay,
    },
    {
      field: "urgent",
      maxWidth: 120,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value === true ? "Yes" : "No";
      },
    },
    {
      field: "taskStatus",
      headerName: "Task Status",
      filter: TaskStatusCustomFilter,
      cellRenderer: readableTaskStatus,
      valueFormatter: (params: ValueFormatterParams) => params.value,
      tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
    },
    {
      field: "taskType",
      headerName: "Task Type",
      valueGetter: readableTaskType,
      hide: true,
    },
    {
      field: "locationID",
      headerName: "Location Title",
      hide: true,
    },
  ];

  // #region SECTION: Table
  return (
    <Fragment>
      <div className="ag-theme-alpine flex h-full min-h-[600px] flex-col">
        <AgGridReact
          reactiveCustomComponents
          ref={gridRef}
          onGridReady={onGridReady}
          suppressNoRowsOverlay={true}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowClass="cursor-pointer"
          rowData={taskList}
          animateRows={true}
          columnDefs={columnDefs}
          rowSelection="single"
          onRowClicked={(event: RowClickedEvent<ExistingTask, any>) => {
            if (event.data && typeof event.data.id === "string") {
              onGoToTaskDetails(event.data.id);
            }
          }}
        />
      </div>
    </Fragment>
  );
  // #endregion
}
