//Libs
import { useEffect, useState } from "react";
import { User } from "firebase/auth";

//Local
import { DbRead, DbWrite } from "../../database";
import { useAuthStore } from "../../store/firebase-auth";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";
import InventoryLocationsListPage from "./InventoryLocationsListPage";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import AddInventoryLocationDialog, {
  InventoryLocationState,
} from "../../components/inventory/AddInventoryLocationDialog";

interface Props {
  siteKey: string;
}

export default function InventoryTransactionListContainer({ siteKey }: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  /* STATES */
  const [addInventoryLocationDialogOpen, setAddInventoryLocationDialogOpen] =
    useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [inventoryLocations, setInventoryLocations] = useState<
    ExistingInventoryLocation[]
  >([]);

  // subscribe to inventoryLocations in a useEffect
  useEffect(() => {
    const unsubscribe = DbRead.inventoryLocations.subscribeAll({
      siteKey: siteKey,
      onChange: setInventoryLocations,
      onError: (error) => {
        throw error;
      },
    });
  }, [siteKey]);

  /* FUNCTIONS */
  async function handleSaveNewInventoryLocation(
    inventoryLocation: InventoryLocationState,
  ) {
    console.log("save inventory transaction");
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    //DB
    try {
      await DbWrite.inventoryLocations.create(siteKey, inventoryLocation);
    } catch (error) {
      logger.error(
        `An error occurred during handleSaveNewInventoryLocation`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
    setIsBusy(false);
  }

  const addInventoryLocationDialog = (
    <AddInventoryLocationDialog
      isDialogOpen={addInventoryLocationDialogOpen}
      closeDialog={() => setAddInventoryLocationDialogOpen(false)}
      handleSave={handleSaveNewInventoryLocation}
    />
  );

  return (
    <InventoryLocationsListPage
      inventoryLocationsList={inventoryLocations}
      onAddInventoryLocation={() => setAddInventoryLocationDialogOpen(true)}
    >
      {{
        AddInventoryLocation: addInventoryLocationDialog,
      }}
    </InventoryLocationsListPage>
  );
}
