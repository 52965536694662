// Local
import * as strings from "../../strings";

interface Props {
  children: {
    Stepper: React.ReactNode;
    Review: React.ReactNode;
    ActionButtons: React.ReactNode;
    CollapseExpandButtons: React.ReactNode;
  };
}

export default function SiteReviewPage(props: Props) {
  return (
    <div className="mx-auto w-full items-center px-2 pb-10 md:px-14 lg:max-w-screen-lg lg:gap-x-28 lg:px-2">
      <div className="flex flex-col">
        {props.children.Stepper}
        <div className="grid pt-8 xs:pt-12 sm:grid-cols-3">
          <h1 className="self-center text-5xl font-semibold text-primary sm:row-start-1">
            {strings.CREATE_SITE_REVIEW_HEADING}
          </h1>
          <p className="max-w-2xl py-6 text-sm font-medium text-gray-500 sm:col-span-2 sm:row-start-2 sm:mr-8 sm:pb-0 md:col-span-3 md:mr-0">
            {strings.CREATE_SITE_REVIEW_DESCRIPTION}
          </p>
          {props.children.ActionButtons}
        </div>
        {props.children.CollapseExpandButtons}
        {props.children.Review}
      </div>
    </div>
  );
}
