import React from "react";

type Props = {
  /** default `"p-1.5"` */
  buttonPadding?: string;
  /** provide an icon */
  children: React.ReactNode;
} & React.ComponentPropsWithRef<"button">;

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, buttonPadding, className, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className={`${className ?? ""} ${buttonPadding ?? "p-1.5"} rounded-md border border-gray-500 bg-white transition hover:border-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2`}
      >
        {children}
      </button>
    );
  },
);
