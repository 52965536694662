//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";

export interface ChatMessage {
  createdBy: string;
  timestampCreated: Timestamp;
  metadata: { [key: string]: any };
  remoteId: string;
  repliedMessage: string;
  roomId: string;
  showStatus: boolean;
  status: string;
  type: string;
  timestampLastModified: Timestamp;
  text: string;
}

export interface ExistingChatMessage extends ChatMessage {
  id: string;
  refPath: string;
}

export const ChatMessageManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingChatMessage object.
   */
  createFromFirestoreSnapshot: createChatMessageFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertChatMessageForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateChatMessage,
  /** Validate a ChatMessage doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateChatMessageWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingChatMessage object.
 */
function createChatMessageFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingChatMessage {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateChatMessageWithFallbacks(snapshotData),
  };
}

function convertChatMessageForFirestore(
  chatMessage: ChatMessage,
): DocumentData {
  const local = Object.assign({}, chatMessage);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ...rest } = local;
  return dropUndefined(rest);
}

/* Zod validation schemas */
function validateChatMessageWithFallbacks(value: unknown): ChatMessage {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = chatMessageSchemaWithFallbacks.parse(value);
  return result;
}

function validateChatMessage(value: unknown): ChatMessage {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = chatMessageSchema.parse(value);
  return result;
}

const chatMessageSchema = z.object({
  createdBy: z.string().max(100),
  timestampCreated: z.instanceof(Timestamp),
  metadata: z.record(z.any()),
  remoteId: z.string().max(100),
  repliedMessage: z.string().max(100),
  roomId: z.string().max(100),
  showStatus: z.boolean(),
  status: z.string().max(100),
  type: z.string().max(100),
  text: z.string().max(10000),
  timestampLastModified: z.instanceof(Timestamp),
});

const chatMessageSchemaWithFallbacks = chatMessageSchema.extend({
  createdBy: z.string().max(100),
  timestampCreated: z.instanceof(Timestamp),
  metadata: z.record(z.any()),
  remoteId: z.string().max(100),
  repliedMessage: z.string().max(100),
  roomId: z.string().max(100),
  showStatus: z.boolean(),
  status: z.string().max(100),
  type: z.string().max(100),
  text: z.string().max(10000),
  timestampLastModified: z.instanceof(Timestamp),
});
