// Local

import { convertFSTimestampToLuxonDT, convertISOToLuxonDT } from ".";

interface Args {
  /** Should be a Firestore Timestamp or ISO string */
  startDate: unknown;
  /**  Should be a Firestore Timestamp or ISO string */
  endDate: unknown;
}

/**
 * Given two Firestore Timestamps or two ISO strings, will calculate the difference
 * in days between them.
 *
 * @returns Number: difference in days
 *
 * Tested
 */
export function getTimeDifferenceInDays(args: Args): number {
  if (typeof args.startDate === "string" && typeof args.endDate === "string") {
    const start = convertISOToLuxonDT(args.startDate);
    const end = convertISOToLuxonDT(args.endDate);

    const daysObj = end.diff(start, "days").toObject();
    if (!daysObj.days) {
      throw new Error("unable to get difference in days");
    }

    const differenceDays = Math.floor(daysObj.days);

    if (differenceDays < 0) {
      return 0;
    } else {
      return differenceDays;
    }
  } else {
    const start = convertFSTimestampToLuxonDT(args.startDate);
    const end = convertFSTimestampToLuxonDT(args.endDate);

    const daysObj = end.diff(start, "days").toObject();
    if (!daysObj.days) {
      throw new Error("unable to get difference in days");
    }
    const differenceDays = Math.floor(daysObj.days);

    if (differenceDays < 0) {
      return 0;
    } else {
      return differenceDays;
    }
  }
}
