//Libs

//Local
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import placeholder_avatar from "../../images/placeholder_avatar.svg";
import { logger as devLogger } from "../../logging";

interface ResetUserPasswordResponse {
  result: string;
  isError: boolean;
}

export function useSecondaryActionsButtons(props: {
  // DATA
  user: ExistingSiteKeyUserDoc | undefined;
  currentUserId: string;
  siteKey: string;
  actionsLoading: boolean;
  downloadLink: string | null;
  isDownloadLinkError: boolean;
  dropdownIsOpen: boolean;
  QRDialog: JSX.Element;
  // FUNCTIONS
  handleResetPasswordByEmail: (email: string) => void;
  handleGenerateNewPassword: (
    uid: string,
    siteKey: string,
  ) => Promise<ResetUserPasswordResponse>;
  handleSendDownloadLinks: () => void;
  setOpenUserDialog: (value: boolean) => void;
  setDropdownIsOpen: (value: boolean) => void;
  handleAppDownloadClick: (platform: "ios" | "android") => void;
}) {
  function handleDropDown(value: boolean) {
    props.setDropdownIsOpen(value);
  }

  const ActionDropdownButton = (
    <div className="dropdown w-full">
      <BaseButtonSecondary
        type="button"
        onClick={() => handleDropDown(!props.dropdownIsOpen)}
        isBusy={props.actionsLoading}
        onBlur={() => handleDropDown(false)}
        className="w-52"
      >
        <div className="w-full text-left">Actions</div>
        <svg
          className="ml-2 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </BaseButtonSecondary>

      <div
        id="dropdown"
        className={`relative z-10 w-52 divide-y divide-gray-100 rounded-md bg-white shadow ${
          props.dropdownIsOpen ? "over" : "hidden"
        }`}
      >
        <ul className="absolute z-10 w-52 divide-y divide-gray-100 rounded-md bg-white text-sm shadow">
          {/* Generate New Password */}
          {props.user?.id != props.currentUserId && (
            <li>
              <a
                key="i"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  if (!props.user?.id) {
                    devLogger.error(
                      "Unable to generate new password: user ID not provided",
                    );
                  } else {
                    props.handleGenerateNewPassword(
                      props.user.id,
                      props.siteKey,
                    );
                  }
                  props.setDropdownIsOpen(false);
                }}
                className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
              >
                Generate New Password
              </a>
            </li>
          )}

          {/* Send Password Reset Email */}
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                if (props.user?.email === "" || props.user?.email == null) {
                  devLogger.error(
                    `The email value is not correct: ${props.user?.email}`,
                  );
                } else {
                  props.handleResetPasswordByEmail(props.user.email);
                }
                props.setDropdownIsOpen(false);
              }}
              className="block cursor-pointer px-4 py-4 text-sm hover:bg-gray-100"
            >
              Send Password Reset Email
            </a>
          </li>

          {/* Download iOS App */}
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                props.handleAppDownloadClick("ios");
                props.setDropdownIsOpen(false);
              }}
              className="block cursor-pointer px-4 py-4 text-sm hover:bg-gray-100"
            >
              Download iOS App
            </a>
          </li>

          {/* Download Android App */}
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                props.handleAppDownloadClick("android");
                props.setDropdownIsOpen(false);
              }}
              className="block cursor-pointer px-4 py-4 text-sm hover:bg-gray-100"
            >
              Download Android App
            </a>
          </li>

          {/* Send Download Links Email */}
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                props.handleSendDownloadLinks();
                props.setDropdownIsOpen(false);
              }}
              className="block cursor-pointer px-4 py-4 text-sm hover:bg-gray-100"
            >
              Send Download Links Email
            </a>
          </li>
        </ul>
      </div>
      {props.downloadLink && props.QRDialog}
    </div>
  );

  const ChangeUserButton: JSX.Element = (
    <div className="flex flex-col items-center">
      <button
        onClick={() => props.setOpenUserDialog(true)}
        className="relative"
        type="button"
        aria-label="Edit User Photo"
      >
        <img
          src={
            props.user?.userPhoto_URL
              ? props.user?.userPhoto_URL
              : placeholder_avatar
          }
          alt="User avatar"
          className="h-40 w-40 rounded-full object-cover hover:opacity-80"
        />
      </button>
    </div>
  );

  return {
    ChangeUserButton,
    ActionDropdownButton,
  };
}
