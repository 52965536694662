//Libs
import { Fragment } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { ExistingCustomField } from "../../models/custom-field";
import { getReadableTaskType } from "../../models/task-types";
//Local

interface Props {
  fieldTitle: string;
  field: ExistingCustomField[];
  craftRecord: boolean;
  taskType?: string;
}

export default function CustomFieldsSidebarMenuButton({
  fieldTitle,
  field,
  craftRecord,
  taskType,
}: Props) {
  const title = fieldTitle.split(/(?=[A-Z])/).join(" ");

  return (
    <Disclosure>
      {({ open }) => (
        <Fragment>
          <Disclosure.Button
            className={`flex w-full items-center justify-between pb-3 font-medium ${
              open ? "text-primary" : "text-gray-400 "
            }`}
          >
            <div className="text-left">
              {craftRecord ? (
                <span className="capitalize">{title}</span>
              ) : (
                <span className="ml-4">
                  {" "}
                  {getReadableTaskType(parseInt(taskType!))} Tasks
                </span>
              )}
            </div>
            <ChevronUpIcon
              aria-label={` ${
                open ? `Expand ${fieldTitle} menu` : `Close ${fieldTitle} menu`
              }`}
              className={`${open ? "rotate-180 transform" : ""} h-5 w-5`}
            />
          </Disclosure.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Disclosure.Panel className="flex flex-col px-4 pb-2 pt-4 text-sm font-medium text-gray-700 ">
              {Object.entries(field).map(([key, value], fieldIdx) => {
                return (
                  <Disclosure.Button
                    as="div"
                    className="border-l-2 border-primary py-3 pl-8 text-left "
                    key={fieldIdx}
                  >
                    <a
                      href={
                        value.craftRecordOrTask === "craftRecord"
                          ? `#${value.id}`
                          : `#${value.taskType}-${value.id}`
                      }
                      type="button"
                      className="text-left hover:font-semibold hover:text-primary"
                    >
                      {value.title}
                    </a>
                  </Disclosure.Button>
                );
              })}
            </Disclosure.Panel>
          </Transition>
        </Fragment>
      )}
    </Disclosure>
  );
}
