//Libs
import { Timestamp } from "firebase/firestore";

//Local
import { convertToReadableTimestamp } from "./convertToReadableTimestamp";
import { flattenObj } from "./flatten";
import { ExistingMembership } from "../../models/membership";
import { DbRead } from "../../database";

export async function formatMembershipDataForExcelExport(
  membershipList: ExistingMembership[],
  membershipTamplatesMap: Record<string, string>,
  userDisplayNamesMap: Record<string, string>,
  siteKey: string,
): Promise<Record<string, any>[]> {
  async function renderAddress(customerLocationID: string | null) {
    if (customerLocationID == null) {
      return "";
    } else {
      const customerLocation = await DbRead.customerLocations.getSingle(
        siteKey,
        customerLocationID,
      );
      return `${customerLocation.addressLine1} ${customerLocation.addressLine2}, ${customerLocation.city}, ${customerLocation.state} ${customerLocation.zipCode}`;
    }
  }

  const result: Record<string, any>[] = [];
  for (const membership of membershipList) {
    const address = await renderAddress(membership.customerLocationID);
    const data = {
      ...flattenObj(membership),
      createdBy: userDisplayNamesMap[membership.createdBy],
      membershipTemplate:
        membershipTamplatesMap[membership.membershipTemplateID],
      address: address,
      lastPaymentDate: membership.lastPaymentDate
        ? convertToReadableTimestamp(membership.lastPaymentDate)
        : "",
      lastCompletedTaskDate: membership.lastCompletedTaskDate
        ? convertToReadableTimestamp(membership.lastCompletedTaskDate)
        : "",
      timestampCreated: membership.timestampCreated
        ? convertToReadableTimestamp(membership.timestampCreated)
        : "",
      timestampLastModified: membership.timestampLastModified
        ? convertToReadableTimestamp(membership.timestampLastModified)
        : "",
      membershipEndDate: membership.membershipEndDate
        ? convertToReadableTimestamp(membership.membershipEndDate)
        : "",
      membershipStartDate: membership.membershipStartDate
        ? convertToReadableTimestamp(membership.membershipStartDate)
        : "",
      nextInvoiceDate: membership.nextInvoiceDate
        ? convertToReadableTimestamp(membership.nextInvoiceDate)
        : "",
      nextScheduledTaskDate: membership.nextScheduledTaskDate
        ? convertToReadableTimestamp(membership.nextScheduledTaskDate)
        : "",
    };
    result.push(data);
  }

  return result;
}
