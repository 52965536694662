//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { guardIsPlainObject } from "../utils";

export interface EstimatePackage {
  notes: string | null;
  customerID: string;

  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
}

export interface ExistingEstimatePackage extends EstimatePackage {
  id: string;
  refPath: string;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  EstimatePackage,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface EstimatePackage_CreateAPI extends withoutTimestamps {
  siteKey: string;
  /** the uuid will become the document id. */
  uuid: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type EstimatePackage_UpdateAPI = Omit<
  Partial<ExistingEstimatePackage>,
  "timestampCreated" | "timestampLastModified"
>;

// #region SECTION: Functions
/** Utilities for interacting with EstimatePackage objects  */
export const EstimatePackageManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingEstimatePackage object.
   */
  createFromFirestoreSnapshot: createEstimatePackageFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateEstimatePackage,
  /** Validate an EstimatePackage doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateEstimatePackageWithFallbacks,
  /** Validate a new estimate package. For the create endpoint. */
  parseCreate: validateEstimatePackage_Create,
  /** Validate an existing estimate package. For the update endpoint. */
  parseUpdate: validateEstimatePackage_Update,
};

/**
 * Convert the Document Snapshot into a validated ExistingEstimatePackage object.
 */
function createEstimatePackageFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingEstimatePackage {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateEstimatePackageWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateEstimatePackageWithFallbacks(value: unknown): EstimatePackage {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = estimatePackageSchemaWithFallbacks.parse(value);
  return result;
}

function validateEstimatePackage(value: unknown): EstimatePackage {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = estimatePackageSchema.parse(value);
  return result;
}

function validateEstimatePackage_Create(
  value: unknown,
): EstimatePackage_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return estimatePackageSchema_CreateAPI.parse(value);
}

function validateEstimatePackage_Update(
  value: unknown,
): EstimatePackage_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return estimatePackageSchema_UpdateAPI.parse(value);
}
// #endregion

// #region SECTION: Schemas
// Used when validating data coming from the database.
const estimatePackageSchemaWithFallbacks = z.object({
  notes: zFallback(
    z.string().max(1000).nullable(),
    null,
    "estimatePackageSchemaWithFallbacks: 'notes'",
  ),
  customerID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimatePackageSchemaWithFallbacks: 'customerID'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "estimatePackageSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "estimatePackageSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimatePackageSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimatePackageSchemaWithFallbacks: 'lastModifiedBy'",
  ),
});

// Used when writing to the DB or reading from the user.
const estimatePackageSchema = z.object({
  notes: z.string().max(1000).nullable(),
  customerID: z.string().min(1).max(200),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = estimatePackageSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const estimatePackageSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
  uuid: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const estimatePackageSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();
