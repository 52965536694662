// Libs
import create from "zustand";

// Local
import { DbRead } from "../database";
import { ExistingSiteKey } from "../models/site-key";
import { logger as devLogger } from "../logging";

// Define the property types.
interface RootUserSiteKeyListStore {
  rootUserSiteKeyList: ExistingSiteKey[];
  loading: boolean;
  fetch: () => void;
}

// Export a hook to access data globally and define initial values.
export const useRootUserSiteKeyListStore = create<RootUserSiteKeyListStore>(
  (set) => {
    return {
      rootUserSiteKeyList: [],
      loading: false,
      fetch: async () => {
        set({ loading: true });
        try {
          const list = await DbRead.siteKey.getRootUserListOfSiteKeyDocs();
          set({ rootUserSiteKeyList: list, loading: false });
        } catch (error) {
          set({ loading: false });
          devLogger.error("Error on fetch rootUserSiteKeyList store", error);
        }
      },
    };
  },
);
