import { TrashIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

interface Props {
  thumbnailPhotosWithDocID: Array<{ thumbnail: string; docID: string }>;
  onThumbnailClick: (clickedIndex: number) => void;
  onDeletePhoto: (docID: string) => Promise<void>;
}

export default function ResponseThumbnailGallery(props: Props) {
  return (
    <Fragment>
      {props.thumbnailPhotosWithDocID.map(({ thumbnail, docID }, i) => {
        return (
          <div key={thumbnail} className="relative">
            <img
              alt=""
              src={thumbnail}
              className="m-2 h-28 w-28 cursor-pointer rounded-md object-cover sm:h-32 sm:w-40"
              onClick={() => props.onThumbnailClick(i)}
            />
            <TrashIcon
              aria-label="delete photo"
              className="absolute right-3 top-3 h-7 w-7 rounded-full bg-gray-100/60 fill-gray-700 p-0.5 hover:bg-gray-100/90 sm:h-8 sm:w-8 sm:p-1"
              onClick={() => props.onDeletePhoto(docID)}
            />
            <TrashIcon
              aria-label="delete photo"
              className="absolute right-3 top-3 h-7 w-7 cursor-pointer rounded-full bg-gray-100/60 fill-gray-700 p-0.5 hover:bg-gray-100/90 sm:h-8 sm:w-8 sm:p-1"
              onClick={() => props.onDeletePhoto(docID)}
            />
          </div>
        );
      })}
    </Fragment>
  );
}
