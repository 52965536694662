import { logger as devLogger } from "../../logging";
import { ExistingChecklistResponse } from "../../models/checklist-response";
import getRHDIcons from "./getRHDIcons";
import truncateStringToChosenCharacterCount from "./truncateStringToChosenCharacterCount";

function makeInnerContent(
  responsePassing: ExistingChecklistResponse["responsePassing"],
  responseValue: ExistingChecklistResponse["responseValue"],
  units: ExistingChecklistResponse["units"],
): string {
  let innerContent: string;

  // Special case: does not matter what responseValue was.
  if (responsePassing === "error") return "";

  switch (typeof responseValue) {
    case "number":
      innerContent = `${responseValue} ${units}`;
      break;

    case "string":
      innerContent = `${truncateStringToChosenCharacterCount(
        responseValue,
        140,
      )} ${units}`;
      break;

    default:
      innerContent = "No Response";
      // If responseValue isn't string, number, or null, something has gone *very* wrong.
      if (responseValue !== null) {
        devLogger.warn(
          `Unexpected responseValue. Value: ${responseValue}. typeof value: ${typeof responseValue}`,
        );
      }
      break;
  }

  return innerContent;
}

export default function RHDCreateResponseFormat({
  responsePassing,
  responseValue,
  units,
}: ExistingChecklistResponse): JSX.Element {
  return (
    <>
      <span className="py-px text-sm sm:text-base">
        {makeInnerContent(responsePassing, responseValue, units)}
      </span>
      <span className="px-1.5">{getRHDIcons(responsePassing)}</span>
    </>
  );
}
