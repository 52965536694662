//Libs

//Local
import { ExistingSiteKey } from "../../models/site-key";

export enum TableTabTypes {
  HISTORY = "History",
  TASKS = "Tasks",
  JOBS = "Jobs",
  ADDRESSES = "Addresses",
  ESTIMATES = "Estimates",
  INVOICES = "Invoices",
  PAYMENTS = "Payments",
  CALLS = "Calls",
  PHOTOS_AND_ATTACHMENTS = "Files",
}

interface Props {
  siteKeyCustomizations: ExistingSiteKey["customizations"] | undefined;
  activeTableTab: TableTabTypes;
  setActiveTableTab: React.Dispatch<React.SetStateAction<TableTabTypes>>;
}

export default function TableTabs(props: Props) {
  return (
    <div className="grid max-w-fit grid-cols-2 gap-2 sm:grid-cols-3 md:flex md:flex-row md:rounded-xl md:bg-gray-100 lg:gap-1">
      <button
        onClick={() => props.setActiveTableTab(TableTabTypes.TASKS)}
        className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
          props.activeTableTab === TableTabTypes.TASKS
            ? "bg-primary text-primaryButtonText"
            : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
        }`}
      >
        {TableTabTypes.TASKS}
      </button>
      <button
        onClick={() => props.setActiveTableTab(TableTabTypes.JOBS)}
        className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
          props.activeTableTab === TableTabTypes.JOBS
            ? "bg-primary text-primaryButtonText"
            : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
        }`}
      >
        {TableTabTypes.JOBS}
      </button>
      <button
        onClick={() => props.setActiveTableTab(TableTabTypes.ESTIMATES)}
        className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
          props.activeTableTab === TableTabTypes.ESTIMATES
            ? "bg-primary text-primaryButtonText"
            : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
        }`}
      >
        {TableTabTypes.ESTIMATES}
      </button>
      <button
        onClick={() => props.setActiveTableTab(TableTabTypes.INVOICES)}
        className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
          props.activeTableTab === TableTabTypes.INVOICES
            ? "bg-primary text-primaryButtonText"
            : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
        }`}
      >
        {TableTabTypes.INVOICES}
      </button>
      {/* TODO: uncomment when we are ready to show table of payments */}
      {/*    <button
        onClick={() => props.setActiveTableTab(TableTabTypes.PAYMENTS)}
        className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${props.activeTableTab === TableTabTypes.PAYMENTS
          ? "bg-primary text-primaryButtonText"
          : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
          }`}
      >
        {TableTabTypes.PAYMENTS}
      </button> */}
      <button
        onClick={() => props.setActiveTableTab(TableTabTypes.ADDRESSES)}
        className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
          props.activeTableTab === TableTabTypes.ADDRESSES
            ? "bg-primary text-primaryButtonText"
            : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
        }`}
      >
        {TableTabTypes.ADDRESSES}
      </button>
      {props.siteKeyCustomizations?.voip &&
        Object.keys(props.siteKeyCustomizations.voip).length > 0 && (
          <button
            onClick={() => props.setActiveTableTab(TableTabTypes.CALLS)}
            className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
              props.activeTableTab === TableTabTypes.CALLS
                ? "bg-primary text-primaryButtonText"
                : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
            }`}
          >
            {TableTabTypes.CALLS}
          </button>
        )}
      <button
        onClick={() =>
          props.setActiveTableTab(TableTabTypes.PHOTOS_AND_ATTACHMENTS)
        }
        className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
          props.activeTableTab === TableTabTypes.PHOTOS_AND_ATTACHMENTS
            ? "bg-primary text-primaryButtonText"
            : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
        }`}
      >
        {TableTabTypes.PHOTOS_AND_ATTACHMENTS}
      </button>
      <button
        onClick={() => props.setActiveTableTab(TableTabTypes.HISTORY)}
        className={`relative block w-32 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
          props.activeTableTab === TableTabTypes.HISTORY
            ? "bg-primary text-primaryButtonText"
            : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
        }`}
      >
        {TableTabTypes.HISTORY}
      </button>
    </div>
  );
}
