//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { v4 as uuidv4 } from "uuid";

//Local
import { logger as devLogger } from "../../logging";
import * as strings from "../../strings";
import BaseModal from "../../components/BaseModal";
import { ErrorMessage } from "../../components/ErrorMessage";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { ExistingSiteKey } from "../../models/site-key";
import BaseInputSelect from "../../components/BaseInputSelect";
import { getReadableCraftType } from "../../models/craft-types";
import StyledMessage from "../../components/StyledMessage";
import { getReadableTaskType } from "../../models/task-types";
import { getReadableTaskStatus, OTaskStatus } from "../../models/task-status";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import BaseInputText from "../../components/BaseInputText";
import BaseInputNumber from "../../components/BaseInputNumber";
import { TemporaryTaskGeneration } from "./AddEditMembershipTemplateContainer";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  taskGenerationDoc: TemporaryTaskGeneration | null;
  siteKeyDoc: ExistingSiteKey;
  siteKeyLocationList: ExistingSiteKeyLocation[];
  /* FUNCTIONS */
  closeDialog: () => void;
  handleEditTask: (editTaskValues: TemporaryTaskGeneration) => void;
  handleAddNewTask: (newTaskValues: TemporaryTaskGeneration) => void;
}

export const TaskGenerationFormSchema = z.object({
  craftType: z.number(),
  taskType: z.number(),
  taskStatus: z.number(),
  description: z.string().min(1).max(2000),
  locationID: z.string().min(1).max(200),
  daysAfterRenewal: z.union([z.number(), z.literal("")]),
  // options: z.record(z.any()),
  // taskSpecificDetails: z.record(z.any()),
  // craftDetails: z.record(z.any()),
});
export type TaskGenerationFormState = z.infer<typeof TaskGenerationFormSchema>;

export default function AddEditTaskGenerationDialog({
  closeDialog,
  isDialogOpen,
  taskGenerationDoc,
  siteKeyDoc,
  siteKeyLocationList,
  handleEditTask,
  handleAddNewTask,
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const validCraftTypes = siteKeyDoc.validCraftTypes;
  const validTaskStatus = [OTaskStatus.AWAITING, OTaskStatus.AWAITING_SCHEDULE];
  const validTaskTypes = siteKeyDoc.validTaskTypes;

  const TaskGenerationDefaultValues: TaskGenerationFormState = useMemo(() => {
    if (taskGenerationDoc != null) {
      return {
        craftType: taskGenerationDoc.craftType,
        taskType: taskGenerationDoc.taskType,
        taskStatus: taskGenerationDoc.taskStatus,
        description: taskGenerationDoc.description,
        locationID: taskGenerationDoc.locationID,
        daysAfterRenewal:
          taskGenerationDoc.daysAfterRenewal == null
            ? ""
            : taskGenerationDoc.daysAfterRenewal,
      };
    } else {
      return {
        craftType: validCraftTypes[0],
        taskStatus: validTaskStatus[0],
        taskType: validTaskTypes[0],
        description: "",
        locationID: "",
        daysAfterRenewal: "",
      };
    }
  }, [taskGenerationDoc]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<TaskGenerationFormState>({
    defaultValues: TaskGenerationDefaultValues,
    resolver: zodResolver(TaskGenerationFormSchema),
    mode: "onChange",
  });

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    closeDialog();
  }

  useEffect(() => {
    reset(TaskGenerationDefaultValues);
  }, [TaskGenerationDefaultValues, reset]);

  const onSubmit: SubmitHandler<TaskGenerationFormState> = async (
    formValues,
  ) => {
    if (taskGenerationDoc != null) {
      /* case of editing */
      const partialTaskGenerationValue: TemporaryTaskGeneration = {
        ...taskGenerationDoc,
        craftType: formValues.craftType,
        taskType: formValues.taskType,
        taskStatus: formValues.taskStatus,
        description: formValues.description,
        locationID: formValues.locationID,
        daysAfterRenewal:
          formValues.daysAfterRenewal == ""
            ? null
            : formValues.daysAfterRenewal,
      };

      try {
        handleEditTask(partialTaskGenerationValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    } else {
      /* case of creating  */
      const newTaskGenerationValue: TemporaryTaskGeneration = {
        id: uuidv4(),
        craftType: formValues.craftType,
        taskType: formValues.taskType,
        taskStatus: formValues.taskStatus,
        description: formValues.description,
        locationID: formValues.locationID,
        daysAfterRenewal:
          formValues.daysAfterRenewal == ""
            ? null
            : formValues.daysAfterRenewal,
        options: {},
        taskSpecificDetails: {},
        craftDetails: {},
      };

      try {
        handleAddNewTask(newTaskGenerationValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    }
  };

  const taskGenerationHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {taskGenerationDoc ? strings.EDIT_PRICEBOOKITEM : strings.NEW_ITEM}
      </h1>
      <button type="button" onClick={() => closeAndReset()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = taskGenerationDoc ? (
    <ErrorMessage
      message={strings.ERROR_EDITING_PRICEBOOK_ITEM}
      clearMessage={() => setDisplayError(false)}
    />
  ) : (
    <ErrorMessage
      message={strings.ERROR_CREATING_PRICEBOOK_ITEM}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={isDialogOpen}
      title={taskGenerationHeader}
      parentDivStyles="inline-block transform overflow-hidden max-w-screen-xs md:max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-8 md:grid md:grid-cols-2"
        >
          {/* Field: Location ID */}
          <div>
            <Controller
              name="locationID"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="locationID"
                  text="Site Location"
                  admin={true}
                  required={true}
                  className="capitalize"
                  {...field}
                  value={field.value === null ? "" : field.value}
                  onChange={(event) =>
                    field.onChange(
                      event.target.value === "" ? null : event.target.value,
                    )
                  }
                >
                  {/* The empty string for value tricks validation into failing */}
                  <option value="">None</option>
                  {siteKeyLocationList.map((siteLocation) => {
                    return (
                      <option key={siteLocation.id} value={siteLocation.id}>
                        {siteLocation.title}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              )}
            />
            {errors.locationID?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.locationID.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Craft Type */}
          <div>
            <Controller
              name="craftType"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="craftType"
                  text="Craft Type"
                  admin={true}
                  required={true}
                  className="capitalize"
                  {...field}
                  value={field.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    field.onChange(numberValue);
                  }}
                >
                  {validCraftTypes.map((craftType) => {
                    return (
                      <option key={craftType} value={craftType}>
                        {getReadableCraftType(craftType)}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              )}
            />
            {errors.craftType?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.craftType.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Task Type */}
          <div>
            <Controller
              name="taskType"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="taskType"
                  text="Task Type"
                  admin={true}
                  required={true}
                  className="capitalize"
                  {...field}
                  value={field.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    field.onChange(numberValue);
                  }}
                >
                  {validTaskTypes.map((taskType) => {
                    return (
                      <option key={taskType} value={taskType}>
                        {getReadableTaskType(taskType)}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              )}
            />
            {errors.taskType?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.taskType.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Task Status */}
          <div>
            <Controller
              name="taskStatus"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="taskStatus"
                  text="Task Status"
                  admin={true}
                  required={true}
                  className="capitalize"
                  {...field}
                  value={field.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    field.onChange(numberValue);
                  }}
                >
                  {validTaskStatus.map((taskStatus) => {
                    return (
                      <option key={taskStatus} value={taskStatus}>
                        {getReadableTaskStatus(taskStatus)}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              )}
            />
            {errors.taskStatus?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.taskStatus.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Description */}
          <div>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Description"
                  inputName="description"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.description?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.description.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Days After Renewal */}
          <div>
            <Controller
              name="daysAfterRenewal"
              control={control}
              render={({ field }) => (
                <BaseInputNumber
                  text="Days After Renewal"
                  inputName="daysAfterRenewal"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value}
                  onChange={(event) => {
                    const numberValue = event.target.valueAsNumber;
                    if (displayError === true && numberValue > 0) {
                      setDisplayError(false);
                    }
                    field.onChange(isNaN(numberValue) ? "" : numberValue);
                  }}
                />
              )}
            />
            {errors.daysAfterRenewal?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.daysAfterRenewal.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="col-span-2 flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}
