//Libs
import React, { Fragment, useEffect, useState } from "react";
import { RangeValue } from "@react-types/shared";

import {
  CalendarDate,
  CalendarDateTime,
  getLocalTimeZone,
  toCalendarDate,
  today,
  ZonedDateTime,
} from "@internationalized/date";
import { useMutation, useQueries } from "react-query";
import { DocumentData } from "firebase/firestore";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";

//Local
import { DbRead, DbWrite } from "../../database";
import { logger } from "../../logging";
import {
  APIPaymentSavedCard,
  ExistingStiltPayment,
  StiltPaymentManager,
  StiltPayment_CreateAPI,
  paymentMethods,
} from "../../models/stilt-payment";
import RADateRangePicker from "../../components/DateRangePicker/RADateRangePicker";
import PaymentListPage from "./PaymentListPage";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  ExistingStiltInvoice,
  StiltInvoiceManager,
  StiltInvoice_UpdateAPI,
  TemplatePaymentTerm,
} from "../../models/invoice";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { useToastMessageStore } from "../../store/toast-messages";
import { convertFSTimestampToLuxonDT, createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import { diffObjects } from "../../assets/js/object-diff";
import { SchedulingButton } from "../Scheduling/SchedulingContainer";
import { NewManualPayment } from "../../components/Invoices/RecordManualPaymentDialog";
import { useAuthStore } from "../../store/firebase-auth";
import BaseInputSelect from "../../components/BaseInputSelect";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { ExistingCustomer } from "../../models/customer";
import { getEmailList } from "../../utils/getEmailList";

interface Props {
  siteKey: string;
}

export default function PaymentListContainer({ siteKey }: Props) {
  /* STORES */
  const [siteKeyDoc, siteKeyDocLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);

  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  /* USE STATES */
  const [paymentsList, setPaymentsList] = useState<ExistingStiltPayment[]>([]);
  const [isGetPayments, setIsGetPayments] = useState<boolean>(false);
  // State for InvoiceDateRangePicker, defaults to last 30 days
  const [range, setRange] = useState<RangeValue<CalendarDate>>({
    start: today(getLocalTimeZone()).subtract({ days: 30 }),
    end: today(getLocalTimeZone()),
  });

  const [isViewInvoiceDialogOpen, setIsViewInvoiceDialogOpen] = useState(false);
  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);
  const [stiltInvoiceID, setStiltInvoiceID] = useState<
    ExistingStiltInvoice["id"] | null
  >(null);
  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
    DateTime.now(),
  );

  /* QUERIES */
  const invoiceListQueryResult = useQueries(
    paymentsList.map((payment) => {
      return {
        queryKey: ["paymentListInvoiceDoc", siteKey, payment.invoiceID],
        queryFn: async () =>
          await DbRead.invoices.getSingle(siteKey, payment.invoiceID),
      };
    }),
  );

  const invoiceList = invoiceListQueryResult
    .map((invoiceDoc) => {
      if (invoiceDoc.data != null) {
        return invoiceDoc.data;
      } else return null;
    })
    .filter<ExistingStiltInvoice>(
      (invoice): invoice is ExistingStiltInvoice => invoice != null,
    );

  const selectedInvoice = invoiceList.find(
    (invoice) => invoice.id === stiltInvoiceID,
  );
  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);
  // const [
  //   isBusyGeneratingTransactionReport,
  //   setIsBusyGeneratingTransactionReport,
  // ] = useState(false);
  const [currentCustomer, setCurrentCustomer] =
    useState<ExistingCustomer | null>(null);

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  useEffect(() => {
    if (selectedInvoice) {
      if (selectedInvoice.dueDate != null) {
        setSelectedDueDate(
          convertFSTimestampToLuxonDT(selectedInvoice.dueDate),
        );
      }
      if (selectedInvoice.issueDate != null) {
        setSelectedIssueDate(
          convertFSTimestampToLuxonDT(selectedInvoice.issueDate),
        );
      }
    }
  }, [selectedInvoice]);

  /* MUTATIONS */
  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
  );

  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  /* USE EFFECT */

  // Fetch the list of payments when this component loads.
  useEffect(() => {
    async function getPayments() {
      logger.debug("getPayments useEffect called ");
      setIsGetPayments(true);
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribeAllPaymentsInDateRange =
        DbRead.payments.subscribeInDateRange({
          siteKey: siteKey,
          dateRange: range,
          onChange: setPaymentsList,
          onError: (error) =>
            logger.error(`Error in getPayments: ${error.message}`),
        });
      setIsGetPayments(false);
      return unsubscribeAllPaymentsInDateRange;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getPayments();
    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(PaymentListContainer) getPayments useEffect return ƒn just invoked.",
      );
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          logger.debug("Now unsubscribing from payments.");
          unsubscribe();
        }
      });
      logger.debug("Done running cleanup ƒn for getPayments useEffect.");
    };
  }, [siteKey, range]);

  // Retrieve customer from selected invoice. For paying with a saved card.
  useEffect(() => {
    async function getCustomer() {
      if (!selectedInvoice) return;

      const customer = await DbRead.customers.get(
        siteKey,
        selectedInvoice.customerID,
      );
      setCurrentCustomer(customer);
    }
    getCustomer();
  }, [selectedInvoice, siteKey]);

  /* FUNCTIONS */

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  // This function is called when the date range changes.
  // Converts the date range to CalendarDate objects and sets the range state.
  // The React-Aria DateRangePicker component requires its "onChange"
  // to use "RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>"
  // objects (not just "RangeValue<CalendarDate>" objects) and doesn't work
  // without this conversion.
  function handleDateRangeChange(
    value: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>,
  ) {
    logger.debug(
      `Invoked handleDateRangeChange for range: ${value.start.month}-${value.start.day} to ${value.end.month}-${value.end.day}`,
    );
    try {
      const convertedStart = toCalendarDate(value.start);
      const convertedEnd = toCalendarDate(value.end);
      const converted = { start: convertedStart, end: convertedEnd };
      setRange(converted);
      logger.debug(
        `set range to ${converted.start.month}-${converted.start.day} to ${converted.end.month}-${converted.end.day}`,
      );
    } catch (e) {
      // TODO: Handle this error better
      if (e instanceof Error) {
        logger.error("Error in handleDateRangeChange", e);
      }
    }
  }

  function openViewInvoiceDialog(
    invoiceID: ExistingStiltPayment["invoiceID"],
  ): void {
    if (!siteKeyDoc) return;

    setViewInvoiceDialogProps({
      siteKeyID: siteKey,
      invoiceID,
      merchantName: siteKeyDoc.name,
      merchantLogoURL:
        siteKeyDoc.customizations.accounting?.merchantLogoURL ?? null,
    });
    setIsViewInvoiceDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  function closeInvoiceDialog(): void {
    setIsViewInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  function onClickHandlePaymentButton(invoiceID: ExistingStiltInvoice["id"]) {
    setIsHandlePaymentDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    // try/catch is in the InvoiceSummary component; don't want it here
    return DbWrite.payments.issueRefund(siteKey, paymentID, refundAmount);
  }

  async function handleEditInvoiceDialogOpen() {
    if (selectedInvoice) {
      setEditInvoiceDialogOpen(true);
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!stiltInvoiceID) return;
    if (!selectedInvoice) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_INVOICE,
        dialog: false,
        type: "error",
      });
      return;
    }
    if (email.length === 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "email",
    );
    if (!paymentLink) {
      logger.error("emailInvoice: missing invoice link");
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_GEN_INVOICE_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
      setStiltInvoiceID(null);
    } catch (e) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_INVOICE,
        dialog: false,
        type: "error",
      });
      logger.error("error emailing customer invoice", e);
      setStiltInvoiceID(null);
    }
  }

  async function emailReceipt(emailList: string[]): Promise<void> {
    if (!selectedInvoice) return;
    if (emailList.length === 0) {
      throw Error("emailReceipt given empty email list");
    }
    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: siteKey,
        invoiceID: selectedInvoice.id,
        customerEmailList: emailList,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      // logger.error("emailReceipt failure", e);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT,
        dialog: false,
        type: "error",
      });
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!selectedInvoice) return;
    if (selectedInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await emailReceipt(emails);
    }
  }

  /**
   * Build an xlsx spreadsheet.
   */
  // async function saveSpreadSheet(data: any) {
  //   const fileName = "Transaction Report";

  //   // Split the input string into lines
  //   const lines = data.split("\n");

  //   // The first line contains the headers, split it into an array
  //   const headers = lines[0].split("|");

  //   // Create a new workbook and add a worksheet
  //   const workbook = new Workbook();
  //   const worksheet = workbook.addWorksheet("Sheet1");

  //   // Add the headers to the first row
  //   worksheet.addRow(headers);

  //   // Iterate over each subsequent line
  //   for (let i = 1; i < lines.length; i++) {
  //     // Skip empty lines
  //     if (lines[i].trim() === "") {
  //       continue;
  //     }

  //     // Split the line into values and add it as a row
  //     const values = lines[i].split("|");
  //     worksheet.addRow(values);
  //   }

  //   // // Insert the user's report name as the title row (first row)
  //   // const customTitle = `${
  //   //   userDisplayNamesMap[firebaseUser?.uid ?? ""]
  //   // } [${fileName}]`;
  //   // sheet.insertRow(1, [customTitle]);
  //   //
  //   // // updated the font for first row.
  //   // sheet.getRow(1).font = { bold: true };
  //   // sheet.getRow(2).font = { bold: true };

  //   try {
  //     // write the content using writeBuffer
  //     const buf = await workbook.xlsx.writeBuffer();

  //     // download the processed file
  //     saveAs(new Blob([buf]), `Transaction Report.xlsx`);
  //     // setIsBusy(false);
  //   } catch (error) {
  //     console.error("Something Went Wrong", error);
  //   } finally {
  //     // removing worksheet's instance to create new one
  //     workbook.removeWorksheet(fileName);
  //   }
  // }

  // async function handleGenerateTransactionReport(): Promise<void> {
  //   setIsBusyGeneratingTransactionReport(true);
  //   try {
  //     const data = await DbWrite.payments.generateTransactionReport({
  //       siteKeyID: siteKey,
  //     });
  //     console.log(data.data);
  //     await saveSpreadSheet(data.data);
  //     setIsBusyGeneratingTransactionReport(false);
  //   } catch (e) {
  //     logger.error("handleGenerateTransactionReport", e);
  //     setIsBusyGeneratingTransactionReport(false);
  //   }
  // }

  async function handleDeleteInvoice(
    siteKeyID: string,
    invoiceID: string,
  ): Promise<void> {
    return DbWrite.invoices.delete(siteKeyID, invoiceID);
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (!stiltInvoiceID) return;
    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "web",
    );
    if (paymentLink != null) {
      goToPaymentPage(paymentLink);
    } else {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
    }
  }

  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!selectedInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      selectedInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: selectedInvoice.id,
      refPath: selectedInvoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    logger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      logger.debug("Payment terms has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Payment terms for Invoice #${selectedInvoice.invoiceNumber}`,
        ),
        dialog: true,
        type: "success",
      });
      setIsViewInvoiceDialogOpen(false);
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewPaymentTerms`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: true,
        type: "error",
      });
    }

    setStiltInvoiceID(null);
  }

  async function applyManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: selectedDateTime.toISO(),
      customerID: selectedInvoice.customerID,
      invoiceID: stiltInvoiceID,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: siteKey,
      billToCustomerID: selectedInvoice.billToCustomerID,
      locationID: selectedInvoice.locationID,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    logger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      if (
        validPaymentData.amount === selectedInvoice.amountDue &&
        selectedInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (selectedInvoice.email !== null) {
          addMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: true,
            type: "success",
          });
        } else {
          addMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: true,
            type: "info",
          });
        }
      } else {
        addMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: true,
          type: "success",
        });
      }
    } catch (err) {
      logger.error("handleRecordManualPayment", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKey,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  /* COMPONENTS */

  const paymentDateRangePicker = (
    <RADateRangePicker value={range} onChange={handleDateRangeChange} />
  );

  // const transactionReportDownload = (
  //   <div className="flex gap-4">
  //     <StyledTooltip title={`Export to Excel`}>
  //       <button
  //         onClick={handleGenerateTransactionReport}
  //         className="rounded-md bg-gray-200 p-2"
  //       >
  //         {isBusyGeneratingTransactionReport && <LoadingSpinner></LoadingSpinner>}
  //         {!isBusyGeneratingTransactionReport && (
  //           <FileDownloadIcon fontSize="medium" className="shrink-0" />
  //         )}
  //       </button>
  //     </StyledTooltip>
  //   </div>
  // );

  const dueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedIssueDate
              ? selectedIssueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedIssueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = selectedInvoice && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={selectedInvoice}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  const datePicker = selectedInvoice && (
    <DatePicker
      selected={selectedDateTime.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setSelectedDateTime(luxonDate);
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    siteKeyDoc &&
    userPermissions &&
    selectedInvoice &&
    currentCustomer && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        closeDialog={() => setIsHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={currentCustomer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, currentCustomer)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  const viewInvoiceDialog = isViewInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        handlePayment={onClickHandlePaymentButton}
        isOpen={isViewInvoiceDialogOpen}
        onClose={closeInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        editInvoice={handleEditInvoiceDialogOpen}
        sendEmail={emailInvoice}
        deleteInvoice={handleDeleteInvoice}
        userDisplayNamesMap={userDisplayNamesMap}
        handleDeletePayment={handleDeletePayment}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  // Display loading clipboard while customer list loads.
  if (siteKeyDocLoading || userDisplayNamesMapIsLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <Fragment>
      <PaymentListPage
        dateRange={range}
        paymentList={paymentsList}
        isGetPayments={isGetPayments}
        invoiceList={invoiceList}
        currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
        openInvoiceDialog={openViewInvoiceDialog}
        userDisplayNamesMap={userDisplayNamesMap}
      >
        {{
          PaymentDateRangePicker: paymentDateRangePicker,
          // TransactionReportDownload: transactionReportDownload,
        }}
      </PaymentListPage>
      {viewInvoiceDialog}
    </Fragment>
  );
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}
