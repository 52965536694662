// Libs
import { DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

// Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

// #region SECTION: Schemas / Interfaces / Types
export interface SiteKeyUserPermissions {
  approved: boolean;
  inactive?: boolean;
  companyID: string | null;
  defaultLocationID: string | null;
  customData: { [key: string]: any };
  managementSubscriptions: {
    newTaskCreated: boolean;
    allTaskStatusChanged: boolean;
    taskDeleted: boolean;
    craftRecordDeleted: boolean;
    scaffolds: boolean;
  };
  permissions: {
    getsNewTaskNotifications: boolean;
    canEditContractorDetails: boolean;
    canCreateTasks: boolean;
    canUpdateTasks: boolean;
    canDeleteTasks: boolean;
    canCreateCraftRecords: boolean;
    canUpdateCraftRecords: boolean;
    canDeleteCraftRecords: boolean;
    isPlantPersonnel: boolean;
    isSiteAdmin: boolean;
    // Compliance permissions
    complianceRequirements_create: boolean;
    complianceRequirements_read: boolean;
    complianceRequirements_delete: boolean;

    complianceResponses_create: boolean;
    complianceResponses_read: boolean;
    /** Allowed to read responses across companies, for example plant personnel & site admins. */
    complianceResponses_readAll: boolean;
    complianceResponses_delete: boolean;

    complianceResponses_review: boolean;
    tasks_changeDate?: boolean;
  };
}

export interface ExistingSiteKeyUserPermissions extends SiteKeyUserPermissions {
  id: string;
  refPath: string;
}

/** For reading from the DB */
const SchemaSiteKeyUserPermissionsWithFallbacks = z.object({
  approved: zFallback(
    z.boolean(),
    false,
    "SchemaSiteKeyUserPermissionsWithFallbacks: 'approved'",
  ),
  inactive: zFallback(
    z.boolean().optional(),
    false,
    "SchemaSiteKeyUserPermissionsWithFallbacks: 'inactive'",
  ),
  companyID: zFallback(
    z.string().nullable(),
    null,
    "SchemaSiteKeyUserPermissionsWithFallbacks: 'companyID'",
  ),
  defaultLocationID: zFallback(
    z.string().min(1).max(2000).nullable(),
    null,
    "SiteKeyUserSchemaWithFallbacks: 'defaultLocationID'",
  ),
  customData: zFallback(
    z.record(z.any()),
    {},
    "SiteKeyUserSchemaWithFallbacks: 'customData'",
  ),
  managementSubscriptions: z.object({
    newTaskCreated: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'managementSubscriptions.newTaskCreated'",
    ),
    allTaskStatusChanged: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'managementSubscriptions.allTaskStatusChanged'",
    ),
    taskDeleted: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'managementSubscriptions.taskDeleted'",
    ),
    craftRecordDeleted: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'managementSubscriptions.craftRecordDeleted'",
    ),
    scaffolds: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'managementSubscriptions.scaffolds'",
    ),
  }),
  permissions: z.object({
    getsNewTaskNotifications: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.getsNewTaskNotifications'",
    ),
    canEditContractorDetails: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.canEditContractorDetails'",
    ),
    canCreateTasks: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.canCreateTasks'",
    ),
    canUpdateTasks: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.canUpdateTasks'",
    ),
    canDeleteTasks: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.canDeleteTasks'",
    ),
    canCreateCraftRecords: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.canCreateCraftRecords'",
    ),
    canUpdateCraftRecords: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.canUpdateCraftRecords'",
    ),
    canDeleteCraftRecords: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.canDeleteCraftRecords'",
    ),
    isPlantPersonnel: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.isPlantPersonnel'",
    ),
    isSiteAdmin: zFallback(
      z.boolean(),
      false,
      "SchemaSiteKeyUserPermissionsWithFallbacks: 'permissions.isSiteAdmin'",
    ),
    complianceRequirements_create: zFallback(
      z.boolean(),
      false,
      "SiteKeyUserPermissions: 'permissions.complianceRequirements_create'",
    ),
    complianceRequirements_read: zFallback(
      z.boolean(),
      false,
      "SiteKeyUserPermissions: 'permissions.complianceRequirements_read'",
    ),
    complianceRequirements_delete: zFallback(
      z.boolean(),
      false,
      "SiteKeyUserPermissions: 'permissions.complianceRequirements_delete'",
    ),
    complianceResponses_create: zFallback(
      z.boolean(),
      false,
      "SiteKeyUserPermissions: 'permissions.complianceResponses_create'",
    ),
    complianceResponses_read: zFallback(
      z.boolean(),
      false,
      "SiteKeyUserPermissions: 'permissions.complianceResponses_read'",
    ),
    complianceResponses_readAll: zFallback(
      z.boolean(),
      false,
      "SiteKeyUserPermissions: 'permissions.complianceResponses_readAll'",
    ),
    complianceResponses_delete: zFallback(
      z.boolean(),
      false,
      "SiteKeyUserPermissions: 'permissions.complianceResponses_delete'",
    ),
    complianceResponses_review: zFallback(
      z.boolean(),
      false,
      "SiteKeyUserPermissions: 'permissions.complianceResponses_review'",
    ),
    tasks_changeDate: zFallback(
      z.boolean().optional(),
      false,
      "SiteKeyUserPermissions: 'permissions.tasks_changeDate'",
    ),
  }),
});

/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 * */
const SchemaSiteKeyUserPermissions = z.object({
  approved: z.boolean(),
  inactive: z.boolean().optional(),
  companyID: z.string().nullable(),
  defaultLocationID: z.string().min(1).max(200).nullable(),
  customData: z.record(z.any()),
  managementSubscriptions: z.object({
    newTaskCreated: z.boolean(),
    allTaskStatusChanged: z.boolean(),
    taskDeleted: z.boolean(),
    craftRecordDeleted: z.boolean(),
    scaffolds: z.boolean(),
  }),
  permissions: z.object({
    getsNewTaskNotifications: z.boolean(),
    canEditContractorDetails: z.boolean(),
    canCreateTasks: z.boolean(),
    canUpdateTasks: z.boolean(),
    canDeleteTasks: z.boolean(),
    canCreateCraftRecords: z.boolean(),
    canUpdateCraftRecords: z.boolean(),
    canDeleteCraftRecords: z.boolean(),
    isPlantPersonnel: z.boolean(),
    isSiteAdmin: z.boolean(),
    complianceRequirements_create: z.boolean(),
    complianceRequirements_read: z.boolean(),
    complianceRequirements_delete: z.boolean(),
    complianceResponses_create: z.boolean(),
    complianceResponses_read: z.boolean(),
    complianceResponses_readAll: z.boolean(),
    complianceResponses_delete: z.boolean(),
    complianceResponses_review: z.boolean(),
    tasks_changeDate: z.boolean().optional(),
  }),
});

const EditSiteKeyPermissionsSchema = SchemaSiteKeyUserPermissions.deepPartial();
export type EditSiteKeyPermissionsState = z.infer<
  typeof EditSiteKeyPermissionsSchema
>;

// I wanted to see how the inferred type looks.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type inferredType = z.infer<typeof SchemaSiteKeyUserPermissionsWithFallbacks>;
// #endregion

// #region SECTION: Functions
/**
 * Utilities for interacting with SiteKeyUserPermissions objects.
 */
export const SiteKeyUserPermissionsManager = {
  /** Validate a SiteKeyUserPermissions doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateSiteKeyUserPermissionsWithFallbacks,
  /** Validate a SiteKeyUserPermissions doc. Use for writing to the database or reading from the user. */
  parse: validateSiteKeyUserPermissions,
  /**
   * Convert the Document Snapshot into a validated ExistingSiteKeyUserPermissions object.
   * Fallback values will be used if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  fromFirestore: createFromSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  toFirestore: convertForFirestore,
  /** Convert nested keys to human readable strings. Use for managementSubscriptions
   * and permissions */
  getReadableKeys: convertToReadableKeys,
};

/**
 * Convert the Document Snapshot into a validated ExistingSiteKeyUserPermissions object.
 * Fallback values will be used if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingSiteKeyUserPermissions {
  if (snapshot.exists() !== true) {
    throw new NotFoundError(
      `Document snapshot does not exist, cannot convert. refPath: ${snapshot.ref.path}`,
    );
  }
  const data = validateSiteKeyUserPermissionsWithFallbacks(snapshot.data());
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...data,
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertForFirestore(
  value: ExistingSiteKeyUserPermissions,
): SiteKeyUserPermissions {
  const { id, refPath, ...dataParts } = value;
  const result = dropUndefined(dataParts);
  return result;
}

/** Rejects if it doesn't pass validation. */
function validateSiteKeyUserPermissions(
  permissionsDoc: unknown,
): SiteKeyUserPermissions {
  if (!guardIsPlainObject(permissionsDoc)) {
    throw new Error(
      `SiteKeyUserPermissions is not an object: ${permissionsDoc}`,
    );
  }

  return SchemaSiteKeyUserPermissions.parse(permissionsDoc);
}

/** For validating stuff coming in from the database. */
function validateSiteKeyUserPermissionsWithFallbacks(
  permissionsDoc: unknown,
): SiteKeyUserPermissions {
  if (!guardIsPlainObject(permissionsDoc)) {
    throw new Error(
      `SiteKeyUserPermissions is not an object: ${permissionsDoc}`,
    );
  }

  return SchemaSiteKeyUserPermissionsWithFallbacks.parse(permissionsDoc);
}

export function validateEditSiteKeyUserPermissions(
  value: unknown,
): EditSiteKeyPermissionsState {
  if (!guardIsPlainObject(value)) {
    throw new Error(`EditSiteKeyPermissions is not an object: ${value}`);
  }
  const result = EditSiteKeyPermissionsSchema.parse(value);
  return result;
}
// #endregion

function convertToReadableKeys(value: string): string {
  const readableStr: string | undefined = readableNestedKeysMap[value];
  // Unsure whether it's better to return unknown, or to just return the input
  // if (!readableStr) return "UNKNOWN";
  if (!readableStr) return value;
  return readableStr;
}

const readableNestedKeysMap: Record<string, string> = {
  managementSubscriptions: "Management Notifications",
  newTaskCreated: "New Task Created",
  allTaskStatusChanged: "All Task Status Changed",
  taskDeleted: "Task Deleted",
  craftRecordDeleted: "Craft Record Deleted",
  scaffolds: "Scaffolds",

  permissions: "Permissions",
  getsNewTaskNotifications: "Gets New Task Notifications",
  canEditContractorDetails: "Can Edit Contractor Details",
  canCreateTasks: "Can Create Tasks",
  canUpdateTasks: "Can Update Tasks",
  canDeleteTasks: "Can Delete Tasks",
  canCreateCraftRecords: "Can Create Craft Records",
  canUpdateCraftRecords: "Can Update Craft Records",
  canDeleteCraftRecords: "Can Delete Craft Records",
  isPlantPersonnel: "Is Plant Personnel",
  isSiteAdmin: "Is Site Admin",
  complianceRequirements_create: "Create Compliance Requirements",
  complianceRequirements_read: "Read Compliance Requirements",
  complianceRequirements_delete: "Delete Compliance Requirements",
  complianceResponses_create: "Create Compliance Responses",
  complianceResponses_read: "Read Compliance Responses",
  complianceResponses_readAll: "Read All Compliance Responses",
  complianceResponses_delete: "Delete Compliance Responses",
  complianceResponses_review: "Review Compliance Responses",
  tasks_changeDate: "Change Task Dates",
};
