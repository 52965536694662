//Libs
import { Dispatch, Fragment, useState, useRef } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { z } from "zod";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ExistingStiltInvoice } from "../models/invoice";
import { convertToReadableTimestamp } from "../assets/js/convertToReadableTimestamp";

//Local

interface Props {
  emailList: string[];
  selectedEmailList: string[];
  setSelectedEmailList: Dispatch<React.SetStateAction<string[]>>;
  setErrorString: Dispatch<React.SetStateAction<string | null>>;
  invoiceSentToCustomer: ExistingStiltInvoice["timestampSentToCustomer"];
  children: {
    sendEmailButton: React.ReactNode;
    includeJobPhotosSwitch?: React.ReactNode;
  };
}

export default function MultipleEmailSelector2({
  emailList,
  selectedEmailList,
  setSelectedEmailList,
  setErrorString,
  invoiceSentToCustomer,
  children,
}: Props) {
  const [query, setQuery] = useState("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const filteredEmailList =
    query === ""
      ? emailList
      : emailList.filter((email) =>
          email
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  function handleUnselectEmail(emailToBeDeleted: string) {
    const filteredList = selectedEmailList.filter(
      (selectedEmail) => selectedEmail !== emailToBeDeleted,
    );
    setSelectedEmailList(filteredList);
  }

  function isValid(email: string): boolean {
    if (!isEmail(email)) {
      setErrorString(`${email} is not a valid email address.`);
      return false;
    }
    if (isInList(email)) {
      setErrorString(`${email} has already been added.`);
      return false;
    }
    return true;
  }

  function isEmail(email: string): boolean {
    const safeParseResult = z.string().email().safeParse(email);

    return safeParseResult.success;
  }

  function isInList(email: string) {
    return selectedEmailList.includes(email);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();

      const emailArray = (event.target as HTMLInputElement).value.split(",");
      if (emailArray) {
        const selectedEmailListCopy = [...selectedEmailList];

        for (const email of emailArray) {
          const value = email.trim();
          if (value === "") continue;
          if (isValid(value)) selectedEmailListCopy.push(value);
        }
        setSelectedEmailList(selectedEmailListCopy);
        setQuery("");
      }
    }
  }

  return (
    <div className="flex w-full flex-col gap-5">
      {children.includeJobPhotosSwitch && (
        <span className="-mb-2 self-end">
          {children.includeJobPhotosSwitch}
        </span>
      )}

      {/* DISPLAY SELECTED EMAILS */}
      {selectedEmailList.length !== 0 && (
        <div className="-mb-2 flex flex-wrap">
          {selectedEmailList.map((email) => {
            return (
              <div
                key={email}
                className="m-1 flex w-fit items-center justify-between gap-1 rounded-md bg-primary px-2.5 py-0.5 text-sm font-medium text-primaryButtonText"
              >
                {email}
                <button
                  className="py-0.5 pl-1"
                  onClick={() => handleUnselectEmail(email)}
                >
                  x
                </button>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex flex-col">
        <Combobox
          value={selectedEmailList}
          onChange={(value) => setSelectedEmailList(value)}
          multiple
        >
          <div className="relative mt-1">
            <div className="relative">
              <Combobox.Input
                className={`mt-2 block w-full min-w-52 max-w-lg flex-1 rounded border border-black p-4 text-gray-700 outline-none focus:border-primaryLight focus:ring focus:ring-primaryLight sm:text-sm`}
                onChange={(event) => setQuery(event.target.value)}
                onKeyDown={(event) => {
                  handleKeyDown(event);
                }}
                value={query}
                onClick={() => setQuery("")}
                autoComplete="off"
                id="emailSelector"
              />
              <label
                htmlFor="emailSelector"
                className="absolute -top-3 left-3 bg-white px-2"
              >
                Add Email Address
              </label>
              <Combobox.Button
                className="absolute inset-y-0 right-0 flex items-center pr-2"
                ref={buttonRef}
              >
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              // afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute isolate z-[20] mt-1 max-h-60 w-full min-w-52 max-w-lg overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filteredEmailList.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredEmailList.map((email) => (
                    <Combobox.Option
                      key={email}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-gray-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={email}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {email}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-gray-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        {invoiceSentToCustomer && (
          <div className="mt-1 self-end px-0 text-sm italic">{`Last Sent to Customer: ${convertToReadableTimestamp(invoiceSentToCustomer)}`}</div>
        )}
      </div>

      {children.sendEmailButton}
    </div>
  );
}
