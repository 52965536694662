//Libs
import create from "zustand";

//Local

export type ToastType = {
  id: string;
  message: string;
  dialog: boolean;
  type: "success" | "warning" | "error" | "info";
  action?: () => void;
  actionText?: string;
};

//Define the property types
interface ToastMessagesStore {
  messagesList: ToastType[];
  addToastMessage: (newMessage: ToastType) => void;
  deleteToastMessage: (messageID: string) => void;
}

export const useToastMessageStore = create<ToastMessagesStore>((set) => {
  return {
    messagesList: [],
    addToastMessage: (newMessage: ToastType) => {
      if (newMessage.type === "success") {
        set((state) => ({ messagesList: [...state.messagesList, newMessage] }));
        setTimeout(() => {
          set((state) => {
            const arrayCopy = [...state.messagesList];
            const itemIndex = arrayCopy.findIndex(
              (toast) => toast.id === newMessage.id,
            );
            arrayCopy.splice(itemIndex, 1);
            state.messagesList = arrayCopy;
          });
        }, 5000);
      } else {
        set((state) => ({ messagesList: [...state.messagesList, newMessage] }));
      }
    },
    deleteToastMessage: (messageID: string) => {
      set((state) => {
        const arrayCopy = [...state.messagesList];
        const itemIndex = arrayCopy.findIndex(
          (toast) => toast.id === messageID,
        );
        arrayCopy.splice(itemIndex, 1);
        state.messagesList = arrayCopy;
      });
    },
  };
});
