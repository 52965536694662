//Libs

//Local
import { ComplianceCompany } from "../../models/compliance-types-and-statuses";
import styles from "../../assets/css/ComplianceSidebar.module.css";

interface Props {
  //DATA
  selectedCompany: ComplianceCompany | null;
  isCompanyUser: boolean;
  showMobileSidebar: boolean;
  //CHILDREN
  children: {
    Sidebar: React.ReactNode;
    CompanyDashboard: React.ReactNode;
    MainDashboard: React.ReactNode;
  };
}

export default function CompliancePage(props: Props) {
  return (
    <div
      className={`px-4 sm:px-8 md:grid md:grid-cols-5 md:items-start md:justify-center md:gap-8 md:pl-0 ${
        !props.showMobileSidebar && "min-h-full"
      }`}
    >
      {/* Screens larger than small ipad */}
      <div className="relative hidden pb-16 md:col-span-2 md:block">
        {props.children.Sidebar}
        <div className={styles.complianceSidebarShadow}></div>
      </div>
      {props.selectedCompany == null && props.isCompanyUser === false
        ? props.children.MainDashboard
        : props.children.CompanyDashboard}
    </div>
  );
}
