//Libs

//Local
import { ExistingSiteKeyUserDoc } from "../models/site-key-users";

export enum Tabs {
  ALL = "All",
  APPROVED = "Approved",
  NEEDS_APPROVAL = "Needs Approval",
  INACTIVE = "Inactive",
}

interface Props {
  activeTab: Tabs;
  handleFilterSelection: (filterSelection: Tabs) => void;
  filterMap: Record<Tabs, ExistingSiteKeyUserDoc[]>;
}

export default function FilterTabs(props: Props) {
  return (
    <div className="">
      <div className="grid grid-cols-1 gap-2 rounded-xl sm:grid-cols-2 md:flex md:flex-row md:bg-gray-100 lg:gap-1">
        <button
          onClick={() => props.handleFilterSelection(Tabs.ALL)}
          className={`relative block w-40 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
            props.activeTab === Tabs.ALL
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
          }`}
        >
          {Tabs.ALL}
          <div
            className={`absolute -right-4 -top-4 z-10 rounded-full bg-amber-600 bg-primary text-center ${
              props.filterMap["All"].length <= 9 ? "px-3 py-1" : "px-2 py-1"
            }`}
          >
            <span className="text-sm font-bold text-white">
              {props.filterMap["All"].length}
            </span>
          </div>
        </button>
        <button
          onClick={() => props.handleFilterSelection(Tabs.APPROVED)}
          className={`relative block w-40 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
            props.activeTab === Tabs.APPROVED
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
          }`}
        >
          {Tabs.APPROVED}
          <div
            className={`absolute -right-4 -top-4 z-10 rounded-full bg-amber-600 bg-primary text-center ${
              props.filterMap["Approved"].length <= 9
                ? "px-3 py-1"
                : "px-2 py-1"
            }`}
          >
            <span className="text-sm font-bold text-white">
              {props.filterMap["Approved"].length}
            </span>
          </div>
        </button>
        <button
          onClick={() => props.handleFilterSelection(Tabs.INACTIVE)}
          className={`relative block w-40 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
            props.activeTab === Tabs.INACTIVE
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
          }`}
        >
          {Tabs.INACTIVE}
          <div
            className={`absolute -right-4 -top-4 z-10 rounded-full bg-amber-600 bg-primary text-center ${
              props.filterMap["Inactive"].length <= 9
                ? "px-3 py-1"
                : "px-2 py-1"
            } `}
          >
            <span className="text-sm font-bold text-white">
              {props.filterMap["Inactive"].length}
            </span>
          </div>
        </button>
        <button
          onClick={() => props.handleFilterSelection(Tabs.NEEDS_APPROVAL)}
          className={`relative block w-40 rounded p-3 text-base font-medium ring-white/60 ring-offset-2 ring-offset-primaryLight focus:outline-none focus:ring-2 ${
            props.activeTab === Tabs.NEEDS_APPROVAL
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-700 hover:bg-white/[0.12] hover:text-primary"
          }`}
        >
          {Tabs.NEEDS_APPROVAL}
          <div
            className={`absolute -right-4 -top-4 z-10 rounded-full bg-amber-600 bg-primary text-center ${
              props.filterMap["Needs Approval"].length <= 9
                ? "px-3 py-1"
                : "px-2 py-1"
            }`}
          >
            <span className="text-sm font-bold text-white">
              {props.filterMap["Needs Approval"].length}
            </span>
          </div>
        </button>
      </div>
    </div>
  );
}
