import { MultipleUidCustomField } from "../../models/custom-field";
import { Json } from "../../models/json-type";
import { IMultipleUid_AssignUser } from "./MultipleUidDialog";

/**
 * @returns the userList that was passed in. Flips a user's isAssigned value to
 * true if that user is in the originalValue list.
 */
export function getSelectedUsers(
  userList: IMultipleUid_AssignUser[],
  originalValue: MultipleUidCustomField["defaultValue"] | Json,
): IMultipleUid_AssignUser[] {
  if (Array.isArray(originalValue)) {
    return userList.map((user) => {
      if (originalValue.includes(user.uid)) {
        user.isAssigned = true;
      }
      return user;
    });
  } else {
    return userList;
  }
}
