// Libs
import React, { RefObject, useCallback, useLayoutEffect } from "react";
import { useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  ITooltipParams,
  RowDragEvent,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import isEqual from "lodash/isEqual";
import AddBoxIcon from "@mui/icons-material/AddBox";

// Local
import { ExistingChecklistItem } from "../../models/checklist-item";
import { ShowRightStringForPassingMinMaxUnits } from "../../assets/js/ShowRightStringForPassingMinMaxUnits";
import ChipTag from "../ChipTag";
import ButtonColored from "../ButtonColored";
import { StyledTooltip } from "../../components/StyledTooltip";
import { PencilIconWithRef } from "../PencilEditButton";
import { HistoryIconWithRef } from "../HistoryIconButton";
import { TrashIconWithSpinner } from "../ButtonDeleteTrash";
import { DuplicateItemButton } from "../DuplicateItemButton";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "../../assets/css/ag-grid-custom.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

// #region SECTION: Cell display related
const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

/**
 * These properties are added to every column. They can be overridden on a per
 * column basis.
 */
const commonColProps = {
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  tooltipValueGetter: (params: ITooltipParams) =>
    params.valueFormatted ?? params.value,
};

function renderTagsCell(params: ICellRendererParams): JSX.Element | null {
  const tagsList: ExistingChecklistItem["tags"] = params.value;
  if (Array.isArray(tagsList)) {
    return (
      <div className="-ml-2 flex h-10 items-center">
        {tagsList.map((tag) => {
          if (typeof tag === "string") {
            const key = tag + params.node.data.id;
            return <ChipTag tag={tag} key={key} />;
          } else {
            return null;
          }
        })}
      </div>
    );
  } else {
    return null;
  }
}
// #endregion Cell display related

interface TableProps {
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
  itemList: Props["itemList"];
  handleRowDragEvent: (event: RowDragEvent) => void;
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  userCanUpdate: Props["userCanUpdate"];
}

const TheTable = React.memo(
  ({
    gridRef,
    itemList,
    handleRowDragEvent,
    renderIconCell,
    userCanUpdate,
    onGridReady,
  }: TableProps) => {
    const columnDefs: ColDef[] = [
      {
        field: "mainText",
        headerName: "Item",
        cellClass: "font-semibold text-gray-700",
        rowDrag: userCanUpdate,
        initialWidth: 400,
      },
      {
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        suppressAutoSize: true,
        suppressSizeToFit: true,
        sortable: false,
        maxWidth: 160,
      },
      {
        field: "note",
        headerName: "Notes",
      },
      {
        field: "tags",
        cellRenderer: renderTagsCell,
      },
      {
        field: "required",
        maxWidth: 150,
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value === true ? "Yes" : "No";
        },
      },
      {
        headerName: "Unit Range",
        valueGetter: (params: ValueGetterParams) => {
          return ShowRightStringForPassingMinMaxUnits({
            passingMin: params.data.passingMin,
            passingMax: params.data.passingMax,
            units: params.data.units,
          });
        },
      },
      {
        headerName: "Available responses",
        field: "selectionOptions",
      },
      {
        headerName: "Passing responses",
        field: "passingOptions",
      },
    ];

    return (
      <div className={`ag-theme-alpine flex h-full min-h-[600px] flex-col`}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={itemList}
          rowDragManaged={true}
          onRowDragEnd={handleRowDragEvent}
          onRowDragLeave={handleRowDragEvent}
          animateRows={true}
          rowSelection="single"
          columnDefs={columnDefs}
        />
      </div>
    );
  },
  (previous, next) => {
    const isItemListTheSame = isEqual(previous.itemList, next.itemList);
    const isGridRefTheSame = previous.gridRef === next.gridRef;
    const isHandleRowDragEventTheSame =
      previous.handleRowDragEvent === next.handleRowDragEvent;
    const isRenderIconCellTheSame =
      previous.renderIconCell === next.renderIconCell;

    // console.log("THE TABLE", { isItemListTheSame, isGridRefTheSame, isHandleRowDragEventTheSame, isRenderIconCellTheSame, });

    return (
      isItemListTheSame &&
      isGridRefTheSame &&
      isHandleRowDragEventTheSame &&
      isRenderIconCellTheSame
    );
  },
);

interface Props {
  itemList: ExistingChecklistItem[];
  onUpdateItemOrder?: (itemList: string[]) => Promise<void>;
  onDeleteItem: (itemID: ExistingChecklistItem["id"]) => Promise<void>;
  onHandleEditItem: (itemID: ExistingChecklistItem["id"]) => void;
  onDuplicateItem: (itemID: ExistingChecklistItem["id"]) => void;
  onViewHistory: (item: ExistingChecklistItem) => void;
  toggleAddItemModal: () => void;
  handleRowDragEvent: (event: RowDragEvent) => void;
  toggleSaveItemOrderButton: (adjustClass: "hide" | "show") => void;
  saveItemOrderDivRef: RefObject<HTMLDivElement>;
  userCanUpdate: boolean;
}

const ChecklistItemTable = React.memo(
  function ({
    itemList,
    onUpdateItemOrder,
    onDeleteItem,
    onHandleEditItem,
    onViewHistory,
    onDuplicateItem,
    toggleAddItemModal,
    handleRowDragEvent,
    toggleSaveItemOrderButton,
    saveItemOrderDivRef,
    userCanUpdate,
  }: Props): JSX.Element {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    const [isUpdatingItemOrder, setIsUpdatingItemOrder] = useState(false);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    async function handleUpdateItemOrder(
      newItemList: ExistingChecklistItem[],
    ): Promise<void> {
      if (onUpdateItemOrder != null) {
        setIsUpdatingItemOrder(true);

        const newItemOrder = newItemList.map((item) => item.id);

        await onUpdateItemOrder(newItemOrder);

        setIsUpdatingItemOrder(false);
        toggleSaveItemOrderButton("hide");
      }
    }

    const renderIconCell = useCallback(
      function (params: ICellRendererParams) {
        return (
          <div className="-ml-3 -mr-3 flex items-center">
            <StyledTooltip title="Edit" enterDelay={1200}>
              <PencilIconWithRef
                onClick={() => onHandleEditItem(params.data.id)}
              />
            </StyledTooltip>

            <StyledTooltip title="Copy" enterDelay={1200}>
              <DuplicateItemButton
                onClick={() => onDuplicateItem(params.data.id)}
              />
            </StyledTooltip>

            <StyledTooltip title="Delete" enterDelay={1200}>
              <TrashIconWithSpinner
                onDelete={async () => await onDeleteItem(params.data.id)}
              />
            </StyledTooltip>

            <StyledTooltip title="View History" enterDelay={1200}>
              <HistoryIconWithRef onClick={() => onViewHistory(params.data)} />
            </StyledTooltip>
          </div>
        );
      },
      [onDeleteItem, onHandleEditItem, onViewHistory],
    );

    // SECTION:
    return (
      <div className="flex h-full flex-col">
        {/* ACTION BUTTONS */}
        <div
          className={`ml-1 mt-8 flex flex-row-reverse items-center justify-between sm:mt-4 ${
            userCanUpdate ? "" : "hidden"
          }`}
        >
          <button
            type="button"
            onClick={toggleAddItemModal}
            className="text-primary"
          >
            <AddBoxIcon aria-label="add item" sx={{ fontSize: 45 }} />
          </button>
          {/* SAVE ITEM ORDER BUTTON */}
          <div ref={saveItemOrderDivRef} className="hidden">
            <ButtonColored
              kind="warning"
              isBusy={isUpdatingItemOrder}
              onClick={() => {
                const newListOrder: ExistingChecklistItem[] = [];
                gridRef.current.api.forEachNode((row: RowNode) =>
                  newListOrder.push(row.data),
                );
                handleUpdateItemOrder(newListOrder);
              }}
            >
              <ExclamationCircleIcon
                aria-label="save item order"
                className="mr-4 h-5"
              />
              Save Item Order
            </ButtonColored>
          </div>
        </div>
        <TheTable
          gridRef={gridRef}
          onGridReady={onGridReady}
          handleRowDragEvent={handleRowDragEvent}
          itemList={itemList}
          renderIconCell={renderIconCell}
          userCanUpdate={userCanUpdate}
        />
      </div>
    );
  },
  (previous, next) => {
    const isItemListTheSame = isEqual(previous.itemList, next.itemList);
    const isOnDeleteItemTheSame = previous.onDeleteItem === next.onDeleteItem;
    const isOnHandleEditItemTheSame =
      previous.onHandleEditItem === next.onHandleEditItem;
    const isOnUpdateItemOrderTheSame =
      previous.onUpdateItemOrder === next.onUpdateItemOrder;
    const isOnViewHistoryTheSame =
      previous.onViewHistory === next.onViewHistory;
    const isToggleAddItemModalTheSame =
      previous.toggleAddItemModal === next.toggleAddItemModal;

    // console.log("CHECKLIST ITEM TABLE", { isItemListTheSame, isOnDeleteItemTheSame, isOnHandleEditItemTheSame, isOnUpdateItemOrderTheSame, isOnViewHistoryTheSame, isToggleAddItemModalTheSame, });

    return (
      isItemListTheSame &&
      isOnDeleteItemTheSame &&
      isOnHandleEditItemTheSame &&
      isOnUpdateItemOrderTheSame &&
      isOnViewHistoryTheSame &&
      isToggleAddItemModalTheSame
    );
  },
);
export default ChecklistItemTable;
