import isPlainObject from "lodash/isPlainObject";

export function flattenObj(original: Record<string, any>): Record<string, any> {
  const output: Record<string, any> = {};

  Object.entries(original).forEach(([key, value]) => {
    if (isPlainObject(value)) {
      // BY THE POWER OF **RECURSION** I HAVE THE POWER
      const tempFlatObj = flattenObj(value);
      Object.keys(tempFlatObj).forEach((nestedKey) => {
        const dottedKey = `${key}.${nestedKey}`;
        output[dottedKey] = tempFlatObj[nestedKey];
      });
    } else {
      output[key] = value;
    }
  });

  return output;
}
