// Libs
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import { Control, Controller, FieldValues } from "react-hook-form";

// Local
import { StringArrayCustomField } from "../../models/custom-field";
import { Json } from "../../models/json-type";
import * as strings from "../../strings";
import StyledMessage from "../StyledMessage";

interface Props {
  customField: StringArrayCustomField & { id: string };
  control: Control<FieldValues, any>;
  defaultValue: Json | Date;
  isRequired: boolean;
}

export default function FieldTypeStringArray(props: Props) {
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (
      // non-empty array
      Array.isArray(props.defaultValue) &&
      props.defaultValue.length > 0
    ) {
      if (props.defaultValue.every((val) => typeof val === "string")) {
        setOptions(props.defaultValue as string[]);
      } else {
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  }, [props.defaultValue]);

  const [selectedOption, setSelectedOption] = useState(
    options.length === 1 ? options[0] : strings.SELECT_AN_OPTION,
  );

  return (
    <Controller
      defaultValue={props.defaultValue}
      name={props.customField.id}
      control={props.control}
      rules={{ required: props.isRequired }}
      render={({ field, fieldState }) => {
        return (
          <div key={props.customField.id + props.customField.title}>
            <label
              htmlFor={`selection-dropdown-for-${props.customField.title}`}
              className="inline-block"
            >
              {props.customField.title}{" "}
              {props.isRequired && (
                <span className="text-lg font-medium text-redFail">*</span>
              )}
            </label>
            <Listbox
              key={props.customField.id}
              {...field}
              onChange={(event) => {
                setSelectedOption(event);
                field.onChange(event);
              }}
            >
              <div className="relative mt-1">
                <Listbox.Button className="relative h-10 w-full cursor-pointer rounded-md border border-gray-400 bg-white py-2 pl-3 text-left text-gray-800 outline-none transition-colors hover:border-gray-800 focus:ring-1 focus:ring-primaryLight">
                  <span className="block truncate">{selectedOption}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-700"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full rounded-md bg-white text-base ring-1 ring-black/5 focus:outline-none">
                    {options.map((item) => {
                      return (
                        <Listbox.Option
                          key={item}
                          className={({ active, selected }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active || selected
                                ? "bg-primaryOpacity90 text-primary"
                                : "text-gray-700"
                            }`
                          }
                          value={item}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {item}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            {fieldState.error && fieldState.error.type === "required" && (
              <div className="mt-2">
                <StyledMessage type="error">
                  {{ message: strings.REQUIRED }}
                </StyledMessage>
              </div>
            )}
          </div>
        );
      }}
    />
  );
}
