// Tested ✅
/** Will throw if given negative value */
export function convertDecimalToHoursMinutes(decimalValue: number): {
  hours: number;
  minutes: number;
} {
  if (decimalValue < 0) {
    throw new Error(
      `${convertDecimalToHoursMinutes.name}: decimal cannot be negative. Received: ${decimalValue}`,
    );
  }
  const hours = Math.floor(decimalValue);

  const fractionalPart = decimalValue - hours;
  const minutes = Math.round(fractionalPart * 60);

  return { hours, minutes };
}
