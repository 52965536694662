//Libs
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";
import AudioPlayer from "react-h5-audio-player";

//Local
import { ExistingStiltPhoneCall } from "../../models/calls";
import Link from "../../components/StiltLink";
import * as urls from "../../urls";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { phoneUtils } from "../../utils/phoneUtils";
import { StyledTooltip } from "../StyledTooltip";
import voicemail from "../../images/voicemail.svg";

//Styles
import "react-h5-audio-player/lib/styles.css";
import "../../assets/css/AudioPlayer.css";

/* Stuff for Table Component */
const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface Props {
  customerCalls: ExistingStiltPhoneCall[];
}

export default function CustomerCallListTablePage({ customerCalls }: Props) {
  const gridRef = useRef<AgGridReact<ExistingStiltPhoneCall>>(null);
  const [gridReady, setGridReady] = useState(false);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current!.api.sizeColumnsToFit();
    }
  }, [gridReady]);

  const handleGridReady = useCallback(
    () => (_event: GridReadyEvent) => {
      setGridReady(true);
    },
    [],
  );

  // See [Auto Size Strategy](https://ag-grid.com/react-data-grid/column-sizing/#auto-size-columns-to-fit-grid)
  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => {
    return {
      type: "fitGridWidth",
      defaultMinWidth: 100,
      columnLimits: [
        {
          colId: "ID",
          minWidth: 150,
        },
      ],
    };
  }, []);

  const renderAudioRecording = (params: ICellRendererParams): JSX.Element => {
    const url = params.data.recordingURL;

    if (!url) {
      return <div></div>;
    }

    return (
      <div className="mx-0">
        <AudioPlayer
          style={{
            padding: "2px",
            background: "transparent",
            boxShadow: "none",
          }}
          src={url}
          showJumpControls={false}
          layout={"horizontal-reverse"}
          customVolumeControls={[]}
          customAdditionalControls={[]}
        />
      </div>
    );
  };

  function customerRenderer(
    params: CustomCellRendererProps<ExistingStiltPhoneCall>,
  ): JSX.Element {
    const id = params.data?.id;
    const name = params.data?.customerSnapshot?.name ?? "Unknown";
    return (
      <Link
        to={`${urls.CUSTOMERS_URL}/${id}`}
        className="text-blue-500 underline"
      >
        {name}
      </Link>
    );
  }

  const renderTimestamp = (params: ICellRendererParams): JSX.Element => {
    return (
      <div>
        {params.data.timestampCreated != null
          ? convertToReadableTimestamp(params.data.timestampCreated)
          : ""}
      </div>
    );
  };

  const renderFrom = (params: ICellRendererParams): JSX.Element => {
    const from = params.data.from ?? "--";
    return <div>{phoneUtils.display(from)}</div>;
  };
  const renderTo = (params: ICellRendererParams): JSX.Element => {
    const to = params.data.to ?? "--";
    return <div>{phoneUtils.display(to)}</div>;
  };

  const renderCallDirection = (params: ICellRendererParams): JSX.Element => {
    let direction = params.data.direction;
    if (
      params.data.direction === "outbound-api" ||
      params.data.direction === "outbound"
    ) {
      direction = "Outbound";
    }
    if (params.data.direction === "inbound") {
      direction = "Inbound";
    }

    return <div>{direction}</div>;
  };

  const renderIconCell = (params: ICellRendererParams) => {
    if (params.data.voicemail === true) {
      return (
        <div className="flex items-center">
          <StyledTooltip title="Voicemail">
            <img src={voicemail} width={25} />
          </StyledTooltip>
        </div>
      );
    }
  };

  const columnDefs: ColDef<ExistingStiltPhoneCall>[] = [
    {
      field: "callStatus",
      headerName: "Status",
      maxWidth: 120,
    },
    {
      field: "direction",
      headerName: "Direction",
      cellRenderer: renderCallDirection,
      maxWidth: 120,
    },
    {
      field: "timestampCreated",
      headerName: "Date",
      cellRenderer: renderTimestamp,
      width: 150,
    },
    {
      headerName: "From",
      cellRenderer: renderFrom,
      width: 60,
    },
    {
      headerName: "To",
      cellRenderer: renderTo,
      width: 60,
    },
    {
      headerName: "Call Recording",
      cellRenderer: renderAudioRecording,
    },
    {
      colId: "actions",
      cellRenderer: renderIconCell,
      suppressMovable: true,
      suppressHeaderMenuButton: true,
      sortable: false,
      maxWidth: 80,
      flex: 0,
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
      },
    },
  ];

  return (
    <div className={"ag-theme-alpine isolate h-[300px]"}>
      <AgGridReact<ExistingStiltPhoneCall>
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={handleGridReady}
        autoSizeStrategy={autoSizeStrategy}
        defaultColDef={commonColProps}
        className="-z-10 mt-5 shadow"
        rowData={customerCalls}
        animateRows={true}
        pagination={true}
        rowSelection="single"
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
      />
    </div>
  );
}
