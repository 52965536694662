//Libs
import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";
import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
} from "ag-grid-community";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local
import {
  ExistingInventoryObjectForTable,
  getReadableInventoryObjectStatus,
} from "../../models/inventory-object";
import * as strings from "../../strings";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import SearchBox from "../../components/SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

interface Props {
  /* DATA */
  inventoryObjectTableData: ExistingInventoryObjectForTable[];
  inventoryLocationList: ExistingInventoryLocation[];
  /* FUNCTIONS */
  onAddInventoryObject: () => void;
  /* CHILDREN */
  children: {
    AddInventoryObject: React.ReactNode;
  };
}

export default function InventoryObjectListPage({
  inventoryObjectTableData,
  inventoryLocationList,
  onAddInventoryObject,
  ...props
}: Props) {
  const renderStatus = (params: ICellRendererParams): JSX.Element => (
    <div className="capitalize">
      {getReadableInventoryObjectStatus(params.data.status)}
    </div>
  );

  const renderTitle = (params: ICellRendererParams): JSX.Element => {
    return <div className="capitalize">{params.value}</div>;
  };

  const renderLocationTitle = (params: ICellRendererParams): JSX.Element => {
    const location = inventoryLocationList.find(
      (inventoryLocation) =>
        inventoryLocation.id === params.data.inventoryLocationID,
    );
    return <div className="capitalize">{location?.title}</div>;
  };

  return (
    <div className={"ag-theme-alpine flex h-full min-h-[600px] flex-col"}>
      <h1 className="mb-8 mt-4 text-5xl font-semibold text-primary">
        {strings.INVENTORY_OBJECT}
      </h1>
      <InventoryObjectListTable
        inventoryObjectTableData={inventoryObjectTableData}
        inventoryLocationList={inventoryLocationList}
        renderStatus={renderStatus}
        renderTitle={renderTitle}
        renderLocationTitle={renderLocationTitle}
        onAddInventoryObject={onAddInventoryObject}
      />
      {props.children.AddInventoryObject}
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  width: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  flex: 1,
};

interface InventoryObjectListTableProps {
  inventoryObjectTableData: Props["inventoryObjectTableData"];
  inventoryLocationList: Props["inventoryLocationList"];
  renderStatus: (params: ICellRendererParams) => JSX.Element;
  renderTitle: (params: ICellRendererParams) => JSX.Element;
  renderLocationTitle: (params: ICellRendererParams) => JSX.Element;
  onAddInventoryObject: () => void;
}

/* TABLE COMPONENT */
const InventoryObjectListTable = React.memo(
  ({
    inventoryObjectTableData,
    inventoryLocationList,
    renderStatus,
    renderTitle,
    renderLocationTitle,
    onAddInventoryObject,
  }: InventoryObjectListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const columnDefs: ColDef[] = [
      {
        headerName: "Title",
        field: "title",
        cellRenderer: renderTitle,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        autoHeight: true,
        width: 300,
      },
      {
        headerName: "Inventory Location",
        field: "inventoryLocationID",
        cellRenderer: renderLocationTitle,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return getLocationTitle(params, inventoryLocationList);
        },
      },
      {
        headerName: "Qty Available",
        field: "available",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Qty Awaiting Pickup",
        field: "awaitingPickup",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Qty In Use",
        field: "inUse",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Qty Reserved",
        field: "reserved",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: renderStatus,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <Fragment>
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full max-w-md sm:w-96"
          />
          <button
            type="button"
            onClick={onAddInventoryObject}
            className="text-primary"
          >
            <AddBoxIcon
              aria-label="Add Inventory Object"
              sx={{ fontSize: 45 }}
            />
          </button>
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={inventoryObjectTableData}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
        />
      </Fragment>
    );
  },
  (previous, next) => {
    const isInventoryObjectListTheSame = isEqual(
      previous.inventoryObjectTableData,
      next.inventoryObjectTableData,
    );
    return isInventoryObjectListTheSame;
  },
);

export function getLocationTitle(
  params: GetQuickFilterTextParams,
  inventoryLocationList: ExistingInventoryLocation[],
): string {
  const location = inventoryLocationList.find(
    (inventoryLocation) =>
      inventoryLocation.id === params.data.inventoryLocationID,
  );
  return location?.title ?? "";
}
