//Libs
import { Fragment, useEffect, useState } from "react";
import { PencilIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import {
  Formik,
  FormikProps,
  Field,
  ErrorMessage,
  FormikHelpers,
} from "formik";
import { DocumentData } from "firebase/firestore";

//Local
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import BaseModal from "../components/BaseModal";
import FormLabel from "../components/FormLabel";
import BaseInputText from "../components/BaseInputText";
import BaseInputSelect from "../components/BaseInputSelect";
import BaseButtonSecondary from "../components/BaseButtonSecondary";
import BaseInputTextArea from "../components/BaseInputTextArea";
import BaseInputTag from "../components/BaseInputTag";
import InputTextWithButton from "../components/InputTextWithButton ";
import BaseInputNumber from "../components/BaseInputNumber";
import BaseInputCheckbox from "../components/BaseInputCheckbox";
import ModalItem from "../components/ModalItem";
import {
  ResponseTypes,
  responseTypes,
  isResponseType,
} from "../models/checklist-response-types";
import {
  ChecklistItem,
  ExistingChecklistItem,
  ChecklistItemManager,
} from "../models/checklist-item";
import {
  ConvertArrayToString,
  showPassingOptions,
} from "../assets/js/HandleOptions";
import { convertPassingNumberValues } from "../assets/js/PassingNumberValues";
import { handleTags } from "../assets/js/HandleTags";
import { logger as devLogger } from "../logging";
import * as strings from "../strings";

interface Props {
  item: ExistingChecklistItem;
  onEditItem: (data: DocumentData) => Promise<void>;
  onCancel: () => void;
  uid: string | null;
}

export default function EditItemPage({
  item,
  onEditItem,
  onCancel,
  uid,
  ...props
}: Props) {
  const initialOptions = showPassingOptions(
    item.selectionOptions,
    item.passingOptions,
  );

  const [formSelectionOptions, setFormSelectionOptions] =
    useState<Record<string, boolean>>(initialOptions);
  const passingOptions =
    getPassingOptionsFromFormSelectionOptions(formSelectionOptions);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [showOptionSection, setShowOptionSection] = useState(false);

  useEffect(() => {
    if (item.responseType === "selection") {
      setShowOptionSection(true);
    }
  }, [item]);

  // We do this so we have both the runtime value and the type.
  const presets = ["presetYesNo"] as const;
  type Presets = (typeof presets)[number];

  // We do this so we have both the runtime value and the type.
  const presetsPlusResponseTypes = [...responseTypes, ...presets] as const;
  type PresetsPlusResponseTypes = (typeof presetsPlusResponseTypes)[number];

  // Typeguard
  function isPresetsPlusResponseTypes(
    value: unknown,
  ): value is PresetsPlusResponseTypes {
    if (presetsPlusResponseTypes.includes(value as any)) {
      return true;
    }
    return false;
  }

  /**
   * We need to translate the users type choice, which can be either a ResponseType
   * or a preset we have created, to the actual responseType values.
   */
  function convertPresetsToResponseTypes(
    value: PresetsPlusResponseTypes,
  ): ResponseTypes {
    const mapPresetsToResponseTypes: Record<Presets, ResponseTypes> = {
      presetYesNo: "selection",
    } as const;

    if (isResponseType(value)) {
      return value;
    }
    return mapPresetsToResponseTypes[value];
  }

  const AllItemsFormSchema = z.object({
    mainText: z.string().min(1).max(200),
    note: z.string().min(0).max(200),
    tags: z.string().min(0).max(200),
    required: z.boolean(),
    typeOrPreset: z.enum(presetsPlusResponseTypes),
    passingMax: z.union([z.number(), z.literal("")]),
    passingMin: z.union([z.number(), z.literal("")]),
    units: z.string().min(0).max(200),
    addOptions: z.string().nullable(),
    passingOptions: z.string().array(),
    craftRecordIDs: z.string().array(),
    deleted: z.boolean(),
    optionError: z.string().optional(),
  });

  type AllItemsFormState = z.infer<typeof AllItemsFormSchema>;

  const initialFormValues: AllItemsFormState = {
    mainText: item.mainText,
    note: item.note,
    tags: ConvertArrayToString(item.tags),
    required: item.required,
    typeOrPreset: item.responseType,
    passingMax: item.passingMax !== null ? item.passingMax : "",
    passingMin: item.passingMin !== null ? item.passingMin : "",
    units: item.units,
    addOptions: null,
    passingOptions: item.passingOptions !== null ? item.passingOptions : [],
    craftRecordIDs: item.craftRecordIDs,
    deleted: false,
  };

  /**
   * Convert the formSelectionOptions state object into
   * an array of strings for only the passing options. Values
   * that were true.
   */
  function getPassingOptionsFromFormSelectionOptions(
    options: Record<string, boolean>,
  ): string[] {
    const passingOptions: string[] = [];

    Object.keys(options).forEach((optionName) => {
      if (options[optionName] === true) {
        passingOptions.push(optionName);
      }
    });

    return passingOptions;
  }

  /**
   * Handle updating a Checklist Item from the form data ready
   * to be written to Firestore.
   */
  function convertFormDataToDocumentData(args: {
    formValues: AllItemsFormState;
  }): DocumentData {
    const localResponseType = convertPresetsToResponseTypes(
      args.formValues.typeOrPreset,
    );

    if (!isResponseType(localResponseType)) {
      devLogger.error(`Invalid responseType: ${localResponseType}`);
      throw new Error(`Invalid responseType: ${localResponseType}`);
    }

    const checklistItem: ChecklistItem = {
      mainText: args.formValues.mainText,
      note: args.formValues.note,
      responseType: localResponseType,
      required: args.formValues.required,
      tags: handleTags(args.formValues.tags),
      passingMax: convertPassingNumberValues(args.formValues.passingMax),
      passingMin: convertPassingNumberValues(args.formValues.passingMin),
      selectionOptions:
        localResponseType !== "selection"
          ? null
          : Object.keys(formSelectionOptions),
      passingOptions:
        localResponseType !== "selection"
          ? null
          : getPassingOptionsFromFormSelectionOptions(formSelectionOptions),
      units: args.formValues.units,
      craftRecordIDs: args.formValues.craftRecordIDs,
      deleted: false,
    };

    // Validate data before sending to server.
    return ChecklistItemManager.parse(checklistItem);
  }

  /**
   * Handle for submission for Formik
   */
  async function handleEdit(
    values: AllItemsFormState,
    actions: FormikHelpers<AllItemsFormState>,
  ): Promise<void> {
    if (uid == null) {
      devLogger.error(`User ID was not found. Value: ${uid}`);
      return;
    }

    const validatedData = convertFormDataToDocumentData({
      formValues: values,
    });

    // Write to Firestore
    devLogger.debug("ABOUT TO EDIT THE DOC IN THE DATABASE 🔥");
    // Call Firestore method to write data to database.
    await onEditItem(validatedData);

    devLogger.debug("DONE 🔥");

    actions.setSubmitting(false);
    setShowOptionSection(false);
    toggleModal();
  }

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
    onCancel();
  }

  //this function it's called when clicking on the X of the passingOptions that we want to delete
  function deleteOption(optionToRemove: string) {
    const convertToArray = Object.entries(formSelectionOptions);
    //convertToArray = ['key', 'value']
    const newFormSelectionOptions = convertToArray.filter(
      ([option]) => option !== optionToRemove,
    );
    setFormSelectionOptions(Object.fromEntries(newFormSelectionOptions));
  }

  function handleTempPassingOptionsCheckbox(property: string) {
    setFormSelectionOptions((prevValues) => ({
      ...prevValues,
      [property]: !prevValues[property],
    }));
  }

  function handleSelections(value: string) {
    switch (value) {
      case "selection":
        setShowOptionSection(true);
        if (Object.keys(formSelectionOptions).length === 0) {
          setFormSelectionOptions({});
        } else {
          return formSelectionOptions;
        }
        break;
      case "presetYesNo":
        setShowOptionSection(true);
        if (Object.keys(formSelectionOptions).length === 0) {
          setFormSelectionOptions({ Yes: false, No: false });
        } else {
          if (
            formSelectionOptions.hasOwnProperty("Yes") &&
            formSelectionOptions.hasOwnProperty("No")
          ) {
            return formSelectionOptions;
          } else {
            setFormSelectionOptions({ Yes: false, No: false });
          }
        }
        break;
      default:
        setShowOptionSection(false);
    }
  }

  devLogger.debug("formSelectionOptions", formSelectionOptions);

  /**
   * Determine which set of JSX inputs to render based on typeOrPreset.
   */
  function renderConditionalInputs(
    selectionOptionsJSX: JSX.Element,
    numberSectionJSX: JSX.Element,
    formikProps: FormikProps<AllItemsFormState>,
  ): React.ReactNode {
    if (showOptionSection) {
      return selectionOptionsJSX;
    } else if (
      !showOptionSection &&
      ["integer", "float"].includes(formikProps.values.typeOrPreset)
    ) {
      return numberSectionJSX;
    } else {
      return null;
    }
  }

  return (
    <BaseModal open={isModalOpen} closeModal={toggleModal} title="Edit Item">
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleEdit}
        validate={(values) => {
          const errors: Record<string, any> = {};
          const result = AllItemsFormSchema.safeParse(values);

          if (!result.success) {
            const zodError = result.error;
            return zodError.flatten().fieldErrors;
          }

          return errors;
        }}
      >
        {(formikProps: FormikProps<AllItemsFormState>) => {
          const SelectionOptionsJSX: JSX.Element = (
            <Fragment>
              {/* Selection Options Input */}
              <FormLabel
                htmlFor="addOptions"
                label="Add Options"
                className="mr-1 inline-block"
              />
              <Field
                as={InputTextWithButton}
                name="addOptions"
                id="addOptions"
                placeholder="Insert your option and click the button"
                onButtonClick={(optionString: string) => {
                  setFormSelectionOptions((oldState) => {
                    const newState = { ...oldState };
                    newState[optionString] = false;
                    return newState;
                  });
                }}
              />
              <ErrorMessage
                name="addOptions"
                component="div"
                className="mt-2 text-xs text-red-400"
              />

              {/* Passing Options Select */}
              {Object.keys(passingOptions).length !== 0 ? (
                <div className="flex items-center py-4">
                  <span className="flex-1 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Options
                  </span>
                  <span className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Considered Passing?
                  </span>
                </div>
              ) : null}

              {/* Create list of checkboxes for each selection option */}
              <div id="checkbox-group"></div>
              <div
                className="grid grid-cols-1 py-2"
                role="group"
                aria-labelledby="checkbox-group"
              >
                {Object.keys(formSelectionOptions).map((name, key) => {
                  return (
                    <div className="flex flex-row items-center py-2" key={key}>
                      <button
                        type="button"
                        className="flex-none"
                        onClick={() => deleteOption(name)}
                      >
                        <XMarkIcon
                          aria-label="delete option"
                          className="mr-2 h-5"
                        />
                      </button>
                      <FormLabel
                        htmlFor={name}
                        label={name}
                        removePadding={true}
                        className="flex-grow pr-2"
                      />
                      <Field
                        as={BaseInputCheckbox}
                        name="passingOptions"
                        id={name}
                        label=""
                        value={name}
                        checked={formSelectionOptions[name]}
                        onClick={() => {
                          handleTempPassingOptionsCheckbox(name);
                        }}
                      />
                    </div>
                  );
                })}
                {/* Show error messages based on custom logic.  */}
                {convertPresetsToResponseTypes(
                  formikProps.values.typeOrPreset,
                ) === "selection" &&
                  passingOptions.length === 0 && (
                    <div className="mt-2 text-xs text-red-400">
                      At least one option should be marked as passing.
                    </div>
                  )}
              </div>
            </Fragment>
          );

          const NumberSectionJSX: JSX.Element = (
            <Fragment>
              {/* Passing Min Input */}
              <FormLabel htmlFor="passingMin" label="Passing Min" />
              <Field
                as={BaseInputNumber}
                name="passingMin"
                id="passingMin"
                className="my-2 flex max-w-lg rounded-md shadow-sm"
              />
              <ErrorMessage
                name="passingMin"
                component="div"
                className="mt-2 text-xs text-red-400"
              />

              {/* Passing Max Input */}
              <FormLabel htmlFor="passingMax" label="Passing Max" />
              <Field
                as={BaseInputNumber}
                name="passingMax"
                id="passingMax"
                className="my-2 flex max-w-lg rounded-md shadow-sm"
              />
              <ErrorMessage
                name="passingMax"
                component="div"
                className="mt-2 text-xs text-red-400"
              />

              {/* Units Input */}
              <FormLabel htmlFor="units" label="Units" />
              <Field
                as={BaseInputText}
                name="units"
                id="units"
                className="my-2 flex max-w-lg rounded-md shadow-sm"
              />
              <ErrorMessage
                name="units"
                component="div"
                className="mt-2 text-xs text-red-400"
              />
            </Fragment>
          );

          const ContentJSX: JSX.Element = (
            <Fragment>
              {/* Main Text Input */}
              <FormLabel htmlFor="mainText" label="Main Text" />
              <Field
                as={BaseInputText}
                name="mainText"
                id="mainText"
                className="my-2 flex max-w-lg rounded-md shadow-sm"
              />
              <ErrorMessage
                name="mainText"
                component="div"
                className="mt-2 text-xs text-red-400"
              />
              {/* Note Input */}
              <FormLabel htmlFor="note" label="Note" />
              <Field
                as={BaseInputTextArea}
                name="note"
                id="note"
                className="my-2 flex max-w-lg rounded-md shadow-sm"
                rows={3}
              />
              <ErrorMessage
                name="note"
                component="div"
                className="mt-2 text-xs text-red-400"
              />
              {/* Tags Input */}
              <FormLabel htmlFor="tags" label="Tags" />
              <Field
                as={BaseInputTag}
                name="tags"
                id="tags"
                className="my-2 flex max-w-lg rounded-md shadow-sm"
                placeholder="Insert your tags, separated by comma"
              />
              <ErrorMessage
                name="tags"
                component="div"
                className="mt-2 text-xs text-red-400"
              />
              {/* Required Input */}
              <div className="flex items-center py-4">
                <FormLabel
                  htmlFor="required"
                  label="Required"
                  className="flex-1"
                  removePadding={true}
                />
                <Field
                  as={BaseInputCheckbox}
                  id="required"
                  label=""
                  name="required"
                  checked={formikProps.values.required === true}
                />
              </div>
              {/* Response Type Select */}
              <FormLabel htmlFor="typeOrPreset" label="Response Type" />
              <Field
                as={BaseInputSelect}
                admin={false}
                name="typeOrPreset"
                className="my-2"
                id="typeOrPreset"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const value = event.target.value;

                  if (isPresetsPlusResponseTypes(value)) {
                    formikProps.values.typeOrPreset = value;
                  }

                  handleSelections(value);

                  // Blur the selection box, so renderConditionalInputs invokes immediately.
                  event.target.blur();
                }}
              >
                <option value="" disabled>
                  Please select one...
                </option>
                <option value="string">Text</option>
                <option value="selection">Selection</option>
                <option value="integer">Whole Number</option>
                <option value="float">Decimal</option>
                <option value="presetYesNo">Yes/No</option>
              </Field>
              {/* Render inputs that depend on what option was selected. */}
              {renderConditionalInputs(
                SelectionOptionsJSX,
                NumberSectionJSX,
                formikProps,
              )}
            </Fragment>
          );

          return (
            <ModalItem>
              {{
                content: ContentJSX,
                actions: (
                  <Fragment>
                    <div className="flex gap-x-2">
                      <BaseButtonSecondary
                        className="w-full"
                        type="button"
                        onClick={() => onCancel()}
                      >
                        <XCircleIcon
                          aria-label="cancel item"
                          className="mr-4 h-8"
                        />
                        {strings.buttons.CANCEL}
                      </BaseButtonSecondary>

                      <BaseButtonPrimary
                        className="w-full"
                        type="submit"
                        disabled={formikProps.isSubmitting}
                        isBusy={formikProps.isSubmitting}
                        busyText={strings.buttons.BUSY_SAVING}
                      >
                        <PencilIcon
                          aria-label="save item"
                          className="mr-4 h-8"
                        />
                        {strings.buttons.SAVE}
                      </BaseButtonPrimary>
                    </div>
                  </Fragment>
                ),
              }}
            </ModalItem>
          );
        }}
      </Formik>
    </BaseModal>
  );
}
