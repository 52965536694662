// Libs
import { forwardRef } from "react";

type Ref = HTMLDivElement;
type Props = {
  urlMap: Record<string, string>;
  handleClick: (val: string) => Promise<void>;
} & React.ComponentPropsWithRef<"div">;

/** This is what we display when the end-customer is happy. */
export const SocialsPanel = forwardRef<Ref, Props>(
  ({ urlMap, handleClick, ...props }, ref) => (
    <div
      className="mx-auto max-w-screen-sm space-y-8 rounded-md bg-gray-100 px-7 py-6 shadow-md"
      ref={ref}
      {...props}
    >
      <p className="text-lg text-gray-700">
        We're glad we could make you happy! A positive 5-star review goes a long
        way — if you were really happy with the service we provided, it would
        mean a lot to us if you could let everyone else know by leaving a{" "}
        {Object.keys(urlMap).length === 1
          ? "review:"
          : "review at one of these places:"}
      </p>
      <div className="flex flex-wrap items-center justify-evenly gap-6">
        {Object.entries(urlMap).map(([name, url]) => (
          <ExternalLink key={name} href={url} onClick={handleClick}>
            {name}
          </ExternalLink>
        ))}
      </div>
    </div>
  ),
);

interface LinkProps {
  href: string;
  children: string;
  onClick: (val: string) => Promise<void>;
}
function ExternalLink(props: LinkProps): JSX.Element {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        props.onClick(props.children);
      }}
      className={`inline-flex w-full items-center justify-center rounded-md
      border border-gray-300 bg-white px-3.5 py-2.5 text-base font-medium
      tracking-wide text-gray-700 shadow-sm transition-colors hover:bg-gray-50
      focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2
      xxs:w-auto xxs:min-w-[10rem]`}
    >
      {props.children.toUpperCase()}
    </a>
  );
}
