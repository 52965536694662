interface Props {
  passingMin: number | null;
  passingMax: number | null;
  units: string;
}
export function ShowRightStringForPassingMinMaxUnits({
  passingMin,
  passingMax,
  units,
}: Props) {
  if (passingMin !== null && passingMax === null) {
    return `>= ${passingMin} ${units}`;
  } else if (passingMin === null) {
    if (passingMax !== null) {
      return `<= ${passingMax} ${units}`;
    } else if (units !== "") {
      return `${units}`;
    } else {
      return "";
    }
  } else {
    return `${passingMin} - ${passingMax} ${units}`;
  }
}
