//Libs
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import { Customer, customerTypes } from "../../models/customer";
import { TemporaryLocation } from "./AddEditCustomerLocationDialog";
import {
  customerLocationWithoutTimestamps,
  ExistingCustomerLocation,
} from "../../models/customer-location";
import { ErrorMessage } from "../ErrorMessage";
import { splitAddressComponents } from "../../assets/js/splitAddressComponents";
import { logger as devLogger } from "../../logging";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import BaseInputSelect from "../BaseInputSelect";

interface Props {
  customerLocationDoc: ExistingCustomerLocation | null;
  geocoderResult: google.maps.GeocoderResult;
  customerType: Customer["type"];
  isSubmitting: boolean;
  handleSaveCustomerLocationWithAutocomplete: (
    newLocation: TemporaryLocation,
  ) => void;
  handleEditCustomerLocation?: (
    updateLocation: customerLocationWithoutTimestamps,
  ) => Promise<void>;
  setAddEditCustomerLocationFormOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  children: {
    AddressField: React.ReactNode;
    AddMembershipsButton?: React.ReactNode;
    CustomerContacts?: React.ReactNode;
  };
}

const AddEditLocationWithAutocompleteFormSchema = z.object({
  locationName: z.string().max(200).optional(),
  addressLine2: z.string().max(200).optional(),
  notes: z.string().max(1000).optional(),
  type: z.enum(customerTypes),
});
export type AddEditLocationWithAutocompleteFormState = z.infer<
  typeof AddEditLocationWithAutocompleteFormSchema
>;

export default function AddEditLocationWithAutocompleteForm(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [displayAddressError, setDisplayAddressError] =
    useState<boolean>(false);

  const addEditLocationWithAutocompleteDefaultValues: AddEditLocationWithAutocompleteFormState =
    useMemo(() => {
      if (props.customerLocationDoc) {
        return {
          locationName: props.customerLocationDoc.locationName ?? "",
          addressLine2: props.customerLocationDoc?.addressLine2 ?? "",
          notes: props.customerLocationDoc?.notes ?? "",
          type: props.customerLocationDoc?.type ?? "residential",
        };
      } else {
        return {
          locationName: "",
          addressLine2: "",
          notes: "",
          type: props.customerType,
        };
      }
    }, [props.customerType, props.customerLocationDoc]);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<AddEditLocationWithAutocompleteFormState>({
    defaultValues: addEditLocationWithAutocompleteDefaultValues,
    resolver: zodResolver(AddEditLocationWithAutocompleteFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(addEditLocationWithAutocompleteDefaultValues);
  }, [addEditLocationWithAutocompleteDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the section is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    setDisplayAddressError(false);
    props.setAddEditCustomerLocationFormOpen(false);
  }

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  const addressError = (
    <ErrorMessage
      message="The address should be picked from the dropdown list"
      clearMessage={() => setDisplayAddressError(false)}
    />
  );

  const onSubmit: SubmitHandler<
    AddEditLocationWithAutocompleteFormState
  > = async (formValues) => {
    // if (props.geocoderResult == null) {
    //   setDisplayAddressError(true);
    //   return;
    // }

    if (props.customerLocationDoc && props.handleEditCustomerLocation) {
      let updateLocation: customerLocationWithoutTimestamps = {
        ...props.customerLocationDoc,
        locationName: formValues.locationName,
        addressLine2: formValues.addressLine2 ?? null,
        notes: formValues.notes ?? null,
        type: formValues.type,
      };
      if (props.geocoderResult) {
        updateLocation = {
          ...updateLocation,
          fullAddress: props.geocoderResult.formatted_address,
          addressLine1:
            splitAddressComponents(props.geocoderResult).streetNumber +
            " " +
            splitAddressComponents(props.geocoderResult).street,
          streetNumber: splitAddressComponents(props.geocoderResult)
            .streetNumber,
          street: splitAddressComponents(props.geocoderResult).street,
          city: splitAddressComponents(props.geocoderResult).city,
          zipCode: splitAddressComponents(props.geocoderResult).zipCode,
          state: splitAddressComponents(props.geocoderResult).state,
          county: splitAddressComponents(props.geocoderResult).county,
          latitude: props.geocoderResult.geometry.location.lat(),
          longitude: props.geocoderResult.geometry.location.lng(),
          googlePlaceID: props.geocoderResult.place_id,
        };
      }

      try {
        await props.handleEditCustomerLocation(updateLocation);
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    } else {
      const addressLine1 =
        splitAddressComponents(props.geocoderResult).streetNumber +
        " " +
        splitAddressComponents(props.geocoderResult).street;
      /* sending all the values minus the ones that will be the same for every customerLocation object like the customerID */
      const partialLocationValues: TemporaryLocation = {
        locationName: formValues.locationName,
        fullAddress: props.geocoderResult.formatted_address,
        addressLine1: addressLine1 !== " " ? addressLine1 : null,
        addressLine2: formValues.addressLine2 ?? null,
        streetNumber: splitAddressComponents(props.geocoderResult).streetNumber,
        street: splitAddressComponents(props.geocoderResult).street,
        city: splitAddressComponents(props.geocoderResult).city,
        zipCode: splitAddressComponents(props.geocoderResult).zipCode,
        state: splitAddressComponents(props.geocoderResult).state,
        county: splitAddressComponents(props.geocoderResult).county,
        latitude: props.geocoderResult.geometry.location.lat(),
        longitude: props.geocoderResult.geometry.location.lng(),
        googlePlaceID: props.geocoderResult.place_id,
        notes: formValues.notes ?? null,
        type: formValues.type,
        tags: [],
        customData: {},
        yearBuilt: null,
        estimatedValue: null,
        squareFootage: null,
        deleted: false,
        totalTaxRate: null,
        taxRates: [],
        billToCustomerID: null,
        billToCustomerLocationID: null,
      };
      try {
        props.handleSaveCustomerLocationWithAutocomplete(partialLocationValues);
        // Close the section if successful.
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      className="space-y-8"
    >
      {/* Field: Location Name */}
      <div>
        <Controller
          name="locationName"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Location Name"
              inputName="locationName"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.locationName?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.locationName.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Google Places Component for Full Address Field */}
      {props.children.AddressField}

      {/* Field: Address (cont.) */}
      <div>
        <Controller
          name="addressLine2"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Address (cont.)"
              inputName="addressLine2"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.addressLine2?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.addressLine2.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Type */}
      <div>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <BaseInputSelect
              inputName="type"
              text="Type"
              admin={true}
              required={true}
              className="capitalize"
              {...field}
              value={field.value === null ? "" : field.value}
            >
              {/* The empty string for value tricks validation into failing */}
              <option value="" disabled>
                Select a type
              </option>
              {customerTypes.map((type) => {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                );
              })}
            </BaseInputSelect>
          )}
        />
        {errors.type?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.type.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Notes */}
      <div>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Notes"
              inputName="notes"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.notes?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.notes.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {props.children.CustomerContacts}
      {props.children.AddMembershipsButton}

      <div className="mt-4">
        {displayError ? errorMessage : null}
        {displayAddressError ? addressError : null}
      </div>

      {/* Action Buttons */}
      <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={closeAndReset}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          disabled={props.isSubmitting}
          isBusy={props.isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
          data-testid="save item"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
    </form>
  );
}
