// Libs
import { z } from "zod";
import { DocumentSnapshot, Timestamp } from "firebase/firestore";

// Local
import { guardIsPlainObject } from "../utils";
import { NotFoundError } from "../error-classes";

export interface LightspeedTransactionData {
  invoiceID: string;
  locationID: string;
  priceBookItemID: string;
  createdBy: string;
  quantity: number;
  previousInventoryLevel?: number;
  adjustedInventoryLevel?: number;
  timestampCreated: Timestamp;
  timestampPushed: Timestamp | null;
  priceBookItemTitle: string;
  invoiceNumber: string;
}

export interface ExistingLightspeedTransaction
  extends LightspeedTransactionData {
  id: string;
  refPath: string;
}

export const LightspeedTransactionManager = {
  parse: validateLightspeedTransaction,
  createFromFirestoreSnapshot: createFromFirestoreSnapshot,
};

function validateLightspeedTransaction(
  value: unknown,
): LightspeedTransactionData {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = lightspeedTransactionSchema.parse(value);
  return result;
}

function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingLightspeedTransaction {
  if (!snapshot.exists) {
    throw new NotFoundError("Document does not exist.");
  }
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateLightspeedTransaction(snapshot.data()),
  };
}

const lightspeedTransactionSchema = z.object({
  invoiceID: z.string().min(1).max(200),
  locationID: z.string().min(1).max(200),
  priceBookItemID: z.string().min(1).max(200),
  createdBy: z.string().min(1).max(200),
  quantity: z.number(),
  previousInventoryLevel: z.number().optional(),
  adjustedInventoryLevel: z.number().optional(),
  timestampCreated: z.instanceof(Timestamp),
  timestampPushed: z.instanceof(Timestamp).nullable(),
  priceBookItemTitle: z.string().min(1).max(2000),
  invoiceNumber: z.string().min(1).max(200),
});
