//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useMemo, useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { v4 as uuid4 } from "uuid";

//Local
import BaseModal from "../../BaseModal";
import BaseInputText from "../../BaseInputText";
import BaseButtonSecondary from "../../BaseButtonSecondary";
import BaseButtonPrimary from "../../BaseButtonPrimary";
import BaseInputSelect from "../../BaseInputSelect";
import StyledMessage from "../../StyledMessage";
import * as strings from "../../../strings";
import { complianceRequirementTypes } from "../../../models/compliance-types-and-statuses";
import {
  ComplianceItemCommon,
  ComplianceItemCommon_WithoutOrderProperty,
} from "../../../models/compliance-item";
import { ComplianceRequirement } from "../../../models/compliance-requirement";
import { logger as devLogger } from "../../../logging";
import { ErrorMessage } from "../../ErrorMessage";
import NewComplianceItemDialog from "./NewComplianceItemDialog";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  handleSave: (
    formValues: Pick<
      ComplianceRequirement,
      "title" | "description" | "type" | "customFields"
    >,
  ) => Promise<void>;
}

const RequirementSchema = z.object({
  title: z.string().min(1, { message: "Required" }).max(2000),
  description: z.string().min(1, { message: "Required" }).max(2000),
  type: z.enum(complianceRequirementTypes),
});
export type RequirementState = z.infer<typeof RequirementSchema>;

export default function AddNewRequirementDialog(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [count, incrementCount] = useState(1);

  const [additionalAttributeDialogOpen, setAdditionalAttributeDialogOpen] =
    useState<boolean>(false);

  function handleSaveAdditionalAttribute(
    newComplianceItem: ComplianceItemCommon_WithoutOrderProperty,
  ) {
    const itemWithOrderProperty: ComplianceItemCommon = {
      order: count,
      ...newComplianceItem,
    };
    const newItemID: string = uuid4();
    setComplianceItems({
      ...complianceItems,
      [newItemID]: itemWithOrderProperty,
    });
    incrementCount((prev) => prev + 1);
  }

  const newAdditionalAttributeDialog =
    additionalAttributeDialogOpen === true ? (
      <NewComplianceItemDialog
        closeDialog={() => setAdditionalAttributeDialogOpen(false)}
        isDialogOpen={additionalAttributeDialogOpen}
        handleSaveComplianceItem={handleSaveAdditionalAttribute}
      />
    ) : null;

  const [complianceItems, setComplianceItems] = useState<
    Record<string, ComplianceItemCommon>
  >({});

  const requirementDefaultValues: RequirementState = useMemo(() => {
    return {
      title: "",
      description: "",
      type: "document",
    };
  }, []);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<RequirementState>({
    defaultValues: requirementDefaultValues,
    resolver: zodResolver(RequirementSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(requirementDefaultValues);
  }, [requirementDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function onCloseDialog() {
    props.closeDialog();
    reset();
  }

  const onSubmit: SubmitHandler<RequirementState> = async (formValues) => {
    const value: Pick<
      ComplianceRequirement,
      "title" | "description" | "type" | "customFields"
    > = {
      title: formValues.title,
      description: formValues.description,
      type: formValues.type,
      customFields: complianceItems,
    };

    try {
      await props.handleSave(value);
      // Close dialog if successful.
      onCloseDialog();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  const newRequirementHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.NEW_REQUIREMENT}
      </h1>
      <button type="button" onClick={() => onCloseDialog()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = (
    <ErrorMessage
      message={strings.ERR_CREATE_COMPLIANCE_REQUIREMENT}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <BaseModal
      closeModal={onCloseDialog}
      open={props.isDialogOpen}
      title={newRequirementHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          {/* Field: Title */}
          <div>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Title"
                  inputName="title"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.title?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.title.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Description */}
          <div>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Description"
                  inputName="description"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.description?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.description.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Type */}
          <div>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="type"
                  text="Type"
                  admin={true}
                  required={true}
                  {...field}
                >
                  {/* The empty string for value tricks validation into failing */}
                  <option value="" disabled>
                    Select a type
                  </option>
                  <option value={"document"}>Document</option>
                </BaseInputSelect>
              )}
            />
            {errors.type?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.type.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          <div className="space-y-4">
            {/* Additional Attributes Section */}
            <div className="flex items-center sm:justify-between">
              <h2 className="mr-auto inline-block text-xl font-bold text-gray-700">
                Additional attributes
              </h2>
              {/* Add new compliance item button */}
              <button
                data-testid="addRequirementSaveBtn"
                type="button"
                onClick={() => setAdditionalAttributeDialogOpen(true)}
                className="text-primary"
              >
                <AddBoxIcon aria-label="add item" sx={{ fontSize: 35 }} />
              </button>
            </div>
            {newAdditionalAttributeDialog}

            {/* TODO: compliance item should be clickable to see the specifics? */}
            {/* Compliance Item - Custom Fields */}
            {Object.values(complianceItems).map((complianceItem, idx) => {
              return (
                <div key={idx} className="rounded-md border px-4 py-3">
                  {complianceItem.text}
                </div>
              );
            })}
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={onCloseDialog}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>

        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}
