//Libs
import { ArrowDownTrayIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseInputSelect from "../components/BaseInputSelect";
import BaseInputText from "../components/BaseInputText";
import BaseInputTextArea from "../components/BaseInputTextArea";
import FormLabel from "../components/FormLabel";
import { ExistingSiteKeyLocation } from "../models/site-key-location";
import BaseInputCheckbox from "./BaseInputCheckbox";
import StyledMessage from "./StyledMessage";
import BaseButtonSecondary from "./BaseButtonSecondary";
import BaseButtonPrimary from "./BaseButtonPrimary";
import * as strings from "../strings";

interface Props {
  locationsList: ExistingSiteKeyLocation[];
  initialFormValues: FormSchema;
  onFormSubmit: SubmitHandler<FormSchema>;
  onCancel: () => void;
}

const formSchema = z.object({
  title: z.string().min(1).max(200),
  description: z.string().max(200).nullable(),
  locationID: z.string().min(1).max(200),
  requireApprovals: z.boolean(),
});

export type FormSchema = z.infer<typeof formSchema>;

export default function ChecklistBodyForm(props: Props) {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormSchema>({
    defaultValues: props.initialFormValues,
    resolver: zodResolver(formSchema),
  });

  return (
    <form
      onSubmit={handleSubmit(props.onFormSubmit)}
      autoComplete="off"
      className="mx-2 space-y-8 divide-y divide-gray-200"
    >
      <div className="mt-6 space-y-6 pt-5 sm:mt-5 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
          <FormLabel htmlFor="title" label="Title" />
          <div className="sm:col-span-2">
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <BaseInputText
                  id="title"
                  admin={false}
                  {...field}
                  className="mt-1 flex max-w-lg rounded-md shadow-sm sm:mt-0"
                />
              )}
            />
            {/* error display */}
            {errors.title?.message && (
              <StyledMessage type="error">
                {{ message: errors.title.message }}
              </StyledMessage>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <FormLabel htmlFor="description" label="Description" />
          <div className="sm:col-span-2">
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <BaseInputTextArea
                  id="description"
                  admin={false}
                  rows={3}
                  {...field}
                  value={
                    field.value === null
                      ? undefined
                      : field.value /* because value may be null */
                  }
                  onChange={(event) => {
                    // Store empty values as null instead of undefined
                    field.onChange(event.target.value ?? null);
                  }}
                  className="mt-1 flex max-w-lg rounded-md shadow-sm sm:mt-0"
                />
              )}
            />
            {/* error display */}
            {errors.description?.message && (
              <StyledMessage type="error">
                {{ message: errors.description.message }}
              </StyledMessage>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <FormLabel htmlFor="location" label="Location" />
          <div className="sm:col-span-2">
            <Controller
              name="locationID"
              control={control}
              render={({ field }) => (
                <BaseInputSelect admin={false} {...field} id="location">
                  <option value="" disabled>
                    Select Location
                  </option>

                  {props.locationsList.map((locationDoc) => {
                    return (
                      <option value={locationDoc.id} key={locationDoc.id}>
                        {locationDoc.title}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              )}
            />
            {/* error display */}
            {errors.locationID?.message && (
              <StyledMessage type="error">
                {{ message: errors.locationID.message }}
              </StyledMessage>
            )}
          </div>
        </div>

        <div className="py-6 sm:border-t sm:border-gray-200 sm:py-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
            <FormLabel htmlFor="approvals" label="Approvals" />

            <div className="mt-4 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg space-y-4">
                <Controller
                  control={control}
                  name="requireApprovals"
                  render={({ field }) => (
                    <BaseInputCheckbox
                      id="approvals"
                      label="Require approval upon completion"
                      {...field}
                      value={undefined} // don't need a value for this checkbox, just true or false.
                      checked={field.value ?? false}
                      onChange={(event) => field.onChange(event.target.checked)}
                    >
                      Supervisor/Manager will need to approve the completed
                      checklist before it can be closed out
                    </BaseInputCheckbox>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Action Buttons */}
      <div className="mr-1 pb-2 pt-5">
        <div className="flex justify-end gap-x-2">
          <BaseButtonSecondary type="button" onClick={props.onCancel}>
            <XCircleIcon aria-label="cancel checklist" className="mr-4 h-5" />
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>
          <BaseButtonPrimary
            type="submit"
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
          >
            <ArrowDownTrayIcon
              aria-label="save checklist"
              className="mr-4 h-5"
            />
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </div>
    </form>
  );
}
