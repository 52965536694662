// Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { z } from "zod";

// Local
import {
  adminPropDefaultsForCustomField,
  CustomFieldManager,
  StringTextareaCustomField,
} from "../../../models/custom-field";
import {
  FormComponentInterface,
  TaskTypeField,
  TitleField,
  MinField,
  MaxField,
  EditableAndRequiredFields,
  OnTaskStatusField,
  isDefinedOnTaskStatus,
  ActionButtons,
  HideOnCRCreationField,
  StringTextareaSchema_RHF,
  StringTextareaDefaultValueField,
} from "./AddCustomFieldDialog";
import { ErrorMessage } from "../../ErrorMessage";
import { logger as devLogger } from "../../../logging";
import { OTaskTypes } from "../../../models/task-types";

const stringTextareaDefaultValues: Partial<StringTextareaInterface> = {
  defaultValue: "",
  min: null,
  max: null,

  title: "",
  editable: false,
  required: false,
  hideOnCraftRecordCreation: false,
};
type StringTextareaInterface = z.infer<typeof StringTextareaSchema_RHF>;

export const StringTextareaForm: React.FunctionComponent<
  FormComponentInterface
> = (props) => {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<StringTextareaInterface>({
    defaultValues: stringTextareaDefaultValues,
    resolver: zodResolver(StringTextareaSchema_RHF),
    mode: "onChange",
  });

  useEffect(() => {
    reset(stringTextareaDefaultValues);
  }, [reset]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const watchTaskType = useWatch({ control: control, name: "taskType" });

  const onSave: SubmitHandler<StringTextareaInterface> = async (formValues) => {
    // Save button indicator
    setIsSubmitting(true);

    if (props.workType === undefined) throw Error("workType is undefined");

    // If it's an empty string, they didn't input anything. Save value as null.
    const defaultValue =
      formValues.defaultValue.length === 0 ? null : formValues.defaultValue;

    // Drop undefined values from the onTaskStatus list.
    // onTaskStatus itself will be undefined if this is a workType.
    const onTaskStatus = formValues.onTaskStatus?.filter(isDefinedOnTaskStatus);

    // Compose the output structure.
    let fullOutput: StringTextareaCustomField;

    const partialOutput: Omit<
      StringTextareaCustomField,
      "taskType" | "craftRecordOrTask"
    > = {
      ...adminPropDefaultsForCustomField(),
      fieldType: "string-textarea",
      defaultValue: defaultValue,
      title: formValues.title,
      editable: formValues.editable,
      required: formValues.required,
      min: formValues.min,
      max: formValues.max,
      craftType: props.workType,
    };

    // If taskType is 0, they selected "None". This means it's a craftRecord, and
    // we don't want to save the taskType to the database.
    if (formValues.taskType === 0) {
      fullOutput = {
        ...partialOutput,
        craftRecordOrTask: "craftRecord",
        hideOnCraftRecordCreation: formValues.hideOnCraftRecordCreation,
      };
    } else {
      fullOutput = {
        ...partialOutput,
        craftRecordOrTask: "task",
        taskType: formValues.taskType,
        onTaskStatus: onTaskStatus,
      };
    }

    // Validate CustomFieldDocData
    const validatedOutput = CustomFieldManager.parse(fullOutput);

    try {
      await props.handleSave(validatedOutput);

      setIsSubmitting(false);

      // Close dialog if successful.
      props.closeDialog();
    } catch (e) {
      setIsSubmitting(false);
      setDisplayError(true);
      devLogger.error(e);
    }
  };

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <form
      className="contents"
      autoComplete="off"
      onSubmit={handleSubmit(onSave)}
    >
      <hr className="mt-4 w-full border border-dashed border-gray-300 sm:col-span-2 lg:col-span-3" />
      <TaskTypeField
        control={control}
        errors={errors}
        workType={props.workType}
      />

      <TitleField control={control} errors={errors} />

      {/* Specific to fieldType string-textarea */}
      <StringTextareaDefaultValueField control={control} errors={errors} />
      <MinField control={control} errors={errors} isStringBased />
      <MaxField control={control} errors={errors} isStringBased />

      <EditableAndRequiredFields control={control} />

      {watchTaskType === 0 && <HideOnCRCreationField control={control} />}

      {watchTaskType != null &&
        watchTaskType !== 0 &&
        Object.values(OTaskTypes).includes(watchTaskType) && (
          <OnTaskStatusField
            control={control}
            taskStatusList={props.taskStatusList}
          />
        )}

      <span className="-my-5 h-10 justify-self-center sm:col-span-2 lg:col-span-3">
        {displayError ? errorMessage : null}
      </span>

      <ActionButtons submitting={isSubmitting} onCancel={props.closeDialog} />
    </form>
  );
};
