import { FieldValues } from "react-hook-form";

// Local
import { ExistingCustomField } from "../../models/custom-field";

/**
 * drops unchanged responses if it's an optional field. keeps all responses
 * that are required, regardless of if there's a value present or not.
 *
 * tested ✅
 */
export function dropUnchangedOptionalFields(args: {
  dirtyFields: Partial<Readonly<Record<string, any>>>;
  formValues: FieldValues;
  customFields: ExistingCustomField[];
}): FieldValues {
  const dirtyKeys = Object.keys(args.formValues).filter(
    (key) => args.dirtyFields[key],
  );

  return Object.entries(args.formValues).reduce((acc, [key, value]) => {
    // keep responses that are in dirtyKeys list
    if (dirtyKeys.includes(key)) {
      acc[key] = value;
    }

    // keep responses for required fields
    for (let i = 0; i < args.customFields.length; i++) {
      const cf = args.customFields[i];

      // hours-minutes special case --
      const isHoursKeyMatch = `${cf.id}_hoursValue` === key;
      const isMinutesKeyMatch = `${cf.id}_minutesValue` === key;

      if (
        cf.required &&
        (cf.id === key || isHoursKeyMatch || isMinutesKeyMatch)
      ) {
        acc[key] = value;
      }
    }
    return acc;
  }, {} as FieldValues);
}
