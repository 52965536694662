//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { guardIsPlainObject } from "../utils";
import { ExistingEstimate } from "./estimate";
import { DbRead } from "../database";

export interface CommissionAdjustment {
  // negative or positive number that will increase or decrease a technician's
  // commissions paid.
  amount: number;
  // The date that this adjustment is to be for, for payroll and reporting purposes.
  // For example, if today is Monday and we are running payroll and we need to make
  // and adjustment that shows up for the payroll being run today, we would want to
  // back-date this to last week so that it affects the payroll I'm running now.
  timestampApplied: Timestamp;
  // If this adjustment is in reference to a specific estimate or task, allow the user to
  // document it here. This will allow the app to display the adjustment inline with
  // the other commissions when technicians and users view the estimate/task.
  estimateID?: string;
  taskID?: string;
  // The user for which this adjustment shall apply. Limited to one user.
  appliedToUserID: string;
  // Notes about this specific adjustment
  memo: string;
  deleted: boolean;

  timestampCreated: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
  timestampLastModified: Timestamp;
}

export interface ExistingCommissionAdjustment extends CommissionAdjustment {
  id: string;
  refPath: string;
}

export type CreateCommissionAdjustment = Omit<
  CommissionAdjustment,
  | "timestampCreated"
  | "timestampLastModified"
  | "deleted"
  | "createdBy"
  | "lastModifiedBy"
>;

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  CommissionAdjustment,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface CommissionAdjustment_CreateAPI {
  amount: number;
  timestampApplied: string;
  estimateID?: string;
  taskID?: string;
  appliedToUserID: string;
  memo: string;
  siteKey: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type CommissionAdjustment_UpdateAPI = Omit<
  Partial<ExistingCommissionAdjustment>,
  "timestampCreated" | "timestampLastModified" | "timestampApplied"
> & { id: string; timestampApplied: string };

// #region SECTION: Functions
/** Utilities for interacting with CommissionAdjustment objects  */
export const CommissionAdjustmentManager = {
  /**
   * Convert document snapshot into a validated ExistingCommissionAdjustment object.
   */
  createFromFirestoreSnapshot: createCommissionAdjustmentFromFirestoreSnapshot,
  parseCreate: validateCommissionAdjustment_Create,
  parseUpdate: validateCommissionAdjustment_Update,
};

/**
 * Convert the Document Snapshot into a validated ExistingPriceBookItem object.
 */
function createCommissionAdjustmentFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingCommissionAdjustment {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateCommissionAdjustmentWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateCommissionAdjustmentWithFallbacks(
  value: unknown,
): CommissionAdjustment {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = commissionAdjustmentSchemaWithFallbacks.parse(value);
  return result;
}

function validateCommissionAdjustment_Create(
  value: unknown,
): CommissionAdjustment_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return commissionAdjustmentSchema_CreateAPI.parse(value);
}

function validateCommissionAdjustment_Update(
  value: unknown,
): CommissionAdjustment_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return commissionAdjustmentSchema_UpdateAPI.parse(value);
}

// #endregion

// #region SECTION: Schemas
// Used when validating data coming from the database.
const commissionAdjustmentSchemaWithFallbacks = z.object({
  amount: z.number(),
  timestampApplied: z.instanceof(Timestamp),
  estimateID: z.string().optional(),
  taskID: z.string().optional(),
  appliedToUserID: z.string(),
  memo: z.string(),
  timestampCreated: z.instanceof(Timestamp),
  createdBy: z.string(),
  lastModifiedBy: z.string(),
  timestampLastModified: z.instanceof(Timestamp),
  deleted: z.boolean(),
});

// Used when writing to the DB or reading from the user.
const commissionAdjustmentSchema = z.object({
  amount: z.number(),
  timestampApplied: z.instanceof(Timestamp),
  estimateID: z.string().optional(),
  taskID: z.string().optional(),
  appliedToUserID: z.string(),
  memo: z.string(),
  timestampCreated: z.instanceof(Timestamp),
  createdBy: z.string(),
  lastModifiedBy: z.string(),
  timestampLastModified: z.instanceof(Timestamp),
  deleted: z.boolean(),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = commissionAdjustmentSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const commissionAdjustmentSchema_CreateAPI = z.object({
  siteKey: z.string().min(1).max(400),
  amount: z.number(),
  timestampApplied: z.string().min(1).max(400),
  estimateID: z.string().optional(),
  taskID: z.string().optional(),
  appliedToUserID: z.string(),
  memo: z.string(),
});

// Used for interacting with the Update endpoint
const commissionAdjustmentSchema_UpdateAPI = withoutTimestampsSchema
  .partial()
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
    timestampApplied: z.string().min(1).max(400),
  });

export const existingCommissionAdjustmentSchema =
  commissionAdjustmentSchema.extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  });
