// Libs
import { DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

// Local
import { siteKeySchema } from "./site-key";
import { guardIsPlainObject } from "../utils";
import { NotFoundError } from "../error-classes";

/**
 * Introduced in the create a siteKey cycle. Some default values for siteKeys are
 * stored in documents located in the newSiteConfig collection, at the root level.
 */

// #region SECTION: Schemas
const newSiteConfigSchema_incomplete = siteKeySchema.pick({
  customizations: true,
  validCraftTypes: true,
  validTaskStatusCodes: true,
  validTaskTypes: true,
});

// There is no need to split the schemas into 'new' and 'existing'. Will always be 'existing'.
const newSiteConfigSchema = newSiteConfigSchema_incomplete.extend({
  id: z.string(),
  refPath: z.string(),
  kpiConfig: z.record(z.any()),
  templateName: z.string().min(1).max(200),
});
// #endregion

// Inferred type.
export type NewSiteConfigState = z.infer<typeof newSiteConfigSchema>;

// #region SECTION: Functions
/** Utilites for interacting with NewSiteConfig objects. */
export const NewSiteConfigManager = {
  /**
   * Convert the Document Snapshot into a validated NewSiteConfig object.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestore,
  /**
   * Validate a NewSiteConfig doc. Using this in the tests, unsure if it will ever
   * need to be used elsewhere.
   */
  parse: validateNewSiteConfig,
};

function createFromFirestore(snapshot: DocumentSnapshot): NewSiteConfigState {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapshotData = {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...snapshot.data(),
  };
  return validateNewSiteConfig(snapshotData);
}

function validateNewSiteConfig(value: unknown): NewSiteConfigState {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return newSiteConfigSchema.parse(value);
}
// #endregion
