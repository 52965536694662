//Libs
import { useMutation } from "react-query";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { DocumentData } from "firebase/firestore";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Sync from "@mui/icons-material/Sync";

//Local
import { DbRead, DbWrite, timestampNow } from "../../database";
import * as strings from "../../strings";
import PriceBookItemListPage from "./PriceBookItemListPage";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { useAuthStore } from "../../store/firebase-auth";
import { logger } from "../../logging";
import {
  AddNewPBItem,
  ExistingPriceBookItem,
  PBItem_CreateAPI,
  PBItem_UpdateAPI,
  PriceBookItemManager,
} from "../../models/price-book-item";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import { diffObjects } from "../../assets/js/object-diff";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { useTypesenseStore } from "../../store/typesense";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import AddEditPriceBookItemDialog from "../../components/estimates/AddEditPriceBookItemDialog";
import { PRICEBOOK_CATEGORIES_URL } from "../../urls";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { Column, Workbook } from "exceljs";
import { flattenObj } from "../../assets/js/flatten";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ExistingPriceBookItemCategory } from "../../models/price-book-item-category";
import { saveAs } from "file-saver";
import { StyledTooltip } from "../../components/StyledTooltip";
import LoadingSpinner from "../../components/LoadingSpinner";

interface Props {
  siteKey: string;
}

export default function PriceBookItemListContainer({ siteKey }: Props) {
  const navigate = useNavigate();

  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const [siteKeyLocationList, getLocationTitle] = useSiteKeyLocationsStore(
    (state) => [state.siteKeyLocationList, state.getLocationTitle],
  );

  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [priceBookItemCategories, setPriceBookItemCategories] = useState<
    ExistingPriceBookItemCategory[]
  >([]);
  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  /* USE STATES */
  const [addEditPBItemDialogOpen, setAddEditPBItemDialogOpen] =
    useState<boolean>(false);
  const [pBItemDoc, setPBItemDoc] = useState<ExistingPriceBookItem | null>(
    null,
  );
  const [pbItemsFromTypesense, setPbItemsFromTypesense] = useState<
    ExistingPriceBookItem[]
  >([]);
  const [isBusy, setIsBusy] = useState<"sync" | "export" | null>(null);

  useEffect(() => {
    async function getPBItems() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );
      setPbItemsFromTypesense(pbItems);
    }

    getPBItems();
  }, [typesenseSearchKey]);

  /* USE EFFECTS */
  useEffect(() => {
    if (siteKey == null) {
      logger.error("siteKey is null");
      return undefined;
    }
    function getCategories() {
      // Subscribe to all priceBookItemCategories
      const unsubscribe = DbRead.priceBookItemCategories.subscribeAll({
        siteKey: siteKey,
        onChange: setPriceBookItemCategories,
        onError: (error) => {
          logger.error(
            "Error while subscribing to priceBookItemCategories",
            error,
          );
        },
      });
      return unsubscribe;
    }
    const unsubscribe = getCategories();
    return () => {
      unsubscribe();
    };
  }, [siteKey]);

  const onFilterTextBoxChanged = useCallback(async () => {
    if (!typesenseSearchKey) return;
    const searchTerm = (
      document.getElementById("filter-text-box") as HTMLInputElement
    ).value;

    const pbItems = await typesensePriceBookItemsQuery(
      typesenseSearchKey,
      searchTerm,
    );
    setPbItemsFromTypesense(pbItems);
  }, [typesenseSearchKey]);

  /* MUTATIONS */
  /**
   * For adding a price book item on DB
   */
  const mutateAddPBItem = useMutation(
    async (args: { validPBItem: PBItem_CreateAPI }) => {
      await DbWrite.priceBookItems.create(args.validPBItem);
    },
  );

  /**
   * For edit an existing price book item on the DB
   */
  const mutateEditPBItem = useMutation(
    async (args: { editPBItem: PBItem_UpdateAPI }) => {
      await DbWrite.priceBookItems.update(args.editPBItem);
    },
  );

  /* FUNCTIONS */
  async function handleSaveNewPBItem(formValues: AddNewPBItem) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    const newPBItem: PBItem_CreateAPI = {
      ...formValues,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKey,
    };

    //Validate
    const validatedPBItem = PriceBookItemManager.parseCreate(newPBItem);
    logger.info("Validated Price Book Item:", validatedPBItem);

    //DB
    try {
      await mutateAddPBItem.mutateAsync({
        validPBItem: validatedPBItem,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(validatedPBItem.title),
        dialog: false,
        type: "success",
      });

      /* because typesense doesn't call the db everytime a new item is added, we add a temporary version client side, user must refresh to get real data */
      const { siteKey, ...rest } = validatedPBItem;
      const randomID = DbRead.randomDocID.get();

      const tempNewItem: ExistingPriceBookItem = {
        ...rest,
        timestampCreated: timestampNow(),
        timestampLastModified: timestampNow(),
        id: randomID,
        refPath: `siteKeys/${siteKey}/customers/${randomID}`,
      };

      setPbItemsFromTypesense((prevValues) => [...prevValues, tempNewItem]);
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewPBItem`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleEditPBItem(
    updatePBItem: Partial<ExistingPriceBookItem>,
  ) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (pBItemDoc == null) {
      return;
    }

    updatePBItem["lastModifiedBy"] = firebaseUser.uid;

    const diffPBItemValues: DocumentData = diffObjects(
      pBItemDoc,
      updatePBItem,
    ).diff;

    if (Object.keys(diffPBItemValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    diffPBItemValues["id"] = pBItemDoc.id;
    diffPBItemValues["refPath"] = pBItemDoc.refPath;

    /* validate values from the form */
    const validateEditPBItem =
      PriceBookItemManager.parseUpdate(diffPBItemValues);

    try {
      await mutateEditPBItem.mutateAsync({
        editPBItem: validateEditPBItem,
      });
      logger.debug("Pricebook item has been updated successfully.");
      console.log("updating a pricebook item?");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(pBItemDoc.title),
        dialog: false,
        type: "success",
      });

      /* because typesense doesn't call the db everytime an item is added, we add a temporary version client side, user must refresh to get real data */

      const newPbItemsFromTypesense = [...pbItemsFromTypesense];

      const editedItem: ExistingPriceBookItem = {
        ...pBItemDoc,
        ...validateEditPBItem,
      };

      const editedpBItemIndex = newPbItemsFromTypesense.findIndex(
        (pbItem) => pbItem.id === pBItemDoc.id,
      );
      newPbItemsFromTypesense[editedpBItemIndex] = editedItem;

      setPbItemsFromTypesense(newPbItemsFromTypesense);
    } catch (error) {
      logger.error(`An error occurred during handleEditPBItem`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleDeletePBItem(pbItemID: string) {
    if (pBItemDoc == null) {
      return;
    }
    const title = pBItemDoc.title;

    try {
      logger.debug(`Deleting pricebook item with ID: ${pbItemID}`);

      await DbWrite.priceBookItems.delete(siteKey, pbItemID);
      setAddEditPBItemDialogOpen(false);
      setPBItemDoc(null);
      logger.debug("Pricebook item deleted successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulDelete(title),
        dialog: false,
        type: "success",
      });

      /* because typesense doesn't call the db everytime an item is added, we add a temporary version client side, user must refresh to get real data */
      const newPbItemsFromTypesense = [...pbItemsFromTypesense];

      const deletedItemIndex = newPbItemsFromTypesense.findIndex(
        (pbItem) => pbItem.id === pbItemID,
      );
      newPbItemsFromTypesense.splice(deletedItemIndex, 1);

      setPbItemsFromTypesense(newPbItemsFromTypesense);
    } catch (error) {
      logger.error(`An error occurred during handleDeletePBItem`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  function openEditPBItemDialog(priceBookItem: ExistingPriceBookItem) {
    setPBItemDoc(priceBookItem);
    setAddEditPBItemDialogOpen(true);
  }

  /**
   * Build an xlsx spreadsheet.
   */
  async function saveSpreadSheet() {
    setIsBusy("export");

    const allPriceBookItems = await DbRead.priceBookItems.getAll(siteKey);
    const fileName = strings.PRICEBOOK;

    const columns = [
      {
        key: "title",
        header: "Title",
      },
      {
        key: "sku",
        header: "SKU",
      },
      {
        key: "description",
        header: "Description",
      },
      {
        key: "category",
        header: "Category",
      },
      {
        key: "units",
        header: "Units",
      },
      {
        key: "unitPrice",
        header: "Unit Price",
      },
      {
        key: "cost",
        header: "Cost",
      },
      {
        key: "type",
        header: "Type",
      },
      {
        key: "tags",
        header: "Tags",
      },
      {
        key: "taxable",
        header: "Taxable",
      },
      {
        key: "discountable",
        header: "Discountable",
      },
      {
        key: "timestampCreated",
        header: "Timestamp Created",
      },
      {
        key: "createdBy",
        header: "Created By",
      },
      {
        key: "timestampLastModified",
        header: "Timestamp Last Modified",
      },
    ] as Column[];

    const wb = new Workbook();
    const sheet = wb.addWorksheet(fileName);

    sheet.columns = columns;

    // Map of membershipTemplate id to membershipTemplate title
    const categoryTitlesMap: Record<string, string> = {};
    priceBookItemCategories.forEach((category) => {
      categoryTitlesMap[category.id] = category.name;
    });

    const formattedData = await formatDataForExcelExport(
      allPriceBookItems,
      categoryTitlesMap,
      userDisplayNamesMap,
      siteKey,
    );

    sheet.addRows(formattedData);

    // Create title which will be Price Book - todaysDate
    const customTitle = `${fileName} - ${new Date().toLocaleDateString()}`;
    sheet.insertRow(1, [customTitle]);

    // updated the font for first row.
    sheet.getRow(1).font = { bold: true };
    sheet.getRow(2).font = { bold: true };

    try {
      // write the content using writeBuffer
      const buf = await wb.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${customTitle}.xlsx`);
    } catch (error) {
      addMessage({
        id: createToastMessageID(),
        message: strings.GENERIC_ERROR_MESSAGE,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsBusy(null);
      // removing worksheet's instance to create new one
      wb.removeWorksheet(fileName);
    }
  }

  async function syncLightspeed() {
    setIsBusy("sync");
    try {
      await DbWrite.priceBookItems.syncLightspeed(siteKey);
      addMessage({
        id: createToastMessageID(),
        message: strings.LIGHTSPEED_SYNC_SUCCESS,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error("syncLightspeed", e);
      addMessage({
        id: createToastMessageID(),
        message: strings.LIGHTSPEED_SYNC_ERR,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsBusy(null);
    }
  }

  /* COMPONENTS */
  const addNewPriceBookItemButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => setAddEditPBItemDialogOpen(true)}
      className="w-full text-primary sm:w-auto"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.ADD_NEW_PRICEBOOKITEM}
    </BaseButtonPrimary>
  );

  const excelExportButton = (
    <StyledTooltip title={`Export to Excel`}>
      <button
        onClick={saveSpreadSheet}
        className="h-10 rounded-md bg-gray-200 p-2 transition-colors hover:bg-gray-300"
        disabled={isBusy === "export"}
      >
        {isBusy === "export" ? (
          <LoadingSpinner marginClass="m-0.5" />
        ) : (
          <FileDownloadIcon fontSize="medium" className="shrink-0" />
        )}
      </button>
    </StyledTooltip>
  );

  const syncLightspeedButton = siteKeyDoc?.customizations.accounting
    ?.lightspeedKey && (
    <StyledTooltip title="Pull From Lightspeed (this will take a few minutes)">
      <button
        onClick={syncLightspeed}
        className="h-10 rounded-md bg-gray-200 p-2 transition-colors hover:bg-gray-300"
        disabled={isBusy === "sync"}
      >
        {isBusy === "sync" ? (
          <LoadingSpinner marginClass="m-0.5" />
        ) : (
          <Sync fontSize="medium" className="shrink-0" />
        )}
      </button>
    </StyledTooltip>
  );

  const addEditPriceBookItemCategoriesButton = (
    <BaseButtonSecondary
      type="button"
      onClick={() => navigate(PRICEBOOK_CATEGORIES_URL)}
      className="w-full text-primary sm:w-auto"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.ADD_EDIT_PRICEBOOKITEM_CATEGORIES}
    </BaseButtonSecondary>
  );

  const addEditPBItemDialog = userPermissions && (
    <AddEditPriceBookItemDialog
      isDialogOpen={addEditPBItemDialogOpen}
      siteKeyLocationList={siteKeyLocationList}
      closeDialog={() => {
        setAddEditPBItemDialogOpen(false);
        setPBItemDoc(null);
      }}
      handleSaveNewPBItem={handleSaveNewPBItem}
      handleEditPBItem={handleEditPBItem}
      handleDeletePBItem={handleDeletePBItem}
      userPermissions={userPermissions}
      allowEditingAccountNumbers={
        !siteKeyDoc?.customizations?.accounting?.codatConnectionID
      }
      inventoryEnabled={siteKeyDoc?.customizations.inventoryEnabled ?? false}
      priceBookCategories={priceBookItemCategories}
      allowEditingCommissions={siteKeyDoc?.customizations.commissions}
      priceBookItemDoc={pBItemDoc}
    />
  );

  /* RENDER LOADING */
  if (typesenseLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <Fragment>
      <PriceBookItemListPage
        priceBookItemList={pbItemsFromTypesense}
        priceBookItemCategories={priceBookItemCategories}
        addNewPriceBookItemButton={addNewPriceBookItemButton}
        addEditPriceBookItemCategoriesButton={
          addEditPriceBookItemCategoriesButton
        }
        exportToExcelButton={excelExportButton}
        syncLightspeedButton={syncLightspeedButton}
        onEditPBItem={openEditPBItemDialog}
        onSearch={onFilterTextBoxChanged}
        currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
        getLocationTitle={getLocationTitle}
        siteKeyLocationList={siteKeyLocationList}
      />
      {addEditPBItemDialog}
    </Fragment>
  );
}

async function formatDataForExcelExport(
  priceBookItems: ExistingPriceBookItem[],
  categoryTitlesMap: Record<string, string>,
  userDisplayNamesMap: Record<string, string>,
  _siteKey: string,
): Promise<Record<string, any>[]> {
  const result: Record<string, any>[] = [];
  for (const item of priceBookItems) {
    const data = {
      ...flattenObj(item),
      sku: typeof item.customData.sku === "string" ? item.customData.sku : "",
      tags: item.tags ? item.tags.join(", ") : "",
      createdBy: userDisplayNamesMap[item.createdBy],
      category: item.categoryID ? categoryTitlesMap[item.categoryID] : "",
      timestampCreated: item.timestampCreated
        ? convertToReadableTimestamp(item.timestampCreated)
        : "",
      timestampLastModified: item.timestampLastModified
        ? convertToReadableTimestamp(item.timestampLastModified)
        : "",
    };
    result.push(data);
  }

  return result;
}
