// Local
import { ComplianceItemCommon } from "./compliance-item";
import { ExistingComplianceRequirement } from "./compliance-requirement";
import { ExistingComplianceResponse } from "./compliance-response";

// #region Compliance types and statuses
export const complianceRequirementTypes = ["document"] as const;
export type ComplianceRequirementTypes =
  (typeof complianceRequirementTypes)[number];

export const complianceResponseStatuses = [
  "awaiting approval",
  "approved",
  "rejected",
] as const;
export type ComplianceResponseStatuses =
  (typeof complianceResponseStatuses)[number];

export function isComplianceResponseStatus(
  value: unknown,
): value is ComplianceResponseStatuses {
  return complianceResponseStatuses.includes(value as any);
}
// #endregion Compliance types and statuses

export type ComplianceCompany = {
  siteKeyCompanyID: string;
  name: string;
  address: string;
  logo: string;
  status: ComplianceResponseStatuses;
  responses: ExistingComplianceResponse[];
  requirements: ExistingComplianceRequirement[];
};

export type ComplianceSummary = {
  id: ExistingComplianceRequirement["id"];
  title: string;
  totalApproved: number;
  description: string;
  customFields: Record<string, ComplianceItemCommon>[];
};
