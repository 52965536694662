//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import { ExistingEstimate } from "../../models/estimate";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import CustomerEstimateListTable from "../customers/CustomerEstimateListTable";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { ExistingEstimateItem } from "../../models/estimate-item";
import { ICellRendererParams } from "ag-grid-community";
import { ExistingStiltInvoice } from "../../models/invoice";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  estimateListForTable: ExistingEstimate[];
  customerLocations: ExistingCustomerLocation[];
  estimateItemList: ExistingEstimateItem[];
  invoiceList: ExistingStiltInvoice[];
  currency: string;
  handleGoToViewEstimate: (estimate: ExistingEstimate) => void;
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  children: {
    ViewEstimateDialog: React.ReactNode;
    ViewInvoiceDialog: React.ReactNode;
    HandlePaymentDialog: React.ReactNode;
    ConfirmationDialog: React.ReactNode;
    InvoiceListForWRDialog: React.ReactNode;
    HandleSendReceiptDialog: React.ReactNode;
  };
}

export default function EstimateListDialog(props: Props) {
  return (
    <BaseModal
      open={props.isOpen}
      closeModal={props.onClose}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.ESTIMATES}</h1>
          <button
            onClick={props.onClose}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left max-w-md md:max-w-4xl"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg ">
        <CustomerEstimateListTable
          estimateTableData={props.estimateListForTable}
          customerLocations={props.customerLocations}
          estimateItemList={props.estimateItemList}
          goToViewEstimate={props.handleGoToViewEstimate}
          renderIconCell={props.renderIconCell}
          invoiceList={props.invoiceList}
          currency={props.currency}
        />
        <div className="flex flex-col justify-between gap-4 sm:flex-row-reverse">
          <BaseButtonSecondary
            className="w-full sm:max-w-xs"
            onClick={() => props.onClose()}
          >
            Close
          </BaseButtonSecondary>
        </div>
      </div>
      {props.children.ViewEstimateDialog}
      {props.children.HandlePaymentDialog}
      {props.children.ViewInvoiceDialog}
      {props.children.ConfirmationDialog}
      {props.children.InvoiceListForWRDialog}
      {props.children.HandleSendReceiptDialog}
    </BaseModal>
  );
}
