//Libs

//Local
import SiteDetails, {
  SiteDetailsMapProps,
} from "../../components/admin/SiteDetails";

interface Props {
  siteDetails: SiteDetailsMapProps;

  children: {
    Breadcrumbs: React.ReactNode;
    SiteKeyDropdown: React.ReactNode;
    WorkTypeCards: React.ReactNode;
    DisplayResponseMessage: React.ReactNode;
  };
}

export default function SiteDetailsPage(args: Props) {
  return (
    <div className="mx-auto w-full px-2 pb-16 sm:max-w-screen-md lg:max-w-screen-xl">
      <div className="flex flex-col xs:flex-row xs:items-center xs:justify-between sm:flex-col lg:flex-row">
        <span className="sm:self-start">{args.children.Breadcrumbs}</span>
        <span className="z-10 xs:self-center sm:self-end lg:self-center">
          {args.children.SiteKeyDropdown}
        </span>
      </div>
      <SiteDetails {...args.siteDetails}>
        {{
          DisplayResponseMessage: args.children.DisplayResponseMessage,
        }}
      </SiteDetails>
      <div className="grid grid-cols-1 justify-center gap-5 xs:grid-cols-2 xs:gap-7 lg:grid-cols-4">
        {args.children.WorkTypeCards}
      </div>
    </div>
  );
}
