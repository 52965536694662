/**
 * Shorten a possibly long string to chosen character limit,
 * appended with an ellipses. Ellipses not included in
 * character count.
 * @param str String to truncate
 * @param charCount Number of characters the returned string will be
 * @returns String with length of charCount+3 (ellipses). If
 * input string is shorter than passed charCount, original
 * string is returned.
 */
export default function truncateStringToChosenCharacterCount(
  str: string,
  charCount: number,
): string {
  if (str.length >= charCount && charCount > 0) {
    const newString = str.substring(0, charCount);
    return `${newString}...`;
  } else {
    return str;
  }
}
