//Libs
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getDownloadURL,
  getStorage,
  ref,
  StorageError,
  uploadBytesResumable,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import Fuse from "fuse.js";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

//Local
import * as strings from "../../strings";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  ComplianceCompany,
  ComplianceResponseStatuses,
  ComplianceSummary,
} from "../../models/compliance-types-and-statuses";
import { useRootUserSiteKeyListStore } from "../../store/root-user-site-key-list";
import { useSiteKeyCompaniesStore } from "../../store/site-key-companies";
import CompliancePage from "./CompliancePage";
import AddNewRequirementDialog from "../../components/compliance/AddNewRequirementDialog/AddNewRequirementDialog";
import {
  ComplianceRequirement,
  ComplianceRequirement_CreateAPI,
  ComplianceRequirementManager,
  ExistingComplianceRequirement,
  ComplianceRequirement_DeleteAPI,
} from "../../models/compliance-requirement";
import { logger } from "../../logging";
import { DbRead, DbWrite } from "../../database";
import { useAuthStore } from "../../store/firebase-auth";
import { useUserPermissionsStore } from "../../store/user-permissions";
import {
  ComplianceResponseManager,
  ComplianceResponse_CreateAPI,
  ComplianceResponse_DeleteAPI,
  ComplianceResponse_ReviewAPI,
  ExistingComplianceResponse,
} from "../../models/compliance-response";
import {
  CFAnswersState,
  ComplianceResponseDialog,
} from "../../components/compliance/ComplianceResponseDialog";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import {
  AttachmentManager,
  Attachment_CreateAPI,
} from "../../models/attachment";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import CompanyCard from "../../components/compliance/CompanyCard";
import CompanyDashboard from "../../components/compliance/CompanyDashboard";
import MainDashboard from "../../components/compliance/MainDashboard";
import { getSortedComplianceItemList } from "../../assets/js/getSortedComplianceItemList";
import { ComplianceItemCommon } from "../../models/compliance-item";
import CompanySearchBox from "../../components/compliance/CompanySearchBox";
import ComplianceResponsesAccordionList from "../../components/compliance/ComplianceResponsesAccordionList";
import ReviewCommentDialog from "../../components/compliance/ReviewCommentDialog";
import { logger as devLogger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import ComplianceRequirementRangesDialog from "../../components/compliance/ComplianceRequirementRangesDialog";
import StyledMessage from "../../components/StyledMessage";

/** For creating a response */
export type FieldsFromRequirement = Pick<
  ComplianceResponse_CreateAPI,
  "requirementID" | "type" | "title" | "description" | "customFields"
>;

const storage = getStorage();

interface Props {
  siteKey: string;
}

export default function ComplianceContainer({ siteKey }: Props) {
  //#region STORE DATA, useState, react-query stuff
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const listOfCompanies = useSiteKeyCompaniesStore(
    (state) => state.siteKeyCompanies,
  );

  const rootUserSiteKeyList = useRootUserSiteKeyListStore(
    (state) => state.rootUserSiteKeyList,
  );

  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  const queryClient = useQueryClient();

  const requirementsQueryKey = [
    "complianceContainer_requirementDocList",
    siteKey,
    userPermissions,
  ];
  const { data: requirementList = [], isLoading: isLoadingRequirementList } =
    useQuery(requirementsQueryKey, () => {
      if (userPermissions == null) {
        logger.debug("no userPermissions yet");
        return undefined;
      } else {
        return DbRead.complianceRequirements.getAll(siteKey, userPermissions);
      }
    });

  const responsesQueryKey = [
    "complianceContainer_responseDocList",
    siteKey,
    userPermissions,
  ];
  const { data: responseList = [], isLoading: isLoadingResponseList } =
    useQuery(
      responsesQueryKey,
      () => {
        if (userPermissions == null) {
          logger.info("no userPermissions yet");
          return undefined;
        } else {
          return DbRead.complianceResponses.getAll(siteKey, userPermissions);
        }
      },
      { enabled: !!userPermissions },
    );

  // Use the responseList doc IDs to find the associated attachments.
  const responseIDs = useMemo(() => {
    return responseList.map((response) => response.id);
  }, [responseList]);

  const attachmentsQueryKey = [
    "complianceContainer_attachmentList",
    siteKey,
    userPermissions,
    responseIDs,
  ];
  const { data: attachmentList = [], isLoading: isLoadingAttachmentList } =
    useQuery(
      attachmentsQueryKey,
      () => {
        if (userPermissions == null) {
          logger.info("no userPermissions yet");
          return undefined;
        } else {
          return DbRead.attachments.getAllForCompliance(
            siteKey,
            userPermissions,
            responseIDs,
          );
        }
      },
      { enabled: !!userPermissions && !!responseList, keepPreviousData: true },
    );

  const [addNewRequirementDialogOpen, setAddNewRequirementDialogOpen] =
    useState<boolean>(false);

  const [isResponseDialogOpen, setIsResponseDialogOpen] = useState(false);

  const [isRequirementRangesDialogOpen, setIsRequirementRangesDialogOpen] =
    useState<boolean>(false);
  const [selectedRequirement, setSelectedRequirement] =
    useState<ExistingComplianceRequirement | null>(null);

  /* Company user permission: can't see the main dashboard */
  const isCompanyUser =
    userPermissions?.permissions.complianceResponses_readAll === false;

  const [selectedCompanyID, setSelectedCompanyID] = useState<
    ComplianceCompany["siteKeyCompanyID"] | null
  >(null);

  const [rejectedResponseID, setRejectedResponseID] = useState<string | null>(
    null,
  );

  const [isApprovingID, setIsApprovingID] = useState<string | null>(null);
  const [isRejectingID, setIsRejectingID] = useState<string | null>(null);

  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  const [searchFilter, setSearchFilter] = useState<
    Fuse.FuseResult<Record<string, any>>[]
  >([]);

  const [addReviewCommentDialogOpen, setAddReviewCommentDialogOpen] =
    useState<boolean>(false);

  const [selectedResponse, setSelectedResponse] = useState<
    ExistingComplianceResponse | undefined
  >(undefined);
  //#endregion

  /**
   * For handling the mobile sidebar. We need to set showMobileSidebar to false if
   * a user increases the viewport width beyond the small breakpoint (640px). If
   * showMobileSidebar was true and we didn't implement something like this, our
   * UI would break.
   */
  useEffect(() => {
    // Can exit early if showMobileSidebar is already false
    if (!showMobileSidebar) return undefined;
    const debouncedResize = debounce(function handleResizedWidth() {
      if (window.innerWidth >= 768) {
        setShowMobileSidebar(false);
      }
    }, 100);
    window.addEventListener("resize", debouncedResize);

    // Clean up old event listener before creating a new one.
    return () => window.removeEventListener("resize", debouncedResize);
  }, [showMobileSidebar]);

  //#region FUNCTIONS
  const complianceCompanyList: ComplianceCompany[] = useMemo(() => {
    const companies: ComplianceCompany[] = listOfCompanies.map((company) => {
      // find the siteKeyDoc for each company
      const siteKeyDoc = rootUserSiteKeyList.find((siteKey) => {
        return siteKey.managingCompanyID === company.id;
      });

      const responsesByCompanyID = responseList.filter(
        (response) => response.siteKeyCompanyID === company.id,
      );

      const totalApproved = responsesByCompanyID.filter(
        (response) => response.status === "approved",
      ).length;
      const isAwaitingApproval = responsesByCompanyID.find(
        (response) => response.status === "awaiting approval",
      );

      //default status set to "awaiting approval"
      function getCompanyStatus(): ComplianceResponseStatuses {
        const allRequirementAnswered =
          requirementList.length === responsesByCompanyID.length;

        if (
          allRequirementAnswered &&
          responsesByCompanyID.length === totalApproved
        ) {
          return "approved";
        } else if (isAwaitingApproval) {
          return "awaiting approval";
        } else {
          return "rejected";
        }
      }
      //TODO: ^ this logic need to be revisited when requirement
      // will not be the same for every company

      // assemble complianceCompanyList object
      return {
        siteKeyCompanyID: company.id,
        name: company.name,
        address: siteKeyDoc?.address ? siteKeyDoc.address : "",
        logo: company.logoPhotoURL ? company.logoPhotoURL : "",
        status: getCompanyStatus(),
        responses: responsesByCompanyID,
        requirements: requirementList,
      };
    });

    // Need to filter out any duplicate companies - which happens when the user
    // is a contractor/company user
    const filteredCompanies = companies.reduce((acc, company) => {
      !acc.find((val) => val.siteKeyCompanyID === company.siteKeyCompanyID) &&
        acc.push(company);
      return acc;
    }, [] as ComplianceCompany[]);

    // sort companies alphabetically
    const sortedCompanies: ComplianceCompany[] = filteredCompanies.sort(
      (a, b) => a.name.localeCompare(b.name),
    );
    return sortedCompanies;
  }, [listOfCompanies, requirementList, rootUserSiteKeyList, responseList]);

  // Compute the selected company when the selected companyID changes or the
  // list of compliance companies changes.
  const selectedCompany: ComplianceCompany | null = useMemo(() => {
    const result = complianceCompanyList.find(
      (company) => company.siteKeyCompanyID === selectedCompanyID,
    );
    return result ?? null;
  }, [complianceCompanyList, selectedCompanyID]);

  const complianceSummaryList = useMemo(() => {
    const complianceSummary: ComplianceSummary[] = requirementList.map(
      (requirement) => {
        //find the responses for each requirement
        const responses = responseList.filter((response) => {
          return response.requirementID === requirement.id;
        });

        // filter the responses that have status "approved"
        const totalApprovedStatus = responses.filter(
          (response) => response.status === "approved",
        );

        // calculate the percentage of the "approved" responses
        const percentageApproved = Math.round(
          (100 * totalApprovedStatus.length) / listOfCompanies.length,
        );

        const sortedComplianceItem: Record<string, ComplianceItemCommon>[] =
          getSortedComplianceItemList(requirement.customFields);

        //assemble compliance summary object
        return {
          id: requirement.id,
          title: requirement.title,
          totalApproved: percentageApproved,
          description: requirement.description,
          customFields: sortedComplianceItem,
        };
      },
    );

    //sort the compliance summary by requirement title
    const sortedComplianceSummary = complianceSummary.sort((a, b) =>
      a.title.localeCompare(b.title),
    );

    return sortedComplianceSummary;
  }, [listOfCompanies.length, requirementList, responseList]);

  /* function that calculate the total of the awaiting approval statuses */
  const totalAwaitingApproval = useMemo(() => {
    const awaitingApproval = complianceCompanyList.filter(
      (company) => company.status === "awaiting approval",
    );
    return awaitingApproval.length;
  }, [complianceCompanyList]);

  /* function that calculate the percentage of the overall compliance */
  const percentageOverallCompliance = useMemo(() => {
    const totalApprovedStatus = complianceCompanyList.filter(
      (company) => company.status === "approved",
    );
    const percentage =
      (100 * totalApprovedStatus.length) / complianceCompanyList.length;
    return Math.round(percentage);
  }, [complianceCompanyList]);

  /** For calling DB and invalidating stale query */
  const mutateAddRequirement = useMutation(
    async (validData: ComplianceRequirement_CreateAPI) => {
      await DbWrite.complianceRequirements.create(validData);
    },
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries(requirementsQueryKey),
    },
  );

  /* choose the company to show depending from isCompanyUser value */
  function getCompanyList(): ComplianceCompany | null {
    if (selectedCompany == null && isCompanyUser === true) {
      return complianceCompanyList[0];
    } else if (selectedCompany != null && isCompanyUser === false) {
      return selectedCompany;
    } else {
      return selectedCompany;
    }
  }

  /** Send a new requirement doc to the DB */
  async function handleSaveNewRequirement(
    formValues: Pick<
      ComplianceRequirement,
      "title" | "description" | "type" | "customFields"
    >,
  ) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return undefined;
    }
    const now = DateTime.now().toISO();

    const requirement: ComplianceRequirement_CreateAPI = {
      siteKey: siteKey,
      timestampCreated: now,
      timestampLastModified: now,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      deleted: false,
      title: formValues.title,
      type: formValues.type,
      description: formValues.description,
      customFields: formValues.customFields,
    };

    // Validate
    const validated = ComplianceRequirementManager.parseCreateAPI(requirement);
    logger.info("Validated requirement:", validated);

    // DB 🔥
    return mutateAddRequirement.mutateAsync(validated);
  }

  /** For calling DB and invalidating stale query */
  const mutateDeleteRequirement = useMutation(
    async (args: {
      validData: ComplianceRequirement_DeleteAPI;
      relatedResponsesToDelete: ExistingComplianceResponse[];
    }) => {
      if (args.relatedResponsesToDelete.length > 0) {
        // Delete requirement
        await DbWrite.complianceRequirements.delete(
          args.validData.siteKey,
          args.validData.requirementID,
        );

        // Delete all associated responses.
        const promises = args.relatedResponsesToDelete.map((response) => {
          return handleDeleteResponse(response.id);
        });
        await Promise.all(promises);
      } else {
        // The requirement has no associated responses.
        await DbWrite.complianceRequirements.delete(
          args.validData.siteKey,
          args.validData.requirementID,
        );
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(requirementsQueryKey);
      },
    },
  );

  /** 'Delete' a compliance requirement. (Set deleted to true) */
  async function handleDeleteRequirement(
    id: ExistingComplianceRequirement["id"],
  ) {
    //if a requirement is deleted, all the related responses need to be deleted too
    const relatedResponsesToDelete = responseList.flatMap((response) => {
      if (response.requirementID === id) {
        return response;
      } else {
        return [];
      }
    });

    const deleteRequirement: ComplianceRequirement_DeleteAPI = {
      siteKey: siteKey,
      requirementID: id,
    };
    // Validate
    const validData =
      ComplianceRequirementManager.parseDeleteAPI(deleteRequirement);
    // DB 🔥
    return mutateDeleteRequirement.mutateAsync({
      validData,
      relatedResponsesToDelete,
    });
  }

  /** For calling DB and invalidating stale query */
  const mutateDeleteResponse = useMutation(
    async (validData: ComplianceResponse_DeleteAPI) => {
      await DbWrite.complianceResponses.delete(
        validData.siteKey,
        validData.responseID,
      );
    },
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries(responsesQueryKey),
    },
  );

  /** 'Delete' a compliance response. (Set deleted to true) */
  async function handleDeleteResponse(id: ExistingComplianceResponse["id"]) {
    const deleteResponse: ComplianceResponse_DeleteAPI = {
      siteKey: siteKey,
      responseID: id,
    };
    // Validate
    const validated = ComplianceResponseManager.parseDeleteAPI(deleteResponse);
    // DB 🔥
    return mutateDeleteResponse.mutateAsync(validated);
  }

  //#region Requirement Ranges Dialog functions
  function handleOpenRequirementRanges(
    requirement: ExistingComplianceRequirement,
    siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"],
  ) {
    setIsRequirementRangesDialogOpen(true);
    setSelectedRequirement(requirement);
    setSelectedCompanyID(siteKeyCompanyID);
  }

  function handleCloseRequirementRanges() {
    setIsRequirementRangesDialogOpen(false);
    setSelectedRequirement(null);
  }
  //#endregion

  // #region Response Dialog functions
  function handleOpenResponseDialog(
    requirement: ExistingComplianceRequirement,
    siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"],
  ) {
    setIsResponseDialogOpen(true);
    setSelectedRequirement(requirement);
    setSelectedCompanyID(siteKeyCompanyID);
  }

  function handleCloseResponseDialog() {
    setIsResponseDialogOpen(false);
    setSelectedRequirement(null);
  }

  function handleResubmitResponse(
    requirement: ExistingComplianceRequirement,
    siteKeyCompanyID: ComplianceCompany["siteKeyCompanyID"],
    idRejectedResponse: ExistingComplianceResponse["id"],
  ) {
    handleOpenResponseDialog(requirement, siteKeyCompanyID);
    setRejectedResponseID(idRejectedResponse);
  }

  /** For calling DB and invalidating stale query */
  const mutateAddResponse = useMutation(
    async (args: {
      validData: ComplianceResponse_CreateAPI;
      docID: string;
    }) => {
      if (rejectedResponseID == null) {
        await DbWrite.complianceResponses.create(args.validData, args.docID);
      } else {
        const promises = [
          DbWrite.complianceResponses.create(args.validData, args.docID),
          handleDeleteResponse(rejectedResponseID),
        ];

        await Promise.all(promises);
        setRejectedResponseID(null);
      }
    },
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries(responsesQueryKey),
    },
  );

  async function handleSubmitResponse(args: {
    formValues: CFAnswersState;
    fieldsFromRequirement: FieldsFromRequirement;
    docID: string;
  }) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return undefined;
    }
    if (selectedCompanyID == null) {
      logger.error("siteKeyCompanyID is null");
      return undefined;
    }
    const now = DateTime.now().toISO();

    const response: ComplianceResponse_CreateAPI = {
      requirementID: args.fieldsFromRequirement.requirementID,
      siteKey: siteKey,
      customFieldsAnswers: args.formValues,
      siteKeyCompanyID: selectedCompanyID,
      status: "awaiting approval",

      type: args.fieldsFromRequirement.type,
      title: args.fieldsFromRequirement.title,
      description: args.fieldsFromRequirement.description,
      customFields: args.fieldsFromRequirement.customFields,

      timestampCreated: now,
      timestampLastModified: now,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      deleted: false,

      reviewComments: null,
      timestampReviewed: null,
      reviewedBy: null,
    };

    // Validate
    const validated = ComplianceResponseManager.parseCreateAPI(response);
    logger.info("Validated response:", validated);

    // DB 🔥
    return mutateAddResponse.mutateAsync({
      validData: validated,
      docID: args.docID,
    });
  }
  // #endregion Response Dialog functions

  /* For calling DB and invalidating stale query */
  const mutateReviewResponse = useMutation(
    async (validData: ComplianceResponse_ReviewAPI) => {
      await DbWrite.complianceResponses.review(validData);
      setIsApprovingID(null);
      setIsRejectingID(null);
    },
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries(responsesQueryKey),
      onError: () => {
        setIsApprovingID(null);
        setIsRejectingID(null);
        addMessage({
          id: createToastMessageID(),
          message: strings.ERR_REVIEW_COMPLIANCE_RESPONSE,
          dialog: false,
          type: "error",
        });
      },
    },
  );

  /* 'Set' review response */
  async function handleReviewResponse(
    responseID: string,
    refPath: string,
    status: "approved" | "rejected",
    reviewComments: string | null,
  ) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return undefined;
    }
    const now = DateTime.now().toISO();

    const partialOutput: Omit<
      ComplianceResponse_ReviewAPI,
      "status" | "reviewComments"
    > = {
      id: responseID,
      refPath,
      lastModifiedBy: firebaseUser.uid,
      reviewedBy: firebaseUser.uid,
      timestampLastModified: now,
      timestampReviewed: now,
    };

    // Typescript complains if we compose the output structure without narrowing
    // the 'status' property first. (Would also complain about 'reviewComments')
    let fullOutput: ComplianceResponse_ReviewAPI;

    if (status === "approved") {
      setIsApprovingID(responseID);
      fullOutput = {
        ...partialOutput,
        status: "approved",
        reviewComments: null,
      };
    } else {
      setIsRejectingID(responseID);
      // This check is necessary to appease Typescript. The 'reviewComments' prop
      // passed to this ƒn is string or null. But if we're in this else block, the
      // user had to leave a comment, meaning reviewComments is a string.
      if (reviewComments === null) return undefined;
      // Just doing this to be super safe. If we're here, status shouldn't be anything
      // other than 'rejected'.
      if (status !== "rejected") return undefined;

      fullOutput = {
        ...partialOutput,
        status: "rejected",
        reviewComments,
      };
    }

    //Validate
    const validated = ComplianceResponseManager.parseReviewAPI(fullOutput);

    // DB 🔥
    return mutateReviewResponse.mutateAsync(validated);
  }

  /* function to get the userDisplayName */
  function getUserDisplayName(uid: string): string {
    return userDisplayNamesMap[uid];
  }

  async function onReviewResponse(
    response: ExistingComplianceResponse,
    status: ComplianceResponseStatuses,
  ) {
    if (status === "approved") {
      await handleReviewResponse(response.id, response.refPath, status, null);
    } else if (status === "rejected") {
      setSelectedResponse(response);
      // open comment dialog
      setAddReviewCommentDialogOpen(true);
    } else {
      devLogger.error("Invalid status", status);
    }
  }

  //#endregion

  // #region SECTION: Attachment Upload Related

  /* For calling DB and invalidating stale query */
  const mutateUploadAttachment = useMutation(
    async (attachmentDoc: Attachment_CreateAPI) => {
      await DbWrite.attachments.createForCompliance(siteKey, attachmentDoc);
    },
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries(attachmentsQueryKey),
    },
  );

  function handleAttachmentUpload(args: {
    file: File;
    responseID: ExistingComplianceResponse["id"];
  }): void {
    // Use a random string as the file's name
    const newFileName = `${uuidv4()}.${args.file.type.split("/")[1]}`;

    // Upload file to the path starting with 'siteKeys'
    const storageRef = ref(
      storage,
      `siteKeys/${siteKey}/attachments/` + newFileName,
    );

    const uploadTask = uploadBytesResumable(storageRef, args.file);

    // Listen for state changes, errors, and completion of the upload.
    const unsubscribe = uploadTask.on("state_changed", {
      // the 'next' callback could give progress reports, ie: '79% done'
      next: null,
      error: handleUploadError,
      complete: onUploadComplete,
    });

    // This only gets called if there is an error.
    function handleUploadError(error: StorageError) {
      switch (error.code) {
        case "storage/unauthorized":
          logger.error(
            "Unauthorized user attempting to upload an attachment. UID:",
            firebaseUser?.uid,
          );
          break;
        case "storage/quota-exceeded":
          logger.error("Quota on Cloud Storage bucket has been exceeded.");
          break;
        case "storage/unknown":
          logger.error("Unknown error occurred, inspect error.serverResponse");
          break;
        default:
          logger.error("Unknown error occurred while uploading attachment");
      }

      // If there has been an error during upload, unsubscribe from uploadTask.on().
      unsubscribe();
    }

    // Upload completed successfully.
    async function onUploadComplete() {
      try {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        const now = DateTime.now().toISO();

        await afterUploadComplete({
          authorizedCompanies: [selectedCompanyID ? selectedCompanyID : ""], // TODO: better way to handle this...
          url: downloadURL,
          filename: newFileName,
          complianceResponseID: args.responseID,
          timestampCreated: now,
          createdBy: firebaseUser?.uid ?? "",
          deleted: false,
        });

        // Unsubscribe from uploadTask.on(), after upload is complete.
        unsubscribe();
      } catch (err) {
        logger.error(
          "Couldn't get download URL, or couldn't add attachment doc to DB ->",
          err,
        );
      }
    }
  }

  async function afterUploadComplete(args: Attachment_CreateAPI) {
    const attachmentDoc = AttachmentManager.parseCreateAPI(args);
    return mutateUploadAttachment.mutateAsync(attachmentDoc);
  }
  // #endregion Attachment Upload Related

  /** Display add requirement button if the user has appropriate permissions. */
  const showAddRequirementButton =
    userPermissions?.permissions.complianceRequirements_create === true;

  /** Display delete requirement button if the user has appropriate permissions. */
  const showDeleteRequirementButton =
    userPermissions?.permissions.complianceRequirements_delete === true;

  /** Display review response button if the user has appropriate permissions. */
  const showReviewResponseButton =
    userPermissions?.permissions.complianceResponses_review === true;

  /** Display delete response button if the user has appropriate permissions. */
  const showDeleteResponseButton =
    userPermissions?.permissions.complianceResponses_delete === true;

  const CompanyListSidebar = (
    <div
      className={
        showMobileSidebar ? "px-4 sm:px-8" : "mt-16 max-h-min min-h-screen"
      }
    >
      <div className="m-4 mb-8">
        <CompanySearchBox
          list={complianceCompanyList}
          searchInput={setSearchFilter}
          placeholder="Company name..."
          keys={["name", "address", "status"]}
        />
      </div>
      <div
        className={`border md:border-0 ${
          showMobileSidebar ? "mb-16 h-auto md:mt-0" : ""
        }`}
      >
        {complianceCompanyList
          .filter((company) => {
            if (searchFilter.length === 0) {
              return company;
            } else {
              return searchFilter.some((companySearched) => {
                return companySearched.item.name === company.name;
              });
            }
          })
          .map((company) => {
            return (
              <CompanyCard
                key={company.siteKeyCompanyID}
                company={company}
                selectedCompanyID={selectedCompany?.siteKeyCompanyID}
                setSelectedCompanyID={setSelectedCompanyID}
              />
            );
          })}
      </div>
    </div>
  );

  const MobileSidebarButton = showMobileSidebar ? (
    <BaseButtonPrimary onClick={() => setShowMobileSidebar(false)}>
      {strings.buttons.HIDE_COMPANIES}
    </BaseButtonPrimary>
  ) : (
    <BaseButtonPrimary onClick={() => setShowMobileSidebar(true)}>
      {strings.buttons.VIEW_COMPANIES}
    </BaseButtonPrimary>
  );

  const goToMainViewButton =
    isCompanyUser === false ? (
      <div className="w-2/3 space-y-6">
        <button
          onClick={() => setSelectedCompanyID(null)}
          className="my-2 mr-5 inline-flex max-w-fit items-center justify-start rounded-sm border border-gray-300 bg-white text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
        >
          <ArrowLeftIcon
            aria-label="go to main view"
            className="mx-5 my-2 h-8 text-primary"
          />
        </button>
      </div>
    ) : null;

  const reviewCommentDialog =
    addReviewCommentDialogOpen === true && selectedResponse != null ? (
      <ReviewCommentDialog
        response={selectedResponse}
        closeDialog={() => setAddReviewCommentDialogOpen(false)}
        isDialogOpen={addReviewCommentDialogOpen}
        handleSaveComment={handleReviewResponse}
      />
    ) : null;

  const complianceAccordionResponseList = (
    <ComplianceResponsesAccordionList
      company={getCompanyList()}
      attachmentList={attachmentList}
      onReviewResponse={onReviewResponse}
      onOpenResponseDialog={handleOpenResponseDialog}
      showDeleteResponseButton={showDeleteResponseButton}
      showReviewResponseButton={showReviewResponseButton}
      getUserDisplayName={getUserDisplayName}
      onDeleteResponse={handleDeleteResponse}
      isApprovingID={isApprovingID}
      isRejectingID={isRejectingID}
      onResubmitResponse={handleResubmitResponse}
      onOpenRequirementRanges={handleOpenRequirementRanges}
    >
      {{
        ReviewCommentDialog: reviewCommentDialog,
      }}
    </ComplianceResponsesAccordionList>
  );

  const companyDashboard = (
    <CompanyDashboard company={getCompanyList()}>
      {{
        goToMainView: goToMainViewButton,
        ComplianceAccordionResponseList: complianceAccordionResponseList,
      }}
    </CompanyDashboard>
  );

  const mainDashboard = (
    <MainDashboard
      complianceSummaryList={complianceSummaryList}
      percentageOverallCompliance={percentageOverallCompliance}
      totalAwaitingApproval={totalAwaitingApproval}
      onAddNewRequirement={() => setAddNewRequirementDialogOpen(true)}
      handleDeleteRequirement={handleDeleteRequirement}
      showDeleteRequirementButton={showDeleteRequirementButton}
      showAddRequirementButton={showAddRequirementButton}
      showMobileSidebar={showMobileSidebar}
    >
      {{
        MobileSidebarButton: MobileSidebarButton,
      }}
    </MainDashboard>
  );

  const cantReadResponses =
    userPermissions?.permissions.complianceResponses_readAll === false &&
    userPermissions?.permissions.complianceResponses_read === false;
  const cantReadRequirements =
    userPermissions?.permissions.complianceRequirements_read === false;

  const insufficientPermissions = cantReadResponses || cantReadRequirements;
  if (insufficientPermissions) {
    return (
      <div className="absolute inset-0 m-auto max-h-max max-w-lg px-3">
        <StyledMessage type="error" dismissible={false}>
          {{ message: strings.INSUFFICIENT_PERMISSIONS }}
        </StyledMessage>
      </div>
    );
  }

  if (
    complianceCompanyList == null ||
    isLoadingRequirementList ||
    isLoadingResponseList ||
    isLoadingAttachmentList
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <>
      <CompliancePage
        isCompanyUser={isCompanyUser}
        selectedCompany={selectedCompany}
        showMobileSidebar={showMobileSidebar}
      >
        {{
          Sidebar: CompanyListSidebar,
          CompanyDashboard: companyDashboard,
          MainDashboard: mainDashboard,
        }}
      </CompliancePage>

      {isRequirementRangesDialogOpen && selectedRequirement != null ? (
        <ComplianceRequirementRangesDialog
          isDialogOpen={isRequirementRangesDialogOpen}
          closeDialog={handleCloseRequirementRanges}
          requirement={selectedRequirement}
        />
      ) : null}

      {isResponseDialogOpen && selectedRequirement != null ? (
        <ComplianceResponseDialog
          isDialogOpen={isResponseDialogOpen}
          closeDialog={handleCloseResponseDialog}
          requirement={selectedRequirement}
          onSubmitResponse={handleSubmitResponse}
          onAttachmentUpload={handleAttachmentUpload}
        />
      ) : null}

      {addNewRequirementDialogOpen === true ? (
        <AddNewRequirementDialog
          closeDialog={() => setAddNewRequirementDialogOpen(false)}
          isDialogOpen={addNewRequirementDialogOpen}
          handleSave={handleSaveNewRequirement}
        />
      ) : null}

      {showMobileSidebar && selectedCompany === null
        ? CompanyListSidebar
        : null}
    </>
  );
}

/** Delay function execution for given function and milliseconds */
// eslint-disable-next-line @typescript-eslint/ban-types
function debounce(fn: Function, ms: number) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}
