import { MouseEvent } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export default function NavButtonBack(): JSX.Element {
  const navigate = useNavigate();

  function handleClick(event: MouseEvent) {
    event.preventDefault();
    navigate(-1);
  }

  return (
    <button
      onClick={handleClick}
      className="my-2 mr-5 inline-flex max-w-fit items-center justify-start rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
    >
      <ArrowLeftIcon
        aria-label="go back button"
        className="mx-5 my-2 h-8 text-primary"
      />
    </button>
  );
}
