// Libs
import { DocumentSnapshot, DocumentData } from "firebase/firestore";
import { z } from "zod";

// Local
import { dropUndefined, guardIsPlainObject } from "../utils";
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";

// #region SECTION: Schemas / Interfaces / Types
export interface SiteKeyUserDoc {
  companyName: string;
  department?: string | null;
  displayName: string;
  email: string;
  jobTitle: string;
  phone: string;
  userPhoto_URL: string | null;
  systemUser?: boolean;
}
export interface ExistingSiteKeyUserDoc extends SiteKeyUserDoc {
  id: string;
  refPath: string;
}

/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
const SiteKeyUserSchema = z.object({
  companyName: z.string().min(1).max(200),
  department: z.string().min(1).max(200).optional().nullable(),
  displayName: z.string().min(1).max(200),
  email: z.string().email(),
  jobTitle: z.string().min(1).max(200),
  phone: z.string().min(1).max(50),
  userPhoto_URL: z.string().min(1).max(2000).nullable(),
  systemUser: z.boolean().optional(),
});
const ExistingSiteKeyUserSchema = SiteKeyUserSchema.extend({
  id: z.string().min(1).max(200).optional(),
  refPath: z.string().min(1).max(2000).optional(),
});

const EditSiteKeyUserDocSchema = ExistingSiteKeyUserSchema.partial();
export type EditSiteKeyUserDocState = z.infer<typeof EditSiteKeyUserDocSchema>;

/** For reading from the database */
const SiteKeyUserSchemaWithFallbacks = z.object({
  companyName: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SiteKeyUserSchemaWithFallbacks: 'companyName'",
  ),
  department: zFallback(
    z.string().min(1).max(200).optional().nullable(),
    null,
    "SiteKeyUserSchemaWithFallbacks: 'department'",
  ),
  displayName: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SiteKeyUserSchemaWithFallbacks: 'displayName'",
  ),
  email: zFallback(
    z.string().email(),
    "",
    "SiteKeyUserSchemaWithFallbacks: 'email'",
  ),
  jobTitle: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SiteKeyUserSchemaWithFallbacks: 'jobTitle'",
  ),
  phone: zFallback(
    z.string().min(1).max(50),
    "unknown",
    "SiteKeyUserSchemaWithFallbacks: 'phone'",
  ),
  userPhoto_URL: zFallback(
    z.string().min(1).max(2000).nullable(),
    null,
    "SiteKeyUserSchemaWithFallbacks: 'userPhoto_URL'",
  ),
  systemUser: zFallback(
    z.boolean().optional(),
    false,
    "siteKeyUser fallback used: 'systemUser'",
  ),
});
// #endregion

// #region SECTION: Functions
/** Utilities for interacting with SiteKeyUser objects. */
export const SiteKeyUsersManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingSiteKeyUserDoc object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromSnapshot: createFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  convertForFirestore,
  /** Validate a SiteKeyUser doc. Use for writing to the database or reading from the user. */
  parse: validateSiteKeyUser,
  /** Validate a SiteKeyUser doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateSiteKeyUserWithFallbacks,
  /** Validation. Use for editing a SiteKeyUser doc. No fallbacks will be used. */
  parseEdit: validateEditSiteKeyUser,
};

/**
 * Convert the Document Snapshot into a validated ExistingSiteKeyUserDoc object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingSiteKeyUserDoc {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateSiteKeyUserWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drops undefined values */
function convertForFirestore(
  siteKeyUsers: ExistingSiteKeyUserDoc,
): DocumentData {
  const localItem = Object.assign({}, siteKeyUsers);
  const { id, refPath, ...rest } = localItem;
  const result = dropUndefined(rest);
  return result;
}

/** Rejects if it doesn't pass validation */
function validateSiteKeyUser(userDoc: unknown): SiteKeyUserDoc {
  if (!guardIsPlainObject(userDoc)) {
    throw new Error(`SiteKeyUserDoc is not an object: ${userDoc}`);
  }

  return SiteKeyUserSchema.parse(userDoc);
}

/** For validating stuff coming in from the database. */
function validateSiteKeyUserWithFallbacks(userDoc: unknown): SiteKeyUserDoc {
  if (!guardIsPlainObject(userDoc)) {
    throw new Error(`SiteKeyUser is not an object: ${userDoc}`);
  }

  return SiteKeyUserSchemaWithFallbacks.parse(userDoc);
}

function validateEditSiteKeyUser(value: unknown): EditSiteKeyUserDocState {
  if (!guardIsPlainObject(value)) {
    throw new Error(`EditSiteKeyUserDoc is not an object: ${value}`);
  }
  const result = EditSiteKeyUserDocSchema.parse(value);
  return result;
}
// #endregion
