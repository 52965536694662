// Libs
import { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Control, Controller, FieldValues } from "react-hook-form";

// Local
import { MultipleUidCustomField } from "../../models/custom-field";
import MultipleUidDialog, {
  IMultipleUid_AssignUser,
} from "./MultipleUidDialog";
import { Json } from "../../models/json-type";

interface Props {
  userList: IMultipleUid_AssignUser[];
  defaultValue: Json;
  handleSubmitMultipleUID: (args: Record<string, string[]>) => void;
  customField: MultipleUidCustomField & { id: string };
  control: Control<FieldValues, any>;
  isRequired: boolean;
}

export default function FieldTypeMultipleUid(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const defaultValue =
    typeof props.defaultValue === "string" ? props.defaultValue : "";
  // display which users are selected when the dialog is closed.
  const [selectedUsersString, setSelectedUsersString] = useState(defaultValue);

  const [updatedUserList, setUpdatedUserList] = useState(props.userList);

  // useEffect(() => {
  //   setUpdatedUserList(props.userList);
  // }, [props.userList]);

  function handleDialogSubmit(
    fieldID: string,
    users: IMultipleUid_AssignUser[],
  ) {
    const selectedList = users.filter((user) => user.isAssigned);
    const userDisplayNameList = selectedList
      .map((user) => user.name)
      .join(", ");
    setSelectedUsersString(userDisplayNameList);
    setUpdatedUserList(users);
    const userIdList = selectedList.map((user) => user.uid);
    const data = { [fieldID]: userIdList };
    props.handleSubmitMultipleUID(data);
  }

  function getAssignedUids(users: IMultipleUid_AssignUser[]): string[] {
    const selectedList = users.filter((user) => user.isAssigned);
    return selectedList.map((user) => user.uid);
  }

  return (
    <Controller
      defaultValue={getAssignedUids(updatedUserList)}
      name={props.customField.id}
      control={props.control}
      rules={{ required: props.isRequired }}
      render={({ field }) => {
        return (
          <div key={props.customField.id + props.customField.title}>
            <MultipleUidDialog
              open={isOpen}
              closeDialog={() => setIsOpen(false)}
              userList={updatedUserList}
              // {...field}
              handleSubmit={(ev) => {
                field.onChange(getAssignedUids(ev));
                handleDialogSubmit(props.customField.id, ev);
              }}
            />

            <div className="mt-6 max-w-md rounded-md border border-gray-400 transition-colors hover:border-gray-800">
              <button
                id={`selection-dropdown-for-${props.customField.title}`}
                type="button"
                className="w-full cursor-pointer rounded px-4 py-3 transition-colors hover:bg-gray-50"
                onClick={() => setIsOpen(true)}
              >
                <div className="flex items-center gap-4">
                  <UserCircleIcon
                    aria-hidden={true}
                    className="h-7 w-7 shrink-0 rounded text-gray-600"
                  />
                  <label
                    htmlFor={`selection-dropdown-for-${props.customField.title}`}
                    className="block cursor-pointer"
                  >
                    {props.customField.title}{" "}
                    {props.isRequired && (
                      <span className="text-lg font-medium text-redFail">
                        *
                      </span>
                    )}
                  </label>
                  <ChevronRightIcon
                    aria-hidden={true}
                    className="ml-auto h-5 w-5 shrink-0"
                  />
                </div>
                <span className="block w-full pl-12 pr-6 text-left">
                  {selectedUsersString}
                </span>
              </button>
            </div>
          </div>
        );
      }}
    ></Controller>
  );
}
