import { FieldValues } from "react-hook-form";

/**
 * compares react-hook-form's dirtyFields object with the submitted form values
 * and returns only the form values that are present in dirtyFields.
 */
export function dropUnchangedFields(args: {
  dirtyFields: Partial<Readonly<Record<string, any>>>;
  formValues: FieldValues;
}): FieldValues {
  const dirtyKeys = Object.keys(args.formValues).filter(
    (key) => args.dirtyFields[key],
  );
  // return key/value pairs that are in dirtyKeys list
  return Object.entries(args.formValues).reduce((acc, [key, value]) => {
    if (dirtyKeys.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {} as FieldValues);
}
