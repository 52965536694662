//Libs
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import { ExistingChecklistResponse } from "../models/checklist-response";
import ResponseCardBase, {
  ResponseCardBaseProps,
} from "../components/ResponseCardBase";
import { ConvertArrayToString } from "../assets/js/HandleOptions";
import BaseInputCheckbox from "./BaseInputCheckbox";
import { logger as devLogger } from "../logging";

interface Props {
  response: ExistingChecklistResponse;
  onSaveValue: (value: string | null, responseID: string) => Promise<void>;
  /**
   * Takes IconRow as children.
   */
  children: React.ReactNode;
}

export default function ResponseCardSelectionCheckbox(
  props: Props & Omit<ResponseCardBaseProps, "children">,
): JSX.Element {
  const ResponseSelectionSchema = z.object({
    responseSelectionValue: z.boolean(),
  });
  type ResponseSelectionState = z.infer<typeof ResponseSelectionSchema>;

  async function onSubmit(data: ResponseSelectionState) {
    if (props.response.id == null) {
      devLogger.error(`ResponseID was not found. Value: ${props.response.id}`);
      return;
    }

    if (
      data.responseSelectionValue === false &&
      props.response.responseValue === null
    ) {
      devLogger.debug("The value hasn't changed");
      return;
    }

    let valueToSend = "";

    if (data.responseSelectionValue === true) {
      valueToSend = props.response.selectionOptions
        ? ConvertArrayToString(props.response.selectionOptions)
        : "";
    } else {
      valueToSend = "";
    }

    if (valueToSend === props.response.responseValue) {
      devLogger.debug("The value hasn't changed");
      return;
    }

    await props.onSaveValue(valueToSend, props.response.id);
    devLogger.debug("Response value was successfully recorded");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResponseSelectionState>({
    defaultValues: {
      responseSelectionValue:
        typeof props.response.responseValue === "string" ? true : false,
    },
    resolver: zodResolver(ResponseSelectionSchema),
  });

  const inputForm = (
    <form autoComplete="off" onBlur={handleSubmit(onSubmit)}>
      <div className="my-7 grid w-full grid-cols-1 px-2 sm:w-72">
        {props.response.selectionOptions !== null ? (
          <Fragment>
            <BaseInputCheckbox
              label={ConvertArrayToString(props.response.selectionOptions)}
              responseStyle
              {...register("responseSelectionValue")}
              id={props.response.id}
            />
            {errors.responseSelectionValue?.message && (
              <div className="ml-1 mt-2 text-xs text-red-400">
                {errors.responseSelectionValue?.message}
              </div>
            )}
          </Fragment>
        ) : (
          ""
        )}
      </div>
    </form>
  );

  return (
    <ResponseCardBase response={props.response}>
      {{
        input: inputForm,
        iconRow: props.children,
      }}
    </ResponseCardBase>
  );
}
