//Libs
import { z } from "zod";

//Local
import { guardIsPlainObject } from "../utils";

export interface InventoryLocationQuantity {
  available?: number;
  reserved?: number;
  inUse?: number;
  awaitingPickup?: number;
  lowQuantityThreshold?: number;
}

export const InventoryLocationQuantityManager = {
  parse: validateInventoryLocationQuantity,
};

/* VALIDATION */
function validateInventoryLocationQuantity(
  value: unknown,
): InventoryLocationQuantity {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = inventoryLocationQuantitySchema.parse(value);
  return result;
}
export const inventoryLocationQuantitySchema = z.object({
  available: z.number().optional(),
  reserved: z.number().optional(),
  inUse: z.number().optional(),
  awaitingPickup: z.number().optional(),
  lowQuantityThreshold: z.number().optional(),
});
