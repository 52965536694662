import currencyFormatter from "../../currency";
import {
  ExistingStiltPayment,
  UnauthedPaymentMade,
  paymentMethodsMap,
} from "../../models/stilt-payment";
import {
  convertISOToFSTimestamp,
  convertFSTimestampToLuxonDT,
} from "../../utils";

type Authed = {
  isUserAuthenticated: true;
  payment: ExistingStiltPayment;
};
type Unauthed = {
  isUserAuthenticated: false;
  payment: UnauthedPaymentMade;
};

type PaymentLineFormattedType = {
  currencyType: string;
  timezone: string;
  /** not used if the user is unauthenticated. */
  userDisplayNamesMap: Record<string, string>;
} & (Authed | Unauthed);

/**
 * No memo or tracking data is included when this is being viewed by the end-customer.
 * This component is used when displaying the Payments section of an invoice,
 * within the web app.
 *
 * Conversely, `getPaymentLineText` is used to retrieve the payment line text with
 * no formatting applied, and is used when sending an invoice/receipt to the
 * end-customer via email/PDF.
 */
export default function PaymentLineFormatted({
  payment,
  currencyType,
  timezone,
  isUserAuthenticated,
  userDisplayNamesMap,
}: PaymentLineFormattedType): JSX.Element {
  const ts =
    typeof payment.timestampPaymentMade === "string"
      ? convertISOToFSTimestamp(payment.timestampPaymentMade)
      : payment.timestampPaymentMade;
  const lux = convertFSTimestampToLuxonDT(ts);
  const formattedDate = lux.setZone(timezone).toFormat("LL/dd/yy hh:mm a");

  // REFUNDS -----
  if (payment.paymentType === "refund") {
    let refundStr = `${currencyFormatter(
      -payment.amount,
      currencyType,
    )} refunded via ${paymentMethodsMap[payment.paymentMethod]}`;
    if (
      payment.paymentMethod === "credit_card" ||
      payment.paymentMethod === "tokenized_card"
    ) {
      if (payment.lastFour) refundStr += ` (${payment.lastFour})`;
    } else if (
      payment.paymentMethod === "check" ||
      payment.paymentMethod === "tokenized_check"
    ) {
      if (payment.checkNumber) refundStr += ` (${payment.checkNumber})`;
    }

    refundStr += ` on ${formattedDate}`;

    // IF USER IS AUTHENTICATED, include memo and tracking data
    let trackingStr: string | null = null;
    if (isUserAuthenticated) {
      if (
        payment.memo &&
        payment.memo.length > 0 &&
        payment.memo.toLowerCase().trim() !== "refund"
      ) {
        refundStr += "\n" + payment.memo;
      }

      if (payment.createdBy) {
        trackingStr = `${payment.paymentSource === "manual" ? "Manual refund p" : "P"}`;
        trackingStr += `rocessed by ${userDisplayNamesMap[payment.createdBy] ?? "[unknown]"}`;
      }
    }

    if (trackingStr) {
      return (
        <span key={refundStr} className="mb-2 block italic last:mb-0">
          <span>{refundStr}</span>
          <span className="block text-sm">{trackingStr}</span>
        </span>
      );
    }
    return (
      <span key={refundStr} className="mb-2 block italic last:mb-0">
        {refundStr}
      </span>
    );
  }

  // PAYMENTS -----
  if (isUserAuthenticated) {
    return (
      <PaymentLineAuthed
        payment={payment}
        currencyType={currencyType}
        formattedDate={formattedDate}
        userDisplayNamesMap={userDisplayNamesMap}
      />
    );
  } else {
    return (
      <PaymentLineUnauthed
        payment={payment}
        currencyType={currencyType}
        formattedDate={formattedDate}
      />
    );
  }
}

/** handles building a payment line. includes memo and tracking data; is only intended for authenticated users. (i.e, not for end-customer) */
function PaymentLineAuthed({
  payment,
  currencyType,
  formattedDate,
  userDisplayNamesMap,
}: {
  payment: ExistingStiltPayment;
  currencyType: string;
  formattedDate: string;
  userDisplayNamesMap: Record<string, string>;
}): JSX.Element {
  let paymentStr = `${currencyFormatter(payment.amount, currencyType)} paid via ${
    paymentMethodsMap[payment.paymentMethod]
  } on ${formattedDate}`;

  if (
    payment.paymentMethod === "credit_card" ||
    payment.paymentMethod === "tokenized_card"
  ) {
    paymentStr += "\n";
    if (payment.nameOnCard) paymentStr += payment.nameOnCard;
    if (payment.cardType) paymentStr += " - " + payment.cardType;
    if (payment.lastFour) paymentStr += " - " + payment.lastFour;
  } else if (
    payment.paymentMethod == "check" ||
    payment.paymentMethod == "tokenized_check"
  ) {
    if (payment.checkNumber) paymentStr += "\nCheck #: " + payment.checkNumber;
  }

  if (payment.memo && payment.memo.length > 0) {
    paymentStr += "\n" + payment.memo;
  }

  // display tracking data
  let trackingStr: string | null = null;

  if (payment.paymentSource === "manual") {
    trackingStr = "Manual payment";
    if (payment.createdBy) {
      trackingStr += ` processed by ${userDisplayNamesMap[payment.createdBy] ?? "[unknown]"}`;
    }
  } else {
    if (payment.customData.uid && payment.customData.platform) {
      trackingStr = `Processed via ${payment.customData.platform} by ${userDisplayNamesMap[payment.customData.uid] ?? "[unknown]"}`;
    } else if (payment.customData.uid) {
      trackingStr = `Processed by ${userDisplayNamesMap[payment.customData.uid] ?? "[unknown]"}`;
    } else if (payment.customData.platform) {
      trackingStr = `Processed via ${payment.customData.platform}`;
    }

    // payment via card on file won't have tracking data. can use payment.createdBy
    if (payment.paymentMethod === "tokenized_card" && payment.createdBy) {
      trackingStr = `Processed by ${userDisplayNamesMap[payment.createdBy] ?? "[unknown]"}`;
    }
  }

  if (trackingStr) {
    return (
      <span
        key={paymentStr}
        className={`mb-2 block last:mb-0 ${payment.amount > 0 ? "text-green-600" : ""}`}
      >
        {paymentStr}
        <span className="block text-sm italic text-gray-800">
          {trackingStr}
        </span>
      </span>
    );
  }
  return (
    <span
      key={paymentStr}
      className={`mb-2 block last:mb-0 ${payment.amount > 0 ? "text-green-600" : ""}`}
    >
      {paymentStr}
    </span>
  );
}

/** handles building a payment line. does not include memo. intended for end-customers. */
function PaymentLineUnauthed({
  payment,
  currencyType,
  formattedDate,
}: {
  payment: UnauthedPaymentMade;
  currencyType: string;
  formattedDate: string;
}): JSX.Element {
  let paymentStr = `${currencyFormatter(payment.amount, currencyType)} paid via ${
    paymentMethodsMap[payment.paymentMethod]
  } on ${formattedDate}`;

  // CARD
  if (
    payment.paymentMethod === "credit_card" ||
    payment.paymentMethod === "tokenized_card"
  ) {
    paymentStr += "\n";
    if (payment.nameOnCard) paymentStr += payment.nameOnCard;
    if (payment.cardType) paymentStr += " - " + payment.cardType;
    if (payment.lastFour) paymentStr += " - " + payment.lastFour;
  }

  // CHECK
  if (
    payment.paymentMethod == "check" ||
    payment.paymentMethod == "tokenized_check"
  ) {
    if (payment.checkNumber) paymentStr += "\nCheck #: " + payment.checkNumber;
  }
  return (
    <span
      key={paymentStr}
      className={`mb-2 block last:mb-0 ${payment.amount > 0 ? "text-green-600" : ""}`}
    >
      {paymentStr}
    </span>
  );
}
