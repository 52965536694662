// Libs
import create from "zustand";

// Local
import { ExistingRootUser } from "../models/root-user";
import { DbRead } from "../database";
import { logger as devLogger } from "../logging";

// Define the property types.
type unsubscribeFunc = () => void;
interface RootUserStore {
  rootUser: ExistingRootUser | null;
  /** Subscribe to real-time updates on the root user document */
  subscribe: (uid: string) => void;
  getDefaultSiteKey: () => string | undefined;
  /** Remove real-time listener from the root user document */
  unsubscribeRootUser: unsubscribeFunc | null;
}

// Export a hook to access data globally and define initial values.
export const useRootUserStore = create<RootUserStore>((set, get) => {
  return {
    rootUser: null,
    subscribe: (uid) => {
      // Making this get call to ensure cache is update with latest doc...
      DbRead.rootUser.get(uid);
      // Then we can proceed with the listener.
      // In some situations, Firebase holds onto cached data in the browser,
      // (even after a hard refresh) and the listeners aren't updating properly
      // By calling the single get read first, it forces the cache to update
      const unsubscribe = DbRead.rootUser.subscribe(
        uid,
        (doc) => {
          set({ rootUser: doc });
        },
        (error) => devLogger.error(error),
      );
      set({ unsubscribeRootUser: unsubscribe });
    },
    getDefaultSiteKey: () => {
      const defaultSiteKey = get().rootUser?.defaultSiteKey;
      if (typeof defaultSiteKey === "string") {
        return defaultSiteKey;
      }
    },
    unsubscribeRootUser: null,
  };
});
