export function convertPassingNumberValues(
  passingNumber: number | "",
): number | null {
  let convertedValue: null | number;
  if (passingNumber === "") {
    convertedValue = null;
  } else {
    convertedValue = passingNumber;
  }
  return convertedValue;
}

export function validatePassingNumberValues(
  event: React.ChangeEvent<HTMLInputElement>,
  setState: (value: number | "") => void,
) {
  const value = event.target.value;
  const numValue: number = Number(value);
  if (value === "") {
    setState(value);
  } else if (isNaN(numValue)) {
    setState("");
  } else {
    setState(numValue);
  }
}
