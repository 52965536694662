// Libs
import { z } from "zod";
import isISO4217 from "validator/lib/isISO4217";
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";

// Local
import { dropUndefined, guardIsPlainObject, zFallback } from "../utils";
import { NotFoundError } from "../error-classes";
import {
  AccountingSyncStiltDoc,
  AccountingSyncStiltDocSchema,
  AccountingSyncStiltDocSchemaWithFallbacks,
} from "./accounting-sync";

// #region Types
export const accountTypes = [
  "Unknown",
  "Asset",
  "Expense",
  "Income",
  "Liability",
  "Equity",
] as const;
export type AccountType = (typeof accountTypes)[number];

/** General Ledger Account */
export interface GLAccount {
  /** The account name. */
  name: string;
  /** Human-readable */
  accountType: AccountType;
  /**
   * Currency code. If present, must adhere to the [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217)
   * standard. IE, "USD", "EUR", "GBP", "CAD"
   */
  currency: string | null;
  /**
   * @string accounting integration software is Conductor
   * @number accounting integration software is Codat, or we are not integrating
   * with the Stilt-customer's accounting platform.
   */
  balance: number;
  accountingSync?: AccountingSyncStiltDoc;
  customData: Record<string, any>;

  // Usual fields
  deleted: boolean;
  lastModifiedBy: string;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
}

interface FirestoreAttributes {
  id: string;
  refPath: string;
}

/** Existing General Ledger Account */
export type ExistingGLAccount = GLAccount & FirestoreAttributes;

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  GLAccount,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface GLAccount_CreateAPI extends withoutTimestamps {
  siteKey: string;
}

// #endregion Types

// #region Functions
// SECTION:
/** Utilities for interacting with general ledger account objects. */
export const GLAccountManager = {
  /**
   * Convert document snapshot into a validated ExistingGLAccount object.
   * Uses schema with fallbacks.
   * @throws NotFoundError if doc does not exist
   */
  fromSnapshot: createFromFirestoreSnapshot,
  /** Drop id and refPath, drop undefined values. Does not validate data. */
  convertUpdateForFirestore: convertUpdateForFirestore,
  /** Used when writing to the database. @throws if `value` is not an object. */
  parse: validateGLAccount,
  /** Used when reading from the database. @throws if `value` is not an object. */
  fallbacksParse: fallbacksValidateGLAccount,
  /** Used when writing to the database. @throws if `value` is not an object. */
  parseExisting: validateExistingGLAccount,
  /** Validate a new general ledger account. For the create endpoint. */
  parseCreate: validateGLAccount_Create,
};

function validateGLAccount_Create(value: unknown): GLAccount_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return gLAccountSchema_CreateAPI.parse(value);
}

function fallbacksValidateGLAccount(value: unknown): GLAccount {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return fallbacksGLAccountSchema.parse(value);
}

function validateGLAccount(value: unknown): GLAccount {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return glAccountSchema.parse(value);
}

function validateExistingGLAccount(value: unknown): ExistingGLAccount {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return existingGLAccountSchema.parse(value);
}

function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingGLAccount {
  if (!snapshot.exists) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...fallbacksValidateGLAccount(snapshot.data()),
  };
}

function convertUpdateForFirestore(account: ExistingGLAccount): DocumentData {
  const local = Object.assign({}, account);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  return dropUndefined(rest);
}

/** ensure value is a valid ISO4217 currency */
export function refineIsCurrency(value: string): boolean {
  return isISO4217(value);
}
// #endregion Functions

// #region Zod schemas
// SECTION:

/** message if value is an invalid currency code */
export const msgInvalidISO = {
  message: "Invalid ISO4217 currency code",
} as const;

/** no timestamps */
const commonSchema = z.object({
  name: z.string().min(1).max(300),
  accountType: z.enum(accountTypes),
  currency: z.string().refine(refineIsCurrency, msgInvalidISO).nullable(),
  balance: z.number(),
  deleted: z.boolean(),
  lastModifiedBy: z.string().min(1).max(200),
  customData: z.record(z.any()),
  accountingSync: AccountingSyncStiltDocSchema.optional(),
});

/** used when validating data to write to the database */
const glAccountSchema = commonSchema.extend({
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
});

/** used when validating data to write to the database, for existing general ledger account objects */
const existingGLAccountSchema = glAccountSchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(500),
});

/** no timestamps */
const fallbacksCommonSchema = z.object({
  name: zFallback(
    z.string().min(1).max(300),
    "Unknown",
    "name - gl-account.ts",
  ),
  accountType: zFallback(
    z.enum(accountTypes),
    "Unknown",
    "accountType - gl-account.ts",
  ),
  currency: zFallback(
    z.string().refine(refineIsCurrency).nullable(),
    null,
    "currency - gl-account.ts",
  ),
  balance: zFallback(z.number(), 0, "balance - gl-account.ts"),
  deleted: zFallback(z.boolean(), false, "deleted - gl-account.ts"),
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "Unknown",
    "lastModifiedBy - gl-account.ts",
  ),
  customData: zFallback(z.record(z.any()), {}, "customData - gl-account.ts"),
  accountingSync: AccountingSyncStiltDocSchemaWithFallbacks.optional(),
});

/** used when validating data coming out of the database */
const fallbacksGLAccountSchema = fallbacksCommonSchema.extend({
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "timestampCreated - gl-account.ts",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "timestampLastModified - gl-account.ts",
  ),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = glAccountSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const gLAccountSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
});

// #endregion Zod schemas
