//Libs
import { useMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";
import {
  getDownloadURL,
  ref,
  StorageError,
  uploadBytesResumable,
} from "firebase/storage";
import { getStorage } from "firebase/storage";
import { useCallback, useEffect, useState, ChangeEvent } from "react";
import { DocumentData } from "firebase/firestore";

//Local
import CompaniesListTable from "./CompaniesListPage";
import { useSiteKeyCompaniesStore } from "../../store/site-key-companies";
import EscapeIframeBreadcrumbs from "../../components/EscapeIframeBreadcrumbs";

import ViewCompanyInfo from "../../components/admin/ViewCompanyInfo";
import AddNewCompanyDialog, {
  CompanyState,
} from "../../components/admin/AddNewCompanyDialog";
import { EditCompanyState } from "../../components/admin/EditCompanyDialog";
import { DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  SiteKeyCompany,
  ExistingSiteKeyCompany,
  SiteKeyCompanyRecordManager,
} from "../../models/site-key-companies";
import { BASE_APP_URL } from "../../urls";
import { logger as devLogger } from "../../logging";
import {
  CraftTypeValues,
  getCraftTypeFromString,
  getCraftTypeStringFromReadableString,
} from "../../models/craft-types";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { diffObjects } from "../../assets/js/object-diff";
import EditCompanyDialog from "../../components/admin/EditCompanyDialog";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { useSiteKeyDocStore } from "../../store/site-key-doc";

interface Props {
  siteKey: string;
}

export default function CompaniesListContainer({ siteKey }: Props) {
  //#region STORE DATA
  const [fetchSiteKeyCompanies, listOfCompanies] = useSiteKeyCompaniesStore(
    (state) => [state.fetch, state.siteKeyCompanies],
  );

  const userPermissionsDoc = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  //#endregion

  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  //#region QUERIES AND MUTATIONS

  /* Mutation for add a new site key company to the db */
  const mutateAddNewCompany = useMutation(
    async (readyForDatabase: DocumentData) => {
      return await DbWrite.siteKeyCompanies.adminAddNew(
        siteKey,
        readyForDatabase,
      );
    },
    {
      onSuccess: () => {
        if (siteKeyDoc && userPermissionsDoc && !isUploadingLogo) {
          return fetchSiteKeyCompanies(siteKeyDoc, userPermissionsDoc);
        }
      },
    },
  );

  /* Mutation for edit the site key company to the db */
  const mutateEditCompany = useMutation(
    async (args: {
      companyID: string;
      editSiteKeyCompany: Record<string, any>;
    }) => {
      return await DbWrite.siteKeyCompanies.adminEditCompany(
        siteKey,
        args.companyID,
        args.editSiteKeyCompany,
      );
    },
    {
      onSuccess: () => {
        if (siteKeyDoc && userPermissionsDoc && !isUploadingLogo) {
          return fetchSiteKeyCompanies(siteKeyDoc, userPermissionsDoc);
        }
        return undefined;
      },
    },
  );

  /* Mutation for update company logo prop in siteKeyCompanies doc */
  const mutateUpdateCompanyLogo = useMutation(
    async (args: { newCompanyID: string; newCompanyLogo: string }) => {
      return await DbWrite.siteKeyCompanies.adminUpdateLogo(
        siteKey,
        args.newCompanyID,
        args.newCompanyLogo,
      );
    },
    {
      onSuccess: () => {
        if (siteKeyDoc && userPermissionsDoc) {
          return fetchSiteKeyCompanies(siteKeyDoc, userPermissionsDoc);
        }
        return undefined;
      },
    },
  );
  //#endregion

  //#region STATES
  const [viewCompanyInfoOpen, setViewCompanyInfoOpen] = useState(false);
  const [companyDoc, setCompanyDoc] = useState<ExistingSiteKeyCompany | null>(
    null,
  );
  const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);
  const [editCompanyDialogOpen, setEditCompanyDialogOpen] = useState(false);
  const [isUploadingLogo, setIsUploadingLogo] = useState<boolean>(false);
  const [preview, setPreview] = useState<string | null>(null);
  const [fileInformation, setFileInformation] = useState(
    strings.UPLOAD_NO_FILE_CHOSEN,
  );
  const [responseToUserError, setResponseToUserError] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  //#endregion

  const storage = getStorage();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(null);
      return undefined;
    }

    const objectURL = URL.createObjectURL(selectedFile);
    setPreview(objectURL);

    // Free memory when component is unmounted
    return () => URL.revokeObjectURL(objectURL);
  }, [companyDoc, selectedFile]);

  //#region FUNCTIONS

  function openEditCompanyDialog(company: ExistingSiteKeyCompany) {
    setEditCompanyDialogOpen(true);
    setCompanyDoc(company);
    setPreview(company.logoPhotoURL);
  }

  /* fn that handles company logo preview and errors from uploading files too big or wrong img type */
  function handlePreviewAndErrors(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length === 1) {
      const chosenFile = event.target.files[0];

      // If file is larger than 10MB
      if (chosenFile.size >= 10000000) {
        setResponseToUserError(true);
        setFileInformation(strings.UPLOAD_UNDER_10MB);
        return;
      }

      // TODO: revisit this once we conquer the heic upload.
      // Added this check because uploads via iPhone don't prevent the user from selecting an heic image.
      // Accepted file types: png, jpeg, jpg
      if (
        chosenFile.type !== "image/png" &&
        chosenFile.type !== "image/jpeg" &&
        chosenFile.type !== "image/jpg"
      ) {
        setResponseToUserError(true);
        setFileInformation(strings.UPLOAD_ACCEPTED_FILETYPES);
        return;
      }

      // Set file - ready to be sent to submit handler ƒn
      setSelectedFile(chosenFile);

      // Reset the css styles
      if (responseToUserError) setResponseToUserError(false);
    }
  }

  /* fn to save upload company logo in the db storage */
  async function handleCompanyLogo(newCompanyID: string, selectedFile: File) {
    // Use a random string as the file's name
    const newFileName = `${uuidv4()}.${selectedFile.type.split("/")[1]}`;

    // Upload file to the path starting with 'siteKeys'
    const storageRef = ref(
      storage,
      `siteKeys/${siteKey}/siteKeyCompanies/` + newFileName,
    );

    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    // Listen for state changes, errors, and completion of the upload.
    const unsubscribe = uploadTask.on("state_changed", {
      // the 'next' callback could give progress reports, ie: '79% done'
      next: null,
      error: handleUploadError,
      complete: onUploadComplete,
    });

    // This only gets called if there is an error.
    function handleUploadError(error: StorageError) {
      switch (error.code) {
        case "storage/unauthorized":
          setFileInformation(
            strings.UPLOAD_ERROR_USER_NOT_PERMITTED_TO_UPLOAD_PHOTO,
          );
          setIsSubmitting(false);
          devLogger.error("Unauthorized user attempting to upload an image.");
          break;
        case "storage/quota-exceeded":
          setFileInformation(strings.UPLOAD_ERROR_QUOTA_EXCEEDED);
          setIsSubmitting(false);
          devLogger.error("Quota on Cloud Storage bucket has been exceeded.");
          break;
        case "storage/unknown":
          setFileInformation(strings.GENERIC_ERROR_MESSAGE);
          setIsSubmitting(false);
          devLogger.error(
            "Unknown error occurred, inspect error.serverResponse",
          );
          break;
        default:
          setFileInformation(strings.UPLOAD_ERROR_UNABLE);
          setIsSubmitting(false);
          devLogger.error("Unknown error occurred while uploading image.");
      }

      // If there has been an error during upload, unsubscribe from uploadTask.on().
      unsubscribe();
    }

    // Upload completed successfully.
    async function onUploadComplete() {
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      try {
        mutateUpdateCompanyLogo.mutateAsync({
          newCompanyID,
          newCompanyLogo: downloadURL,
        });
        setIsUploadingLogo(false);
        unsubscribe();
      } catch (err) {
        setFileInformation(strings.UPLOAD_ERROR_UNABLE);
        setIsSubmitting(false);
        devLogger.error(
          "An error occurred during update site key company logo -> ",
          err,
        );
      }
    }
  }

  /* update company doc on db */
  async function handleEditCompany(
    companyID: string,
    formValues: EditCompanyState,
  ) {
    if (selectedFile !== null) setIsUploadingLogo(true);

    // Drop undefined values from the craftTypes list.
    const definedCraftTypes = formValues.craftTypes.filter(isDefinedCraftTypes);

    try {
      const formValueForSiteKeyCompanies: Partial<SiteKeyCompany> = {
        name: formValues.name.trim(),
        mainPointOfContact:
          formValues.mainPointOfContact === ""
            ? null
            : formValues.mainPointOfContact.trim(),
        craftTypes: definedCraftTypes,
      };

      const initialValuesForSiteKeyCompanies = {
        name: companyDoc?.name,
        mainPointOfContact: companyDoc?.mainPointOfContact,
        craftTypes: companyDoc?.craftTypes,
      };

      const diffSiteKeyCompaniesValues: DocumentData = diffObjects(
        initialValuesForSiteKeyCompanies,
        formValueForSiteKeyCompanies,
      ).diff;

      if (Object.keys(diffSiteKeyCompaniesValues).length === 0) {
        devLogger.debug("No values have changed.");
        return;
      }

      /* validate values from the form */
      const validateEditSiteKeyCompany = SiteKeyCompanyRecordManager.parseEdit(
        diffSiteKeyCompaniesValues,
      );

      await mutateEditCompany.mutateAsync({
        companyID,
        editSiteKeyCompany: validateEditSiteKeyCompany,
      });
      devLogger.debug("Company has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.successfulUpdate(formValues.name),
        type: "success",
      });
      if (selectedFile !== null) {
        try {
          await handleCompanyLogo(companyID, selectedFile);
          devLogger.debug("Success, the company logo is added successfully");
        } catch (err) {
          // Set the text to show the user.
          setFileInformation(strings.UPLOAD_ERROR_UNABLE);
          setIsSubmitting(false);
          devLogger.error(
            "An error occurred during handleCompanyLogo function -> ",
            err,
          );
        }
      }
    } catch (err) {
      // Set the text to show the user.
      setFileInformation(strings.ERROR_EDITING_COMPANY);
      setIsSubmitting(false);
      devLogger.error(
        `An error occurred during handleEditCompany for company id ${companyID}`,
        err,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  /* add new company to the db */
  async function addNewCompany(
    craftTypes: CraftTypeValues[],
    formValues: CompanyState,
  ): Promise<string | undefined> {
    try {
      const output: SiteKeyCompany = {
        canRequestCraftTypes: [],
        craftTypes: craftTypes,
        isPlantCompany: false,
        logoPhotoURL: null,
        mainPointOfContact:
          formValues.mainPointOfContact === ""
            ? null
            : formValues.mainPointOfContact.trim(),
        members: [],
        name: formValues.name.trim(),
      };

      /* validate values from the form */
      const readyForDatabase = SiteKeyCompanyRecordManager.parse(output);

      const addNewCompanyMutation =
        await mutateAddNewCompany.mutateAsync(readyForDatabase);
      return addNewCompanyMutation;
    } catch (err) {
      // Set the text to show the user.
      setFileInformation(strings.ERROR_ADDING_COMPANY);
      setIsSubmitting(false);
      devLogger.error("An error occurred while adding new company to db", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  /* handle adding a new company with or without logo */
  async function handleSaveNewCompany(formValues: CompanyState) {
    //convert from string to CraftTypeValues
    const craftTypes = formValues.craftTypes.map((craftType) => {
      const craftTypeString = getCraftTypeStringFromReadableString(craftType);
      return getCraftTypeFromString(craftTypeString);
    });

    // Drop undefined values from the craftTypes list.
    const definedCraftTypes = craftTypes.filter(isDefinedCraftTypes);
    if (definedCraftTypes.length === 0)
      return devLogger.debug("craft types cannot be empty");

    try {
      if (selectedFile !== null) setIsUploadingLogo(true);

      const newCompanyID = await addNewCompany(definedCraftTypes, formValues);

      if (selectedFile !== null && newCompanyID !== undefined) {
        try {
          handleCompanyLogo(newCompanyID, selectedFile);
          devLogger.debug("Success, the company logo is added successfully");
        } catch (err) {
          // Set the text to show the user.
          setFileInformation(strings.UPLOAD_ERROR_UNABLE);
          setIsSubmitting(false);
          devLogger.error(
            "An error occurred during handleCompanyLogo function -> ",
            err,
          );
        }
      }
      devLogger.debug("Success! The new company is added!");
      addMessage({
        id: createToastMessageID(),
        message: `${formValues.name} has been added`,
        dialog: false,
        type: "success",
      });
    } catch (err) {
      // Set the text to show the user.
      setFileInformation(strings.ERROR_ADDING_COMPANY);
      setIsSubmitting(false);
      devLogger.error("An error occurred during handleSaveNewCompany", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }

    // Stop the button's busy state, regardless of the result.
    setIsSubmitting(false);
  }

  const showCompanyInfo = useCallback(async function (
    company: ExistingSiteKeyCompany,
  ): Promise<void> {
    setViewCompanyInfoOpen(true);
    setCompanyDoc(company);
  }, []);

  //#endregion

  //#region UI COMPOSITION

  /* Breadcrumbs */
  const vueAdminPanelLink = BASE_APP_URL + "/user-info";
  const home = {
    name: strings.ADMIN,
    href: vueAdminPanelLink,
  };
  const pages = [
    {
      name: strings.COMPANIES,
      href: null,
      current: true,
    },
  ];
  const breadcrumbs = (
    <EscapeIframeBreadcrumbs
      home={home}
      pages={pages}
      backButtonHref={vueAdminPanelLink}
    />
  );

  /* Company Logo */
  const companyLogoSection = (
    <div className="relative grid grid-cols-1 justify-items-center rounded border border-black p-6 sm:grid-cols-2 md:pl-2 md:pr-0 lg:col-span-2 lg:px-4">
      <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
        Company Logo
      </label>
      <label
        htmlFor="img-upload"
        className="col-span-2 inline-flex h-10 w-full max-w-xs items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primaryButtonText shadow-sm hover:bg-primaryDark hover:text-secondaryButtonText focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2 "
      >
        Upload
      </label>
      <input
        id="img-upload"
        type="file"
        onChange={(ev) => handlePreviewAndErrors(ev)}
        accept="image/jpg, image/jpeg, image/png"
        className="hidden"
      />

      <span
        className={
          responseToUserError ? "text-base font-medium text-red-800" : "hidden"
        }
      >
        {fileInformation}
      </span>

      {selectedFile && preview && (
        <img
          src={preview}
          alt="Company logo preview"
          className="col-span-2 mt-3 w-48 rounded-md sm:w-64"
        />
      )}
      {!selectedFile &&
        !preview &&
        editCompanyDialogOpen &&
        companyDoc?.logoPhotoURL && (
          <img
            src={companyDoc.logoPhotoURL}
            alt="Company logo preview"
            className="col-span-2 mt-3 w-48 rounded-md sm:w-64"
          />
        )}
    </div>
  );

  // Loading indicator.
  if (!siteKeyDoc) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const viewCompanyInfoDialog =
    companyDoc !== null ? (
      <ViewCompanyInfo
        isDialogOpen={viewCompanyInfoOpen}
        closeDialog={() => setViewCompanyInfoOpen(false)}
        company={companyDoc}
      />
    ) : null;

  const addOrEditCompanyDialog =
    addCompanyDialogOpen === true ? (
      <AddNewCompanyDialog
        isDialogOpen={addCompanyDialogOpen}
        closeDialog={() => {
          setAddCompanyDialogOpen(false);
          setSelectedFile(null);
          setPreview(null);
          setResponseToUserError(false);
          setFileInformation(strings.UPLOAD_NO_FILE_CHOSEN);
        }}
        handleSave={handleSaveNewCompany}
        siteKey={siteKeyDoc}
        loading={isSubmitting}
      >
        {{
          CompanyLogoSection: companyLogoSection,
        }}
      </AddNewCompanyDialog>
    ) : companyDoc && editCompanyDialogOpen ? (
      <EditCompanyDialog
        isDialogOpen={editCompanyDialogOpen}
        closeDialog={() => {
          setEditCompanyDialogOpen(false);
          setSelectedFile(null);
          setPreview(null);
          setResponseToUserError(false);
          setFileInformation(strings.UPLOAD_NO_FILE_CHOSEN);
          setCompanyDoc(null);
        }}
        handleEdit={handleEditCompany}
        siteKey={siteKeyDoc}
        company={companyDoc}
        loading={isSubmitting}
      >
        {{
          CompanyLogoSection: companyLogoSection,
        }}
      </EditCompanyDialog>
    ) : null;

  //#endregion

  return (
    <CompaniesListTable
      companies={listOfCompanies}
      onEditCompany={openEditCompanyDialog}
      onViewCompanyInfo={showCompanyInfo}
      onAddNewCompany={() => setAddCompanyDialogOpen(true)}
    >
      {{
        Breadcrumbs: breadcrumbs,
        ViewCompanyInfoDialog: viewCompanyInfoDialog,
        AddOrEditCompany: addOrEditCompanyDialog,
      }}
    </CompaniesListTable>
  );
}

export function isDefinedCraftTypes(
  val: CraftTypeValues | undefined,
): val is CraftTypeValues {
  return val !== undefined;
}
