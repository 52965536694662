//Libs
import { TrashIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";

//Local
import LoadingSpinner from "./LoadingSpinner";

type TrashIconProps = {
  onDelete: () => Promise<void>;
  /** Height value WITH unit. Default is 38px */
  height?: string;
  /** Width value WITH unit. Default is 38px */
  width?: string;
  /** Default is text-gray-700 */
  color?: string;
  /** Default is hover:bg-primaryOpacity90 */
  hoverColor?: string;
} & React.ComponentPropsWithRef<"button">;

export const TrashIconWithSpinner = React.forwardRef<
  HTMLButtonElement,
  TrashIconProps
>(
  (
    { onDelete, color, height, width, hoverColor, ...props },
    ref,
  ): JSX.Element => {
    const styles = {
      display: "block",
      height: height || "38px",
      width: width || "38px",
      cursor: "pointer",
      padding: "6px",
    };
    const [isBusy, setIsBusy] = useState(false);

    return (
      <button
        ref={ref}
        {...props}
        className={`mx-1 rounded-full ${color || "text-gray-700"} ${hoverColor || "hover:bg-primaryOpacity90"}`}
        disabled={isBusy}
        onClick={async () => {
          // set animation
          setIsBusy(true);
          try {
            await onDelete();
          } finally {
            // stop animation
            setIsBusy(false);
          }
        }}
      >
        {isBusy ? (
          <LoadingSpinner marginClass="mx-2" />
        ) : (
          <TrashIcon aria-label="delete button" style={styles} />
        )}
      </button>
    );
  },
);
