// Libs
import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  RowClassParams,
  RowClassRules,
  ValueFormatterFunc,
  ValueGetterFunc,
} from "ag-grid-community";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

// Local
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ExistingChecklistResponse } from "../../models/checklist-response";
import ChipTag from "../ChipTag";
import { StyledTooltip } from "../StyledTooltip";
import { ExistingChecklistPhoto } from "../../models/checklist-photo";
import { PhotoIconWithRef } from "../PhotoIconButton";
import { HistoryIconWithRef } from "../HistoryIconButton";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "../../assets/css/ag-grid-custom.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

// #region SECTION: Cell display related
const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

/**
 * These properties are added to every column. They can be overridden on a per
 * column basis.
 */
const commonColProps = {
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  tooltipValueGetter: (params: ITooltipParams) =>
    params.valueFormatted ?? params.value,
};

const capitalizeResponsePassing: ValueFormatterFunc = (params): string => {
  const responsePassing = params.data.responsePassing;
  if (typeof responsePassing === "string") {
    return responsePassing[0].toUpperCase() + responsePassing.slice(1);
  } else {
    return responsePassing;
  }
};

const getDateValue: ValueGetterFunc = (params) => {
  const timestampCreated = params.data.timestamp;
  if (timestampCreated instanceof Timestamp) {
    return DateTime.fromSeconds(timestampCreated.seconds).toJSDate();
  } else {
    return null;
  }
};
const getDateDisplay: ValueFormatterFunc = (params): string => {
  const timestampCreated = params.data.timestamp;
  let readableTime = convertToReadableTimestamp(timestampCreated);

  if (readableTime === "Unknown Timestamp") readableTime = "";
  return readableTime;
};

// set background color on rows depending on ResponsePassing value
const getRowClassRules: RowClassRules = {
  "ag-green": function (params: RowClassParams) {
    return params.data.responsePassing === "yes";
  },
  "ag-red": function (params: RowClassParams) {
    return params.data.responsePassing === "no";
  },
};

//for showing the notification on the photo icon
function showPhotoNotification(
  responsePhotoList: ExistingChecklistPhoto[],
  responseDocID: string,
): boolean {
  return (
    responsePhotoList.filter(
      (photoDoc) => photoDoc.checklistResponseID === responseDocID,
    ).length > 0
  );
}

function renderTagsCell(params: ICellRendererParams): JSX.Element | null {
  const tagsList: ExistingChecklistResponse["tags"] = params.value;
  if (Array.isArray(tagsList)) {
    return (
      <div className="-ml-2 flex h-10 items-center">
        {tagsList.map((tag) => {
          if (typeof tag === "string") {
            const key = tag + params.node.data.id;
            return <ChipTag tag={tag} key={key} />;
          } else {
            return null;
          }
        })}
      </div>
    );
  } else {
    return null;
  }
}

// #endregion

export interface Props {
  responseList: ExistingChecklistResponse[];
  responsePhotos: ExistingChecklistPhoto[];
  showNotification: boolean;
  onViewPhotos: (response: ExistingChecklistResponse) => void;
  onViewHistory: (response: ExistingChecklistResponse) => Promise<void>;
  children: {
    viewRCPageButton: JSX.Element;
    filterButton: JSX.Element;
  };
}

function ChecklistResponsesTable(props: Props): JSX.Element {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  // Use effect wants me to destructure this for it's deps list.
  const { responsePhotos, onViewHistory, onViewPhotos } = props;

  const renderIconCell = useCallback(
    function (params: ICellRendererParams) {
      return (
        <div className="-ml-3 -mr-3 flex items-center justify-around">
          <StyledTooltip title="Photos" enterDelay={1200}>
            <PhotoIconWithRef
              showPhotoNotification={showPhotoNotification(
                responsePhotos,
                params.data.id, // checklistResponse ID
              )}
              onClick={() => onViewPhotos(params.data)}
            />
          </StyledTooltip>

          <StyledTooltip title="View History" enterDelay={1200}>
            <HistoryIconWithRef onClick={() => onViewHistory(params.data)} />
          </StyledTooltip>
        </div>
      );
    },
    [onViewHistory, onViewPhotos, responsePhotos],
  );

  const columnDefs: ColDef[] = [
    {
      field: "mainText",
      headerName: "Main Text",
      cellClass: "font-semibold text-gray-700",
      headerClass: headerStyles,
      initialWidth: 300,
    },
    {
      field: "tags",
      cellRenderer: renderTagsCell,
    },
    {
      field: "responseValue",
      headerName: "Response",
    },
    {
      field: "responsePassing",
      headerName: "Response Passing",
      valueFormatter: capitalizeResponsePassing,
    },
    {
      field: "timestamp",
      headerName: "Submitted",
      valueFormatter: getDateDisplay,
      valueGetter: getDateValue,
      maxWidth: 250,
    },
    { field: "comments" },
    {
      cellRenderer: renderIconCell,
      suppressMovable: true,
      suppressHeaderMenuButton: true,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      sortable: false,
      maxWidth: 100,
    },
  ];

  return (
    <Fragment>
      <div className="mr-1 mt-6 flex items-end justify-between">
        {/* TABLE TITLE */}
        <span className={"text-xl font-semibold text-gray-700"}>
          Checklist Items
        </span>
        <div className="flex items-center space-x-2">
          {props.children.filterButton}
          {props.children.viewRCPageButton}
        </div>
      </div>
      <div className="ag-theme-alpine flex h-full min-h-[600px] flex-col">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={props.responseList}
          animateRows={true}
          rowSelection="single"
          rowClassRules={getRowClassRules}
          rowClass="ag-row-hover"
          columnDefs={columnDefs}
        />
      </div>
    </Fragment>
  );
  // #endregion
}

export default React.memo(ChecklistResponsesTable);
