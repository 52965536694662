import { DbRead } from "../../database";
import SelfCheckoutForm, {
  SelfCheckoutPayment,
} from "../../components/Payments/SelfCheckoutForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export interface SelfCheckoutFormConfig {
  merchantLogoURL: string;
  returnURL: string;
  siteKey: string;
  currency: string;
  primaryColor: string;
  secondaryColor: string;
}

/** For the unauthenticated end-customer */
export default function PaymentsSelfCheckoutContainer(): JSX.Element {
  const [selfCheckoutFormConfig, setSelfCheckoutFormConfig] =
    useState<SelfCheckoutFormConfig | null>(null);

  // Extract siteKey ID from the URL
  type UrlParams = { siteKey: string };
  const data = useParams<UrlParams>();
  const siteKey = data.siteKey;
  if (typeof siteKey !== "string") {
    throw new Error(`siteKey was not a string: ${siteKey}`);
  }

  async function handleProceedWithPayment(
    selfCheckoutData: SelfCheckoutPayment,
  ): Promise<string | undefined> {
    if (!siteKey) {
      return;
    }

    return await DbRead.payments.proceedWithSelfCheckout({
      siteKey: siteKey,
      data: selfCheckoutData,
    });
  }

  useEffect(() => {
    async function fetchSelfCheckoutFormConfig() {
      if (!siteKey) {
        throw new Error("siteKey is required in the URL params");
      }
      const selfCheckoutFormConfig =
        await DbRead.payments.getSelfCheckoutConfig(siteKey);
      setSelfCheckoutFormConfig(selfCheckoutFormConfig);
    }

    fetchSelfCheckoutFormConfig();
  }, [siteKey]);

  if (!selfCheckoutFormConfig) {
    return <div>Loading...</div>;
  }

  return (
    <SelfCheckoutForm
      handleProceedWithPayment={handleProceedWithPayment}
      selfCheckoutFormConfig={selfCheckoutFormConfig}
    >
      {}
    </SelfCheckoutForm>
  );
}
