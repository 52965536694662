export function getTemporaryDataForEditMemberships(
  membershipIDsCount: Record<string, number>,
): Record<string, any>[] {
  const tempData: Record<string, any>[] = [];
  if (Object.keys(membershipIDsCount.length !== 0)) {
    Object.entries(membershipIDsCount).forEach(([templateID, quantity]) =>
      tempData.push({ quantity: quantity, templateID: templateID }),
    );
  }
  return tempData;
}
