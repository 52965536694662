//Libs

//Local
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import {
  ExistingMembership,
  getReadableMembershipStatus,
} from "../../models/membership";
import { MEMBERSHIPS } from "../../urls";
import { useNavigate } from "react-router-dom";

interface Props {
  membership: ExistingMembership;
  title: string;
}

export default function MembershipPill({ membership, title }: Props) {
  const navigate = useNavigate();

  const startDate = convertToReadableTimestampDate(
    membership.membershipStartDate,
  );
  const endDate = membership.membershipEndDate
    ? ` to ${convertToReadableTimestampDate(membership.membershipEndDate)}`
    : ` to - -`;

  function handleGoToMembershipPage() {
    navigate(`${MEMBERSHIPS}/${membership.id}`);
  }

  return (
    <div
      onClick={() => {
        handleGoToMembershipPage();
      }}
      key={membership.id}
      className={`flex max-w-fit flex-col items-center justify-center rounded-lg bg-blue-200 px-2.5 py-1 text-xs font-medium capitalize text-gray-900 hover:bg-gray-100`}
    >
      {title}
      <span className="block normal-case">
        ({getReadableMembershipStatus(membership.status)}) {startDate}
        {endDate}
      </span>
    </div>
  );
}
