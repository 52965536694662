//Libs
import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

//Local

interface CustomFieldsDropdownMenuProps {
  children: {
    CraftRecordsMenu: React.ReactNode;
    TasksMenu: React.ReactNode;
  };
}

export default function CustomFieldDropdownMenu(
  props: CustomFieldsDropdownMenuProps,
): JSX.Element {
  const [selected, setSelected] = useState("Select here");

  return (
    <div className={`w-full rounded border border-gray-300 bg-white`}>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-md outline-none focus-visible:border focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight sm:text-sm">
            <span className="block truncate">{selected}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-5 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {props.children.CraftRecordsMenu}
              {props.children.TasksMenu}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
