import { User } from "firebase/auth";
import create from "zustand";

// Define the property types.
interface UserState {
  firebaseUser: User | null;
  updateUser: (user: User) => void;
}

// Define initial values.
const initialState = {
  firebaseUser: null,
};

// Export a hook to access global user related data.
export const useAuthStore = create<UserState>((set) => {
  return {
    ...initialState,
    updateUser: (updatedUser) => {
      set({ firebaseUser: { ...updatedUser } });
    },
  };
});
