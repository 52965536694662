// Libs
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef } from "react";
import { z } from "zod";

// Local
import * as strings from "../../strings";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseInputTextArea from "../BaseInputTextArea";
import FeedbackPanelStars from "./FeedbackPanelStars";

// RHF stuff
const schema = z.object({
  rateOurService: z.number().max(5),
  rateOurTechnician: z.number().max(5),
  additionalComments: z.string().max(800).optional(),
});
export type FeedbackFormState = z.infer<typeof schema>;
const defaultValues: FeedbackFormState = {
  rateOurService: 0,
  rateOurTechnician: 0,
  additionalComments: "",
};

type Ref = HTMLFormElement;
type Props = {
  handleSubmitForm: (formValues: FeedbackFormState) => Promise<void>;
} & React.ComponentPropsWithRef<"form">;

/** This is what we display when the end-customer is unhappy. */
export const FeedbackPanel = forwardRef<Ref, Props>(
  ({ handleSubmitForm, ...props }, ref) => {
    function handleStarClick(star: number, id: string) {
      if (id === "rateOurService" || id === "rateOurTechnician") {
        setValue(id, star);
      }
    }

    const {
      control,
      handleSubmit,
      formState: { isSubmitting },
      setValue,
    } = useForm<FeedbackFormState>({
      defaultValues,
      resolver: zodResolver(schema),
    });

    return (
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        autoComplete="off"
        className="mx-auto max-w-lg space-y-8 rounded-md bg-gray-100 px-7 py-6 shadow-md"
        ref={ref}
        {...props}
      >
        <article>
          <p>{strings.RATE_OUR_SERVICE}</p>
          <Controller
            name="rateOurService"
            control={control}
            render={({ field }) => (
              <FeedbackPanelStars
                id="rateOurService"
                handleClick={handleStarClick}
                {...field}
              />
            )}
          />
        </article>
        <article>
          <p>{strings.RATE_OUR_TECHNICIAN}</p>
          <Controller
            name="rateOurTechnician"
            control={control}
            render={({ field }) => (
              <FeedbackPanelStars
                id="rateOurTechnician"
                handleClick={handleStarClick}
                {...field}
              />
            )}
          />
        </article>
        <article>
          <p className="mb-2">{strings.ANYTHING_YOU_WANT_TO_SHARE}</p>
          <Controller
            name="additionalComments"
            control={control}
            render={({ field }) => (
              <BaseInputTextArea
                rows={4}
                admin={false}
                maxLength={800}
                {...field}
              />
            )}
          />
        </article>

        {/* ACTION */}
        <div className="flex items-center justify-center">
          <BaseButtonPrimary
            className="w-full uppercase tracking-wide xs:w-52"
            type="submit"
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SUBMITTING}
          >
            {strings.buttons.SUBMIT}
          </BaseButtonPrimary>
        </div>
      </form>
    );
  },
);
