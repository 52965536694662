//Libs

//Local
import SiteCard from "../../components/admin/SiteCard";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ExistingSiteKey } from "../../models/site-key";
import { ADMIN_MY_SITES_URL } from "../../urls";

interface Props {
  sites: ExistingSiteKey[];
  loadingState: boolean;
  handleViewSite: (siteKey: string) => void;
}
export default function MySitesPage({
  sites,
  loadingState,
  handleViewSite,
}: Props) {
  const home = { name: "Admin", href: ADMIN_MY_SITES_URL, current: false };
  const pages = [{ name: `My Sites`, href: ADMIN_MY_SITES_URL, current: true }];

  //TODO: looks better, but still "no site keys available" blinks for a second

  return (
    <div className="mx-auto flex w-full max-w-sm flex-col justify-center space-y-2 px-2 pb-16 sm:max-w-screen-sm lg:max-w-screen-xl">
      <Breadcrumbs home={home} pages={pages} />
      {/* If the rootUsers List of siteKeys is empty and the loading state is false, show this message */}
      {sites.length === 0 && !loadingState ? (
        <div className="text-center text-lg font-bold text-primary sm:text-3xl">
          No site keys available.
        </div>
      ) : (
        <div className="mt-8 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
          {sites.map((site) => {
            return (
              <div key={site.id}>
                <SiteCard site={site} onHandleView={handleViewSite} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
