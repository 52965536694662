//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { guardIsPlainObject } from "../utils";

export interface Signature {
  estimateID: string | null;
  invoiceID: string | null;
  taskID: string | null;
  note: string;
  timestampCreated: Timestamp;
  createdBy: string;
  signedBy: string;
  imageData: string;
  deleted: boolean;
}

export interface ExistingSignature extends Signature {
  id: string;
  refPath: string;
}

export const SignatureManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingAsset object.
   */
  createFromFirestoreSnapshot: createSignatureFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateSignature,
  /** Validate a Asset doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateSignatureWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingPriceBookItem object.
 */
function createSignatureFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingSignature {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateSignatureWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateSignatureWithFallbacks(value: unknown): Signature {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = signatureSchemaWithFallbacks.parse(value);
  return result;
}

function validateSignature(value: unknown): Signature {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = signatureSchema.parse(value);
  return result;
}

const signatureSchema = z.object({
  estimateID: z.string().min(1).max(200).nullable(),
  invoiceID: z.string().min(1).max(200).nullable(),
  taskID: z.string().min(1).max(200).nullable(),
  note: z.string().min(0).max(200),
  timestampCreated: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  signedBy: z.string().min(1).max(200),
  imageData: z.string().min(1).max(80000),
  deleted: z.boolean(),
});

const signatureSchemaWithFallbacks = z.object({
  estimateID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "signatureSchemaWithFallbacks: 'estimateID'",
  ),
  invoiceID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "signatureSchemaWithFallbacks: 'invoiceID'",
  ),
  taskID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "signatureSchemaWithFallbacks: 'taskID'",
  ),
  note: zFallback(
    z.string().min(0).max(200),
    "",
    "signatureSchemaWithFallbacks: 'note'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "signatureSchemaWithFallbacks: 'timestampCreated'",
  ),
  createdBy: zFallback(
    z.string().min(1).max(200),
    "server",
    "signatureSchemaWithFallbacks: 'createdBy'",
  ),
  signedBy: zFallback(
    z.string().min(1).max(200),
    "customer",
    "signatureSchemaWithFallbacks: 'signedBy'",
  ),
  imageData: zFallback(
    z.string().min(1).max(80000),
    "",
    "signatureSchemaWithFallbacks: 'imageData'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "signatureSchemaWithFallbacks: 'deleted'",
  ),
});
