//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import {
  CustomerContact,
  ExistingCustomerContact,
} from "../../models/customer-contact";
import AddEditCustomerContactForm from "./AddEditCustomerContactForm";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  selectedCustomerContact: ExistingCustomerContact | null;
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSave: (
    formValues: Omit<
      CustomerContact,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    >,
  ) => Promise<void>;
  handleEdit: (
    formValues: Omit<
      CustomerContact,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    >,
  ) => Promise<void>;
}

export default function AddEditCustomerContactDialog(props: Props) {
  const newCustomerContactHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.NEW_CUSTOMER_CONTACT}
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={newCustomerContactHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <AddEditCustomerContactForm
        className="flex flex-col space-y-8 p-8 text-lg"
        handleSave={props.handleSave}
        handleEdit={props.handleEdit}
        setAddCustomerContactFormOpen={props.closeDialog}
        selectedCustomerContact={props.selectedCustomerContact}
      />
    </BaseModal>
  );
}
