// Libs
import React, { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";

// Local
import LoadingSpinner from "./LoadingSpinner";

const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

type Props = {
  onClick: () => Promise<void>;
} & React.ComponentPropsWithRef<"button">;

/** Intent is for this icon button to be used within a table row. */
const EmailButtonWithSpinner = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref): JSX.Element => {
    const [isBusy, setIsBusy] = useState(false);

    return (
      <button
        ref={ref}
        {...props}
        className={`mx-1 rounded-full text-gray-700 transition-colors ${
          !isBusy ? "hover:bg-primaryOpacity90" : ""
        }`}
        disabled={isBusy}
        onClick={async () => {
          setIsBusy(true);
          await props.onClick();
          setIsBusy(false);
        }}
      >
        {!isBusy ? (
          <EmailIcon aria-label="send email" style={styles} />
        ) : (
          <LoadingSpinner marginClass="mx-2" />
        )}
      </button>
    );
  },
);

export default EmailButtonWithSpinner;
