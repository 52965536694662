//Libs
import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

export const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

export const XIconWithRef = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithRef<"button">
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className="mr-1 rounded-full text-gray-700 hover:bg-primaryOpacity90"
    >
      <XMarkIcon aria-label="close button" style={styles} />
    </button>
  );
});
