//Libs
import {
  useRef,
  useEffect,
  Children,
  isValidElement,
  cloneElement,
  useState,
  ReactElement,
} from "react";

//Local

interface MapProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  children: React.ReactNode;
}

export default function SiteMap({ center, zoom, children }: MapProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const style = {
    height: "100%",
  };

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  if (map) {
    map.setCenter(center);
    map.setZoom(zoom);
    map.setOptions({ streetViewControl: false });
  }

  return (
    <div ref={ref} id="map" style={style}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child as ReactElement<any>, { map });
        }
        // ^ got an error out of nowhere, under `map`.
        // https://stackoverflow.com/a/73858480/
      })}
    </div>
  );
}
