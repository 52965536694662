//Libs
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";

//Local
import { Customer, customerTypes } from "../../models/customer";
import { TemporaryLocation } from "./AddEditCustomerLocationDialog";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import BaseInputSelect from "../BaseInputSelect";
import { getAddress } from "./CreateTask/CustomerLocationSection";
import { logger as devLogger } from "../../logging";
import {
  customerLocationWithoutTimestamps,
  ExistingCustomerLocation,
} from "../../models/customer-location";

interface Props {
  customerLocationDoc: ExistingCustomerLocation | null;
  customerType: Customer["type"];
  isSubmitting: boolean;
  handleSaveManualCustomerLocation: (newLocation: TemporaryLocation) => void;
  handleEditCustomerLocation?: (
    updateLocation: customerLocationWithoutTimestamps,
  ) => Promise<void>;
  setAddEditCustomerLocationFormOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  children?: {
    AddMembershipsButton: React.ReactNode;
    CustomerContacts: React.ReactNode;
  };
}

const AddEditLocationManuallyFormSchema = z.object({
  locationName: z.string().max(200).optional(),
  addressLine1: z.string().min(1, { message: "Address is required" }).max(200),
  addressLine2: z.string().max(200).optional(),
  street: z.string().max(200).optional(),
  city: z.string().max(200).optional(),
  zipCode: z.string().max(200).optional(),
  state: z.string().max(200).optional(),
  notes: z.string().max(1000).optional(),
  type: z.enum(customerTypes),
});
export type AddEditLocationManuallyFormState = z.infer<
  typeof AddEditLocationManuallyFormSchema
>;

export default function AddEditLocationManuallyForm(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const addEditLocationManuallyDefaultValues: AddEditLocationManuallyFormState =
    useMemo(() => {
      if (props.customerLocationDoc) {
        return {
          locationName: props.customerLocationDoc.locationName ?? "",
          addressLine1: props.customerLocationDoc.addressLine1 ?? "",
          addressLine2: props.customerLocationDoc.addressLine2 ?? "",
          street: props.customerLocationDoc.street ?? "",
          city: props.customerLocationDoc.city ?? "",
          zipCode: props.customerLocationDoc.zipCode ?? "",
          state: props.customerLocationDoc.state ?? "",
          notes: props.customerLocationDoc.notes ?? "",
          type: props.customerType ?? "residential",
        };
      } else {
        return {
          locationName: "",
          addressLine1: "",
          addressLine2: "",
          street: "",
          city: "",
          zipCode: "",
          state: "",
          notes: "",
          type: props.customerType,
        };
      }
    }, [props.customerType, props.customerLocationDoc]);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<AddEditLocationManuallyFormState>({
    defaultValues: addEditLocationManuallyDefaultValues,
    resolver: zodResolver(AddEditLocationManuallyFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(addEditLocationManuallyDefaultValues);
  }, [addEditLocationManuallyDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the section is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    props.setAddEditCustomerLocationFormOpen(false);
  }

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  const onSubmit: SubmitHandler<AddEditLocationManuallyFormState> = async (
    formValues,
  ) => {
    const {
      locationName,
      addressLine1,
      addressLine2,
      street,
      city,
      zipCode,
      state,
      notes,
      type,
    } = formValues;

    if (props.customerLocationDoc && props.handleEditCustomerLocation) {
      const updateLocation: customerLocationWithoutTimestamps = {
        ...props.customerLocationDoc,
        locationName: locationName,
        fullAddress: getAddress(formValues),
        addressLine1: addressLine1,
        addressLine2: addressLine2 ?? null,
        street: street ?? null,
        city: city ?? null,
        zipCode: zipCode ?? null,
        state: state ?? null,
        notes: notes ?? null,
        type: type ?? null,
      };

      try {
        await props.handleEditCustomerLocation(updateLocation);
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    } else {
      const partialLocationValues: TemporaryLocation = {
        locationName: locationName,
        fullAddress: getAddress(formValues),
        addressLine1: addressLine1,
        addressLine2: addressLine2 ?? null,
        streetNumber: null,
        street: street ?? null,
        city: city ?? null,
        zipCode: zipCode ?? null,
        state: state ?? null,
        county: null,
        latitude: null,
        longitude: null,
        googlePlaceID: null,
        notes: notes ?? null,
        type: type ?? null,
        tags: [],
        customData: {},
        yearBuilt: null,
        estimatedValue: null,
        squareFootage: null,
        deleted: false,
        totalTaxRate: null,
        taxRates: [],
        billToCustomerID: null,
        billToCustomerLocationID: null,
      };

      try {
        props.handleSaveManualCustomerLocation(partialLocationValues);
        // Close the section if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      className="space-y-8"
    >
      {/* Field: Location Name */}
      <div>
        <Controller
          name="locationName"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Location Name"
              inputName="locationName"
              admin={true}
              required={false}
              {...field}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
        {errors.locationName?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.locationName.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Address Line 1 */}
      <div>
        <Controller
          name="addressLine1"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Address"
              inputName="addressLine1"
              admin={true}
              required={true}
              {...field}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
        {errors.addressLine1?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.addressLine1.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Address (cont.) */}
      <div>
        <Controller
          name="addressLine2"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Address (cont.)"
              inputName="addressLine2"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.addressLine2?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.addressLine2.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: City */}
      <div>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="City"
              inputName="city"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.city?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.city.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Zip Code */}
      <div>
        <Controller
          name="zipCode"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Zip Code"
              inputName="zipCode"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.zipCode?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.zipCode.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: State */}
      <div>
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="State"
              inputName="state"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.state?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.state.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Type */}
      <div>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <BaseInputSelect
              inputName="type"
              text="Type"
              admin={true}
              required={true}
              className="capitalize"
              {...field}
              value={field.value === null ? "" : field.value}
            >
              {/* The empty string for value tricks validation into failing */}
              <option value="" disabled>
                Select a type
              </option>
              {customerTypes.map((type) => {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                );
              })}
            </BaseInputSelect>
          )}
        />
        {errors.type?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.type.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Notes */}
      <div>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Notes"
              inputName="notes"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.notes?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.notes.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {props.children && props.children.CustomerContacts}
      {props.children && props.children.AddMembershipsButton}

      <div className="mt-4">{displayError ? errorMessage : null}</div>

      {/* Action Buttons */}
      <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={closeAndReset}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          disabled={props.isSubmitting}
          isBusy={props.isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
          data-testid="save item"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
    </form>
  );
}
