import create from "zustand";
import { DbRead } from "../database";
import { z } from "zod";

// Define the property types.
interface TypesenseStore {
  scopedSearchKey: string | null;
  updateScopedSearchKey: (key: string) => void;
  loading: boolean;
}

// Export a hook to access global user related data.
export const useTypesenseStore = create<TypesenseStore>((set) => {
  return {
    scopedSearchKey: null,
    loading: false,
    updateScopedSearchKey: async (siteKey: string) => {
      set({ loading: true });
      const response = await DbRead.typesense.getSearchKey(siteKey);
      const responseSchema = z.object({ searchKey: z.string() });
      const parseData = responseSchema.parse(response.data);
      set({ scopedSearchKey: parseData.searchKey, loading: false });
    },
  };
});
