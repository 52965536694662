import React from "react";
import {
  Button,
  CalendarCell,
  CalendarGrid,
  CalendarGridHeader,
  CalendarGridBody,
  CalendarHeaderCell,
  CalendarCellRenderProps,
  DateInput,
  DateRangePicker as RADateRangePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Popover,
  RangeCalendar,
  RangeCalendarContext,
  useSlottedContext,
} from "react-aria-components";
import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "@internationalized/date";
import { VisuallyHidden, useLocale } from "react-aria";
import { RangeValue } from "@react-types/shared";
import {
  CalendarDate,
  today,
  getLocalTimeZone,
  ZonedDateTime,
  CalendarDateTime,
  getDayOfWeek,
} from "@internationalized/date";
import { FaCalendarAlt } from "react-icons/fa";

interface DRPProps {
  value: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  onChange: (
    value: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>,
  ) => void;
}

export default function DateRangePicker(props: DRPProps) {
  const now = today(getLocalTimeZone());
  const [focusedValue, onFocusChange] = React.useState(now);
  const { value, onChange } = props;
  const { locale } = useLocale();

  const presetOptions = [
    { value: { start: now, end: now }, label: "Today" },
    {
      value: { start: startOfWeek(now, locale), end: endOfWeek(now, locale) },
      label: "This week",
    },
    {
      value: { start: now.subtract({ days: 6 }), end: now },
      label: "Last 7 days",
    },
    {
      value: { start: startOfMonth(now), end: endOfMonth(now) },
      label: "This month",
    },
    {
      value: { start: now.subtract({ days: 29 }), end: now },
      label: "Last 30 days",
    },
    {
      label: "Last month",
      value: {
        start: startOfMonth(now.subtract({ months: 1 })),
        end: endOfMonth(now.subtract({ months: 1 })),
      },
    },
    {
      label: "Q1",
      value: {
        start: new CalendarDate(now.year, 1, 1),
        end: new CalendarDate(now.year, 3, 31),
      },
    },
    {
      label: "Q2",
      value: {
        start: new CalendarDate(now.year, 4, 1),
        end: new CalendarDate(now.year, 6, 30),
      },
    },
    {
      label: "Q3",
      value: {
        start: new CalendarDate(now.year, 7, 1),
        end: new CalendarDate(now.year, 9, 30),
      },
    },
    {
      label: "Q4",
      value: {
        start: new CalendarDate(now.year, 10, 1),
        end: new CalendarDate(now.year, 12, 31),
      },
    },
    {
      label: "YTD",
      value: {
        start: new CalendarDate(now.year, 1, 1),
        end: now,
      },
    },
    {
      label: "Last Year",
      value: {
        start: new CalendarDate(now.year - 1, 1, 1),
        end: new CalendarDate(now.year - 1, 12, 31),
      },
    },
    {
      label: "Last 12 Months",
      value: {
        start: now.subtract({ months: 11 }),
        end: now,
      },
    },
  ];

  function renderCalendarCell(cellProps: CalendarCellRenderProps): string {
    const {
      isSelectionStart,
      isSelectionEnd,
      isSelected,
      isFocusVisible,
      isOutsideVisibleRange,
      isOutsideMonth,
      date,
    } = cellProps;
    const dayOfWeek = getDayOfWeek(date, locale);
    const isRoundedLeft =
      isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
    const isRoundedRight =
      isSelected &&
      (isSelectionEnd ||
        dayOfWeek === 6 ||
        date.day === date.calendar.getDaysInMonth(date));

    return `${
      isOutsideVisibleRange || isOutsideMonth
        ? // Hide cells outside the visible range.
          "invisible"
        : // Otherwise, show the cell.
          `
          ${isRoundedLeft ? "rounded-l-full" : ""} ${
            isRoundedRight ? "rounded-r-full" : ""
          } ${
            isSelected ? "bg-primaryOpacity90 text-primary" : "text-gray-800"
          } ${
            isFocusVisible
              ? // Focus ring, visible while the cell has keyboard focus.
                "group-focus:z-2 ring-2 ring-primary ring-offset-2"
              : ""
          } ${
            isSelectionStart || isSelectionEnd
              ? // Darker selection background for the start and end.
                "bg-primary text-primary"
              : ""
          } ${
            !isSelected
              ? // Hover state for non-selected cells.
                "hover:bg-gray-100"
              : ""
          } w-6 text-center focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-1`
    }`;
  }

  return (
    <RangeCalendarContext.Provider
      value={{
        value,
        onChange: onChange as any, // don't judge me
        focusedValue,
        onFocusChange,
      }}
    >
      <RADateRangePicker
        value={props.value}
        onChange={props.onChange}
        className={"flex h-10 text-left text-base"}
        aria-label="Date range selector"
      >
        <Group
          className={
            "flex items-center justify-center rounded-l-md border border-primary bg-white p-1 pl-3 pr-10 transition-colors group-focus-within:border-gray-800 group-hover:border-gray-400 group-focus-within:group-hover:border-gray-800"
          }
        >
          <DateInput slot="start" className={"flex items-center"}>
            {(segment) => (
              <DateSegment
                segment={segment}
                className={
                  "group box-content rounded-sm px-0.5 text-right outline-none focus:bg-primaryDark focus:text-primary"
                }
              />
            )}
          </DateInput>
          <span aria-hidden="true" className={"flex items-center px-2"}>
            –
          </span>
          <DateInput slot="end" className={"flex items-center"}>
            {(segment) => (
              <DateSegment
                segment={segment}
                className={
                  "group box-content rounded-sm px-0.5 text-right outline-none focus:bg-primaryDark focus:text-primary"
                }
              />
            )}
          </DateInput>
        </Group>
        <Button
          className={`-ml-px rounded-l-none rounded-r-md border border-primary px-2 outline-none transition-colors group-focus-within:border-gray-800 group-focus-within:group-hover:border-gray-800 `}
        >
          <FaCalendarAlt className="h-5 w-5 text-gray-800 group-focus-within:text-gray-700" />
          <VisuallyHidden>Choose dates</VisuallyHidden>
        </Button>
        <Popover placement="bottom" offset={2}>
          <Dialog className="mt-2 max-w-full rounded-md border border-gray-300 bg-white p-8 shadow-lg sm:max-w-md">
            <RangeCalendar
              className={"mb-2 flex flex-col justify-center text-gray-800"}
              visibleDuration={{ months: 2 }}
              pageBehavior="single"
              autoFocus={true}
            >
              <div className="flex items-center justify-between gap-2 pb-4">
                <Button
                  slot="previous"
                  className={({ isFocusVisible }) =>
                    isFocusVisible
                      ? "rounded-full p-2 outline-none ring-2 ring-primary ring-offset-1"
                      : "rounded-full p-2 outline-none"
                  }
                >
                  ◀
                </Button>
                <Heading className="ml-2 flex-1 text-center text-xl font-bold" />
                <Button
                  slot="next"
                  className={({ isFocusVisible }) =>
                    isFocusVisible
                      ? "rounded-full p-2 outline-none ring-2 ring-primary ring-offset-1"
                      : "rounded-full p-2 outline-none"
                  }
                >
                  ▶
                </Button>
              </div>
              <div className="flex flex-col justify-center gap-4 sm:flex-row">
                <CalendarGrid>
                  <CalendarGridHeader>
                    {(day) => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
                  </CalendarGridHeader>
                  <CalendarGridBody>
                    {(date) => (
                      <CalendarCell
                        date={date}
                        className={(cellProps: CalendarCellRenderProps) => {
                          return renderCalendarCell({
                            ...cellProps,
                          });
                        }}
                      />
                    )}
                  </CalendarGridBody>
                </CalendarGrid>

                <CalendarGrid offset={{ months: 1 }}>
                  {(date) => (
                    <CalendarCell
                      date={date}
                      className={(cellProps: CalendarCellRenderProps) => {
                        return renderCalendarCell({
                          ...cellProps,
                        });
                      }}
                    />
                  )}
                </CalendarGrid>
              </div>
            </RangeCalendar>
            <div className="flex max-w-full flex-wrap">
              {presetOptions.map((option) => (
                <Preset value={option.value} key={option.label}>
                  {option.label}
                </Preset>
              ))}
            </div>
          </Dialog>
        </Popover>
      </RADateRangePicker>
    </RangeCalendarContext.Provider>
  );
}

interface PresetProps {
  value: { start: CalendarDate; end: CalendarDate };
  children: React.ReactNode;
}

function Preset({ value, children }: PresetProps) {
  const context = useSlottedContext(RangeCalendarContext)!;
  const onPress = () => {
    if (context && context.onChange) {
      context.onChange(value);
    }
  };

  return (
    <Button
      onPress={onPress}
      className="m-1 min-w-max rounded-md bg-primaryDark px-3 py-1 text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-1 focus-visible:ring-offset-white"
    >
      {children}
    </Button>
  );
}
