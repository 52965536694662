//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { dropUndefined, guardIsPlainObject } from "../utils";

export interface Note {
  // has fields of 'note', 'customerID', 'timestampCreated', 'createdBy', 'deleted', 'timestampLastModified', 'lastModifiedBy', 'pinned'
  note: string;
  customerID: string;
  timestampCreated: Timestamp;
  createdBy: string;
  deleted: boolean;
  timestampLastModified: Timestamp;
  lastModifiedBy: string;
  pinned: boolean;
}

export interface ExistingNote extends Note {
  id: string;
  refPath: string;
}

export const NoteManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingNote object.
   */
  createFromFirestoreSnapshot: createNoteFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertNoteForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateNote,
  /** Validate a Note doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateNoteWithFallbacks,
  parseUpdate: validatePartialNote,
};

/**
 * Convert the Document Snapshot into a validated ExistingPriceBookItem object.
 */
function createNoteFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingNote {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateNoteWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertNoteForFirestore(note: Note): DocumentData {
  const localItem = Object.assign({}, note);
  const { ...rest } = localItem;
  const result = dropUndefined(rest);
  return result;
}

/* Zod validation schemas */
function validateNoteWithFallbacks(value: unknown): Note {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = noteSchemaWithFallbacks.parse(value);
  return result;
}

function validateNote(value: unknown): Note {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = noteSchema.parse(value);
  return result;
}
function validatePartialNote(value: unknown): Partial<Note> {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = partialNoteSchema.parse(value);
  return result;
}

const noteSchema = z.object({
  note: z.string().min(1).max(10000),
  customerID: z.string().min(1).max(400),
  timestampCreated: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(400),
  deleted: z.boolean(),
  timestampLastModified: z.instanceof(Timestamp),
  lastModifiedBy: z.string().min(1).max(400),
  pinned: z.boolean(),
});

const noteSchemaWithFallbacks = z.object({
  note: zFallback(
    z.string().min(1).max(10000),
    "",
    "SchemaNoteWithFallbacks: 'note'",
  ),
  customerID: zFallback(
    z.string().min(1).max(400),
    "",
    "SchemaNoteWithFallbacks: 'customerID'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "SchemaNoteWithFallbacks: 'timestampCreated'",
  ),
  createdBy: zFallback(
    z.string().min(1).max(400),
    "",
    "SchemaNoteWithFallbacks: 'createdBy'",
  ),
  deleted: zFallback(z.boolean(), false, "SchemaNoteWithFallbacks: 'deleted'"),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "SchemaNoteWithFallbacks: 'timestampLastModified'",
  ),
  lastModifiedBy: zFallback(
    z.string().min(1).max(400),
    "",
    "SchemaNoteWithFallbacks: 'lastModifiedBy'",
  ),
  pinned: zFallback(z.boolean(), false, "SchemaNoteWithFallbacks: 'pinned'"),
});

const partialNoteSchema = noteSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();
