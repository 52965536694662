//Libs

//Local
import { OTaskTypes, TaskTypesValues } from "./task-types";

// export type NewCraftRecord = z.infer<typeof SchemaNewCraftRecord>
/**
 * Defined craft type codes.
 */
export enum CraftTypes {
  SCAFFOLDING = 10,
  INSULATION = 15,
  WATERBLASTING = 20,
  VACUUM_TRUCK = 21,
  SANDBLASTING = 25,
  FILTER_CLOTHS = 26,
  PAINTING = 30,
  HOISTWELLS = 40,
  MOBILE_EQUIPMENT = 50,
  CAMERAS = 51,
  HVAC = 60,
  ELECTRICAL = 65,
  CARPENTRY = 70,
  PLUMBING = 80,
  PERMITTING = 90,
  GENERAL_LABOR = 98,
  HOUSEKEEPING = 99,
  CLEANING = 100,
  CHECKLISTS = 110,
}

/**
 * Defined craft type codes.
 */
export const OCraftTypes = {
  SCAFFOLDING: 10,
  INSULATION: 15,
  WATERBLASTING: 20,
  VACUUM_TRUCK: 21,
  SANDBLASTING: 25,
  FILTER_CLOTHS: 26,
  PAINTING: 30,
  HOISTWELLS: 40,
  MOBILE_EQUIPMENT: 50,
  CAMERAS: 51,
  HVAC: 60,
  ELECTRICAL: 65,
  CARPENTRY: 70,
  PLUMBING: 80,
  PERMITTING: 90,
  GENERAL_LABOR: 98,
  HOUSEKEEPING: 99,
  CLEANING: 100,
  CHECKLISTS: 110,
} as const;

export type CraftTypeValues = (typeof OCraftTypes)[keyof typeof OCraftTypes];

/** Typeguard ƒn */
export function isValidCraftType(val: unknown): val is CraftTypeValues {
  return Object.values(OCraftTypes).includes(val as any);
}

export function getReadableCraftType(val: unknown): string {
  const craftStr: string | undefined =
    readableCraftTypeMap[val as CraftTypeValues];
  if (!craftStr) return "UNKNOWN";
  return craftStr;
}

/** Map craft integers to their human readable strings. */
export const readableCraftTypeMap: Record<CraftTypeValues, string> = {
  [CraftTypes.SCAFFOLDING]: "Scaffolding",
  [CraftTypes.INSULATION]: "Insulation",
  [CraftTypes.WATERBLASTING]: "Waterblasting",
  [CraftTypes.VACUUM_TRUCK]: "Vacuum Truck",
  [CraftTypes.SANDBLASTING]: "Sandblasting",
  [CraftTypes.PAINTING]: "Painting",
  [CraftTypes.HOISTWELLS]: "Hoistwells",
  [CraftTypes.MOBILE_EQUIPMENT]: "Mobile Equipment",
  [CraftTypes.HVAC]: "HVAC",
  [CraftTypes.ELECTRICAL]: "Electrical",
  [CraftTypes.CARPENTRY]: "Carpentry",
  [CraftTypes.PLUMBING]: "Plumbing",
  [CraftTypes.PERMITTING]: "Permitting",
  [CraftTypes.GENERAL_LABOR]: "General Labor",
  [CraftTypes.HOUSEKEEPING]: "Housekeeping",
  [CraftTypes.CHECKLISTS]: "Checklists",
  [CraftTypes.FILTER_CLOTHS]: "Filter Cloths",
  [CraftTypes.CAMERAS]: "Cameras",
  [CraftTypes.CLEANING]: "Cleaning",
};

/**
 * Get the human readable string version of the craft type number.
 * @param craftType
 */
export function getCraftTypeStringFromReadableString(
  craftType: string | undefined,
) {
  switch (craftType) {
    case "Scaffolding":
      return "scaffolding";
    case "Insulation":
      return "insulation";
    case "Waterblasting":
      return "waterblasting";
    case "Vacuum Truck":
      return "vacuum-truck";
    case "Sandblasting":
      return "sandblasting";
    case "Filter Cloths":
      return "filter-cloths";
    case "Painting":
      return "painting";
    case "Hoistwells":
      return "hoistwells";
    case "Mobile Equipment":
      return "mobile-equipment";
    case "Cameras":
      return "cameras";
    case "HVAC":
      return "hvac";
    case "Electrical":
      return "electrical";
    case "Carpentry":
      return "carpentry";
    case "Plumbing":
      return "plumbing";
    case "Permitting":
      return "permitting";
    case "General Labor":
      return "general-labor";
    case "Housekeeping":
      return "housekeeping";
    case "Checklists":
      return "checklists";
    case "Cleaning":
      return "cleaning";
    default:
      return "UNKNOWN";
  }
}

/* Functions to handle Craft Type   */

export function getCraftTypeFromString(
  craftDetails: string,
): CraftTypeValues | undefined {
  switch (craftDetails) {
    case "scaffolding":
      return OCraftTypes.SCAFFOLDING;
    case "insulation":
      return OCraftTypes.INSULATION;
    case "waterblasting":
      return OCraftTypes.WATERBLASTING;
    case "vacuum-truck":
      return OCraftTypes.VACUUM_TRUCK;
    case "sandblasting":
      return OCraftTypes.SANDBLASTING;
    case "filter-cloths":
      return OCraftTypes.FILTER_CLOTHS;
    case "painting":
      return OCraftTypes.PAINTING;
    case "hoistwells":
      return OCraftTypes.HOISTWELLS;
    case "mobile-equipment":
      return OCraftTypes.MOBILE_EQUIPMENT;
    case "cameras":
      return OCraftTypes.CAMERAS;
    case "hvac":
      return OCraftTypes.HVAC;
    case "electrical":
      return OCraftTypes.ELECTRICAL;
    case "carpentry":
      return OCraftTypes.CARPENTRY;
    case "plumbing":
      return OCraftTypes.PLUMBING;
    case "permitting":
      return OCraftTypes.PERMITTING;
    case "general-labor":
      return OCraftTypes.GENERAL_LABOR;
    case "housekeeping":
      return OCraftTypes.HOUSEKEEPING;
    case "checklists":
      return OCraftTypes.CHECKLISTS;
    case "cleaning":
      return OCraftTypes.CLEANING;
    default:
      return undefined;
  }
}

export function getStringsFromCraftTypes(
  craftDetails: CraftTypeValues,
): string {
  switch (craftDetails) {
    case OCraftTypes.SCAFFOLDING:
      return "scaffolding";
    case OCraftTypes.INSULATION:
      return "insulation";
    case OCraftTypes.WATERBLASTING:
      return "waterblasting";
    case OCraftTypes.VACUUM_TRUCK:
      return "vacuum-truck";
    case OCraftTypes.SANDBLASTING:
      return "sandblasting";
    case OCraftTypes.FILTER_CLOTHS:
      return "filter-cloths";
    case OCraftTypes.PAINTING:
      return "painting";
    case OCraftTypes.HOISTWELLS:
      return "hoistwells";
    case OCraftTypes.MOBILE_EQUIPMENT:
      return "mobile-equipment";
    case OCraftTypes.CAMERAS:
      return "cameras";
    case OCraftTypes.HVAC:
      return "hvac";
    case OCraftTypes.ELECTRICAL:
      return "electrical";
    case OCraftTypes.CARPENTRY:
      return "carpentry";
    case OCraftTypes.PLUMBING:
      return "plumbing";
    case OCraftTypes.PERMITTING:
      return "permitting";
    case OCraftTypes.GENERAL_LABOR:
      return "general-labor";
    case OCraftTypes.HOUSEKEEPING:
      return "housekeeping";
    case OCraftTypes.CHECKLISTS:
      return "checklists";
    case OCraftTypes.CLEANING:
      return "cleaning";
    default:
      const _exhaustiveCheck: never = craftDetails;
      return _exhaustiveCheck;
  }
}

/* Function that return valid task types for a given Craft Type */
export function getValidTaskTypes(
  craftType: CraftTypeValues | undefined,
): TaskTypesValues[] {
  switch (craftType) {
    case OCraftTypes.SCAFFOLDING:
      return [
        OTaskTypes.INSTALLATION,
        OTaskTypes.MODIFICATION,
        OTaskTypes.REMOVAL,
        OTaskTypes.INSPECTION,
        OTaskTypes.PHONE_CALL,
      ];
    case OCraftTypes.WATERBLASTING:
      return [OTaskTypes.WATERBLASTING, OTaskTypes.PHONE_CALL];
    case OCraftTypes.VACUUM_TRUCK:
      return [OTaskTypes.VACUUM_TRUCK, OTaskTypes.PHONE_CALL];
    case OCraftTypes.SANDBLASTING:
      return [OTaskTypes.SANDBLASTING, OTaskTypes.PHONE_CALL];
    case OCraftTypes.INSULATION:
      return [
        OTaskTypes.INSULATION,
        OTaskTypes.ABATEMENT,
        OTaskTypes.PHONE_CALL,
      ];
    case OCraftTypes.PAINTING:
      return [OTaskTypes.PAINTING, OTaskTypes.PHONE_CALL];
    case OCraftTypes.HOISTWELLS:
      return [
        OTaskTypes.INSTALLATION,
        OTaskTypes.REPAIR,
        OTaskTypes.REMOVAL,
        OTaskTypes.INSPECTION,
        OTaskTypes.PHONE_CALL,
      ];
    case OCraftTypes.CAMERAS:
      return [
        OTaskTypes.INSTALLATION,
        OTaskTypes.REPAIR,
        OTaskTypes.REMOVAL,
        OTaskTypes.INSPECTION,
        OTaskTypes.PHONE_CALL,
      ];
    case OCraftTypes.FILTER_CLOTHS:
      return [
        OTaskTypes.CLOTH_REPLACEMENT,
        OTaskTypes.REPAIR,
        OTaskTypes.HOUSEKEEPING,
        OTaskTypes.INSPECTION,
        OTaskTypes.PHONE_CALL,
      ];
    case OCraftTypes.MOBILE_EQUIPMENT:
      return [OTaskTypes.REPAIR, OTaskTypes.INSPECTION, OTaskTypes.PHONE_CALL];
    case OCraftTypes.HVAC:
      return [
        OTaskTypes.PHONE_CALL,
        OTaskTypes.ESTIMATE,
        OTaskTypes.INSTALLATION,
        OTaskTypes.REPAIR,
        OTaskTypes.WARRANTY,
        OTaskTypes.SERVICE_CALL,
        OTaskTypes.EMERGENCY_CALL,
        OTaskTypes.CALL_BACK,
        OTaskTypes.NO_HEAT,
        OTaskTypes.NO_AC,
        OTaskTypes.GENERATOR_INSTALL,
        OTaskTypes.REFRIGERATION,
        OTaskTypes.FURNACE_SERVICE,
        OTaskTypes.PREVENTATIVE_MAINTENANCE,
        OTaskTypes.HEARTH_SERVICE_WOOD_PELLET,
        OTaskTypes.WETT_INSPECTION,
        OTaskTypes.BOILER_SERVICE,
        OTaskTypes.AIR_CONDITIONING_SERVICE,
        OTaskTypes.FURNACE_TUNEUP,
        OTaskTypes.SEASONAL_TUNEUP,
        OTaskTypes.RESIDENTIAL_SERVICE,
        OTaskTypes.COMMERCIAL_SERVICE,
        OTaskTypes.COMFORT_CLUB,
        OTaskTypes.NO_COOL,
        OTaskTypes.WATER_LEAK,
        OTaskTypes.OTHER,
        OTaskTypes.INSTALL_PART_SERVICE_LARGE,
        OTaskTypes.INSTALL_PART_SERVICE_NORMAL,
        OTaskTypes.INSTALL_PART_SERVICE_MED,
        OTaskTypes.MAINTENANCE_PLAN,
        OTaskTypes.CHECK_UP,
        OTaskTypes.MARINE,
        OTaskTypes.FILTER_CHANGE_OUT,
        OTaskTypes.RESIDENTIAL_NEW_CONSTRUCTION,
        OTaskTypes.COMMERCIAL_NEW_CONSTRUCTION,
        OTaskTypes.MEETING,
        OTaskTypes.SECOND_OPINION,
        OTaskTypes.AQUA_PRO,
        OTaskTypes.ORDER_FILTERS,
        OTaskTypes.INSTALL_EVALUATION,
        OTaskTypes.MEASURE_FOR_FILTERS,
        OTaskTypes.GAS_N_GO,
        OTaskTypes.PICK_UP_PARTS,
        OTaskTypes.RECALL,
        OTaskTypes.SERVICE_AND_REPAIR,
        OTaskTypes.AC_NOT_COOLING,
        OTaskTypes.FOLLOW_UP_JOB_COMPLETION,
        OTaskTypes.SPLIT_SYSTEM_INSTALL,
        OTaskTypes.POST_INSTALL_INSPECTION,
        OTaskTypes.PMA_RI,
        OTaskTypes.DUCT_REPAIR_CLEANING,
        OTaskTypes.CLEAN_AND_CHECK,
        OTaskTypes.GENERAL,
        OTaskTypes.PART_REPLACEMENT,
        OTaskTypes.AC_NOT_HEATING,
        OTaskTypes.SERVICE_CALL_ERROR_CODE,
        OTaskTypes.FAIL_INSPECTION_CORRECTION,
        OTaskTypes.WARRANTY_SERVICE_CALL,
        OTaskTypes.SYSTEM_NOT_WORKING,
        OTaskTypes.WEEKEND_SERVICE_CALL,
        OTaskTypes.AFTER_HOUR_SERVICE_CALL,
        OTaskTypes.MASTIC_SEAL,
        OTaskTypes.WATER_LEAKING,
        OTaskTypes.MAINTENANCE_TUNE_UP,
        OTaskTypes.DUCT_CHANGE_OUT,
        OTaskTypes.FORM_PICK_UP_AND_DELIVERY,
        OTaskTypes.PACKAGE_SYSTEM_INSTALL,
        OTaskTypes.PART_DELIVERY,
        OTaskTypes.BLOW_IN_INSULATION,
        OTaskTypes.DRYER_VENT_CLEANING,
      ];
    case OCraftTypes.PLUMBING:
      return [
        OTaskTypes.REPAIR,
        OTaskTypes.ESTIMATE,
        OTaskTypes.INSTALLATION,
        OTaskTypes.PHONE_CALL,
        OTaskTypes.WARRANTY,
        OTaskTypes.SERVICE_CALL,
        OTaskTypes.EMERGENCY_CALL,
        OTaskTypes.CALL_BACK,
        OTaskTypes.NO_HEAT,
        OTaskTypes.LEAKY_PIPE_DRAIN,
        OTaskTypes.CLOGGED_DRAIN,
        OTaskTypes.GENERATOR_INSTALL,
        OTaskTypes.NO_HOT_WATER,
        OTaskTypes.REFRIGERATION,
        OTaskTypes.PREVENTATIVE_MAINTENANCE,
        OTaskTypes.HWT_INSTALL,
        OTaskTypes.WATER_SOFTENER_INSTALL,
        OTaskTypes.FROZEN_PIPES,
        OTaskTypes.BOILER_SERVICE,
        OTaskTypes.RESIDENTIAL_SERVICE,
        OTaskTypes.COMMERCIAL_SERVICE,
        OTaskTypes.COMFORT_CLUB,
      ];
    case OCraftTypes.CARPENTRY:
      return [OTaskTypes.CARPENTRY, OTaskTypes.PHONE_CALL];
    case OCraftTypes.ELECTRICAL:
      return [
        OTaskTypes.REPAIR,
        OTaskTypes.ESTIMATE,
        OTaskTypes.PHONE_CALL,
        OTaskTypes.ELECTRICAL,
      ];
    case OCraftTypes.HOUSEKEEPING:
      return [OTaskTypes.HOUSEKEEPING, OTaskTypes.PHONE_CALL];
    case OCraftTypes.GENERAL_LABOR:
      return [
        OTaskTypes.GENERAL_LABOR,
        OTaskTypes.AUTO_DETAILING,
        OTaskTypes.PHONE_CALL,
        OTaskTypes.BOLLARDS,
        OTaskTypes.CONCRETE,
        OTaskTypes.CONVEYOR,
        OTaskTypes.DOORS,
        OTaskTypes.ELECTRICAL,
        OTaskTypes.EPOXY,
        OTaskTypes.FENCING,
        OTaskTypes.HANDYMAN,
        OTaskTypes.MASONRY,
        OTaskTypes.PARKING_LOT,
        OTaskTypes.ROOFING,
        OTaskTypes.SPECIALTY_PROJECT,
        OTaskTypes.ESTIMATE,
      ];
    case OCraftTypes.PERMITTING:
      return [
        OTaskTypes.LOCKOUT,
        OTaskTypes.WALKTHROUGH,
        OTaskTypes.PERFORMING_WORK,
        OTaskTypes.PHONE_CALL,
      ];
    case OCraftTypes.CHECKLISTS:
      return [OTaskTypes.OPERATOR_ROUNDS, OTaskTypes.PHONE_CALL];
    case OCraftTypes.CLEANING:
      return [
        OTaskTypes.PHONE_CALL,
        OTaskTypes.ESTIMATE,
        OTaskTypes.CLEANING,
        OTaskTypes.AIRDUCT_CLEANING,
        OTaskTypes.CARPET_CLEANING,
        OTaskTypes.CARPET_STRETCHING,
        OTaskTypes.COMMERCIAL_CARPET_CLEANING,
        OTaskTypes.DRYER_VENT_CLEANING,
        OTaskTypes.TILE_AND_GROUT,
        OTaskTypes.UPHOLSTERY_CLEANING,
        OTaskTypes.WATER_MITIGATION,
        OTaskTypes.MOLD_MITIGATION,
        OTaskTypes.RETOUCH,
        OTaskTypes.PICKUP_DROPOFF,
        OTaskTypes.IN_OFFICE,
        OTaskTypes.HARDWOOD,
        OTaskTypes.RESTORATION,
        OTaskTypes.PRESSURE_WASHING,
        OTaskTypes.DUMPSTER_RENTAL,
        OTaskTypes.COMMERCIAL,
      ];
    default:
      return [];
  }
}

/* Functions to handle Craft Type Record  */
/**
 * Get the string version of the craft type used in database paths.
 * @param craftType
 */
export function getCraftTypeRecordString(craftType: CraftTypeValues): string {
  switch (craftType) {
    case OCraftTypes.SCAFFOLDING:
      return "scaffoldRecords";
    case OCraftTypes.INSULATION:
      return "insulationRecords";
    case OCraftTypes.WATERBLASTING:
      return "waterblastingRecords";
    case OCraftTypes.VACUUM_TRUCK:
      return "vacuumTruckRecords";
    case OCraftTypes.SANDBLASTING:
      return "sandblastingRecords";
    case OCraftTypes.FILTER_CLOTHS:
      return "filterCloths";
    case OCraftTypes.PAINTING:
      return "paintingRecords";
    case OCraftTypes.HOISTWELLS:
      return "hoistwellRecords";
    case OCraftTypes.MOBILE_EQUIPMENT:
      return "mobileEquipmentRecords";
    case OCraftTypes.CAMERAS:
      return "cameras";
    case OCraftTypes.HVAC:
      return "hvacRecords";
    case OCraftTypes.ELECTRICAL:
      return "electricalRecords";
    case OCraftTypes.CARPENTRY:
      return "carpentryRecords";
    case OCraftTypes.PLUMBING:
      return "plumbingRecords";
    case OCraftTypes.PERMITTING:
      return "permittingRecords";
    case OCraftTypes.GENERAL_LABOR:
      return "generalLaborRecords";
    case OCraftTypes.HOUSEKEEPING:
      return "housekeepingRecords";
    case OCraftTypes.CHECKLISTS:
      return "checklistRecords";
    case OCraftTypes.CLEANING:
      return "cleaningRecords";
    default:
      return "UNKNOWN";
  }
}

export function getCraftTypeFromRecordString(
  craftDetails: string,
): CraftTypeValues | undefined {
  switch (craftDetails) {
    case "scaffoldRecords":
      return OCraftTypes.SCAFFOLDING;
    case "insulationRecords":
      return OCraftTypes.INSULATION;
    case "waterblastingRecords":
      return OCraftTypes.WATERBLASTING;
    case "vacuumTruckRecords":
      return OCraftTypes.VACUUM_TRUCK;
    case "sandblastingRecords":
      return OCraftTypes.SANDBLASTING;
    case "filterCloths":
      return OCraftTypes.FILTER_CLOTHS;
    case "paintingRecords":
      return OCraftTypes.PAINTING;
    case "hoistwellRecords":
      return OCraftTypes.HOISTWELLS;
    case "mobileEquipmentRecords":
      return OCraftTypes.MOBILE_EQUIPMENT;
    case "cameras":
      return OCraftTypes.CAMERAS;
    case "hvacRecords":
      return OCraftTypes.HVAC;
    case "electricalRecords":
      return OCraftTypes.ELECTRICAL;
    case "carpentryRecords":
      return OCraftTypes.CARPENTRY;
    case "plumbingRecords":
      return OCraftTypes.PLUMBING;
    case "permittingRecords":
      return OCraftTypes.PERMITTING;
    case "generalLaborRecords":
      return OCraftTypes.GENERAL_LABOR;
    case "housekeepingRecords":
      return OCraftTypes.HOUSEKEEPING;
    case "checklistRecords":
      return OCraftTypes.CHECKLISTS;
    case "cleaningRecords":
      return OCraftTypes.CLEANING;
    default:
      return undefined;
  }
}

/**
 * For local use: get the defaultValues for craftTypes.
 *
 * @param originallyCheckedWorkTypes The values that were checked before entering
 * the dialog.
 * @param siteKeyWorkTypes The work types belonging to the siteKey.
 * @returns A list of CraftTypeValues/undefined. Undefined if value should not be
 * checked upon entering the dialog.
 */
export function getSortedWorkTypesList(
  originallyCheckedWorkTypes: CraftTypeValues[],
  siteKeyWorkTypes: number[],
): CraftTypeValues[] {
  const workTypeEnums = siteKeyWorkTypes.filter(isValidCraftType);

  const sortedList = alphabetizeWorkTypes(workTypeEnums);

  const checkedWT: CraftTypeValues[] = [];
  sortedList.forEach((code) => {
    if (originallyCheckedWorkTypes.includes(code)) {
      checkedWT.push(code);
    }
  });
  return checkedWT;
}

export function alphabetizeWorkTypes(
  workTypes: CraftTypeValues[],
): CraftTypeValues[] {
  return workTypes.sort((a, b) => {
    const aName = getReadableCraftType(a);
    const bName = getReadableCraftType(b);
    return aName.localeCompare(bName);
  });
}
