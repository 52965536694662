// Lib
import { PiPhoneIncoming } from "react-icons/pi";
import { phoneUtils } from "../utils/phoneUtils";

const statusColorClasses: Record<string, string> = {
  ringing: "text-greenPass",
  "on-hold": "text-redFail", // This may not exist in Twilio, or could be 'queued' status?
  "in-progress": "text-blue-500",
  busy: "text-orange-500",
  completed: "text-gray-500",
  cleared: "text-gray-500", // We allow users to manually set a status to 'cleared' to handle odd database records.
  fallback: "text-gray-500",
};

type Props = {
  phone: string;
  /**
   * Colors phone icon for certain statuses: ringing, on hold, busy, in progress
   */
  callStatus?: string;
  name?: string;
  activeCallCount?: number;
  /**
   * Number of customer IDs that were matched by the server. If provided, a link to the customer search page will be displayed.
   */
  numberOfCustomerIDs?: number;
  /**
   * Action to navigate to the customer's detail page.
   */
  onGoToCustomer?: () => void;
  /**
   * Action to display or go to the recent call list modal.
   */
  onGoToCallList?: () => void;
  /**
   * Action to navigate to the create new task page for a customer.
   */
  onGoToCreateNewTaskPage?: () => void;
  /**
   * Action to navigate to the customer search page.
   */
  onGoToCustomerSearch?: (args: { query: string }) => void;
  /**
   * Additional classes to apply to the root div element.
   */
  rootClasses?: string;
};

/**
 * A component that displays an alert for an incoming call. Also used as a list item in the IncomingCallListModal.
 *
 * Contains logic to display different information and links depending on provided props.
 *
 * Case 1: No customer found -> display phone number and link to create new task page.
 * Case 2: Customer found -> display customer name and phone number, and link to customer detail page.
 * Case 3: Multiple customers found -> display a message and link to the customer search page.
 */
export default function IncomingCallAlert(props: Props): JSX.Element {
  const rootClasses = props.rootClasses ?? "text-white";
  const callStatus = props.callStatus ?? "fallback";
  const callStatusColor =
    statusColorClasses[callStatus] ?? statusColorClasses.fallback;

  const hasMultipleCustomerIDs =
    typeof props.numberOfCustomerIDs === "number" &&
    props.numberOfCustomerIDs > 1;

  return (
    <div className={`${rootClasses} flex items-center gap-4`}>
      {/* Icon */}
      <div>
        <PiPhoneIncoming size={48} className={`${callStatusColor}`} />
      </div>
      {/* Call Info */}
      <div className="flex flex-col justify-center overflow-hidden whitespace-nowrap">
        {/* Customer Match Case */}
        {props.name && props.onGoToCustomer ? (
          <>
            {/* Multiple records found case */}
            {hasMultipleCustomerIDs ? (
              <div
                role={"button"}
                data-testid="customer-name"
                className="cursor-pointer truncate font-bold text-inherit underline"
                onClick={() => {
                  if (!props.onGoToCustomerSearch) return;
                  props.onGoToCustomerSearch({ query: props.phone });
                }}
              >
                {`Multiple records (${props.name}?)`}
              </div>
            ) : (
              // Single record found case
              <div
                role={"button"}
                data-testid="customer-name"
                className="cursor-pointer truncate font-bold text-inherit underline"
                onClick={props.onGoToCustomer}
              >
                {props.name}
              </div>
            )}
            <div
              data-testid="incoming-phone-number"
              className="truncate text-inherit"
            >
              {phoneUtils.display(props.phone)}
            </div>
          </>
        ) : (
          // No Customer Match Case
          <div
            data-testid="incoming-phone-number"
            className="cursor-pointer truncate font-bold text-inherit underline"
            onClick={props.onGoToCreateNewTaskPage}
          >
            {phoneUtils.display(props.phone)}
          </div>
        )}
      </div>
      {/* Call Count */}
      {(props.activeCallCount || 0) > 1 && (
        <div
          role={props.onGoToCallList ? "button" : undefined}
          className={`${
            props.onGoToCallList ? "cursor-pointer hover:bg-yellow-500" : ""
          } flex h-10 w-10 items-center justify-center rounded bg-yellow-400 font-semibold text-gray-900`}
          onClick={props.onGoToCallList ?? undefined}
        >
          <div data-testid="call-count">{props.activeCallCount}</div>
        </div>
      )}
    </div>
  );
}
