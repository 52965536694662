/**
 * Types, schemas, and validation for server-jobs (recurring jobs)
 *
 * [Zod Docs](https://github.com/colinhacks/zod)
 */

// Libs
import { DocumentSnapshot } from "firebase/firestore";
import { z, ZodLiteral, ZodObject, ZodRawShape } from "zod";

// Local
import {
  guardIsPlainObject,
  isValidISO8601,
  msgInvalidISO8601,
} from "../utils";
import { Schedule } from "../rschedule";
import { NotFoundError } from "../error-classes";

// Had an error where this was a non-exported package path: [ERR_PACKAGE_PATH_NOT_EXPORTED]
// work around: define the equivalent type here.
// import { Primitive } from "zod/lib/helpers/typeAliases";
type Primitive = string | number | bigint | boolean | null | undefined;

/** START HERE if you need to add a new job type to the system. */
export const OServerJobTypes = {
  REPORT: "REPORT",
  EMAIL: "EMAIL",
  NOTIFICATION: "NOTIFICATION",
  CREATE_CHECKLIST: "CREATE_CHECKLIST",
} as const;
export type ServerJobTypes =
  (typeof OServerJobTypes)[keyof typeof OServerJobTypes];

function isServerJob(value: unknown): value is ServerJobTypes {
  return Object.values(OServerJobTypes).includes(value as any);
}

export const OServerJobStatus = {
  SCHEDULED: "SCHEDULED",
  SENT: "SENT",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
} as const;
export type ServerJobStatus =
  (typeof OServerJobStatus)[keyof typeof OServerJobStatus];

type ServerJobCommon = {
  /** The siteKey associated with the job */
  siteKey: string;
  /** An ISO Date time string */
  performAt: string;
  /** Indicates which type of server job. There are several options. */
  kind: ServerJobTypes;
  /** The current status of the job */
  status: ServerJobStatus;
  /** A descriptive title for the job */
  title: string;
  /**
   * A RRULE.js string that, if present, will instruct the server to create
   * another job instance at the next scheduled datetime.
   */
  schedule?: Schedule.JSON;
  // TODO: add a pause property or functionality?
};

export const ServerJobCommonSchema = z.object({
  siteKey: z.string().min(1).max(200),
  performAt: z.string().refine(isValidISO8601, msgInvalidISO8601),
  kind: z.nativeEnum(OServerJobTypes),
  status: z.nativeEnum(OServerJobStatus),
  title: z
    .string()
    .max(1000)
    .or(z.any().transform(() => "unknown")),
  schedule: z.string().min(1).optional(),
});

// [SPECIFIC SERVER JOB TYPES]

export interface ChecklistTaskJob extends ServerJobCommon {
  kind: "CREATE_CHECKLIST";
  /** The Document ID for the checklist template (not the full path) */
  craftRecordID: string;
}
export const ChecklistTaskJobSchema = ServerJobCommonSchema.extend({
  kind: z.literal(OServerJobTypes.CREATE_CHECKLIST),
  craftRecordID: z.string().min(1).max(2000),
});

/** Not in use yet */
interface EmailJob extends ServerJobCommon {
  kind: "EMAIL";
  address: string;
}
const EmailJobSchema = ServerJobCommonSchema.extend({
  kind: z.literal(OServerJobTypes.EMAIL),
  address: z.string().email(),
});

/** Not in use yet */
interface ReportJob extends ServerJobCommon {
  kind: "REPORT";
  reportType: "checklist-rollup";
}
const ReportJobSchema = ServerJobCommonSchema.extend({
  kind: z.literal(OServerJobTypes.REPORT),
  reportType: z.enum(["checklist-rollup"]),
});

/** Not in use yet */
interface NotificationJob extends ServerJobCommon {
  kind: "NOTIFICATION";
  token: string;
}
const NotificationJobSchema = ServerJobCommonSchema.extend({
  kind: z.literal(OServerJobTypes.NOTIFICATION),
  token: z.string().min(1).max(2000),
});

export type NewServerJob =
  | ChecklistTaskJob
  | EmailJob
  | ReportJob
  | NotificationJob;

type PropsFromFirestore = {
  id: string;
  refPath: string;
};

export type ExistingServerJob = NewServerJob & PropsFromFirestore;

export const ServerJobsManager = {
  createFromFirestoreSnapshot: createServerJobFromFirestoreSnapshot,
  parse: validateServerJob,
};
/**
 * Convert the Document Snapshot into the ExistingServerJob object.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createServerJobFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingServerJob {
  if (!snapshot.exists()) throw new NotFoundError("Dccument does not exist");
  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateServerJob(snapshotData),
  };
}

// This ensures we add zod schemas as new Server Job Types are added.
const serverJobSchemas: Record<
  ServerJobTypes,
  ZodDiscriminatedUnionOption<"kind", Primitive>
> = {
  CREATE_CHECKLIST: ChecklistTaskJobSchema,
  REPORT: ReportJobSchema,
  EMAIL: EmailJobSchema,
  NOTIFICATION: NotificationJobSchema,
} as const;
const schemasList = Object.values(serverJobSchemas);

// Uses shiny new feature in zod 3.12. Should have better error messages.
// [Zod discriminated unions](https://github.com/colinhacks/zod#discriminated-unions)
// NOTE: This list in the second argument is complicated to type 😒
const discriminatedUnionSchema = z.discriminatedUnion("kind", [
  schemasList[0],
  schemasList[1],
  ...schemasList.slice(2),
]);

// Add logical checks. Zod "refinements".
// For instance if property B needs to exist if property A is true, etc.
const combinedSchema = discriminatedUnionSchema;

/**
 * Parse data and return a validated NewServerJob or throw an error.
 */
function validateServerJob(value: unknown): NewServerJob {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }

  if (!isServerJob(value.kind)) {
    throw new Error(
      `The server job type was not recognized. Value: ${value.kind}`,
    );
  }

  switch (value.kind) {
    case "CREATE_CHECKLIST": {
      return combinedSchema.parse(value) as NewServerJob;
    }
    case "EMAIL": {
      return combinedSchema.parse(value) as NewServerJob;
    }
    case "NOTIFICATION": {
      return combinedSchema.parse(value) as NewServerJob;
    }
    case "REPORT": {
      return combinedSchema.parse(value) as NewServerJob;
    }
    default: {
      const _exhaustiveCheck: never = value.kind;
      return _exhaustiveCheck;
    }
  }
}

// Copying this from the zod types.d.ts file since it's not exported.
// so we can pre-defind the serverJobSchemas and use in the discriminationUnion function.
declare type ZodDiscriminatedUnionOption<
  Discriminator extends string,
  DiscriminatorValue extends Primitive,
> = ZodObject<
  {
    [key in Discriminator]: ZodLiteral<DiscriminatorValue>;
  } & ZodRawShape,
  any,
  any
>;
