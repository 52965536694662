//Libs

//Local

interface Props {
  children: {
    Stepper: React.ReactNode;
    SiteDetails: React.ReactNode;
  };
}

export default function CreateSitePage(props: Props) {
  return (
    <div className="mx-auto w-full items-center px-2 pb-10 md:px-14 lg:max-w-screen-lg lg:gap-x-28 lg:px-2">
      <div className="flex flex-col space-y-8">
        {props.children.Stepper}
        <div className="pt-8 text-5xl font-semibold text-primary">
          Create a Site
        </div>
        {props.children.SiteDetails}
      </div>
    </div>
  );
}
