// Libs
import axios from "axios";

// Local
import { logger as devLogger } from "../logging";

const mapsApiBaseUrl = "https://maps.googleapis.com/maps/api/geocode/json?";
const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;

export async function convertAddressToCoords(address: string): Promise<
  | {
      latitude: number;
      longitude: number;
    }
  | undefined
> {
  const convertedAddress = address.replaceAll(" ", "+");
  const fullApiRoute = `${mapsApiBaseUrl}address=${convertedAddress}&key=${apiKey}`;

  try {
    const response = await axios.get(fullApiRoute);
    const results = response.data["results"];
    devLogger.debug("results", results);
    const { lat: latitude, lng: longitude } =
      response.data["results"][0].geometry.location;
    return { latitude, longitude };
  } catch (e) {
    devLogger.warn(e);
  }
}

export async function convertCoordsToAddress(
  coords: google.maps.LatLngLiteral,
): Promise<string | undefined> {
  const fullApiRoute = `${mapsApiBaseUrl}latlng=${coords.lat},${coords.lng}&key=${apiKey}`;

  try {
    const response = await axios.get(fullApiRoute);
    const results = response.data["results"];
    return results[0].formatted_address;
  } catch (error) {
    devLogger.error("Reverse geocoding error: ", error);
  }
}
