import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

/** Given a Luxon DateTime object, returns a Firestore Timestamp. */
export function convertLuxonDTToFSTimestamp(dt: unknown): Timestamp {
  const isLuxonDT = DateTime.isDateTime(dt);
  if (!isLuxonDT) {
    throw new Error(`Input is not a Luxon DateTime object. Received: ${dt}`);
  }
  // Luxon DT -> milliseconds -> Timestamp
  const ms = dt.toMillis();
  const ts = Timestamp.fromMillis(ms);
  if (Number.isNaN(ts.seconds)) {
    throw new Error(`Invalid Timestamp. Received: ${dt}`);
  }
  return ts;
}
