// Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

type CommonProps = {
  beginningText?: string;
  endingText?: string;
  linkText: string;
  onLinkClick: () => void;
  type: "success" | "warning" | "error";
};
type DismissibleProps = CommonProps & {
  dismissible?: true;
  onDismiss: () => void;
};
type NotDismissibleProps = CommonProps & {
  dismissible?: false;
};
type Props = DismissibleProps | NotDismissibleProps;

/** If dismissible is true, you have to pass in an onClick handler. */
export function StyledMessageWithLink(props: Props): JSX.Element {
  const typeStyles: Record<Props["type"], string> = {
    success: "bg-green-100 text-green-800",
    warning: "bg-orange-100 text-orange-800",
    error: "bg-red-100 text-red-900",
  };

  if (!props.dismissible) {
    return (
      <p
        className={`${
          typeStyles[props.type]
        } inline-block rounded-md px-4 py-2 text-center font-medium`}
      >
        {props.beginningText}
        <span
          className="cursor-pointer border-b border-current pb-1"
          onClick={props.onLinkClick}
        >
          {props.linkText}
        </span>
        {props.endingText}
      </p>
    );
  } else {
    return (
      <div
        className={`${
          typeStyles[props.type]
        } flex items-center justify-between rounded-md  px-4 py-2 text-center font-medium`}
      >
        <p>
          {props.beginningText}
          <span
            className="cursor-pointer border-b border-current pb-1"
            onClick={props.onLinkClick}
          >
            {props.linkText}
          </span>
          {props.endingText}
        </p>
        <XMarkIcon
          aria-label="dismiss message"
          className="ml-3 h-5 w-5 flex-shrink-0 cursor-pointer"
          onClick={props.onDismiss}
        />
      </div>
    );
  }
}
