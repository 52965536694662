//Libs
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import { ExistingChecklistResponse } from "../models/checklist-response";
import ResponseCardBase, { ResponseCardBaseProps } from "./ResponseCardBase";
import { logger as devLogger } from "../logging";

interface Props {
  response: ExistingChecklistResponse;
  onSaveValue: (value: string | null, responseID: string) => Promise<void>;
  /**
   * Takes IconRow as children.
   */
  children: React.ReactNode;
}

export default function ResponseCardSelectionDropdown(
  props: Props & Omit<ResponseCardBaseProps, "children">,
) {
  const ResponseSelectionSchema = z.object({
    responseSelectionValue: z.string().max(200),
  });
  type ResponseSelectionState = z.infer<typeof ResponseSelectionSchema>;

  async function onSubmit(data: ResponseSelectionState) {
    if (props.response.id == null) {
      devLogger.error(`ResponseID was not found. Value: ${props.response.id}`);
      return;
    }

    if (data.responseSelectionValue === props.response.responseValue) {
      devLogger.debug("The value hasn't changed");
      return;
    }

    await props.onSaveValue(data.responseSelectionValue, props.response.id);
    devLogger.debug("Response value was successfully recorded");
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ResponseSelectionState>({
    defaultValues: {
      responseSelectionValue:
        typeof props.response.responseValue === "string"
          ? props.response.responseValue
          : "",
    },
    resolver: zodResolver(ResponseSelectionSchema),
  });

  const disabledOption = "Select an option";
  const [selectedOption, setSelectedOption] = useState(
    props.response.responseValue !== null &&
      typeof props.response.responseValue === "string"
      ? props.response.responseValue
      : disabledOption,
  );
  const passingOptions = props.response.passingOptions;

  let dropdownBgColor = "bg-white";
  let dropdownTextColor = "text-gray-700";
  if (selectedOption !== disabledOption) {
    if (
      Array.isArray(passingOptions) &&
      passingOptions.includes(selectedOption)
    ) {
      dropdownBgColor = "bg-green-200";
      dropdownTextColor = "text-greenPass";
    } else if (
      Array.isArray(passingOptions) &&
      !passingOptions.includes(selectedOption)
    ) {
      dropdownBgColor = "bg-red-200";
      dropdownTextColor = "text-redFail";
    }
  }

  function pOptionIsInSOptions(sOption: string): boolean {
    let pOptionIsInSOptions = false;
    if (props.response.passingOptions !== null) {
      pOptionIsInSOptions = props.response.passingOptions.includes(sOption);
    }
    return pOptionIsInSOptions;
  }

  const inputForm = (
    <>
      <Listbox
        key={props.response.id}
        value={selectedOption}
        onChange={(event) => {
          setSelectedOption(event);
          setValue("responseSelectionValue", event);
        }}
      >
        <div className="relative my-7 ">
          <Listbox.Button
            className={`relative w-full cursor-default rounded-md border border-gray-300 sm:w-72 ${dropdownBgColor} ${dropdownTextColor} py-2 pl-3 pr-10 text-left font-bold focus:border-primaryLight focus:outline-none focus:ring-1 focus:ring-primaryLight sm:text-sm`}
          >
            <span className="block truncate">{selectedOption}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <form autoComplete="off" onClick={handleSubmit(onSubmit)}>
              <Listbox.Options
                id={props.response.id}
                {...register("responseSelectionValue")}
                className="absolute z-10 mt-1 max-h-60 w-full rounded-md bg-white text-base ring-1 ring-black/5 focus:outline-none sm:w-72 sm:text-sm"
              >
                {props.response.selectionOptions?.map((item) => {
                  const bgColor =
                    pOptionIsInSOptions(item) === false
                      ? "bg-red-200"
                      : "bg-green-200";
                  const textColor =
                    pOptionIsInSOptions(item) === false
                      ? "text-redFail"
                      : "text-greenPass";
                  return (
                    <Listbox.Option
                      key={item}
                      className={({ selected, active }) =>
                        `${
                          active || selected
                            ? `${textColor} ${bgColor}`
                            : "text-gray-500"
                        }
                         relative cursor-default select-none py-2 pl-10 pr-4`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <Fragment>
                          <span
                            className={`${
                              selected ? `font-bold` : "font-normal"
                            } block truncate`}
                          >
                            {item}
                          </span>
                          {selected ? (
                            <span
                              className={`${active ? `${textColor}` : ""}
                               absolute inset-y-0 left-0 flex items-center pl-3`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </Fragment>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </form>
          </Transition>
        </div>
      </Listbox>

      {errors.responseSelectionValue?.message && (
        <div className="ml-1 mt-2 text-xs text-red-400">
          {errors.responseSelectionValue?.message}
        </div>
      )}
    </>
  );

  return (
    <ResponseCardBase response={props.response}>
      {{
        input: inputForm,
        iconRow: props.children,
      }}
    </ResponseCardBase>
  );
}
