// Libs
import { SetStateAction, useState } from "react";
import { DocumentData } from "firebase/firestore";

// Local
import {
  getNextValidTaskStatus,
  getTaskStatusClasses,
  getTaskStatusIcon,
} from "../../assets/js/tasks";
import { ExistingSiteKey } from "../../models/site-key";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { ExistingTask } from "../../models/task";
import {
  TaskStatus,
  getTaskStatusStringSuccinct,
} from "../../models/task-status";
import { changeTaskStatus } from "./functions";
import LoadingSpinner from "../LoadingSpinner";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { logger } from "../../logging";

interface Props {
  // DATA
  task: ExistingTask;
  userPermissions: ExistingSiteKeyUserPermissions;
  siteKeyDoc: ExistingSiteKey;
  uid: string;

  // FUNCTIONS
  handleUpdateTask: (updateData: DocumentData, taskID: string) => Promise<void>;
  setOpenTaskStatusChangeDialog: (val: SetStateAction<string | null>) => void;
  setShowCraftPersistence: (val: SetStateAction<boolean>) => void;
  setNextTaskStatus: (val: SetStateAction<TaskStatus | null>) => void;
}

/** Could be 0, 1, or 2 buttons. (The component handles these cases) */
export default function ChangeTaskStatusButton(props: Props) {
  const nextValidTaskStatusList = getNextValidTaskStatus(
    props.task,
    props.userPermissions,
    props.siteKeyDoc,
  );
  // Add CANCELED task status to nextValidTaskStatusList if siteKey.validTaskStatusCodes contains CANCELED and if the task is not completed or canceled
  if (
    props.siteKeyDoc.validTaskStatusCodes.includes(TaskStatus.CANCELED) &&
    ![TaskStatus.COMPLETE, TaskStatus.CANCELED].includes(props.task.taskStatus)
  ) {
    nextValidTaskStatusList.push(TaskStatus.CANCELED);
  }
  // Add AWAITING_PARTS task status to nextValidTaskStatusList if siteKey.validTaskStatusCodes contains AWAITING_PARTS and if the task is not completed or canceled or already set to AWAITING_PARTS
  if (
    props.siteKeyDoc.validTaskStatusCodes.includes(TaskStatus.AWAITING_PARTS) &&
    ![
      TaskStatus.COMPLETE,
      TaskStatus.CANCELED,
      TaskStatus.AWAITING_PARTS,
      TaskStatus.PARTS_ORDERED,
    ].includes(props.task.taskStatus)
  ) {
    nextValidTaskStatusList.push(TaskStatus.AWAITING_PARTS);
  }

  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const [isBusy, setIsBusy] = useState(false);

  return (
    <>
      {nextValidTaskStatusList.map((nvts) => (
        <article
          key={nvts}
          className="flex w-14 flex-col items-center justify-start gap-1 text-center"
        >
          <button
            className={`rounded-full bg-gray-100 p-1 transition-colors hover:bg-gray-200 ${getTaskStatusClasses(
              nvts,
            )}`}
            disabled={isBusy}
            onClick={async () => {
              setIsBusy(true);
              try {
                const result = await changeTaskStatus({
                  task: props.task,
                  nextTaskStatus: nvts,
                  siteKeyDoc: props.siteKeyDoc,
                  uid: props.uid,
                  handleUpdateTaskStatus: props.handleUpdateTask,
                  setOpenTaskStatusChangeDialog:
                    props.setOpenTaskStatusChangeDialog,
                  setShowCraftPersistence: props.setShowCraftPersistence,
                  setNextTaskStatus: props.setNextTaskStatus,
                });
                // If result is 0, we're opening the ChangeTaskStatusDialog. Don't
                // prematurely send a toast notification.
                if (result !== 0) {
                  addToastMessage({
                    id: createToastMessageID(),
                    message: strings.successfulUpdate("Task status"),
                    dialog: false,
                    type: "success",
                  });
                }

                setIsBusy(false);
              } catch (e) {
                logger.debug("Error updating task status:", e);
                addToastMessage({
                  id: createToastMessageID(),
                  message: strings.failedUpdate("task status"),
                  dialog: false,
                  type: "error",
                });
                setIsBusy(false);
              }
            }}
          >
            {isBusy ? (
              <LoadingSpinner sizeClass="w-5 h-5" marginClass="m-1.5" />
            ) : (
              getTaskStatusIcon(nvts)
            )}
          </button>
          <span className="text-xs text-gray-500">
            Set {getTaskStatusStringSuccinct(nvts)}
          </span>
        </article>
      ))}
    </>
  );
}
