// Libs
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import TodayIcon from "@mui/icons-material/Today";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import CreateIcon from "@mui/icons-material/Create";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import HideImageIcon from "@mui/icons-material/HideImage";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DirectionsOutlinedIcon from "@mui/icons-material/DirectionsOutlined";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { TrashIcon } from "@heroicons/react/24/solid";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import PersonIcon from "@mui/icons-material/Person";
import SyncIcon from "@mui/icons-material/Sync";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PaymentIcon from "@mui/icons-material/Payment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DescriptionIcon from "@mui/icons-material/Description";

// Local
import { EventTypeValues, OEventTypes } from "../../../models/event-types";

export function getEventIcon(eventType: EventTypeValues): JSX.Element {
  switch (eventType) {
    case OEventTypes.NEW_USER_APPROVED:
      return <PersonAddAlt1Icon className="text-gray-50" />;
    case OEventTypes.NEW_USER_APPLIED:
      return <ManageAccountsIcon className="text-gray-50" />;
    case OEventTypes.CRAFT_RECORD_CREATED:
      return <ShapeLineIcon className="text-gray-50" />;
    case OEventTypes.NEW_TASK_ADDED:
      return <AssignmentTurnedInIcon className="text-gray-50" />;
    case OEventTypes.TASK_STATUS_UPDATED:
      return <PendingActionsIcon className="text-gray-50" />;
    case OEventTypes.TASK_REASSIGNED_COMPANY:
      return <ApartmentIcon className="text-gray-50" />;
    case OEventTypes.TASK_DESCRIPTION_UPDATED:
      return <EventNoteOutlinedIcon className="text-gray-50" />;
    case OEventTypes.TASK_WORK_ORDER_UPDATED:
      return <TodayIcon className="text-gray-50" />;
    case OEventTypes.TASK_DETAILS_UPDATED:
    case OEventTypes.UPDATED_CRAFT_DETAILS:
      return <SpeakerNotesOutlinedIcon className="text-gray-50" />;
    case OEventTypes.TASK_SCHEDULE_DATE_CHANGED:
      return <EditCalendarIcon className="text-gray-50" />;
    case OEventTypes.UPDATED_TITLE:
      return <CreateIcon className="text-gray-50" />;
    case OEventTypes.UPDATED_DESCRIPTION:
      return <FormatIndentIncreaseIcon className="text-gray-50" />;
    case OEventTypes.ADDED_PHOTO:
      return <AddPhotoAlternateIcon className="text-gray-50" />;
    case OEventTypes.REMOVED_PHOTO:
      return <HideImageIcon className="text-gray-50" />;
    case OEventTypes.CHANGED_ASSET:
      return <LocalOfferOutlinedIcon className="text-gray-50" />;
    case OEventTypes.CHANGED_LOCATION_ID:
      return <DirectionsOutlinedIcon className="text-gray-50" />;
    case OEventTypes.UPDATED_LOCATION_ON_MAP:
    case OEventTypes.CUSTOMER_LOCATION_ADDED:
    case OEventTypes.CUSTOMER_LOCATION_UPDATED:
      return <EditLocationOutlinedIcon className="text-gray-50" />;
    case OEventTypes.ADDED_ATTACHMENT:
      return <AttachmentOutlinedIcon className="text-gray-50" />;
    case OEventTypes.REMOVED_ATTACHMENT:
    case OEventTypes.TASK_DELETED:
    case OEventTypes.CRAFT_RECORD_DELETED:
      return <TrashIcon className="h-6 w-6 text-gray-50" />;
    case OEventTypes.CRAFT_RECORD_RESTORED:
    case OEventTypes.TASK_RESTORED:
      return <RestorePageOutlinedIcon className="text-gray-50" />;
    case OEventTypes.CUSTOMER_CREATED:
    case OEventTypes.CUSTOMER_UPDATED:
      return <PersonIcon className="text-gray-50" />;
    case OEventTypes.ACCOUNTING_SYNC_SUCCESS:
      return <SyncIcon className="text-gray-50" />;
    case OEventTypes.ACCOUNTING_SYNC_ERROR:
      return <SyncProblemIcon className="text-gray-50" />;
    case OEventTypes.SMS_NOTIFICATION_SENT:
      return <PhoneIphoneIcon className="text-gray-50" />;
    case OEventTypes.INVOICE_EMAIL_SENT_TO_CUSTOMER:
    case OEventTypes.FEEDBACK_EMAIL_SENT_TO_CUSTOMER:
    case OEventTypes.APPOINTMENT_REMINDER_EMAIL_SENT_TO_CUSTOMER:
      return <MarkEmailReadIcon className="text-gray-50" />;
    case OEventTypes.FEEDBACK_RESPONSE_RECEIVED:
      return <ContactMailIcon className="text-gray-50" />;
    case OEventTypes.PAYMENT_CREATED:
    case OEventTypes.PAYMENT_REFUNDED:
      return <PaymentIcon className="text-gray-50" />;
    case OEventTypes.INVOICE_UPDATED:
    case OEventTypes.INVOICE_STATUS_CHANGED:
    case OEventTypes.ESTIMATE_CONVERTED_TO_INVOICE:
      return <PostAddIcon className="text-gray-50" />;
    case OEventTypes.NEW_ESTIMATE_CREATED:
    case OEventTypes.ESTIMATE_UPDATED:
    case OEventTypes.ESTIMATE_STATUS_CHANGED:
      return <DescriptionIcon className="text-gray-50" />;
    default:
      const _exhaustivenessCheck: never = eventType;
      return _exhaustivenessCheck;
  }
}
