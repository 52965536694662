// Local
import { ScheduleByPriorityType } from "../../../components/customers/CreateTask/SchedulingSection";
import { ExistingSiteKey } from "../../../models/site-key";
import { ExistingSiteKeyUserPermissions } from "../../../models/site-key-user-permissions";
import { ExistingTask } from "../../../models/task";
import { TaskStatus } from "../../../models/task-status";
import { awaitingEstimateEnabled } from "./awaitingEstimateEnabled";
import { getTaskStatusForApprovedTask } from "./getTaskStatusForApprovedTask";

export function getTaskStatus(args: {
  task: ExistingTask;
  scheduleVal: ScheduleByPriorityType | null;
  newTaskCreation: boolean;
  siteKey: ExistingSiteKey;
  siteKeyUserPermissions: ExistingSiteKeyUserPermissions;
}): number {
  if (
    args.siteKey.validTaskStatusCodes.includes(TaskStatus.AWAITING_ESTIMATE) &&
    args.siteKey.validTaskStatusCodes.includes(TaskStatus.AWAITING_APPROVAL) &&
    awaitingEstimateEnabled(
      args.siteKey,
      args.task.craftType,
      args.task.taskType,
    )
  ) {
    if (args.siteKeyUserPermissions.permissions.isPlantPersonnel === false) {
      // Non-plant personnel can only create tasks in AWAITING_ESTIMATE status
      if (args.newTaskCreation === true) {
        return TaskStatus.AWAITING_ESTIMATE;
      }
      // If not a new task, then they must be changing the schedule date.
      //    So, we need to check if the task has already been moved past
      //    approval status. If it hasn't, then status must remain the same
      if (
        [TaskStatus.AWAITING_APPROVAL, TaskStatus.AWAITING_ESTIMATE].includes(
          args.task.taskStatus,
        )
      ) {
        return args.task.taskStatus;
      } else {
        // Task must already be approved, we can use normal logic now:
        return getTaskStatusForApprovedTask(
          args.scheduleVal,
          args.task.timestampScheduled,
        );
      }
    } else {
      if (args.task.urgent === false && args.newTaskCreation === true) {
        // For plant personnel... non-urgent tasks will go to AWAITING_ESTIMATE if
        //    it is a new task
        return TaskStatus.AWAITING_ESTIMATE;
      } else if (args.task.urgent === true && args.newTaskCreation === true) {
        return TaskStatus.AWAITING;
      } else if (
        [TaskStatus.AWAITING_APPROVAL, TaskStatus.AWAITING_ESTIMATE].includes(
          args.task.taskStatus,
        )
      ) {
        return args.task.taskStatus;
      } else {
        // Task must already be approved, we can use normal logic now:
        return getTaskStatusForApprovedTask(
          args.scheduleVal,
          args.task.timestampScheduled,
        );
      }
    }
  } else {
    return getTaskStatusForApprovedTask(
      args.scheduleVal,
      args.task.timestampScheduled,
    );
  }
}
