//Libs

//Local
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ExistingNote } from "../../models/note";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { TrashIconWithSpinner } from "../ButtonDeleteTrash";
import { PencilIconWithRef } from "../PencilEditButton";
import { StyledTooltip } from "../StyledTooltip";

interface Props {
  note: ExistingNote;
  userDisplayNamesMap: Record<string, string>;
  userPermissions: ExistingSiteKeyUserPermissions | null;
  onDeleteCustomerNote: (noteID: string) => Promise<void>;
  onEditCustomerNote: (note: ExistingNote) => void;
}

export default function CustomerNotesCard({
  note,
  userDisplayNamesMap,
  userPermissions,
  onDeleteCustomerNote,
  onEditCustomerNote,
}: Props) {
  return (
    <div
      className={`grid grid-cols-[3fr,1fr] rounded-md border bg-white px-2 py-1 ${note.pinned === true && "border-2 border-blue-500"}`}
    >
      <div key={note.id} className={`flex flex-col `}>
        <p>{note.note}</p>
        <div className="pt-2 text-sm text-gray-700">
          Created: {convertToReadableTimestamp(note.timestampCreated)} by{" "}
          {userDisplayNamesMap[note.createdBy] ?? ""}
        </div>
        {note.timestampCreated.toMillis() !==
          note.timestampLastModified.toMillis() && (
          <div className="text-sm text-gray-700">
            Modified: {convertToReadableTimestamp(note.timestampLastModified)}
            {" by "}
            {userDisplayNamesMap[note.lastModifiedBy] ?? ""}
          </div>
        )}
      </div>
      {userPermissions?.permissions.isSiteAdmin && (
        <div className="flex flex-col items-center gap-1 place-self-center justify-self-end">
          <StyledTooltip title="Edit Note" enterDelay={1200}>
            <PencilIconWithRef
              className="mx-0 px-0"
              onClick={() => onEditCustomerNote(note)}
            />
          </StyledTooltip>
          <StyledTooltip title="Delete Note" enterDelay={1200}>
            <TrashIconWithSpinner
              className="mx-0 px-0"
              onDelete={async () => onDeleteCustomerNote(note.id)}
            />
          </StyledTooltip>
        </div>
      )}
    </div>
  );
}
