//Libs
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DescriptionIcon from "@mui/icons-material/Description";
import { Fragment } from "react";

//Local
import { ExistingCraftRecord } from "../models/craft-record";
import placeholder_avatar from "../images/placeholder_avatar.svg";
import { convertToReadableTimestamp } from "../assets/js/convertToReadableTimestamp";

interface Props {
  template?: ExistingCraftRecord;
  userDisplayNamesMap: Record<string, string>;
  locationName: string;
  isTaskDetails: boolean;
}

export default function TemplateDetailsInfoArea({
  template: checklist,
  userDisplayNamesMap,
  locationName,
  isTaskDetails,
}: Props) {
  if (checklist === undefined) {
    return null;
  }

  const displayName = userDisplayNamesMap[checklist.createdBy];

  /* TODO: IMPORTANT!! remember to remove the placeholder_avatar! */
  /* TODO: DAN - how would be handle the user photo in the checklist? we'll add the user photo to userDisplayNamesMap? */
  /* TODO: if we use the aggregates/userDisplayNames we don't have access to the user photo */

  const warningStyle = isTaskDetails ? "text-orange-400" : "text-orange-800";
  const textStyle = isTaskDetails ? "text-white" : "text-gray-500";

  return (
    <Fragment>
      <div className="mt-2">
        <span
          className={`text-left text-sm font-medium tracking-wider ${warningStyle}`}
        >
          {checklist.craftDetails.requireApprovals
            ? "Checklist Tasks for this Template must be approved after submission"
            : ""}
        </span>
      </div>
      <div className={`flex items-center font-medium ${textStyle}`}>
        <span className="text-left text-sm tracking-wider ">
          <LocationOnIcon aria-label="location" />
        </span>
        <span className=" px-3 py-2 text-sm ">{locationName}</span>
      </div>
      <div className={`flex items-center font-medium ${textStyle}`}>
        <span className="text-left text-sm tracking-wider ">
          <DescriptionIcon aria-label="description" />
        </span>
        <span className=" px-3 py-2 text-sm ">{checklist.description}</span>
      </div>
      <div className="flex items-center">
        <img
          src={placeholder_avatar}
          alt="User avatar"
          className="h-10 w-10 rounded-full"
        />
        <div className={`flex flex-col px-3 py-2 text-sm ${textStyle}`}>
          <div>
            Created by{" "}
            <span className="font-medium text-blue-400">{displayName}</span>
          </div>
          <span>
            {convertToReadableTimestamp(checklist.timestampRecordCreated)}
          </span>
        </div>
      </div>
    </Fragment>
  );
}
