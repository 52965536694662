// Libs
import { CircleStackIcon } from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";

// Local
import { ExistingChecklistResponse } from "../models/checklist-response";
import {
  convertToReadableTimestamp,
  convertToReadableTimestampDate,
  convertToReadableTimestampTime,
} from "../assets/js/convertToReadableTimestamp";
import ResponseButtonSecondary from "./ResponseButtonSecondary";
import RHDCreateResponseFormat from "../assets/js/RHDCreateResponseFormat";
import LoadingSpinner from "./LoadingSpinner";
import { ExistingChecklistItem } from "../models/checklist-item";
import RHDModal from "./RHDModal";

interface Props {
  incomingHistoryData: ExistingChecklistResponse[];
  isDialogOpen: boolean;
  closeDialog: () => void;
  isHistoryLoading: boolean | undefined;
  theMainText: ExistingChecklistItem["mainText"];
  responseID?: ExistingChecklistResponse["id"];
}

// TODO: Extract this & make it reusable
function sortTimestampByDescending(
  incomingHistoryData: ExistingChecklistResponse[],
): ExistingChecklistResponse[] {
  // Create a shallow copy
  const copyIncomingHistoryData = [...incomingHistoryData];

  return copyIncomingHistoryData.sort((a, b) => {
    const timestampA = a.timestamp;
    const timestampB = b.timestamp;
    const aIsTimestamp = timestampA instanceof Timestamp;
    const bIsTimestamp = timestampB instanceof Timestamp;

    if (aIsTimestamp && bIsTimestamp) {
      return timestampB.toMillis() - timestampA.toMillis();
    } else if (!aIsTimestamp && bIsTimestamp) {
      return 1;
    } else if (aIsTimestamp && !bIsTimestamp) {
      return -1;
    } else {
      return 0;
    }
  });
}

function displayHistoryDataIfItExists(
  incomingHistoryData: ExistingChecklistResponse[],
  responseID: string,
): JSX.Element {
  if (incomingHistoryData.length === 0) {
    return (
      <li className="flex flex-col items-center">
        <CircleStackIcon
          aria-label="view history data"
          className="mt-2 h-9 w-9 text-gray-700"
        />
        <p className="my-1.5 text-sm sm:text-base">No data to display.</p>
      </li>
    );
  } else {
    return (
      <>
        {incomingHistoryData.map((item) => (
          <li
            className={`mb-1.5 flex items-start justify-between rounded-md border ${
              responseID === item.id
                ? "border-primary/10"
                : "border-gray-400/10"
            } bg-white px-1 py-1.5 text-sm shadow-sm xxs:px-2 sm:text-base`}
            key={item.id}
          >
            <span className="word-spacing-small flex-shrink-0 pr-14 font-mono tracking-tighter sm:pr-28">
              <span className="hidden pl-1.5 pt-0.5 xs:block">
                {convertToReadableTimestamp(item.timestamp)}
              </span>
              <span className="flex flex-col pl-1.5 text-left xs:hidden">
                <span>{convertToReadableTimestampDate(item.timestamp)}</span>
                <span>{convertToReadableTimestampTime(item.timestamp)}</span>
              </span>
            </span>
            <span className="flex text-right font-normal">
              {RHDCreateResponseFormat(item)}
            </span>
          </li>
        ))}
      </>
    );
  }
}

export default function ResponseHistoryDialog({
  incomingHistoryData,
  isDialogOpen,
  closeDialog,
  isHistoryLoading,
  theMainText,
  responseID,
}: Props) {
  const sortedHistoryList = sortTimestampByDescending(incomingHistoryData);

  function DisplayBodyContent(): JSX.Element {
    if (isHistoryLoading) {
      return (
        <div className="my-2 text-center">
          <LoadingSpinner marginClass="mx-auto mb-4" sizeClass="h-11 w-11" />
        </div>
      );
    } else {
      if (responseID) {
        return displayHistoryDataIfItExists(sortedHistoryList, responseID);
      } else {
        return displayHistoryDataIfItExists(sortedHistoryList, "unknownID");
      }
    }
  }

  return (
    <RHDModal open={isDialogOpen} closeModal={closeDialog}>
      <div>
        <h2 className="mx-3.5 mb-4 text-left text-base font-normal leading-snug xs:mx-6">
          {theMainText}
        </h2>
        <ul
          className="relative mx-3 max-h-80 overflow-y-auto xs:mx-5 sm:max-h-96"
          data-testid="response-history-list"
        >
          <DisplayBodyContent />
        </ul>

        <div className="mt-4 flex justify-center">
          <ResponseButtonSecondary
            className="xs:text-base"
            type="button"
            onClick={closeDialog}
          >
            CLOSE
          </ResponseButtonSecondary>
        </div>
      </div>
    </RHDModal>
  );
}
