// Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

// Local
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject } from "../utils";

// #region SECTION: Types & Schemas
export type Attachment = {
  // Existing fields
  authorizedCompanies: string[];
  url: string;
  filename: string;

  // Mutually exclusive
  craftRecordID?: string; // foreign key
  taskID?: string; // FK
  assetID?: string; // FK
  complianceResponseID?: string; // FK
  customerID?: string;

  // Common
  timestampCreated: Timestamp; // Firestore timestamp.
  createdBy: string;
  deleted?: boolean;
};

type FirestoreAttributes = {
  id: string;
  refPath: string;
};

export type ExistingAttachment = Attachment & FirestoreAttributes;

const CommonSchema = z.object({
  authorizedCompanies: z.array(z.string().min(1).max(200)),
  filename: z.string().min(1).max(400),
  url: z.string().min(1).max(1000),
  createdBy: z.string().min(1).max(200),
  craftRecordID: z.string().min(1).max(200).optional(),
  taskID: z.string().min(1).max(200).optional(),
  assetID: z.string().min(1).max(200).optional(),
  complianceResponseID: z.string().min(1).max(200).optional(),
  customerID: z.string().min(1).max(200).optional(),
  deleted: z.boolean().optional(),
});

/** Schema used within the app */
const AttachmentSchema = CommonSchema.extend({
  timestampCreated: z.instanceof(Timestamp),
});

/** For the create endpoint, where timestamps are ISO strings */
export type Attachment_CreateAPI = {
  /** ISO datetime string */
  timestampCreated: string;
} & Omit<Attachment, "timestampCreated">;

/** Schema for interacting with the create endpoint */
const AttachmentSchema_CreateAPI = CommonSchema.extend({
  timestampCreated: z.string().min(1).max(200),
});
// #endregion Types & Schemas

// #region SECTION: Functions
/** Collection of functions that interact with Attachment documents. */
export const AttachmentManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingAttachment object.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromSnapshot: createAttachmentFromSnapshot,
  /** Validate an Attachment doc */
  parse: validateAttachment,
  /**
   * Validate an Attachment doc that's coming from/going to the create endpoint,
   * where timestamps are ISO strings.
   */
  parseCreateAPI: validateCreateAPI,
};

function createAttachmentFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingAttachment {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateAttachment(snapData),
  };
}

function validateAttachment(doc: unknown): Attachment {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`Attachment is not an object: ${doc}`);
  }
  return AttachmentSchema.parse(doc);
}

function validateCreateAPI(doc: unknown): Attachment_CreateAPI {
  if (!guardIsPlainObject(doc)) {
    throw new Error(`Attachment is not an object: ${doc}`);
  }
  return AttachmentSchema_CreateAPI.parse(doc);
}
// #endregion Functions
