// Libs
import { Control, Controller, FieldValues } from "react-hook-form";
import { forwardRef, useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// Local
import * as strings from "../../strings";
import { ExistingCustomField } from "../../models/custom-field";
import { Json } from "../../models/json-type";

interface Props {
  customField: ExistingCustomField;
  control: Control<FieldValues, any>;
  defaultValue: Json | Date;
  isRequired: boolean;
}

type ButtonProps = React.ComponentPropsWithRef<"button">;
type Ref = HTMLButtonElement;

export default function FieldTypeTimestamp(props: Props) {
  const SchedulingButton = forwardRef<Ref, ButtonProps>(
    ({ value, onClick }, ref) => (
      <button
        className="inline-flex items-center justify-center rounded-md border border-gray-400 bg-white px-3 py-2 transition-colors hover:border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2"
        onClick={(ev) => {
          ev.preventDefault();
          if (onClick) onClick(ev);
        }}
        ref={ref}
        id={props.customField.id + props.customField.title}
      >
        <CalendarMonthIcon className="mr-2 text-gray-600" />
        {/* if they haven't selected anything yet, value is an empty string */}
        {value != null && typeof value != "number" && value.length !== 0
          ? value
          : strings.SELECT}
      </button>
    ),
  );

  const [displayTime, setDisplayTime] = useState("");
  const [dateAndTime, setDateAndTime] = useState<Date | null>(null);

  useEffect(() => {
    // We *should* only ever receive a Date or null (as dictated in
    // getDefaultValuesForNewResponse and getDefaultValuesForExistingResponse)
    if (props.defaultValue === null || props.defaultValue instanceof Date) {
      setDateAndTime(props.defaultValue);
      parseTime(props.defaultValue);
    }
  }, [props.defaultValue]);

  function parseTime(date: Date | null): void {
    if (date === null) {
      setDisplayTime("");
      return;
    }
    const hour = date.getHours(); // 0 - 23
    const [convertedHour, amPM] = convertFromMilitaryTime(hour);
    const minute = date.getMinutes(); // 0 - 59
    const formattedMinute = minute.toString().padStart(2, "0");

    setDisplayTime(`${convertedHour}:${formattedMinute} ${amPM}`);
  }

  return (
    <Controller
      defaultValue={props.defaultValue}
      name={props.customField.id}
      control={props.control}
      rules={{ required: props.isRequired }}
      render={({ field }) => {
        return (
          <>
            <label
              // corresponding ID is on the SchedulingButton
              htmlFor={props.customField.id + props.customField.title}
              className="mb-1 inline-block"
            >
              {props.customField.title}{" "}
              {props.isRequired && (
                <span className="text-lg font-medium text-redFail">*</span>
              )}
            </label>
            <div className="flex flex-wrap items-center gap-4">
              <div className="max-w-fit">
                <DatePicker
                  selected={dateAndTime}
                  {...field}
                  onChange={(date) => {
                    if (date) {
                      setDateAndTime(date);
                      parseTime(date);
                      field.onChange(date);
                    }
                  }}
                  showTimeSelect
                  customInput={<SchedulingButton />}
                />
              </div>
              {/* DISPLAY SELECTED TIME */}
              <div>
                <AccessTimeIcon className="mr-2 text-gray-600" />
                {displayTime}
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

/**
 * @param hour 0 - 23
 * @returns hour between 1 & 12, plus AM or PM
 */
function convertFromMilitaryTime(hour: number): [number, string] {
  if (hour >= 1 && hour <= 11) {
    return [hour, "AM"];
  } else if (hour === 0) {
    return [12, "AM"];
  } else if (hour === 12) {
    return [hour, "PM"];
  } else {
    const x = hour - 12;
    return [x, "PM"];
  }
}
