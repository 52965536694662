//Libs
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputNumber from "../BaseInputNumber";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { logger as devLogger } from "../../logging";
import BaseInputText from "../BaseInputText";
import { SelfCheckoutFormConfig } from "../../Pages/PaymentsSelfCheckout/PaymentsSelfCheckoutContainer";
import { useParams } from "react-router-dom";
import StyledMessage from "../StyledMessage";

interface Props {
  handleProceedWithPayment: (
    selfCheckoutPayment: SelfCheckoutPayment,
  ) => Promise<string | undefined>;
  selfCheckoutFormConfig: SelfCheckoutFormConfig | null;
  children: [];
}

export interface SelfCheckoutPayment {
  totalAmount: number;
  referenceNumber: string;
  customerName: string;
  returnURL: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  phone: string;
  email: string;
}

const ziftSelfCheckoutDataSchema = z.object({
  totalAmount: z.number().min(0),
  referenceNumber: z.string().min(1).max(255),
  customerName: z.string().min(1).max(255),
  street: z.string().min(0).max(128),
  city: z.string().min(0).max(50),
  countryCode: z.string().min(0).max(2),
  state: z.string().min(0).max(2),
  zipCode: z.string().min(0).max(15),
  phone: z.string().min(0).max(20),
  email: z.string().min(0).max(100),
});

export type SelfCheckoutFormState = z.infer<typeof ziftSelfCheckoutDataSchema>;

export default function SelfCheckoutForm(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const SelfCheckoutDefaultValues: SelfCheckoutFormState = useMemo(() => {
    return {
      siteKey: "",
      totalAmount: 0,
      referenceNumber: "",
      customerName: "",
      returnURL: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      countryCode: "US",
      phone: "",
      email: "",
    };
  }, []);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    watch,
  } = useForm<SelfCheckoutFormState>({
    defaultValues: SelfCheckoutDefaultValues,
    resolver: zodResolver(ziftSelfCheckoutDataSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(SelfCheckoutDefaultValues);
  }, [SelfCheckoutDefaultValues, reset]);

  // Extract siteKey ID from the URL
  type UrlParams = { siteKey: string };
  const data = useParams<UrlParams>();
  const siteKey = data.siteKey;
  if (typeof siteKey !== "string") {
    throw new Error(`siteKey was not a string: ${siteKey}`);
  }

  const onSubmit: SubmitHandler<SelfCheckoutFormState> = async (formValues) => {
    const data: SelfCheckoutPayment = {
      totalAmount: formValues.totalAmount,
      referenceNumber: formValues.referenceNumber,
      customerName: formValues.customerName,
      returnURL:
        props.selfCheckoutFormConfig?.returnURL ?? window.location.href,
      street: formValues.street,
      city: formValues.city,
      state: formValues.state,
      zipCode: formValues.zipCode,
      countryCode: formValues.countryCode,
      phone: formValues.phone,
      email: formValues.email,
    };

    try {
      setIsBusy(true);
      const paymentURL = await props.handleProceedWithPayment(data);
      if (typeof paymentURL === "string") {
        window.location.href = paymentURL;
      }
      setIsBusy(false);
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
      setIsBusy(false);
    }
  };

  const errorMessage = (
    <ErrorMessage
      message="Error proceeding with payment"
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <div className="mx-4 items-center">
      <div className="justify-items-center rounded bg-gray-100 p-4">
        <div className="space-y-4">
          {props.selfCheckoutFormConfig?.merchantLogoURL && (
            <img
              src={props.selfCheckoutFormConfig?.merchantLogoURL}
              alt="merchant logo"
              className="mx-auto mb-10 max-h-[6rem] w-auto md:mb-0 md:mr-0 md:max-h-full md:max-w-[15rem]"
            />
          )}
          <h2
            className={`${
              props.selfCheckoutFormConfig?.merchantLogoURL
                ? "text-center md:flex-row"
                : ""
            } text-2xl font-semibold text-primary`}
          >
            {strings.MAKE_A_PAYMENT}
          </h2>
        </div>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4 grid grid-cols-1 gap-8 md:grid-cols-2">
          <Controller
            name="referenceNumber"
            control={control}
            render={({ field }) => (
              <BaseInputText
                inputName="referenceNumber"
                text="Job Number"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.referenceNumber?.message && (
            <StyledMessage type="error">
              {{ message: errors.referenceNumber.message }}
            </StyledMessage>
          )}
          <Controller
            name="customerName"
            control={control}
            render={({ field }) => (
              <BaseInputText
                inputName="customerName"
                text="Name"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.customerName?.message && (
            <StyledMessage type="error">
              {{ message: errors.customerName.message }}
            </StyledMessage>
          )}
          <Controller
            name="street"
            control={control}
            render={({ field }) => (
              <BaseInputText
                inputName="street"
                text="Street"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.street?.message && (
            <StyledMessage type="error">
              {{ message: errors.street.message }}
            </StyledMessage>
          )}

          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <BaseInputText
                inputName="city"
                text="City"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.city?.message && (
            <StyledMessage type="error">
              {{ message: errors.city.message }}
            </StyledMessage>
          )}

          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <BaseInputText
                inputName="state"
                text="State (2-digit code)"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.state?.message && (
            <StyledMessage type="error">
              {{ message: errors.state.message }}
            </StyledMessage>
          )}

          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <BaseInputText
                inputName="zipCode"
                text="Zip Code"
                admin={true}
                required={true}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.zipCode?.message && (
            <StyledMessage type="error">
              {{ message: errors.zipCode.message }}
            </StyledMessage>
          )}

          <Controller
            name="countryCode"
            control={control}
            render={({ field }) => (
              <select
                name="countryCode"
                value={field.value}
                onChange={field.onChange}
                required
                className="block w-full min-w-0 flex-1 rounded border border-black p-4 text-gray-700 outline-none focus:border-primaryLight focus:ring focus:ring-primaryLight sm:text-sm"
              >
                <option value="US">US</option>
                <option value="CA">CA</option>
              </select>
            )}
          />

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <BaseInputText
                inputName="phone"
                text="Phone Number"
                admin={true}
                required={false}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.phone?.message && (
            <StyledMessage type="error">
              {{ message: errors.phone.message }}
            </StyledMessage>
          )}

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <BaseInputText
                inputName="email"
                text="Email Address"
                admin={true}
                required={false}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.email?.message && (
            <StyledMessage type="error">
              {{ message: errors.email.message }}
            </StyledMessage>
          )}

          <Controller
            name="totalAmount"
            control={control}
            render={({ field }) => (
              <BaseInputNumber
                text="Payment Amount"
                inputName="totalAmount"
                admin={true}
                required={true}
                {...field}
                value={field.value}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  if (displayError === true && numberValue > 0) {
                    setDisplayError(false);
                  }
                  field.onChange(isNaN(numberValue) ? "" : numberValue);
                }}
              />
            )}
          />
          {errors.totalAmount?.message && (
            <StyledMessage type="error">
              {{ message: errors.totalAmount.message }}
            </StyledMessage>
          )}
        </div>

        <BaseButtonPrimary
          type="submit"
          disabled={props.selfCheckoutFormConfig === null}
          isBusy={isBusy}
          className="mt-8 w-full"
        >
          {strings.PAY_NOW}
        </BaseButtonPrimary>
      </form>
      {displayError ? (
        <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
}
