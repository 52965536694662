// Libs
import { useState, useLayoutEffect, useCallback, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DocumentData, Timestamp } from "firebase/firestore";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { RowDragEvent } from "ag-grid-community";

// Local
import {
  checkPassingOptionsInSelectionOptions,
  ExistingChecklistItem,
} from "../models/checklist-item";
import { DbRead, DbWrite } from "../database";
import { ExistingCraftRecord } from "../models/craft-record";
import { logger as devLogger } from "../logging";
import { ExistingChecklistResponse } from "../models/checklist-response";
import TemplateDetailsPage from "./TemplateDetailsPage";
import { TaskTypes } from "../models/task-types";
import { TaskStatus } from "../models/task-status";
import { CraftTypes } from "../models/craft-types";
import { useUserPermissionsStore } from "../store/user-permissions";
import { CraftRecordPersistenceTypes, Task } from "../models/task";
import { useAuthStore } from "../store/firebase-auth";
import { useUserDisplayNamesStore } from "../store/user-display-names-map";
import { useNavToChecklistDetails } from "../navigation";
import {
  ExistingServerJob,
  NewServerJob,
  OServerJobStatus,
  OServerJobTypes,
} from "../models/server-job";
import { useSiteKeyLocationsStore } from "../store/site-key-locations";
import { TASKS_URL, TEMPLATES_URL, TEMPLATE_ITEMS_URL } from "../urls";
interface Props {
  siteKey: string;
}

/**
 * A Page to see the checklist items for a single checklist.
 */
export default function TemplateDetailsContainer({ siteKey, ...props }: Props) {
  const userSitePermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  const [checklistResponseList, setChecklistResponseList] = useState<
    ExistingChecklistResponse[]
  >([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [itemList, setItemList] = useState<ExistingChecklistItem[]>([]);
  const [checklistTemplate, setChecklistTemplate] =
    useState<ExistingCraftRecord>();

  const saveItemOrderDivRef = useRef<HTMLDivElement>(null);

  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  type UrlParams = { id: string };
  const { id: checklistID } = useParams<UrlParams>();
  if (typeof checklistID !== "string") {
    throw new Error(`checklistID was not a string: ${checklistID}`);
  }

  const navToTaskDetails = useNavToChecklistDetails();

  const navigate = useNavigate();
  if (checklistID == null) {
    navigate("/404");
  }

  function handleEditChecklist(checklistID: string) {
    navigate(`${TEMPLATES_URL}/${checklistID}/edit`);
  }

  function handleOnViewChecklistTasks(checklistID: string) {
    navigate(`${TASKS_URL}/${checklistID}`);
  }

  // Pass the craftRecordID via the history object, so
  // we have access to it on the edit item page.
  const handleEditItem = useCallback(
    function (itemID: string, craftRecordID: string): void {
      navigate(`${TEMPLATE_ITEMS_URL}/${itemID}/edit`, {
        state: { craftRecordID: craftRecordID },
      });
      // navigate({
      //   pathname: `${TEMPLATE_ITEMS_URL}/${itemID}/edit`,
      //   state: { craftRecordID: craftRecordID },
      // });
    },
    [navigate],
  );

  async function handleAddItem(data: DocumentData) {
    if (typeof checklistID !== "string") {
      throw new Error(`checklistID was not a string: ${checklistID}`);
    }

    if (typeof siteKey === "string") {
      await DbWrite.checklistItems.add(siteKey, checklistID, data);
    } else {
      devLogger.error(`Site key was not a string ${siteKey}`);
    }
  }

  async function handleDuplicateItem(
    checklistItemID: string,
    craftRecordID: string,
  ) {
    if (typeof siteKey === "string") {
      const item = await DbRead.checklistItems.getItem(
        siteKey,
        checklistItemID,
      );
      const { id, refPath, ...rest } = item;
      console.log(rest);
      await DbWrite.checklistItems.add(siteKey, craftRecordID, rest);
    } else {
      devLogger.error(`Site key was not a string ${siteKey}`);
    }
  }

  const handleUpdateItemOrder = useCallback(
    async function (itemList: string[]): Promise<void> {
      if (typeof checklistTemplate?.refPath === "string") {
        await DbWrite.checklist.updateItemOrder(
          checklistTemplate?.refPath,
          itemList,
        );
        devLogger.debug("✅ ORDER SAVED:", itemList);
      }
    },
    [checklistTemplate?.refPath],
  );

  const handleViewResponseHistory = useCallback(
    async function (checklistItemID: string): Promise<void> {
      if (typeof siteKey === "string") {
        setIsHistoryLoading(true);
        const result = await DbRead.checklistResponses.getByItemID(
          siteKey,
          checklistItemID,
        );
        setIsHistoryLoading(false);
        setChecklistResponseList(result);
      }
    },
    [siteKey],
  );

  // check and remove checklistItems with invalid passingOptions
  const validChecklistItems = useMemo(() => {
    const validItems: ExistingChecklistItem[] = [];
    itemList.forEach((item) => {
      if (
        item.responseType === "selection" &&
        item.selectionOptions != null &&
        item.passingOptions != null
      ) {
        const isValid = checkPassingOptionsInSelectionOptions(
          item.selectionOptions,
          item.passingOptions,
        );
        if (isValid === false) {
          return;
        } else {
          validItems.push(item);
        }
      } else {
        validItems.push(item);
      }
    });
    return validItems;
  }, [itemList]);

  // Remove from one checklist
  const handleRemoveItem = useCallback(
    async function (
      checklistItemID: string,
      craftRecordID?: string,
    ): Promise<void> {
      if (typeof siteKey === "string" && typeof craftRecordID === "string") {
        const result = await DbWrite.checklistItems.removeSingleFromOneCraftRec(
          siteKey,
          craftRecordID,
          checklistItemID,
        );
        devLogger.debug(result);
      }
    },
    [siteKey],
  );

  // #region SECTION: TABLE ITEM ORDER
  /**
   * @param adjustClass Show or hide the button.
   */
  function toggleSaveItemOrderButton(adjustClass: "hide" | "show"): void {
    if (saveItemOrderDivRef.current) {
      if (adjustClass === "hide") {
        saveItemOrderDivRef.current.className = "hidden";
      } else {
        saveItemOrderDivRef.current.className = "";
      }
    }
  }

  /**
   * Listens for rowDragEnd and rowDragLeave events. If the item order has changed,
   * shows the save item order button.
   */
  const handleRowDragEvent = useCallback(
    function (event: RowDragEvent) {
      const allRows = event.node.parent?.allLeafChildren;

      if (allRows !== undefined) {
        const newListOrder: ExistingChecklistItem[] = allRows.map(
          (row) => row.data,
        );

        const hasOrderChanged = checkIfOrderChanged(itemList, newListOrder);

        // If the order of items has changed, show the button.
        if (hasOrderChanged) toggleSaveItemOrderButton("show");
      }
    },
    [itemList],
  );
  // #endregion TABLE ITEM ORDER

  /**
   * Create a checklist for the current template and trigger the creation of
   * the checklist response documents by calling the CF.
   * TODO(Dan): clean this up / extract a bit.
   */
  async function actionQuickCreateChecklist(): Promise<void> {
    devLogger.debug("starting actionQuickCreateChecklist");
    // Making sure app state and other variables are defined.
    if (typeof siteKey !== "string") return;
    if (checklistTemplate === undefined) return;
    if (typeof checklistTemplate.id !== "string") return;
    if (!userSitePermissions) {
      devLogger.warn("Expected userSitePermissions to exist!");
      return;
    }
    if (!firebaseUser) {
      devLogger.warn("Expected firebaseUser to exist!");
      return;
    }

    const craftRecordID = checklistTemplate.id;

    const fullCraftRecordPath = `siteKeys/${siteKey}/parentRecords/${craftRecordID}`;

    const now = Timestamp.now();

    // Needed to provide a string fallback for assignedCompanyID since it can be null on the user's document,
    // but null is not permitted here.
    let assignedCompanyID: string;
    if (userSitePermissions.companyID) {
      assignedCompanyID = userSitePermissions.companyID;
    } else {
      assignedCompanyID = "unknown";
      devLogger.warn(
        `Unexpected value for user ${firebaseUser.uid} assigned company id. 
      Value ${userSitePermissions.companyID}`,
      );
    }

    const newTask: Task = {
      // Needed to provide a string fallback for assignedCompanyID since it can be null on the user's document,
      // but null is not permitted here.
      assignedCompanyID: assignedCompanyID,
      craftRecordID: fullCraftRecordPath,
      craftRecordPersistence: CraftRecordPersistenceTypes["KEEP"],
      craftType: CraftTypes.CHECKLISTS,
      createdBy: firebaseUser.uid,
      crewCount: 0,
      description: null,
      durations: {},
      holdDurations: {},
      lastModifiedBy: firebaseUser.uid,
      locationID: checklistTemplate.locationID,
      nextOpportunity: false,
      notifyCompanyOnCreation: false,
      taskSpecificDetails: {},
      taskStatus: TaskStatus.AWAITING,
      taskType: TaskTypes.OPERATOR_ROUNDS,
      thumbnailURL: null,
      timestampAwaitingStart: now,
      timestampCreated: now,
      timestampLastModified: now,
      timestampScheduled: now,
      timestampTaskCompleted: null,
      timestampTaskStarted: null,
      title: checklistTemplate.title,
      urgent: false,
      workOrder: "",
    };
    devLogger.debug("newTask: ", newTask);

    // Write new checklist to database
    const taskRef = await DbWrite.tasks.add(siteKey, newTask);
    devLogger.debug(`newTask ID: ${taskRef.id}`);

    //TODO: call the update task detail to set default custom fields.

    // Create response documents for checklist
    await DbWrite.checklistResponses.generate({
      siteKey: siteKey,
      taskID: taskRef.id,
      craftRecordID: craftRecordID,
    });

    devLogger.debug("finished actionQuickCreateChecklist");

    navToTaskDetails(taskRef.id);
  }

  async function handleDeleteScheduleRule(jobID: string) {
    return DbWrite.serverJobs.delete(siteKey, jobID);
  }

  const queryClient = useQueryClient();

  const basicJobProps = {
    kind: OServerJobTypes.CREATE_CHECKLIST,
    siteKey: siteKey,
    status: OServerJobStatus.SCHEDULED,
    title: "Scheduled Checklist Creation",
    // todo(dan) fix this hacky handling of undefined templates.
    craftRecordID: checklistTemplate?.id ?? "",
  };

  // QUERIES
  const queryTemplate = useQuery(
    ["templateDetailsContainer_template", siteKey, checklistID],
    () => DbRead.checklist.get(siteKey, checklistID),
  );

  const serverJobsQueryKey = [
    "templateDetailsContainer_serverJobs",
    siteKey,
    checklistID,
  ];
  const queryServerJobs = useQuery(
    serverJobsQueryKey,
    () => DbRead.serverJob.listByTemplateID(siteKey, checklistID),
    {
      enabled: !!queryTemplate.data?.id,
    },
  );

  // MUTATIONS
  const mutationDeleteServerJob = useMutation(handleDeleteScheduleRule, {
    onMutate: async (jobID: string) => {
      devLogger.debug(`deleting job: ${jobID}`);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(serverJobsQueryKey);

      // Snapshot the previous value
      const previousJobs =
        queryClient.getQueryData<ExistingServerJob[]>(serverJobsQueryKey);

      // Optimistically update to the new value
      if (previousJobs) {
        const optimisticJobList = previousJobs.filter(
          (job) => job.id !== jobID,
        );

        queryClient.setQueryData<ExistingServerJob[]>(
          serverJobsQueryKey,
          optimisticJobList,
        );
      }

      return { previousJobs };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    // note: context needs the onMutate function explicity typed.
    onError: (error, variables, context) => {
      devLogger.error(error);
      devLogger.debug("variables", variables);
      if (context?.previousJobs) {
        queryClient.setQueryData<ExistingServerJob[]>(
          serverJobsQueryKey,
          context.previousJobs,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(serverJobsQueryKey);
    },
  });

  const mutAddServerJob = useMutation(DbWrite.serverJobs.add, {
    // note: need to specify the parameter type here so that 'context' down in
    // onError will be correctly inferred. Weird but true. 🤨
    onMutate: async (job: NewServerJob) => {
      devLogger.debug("adding job:", job);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(serverJobsQueryKey);

      // Snapshot the previous value
      const previousJobs =
        queryClient.getQueryData<ExistingServerJob[]>(serverJobsQueryKey);

      // Optimistically update to the new value
      if (previousJobs) {
        queryClient.setQueryData<ExistingServerJob[]>(serverJobsQueryKey, [
          ...previousJobs,
          {
            ...job,
            id: "placeholder",
            refPath: "placeholder/dontknowyet",
          },
        ]);
      }

      return { previousJobs };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    // note: context needs the onMutate function explicity typed.
    onError: (error, variables, context) => {
      devLogger.error(error);
      devLogger.debug("variables", variables);
      if (context?.previousJobs) {
        queryClient.setQueryData<ExistingServerJob[]>(
          serverJobsQueryKey,
          context.previousJobs,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(serverJobsQueryKey);
    },
  });

  useLayoutEffect(() => {
    if (typeof checklistID === "string" && typeof siteKey === "string") {
      const unsubscribeChecklist = DbRead.parentRecords.subscribe(
        siteKey,
        checklistID,
        setChecklistTemplate,
      );

      const unsubscribeItems = DbRead.checklistItems.subscribeByChecklistID(
        siteKey,
        checklistID,
        setItemList,
      );

      const unsubscribeAll = () => {
        unsubscribeChecklist();
        unsubscribeItems();
      };
      return unsubscribeAll;
    }
  }, [siteKey, checklistID]);

  const getLocationTitle = useSiteKeyLocationsStore(
    (state) => state.getLocationTitle,
  );

  let locationName: string = "";
  if (checklistTemplate !== undefined) {
    locationName = getLocationTitle(checklistTemplate.locationID);
  }

  return (
    <TemplateDetailsPage
      checklist={checklistTemplate}
      itemList={validChecklistItems}
      onAddItem={handleAddItem}
      onUpdateItemOrder={handleUpdateItemOrder}
      responseList={checklistResponseList}
      onViewResponseHistory={handleViewResponseHistory}
      isHistoryLoading={isHistoryLoading}
      onRemoveItem={handleRemoveItem}
      onEditChecklist={handleEditChecklist}
      onViewChecklistTasks={handleOnViewChecklistTasks}
      onEditItem={handleEditItem}
      onDuplicateItem={handleDuplicateItem}
      actionQuickCreateChecklist={actionQuickCreateChecklist}
      userDisplayNamesMap={userDisplayNamesMap}
      locationName={locationName}
      addScheduleDialogProps={{
        isFetchingJobs: queryServerJobs.isLoading,
        serverJobList: queryServerJobs.data ?? [],
        onDeleteRule: mutationDeleteServerJob.mutateAsync,
        onFormSubmit: async (args) => {
          await mutAddServerJob.mutateAsync({
            ...basicJobProps,
            ...args,
          });
        },
      }}
      handleRowDragEvent={handleRowDragEvent}
      toggleSaveItemOrderButton={toggleSaveItemOrderButton}
      saveItemOrderDivRef={saveItemOrderDivRef}
      userCanUpdate={
        userSitePermissions?.permissions.canUpdateCraftRecords
          ? userSitePermissions.permissions.canUpdateCraftRecords
          : false
      }
    />
  );
}

/**
 * Helper function for handleRowDragEvent. Returns false if the order has not changed.
 */
function checkIfOrderChanged(
  originalList: ExistingChecklistItem[],
  newList: ExistingChecklistItem[],
): boolean {
  // Are the items in the arrays in the exact same order?
  const compare = originalList.every(
    (item, index) => item.id === newList[index].id,
  );
  // If they are in the same order, we want this ƒn to return false.
  return !compare;
}
