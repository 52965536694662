// Libs
import { useState } from "react";
import Fuse from "fuse.js";
import { XCircleIcon } from "@heroicons/react/24/solid";
import List from "@mui/material/List";

// Local
import * as strings from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseModal from "../BaseModal";
import placeholder_avatar from "../../images/placeholder_avatar.svg";
import { logger } from "../../logging";
import StyledMessage from "../StyledMessage";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";

export interface IMultipleUid_AssignUser {
  uid: ExistingSiteKeyUserDoc["id"];
  name: ExistingSiteKeyUserDoc["displayName"];
  company: ExistingSiteKeyUserDoc["companyName"];
  avatarUrl: ExistingSiteKeyUserDoc["userPhoto_URL"];
  isAssigned: boolean;
  assignedVehicle: string | null;
  subscribed?: boolean;
}

interface Props {
  // DIALOG BASICS
  /** Default is "Select Users" */
  headingText?: string;
  open: boolean;
  closeDialog: () => void;

  // SPECIFIC TO THIS COMPONENT
  userList: IMultipleUid_AssignUser[];
  handleSubmit: (args: IMultipleUid_AssignUser[]) => void;
  /** Default: "Encountered an error while updating users assigned to this task" */
  errorMessageText?: string;
}

export default function MultipleUidDialog(props: Props): JSX.Element {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filter, setFilter] = useState<FuseResultType>([]);

  const [usersState, setUsersState] = useState(props.userList);

  function handleChange(uid: string, checked: boolean) {
    const foundUser = usersState.find((u) => u.uid === uid);
    if (foundUser) {
      foundUser.isAssigned = checked;
      setUsersState((prev) => [...new Set([...prev, foundUser])]);
    }
  }

  function onSubmit() {
    setIsSubmitting(true);
    try {
      props.handleSubmit(usersState);
      setIsSubmitting(false);
      handleClose();
    } catch (e) {
      logger.info(`MultipleUidDialog submit handler ƒn error:`, e);
      setIsSubmitting(false);
      setShowErrorMessage(true);
    }
  }

  function handleClose() {
    setFilter([]);
    props.closeDialog();
  }

  return (
    <BaseModal
      open={props.open}
      closeModal={handleClose}
      title={props.headingText ?? strings.SELECT_USERS}
    >
      <div className="mt-8">
        <Filter
          list={props.userList}
          searchInput={setFilter}
          keys={["name", "company"]}
          placeholder={`${strings.SEARCH}...`}
        />

        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "scroll",
            maxHeight: 500,
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >
          {usersState
            .filter((user) => {
              if (filter.length === 0) {
                return user;
              } else {
                return filter.some(
                  (results) => results.item.name === user.name,
                );
              }
            })
            .map((user, i) => {
              const uniqueKey = `${user.name.replaceAll(" ", "_")}_${i}`;

              return (
                <label
                  key={uniqueKey}
                  htmlFor={uniqueKey}
                  className="mb-0 flex cursor-pointer items-center justify-between gap-1 rounded p-2 transition-colors hover:bg-gray-100"
                >
                  <div className="flex items-center gap-2">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.avatarUrl ?? placeholder_avatar}
                      alt={user.name}
                    />
                    <div>
                      <p
                        className={`${user.assignedVehicle || user.subscribed ? "text-gray-400" : "text-primary"}`}
                      >
                        {user.name}
                        {user.assignedVehicle
                          ? ` - vehicle ${user.assignedVehicle}`
                          : null}
                        {user.subscribed
                          ? ` already subscribed to this report`
                          : null}
                      </p>
                    </div>
                  </div>
                  <input
                    disabled={
                      user.subscribed === true || user.assignedVehicle != null
                    }
                    defaultChecked={user.isAssigned}
                    id={uniqueKey}
                    type="checkbox"
                    className="h-5 w-5 cursor-pointer accent-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary"
                    onChange={(ev) => handleChange(user.uid, ev.target.checked)}
                  />
                </label>
              );
            })}
        </List>

        <div className="mb-4 text-center text-sm">
          {showErrorMessage && (
            <StyledMessage type="error">
              {{
                message: props.errorMessageText ?? "Unknown error",
                icon: (
                  <XCircleIcon
                    className="h-8 w-8 text-red-900"
                    aria-hidden="true"
                  />
                ),
              }}
            </StyledMessage>
          )}
        </div>

        {/* ACTION BUTTONS */}
        <div className="flex justify-between gap-6">
          <BaseButtonSecondary
            type="button"
            onClick={props.closeDialog}
            className="flex-1 uppercase tracking-wider"
            disabled={isSubmitting}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>
          <BaseButtonPrimary
            type="submit"
            onClick={onSubmit}
            className="flex-1 uppercase tracking-wider"
            disabled={isSubmitting}
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}

type FuseResultType = Fuse.FuseResult<Record<string, any>>[];

interface FilterProps {
  list: Record<string, any>[];
  searchInput: (searchTerm: FuseResultType) => void;
  placeholder?: string;
  keys: string[];
}

function Filter(props: FilterProps) {
  const [inputValue, setInputValue] = useState("");

  const fuseOptions = {
    includeMatches: true,
    threshold: 0.2,
    keys: props.keys,
  };

  const fuse = new Fuse(props.list, fuseOptions);

  function search(searchTerm: string) {
    const result = fuse.search(searchTerm);
    props.searchInput(result);
  }

  return (
    <label className="mb-1 block overflow-hidden">
      <input
        className="w-full border-b-2 border-b-gray-400 outline-none placeholder:text-gray-500 hover:border-b-gray-700 focus:border-b-gray-700"
        type="text"
        placeholder={props.placeholder}
        value={inputValue}
        tabIndex={1}
        onChange={(ev) => {
          setInputValue(ev.target.value);
          search(ev.target.value);
        }}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
          }
        }}
      />
    </label>
  );
}
