//Libs
import { z } from "zod";

//Local
import { dropUndefined, guardIsPlainObject } from "../utils";
import { NotFoundError } from "../error-classes";
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { TwilioIncomingCallParams } from "./twilio";

/**
 * See [Twilio Docs Call Status Values](https://www.twilio.com/docs/voice/api/call-resource#call-status-values)
 *
 * We add "cleared" if a user needs to manually change the status to remove it
 * from the UI and there may not be another appropriate status.
 */
export const CallStatusEnum = {
  queued: "Queued",
  ringing: "Ringing",
  "in-progress": "In Progress",
  canceled: "Canceled",
  completed: "Completed",
  busy: "Busy",
  failed: "Failed",
  "no-answer": "No Answer",
  /**
   * This status is not from Twilio. It is used to indicate that the call has
   * been manually cleared by a user.
   */
  cleared: "Cleared",
} as const;
export type CallStatus = keyof typeof CallStatusEnum;

export interface StiltPhoneCall {
  timestampCreated: Timestamp;
  customerID: string | null;
  customData: { [key: string]: any };
  accountSid: string | null;
  apiVersion: string | null;
  callSid: string | null;
  callStatus: string | null;
  callToken: string | null;
  called: string | null;
  calledCity: string | null;
  calledCountry: string | null;
  calledState: string | null;
  calledZip: string | null;
  caller: string | null;
  callerCity: string | null;
  callerCountry: string | null;
  callerState: string | null;
  callerZip: string | null;
  direction: string | null;
  from: string | null;
  fromCity: string | null;
  fromCountry: string | null;
  fromState: string | null;
  fromZip: string | null;
  stirVerstat: string | null;
  to: string | null;
  toCity: string | null;
  toCountry: string | null;
  toState: string | null;
  toZip: string | null;
  customerSnapshot?: Record<string, any>;
  possibleCustomerIDs?: string[];
  recordingURL?: string;
  voicemail?: boolean;
}

export interface ExistingStiltPhoneCall extends StiltPhoneCall {
  id: string;
  refPath: string;
}

const stiltPhoneCallSchema = z.object({
  timestampCreated: z.instanceof(Timestamp),
  customerID: z.string().nullable(),
  customData: z.record(z.any()),
  accountSid: z.string().nullable(),
  apiVersion: z.string().nullable(),
  callSid: z.string().nullable(),
  callStatus: z.string().nullable(),
  callToken: z.string().nullable(),
  called: z.string().nullable(),
  calledCity: z.string().nullable(),
  calledCountry: z.string().nullable(),
  calledState: z.string().nullable(),
  calledZip: z.string().nullable(),
  caller: z.string().nullable(),
  callerCity: z.string().nullable(),
  callerCountry: z.string().nullable(),
  callerState: z.string().nullable(),
  callerZip: z.string().nullable(),
  direction: z.string().nullable(),
  from: z.string().nullable(),
  fromCity: z.string().nullable(),
  fromCountry: z.string().nullable(),
  fromState: z.string().nullable(),
  fromZip: z.string().nullable(),
  stirVerstat: z.string().nullable(),
  to: z.string().nullable(),
  toCity: z.string().nullable(),
  toCountry: z.string().nullable(),
  toState: z.string().nullable(),
  toZip: z.string().nullable(),
  customerSnapshot: z.record(z.any()).optional(),
  possibleCustomerIDs: z.array(z.string()).optional(),
  recordingURL: z.string().optional(),
  voicemail: z.boolean().optional(),
});

export type StiltPhoneCall_UpdateAPI = Omit<
  Partial<ExistingStiltPhoneCall>,
  "timestampCreated"
>;

function validateStiltPhoneCall_Update(
  value: unknown,
): StiltPhoneCall_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return phoneCallSchema_UpdateAPI.parse(value);
}

const withoutTimestampsSchema = stiltPhoneCallSchema.omit({
  timestampCreated: true,
});

const phoneCallSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();

// #region SECTION: Functions
/** Utilities for interacting with StiltPayment objects  */
export const StiltPhoneCallManager = {
  convertForFirestore: convertForFirestore,
  convertForFirestoreFromTwilio: convertForFirestoreFromTwilio,
  createFromFirestoreSnapshot: createFromFirestoreSnapshot,
  parse: validateStiltPhoneCall,
  /** Validate an existing estimate. For the update endpoint. */
  parseUpdate: validateStiltPhoneCall_Update,
  extractFullNameFromCustomData: extractFullNameFromCustomData,
} as const;

function convertForFirestoreFromTwilio(
  twilioIncomingCallParams: TwilioIncomingCallParams,
): DocumentData {
  const stiltPhoneCall: StiltPhoneCall = {
    timestampCreated: Timestamp.now(),
    customData: {},
    customerID: null,
    accountSid: twilioIncomingCallParams.AccountSid ?? null,
    apiVersion: twilioIncomingCallParams.ApiVersion ?? null,
    callSid: twilioIncomingCallParams.CallSid ?? null,
    callStatus: twilioIncomingCallParams.CallStatus ?? null,
    callToken: twilioIncomingCallParams.CallToken ?? null,
    called: twilioIncomingCallParams.Called ?? null,
    calledCity: twilioIncomingCallParams.CalledCity ?? null,
    calledCountry: twilioIncomingCallParams.CalledCountry ?? null,
    calledState: twilioIncomingCallParams.CalledState ?? null,
    calledZip: twilioIncomingCallParams.CalledZip ?? null,
    caller: twilioIncomingCallParams.Caller ?? null,
    callerCity: twilioIncomingCallParams.CallerCity ?? null,
    callerCountry: twilioIncomingCallParams.CallerCountry ?? null,
    callerState: twilioIncomingCallParams.CallerState ?? null,
    callerZip: twilioIncomingCallParams.CallerZip ?? null,
    direction: twilioIncomingCallParams.Direction ?? null,
    from: twilioIncomingCallParams.From ?? null,
    fromCity: twilioIncomingCallParams.FromCity ?? null,
    fromCountry: twilioIncomingCallParams.FromCountry ?? null,
    fromState: twilioIncomingCallParams.FromState ?? null,
    fromZip: twilioIncomingCallParams.FromZip ?? null,
    stirVerstat: twilioIncomingCallParams.StirVerstat ?? null,
    to: twilioIncomingCallParams.To ?? null,
    toCity: twilioIncomingCallParams.ToCity ?? null,
    toCountry: twilioIncomingCallParams.ToCountry ?? null,
    toState: twilioIncomingCallParams.ToState ?? null,
    toZip: twilioIncomingCallParams.ToZip ?? null,
  };
  const local = Object.assign({}, stiltPhoneCall);
  return dropUndefined(local);
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertForFirestore(
  stiltPhoneCall: ExistingStiltPhoneCall | StiltPhoneCall_UpdateAPI,
): DocumentData {
  const local = Object.assign({}, stiltPhoneCall);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  return dropUndefined(rest);
}

function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingStiltPhoneCall {
  if (!snapshot.exists()) {
    throw new NotFoundError("Document does not exist.");
  }
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateStiltPhoneCall(snapshot.data()),
  };
}

/* Zod validation schemas */
function validateStiltPhoneCall(value: unknown): StiltPhoneCall {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = stiltPhoneCallSchema.parse(value);
  return result;
}

/**
 * Utility function to extract the full name from the customData field of a phone call.
 */
function extractFullNameFromCustomData(
  callDoc: ExistingStiltPhoneCall,
): string | undefined {
  const customerSnapshot = callDoc.customerSnapshot;
  if (customerSnapshot && typeof customerSnapshot === "object") {
    const { firstName, lastName, name } = customerSnapshot;
    if (name) {
      return name.trim();
    }
    if (firstName || lastName) {
      return `${firstName ?? ""} ${lastName ?? ""}`.trim();
    }
  }
  return undefined;
}

// #endregion
