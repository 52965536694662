// Libs
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCallback, useEffect, useMemo, useState } from "react";

// Local
import WorkTypeCard from "../../components/admin/WorkTypeCard";
import Breadcrumbs from "../../components/Breadcrumbs";
import { DbRead, DbWrite } from "../../database";
import {
  CraftTypes,
  getStringsFromCraftTypes,
  isValidCraftType,
} from "../../models/craft-types";
import SiteDetailsPage from "./SiteDetailsPage";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  EditSiteKeyDetailsState,
  ExistingSiteKey,
  SiteKeyManager,
} from "../../models/site-key";
import { diffObjects } from "../../assets/js/object-diff";
import { logger as devLogger } from "../../logging";
import { convertAddressToCoords } from "../../utils/googleGeocoding";
import { SiteDetailsMapProps } from "../../components/admin/SiteDetails";
import SiteKeyDropdown from "../../components/admin/SiteKeyDropdown";
import StyledMessage from "../../components/StyledMessage";
import { ADMIN_MY_SITES_URL } from "../../urls";

type displayMessageDataType = {
  text: string;
  type: "success" | "error";
};

export default function SiteDetailsContainer() {
  // Retrieve chosen siteKey id from the url.
  type UrlParams = { id: string };
  const { id: selectedSiteKeyID } = useParams<UrlParams>();
  if (typeof selectedSiteKeyID !== "string") {
    throw new Error(`selectedSiteKeyID was not a string: ${selectedSiteKeyID}`);
  }

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [displayResponseMessageData, setDisplayResponseMessageData] =
    useState<displayMessageDataType | null>(null);

  // Retrieve admin's siteKey docs and the loading status.
  const { data: siteKeyList = [], isLoading } = useQuery(
    ["siteDetailsContainer_siteKeyList"],
    () => DbRead.siteKey.getRootUserListOfSiteKeyDocs(),
  );

  //sort the siteKeyList alphabetically
  const sortedSiteKeyList = useMemo(
    function (): ExistingSiteKey[] {
      return siteKeyList.sort((a, b) => a.id!.localeCompare(b.id!));
    },
    [siteKeyList],
  );

  // Retrieve the current siteKey doc.
  const currentSiteKey = sortedSiteKeyList.find(
    (siteKey) => siteKey.id === selectedSiteKeyID,
  );

  // Craft types that the current siteKey has access to.
  const craftTypeList = currentSiteKey ? currentSiteKey.validCraftTypes : [];

  // Filter out any invalid craft types.
  const availableCraftTypes = craftTypeList.filter((type) =>
    isValidCraftType(type),
  );

  const timezoneList = getTimezoneList(
    currentSiteKey ? currentSiteKey.timezone : "",
  );

  const [latitude, setLatitude] = useState<number | undefined>(
    currentSiteKey?.latitude,
  );
  const [longitude, setLongitude] = useState<number | undefined>(
    currentSiteKey?.longitude,
  );

  const responseMessage =
    displayResponseMessageData != null ? (
      <StyledMessage
        type={displayResponseMessageData.type}
        dismissible
        onDismiss={clearMessage}
      >
        {{ message: displayResponseMessageData.text }}
      </StyledMessage>
    ) : null;

  const mutateSiteKey = useMutation(
    async (args: { siteKey: string; validatedData: EditSiteKeyDetailsState }) =>
      await DbWrite.siteKey.edit(args.siteKey, args.validatedData),
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries([
          "siteDetailsContainer_siteKeyList",
        ]),
    },
  );

  const handleSaveValues = useCallback(
    async (formValues: EditSiteKeyDetailsState): Promise<void> => {
      // The initial values, for comparison.
      const initialValues = {
        name: currentSiteKey?.name,
        timezone: currentSiteKey?.timezone,
        address: currentSiteKey?.address,
        latitude: currentSiteKey?.latitude,
        longitude: currentSiteKey?.longitude,
      };

      // Compare initial values and current values, returning the differences.
      const changedValues: EditSiteKeyDetailsState = diffObjects(
        initialValues,
        formValues,
      ).diff;
      devLogger.debug("site details changedValues:", changedValues);

      // If there's been no change, return early.
      if (Object.keys(changedValues).length === 0) {
        setDisplayResponseMessageData({
          text: "No changes to save.",
          type: "error",
        });
        return;
      }

      // Validate.
      const validated = SiteKeyManager.parseEditDetails(changedValues);

      try {
        // Write to DB.
        await mutateSiteKey.mutateAsync({
          siteKey: selectedSiteKeyID,
          validatedData: validated,
        });
        setDisplayResponseMessageData({
          text: "Site details saved.",
          type: "success",
        });
      } catch (e) {
        setDisplayResponseMessageData({
          text: "Problem saving site details.",
          type: "error",
        });
        devLogger.error(
          `Error saving site details for ${selectedSiteKeyID}:`,
          e,
        );
      }
    },
    [
      selectedSiteKeyID,
      currentSiteKey?.name,
      currentSiteKey?.timezone,
      currentSiteKey?.address,
      currentSiteKey?.latitude,
      currentSiteKey?.longitude,
      mutateSiteKey,
    ],
  );

  // TODO: figure out why i can't get the user input to persist *during* onSubmit
  const siteDetails: SiteDetailsMapProps = {
    siteName: currentSiteKey?.name ?? "",
    timezone: timezoneList,
    address: currentSiteKey?.address ?? "",
    latitude,
    longitude,
    handleSave: handleSaveValues,
  };

  useEffect(() => {
    async function getCoordinates() {
      const undefinedLatitude = currentSiteKey?.latitude === undefined;
      const undefinedLongitude = currentSiteKey?.longitude === undefined;

      // If the siteKey's address field is not an empty string, and latitude or
      // longitude are not defined, get the coordinates.
      if (
        currentSiteKey &&
        currentSiteKey.address &&
        currentSiteKey.address.length > 1 &&
        (undefinedLatitude || undefinedLongitude)
      ) {
        const coords = await convertAddressToCoords(currentSiteKey.address);
        if (coords) {
          setLatitude(coords.latitude);
          setLongitude(coords.longitude);

          // Update the database.
          await handleSaveValues({ latitude, longitude });
        }
      } else {
        // Need to 'reset' the values when the siteKey changes.
        setLatitude(currentSiteKey?.latitude);
        setLongitude(currentSiteKey?.longitude);
      }
    }
    getCoordinates();
  }, [currentSiteKey, latitude, longitude, handleSaveValues]);

  // Loading indicator.
  if (isLoading || !currentSiteKey) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  // #region SECTION: Composition Elements
  // Breadcrumbs
  const home = { name: "Admin", href: ADMIN_MY_SITES_URL, current: false };
  const pages = [
    { name: "My Sites", href: ADMIN_MY_SITES_URL, current: false },
    {
      name: `${currentSiteKey.name}`,
      href: `${ADMIN_MY_SITES_URL}/${currentSiteKey.id}`,
      current: true,
    },
  ];
  const breadcrumbs = <Breadcrumbs home={home} pages={pages} />;

  const siteKeyDropdown = (
    <SiteKeyDropdown
      currentSiteKey={currentSiteKey}
      allSiteKeys={sortedSiteKeyList}
      onSelection={handleSelectSiteKey}
      styles="w-full xs:w-52"
    />
  );

  const workTypeCards = availableCraftTypes.map((craftType) => (
    <WorkTypeCard
      key={craftType}
      availableCraftType={craftType}
      onHandleView={() => handleViewWorkType(craftType)}
    />
  ));
  // #endregion Composition Elements

  function handleViewWorkType(craftType: CraftTypes) {
    const workType = getStringsFromCraftTypes(craftType);
    navigate(
      `${ADMIN_MY_SITES_URL}/${selectedSiteKeyID}/custom-fields/${workType}`,
    );
  }

  function handleSelectSiteKey(siteKeyID: string | null) {
    // If they clicked the same siteKey they're already viewing, do nothing.
    if (siteKeyID !== null && siteKeyID !== selectedSiteKeyID) {
      navigate(`${ADMIN_MY_SITES_URL}/${siteKeyID}`);
    }
  }

  function clearMessage() {
    setDisplayResponseMessageData(null);
  }

  return (
    <SiteDetailsPage siteDetails={siteDetails}>
      {{
        Breadcrumbs: breadcrumbs,
        SiteKeyDropdown: siteKeyDropdown,
        WorkTypeCards: workTypeCards,
        DisplayResponseMessage: responseMessage,
      }}
    </SiteDetailsPage>
  );
}

/**
 * Ensure that the same timezone doesn't show up twice in our list.
 */
export function getTimezoneList(currentTimezone: string): string[] {
  // We decided to go with hardcoding timezones, in lieu of using a library.
  // Values were retrieved from the JavaScript Intl API.
  const timezones = [
    "America/Adak",
    "America/Anchorage",
    "America/Boise",
    "America/Chicago",
    "America/Denver",
    "America/Detroit",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indianapolis",
    "America/Juneau",
    "America/Kentucky/Monticello",
    "America/Los_Angeles",
    "America/Louisville",
    "America/Menominee",
    "America/Metlakatla",
    "America/New_York",
    "America/Nome",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Phoenix",
    "America/Sitka",
    "America/Yakutat",
    "Pacific/Honolulu",
  ];

  const tzIndex = timezones.findIndex((val) => val === currentTimezone);

  if (tzIndex !== -1) {
    timezones.splice(tzIndex, 1);
  }

  return [currentTimezone, ...timezones];
}
