//Libs
import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GetQuickFilterTextParams,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";

//Local
import * as strings from "../../strings";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import isEqual from "lodash/isEqual";
import SearchBox from "../../components/SearchBox";
import { ExistingLightspeedTransaction } from "../../models/lightspeed";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import SiteKeyLocationCustomFilter from "../../components/tables/SiteKeyLocationCustomFilter";

interface Props {
  tableData: ExistingLightspeedTransaction[];
  pendingOrSubmitted: "pending" | "submitted";
  openInvoiceDialog: (invoiceID: string) => void;
  setSelectedRows: (rows: any) => void;
  exportToExcelButton: React.ReactNode;
  actionDropdownButton: React.ReactNode;
  pendingOrSubmittedToggle: React.ReactNode;
  dateRangePicker: React.ReactNode;
}

export default function LightspeedPage({
  tableData,
  openInvoiceDialog,
  exportToExcelButton,
  actionDropdownButton,
  setSelectedRows,
  pendingOrSubmitted,
  pendingOrSubmittedToggle,
  dateRangePicker,
}: Props) {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const siteKeyLocationList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );
  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  function rowSelectionChanged() {
    setSelectedRows(getSelectedRowData());
  }

  function getSelectedRowData() {
    const selectedData = gridRef.current.api?.getSelectedRows();
    return selectedData;
  }

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value,
    );
  }, []);

  return (
    <div>
      <div className="flex flex-wrap gap-2 pb-4">
        <span>
          <h1 className="text-5xl font-semibold text-primary">
            {strings.LIGHTSPEED_TRANSACTIONS}
          </h1>
          {pendingOrSubmitted === "submitted" && (
            <h3>{`${tableData?.length ?? 0} transactions submitted within the date range.`}</h3>
          )}
          {pendingOrSubmitted === "pending" && (
            <h3>{`${tableData?.length ?? 0} pending transactions. These will attempt to push automatically every day at midnight.`}</h3>
          )}
        </span>
      </div>
      <div className="flex flex-row items-center justify-end gap-2">
        {actionDropdownButton}
        <SearchBox onInput={onFilterTextBoxChanged} widthClasses="max-w-sm" />
        <div className="w-full"></div>
        {pendingOrSubmitted === "submitted" && dateRangePicker}
        {pendingOrSubmittedToggle}
        {exportToExcelButton}
      </div>
      <div className={"ag-theme-alpine flex h-[600px] flex-col"}>
        <LightspeedListTable
          tableData={tableData}
          openInvoiceDialog={openInvoiceDialog}
          gridRef={gridRef}
          onGridReady={onGridReady}
          rowSelectionChanged={rowSelectionChanged}
          siteKeyLocations={siteKeyLocationList}
        />
      </div>
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  // minWidth: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  // wrapText: true,
  autoHeight: true,
  headerClass: headerStyles,
  wrapHeaderText: true,
  autoHeaderHeight: true,
};

interface LightspeedListTableProps {
  tableData: Props["tableData"];
  openInvoiceDialog: Props["openInvoiceDialog"];
  rowSelectionChanged: (event: GridReadyEvent) => void;
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
  siteKeyLocations: ExistingSiteKeyLocation[];
}

/* TABLE COMPONENT */
const LightspeedListTable = memo(
  ({
    tableData,
    openInvoiceDialog,
    rowSelectionChanged,
    gridRef,
    onGridReady,
    siteKeyLocations,
  }: LightspeedListTableProps) => {
    const renderName = (params: ICellRendererParams): JSX.Element => {
      return <div className="capitalize">{params.data.priceBookItemTitle}</div>;
    };

    const renderQuantity = (params: ICellRendererParams): JSX.Element => {
      return <div>{params.data.quantity}</div>;
    };

    const renderInvoiceNumber = (params: ICellRendererParams): JSX.Element => {
      return <div>{params.data.invoiceNumber}</div>;
    };

    const renderLocation = (params: ICellRendererParams): JSX.Element => {
      const location = siteKeyLocations.find(
        (location) => location.id === params.data.locationID,
      );
      return <div>{location?.title ?? "Unknown Location"}</div>;
    };

    const renderTimestampPushed = (
      params: ICellRendererParams,
    ): JSX.Element => {
      if (params.data.timestampPushed) {
        return (
          <div className="capitalize">
            {convertToReadableTimestamp(params.data.timestampPushed)}
          </div>
        );
      }
      return <div />;
    };

    const renderTimestampCreated = (
      params: ICellRendererParams,
    ): JSX.Element => {
      if (params.data.timestampCreated) {
        return (
          <div className="capitalize">
            {convertToReadableTimestamp(params.data.timestampCreated)}
          </div>
        );
      }
      return <div />;
    };

    function handleClickEvent(data: any) {
      return openInvoiceDialog(data.invoiceID);
    }

    const columnDefs: ColDef[] = [
      {
        headerName: "Item",
        cellRenderer: renderName,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.data.priceBookItemTitle;
        },
      },
      {
        field: "quantity",
        headerName: "Quantity",
        cellRenderer: renderQuantity,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.data.quantity;
        },
      },
      {
        field: "invoiceNumber",
        headerName: "Invoice Number",
        cellRenderer: renderInvoiceNumber,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.data.invoiceNumber;
        },
      },
      {
        field: "locationID",
        headerName: "Location",
        cellRenderer: renderLocation,
        filter: SiteKeyLocationCustomFilter,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.data.locationID;
        },
      },
      {
        field: "timestampPushed",
        headerName: "Pushed to Lightspeed",
        cellRenderer: renderTimestampPushed,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          getTimestampQuickFilterString(params.data?.timestampPushed),
      },
      {
        field: "timestampCreated",
        headerName: "Created",
        cellRenderer: renderTimestampCreated,
        onCellClicked: (ev) => handleClickEvent(ev.data),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          getTimestampQuickFilterString(params.data?.timestampCreated),
      },
    ];

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="mt-5 shadow"
        rowData={tableData}
        animateRows={true}
        rowSelection="multiple"
        enableCellTextSelection={true}
        suppressRowClickSelection={true}
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        onSelectionChanged={(event) => {
          rowSelectionChanged(event);
        }}
      />
    );
  },
  (previous, next) => {
    const isTableDataTheSame = isEqual(previous.tableData, next.tableData);
    return isTableDataTheSame;
  },
);

function getTimestampQuickFilterString(timestamp: unknown): string {
  if (timestamp != null) {
    return convertToReadableTimestamp(timestamp);
  } else {
    return "";
  }
}
