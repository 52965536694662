import { ExistingChecklistResponse } from "../models/checklist-response";

/**
 * Sorts by the orderNum property available on the checklist response
 * documents. Sorts by ascending.
 * If the given listOfResponses contains any orderNum property where the value
 * is undefined (or null), those will appear at the end of the list.
 */
export function sortByOrderNum(
  listOfResponses: ExistingChecklistResponse[],
): ExistingChecklistResponse[] {
  const copyListOfResponses = [...listOfResponses];

  return copyListOfResponses.sort((a, b) => {
    const aIsPositiveNumber = typeof a.orderNum === "number" && a.orderNum >= 0;
    const bIsPositiveNumber = typeof b.orderNum === "number" && b.orderNum >= 0;

    if (aIsPositiveNumber && bIsPositiveNumber) {
      return a.orderNum - b.orderNum;
    } else if (!aIsPositiveNumber && bIsPositiveNumber) {
      return 1;
    } else if (aIsPositiveNumber && !bIsPositiveNumber) {
      return -1;
    } else {
      return 0;
    }
  });
}
