/**
 * Defined task type codes.
 */
export enum TaskTypes {
  PHONE_CALL = 3,
  ESTIMATE = 5,
  INSTALLATION = 10,
  MODIFICATION = 11,
  REMOVAL = 12,
  INSPECTION = 13,
  MATERIAL_PICKUP = 14,
  WATERBLASTING = 20,
  VACUUM_TRUCK = 25,
  SANDBLASTING = 30,
  CLOTH_REPLACEMENT = 31,
  INSULATION = 40,
  ABATEMENT = 50,
  PAINTING = 60,
  REPAIR = 70,
  CARPENTRY = 75,
  LOCKOUT = 90,
  WALKTHROUGH = 91,
  PERFORMING_WORK = 92,
  GENERAL_LABOR = 95,
  AUTO_DETAILING = 96,
  HOUSEKEEPING = 99,
  CLEANING = 100,
  OPERATOR_ROUNDS = 110,
  SAFETY_INSPECTION = 111,
  PM_ROUTE = 112,
  HOUSEKEEPING_INSPECTION = 113,
  EQUIPMENT_INSPECTION = 114,
  WARRANTY = 601,
  SERVICE_CALL = 602,
  EMERGENCY_CALL = 603,
  CALL_BACK = 604,
  NO_HEAT = 605,
  NO_AC = 606,
  LEAKY_PIPE_DRAIN = 607,
  CLOGGED_DRAIN = 608,
  GENERATOR_INSTALL = 609,
  NO_HOT_WATER = 610,
  REFRIGERATION = 611,
  FURNACE_SERVICE = 612,
  PREVENTATIVE_MAINTENANCE = 613,
  HWT_INSTALL = 614,
  WATER_SOFTENER_INSTALL = 615,
  FROZEN_PIPES = 616,
  HEARTH_SERVICE_WOOD_PELLET = 617,
  WETT_INSPECTION = 618,
  BOILER_SERVICE = 619,
  AIR_CONDITIONING_SERVICE = 620,
  AIRDUCT_CLEANING = 1001,
  CARPET_CLEANING = 1002,
  CARPET_STRETCHING = 1003,
  COMMERCIAL_CARPET_CLEANING = 1004,
  DRYER_VENT_CLEANING = 1005,
  TILE_AND_GROUT = 1006,
  UPHOLSTERY_CLEANING = 1007,
  WATER_MITIGATION = 1008,
  MOLD_MITIGATION = 1012,
  HARDWOOD = 1013,
  RESTORATION = 1014,
  PRESSURE_WASHING = 1015,
  DUMPSTER_RENTAL = 1016,
  COMMERCIAL = 1017,
  RETOUCH = 1009,
  PICKUP_DROPOFF = 1010,
  IN_OFFICE = 1011,
  FURNACE_TUNEUP = 630,
  SEASONAL_TUNEUP = 631,
  RESIDENTIAL_SERVICE = 632,
  COMMERCIAL_SERVICE = 633,
  COMFORT_CLUB = 634,
  NO_COOL = 640,
  WATER_LEAK = 641,
  OTHER = 642,
  INSTALL_PART_SERVICE_LARGE = 643,
  INSTALL_PART_SERVICE_NORMAL = 644,
  INSTALL_PART_SERVICE_MED = 645,
  MAINTENANCE_PLAN = 646,
  CHECK_UP = 647,
  MARINE = 648,
  FILTER_CHANGE_OUT = 649,
  RESIDENTIAL_NEW_CONSTRUCTION = 650,
  COMMERCIAL_NEW_CONSTRUCTION = 651,
  MEETING = 652,
  SECOND_OPINION = 653,
  AQUA_PRO = 654,
  ORDER_FILTERS = 655,
  INSTALL_EVALUATION = 656,
  MEASURE_FOR_FILTERS = 657,
  GAS_N_GO = 658,
  PICK_UP_PARTS = 659,
  RECALL = 660,
  SERVICE_AND_REPAIR = 670,
  AC_NOT_COOLING = 672,
  FOLLOW_UP_JOB_COMPLETION = 673,
  SPLIT_SYSTEM_INSTALL = 674,
  POST_INSTALL_INSPECTION = 675,
  PMA_RI = 676,
  DUCT_REPAIR_CLEANING = 677,
  CLEAN_AND_CHECK = 678,
  GENERAL = 679,
  PART_REPLACEMENT = 680,
  AC_NOT_HEATING = 681,
  SERVICE_CALL_ERROR_CODE = 682,
  FAIL_INSPECTION_CORRECTION = 683,
  WARRANTY_SERVICE_CALL = 684,
  SYSTEM_NOT_WORKING = 685,
  WEEKEND_SERVICE_CALL = 686,
  AFTER_HOUR_SERVICE_CALL = 687,
  MASTIC_SEAL = 688,
  WATER_LEAKING = 689,
  MAINTENANCE_TUNE_UP = 690,
  DUCT_CHANGE_OUT = 692,
  FORM_PICK_UP_AND_DELIVERY = 693,
  PACKAGE_SYSTEM_INSTALL = 694,
  PART_DELIVERY = 695,
  BLOW_IN_INSULATION = 696,
  BOLLARDS = 701,
  CONCRETE = 702,
  CONVEYOR = 703,
  DOORS = 704,
  ELECTRICAL = 705,
  EPOXY = 706,
  FENCING = 707,
  HANDYMAN = 708,
  MASONRY = 709,
  PARKING_LOT = 710,
  ROOFING = 711,
  SPECIALTY_PROJECT = 712,
}

/**
 * Defined task type codes.
 */
export const OTaskTypes = {
  PHONE_CALL: 3,
  ESTIMATE: 5,
  INSTALLATION: 10,
  MODIFICATION: 11,
  REMOVAL: 12,
  INSPECTION: 13,
  MATERIAL_PICKUP: 14,
  WATERBLASTING: 20,
  VACUUM_TRUCK: 25,
  SANDBLASTING: 30,
  CLOTH_REPLACEMENT: 31,
  INSULATION: 40,
  ABATEMENT: 50,
  PAINTING: 60,
  REPAIR: 70,
  CARPENTRY: 75,
  LOCKOUT: 90,
  WALKTHROUGH: 91,
  PERFORMING_WORK: 92,
  GENERAL_LABOR: 95,
  AUTO_DETAILING: 96,
  HOUSEKEEPING: 99,
  CLEANING: 100,
  OPERATOR_ROUNDS: 110,
  SAFETY_INSPECTION: 111,
  PM_ROUTE: 112,
  HOUSEKEEPING_INSPECTION: 113,
  EQUIPMENT_INSPECTION: 114,
  WARRANTY: 601,
  SERVICE_CALL: 602,
  EMERGENCY_CALL: 603,
  CALL_BACK: 604,
  NO_HEAT: 605,
  NO_AC: 606,
  LEAKY_PIPE_DRAIN: 607,
  CLOGGED_DRAIN: 608,
  GENERATOR_INSTALL: 609,
  NO_HOT_WATER: 610,
  REFRIGERATION: 611,
  FURNACE_SERVICE: 612,
  PREVENTATIVE_MAINTENANCE: 613,
  HWT_INSTALL: 614,
  WATER_SOFTENER_INSTALL: 615,
  FROZEN_PIPES: 616,
  HEARTH_SERVICE_WOOD_PELLET: 617,
  WETT_INSPECTION: 618,
  BOILER_SERVICE: 619,
  AIR_CONDITIONING_SERVICE: 620,
  AIRDUCT_CLEANING: 1001,
  CARPET_CLEANING: 1002,
  CARPET_STRETCHING: 1003,
  COMMERCIAL_CARPET_CLEANING: 1004,
  DRYER_VENT_CLEANING: 1005,
  TILE_AND_GROUT: 1006,
  UPHOLSTERY_CLEANING: 1007,
  WATER_MITIGATION: 1008,
  RETOUCH: 1009,
  PICKUP_DROPOFF: 1010,
  IN_OFFICE: 1011,
  MOLD_MITIGATION: 1012,
  HARDWOOD: 1013,
  RESTORATION: 1014,
  PRESSURE_WASHING: 1015,
  DUMPSTER_RENTAL: 1016,
  COMMERCIAL: 1017,
  FURNACE_TUNEUP: 630,
  SEASONAL_TUNEUP: 631,
  RESIDENTIAL_SERVICE: 632,
  COMMERCIAL_SERVICE: 633,
  COMFORT_CLUB: 634,
  NO_COOL: 640,
  WATER_LEAK: 641,
  OTHER: 642,
  INSTALL_PART_SERVICE_LARGE: 643,
  INSTALL_PART_SERVICE_NORMAL: 644,
  INSTALL_PART_SERVICE_MED: 645,
  MAINTENANCE_PLAN: 646,
  CHECK_UP: 647,
  MARINE: 648,
  FILTER_CHANGE_OUT: 649,
  RESIDENTIAL_NEW_CONSTRUCTION: 650,
  COMMERCIAL_NEW_CONSTRUCTION: 651,
  MEETING: 652,
  SECOND_OPINION: 653,
  AQUA_PRO: 654,
  ORDER_FILTERS: 655,
  INSTALL_EVALUATION: 656,
  MEASURE_FOR_FILTERS: 657,
  GAS_N_GO: 658,
  PICK_UP_PARTS: 659,
  RECALL: 660,
  SERVICE_AND_REPAIR: 670,
  AC_NOT_COOLING: 672,
  FOLLOW_UP_JOB_COMPLETION: 673,
  SPLIT_SYSTEM_INSTALL: 674,
  POST_INSTALL_INSPECTION: 675,
  PMA_RI: 676,
  DUCT_REPAIR_CLEANING: 677,
  CLEAN_AND_CHECK: 678,
  GENERAL: 679,
  PART_REPLACEMENT: 680,
  AC_NOT_HEATING: 681,
  SERVICE_CALL_ERROR_CODE: 682,
  FAIL_INSPECTION_CORRECTION: 683,
  WARRANTY_SERVICE_CALL: 684,
  SYSTEM_NOT_WORKING: 685,
  WEEKEND_SERVICE_CALL: 686,
  AFTER_HOUR_SERVICE_CALL: 687,
  MASTIC_SEAL: 688,
  WATER_LEAKING: 689,
  MAINTENANCE_TUNE_UP: 690,
  DUCT_CHANGE_OUT: 692,
  FORM_PICK_UP_AND_DELIVERY: 693,
  PACKAGE_SYSTEM_INSTALL: 694,
  PART_DELIVERY: 695,
  BLOW_IN_INSULATION: 696,
  BOLLARDS: 701,
  CONCRETE: 702,
  CONVEYOR: 703,
  DOORS: 704,
  ELECTRICAL: 705,
  EPOXY: 706,
  FENCING: 707,
  HANDYMAN: 708,
  MASONRY: 709,
  PARKING_LOT: 710,
  ROOFING: 711,
  SPECIALTY_PROJECT: 712,
} as const;
export type TaskTypesValues = (typeof OTaskTypes)[keyof typeof OTaskTypes];

/** Typeguard ƒn */
export function isValidTaskType(val: unknown): val is TaskTypesValues {
  return Object.values(OTaskTypes).includes(val as any);
}

/**
 * Return the human readable string for the task type integer.
 * @param taskType
 */
export function getReadableTaskType(value: number): string {
  switch (value) {
    case OTaskTypes.PHONE_CALL:
      return "Phone Call";
    case OTaskTypes.ESTIMATE:
      return "Estimate";
    case OTaskTypes.INSTALLATION:
      return "Installation";
    case OTaskTypes.MODIFICATION:
      return "Modification";
    case OTaskTypes.REMOVAL:
      return "Removal";
    case OTaskTypes.INSPECTION:
      return "Inspection";
    case OTaskTypes.MATERIAL_PICKUP:
      return "Material Pickup";
    case OTaskTypes.WATERBLASTING:
      return "Waterblasting";
    case OTaskTypes.VACUUM_TRUCK:
      return "Vacuum Truck";
    case OTaskTypes.SANDBLASTING:
      return "Sandblasting";
    case OTaskTypes.CLOTH_REPLACEMENT:
      return "Cloth Replacement";
    case OTaskTypes.INSULATION:
      return "Insulation";
    case OTaskTypes.ABATEMENT:
      return "Abatement";
    case OTaskTypes.PAINTING:
      return "Painting";
    case OTaskTypes.REPAIR:
      return "Repair";
    case OTaskTypes.CARPENTRY:
      return "Carpentry";
    case OTaskTypes.LOCKOUT:
      return "Lockout";
    case OTaskTypes.WALKTHROUGH:
      return "Walkthrough";
    case OTaskTypes.PERFORMING_WORK:
      return "Performing Work";
    case OTaskTypes.GENERAL_LABOR:
      return "General Labor";
    case OTaskTypes.AUTO_DETAILING:
      return "Auto Detailing";
    case OTaskTypes.HOUSEKEEPING:
      return "Housekeeping";
    case OTaskTypes.CLEANING:
      return "Cleaning";
    case OTaskTypes.OPERATOR_ROUNDS:
      return "Operator Rounds";
    case OTaskTypes.SAFETY_INSPECTION:
      return "Safety Inspection";
    case OTaskTypes.PM_ROUTE:
      return "PM Route";
    case OTaskTypes.HOUSEKEEPING_INSPECTION:
      return "Housekeeping Inspection";
    case OTaskTypes.EQUIPMENT_INSPECTION:
      return "Equipment Inspection";
    case OTaskTypes.WARRANTY:
      return "Warranty";
    case OTaskTypes.SERVICE_CALL:
      return "Service Call";
    case OTaskTypes.EMERGENCY_CALL:
      return "Emergency Call";
    case OTaskTypes.CALL_BACK:
      return "Call Back";
    case OTaskTypes.NO_HEAT:
      return "No Heat";
    case OTaskTypes.NO_AC:
      return "No AC";
    case OTaskTypes.LEAKY_PIPE_DRAIN:
      return "Leak Pipe/Drain";
    case OTaskTypes.CLOGGED_DRAIN:
      return "Clogged Drain";
    case OTaskTypes.GENERATOR_INSTALL:
      return "Generator Install";
    case OTaskTypes.NO_HOT_WATER:
      return "No Hot Water";
    case OTaskTypes.REFRIGERATION:
      return "Refrigeration";
    case OTaskTypes.FURNACE_SERVICE:
      return "Furnace Service";
    case OTaskTypes.PREVENTATIVE_MAINTENANCE:
      return "Preventative Maintenance";
    case OTaskTypes.HWT_INSTALL:
      return "HWT Install";
    case OTaskTypes.WATER_SOFTENER_INSTALL:
      return "Water Softener Install";
    case OTaskTypes.FROZEN_PIPES:
      return "Frozen Pipes";
    case OTaskTypes.HEARTH_SERVICE_WOOD_PELLET:
      return "Hearth Service-Wood/Pellet";
    case OTaskTypes.WETT_INSPECTION:
      return "WETT Inspection";
    case OTaskTypes.BOILER_SERVICE:
      return "Boiler Service";
    case OTaskTypes.AIR_CONDITIONING_SERVICE:
      return "Air Conditioning Service";
    case OTaskTypes.AIRDUCT_CLEANING:
      return "Airduct Cleaning";
    case OTaskTypes.CARPET_CLEANING:
      return "Carpet Cleaning";
    case OTaskTypes.CARPET_STRETCHING:
      return "Carpet Stretching";
    case OTaskTypes.COMMERCIAL_CARPET_CLEANING:
      return "Commercial Carpet Cleaning";
    case OTaskTypes.DRYER_VENT_CLEANING:
      return "Dryer Vent Cleaning";
    case OTaskTypes.TILE_AND_GROUT:
      return "Tile & Grout";
    case OTaskTypes.UPHOLSTERY_CLEANING:
      return "Upholstery Cleaning";
    case OTaskTypes.WATER_MITIGATION:
      return "Water Mitigation";
    case OTaskTypes.MOLD_MITIGATION:
      return "Mold Mitigation";
    case OTaskTypes.COMMERCIAL:
      return "Commercial";
    case OTaskTypes.HARDWOOD:
      return "Hardwood";
    case OTaskTypes.RESTORATION:
      return "Restoration";
    case OTaskTypes.PRESSURE_WASHING:
      return "Pressure Washing";
    case OTaskTypes.DUMPSTER_RENTAL:
      return "Dumpster Rental";
    case OTaskTypes.RETOUCH:
      return "Re-Touch";
    case OTaskTypes.PICKUP_DROPOFF:
      return "Pickup/Dropoff";
    case OTaskTypes.IN_OFFICE:
      return "IN OFFICE";
    case OTaskTypes.FURNACE_TUNEUP:
      return "Furnace Tune-up";
    case OTaskTypes.SEASONAL_TUNEUP:
      return "Seasonal Tune-up";
    case OTaskTypes.RESIDENTIAL_SERVICE:
      return "Residential Service";
    case OTaskTypes.COMMERCIAL_SERVICE:
      return "Commercial Service";
    case OTaskTypes.COMFORT_CLUB:
      return "Comfort Club";
    case OTaskTypes.NO_COOL:
      return "No Cool";
    case OTaskTypes.WATER_LEAK:
      return "Water Leak";
    case OTaskTypes.OTHER:
      return "Other";
    case OTaskTypes.INSTALL_PART_SERVICE_LARGE:
      return "Install Part/Service - Large";
    case OTaskTypes.INSTALL_PART_SERVICE_NORMAL:
      return "Install Part/Service - Normal";
    case OTaskTypes.INSTALL_PART_SERVICE_MED:
      return "Install Part/Service - Medium";
    case OTaskTypes.MAINTENANCE_PLAN:
      return "Maintenance Plan";
    case OTaskTypes.CHECK_UP:
      return "Check Up";
    case OTaskTypes.MARINE:
      return "Marine";
    case OTaskTypes.FILTER_CHANGE_OUT:
      return "Filter Change Out";
    case OTaskTypes.RESIDENTIAL_NEW_CONSTRUCTION:
      return "Resi New Construction";
    case OTaskTypes.COMMERCIAL_NEW_CONSTRUCTION:
      return "Comm New Construction";
    case OTaskTypes.MEETING:
      return "Meeting";
    case OTaskTypes.SECOND_OPINION:
      return "2nd Opinion";
    case OTaskTypes.AQUA_PRO:
      return "Aqua Pro";
    case OTaskTypes.ORDER_FILTERS:
      return "Order Filters";
    case OTaskTypes.INSTALL_EVALUATION:
      return "Install Evaluation";
    case OTaskTypes.MEASURE_FOR_FILTERS:
      return "Measure For Filters";
    case OTaskTypes.GAS_N_GO:
      return "Gas n'Go";
    case OTaskTypes.PICK_UP_PARTS:
      return "Pick Up Parts";
    case OTaskTypes.RECALL:
      return "Recall";
    case OTaskTypes.SERVICE_AND_REPAIR:
      return "Service & Repair";
    case OTaskTypes.AC_NOT_COOLING:
      return "AC Not Cooling";
    case OTaskTypes.FOLLOW_UP_JOB_COMPLETION:
      return "Follow up Job Completion";
    case OTaskTypes.SPLIT_SYSTEM_INSTALL:
      return "Split System Install";
    case OTaskTypes.POST_INSTALL_INSPECTION:
      return "Post Install Inspection";
    case OTaskTypes.PMA_RI:
      return "PMA-RI";
    case OTaskTypes.DUCT_REPAIR_CLEANING:
      return "Duct Repair / Cleaning";
    case OTaskTypes.CLEAN_AND_CHECK:
      return "Clean and Check";
    case OTaskTypes.GENERAL:
      return "General";
    case OTaskTypes.PART_REPLACEMENT:
      return "Part Replacement";
    case OTaskTypes.AC_NOT_HEATING:
      return "AC Not Heating";
    case OTaskTypes.SERVICE_CALL_ERROR_CODE:
      return "Service Call Error code";
    case OTaskTypes.FAIL_INSPECTION_CORRECTION:
      return "Fail Inspection Correction";
    case OTaskTypes.WARRANTY_SERVICE_CALL:
      return "Warranty Service Call";
    case OTaskTypes.SYSTEM_NOT_WORKING:
      return "System not Working";
    case OTaskTypes.WEEKEND_SERVICE_CALL:
      return "Weekend Service Call";
    case OTaskTypes.AFTER_HOUR_SERVICE_CALL:
      return "After Hour Service Call";
    case OTaskTypes.MASTIC_SEAL:
      return "Mastic Seal";
    case OTaskTypes.WATER_LEAKING:
      return "Water Leaking";
    case OTaskTypes.MAINTENANCE_TUNE_UP:
      return "Maintenance Tune up";
    case OTaskTypes.DUCT_CHANGE_OUT:
      return "Duct Change Out";
    case OTaskTypes.FORM_PICK_UP_AND_DELIVERY:
      return "Form pick up and delivery";
    case OTaskTypes.PACKAGE_SYSTEM_INSTALL:
      return "Package System Install";
    case OTaskTypes.PART_DELIVERY:
      return "Part delivery";
    case OTaskTypes.BLOW_IN_INSULATION:
      return "Blow In Insulation";
    case OTaskTypes.BOLLARDS:
      return "Bollards";
    case OTaskTypes.CONCRETE:
      return "Concrete";
    case OTaskTypes.CONVEYOR:
      return "Conveyor";
    case OTaskTypes.DOORS:
      return "Doors";
    case OTaskTypes.ELECTRICAL:
      return "Electrical";
    case OTaskTypes.EPOXY:
      return "Epoxy";
    case OTaskTypes.FENCING:
      return "Fencing";
    case OTaskTypes.HANDYMAN:
      return "Handyman";
    case OTaskTypes.MASONRY:
      return "Masonry";
    case OTaskTypes.PARKING_LOT:
      return "Parking Lot";
    case OTaskTypes.ROOFING:
      return "Roofing";
    case OTaskTypes.SPECIALTY_PROJECT:
      return "Specialty Project";
    default:
      return "UNKNOWN";
  }
}

/**
 * Return the string used for the reference path for task specific details.
 * @param taskType
 */
export function getTaskSpecificDetailsString(
  taskType: number | unknown,
): string {
  switch (taskType) {
    case OTaskTypes.PHONE_CALL:
      return "phoneCall";
    case OTaskTypes.ESTIMATE:
      return "estimate";
    case OTaskTypes.INSTALLATION:
      return "installation";
    case OTaskTypes.MODIFICATION:
      return "modification";
    case OTaskTypes.REMOVAL:
      return "removal";
    case OTaskTypes.INSPECTION:
      return "inspection";
    case OTaskTypes.MATERIAL_PICKUP:
      return "materialPickup";
    case OTaskTypes.WATERBLASTING:
      return "waterblasting";
    case OTaskTypes.VACUUM_TRUCK:
      return "vacuumTruck";
    case OTaskTypes.SANDBLASTING:
      return "sandblasting";
    case OTaskTypes.INSULATION:
      return "insulation";
    case OTaskTypes.ABATEMENT:
      return "abatement";
    case OTaskTypes.PAINTING:
      return "painting";
    case OTaskTypes.REPAIR:
      return "repair";
    case OTaskTypes.CARPENTRY:
      return "carpentry";
    case OTaskTypes.LOCKOUT:
      return "lockout";
    case OTaskTypes.WALKTHROUGH:
      return "walkthrough";
    case OTaskTypes.PERFORMING_WORK:
      return "performingWork";
    case OTaskTypes.CLOTH_REPLACEMENT:
      return "clothReplacement";
    case OTaskTypes.GENERAL_LABOR:
      return "generalLabor";
    case OTaskTypes.AUTO_DETAILING:
      return "autoDetailing";
    case OTaskTypes.HOUSEKEEPING:
      return "housekeeping";
    case OTaskTypes.OPERATOR_ROUNDS:
      return "operatorRounds";
    case OTaskTypes.EQUIPMENT_INSPECTION:
      return "equipmentInspection";
    case OTaskTypes.SAFETY_INSPECTION:
      return "safetyInspection";
    case OTaskTypes.HOUSEKEEPING_INSPECTION:
      return "housekeepingInspection";
    case OTaskTypes.PM_ROUTE:
      return "pmRoute";
    case OTaskTypes.CLEANING:
      return "cleaning";
    case OTaskTypes.WARRANTY:
      return "warranty";
    case OTaskTypes.SERVICE_CALL:
      return "serviceCall";
    case OTaskTypes.EMERGENCY_CALL:
      return "emergencyCall";
    case OTaskTypes.CALL_BACK:
      return "callBack";
    case OTaskTypes.NO_HEAT:
      return "noHeat";
    case OTaskTypes.NO_AC:
      return "noAc";
    case OTaskTypes.LEAKY_PIPE_DRAIN:
      return "leakyPipeDrain";
    case OTaskTypes.CLOGGED_DRAIN:
      return "cloggedDrain";
    case OTaskTypes.GENERATOR_INSTALL:
      return "generatorInstall";
    case OTaskTypes.NO_HOT_WATER:
      return "noHotWater";
    case OTaskTypes.REFRIGERATION:
      return "refrigeration";
    case OTaskTypes.FURNACE_SERVICE:
      return "furnaceService";
    case OTaskTypes.PREVENTATIVE_MAINTENANCE:
      return "preventativeMaintenance";
    case OTaskTypes.HWT_INSTALL:
      return "hwtInstall";
    case OTaskTypes.WATER_SOFTENER_INSTALL:
      return "waterSoftenerInstall";
    case OTaskTypes.FROZEN_PIPES:
      return "frozenPipes";
    case OTaskTypes.HEARTH_SERVICE_WOOD_PELLET:
      return "hearthServiceWoodPellet";
    case OTaskTypes.WETT_INSPECTION:
      return "wettInspection";
    case OTaskTypes.BOILER_SERVICE:
      return "boilerService";
    case OTaskTypes.AIR_CONDITIONING_SERVICE:
      return "airConditioningService";
    case OTaskTypes.AIRDUCT_CLEANING:
      return "airductCleaning";
    case OTaskTypes.CARPET_CLEANING:
      return "carpetCleaning";
    case OTaskTypes.CARPET_STRETCHING:
      return "carpetStretching";
    case OTaskTypes.COMMERCIAL_CARPET_CLEANING:
      return "commercialCarpetCleaning";
    case OTaskTypes.DRYER_VENT_CLEANING:
      return "dryerVentCleaning";
    case OTaskTypes.TILE_AND_GROUT:
      return "tileAndGrout";
    case OTaskTypes.UPHOLSTERY_CLEANING:
      return "upholsteryCleaning";
    case OTaskTypes.WATER_MITIGATION:
      return "waterMitigation";
    case OTaskTypes.MOLD_MITIGATION:
      return "moldMitigation";
    case OTaskTypes.COMMERCIAL:
      return "commercial";
    case OTaskTypes.HARDWOOD:
      return "hardwood";
    case OTaskTypes.RESTORATION:
      return "restoration";
    case OTaskTypes.PRESSURE_WASHING:
      return "pressureWashing";
    case OTaskTypes.DUMPSTER_RENTAL:
      return "dumpsterRental";
    case OTaskTypes.RETOUCH:
      return "retouch";
    case OTaskTypes.PICKUP_DROPOFF:
      return "pickupDropoff";
    case OTaskTypes.IN_OFFICE:
      return "inOffice";
    case OTaskTypes.FURNACE_TUNEUP:
      return "furnaceTuneup";
    case OTaskTypes.SEASONAL_TUNEUP:
      return "seasonalTuneup";
    case OTaskTypes.RESIDENTIAL_SERVICE:
      return "residentialService";
    case OTaskTypes.COMMERCIAL_SERVICE:
      return "commercialService";
    case OTaskTypes.COMFORT_CLUB:
      return "comfortClub";
    case OTaskTypes.NO_COOL:
      return "noCool";
    case OTaskTypes.WATER_LEAK:
      return "waterLeak";
    case OTaskTypes.OTHER:
      return "other";
    case OTaskTypes.INSTALL_PART_SERVICE_LARGE:
      return "installPartServiceLarge";
    case OTaskTypes.INSTALL_PART_SERVICE_NORMAL:
      return "installPartServiceNormal";
    case OTaskTypes.INSTALL_PART_SERVICE_MED:
      return "installPartServiceMed";
    case OTaskTypes.MAINTENANCE_PLAN:
      return "maintenancePlan";
    case OTaskTypes.CHECK_UP:
      return "checkUp";
    case OTaskTypes.MARINE:
      return "marine";
    case OTaskTypes.FILTER_CHANGE_OUT:
      return "filterChangeOut";
    case OTaskTypes.RESIDENTIAL_NEW_CONSTRUCTION:
      return "residentialNewConstruction";
    case OTaskTypes.COMMERCIAL_NEW_CONSTRUCTION:
      return "commercialNewConstruction";
    case OTaskTypes.MEETING:
      return "meeting";
    case OTaskTypes.SECOND_OPINION:
      return "secondOpinion";
    case OTaskTypes.AQUA_PRO:
      return "aquaPro";
    case OTaskTypes.ORDER_FILTERS:
      return "orderFilters";
    case OTaskTypes.INSTALL_EVALUATION:
      return "installEvaluation";
    case OTaskTypes.MEASURE_FOR_FILTERS:
      return "measureForFilters";
    case OTaskTypes.GAS_N_GO:
      return "gasNGo";
    case OTaskTypes.PICK_UP_PARTS:
      return "pickUpParts";
    case OTaskTypes.RECALL:
      return "recall";
    case OTaskTypes.SERVICE_AND_REPAIR:
      return "serviceAndRepair";
    case OTaskTypes.AC_NOT_COOLING:
      return "acNotCooling";
    case OTaskTypes.FOLLOW_UP_JOB_COMPLETION:
      return "followUpJobCompletion";
    case OTaskTypes.SPLIT_SYSTEM_INSTALL:
      return "splitSystemInstall";
    case OTaskTypes.POST_INSTALL_INSPECTION:
      return "postInstallInspection";
    case OTaskTypes.PMA_RI:
      return "pmaRi";
    case OTaskTypes.DUCT_REPAIR_CLEANING:
      return "ductRepairCleaning";
    case OTaskTypes.CLEAN_AND_CHECK:
      return "cleanAndCheck";
    case OTaskTypes.GENERAL:
      return "general";
    case OTaskTypes.PART_REPLACEMENT:
      return "partReplacement";
    case OTaskTypes.AC_NOT_HEATING:
      return "acNotHeating";
    case OTaskTypes.SERVICE_CALL_ERROR_CODE:
      return "serviceCallErrorCode";
    case OTaskTypes.FAIL_INSPECTION_CORRECTION:
      return "failInspectionCorrection";
    case OTaskTypes.WARRANTY_SERVICE_CALL:
      return "warrantyServiceCall";
    case OTaskTypes.SYSTEM_NOT_WORKING:
      return "systemNotWorking";
    case OTaskTypes.WEEKEND_SERVICE_CALL:
      return "weekendServiceCall";
    case OTaskTypes.AFTER_HOUR_SERVICE_CALL:
      return "afterHourServiceCall";
    case OTaskTypes.MASTIC_SEAL:
      return "masticSeal";
    case OTaskTypes.WATER_LEAKING:
      return "waterLeaking";
    case OTaskTypes.MAINTENANCE_TUNE_UP:
      return "maintenanceTuneUp";
    case OTaskTypes.DUCT_CHANGE_OUT:
      return "ductChangeOut";
    case OTaskTypes.FORM_PICK_UP_AND_DELIVERY:
      return "formPickUpAndDelivery";
    case OTaskTypes.PACKAGE_SYSTEM_INSTALL:
      return "packageSystemInstall";
    case OTaskTypes.PART_DELIVERY:
      return "partDelivery";
    case OTaskTypes.BLOW_IN_INSULATION:
      return "blowInInsulation";
    case OTaskTypes.BOLLARDS:
      return "bollards";
    case OTaskTypes.CONCRETE:
      return "concrete";
    case OTaskTypes.CONVEYOR:
      return "conveyor";
    case OTaskTypes.DOORS:
      return "doors";
    case OTaskTypes.ELECTRICAL:
      return "electrical";
    case OTaskTypes.EPOXY:
      return "epoxy";
    case OTaskTypes.FENCING:
      return "fencing";
    case OTaskTypes.HANDYMAN:
      return "handyman";
    case OTaskTypes.MASONRY:
      return "masonry";
    case OTaskTypes.PARKING_LOT:
      return "parkingLot";
    case OTaskTypes.ROOFING:
      return "roofing";
    case OTaskTypes.SPECIALTY_PROJECT:
      return "specialtyProject";
    default:
      return "UNKNOWN";
  }
}

export function getTaskTypeFromString(
  taskSpecificDetailsString: string,
): TaskTypesValues | undefined {
  switch (taskSpecificDetailsString) {
    case "phoneCall":
      return OTaskTypes.PHONE_CALL;
    case "estimate":
      return OTaskTypes.ESTIMATE;
    case "installation":
      return OTaskTypes.INSTALLATION;
    case "modification":
      return OTaskTypes.MODIFICATION;
    case "removal":
      return OTaskTypes.REMOVAL;
    case "inspection":
      return OTaskTypes.INSPECTION;
    case "materialPickup":
      return OTaskTypes.MATERIAL_PICKUP;
    case "waterblasting":
      return OTaskTypes.WATERBLASTING;
    case "vacuumTruck":
      return OTaskTypes.VACUUM_TRUCK;
    case "sandblasting":
      return OTaskTypes.SANDBLASTING;
    case "insulation":
      return OTaskTypes.INSULATION;
    case "abatement":
      return OTaskTypes.ABATEMENT;
    case "painting":
      return OTaskTypes.PAINTING;
    case "repair":
      return OTaskTypes.REPAIR;
    case "carpentry":
      return OTaskTypes.CARPENTRY;
    case "lockout":
      return OTaskTypes.LOCKOUT;
    case "walkthrough":
      return OTaskTypes.WALKTHROUGH;
    case "performingWork":
      return OTaskTypes.PERFORMING_WORK;
    case "clothReplacement":
      return OTaskTypes.CLOTH_REPLACEMENT;
    case "generalLabor":
      return OTaskTypes.GENERAL_LABOR;
    case "autoDetailing":
      return OTaskTypes.AUTO_DETAILING;
    case "housekeeping":
      return OTaskTypes.HOUSEKEEPING;
    case "operatorRounds":
      return OTaskTypes.OPERATOR_ROUNDS;
    case "equipmentInspection":
      return OTaskTypes.EQUIPMENT_INSPECTION;
    case "safetyInspection":
      return OTaskTypes.SAFETY_INSPECTION;
    case "housekeepingInspection":
      return OTaskTypes.HOUSEKEEPING_INSPECTION;
    case "pmRoute":
      return OTaskTypes.PM_ROUTE;
    case "cleaning":
      return OTaskTypes.CLEANING;
    case "warranty":
      return OTaskTypes.WARRANTY;
    case "serviceCall":
      return OTaskTypes.SERVICE_CALL;
    case "emergencyCall":
      return OTaskTypes.EMERGENCY_CALL;
    case "callBack":
      return OTaskTypes.CALL_BACK;
    case "noHeat":
      return OTaskTypes.NO_HEAT;
    case "noAc":
      return OTaskTypes.NO_AC;
    case "leakyPipeDrain":
      return OTaskTypes.LEAKY_PIPE_DRAIN;
    case "cloggedDrain":
      return OTaskTypes.CLOGGED_DRAIN;
    case "generatorInstall":
      return OTaskTypes.GENERATOR_INSTALL;
    case "noHotWater":
      return OTaskTypes.NO_HOT_WATER;
    case "refrigeration":
      return OTaskTypes.REFRIGERATION;
    case "furnaceService":
      return OTaskTypes.FURNACE_SERVICE;
    case "preventativeMaintenance":
      return OTaskTypes.PREVENTATIVE_MAINTENANCE;
    case "hwtInstall":
      return OTaskTypes.HWT_INSTALL;
    case "waterSoftenerInstall":
      return OTaskTypes.WATER_SOFTENER_INSTALL;
    case "frozenPipes":
      return OTaskTypes.FROZEN_PIPES;
    case "hearthServiceWoodPellet":
      return OTaskTypes.HEARTH_SERVICE_WOOD_PELLET;
    case "wettInspection":
      return OTaskTypes.WETT_INSPECTION;
    case "boilerService":
      return OTaskTypes.BOILER_SERVICE;
    case "airConditioningService":
      return OTaskTypes.AIR_CONDITIONING_SERVICE;
    case "airductCleaning":
      return OTaskTypes.AIRDUCT_CLEANING;
    case "carpetCleaning":
      return OTaskTypes.CARPET_CLEANING;
    case "carpetStretching":
      return OTaskTypes.CARPET_STRETCHING;
    case "commercialCarpetCleaning":
      return OTaskTypes.COMMERCIAL_CARPET_CLEANING;
    case "dryerVentCleaning":
      return OTaskTypes.DRYER_VENT_CLEANING;
    case "tileAndGrout":
      return OTaskTypes.TILE_AND_GROUT;
    case "upholsteryCleaning":
      return OTaskTypes.UPHOLSTERY_CLEANING;
    case "waterMitigation":
      return OTaskTypes.WATER_MITIGATION;
    case "moldMitigation":
      return OTaskTypes.MOLD_MITIGATION;
    case "commercial":
      return OTaskTypes.COMMERCIAL;
    case "hardwood":
      return OTaskTypes.HARDWOOD;
    case "restoration":
      return OTaskTypes.RESTORATION;
    case "pressureWashing":
      return OTaskTypes.PRESSURE_WASHING;
    case "dumpsterRental":
      return OTaskTypes.DUMPSTER_RENTAL;
    case "retouch":
      return OTaskTypes.RETOUCH;
    case "pickupDropoff":
      return OTaskTypes.PICKUP_DROPOFF;
    case "inOffice":
      return OTaskTypes.IN_OFFICE;
    case "furnaceTuneup":
      return OTaskTypes.FURNACE_TUNEUP;
    case "seasonalTuneup":
      return OTaskTypes.SEASONAL_TUNEUP;
    case "residentialService":
      return OTaskTypes.RESIDENTIAL_SERVICE;
    case "commercialService":
      return OTaskTypes.COMMERCIAL_SERVICE;
    case "comfortClub":
      return OTaskTypes.COMFORT_CLUB;
    case "noCool":
      return OTaskTypes.NO_COOL;
    case "waterLeak":
      return OTaskTypes.WATER_LEAK;
    case "other":
      return OTaskTypes.OTHER;
    case "installPartServiceLarge":
      return OTaskTypes.INSTALL_PART_SERVICE_LARGE;
    case "installPartServiceNormal":
      return OTaskTypes.INSTALL_PART_SERVICE_NORMAL;
    case "installPartServiceMed":
      return OTaskTypes.INSTALL_PART_SERVICE_MED;
    case "maintenancePlan":
      return OTaskTypes.MAINTENANCE_PLAN;
    case "checkUp":
      return OTaskTypes.CHECK_UP;
    case "marine":
      return OTaskTypes.MARINE;
    case "filterChangeOut":
      return OTaskTypes.FILTER_CHANGE_OUT;
    case "residentialNewConstruction":
      return OTaskTypes.RESIDENTIAL_NEW_CONSTRUCTION;
    case "commercialNewConstruction":
      return OTaskTypes.COMMERCIAL_NEW_CONSTRUCTION;
    case "meeting":
      return OTaskTypes.MEETING;
    case "secondOpinion":
      return OTaskTypes.SECOND_OPINION;
    case "aquaPro":
      return OTaskTypes.AQUA_PRO;
    case "orderFilters":
      return OTaskTypes.ORDER_FILTERS;
    case "installEvaluation":
      return OTaskTypes.INSTALL_EVALUATION;
    case "measureForFilters":
      return OTaskTypes.MEASURE_FOR_FILTERS;
    case "gasNGo":
      return OTaskTypes.GAS_N_GO;
    case "pickUpParts":
      return OTaskTypes.PICK_UP_PARTS;
    case "recall":
      return OTaskTypes.RECALL;
    case "serviceAndRepair":
      return OTaskTypes.SERVICE_AND_REPAIR;
    case "acNotCooling":
      return OTaskTypes.AC_NOT_COOLING;
    case "followUpJobCompletion":
      return OTaskTypes.FOLLOW_UP_JOB_COMPLETION;
    case "splitSystemInstall":
      return OTaskTypes.SPLIT_SYSTEM_INSTALL;
    case "postInstallInspection":
      return OTaskTypes.POST_INSTALL_INSPECTION;
    case "pmaRi":
      return OTaskTypes.PMA_RI;
    case "ductRepairCleaning":
      return OTaskTypes.DUCT_REPAIR_CLEANING;
    case "cleanAndCheck":
      return OTaskTypes.CLEAN_AND_CHECK;
    case "general":
      return OTaskTypes.GENERAL;
    case "partReplacement":
      return OTaskTypes.PART_REPLACEMENT;
    case "acNotHeating":
      return OTaskTypes.AC_NOT_HEATING;
    case "serviceCallErrorCode":
      return OTaskTypes.SERVICE_CALL_ERROR_CODE;
    case "failInspectionCorrection":
      return OTaskTypes.FAIL_INSPECTION_CORRECTION;
    case "warrantyServiceCall":
      return OTaskTypes.WARRANTY_SERVICE_CALL;
    case "systemNotWorking":
      return OTaskTypes.SYSTEM_NOT_WORKING;
    case "weekendServiceCall":
      return OTaskTypes.WEEKEND_SERVICE_CALL;
    case "afterHourServiceCall":
      return OTaskTypes.AFTER_HOUR_SERVICE_CALL;
    case "masticSeal":
      return OTaskTypes.MASTIC_SEAL;
    case "waterLeaking":
      return OTaskTypes.WATER_LEAKING;
    case "maintenanceTuneUp":
      return OTaskTypes.MAINTENANCE_TUNE_UP;
    case "ductChangeOut":
      return OTaskTypes.DUCT_CHANGE_OUT;
    case "formPickUpAndDelivery":
      return OTaskTypes.FORM_PICK_UP_AND_DELIVERY;
    case "packageSystemInstall":
      return OTaskTypes.PACKAGE_SYSTEM_INSTALL;
    case "partDelivery":
      return OTaskTypes.PART_DELIVERY;
    case "blowInInsulation":
      return OTaskTypes.BLOW_IN_INSULATION;
    case "bollards":
      return OTaskTypes.BOLLARDS;
    case "concrete":
      return OTaskTypes.CONCRETE;
    case "conveyor":
      return OTaskTypes.CONVEYOR;
    case "doors":
      return OTaskTypes.DOORS;
    case "electrical":
      return OTaskTypes.ELECTRICAL;
    case "epoxy":
      return OTaskTypes.EPOXY;
    case "fencing":
      return OTaskTypes.FENCING;
    case "handyman":
      return OTaskTypes.HANDYMAN;
    case "masonry":
      return OTaskTypes.MASONRY;
    case "parkingLot":
      return OTaskTypes.PARKING_LOT;
    case "roofing":
      return OTaskTypes.ROOFING;
    case "specialtyProject":
      return OTaskTypes.SPECIALTY_PROJECT;
    default:
      return undefined;
  }
}

export function getTaskTypeValueFromReadableStr(
  humanReadableString: string,
): TaskTypesValues | undefined {
  switch (humanReadableString) {
    case "Phone Call":
      return OTaskTypes.PHONE_CALL;
    case "Estimate":
      return OTaskTypes.ESTIMATE;
    case "Installation":
      return OTaskTypes.INSTALLATION;
    case "Modification":
      return OTaskTypes.MODIFICATION;
    case "Removal":
      return OTaskTypes.REMOVAL;
    case "Inspection":
      return OTaskTypes.INSPECTION;
    case "Material Pickup":
      return OTaskTypes.MATERIAL_PICKUP;
    case "Waterblasting":
      return OTaskTypes.WATERBLASTING;
    case "Vacuum Truck":
      return OTaskTypes.VACUUM_TRUCK;
    case "Sandblasting":
      return OTaskTypes.SANDBLASTING;
    case "Insulation":
      return OTaskTypes.INSULATION;
    case "Abatement":
      return OTaskTypes.ABATEMENT;
    case "Painting":
      return OTaskTypes.PAINTING;
    case "Repair":
      return OTaskTypes.REPAIR;
    case "Carpentry":
      return OTaskTypes.CARPENTRY;
    case "Lockout":
      return OTaskTypes.LOCKOUT;
    case "Walkthrough":
      return OTaskTypes.WALKTHROUGH;
    case "Performing Work":
      return OTaskTypes.PERFORMING_WORK;
    case "Cloth Replacement":
      return OTaskTypes.CLOTH_REPLACEMENT;
    case "General Labor":
      return OTaskTypes.GENERAL_LABOR;
    case "Auto Detailing":
      return OTaskTypes.AUTO_DETAILING;
    case "Housekeeping":
      return OTaskTypes.HOUSEKEEPING;
    case "Operator Rounds":
      return OTaskTypes.OPERATOR_ROUNDS;
    case "Equipment Inspection":
      return OTaskTypes.EQUIPMENT_INSPECTION;
    case "Safety Inspection":
      return OTaskTypes.SAFETY_INSPECTION;
    case "Housekeeping Inspection":
      return OTaskTypes.HOUSEKEEPING_INSPECTION;
    case "PM Route":
      return OTaskTypes.PM_ROUTE;
    case "Cleaning":
      return OTaskTypes.CLEANING;
    case "Warranty":
      return OTaskTypes.WARRANTY;
    case "Service Call":
      return OTaskTypes.SERVICE_CALL;
    case "Emergency Call":
      return OTaskTypes.EMERGENCY_CALL;
    case "Call Back":
      return OTaskTypes.CALL_BACK;
    case "No Heat":
      return OTaskTypes.NO_HEAT;
    case "No AC":
      return OTaskTypes.NO_AC;
    case "Leak Pipe/Drain":
      return OTaskTypes.LEAKY_PIPE_DRAIN;
    case "Clogged Drain":
      return OTaskTypes.CLOGGED_DRAIN;
    case "Generator Install":
      return OTaskTypes.GENERATOR_INSTALL;
    case "No Hot Water":
      return OTaskTypes.NO_HOT_WATER;
    case "Refrigeration":
      return OTaskTypes.REFRIGERATION;
    case "Furnace Service":
      return OTaskTypes.FURNACE_SERVICE;
    case "Preventative Maintenance":
      return OTaskTypes.PREVENTATIVE_MAINTENANCE;
    case "HWT Install":
      return OTaskTypes.HWT_INSTALL;
    case "Water Softener Install":
      return OTaskTypes.WATER_SOFTENER_INSTALL;
    case "Frozen Pipes":
      return OTaskTypes.FROZEN_PIPES;
    case "Hearth Service-Wood/Pellet":
      return OTaskTypes.HEARTH_SERVICE_WOOD_PELLET;
    case "WETT Inspection":
      return OTaskTypes.WETT_INSPECTION;
    case "Boiler Service":
      return OTaskTypes.BOILER_SERVICE;
    case "Air Conditioning Service":
      return OTaskTypes.AIR_CONDITIONING_SERVICE;
    case "Airduct Cleaning":
      return OTaskTypes.AIRDUCT_CLEANING;
    case "Carpet Cleaning":
      return OTaskTypes.CARPET_CLEANING;
    case "Carpet Stretching":
      return OTaskTypes.CARPET_STRETCHING;
    case "Commercial Carpet Cleaning":
      return OTaskTypes.COMMERCIAL_CARPET_CLEANING;
    case "Dryer Vent Cleaning":
      return OTaskTypes.DRYER_VENT_CLEANING;
    case "Tile & Grout":
      return OTaskTypes.TILE_AND_GROUT;
    case "Upholstery Cleaning":
      return OTaskTypes.UPHOLSTERY_CLEANING;
    case "Water Mitigation":
      return OTaskTypes.WATER_MITIGATION;
    case "Mold Mitigation":
      return OTaskTypes.MOLD_MITIGATION;
    case "Commercial":
      return OTaskTypes.COMMERCIAL;
    case "Hardwood":
      return OTaskTypes.HARDWOOD;
    case "Restoration":
      return OTaskTypes.RESTORATION;
    case "Pressure Washing":
      return OTaskTypes.PRESSURE_WASHING;
    case "Dumpster Rental":
      return OTaskTypes.DUMPSTER_RENTAL;
    case "Re-Touch":
      return OTaskTypes.RETOUCH;
    case "Pickup/Dropoff":
      return OTaskTypes.PICKUP_DROPOFF;
    case "IN OFFICE":
      return OTaskTypes.IN_OFFICE;
    case "Furnace Tune-up":
      return OTaskTypes.FURNACE_TUNEUP;
    case "Seasonal Tune-up":
      return OTaskTypes.SEASONAL_TUNEUP;
    case "Residential Service":
      return OTaskTypes.RESIDENTIAL_SERVICE;
    case "Commercial Service":
      return OTaskTypes.COMMERCIAL_SERVICE;
    case "Comfort Club":
      return OTaskTypes.COMFORT_CLUB;
    case "No Cool":
      return OTaskTypes.NO_COOL;
    case "Water Leak":
      return OTaskTypes.WATER_LEAK;
    case "Other":
      return OTaskTypes.OTHER;
    case "Install Part/Service - Large":
      return OTaskTypes.INSTALL_PART_SERVICE_LARGE;
    case "Install Part/Service - Normal":
      return OTaskTypes.INSTALL_PART_SERVICE_NORMAL;
    case "Install Part/Service - Medium":
      return OTaskTypes.INSTALL_PART_SERVICE_MED;
    case "Maintenance Plan":
      return OTaskTypes.MAINTENANCE_PLAN;
    case "Check Up":
      return OTaskTypes.CHECK_UP;
    case "Marine":
      return OTaskTypes.MARINE;
    case "Filter Change Out":
      return OTaskTypes.FILTER_CHANGE_OUT;
    case "Resi New Construction":
      return OTaskTypes.RESIDENTIAL_NEW_CONSTRUCTION;
    case "Comm New Construction":
      return OTaskTypes.COMMERCIAL_NEW_CONSTRUCTION;
    case "Meeting":
      return OTaskTypes.MEETING;
    case "2nd Opinion":
      return OTaskTypes.SECOND_OPINION;
    case "Aqua Pro":
      return OTaskTypes.AQUA_PRO;
    case "Order Filters":
      return OTaskTypes.ORDER_FILTERS;
    case "Install Evaluation":
      return OTaskTypes.INSTALL_EVALUATION;
    case "Measure For Filters":
      return OTaskTypes.MEASURE_FOR_FILTERS;
    case "Gas n'Go":
      return OTaskTypes.GAS_N_GO;
    case "Pick Up Parts":
      return OTaskTypes.PICK_UP_PARTS;
    case "Recall":
      return OTaskTypes.RECALL;
    case "Service & Repair":
      return OTaskTypes.SERVICE_AND_REPAIR;
    case "AC Not Cooling":
      return OTaskTypes.AC_NOT_COOLING;
    case "Follow up Job Completion":
      return OTaskTypes.FOLLOW_UP_JOB_COMPLETION;
    case "Split System Install":
      return OTaskTypes.SPLIT_SYSTEM_INSTALL;
    case "Post Install Inspection":
      return OTaskTypes.POST_INSTALL_INSPECTION;
    case "PMA-RI":
      return OTaskTypes.PMA_RI;
    case "Duct Repair / Cleaning":
      return OTaskTypes.DUCT_REPAIR_CLEANING;
    case "Clean and Check":
      return OTaskTypes.CLEAN_AND_CHECK;
    case "General":
      return OTaskTypes.GENERAL;
    case "Part Replacement":
      return OTaskTypes.PART_REPLACEMENT;
    case "AC Not Heating":
      return OTaskTypes.AC_NOT_HEATING;
    case "Service Call Error code":
      return OTaskTypes.SERVICE_CALL_ERROR_CODE;
    case "Fail Inspection Correction":
      return OTaskTypes.FAIL_INSPECTION_CORRECTION;
    case "Warranty Service Call":
      return OTaskTypes.WARRANTY_SERVICE_CALL;
    case "System not Working":
      return OTaskTypes.SYSTEM_NOT_WORKING;
    case "Weekend Service Call":
      return OTaskTypes.WEEKEND_SERVICE_CALL;
    case "After Hour Service Call":
      return OTaskTypes.AFTER_HOUR_SERVICE_CALL;
    case "Mastic Seal":
      return OTaskTypes.MASTIC_SEAL;
    case "Water Leaking":
      return OTaskTypes.WATER_LEAKING;
    case "Maintenance Tune up":
      return OTaskTypes.MAINTENANCE_TUNE_UP;
    case "Duct Change Out":
      return OTaskTypes.DUCT_CHANGE_OUT;
    case "Form pick up and delivery":
      return OTaskTypes.FORM_PICK_UP_AND_DELIVERY;
    case "Package System Install":
      return OTaskTypes.PACKAGE_SYSTEM_INSTALL;
    case "Part delivery":
      return OTaskTypes.PART_DELIVERY;
    case "Blow In Insulation":
      return OTaskTypes.BLOW_IN_INSULATION;
    case "Bollards":
      return OTaskTypes.BOLLARDS;
    case "Concrete":
      return OTaskTypes.CONCRETE;
    case "Conveyor":
      return OTaskTypes.CONVEYOR;
    case "Doors":
      return OTaskTypes.DOORS;
    case "Electrical":
      return OTaskTypes.ELECTRICAL;
    case "Epoxy":
      return OTaskTypes.EPOXY;
    case "Fencing":
      return OTaskTypes.FENCING;
    case "Handyman":
      return OTaskTypes.HANDYMAN;
    case "Masonry":
      return OTaskTypes.MASONRY;
    case "Parking Lot":
      return OTaskTypes.PARKING_LOT;
    case "Roofing":
      return OTaskTypes.ROOFING;
    case "Specialty Project":
      return OTaskTypes.SPECIALTY_PROJECT;
    default:
      return undefined;
  }
}

export function isDefinedTaskTypes(
  val: TaskTypesValues | undefined,
): val is TaskTypesValues {
  return val !== undefined;
}

/**
 * Get the defaultValues for taskTypes.
 *
 * @param originallyCheckedTaskTypes The values that were checked before entering
 * the dialog.
 * @param siteKeyTaskTypes The task types belonging to the siteKey.
 * @returns A list of TaskTypeValues/undefined. Undefined if value should not be
 * checked upon entering the dialog.
 */
export function getSortedTaskTypesList(
  originallyCheckedTaskTypes: TaskTypesValues[],
  siteKeyTaskTypes: number[],
): TaskTypesValues[] {
  const taskTypeEnums = siteKeyTaskTypes.filter(isValidTaskType);

  const sortedList = alphabetizeTaskTypes(taskTypeEnums);

  const checkedTT: TaskTypesValues[] = [];
  sortedList.forEach((code) => {
    if (originallyCheckedTaskTypes.includes(code)) {
      checkedTT.push(code);
    }
  });
  return checkedTT;
}

export function alphabetizeTaskTypes(
  taskTypes: TaskTypesValues[],
): TaskTypesValues[] {
  return taskTypes.sort((a, b) => {
    const aName = getReadableTaskType(a);
    const bName = getReadableTaskType(b);
    return aName.localeCompare(bName);
  });
}
