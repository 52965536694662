import { parsePhoneNumberWithError } from "libphonenumber-js";
import type { PhoneNumber } from "libphonenumber-js";

interface StiltPhoneUtils {
  parse: (phoneNumber: string) => {
    PhoneNumber: PhoneNumber;
    warning: boolean;
  };
  display: (phoneNumber: string) => string;
}

export const phoneUtils: StiltPhoneUtils = {
  /**
   * Throws an error if the phone number is unable to be parsed.
   */
  parse: (phoneNumber: string) => {
    const parsed = parsePhoneNumberWithError(phoneNumber, {
      defaultCountry: "US",
    });

    // We do not want to deal with SMS short codes or emergency numbers such as 911.
    let warning: boolean = false;
    if (parsed.number.length < 10) {
      warning = true;
    }
    return { PhoneNumber: parsed, warning };
  },
  /**
   * Formats a phone number for display.
   * @param phoneNumber
   */
  display: (phoneNumber: string) => {
    // Remove the '+' sign and any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Make sure we have 11 digits for the phone number
    if (cleaned.length !== 11) {
      return cleaned;
    }

    // Capture area code, prefix, and line number
    const countryCode = cleaned.substring(0, 1); // Country code (ignoring for final format)
    const areaCode = cleaned.substring(1, 4); // Area code
    const prefix = cleaned.substring(4, 7); // Prefix
    const lineNumber = cleaned.substring(7, 11); // Line number

    // Format the phone number
    return `(${areaCode}) ${prefix}-${lineNumber}`;
  },
};
