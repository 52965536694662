export const CUSTOM_FIELD_DELETE_DISABLED = "This field cannot be removed";
export const UNEXPECTED_ERROR = "Unexpected error. Please contact support.";
export const VALIDATION_FAILED = "Validation failed.";
export const ERROR_USER_NOT_FOUND = "User not found.";
export const EMAIL_ALREADY_IN_USE =
  "The email address is already in use by another account.";
export const REQUIRED_FIELD = "This field is required";
export const REQUIRED = "Required";
export const passwordFieldLengthRequired = (minLength: number): string => {
  return `Password must be a minimum of ${minLength} characters long`;
};
export const VERIFICATION_EMAIL_SENT =
  "A verification link has been sent to your email";
export const VERIFICATION_EMAIL_SENT_MUST_BE_CONFIRMED =
  "A verification link has been sent to your email, which must be confirmed before you can proceed";
export const EMAIL_NOT_VERIFIED = "Email has not been verified.";
export const EMAIL_VERIFIED = "Email has been verified.";
export const ACCOUNT_CREATION_SUCCESS = "Account created successfully";
export const DUPLICATE_ESTIMATE_SUCCESS =
  "Success! You are now viewing the duplicated estimate";
export const ADMIN_NOTIFIED_OF_APPLICATION_DESCRIPTION =
  "Your site administrator will be notified. When approved you can login at the main site";
export const SITE_KEY_DOES_NOT_EXIST_ERROR =
  "The site key provided does not exist. Please try again or check with your site administrator.";

export const ENTER_SITE_KEY_FROM_ADMIN =
  "Enter the site key provided by your site administrator";

export const UNSAVED_CHANGES = "You have unsaved changes.";
export const APPROVE_NEW_USER_MUST_SELECT_COMPANY =
  "When approving a new user, you must also select a company.";
export const ADD_NEW_USER_SUCCESS =
  "Success. User will receive an email with login instructions.";
export const ADD_USER_SUCCESS = "Success. User has been added to this site.";
export const PROBLEM_ADDING_USER = "Problem adding user.";

/** NEW USER PAGE */
export const NEW_USER_PAGE_HEADING = "New User Options";
export const NEW_USER_PAGE_CHOOSE_AN_OPTION = "Choose an option:";
export const NEW_USER_PAGE_OPTION_1 = "Create a new site as part of your trial";
export const NEW_USER_PAGE_OPTION_2 =
  "Apply to an existing site with a site key from your administrator";

/** CREATE SITE - Progress screen */
export const CREATE_SITE_PROGRESS_HEADING = "We are building your site 🚀";
export const PROGRESS_CREATING_SITE = "Creating site...";
export const progressCreatingLocation = (numLocations: number): string => {
  return numLocations === 1
    ? "Creating location..."
    : `Creating ${numLocations} locations...`;
};
export const progressCreatingCompany = (numCompanies: number): string => {
  return numCompanies === 1
    ? "Creating company..."
    : `Creating ${numCompanies} companies...`;
};
export const PROGRESS_CREATING_WORK_AND_TASK_TYPES =
  "Creating work and task types...";
export const PROGRESS_CREATING_CUSTOM_FIELDS = "Creating custom fields...";
export const PROGRESS_CREATING_KPIS = "Creating KPIs...";
export const ERROR_FETCHING_DATA =
  "Error fetching data. Please contact support.";
export const ERROR_RECALCULATING_DATA =
  "Error recalculating data. Please contact support.";
export const KPI_NOT_SELECTED = "KPI Type Not Selected";

export const PROGRESS_CREATING_ADMIN_ACCOUNT =
  "Creating administrator account...";
export const createSiteFailure = (statusCode: number): string => {
  const codesMap = {
    400: "Unknown error occurred: we've been notified. If it persists, please contact support.",
    401: "Please verify your email first.",
    404: "Error: root user not found.",
    503: "Error: database write failure.",
  };
  // TODO: improve
  if (
    statusCode === 400 ||
    statusCode === 401 ||
    statusCode === 404 ||
    statusCode === 503
  ) {
    return codesMap[statusCode];
  } else {
    return UNEXPECTED_ERROR;
  }
};
export const CREATE_SITE_SUCCESS_PARTIAL_STRING =
  "New site created. Your site key is: ";

/** CREATE SITE - Review screen */
export const CREATE_SITE_REVIEW_DROPDOWN = "Select Template";
export const CREATE_SITE_TEMPLATE_DIALOG = "Add Template Details";
export const CREATE_SITE_REVIEW_HEADING = "Review";
export const CREATE_SITE_REVIEW_DESCRIPTION =
  "This is a review of the categories that will be added to the new site. Once the site has been created, you will be able to add and/or edit the locations, companies, custom fields, administrator information, and the site itself.";
// "This is a review of the categories..." versus "Review the categories..." ???
// Unsure of language to use. (True of entire paragraph 😅)

export const applicationSentSuccess = (siteKey: string): string => {
  return `You successfully sent your application for ${siteKey}`;
};
export const applicationSentError = (siteKey: string): string => {
  return `Error on application to ${siteKey}`;
};

export const APPLY_TO_EXISTING_SITE = "Apply to an existing site";

/* Unsure if grouping them by intended use is a good idea */
export const buttons = {
  BUSY_SAVING: "Saving...",
  BUSY_APPLYING: "Applying...",
  BUSY_CREATING: "Creating...",
  BUSY_DUPLICATING: "Duplicating...",
  BUSY_UPLOADING: "Uploading...",
  BUSY_DOWNLOADING: "Downloading...",
  BUSY_LOADING: "Loading...",
  BUSY_SUBMITTING: "Submitting...",
  BUSY_GENERATING: "Generating...",
  BUSY_SENDING: "Sending...",
  BUSY_PAYING: "Paying...",
  BUSY_APPROVING: "Approving...",
  BUSY_REJECTING: "Rejecting...",
  BUSY_BEGINNING: "Beginning...",
  BUSY_DELETING: "Deleting...",
  BUSY_UPDATING: "Updating...",
  BUSY_REMOVING: "Removing...",
  BUSY_OPTIMIZING: "Optimizing...",
  BUSY_CLOSING: "Closing...",
  BUSY_RESTORING: "Restoring...",
  BUSY_MERGING: "Merging...",
  CONTINUE_TO_DASHBOARD: "Continue to Dashboard",
  REFRESH_DATA: "Refresh Data",
  BUSY_REFRESHING: "Refreshing...",
  APPLY: "Apply",
  SUCCESS: "Success",
  FAIL: "Fail",
  BACK: "Back",
  NEXT: "Next",
  CLOSE: "Close",
  DELETE: "Delete",
  DOWNLOAD: "Download",
  CANCEL: "Cancel",
  CLEAR: "Clear",
  EDIT: "Edit",
  ADD: "Add",
  SAVE: "Save",
  SAVE_CHANGES: "Save Changes",
  SAVED: "Saved",
  SUBMIT: "Submit",
  RECALCULATE: "Re-Calculate",
  SUBMIT_FOR_APPROVAL: "Submit for Approval",
  COMPLETE: "Complete",
  RESUME: "Resume",
  START: "Start",
  PAY: "Pay",
  YES: "Yes",
  NO: "No",
  SEND_AS_EMAIL: "Send as Email",
  CHECK_ALL: "Check All",
  UNCHECK_ALL: "Uncheck All",
  SELECT_ALL: "Select All",
  UNSELECT_ALL: "Unselect All",
  LOGIN: "Login",
  ALREADY_HAVE_ACCOUNT: "Already have an account?",
  ADD_DEFAULT_VALUE: "Add Default Value",
  ADD_SELECTION_OPTION: "Add Selection Option",
  CREATE_ACCOUNT: "Create Account",
  ADD_SITE: "Add Site",
  SAVE_TEMPLATE: "Save as template",
  CREATE_NEW_TEMPLATE: "Create New Template",
  CREATE_NEW_SITE: "Create a new site",
  APPLY_TO_EXISTING_SITE: APPLY_TO_EXISTING_SITE,
  GENERATE_CHECKLIST_TASK: "Generate Checklist Task",
  EDIT_TEMPLATE_DETAILS: "Edit Template Details",
  SCHEDULE_CHECKLIST_TASK: "Schedule Checklist Task",
  VIEW_TASKS_FOR_TEMPLATE: "View Tasks for this Template",
  SAVE_AND_CLOSE: "Save and Close",
  SAVE_AND_ADD_ANOTHER: "Save and Add Another",
  VIEW_TEMPLATE: "View Template",
  RESET_PASSWORD: "Send Password Reset Email",
  EMAIL_SENT: "Email Sent",
  SEND_DOWNLOAD_LINKS: "Send Download Links Email",
  APPROVE: "Approve",
  REJECT: "Reject",
  VIEW_COMPANIES: "View companies",
  HIDE_COMPANIES: "Hide companies",
  CREATE_TASK: "Create task",
  UPDATE: "Update",
  SEND_ESTIMATE_TO_CUSTOMER: "Send Estimate To Customer",
  APPROVE_ESTIMATE: "Approve Estimate",
  LOCK_ESTIMATE: "Lock Estimate",
  ESTIMATE_ON_HOLD: "Estimate On Hold",
  REJECT_ESTIMATE: "Reject Estimate",
  REVERT_TO_DRAFT: "Revert to Draft",
  REMOVE_ALL_ITEMS: "Remove all Items",
  SELECT_PRICE_BOOK_ITEM: "Select from Price Book",
  EDIT_ESTIMATE_ITEM: "Edit Estimate Item",
  SAVE_ESTIMATE: "Save Estimate",
  CREATE_NEW_ESTIMATE: "New Estimate",
  DELETE_ESTIMATE: "Delete Estimate",
  DELETE_CUSTOMER: "Delete Customer",
  DELETE_EQUIPMENT: "Delete Equipment",
  MERGE_CUSTOMER: "Merge Customer",
  CUSTOMER_STATEMENT: "Customer Statement",
  CUSTOMER_SCHEDULE: "Customer Schedule",
  CUSTOMER_SCHEDULES: "Customer Schedules",
  ADD_NEW_ADDRESS: "Add New Address",
  ADD_NEW_LOCATION: "Add New Location",
  VIEW_TASK: "View Task",
  OPTIMIZE: "Optimize",
  ADD_NEW_EVENT: "Add New Event",
  ASSIGN: "Assign",
  SHOW_SCHEDULED_TASKS_CALENDAR: "Show Scheduled Tasks Calendar",
  HIDE_SCHEDULED_TASKS_CALENDAR: "Hide Scheduled Task Calendar",
  CALCULATE_SCHEDULED_TASKS: "Calculate Scheduled Tasks",
  KEEP_OPEN: "Keep Open",
  RESET: "RESET",
  VIEW_PREVIOUS_JOBS: "View Previous Jobs",
  CONFIRM: "Confirm",
  ESTIMATE_SENT: "Estimate Sent",
  OPEN_JOB: "Open Job",
  REFUND: "Refund",
  UPDATE_PAYMENT_TERMS: "Update Payment Terms",
  EDIT_INVOICE: "Edit Invoice",
  REMOVE_DUE_DATE: "Remove Due Date",
  REMOVE_ISSUE_DATE: "Remove Issue Date",
  CONFIRM_SUBMITTED: "Generate PDF and change to Submitted",
  KEEP_PENDING: "Generate PDF",
  CHANGE_TS_TO_AWAITING_PARTS: "Change This Task Status To Awaiting Parts",
  CHANGE_TS_TO_PARTS_ORDERED: "Change This Task Status To Parts Ordered",
  CREATE_FOLLOW_UP_TASK: "Complete This Task and Create Follow-Up Task",
  CHARGE_CARD: "Charge Card",
  BUSY_CHARGING_CARD: "Charging Card...",
  SEND_NOW: "Send Now",
} as const;
export const SEARCH = "Search";

export const LIGHTSPEED_SYNC_SUCCESS = "Lightspeed sync successful";
export const LIGHTSPEED_SYNC_ERR =
  "Unable to pull Lightspeed items. Please try again.";
export const SUCCESS_REFRESH_CODAT = `Refreshed data successfully. You may need to wait a few minutes before using the "Retry Sync" button.`;

/** Uploading a file (generally, a photo) */
export const UPLOAD_NO_FILE_CHOSEN = "No file chosen.";
export const UPLOAD_UNDER_10MB = "Upload a file under 10MB.";
export const UPLOAD_ACCEPTED_FILETYPES = "Accepted file types: PNG, JPEG, JPG.";
export const UPLOAD_ADD_PHOTOS = "Add photo";
export const UPLOAD_TAP_TO_ADD_PHOTO = "Tap to add a photo";
export const UPLOAD_ERROR_USER_NOT_PERMITTED_TO_UPLOAD_PHOTO =
  "You do not have permission to upload an image.";
export const UPLOAD_ERROR_QUOTA_EXCEEDED = "Quota exceeded. Unable to upload.";
export const GENERIC_ERROR_MESSAGE =
  "Unknown error occurred. Please try again.";
export const UPLOAD_ERROR_UNABLE = "Unable to upload. Please try again.";
export const UPLOAD_SUCCESS_SERVER_PROCESSING =
  "Success. The server is processing your upload. It will appear shortly.";

export const ERROR_EDITING_COMPANY = "Error while editing company.";
export const ERROR_ADDING_COMPANY = "Error while adding company.";
export const MISSING_ADDRESS_SELECT_FROM_DROPDOWN =
  "Missing required field. Choose an address from the dropdown list";
export const successfulUpdate = (updatedThing: string) => {
  return `${updatedThing} has been updated`;
};
export const successfulAdd = (addedThing: string) => {
  return `Added ${addedThing}`;
};
export const successfulDuplicate = (duplicatedThing: string) => {
  return `Duplicated ${duplicatedThing}`;
};
export const successfulDownload = (downloadedThing: string) => {
  return `Downloaded ${downloadedThing}`;
};
export const successfulDelete = (deletedThing: string) => {
  return `${deletedThing} has been deleted`;
};
export const successfulRestore = (restoredThing: string) => {
  return `${restoredThing} has been restored`;
};
export const successfulMerge = (thingToMerge: string, thingToKeep: string) => {
  return `${thingToMerge} has been merged into ${thingToKeep} `;
};
export const failedAdd = (addedThing: string) => {
  return `Failed to add ${addedThing}`;
};
export const failedDelete = (deletedThing: string) => {
  return `Failed to delete ${deletedThing}`;
};
export const failedUpload = (uploadedThing: string) => {
  return `Failed to upload ${uploadedThing}`;
};
export const failedDownload = (downloadedThing: string) => {
  return `Failed to download ${downloadedThing}`;
};
export const failedUpdate = (updatedThing: string) => {
  return `Failed to update ${updatedThing}`;
};

export const SAVED_CHANGES = "Saved changes.";
export const errorSavingComment = (responseID: string) => {
  return `Error while saving comment for: ${responseID}`;
};
export const errorSavingResponseValue = (responseID: string) => {
  return `Error while saving response value for: ${responseID}`;
};

export const errorTemplateNotFoundForTask = (taskID: string) => {
  return `Template not found for: ${taskID}`;
};

export const CHECKLIST_TASKS = "Checklist Tasks";
export const TEMPLATES = "Templates";
export const ADMIN = "Admin";
export const LOCATIONS = "Locations";
export const SITE_LOCATIONS = "Site Locations";
export const COMPANIES = "Companies";
export const USER_MANAGEMENT = "User Management";
export const USER_PERMISSIONS = "User Permissions";
export const SITE_APPROVAL = "Site Approval";
export const TASKS = "Tasks";
export const TASKS_TO_DUPLICATE = "Tasks to duplicate";
export const CRAFT_RECORDS = "Craft Records";
export const GENERAL = "General";
export const MANAGEMENT_NOTIFICATIONS = "Management Notifications";
export const ADDITIONAL_ITEMS = "Additional Items";

/* COMPLIANCE */
export const COMPLIANCE = "Compliance";
export const COMPLIANCE_DASHBOARD = "Compliance Dashboard";
export const COMPLIANCE_REQUIREMENTS = "Requirements";
export const ADD_COMPLIANCE_REQUIREMENT = "Add requirement";
export const NEW_REQUIREMENT = "New Requirement";
export const RESPONSE_STATUS_APPROVED = "Approved";
export const RESPONSE_STATUS_REJECTED = "Rejected";
export const AWAITING_APPROVAL_STATUS = "Needs review";
export const NO_STATUS = "Needs response";
export const COMPANY_STATUS_APPROVED = "In compliance";
export const COMPANY_STATUS_REJECTED = "Not in compliance";
export const VIEW_PDF = "View PDF";
export const VIEW_FULL_SIZE = "View Full Size";

export const REJECT_COMMENT =
  "Rejected - click the above button to submit another response";
export const REQUIREMENT_DETAILS = "Requirement Details";

export const RESPONSE = "Response";
export const PASSING_RESPONSE_STATUS = "Passing";
export const NOT_PASSING_RESPONSE_STATUS = "Not passing";
export const DELETE_REQUIREMENT = "Delete requirement";
export const DELETE_RESPONSE = "Delete response";
export const ADD_ATTACHMENT = "Add an attachment";
export const TAP_TO_ADD_ATTACHMENT = "Tap to add an attachment";
export const ACCEPTED_COMPLIANCE_FILETYPES =
  "Accepted file types: PDF, PNG, JPEG.";
export const UPLOAD_UNDER_50MB = "Upload a file under 50MB.";
export const NO_ATTACHMENT = "No attachment";
export const SERVER_PROCESSING_ATTACHMENT =
  "The server is processing your attachment. It will appear shortly.";

export const ERR_REVIEW_COMPLIANCE_RESPONSE =
  "Reviewing compliance response was unsuccessful.";
export const ERR_SUBMIT_COMPLIANCE_RESPONSE =
  "Submitting compliance response was unsuccessful.";
export const ERR_DELETE_COMPLIANCE_RESPONSE =
  "Deleting compliance response was unsuccessful.";
export const ERR_CREATE_COMPLIANCE_REQUIREMENT =
  "Creating compliance requirement was unsuccessful.";
export const ERR_DELETE_COMPLIANCE_REQUIREMENT =
  "Deleting compliance requirement was unsuccessful.";

export const INSUFFICIENT_PERMISSIONS =
  "You do not have the permissions necessary to view this page. Contact your administrator if you think this is in error.";

export const ERR_MINUTES_BTWN_0_59 = "Minutes must be between 0 and 59";
export const ERR_NEGATIVE_HOURS = "Hours cannot be negative";
export const errExceededMaxLength = (maxLength: number) => {
  return `Max length is ${maxLength}`;
};

export const SELECT_AN_OPTION = "Select an option";

export const SAVE = "Save";

/* CUSTOMERS */

export const CUSTOMERS = "Customers";
export const NEW_CUSTOMER = "New Customer";
export const NEW_CUSTOMER_CONTACT = "New Customer Contact";
export const ADD_NEW_CUSTOMER = "Add New Customer";
export const DELETE_CUSTOMER = "Delete Customer";
export const DELETED = "Deleted";
export const CONFIRM_DELETE_CUSTOMER =
  "Are you sure you want to delete this customer? This will also delete all customer locations.";
export const REMOVE_BILL_TO_SELECTION = "Remove Bill To Selections";
export const EDIT_CUSTOMER = "Edit Customer";
export const ERR_CREATE_NEW_CUSTOMER =
  "Creating new customer was unsuccessful.";
export const ERR_CREATE_NEW_CUSTOMER_CONTACT =
  "Creating new customer contact was unsuccessful.";
export const ERR_EDIT_CUSTOMER = "Updating customer was unsuccessful";
export const ERR_MERGE_CUSTOMER = "Merge customer was unsuccessful";
export const NEW_TASK = "New Task";
export const NEW_TASK_FOR_THIS_CUSTOMER = "New Task For This Customer";
export const NEW_TASK_FOR_EXISTING_JOB = "New Task (for existing Job)";
export const NEW_JOB = "New Job";
export const CLOSE_RECORD = "Close Job";
export const CREATE_TASK = "Create Task";
export const CREATE_JOB = "Create Job";
export const CREATE_TASK_FOR_JOB = "Create Task For This Job";
export const CREATE_ASSET_EQUIPMENT = "Add Asset/Equipment";
export const SELECT_CREATION_CHOICE = "Create a Job or an Event?";
export const CUSTOMER = "Customer";
export const SELECT_EXISTING_CUSTOMER = "Select Existing Customer";
export const SELECT_EXISTING_CUSTOMER_LOCATION =
  "Select Existing Customer Location";
export const CONFIRM_UPDATE_CL = "Confirm Updating Customer Location";
export const SELECT_CUSTOMER = "Select Customer";
export const SELECTED_CUSTOMER = "Selected customer";
export const SELECT = "Select";
export const CUSTOMER_NAME = "Customer Name";
export const EMAIL = "Email";
export const SELECT_EMAIL = "Select Email Addresses";
export const SEND_EMAIL = "Send email";
export const SENT_EMAIL = "Sent email";
export const SEND_EMAIL_ERR = "Unable to send email";
export const EDIT = "Edit";
export const DOWNLOAD = "Download";
export const REPORTS = "Reports";
export const SAVED_REPORTS = "Saved reports";
export const GENERATED_REPORTS = "Generated reports";
export const CREATE_REPORT_FROM_TEMPLATE = "Create report from template";
export const PHONE = "Phone";
export const PHONE_TOO_SHORT = "Phone number is too short";
export const CUSTOMER_TYPE = "Customer Type";
export const NEW_LOCATION = "New Location";
export const CUSTOMER_LOCATION = "Customer Location";
export const CUSTOMER_LOCATIONS = "Customer Locations";
export const LOCATION_CONTACTS = "Additional Location Contacts";
export const CUSTOMER_CONTACTS = "Additional Contacts";
export const NO_CUSTOMER_SELECTED = "No customer selected";
export const NO_CUSTOMER_LOCATION_SELECTED = "No customer location selected";
export const selectBlankFirst = (blank: string) => {
  return `Select a ${blank} before proceeding.`;
};
export const WORK_AND_TASK_TYPE = "Work and Task Type";
export const WORK_AND_TASK_TYPES_DESC =
  "Select a work type, then select a task type.";
export const WORK_TYPE = "Work Type";
export const TASK_TYPE = "Task Type";
export const NO_WORK_TYPE_SELECTED = "No work type selected";
export const NO_TASK_TYPE_SELECTED = "No task type selected";
export const NO_ASSIGNED_TO_USER = "Task not assigned to user";
export const TASKS_OVERLAPPING =
  "Tasks are overlapping. Please choose a different technician or scheduled time";
export const NOTE = "Note";
export const NOTES = "Notes";
export const PLACEHOLDER_VISIBLE_NOTE =
  "This note will be visible to the customer";
export const PLACEHOLDER_INTERNAL_NOTE =
  "This note will not be visible to the customer";
export const ERR_DESCRIPTION_TOO_LONG =
  "Character limit for description has been exceeded";
export const TAX_EXEMPT = "Tax Exempt";
export const TAX_EXEMPT_PST = "Tax Exempt (PST)";
export const TAX_EXEMPT_GST = "Tax Exempt (GST)";
export const ENABLE_CALL_FORWARDING = "Enable Call Forwarding";
export const DO_NOT_SERVICE = "Do Not Service";
export const OPT_IN = "Opt-in to automated messaging?";
export const INTERNAL_NOTES = "Internal Notes";
export const CUSTOMER_NOTES = "Customer Notes";
export const LOCATION_NOTES = "Location Notes";
export const JOB_NOTES = "Job Notes";
export const TEMPLATE_NOTES = "Job Template Notes";
export const OPTIONAL = "Optional";
export const SCHEDULING = "Scheduling";
export const SERVICE_DATE_TIME = "Service date and service start time";
export const SERVICE_WINDOW_DURATION = "Service window duration";
export const ADD_CUSTOM_FIELD = "Add Custom Field";
export const CUSTOM_FIELDS = "Custom Fields";
export const ADDITIONAL_FIELDS = "Additional Fields";
export const SELECT_USERS = "Select Users";
export const ERR_UPDATING_USERS_ASSIGNED_TO_TASK =
  "Encountered an error while updating users assigned to this task";
export const NO_ADDITIONAL_FIELDS_TO_FILL_OUT =
  "No additional fields to fill out";
export const NONE_TO_DISPLAY = "None to display";
export const NO_USER_PERMISSIONS_DOC =
  "Unable to locate your user permissions. Please contact support@stiltwork.com if this error persists.";
export const UNRECOGNIZED_WORK_TYPE =
  "Unrecognized work type. Please contact support@stiltwork.com if this error persists.";
export const UNRECOGNIZED_TASK_TYPE =
  "Unrecognized task type. Please contact support@stiltwork.com if this error persists.";
export const missingResponseForCF = (title: string) => {
  return `Missing required custom field response for ${title}`;
};
export const SELECT_FROM_THE_FOLLOWING = "Select from the following";
export const URGENT = "Urgent";
export const NEXT_OPPORTUNITY = "Next Opportunity";
export const AWAITING_SCHEDULE = "Awaiting Schedule";
export const AWAITING_PARTS = "Awaiting Parts";
export const AWAITING_ESTIMATE = "Awaiting Estimate";
export const AWAITING_APPROVAL = "Awaiting Approval";

export const LOCATION_DETAILS = "Location Details";
export const FULL_ADDRESS = "Full Address";
export const ADDRESS_LINE_1 = "Address Line 1";
export const ADDRESS_LINE_2 = "Address Line 2";
export const CUSTOMER_DETAILS = "Customer Details";
export const CONTACT_FIRST_NAME = "Contact First Name";
export const CONTACT_LAST_NAME = "Contact Last Name";
export const TYPE_RESIDENTIAL_COMMERCIAL = "Type (Residential / Commercial)";
export const ADD_BILLING_INFO = "Add different billing information…";
export const BILLS_TO = "Bills To:";
export const CHANGE_BILLING_INFORMATION = "Change Billing Information";
export const BILL_TO_CUSTOMER = "Select a different billing customer...";
export const BILL_TO_LOCATION = "Select a different billing location...";
export const ADD_NEW_LOCATION = "Add New Location";
export const BILLING_INFO = "Billing Info";
export const MANUAL_ENTRY = "Manual Entry";

// INVOICES / PAYMENTS
export const INVOICE = "Invoice";
export const FULL_NAME = "Full Name";
export const ADDRESS = "Address";
export const COUNTRY = "Country";
export const STATE = "State";
export const CITY = "City";
export const ZIP = "ZIP";
export const CARD_NUMBER = "Card Number";
export const EXPIRATION = "Expiration";
export const CVV = "CVV";
export const ACCOUNT_TYPE = "Account Type";
export const CHECKING = "Checking";
export const SAVINGS = "Savings";
export const ACCOUNT_NUMBER = "Account Number";
export const ROUTING_NUMBER = "Routing Number";
export const ERROR_CREATING_CUSTOMER_TASK =
  "Encountered an error while creating new task for customer";
/** **only use if it's accompanied by logger.error, which will trigger a Sentry alert** */
export const ERR_ISSUING_REFUND =
  "Encountered an error while issuing a refund for this payment; we have been notified.";
export const ISSUED_REFUND = "Issued refund";
export const NO_INVOICE_FOR_TASK_WARNING =
  "No invoices have been created for this task";

export const CREATE_INVOICE = "Create Invoice";
export const VIEW_INVOICE = "View Invoice";
export const DELETE_INVOICE = "Delete Invoice";
export const INVOICES = "Invoices";
export const MISSING_EMAIL = "Email is missing";
export const PAYMENT = "Payment";
export const PAYMENTS = "Payments";
export const PAY_NOW = "Pay Now";
export const MAKE_A_PAYMENT = "Make A Payment";
export const PAY_WITH_NEW_CARD = "Pay with new card";
export const PAY_WITH_CARD_ON_FILE = "Pay with card on file";
export const SELECT_CARD = "Select Card";
export const PAY_WITH_CARD_ON_FILE_SUCCESS = "Paid invoice with card on file";
export const PAY_WITH_CARD_ON_FILE_ERROR =
  "Unable to pay invoice using the card on file";
export const PAY_IN_FULL = "Pay In Full";
export const PAY_PARTIAL = "Make Partial Payment";
export const RECORD_MANUAL_PAYMENT = "Record Manual Payment";
export const MULTI_PAY_CC_HEADER = "Pay Multiple Invoices By Card";
export const PAY = "Pay";
export const PAID = "Paid";
export const EMAIL_CUSTOMER_LINK = "Email Link To Customer";
export const EMAIL_RECEIPT = "Email Receipt";
export const EMAIL_INVOICE = "Email Invoice";
export const GENERATE_PAYMENT_LINK = "Generate Payment Link";
export const GENERATE_CUSTOMER_STATEMENTS = "Generate Customer Statements";
export const TOOLTIP_GENERATE_SCHEDULES =
  "Generate PDFs of scheduled tasks for each customer";
export const HANDLE_PAYMENT = "Handle Payment";
export const ERROR_PAYMENT_LINK = "Error generating payment link";
export const ERROR_ATTEMPTING_ACCOUNTING_SYNC =
  "Error with accounting sync attempt";
export const ERROR_ATTEMPTING_PUSH = "Error with push request";
export const ERROR_RECORD_MANUAL_PAYMENT = "Error recording manual payment";
export const ERR_DELETING_PAYMENT = "Unable to delete payment";
export const ERR_DELETING_REFUND = "Unable to delete refund";
export const DELETED_PAYMENT = "Deleted payment";
export const DELETED_REFUND = "Deleted refund";
export const PAYMENT_DID_NOT_MATCH_INVOICE_AMOUNTS =
  "Payment amount did not equal the total amount due of the given invoices";
export const SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT =
  "Success! A receipt will be emailed to this customer";
export const SUCCESS_MANUAL_PAYMENT_NO_EMAIL =
  "Success! This customer does not have an email saved and will not be sent a receipt automatically";
export const SUCCESS_MANUAL_PAYMENT_NO_RECEIPT =
  "Success! Manual payment has been saved";
export const TOTAL_PAYMENT_DUE = "Total Payment Due: ";
export const AMOUNT_PAID_MTD = "Amount Paid Month To Date: ";
export const AMOUNT_PAID_YTD = "Amount Paid Year To Date: ";
export const SUBTOTAL = "Subtotal";
export const SUBTOTAL_WITH_DISCOUNT = "Subtotal with Discount";
export const TOTAL_DISCOUNT_AMOUNT = "Total Discount Amount";
export const DISCOUNT = "Discount";
export const TIP = "Tip";
export const TAX_TOTAL = "Tax Total";
export const PST = "PST";
export const GST = "GST";
export const TOTAL_AMOUNT = "Total Amount";
export const AMOUNT_DUE = "Amount Due";
export const OVERPAYMENT = "Overpayment";
export const NO_EMAIL_FOR_CUSTOMER = "No email address found for this customer";
export const ERROR_EMAIL = "Please insert a valid email";
export const ERROR_SUBJECT = "Please insert a subject for this email";
export const NO_PHONE_FOR_CUSTOMER = "No phone number found for this customer";
export const EMAILED_CUSTOMER_INVOICE = "Emailed invoice link to customer";
export const EMAILED_CUSTOMER_RECEIPT = "Emailed receipt to customer";
export const ERR_EMAILING_INVOICE =
  "Encountered an error while emailing invoice link";
export const ERR_DELETE_INVOICE =
  "Encountered an error while deleting this invoice.";
/** **only use if it's accompanied by logger.error, which will trigger a Sentry alert** */
export const ERR_GEN_INVOICE_LINK =
  "Encountered an error while generating the invoice link; we have been notified.";
/** **only use if it's accompanied by logger.error, which will trigger a Sentry alert** */
export const ERR_EMAILING_RECEIPT_NOTIFIED =
  "Encountered an error while emailing receipt; we have been notified.";
export const ERR_EMAILING_RECEIPT =
  "Encountered an error while emailing receipt";
export const CONFIRM_DELETE_PAYMENT =
  "Are you sure you want to delete this payment record?";
export const DELETE_PAYMENT = "Delete Payment";

/* ESTIMATES */
export const ADD_NEW_PRICEBOOKITEM = "Add New Item";
export const ADD_NEW_PRICEBOOKITEM_CATEGORY = "Add New Category";
export const ADD_EDIT_PRICEBOOKITEM_CATEGORIES = "Add/Edit Categories";
export const NEW_ITEM = "New Item";
export const NEW_CATEGORY = "New Category";
export const EDIT_PRICEBOOKITEM = "Edit Item";
export const EDIT_PRICEBOOKITEM_CATEGORY = "Edit Category";
export const PRICEBOOK = "Price Book";
export const PRICEBOOK_CATEGORIES = "Price Book Categories";
export const SELECT_PRICEBOOK_ITEM = "Select an Item";
export const SELECT_RENEWAL_PRICEBOOK_ITEM = "Select an Item for Renewal";
export const ERROR_CREATING_PRICEBOOK_ITEM =
  "Creating new pricebook item was unsuccessful.";
export const ERROR_CREATING_PRICEBOOK_ITEM_CATEGORY =
  "Creating new pricebook item category was unsuccessful.";
export const ERROR_SELECTING_PRICEBOOK_ITEM = "No pricebook item selected";
export const ERROR_EDITING_PRICEBOOK_ITEM =
  "Editing pricebook item was unsuccessful";
export const ERROR_EDITING_PRICEBOOK_ITEM_CATEGORY =
  "Editing pricebook item category was unsuccessful";

export const ERR_CREATE_CALENDAR_EVENT = "Unable to create calendar event";
export const ERR_UPDATE_CALENDAR_EVENT = "Unable to update calendar event";
export const ERR_DELETE_CALENDAR_EVENT = "Unable to delete calendar event";

/* COMMISSIONS */
export const EDIT_COMMISSION_ADJUSTMENT = "Edit Commission Adjustment";
export const NEW_COMMISSION_ADJUSTMENT = "New Commission Adjustment";
export const COMMISSION_ADJUSTMENT = "Commission Adjustment";
export const ERROR_EDIT_COMMISSION_ADJUSTMENT =
  "Error Editing Commission Adjustment";
export const ERROR_NEW_COMMISSION_ADJUSTMENT =
  "Error Creating Commission Adjustment";
export const COMMISSIONS = "Commissions";

export const UNITS = "Units";
export const TYPE = "Type";
export const TAGS = "Tags";
export const TAXABLE = "Taxable";
export const EDITABLE = "Editable";
export const DISCOUNTABLE = "Discountable";

export const LINE_ITEMS = "Items";
export const ESTIMATE_DETAILS = "Estimate Details";
export const ESTIMATES = "Estimates";
export const ADD_ESTIMATE = "Add Estimate";
export const ESTIMATE_NOTES = "Estimate Notes";
export const ESTIMATE_INTERNAL_NOTES = "Estimate Internal Notes";
export const EDIT_NOTES = "Edit Notes";
export const ERR_EDIT_NOTES = "Updating notes was unsuccessful";
export const EDIT_INTERNAL_NOTES = "Edit Internal Notes";
export const ERR_EDIT_INTERNAL_NOTES =
  "Updating internal notes was unsuccessful";
export const ESTIMATE_STATUS = "Estimate Status";
export const NO_ESTIMATE_ITEM_SELECTED =
  "At least one estimate item should be inserted";
export const CREATE_NEW_ESTIMATE = "New Estimate";
export const APPROVE_ESTIMATE = "Approve Estimate";
export const ESTIMATE_ON_HOLD = "Estimate On Hold";
export const REJECT_ESTIMATE = "Reject Estimate";
export const SAVE_ESTIMATE = "Save Estimate";
export const SAVE_ESTIMATE_AND_GET_EXTRA_INFO =
  "Save Estimate and Get Extra Information";
export const REMOVE_ALL_ITEMS = "Remove all Items";
export const SEND_ESTIMATE_TO_CUSTOMER = "Send Estimate To Customer";
export const SEND_RECEIPT_TO_CUSTOMER = "Send Receipt To Customer";
export const VIEW_ESTIMATE = "View Estimate";
export const SEND_ESTIMATE_VIA_EMAIL = "Send Estimate Via Email";
export const SEND_ESTIMATE_VIA_SMS = "Send Estimate Via SMS";

/* WORK RECORDS / TASKS */
export const RESCHEDULE_TASK = "Reschedule Task";
export const ERROR_RESCHEDULING_TASK =
  "Encountered an error while rescheduling task";
export const COMPANY = "Company";
export const STATUS = "Status";
export const NO_DETAILS_FOR_WORK_REC =
  "There are no specific details for this Job";
export const NO_CLOSED_TASKS = "No closed tasks for this Job";
export const NO_OPEN_TASKS = "No open tasks for this Job";
export const DELETE_WORK_RECORD = "Delete job";
export const DELETE_EVENT = "Delete event";
export const DELETE_ITEM = "Delete item";
export const DELETE_VEHICLE = "Delete vehicle";
export const DELETE_ZONE = "Delete zone";
export const DELETE_COMMISSION_ADJUSTMENT = "Delete";
export const DUPLICATE_JOB = "Duplicate job";
export const DUPLICATE_ESTIMATE = "Duplicate estimate";
export const ERR_DELETE_WORK_REC =
  "Encountered an error while deleting this job";
export const ERR_DELETE_EQUIPMENT =
  "Encountered an error while deleting this equipment";
export const ERR_DUPLICATE_WORK_REC =
  "Encountered an error while duplicating this job";
export const ERR_DELETE_TASK = "Encountered an error while deleting this task";
export const VIEW_MAP = "View Map";
export const VIEW_PHOTOS = "View Photos";
export const PHOTOS = "Photos";
export const INCLUDE_JOB_PHOTOS = "Include Job Photos";
export const DISPLAY_PAYMENT_LINK = "Display Payment Link";
export const EDIT_WORK_RECORD = "Edit Job";
export const ERR_UPDATE_WORK_REC =
  "Encountered an error while updating this job";
export const ERR_UPDATE_TASK = "Encountered an error while updating this task";
export const SELECT_LOCATION = "Select Location";
export const SELECTED_LOCATION = "Selected location";
export const SELECT_STATUS = "Select Status";
export const TITLE = "Title";
export const ATTACHMENTS = "Attachments";
export const ADD_ATTACHMENT_SUCCESS = "Attachment added successfully";
export const DOWNLOAD_ATTACHMENT = "Download attachment";
export const DELETE_ATTACHMENT = "Delete attachment";
export const DELETE_PHOTO = "Delete photo";
export const UPLOAD_PHOTO = "Upload photo";
export const EXPAND_PHOTO = "Expand photo";
export const DOWNLOAD_ALL_PHOTOS = "Download all photos";
export const DOWNLOAD_PDF = "Download PDF";
export const DETAILS = "Details";
export const EVENTS = "Events";
export const NO_SPECIFIC_DETAILS = "No specific details to display";
export const ADDITIONAL_NOTES = "Additional Notes";
export const EDIT_TASK = "Edit Task";
export const EDIT_DATE = "Edit Date";
export const EDIT_TASK_DATE = "Edit Task Date";
export const DELETE_TASK = "Delete Task";
export const CLOSE_WORK_REC_OR_LEAVE_OPEN_QUESTION =
  "Would you like to close the parent job or keep it open for future tasks?";
export const NO_OPEN_TASKS_REMAINING = "No open tasks are remaining";
export const TASK_STATUS_CHANGE = "Task Status Change";
export const SELECT_WORK_REC_PERSISTENCE = "Select a job persistence option";
export const BARRIER_COMMENTS_REQUIRED_WHEN_BARRIER_OTHER_VALUE_PRESENT =
  "Barrier Comments are required when there is a value for Barrier - Other";
export const WORK_RECORD = "Job";
export const WORK_RECORDS = "Jobs";
export const CONFIRM_CLOSE_WR = "Confirm Closing Job";
export const CONFIRM_DELETE_WR = "Confirm Deleting Job";
export const DUPLICATE_SCHEDULING =
  "All duplicated tasks will be scheduled according to your selection below.";
export const NO_SITE_LOCATION_SELECTED = "No site location selected";
export const CONFIRM_REVERT_TO_AWAITING =
  "Are you sure you want to revert this task status? This task will be changed back to Awaiting status with the same schedule date.";
export const SEND_BOOKING_CONFIRMATION_EMAIL = "Booking Confirmation Email";
export const DEFAULT_SEND_BOOKING_CONFIRMATION_EMAIL =
  "Send booking confirmation emails";
export const BOOKING_CONFIRMATION_EMAIL_HELP_TEXT =
  "Turn this on to have an email sent to the customer notifying them of this scheduled task";
export const SEND_24H_REMINDER_EMAIL = "Reminder Email";
export const DEFAULT_SEND_24H_REMINDER_EMAIL = "Send reminder emails";
export const SEND_24H_REMINDER_EMAIL_HELP_TEXT =
  "Turn this on to have an email sent to the customer reminding them of this scheduled task 24hrs prior to the scheduled time";
export const CONFIRM_CHANGE_TASK_STATUS_TO_AWAITING_PARTS =
  "Do you want to change the status of this existing task to Awaiting Parts, or do you want to complete this task and create a follow-up task in Awaiting Parts status?";
export const CONFIRM_CHANGE_TASK_STATUS_TO_PARTS_ORDERED =
  "Do you want to change the status of this existing task to Parts Ordered, or do you want to complete this task and create a follow-up task in Parts Ordered status?";

/* INVENTORY */
export const INVENTORY_OBJECT = "Inventory Objects";
export const NEW_INVENTORY_OBJECT = "Add Inventory Object";
export const ERR_CREATE_NEW_INVENTORY_OBJECT =
  "Creating new inventory object was unsuccessful";
export const ERR_CREATE_NEW_INVENTORY_LOCATION =
  "Creating new inventory location was unsuccessful";
export const SELECT_INVENTORY_OBJECT = "Select Inventory Object";

export const INVENTORY_TRANSACTION = "Inventory Transactions";
export const NEW_INVENTORY_TRANSACTION = "Add Inventory Transaction";
export const NO_INVENTORY_TRANSACTION = "No Inventory Transaction Selected";

export const INVENTORY_LOCATIONS = "Inventory Locations";
export const NEW_INVENTORY_LOCATION = "Add Inventory Location";

/* SCHEDULING */
export const USERS_ASSIGNED_SUCCESSFULLY =
  "User successfully assigned to tasks";
export const ADD_STILT_CALENDAR_EVENT = "Add New Calendar Event";
export const EDIT_STILT_CALENDAR_EVENT = "Edit Calendar Event";
export const ASSIGN_USER = "Assign User";
export const ASSIGN_USERS = "Assign User(s)";
export const ASSIGN_VEHICLES = "Assign Vehicle(s)";
export const ERROR_START_DATE_OLDER = "Start date is older than end date";
export const VEHICLES = "Vehicles";
export const ZONES = "Zones";
export const ADD_NEW_VEHICLE = "Add New Vehicle";
export const ADD_NEW_ZONE = "Add New Zone";
export const EDIT_VEHICLE = "Edit Vehicle";
export const EDIT_ZONE = "Edit Zone";
export const ERROR_EDITING_VEHICLE = "Editing vehicle was unsuccessful";
export const ERROR_CREATING_VEHICLE = "Creating new vehicle was unsuccessful.";
export const ERROR_EDITING_ZONE = "Editing zone was unsuccessful";
export const ERROR_CREATING_ZONE = "Creating new zone was unsuccessful.";
export const ASSIGN_USERS_TO_EVENT = "Assign user(s) to this event";
export const ASSIGN_VEHICLES_TO_EVENT = "Assign vehicle(s) to this event";

/* MEMBERSHIPS */
export const ADD_MEMBERSHIPS = "Add Memberships";
export const CONFIRM_TASK_FOR_MEMBERSHIP = "Confirm task for membership";
export const EDIT_MEMBERSHIP = "Edit Membership";
export const NEW_MEMBERSHIP = "New Membership";
export const MEMBERSHIPS = "Memberships";
export const MEMBERSHIP_TASK = "Membership Task";
export const MEMBERSHIP_DETAILS = "Membership Details";
export const MEMBERSHIP_TEMPLATES = "Membership Templates";
export const RENEWED_FROM_PRVIOUS_MEMBERSHIP =
  "Renewed from previous membership";
export const RENEWED_TO_NEW_MEMBERSHIP = "Renewed to new membership";
export const SELECT_MEMBERSHIP = "Select Membership";
export const ADD_NEW_TEMPLATE = "Add New Template";
export const EDIT_TEMPLATE = "Edit Template";
export const ADD_TASK_TEMPLATE = "Add Task for Template";
export const ERROR_CREATING_MEMBERSHIP_TEMPLATE =
  "Creating new membership template was unsuccessful.";
export const ERROR_EDITING_MEMBERSHIP_TEMPLATE =
  "Editing membership template was unsuccessful";
export const DISCOUNTS = "Discounts";
export const SCHEDULE_NEXT_TASK = "Schedule Next Task";
export const LAST_COMPLETED_TASK_DATE = "Last Completed Task";
export const LAST_PAYMENT_AMOUNT = "Last Payment Amount";
export const LAST_PAYMENT_DATE = "Last Payment";
export const MEMBERSHIP_START_DATE = "Membership Start";
export const MEMBERSHIP_END_DATE = "Membership End";
export const NEXT_INVOICE_DATE = "Next Invoice";
export const NEXT_SCHEDULED_TASK_DATE = "Next Scheduled Task";

/* ASSETS & EQUIPMENT */
export const ASSETS_EQUIPMENT = "Assets & Equipment";
export const SELECT_ASSET = "Select Asset/Equipment";
export const NEW_ASSET = "New Asset/Equipment Record";
export const EDIT_ASSET = "Edit Asset/Equipment";
export const CONFIRM_DELETE_EQUIPMENT = "Delete Equipment Confirmation";

/* CRAFT LIST */
export const ALL_RECORDS = "All Jobs";
export const CLOSED_RECORDS = "Closed Jobs";
export const DELETED_WORK_RECORDS = "Deleted Jobs";
export const RESTORE_WR_QUESTION = "Restore Job?";
export const ERR_WR_CANNOT_BE_RESTORED =
  "This Job cannot be restored. Please try again.";

/* TASK LIST */
export const ALL_TASKS = "All Tasks";
export const DELETED_TASKS = "Deleted Tasks";
export const RESTORE_TASK_QUESTION = "Restore Task?";
export const ERR_TASK_CANNOT_BE_RESTORED =
  "This Task cannot be restored because the parent Job is also deleted. Please restore the parent Job and then try again.";

/* VUE */
export const DASHBOARD = "Dashboard";

// FEEDBACK
export const FEEDBACK = "Feedback";
export const NOT_REALLY = "Not really";
export const YES_WITH_EXCLAMATION = "Yes!";
export const RATE_OUR_SERVICE = "Rate our service";
export const RATE_OUR_TECHNICIAN = "Rate our technician";
export const ANYTHING_YOU_WANT_TO_SHARE =
  "Is there anything specific you would like to share?";
/** **only use if it's accompanied by logger.error, which will trigger a Sentry alert** */
export const ERR_SUBMIT_RESPONSE_NOTIFIED =
  "Encountered an error while submitting your response; we have been notified";
export const THANKS_FOR_FEEDBACK =
  "Thank you for your feedback. It is valuable to us.";
/** **only use if it's accompanied by logger.error, which will trigger a Sentry alert** */
export const ERR_LOCATE_FEEDBACK_NOTIFIED =
  "Encountered an error while locating this customer's feedback details; we have been notified";
export const feedbackFromCustomer = (customerName: string) => {
  return `Feedback from ${customerName}`;
};
export const noFeedbackFromCustomer = (customerName: string) => {
  return `${customerName} has not provided any feedback yet.`;
};
export const SERVICE_RATING = "Service rating";
export const TECHNICIAN_RATING = "Technician rating";
export const ADDITIONAL_COMMENTS = "Additional comments";
export const NUM_EMAILS_SENT = "Number of emails sent";
export const EMAIL_LAST_SENT = "Email last sent";
export const TIME_OPENED = "Time opened";
export const TIME_CREATED = "Time created";
export const feedback_status = {
  POSITIVE: "Positive feedback",
  NEGATIVE: "Negative feedback",
  AWAITING: "Awaiting feedback",
} as const;

/* QUICKBOOKS */
export const QUICKBOOKS = "QuickBooks";
export const QUICKBOOKS_SYNC_ERRORS = "QuickBooks Sync Errors";
export const QB_CANT_CONNECT =
  "Unable to establish a connection to Intuit's Servers. Please try again later.";
export const QB_CANT_DISCONNECT =
  "Unable to disconnect from QuickBooks. Please try again later.";
export const CONNECT = "Connect";
export const RECONNECT = "Reconnect";
export const DISCONNECT = "Disconnect";
export const DISCONNECTED = "Disconnected";

/* LIGHTSPEED */
export const LIGHTSPEED = "Lightspeed";
export const LIGHTSPEED_TRANSACTIONS = "Lightspeed Transactions";
export const DATE_PUSHED = "Date Pushed";

/* TABLES */
export const ERR_SAVE_TABLE_VIEW = "Error saving table views preferences";
export const NEW_TABLE_VIEW = "New Table View";
export const SAVE_TABLE_VIEW = "Save this View";
export const DELETE_TABLE_VIEW = "Delete this View";
export const SET_DEFAULT_VIEW = "Set this View as Default";
export const EDIT_TABLE_VIEWS = "Edit My Views";

// #region CALLS
/* CALLS */
export const CALLS_PAGE_TITLE = "Incoming Calls";

// #endregion

// #region GENERAL LEDGER ACCOUNTS
/* GENERAL LEDGER ACCOUNTS */
export const GENERAL_LEDGER_ACCOUNTS = "General Ledger Accounts";
export const ADD_NEW_GL_ACCOUNT = "Add New Account";
export const ERROR_CREATING_GL_ACCOUNT =
  "Creating new general ledger account was unsuccessful.";
// #endregion

export const TIME_TO_GENERATE = "Time to generate";
