// Libs
import { AttachFile } from "@mui/icons-material";
import {
  Control,
  Controller,
  FormState,
  useForm,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import { z } from "zod";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Local
import { ExistingComplianceRequirement } from "../../models/compliance-requirement";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseInputText from "../BaseInputText";
import { ComplianceItemCommon } from "../../models/compliance-item";
import BaseInputSelect from "../BaseInputSelect";
import { logger } from "../../logging";
import BaseInputNumber from "../BaseInputNumber";
import { FieldsFromRequirement } from "../../Pages/Compliance/ComplianceContainer";
import { ErrorMessage } from "../ErrorMessage";
import StyledMessage from "../StyledMessage";
import { ExistingComplianceResponse } from "../../models/compliance-response";
import { getSortedComplianceItemList } from "../../assets/js/getSortedComplianceItemList";

interface Props {
  requirement: ExistingComplianceRequirement;
  isDialogOpen: boolean;
  closeDialog: () => void;
  onSubmitResponse: (args: {
    formValues: CFAnswersState;
    fieldsFromRequirement: FieldsFromRequirement;
    docID: string;
  }) => Promise<void>;
  onAttachmentUpload: (args: {
    file: File;
    responseID: ExistingComplianceResponse["id"];
  }) => void;
}

const CFAnswersSchema = z.record(z.union([z.string(), z.number(), z.null()]));
export type CFAnswersState = z.infer<typeof CFAnswersSchema>;

export function ComplianceResponseDialog(props: Props): JSX.Element {
  const [showServerError, setShowServerError] = useState(false);

  // For setting the UI text
  const [fileInformation, setFileInformation] = useState(
    strings.UPLOAD_NO_FILE_CHOSEN,
  );
  // The file itself
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // Attachment preview
  const [preview, setPreview] = useState<string | null>(null);
  // Makes text bigger & red, in the case of the user clicking submit without selecting
  // a file, or if the file they select surpasses the size limit.
  const [attachmentResponseToUserError, setAttachmentResponseToUserError] =
    useState(false);

  const docID = uuidv4();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(null);
      return;
    }

    const objectURL = URL.createObjectURL(selectedFile);
    setPreview(objectURL);

    // Free memory when component is unmounted
    return () => {
      URL.revokeObjectURL(objectURL);
      setPreview(null);
    };
  }, [selectedFile]);

  const defaultValues: CFAnswersState = useMemo(() => {
    return {};
  }, []);
  // Create the defaultValues object.
  Object.entries(props.requirement.customFields).forEach(([id, eachCF]) => {
    const isStringBased =
      eachCF.responseType === "string" || eachCF.responseType === "selection";
    if (isStringBased) {
      defaultValues[id] = "";
    } else {
      defaultValues[id] = null;
    }
  });

  const {
    control,
    handleSubmit,
    register,
    getFieldState,
    getValues,
    formState,
    reset,
  } = useForm<CFAnswersState>({
    defaultValues: defaultValues,
    mode: "onSubmit",
  });
  const { isSubmitting } = formState;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function onCloseDialog() {
    props.closeDialog();
    reset();
    setSelectedFile(null);
    setPreview(null);
    setAttachmentResponseToUserError(false);
    setFileInformation(strings.UPLOAD_NO_FILE_CHOSEN);
  }

  // When the form is submitted
  async function onSubmit(formValues: CFAnswersState) {
    // Need to check for intersection of required and no value. If found, return.
    // Doing some manual validation.
    const validityList = Object.entries(props.requirement.customFields).map(
      ([id, eachCF]) => {
        const noReceivedValue =
          formValues[id] === null || formValues[id] === "";

        if (eachCF.required && noReceivedValue) {
          logger.debug(
            `${eachCF.text}: required but received no input`,
            `ID: ${id}`,
          );
          return false;
        }
        return true;
      },
    );
    // Stop the form from submitting if it failed validation
    if (validityList.includes(false)) {
      return;
    }
    // Stop the form from submitting if there's no file selected
    if (selectedFile == null) {
      setAttachmentResponseToUserError(true);
      return;
    }

    const fromTheRequirement: FieldsFromRequirement = {
      requirementID: props.requirement.id,
      type: props.requirement.type,
      title: props.requirement.title,
      description: props.requirement.description,
      customFields: props.requirement.customFields,
    };
    try {
      props.onAttachmentUpload({
        file: selectedFile,
        responseID: docID,
      });

      await props.onSubmitResponse({
        formValues,
        fieldsFromRequirement: fromTheRequirement,
        docID: docID,
      });

      onCloseDialog();
    } catch (e) {
      setShowServerError(true);
      logger.error("error submitting compliance response:", e);
    }
  }

  function handleFileSelected(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length === 1) {
      const chosenFile = event.target.files[0];

      // If file is larger than 50MB
      if (chosenFile.size >= 50000000) {
        setAttachmentResponseToUserError(true);
        setFileInformation(strings.UPLOAD_UNDER_50MB);
        return;
      }

      // Accepted file types: pdf, png, jpeg
      if (
        chosenFile.type !== "image/png" &&
        chosenFile.type !== "image/jpeg" &&
        chosenFile.type !== "application/pdf"
      ) {
        setAttachmentResponseToUserError(true);
        setFileInformation(strings.ACCEPTED_COMPLIANCE_FILETYPES);
        return;
      }

      // Set file - ready to be sent to submit handler ƒn
      setSelectedFile(chosenFile);

      // UI will display the chosen filename
      setFileInformation(chosenFile.name);

      // Reset the css styles
      setAttachmentResponseToUserError(false);
    }
  }

  const errorMsg = (
    <ErrorMessage
      message={strings.ERR_SUBMIT_COMPLIANCE_RESPONSE}
      clearMessage={() => setShowServerError(false)}
    />
  );
  const sortedComplianceItemList = getSortedComplianceItemList(
    props.requirement.customFields,
  );

  // SECTION: Render
  return (
    <BaseModal
      open={props.isDialogOpen}
      title={
        <div className="relative rounded-t-lg bg-primary p-6 text-xl font-semibold text-white">
          {strings.RESPONSE}: {props.requirement.title}
        </div>
      }
      closeModal={props.closeDialog}
      parentDivStyles="text-left max-w-sm sm:max-w-xl"
    >
      <div className="relative flex flex-col justify-between px-6 py-4 text-base">
        {showServerError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-80">
            {errorMsg}
          </span>
        ) : null}
        <p className="mb-4 text-gray-500">{props.requirement.description}</p>
        {/* CUSTOM FIELDS */}
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          {sortedComplianceItemList.map((item) =>
            Object.entries(item).map(([id, eachCF]) => (
              <div key={id} className="mb-3">
                <label
                  className="mb-1 block font-semibold text-gray-700"
                  htmlFor={eachCF.text}
                >
                  {eachCF.text}
                  {eachCF.required ? <span className="pl-0.5">*</span> : null}
                </label>

                {eachCF.responseType === "string" && (
                  <StringField
                    cfID={id}
                    item={eachCF}
                    control={control}
                    register={register}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    formState={formState}
                  />
                )}
                {eachCF.responseType === "selection" && (
                  <SelectionField
                    cfID={id}
                    item={eachCF}
                    control={control}
                    register={register}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    formState={formState}
                  />
                )}
                {eachCF.responseType === "integer" && (
                  <IntegerField
                    cfID={id}
                    item={eachCF}
                    control={control}
                    register={register}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    formState={formState}
                  />
                )}
                {eachCF.responseType === "float" && (
                  <FloatField
                    cfID={id}
                    item={eachCF}
                    control={control}
                    register={register}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    formState={formState}
                  />
                )}
              </div>
            )),
          )}

          {/* UPLOAD ATTACHMENT */}
          <div className="my-3 flex flex-col items-center">
            <label
              htmlFor="upload-attachment"
              className="inline-block w-80 cursor-pointer rounded border-4 border-dotted border-transparent bg-white p-4 text-center text-base font-semibold text-gray-700 transition hover:border-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
            >
              <AttachFile aria-label={strings.ADD_ATTACHMENT} />
              {strings.TAP_TO_ADD_ATTACHMENT}
            </label>
            <input
              id="upload-attachment"
              type="file"
              onChange={(ev) => handleFileSelected(ev)}
              accept="application/pdf, image/jpeg, image/png"
              className="hidden"
            />
            <span
              className={`mt-3 w-56 rounded px-3 py-1.5 text-center text-sm text-gray-700 sm:w-80 ${
                attachmentResponseToUserError ? "bg-red-50" : ""
              }`}
            >
              <span
                className={
                  attachmentResponseToUserError
                    ? "text-base font-medium text-red-800"
                    : ""
                }
              >
                {fileInformation}
              </span>
            </span>
            {/* If preview & selectedFile are not null, display image */}
            {selectedFile &&
              (selectedFile.type === "image/png" ||
                selectedFile.type === "image/jpeg") &&
              preview && (
                <img
                  src={preview}
                  alt=""
                  className="mt-3 w-48 rounded sm:w-64"
                  aria-label="response image"
                />
              )}
            {/* If it's a PDF, display it */}
            {selectedFile &&
              selectedFile.type === "application/pdf" &&
              preview && (
                <embed
                  src={preview}
                  type="application/pdf"
                  className="mt-3 w-48 rounded sm:w-64"
                  aria-label="response PDF"
                />
              )}
          </div>

          {/* BUTTONS */}
          <div className="mt-8 flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
            <BaseButtonSecondary
              className="w-full uppercase"
              onClick={onCloseDialog}
            >
              {strings.buttons.CLOSE}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              className="w-full uppercase"
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SUBMITTING}
            >
              {strings.buttons.SUBMIT}
            </BaseButtonPrimary>
          </div>
        </form>
      </div>
    </BaseModal>
  );
}

// SECTION: Field types JSX
interface FieldProps {
  cfID: string;
  item: ComplianceItemCommon;
  control: Control<any, any>;
  register: UseFormRegister<Record<string, string | number | null>>;
  getValues: UseFormGetValues<Record<string, string | number | null>>;
  getFieldState: UseFormGetFieldState<Record<string, string | number | null>>;
  formState: FormState<Record<string, string | number | null>>;
}

function StringField(props: FieldProps): JSX.Element {
  const isTouched = props.getFieldState(props.cfID, props.formState).isTouched;
  return (
    <>
      <Controller
        defaultValue=""
        name={props.cfID}
        control={props.control}
        render={({ field }) => (
          <BaseInputText
            admin
            inputName={props.item.text}
            {...field}
            {...props.register(props.cfID)}
          />
        )}
      />
      <div className="mt-2 text-sm">
        {props.getValues(props.cfID) === "" &&
          props.item.required &&
          isTouched && (
            <StyledMessage type="error">
              {{ message: strings.REQUIRED }}
            </StyledMessage>
          )}
      </div>
    </>
  );
}

function SelectionField(props: FieldProps): JSX.Element {
  const options = props.item.selectionOptions;
  if (options == null) {
    logger.error(`selectionOptions was null. custom field ID: ${props.cfID}`);
    return <div>There has been a problem.</div>;
  }

  const isTouched = props.getFieldState(props.cfID, props.formState).isTouched;
  return (
    <>
      <Controller
        name={props.cfID}
        control={props.control}
        render={({ field }) => (
          <BaseInputSelect admin inputName={props.item.text} {...field}>
            <option value="" disabled>
              Select
            </option>
            {options.map((opt, i) => (
              <option key={i} value={opt}>
                {opt}
              </option>
            ))}
          </BaseInputSelect>
        )}
      />
      <div className="mt-2 text-sm">
        {props.getValues(props.cfID) === "" &&
          props.item.required &&
          isTouched && (
            <StyledMessage type="error">
              {{ message: strings.REQUIRED }}
            </StyledMessage>
          )}
      </div>
    </>
  );
}

function IntegerField(props: FieldProps): JSX.Element {
  const noValue =
    props.getValues(props.cfID) === "" || props.getValues(props.cfID) === null;
  const isTouched = props.getFieldState(props.cfID, props.formState).isTouched;
  return (
    <>
      <Controller
        defaultValue=""
        name={props.cfID}
        control={props.control}
        render={({ field }) => (
          <BaseInputNumber
            admin
            inputName={props.item.text}
            {...field}
            value={field.value === null ? "" : field.value}
            onChange={(event) => {
              const numberValue = event.target.valueAsNumber;
              field.onChange(isNaN(numberValue) ? null : numberValue);
            }}
          />
        )}
      />
      <div className="mt-2 text-sm">
        {noValue && props.item.required && isTouched && (
          <StyledMessage type="error">
            {{ message: strings.REQUIRED }}
          </StyledMessage>
        )}
      </div>
    </>
  );
}

function FloatField(props: FieldProps): JSX.Element {
  const noValue =
    props.getValues(props.cfID) === "" || props.getValues(props.cfID) === null;
  const isTouched = props.getFieldState(props.cfID, props.formState).isTouched;
  return (
    <>
      <Controller
        defaultValue=""
        name={props.cfID}
        control={props.control}
        render={({ field }) => (
          <BaseInputNumber
            admin
            inputName={props.item.text}
            {...field}
            value={field.value === null ? "" : field.value}
            onChange={(event) => {
              const numberValue = event.target.valueAsNumber;
              field.onChange(isNaN(numberValue) ? null : numberValue);
            }}
          />
        )}
      />
      <div className="mt-2 text-sm">
        {noValue && props.item.required && isTouched && (
          <StyledMessage type="error">
            {{ message: strings.REQUIRED }}
          </StyledMessage>
        )}
      </div>
    </>
  );
}
