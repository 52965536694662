//Libs
import { DateTime } from "luxon";

//Local
import { ExistingStiltInvoice } from "../../models/invoice";
import * as strings from "../../strings";
import { convertLuxonDTToFSTimestamp } from "../../utils";
import { ExistingStiltPayment } from "../../models/stilt-payment";
import currencyFormatter from "../../currency";

interface Props {
  invoiceList: ExistingStiltInvoice[];
  paymentsList: ExistingStiltPayment[];
  currency: string;
}

export default function InvoiceDetailsBox(props: Props) {
  return (
    <div className=" flex w-full flex-col gap-4 sm:grid sm:grid-cols-3">
      <div className="flex flex-col rounded-md border px-8 py-4 capitalize">
        <span className="text-lg font-medium text-gray-400">
          {strings.TOTAL_PAYMENT_DUE}
        </span>
        {getOpenInvoiceTotal(props.invoiceList, props.currency)}
      </div>
      <div className="flex flex-col rounded-md border px-8 py-4 capitalize">
        <span className="text-lg font-medium text-gray-400">
          {strings.AMOUNT_PAID_MTD}
        </span>
        {getAmountPaidMTD(props.paymentsList, props.currency)}
      </div>
      <div className="flex flex-col rounded-md border px-8 py-4 capitalize">
        <span className="text-lg font-medium text-gray-400">
          {strings.AMOUNT_PAID_YTD}
        </span>
        {getAmountPaidYTD(props.paymentsList, props.currency)}
      </div>
    </div>
  );
}

function getOpenInvoiceTotal(
  invoiceList: ExistingStiltInvoice[],
  currency: string,
) {
  let totalAmountDue = 0;
  invoiceList
    .filter((i) => i.status !== "canceled")
    .forEach((invoice) => (totalAmountDue += invoice.amountDue));
  return currencyFormatter(totalAmountDue, currency);
}

function getAmountPaidMTD(
  paymentsList: ExistingStiltPayment[],
  currency: string,
) {
  const currentDate = DateTime.now();
  const startOfMonth = currentDate.startOf("month");

  const mtdPayments = paymentsList.filter(
    (payment) =>
      payment.timestampPaymentMade &&
      payment.timestampPaymentMade >=
        convertLuxonDTToFSTimestamp(startOfMonth) &&
      payment.timestampPaymentMade <= convertLuxonDTToFSTimestamp(currentDate),
  );

  let totalAmount = 0;
  mtdPayments.forEach((payment) => (totalAmount += payment.amount));
  return currencyFormatter(totalAmount, currency);
}

function getAmountPaidYTD(
  paymentsList: ExistingStiltPayment[],
  currency: string,
) {
  const currentDate = DateTime.now();
  const startOfYear = currentDate.startOf("year");

  const mtdPayments = paymentsList.filter(
    (payment) =>
      payment.timestampPaymentMade &&
      payment.timestampPaymentMade >=
        convertLuxonDTToFSTimestamp(startOfYear) &&
      payment.timestampPaymentMade <= convertLuxonDTToFSTimestamp(currentDate),
  );

  let totalAmount = 0;
  mtdPayments.forEach((payment) => (totalAmount += payment.amount));
  return currencyFormatter(totalAmount, currency);
}
