//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { logger as devLogger } from "../../logging";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import {
  AddNewPBItemCategory,
  ExistingPriceBookItemCategory,
} from "../../models/price-book-item-category";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  userPermissions: ExistingSiteKeyUserPermissions;
  priceBookItemCategoryDoc: ExistingPriceBookItemCategory | null;
  allowEditingAccountNumbers: boolean;
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSaveNewPBItemCategory: (
    newPBItemCategory: AddNewPBItemCategory,
  ) => Promise<void>;
  handleEditPBItemCategory: (
    updatePBItem: Partial<ExistingPriceBookItemCategory>,
  ) => Promise<void>;
}

export const PBItemCategoryFormSchema = z.object({
  name: z.string(),
  active: z.boolean(),
  account: z.string().min(0).max(200).optional(),
  costOfSaleAccount: z.string().min(0).max(200).optional(),
});

export type PBItemCategoryFormState = z.infer<typeof PBItemCategoryFormSchema>;

export default function AddEditPriceBookItemCategoryDialog({
  priceBookItemCategoryDoc,
  userPermissions,
  closeDialog,
  handleSaveNewPBItemCategory,
  isDialogOpen,
  allowEditingAccountNumbers,
  handleEditPBItemCategory,
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const PBItemCategoryFormDefaultValues: PBItemCategoryFormState =
    useMemo(() => {
      if (priceBookItemCategoryDoc != null) {
        return {
          name: priceBookItemCategoryDoc.name,
          active: priceBookItemCategoryDoc.active,
          account: priceBookItemCategoryDoc.account,
          costOfSaleAccount: priceBookItemCategoryDoc.costOfSaleAccount,
        };
      } else {
        return {
          name: "",
          active: true,
          account: "",
          costOfSaleAccount: "",
        };
      }
    }, [priceBookItemCategoryDoc, userPermissions]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<PBItemCategoryFormState>({
    defaultValues: PBItemCategoryFormDefaultValues,
    resolver: zodResolver(PBItemCategoryFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(PBItemCategoryFormDefaultValues);
  }, [PBItemCategoryFormDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    closeDialog();
  }

  const onSubmit: SubmitHandler<PBItemCategoryFormState> = async (
    formValues,
  ) => {
    if (priceBookItemCategoryDoc != null) {
      const partialPBItemCategoryValue: Partial<ExistingPriceBookItemCategory> =
        {
          name: formValues.name,
          active: formValues.active,
        };

      if (allowEditingAccountNumbers) {
        partialPBItemCategoryValue.account = formValues.account;
        partialPBItemCategoryValue.costOfSaleAccount =
          formValues.costOfSaleAccount;
      }

      try {
        await handleEditPBItemCategory(partialPBItemCategoryValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    } else {
      const partialPBItemCategoryValue: AddNewPBItemCategory = {
        name: formValues.name,
        active: formValues.active,
        customData: {},
      };

      if (allowEditingAccountNumbers) {
        partialPBItemCategoryValue.account = formValues.account;
        partialPBItemCategoryValue.costOfSaleAccount =
          formValues.costOfSaleAccount;
      }

      try {
        await handleSaveNewPBItemCategory(partialPBItemCategoryValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    }
  };

  const priceBookItemCategoryHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {priceBookItemCategoryDoc
          ? strings.EDIT_PRICEBOOKITEM_CATEGORY
          : strings.NEW_CATEGORY}
      </h1>
      <button type="button" onClick={() => closeAndReset()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = priceBookItemCategoryDoc ? (
    <ErrorMessage
      message={strings.ERROR_EDITING_PRICEBOOK_ITEM_CATEGORY}
      clearMessage={() => setDisplayError(false)}
    />
  ) : (
    <ErrorMessage
      message={strings.ERROR_CREATING_PRICEBOOK_ITEM_CATEGORY}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={isDialogOpen}
      title={priceBookItemCategoryHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          {/* Field: Title */}
          <div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Name"
                  inputName="name"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.name?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.name.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Account */}
          {allowEditingAccountNumbers && (
            <div>
              <Controller
                name="account"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Account"
                    inputName="account"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.account?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.account.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
          {/* Field: Cost of Sale Account */}
          {allowEditingAccountNumbers && (
            <div>
              <Controller
                name="costOfSaleAccount"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Cost Of Sale Account"
                    inputName="costOfSaleAccount"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.costOfSaleAccount?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.costOfSaleAccount.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}

          {/* SWITCH GROUP */}
          <div className="grid grid-cols-3 gap-4">
            {/* Field: Taxable */}
            <div>
              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <StyledSwitchGroup
                    readableName="Active?"
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    checked={field.value}
                    id="active"
                    name={field.name}
                  />
                )}
              />
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}
