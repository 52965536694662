// libs

// local
import { ExistingCustomer } from "../../models/customer";
import Breadcrumbs from "../../components/Breadcrumbs";
import { CUSTOMERS_URL } from "../../urls";
import { Fragment } from "react/jsx-runtime";

export interface Props {
  /* DATA */
  customer: ExistingCustomer;
  /* COMPONENTS */
  deleteCustomerButton: React.ReactNode;
  mergeCustomerButton: React.ReactNode;
  customerStatementButton: React.ReactNode;
  customerScheduleButton: React.ReactNode;
  /* CHILDREN */
  children: {
    AddOrEditLocationDialog: (
      customerType: ExistingCustomer["type"],
    ) => React.ReactNode;
    EditCustomerDialog: React.ReactNode;
    RescheduleTaskDialog: React.ReactNode;
    CustomerAndAddressDetails: React.ReactNode;
    CustomerTables: React.ReactNode;
    TableTabs: React.ReactNode;
    HandlePaymentDialog: React.ReactNode;
    CustomerMembershipPills: React.ReactNode;
    CustomerEquipmentPills: React.ReactNode;
    CustomerTagChips: React.ReactNode;
    CustomerNotes: React.ReactNode;
  };
}

export default function ShowSingleCustomerPage({
  customer,
  deleteCustomerButton,
  mergeCustomerButton,
  customerStatementButton,
  customerScheduleButton,
  ...props
}: Props) {
  // For breadcrumbs
  const home = {
    name: "List of All Customers",
    href: CUSTOMERS_URL,
    current: false,
  };
  const pages = [
    {
      name: customer.name,
      href: `${CUSTOMERS_URL}/${customer.id}`,
      current: true,
    },
  ];

  return (
    <Fragment>
      <div className="flex flex-row">
        <div className="flex w-full flex-col pr-4">
          <Breadcrumbs home={home} pages={pages} />
          <div className="my-8 flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between">
            <div className="space-y-2">
              <h1 className="mr-auto text-4xl font-bold capitalize text-primary">
                {customer.name}
              </h1>
              {props.children.CustomerTagChips}
            </div>
            <div className="flex w-fit items-end gap-4">
              {customerScheduleButton}
              {customerStatementButton}
              {mergeCustomerButton}
              {deleteCustomerButton}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {props.children.CustomerMembershipPills}
            {props.children.CustomerEquipmentPills}
          </div>
          <hr className="my-4 block w-full border border-gray-200" />
          <div className="flex flex-col">
            {props.children.CustomerAndAddressDetails}
            <hr className="my-4 block w-full border border-gray-200" />
            {props.children.TableTabs}
            {props.children.CustomerTables}
          </div>
        </div>
        <div className="min-h-full border-l-2 border-gray-200">
          {props.children.CustomerNotes}
        </div>
      </div>
      {props.children.RescheduleTaskDialog}
      {props.children.AddOrEditLocationDialog(customer.type)}
      {props.children.HandlePaymentDialog}
      {props.children.EditCustomerDialog}
    </Fragment>
  );
}
