import { ExistingChecklistResponse } from "../../models/checklist-response";
import ResponseCardFloatType from "../../components/ResponseCardFloatType";
import ResponseCardIntegerType from "../../components/ResponseCardIntegerType";
import ResponseCardSelectionCheckbox from "../../components/ResponseCardSelectionCheckbox";
import ResponseCardSelectionDropdown from "../../components/ResponseCardSelectionDropdown";
import ResponseCardSelectionRadio from "../../components/ResponseCardSelectionRadio";
import ResponseCardStringType from "../../components/ResponseCardStringType";
import ResponseCardIconRow from "../../components/ResponseCardIconRow";
import {
  OnSaveValue,
  HandleViewComments,
  HandleViewHistory,
  HandleViewPhotos,
} from "./types";
import { ExistingChecklistPhoto } from "../../models/checklist-photo";

export function responseCardsContent(args: {
  /**
   * photo documents for all responses for this task.
   */
  photoList: ExistingChecklistPhoto[];
  responseList: ExistingChecklistResponse[];
  onSaveValue: OnSaveValue;
  handleViewHistory: HandleViewHistory;
  handleViewPhotos: HandleViewPhotos;
  handleViewComments: HandleViewComments;
}): (JSX.Element | null)[] {
  return args.responseList.map((response): JSX.Element | null => {
    const filterPhotos = args.photoList.filter((photo) => {
      const photoBelongsToResponse = photo.checklistResponseID === response.id;
      return photoBelongsToResponse;
    });

    const showPhotoNotification = filterPhotos.length > 0;

    if (response.id) {
      const iconRow = (
        <ResponseCardIconRow
          responseComment={response.comments ? response.comments : ""}
          showPhotoNotification={showPhotoNotification}
          onClickHistoryIcon={async () => {
            await args.handleViewHistory(response);
          }}
          onClickCommentIcon={() => args.handleViewComments(response)}
          onClickCameraIcon={() => args.handleViewPhotos(response)}
        />
      );

      return getCardForResponseType({
        key: response.id,
        onSaveValue: args.onSaveValue,
        response: response,
        iconRow: iconRow,
      });
    } else {
      return null;
    }
  });
}

interface ConditionalInputProps {
  key: string;
  response: ExistingChecklistResponse;
  /**
   * use IconRow component
   */
  iconRow: JSX.Element;
  /**
   * What to do when the input value for the response is saved.
   */
  onSaveValue: (
    value: string | number | null,
    responseID: string,
  ) => Promise<void>;
}

/**
 * Determine which set of JSX inputs to render based on the responseType.
 */
function getCardForResponseType(
  args: ConditionalInputProps,
): JSX.Element | null {
  switch (args.response.responseType) {
    case "string": {
      return (
        <ResponseCardStringType
          key={args.response.id}
          response={args.response}
          onSaveValue={args.onSaveValue}
        >
          {args.iconRow}
        </ResponseCardStringType>
      );
    }
    case "selection": {
      if (args.response.selectionOptions !== null) {
        if (args.response.selectionOptions.length === 1) {
          return (
            <ResponseCardSelectionCheckbox
              key={args.key}
              response={args.response}
              onSaveValue={args.onSaveValue}
            >
              {args.iconRow}
            </ResponseCardSelectionCheckbox>
          );
        } else if (args.response.selectionOptions.length === 2) {
          return (
            <ResponseCardSelectionRadio
              key={args.key}
              response={args.response}
              onSaveValue={args.onSaveValue}
            >
              {args.iconRow}
            </ResponseCardSelectionRadio>
          );
        } else {
          return (
            <ResponseCardSelectionDropdown
              key={args.key}
              response={args.response}
              onSaveValue={args.onSaveValue}
            >
              {args.iconRow}
            </ResponseCardSelectionDropdown>
          );
        }
      } else {
        return null;
      }
    }
    case "integer": {
      return (
        <ResponseCardIntegerType
          key={args.key}
          response={args.response}
          onSaveValue={args.onSaveValue}
        >
          {args.iconRow}
        </ResponseCardIntegerType>
      );
    }
    case "float": {
      return (
        <ResponseCardFloatType
          key={args.key}
          response={args.response}
          onSaveValue={args.onSaveValue}
        >
          {args.iconRow}
        </ResponseCardFloatType>
      );
    }
    default:
      const _exhaustiveCheck: never = args.response.responseType;
      return _exhaustiveCheck;
  }
}
