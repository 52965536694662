import { z } from "zod";

// Local
import { clientUIFields, ClientUIFields } from "../../dynamic-fields";
import { guardIsPlainObject, zFallback } from "../../utils";

/**
 * NOTE: ReportSpec does not have its own Firestore collection.
 * It lives within the ReportData documents. That's why there are
 * no Firestore converter functions in ReportSpecManager.
 */

// #region Types
/**
 * ReportSpec (specification) defines the rules for validating a report configuration.
 */
export interface ReportSpec {
  reportName: string;
  description?: string;
  customizations: CustomizationMap;
  configValidationSchema: Record<string, any>;
}

export type CustomizationItem = {
  type: ClientUIFields;
  name: string;
  selectionOptions?: readonly string[];
  selectionOptionsRenamingMap?: { [index: string]: string };
  min?: number;
  max?: number;
  default?: string | number | boolean;
};

export type CustomizationMap = { [index: string]: CustomizationItem };
// #endregion Types

/** Utilities for interacting with ReportSpec objects. */
export const ReportSpecManager = {
  parse: validateReportSpec,
  parseResponse: validateReportSpecResponse,
  parseWithFallbacks: validateReportSpecWithFallbacks,
};

// #region Functions
function validateReportSpec(value: unknown): ReportSpec {
  if (!guardIsPlainObject(value)) {
    throw new Error(`reportSpec not an object: ${value}`);
  }
  return reportSpecSchema.parse(value);
}

function validateReportSpecResponse(
  value: unknown,
): Record<string, ReportSpec> {
  if (!guardIsPlainObject(value)) {
    throw new Error(`reportSpec not an object: ${value}`);
  }
  return reportSpecResponseSchema.parse(value);
}

function validateReportSpecWithFallbacks(value: unknown): ReportSpec {
  if (!guardIsPlainObject(value)) {
    throw new Error(`reportSpec not an object: ${value}`);
  }
  return reportSpecSchemaWithFallbacks.parse(value);
}
// #endregion Functions

// #region Schemas
const customizationItemSchema = z.object({
  type: z.enum(clientUIFields),
  name: z.string().min(1).max(200),
  selectionOptions: z.array(z.string()).optional(),
  selectionOptionsRenamingMap: z.record(z.string()).optional(),
  min: z.number().optional(),
  max: z.number().optional(),
  default: z.union([z.string(), z.number(), z.boolean()]).optional(),
});

const customizationItemSchemaWithFallbacks = z.object({
  type: z.enum(clientUIFields),
  name: zFallback(z.string(), "Unknown", "customizationItem.name"),
  selectionOptions: zFallback(
    z.array(z.string()).optional(),
    [],
    "customizationItem.selectionOptions",
  ),
  selectionOptionsRenamingMap: zFallback(
    z.record(z.string()).optional(),
    {},
    "customizationItem.selectionOptionsRenamingMap",
  ),
  min: zFallback(z.number().optional(), undefined, "customizationItem.min"),
  max: zFallback(z.number().optional(), undefined, "customizationItem.max"),
  default: zFallback(
    z.union([z.string(), z.number(), z.boolean()]).optional(),
    undefined,
    "customizationItem.default",
  ),
});

export const reportSpecSchema = z.object({
  // exported for use in report-data.ts
  reportName: z.string().min(1).max(200),
  description: z.string().min(1).max(2000).optional(),
  customizations: z.record(customizationItemSchema),
  configValidationSchema: z.record(z.any()),
});

const reportSpecResponseSchema = z.record(z.string(), reportSpecSchema);

export const reportSpecSchemaWithFallbacks = z.object({
  // exported for use in report-data.ts
  reportName: zFallback(
    z.string().min(1).max(200),
    "Unknown",
    "reportSpec.reportName",
  ),
  description: zFallback(
    z.string().min(1).max(2000).optional(),
    undefined,
    "reportSpec.description",
  ),
  customizations: zFallback(
    z.record(customizationItemSchemaWithFallbacks),
    {},
    "reportSpec.customizations",
  ),
  configValidationSchema: zFallback(
    z.record(z.any()),
    {},
    "reportSpec.configValidationSchema",
  ),
});

// #endregion Schemas
