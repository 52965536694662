// Local
import StyledMessage from "./StyledMessage";

export function ErrorMessage(props: {
  clearMessage: () => void;
  message: string;
}): JSX.Element {
  return (
    <div className="mx-auto inline-block">
      <StyledMessage type="error" dismissible onDismiss={props.clearMessage}>
        {{ message: props.message }}
      </StyledMessage>
    </div>
  );
}
