//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import { CustomerLocation } from "../../models/customer-location";
import BaseModal from "../BaseModal";
import { BillingInfo } from "../../models/customer";
import EditBillingInfoWithAutocompleteForm from "./EditBillingInfoWithAutocompleteForm";
import EditBillingInfoManuallyForm from "./EditBillingInfoManuallyForm";

export type TemporaryLocation = Omit<
  CustomerLocation,
  | "customerID"
  | "timestampCreated"
  | "timestampLastModified"
  | "createdBy"
  | "lastModifiedBy"
>;

interface Props {
  /* DATA */
  billingInfo: BillingInfo;
  isDialogOpen: boolean;
  isSubmitting: boolean;
  geocoderResult?: google.maps.GeocoderResult;
  showManualEntryForm: boolean;
  /* FUNCTIONS */
  closeDialog: () => void;
  handleUpdateBillingInfo: (billingInfo: BillingInfo) => void;
  /* CHILDREN */
  children: {
    AddressField: React.ReactNode;
  };
}

export default function EditBillingInfoDialog(props: Props) {
  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Billing Info
      </h1>
      <button type="button" onClick={props.closeDialog}>
        <XMarkIcon
          aria-label="close new attribute form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={() => {}}
      open={props.isDialogOpen}
      title={header}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="space-y-8 p-4">
        {props.showManualEntryForm ? (
          <EditBillingInfoManuallyForm
            isSubmitting={props.isSubmitting}
            handleUpdateBillingInfo={props.handleUpdateBillingInfo}
            billingInfo={props.billingInfo}
            setEditBillingInfoFormOpen={props.closeDialog}
          />
        ) : (
          <EditBillingInfoWithAutocompleteForm
            billingInfo={props.billingInfo}
            handleUpdateBillingInfo={props.handleUpdateBillingInfo}
            geocoderResult={props.geocoderResult}
            isSubmitting={props.isSubmitting}
            setEditBillingInfoFormOpen={props.closeDialog}
          >
            {{
              AddressField: props.children.AddressField,
            }}
          </EditBillingInfoWithAutocompleteForm>
        )}
      </div>
    </BaseModal>
  );
}
