//Libs
import {
  ColDef,
  GetQuickFilterTextParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  Dispatch,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Timestamp } from "firebase/firestore";

//Local
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import isEqual from "lodash/isEqual";
import {
  ExistingStiltInvoice,
  getReadableStiltInvoiceStatus,
  StiltInvoiceStatusValues,
} from "../../models/invoice";
import { getTimeDifferenceInDays } from "../../utils/getTimeDifferenceInDays";
import { StyledTooltip } from "../StyledTooltip";
import { PaymentIconWithSpinner } from "../PaymentButton";
import EmailButtonWithSpinner from "../EmailButtonWithSpinner";
import { PDFIconWithSpinner } from "../PDFButton";
import currencyFormatter from "../../currency";
import InvoiceStatusCustomFilter from "../tables/InvoiceStatusCustomFilter";
import DropdownSelectionInvoiceList from "../Invoices/DropdownSelectionInvoiceList";
import { InvoiceActionTypes } from "../../Pages/Invoices/InvoiceListPage";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import SiteKeyLocationCustomFilter from "../tables/SiteKeyLocationCustomFilter";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import InvoiceActionDropdown from "../Invoices/InvoiceActionDropdown";

interface Props {
  invoiceList: ExistingStiltInvoice[];
  currency: string;
  openHandlePaymentDialog: (invoiceID: ExistingStiltInvoice["id"]) => void;
  emailReceipt: (invoiceID: ExistingStiltInvoice["id"]) => void;
  getPDF: (invoiceID: ExistingStiltInvoice["id"]) => Promise<void>;
  openInvoiceDialog: (invoiceID: string) => void;
  actionsLoading: boolean;
  invoiceActionSelected: (
    actionType: InvoiceActionTypes,
    invoiceIDs: ExistingStiltInvoice[],
  ) => void;
  setStiltInvoiceStatusSelection: Dispatch<
    React.SetStateAction<StiltInvoiceStatusValues | null>
  >;
  invoiceStatus: (StiltInvoiceStatusValues | null)[];
  siteKeyLocationList: ExistingSiteKeyLocation[];
  getLocationTitle: (id: string) => string;
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  width: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

export default function CustomerInvoiceListTablePage({
  actionsLoading,
  currency,
  emailReceipt,
  getPDF,
  openHandlePaymentDialog,
  openInvoiceDialog,
  invoiceActionSelected,
  invoiceList,
  invoiceStatus,
  setStiltInvoiceStatusSelection,
  siteKeyLocationList,
  getLocationTitle,
}: Props) {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      const api: GridApi = gridRef.current.api;
      api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  function rowSelectionChanged() {
    setSelectedRows(getSelectedRowData());
  }

  function getSelectedRowData() {
    const selectedData = gridRef.current.api?.getSelectedRows();
    return selectedData;
  }

  return (
    <div className="ag-theme-alpine flex h-[300px] flex-col">
      <div className="mt-2 flex items-center justify-between gap-2 lg:mt-0">
        <InvoiceActionDropdown
          actionsLoading={actionsLoading}
          invoiceActionSelected={invoiceActionSelected}
          selectedRows={selectedRows}
        />
        <DropdownSelectionInvoiceList
          onSelectionStatus={(invoiceStatus) =>
            setStiltInvoiceStatusSelection(invoiceStatus)
          }
          stiltInvoiceStatus={invoiceStatus}
        />
      </div>
      <CustomerInvoiceListTable
        invoiceList={invoiceList.sort(
          (a, b) =>
            b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
        )}
        openHandlePaymentDialog={openHandlePaymentDialog}
        emailReceipt={emailReceipt}
        getPDF={getPDF}
        openInvoiceDialog={openInvoiceDialog}
        currency={currency}
        rowSelectionChanged={rowSelectionChanged}
        gridRef={gridRef}
        onGridReady={onGridReady}
        siteKeyLocationList={siteKeyLocationList}
        getLocationTitle={getLocationTitle}
      />
    </div>
  );
}

interface CustomerInvoiceListTableProps {
  invoiceList: ExistingStiltInvoice[];
  currency: string;
  openHandlePaymentDialog: (invoiceID: ExistingStiltInvoice["id"]) => void;
  emailReceipt: (invoiceID: ExistingStiltInvoice["id"]) => void;
  getPDF: (invoiceID: ExistingStiltInvoice["id"]) => Promise<void>;
  openInvoiceDialog: (invoiceID: string) => void;
  rowSelectionChanged: (event: GridReadyEvent) => void;
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
  siteKeyLocationList: Props["siteKeyLocationList"];
  getLocationTitle: Props["getLocationTitle"];
}

const CustomerInvoiceListTable = memo(
  ({
    invoiceList,
    openHandlePaymentDialog,
    emailReceipt,
    getPDF,
    openInvoiceDialog,
    currency,
    rowSelectionChanged,
    gridRef,
    onGridReady,
    siteKeyLocationList,
    getLocationTitle,
  }: CustomerInvoiceListTableProps) => {
    const renderTimestampIssueDate = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return <div>{convertToReadableTimestamp(params.value)}</div>;
    };

    const renderTimestampDueDate = (
      params: ICellRendererParams,
    ): JSX.Element => {
      if (params.data.amountDue === 0 || params.data.status === "paid") {
        return <div></div>;
      } else {
        if (params.data.dueDate == null) {
          return <div>0</div>;
        } else {
          const startDate: Timestamp = params.value;
          const endDate = Timestamp.now();

          //ACTION
          const daysDifference = getTimeDifferenceInDays({
            startDate,
            endDate,
          });
          return <div>{daysDifference}</div>;
        }
      }
    };

    const renderName = (params: ICellRendererParams): JSX.Element => (
      <div>
        {params.data.firstName} {params.data.lastName}
      </div>
    );

    const renderInvoiceNumber = (params: ICellRendererParams): JSX.Element => (
      <div>{params.data.invoiceNumber ?? ""}</div>
    );

    const renderPoNumber = (params: ICellRendererParams): JSX.Element => (
      <div>{params.data.poNumber ?? ""}</div>
    );

    const renderStatus = (params: ICellRendererParams): JSX.Element => {
      let customBg = "bg-primary text-primaryButtonText";
      switch (params.data.status) {
        case "paid":
          customBg = "bg-greenPass text-white";
          break;
        case "partiallyPaid":
          customBg = "bg-orangeError text-white";
          break;
        case "canceled":
          customBg = "bg-gray-200 text-black";
          break;
        case "draft":
          customBg = "bg-gray-200 text-black";
          break;
      }

      return (
        <div className="flex items-center">
          <div
            className={`m-1 rounded-full px-2.5 pb-1 pt-0.5 text-xs font-medium ${customBg} capitalize`}
          >
            {getReadableStiltInvoiceStatus(params.data.status)}
          </div>
        </div>
      );
    };

    const renderTotalAmount = (params: ICellRendererParams): JSX.Element => {
      return (
        <div
          className={params.data.status === "canceled" ? "line-through" : ""}
        >
          {currencyFormatter(params.data.totalAmount, currency)}
        </div>
      );
    };

    const renderAmountDue = (params: ICellRendererParams): JSX.Element => {
      return (
        <div
          className={params.data.status === "canceled" ? "line-through" : ""}
        >
          {currencyFormatter(params.data.amountDue, currency)}
        </div>
      );
    };

    // TODO: loading state, handle failures
    const renderIconCell = useCallback(
      function (params: ICellRendererParams) {
        return (
          <div className="flex items-center">
            {params.data.status !== "paid" ? (
              <div className="flex items-center">
                <StyledTooltip title="Handle Payment">
                  <PaymentIconWithSpinner
                    onCreate={async () =>
                      openHandlePaymentDialog(params.data.id)
                    }
                  />
                </StyledTooltip>
              </div>
            ) : (
              <div className="flex items-center">
                <StyledTooltip title="Send Receipt">
                  <EmailButtonWithSpinner
                    onClick={async () => await emailReceipt(params.data.id)}
                  />
                </StyledTooltip>
              </div>
            )}
            <StyledTooltip title="Download PDF">
              <PDFIconWithSpinner
                onCreate={async () => getPDF(params.data.id)}
              />
            </StyledTooltip>
          </div>
        );
      },
      [openHandlePaymentDialog, emailReceipt, getPDF],
    );

    const renderSiteKeyLocation = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return <div>{getLocationTitle(params.data.locationID)}</div>;
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Invoice Number",
        field: "invoiceNumber",
        cellRenderer: renderInvoiceNumber,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.data.invoiceNumber ?? +" ";
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        onCellClicked: (ev) => openInvoiceDialog(ev.data.id),
      },
      {
        headerName: "Customer Name",
        cellRenderer: renderName,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (ev) => openInvoiceDialog(ev.data.id),
      },
      {
        field: "amountDue",
        headerName: "Amount Due",
        cellRenderer: renderAmountDue,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (ev) => openInvoiceDialog(ev.data.id),
      },
      {
        headerName: "Total Amount",
        field: "totalAmount",
        cellRenderer: renderTotalAmount,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (ev) => openInvoiceDialog(ev.data.id),
      },
      {
        headerName: "Status",
        field: "status",
        filter: InvoiceStatusCustomFilter,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        cellRenderer: renderStatus,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        onCellClicked: (ev) => openInvoiceDialog(ev.data.id),
      },
      {
        headerName: "Issue Date",
        field: "issueDate",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        cellRenderer: renderTimestampIssueDate,
        onCellClicked: (ev) => openInvoiceDialog(ev.data.id),
      },
      {
        headerName: "PO Number",
        field: "poNumber",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        cellRenderer: renderPoNumber,
        onCellClicked: (ev) => openInvoiceDialog(ev.data.id),
      },
      {
        headerName: "Days Overdue",
        field: "dueDate",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        cellRenderer: renderTimestampDueDate,
        onCellClicked: (ev) => openInvoiceDialog(ev.data.id),
      },
      {
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        flex: 0,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
    ];

    if (siteKeyLocationList.length > 1) {
      columnDefs.splice(columnDefs.length - 1, 0, {
        field: "locationID",
        headerName: "Site Location",
        cellRenderer: renderSiteKeyLocation,
        filter: SiteKeyLocationCustomFilter,
      });
    }

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="mt-5 shadow"
        rowData={invoiceList}
        animateRows={true}
        rowSelection="multiple"
        columnDefs={columnDefs}
        onSelectionChanged={(event) => {
          rowSelectionChanged(event);
        }}
      />
    );
  },
  (previous, next) => {
    const isInvoiceListTheSame = isEqual(
      previous.invoiceList,
      next.invoiceList,
    );

    return isInvoiceListTheSame;
  },
);
