//Libs

//Local
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ExistingTask } from "../../models/task";
import placeholder_avatar from "../../images/placeholder_avatar.svg";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";

interface Props {
  /* DATA */
  taskDoc: ExistingTask;
  displayName: string;
  /* FUNCTIONS */
  goToViewTask?: () => void;
}

export default function TaskDetailsInfoForEstimate({
  taskDoc,
  displayName,
  goToViewTask,
}: Props) {
  return (
    <div className="grid grid-cols-1 space-y-4 rounded-md bg-gray-50 p-4 shadow-md lg:grid-cols-[2fr,1fr] lg:space-y-0">
      <div className="flex flex-col justify-between">
        <div className="text-lg font-medium text-blue-400">
          This Estimate was created for the following job:
        </div>
        <div className="text-xl font-bold text-gray-700">{taskDoc.title}</div>
        <div className="hidden sm:mt-4 sm:flex sm:items-center">
          <img
            src={placeholder_avatar}
            alt="User avatar"
            className="h-10 w-10 rounded-full"
          />
          <div className="flex flex-col px-3 py-2 text-sm">
            <div>
              Submitted by{" "}
              <span className="font-medium text-blue-400">{displayName}</span>
            </div>
            <span>
              {taskDoc.timestampTaskCompleted !== null
                ? convertToReadableTimestamp(taskDoc.timestampTaskCompleted)
                : "--"}
            </span>
          </div>
        </div>
      </div>
      <div className="mr-4 space-y-4 sm:justify-self-end">
        <div className="flex flex-col leading-4">
          <span className="font-medium text-gray-700 sm:font-normal sm:text-gray-400">
            {" "}
            Scheduled:{" "}
          </span>
          <span className="text-gray-700">
            {" "}
            {taskDoc.timestampScheduled
              ? convertToReadableTimestamp(taskDoc.timestampScheduled)
              : "--"}{" "}
          </span>
        </div>
        <div className="flex flex-col leading-4">
          <span className="font-medium text-gray-700 sm:font-normal sm:text-gray-400">
            {" "}
            Started:{" "}
          </span>
          <span className="text-gray-700">
            {" "}
            {taskDoc.timestampTaskStarted
              ? convertToReadableTimestamp(taskDoc.timestampTaskStarted)
              : "--"}{" "}
          </span>
        </div>
        <div className="hidden flex-col leading-4">
          <span className="text-gray-400"> Submitted: </span>
          <span className="text-gray-700">
            {" "}
            {taskDoc.timestampTaskStarted
              ? convertToReadableTimestamp(taskDoc.timestampTaskStarted)
              : "--"}{" "}
          </span>
        </div>
        <div className="hidden flex-col leading-4">
          <span className="text-gray-400"> Approved: </span>
          <span className="text-gray-700">
            {" "}
            {taskDoc.timestampTaskStarted
              ? convertToReadableTimestamp(taskDoc.timestampTaskStarted)
              : "--"}{" "}
          </span>
        </div>
        {goToViewTask ? (
          <BaseButtonPrimary className="w-full max-w-xs" onClick={goToViewTask}>
            {strings.buttons.VIEW_TASK}
          </BaseButtonPrimary>
        ) : null}
      </div>
    </div>
  );
}
