//Libs
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import BaseButtonSecondary from "./BaseButtonSecondary";
import BaseButtonPrimary from "./BaseButtonPrimary";
import BaseInputTextArea from "./BaseInputTextArea";
import { logger as devLogger } from "../logging";
import { ExistingChecklistResponse } from "../models/checklist-response";
import BaseModal from "./BaseModal";
import * as strings from "../strings";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  response: ExistingChecklistResponse;
  /**
   * A async function typically used to save the comment to the database when
   * the submit button is clicked.
   */
  onSaveComment: (
    value: string,
    response: ExistingChecklistResponse,
  ) => Promise<void>;
}

export default function ResponseCardCommentDialog({
  isDialogOpen,
  closeDialog,
  response,
  onSaveComment,
}: Props) {
  const ResponseCommentSchema = z.object({
    responseComment: z.string().max(200),
  });
  type ResponseCommentState = z.infer<typeof ResponseCommentSchema>;

  const { register, handleSubmit, reset, formState, getFieldState } =
    useForm<ResponseCommentState>({
      defaultValues: { responseComment: response.comments },
      resolver: zodResolver(ResponseCommentSchema),
      mode: "onChange",
    });

  const { isSubmitting } = formState;

  async function onSubmit(data: ResponseCommentState) {
    if (response.id == null) {
      devLogger.error(`ResponseID was not found. Value: ${response.id}`);
      return;
    }

    // Check if the field has been modified.
    const commentState = getFieldState("responseComment", formState);
    const hasCommentChanged = commentState.isDirty;

    if (hasCommentChanged === false) {
      devLogger.debug("The comment hasn't changed");
      return;
    }

    // some logic/function we pass into this component goes here.
    await onSaveComment(data.responseComment, response);
    devLogger.debug("Comment has successfully updated!");

    closeDialog();
  }

  useEffect(() => {
    reset({ responseComment: response.comments });
  }, [reset, response.comments, isDialogOpen]);

  const commentTouched = getFieldState("responseComment", formState).isTouched;
  const commentChanged = getFieldState("responseComment", formState).isDirty;

  return (
    <BaseModal
      open={isDialogOpen}
      closeModal={closeDialog}
      title={
        <div className="relative right-4 -mt-4 w-64 bg-primary px-4 py-2 text-left text-white sm:w-80">
          Add Comments
        </div>
      }
      parentDivStyles="text-left w-64 sm:w-80 p-4"
    >
      <div className="flex h-full flex-col justify-between">
        <p className="mt-2 text-left text-sm font-semibold text-black ">
          Checklist Item:
        </p>
        <p className="text-left text-xs text-gray-800">{response.mainText}</p>

        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <BaseInputTextArea
            admin={false}
            data-testid="testBaseInputTextArea"
            className="mt-3 max-w-lg"
            rows={4}
            {...register("responseComment", {})}
          />
          <div className="mt-2 text-xs text-red-400">
            {!commentChanged && commentTouched
              ? "The comment hasn't changed"
              : ""}
          </div>

          {/* buttons */}
          <div className="mt-4 flex w-full items-center justify-between gap-4">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={() => {
                closeDialog();
              }}
            >
              {strings.buttons.CLOSE}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SUBMITTING}
              type="submit"
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SUBMIT}
            </BaseButtonPrimary>
          </div>
        </form>
      </div>
    </BaseModal>
  );
}
