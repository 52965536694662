//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { QRCodeSVG } from "qrcode.react";

//Local
import BaseModal from "../BaseModal";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";

interface Props {
  passphrase: string | null;
  isDialogOpen: boolean;
  closeDialog: () => void;
  onAppDownload: (platform: "ios" | "android") => void;
  downloadLink: string | null;
  isDownloadLinkError: boolean;
  isAndroidSubmitting: boolean;
  isIosSubmitting: boolean;
}

export default function DisplayNewUserPassphraseDialog(props: Props) {
  const userPassphraseHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Temporary password for the new user
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close password dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={userPassphraseHeader}
      parentDivStyles="max-w-lg inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <>
        <h3 className="px-6 py-4">
          Please save this password. It has also been emailed to the new user.
        </h3>
        <p className="px-6 pb-6 font-semibold text-blue-600">
          {props.passphrase}
        </p>

        <div>
          {!props.downloadLink ? (
            <>
              <h3 className="px-6 py-4">
                Get a QR code to download the mobile app on iOS or Android:
              </h3>
              <div className="flex items-center gap-4 px-6 pb-8">
                <BaseButtonPrimary
                  onClick={() => props.onAppDownload("ios")}
                  type="button"
                  className="w-full"
                  disabled={props.isAndroidSubmitting}
                  isBusy={props.isIosSubmitting}
                  busyText={strings.buttons.BUSY_SUBMITTING}
                >
                  iOS App
                </BaseButtonPrimary>
                <BaseButtonPrimary
                  onClick={() => props.onAppDownload("android")}
                  type="button"
                  className="w-full"
                  disabled={props.isIosSubmitting}
                  isBusy={props.isAndroidSubmitting}
                  busyText={strings.buttons.BUSY_SUBMITTING}
                >
                  Android App
                </BaseButtonPrimary>
              </div>
              {props.isDownloadLinkError && (
                <p className="px-6 pb-8 text-red-600">
                  We're sorry, but we couldn't process your request at this
                  time. Please try again later. If the problem persists, contact
                  our support team for assistance.
                </p>
              )}
            </>
          ) : (
            <>
              <h3 className="flex justify-center px-6 pt-4 text-lg font-semibold">
                Scan with your camera to download the mobile app:
              </h3>
              <div className="mb-6 flex justify-center">
                <QRCodeSVG
                  value={props.downloadLink}
                  size={256}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={true}
                />
              </div>
            </>
          )}
        </div>
      </>
    </BaseModal>
  );
}
