//Libs
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import { ExistingChecklistResponse } from "../models/checklist-response";
import ResponseCardBase, { ResponseCardBaseProps } from "./ResponseCardBase";
import ResponseRadioSelect from "./ResponseRadioSelect";
import { logger as devLogger } from "../logging";

interface Props {
  response: ExistingChecklistResponse;
  onSaveValue: (value: string | null, responseID: string) => Promise<void>;
  /**
   * Accepts IconRow as children.
   */
  children: React.ReactNode;
}

export default function ResponseCardSelectionRadio(
  props: Props & Omit<ResponseCardBaseProps, "children">,
): JSX.Element {
  const ResponseSelectionSchema = z.object({
    responseSelectionValue: z.string().max(200),
  });
  type ResponseSelectionState = z.infer<typeof ResponseSelectionSchema>;

  async function onSubmit(data: ResponseSelectionState) {
    if (props.response.id == null) {
      devLogger.error(`ResponseID was not found. Value: ${props.response.id}`);
      return;
    }

    if (data.responseSelectionValue === props.response.responseValue) {
      devLogger.debug("The value hasn't changed");
      return;
    }

    await props.onSaveValue(data.responseSelectionValue, props.response.id);
    devLogger.debug("Response value was successfully recorded");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResponseSelectionState>({
    defaultValues: {
      responseSelectionValue:
        typeof props.response.responseValue === "string"
          ? props.response.responseValue
          : "",
    },
    resolver: zodResolver(ResponseSelectionSchema),
  });

  function pOptionIsInSOptions(sOption: string): boolean {
    let pOptionIsInSOptions = false;
    if (props.response.passingOptions !== null) {
      pOptionIsInSOptions = props.response.passingOptions.includes(sOption);
    }
    return pOptionIsInSOptions;
  }

  const inputForm = (
    <form autoComplete="off" onBlur={handleSubmit(onSubmit)}>
      <div
        id={props.response.id}
        className="my-7 grid w-full grid-cols-2 sm:w-72"
      >
        {props.response.selectionOptions?.map((item) => (
          <ResponseRadioSelect
            key={item}
            id={item}
            label={item}
            pOptionIsInSOptions={pOptionIsInSOptions(item)}
            {...register("responseSelectionValue")}
          />
        ))}
      </div>
      {errors.responseSelectionValue?.message && (
        <div className="ml-1 mt-2 text-xs text-red-400">
          {errors.responseSelectionValue?.message}
        </div>
      )}
    </form>
  );

  return (
    <ResponseCardBase response={props.response}>
      {{
        input: inputForm,
        iconRow: props.children,
      }}
    </ResponseCardBase>
  );
}
