//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { InventoryTransaction } from "../../models/inventory-transaction";

// Styles

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  /* FUNCTIONS */
  closeDialog: () => void;
  setSelectInventoryObjectDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setInventoryTransactionList: React.Dispatch<
    React.SetStateAction<InventoryTransaction[]>
  >;
  children: {
    SelectInventoryObject: React.ReactNode;
    InventoryTransactionCardsSection: React.ReactNode;
    ActionButtons: React.ReactNode;
  };
}

export default function AddInventoryTransactionDialog({
  closeDialog,
  isDialogOpen,
  setSelectInventoryObjectDialogOpen,
  setInventoryTransactionList,
  ...props
}: Props) {
  function resetAndClose() {
    closeDialog();
    setInventoryTransactionList([]);
  }

  const inventoryTransactionHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold">
        {strings.NEW_INVENTORY_TRANSACTION}
      </h1>
      <button type="button" onClick={closeDialog}>
        <XMarkIcon
          aria-label="close location form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={() => resetAndClose()}
      open={isDialogOpen}
      title={inventoryTransactionHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-lg rounded-lg bg-white text-left align-middle shadow-xl transition-all overflow-visible"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        {/* SELECT INVENTORY OBJECT */}
        <div className="flex items-center justify-between">
          <div className="mr-4 pr-2 text-2xl capitalize text-primary">
            {strings.SELECT_INVENTORY_OBJECT}
          </div>
          <button
            type="button"
            onClick={() => setSelectInventoryObjectDialogOpen(true)}
            className="text-primary"
          >
            <AddBoxIcon
              aria-label="Select inventory object"
              sx={{ fontSize: 45 }}
            />
          </button>
        </div>

        {props.children.InventoryTransactionCardsSection}

        {/* ACTION BUTTONS */}
        {props.children.ActionButtons}
        {props.children.SelectInventoryObject}
      </div>
    </BaseModal>
  );
}
