//Libs
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { RangeValue } from "@react-types/shared";
import {
  CalendarDate,
  ZonedDateTime,
  CalendarDateTime,
} from "@internationalized/date";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  RowClickedEvent,
  ValueGetterFunc,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

//Local
import SearchBox from "../../components/SearchBox";
import * as strings from "../../strings";
import {
  ExistingStiltPayment,
  getReadablePaymentMethod,
} from "../../models/stilt-payment";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import currencyFormatter from "../../currency";
import { ExistingStiltInvoice } from "../../models/invoice";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { getUserName } from "../CraftList/WorkRecordListPage";
import UsersCustomFilter from "../../components/tables/UsersCustomFilter";

interface Props {
  /* DATA */
  paymentList: ExistingStiltPayment[];
  invoiceList: ExistingStiltInvoice[];
  dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  isGetPayments: boolean;
  currency: string;
  userDisplayNamesMap: Record<string, string>;
  openInvoiceDialog: (invoiceID: ExistingStiltPayment["invoiceID"]) => void;
  children: {
    PaymentDateRangePicker: React.ReactNode;
    // TransactionReportDownload: React.ReactNode;
  };
}

export default function PaymentListPage(props: Props) {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);
  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value,
    );
  }, []);

  const formattedTimestampPaymentMade = (
    params: ICellRendererParams,
  ): JSX.Element => {
    return (
      <div className="capitalize">
        {params.data.timestampPaymentMade
          ? convertToReadableTimestamp(params.data.timestampPaymentMade)
          : ""}
      </div>
    );
  };

  const renderInvoiceNumber = (params: ICellRendererParams): JSX.Element => {
    const invoiceDoc = props.invoiceList.find(
      (invoice) => invoice.id === params.data.invoiceID,
    );
    return <div className="capitalize">{invoiceDoc?.invoiceNumber ?? ""}</div>;
  };

  const renderCustomerName = (params: ICellRendererParams): JSX.Element => {
    const invoiceDoc = props.invoiceList.find(
      (invoice) => invoice.id === params.data.invoiceID,
    );
    return <div className="capitalize">{invoiceDoc?.name ?? ""}</div>;
  };

  const renderAmount = (params: ICellRendererParams): JSX.Element => {
    return <div>{currencyFormatter(params.data.amount, props.currency)}</div>;
  };
  const formattedPaymentMethod = (params: ICellRendererParams): JSX.Element => {
    return (
      <div className="capitalize">
        {getReadablePaymentMethod(params.data.paymentMethod)}
      </div>
    );
  };

  const renderUserName: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const displayName = props.userDisplayNamesMap[params.data.createdBy];
      return displayName;
    },
    [props.userDisplayNamesMap],
  );

  return (
    <div className="space-y-8">
      <h1 className="text-5xl font-semibold text-primary">
        {strings.PAYMENTS}
      </h1>
      <div className={"ag-theme-alpine isolate h-[600px] pb-14"}>
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full sm:max-w-sm"
          />
          <div className="w-full"></div>
          {props.children.PaymentDateRangePicker}
          {/*{props.children.TransactionReportDownload}*/}
        </div>
        {props.isGetPayments === true ? (
          <div className="flex h-full flex-col items-center justify-center">
            <LoadingClipboardAnimation />
          </div>
        ) : (
          <PaymentListTable
            paymentList={props.paymentList}
            gridRef={gridRef}
            onGridReady={onGridReady}
            formattedTimestampPaymentMade={formattedTimestampPaymentMade}
            renderAmount={renderAmount}
            formattedPaymentMethod={formattedPaymentMethod}
            renderInvoiceNumber={renderInvoiceNumber}
            renderCustomerName={renderCustomerName}
            openInvoiceDialog={props.openInvoiceDialog}
            userDisplayNamesMap={props.userDisplayNamesMap}
            renderUserName={renderUserName}
          />
        )}
      </div>
    </div>
  );
}

/* Stuff for Table Component */
const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface PaymentListTableProps {
  paymentList: Props["paymentList"];
  gridRef: React.MutableRefObject<any>;
  userDisplayNamesMap: Props["userDisplayNamesMap"];
  onGridReady: (event: GridReadyEvent) => void;
  formattedTimestampPaymentMade: (params: ICellRendererParams) => JSX.Element;
  renderAmount: (params: ICellRendererParams) => JSX.Element;
  formattedPaymentMethod: (params: ICellRendererParams) => JSX.Element;
  renderInvoiceNumber: (params: ICellRendererParams) => JSX.Element;
  renderCustomerName: (params: ICellRendererParams) => JSX.Element;
  openInvoiceDialog: Props["openInvoiceDialog"];
  renderUserName: ValueGetterFunc<any, any>;
}

/* TABLE COMPONENT */
const PaymentListTable = React.memo(
  ({
    paymentList,
    gridRef,
    userDisplayNamesMap,
    onGridReady,
    formattedTimestampPaymentMade,
    renderAmount,
    formattedPaymentMethod,
    renderInvoiceNumber,
    renderCustomerName,
    openInvoiceDialog,
    renderUserName,
  }: PaymentListTableProps) => {
    const columnDefs: ColDef[] = [
      {
        headerName: "Payment Made",
        field: "timestampPaymentMade",
        cellRenderer: formattedTimestampPaymentMade,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Invoice number",
        field: "invoiceNumber",
        cellRenderer: renderInvoiceNumber,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Amount",
        field: "amount",
        cellRenderer: renderAmount,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Customer Name",
        field: "name",
        cellRenderer: renderCustomerName,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Payment Method",
        field: "paymentMethod",
        cellRenderer: formattedPaymentMethod,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },

      {
        headerName: "Memo",
        field: "memo",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Check #",
        field: "checkNumber",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "createdBy",
        filter: UsersCustomFilter,
        headerName: "Created By",
        cellRenderer: renderUserName,
        tooltipValueGetter: (params) =>
          getUserName(userDisplayNamesMap, params.data.createdBy),
      },
    ];

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="-z-10 mt-5 shadow"
        rowData={paymentList}
        animateRows={true}
        rowSelection="single"
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        onRowClicked={(event: RowClickedEvent) =>
          openInvoiceDialog(event.data.invoiceID)
        }
      />
    );
  },
);
