// Libs
import { useNavigate } from "react-router-dom";
import RADateRangePicker from "../components/DateRangePicker/RADateRangePicker";
import { useEffect, useRef, useState } from "react";
import {
  CalendarDate,
  CalendarDateTime,
  getLocalTimeZone,
  toCalendarDate,
  today,
  ZonedDateTime,
} from "@internationalized/date";
import { RangeValue } from "@react-types/shared";

// Local
import { DbRead } from "../database";
import { logger } from "../logging";
import AllChecklistsTasksPage from "./AllChecklistsTasksPage";
import { ExistingTask } from "../models/task";
import LoadingClipboardAnimation from "../components/LoadingClipBoardAnimation";
import { TASK_DETAILS_URL } from "../urls";
import { ExistingSiteKey } from "../models/site-key";
import { useUserPermissionsStore } from "../store/user-permissions";

interface Props {
  siteKey: ExistingSiteKey;
}

export default function AllChecklistsTasksContainer({ siteKey }: Props) {
  const isMounted = useRef(true);

  const navigate = useNavigate();

  function viewResponses(taskID: string): void {
    navigate(`${TASK_DETAILS_URL}/${taskID}`);
  }

  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const [taskList, setTaskList] = useState<ExistingTask[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // State for checklistTasksDateRangePicker, defaults to last 30 days
  const [range, setRange] = useState<RangeValue<CalendarDate>>({
    start: today(getLocalTimeZone()).subtract({ days: 30 }),
    end: today(getLocalTimeZone()),
  });

  // Fetch the list of checklist tasks when this component loads.
  useEffect(() => {
    async function getChecklistTasks() {
      //typeguard
      if (!userPermissions) {
        logger.debug("No userPermissions yet...");
        return undefined;
      }

      logger.debug("getChecklistTasks useEffect called ");

      // Start loading.
      if (isMounted.current) setIsLoading(true);

      // Query checklist tasks via realtime updates. Set the list of tasks.
      const unsubscribeAllTasksInDateRange =
        DbRead.tasks.subscribeAllTasksInDateRange({
          siteKey: siteKey.id,
          permissions: userPermissions,
          dateRange: range,
          onChange: setTaskList,
          onError: (error) =>
            logger.error(`Error in getChecklistTasks: ${error.message}`),
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setIsLoading(false);
      }

      return unsubscribeAllTasksInDateRange;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getChecklistTasks();
    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(AllChecklistsTasksContainer) getChecklistTasks useEffect return ƒn just invoked.",
      );
      isMounted.current = false;
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          logger.debug("Now unsubscribing from checklist tasks.");
          unsubscribe();
        }
      });
      logger.debug("Done running cleanup ƒn for getChecklistTasks useEffect.");
    };
  }, [siteKey, range]);

  // This function is called when the date range changes.
  // Converts the date range to CalendarDate objects and sets the range state.
  // The React-Aria DateRangePicker component requires its "onChange"
  // to use "RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>"
  // objects (not just "RangeValue<CalendarDate>" objects) and doesn't work
  // without this conversion.
  function handleDateRangeChange(
    value: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>,
  ) {
    logger.debug(
      `Invoked handleDateRangeChange for range: ${value.start.month}-${value.start.day} to ${value.end.month}-${value.end.day}`,
    );
    try {
      const convertedStart = toCalendarDate(value.start);
      const convertedEnd = toCalendarDate(value.end);
      const converted = { start: convertedStart, end: convertedEnd };
      setRange(converted);
      logger.debug(
        `set range to ${converted.start.month}-${converted.start.day} to ${converted.end.month}-${converted.end.day}`,
      );
    } catch (e) {
      // TODO: Handle this error better
      if (e instanceof Error) {
        console.log("Error in handleDateRangeChange", e);
        logger.error("Error in handleDateRangeChange", e);
      }
    }
  }

  const checklistTasksDateRangePicker = (
    <RADateRangePicker value={range} onChange={handleDateRangeChange} />
  );

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  } else {
    return (
      <AllChecklistsTasksPage
        tasksList={taskList}
        onGoToTaskDetails={viewResponses}
        checklistTasksDateRangePicker={checklistTasksDateRangePicker}
      />
    );
  }
}
