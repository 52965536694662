// Libs
import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { Fragment, forwardRef, useCallback, useRef } from "react";

// Local
import BaseInputCheckbox from "../BaseInputCheckbox";
import BaseButtonSecondary from "../BaseButtonSecondary";
import {
  AccountingSyncStatus,
  getReadableAccountingSyncStatus,
  isValidAccountingSyncStatusValue,
} from "../../models/accounting-sync";

const AccountingSyncStatusCustomFilter = forwardRef(
  (
    {
      model,
      onModelChange,
      getValue,
    }: CustomFilterProps<any, any, { value: string[] }>,
    ref,
  ) => {
    const refInput = useRef<HTMLInputElement>(null);

    const doesFilterPass = useCallback(
      ({ node }: IDoesFilterPassParams) => {
        const nodeValue = getValue(node);
        if (model && isValidAccountingSyncStatusValue(nodeValue)) {
          return model.value.includes(nodeValue);
        } else return false;
      },
      [model],
    );

    const afterGuiAttached = useCallback(() => {
      window.setTimeout(() => {
        refInput.current?.focus();
      });
    }, []);

    useGridFilter({
      doesFilterPass,
      afterGuiAttached,
    });

    const onChange = (value: string[] | null) => {
      if (value == null) {
        onModelChange(null);
      } else {
        onModelChange({ value });
      }
    };

    return (
      <Fragment>
        <form className="flex h-full max-h-96 w-fit flex-col gap-2 rounded-md p-3">
          {Object.values(AccountingSyncStatus).map((accountingSyncStatus) => {
            return (
              isValidAccountingSyncStatusValue(accountingSyncStatus) && (
                <BaseInputCheckbox
                  key={accountingSyncStatus}
                  label={getReadableAccountingSyncStatus(accountingSyncStatus)}
                  checked={
                    model != null
                      ? model.value.includes(accountingSyncStatus)
                      : false
                  }
                  onChange={(event) => {
                    const value = event.target.checked;
                    if (value === true) {
                      const modelCopy = model === null ? [] : [...model!.value];
                      onChange([...modelCopy, accountingSyncStatus]);
                    } else {
                      const modelCopy = [...model!.value];
                      const statusIndex = modelCopy.findIndex(
                        (value) => accountingSyncStatus === value,
                      );
                      modelCopy.splice(statusIndex, 1);
                      if (modelCopy.length === 0) {
                        onChange(null);
                      } else {
                        onChange(modelCopy);
                      }
                    }
                  }}
                  ref={refInput}
                  className="form-control"
                />
              )
            );
          })}
          <BaseButtonSecondary
            type="button"
            className="form-control"
            onClick={() => onChange(null)}
          >
            Reset
          </BaseButtonSecondary>
        </form>
      </Fragment>
    );
  },
);

export default AccountingSyncStatusCustomFilter;
