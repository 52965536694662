// Local
import { TaskTypes, getReadableTaskType } from "./task-types";

/**
 * Defined task type codes.
 */
export enum TaskStatus {
  AWAITING_ESTIMATE = 5,
  AWAITING_REVIEW = 6,
  AWAITING_APPROVAL = 8,
  AWAITING_SCHEDULE = 10,
  AWAITING_PARTS = 11,
  PARTS_ORDERED = 12,
  SCHEDULED = 20,
  AWAITING = 30,
  IN_ROUTE = 35,
  IN_PROGRESS = 40,
  ON_HOLD = 50,
  /** @deprecated do not use. */
  AWAITING_PAYMENT = 80,
  COMPLETE = 90,
  CANCELED = 99,
}

export const OTaskStatus = {
  AWAITING_ESTIMATE: 5,
  AWAITING_REVIEW: 6,
  AWAITING_APPROVAL: 8,
  AWAITING_SCHEDULE: 10,
  AWAITING_PARTS: 11,
  PARTS_ORDERED: 12,
  SCHEDULED: 20,
  AWAITING: 30,
  IN_ROUTE: 35,
  IN_PROGRESS: 40,
  ON_HOLD: 50,
  /** @deprecated do not use. */
  AWAITING_PAYMENT: 80,
  COMPLETE: 90,
  CANCELED: 99,
} as const;

export type TaskStatusValues = (typeof OTaskStatus)[keyof typeof OTaskStatus];

export function getReadableTaskStatus(value: number): string {
  const statusString: string | undefined =
    readableTaskStatusMap[value as TaskStatusValues];
  if (!statusString) return "UNKNOWN";
  return statusString;
}

/**
 * Map of the status integers to their human readable strings.
 */
export const readableTaskStatusMap: Record<TaskStatusValues, string> = {
  [OTaskStatus.AWAITING_ESTIMATE]: "Awaiting Estimate",
  [OTaskStatus.AWAITING_REVIEW]: "Awaiting Review",
  [OTaskStatus.AWAITING_APPROVAL]: "Awaiting Approval",
  [OTaskStatus.AWAITING_SCHEDULE]: "Awaiting Schedule",
  [OTaskStatus.AWAITING_PARTS]: "Awaiting Parts",
  [OTaskStatus.PARTS_ORDERED]: "Parts Ordered",
  [OTaskStatus.SCHEDULED]: "Scheduled",
  [OTaskStatus.AWAITING]: "Awaiting",
  [OTaskStatus.IN_ROUTE]: "In Route",
  [OTaskStatus.IN_PROGRESS]: "In Progress",
  [OTaskStatus.ON_HOLD]: "On Hold",
  [OTaskStatus.AWAITING_PAYMENT]: "Awaiting Payment",
  [OTaskStatus.COMPLETE]: "Complete",
  [OTaskStatus.CANCELED]: "Canceled",
};

/** Typeguard to determine if the given value is one of the TaskStatusValues */
export function isValidTaskStatusValue(
  value: number,
): value is TaskStatusValues {
  const statusValues = Object.values(OTaskStatus);
  return statusValues.includes(value as any);
}

export function getTaskStatusStringVerbose(
  taskStatus: TaskStatus,
  taskType: TaskTypes,
): string {
  switch (taskStatus) {
    case TaskStatus.AWAITING_ESTIMATE:
      return "Awaiting Estimate";
    case TaskStatus.AWAITING_REVIEW:
      return "Awaiting Review";
    case TaskStatus.AWAITING_APPROVAL:
      return "Awaiting Approval";
    case TaskStatus.AWAITING_SCHEDULE:
      return "Awaiting Schedule";
    case TaskStatus.AWAITING_PARTS:
      return "Awaiting Parts";
    case TaskStatus.PARTS_ORDERED:
      return "Parts Ordered";
    case TaskStatus.SCHEDULED:
      return "Scheduled";
    case TaskStatus.AWAITING:
      return `Awaiting ${getReadableTaskType(taskType)}`;
    case TaskStatus.IN_ROUTE:
      return "In Route";
    case TaskStatus.IN_PROGRESS:
      return `In Progress ${getReadableTaskType(taskType)}`;
    case TaskStatus.ON_HOLD:
      return `In Progress ${getReadableTaskType(taskType)} - Hold`;
    case TaskStatus.AWAITING_PAYMENT:
      return "Awaiting Payment";
    case TaskStatus.COMPLETE:
      return `${getReadableTaskType(taskType)} Complete`;
    case TaskStatus.CANCELED:
      return "Canceled";
    default:
      const _exhaustivenessCheck: never = taskStatus;
      return _exhaustivenessCheck;
  }
}

export function getTaskStatusStringSuccinct(taskStatus: TaskStatus): string {
  switch (taskStatus) {
    case TaskStatus.AWAITING_ESTIMATE:
      return "Awaiting Estimate";
    case TaskStatus.AWAITING_REVIEW:
      return "Awaiting Review";
    case TaskStatus.AWAITING_APPROVAL:
      return "Awaiting Approval";
    case TaskStatus.AWAITING_SCHEDULE:
      return "Awaiting Schedule";
    case TaskStatus.AWAITING_PARTS:
      return "Awaiting Parts";
    case TaskStatus.PARTS_ORDERED:
      return "Parts Ordered";
    case TaskStatus.SCHEDULED:
      return "Scheduled";
    case TaskStatus.AWAITING:
      return "Awaiting";
    case TaskStatus.IN_ROUTE:
      return "In Route";
    case TaskStatus.IN_PROGRESS:
      return "In Progress";
    case TaskStatus.ON_HOLD:
      return "Hold";
    case TaskStatus.AWAITING_PAYMENT:
      return "Awaiting Payment";
    case TaskStatus.COMPLETE:
      return "Complete";
    case TaskStatus.CANCELED:
      return "Canceled";
    default:
      const _exhaustivenessCheck: never = taskStatus;
      return _exhaustivenessCheck;
  }
}
