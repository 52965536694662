// Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  RowClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { ExistingCraftRecord } from "../../models/craft-record";
import { ExistingTask } from "../../models/task";
import { getScheduleFlagsValue } from "../customers/CustomerTaskListTable";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { TaskStatus, getReadableTaskStatus } from "../../models/task-status";
import { ConvertArrayToString } from "../../assets/js/HandleOptions";
import SearchBox from "../SearchBox";
import TaskStatusCustomFilter from "../tables/TaskStatusCustomFilter";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  workRecordList: ExistingCraftRecord[];
  taskList: ExistingTask[];
  userDisplayNamesMap: Record<string, string>;
  goToWorkRecordAndTasksPage: (
    craftRecordID: ExistingCraftRecord["id"],
  ) => void;
}

export default function ViewPreviousJobsDialog(props: Props) {
  return (
    <BaseModal
      open={props.isOpen}
      closeModal={props.onClose}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.WORK_RECORDS}</h1>
          <button
            onClick={props.onClose}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left pb-6 w-full max-w-7xl"
    >
      <div className="relative flex flex-col gap-8 px-8 pt-8 text-lg">
        <div className={"ag-theme-alpine h-[400px]"}>
          <ViewPreviousJobsTable
            taskList={props.taskList}
            workRecordList={props.workRecordList}
            userDisplayNamesMap={props.userDisplayNamesMap}
            goToWorkRecordAndTasksPage={(craftRecordID: string) => {
              props.goToWorkRecordAndTasksPage(craftRecordID);
              props.onClose();
            }}
          />
        </div>
        <div className="mt-12 sm:ml-auto">
          <BaseButtonSecondary
            className="w-full sm:w-40"
            onClick={() => props.onClose()}
          >
            Close
          </BaseButtonSecondary>
        </div>
      </div>
    </BaseModal>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface ViewPreviousJobsTableProps {
  taskList: Props["taskList"];
  workRecordList: Props["workRecordList"];
  userDisplayNamesMap: Props["userDisplayNamesMap"];
  goToWorkRecordAndTasksPage: Props["goToWorkRecordAndTasksPage"];
}

/* TABLE */
const ViewPreviousJobsTable = React.memo(
  ({
    taskList,
    workRecordList,
    userDisplayNamesMap,
    goToWorkRecordAndTasksPage,
  }: ViewPreviousJobsTableProps) => {
    taskList.sort((a, b) => {
      if (a.timestampScheduled === null) {
        return -1;
      }
      if (b.timestampScheduled === null) {
        return 1;
      }
      return a.timestampScheduled > b.timestampScheduled ? -1 : 1;
    });

    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    /* RENDER CELL COMPONENTS */

    const readableTaskStatus = (params: ICellRendererParams): JSX.Element => {
      let customBg = "bg-primary text-primaryButtonText";
      switch (params.data.taskStatus) {
        case TaskStatus.IN_ROUTE:
          customBg = "bg-sky-300 text-white";
          break;
        case TaskStatus.IN_PROGRESS:
          customBg = "bg-pink-300 text-white";
          break;
        case TaskStatus.AWAITING_PAYMENT:
          customBg = "bg-purple-300 text-white";
          break;
      }

      return (
        <div className="flex items-center">
          <div
            className={`m-1  rounded-full px-2.5 pb-1 pt-0.5 text-xs font-medium ${customBg}`}
          >
            {getReadableTaskStatus(params.data.taskStatus)}
          </div>
        </div>
      );
    };

    const renderTimestampScheduled = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return (
        <div>
          {params.data.timestampScheduled != null
            ? convertToReadableTimestamp(params.data.timestampScheduled)
            : "Not scheduled"}
        </div>
      );
    };

    const renderScheduleFlags = (
      params: ICellRendererParams,
    ): JSX.Element | null => {
      if (params.data.urgent === true) {
        return (
          <div className="flex items-center">
            <div className="m-1 rounded-full bg-orange-400 px-2.5 pb-1 pt-0.5 text-xs font-medium text-white">
              {strings.URGENT}
            </div>
          </div>
        );
      } else if (params.data.nextOpportunity === true) {
        return (
          <div className="flex items-center">
            <div className="m-1 rounded-full bg-green-400 px-2.5 pb-1 pt-0.5 text-xs font-medium text-white">
              {strings.NEXT_OPPORTUNITY}
            </div>
          </div>
        );
      } else {
        return null;
      }
    };

    const renderTitle = (params: ICellRendererParams): JSX.Element => {
      const currentWorkRecord = workRecordList.find(
        (workRecord) =>
          workRecord.id === params.data.craftRecordID.split("/")[3],
      );

      return (
        <div className="my-2 flex items-center">
          <div className="flex flex-col space-y-2">
            <span className="block text-base font-medium leading-none">
              {params.data.title}
            </span>
            {currentWorkRecord?.description && (
              <span className="block text-sm leading-none text-gray-400">
                Job Notes: {currentWorkRecord?.description}
              </span>
            )}
            {params.data.description && (
              <span className="block text-sm leading-none text-gray-400">
                Task Notes: {params.data.description}
              </span>
            )}
          </div>
        </div>
      );
    };

    const renderAssignTo = (params: ICellRendererParams): JSX.Element => {
      const assignedToList: string[] | undefined =
        params.data.taskSpecificDetails["assignedTo"];
      if (assignedToList && assignedToList.length !== 0) {
        const displayAssignedToName = assignedToList.map(
          (assignedTo) => userDisplayNamesMap[assignedTo],
        );
        return <div>{ConvertArrayToString(displayAssignedToName)}</div>;
      } else {
        return <div></div>;
      }
    };

    const columnDefs: ColDef[] = [
      {
        field: "title",
        headerName: "Title",
        cellRenderer: renderTitle,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.data.title + " " + params.data.description;
        },
        minWidth: 500,
        autoHeight: true,
      },
      {
        field: "taskStatus",
        headerName: "Task Status",
        filter: TaskStatusCustomFilter,
        cellRenderer: readableTaskStatus,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
      {
        headerName: "Assigned to",
        cellRenderer: renderAssignTo,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "timestampScheduled",
        headerName: "Scheduled",
        cellRenderer: renderTimestampScheduled,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Schedule Flags",
        cellRenderer: renderScheduleFlags,
        valueGetter: (params) => getScheduleFlagsValue(params),
        tooltipValueGetter: (params) => getScheduleFlagsValue(params),
        comparator: (valueA, valueB) => {
          if (valueA === valueB) return 0;
          return valueA > valueB ? 1 : -1;
        },
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
    ];

    return (
      <Fragment>
        <SearchBox
          onInput={onFilterTextBoxChanged}
          widthClasses="w-full sm:w-96"
        />
        <AgGridReact
          reactiveCustomComponents
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={taskList}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
          onRowClicked={(event: RowClickedEvent) => {
            if (goToWorkRecordAndTasksPage) {
              goToWorkRecordAndTasksPage(
                event.data.craftRecordID.split("/")[3],
              );
            }
          }}
        />
      </Fragment>
    );
  },
);
