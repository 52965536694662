// Libs
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  PlusIcon,
  TrashIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { ChangeEvent, forwardRef, useState } from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";

// Local
import { ExistingCraftRecord } from "../../models/craft-record";
import * as strings from "../../strings";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseModal from "../BaseModal";
import StyledMessage from "../StyledMessage";
import BaseInputText from "../BaseInputText";
import { logger } from "../../logging";
import { StyledTooltip } from "../StyledTooltip";
import LoadingSpinner from "../LoadingSpinner";
import { ExistingAttachment } from "../../models/attachment";
import { Json } from "../../models/json-type";
import { ExistingCustomField } from "../../models/custom-field";
import { IMultipleUid_AssignUser } from "../CustomFields/MultipleUidDialog";
import {
  Input,
  convertResponsesToExpectedTypes,
  dropUnchangedFields,
  getDefaultValuesForExistingResponse,
} from "../CustomFields";
import BaseInputSelect from "../BaseInputSelect";

type TextareaProps = {
  text: string;
  value?: string;
} & React.ComponentPropsWithRef<"textarea">;
type Ref = HTMLTextAreaElement;
const LocalTextarea = forwardRef<Ref, TextareaProps>(
  (props, ref): JSX.Element => {
    return (
      <div className="relative">
        <textarea
          {...props}
          ref={ref}
          id="description"
          rows={2}
          className="block w-full rounded border border-black p-4 text-gray-700 focus:border-primaryLight focus:outline-none focus:ring focus:ring-primaryLight sm:text-sm"
          value={props.value}
        />
        <label
          htmlFor="description"
          className="absolute -top-3 left-3 bg-white px-2"
        >
          {props.text}
        </label>
      </div>
    );
  },
);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  workRecord: ExistingCraftRecord;
  /** **Just the IDs and titles** */
  siteKeyLocations: Record<string, string>[];
  attachments: ExistingAttachment[]; // REFACTOR: pass in just ids, urls, and filenames
  siteKeyCustomFields: ExistingCustomField[];
  userList: IMultipleUid_AssignUser[];
  userDisplayNamesMap: Record<string, string>;

  // JUICY FUNCTIONS
  // temp removal of locationID /** title, description, and locationID */
  /** title and description */
  handleUpdateWorkRecord: (formValues: Record<string, Json>) => Promise<void>;
  handleUploadAttachment: (args: {
    file: File;
    craftRecordID: string;
    setIsUploading: (val: boolean) => void;
    setAddAttachmentSuccess: (val: boolean) => void;
    setAddAttachmentError: (val: boolean) => void;
  }) => void;
  handleDeleteAttachment: (id: ExistingAttachment["id"]) => Promise<void>;
}

export default function EditWorkRecordDialog(props: Props): JSX.Element {
  const findLocation = props.siteKeyLocations.find((item) => {
    const id = Object.keys(item)[0];
    return id === props.workRecord.locationID;
  });

  // if we couldn't find the location, just make it the first one. if there are
  // no siteKey location docs, assign an empty object so the app doesn't crash.
  const currentLocation = findLocation ?? props.siteKeyLocations[0] ?? {};
  const currentLocationID = Object.keys(currentLocation)[0] ?? "";
  const currentLocationTitle = Object.values(currentLocation)[0] ?? "";

  // this is what we'll loop through when displaying the location dropdown. it
  // shouldn't contain the `currentLocation`
  const filteredLocations = props.siteKeyLocations.filter((item) => {
    const title = Object.values(item)[0];
    return title !== currentLocationTitle;
  });

  const defaultValues: Record<string, Json | Date> = {
    title: props.workRecord.title,
    description: props.workRecord.description,
    locationID: currentLocationID,
  };
  props.siteKeyCustomFields.forEach((cf) => {
    const dv = getDefaultValuesForExistingResponse({
      type: cf.fieldType,
      existingResponse: props.workRecord.craftDetails[cf.id],
      userDisplayNamesMap: props.userDisplayNamesMap,
      userList: props.userList,
      selectionOptions:
        cf.fieldType === "selection" ? cf.selectionOptions : null,
    });

    if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
      defaultValues[`${cf.id}_hoursValue`] = dv[0];
      defaultValues[`${cf.id}_minutesValue`] = dv[1];
    } else {
      defaultValues[cf.id] = dv;
    }
  });

  const {
    handleSubmit,
    formState: { dirtyFields },
    control,
    reset,
    getValues,
  } = useForm({
    defaultValues: defaultValues as Record<string, any>,
  });

  function closeDialog() {
    props.onClose();
    reset(defaultValues);
  }

  // For the edit work record form
  const [isSubmitting, setIsSubmitting] = useState(false);
  // For the edit work record form
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // For uploading an attachment
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);
  // For uploading an attachment
  const [addAttachmentSuccess, setAddAttachmentSuccess] = useState(false);
  // For uploading an attachment
  const [addAttachmentError, setAddAttachmentError] = useState(false);
  // For deleting an attachment
  const [isDeletingAttachment, setIsDeletingAttachment] = useState<
    string | null
  >(null);
  // For deleting an attachment
  const [deleteAttachmentError, setDeleteAttachmentError] = useState(false);

  const onUpdateWorkRecord: SubmitHandler<FieldValues> = async (formValues) => {
    setIsSubmitting(true);
    const xvalues = { ...formValues };

    const updatedValues = dropUnchangedFields({
      dirtyFields,
      formValues: xvalues,
    });

    if (updatedValues.description?.length === 0) {
      updatedValues.description = null;
    }

    const convertedValues = convertResponsesToExpectedTypes({
      customFields: props.siteKeyCustomFields,
      values: updatedValues,
      userList: props.userList,
    });
    logger.info("onUpdateWorkRecord values", convertedValues);

    try {
      await props.handleUpdateWorkRecord(convertedValues);
      setIsSubmitting(false);
      props.onClose();
    } catch (e) {
      logger.error("error while executing props.handleUpdateWorkRecord:", e);
      setShowErrorMessage(true);
      setIsSubmitting(false);
    }
  };

  function handleAddAttachment(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length === 1) {
      const chosenFile = event.target.files[0];

      setIsUploadingAttachment(true);

      props.handleUploadAttachment({
        file: chosenFile,
        craftRecordID: props.workRecord.id,
        setIsUploading: setIsUploadingAttachment,
        setAddAttachmentSuccess,
        setAddAttachmentError,
      });
    }
  }

  return (
    <BaseModal
      open={props.isOpen}
      closeModal={closeDialog}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.EDIT_WORK_RECORD}</h1>
          <button
            onClick={closeDialog}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left max-w-md md:max-w-3xl pb-6"
    >
      <div className="relative">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onUpdateWorkRecord)}
          className="space-y-8 px-6 py-8 md:px-8"
        >
          {/* TITLE */}
          <div>
            <Controller
              name="title"
              rules={{ required: true, maxLength: 200 }}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <BaseInputText
                    text={strings.TITLE}
                    inputName="title"
                    admin={true}
                    required={true}
                    {...field}
                  />
                  {fieldState.error && fieldState.error.type === "required" && (
                    <div className="mt-2">
                      <StyledMessage type="error">
                        {{ message: strings.REQUIRED }}
                      </StyledMessage>
                    </div>
                  )}
                  {fieldState.error &&
                    fieldState.error.type === "maxLength" && (
                      <div className="mt-2">
                        <StyledMessage type="error">
                          {{ message: strings.errExceededMaxLength(200) }}
                        </StyledMessage>
                      </div>
                    )}
                </>
              )}
            />
          </div>

          {/* DESCRIPTION / NOTES */}
          <div>
            <Controller
              name="description"
              rules={{ maxLength: 3000 }}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <LocalTextarea
                    text={strings.NOTES}
                    {...field}
                    placeholder={strings.PLACEHOLDER_VISIBLE_NOTE}
                    value={field.value ? field.value : undefined}
                    onChange={(ev) => field.onChange(ev.target.value ?? null)}
                  />
                  {fieldState.error &&
                    fieldState.error.type === "maxLength" && (
                      <div className="mt-2">
                        <StyledMessage type="error">
                          {{ message: strings.errExceededMaxLength(3000) }}
                        </StyledMessage>
                      </div>
                    )}
                </>
              )}
            />
          </div>

          {/* LOCATION */}
          <div>
            <Controller
              name="locationID"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  text={strings.SELECT_LOCATION}
                  inputName="locationID"
                  admin={true}
                  required={false}
                  {...field}
                >
                  <option value={currentLocationID}>
                    {currentLocationTitle}
                  </option>
                  {filteredLocations.map((item) => {
                    const id = Object.keys(item)[0];
                    const title = Object.values(item)[0];
                    return (
                      <option value={id} key={id}>
                        {title}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              )}
            />
            {/* Edit Location callout alert if locationID is updated */}
            {defaultValues.locationID !== getValues().locationID && (
              <div className="mt-1 rounded-md bg-amber-100 px-2 py-1 text-sm font-medium text-black">
                Note: If you change the location of this job, all of the
                underlying tasks will also have their locations updated to this
                new location. If there are any estimates, invoices, or payments,
                the location will not be updated on those records and you will
                need to re-create them with the new location. Please contact
                support@stiltwork.com for additional assistance if needed.
              </div>
            )}
          </div>

          {/* SECTION: CUSTOM FIELDS */}
          <div
            className={`space-y-8 md:grid md:grid-cols-2 md:items-center md:gap-x-16 md:gap-y-8 md:space-y-0 ${
              props.siteKeyCustomFields.length > 0 ? "pb-4" : ""
            }`}
          >
            {props.siteKeyCustomFields.map((cf) => (
              <span key={cf.id} className="block">
                <Input
                  userList={props.userList}
                  customField={cf}
                  control={control}
                  defaultValue={defaultValues[cf.id]}
                  isRequired={false}
                  handleSubmitMultipleUID={(_data) => {}}
                />
              </span>
            ))}
          </div>

          <div className="mx-auto mt-4 w-64 text-sm">
            {showErrorMessage && (
              <StyledMessage type="error">
                {{
                  message: strings.ERR_UPDATE_WORK_REC,
                  icon: (
                    <XCircleIcon
                      className="h-8 w-8 text-red-900"
                      aria-hidden="true"
                    />
                  ),
                }}
              </StyledMessage>
            )}
          </div>

          {/* ACTION BUTTONS */}
          <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row ">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase xs:w-52"
              onClick={closeDialog}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase xs:w-52"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>

        {/* ATTACHMENTS */}
        <hr className="mb-4 mt-8 block w-full border border-gray-300" />
        <div className="flex items-center justify-between p-6 md:px-8">
          <h2 className="text-xl font-semibold">{strings.ATTACHMENTS}</h2>
          <StyledTooltip title={strings.TAP_TO_ADD_ATTACHMENT}>
            <label htmlFor="upload-attachment">
              {!isUploadingAttachment ? (
                <PlusIcon
                  className="h-10 w-10 cursor-pointer rounded-md bg-primary p-1.5 text-white"
                  aria-label={strings.TAP_TO_ADD_ATTACHMENT}
                />
              ) : (
                <span className="block h-10 w-10 rounded-md bg-primary p-2">
                  <LoadingSpinner
                    marginClass="m-0"
                    colorClass="text-white"
                    sizeClass="h-6 w-6"
                  />
                </span>
              )}
            </label>
          </StyledTooltip>
          <input
            id="upload-attachment"
            type="file"
            onChange={(ev) => handleAddAttachment(ev)}
            className="hidden"
          />
        </div>

        {/* DISPLAY ATTACHMENTS / ACTIONS */}
        <div className="px-6 md:px-8">
          {props.attachments.map((attachment) => (
            <div key={attachment.id} className="mb-6 flex items-center gap-6">
              {/* DELETE */}
              <button
                className={`rounded-full bg-red-50 ${
                  isDeletingAttachment === attachment.id
                    ? "cursor-auto"
                    : "hover:bg-red-100"
                }`}
                // ⌄ disables all delete buttons if an attachment is currently being deleted
                disabled={isDeletingAttachment !== null}
                onClick={async () => {
                  setIsDeletingAttachment(attachment.id);
                  try {
                    await props.handleDeleteAttachment(attachment.id);
                  } catch (e) {
                    logger.error(
                      `error deleting attachment. doc ID: ${attachment.id} .. error:`,
                      e,
                    );
                    setDeleteAttachmentError(true);
                  } finally {
                    setIsDeletingAttachment(null);
                  }
                }}
              >
                {isDeletingAttachment === attachment.id ? (
                  <span className="block h-9 w-9 p-2 md:h-10 md:w-10">
                    <LoadingSpinner
                      marginClass="m-0"
                      colorClass="text-red-700"
                      sizeClass="h-5 w-5 md:h-6 md:w-6"
                    />
                  </span>
                ) : (
                  <StyledTooltip title={strings.DELETE_ATTACHMENT}>
                    <TrashIcon
                      aria-label={`delete ${attachment.filename}`}
                      className="w-9 flex-shrink-0 fill-current p-1.5 text-red-700 md:w-10"
                    />
                  </StyledTooltip>
                )}
              </button>

              {/* DOWNLOAD */}
              {/* they can't download directly because the file lives in firebase
              storage -- different domain -- can't download cross-origin. so this
              will open the attachment in a new tab, where they can download it */}
              <StyledTooltip title={strings.DOWNLOAD_ATTACHMENT}>
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-full bg-gray-500 transition-colors hover:bg-gray-600"
                >
                  <ArrowDownTrayIcon
                    className="w-8 flex-shrink-0 p-1.5 text-white md:w-10"
                    aria-label={`download ${attachment.filename}`}
                  />
                </a>
              </StyledTooltip>

              {/* DISPLAY FILE NAME */}
              {attachment.filename}
            </div>
          ))}
        </div>
        <div className="absolute bottom-4 left-0 right-0 mx-auto w-fit">
          {addAttachmentSuccess && (
            <StyledMessage
              type="success"
              dismissible
              onDismiss={() => setAddAttachmentSuccess(false)}
            >
              {{
                message: strings.successfulAdd("attachment"),
                icon: (
                  <CheckCircleIcon className="h-8 w-8" aria-hidden="true" />
                ),
              }}
            </StyledMessage>
          )}
          {addAttachmentError && (
            <StyledMessage
              type="error"
              dismissible
              onDismiss={() => setAddAttachmentError(false)}
            >
              {{
                message: strings.failedAdd("attachment"),
                icon: <XCircleIcon className="h-8 w-8" aria-hidden="true" />,
              }}
            </StyledMessage>
          )}
          {deleteAttachmentError && (
            <StyledMessage
              type="error"
              dismissible
              onDismiss={() => setDeleteAttachmentError(false)}
            >
              {{
                message: strings.failedDelete("attachment"),
                icon: <XCircleIcon className="h-8 w-8" aria-hidden="true" />,
              }}
            </StyledMessage>
          )}
        </div>
      </div>
    </BaseModal>
  );
}
