//Libs
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMemo } from "react";

//Local
import { isValidTimezone } from "../../assets/js/isValidTimezone";
import StyledMessage from "../StyledMessage";
import BaseInputText from "../BaseInputText";
import BaseInputSelect from "../BaseInputSelect";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { siteNameSchema } from "../../models/site-key";
import * as strings from "../../strings";

interface Props {
  siteName: string;
  timezone: string[];
  onHandleNextButton: (formValues: CreateSiteDetailsState) => void;
  children: {
    Map: React.ReactNode;
    AddressField: React.ReactNode;
  };
}

const createSiteDetailsSchema = z.object({
  siteName: siteNameSchema,
  timezone: z
    .string()
    .min(1)
    .max(200)
    .refine((val) => isValidTimezone(val), {
      message: "Provide a valid timezone.",
    }),
});
export type CreateSiteDetailsState = z.infer<typeof createSiteDetailsSchema>;

export default function CreateSiteDetails(props: Props) {
  const theDefaultValues = useMemo(() => {
    return {
      siteName: props.siteName,
      timezone: props.timezone[0],
    };
  }, [props]);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateSiteDetailsState>({
    defaultValues: theDefaultValues,
    resolver: zodResolver(createSiteDetailsSchema),
    mode: "onChange",
  });

  function onSave(formValues: CreateSiteDetailsState) {
    props.onHandleNextButton(formValues);
  }

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSave)}
      onKeyDown={(event) =>
        event.key === "Enter" ? event.preventDefault() : null
      }
    >
      <div className="mt-8 grid gap-6 xs:gap-8 sm:grid-cols-2 md:mt-10 lg:grid-cols-3">
        <div>
          <Controller
            name="siteName"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Site Name"
                inputName="siteName"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {errors.siteName?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.siteName.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        <div>
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <BaseInputSelect
                text="Timezone"
                inputName="timezone"
                admin={true}
                id="timezone"
                {...field}
              >
                {props.timezone.map((zone) => (
                  <option key={zone} value={zone}>
                    {zone}
                  </option>
                ))}
              </BaseInputSelect>
            )}
          />
          {errors.timezone?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.timezone.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        <div className="sm:col-span-2 lg:col-span-1">
          {props.children.AddressField}
        </div>
      </div>

      {/* Map */}
      <div className="mt-8">{props.children.Map}</div>

      <div className="mt-8 flex flex-row justify-end space-y-4">
        <BaseButtonPrimary
          className="w-full xs:w-52"
          type="submit"
          disabled={isSubmitting}
          isBusy={isSubmitting}
          formNoValidate
        >
          {strings.buttons.NEXT}
        </BaseButtonPrimary>
      </div>
    </form>
  );
}
