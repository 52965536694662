import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { z } from "zod";
import { signInWithCustomToken } from "firebase/auth";

// Local
import { auth } from "../init-firebase";
import { logger as devLogger } from "../logging";
import { DbRead } from "../database";
import NotFound404 from "./NotFound404";
import LoadingClipboardAnimation from "../components/LoadingClipBoardAnimation";
import { convertDestinationURLFromVueApp } from "../utils";
import { HOME_URL } from "../urls";

/**
 * This page handles automatically logging in the user when a firestore document ID
 * is passed as tokenKey in the URL parameters. This is for using the app in an iFrame
 * within the main Stilt web applicaton.
 *
 * thisdomain.com/auto-login?tokenKey=abcdefg&destination=admin_user-management
 *
 */
export default function AutoLogin() {
  function useQuery() {
    const queryParamsString = useLocation().search;
    return new URLSearchParams(queryParamsString); //?tokenKey=abcdefg&destination=admin_user-management
  }

  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();
  const query = useQuery();
  const tokenKey = query.get("tokenKey");
  const destination = query.get("destination");

  devLogger.debug("tokenKey", tokenKey);
  devLogger.debug("destination", destination);

  useEffect(() => {
    // useEffect callback must be synchronous, so we wrap our code in an async function
    // and call it at the end of the block.
    async function fetchToken(): Promise<void> {
      if (tokenKey) {
        const tokenResponseSchema = z.object({ token: z.string() });

        try {
          const response = await DbRead.customToken.get(tokenKey);
          const parseData = tokenResponseSchema.parse(response.data);
          await signInWithCustomToken(auth, parseData.token);
          if (typeof destination === "string") {
            navigate(`/${convertDestinationURLFromVueApp(destination)}`);
          } else {
            navigate(HOME_URL); // ✨
          }
        } catch (err) {
          devLogger.warn(`Unable to automatically sign in.`);
          devLogger.error(err);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }

    fetchToken().catch((err) => devLogger.error(err));
  }, []);

  return (
    <div className="flex h-full flex-auto flex-col items-center justify-center">
      {isLoading ? <LoadingClipboardAnimation /> : <NotFound404 />}
    </div>
  );
}
