//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseModal from "../BaseModal";
import BaseInputText from "../BaseInputText";
import {
  alphabetizeWorkTypes,
  CraftTypes,
  getReadableCraftType,
  getSortedWorkTypesList,
  isValidCraftType,
} from "../../models/craft-types";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputCheckbox from "../BaseInputCheckbox";
import { ExistingSiteKey } from "../../models/site-key";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import StyledMessage from "../StyledMessage";
import { logger as devLogger } from "../../logging";
import * as strings from "../../strings";

interface Props {
  siteKey: ExistingSiteKey;
  isDialogOpen: boolean;
  company: ExistingSiteKeyCompany;
  loading: boolean;
  closeDialog: () => void;
  handleEdit: (
    companyID: string,
    formValues: EditCompanyState,
  ) => Promise<void>;
  children: {
    CompanyLogoSection: React.ReactNode;
  };
}

const CompanySchema = z
  .object({
    name: z.string().min(1).max(200),
    mainPointOfContact: z.string().max(200),
    craftTypes: z.array(
      z
        .nativeEnum(CraftTypes, {
          errorMap: (_issue, _ctx) => {
            return { message: "Required" };
          },
        })
        .or(z.literal(undefined)),
    ),
    /* craftTypes is required, so at least one checkbox must be checked */
  })
  .refine(
    (data) => Object.values(data.craftTypes).some((type) => type !== undefined),
    {
      message: "At least one work type must be selected",
      path: ["craftTypes"],
    },
  );
export type EditCompanyState = z.infer<typeof CompanySchema>;

export default function EditCompanyDialog(props: Props) {
  /** Work types available for the current siteKey */
  const siteWorkTypes = alphabetizeWorkTypes(
    props.siteKey.validCraftTypes.filter(isValidCraftType),
  );

  const companyDefaultValues = useMemo(() => {
    return {
      name: props.company.name ?? "",
      mainPointOfContact: props.company.mainPointOfContact
        ? props.company.mainPointOfContact
        : "",
      craftTypes: [],
    };
  }, [props.company]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    resetField,
  } = useForm<EditCompanyState>({
    defaultValues: companyDefaultValues,
    resolver: zodResolver(CompanySchema),
    mode: "onChange",
  });

  useEffect(() => {
    // Populate the workType values after they've been retrieved from the db.
    const sortedWorkTypesList = getSortedWorkTypesList(
      props.company.craftTypes,
      props.siteKey.validCraftTypes,
    );

    reset({
      name: companyDefaultValues.name,
      mainPointOfContact: companyDefaultValues.mainPointOfContact,
      craftTypes: sortedWorkTypesList,
    });
  }, [props.company, props.siteKey, companyDefaultValues, reset]);

  const companyHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Edit company
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon aria-label="close company form" className="h-6 text-white" />
      </button>
    </div>
  );

  const onClickSaveButton: SubmitHandler<EditCompanyState> = async (
    formValues,
  ) => {
    if (props.company.id) {
      await props.handleEdit(props.company.id, formValues);

      // Close dialog if successful.
      props.closeDialog();
    } else {
      devLogger.error("Company doesn't exist");
    }
  };

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={companyHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <form
        className="flex flex-col space-y-8 p-8 text-lg"
        autoComplete="off"
        onSubmit={handleSubmit(onClickSaveButton)}
      >
        {/* Field: Company Name */}
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Company Name"
                inputName="name"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {errors.name?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.name.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Field: Main Point of Contact */}
        <div>
          <Controller
            name="mainPointOfContact"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Main Point of Contact"
                inputName="mainPointOfContact"
                admin={true}
                {...field}
              />
            )}
          />
          {errors.mainPointOfContact?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.mainPointOfContact.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Field: Work Types */}
        <div className="relative grid grid-cols-1 rounded border border-black p-6 xs:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 md:pl-2 md:pr-0 lg:col-span-2 lg:px-4">
          <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
            Work Types{" "}
            <span className="text-lg font-medium text-redFail">*</span>
          </label>
          {/* Select All Work Type Button */}
          <div className="flex w-full flex-col xs:col-span-2 xs:mb-3 xs:flex-row">
            <BaseButtonSecondary
              className="xs:ml-2 xs:mr-6 xs:w-36 md:ml-4"
              type="button"
              onClick={() =>
                resetField("craftTypes", { defaultValue: [...siteWorkTypes] })
              }
            >
              {strings.buttons.CHECK_ALL}
            </BaseButtonSecondary>
            <BaseButtonSecondary
              className="my-3 xs:my-0 xs:w-36"
              type="button"
              onClick={() =>
                resetField("craftTypes", {
                  defaultValue: siteWorkTypes.map(() => undefined),
                })
              }
            >
              {strings.buttons.UNCHECK_ALL}
            </BaseButtonSecondary>
          </div>
          <span className="contents sm:col-span-1">
            {siteWorkTypes.map((craftType, index) => (
              <Controller
                key={craftType}
                control={control}
                name={`craftTypes.${index}`}
                render={({ field }) => (
                  <BaseInputCheckbox
                    label={getReadableCraftType(craftType)}
                    {...field}
                    value={craftType}
                    checked={field.value === craftType}
                    onChange={(event) => {
                      field.onChange(
                        event.target.checked ? craftType : undefined,
                      );
                    }}
                  />
                )}
              />
            ))}
          </span>

          {errors.craftTypes && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: (errors.craftTypes as any).message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {props.children.CompanyLogoSection}

        {/* Action Buttons */}
        <div className="mt-4 flex w-full items-center justify-between gap-4">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={() => {
              props.closeDialog();
            }}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            type="submit"
            className="w-full justify-center uppercase"
            formNoValidate
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </form>
    </BaseModal>
  );
}
