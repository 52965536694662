//Libs
import React from "react";

//Local

type Ref = HTMLInputElement;

type Props = {
  label: string;
  children?: React.ReactNode;
  responseStyle?: true;
} & React.ComponentPropsWithRef<"input">;

const BaseInputCheckbox = React.forwardRef<Ref, Props>(
  ({ label, children, responseStyle, ...props }, ref): JSX.Element => {
    return (
      <div className="relative inline-flex items-center pr-4 ">
        <div className="flex h-5 items-center ">
          <input
            {...props}
            type="checkbox"
            id={props.id ? props.id : label}
            className={`focus-visible:outline focus-visible:outline-1 ${
              responseStyle
                ? "h-6 w-6 accent-green-600 focus-visible:outline-green-600"
                : "h-4 w-4 accent-current focus-visible:outline-current"
            }`}
            disabled={props.disabled}
            ref={ref}
          />
        </div>

        <div className="ml-3 text-sm">
          <label
            htmlFor={props.id ? props.id : label}
            className={`${
              responseStyle ? "font-medium" : "font-bold capitalize"
            } text-base text-current`}
          >
            {label}
          </label>
          <p className="text-gray-500">{children}</p>
        </div>
      </div>
    );
  },
);

export default BaseInputCheckbox;
