//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseModal from "../BaseModal";
import BaseInputText from "../BaseInputText";
import { getReadableCraftType } from "../../models/craft-types";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputCheckbox from "../BaseInputCheckbox";
import { ExistingSiteKey } from "../../models/site-key";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";

interface Props {
  siteKey: ExistingSiteKey;
  isDialogOpen: boolean;
  loading: boolean;
  closeDialog: () => void;
  handleSave: (formValues: CompanyState) => Promise<void>;
  children: {
    CompanyLogoSection: React.ReactNode;
  };
}

const CompanySchema = z
  .object({
    name: z.string().min(1).max(200),
    mainPointOfContact: z.string().max(200),
    craftTypes: z.array(z.string().or(z.literal(undefined))),
    /* craftTypes is required, so at least one checkbox must be checked */
  })
  .refine(
    (data) => Object.values(data.craftTypes).some((type) => type !== undefined),
    {
      message: "At least one work type must be selected",
      path: ["craftTypes"],
    },
  );
export type CompanyState = z.infer<typeof CompanySchema>;

export default function AddNewCompanyDialog(props: Props) {
  /* convert from site key doc valid CraftTypeValues to craft type strings */
  const readableCraftTypes = useMemo(() => {
    return props.siteKey.validCraftTypes.map((craftType) => {
      return getReadableCraftType(craftType);
    });
  }, [props.siteKey.validCraftTypes]);
  /* sort the craft type string alphabetically */
  const validCraftTypes = readableCraftTypes.sort((a, b) => a.localeCompare(b));

  const companyDefaultValues = useMemo(() => {
    return {
      name: "",
      mainPointOfContact: "",
      craftTypes: [],
    };
  }, []);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    setValue,
  } = useForm<CompanyState>({
    defaultValues: companyDefaultValues,
    resolver: zodResolver(CompanySchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(companyDefaultValues);
  }, [companyDefaultValues, reset]);

  const companyHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Create company
      </h1>
      <button onClick={() => props.closeDialog()}>
        <XMarkIcon aria-label="close company form" className="h-6 text-white" />
      </button>
    </div>
  );

  const onClickSaveButton: SubmitHandler<CompanyState> = async (formValues) => {
    await props.handleSave(formValues);

    // Close dialog if successful.
    props.closeDialog();
  };

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={companyHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <form
        className="flex flex-col space-y-8 p-8 text-lg"
        autoComplete="off"
        onSubmit={handleSubmit(onClickSaveButton)}
      >
        {/* Field: Company Name */}
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Company Name"
                inputName="name"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {errors.name?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.name.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Field: Main Point of Contact */}
        <div>
          <Controller
            name="mainPointOfContact"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Main Point of Contact"
                inputName="mainPointOfContact"
                admin={true}
                {...field}
              />
            )}
          />
          {errors.mainPointOfContact?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.mainPointOfContact.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Field: Work Types */}
        <div className="relative grid grid-cols-1 rounded border border-black p-6 xs:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 md:pl-2 md:pr-0 lg:col-span-2 lg:px-4">
          <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
            Work Types{" "}
            <span className="text-lg font-medium text-redFail">*</span>
          </label>
          {/* Select All Work Type Button */}
          <div className="flex w-full flex-col xs:col-span-2 xs:mb-3 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="xs:ml-2 xs:mr-6 xs:w-36 md:ml-4"
              onClick={() => {
                validCraftTypes.forEach((craftType, index) => {
                  setValue(`craftTypes.${index}`, craftType);
                });
              }}
            >
              {strings.buttons.CHECK_ALL}
            </BaseButtonSecondary>
            <BaseButtonSecondary
              type="button"
              className="my-3 xs:my-0 xs:w-36"
              onClick={() => {
                validCraftTypes.forEach((_, index) => {
                  setValue(`craftTypes.${index}`, undefined);
                });
              }}
            >
              {strings.buttons.UNCHECK_ALL}
            </BaseButtonSecondary>
          </div>
          <span className="contents sm:col-span-1">
            {validCraftTypes.map((craftType, index) => (
              <Controller
                key={craftType}
                control={control}
                name={`craftTypes.${index}`}
                render={({ field }) => (
                  <BaseInputCheckbox
                    label={craftType}
                    {...field}
                    value={craftType}
                    checked={field.value === craftType}
                    onChange={(event) => {
                      field.onChange(
                        event.target.checked ? craftType : undefined,
                      );
                    }}
                  />
                )}
              />
            ))}
          </span>
          {errors.craftTypes && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: (errors.craftTypes as any).message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {props.children.CompanyLogoSection}

        {/* Action Buttons */}
        <div className="mt-4 flex w-full items-center justify-between gap-4">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={() => {
              props.closeDialog();
            }}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            type="submit"
            className="w-full justify-center uppercase"
            formNoValidate
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </form>
    </BaseModal>
  );
}
