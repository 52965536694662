//Libs
import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { DocumentData, Timestamp } from "firebase/firestore";
import { User } from "firebase/auth";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";

//Local
import ViewEstimatePage from "./ViewEstimatePage";
import * as strings from "../../strings";
import { StyledTooltip } from "../../components/StyledTooltip";
import ButtonColored from "../../components/ButtonColored";
import {
  getEstimateStatus,
  getEstimateTotals,
} from "../../assets/js/estimateFunctions";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { logger } from "../../logging";
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { useNavToCreateTask } from "../../navigation";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  CUSTOMERS_URL,
  WORK_RECORD_AND_TASKS_URL,
  whiteLabel,
  ESTIMATES_URL_AUTH,
} from "../../urls";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ErrorMessage } from "../../components/ErrorMessage";
import {
  EstimateManager,
  EstimateStatus,
  ExistingEstimate,
  ExistingEstimateUpdate,
} from "../../models/estimate";
import { useAuthStore } from "../../store/firebase-auth";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { useToastMessageStore } from "../../store/toast-messages";
import { convertFSTimestampToLuxonDT, createToastMessageID } from "../../utils";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import TaskDetailsInfoForEstimate from "../../components/estimates/TaskDetailsInfoForEstimate";
import { ExistingTask } from "../../models/task";
import {
  ExistingStiltInvoice,
  getReadableStiltInvoiceStatus,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  StiltInvoiceStatus,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { PencilIconWithRef } from "../../components/PencilEditButton";
import { logger as devLogger } from "../../logging";
import EditNotesDialog from "../../components/estimates/EditNotesDialog";
import { diffObjects } from "../../assets/js/object-diff";
import BaseInputNumber from "../../components/BaseInputNumber";
import { XIconWithRef } from "../../components/XCloseButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  EstimateItemManager,
  EstimateItem_CreateAPI,
  EstimateItem_UpdateAPI,
  ExistingEstimateItem,
  TemporaryEstimateItem,
} from "../../models/estimate-item";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { isWhiteLabel } from "../../white-label-check";
import {
  customerWithoutTimestamps,
  ExistingCustomer,
  ExistingCustomerUpdate,
} from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { NewManualPayment } from "../../components/Invoices/RecordManualPaymentDialog";
import { SchedulingButton } from "../Scheduling/SchedulingContainer";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import {
  APIPaymentSavedCard,
  StiltPaymentManager,
  StiltPayment_CreateAPI,
  paymentMethods,
} from "../../models/stilt-payment";
import EditInternalNotesDialog from "../../components/estimates/EditInternalNotesDialog";
import { useTypesenseStore } from "../../store/typesense";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import EstimateItemInline from "../../components/estimates/EstimateItemInline";
import EditEstimateItemLine from "../../components/estimates/EditEstimateItemLine";
import AddNewEstimateItemSelection from "../../components/estimates/AddNewEstimateItemSelection";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { getMembershipIdsCount } from "../../assets/js/memberships/getMembershipIdsCount";
import { getTemporaryDataForEditMemberships } from "../../assets/js/memberships/getTemporaryDataForEditMemberships";
import EditCustomerDialog from "../../components/customers/EditCustomerDialog";
import { ExistingSignature } from "../../models/signature";
import { ExistingStiltPhoto } from "../../models/stilt-photo";
import { getEmailList } from "../../utils/getEmailList";
import { generatePDF } from "../../components/Invoices/generatePDF";
import { PDFIconWithSpinner } from "../../components/PDFButton";
import { TrashIconWithSpinner } from "../../components/ButtonDeleteTrash";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import BaseInputSelect from "../../components/BaseInputSelect";
import HandleSendEmailDialog from "../../components/estimates/HandleSendEmailDialog";
import AddEditCommissionAdjustmentDialog from "../../components/Commissions/AddEditCommissionAdjustment";
import {
  CommissionAdjustment_CreateAPI,
  CommissionAdjustment_UpdateAPI,
  CommissionAdjustmentManager,
  CreateCommissionAdjustment,
  ExistingCommissionAdjustment,
} from "../../models/commission-adjustment";

export type OptionalDocs = {
  estimateID: string;
  customerDoc: ExistingCustomer;
  customerLocationDoc: ExistingCustomerLocation;
  invoiceDoc: ExistingStiltInvoice | null;
};

interface Props {
  siteKey: string;
  optionalDocs?: OptionalDocs;
}

export default function ViewEstimateContainer({
  siteKey,
  optionalDocs,
}: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);

  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  const navToCreateTask = useNavToCreateTask();

  // Extract customer ID from the URL
  type UrlParams = { id: string };
  const data = useParams<UrlParams>();
  const estimateID = optionalDocs ? optionalDocs.estimateID : data.id;
  if (typeof estimateID !== "string") {
    throw new Error(`estimateID was not a string: ${estimateID}`);
  }

  /* HISTORY & LOCATIONS */
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const {
    customerDocFromState,
    customerLocationFromState,
    invoiceDocFromState,
  } = getDocsFromState(optionalDocs, state);

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  function goToWorkRecordAndTasksPage(
    craftRecordPath: ExistingTask["craftRecordID"],
  ) {
    const craftRecordID = craftRecordPath.split("/")[3];
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  function handleGoToCustomerPage(customerId: string) {
    navigate(`${CUSTOMERS_URL}/${customerId}`);
  }

  /* STATES */
  const [invoiceDoc, setInvoiceDoc] = useState<ExistingStiltInvoice | null>(
    invoiceDocFromState,
  );
  const [isSendingEmailLoading, setIsSendingEstimateLoading] =
    useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  // TODO: replace all these `isBusy...` states with a single piece of state
  const [isBusyRejecting, setIsBusyRejecting] = useState<boolean>(false);
  const [isBusyRevertingToDraft, setIsBusyRevertingToDraft] =
    useState<boolean>(false);
  const [isBusyDuplicating, setIsBusyDuplicating] = useState<boolean>(false);
  const [isBusyApproving, setIsBusyApproving] = useState<boolean>(false);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<boolean>(false);
  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [isHandlePaymentDialogOpen2, setIsHandlePaymentDialogOpen2] =
    useState(false);
  const [isViewInvoiceDialogOpen, setIsViewInvoiceDialogOpen] = useState(false);
  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);
  const [editCustomerDialogOpen, setEditCustomerDialogOpen] =
    useState<boolean>(false);
  const [handleSendEstimateDialogOpen, setHandleSendEstimateDialogOpen] =
    useState<boolean>(false);
  const [editNotesDialogOpen, setEditNotesDialogOpen] =
    useState<boolean>(false);
  const [editInternalNotesDialogOpen, setEditInternalNotesDialogOpen] =
    useState<boolean>(false);
  const [showEditGlobalDiscount, setShowEditGlobalDiscount] =
    useState<boolean>(false);
  const [isDiscountLoading, setIsDiscountLoading] = useState<boolean>(false);
  const [editLineItems, setEditLineItems] = useState<boolean>(false);
  const [estimateItemList, setEstimateItemList] = useState<
    ExistingEstimateItem[]
  >([]);
  const [estimateDoc, setEstimateDoc] = useState<ExistingEstimate | null>(null);
  const [taskDoc, setTaskDoc] = useState<ExistingTask | null>(null);
  const [commissionAdjustments, setCommissionAdjustments] = useState<
    ExistingCommissionAdjustment[]
  >([]);
  const [signatures, setSignatures] = useState<ExistingSignature[]>([]);

  const [isSavingListItemUpdates, setIsSavingListItemUpdates] =
    useState<boolean>(false);
  const [pbItemsFromTypesense, setPbItemsFromTypesense] = useState<
    ExistingPriceBookItem[]
  >([]);
  const [customerDoc, setCustomerDoc] = useState<ExistingCustomer | null>(
    customerDocFromState,
  );
  const [customerLocationDoc, setCustomerLocationDoc] =
    useState<ExistingCustomerLocation | null>(customerLocationFromState);

  const [invoicePaymentLink, setInvoicePaymentLink] = useState<string | null>(
    null,
  );
  const [showCommissionDialog, setShowCommissionDialog] =
    useState<boolean>(false);
  const [selectedCommissionAdjustmentDoc, setSelectedCommissionAdjustmentDoc] =
    useState<ExistingCommissionAdjustment | null>(null);
  const [defaultPaymentTerms, setDefaultPaymentTerms] = useState<string | null>(
    null,
  );
  const [customerTemporaryMemberships, setCustomerTemporaryMemberships] =
    useState<Record<string, any>[]>([]);
  const [customerMembershipIds, setCustomerMembershipIds] = useState<string[]>(
    [],
  );
  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] =
    useState<boolean>(false);
  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
    DateTime.now(),
  );
  const [temporaryEstimateItemList, setTemporaryEstimateItemList] = useState<
    TemporaryEstimateItem[]
  >([]);
  const [baseEstimateItemList, setBaseEstimateItemList] = useState<
    TemporaryEstimateItem[]
  >([]);
  const [fieldEstimateItemList, setFieldEstimateItemList] = useState<
    TemporaryEstimateItem[]
  >([]);
  const [
    priceBookItemsWithCommissionOverrides,
    setPriceBookItemsWithCommissionOverrides,
  ] = useState<ExistingPriceBookItem[]>([]);
  const [estimateItemPhotos, setEstimateItemPhotos] = useState<
    ExistingStiltPhoto[]
  >([]);

  const customerMembershipIdsCount = getMembershipIdsCount(
    customerMembershipIds,
  );

  const existingCustomerMembershipIds: string[] | undefined =
    customerDoc?.customData["membershipTemplateIDs"];

  const emailList = getEmailList(null, customerDoc);

  useEffect(() => {
    function getTask() {
      if (!estimateDoc?.taskID) return undefined;
      const unsubscribe = DbRead.tasks.subscribeOneByID(
        siteKey,
        estimateDoc?.taskID,
        setTaskDoc,
      );
      return unsubscribe;
    }

    const unsubscribeFn = getTask();
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateDoc, siteKey]);

  useEffect(() => {
    function getInvoiceDoc() {
      if (!estimateDoc) return undefined;
      const unsubscribe = DbRead.invoices.subscribeByEstimateID(
        siteKey,
        estimateDoc.id,
        setInvoiceDoc,
      );
      return unsubscribe;
    }

    const unsubscribeFn = getInvoiceDoc();
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateDoc, siteKey]);

  useEffect(() => {
    async function getEstimateItemPhotos() {
      if (!siteKeyDoc) return;

      const photos: ExistingStiltPhoto[] = [];
      for (const ei of estimateItemList) {
        if (ei.id) {
          const eiPhotos = await DbRead.photos.getByEstimateItemID({
            siteKey: siteKeyDoc.id,
            estimateItemID: ei.id,
          });
          photos.push(...eiPhotos);
        }
      }
      setEstimateItemPhotos(photos);
    }

    getEstimateItemPhotos();
  }, [siteKeyDoc, estimateItemList]);

  useEffect(() => {
    function getEstimate(estimateID: string) {
      const unsubscribe = DbRead.estimates.subscribeByEstimateId({
        estimateId: estimateID,
        siteKey: siteKey,
        onChange: setEstimateDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getEstimate(estimateID);
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateID, siteKey]);

  useEffect(() => {
    function getEstimateItems(estimateID: string) {
      const unsubscribe = DbRead.estimateItems.subscribeByEstimateId({
        estimateId: estimateID,
        siteKey: siteKey,
        onChange: setEstimateItemList,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getEstimateItems(estimateID);
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateDoc, estimateID, siteKey]);

  useEffect(() => {
    function getCustomer() {
      if (!estimateDoc) return undefined;
      const unsubscribe = DbRead.customers.subscribe({
        customerID: estimateDoc.customerID,
        siteKey: siteKey,
        onChange: setCustomerDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomer();
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateDoc, siteKey]);

  useEffect(() => {
    function getCustomerLocation() {
      if (!estimateDoc) return undefined;
      const unsubscribe = DbRead.customerLocations.subscribe({
        customerLocationID: estimateDoc.customerLocationID,
        siteKey: siteKey,
        onChange: setCustomerLocationDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerLocation();
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateDoc, siteKey]);

  useEffect(() => {
    async function getSignatures() {
      if (!estimateID) return;
      const signatures = await DbRead.signatures.get({
        siteKey: siteKey,
        estimateID: estimateID,
      });
      setSignatures(signatures);
    }

    getSignatures();
  }, [estimateID, siteKey]);

  useEffect(() => {
    const isEstimateItemListTheSame = isEqual(
      estimateItemList,
      temporaryEstimateItemList,
    );
    if (!isEstimateItemListTheSame) {
      const tempItem = cloneDeep(estimateItemList);
      setTemporaryEstimateItemList(tempItem);
    }
  }, [estimateItemList]);

  // Get the updated list of base and field estimateItems for commissions whenever the temporaryEstimateItems is changed/updated
  useEffect(() => {
    async function getBaseAndFieldEstimateItems() {
      // Don't continue if there are no commissions for the siteKey
      if (!siteKeyDoc?.customizations.commissions) return;

      // Since commissions are compared against the task, if there's no task
      // then assume all items are at base commissions
      if (!estimateDoc?.taskID) {
        setBaseEstimateItemList(temporaryEstimateItemList);
        setFieldEstimateItemList(temporaryEstimateItemList);
        return;
      }

      const allEstimates = await DbRead.estimates.getByTaskId(
        siteKeyDoc.id,
        estimateDoc?.taskID,
      );

      // Upsell commissions only count when a technician has duplicated an
      // estimate. If there's only 1 estimate for this task then there are no
      // upsells, so we can treat all items as base items
      if (allEstimates.length === 1) {
        setBaseEstimateItemList(temporaryEstimateItemList);
        setFieldEstimateItemList(temporaryEstimateItemList);
        return;
      }

      // If we are viewing a locked estimate, then we will treat it as base
      if (estimateDoc.status === "locked") {
        setBaseEstimateItemList(temporaryEstimateItemList);
        setFieldEstimateItemList(temporaryEstimateItemList);
        return;
      }

      // If there are multiple estimates for this task, then we need to apply
      // logic to determine which estimate is base and which is upsell.
      const lockedEstimates = allEstimates.filter((e) => e.status === "locked");

      // Upsell estimate (or other unhandled scenario)
      if (lockedEstimates.length === 1) {
        const baseEstimateItems = await DbRead.estimateItems.getByEstimateId(
          siteKey,
          lockedEstimates[0].id,
        );
        setBaseEstimateItemList(baseEstimateItems);
        setFieldEstimateItemList(temporaryEstimateItemList);
        return;
      }

      // Fallback, just treat all as base:
      setBaseEstimateItemList(temporaryEstimateItemList);
      setFieldEstimateItemList(temporaryEstimateItemList);
      return;
    }

    getBaseAndFieldEstimateItems();
  }, [temporaryEstimateItemList, siteKeyDoc]);

  useEffect(() => {
    async function getPriceBookItemsWithCommissionOverrides() {
      if (!siteKeyDoc) return;

      if (!siteKeyDoc.customizations.commissions) return;

      const pbItems = await DbRead.priceBookItems.getAllWithCommissionsOverride(
        siteKeyDoc.id,
      );

      setPriceBookItemsWithCommissionOverrides(pbItems);
    }

    getPriceBookItemsWithCommissionOverrides();
  }, [siteKeyDoc]);

  useEffect(() => {
    async function getPBItems() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );
      if (
        siteKeyDoc?.customizations.filterPriceBookItemsByLocationID === true &&
        estimateDoc?.locationID != null
      ) {
        const filterPBItems = pbItems.filter(
          (item) =>
            item.locationID === estimateDoc?.locationID ||
            item.locationID === null,
        );

        setPbItemsFromTypesense(filterPBItems);
      } else {
        setPbItemsFromTypesense(pbItems);
      }
    }

    getPBItems();
  }, [typesenseSearchKey, estimateDoc]);

  useEffect(() => {
    function subscribeCommissionAdjustments() {
      if (!estimateDoc) return undefined;
      const unsubscribe = DbRead.commissionAdjustments.subscribeByEstimateID({
        siteKey,
        estimateID: estimateDoc.id,
        onChange: setCommissionAdjustments,
      });
      return unsubscribe;
    }

    const unsubscribeFn = subscribeCommissionAdjustments();
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateDoc, siteKey]);

  const readableStatus = estimateDoc
    ? getEstimateStatus(estimateDoc.status)
    : "";

  // For breadcrumbs
  const home = {
    name: "List of All Customers",
    href: CUSTOMERS_URL,
    current: false,
  };
  const pages = [
    {
      name: customerDoc?.name,
      href: `${CUSTOMERS_URL}/${customerDoc?.id}`,
      current: false,
    },
    {
      name:
        estimateDoc && estimateDoc.estimateNumber
          ? `# ${estimateDoc.estimateNumber}`
          : convertToReadableTimestamp(estimateDoc?.timestampCreated),
      href: `${CUSTOMERS_URL}/${customerDoc?.id}`,
      current: true,
    },
  ];

  const onFilterTextBoxChanged = useCallback(
    async (searchTerm: string) => {
      if (!typesenseSearchKey) return;

      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        searchTerm,
      );
      if (
        siteKeyDoc?.customizations.filterPriceBookItemsByLocationID === true &&
        estimateDoc?.locationID != null
      ) {
        const filterPBItems = pbItems.filter(
          (item) =>
            item.locationID === estimateDoc.locationID ||
            item.locationID === null,
        );
        setPbItemsFromTypesense(filterPBItems);
      } else {
        setPbItemsFromTypesense(pbItems);
      }
    },
    [
      typesenseSearchKey,
      siteKeyDoc?.customizations.filterPriceBookItemsByLocationID,
      estimateDoc?.locationID,
    ],
  );

  /* MUTATIONS */
  /**
   * For adding a price book item on DB
   */
  const mutateAddCommissionAdjustment = useMutation(
    async (args: {
      validCommissionAdjustment: CommissionAdjustment_CreateAPI;
    }) => {
      await DbWrite.commissionAdjustments.create(
        args.validCommissionAdjustment,
      );
    },
  );

  /**
   * For edit an existing price book item on the DB
   */
  const mutateEditCommissionAdjustment = useMutation(
    async (args: {
      editCommissionAdjustment: CommissionAdjustment_UpdateAPI;
    }) => {
      await DbWrite.commissionAdjustments.update(args.editCommissionAdjustment);
    },
  );

  const mutateDeleteEstimateItem = useMutation(
    async (args: { estimateItemID: ExistingEstimateItem["id"] }) => {
      await DbWrite.estimateItems.delete(siteKey, args.estimateItemID);
    },
  );

  const mutateUpdateEstimateItem = useMutation(
    async (args: {
      editEstimate: ExistingEstimateUpdate;
      validListItemUpdate: EstimateItem_UpdateAPI[];
      validNewListItem: EstimateItem_CreateAPI[];
    }) => {
      if (
        args.validListItemUpdate.length !== 0 &&
        args.validNewListItem.length === 0
      ) {
        const promises = [
          DbWrite.estimates.update(args.editEstimate),
          DbWrite.estimateItems.update(args.validListItemUpdate),
        ];
        await Promise.all(promises);
      } else if (
        args.validListItemUpdate.length === 0 &&
        args.validNewListItem.length !== 0
      ) {
        const promises = [
          DbWrite.estimates.update(args.editEstimate),
          DbWrite.estimateItems.create(args.validNewListItem),
        ];
        await Promise.all(promises);
      } else {
        const promises = [
          DbWrite.estimates.update(args.editEstimate),
          DbWrite.estimateItems.update(args.validListItemUpdate),
          DbWrite.estimateItems.create(args.validNewListItem),
        ];
        await Promise.all(promises);
      }
    },
  );

  const mutateUpdateEstimate = useMutation(
    async (args: { editEstimate: ExistingEstimateUpdate }) => {
      await DbWrite.estimates.update(args.editEstimate);
    },
  );

  const mutateCreateInvoice = useMutation(
    async (args: { siteKey: string; estimateID: string; version: string }) => {
      return await DbWrite.invoices.create({
        siteKey: args.siteKey,
        estimateID: args.estimateID,
        version: args.version,
        platform: "web",
      });
    },
  );

  /**
   * For delete an existing customer on the DB
   */
  const mutateDeleteEstimate = useMutation(
    async (args: { estimateIDToDelete: ExistingEstimate["id"] }) =>
      await DbWrite.estimates.delete(siteKey, args.estimateIDToDelete),
    {
      onSuccess: async () => {
        if (customerDoc) {
          handleGoToCustomerPage(customerDoc.id);
        }
      },
    },
  );

  const mutateEditCustomer = useMutation(
    async (args: { editCustomer: ExistingCustomerUpdate }) => {
      await DbWrite.customers.update(args.editCustomer);
    },
  );
  //
  // const mutateAddMemberships = useMutation(
  //   async (args: { validMembershipList: Membership_CreateAPI[] }) => {
  //     await DbWrite.memberships.create(args.validMembershipList);
  //   },
  // );
  // const mutateDeleteMemberships = useMutation(
  //   async (args: { membershipIdsToDelete: Membership_DeleteAPI[] }) => {
  //     if (siteKeyDoc) {
  //       args.membershipIdsToDelete.forEach(async (membershipID) => {
  //         await DbWrite.memberships.delete(siteKeyDoc.id, membershipID);
  //       });
  //     }
  //   },
  // );

  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
  );

  /* FUNCTIONS */
  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!invoiceDoc) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      invoiceDoc,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: invoiceDoc.id,
      refPath: invoiceDoc.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    logger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });
      logger.debug("Invoice has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.successfulUpdate(
          `Payment terms for Invoice #${invoiceDoc.invoiceNumber}`,
        ),
        type: "success",
      });
      setIsViewInvoiceDialogOpen(false);
    } catch (error) {
      logger.error(`An error occurred during handleSaveUpdatedInvoice`, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  async function getEstimatePDF() {
    if (!siteKeyDoc || !estimateID) return;
    await generatePDF.estimate(siteKeyDoc, [estimateID]);
  }

  //TODO: dry this up from elsewhere
  async function handleEditCustomer(updateCustomer: customerWithoutTimestamps) {
    if (customerDoc == null) {
      return;
    }

    const initialValuesForCustomer = cloneDeep(customerDoc);

    // updateCustomer.customData["membershipTemplateIDs"] = customerMembershipIds;

    /* check the difference between the initial customer doc and the updated one */
    const diffCustomerValues: DocumentData = diffObjects(
      initialValuesForCustomer,
      updateCustomer,
    ).diff;

    if (Object.keys(diffCustomerValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const validateEditCustomer: ExistingCustomerUpdate = {
      ...diffCustomerValues,
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };

    // const { newMembershipIds, deletedMembershipIds } = getUpdatesOnMemberships(
    //   initialValuesForCustomer.customData["membershipTemplateIDs"],
    //   customerMembershipIds,
    // );
    //
    // const validatedMembershipList: Membership_CreateAPI[] = [];
    //
    // /* for newMembershipIds array, needs to write the new docs to DB */
    // if (newMembershipIds.length > 0 && siteKeyDoc) {
    //   const newMembershipDocList: Membership_CreateAPI[] = newMembershipIds.map(
    //     (newMembershipId: string) => {
    //       const currentMembershipTemplate = membershipTemplateList.find(
    //         (membershipTemplate) => membershipTemplate.id === newMembershipId,
    //       );
    //
    //       return {
    //         customerID: customerDoc.id,
    //         customerLocationID: null,
    //         membershipTemplateID: newMembershipId,
    //         status: "awaitingPayment",
    //         notes: null,
    //
    //         createdBy: firebaseUser.uid,
    //         lastModifiedBy: firebaseUser.uid,
    //         siteKey: siteKeyDoc.id,
    //         frequency: currentMembershipTemplate
    //           ? currentMembershipTemplate.frequency
    //           : "indefinite",
    //       };
    //     },
    //   );
    //   const validObjectList = newMembershipDocList.map((membership) =>
    //     MembershipManager.parseCreate(membership),
    //   );
    //   validatedMembershipList.push(...validObjectList);
    // }
    //
    // /* for deletedMembershipIds array, needs to get the membershipID */
    // const membershipIdsToDelete: string[] = [];
    // if (deletedMembershipIds.length !== 0 && siteKeyDoc) {
    //   const deletedIdsCount = getMembershipIdsCount(deletedMembershipIds);
    //   for (const [templateId, quantity] of Object.entries(deletedIdsCount)) {
    //     const membershipDocs = await DbRead.memberships.getByTemplateId(
    //       siteKeyDoc.id,
    //       customerDoc.id,
    //       null,
    //       templateId,
    //     );
    //     if (membershipDocs.length > 0) {
    //       for (let i = 0; i < quantity; i++) {
    //         membershipIdsToDelete.push(membershipDocs[i].id);
    //       }
    //     }
    //   }
    // }

    try {
      // if (
      //   validatedMembershipList.length !== 0 &&
      //   membershipIdsToDelete.length !== 0
      // ) {
      //   /* case: edit customer, add new membership docs & delete membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     // mutateAddMemberships.mutateAsync({
      //     //   validMembershipList: validatedMembershipList,
      //     // }),
      //     // mutateDeleteMemberships.mutateAsync({
      //     //   membershipIdsToDelete: membershipIdsToDelete,
      //     // }),
      //   ];
      //   await Promise.all(promises);
      // } else if (
      //   validatedMembershipList.length === 0 &&
      //   membershipIdsToDelete.length !== 0
      // ) {
      //   /* case: edit customer & delete membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     // mutateDeleteMemberships.mutateAsync({
      //     //   membershipIdsToDelete: membershipIdsToDelete,
      //     // }),
      //   ];
      //   await Promise.all(promises);
      // } else if (
      //   validatedMembershipList.length !== 0 &&
      //   membershipIdsToDelete.length === 0
      // ) {
      //   /* case: edit customer & add membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     mutateAddMemberships.mutateAsync({
      //       validMembershipList: validatedMembershipList,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else {
      //
      // }
      /* case: edit customer */
      await mutateEditCustomer.mutateAsync({
        editCustomer: validateEditCustomer,
      });
      logger.debug("Customer has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          updateCustomer.name ?? initialValuesForCustomer.name,
        ),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewCustomer`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  function removeEstimateItemFromList(
    estimateItemToBeDeleted: TemporaryEstimateItem,
  ): void {
    const filteredList = temporaryEstimateItemList.filter(
      (estimateItem) => estimateItem.id !== estimateItemToBeDeleted.id,
    );
    setTemporaryEstimateItemList(filteredList);
  }

  function handleAddEstimateItem(tempEstimateItem: TemporaryEstimateItem) {
    temporaryEstimateItemList.push(tempEstimateItem);
    setTemporaryEstimateItemList(temporaryEstimateItemList);
  }

  function editEstimateItemFromList(
    editedEstimateItem: TemporaryEstimateItem,
  ): void {
    const indexOfEstimateItem = temporaryEstimateItemList.findIndex(
      (estimateItem) => estimateItem.id === editedEstimateItem.id,
    );

    if (indexOfEstimateItem >= 0) {
      setTemporaryEstimateItemList((oldEstimateList) => {
        oldEstimateList[indexOfEstimateItem] = editedEstimateItem;
        return [...oldEstimateList];
      });
    }
  }

  function turnItemToBeEditable(itemToBeEdited: TemporaryEstimateItem): void {
    const indexOfEstimateItem = temporaryEstimateItemList.findIndex(
      (estimateItem) => estimateItem.id === itemToBeEdited.id,
    );
    if (indexOfEstimateItem >= 0) {
      setTemporaryEstimateItemList((oldEstimateList) => {
        oldEstimateList[indexOfEstimateItem].toBeEdited = true;
        return [...oldEstimateList];
      });
    }
  }

  async function handleDeleteEstimate() {
    if (!estimateDoc) return;

    try {
      mutateDeleteEstimate.mutateAsync({
        estimateIDToDelete: estimateDoc.id,
      });
    } catch (error) {
      logger.error(`An error occurred during handleDeleteEstimate`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        type: "error",
        dialog: false,
      });
    }
  }

  async function handleDuplicateEstimate() {
    if (!estimateDoc) return;

    setIsBusyDuplicating(true);
    try {
      const newEstimateID = await DbWrite.estimates.duplicate(
        estimateDoc,
        estimateItemList,
        firebaseUser.uid,
        siteKey,
      );
      navigate(`${CUSTOMERS_URL}${ESTIMATES_URL_AUTH}/${newEstimateID}`);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.DUPLICATE_ESTIMATE_SUCCESS,
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleDuplicateEstimate`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsBusyDuplicating(false);
    }
  }

  async function handleCreateInvoice() {
    if (!estimateDoc) return;
    if (!isWhiteLabel(whiteLabel)) throw Error("Unexpected white label");
    setIsCreatingInvoice(true);
    try {
      const { paymentURL, invoiceData } = await mutateCreateInvoice.mutateAsync(
        {
          siteKey,
          estimateID: estimateDoc.id,
          version: whiteLabel,
        },
      );

      setInvoicePaymentLink(paymentURL);
      setInvoiceDoc(invoiceData);
      setIsHandlePaymentDialogOpen2(true);
    } catch (error) {
      logger.error("error on handleCreateInvoice", error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
    setIsCreatingInvoice(false);
  }

  function openEditCustomerDialog() {
    setEditCustomerDialogOpen(true);
  }

  function onEditCommissionAdjustment(
    commissionAdjustment: ExistingCommissionAdjustment,
  ) {
    setSelectedCommissionAdjustmentDoc(commissionAdjustment);
    setShowCommissionDialog(true);
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (invoicePaymentLink != null) {
      goToPaymentPage(invoicePaymentLink);
    } else {
      if (!invoiceDoc) return;
      const paymentLink = await generatePaymentUniqueLink(
        siteKey,
        invoiceDoc.id,
        "web",
      );
      if (paymentLink) {
        goToPaymentPage(paymentLink);
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.ERROR_PAYMENT_LINK,
          dialog: false,
          type: "error",
        });
      }
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    emailAddresses: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!customerDoc || !invoiceDoc) return;
    if (emailAddresses.length === 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        type: "error",
        dialog: false,
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      invoiceDoc.id,
      "email",
    );
    if (!paymentLink) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey: siteKey,
        invoiceURL: paymentLink,
        customerEmailList: emailAddresses,
        includeJobPhotos: includeJobPhotos,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_INVOICE,
        dialog: false,
        type: "error",
      });
      logger.error("error emailing customer invoice", e);
    }
  }

  async function emailReceipt(emailList: string[]): Promise<void> {
    if (!invoiceDoc) return;
    if (emailList.length === 0) {
      throw Error("emailReceipt given empty email list");
    }
    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: siteKey,
        invoiceID: invoiceDoc.id,
        customerEmailList: emailList,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      // logger.error("emailReceipt failure", e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT,
        dialog: false,
        type: "error",
      });
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!invoiceDoc) return;
    if (invoiceDoc.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await emailReceipt(emails);
    }
  }

  async function sendEstimateViaEmail(
    emailAddressList: string[],
    emailBody: string,
    emailSubject: string,
  ) {
    setHandleSendEstimateDialogOpen(false);
    if (typeof estimateID !== "string") {
      throw new Error(`estimateID was not a string: ${estimateID}`);
    }

    if (!customerDoc) return;

    setIsSendingEstimateLoading(true);
    if (isWhiteLabel(whiteLabel)) {
      try {
        await DbWrite.estimates.sendViaEmail({
          siteKey,
          estimateID,
          customerEmailList: emailAddressList,
          emailSubject,
          emailBody,
          version: whiteLabel,
        });
        addToastMessage({
          id: createToastMessageID(),
          message: strings.buttons.ESTIMATE_SENT,
          dialog: false,
          type: "success",
        });
      } catch (error) {
        logger.error("error on sendEstimateViaEmail", error);
      }
    } else {
      throw new Error(`Unexpected white label`);
    }
    setHandleSendEstimateDialogOpen(false);
    setIsSendingEstimateLoading(false);
  }

  // async function sendEstimateViaSMS() {
  //   if (!customerDoc) return;
  //   if (!customerDoc.phone || !customerDoc.phone[0]) {
  //     addToastMessage({
  //       id: createToastMessageID(),
  //       message: strings.NO_PHONE_FOR_CUSTOMER,
  //       dialog: false,
  //       type: "error",
  //     });
  //     return;
  //   }
  // }

  async function handleEditNotes(value: string | null) {
    if (!estimateDoc || invoiceDoc) return;

    const notesUpdate: DocumentData = {
      notes: value,
      lastModifiedBy: firebaseUser.uid,
    };

    const initialNotesValue = {
      notes: estimateDoc.notes,
    };

    const diffEstimateValues: DocumentData = diffObjects(
      initialNotesValue,
      notesUpdate,
    ).diff;

    if (Object.keys(diffEstimateValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const validateEditEstimate: ExistingEstimateUpdate = {
      ...diffEstimateValues,
      id: estimateDoc.id,
      refPath: estimateDoc.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };

    try {
      await mutateUpdateEstimate.mutateAsync({
        editEstimate: validateEditEstimate,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.SAVED_CHANGES,
        dialog: false,
        type: "success",
      });
    } catch (error) {
      devLogger.error(`Error on saving notes:`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(`notes`),
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleEditInternalNotes(value: string | null) {
    if (!estimateDoc) return;

    const internalNotesUpdate: DocumentData = {
      internalNotes: value,
      lastModifiedBy: firebaseUser.uid,
    };

    const initialNotesValue = {
      internalNotes: estimateDoc.internalNotes,
    };

    const diffEstimateValues: DocumentData = diffObjects(
      initialNotesValue,
      internalNotesUpdate,
    ).diff;

    if (Object.keys(diffEstimateValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const validateEditEstimate: ExistingEstimateUpdate = {
      ...diffEstimateValues,
      id: estimateDoc.id,
      refPath: estimateDoc.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };

    try {
      await mutateUpdateEstimate.mutateAsync({
        editEstimate: validateEditEstimate,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.SAVED_CHANGES,
        dialog: false,
        type: "success",
      });
    } catch (error) {
      devLogger.error(`Error on saving internal notes:`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(`internal notes`),
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleEditEstimateDiscount(value: number | null) {
    if (!estimateDoc || invoiceDoc) return;
    setShowEditGlobalDiscount(false);
    setIsDiscountLoading(true);

    const discountUpdate: DocumentData = {
      discount: value,
      lastModifiedBy: firebaseUser.uid,
    };

    const initialDiscountValue = {
      discount: estimateDoc.discount,
    };

    const diffEstimateValues: DocumentData = diffObjects(
      initialDiscountValue,
      discountUpdate,
    ).diff;

    if (Object.keys(diffEstimateValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const validateEditEstimate: ExistingEstimateUpdate = {
      ...diffEstimateValues,
      id: estimateDoc.id,
      refPath: estimateDoc.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };

    try {
      await mutateUpdateEstimate.mutateAsync({
        editEstimate: validateEditEstimate,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.SAVED_CHANGES,
        dialog: false,
        type: "success",
      });
    } catch (error) {
      devLogger.error(`Error on saving discount:`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(`discount`),
        dialog: false,
        type: "error",
      });
    } finally {
      setIsDiscountLoading(false);
    }
  }

  async function handleSaveListItemUpdate() {
    if (!estimateDoc) return;
    setIsSavingListItemUpdates(true);

    /* Get the list of existing items that have been updated. Existing items are
     items that have been saved to the database, and these items will have a
     refPath. Reminder, we are pre-adding id using randomDocID.get(), so all items
     will have an id even if they haven't been saved to the db! But only
     existing, saved items will have a refPath */
    const existingEstimateItemList = temporaryEstimateItemList.filter(
      (item) => typeof item.refPath === "string",
    );

    const diffExistingEstimateItemList: Partial<ExistingEstimateItem | null>[] =
      existingEstimateItemList.map((item) => {
        const initialEstimateItem = estimateItemList.filter(
          (initialItem) => initialItem.id === item.id,
        );
        const diffExistingEstimateItemValues: Partial<ExistingEstimateItem> =
          diffObjects(initialEstimateItem[0], item).diff;

        if (Object.values(diffExistingEstimateItemValues).length === 0) {
          return null;
        } else {
          diffExistingEstimateItemValues["id"] = initialEstimateItem[0].id;
          diffExistingEstimateItemValues["refPath"] =
            initialEstimateItem[0].refPath;
        }
        return diffExistingEstimateItemValues;
      });

    const listItemToValidate = diffExistingEstimateItemList.filter(
      (item) => item != null,
    );

    let validatedEditEstimateItems = [];
    const updateEstimateItemList: EstimateItem_UpdateAPI[] =
      listItemToValidate.map((estimateItem) => {
        return {
          ...estimateItem,
          id: estimateItem?.id as string,
          lastModifiedBy: firebaseUser.uid,
        };
      });

    validatedEditEstimateItems = updateEstimateItemList.map((estimateItem) =>
      EstimateItemManager.parseUpdate(estimateItem),
    );
    logger.info(
      "Validated estimate item list update:",
      validatedEditEstimateItems,
    );

    /* deleted items */
    const deletedLineItems = estimateItemList.filter((initialItem) => {
      return !existingEstimateItemList.some(
        (item) => initialItem.id === item.id,
      );
    });

    /* new estimate item */
    const temporaryLineItems = temporaryEstimateItemList.filter(
      (item) => !item.refPath,
    );

    let validateNewEstimateItems = [];
    const newEstimateItemList: EstimateItem_CreateAPI[] =
      temporaryLineItems.map((estimateItem) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { toBeEdited, ...rest } = estimateItem;
        return {
          ...rest,
          locationID: estimateDoc.locationID,
          createdBy: firebaseUser.uid,
          lastModifiedBy: firebaseUser.uid,
          estimateID: estimateDoc.id,
          siteKey: siteKey,
        };
      });

    validateNewEstimateItems = newEstimateItemList.map((estimateItem) =>
      EstimateItemManager.parseCreate(estimateItem),
    );
    logger.info("Validated estimate item list new:", validateNewEstimateItems);

    const validateEditEstimate: ExistingEstimateUpdate = {
      id: estimateDoc.id,
      refPath: estimateDoc.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };

    try {
      if (deletedLineItems.length !== 0) {
        await Promise.all([
          mutateUpdateEstimate.mutateAsync({
            editEstimate: validateEditEstimate,
          }),
          deletedLineItems.forEach((item) => {
            mutateDeleteEstimateItem.mutateAsync({
              estimateItemID: item.id,
            });
          }),
        ]);
      }

      if (
        validatedEditEstimateItems.length === 0 &&
        validateNewEstimateItems.length === 0
      ) {
        return;
      }

      await mutateUpdateEstimateItem.mutateAsync({
        editEstimate: validateEditEstimate,
        validListItemUpdate: validatedEditEstimateItems,
        validNewListItem: validateNewEstimateItems,
      });

      addToastMessage({
        id: createToastMessageID(),
        message: strings.SAVED_CHANGES,
        dialog: false,
        type: "success",
      });
    } catch (error) {
      devLogger.error(`Error on updating line items:`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(`line items`),
        dialog: false,
        type: "error",
      });
    } finally {
      setIsSavingListItemUpdates(false);
      setEditLineItems(false);
    }
  }

  async function applyManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (!invoiceDoc) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: selectedDateTime.toISO(),
      customerID: invoiceDoc.customerID,
      invoiceID: invoiceDoc.id,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: siteKey,
      billToCustomerID: invoiceDoc.billToCustomerID,
      locationID: invoiceDoc.locationID,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    logger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      if (
        validPaymentData.amount === invoiceDoc.amountDue &&
        invoiceDoc.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (invoiceDoc.email !== null) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            type: "success",
            dialog: true,
          });
        } else {
          addToastMessage({
            id: createToastMessageID(),
            dialog: true,
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            type: "info",
          });
        }
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: true,
          type: "success",
        });
      }
    } catch (err) {
      logger.error("handleRecordManualPayment", err);
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        type: "error",
      });
      return;
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKey,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  /* COMPONENTS */
  const estimateInfo = estimateDoc && (
    <div>
      <div className="px-0 italic">{`Created By ${userDisplayNamesMap[estimateDoc.createdBy]} on ${convertToReadableTimestamp(estimateDoc?.timestampCreated)}`}</div>
      {estimateDoc?.timestampSentToCustomer && (
        <div className="px-0 italic">{`Last Sent to Customer: ${convertToReadableTimestamp(estimateDoc.timestampSentToCustomer)}`}</div>
      )}
      {estimateDoc?.timestampApprovedByCustomer && (
        <div className="px-0 italic">{`Approved On: ${convertToReadableTimestamp(estimateDoc.timestampApprovedByCustomer)}`}</div>
      )}
    </div>
  );

  const editCustomerDialog = customerDoc && (
    <EditCustomerDialog
      isDialogOpen={editCustomerDialogOpen}
      addMembershipsButton={null}
      closeDialog={() => {
        const tempData = getTemporaryDataForEditMemberships(
          customerMembershipIdsCount,
        );
        setCustomerTemporaryMemberships(tempData);
        setCustomerMembershipIds(existingCustomerMembershipIds ?? []);
        setEditCustomerDialogOpen(false);
      }}
      handleEditCustomer={handleEditCustomer}
      customer={cloneDeep(customerDoc)}
    >
      {{}}
    </EditCustomerDialog>
  );

  const customerEstimateStatusButtons = !invoiceDoc && (
    <Fragment>
      {/* APPROVE ESTIMATE */}
      <ButtonColored
        isBusy={isBusyApproving}
        busyText={strings.buttons.BUSY_UPDATING}
        className={`w-full  ${
          estimateDoc?.status === EstimateStatus.APPROVED ? "bg-green-50" : ""
        }`}
        kind="info"
        onClick={async () => {
          setIsBusyApproving(true);
          if (estimateDoc) {
            const estimateUpdate: DocumentData = {
              status: EstimateStatus.APPROVED,
              lastModifiedBy: firebaseUser.uid,
            };

            const diffEstimateValues: DocumentData = diffObjects(
              estimateDoc,
              estimateUpdate,
            ).diff;

            const validateEditEstimate: ExistingEstimateUpdate = {
              ...diffEstimateValues,
              id: estimateDoc.id,
              refPath: estimateDoc.refPath,
              lastModifiedBy: firebaseUser.uid,
              timestampLastModified: Timestamp.now(),
            };

            try {
              await mutateUpdateEstimate.mutateAsync({
                editEstimate: validateEditEstimate,
              });
            } catch (error) {
              logger.error("error on approve estimate", error);
            } finally {
              setIsBusyApproving(false);
            }
          }
        }}
      >
        {strings.buttons.APPROVE_ESTIMATE}
      </ButtonColored>

      {/* REJECT ESTIMATE */}
      <ButtonColored
        isBusy={isBusyRejecting}
        busyText={strings.buttons.BUSY_UPDATING}
        className={`w-full ${
          estimateDoc?.status === EstimateStatus.REJECTED ? "bg-red-50" : ""
        }`}
        kind="danger"
        onClick={async () => {
          setIsBusyRejecting(true);
          if (estimateDoc) {
            const estimateUpdate: DocumentData = {
              status: EstimateStatus.REJECTED,
              lastModifiedBy: firebaseUser.uid,
            };
            const diffEstimateValues: DocumentData = diffObjects(
              estimateDoc,
              estimateUpdate,
            ).diff;

            const validateEditEstimate: ExistingEstimateUpdate = {
              ...diffEstimateValues,
              id: estimateDoc.id,
              refPath: estimateDoc.refPath,
              lastModifiedBy: firebaseUser.uid,
              timestampLastModified: Timestamp.now(),
            };

            try {
              await mutateUpdateEstimate.mutateAsync({
                editEstimate: validateEditEstimate,
              });
            } catch (error) {
              logger.error("error on approve estimate", error);
            } finally {
              setIsBusyRejecting(false);
            }
          }
        }}
      >
        {strings.buttons.REJECT_ESTIMATE}
      </ButtonColored>

      {/* BACK TO DRAFT */}
      <ButtonColored
        isBusy={isBusyRevertingToDraft}
        busyText={strings.buttons.BUSY_UPDATING}
        className={`w-full ${
          estimateDoc?.status === EstimateStatus.DRAFT ? "bg-grey-50" : ""
        }`}
        kind="gray"
        onClick={async () => {
          setIsBusyRevertingToDraft(true);
          if (estimateDoc) {
            const estimateUpdate: DocumentData = {
              status: EstimateStatus.DRAFT,
              lastModifiedBy: firebaseUser.uid,
            };
            const diffEstimateValues: DocumentData = diffObjects(
              estimateDoc,
              estimateUpdate,
            ).diff;

            const validateEditEstimate: ExistingEstimateUpdate = {
              ...diffEstimateValues,
              id: estimateDoc.id,
              refPath: estimateDoc.refPath,
              lastModifiedBy: firebaseUser.uid,
              timestampLastModified: Timestamp.now(),
            };

            try {
              await mutateUpdateEstimate.mutateAsync({
                editEstimate: validateEditEstimate,
              });
            } catch (error) {
              logger.error("error on approve estimate", error);
            } finally {
              setIsBusyRevertingToDraft(false);
            }
          }
        }}
      >
        {strings.buttons.REVERT_TO_DRAFT}
      </ButtonColored>
    </Fragment>
  );

  const errorMessage = (
    <ErrorMessage
      message={strings.MISSING_EMAIL}
      clearMessage={() => setDisplayError(false)}
    />
  );

  const actionButtons = (
    <div
      className={`flex w-full flex-col gap-4 place-self-center ${
        optionalDocs ? "mt-8 sm:grid sm:grid-cols-3" : "sm:place-self-end"
      }`}
    >
      {displayError ? errorMessage : null}
      <BaseButtonPrimary
        isBusy={isSendingEmailLoading}
        type="button"
        busyText={strings.buttons.BUSY_SENDING}
        onClick={() => setHandleSendEstimateDialogOpen(true)}
        className={`max-w-x w-full`}
      >
        {strings.buttons.SEND_ESTIMATE_TO_CUSTOMER}
      </BaseButtonPrimary>
      {taskDoc || invoiceDoc ? null : (
        <BaseButtonSecondary
          type="button"
          disabled={estimateDoc?.status === EstimateStatus.REJECTED}
          onClick={() => {
            navToCreateTask(
              null,
              customerDoc?.id,
              customerLocationDoc ?? undefined,
              estimateDoc ?? undefined,
            );
          }}
        >
          {strings.CREATE_TASK}
        </BaseButtonSecondary>
      )}
      {invoiceDoc && invoiceDoc.status !== StiltInvoiceStatus.CANCELED ? (
        <BaseButtonSecondary
          type="button"
          onClick={openViewInvoiceDialog}
          className="flex h-fit flex-col font-medium text-gray-700 sm:font-normal sm:text-gray-400"
        >
          View invoice created on
          <span className="text-gray-700">
            {convertFSTimestampToLuxonDT(
              invoiceDoc.timestampCreated,
            ).toISODate()}
          </span>
        </BaseButtonSecondary>
      ) : (
        <BaseButtonSecondary
          isBusy={isCreatingInvoice}
          type="button"
          busyText={strings.buttons.BUSY_CREATING}
          onClick={handleCreateInvoice}
        >
          {strings.CREATE_INVOICE}
        </BaseButtonSecondary>
      )}
      {invoiceDoc &&
      (invoiceDoc.status === "paid" ||
        invoiceDoc.status === "partiallyPaid") ? (
        <div className="flex flex-col text-center">
          {invoiceDoc.lastPaymentTimestamp != null ? (
            <Fragment>
              <span className="font-medium text-gray-700 sm:font-normal sm:text-gray-400">
                Invoice {getReadableStiltInvoiceStatus(invoiceDoc.status)} on
              </span>
              <span className="text-gray-700">
                {convertFSTimestampToLuxonDT(
                  invoiceDoc.lastPaymentTimestamp,
                ).toISODate()}
              </span>
            </Fragment>
          ) : (
            <span className="font-medium text-gray-700 sm:font-normal sm:text-gray-400">
              Invoice {getReadableStiltInvoiceStatus(invoiceDoc.status)}
            </span>
          )}
        </div>
      ) : null}
      {invoiceDoc &&
      invoiceDoc.status !== "paid" &&
      invoiceDoc.status !== "canceled" ? (
        <BaseButtonSecondary
          type="button"
          onClick={() => setIsHandlePaymentDialogOpen2(true)}
        >
          {strings.HANDLE_PAYMENT}
        </BaseButtonSecondary>
      ) : null}
      <BaseButtonSecondary
        isBusy={isBusyDuplicating}
        type="button"
        busyText={strings.buttons.BUSY_DUPLICATING}
        onClick={handleDuplicateEstimate}
      >
        {strings.DUPLICATE_ESTIMATE}
      </BaseButtonSecondary>
      {siteKeyDoc?.customizations.commissions && (
        <BaseButtonSecondary
          type="button"
          onClick={() => {
            setShowCommissionDialog(true);
          }}
        >
          {strings.COMMISSION_ADJUSTMENT}
        </BaseButtonSecondary>
      )}
      <div className={`flex items-center justify-start`}>
        <StyledTooltip title="Download PDF">
          <PDFIconWithSpinner onCreate={getEstimatePDF} />
        </StyledTooltip>
        {!invoiceDoc ? (
          <StyledTooltip title={strings.buttons.DELETE_ESTIMATE}>
            <TrashIconWithSpinner
              onDelete={handleDeleteEstimate}
              color="text-red-700 hover:bg-red-50"
            />
          </StyledTooltip>
        ) : null}
      </div>
    </div>
  );

  const taskInfoAndActionButtons = (
    <div
      className={`flex w-full flex-col gap-4 sm:grid ${
        optionalDocs ? "sm:grid-cols-3" : "sm: grid-cols-[3fr,1fr]"
      } `}
    >
      {optionalDocs ? null : (
        <Fragment>
          {taskDoc != null ? (
            <TaskDetailsInfoForEstimate
              taskDoc={taskDoc}
              displayName={userDisplayNamesMap[taskDoc.lastModifiedBy]}
              goToViewTask={() =>
                goToWorkRecordAndTasksPage(taskDoc.craftRecordID)
              }
            />
          ) : null}
        </Fragment>
      )}
      <span className={`${optionalDocs ? "col-span-3" : "col-start-2"} `}>
        {actionButtons}
      </span>
    </div>
  );

  const breadcrumbs = optionalDocs ? null : (
    <Breadcrumbs home={home} pages={pages} />
  );

  const displaySignatures = (
    <Fragment>
      {signatures.map((s) => {
        return (
          <div className="flex w-full flex-col items-end justify-end py-4">
            <img
              className="w-48"
              src={`data:image/png;base64,${s.imageData}`}
              alt="signature"
            />
            {s.note}
            <div>
              {DateTime.fromISO(
                s.timestampCreated.toDate().toISOString(),
              ).toFormat("LL/dd/yy hh:mm a")}
            </div>
          </div>
        );
      })}
    </Fragment>
  );

  const displayEstimateItems = (
    <Fragment>
      {editLineItems && (
        <div className="mb-4">
          <AddNewEstimateItemSelection
            onSearch={onFilterTextBoxChanged}
            PBItemQueryResultList={pbItemsFromTypesense}
            handleAddEstimateItem={handleAddEstimateItem}
            currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
          />
        </div>
      )}
      {temporaryEstimateItemList.map((estimateItem) => {
        if (estimateItem.toBeEdited) {
          return (
            <div key={estimateItem.id}>
              <EditEstimateItemLine
                PBItemQueryResultList={pbItemsFromTypesense}
                initialEstimateItemMembershipData={
                  estimateItem.customData?.membershipData ?? null
                }
                estimateItem={estimateItem}
                handleEditEstimateItem={editEstimateItemFromList}
                onSearch={onFilterTextBoxChanged}
                handleDeleteEstimateItem={removeEstimateItemFromList}
                currency={
                  siteKeyDoc?.customizations.accounting?.currency ?? "USD"
                }
              />
              <hr className="my-8 block w-full border border-gray-200" />
            </div>
          );
        } else {
          return (
            <div key={estimateItem.id}>
              <EstimateItemInline
                estimateItem={estimateItem}
                currency={
                  siteKeyDoc?.customizations.accounting?.currency ?? "USD"
                }
                photos={estimateItemPhotos.filter(
                  (p) => p.estimateItemID === estimateItem.id,
                )}
                handleDeleteEstimateItem={removeEstimateItemFromList}
                turnItemToBeEditable={turnItemToBeEditable}
                allowEdit={editLineItems}
              />
              <hr className="my-4 block w-full border border-gray-200" />
            </div>
          );
        }
      })}
    </Fragment>
  );

  function openViewInvoiceDialog(): void {
    if (!siteKeyDoc || !invoiceDoc) return;

    setViewInvoiceDialogProps({
      siteKeyID: siteKey,
      invoiceID: invoiceDoc.id,
      merchantName: siteKeyDoc.name,
      merchantLogoURL:
        siteKeyDoc.customizations.accounting?.merchantLogoURL ?? null,
    });
    setIsViewInvoiceDialogOpen(true);
  }

  const handleSendEstimateDialog = estimateDoc && (
    <HandleSendEmailDialog
      isDialogOpen={handleSendEstimateDialogOpen}
      closeDialog={() => setHandleSendEstimateDialogOpen(false)}
      sendEstimateOrPartsInfoViaEmail={sendEstimateViaEmail}
      // sendEstimateViaSMS={sendEstimateViaSMS}
      merchantName={siteKeyDoc?.name ?? ""}
      timestampSentToCustomer={estimateDoc.timestampSentToCustomer}
      customerEmailList={emailList}
      title={strings.SEND_ESTIMATE_TO_CUSTOMER}
      emailBody={`Thank you for choosing the services of ${siteKeyDoc?.name}.\n\nPlease tap on the link below to view the details of your estimate.`}
      emailSubject={`${siteKeyDoc?.name} sent you an estimate for the work requested.`}
      defaultIncludeJobPhotos={
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false
      }
    />
  );

  /* RENDER LOADING */
  if (
    !estimateDoc ||
    typesenseLoading ||
    !customerDoc ||
    !customerLocationDoc ||
    !siteKeyDoc
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const datePicker = invoiceDoc && (
    <DatePicker
      selected={selectedDateTime.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setSelectedDateTime(luxonDate);
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  /** when "handle payment" is clicked from inside the view invoice dialog. */
  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    invoiceDoc &&
    userPermissions &&
    customerDoc && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        closeDialog={() => setIsHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin}
        invoiceID={invoiceDoc.id}
        invoiceAmount={invoiceDoc.amountDue}
        invoiceStatus={invoiceDoc.status}
        invoiceSentToCustomer={invoiceDoc.timestampSentToCustomer}
        customer={customerDoc}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(invoiceDoc, customerDoc)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  /** when "handle payment" is clicked from estimate page. not inside a dialog */
  const handlePaymentDialog2 = isHandlePaymentDialogOpen2 &&
    invoiceDoc &&
    userPermissions &&
    customerDoc && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen2}
        closeDialog={() => {
          setIsHandlePaymentDialogOpen2(false);
          setInvoicePaymentLink(null);
        }}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin}
        invoiceID={invoiceDoc.id}
        invoiceAmount={invoiceDoc.amountDue}
        invoiceStatus={invoiceDoc.status}
        invoiceSentToCustomer={invoiceDoc.timestampSentToCustomer}
        customer={customerDoc}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(invoiceDoc, customerDoc)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  const dueDatePicker = invoiceDoc && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="flex items-center gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = invoiceDoc && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <DatePicker
        selected={
          selectedIssueDate
            ? selectedIssueDate.toJSDate()
            : DateTime.now().toJSDate()
        }
        onChange={(date: Date) => {
          const luxonDate = DateTime.fromJSDate(date);
          setSelectedIssueDate(luxonDate);
          setPaymentTerms(null);
        }}
        customInput={<SchedulingButton />}
      />
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = invoiceDoc && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={invoiceDoc}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  async function handleSaveNewCommissionAdjustment(
    partialCommissionAdjustment: CreateCommissionAdjustment,
  ) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    const newCommissionAdjustment: CommissionAdjustment_CreateAPI = {
      ...partialCommissionAdjustment,
      timestampApplied: partialCommissionAdjustment.timestampApplied
        .toDate()
        .toISOString(),
      siteKey: siteKey,
      estimateID: estimateID,
    };
    if (estimateDoc?.taskID) {
      newCommissionAdjustment.taskID = estimateDoc.taskID;
    }
    console.log(newCommissionAdjustment);

    //Validate
    const validatedData = CommissionAdjustmentManager.parseCreate(
      newCommissionAdjustment,
    );
    logger.info("Validated Commission Adjustment:", validatedData);

    //DB
    try {
      await mutateAddCommissionAdjustment.mutateAsync({
        validCommissionAdjustment: validatedData,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(strings.COMMISSION_ADJUSTMENT),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(
        `An error occurred during handleSaveNewCommissionAdjustment`,
        error,
      );
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
    setShowCommissionDialog(false);
  }

  async function handleEditCommissionAdjustment(
    updateCommissionAdjustment: Partial<ExistingCommissionAdjustment>,
  ) {
    if (firebaseUser == null) {
      return;
    }

    updateCommissionAdjustment["lastModifiedBy"] = firebaseUser.uid;

    // TODO: Add ID and refPath
    // diffPBItemCategoryValues["id"] = pBItemCategoryDoc.id;
    // diffPBItemCategoryValues["refPath"] = pBItemCategoryDoc.refPath;

    /* validate values from the form */
    const validData = CommissionAdjustmentManager.parseUpdate({
      ...updateCommissionAdjustment,
      timestampApplied: convertFSTimestampToLuxonDT(
        updateCommissionAdjustment.timestampApplied,
      ).toISO(),
    });

    try {
      await mutateEditCommissionAdjustment.mutateAsync({
        editCommissionAdjustment: validData,
      });
      logger.debug("Commission adjustment has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(strings.COMMISSION_ADJUSTMENT),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(
        `An error occurred during handleEditCommissionAdjustment`,
        error,
      );
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
    setSelectedCommissionAdjustmentDoc(null);
    setShowCommissionDialog(false);
  }

  const commissionDialog = siteKeyDoc.customizations.commissions &&
    userPermissions && (
      <AddEditCommissionAdjustmentDialog
        key={selectedCommissionAdjustmentDoc?.id}
        isDialogOpen={showCommissionDialog}
        userPermissions={userPermissions}
        commissionAdjustmentDoc={selectedCommissionAdjustmentDoc}
        closeDialog={() => {
          setSelectedCommissionAdjustmentDoc(null);
          setShowCommissionDialog(false);
        }}
        handleSaveNewCommissionAdjustment={handleSaveNewCommissionAdjustment}
        // handleDeleteCommissionAdjustment={handleSaveNewCommissionAdjustment}
        handleEditCommissionAdjustment={handleEditCommissionAdjustment}
      />
    );

  const viewInvoiceDialog = isViewInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        isOpen={isViewInvoiceDialogOpen}
        onClose={closeViewInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        editInvoice={handleEditInvoiceDialogOpen}
        sendEmail={emailInvoice}
        handlePayment={() => setIsHandlePaymentDialogOpen(true)}
        deleteInvoice={handleDeleteInvoice}
        userDisplayNamesMap={userDisplayNamesMap}
        handleDeletePayment={handleDeletePayment}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  function closeViewInvoiceDialog(): void {
    setIsViewInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    // try/catch is in the InvoiceSummary component; don't want it here
    return DbWrite.payments.issueRefund(siteKey, paymentID, refundAmount);
  }

  async function handleEditInvoiceDialogOpen() {
    if (invoiceDoc) {
      const customerDoc = await DbRead.customers.get(
        siteKey,
        invoiceDoc.billToCustomerID,
      );

      let defaultPaymentTerms = "";

      if (customerDoc.type === "commercial") {
        defaultPaymentTerms =
          siteKeyDoc?.customizations.defaultPaymentTermCommercial;
      } else {
        defaultPaymentTerms =
          siteKeyDoc?.customizations.defaultPaymentTermResidential;
      }

      setDefaultPaymentTerms(defaultPaymentTerms);
      setEditInvoiceDialogOpen(true);
    }
  }

  const editNotesDialog = !(
    estimateDoc.status === EstimateStatus.APPROVED || invoiceDoc != null
  ) && (
    <EditNotesDialog
      isDialogOpen={editNotesDialogOpen}
      closeDialog={() => setEditNotesDialogOpen(false)}
      estimateNotes={estimateDoc.notes}
      onEditNotes={handleEditNotes}
    />
  );

  const editInternalNotesDialog = (
    <EditInternalNotesDialog
      isDialogOpen={editInternalNotesDialogOpen}
      closeDialog={() => setEditInternalNotesDialogOpen(false)}
      estimateInternalNotes={estimateDoc.internalNotes}
      onEditInternalNotes={handleEditInternalNotes}
    />
  );
  const editLineItemsButton = (
    <div className="flex flex-col items-end">
      {estimateDoc.status === EstimateStatus.APPROVED && (
        <div className="col-span-7 col-start-1 italic text-red-700">
          Note: This estimate has already been marked as approved.
        </div>
      )}
      {invoiceDoc != null && (
        <div className="col-span-7 col-start-1 italic text-red-700">
          Note: This estimate has already been converted to an invoice.
        </div>
      )}
      <div className="flex items-center gap-4">
        {editLineItems ? (
          <Fragment>
            <BaseButtonPrimary
              type="button"
              onClick={handleSaveListItemUpdate}
              className="w-full max-w-sm text-primary"
              isBusy={isSavingListItemUpdates}
              busyText={strings.buttons.BUSY_SAVING}
            >
              {strings.buttons.SAVE_CHANGES}
            </BaseButtonPrimary>
            <StyledTooltip title="Close Discount Field">
              <XIconWithRef
                onClick={() => {
                  setEditLineItems(false);
                  setTemporaryEstimateItemList(cloneDeep(estimateItemList));
                }}
              />
            </StyledTooltip>
          </Fragment>
        ) : (
          <StyledTooltip title="Edit Line Items">
            <PencilIconWithRef onClick={() => setEditLineItems(true)} />
          </StyledTooltip>
        )}
      </div>
    </div>
  );

  const editNotesButton = !(
    estimateDoc.status === EstimateStatus.APPROVED || invoiceDoc != null
  ) && (
    <StyledTooltip title="Edit Notes">
      <PencilIconWithRef onClick={() => setEditNotesDialogOpen(true)} />
    </StyledTooltip>
  );

  const editInternalNotesButton = (
    <StyledTooltip title="Edit Internal Notes">
      <PencilIconWithRef onClick={() => setEditInternalNotesDialogOpen(true)} />
    </StyledTooltip>
  );
  const discountField = !(
    estimateDoc.status === EstimateStatus.APPROVED || invoiceDoc != null
  ) && (
    <form autoComplete="off" className="flex items-center gap-4 self-end">
      <BaseInputNumber
        type="number"
        step="0.01"
        className={`w - 20 focus: - primaryLight focus: - primaryLight sm: text - sm block rounded border ring`}
        defaultValue={estimateDoc.discount == null ? "" : estimateDoc.discount}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            const discount = parseFloat(
              (event.target as HTMLInputElement).value,
            );
            if (discount !== 0 && !isNaN(discount)) {
              handleEditEstimateDiscount(discount);
            } else {
              handleEditEstimateDiscount(null);
            }
          }
        }}
      />
      <span className="text-lg"> %</span>
    </form>
  );

  const editDiscountButton = !(
    estimateDoc.status === EstimateStatus.APPROVED || invoiceDoc != null
  ) && (
    <div className="col-span-3 flex items-center justify-end gap-4">
      {showEditGlobalDiscount ? discountField : null}
      {showEditGlobalDiscount ? (
        <StyledTooltip title="Close Discount Field">
          <XIconWithRef onClick={() => setShowEditGlobalDiscount(false)} />
        </StyledTooltip>
      ) : (
        <StyledTooltip title="Edit Discount">
          <PencilIconWithRef onClick={() => setShowEditGlobalDiscount(true)} />
        </StyledTooltip>
      )}
    </div>
  );

  /* RENDER COMPONENT */
  return (
    <section className="mx-auto w-full items-center px-2 pb-10 lg:max-w-5xl">
      <ViewEstimatePage
        merchantLogoURL={null}
        optionalDocs={optionalDocs}
        customer={customerDoc}
        customerLocation={customerLocationDoc}
        estimateStatus={readableStatus}
        estimateDoc={estimateDoc}
        openEditCustomerDialog={openEditCustomerDialog}
        onEditCommissionAdjustment={onEditCommissionAdjustment}
        totals={getEstimateTotals(
          temporaryEstimateItemList,
          customerDoc,
          customerLocationDoc,
          estimateDoc.discount ?? 0,
          siteKeyDoc?.customizations.accounting?.currency ?? "USD",
        )}
        commissions={
          siteKeyDoc.customizations?.commissions
            ? EstimateManager.getJobCommissionsForDisplay(
                baseEstimateItemList,
                fieldEstimateItemList,
                priceBookItemsWithCommissionOverrides,
                siteKeyDoc,
                customerDoc.type,
                estimateDoc.discount ?? 0,
                taskDoc?.taskSpecificDetails.allItemsUpsellCommissions === true,
                taskDoc?.taskSpecificDetails.sameDayJob === true,
                commissionAdjustments,
              )
            : null
        }
      >
        {{
          CustomerEstimateStatusButton: customerEstimateStatusButtons,
          EstimateInfo: estimateInfo,
          EstimateItemLines: displayEstimateItems,
          Breadcrumb: breadcrumbs,
          TaskInfoAndActionButtons: taskInfoAndActionButtons,
          EditNotes: editNotesButton,
          EditInternalNotes: editInternalNotesButton,
          EditLineItemsButton: editLineItemsButton,
          EditDiscount: isDiscountLoading ? (
            <div className="col-span-3 flex items-center justify-end gap-4">
              <LoadingSpinner />
            </div>
          ) : (
            editDiscountButton
          ),
          Signatures: displaySignatures,
        }}
      </ViewEstimatePage>
      {handlePaymentDialog2}
      {editNotesDialog}
      {editInternalNotesDialog}
      {handleSendEstimateDialog}
      {viewInvoiceDialog}
      {editCustomerDialog}
      {commissionDialog}
    </section>
  );
}

async function handleDeleteInvoice(
  siteKeyID: string,
  invoiceID: string,
): Promise<void> {
  return DbWrite.invoices.delete(siteKeyID, invoiceID);
}

function getDocsFromState(optionalDocs: OptionalDocs | undefined, state: any) {
  let customerDocFromState: ExistingCustomer | null = null;
  let customerLocationFromState: ExistingCustomerLocation | null = null;
  let invoiceDocFromState: ExistingStiltInvoice | null = null;

  if (optionalDocs) {
    customerDocFromState = optionalDocs.customerDoc;
    customerLocationFromState = optionalDocs.customerLocationDoc;
    invoiceDocFromState = optionalDocs.invoiceDoc;
  }

  if (state != null) {
    customerDocFromState = state.customerDoc;
    customerLocationFromState = state.customerLocationDoc;
    invoiceDocFromState = state.invoiceDoc;
  }

  return {
    customerDocFromState,
    customerLocationFromState,
    invoiceDocFromState,
  };
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}
