interface Props {
  tag: string;
}

export default function ChipTag({ tag }: Props) {
  return (
    <div className="m-1 rounded-full bg-primary px-2.5 py-1 text-xs font-medium text-primaryButtonText">
      {tag}
    </div>
  );
}
