// Libs
import { TrashIcon } from "@heroicons/react/24/solid";
import React from "react";

type TrashType = {
  onDelete: () => void;
  /** Height value WITH unit. Default is 38px */
  height?: string;
  /** Width value WITH unit. Default is 38px */
  width?: string;
  /** Default is text-gray-700 */
  color?: string;
  /** Default is hover:bg-primaryOpacity90 */
  hoverColor?: string;
} & React.ComponentPropsWithRef<"button">;

/** onDelete event is not intended to be asynchronous. */
export const TrashButton = React.forwardRef<HTMLButtonElement, TrashType>(
  (
    { onDelete, height, width, color, hoverColor, ...props },
    ref,
  ): JSX.Element => {
    const styles = {
      display: "block",
      height: height || "38px",
      width: width || "38px",
      cursor: "pointer",
      padding: "6px",
    };

    return (
      <button
        ref={ref}
        {...props}
        className={`mx-1 rounded-full ${hoverColor || "hover:bg-primaryOpacity90"} ${
          color || "text-gray-700"
        }`}
        onClick={onDelete}
      >
        <TrashIcon aria-label="delete button" style={styles} />
      </button>
    );
  },
);
