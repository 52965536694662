import LoadingSpinner from "./LoadingSpinner";

interface Props {
  text: string;
  value: string;
  color: "green" | "red" | "orange" | "blue" | "gray";
  /** NYI */
  isLoading?: boolean;
}

// TODO: add loading state
export default function PillMetric(props: Props) {
  const colors = getColorClasses(props.color);
  return (
    <div
      className={`${colors.background} my-1 mr-2 flex max-w-fit items-center justify-center rounded-full py-1 pl-1 pr-2 text-sm font-medium capitalize text-gray-900`}
    >
      {props.isLoading && <LoadingSpinner marginClass="m-1" />}
      {!props.isLoading && (
        <span
          className={`${colors.countBackground} mr-1.5 rounded-full px-2 py-0.5 text-base`}
        >
          {props.value}
        </span>
      )}
      {props.text}
    </div>
  );
}

function getColorClasses(color: Props["color"]): Record<string, string> {
  let styles: Record<string, string> = {};
  switch (color) {
    case "blue": {
      styles = {
        background: "bg-blue-200",
        countBackground: "bg-blue-400",
      };
      return styles;
    }

    case "gray": {
      styles = {
        background: "bg-gray-200",
        countBackground: "bg-gray-400",
      };
      return styles;
    }

    case "green": {
      styles = {
        background: "bg-green-300",
        countBackground: "bg-green-500",
      };
      return styles;
    }

    case "orange": {
      styles = {
        background: "bg-orange-200",
        countBackground: "bg-orange-400",
      };
      return styles;
    }

    case "red": {
      styles = {
        background: "bg-red-200",
        countBackground: "bg-red-400",
      };
      return styles;
    }

    default: {
      const _exhaustiveCheck: never = color;
      return _exhaustiveCheck;
    }
  }
}
