// Libs
import { z } from "zod";
import { DocumentSnapshot, DocumentData } from "firebase/firestore";

//Local
import { isValidTimezone } from "../assets/js/isValidTimezone";
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

export interface SiteKey {
  customizations: { [key: string]: any };
  managingCompanyID: string;
  name: string;
  timezone: string;
  unapprovedUsers: string[];
  inactiveUsers?: string[];
  validCraftTypes: number[];
  validEventTypes: number[];
  validTaskStatusCodes: number[];
  validTaskTypes: number[];
  latitude?: number;
  longitude?: number;
  address?: string;
  kpiConfig?: { [key: string]: any };
}

export interface ExistingSiteKey extends SiteKey {
  id: string;
  refPath: string;
}

/**
 * A list of the customization flags that can be set for a site key.
 *
 * TODO: Refactor the flags used in getSidebarData to use this list?
 */
const customizationFlags = [
  "reportTemplatesEnabled",
  "reportBuilderEnabled",
] as const;
export type CustomizationFlag = (typeof customizationFlags)[number];

// #region SECTION: Functions
/** Utilities for interacting with SiteKey objects. */
export const SiteKeyManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingSiteKey object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createSiteKeyFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  convertForFirestore: convertSiteKeyForFirestore,
  /** Validate a SiteKey doc. Use for writing to the database or reading from the user. */
  parse: validateSiteKey,
  /** Validate a SiteKey doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateSiteKeyWithFallbacks,
  /** Validation. Use for editing a SiteKey doc. No fallbacks will be used. */
  parseEditDetails: validateEditSiteKeyDetails,
  /**
   * Validate a SiteKey name. Use for checking the user-submitted site name during
   * the "create a site" process.
   */
  parseSiteName: validateSiteName,
};

/**
 * Convert the Document Snapshot into a validated ExistingSiteKey object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createSiteKeyFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingSiteKey {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateSiteKeyWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertSiteKeyForFirestore(sitekey: ExistingSiteKey): DocumentData {
  const localItem = Object.assign({}, sitekey);
  const { id, refPath, ...rest } = localItem;
  const result = dropUndefined(rest);
  return result;
}

/** For validating stuff coming in from the database. */
function validateSiteKeyWithFallbacks(siteKey: unknown): SiteKey {
  if (!guardIsPlainObject(siteKey)) {
    throw new Error(`SiteKey is not an object: ${siteKey}`);
  }

  return siteKeySchemaWithFallbacks.parse(siteKey);
}

/** Zod validation schemas. Work in progress.
 *  Supposedly require TS strict mode.
 */
function validateSiteKey(value: unknown): siteKeyState {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = siteKeySchema.parse(value);
  return result;
}

function validateEditSiteKeyDetails(value: unknown): EditSiteKeyDetailsState {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = editSiteKeyDetailsSchema.parse(value);
  return result;
}

function validateSiteName(value: unknown): string {
  return siteNameSchema.parse(value);
}
// #endregion

// #region SECTION: Schemas
/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
export const siteKeySchema = z.object({
  customizations: z.record(z.any()),
  managingCompanyID: z.string().min(1).max(200),
  name: z.string().min(1).max(200),
  timezone: z
    .string()
    .min(1)
    .max(200)
    .refine((val) => isValidTimezone(val), {
      message: "Provide a valid timezone.",
    }),
  unapprovedUsers: z.array(z.string().min(1).max(200)),
  inactiveUsers: z.array(z.string().min(1).max(200)).optional(),
  validCraftTypes: z.array(z.number().int()),
  validEventTypes: z.array(z.number().int()),
  validTaskStatusCodes: z.array(z.number().int()),
  validTaskTypes: z.array(z.number().int()),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  address: z.string().min(0).max(200).optional(),
  kpiConfig: z.record(z.any()).optional(),
});
type siteKeyState = z.infer<typeof siteKeySchema>;

/** For reading from the database */
const siteKeySchemaWithFallbacks = z.object({
  customizations: z.record(z.any()),
  managingCompanyID: z.string().min(1).max(200),
  name: z.string().min(1).max(200),
  timezone: z
    .string()
    .min(1)
    .max(200)
    .refine((val) => isValidTimezone(val), {
      message: "Provide a valid timezone.",
    }),
  unapprovedUsers: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "siteKeySchemaWithFallbacks: 'unapprovedUsers'",
  ),
  inactiveUsers: zFallback(
    z.array(z.string().min(1).max(200)).optional(),
    [],
    "siteKeySchemaWithFallbacks: 'inactiveUsers'",
  ),
  validCraftTypes: z.array(z.number().int()),
  validEventTypes: zFallback(
    z.array(z.number().int()),
    [],
    "siteKeySchemaWithFallbacks: 'validEventTypes'",
  ),
  validTaskStatusCodes: z.array(z.number().int()),
  validTaskTypes: z.array(z.number().int()),
  latitude: zFallback(
    z.number().optional(),
    undefined,
    "siteKeySchemaWithFallbacks: 'latitude'",
  ),
  longitude: zFallback(
    z.number().optional(),
    undefined,
    "siteKeySchemaWithFallbacks: 'longitude'",
  ),
  address: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "siteKeySchemaWithFallbacks: 'address'",
  ),
  kpiConfig: zFallback(
    z.record(z.any()).optional(),
    undefined,
    "siteKeySchemaWithFallbacks: 'kpiConfig'",
  ),
});

// For the Admin project. Editing a siteKey. (Introduced in custom fields cycle.)
// These properties are the ones that are editable by the client.
const editFieldsSchema = siteKeySchema.pick({
  name: true,
  timezone: true,
  address: true,
  latitude: true,
  longitude: true,
});
export const editSiteKeyDetailsSchema = editFieldsSchema.partial();
export type EditSiteKeyDetailsState = z.infer<typeof editSiteKeyDetailsSchema>;

/**
 * For validating a user-submitted siteKey name. The siteKey name will become the
 * siteKey ID. (Introduced in the "create a site" cycle.)
 */
export const siteNameSchema = z
  .string()
  .regex(
    /^[a-zA-Z0-9 ]{6,100}$/,
    "Site name can contain letters, numbers, and spaces. Must be between 6 and 100 characters in length",
  )
  .transform((value) => value.trim())
  // Make sure that it's long enough after trimming any whitespace.
  .refine((value) => (value.length < 6 ? false : true), {
    message: "Too many spaces, not enough content",
  })
  .refine(
    (value) => {
      const arr = value.split("");
      for (let i = 0; i < arr.length; i++) {
        // Prevent consecutive spaces.
        if (arr[i] === " " && arr[i + 1] === " ") return false;
      }
      return true;
    },
    {
      message: "Must not contain consecutive spaces",
    },
  );
// #endregion
