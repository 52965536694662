//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaTruck } from "react-icons/fa";
import PersonIcon from "@mui/icons-material/Person";

//Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { logger } from "../../logging";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseInputTextArea from "../BaseInputTextArea";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseInputCheckbox from "../BaseInputCheckbox";
import { StiltCalendarEvent } from "../../models/stilt-calendar-event";
import { IMultipleUid_AssignUser } from "../CustomFields/MultipleUidDialog";
import { ExistingVehicle } from "../../models/vehicle";
import { StyledTooltip } from "../StyledTooltip";
import { IconButton } from "../IconButton";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";

interface Props {
  /* DATA */
  isOpen: boolean;
  userList: IMultipleUid_AssignUser[];
  vehicleList: ExistingVehicle[];
  assignedTo: string | null;
  assignedVehicleIDs: string | null;
  resourcesMode: "technicians" | "vehicles";
  siteUsesVehicles: boolean;
  /* FUNCTIONS */
  onClose: () => void;
  handleSave: (
    formValues: Omit<StiltCalendarEvent, "start" | "end">,
  ) => Promise<void>;
  children: {
    StartDatePicker: React.ReactNode;
    EndDatePicker: React.ReactNode;
  };
}

export const StiltCalendarEventFormSchema = z
  .object({
    title: z.string().min(1, { message: "A title must be provided" }).max(200),
    description: z.string().max(2000),
    resourcesMode: z.literal("vehicles").or(z.literal("technicians")),
    assignedTo: z.array(z.string().or(z.literal(undefined))),
    assignedVehicleIDs: z.array(z.string().or(z.literal(undefined))),
  })
  .superRefine((data, ctx) => {
    const invalidTechsState =
      data.assignedTo.length === 0 ||
      data.assignedTo.every((type) => type == undefined);

    if (data.resourcesMode === "technicians" && invalidTechsState) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "At least one user must be selected",
        path: ["assignedTo"],
        fatal: true,
      });
    }

    const invalidVehiclesState =
      data.assignedVehicleIDs.length === 0 ||
      data.assignedVehicleIDs.every((type) => type == undefined);

    if (data.resourcesMode === "vehicles" && invalidVehiclesState) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "At least one vehicle must be selected",
        path: ["assignedVehicleIDs"],
        fatal: true,
      });
    }
  });

export type StiltCalendarEventFormState = z.infer<
  typeof StiltCalendarEventFormSchema
>;

export default function AddStiltCalendarEventDialog(props: Props) {
  const xusers = [...props.userList];
  xusers.sort((a, b) => a.name.localeCompare(b.name)); // sort method mutates original
  const xvehicles = [...props.vehicleList];
  xvehicles.sort((a, b) => a.title.localeCompare(b.title));

  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const defaultValues: StiltCalendarEventFormState = {
    title: "",
    description: "",
    resourcesMode: props.resourcesMode,
    assignedTo: [],
    assignedVehicleIDs: [],
  };

  if (props.assignedTo != null) {
    const initiallySelected = getAssignedDefaults({
      selected: [props.assignedTo],
      type: "technicians",
      list: xusers,
    });
    defaultValues.assignedTo = initiallySelected;
  }
  if (props.assignedVehicleIDs != null) {
    const initiallySelected = getAssignedDefaults({
      selected: [props.assignedVehicleIDs],
      type: "vehicles",
      list: xvehicles,
    });
    defaultValues.assignedVehicleIDs = initiallySelected;
  }

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    watch,
    handleSubmit,
  } = useForm<StiltCalendarEventFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(StiltCalendarEventFormSchema),
    mode: "onChange",
  });

  const watchResourcesMode = watch("resourcesMode");

  function closeAndReset() {
    reset();
    props.onClose();
  }

  const onSubmit: SubmitHandler<StiltCalendarEventFormState> = async (
    formValues,
  ) => {
    const { assignedTo, assignedVehicleIDs, resourcesMode, ...rest } =
      formValues;

    const selectedUsers = assignedTo.filter(
      (uid): uid is string => uid != null,
    );
    const selectedVehicles = assignedVehicleIDs.filter(
      (vid): vid is string => vid != null,
    );

    let values: Omit<StiltCalendarEvent, "start" | "end">;
    if (resourcesMode === "technicians") {
      values = {
        ...rest,
        assignedTo: selectedUsers,
        // assignedVehicleIDs: [],
      };
    } else {
      values = {
        ...rest,
        assignedTo: [],
        assignedVehicleIDs: selectedVehicles,
      };
    }

    try {
      await props.handleSave(values);
      closeAndReset();
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(`new calendar event: ${rest.title}`),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        logger.warn(e);
        logger.error(`handleSaveNewCalendarEvent: ${e.name} ${e.message}`);
      } else {
        logger.error(`handleSaveNewCalendarEvent`, e);
      }
      addMessage({
        id: createToastMessageID(),
        message: strings.failedAdd(`calendar event: ${rest.title}`),
        dialog: true,
        type: "error",
      });
    }
  };

  const addStiltCalendarEventHeader = (
    <div className="flex w-full items-start justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex text-xl font-semibold ">
        {strings.ADD_STILT_CALENDAR_EVENT}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close add stilt calendar event form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={props.isOpen}
      title={addStiltCalendarEventHeader}
      parentDivStyles="inline-block transform overflow-hidden max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="flex flex-col space-y-8 p-8 text-lg">
        <form autoComplete="off" className="w-full space-y-8">
          {/* Field: Title */}
          <div>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Title"
                  inputName="title"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.title?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.title.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Description */}
          <div>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <BaseInputTextArea
                  id="description"
                  admin={true}
                  text="Description"
                  rows={3}
                  {...field}
                  value={
                    field.value === null
                      ? undefined
                      : field.value /* because value may be null */
                  }
                  onChange={(event) => {
                    // Store empty values as null instead of undefined
                    field.onChange(event.target.value ?? null);
                  }}
                  className="mt-1 flex rounded-md shadow-sm sm:mt-0"
                />
              )}
            />
            {errors.description?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.description.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* added div that wraps the assignment-type fields - so that the toggle icon (change resources mode) could be closer to the assignment fields,
          without having to take `space-y-8` off of the parent element. dunno if that was the right choice. FAFO. */}
          <div className="flex flex-col gap-1">
            {/* CHANGE RESOURCES MODE BUTTON */}
            {props.siteUsesVehicles && (
              <div className="self-end">
                <Controller
                  control={control}
                  name="resourcesMode"
                  render={({ field }) => {
                    const isShowingTechnicians = field.value === "technicians";
                    return (
                      <StyledTooltip
                        title={
                          isShowingTechnicians
                            ? strings.ASSIGN_VEHICLES_TO_EVENT
                            : strings.ASSIGN_USERS_TO_EVENT
                        }
                      >
                        <IconButton
                          type="button"
                          {...field}
                          buttonPadding={
                            !isShowingTechnicians ? "px-1.5 py-1" : undefined
                          }
                          onClick={() =>
                            field.onChange(
                              isShowingTechnicians ? "vehicles" : "technicians",
                            )
                          }
                        >
                          {isShowingTechnicians ? (
                            <FaTruck className="h-6 w-6 fill-gray-600" />
                          ) : (
                            <PersonIcon className="h-5 w-6 text-gray-600" />
                          )}
                        </IconButton>
                      </StyledTooltip>
                    );
                  }}
                />
              </div>
            )}

            {/* ASSIGNED TO */}
            {watchResourcesMode === "technicians" && (
              <div className="relative rounded border border-black p-6">
                <label className="absolute -top-3 left-3 bg-white px-2">
                  {strings.ASSIGN_USERS}{" "}
                  <span className="text-lg font-medium text-redFail">*</span>
                </label>
                <div className="grid xs:grid-cols-2">
                  {xusers.map((user, index) => (
                    <Controller
                      key={user.uid}
                      control={control}
                      name={`assignedTo.${index}`}
                      render={({ field }) => (
                        <BaseInputCheckbox
                          label={user.name}
                          {...field}
                          defaultChecked={field.value === user.uid}
                          onChange={(event) => {
                            field.onChange(
                              event.target.checked ? user.uid : undefined,
                            );
                          }}
                        />
                      )}
                    />
                  ))}
                </div>
                {errors.assignedTo?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.assignedTo.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}

            {/* ASSIGNED VEHICLE ID */}
            {watchResourcesMode === "vehicles" && (
              <div className="relative rounded border border-black p-6">
                <label className="absolute -top-3 left-3 bg-white px-2">
                  {strings.ASSIGN_VEHICLES}{" "}
                  <span className="text-lg font-medium text-redFail">*</span>
                </label>
                <div className="grid xs:grid-cols-2">
                  {xvehicles.map((vehicle, index) => (
                    <Controller
                      key={vehicle.id}
                      control={control}
                      name={`assignedVehicleIDs.${index}`}
                      render={({ field }) => (
                        <BaseInputCheckbox
                          label={vehicle.title}
                          {...field}
                          defaultChecked={field.value === vehicle.id}
                          onChange={(event) => {
                            field.onChange(
                              event.target.checked ? vehicle.id : undefined,
                            );
                          }}
                        />
                      )}
                    />
                  ))}
                </div>
                {errors.assignedVehicleIDs?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.assignedVehicleIDs.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}
          </div>
        </form>

        {props.children.StartDatePicker}
        {props.children.EndDatePicker}

        {/* Action Buttons */}
        <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={closeAndReset}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
            onClick={handleSubmit(onSubmit)}
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}

type SelectedResourceAssignedTo = {
  type: "technicians";
  list: IMultipleUid_AssignUser[];
};
type SelectedResourceAssignedVehicle = {
  type: "vehicles";
  list: ExistingVehicle[];
};
type SelectedResourceType = { selected: string[] } & (
  | SelectedResourceAssignedTo
  | SelectedResourceAssignedVehicle
);

/**
 * Retrieves default form values for the given list of technicians or vehicles. The
 * returned values that are not `undefined` come from the given `selected` resources.
 * @param args - The selected resources and their type. `selected` values should
 * be those that you want to be pre-selected within the form.
 * @returns An array of the same length as the list of resources, with each element
 * being either the resource's identifier, or undefined.
 */
export function getAssignedDefaults(
  args: SelectedResourceType,
): (string | undefined)[] {
  if (args.type === "vehicles") {
    return args.list.map((resource) =>
      args.selected.includes(resource.id) ? resource.id : undefined,
    );
  }

  return args.list.map((resource) =>
    args.selected.includes(resource.uid) ? resource.uid : undefined,
  );
}
