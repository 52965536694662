//Libs
import { useState } from "react";
import Fuse from "fuse.js";
import SearchIcon from "@mui/icons-material/Search";

//Local

interface Props {
  list: Record<string, any>[];
  searchInput: (searchTerm: Fuse.FuseResult<Record<string, any>>[]) => void;
  placeholder: string;
  keys: string[];
}

export default function CompanySearchBox({
  list,
  searchInput,
  placeholder,
  keys,
}: Props) {
  const [inputValue, setInputValue] = useState<string>("");

  const options = {
    keys: keys,
  };

  const fuse = new Fuse(list, options);

  function onSearch(searchTerm: string) {
    setInputValue("");
    const result = fuse.search(searchTerm);
    searchInput(result);
  }

  return (
    <div className="relative">
      <label className="relative block rounded-md">
        <input
          className="w-full rounded-md border border-primary px-3 py-2 outline-none transition placeholder:text-gray-500 focus:ring-1 focus:ring-primaryLight"
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              onSearch(inputValue);
            }
          }}
        />
        <button
          onClick={(event) => {
            event.preventDefault();
            onSearch(inputValue);
          }}
          className="absolute bottom-0 right-0 top-0 rounded-r-md border border-primary bg-primary px-6 text-primaryButtonText transition-colors hover:bg-primaryDark hover:text-secondaryButtonText"
        >
          <SearchIcon
            aria-label="search"
            className="h-5 w-5"
            aria-hidden="true"
          />
        </button>
      </label>
    </div>
  );
}
