// Libs
import create from "zustand";

// Local
import { DbRead } from "../database";
import { ExistingSiteKeyUserDoc } from "../models/site-key-users";
import { ExistingSiteKey } from "../models/site-key";

// Define the property types.
interface SiteKeyUsersStore {
  siteKeyUsersList: ExistingSiteKeyUserDoc[];
  loading: boolean;
  fetch: (siteKey: ExistingSiteKey) => void;
}

// Export a hook to access data globally and define initial values..
export const useSiteKeyUsersStore = create<SiteKeyUsersStore>((set, get) => {
  return {
    siteKeyUsersList: [],
    loading: false,
    fetch: async (siteKey: ExistingSiteKey) => {
      set({ loading: true });
      const usersList = await DbRead.user.getAllUsers({
        siteKey,
        filterInactiveUnapproved: true,
      });
      set({ siteKeyUsersList: usersList, loading: false });
    },
  };
});
