//Libs
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  CellContextMenuEvent,
  ColDef,
  ColumnState,
  GetQuickFilterTextParams,
  ICellRendererParams,
  ITooltipParams,
  RowClickedEvent,
  ValueGetterFunc,
  ValueGetterParams,
} from "ag-grid-community";
import isEqual from "lodash/isEqual";
import { Timestamp } from "firebase/firestore";

//Local
import { ExistingCraftRecord } from "../../models/craft-record";
import placeholder_image from "../../images/no-image-placeholder.png";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { getTimeDifferenceInDays } from "../../utils/getTimeDifferenceInDays";
import { ExistingVehicle } from "../../models/vehicle";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { CraftTypes } from "../../models/craft-types";
import { RestorePageIconWithRef } from "../../components/RestorePageButton";
import { StyledTooltip } from "../../components/StyledTooltip";
import SearchBox from "../../components/SearchBox";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import {
  getDefaultViewForThisTable,
  getTableViewPreferenceList,
  handleDeleteTableView,
  handleSaveNewTableViewPreference,
  handleSetDefaultView,
  setInitialView,
  TableViewPreference,
} from "../../models/table-view-preferences";
import SaveTableViewPreferenceDialog, {
  SaveTableViewPreferenceFormState,
} from "../../components/tables/SaveTableViewPreferenceDialog";
import DropdownTableSavedViews from "../../components/tables/DropdownTableSavedViews";
import * as strings from "../../strings";
import SiteKeyLocationCustomFilter from "../../components/tables/SiteKeyLocationCustomFilter";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import EditTableViewsDialog from "../../components/tables/EditTableViewsDialog";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

interface Props {
  workRecordList: ExistingCraftRecord[];
  userDisplayNamesMap: Record<string, string>;
  workRecordTableColumnsSelection: Record<string, boolean>;
  customColumns: ColDef[];
  validCraftTypes: number[] | undefined;
  siteKeyLocationList: ExistingSiteKeyLocation[];
  vehicleList: ExistingVehicle[];
  getLocationTitle: (id: string) => string;
  goToWorkRecordAndTasksPage?: (
    craftRecordID: ExistingCraftRecord["id"],
  ) => void;
  handleRestoreWorkRecord?: (workRecordDoc: ExistingCraftRecord) => void;
  children: {
    CreateNewTaskButton?: React.ReactNode;
    ActionButtons: React.ReactNode;
    DateRangePicker: React.ReactNode;
  };
}

declare global {
  interface Window {
    colState: ColumnState[];
  }
}

export default function WorkRecordListPage({
  workRecordList,
  workRecordTableColumnsSelection,
  userDisplayNamesMap,
  validCraftTypes,
  siteKeyLocationList,
  vehicleList,
  customColumns,
  goToWorkRecordAndTasksPage,
  getLocationTitle,
  handleRestoreWorkRecord,
  ...props
}: Props) {
  const renderWorkRecordPhoto = (params: ICellRendererParams): JSX.Element => {
    return (
      <div className="m-1 flex h-10 w-10 justify-center overflow-hidden">
        <img
          src={
            params.data.thumbnailURL
              ? params.data.thumbnailURL
              : placeholder_image
          }
          alt="work record thumbnail"
          className="w-full flex-shrink-0 object-cover"
        />
      </div>
    );
  };

  const renderSiteKeyLocation = (params: ICellRendererParams): JSX.Element => {
    return <div>{getLocationTitle(params.data.locationID)}</div>;
  };

  const renderUserName: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const displayName = userDisplayNamesMap[params.data.createdBy];
      return displayName;
    },
    [userDisplayNamesMap],
  );

  const renderTimestamp = (params: ICellRendererParams): JSX.Element => {
    return (
      <div>
        {params.value != null ? convertToReadableTimestamp(params.value) : ""}
      </div>
    );
  };

  function renderNumberDaysStanding(params: ValueGetterParams) {
    if (params.data.craftDetails.timestampMarkedInstalled == null) {
      return "0";
    } else {
      const startDate: Timestamp =
        params.data.craftDetails.timestampMarkedInstalled;
      const endDate = Timestamp.now();

      //ACTION
      const daysDifference = getTimeDifferenceInDays({ startDate, endDate });
      return `${daysDifference}`;
    }
  }

  function renderVechicleName(params: ValueGetterParams) {
    const currentVehicle = vehicleList.find(
      (vehicle) => vehicle.id === params.data.assetID,
    );
    return currentVehicle ? currentVehicle.title : "";
  }

  const renderJob = (params: ICellRendererParams): JSX.Element => {
    return (
      <div className="my-2 flex items-center">
        <div className="flex flex-col space-y-2">
          <span className="block whitespace-normal text-base font-medium capitalize leading-5 text-primary">
            {params.data.title}
          </span>
          <span className="block whitespace-normal text-sm leading-5 text-gray-400">
            {params.data.description}
          </span>
        </div>
      </div>
    );
  };

  const renderIconCell = useCallback(
    function (params: ICellRendererParams) {
      return (
        <div>
          {handleRestoreWorkRecord && (
            <div className="flex items-center">
              <StyledTooltip title="Restore Task">
                <RestorePageIconWithRef
                  onClick={() => handleRestoreWorkRecord(params.data)}
                />
              </StyledTooltip>
            </div>
          )}
        </div>
      );
    },
    [handleRestoreWorkRecord],
  );

  const columnDefs: ColDef[] = [
    {
      field: "thumbnailURL",
      headerName: "Photo",
      cellRenderer: renderWorkRecordPhoto,
      maxWidth: 95,
      autoHeight: true,
    },
    {
      field: "title",
      headerName: "Job",
      cellRenderer: renderJob,
      tooltipValueGetter: () => "View Work Record",
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data.title + " " + params.data.description;
      },
      comparator(_valueA, _valueB, nodeA, nodeB) {
        const titleA = nodeA.data.title;
        const titleB = nodeB.data.title;
        if (titleA === titleB) return 0;
        return titleA > titleB ? 1 : -1;
      },
      autoHeight: true,
      minWidth: 200,
      flex: 2,
    },
    {
      field: "numOpenTasks",
      headerName: "Open Tasks",
      tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      cellRenderer: renderUserName,
      tooltipValueGetter: (params) =>
        getUserName(userDisplayNamesMap, params.data.createdBy),
      getQuickFilterText: (params: GetQuickFilterTextParams) =>
        getUserName(userDisplayNamesMap, params.data.createdBy),
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "timestampRecordCreated",
      headerName: "Created",
      cellRenderer: renderTimestamp,
      tooltipValueGetter: (params) =>
        convertToReadableTimestamp(params.data.timestampRecordCreated),
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data.timestampRecordCreated != null
          ? convertToReadableTimestamp(params.data.timestampRecordCreated)
          : "";
      },
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  columnDefs.push(...customColumns);

  if (handleRestoreWorkRecord) {
    columnDefs.push({
      field: "action",
      cellRenderer: renderIconCell,
      suppressMovable: true,
      suppressHeaderMenuButton: true,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      sortable: false,
      flex: 0,
      maxWidth: 100,
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
    });
  }

  if (validCraftTypes && validCraftTypes.includes(CraftTypes.SCAFFOLDING)) {
    columnDefs.push({
      field: "craftDetails.timestampMarkedInstalled",
      headerName: "# Days Standing",
      cellRenderer: renderNumberDaysStanding,
      tooltipValueGetter: (params) => getNumberDaysStanding(params),
      getQuickFilterText: (params: GetQuickFilterTextParams) =>
        getNumberDaysStanding(params),
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
    });
  }

  if (vehicleList.length >= 1) {
    columnDefs.push({
      field: "assetID",
      headerName: "Asset",
      cellRenderer: renderVechicleName,
      tooltipValueGetter: (params: ITooltipParams) =>
        getVehicleName(params, vehicleList),
      getQuickFilterText: (params: GetQuickFilterTextParams) =>
        getVehicleName(params, vehicleList),
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
    });
  }

  if (siteKeyLocationList.length > 1) {
    columnDefs.push({
      field: "locationID",
      filter: SiteKeyLocationCustomFilter,
      cellRenderer: renderSiteKeyLocation,
      headerName: "Location",
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return getLocationTitle(params.data.locationID);
      },
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
    });
  }

  return (
    <div className={"ag-theme-alpine flex h-full min-h-[600px] flex-col"}>
      <WorkRecordListTable
        workRecordList={workRecordList}
        CreateNewTaskButton={props.children.CreateNewTaskButton}
        TableColumnsButton={props.children.ActionButtons}
        DateRangePicker={props.children.DateRangePicker}
        columnDefs={columnDefs}
        goToWorkRecordAndTasksPage={goToWorkRecordAndTasksPage}
        workRecordTableColumnsSelection={workRecordTableColumnsSelection}
      />
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

interface WorkRecordListTableProps {
  workRecordList: Props["workRecordList"];
  CreateNewTaskButton: React.ReactNode;
  TableColumnsButton: Props["children"]["ActionButtons"];
  DateRangePicker: Props["children"]["DateRangePicker"];
  columnDefs: ColDef[];
  workRecordTableColumnsSelection: Props["workRecordTableColumnsSelection"];
  goToWorkRecordAndTasksPage: Props["goToWorkRecordAndTasksPage"];
}

/* TABLE COMPONENT */
const WorkRecordListTable = React.memo(
  ({
    workRecordList,
    columnDefs,
    workRecordTableColumnsSelection,
    goToWorkRecordAndTasksPage,
    ...props
  }: WorkRecordListTableProps) => {
    const [tableGridReady, setTableGridReady] = useState<boolean>(false);
    const [tableViewPreferenceDialogOpen, setTableViewPreferenceDialogOpen] =
      useState<boolean>(false);
    const [editViewDialogsOpen, setEditViewDialogsOpen] =
      useState<boolean>(false);
    const [tableViewPreferenceList, setTableViewPreferenceList] = useState<
      TableViewPreference[]
    >([]);
    const [selectedView, setSelectedView] =
      useState<TableViewPreference | null>(null);

    const gridRef = useRef<any>(null);

    const defaultColDef = useMemo<ColDef>(() => {
      return {
        minWidth: 150,
        resizable: true,
        filter: true,
        sortable: true,
        headerClass: headerStyles,
        flex: 1,
      };
    }, []);

    const saveTableViewPreferenceDialog = (
      <SaveTableViewPreferenceDialog
        closeDialog={() => setTableViewPreferenceDialogOpen(false)}
        isDialogOpen={tableViewPreferenceDialogOpen}
        handleSave={(formValues: SaveTableViewPreferenceFormState) =>
          handleSaveNewTableViewPreference(
            formValues,
            "workRecordList",
            gridRef,
            setTableViewPreferenceList,
            setSelectedView,
          )
        }
      />
    );

    const editTableViewsDialog = (
      <EditTableViewsDialog
        isDialogOpen={editViewDialogsOpen}
        closeDialog={() => setEditViewDialogsOpen(false)}
        deleteTableView={(viewToDelete: TableViewPreference) =>
          handleDeleteTableView(
            viewToDelete,
            setTableViewPreferenceList,
            setSelectedView,
          )
        }
        handleSetDefaultView={(viewToSetAsDefault: TableViewPreference) =>
          handleSetDefaultView(
            viewToSetAsDefault,
            setTableViewPreferenceList,
            setSelectedView,
          )
        }
        savedViewList={tableViewPreferenceList}
      />
    );

    const dropdownSavedViews = tableViewPreferenceList.length > 0 &&
      selectedView && (
        <DropdownTableSavedViews
          savedViewList={tableViewPreferenceList}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
        />
      );

    useLayoutEffect(() => {
      if (gridRef.current && tableGridReady) {
        gridRef.current.api?.sizeColumnsToFit();
        getTableViewPreferenceList(
          "workRecordList",
          setTableViewPreferenceList,
        );
        const defaultView = getDefaultViewForThisTable("workRecordList");
        setInitialView(
          defaultView,
          gridRef,
          setSelectedView,
          workRecordTableColumnsSelection,
          "workRecordTableColumnsSelection",
        );
      }
    }, [tableGridReady]);

    useEffect(() => {
      localStorage.setItem(
        "workRecordTableColumnsSelection",
        JSON.stringify(workRecordTableColumnsSelection),
      );

      const newState: Partial<ColumnState>[] = [];
      for (const [colId, check] of Object.entries(
        workRecordTableColumnsSelection,
      )) {
        newState.push({
          colId,
          hide: !check,
        });
      }

      gridRef.current.api?.applyColumnState({
        state: newState,
      });
    }, [workRecordTableColumnsSelection]);

    useEffect(() => {
      if (selectedView) {
        gridRef.current!.api.applyColumnState({
          state: selectedView.settings,
          applyOrder: true,
        });
        gridRef.current!.api.setFilterModel(selectedView.filter);
        console.log("set column state for", selectedView.name);
      }
    }, [selectedView]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const onGridReady = useCallback(() => {
      setTableGridReady(true);
    }, []);

    return (
      <Fragment>
        <div className="mt-8 flex flex-col justify-between gap-4 sm:flex-row sm:items-end">
          <SearchBox onInput={onFilterTextBoxChanged} />
          <div className="w-full"></div>
          <div className="flex w-full flex-row items-center justify-end">
            <span className="pr-2 text-right">Date created </span>
            {props.DateRangePicker}
          </div>
          <div className="flex flex-col items-end gap-4">
            <div className="flex gap-4">
              {props.CreateNewTaskButton}
              {props.TableColumnsButton}
            </div>
            <div className="flex items-center gap-4">
              {dropdownSavedViews}
              <BaseButtonPrimary
                onClick={() => setTableViewPreferenceDialogOpen(true)}
              >
                {strings.SAVE_TABLE_VIEW}
              </BaseButtonPrimary>
              <BaseButtonSecondary onClick={() => setEditViewDialogsOpen(true)}>
                {strings.EDIT_TABLE_VIEWS}
              </BaseButtonSecondary>
            </div>
          </div>
        </div>

        <AgGridReact
          reactiveCustomComponents
          ref={gridRef}
          defaultColDef={defaultColDef}
          className="mt-5 shadow"
          rowData={workRecordList}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          onRowClicked={(event: RowClickedEvent) => {
            if (goToWorkRecordAndTasksPage) {
              return goToWorkRecordAndTasksPage(event.data.id);
            } else return undefined;
          }}
          onCellContextMenu={(event: CellContextMenuEvent<any, any>) => {
            const url = `${WORK_RECORD_AND_TASKS_URL}/${event.data.id}`;
            return window.open(url, "_blank");
          }}
        />
        {saveTableViewPreferenceDialog}
        {editTableViewsDialog}
      </Fragment>
    );
  },
  (previous, next) => {
    const isWorkRecordListTheSame = isEqual(
      previous.workRecordList,
      next.workRecordList,
    );

    const isWRTableColumnsSelectionTheSame = isEqual(
      previous.workRecordTableColumnsSelection,
      next.workRecordTableColumnsSelection,
    );

    return isWorkRecordListTheSame && isWRTableColumnsSelectionTheSame;
  },
);

export function getUserName(
  userDisplayNamesMap: Record<string, string>,
  createdBy: string,
) {
  const displayName = userDisplayNamesMap[createdBy];
  return displayName;
}

const getNumberDaysStanding = (
  params: GetQuickFilterTextParams | ITooltipParams,
): string => {
  if (params.data.craftDetails.timestampMarkedInstalled == null) {
    return "0";
  } else {
    const startDate: Timestamp = params.value;
    const endDate = Timestamp.now();

    //ACTION
    const daysDifference = getTimeDifferenceInDays({ startDate, endDate });
    return `${daysDifference}`;
  }
};

const getVehicleName = (
  params: GetQuickFilterTextParams | ITooltipParams,
  vehicleList: ExistingVehicle[],
): string => {
  const currentVehicle = vehicleList.find(
    (vehicle) => vehicle.id === params.data.assetID,
  );
  return currentVehicle ? currentVehicle.title : "";
};
