//Libs
import { DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

//Local
import { guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";
import { NotFoundError } from "../error-classes";

export interface InventoryLocation {
  title: string;
  latitude: number | null;
  longitude: number | null;
}

export interface ExistingInventoryLocation extends InventoryLocation {
  id: string;
  refPath: string;
}

/** Utilities for interacting with InventoryLocation objects  */
export const InventoryLocationManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingInventoryLocation object.
   */
  createFromFirestoreSnapshot: createInventoryLocationFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateInventoryLocation,
  /** Validate an InventoryLocation doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateInventoryLocationWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingInventoryObject object.
 */
function createInventoryLocationFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingInventoryLocation {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateInventoryLocationWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateInventoryLocationWithFallbacks(
  value: unknown,
): InventoryLocation {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = inventoryLocationSchemaWithFallbacks.parse(value);
  return result;
}

function validateInventoryLocation(value: unknown): InventoryLocation {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = inventoryLocationSchema.parse(value);
  return result;
}

const inventoryLocationSchema = z.object({
  title: z.string().min(1).max(500),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
});

const inventoryLocationSchemaWithFallbacks = z.object({
  title: zFallback(
    z.string().min(1).max(500),
    "unknown",
    "inventoryLocationSchemaWithFallbacks: 'title'",
  ),
  latitude: zFallback(
    z.number().nullable(),
    null,
    "inventoryLocationSchemaWithFallbacks: 'latitude'",
  ),
  longitude: zFallback(
    z.number().nullable(),
    null,
    "inventoryLocationSchemaWithFallbacks: 'longitude'",
  ),
});
