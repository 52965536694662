// Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

// Local
import { TaskStatus, TaskStatusValues } from "./task-status";
import { EventTypeValues, EventTypes } from "./event-types";
import { zFallback } from "../utils/zod-fallback";
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject } from "../utils";

/* 
NOTE: This model is more basic than our traditional models, because events are 
never created by a user. They're always server-generated.
*/

// There is no need for an Event interface.
export interface ExistingEvent {
  id: string;
  refPath: string;
  assignedCompanyID: string | null;
  /**
   * The entire DB path to the work record, NOT just the ID. Example:
   * `siteKeys/happyKey-123/parentRecords/workRecordID`
   */
  craftRecordID: string;
  /** UID */
  createdBy: string;
  eventType: EventTypeValues;
  taskID: string | null;
  taskStatus: TaskStatusValues | null;
  title: string;
  timestampCreated: Timestamp;
  description?: string;
  estimateID?: string;
  invoiceID?: string;
  paymentID?: string;
  customerID?: string;
  customerLocationID?: string;
}

// We'll only read Event docs from the DB. No need for a "regular" schema.
const SchemaEventWithFallbacks = z.object({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(600),
  // ^ these really don't need to be validated; doing it to keep TS happy.
  assignedCompanyID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "SchemaEventWithFallbacks: 'assignedCompanyID'",
  ),
  craftRecordID: z.string().min(1).max(1000),
  createdBy: zFallback(
    z.string().min(1).max(200),
    "Unknown",
    "SchemaEventWithFallbacks: 'createdBy'",
  ),
  eventType: z.nativeEnum(EventTypes),
  taskID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "SchemaEventWithFallbacks: 'taskID'",
  ),
  taskStatus: zFallback(
    z.nativeEnum(TaskStatus).nullable(),
    null,
    "SchemaEventWithFallbacks: 'taskStatus'",
  ),
  title: zFallback(
    z.string().min(1).max(10000),
    "Unknown",
    "SchemaEventWithFallbacks: 'title'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "SchemaEventWithFallbacks: 'timestampCreated'",
  ),
  description: zFallback(
    z.string().max(2000).optional(),
    undefined,
    "SchemaEventWithFallbacks: 'description'",
  ),
  estimateID: zFallback(
    z.string().max(100).optional(),
    undefined,
    "SchemaEventWithFallbacks: 'estimateID'",
  ),
  invoiceID: zFallback(
    z.string().max(100).optional(),
    undefined,
    "SchemaEventWithFallbacks: 'invoiceID'",
  ),
  paymentID: zFallback(
    z.string().max(100).optional(),
    undefined,
    "SchemaEventWithFallbacks: 'paymentID'",
  ),
  customerID: zFallback(
    z.string().max(100).optional(),
    undefined,
    "SchemaEventWithFallbacks: 'customerID'",
  ),
  customerLocationID: zFallback(
    z.string().max(100).optional(),
    undefined,
    "SchemaEventWithFallbacks: 'customerLocationID'",
  ),
});

/** Utilities for interacting with Event objects. */
export const EventRecordManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingEvent object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromSnapshot,
};

function createFromSnapshot(snapshot: DocumentSnapshot): ExistingEvent {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return validateEventWithFallbacks({
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...snapshotData,
  });
}

// Shouldn't need to add this to the EventRecordManager.
function validateEventWithFallbacks(event: unknown): ExistingEvent {
  if (!guardIsPlainObject(event)) {
    throw new Error(`Event is not an object: ${event}`);
  }
  return SchemaEventWithFallbacks.parse(event);
}
