//Libs
import { DateTime } from "luxon";

//Local

export function startDateIsEarlierThanEndDate(
  start: DateTime,
  end: DateTime,
): boolean {
  if (start < end) {
    return true;
  } else {
    return false;
  }
}
