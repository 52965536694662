import { ICellRendererParams } from "ag-grid-community";
import {
  ExistingCustomerLocation,
  getReadableLocationAddress,
} from "../../models/customer-location";

export function customerWithLocationsTableCell(params: ICellRendererParams) {
  const noDeletedCustomerLocation = params.data.customerLocations.filter(
    (location: ExistingCustomerLocation) => location.deleted !== true,
  );
  const onlyAddresses = noDeletedCustomerLocation
    .sort((a: ExistingCustomerLocation, b: ExistingCustomerLocation) =>
      getReadableLocationAddress(a).localeCompare(
        getReadableLocationAddress(b),
      ),
    )
    .map((location: ExistingCustomerLocation) => {
      const address = getReadableLocationAddress(location);
      return (
        <span key={location.id} className="text-sm leading-5 text-gray-400">
          {address}
        </span>
      );
      // return location.fullAddress;
    });
  let name = params.data.name;
  if (params.data.type === "commercial") {
    if (params.data.firstName) {
      name += " " + params.data.firstName;
    }

    if (params.data.lastName) {
      name += " " + params.data.lastName;
    }
  }
  return (
    <div className="my-2 flex items-center">
      <div className="flex flex-col space-y-0">
        <span className="block whitespace-normal text-base font-medium capitalize leading-5 text-primary">
          {name}
        </span>
        {onlyAddresses}
      </div>
    </div>
  );
}
