//Libs
import { ColumnState, FilterModel } from "ag-grid-community";
import { v4 as uuid4 } from "uuid";

//Local
import { SaveTableViewPreferenceFormState } from "../components/tables/SaveTableViewPreferenceDialog";

export const tableNames = [
  "workRecordList",
  "taskList",
  "estimateList",
] as const;
// Create the types from the TS array 'customerTypes'.
export type TableName = (typeof tableNames)[number];

export interface TableViewPreference {
  id: string;
  table: TableName;
  name: string;
  default: boolean;
  settings: ColumnState[];
  filter: FilterModel;
}

export function getDefaultViewForThisTable(
  tableName: TableName,
): TableViewPreference | undefined {
  //get the current default object list stored
  const storedDefaultViewList: TableViewPreference[] = JSON.parse(
    localStorage.getItem("defaultViewList") || "[]",
  );

  const filteredDefaultList = storedDefaultViewList.find(
    (defaultView) => defaultView.table === tableName,
  );

  return filteredDefaultList;
}

export function getTableViewPreferenceList(
  tableName: TableName,
  setTableViewPreferenceList: React.Dispatch<
    React.SetStateAction<TableViewPreference[]>
  >,
) {
  //get the list of all the saved table view preferences
  const tableViewPreferenceList: TableViewPreference[] = JSON.parse(
    localStorage.getItem("tableViewPreferenceList") || "[]",
  );

  //filter list of view preference related to this table
  const filteredViewListForThisTable = tableViewPreferenceList.filter(
    (viewPreference) => viewPreference.table === tableName,
  );

  if (filteredViewListForThisTable.length === 0) return;

  setTableViewPreferenceList(filteredViewListForThisTable);
}

export function setInitialView(
  defaultView: TableViewPreference | undefined,
  gridRef: React.MutableRefObject<any>,
  setSelectedView: React.Dispatch<
    React.SetStateAction<TableViewPreference | null>
  >,
  columnSelection?: Record<string, boolean>,
  columnSelectionTableName?: string,
) {
  if (defaultView == null) {
    console.log(
      "there's no view to restore by, it will default to the originally provided column definitions.",
    );
    gridRef.current!.api.resetColumnState();

    if (columnSelection && Object.keys(columnSelection).length > 0) {
      const newState: Partial<ColumnState>[] = [];
      for (const [colId, check] of Object.entries(columnSelection)) {
        newState.push({
          colId,
          hide: !check,
        });
      }

      gridRef.current.api?.applyColumnState({
        state: newState,
      });
    }

    return;
  }

  setSelectedView(defaultView);
  gridRef.current!.api.applyColumnState({
    state: defaultView.settings,
    applyOrder: true,
  });

  if (columnSelectionTableName) {
    const initialColumnSelection: Record<string, boolean> = {};
    for (const column of defaultView.settings) {
      initialColumnSelection[column.colId] = !column.hide;
    }

    localStorage.setItem(
      columnSelectionTableName,
      JSON.stringify(initialColumnSelection),
    );
  }
}

export function handleDeleteTableView(
  viewToDelete: TableViewPreference,
  setTableViewPreferenceList: React.Dispatch<
    React.SetStateAction<TableViewPreference[]>
  >,
  setSelectedView: React.Dispatch<
    React.SetStateAction<TableViewPreference | null>
  >,
) {
  const currentTable = viewToDelete.table;

  const storedTableViewList: TableViewPreference[] = JSON.parse(
    localStorage.getItem("tableViewPreferenceList") || "[]",
  );

  //filter out the selected view
  const updatedViewPreferenceList = storedTableViewList.filter(
    (view) => view.id !== viewToDelete.id,
  );

  //set a new item on localStorage with the updated view preference list
  localStorage.setItem(
    "tableViewPreferenceList",
    JSON.stringify(updatedViewPreferenceList),
  );
  console.log("list of table view updated", updatedViewPreferenceList);

  //send to the preference list of that table, only the items related
  const filteredViewPreferenceList = updatedViewPreferenceList.filter(
    (view) => view.table === currentTable,
  );

  setTableViewPreferenceList(filteredViewPreferenceList);

  if (viewToDelete.default === true) {
    handleSetDefaultView(
      filteredViewPreferenceList[0],
      setTableViewPreferenceList,
      setSelectedView,
    );
  } else {
    const foundDefault = filteredViewPreferenceList.find(
      (view) => view.default === true,
    );
    setSelectedView(foundDefault ?? filteredViewPreferenceList[0]);
  }
}

export function handleSetDefaultView(
  viewToSetAsDefault: TableViewPreference,
  setTableViewPreferenceList: React.Dispatch<
    React.SetStateAction<TableViewPreference[]>
  >,
  setSelectedView: React.Dispatch<
    React.SetStateAction<TableViewPreference | null>
  >,
) {
  const currentTable = viewToSetAsDefault.table;

  //get the current list stored
  const storedTableViewPreferenceList: TableViewPreference[] = JSON.parse(
    localStorage.getItem("tableViewPreferenceList") || "[]",
  );

  //set it again to true because the object could came from a new object set to true or from a previous object that is set to false and we want to set to true
  viewToSetAsDefault.default = true;

  //because the list store all the table views, we need to be sure that we are modify only the item of the same table
  const updatedViewPreferenceList = storedTableViewPreferenceList.map(
    (view) => {
      if (view.id === viewToSetAsDefault.id && view.table === currentTable) {
        view.default = true;
        return view;
      } else if (
        view.id !== viewToSetAsDefault.id &&
        view.table === currentTable
      ) {
        view.default = false;
        return view;
      } else {
        return view;
      }
    },
  );

  //save the updated list with all the tables on localStorage
  localStorage.setItem(
    "tableViewPreferenceList",
    JSON.stringify(updatedViewPreferenceList),
  );
  console.log("updatedViewPreferenceList", updatedViewPreferenceList);

  //send to the preference list of that table, only the items related
  const filteredViewPreferenceList = updatedViewPreferenceList.filter(
    (view) => view.table === currentTable,
  );

  setTableViewPreferenceList(filteredViewPreferenceList);
  setSelectedView(viewToSetAsDefault);

  //get the current default object list stored
  const storedDefaultViewList: TableViewPreference[] = JSON.parse(
    localStorage.getItem("defaultViewList") || "[]",
  );

  //remove the old default for currentTable
  const filterStoredList = storedDefaultViewList.filter(
    (defaultView) => defaultView.table !== currentTable,
  );

  //push the new default into the list
  filterStoredList.push(viewToSetAsDefault);

  //save the updated list on localStorage
  localStorage.setItem("defaultViewList", JSON.stringify(filterStoredList));
  console.log("default table view preference list", filterStoredList);
}

export function handleSaveNewTableViewPreference(
  formValues: SaveTableViewPreferenceFormState,
  tableName: TableName,
  gridRef: React.MutableRefObject<any>,
  setTableViewPreferenceList: React.Dispatch<
    React.SetStateAction<TableViewPreference[]>
  >,
  setSelectedView: React.Dispatch<
    React.SetStateAction<TableViewPreference | null>
  >,
) {
  //get the actual column state
  window.colState = gridRef.current!.api.getColumnState();
  // Gets filter model via the grid API
  const filterModel = gridRef.current.api?.getFilterModel();

  //build the object
  const newTableViewPreference: TableViewPreference = {
    id: uuid4(),
    default: formValues.default,
    table: tableName,
    name: formValues.name,
    settings: window.colState,
    filter: filterModel,
  };

  const tableViewPreferenceList: TableViewPreference[] = JSON.parse(
    localStorage.getItem("tableViewPreferenceList") || "[]",
  );

  //push the object into the list
  tableViewPreferenceList.push(newTableViewPreference);

  // Save the list on localStorage 💾
  localStorage.setItem(
    "tableViewPreferenceList",
    JSON.stringify(tableViewPreferenceList),
  );
  console.log("table view preference is saved", newTableViewPreference);

  const filteredTableViewPreferenceList = tableViewPreferenceList.filter(
    (view) => view.table === tableName,
  );

  setTableViewPreferenceList(filteredTableViewPreferenceList);
  setSelectedView(newTableViewPreference);

  if (
    formValues.default === false &&
    filteredTableViewPreferenceList.length === 1
  ) {
    handleSetDefaultView(
      newTableViewPreference,
      setTableViewPreferenceList,
      setSelectedView,
    );
  }

  if (formValues.default === true) {
    handleSetDefaultView(
      newTableViewPreference,
      setTableViewPreferenceList,
      setSelectedView,
    );
  }
}
