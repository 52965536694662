//Libs
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseButtonPrimary from "../../BaseButtonPrimary";
import BaseButtonSecondary from "../../BaseButtonSecondary";
import BaseInputText from "../../BaseInputText";
import StyledMessage from "../../StyledMessage";
import StyledSwitchGroup from "../../StyledSwitchGroup";
import * as strings from "../../../strings";
import {
  ComplianceItemManager,
  StringComplianceItem_WithoutOrderProperty,
  StringItem_Schema_WithoutOrderProperty,
} from "../../../models/compliance-item";
import { logger as devLogger } from "../../../logging";
import { ErrorMessage } from "../../ErrorMessage";
import { ComplianceItemProps } from "./NewComplianceItemDialog";

const stringComplianceDefaultValues: StringComplianceItem_WithoutOrderProperty =
  {
    text: "",
    required: false,
    units: "",
    responseType: "string",
    passingMin: null,
    passingMax: null,
    selectionOptions: null,
    passingOptions: null,
  };

export const StringComplianceItemForm: React.FunctionComponent<
  ComplianceItemProps
> = (props) => {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<StringComplianceItem_WithoutOrderProperty>({
    defaultValues: stringComplianceDefaultValues,
    resolver: zodResolver(StringItem_Schema_WithoutOrderProperty),
    mode: "onChange",
  });

  useEffect(() => {
    reset(stringComplianceDefaultValues);
  }, [reset]);

  const onSubmit: SubmitHandler<
    StringComplianceItem_WithoutOrderProperty
  > = async (formValues) => {
    const values: StringComplianceItem_WithoutOrderProperty = {
      text: formValues.text,
      required: formValues.required,
      units: formValues.units,
      responseType: "string",
      passingMin: formValues.passingMin,
      passingMax: formValues.passingMax,
      selectionOptions: formValues.selectionOptions,
      passingOptions: formValues.passingOptions,
    };

    // Validate before write to DB
    const validatedValues = ComplianceItemManager.parsePartial(values);

    try {
      props.handleSaveComplianceItem(validatedValues);
      // Close the section if successful.
      onCloseComplianceItem();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  /* fn that handle all the states that needs to be reset when the section is closed */
  function onCloseComplianceItem() {
    props.closeDialog();
    reset();
  }

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <div className="flex flex-col text-lg">
      <form
        autoComplete="off"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        className="space-y-8"
      >
        {/* Field: Text */}
        <div>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Text"
                inputName="text"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {errors.text?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.text.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Field: Required */}
        <div>
          <Controller
            name="required"
            control={control}
            render={({ field }) => (
              <StyledSwitchGroup
                readableName="Required"
                onBlur={field.onBlur}
                onChange={field.onChange}
                ref={field.ref}
                checked={field.value}
                id="required"
                name={field.name}
              />
            )}
          />
        </div>

        {/* Field: Units */}
        <div>
          <Controller
            name="units"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Units"
                inputName="units"
                admin={true}
                required={false}
                {...field}
              />
            )}
          />
          {errors.units?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.units.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        <span className="-my-5 h-10 justify-self-center sm:col-span-2 lg:col-span-3">
          {displayError ? errorMessage : null}
        </span>

        {/* Action Buttons */}
        <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={onCloseComplianceItem}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
            data-testid="save item"
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </form>
    </div>
  );
};
