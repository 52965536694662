//Libs
import { Fragment, useEffect, useMemo, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseInputNumber from "../BaseInputNumber";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseInputSelect from "../BaseInputSelect";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import { logger as devLogger } from "../../logging";
import { ErrorMessage } from "../ErrorMessage";

interface Props {
  isDialogOpen: boolean;
  inventoryLocationList: ExistingInventoryLocation[];
  closeDialog: () => void;
  handleSave: (formValues: InventoryObjectState) => Promise<void>;
}

const InventoryObjectSchema = z.object({
  title: z.string().min(1).max(500),
  inventoryLocation: z.string().min(1).max(200),
  available: z.union([z.number(), z.literal("")]),
  reserved: z.union([z.number(), z.literal("")]),
  inUse: z.union([z.number(), z.literal("")]),
  awaitingPickup: z.union([z.number(), z.literal("")]),
  lowQuantityThreshold: z.union([z.number(), z.literal("")]),
});
export type InventoryObjectState = z.infer<typeof InventoryObjectSchema>;

export default function AddInventoryObjectDialog({
  closeDialog,
  handleSave,
  isDialogOpen,
  inventoryLocationList,
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const inventoryObjectDefaultValues: InventoryObjectState = useMemo(() => {
    return {
      title: "",
      inventoryLocation: "",
      available: "",
      reserved: "",
      inUse: "",
      awaitingPickup: "",
      lowQuantityThreshold: "",
    };
  }, []);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<InventoryObjectState>({
    defaultValues: inventoryObjectDefaultValues,
    resolver: zodResolver(InventoryObjectSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(inventoryObjectDefaultValues);
  }, [inventoryObjectDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function onCloseDialog() {
    closeDialog();
    reset();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit: SubmitHandler<InventoryObjectState> = async (formValues) => {
    try {
      await handleSave(formValues);
      // Close dialog if successful.
      onCloseDialog();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  const inventoryObjectHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.NEW_INVENTORY_OBJECT}
      </h1>
      <button type="button" onClick={() => onCloseDialog()}>
        <XMarkIcon
          aria-label="close location form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = (
    <ErrorMessage
      message={strings.ERR_CREATE_NEW_INVENTORY_OBJECT}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <BaseModal
      closeModal={onCloseDialog}
      open={isDialogOpen}
      title={inventoryObjectHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <Fragment>
        <TheForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          control={control}
          isSubmitting={isSubmitting}
          handleCancel={onCloseDialog}
          inventoryLocationList={inventoryLocationList}
        />
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </Fragment>
    </BaseModal>
  );
}

interface FormProps {
  handleSubmit: UseFormHandleSubmit<InventoryObjectState>;
  onSubmit: SubmitHandler<InventoryObjectState>;
  errors: FieldErrors;
  control: Control<InventoryObjectState, any>;
  isSubmitting: boolean;
  handleCancel: () => void;
  inventoryLocationList: Props["inventoryLocationList"];
}

// Extracted 'TheForm' because: https://stackoverflow.com/a/67002581
const TheForm = (props: FormProps) => {
  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit(props.onSubmit)}
      className="flex flex-col space-y-8 p-8 text-lg"
    >
      {/* Field: Title */}
      <div>
        <Controller
          name="title"
          control={props.control}
          render={({ field }) => (
            <BaseInputText
              text="Title"
              inputName="title"
              admin={true}
              required={true}
              {...field}
            />
          )}
        />
        {props.errors.title?.message &&
          typeof props.errors.title.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.title.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Field: Inventory Location */}
      <div>
        <Controller
          name="inventoryLocation"
          control={props.control}
          render={({ field }) => (
            <BaseInputSelect
              inputName="inventoryLocation"
              text="Inventory Location"
              admin={true}
              required={true}
              {...field}
            >
              {/* The empty string for value tricks validation into failing */}
              <option disabled value="">
                Select a location
              </option>
              <option value="all">All Locations</option>

              {props.inventoryLocationList.map(
                (inventoryLocation, inventoryLocationIdx) => (
                  <option
                    key={inventoryLocationIdx}
                    value={inventoryLocation.id}
                  >
                    {inventoryLocation.title}
                  </option>
                ),
              )}
            </BaseInputSelect>
          )}
        />
        {props.errors.department?.message &&
          typeof props.errors.department.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.department.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Field: Quantity Available */}
      <div>
        <Controller
          name="available"
          control={props.control}
          render={({ field }) => (
            <BaseInputNumber
              text="Quantity Available"
              inputName="available"
              admin={true}
              required={false}
              {...field}
              value={field.value === "" ? "" : field.value}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                field.onChange(isNaN(numberValue) ? "" : numberValue);
              }}
            />
          )}
        />
        {props.errors.available?.message &&
          typeof props.errors.available.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.available.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Field: Quantity Awaiting Pickup */}
      <div>
        <Controller
          name="awaitingPickup"
          control={props.control}
          render={({ field }) => (
            <BaseInputNumber
              text="Quantity Awaiting Pickup"
              inputName="awaitingPickup"
              admin={true}
              required={false}
              {...field}
              value={field.value === "" ? "" : field.value}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                field.onChange(isNaN(numberValue) ? "" : numberValue);
              }}
            />
          )}
        />
        {props.errors.awaitingPickup?.message &&
          typeof props.errors.awaitingPickup.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.awaitingPickup.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Field: Quantity In Use */}
      <div>
        <Controller
          name="inUse"
          control={props.control}
          render={({ field }) => (
            <BaseInputNumber
              text="Quantity In Use"
              inputName="inUse"
              admin={true}
              required={false}
              {...field}
              value={field.value === "" ? "" : field.value}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                field.onChange(isNaN(numberValue) ? "" : numberValue);
              }}
            />
          )}
        />
        {props.errors.inUse?.message &&
          typeof props.errors.inUse.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.inUse.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Field: Quantity Reserved */}
      <div>
        <Controller
          name="reserved"
          control={props.control}
          render={({ field }) => (
            <BaseInputNumber
              text="Quantity Reserved"
              inputName="reserved"
              admin={true}
              required={false}
              {...field}
              value={field.value === "" ? "" : field.value}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                field.onChange(isNaN(numberValue) ? "" : numberValue);
              }}
            />
          )}
        />
        {props.errors.reserved?.message &&
          typeof props.errors.reserved.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.reserved.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Field: Low Quantity Threshold */}
      <div>
        <Controller
          name="lowQuantityThreshold"
          control={props.control}
          render={({ field }) => (
            <BaseInputNumber
              text="Low Quantity Threshold"
              inputName="lowQuantityThreshold"
              admin={true}
              required={false}
              {...field}
              value={field.value === "" ? "" : field.value}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                field.onChange(isNaN(numberValue) ? "" : numberValue);
              }}
            />
          )}
        />
        {props.errors.lowQuantityThreshold?.message &&
          typeof props.errors.lowQuantityThreshold.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.lowQuantityThreshold.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      {/* Action Buttons */}
      <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={props.handleCancel}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          disabled={props.isSubmitting}
          isBusy={props.isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
    </form>
  );
};
