//Libs
import {
  Fragment,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

//Local
import { ExistingGLAccount } from "../../models/gl-account";
import currencyFormatter from "../../currency";

interface Props {
  gLAccountList: ExistingGLAccount[];
}

export default function GLAccountListPage({ gLAccountList }: Props) {
  return (
    <Fragment>
      <div className={"ag-theme-alpine isolate h-[600px]"}>
        <GLAccountListTable gLAccountList={gLAccountList} />
      </div>
    </Fragment>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  flex: 1,
};

interface GLAccountListTableProps {
  gLAccountList: Props["gLAccountList"];
}

/* TABLE COMPONENT */
const GLAccountListTable = memo(
  ({ gLAccountList }: GLAccountListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const renderBalanceCell = (params: ICellRendererParams): JSX.Element => {
      return (
        <div>{` ${currencyFormatter(params.data.balance, params.data.currency)}`}</div>
      );
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Name",
        field: "name",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Account Type",
        field: "accountType",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Currency",
        field: "currency",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Balance $",
        field: "balance",
        cellRenderer: renderBalanceCell,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="-z-10 mt-5 shadow"
        rowData={gLAccountList}
        animateRows={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
      />
    );
  },
);
