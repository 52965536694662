//Libs
import { useNavigate } from "react-router-dom";
//Local
import NavButtonBack from "./NavButtonBack";

interface Props {
  pages: Record<string, any>[];
  home: Record<string, any>;
}

export default function Breadcrumbs({ pages, home }: Props) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="hidden items-center sm:flex">
        <NavButtonBack />
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li className="flex">
              <div className="flex items-center">
                <button
                  onClick={() => navigate(home.href)}
                  className="text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <span className="capitalize">{home.name}</span>
                </button>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name} className="flex">
                <div className="flex items-center">
                  <svg
                    className="h-full w-6 flex-shrink-0 text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() => navigate(page.href)}
                    className={`ml-4 text-sm font-medium ${
                      page.current
                        ? "text-primary hover:text-primaryDark"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                  >
                    <span className="capitalize">{page.name}</span>
                  </button>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="mb-2 grid grid-cols-1 sm:hidden">
        <NavButtonBack />
        <button
          onClick={() => navigate(home.href)}
          className="text-left font-medium"
        >
          <span className="capitalize">{home.name}</span> {`>`}
        </button>
        {pages.map((page) => (
          <button
            key={page.name}
            onClick={() => navigate(page.href)}
            className="text-left font-medium"
          >
            <span className="capitalize">{page.name}</span> {`>`}
          </button>
        ))}
      </div>
    </div>
  );
}
