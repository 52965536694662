import { createConsoleChannel } from "./channel-console";
import { Channel, CommonLogMethods, LogLevel } from "./types";

/**
 * Dispatches log entries to various destinations depending on environment
 * and configuration. (To the console, or to sentry.io.)
 */
export class Logger implements CommonLogMethods {
  readonly name: string;
  readonly levels = LogLevel;
  private defaultLevel: LogLevel = LogLevel.DEBUG;
  private currentLevel: LogLevel = LogLevel.DEBUG;
  private channels: Channel[] = [createConsoleChannel()];
  private defaultChannels: Channel[] = [createConsoleChannel()];

  constructor(name = "default") {
    this.name = name;
  }

  /** Set the default log level */
  setDefaultLevel(level: LogLevel) {
    this.defaultLevel = level;
  }

  /** Return the default log level */
  getDefaultLevel(): LogLevel {
    return this.defaultLevel;
  }

  /** Set the current log level */
  setLevel(level: LogLevel) {
    this.currentLevel = level;
  }

  /** Return the current log level */
  getCurrentLevel(): LogLevel {
    return this.currentLevel;
  }

  /** Restore the current level to the default level */
  resetLevel() {
    this.currentLevel = this.defaultLevel;
  }

  addChannel(newChannel: Channel) {
    this.channels.push(newChannel);
  }

  removeChannel(channelID: string) {
    const index = this.channels.findIndex(
      (channel) => channel.id === channelID,
    );
    if (index === -1) throw Error(`Unable to find channel: ${channelID}`);
    this.channels.splice(index, 1);
  }

  /** Returns a list of channel IDs */
  listChannels(): string[] {
    return this.channels.map((channel) => channel.id);
  }

  setDefaultChannels(list: Channel[]) {
    this.defaultChannels = list;
  }

  /**
   * Set channels back to the default channels
   */
  resetChannels() {
    this.channels = [...this.defaultChannels];
  }

  /** Remove all channels from the logger instance */
  clearChannels() {
    this.channels = [];
  }

  debug(...data: unknown[]) {
    if (this.currentLevel <= LogLevel.DEBUG) {
      this.channels.forEach((channel) => {
        channel.methods.debug(...data);
      });
    }
  }
  info(...data: unknown[]) {
    if (this.currentLevel <= LogLevel.INFO) {
      this.channels.forEach((channel) => {
        channel.methods.info(...data);
      });
    }
  }
  warn(...data: unknown[]) {
    if (this.currentLevel <= LogLevel.WARN) {
      this.channels.forEach((channel) => {
        channel.methods.warn(...data);
      });
    }
  }
  error(...data: unknown[]) {
    if (this.currentLevel <= LogLevel.ERROR) {
      this.channels.forEach((channel) => {
        channel.methods.error(...data);
      });
    }
  }
}
