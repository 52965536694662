//Libs
import React from "react";

export interface Props {
  isResponseCardsView: boolean;
  children: {
    breadcrumbs: React.ReactNode;
    pageHeading: React.ReactNode;
    templateInfo: React.ReactNode;
    taskInfo: React.ReactNode;
    responseCardsList: React.ReactNode;
    responseTable: React.ReactNode;
  };
}

export default function TaskDetailsLayout(props: Props) {
  return (
    <div className="flex h-full flex-col">
      {props.children.breadcrumbs}
      <div className="mt-2 grid grid-cols-1 justify-between rounded-md bg-primary px-4 py-2 shadow-md sm:flex sm:items-center sm:space-x-4">
        <div className="lg:w-1/2">
          <div className="flex flex-col-reverse lg:flex-row lg:items-center lg:justify-between">
            {props.children.pageHeading}
          </div>
          {props.children.templateInfo}
        </div>
        <div className="mt-2 sm:mt-0 sm:w-1/2">{props.children.taskInfo}</div>
      </div>
      {props.isResponseCardsView
        ? props.children.responseCardsList
        : props.children.responseTable}
    </div>
  );
}
