//Libs
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

//Local
import ListAllUsersTable from "../../components/admin/ListAllUsersTable";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { DbRead } from "../../database";
import { useSiteKeyCompaniesStore } from "../../store/site-key-companies";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import { useFilterTabs } from "./use-filter-tabs";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import { ADMIN_EDIT_USER_URL, BASE_APP_URL } from "../../urls";
import { StyledTooltip } from "../../components/StyledTooltip";
import EscapeIframeBreadcrumbs from "../../components/EscapeIframeBreadcrumbs";
import * as strings from "../../strings";
import { useSiteKeyDocStore } from "../../store/site-key-doc";

interface Props {
  siteKey: string;
}

export default function UserManagmentContainer({ siteKey }: Props) {
  const navigate = useNavigate();

  //STATES
  const listOfCompanies: ExistingSiteKeyCompany[] = useSiteKeyCompaniesStore(
    (state) => state.siteKeyCompanies,
  );

  function goToAddUserPage() {
    navigate("add-user");
  }

  function goToEditPage(userID: string) {
    navigate(`${ADMIN_EDIT_USER_URL}/${userID}`);
  }

  //QUERY TO GET ALL USERS AND PERMISSIONS MAP
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  const { data: siteKeyUsersList = [], isLoading: usersListLoading } = useQuery(
    ["users", siteKey],
    () => {
      if (siteKeyDoc != null) {
        return DbRead.user.getAllUsers({
          siteKey: siteKeyDoc,
          filterInactiveUnapproved: false,
        });
      }
      return [];
    },
    { enabled: !!siteKeyDoc },
  );

  /* Fetch the list of permissions when this component loads */
  const { data: permissionsMap = {}, isLoading: permissionsMapLoading } =
    useQuery(
      ["permissions", siteKey],
      () => getPermissionsMap(siteKeyUsersList, siteKey),
      {
        enabled: siteKeyUsersList.length > 0,
      },
    );

  /* Fetch the root users map when the users list is ready */
  const { data: rootUsersMap = {}, isLoading: rootUsersMapLoading } = useQuery(
    ["userManagementContainer_rootUsersMap", siteKey],
    () => DbRead.rootUser.generateRootUsersMap(siteKey),
    {
      enabled: siteKeyUsersList.length > 0,
    },
  );

  //FILTER USER PERMISSIONS
  const { filteredListOfUsers, filterTabs } = useFilterTabs(
    siteKeyUsersList,
    permissionsMap,
  );

  //TABLE OF ALL USERS
  const addUserButton = (
    <StyledTooltip title="Add User">
      <button type="button" onClick={goToAddUserPage} className="text-primary">
        <AddBoxIcon aria-label="Add User" sx={{ fontSize: 45 }} />
      </button>
    </StyledTooltip>
  );

  // Breadcrumbs
  const vueAdminPanelLink = BASE_APP_URL + "/user-info";
  const home = {
    name: strings.ADMIN,
    href: vueAdminPanelLink,
  };
  const pages = [
    {
      name: strings.USER_MANAGEMENT,
      href: null,
      current: true,
    },
  ];
  const Breadcrumbs = (
    <EscapeIframeBreadcrumbs
      home={home}
      pages={pages}
      backButtonHref={vueAdminPanelLink}
    />
  );

  // RENDER LOADING
  if (usersListLoading || permissionsMapLoading || rootUsersMapLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  //RENDER CONTENT
  return (
    <ListAllUsersTable
      usersList={filteredListOfUsers}
      usersPermissionsMap={permissionsMap}
      rootUsersMap={rootUsersMap}
      companiesList={listOfCompanies}
      goToEditPage={goToEditPage}
    >
      {{
        Breadcrumbs,
        addUserButton: addUserButton,
        filterTabs: filterTabs,
      }}
    </ListAllUsersTable>
  );
}

export async function getPermissionsMap(
  allUsersList: ExistingSiteKeyUserDoc[],
  siteKey: string,
): Promise<Record<string, ExistingSiteKeyUserPermissions>> {
  const permissionsMap: Record<string, ExistingSiteKeyUserPermissions> = {};

  const readPromises = allUsersList.map(async (user) => {
    if (user.id) {
      const permissionDoc = await DbRead.user.getSiteKeyPermissions(
        siteKey,
        user.id,
      );
      permissionsMap[user.id] = permissionDoc;
    }
  });
  await Promise.all(readPromises);
  return permissionsMap;
}
