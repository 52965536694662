//Libs
import { useMutation, useQueryClient } from "react-query";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import { DbWrite } from "../../database";
import BaseModal from "../BaseModal";
import BaseInputText from "../BaseInputText";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";

interface Props {
  siteKey: string;
  userID: string;
  userPhone: string;
  isDialogOpen: boolean;
  closeDialog: () => void;
}

const UserPhoneSchema = z.object({
  phone: z.string().min(1).max(50),
});
export type EditUserPhoneState = z.infer<typeof UserPhoneSchema>;

export default function EditUserPhoneDialog(props: Props) {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<EditUserPhoneState>({
    defaultValues: { phone: props.userPhone },
    resolver: zodResolver(UserPhoneSchema),
    mode: "onChange",
  });

  const queryClient = useQueryClient();
  const userQueryKey = ["user", props.siteKey, props.userID];
  const { mutate: mutateUserPhone, isLoading } = useMutation(
    async (userPhone: string) => {
      return await DbWrite.user.updateUserPhone(
        userPhone,
        props.siteKey,
        props.userID,
      );
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(userQueryKey);
        props.closeDialog();
      },
      onError: (error) => {
        console.error(
          "An error occurred while updating the phone number:",
          error,
        );
      },
    },
  );

  const userPhoneHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Edit Phone Number
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon aria-label="close phone form" className="h-6 text-white" />
      </button>
    </div>
  );

  const onClickSaveButton = async (formValues: EditUserPhoneState) => {
    mutateUserPhone(formValues.phone);
  };

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={userPhoneHeader}
      parentDivStyles="max-w-lg inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <form
        className="flex flex-col space-y-8 p-8 text-lg"
        autoComplete="off"
        onSubmit={handleSubmit(onClickSaveButton)}
      >
        {/* Field: Phone Number */}
        <div>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Phone Number"
                inputName="phone"
                type="tel"
                required={true}
                admin={true}
                {...field}
              />
            )}
          />
          {errors.phone?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.phone.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Action Buttons */}
        <div className="mt-4 flex w-full items-center justify-between gap-4">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={() => {
              props.closeDialog();
            }}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            disabled={isSubmitting || isLoading}
            isBusy={isSubmitting || isLoading}
            busyText={strings.buttons.BUSY_SAVING}
            type="submit"
            className="w-full justify-center uppercase"
            formNoValidate
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </form>
    </BaseModal>
  );
}
