import { Timestamp } from "firebase/firestore";

/**
 * Given an ISO datetime string, returns a Firestore Timestamp.
 * @throws `Error` If the input is not a string or the Timestamp is invalid.
 */
export function convertISOToFSTimestamp(iso: unknown): Timestamp {
  if (typeof iso !== "string") {
    throw new Error(`Input was not a string. Received: ${iso}`);
  }
  const ms = Date.parse(iso);
  const ts = Timestamp.fromMillis(ms);
  if (Number.isNaN(ts.seconds)) {
    throw new Error(`Invalid Timestamp. Received: ${iso}`);
  }
  return ts;
}
