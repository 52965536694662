// Libs
import { useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/solid";

// Local
import { MultipleUidCustomField } from "../models/custom-field";
import MultipleUidDialog, {
  IMultipleUid_AssignUser,
} from "./CustomFields/MultipleUidDialog";

interface Props {
  userList: IMultipleUid_AssignUser[];
  customField: MultipleUidCustomField;
  handleAssignUsers: (uidList: string[]) => void;
  resourceFromCalendarUID?: string | null;
}

export default function AssignedToCustomField(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [resourceFromCalendar, setResourceFromCalendar] = useState<
    string | null
  >(props.resourceFromCalendarUID ?? null);

  // display which users are selected when the dialog is closed.
  const [selectedUsersString, setSelectedUsersString] = useState(
    props.userList
      .filter((user) => user.isAssigned)
      .map((user) => user.name)
      .join(", "),
  );

  const [updatedUserList, setUpdatedUserList] = useState<
    IMultipleUid_AssignUser[]
  >(props.userList);

  useEffect(() => {
    setUpdatedUserList(props.userList);
    const selectedList = props.userList.filter((user) => user.isAssigned);
    setSelectedUsersString(selectedList.map((user) => user.name).join(", "));
  }, [props.userList]);

  useEffect(() => {
    if (props.resourceFromCalendarUID != null) {
      const foundUser = updatedUserList.find(
        (u) => u.uid === props.resourceFromCalendarUID,
      );
      const oldUser = updatedUserList.find(
        (u) => u.uid === resourceFromCalendar,
      );
      if (oldUser) {
        oldUser.isAssigned = false;
      }
      if (foundUser) {
        foundUser.isAssigned = true;
        const updatedList = [...new Set([...updatedUserList, foundUser])];
        setUpdatedUserList(updatedList);
        const selectedList = updatedList.filter((user) => user.isAssigned);
        setSelectedUsersString(
          selectedList.map((user) => user.name).join(", "),
        );
        setResourceFromCalendar(props.resourceFromCalendarUID);
      }
    }
  }, [props.resourceFromCalendarUID, resourceFromCalendar]);

  function handleDialogSubmit(users: IMultipleUid_AssignUser[]) {
    const selectedList = users.filter((user) => user.isAssigned);
    setSelectedUsersString(selectedList.map((user) => user.name).join(", "));
    setUpdatedUserList(users);
    const selectedUIDs = getAssignedUids(users);
    props.handleAssignUsers(selectedUIDs);
  }

  function getAssignedUids(users: IMultipleUid_AssignUser[]): string[] {
    const selectedList = users.filter((user) => user.isAssigned);
    return selectedList.map((user) => user.uid);
  }

  return (
    <div>
      <MultipleUidDialog
        open={isOpen}
        closeDialog={() => setIsOpen(false)}
        userList={updatedUserList}
        handleSubmit={(ev) => {
          handleDialogSubmit(ev);
        }}
      />

      <button
        id={`selection-dropdown-for-${props.customField.title}`}
        type="button"
        className="w-full cursor-pointer rounded px-4 py-3 transition-colors hover:bg-gray-50"
        onClick={() => setIsOpen(true)}
      >
        <div className="flex items-center gap-4">
          <UserCircleIcon
            aria-hidden={true}
            className="h-7 w-7 shrink-0 rounded text-gray-600"
          />
          <label
            htmlFor={`selection-dropdown-for-${props.customField.title}`}
            className="block cursor-pointer"
          >
            {props.customField.title}
          </label>
          <ChevronRightIcon
            aria-hidden={true}
            className="ml-auto h-5 w-5 shrink-0"
          />
        </div>
        <span className="block w-full pl-12 pr-6 text-left">
          {selectedUsersString}
        </span>
      </button>
    </div>
  );
}
