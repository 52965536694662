type AddressComponents = {
  streetNumber: string;
  street: string;
  city: string;
  zipCode: string;
  state: string;
  county: string;
};

export function splitAddressComponents(
  geocoderResult: google.maps.GeocoderResult,
): AddressComponents {
  const addressComponents = {
    streetNumber: "",
    street: "",
    city: "",
    zipCode: "",
    state: "",
    county: "",
  };

  geocoderResult.address_components.forEach((addressComponent) => {
    if (addressComponent.types.includes("street_number")) {
      return (addressComponents.streetNumber = addressComponent.long_name);
    } else if (addressComponent.types.includes("route")) {
      return (addressComponents.street = addressComponent.long_name);
    } else if (addressComponent.types.includes("locality")) {
      return (addressComponents.city = addressComponent.long_name);
    } else if (addressComponent.types.includes("administrative_area_level_2")) {
      return (addressComponents.county = addressComponent.long_name);
    } else if (addressComponent.types.includes("postal_code")) {
      return (addressComponents.zipCode = addressComponent.long_name);
    } else if (addressComponent.types.includes("administrative_area_level_1")) {
      return (addressComponents.state = addressComponent.short_name);
    } else {
      return "";
    }
  });
  return addressComponents;
}
