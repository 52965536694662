//Libs
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import groupBy from "lodash/groupBy";

//Local
import CustomFieldsPage from "./CustomFieldsPage";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { DbRead, DbWrite } from "../../database";
import { useMemo, useState } from "react";
import { ExistingSiteKey } from "../../models/site-key";
import {
  CustomFieldDocData,
  ExistingCustomField,
  CustomFieldToDelete,
  CustomFieldManager,
} from "../../models/custom-field";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  CraftTypeValues,
  getCraftTypeFromString,
  getCraftTypeRecordString,
  isValidCraftType,
  getValidTaskTypes,
  getReadableCraftType,
} from "../../models/craft-types";
import CustomFieldSidebarMenu from "../../components/admin/CustomFieldSidebarMenu";
import CustomFieldDropdownMenu from "../../components/admin/CustomFieldDropdownMenu";
import CustomFieldCardsSection from "../../components/admin/CustomFieldSection";
import AddCustomFieldDialog from "../../components/admin/AddCustomFieldDialog/AddCustomFieldDialog";
import { getReadableTaskType } from "../../models/task-types";
import CustomFieldCard from "../../components/admin/CustomFieldCard";
import CustomFieldsSidebarMenuButton from "../../components/admin/CustomFieldsSidebarMenuButton";
import CustomFieldsDropdownMenuButton from "../../components/admin/CustomFieldsDropdownMenuButton";
import { logger as devLogger } from "../../logging";
import WorkTypeIcon from "../../assets/js/WorkTypeIcon";
import SiteKeyDropdown from "../../components/admin/SiteKeyDropdown";
import WorkTypeDropdown from "../../components/admin/WorkTypeDropdown";
import { guardIsPlainObject } from "../../utils";
import {
  isValidTaskStatusValue,
  TaskStatusValues,
} from "../../models/task-status";
import { ADMIN_MY_SITES_URL } from "../../urls";

export default function CustomFieldsContainer() {
  // Retrieve chosen siteKey id and work type from the url.
  type UrlParams = { id: string; workType: string };
  const { id: selectedSiteKeyID, workType: selectedWorkType } =
    useParams<UrlParams>();
  if (typeof selectedSiteKeyID !== "string") {
    throw new Error(`selectedSiteKeyID was not a string: ${selectedSiteKeyID}`);
  }

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [dialogWorkType, setDialogWorkType] = useState<
    CraftTypeValues | undefined
  >();

  function openAddCustomFieldDialog(workType: CraftTypeValues | undefined) {
    setIsAddDialogOpen(true);
    setDialogWorkType(workType);
  }

  // useMutation so we can invalidate the stale queries.
  const mutateAddCustomField = useMutation(
    async ({ siteKey, data }: { siteKey: string; data: CustomFieldDocData }) =>
      await DbWrite.customFields.add(siteKey, data),
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries([
          "customFieldsContainer_siteKeyList",
        ]),
    },
  );

  // Executes when "save" is clicked in the add custom field dialog.
  async function handleSave(data: CustomFieldDocData): Promise<void> {
    if (typeof selectedSiteKeyID !== "string") {
      throw new Error(
        `selectedSiteKeyID was not a string: ${selectedSiteKeyID}`,
      );
    }
    // Validation happened in the dialog.
    devLogger.debug("adding custom field. data:", data);
    return mutateAddCustomField.mutateAsync({
      siteKey: selectedSiteKeyID,
      data,
    });
  }

  /* Function for the Site Key Dropdown */
  function handleSelectSiteKey(siteKeyID: string) {
    if (siteKeyID !== null && siteKeyID !== selectedSiteKeyID) {
      navigate(
        `${ADMIN_MY_SITES_URL}/${siteKeyID}/custom-fields/${selectedWorkType}`,
      );
    }
  }

  /* Function for the Work Type Dropdown */
  function handleSelectedWorkType(workType: string) {
    if (workType !== null && workType !== selectedWorkType) {
      navigate(
        `${ADMIN_MY_SITES_URL}/${selectedSiteKeyID}/custom-fields/${workType}`,
      );
    }
  }

  /* Mutation for delete custom field db write call */
  const mutateDeleteCustomField = useMutation(
    async (args: {
      siteKey: string;
      documentID: string;
      customFieldDeletionParams: CustomFieldToDelete;
    }) =>
      await DbWrite.customFields.deleteCustomField(
        args.siteKey,
        args.documentID,
        args.customFieldDeletionParams,
      ),
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries([
          "customFieldsContainer_siteKeyList",
        ]),
    },
  );

  /* Function that handle the delete custom field */
  async function handleDeleteCustomField(field: ExistingCustomField) {
    if (typeof selectedSiteKeyID !== "string") {
      throw new Error(
        `selectedSiteKeyID was not a string: ${selectedSiteKeyID}`,
      );
    }

    if (field.craftRecordOrTask === "craftRecord") {
      const dataToDelete = {
        craftRecordOrTask: field.craftRecordOrTask,
        craftType: field.craftType,
      };
      await mutateDeleteCustomField.mutateAsync({
        siteKey: selectedSiteKeyID,
        documentID: field.id,
        customFieldDeletionParams: dataToDelete,
      });
    } else {
      const dataToDelete = {
        craftRecordOrTask: field.craftRecordOrTask,
        craftType: field.craftType,
        taskType: field.taskType,
      };
      await mutateDeleteCustomField.mutateAsync({
        siteKey: selectedSiteKeyID,
        documentID: field.id,
        customFieldDeletionParams: dataToDelete,
      });
    }
  }
  // TEMP: remove others if this does not work.
  // const [fetchStore, siteKeyDocList] = useSiteKeyStore((state) => [
  //   state.fetch,
  //   state.siteKeyList,
  // ]);

  // useEffect(() => {
  //   fetchStore();
  // }, [fetchStore]);

  // Retrieve admin's siteKey docs and the loading status.
  // TODO: use rootUserSiteKeyList store to fetch data and change how to handle onSuccess invalidQueries on mutations by refetching data from the store
  // we should do that because we stored data twice in store and useQuery cache so it's redundant.
  const { data: siteKeyList = [], isLoading: siteKeyListIsLoading } = useQuery(
    "customFieldsContainer_siteKeyList",
    () => DbRead.siteKey.getRootUserListOfSiteKeyDocs(),
  );

  const customFieldsList = useMemo(
    () => getCustomFieldsForSite(selectedSiteKeyID, siteKeyList),
    [selectedSiteKeyID, siteKeyList],
  );

  // NOTE: this is commented out because we are not using the actual `customFields` collection at the moment.
  // get all the customFields for a siteKey
  // const { data: customFieldsList = [], isLoading: customFieldsLoading } =
  //   useQuery(["customFieldsContainer_customFields", selectedSiteKeyID], () =>
  //     DbRead.customFields.getAllCustomFields(selectedSiteKeyID)
  //   );

  //sort the siteKeyList alphabetically
  const sortedSiteKeyList = useMemo(
    function (): ExistingSiteKey[] {
      return siteKeyList.sort((a, b) => a.id!.localeCompare(b.id!));
    },
    [siteKeyList],
  );

  // Retrieve the current siteKey doc.
  const currentSiteKey = sortedSiteKeyList.find(
    (siteKey) => siteKey.id === selectedSiteKeyID,
  );

  /* List of all the available work types for the current site key, stringified and sorted alphabetically */
  const readableWorkTypeList = useMemo(
    function (): string[] {
      // Work types that the current siteKey has access to.
      const workTypesList = currentSiteKey
        ? currentSiteKey.validCraftTypes
        : [];
      // Filter out any invalid work types.
      const validWorkTypesList = workTypesList.filter((type) =>
        isValidCraftType(type),
      );
      const readable = validWorkTypesList.map((workType) => {
        //available work types already contain only valid craft types
        const stringifiedWorkType = getReadableCraftType(
          workType as CraftTypeValues,
        );
        return stringifiedWorkType;
      });
      return readable.sort((a, b) => a.localeCompare(b));
    },
    [currentSiteKey],
  );

  if (typeof selectedWorkType !== "string") {
    throw new Error(`selectedWorkType was not a string: ${selectedWorkType}`);
  }
  /* Convert the work type string value in the numerical CraftTypeValues */
  const convertedSelectedWorkType = getCraftTypeFromString(selectedWorkType);

  /* with the numerical value, get the work type record string and make it readable */
  const readableCraftTypeRecord = convertedSelectedWorkType
    ? getCraftTypeRecordString(convertedSelectedWorkType)
        .split(/(?=[A-Z])/)
        .join(" ")
    : "Unknown";

  /* Filter all the craft records */
  const craftRecordsList = useMemo(
    function (): ExistingCustomField[] {
      return customFieldsList
        .filter((customField) => {
          return (
            customField.craftType === convertedSelectedWorkType &&
            customField.craftRecordOrTask === "craftRecord"
          );
        })
        .sort((a, b) => {
          const tOne = a.title;
          const tTwo = b.title;
          return tOne.localeCompare(tTwo);
        });
    },
    [convertedSelectedWorkType, customFieldsList],
  );

  /* Filter all the tasks */
  const tasksList = useMemo(
    function (): ExistingCustomField[] {
      return customFieldsList
        .filter((customField) => {
          return (
            customField.craftType === convertedSelectedWorkType &&
            customField.craftRecordOrTask === "task" &&
            customField.taskType !== null
          );
        })
        .sort((a, b) => {
          const tOne = a.title;
          const tTwo = b.title;
          return tOne.localeCompare(tTwo);
        });
    },
    [convertedSelectedWorkType, customFieldsList],
  );

  /* group all the tasks by task type */
  const tasksGroupedByTaskType = groupBy(tasksList, (taskField) =>
    taskField.craftRecordOrTask === "task" ? taskField.taskType : null,
  );

  const validTaskTypeList =
    convertedSelectedWorkType != null
      ? getValidTaskTypes(convertedSelectedWorkType)
      : null;

  // Loading indicator.
  if (siteKeyListIsLoading || !currentSiteKey) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const validTaskStatusList: TaskStatusValues[] =
    currentSiteKey.validTaskStatusCodes.flatMap((code) =>
      isValidTaskStatusValue(code) ? code : [],
    );

  /* Breadcrumbs */
  const home = { name: "Admin", href: ADMIN_MY_SITES_URL, current: false };
  const pages = [
    { name: "My Sites", href: ADMIN_MY_SITES_URL, current: false },
    {
      name: `${currentSiteKey.name}`,
      href: `${ADMIN_MY_SITES_URL}/${currentSiteKey.id}`,
      current: false,
    },
    {
      name: `${selectedWorkType}`,
      href: `${ADMIN_MY_SITES_URL}/${currentSiteKey.id}/custom-fields/${selectedWorkType}`,
      current: true,
    },
  ];

  const breadcrumbs = <Breadcrumbs home={home} pages={pages} />;

  /* Site Key Dropdown */
  const siteKeyDropdown = (
    <SiteKeyDropdown
      currentSiteKey={currentSiteKey}
      allSiteKeys={sortedSiteKeyList}
      onSelection={handleSelectSiteKey}
      styles="w-full sm:w-52 lg:w-44 xl:w-52"
    />
  );

  /* Work Type Dropdown */
  const workTypeDropdown = (
    <WorkTypeDropdown
      currentSelection={
        readableWorkTypeList.includes(
          getReadableCraftType(convertedSelectedWorkType),
        )
          ? getReadableCraftType(convertedSelectedWorkType)
          : readableWorkTypeList[0]
      }
      allOptions={readableWorkTypeList}
      onSelection={handleSelectedWorkType}
      styles="w-full sm:w-52 lg:w-44 xl:w-52"
    />
  );

  const craftRecordMenu = (
    <CustomFieldsSidebarMenuButton
      fieldTitle={readableCraftTypeRecord}
      field={craftRecordsList}
      craftRecord={true}
    />
  );

  /* Sidebar Menu */
  const sidebarMenu = (
    <CustomFieldSidebarMenu>
      {{
        CraftRecordsMenu: craftRecordMenu,
        /* start tasks menu mapping */
        TasksMenu: Object.entries(tasksGroupedByTaskType).map(
          ([taskType, record], taskFieldIdx) => {
            return (
              <div key={taskFieldIdx}>
                <CustomFieldsSidebarMenuButton
                  fieldTitle={readableCraftTypeRecord}
                  field={record}
                  craftRecord={false}
                  taskType={taskType}
                />
              </div>
            );
          },
        ),
        /* finish tasks menu mapping */
      }}
    </CustomFieldSidebarMenu>
  );

  /* Dropdown Menu */
  const dropdownMenu = (
    <CustomFieldDropdownMenu>
      {{
        CraftRecordsMenu: (
          <CustomFieldsDropdownMenuButton
            fieldTitle={readableCraftTypeRecord}
            field={craftRecordsList}
            craftRecord={true}
          />
        ),
        /* start tasks menu mapping */
        TasksMenu: Object.entries(tasksGroupedByTaskType).map(
          ([taskType, record], taskFieldIdx) => {
            return (
              <div key={taskFieldIdx}>
                <CustomFieldsDropdownMenuButton
                  fieldTitle={readableCraftTypeRecord}
                  field={record}
                  craftRecord={false}
                  taskType={taskType}
                />
              </div>
            );
          },
        ),
        /* finish tasks menu mapping */
      }}
    </CustomFieldDropdownMenu>
  );

  /* Add Custom Field Dialog */
  const addCustomFieldDialog = (
    <AddCustomFieldDialog
      isDialogOpen={isAddDialogOpen}
      closeDialog={() => setIsAddDialogOpen(false)}
      workType={dialogWorkType}
      handleSave={handleSave}
      taskStatusList={validTaskStatusList}
    />
  );

  const workTypeIcon = (
    <div className="mr-4 sm:ml-3 sm:mr-8">
      {convertedSelectedWorkType && (
        <WorkTypeIcon craftType={convertedSelectedWorkType} />
      )}
    </div>
  );

  /* Custom fields cards section */
  const customFieldsCardsSection =
    craftRecordsList.length !== 0 ? (
      <CustomFieldCardsSection
        sectionTitle={readableCraftTypeRecord}
        craftRecord={true}
        openAddCustomFieldDialog={() =>
          openAddCustomFieldDialog(convertedSelectedWorkType)
        }
      >
        {{
          /* start cards mapping */
          CardsMapping: Object.entries(craftRecordsList).map(
            ([_key, field], fieldIdx) => {
              return (
                <div key={fieldIdx} className="contents">
                  <CustomFieldCard
                    field={field}
                    handleDeleteCustomField={handleDeleteCustomField}
                  />
                </div>
              );
            },
          ),
          /* finish cards mapping */
        }}
      </CustomFieldCardsSection>
    ) : (
      <CustomFieldCardsSection
        sectionTitle={readableCraftTypeRecord}
        craftRecord={true}
        openAddCustomFieldDialog={() =>
          openAddCustomFieldDialog(convertedSelectedWorkType)
        }
      >
        {{
          CardsMapping: (
            <div className="col-span-2 -mt-6 text-lg font-bold text-gray-400">
              There are no custom fields for this work type
            </div>
          ),
        }}
      </CustomFieldCardsSection>
    );

  /* Task type cards section */
  const taskTypeCardsSection =
    validTaskTypeList == null ? (
      <div className="mt-10 text-lg font-bold sm:text-3xl">
        There are no task types available for{" "}
        <span className="capitalize">{readableCraftTypeRecord}</span>
      </div>
    ) : (
      validTaskTypeList.map((taskType, taskTypeIdx) => {
        const taskFieldsForTaskType = tasksGroupedByTaskType[taskType];
        if (taskFieldsForTaskType == null) {
          return (
            <div key={taskTypeIdx}>
              <CustomFieldCardsSection
                sectionTitle={readableCraftTypeRecord}
                craftRecord={false}
                taskType={getReadableTaskType(taskType)}
                openAddCustomFieldDialog={() =>
                  openAddCustomFieldDialog(convertedSelectedWorkType)
                }
              >
                {{
                  CardsMapping: (
                    <div className="col-span-2 -mt-6 text-lg font-bold text-gray-400">
                      There are no custom fields for this task type
                    </div>
                  ),
                }}
              </CustomFieldCardsSection>
            </div>
          );
        } else {
          return (
            <div key={taskTypeIdx}>
              <CustomFieldCardsSection
                sectionTitle={readableCraftTypeRecord}
                craftRecord={false}
                taskType={getReadableTaskType(taskType)}
                openAddCustomFieldDialog={() =>
                  openAddCustomFieldDialog(convertedSelectedWorkType)
                }
              >
                {{
                  /* start cards mapping */
                  CardsMapping: Object.entries(taskFieldsForTaskType).map(
                    ([_key, field], fieldIdx) => {
                      return (
                        <div key={fieldIdx} className="contents">
                          <CustomFieldCard
                            field={field}
                            handleDeleteCustomField={handleDeleteCustomField}
                          />
                        </div>
                      );
                    },
                  ),
                  /* finish cards mapping */
                }}
              </CustomFieldCardsSection>
            </div>
          );
        }
      })
    );

  //RENDER THE COMPONENT
  return (
    <CustomFieldsPage>
      {{
        Breadcrumbs: breadcrumbs,
        SiteKeyDropdown: siteKeyDropdown,
        WorkTypeDropdown: workTypeDropdown,
        SidebarMenu: sidebarMenu,
        DropdownMenu: dropdownMenu,
        CustomFieldsCardsSection: customFieldsCardsSection,
        AddCustomFieldDialog: addCustomFieldDialog,
        TaskTypeCardsSection: taskTypeCardsSection,
        WorkTypeIcon: workTypeIcon,
      }}
    </CustomFieldsPage>
  );
}

/**
 * Convert customization map to ExistingCustomField list.
 */
export function getCustomFieldsForSite(
  siteKeyID: string,
  siteKeyList: ExistingSiteKey[],
): ExistingCustomField[] {
  devLogger.debug("Running getCustomFields");

  // Retrieve the desired site
  const siteKeyDoc = siteKeyList.find((siteKey) => siteKey.id === siteKeyID);

  // Make sure the desired site is not undefined.
  if (siteKeyDoc == null) {
    devLogger.debug(
      `getCustomFields: data not yet loaded for site: ${siteKeyID}`,
    );
    return [];
  }
  const craftDetails = siteKeyDoc.customizations?.craftDetails;
  const taskSpecificDetails = siteKeyDoc.customizations?.taskSpecificDetails;

  // We want to convert the json/DynamicDetail to the CustomField format for a standard
  // interface with components.
  let craftCustomFields: ExistingCustomField[] = [];
  if (guardIsPlainObject(craftDetails)) {
    craftCustomFields = Object.entries(craftDetails).flatMap(
      ([craftTypeString, dynamicDetailMap]) => {
        if (guardIsPlainObject(dynamicDetailMap)) {
          return Object.entries(dynamicDetailMap).flatMap(
            ([dynamicDetailID, ddData]) => {
              try {
                return CustomFieldManager.fromSiteCustomization({
                  fieldID: dynamicDetailID,
                  craftRecordString: craftTypeString,
                  fieldData: ddData,
                });
              } catch (e) {
                devLogger.error(
                  `Expected ${siteKeyID}.customizations.${craftTypeString}.${dynamicDetailID} to parse, but got an error:`,
                  e,
                );
                return [];
              }
            },
          );
        } else {
          devLogger.warn(
            `Expected an object for the dynamicDetailMap for ${craftTypeString}, got: ${typeof dynamicDetailMap}`,
          );
          return [];
        }
      },
    );
  } else {
    devLogger.warn(
      `Expected craftDetails to be an object, got ${typeof craftDetails}`,
    );
  }

  let taskCustomFields: ExistingCustomField[] = [];
  if (guardIsPlainObject(taskSpecificDetails)) {
    taskCustomFields = Object.entries(taskSpecificDetails).flatMap(
      ([craftTypeString, taskTypeMap]) => {
        if (guardIsPlainObject(taskTypeMap)) {
          return Object.entries(taskTypeMap).flatMap(
            ([taskTypeString, dynamicDetailMap]) => {
              if (guardIsPlainObject(dynamicDetailMap)) {
                return Object.entries(dynamicDetailMap).flatMap(
                  ([dynamicDetailID, ddData]) => {
                    try {
                      return CustomFieldManager.fromSiteCustomization({
                        fieldID: dynamicDetailID,
                        taskTypeString: taskTypeString,
                        craftRecordString: craftTypeString,
                        fieldData: ddData,
                      });
                    } catch (e) {
                      devLogger.error(
                        `Expected ${siteKeyID}.customizations.${craftTypeString}.${taskTypeString}.${dynamicDetailID} to parse, but got an error:`,
                        e,
                      );
                      return [];
                    }
                  },
                );
              } else {
                devLogger.warn(
                  `Expected an object for the dynamicDetailMap for ${craftTypeString}.${taskTypeString}, got: ${typeof dynamicDetailMap}`,
                );
                return [];
              }
            },
          );
        } else {
          devLogger.warn(
            `Expected an object for the taskTypeMap for ${craftTypeString}, got: ${typeof taskTypeMap}`,
          );
          return [];
        }
      },
    );
  } else {
    devLogger.warn(
      `Expected taskSpecificDetails to be an object, got ${typeof taskSpecificDetails}`,
    );
  }

  return [...craftCustomFields, ...taskCustomFields];
}
