//Libs

//Local
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import { ExistingAsset } from "../../models/asset";

interface Props {
  equipmentDoc: ExistingAsset;
  setSelectedAsset: React.Dispatch<React.SetStateAction<ExistingAsset | null>>;
  setAssetDetailsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EquipmentPill({
  equipmentDoc,
  setAssetDetailsDialogOpen,
  setSelectedAsset,
}: Props) {
  let topText = equipmentDoc.title;

  const installationDate = convertToReadableTimestampDate(
    equipmentDoc.installationDate,
  );

  if (equipmentDoc.equipmentType && equipmentDoc.equipmentType != "") {
    topText += " - " + equipmentDoc.equipmentType;
  }

  return (
    <button
      onClick={() => {
        setSelectedAsset(equipmentDoc);
        setAssetDetailsDialogOpen(true);
      }}
      className={`flex max-w-fit flex-col items-center justify-center rounded-lg bg-gray-200 px-2.5 py-1 text-xs capitalize text-gray-900 hover:bg-lightGrayBgHover`}
    >
      {topText}
      <span className="block normal-case italic">
        {`Installed on ${installationDate}`}
      </span>
    </button>
  );
}
