//Libs
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SubmitHandler,
  useForm,
  Controller,
  UseFormHandleSubmit,
  UseFormReset,
  FieldErrors,
  Control,
} from "react-hook-form";
import { z } from "zod";
import { PlusCircleIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

//Local
import * as strings from "../strings";
import BaseInputText from "../components/BaseInputText";
import StyledMessage from "../components/StyledMessage";
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import BaseButtonSecondary from "../components/BaseButtonSecondary";

interface Props {
  handleSave: (formValues: ApplyToExistingSiteFormState) => Promise<void>;
  goBack: () => void;
  isVerifiedEmail: boolean | undefined;
  isSuccessfullyApplied: boolean | null;
  children: {
    DisplayResponseMessage: React.ReactNode;
    InfoBox: React.ReactNode;
  };
}

//Schema
export const ApplyToExistingSiteFormSchema = z.object({
  siteKey: z.string().min(1, { message: strings.REQUIRED_FIELD }).max(200),
});

//Inferred type
export type ApplyToExistingSiteFormState = z.infer<
  typeof ApplyToExistingSiteFormSchema
>;

export default function ApplyToExistingSitePage(props: Props) {
  //react-hook-form
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ApplyToExistingSiteFormState>({
    defaultValues: { siteKey: "" },
    resolver: zodResolver(ApplyToExistingSiteFormSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<ApplyToExistingSiteFormState> = async (
    formValues: ApplyToExistingSiteFormState,
  ) => {
    //container will call DB
    await props.handleSave(formValues);
    reset();
  };

  return (
    <div className="mx-auto w-full px-2 lg:max-w-screen-lg lg:px-0">
      <div className="pt-8 text-5xl font-semibold text-primary md:text-left">
        {strings.APPLY_TO_EXISTING_SITE}
      </div>

      <div className="flex w-full flex-col space-y-12 pt-12">
        {props.children.DisplayResponseMessage}
        <div className="text-xl font-medium">
          {strings.ENTER_SITE_KEY_FROM_ADMIN}
        </div>

        <TheForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          control={control}
          reset={reset}
          isSubmitting={isSubmitting}
          goBack={props.goBack}
          isSuccessfullyApplied={props.isSuccessfullyApplied}
          isVerifiedEmail={props.isVerifiedEmail}
        />

        {props.children.InfoBox}
      </div>
    </div>
  );
}

// #region SECTION: The Form
interface FormProps {
  handleSubmit: UseFormHandleSubmit<ApplyToExistingSiteFormState>;
  onSubmit: SubmitHandler<ApplyToExistingSiteFormState>;
  errors: FieldErrors;
  control: Control<ApplyToExistingSiteFormState, any>;
  reset: UseFormReset<ApplyToExistingSiteFormState>;
  isSubmitting: boolean;
  goBack: () => void;
  isSuccessfullyApplied: boolean | null;
  isVerifiedEmail: boolean | undefined;
}

// Extracted 'TheForm' because: https://stackoverflow.com/a/67002581
const TheForm = (props: FormProps) => {
  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit(props.onSubmit)}
      className="w-full space-y-12"
    >
      {/* SiteKey */}
      <div>
        <Controller
          name="siteKey"
          control={props.control}
          render={({ field }) => (
            <BaseInputText
              required
              inputName="siteKey"
              text="Site Key"
              admin={true}
              {...field}
            />
          )}
        />
        {props.errors.siteKey?.message &&
          typeof props.errors.siteKey.message === "string" && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.siteKey?.message }}
              </StyledMessage>
            </div>
          )}
      </div>

      <div className="flex w-full flex-col items-center justify-between space-y-8 sm:flex-row sm:space-y-0">
        <BaseButtonSecondary
          className="w-full items-center space-x-2 xs:w-52"
          type="button"
          onClick={props.goBack}
        >
          <ChevronLeftIcon aria-label="go back" className="h-5 w-5" />
          <span>{strings.buttons.BACK}</span>
        </BaseButtonSecondary>
        {props.isSuccessfullyApplied === true ? (
          <BaseButtonPrimary
            className="w-full items-center space-x-2 bg-greenPass hover:bg-greenPass xs:w-52"
            type="button"
            formNoValidate
            disabled={true}
          >
            <CheckCircleIcon aria-label="success" className="h-5 w-5" />
            <span>{strings.buttons.SUCCESS}</span>
          </BaseButtonPrimary>
        ) : (
          <BaseButtonPrimary
            className="w-full items-center space-x-2 xs:w-52"
            type="submit"
            formNoValidate
            disabled={props.isSubmitting || !props.isVerifiedEmail}
            isBusy={props.isSubmitting}
            busyText={strings.buttons.BUSY_APPLYING}
          >
            <PlusCircleIcon aria-label="apply to site" className="h-5 w-5" />
            <span>{strings.buttons.APPLY}</span>
          </BaseButtonPrimary>
        )}
      </div>
    </form>
  );
};
// #endregion
