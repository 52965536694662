import { DateTime } from "luxon";

/**
 * Calculates the value of the scheduledServiceWindowEnd property, used in
 * taskSpecificDetails.
 *
 * @param startTimeISO scheduledServiceWindowStart
 * @param durationInHours Number of hours to add to startTimeISO
 *
 * @returns The adjusted ISO datetime string
 *
 * Tested ✅
 */
export default function getScheduledServiceWindowEnd(
  startTimeISO: string,
  durationInHours: number,
): string {
  // Convert to Luxon because they have nice methods
  const dt = DateTime.fromISO(startTimeISO);
  if (!dt.isValid) {
    throw new Error(`Luxon DT object is not valid. Received: ${startTimeISO}`);
  }

  // Add the given hours to the Luxon DT object.
  const endTime = dt.plus({ hours: durationInHours });

  // Luxon DT -> ISO string
  return endTime.toUTC().toISO();
}
