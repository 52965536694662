//Libs
import { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

//Local
import { BillingInfo } from "../../models/customer";
import { ErrorMessage } from "../ErrorMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";
import { logger as devLogger } from "../../logging";

const BillingAddressManuallyFormSchema = z.object({
  addressLine1: z.string().min(1, { message: "Address is required" }).max(200),
  addressLine2: z.string().max(200).optional(),
  name: z.string().max(1000).optional(),
  email: z.string().max(1000).optional(),
  phone: z.string().max(1000).optional(),
  city: z.string().max(1000).optional(),
  state: z.string().max(1000).optional(),
  zipCode: z.string().max(1000).optional(),
});

interface Props {
  /* DATA */
  billingInfo: BillingInfo;
  isSubmitting: boolean;
  /* FUNCTIONS */
  handleUpdateBillingInfo: (billingInfo: BillingInfo) => void;
  setEditBillingInfoFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EditBillingInfoManuallyForm(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const defaultValues: BillingInfo = useMemo(() => {
    return {
      addressLine1: props.billingInfo.addressLine1 ?? "",
      addressLine2: props.billingInfo.addressLine2 ?? "",
      name: props.billingInfo.name ?? "",
      phone: props.billingInfo.phone ?? "",
      email: props.billingInfo.email ?? "",
      city: props.billingInfo.city ?? "",
      zipCode: props.billingInfo.zipCode ?? "",
      state: props.billingInfo.state ?? "",
    };
  }, []);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<BillingInfo>({
    defaultValues: defaultValues,
    resolver: zodResolver(BillingAddressManuallyFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the section is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    props.setEditBillingInfoFormOpen(false);
  }

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  const onSubmit: SubmitHandler<BillingInfo> = async (formValues) => {
    const billingInfo: BillingInfo = {
      addressLine1: formValues.addressLine1,
      addressLine2: formValues.addressLine2,
      city: formValues.city,
      zipCode: formValues.zipCode,
      state: formValues.state,
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
    };
    try {
      props.handleUpdateBillingInfo(billingInfo);
      // Close the section if successful.
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      className="space-y-8"
    >
      <div>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Name"
              inputName="name"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.name?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.name.message }}
            </StyledMessage>
          </div>
        )}
      </div>
      <div>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Phone"
              inputName="phone"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.phone?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.phone.message }}
            </StyledMessage>
          </div>
        )}
      </div>
      <div>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Email"
              inputName="email"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.email?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.email.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Address */}
      <div>
        <Controller
          name="addressLine1"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Address"
              inputName="addressLine1"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.addressLine2?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.addressLine2.message }}
            </StyledMessage>
          </div>
        )}
      </div>
      {/* Field: Address (cont.) */}
      <div>
        <Controller
          name="addressLine2"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Address (cont.)"
              inputName="addressLine2"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.addressLine2?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.addressLine2.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: City */}
      <div>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="City"
              inputName="city"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.city?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.city.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: Zip Code */}
      <div>
        <Controller
          name="zipCode"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Zip Code"
              inputName="zipCode"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.zipCode?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.zipCode.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      {/* Field: State */}
      <div>
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="State"
              inputName="state"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.state?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.state.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      <div className="mt-4">{displayError ? errorMessage : null}</div>

      {/* Action Buttons */}
      <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={closeAndReset}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          disabled={props.isSubmitting}
          isBusy={props.isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
          data-testid="save info"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
    </form>
  );
}
