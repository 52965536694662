//Libs
import { ChangeEvent, useEffect, useState } from "react";
import { CameraIcon } from "@heroicons/react/24/solid";

//Local
import BaseButtonSecondary from "./BaseButtonSecondary";
import BaseButtonPrimary from "./BaseButtonPrimary";
import ResponseThumbnailGallery from "./ResponseThumbnailGallery";
import { ExistingChecklistResponse } from "../models/checklist-response";
import BaseModal from "./BaseModal";
import * as strings from "../strings";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  thumbnailPhotosWithDocID: Array<{ thumbnail: string; docID: string }>;
  itemMainText: ExistingChecklistResponse["mainText"];
  /** uploadIDs are the checklistPhoto model required IDs. (checklistResponseID, taskID, craftRecordID) */
  uploadIDs: Record<string, string | undefined>;
  onImgUpload: (
    file: File,
    ids: Record<string, string | undefined>,
    changeFileInformation: (value: string) => void,
    changeIsUploading: (value: boolean) => void,
  ) => void;
  openReducedGallery: (clickedIndex: number) => void;
  onDeletePhoto: (docID: string) => Promise<void>;
}

export default function ResponseCardPhotoDialog(props: Props) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(null);
      return;
    }

    const objectURL = URL.createObjectURL(selectedFile);
    setPreview(objectURL);

    // Free memory when component is unmounted
    return () => URL.revokeObjectURL(objectURL);
  }, [selectedFile]);

  // Makes text bigger & red, in the case of the user clicking submit without selecting
  // a file, or if the file they select surpasses the size limit.
  const [responseToUserError, setResponseToUserError] = useState(false);

  // For setting the UI text
  const [fileInformation, setFileInformation] = useState(
    strings.UPLOAD_NO_FILE_CHOSEN,
  );

  // For setting 'isBusy' on the submit button
  const [isUploading, setIsUploading] = useState(false);

  function handleFileSelected(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length === 1) {
      const chosenFile = event.target.files[0];

      // If file is larger than 10MB
      if (chosenFile.size >= 10000000) {
        setResponseToUserError(true);
        setFileInformation(strings.UPLOAD_UNDER_10MB);
        return;
      }

      // TODO: revisit this once we conquer the heic upload.
      // Added this check because uploads via iPhone don't prevent the user from selecting an heic image.
      // Accepted file types: png, jpeg, jpg
      if (
        chosenFile.type !== "image/png" &&
        chosenFile.type !== "image/jpeg" &&
        chosenFile.type !== "image/jpg"
      ) {
        setResponseToUserError(true);
        setFileInformation(strings.UPLOAD_ACCEPTED_FILETYPES);
        return;
      }

      // Set file - ready to be sent to submit handler ƒn
      setSelectedFile(chosenFile);

      // UI will display the chosen filename
      setFileInformation(chosenFile.name);

      // Reset the css styles
      if (responseToUserError) setResponseToUserError(false);
    }
  }

  // It lightweight creeps me out that this isn't async.
  function handleImgUpload() {
    if (selectedFile !== null) {
      setIsUploading(true);
      props.onImgUpload(
        selectedFile,
        props.uploadIDs,
        setFileInformation,
        setIsUploading,
      );

      // Reset preview & file state.
      setPreview(null);
      setSelectedFile(null);
    } else {
      setFileInformation(strings.UPLOAD_NO_FILE_CHOSEN);
      setResponseToUserError(true);
    }
  }

  function onCloseDialog() {
    props.closeDialog();
    setSelectedFile(null);
    setResponseToUserError(false);
    setFileInformation(strings.UPLOAD_NO_FILE_CHOSEN);
  }

  return (
    <BaseModal
      open={props.isDialogOpen}
      title={
        <div className="relative rounded-t-lg bg-primary px-4 py-2 text-xl font-light text-white">
          {strings.UPLOAD_ADD_PHOTOS}
        </div>
      }
      closeModal={onCloseDialog}
      parentDivStyles="text-left max-w-sm sm:w-96"
    >
      <div className="flex flex-col justify-between px-4 pb-4 text-base">
        <p className="mb-1 mt-2 text-gray-900">Checklist Item:</p>
        <p className="text-gray-500">{props.itemMainText}</p>

        <div className="my-3 flex flex-col items-center">
          <label
            htmlFor="img-upload"
            className="inline-block w-52 cursor-pointer rounded border-4 border-dotted border-transparent bg-white p-4 text-center text-sm text-gray-500 transition hover:border-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
          >
            <CameraIcon
              aria-label={strings.UPLOAD_TAP_TO_ADD_PHOTO}
              className="m-auto flex w-9 text-gray-900"
            />
            {strings.UPLOAD_TAP_TO_ADD_PHOTO}
          </label>
          <input
            id="img-upload"
            type="file"
            onChange={(ev) => handleFileSelected(ev)}
            accept="image/jpg, image/jpeg, image/png"
            className="hidden"
          />
          <span
            className={`mt-3 w-56 rounded px-3 py-1.5 text-center text-sm text-gray-700 sm:w-80 ${
              responseToUserError ? "bg-red-50" : ""
            }`}
          >
            <span
              className={
                responseToUserError ? "text-base font-medium text-red-800" : ""
              }
            >
              {fileInformation}
            </span>
          </span>
          {/* If preview & selectedFile are not null, display image */}
          {selectedFile && preview && (
            <img src={preview} alt="" className="mt-3 w-48 rounded sm:w-64" />
          )}
        </div>

        {/* Photos */}
        <div className="grid max-h-48 grid-cols-2 overflow-auto">
          <ResponseThumbnailGallery
            thumbnailPhotosWithDocID={props.thumbnailPhotosWithDocID}
            onThumbnailClick={(i) => {
              props.openReducedGallery(i);
              onCloseDialog();
            }}
            onDeletePhoto={props.onDeletePhoto}
          />
        </div>

        {/* buttons */}
        <div className="mt-4 flex w-full items-center justify-between gap-4">
          <BaseButtonSecondary
            className="w-full justify-center uppercase"
            onClick={onCloseDialog}
          >
            {strings.buttons.CLOSE}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            className="w-full justify-center uppercase"
            onClick={handleImgUpload}
            isBusy={isUploading}
            busyText={strings.buttons.BUSY_UPLOADING}
          >
            {strings.buttons.SUBMIT}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
