// Libs
import { DocumentData } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";

// Local
import { isValidCraftType } from "../../models/craft-types";
import { ExistingCustomField } from "../../models/custom-field";
import { ExistingEvent } from "../../models/event";
import { ExistingSiteKey } from "../../models/site-key";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { ExistingTask } from "../../models/task";
import { isValidTaskType } from "../../models/task-types";
import * as strings from "../../strings";
import PillCount from "../PillCount";
import ChangeTaskStatusButton from "./ChangeTaskStatusButton";
import DetailsAndEventsPanels from "./DetailsAndEventsPanels";
import TaskCardOpen from "./TaskCardOpen";
import { getTaskCustomFieldList } from "./functions";
import TaskStatusChangeDialog from "./TaskStatusChangeDialog";
import { TaskStatus } from "../../models/task-status";
import { IMultipleUid_AssignUser } from "../CustomFields/MultipleUidDialog";
import TaskCardClosed from "./TaskCardClosed";
import { ExistingEstimate } from "../../models/estimate";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { ExistingCustomerContact } from "../../models/customer-contact";
import { ExistingVehicle } from "../../models/vehicle";
import { ExistingStiltInvoice } from "../../models/invoice";

interface Props {
  // DATA
  tasks: ExistingTask[];
  siteKey: ExistingSiteKey;
  userDisplayNamesMap: Record<string, string>;
  companies: ExistingSiteKeyCompany[];
  events: ExistingEvent[];
  userPermissions: ExistingSiteKeyUserPermissions;
  uid: string;
  workRecordTitle: string;
  estimates: ExistingEstimate[];
  taskLocation: ExistingCustomerLocation;
  emailList: string[];
  customerContacts: ExistingCustomerContact[] | null;
  vehicleList: ExistingVehicle[];
  invoices: ExistingStiltInvoice[];
  // FUNCTIONS
  handleDeleteTask: (id: ExistingTask["id"]) => Promise<void>;
  handleOpenRescheduleTaskDialog: (id: ExistingTask["id"]) => Promise<void>;
  handleOpenEditTaskDialog: (id: ExistingTask["id"]) => void;
  handleUpdateTask: (updateData: DocumentData, taskID: string) => Promise<void>;
  handleUpdateTSD: (updateData: DocumentData, refPath: string) => Promise<void>;
  handleCreateNewEstimate: (taskDoc: ExistingTask) => void;
  handleOpenEstimateListDialog: (taskID: ExistingTask["id"]) => void;
  createFollowUpTask: (
    task: ExistingTask,
    followUpTaskStatus: TaskStatus | null,
  ) => Promise<void>;
  // COMPONENTS
  children: {
    createTaskBtn: React.ReactNode;
    workStatusPanel: React.ReactNode;
  };
}

export default function TasksPanel(props: Props): JSX.Element {
  const openTasks = props.tasks.filter((task) => task.taskStatus < 90);
  const closedTasks = props.tasks.filter((task) => task.taskStatus >= 90);
  const countOpenTasks = openTasks.length;
  const countClosedTasks = closedTasks.length;

  // Shown when the task status is changing to a status that is included in the
  // onTaskStatus property of one or more custom fields. (Requires user input)
  // Also shown when task status is being changed to "complete" and the craftRecordPersistence
  // property is set to "prompt".
  const [openTaskStatusChangeDialog, setOpenTaskStatusChangeDialog] = useState<
    string | null
  >(null);
  const [showCraftPersistence, setShowCraftPersistence] = useState(false);
  const [nextTaskStatus, setNextTaskStatus] = useState<TaskStatus | null>(null);

  const [siteKeyUsersList] = useSiteKeyUsersStore((state) => [
    state.siteKeyUsersList,
  ]);

  const userList = useRef<IMultipleUid_AssignUser[]>([]);
  useEffect(() => {
    async function getUserList() {
      userList.current = siteKeyUsersList.map((user) => ({
        uid: user.id,
        name: user.displayName,
        company: user.companyName,
        avatarUrl: user.userPhoto_URL,
        isAssigned: false,
        assignedVehicle: null,
      }));
    }

    getUserList();
  }, [props.siteKey, siteKeyUsersList]);

  function existingEstimatesForTask(taskID: ExistingTask["id"]): boolean {
    const result = props.estimates.filter(
      (estimate) => estimate.taskID === taskID,
    );
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="w-full">
      <div className="mb-2 grid w-full grid-cols-2 gap-4">
        {props.children.workStatusPanel}
      </div>
      <div className="mt-8 flex items-center justify-between">
        <h2 className="text-2xl">{strings.TASKS}</h2>
        {props.children.createTaskBtn}
      </div>
      <hr className="my-2 block w-full border border-gray-100" />
      {/* SECTION: OPEN TASKS */}
      <PillCount
        text="Open"
        count={countOpenTasks}
        color="orange"
        margin="ml-auto mr-1 my-1"
      />
      <hr className="mb-6 mt-2 block w-full border border-gray-100" />
      {countOpenTasks < 1 ? (
        <p className="text-sm text-gray-500">{strings.NO_OPEN_TASKS}</p>
      ) : (
        openTasks.map((task) => {
          // Get the siteKey's customizations for this task
          const targetWorkType = task.craftType;
          const targetTaskType = task.taskType;
          let taskCustomFields: ExistingCustomField[] = [];
          if (
            isValidCraftType(targetWorkType) &&
            isValidTaskType(targetTaskType)
          ) {
            taskCustomFields = getTaskCustomFieldList({
              siteKey: props.siteKey,
              targetWorkType,
              targetTaskType,
            });
          } else {
            taskCustomFields = [];
          }

          // Get the company name to display on the UI
          // const companyName = props.companies.find(
          //   (company) => company.id === task.assignedCompanyID,
          // )?.name;

          const primaryContactPhone = props.customerContacts?.find(
            (contact) =>
              contact.type !== "email" &&
              contact.id === task.primaryContactPhone,
          )?.value;

          // Find the events that pertain to the current task; sort by timestamp
          const eventList = props.events
            .filter((event) => event.taskID === task.id)
            .sort(
              (a, b) =>
                b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
            );

          const invoicesForTask = props.invoices.filter(
            (invoice) =>
              invoice.taskID === task.id && invoice.status !== "canceled",
          );

          const taskStatusChangeDialog = (
            <TaskStatusChangeDialog
              // DIALOG BASICS
              open={openTaskStatusChangeDialog === task.id}
              onClose={() => {
                setOpenTaskStatusChangeDialog(null);
                setShowCraftPersistence(false);
                setNextTaskStatus(null);
              }}
              // DATA
              showCraftPersistence={showCraftPersistence}
              workRecordTitle={props.workRecordTitle}
              task={task}
              originalTask={task}
              isReschedulingTask={false}
              siteKeyCustomFields={taskCustomFields}
              nextTaskStatus={nextTaskStatus}
              userList={userList.current}
              userDisplayNamesMap={props.userDisplayNamesMap}
              uid={props.uid}
              handleUpdateTask={props.handleUpdateTask}
              handleUpdateTSD={props.handleUpdateTSD}
              customerLocation={props.taskLocation}
              emailList={props.emailList}
              createFollowUpTask={props.createFollowUpTask}
              invoices={invoicesForTask}
            />
          );

          return (
            <div key={task.id} className="mt-3">
              <TaskCardOpen
                task={task}
                existingEstimatesForTask={existingEstimatesForTask(task.id)}
                primaryContactPhone={primaryContactPhone}
                handleDeleteTask={props.handleDeleteTask}
                handleOpenRTD={props.handleOpenRescheduleTaskDialog}
                handleOpenEditTaskDialog={props.handleOpenEditTaskDialog}
                handleCreateNewEstimate={props.handleCreateNewEstimate}
                handleOpenEstimateListDialog={
                  props.handleOpenEstimateListDialog
                }
              >
                {{
                  detailsAndEventsPanels: (
                    <DetailsAndEventsPanels
                      details={task.taskSpecificDetails}
                      siteKeyCustomFields={taskCustomFields}
                      userDisplayNamesMap={props.userDisplayNamesMap}
                      events={eventList}
                      vehicleList={props.vehicleList}
                    />
                  ),
                  changeTaskStatusButton: (
                    <ChangeTaskStatusButton
                      task={task}
                      userPermissions={props.userPermissions}
                      siteKeyDoc={props.siteKey}
                      uid={props.uid}
                      handleUpdateTask={props.handleUpdateTask}
                      setOpenTaskStatusChangeDialog={
                        setOpenTaskStatusChangeDialog
                      }
                      setShowCraftPersistence={setShowCraftPersistence}
                      setNextTaskStatus={setNextTaskStatus}
                    />
                  ),
                }}
              </TaskCardOpen>
              {taskStatusChangeDialog}
            </div>
          );
        })
      )}

      {/* SECTION: CLOSED TASKS */}
      <PillCount
        text="Closed"
        count={countClosedTasks}
        color="green"
        margin="ml-auto mr-1 mt-12"
      />
      <hr className="mb-6 mt-2 block w-full border border-gray-100" />
      {countClosedTasks < 1 ? (
        <p className="text-sm text-gray-500">{strings.NO_CLOSED_TASKS}</p>
      ) : (
        closedTasks.map((task) => {
          // Get the siteKey's customizations for this task
          const targetWorkType = task.craftType;
          const targetTaskType = task.taskType;
          let taskCustomFields: ExistingCustomField[] = [];
          if (
            isValidCraftType(targetWorkType) &&
            isValidTaskType(targetTaskType)
          ) {
            taskCustomFields = getTaskCustomFieldList({
              siteKey: props.siteKey,
              targetWorkType,
              targetTaskType,
            });
          } else {
            taskCustomFields = [];
          }

          // Get the company name to display on the UI
          // const companyName = props.companies.find(
          //   (company) => company.id === task.assignedCompanyID,
          // )?.name;

          // Find the events that pertain to the current task; sort by timestamp
          const eventList = props.events
            .filter((event) => event.taskID === task.id)
            .sort(
              (a, b) =>
                b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
            );

          const invoicesForTask = props.invoices.filter(
            (invoice) =>
              invoice.taskID === task.id && invoice.status !== "canceled",
          );

          const taskStatusChangeDialog = (
            <TaskStatusChangeDialog
              // DIALOG BASICS
              open={openTaskStatusChangeDialog === task.id}
              onClose={() => {
                setOpenTaskStatusChangeDialog(null);
                setShowCraftPersistence(false);
                setNextTaskStatus(null);
              }}
              // DATA
              showCraftPersistence={showCraftPersistence}
              workRecordTitle={props.workRecordTitle}
              task={task}
              originalTask={task}
              isReschedulingTask={false}
              siteKeyCustomFields={taskCustomFields}
              nextTaskStatus={nextTaskStatus}
              userList={userList.current}
              userDisplayNamesMap={props.userDisplayNamesMap}
              uid={props.uid}
              handleUpdateTask={props.handleUpdateTask}
              handleUpdateTSD={props.handleUpdateTSD}
              customerLocation={props.taskLocation}
              emailList={props.emailList}
              createFollowUpTask={props.createFollowUpTask}
              invoices={invoicesForTask}
            />
          );

          return (
            <div key={task.id} className="mt-3">
              <TaskCardClosed
                task={task}
                existingEstimatesForTask={existingEstimatesForTask(task.id)}
                handleDeleteTask={props.handleDeleteTask}
                handleOpenRTD={props.handleOpenRescheduleTaskDialog}
                handleOpenEditTaskDialog={props.handleOpenEditTaskDialog}
                handleCreateNewEstimate={props.handleCreateNewEstimate}
                handleOpenEstimateListDialog={
                  props.handleOpenEstimateListDialog
                }
              >
                {{
                  detailsAndEventsPanels: (
                    <DetailsAndEventsPanels
                      details={task.taskSpecificDetails}
                      siteKeyCustomFields={taskCustomFields}
                      userDisplayNamesMap={props.userDisplayNamesMap}
                      events={eventList}
                      vehicleList={props.vehicleList}
                    />
                  ),
                  changeTaskStatusButton: (
                    <ChangeTaskStatusButton
                      task={task}
                      userPermissions={props.userPermissions}
                      siteKeyDoc={props.siteKey}
                      uid={props.uid}
                      handleUpdateTask={props.handleUpdateTask}
                      setOpenTaskStatusChangeDialog={
                        setOpenTaskStatusChangeDialog
                      }
                      setShowCraftPersistence={setShowCraftPersistence}
                      setNextTaskStatus={setNextTaskStatus}
                    />
                  ),
                }}
              </TaskCardClosed>
              {taskStatusChangeDialog}
            </div>
          );
        })
      )}
    </div>
  );
}
