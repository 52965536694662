import { useAuthStore } from "../store/firebase-auth";
import { DbWrite } from "../database";
import { DocumentData } from "firebase/firestore";
import NewTemplatePage from "./NewTemplatePage";
import { useNavigate } from "react-router-dom";
import { useSiteKeyLocationsStore } from "../store/site-key-locations";
import { logger as devLogger } from "../logging";
import { TEMPLATES_URL } from "../urls";

interface Props {
  siteKey: string;
}
// Contain database and state information for the pure NewTemplatePage
// component.
export default function NewTemplateContainer({ siteKey }: Props) {
  const locationsList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );

  const navigate = useNavigate();

  function handleCancelButton() {
    navigate(TEMPLATES_URL);
  }

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  async function handleSave(
    data: DocumentData,
    craftDetailsUpdates: DocumentData,
  ) {
    try {
      const ref = await DbWrite.checklist.add(siteKey, data);
      const path = ref.path;
      if (!craftDetailsUpdates) {
        await DbWrite.craftDetails.setDefaults(path);
      } else {
        await DbWrite.craftDetails.update(path, craftDetailsUpdates);
      }
      const checklistID: string = path.substring(path.length - 20);

      // Navigate to Checklists page (home).
      navigate(`${TEMPLATES_URL}/${checklistID}`);
    } catch (err) {
      devLogger.error(err);
    }
  }

  if (firebaseUser !== null) {
    return (
      <NewTemplatePage
        uid={firebaseUser.uid}
        saveButtonCallback={handleSave}
        locationsList={locationsList}
        onCancel={handleCancelButton}
      />
    );
  } else {
    devLogger.warn("no user logged in!");
    return (
      <NewTemplatePage
        uid={null}
        saveButtonCallback={() => {}}
        locationsList={[]}
        onCancel={() => {}}
      />
    );
  }
}
