//Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useMemo, useEffect } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { z } from "zod";

//Local
import { Customer, customerTypes } from "../../models/customer";
import { formatCustomerName } from "../../assets/js/formatCustomerName";
import { logger as devLogger } from "../../logging";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputSelect from "../BaseInputSelect";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import { ExistingSiteKey } from "../../models/site-key";
import BaseInputTextArea from "../BaseInputTextArea";
import { handleTags } from "../../assets/js/HandleTags";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { phoneUtils } from "../../utils/phoneUtils";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";

interface Props {
  siteKeyCustomizations: ExistingSiteKey["customizations"];
  className: string;
  handleSave: (
    formValues: Omit<
      Customer,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    >,
  ) => Promise<void>;
  setSearchName?: React.Dispatch<React.SetStateAction<string>>;
  setAddCustomerFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addMembershipsButton: React.ReactNode;
  resetMembershipStates: () => void;
  /* CHILDREN */
  children?: {
    AddNewCustomerLocationDialog: (customerType: Customer["type"]) => void;
  };
}

export const CustomerFormSchema = z
  .object({
    name: z.string().max(200),
    firstName: z
      .string()
      .min(1, { message: "First name is required" })
      .max(200),
    lastName: z.string().min(1, { message: "Last name is required" }).max(200),
    email: z.string().email().nullable().or(z.literal("")),
    phone: z.string().max(200),
    notes: z.string().max(1000),
    type: z.enum(customerTypes),
    tags: z.string().min(0).max(2000),
    isTaxExempt: z.boolean(),
    isTaxExemptGST: z.boolean().optional(),
    isTaxExemptPST: z.boolean().optional(),
    doNotService: z.boolean().optional(),
    templateNotes: z.string().max(1000).optional(),
    defaultSendBookingConfirmationEmail: z.boolean(),
    defaultSendJobReminderEmail: z.boolean(),
  })
  .superRefine((customer, ctx) => {
    if (customer.type === "commercial" && customer.name.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        type: "string",
        inclusive: true,
        message: "Business name is required",
        path: ["name"],
      });
    }
  });

export type CustomerFormState = z.infer<typeof CustomerFormSchema>;

export default function AddNewCustomerForm({
  handleSave,
  setAddCustomerFormOpen,
  setSearchName,
  resetMembershipStates,
  siteKeyCustomizations,
  ...props
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const customerFormDefaultValues: CustomerFormState = useMemo(() => {
    return {
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notes: "",
      type: "residential",
      tags: "",
      isTaxExempt: false,
      templateNotes: "",
      defaultSendBookingConfirmationEmail: false,
      defaultSendJobReminderEmail: false,
    };
  }, []);

  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    getValues,
  } = useForm<CustomerFormState>({
    defaultValues: customerFormDefaultValues,
    resolver: zodResolver(CustomerFormSchema),
    mode: "onChange",
  });

  const watchType = useWatch({
    control: control,
    name: "type",
  });

  useEffect(() => {
    reset(customerFormDefaultValues);
  }, [customerFormDefaultValues, reset]);

  useEffect(() => {
    reset({
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notes: "",
      type: watchType,
      tags: "",
      isTaxExempt: false,
      templateNotes: "",
      defaultSendBookingConfirmationEmail: false,
      defaultSendJobReminderEmail: false,
    });
    if (setSearchName != null) {
      setSearchName("");
    }
  }, [setSearchName, reset, watchType]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    setAddCustomerFormOpen(false);
    resetMembershipStates();
    if (setSearchName != null) {
      setSearchName("");
    }
  }

  function handleSearchName() {
    const name = formatCustomerName({
      type: getValues("type"),
      name: getValues("name"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
    });
    if (setSearchName != null) {
      setSearchName(name);
    }
  }

  const onSubmit: SubmitHandler<CustomerFormState> = async (formValues) => {
    let parsed;
    if (formValues.phone !== "") {
      const { PhoneNumber, warning } = phoneUtils.parse(formValues.phone);
      if (warning === true) {
        parsed = formValues.phone;
        addToastMessage({
          id: createToastMessageID(),
          message: strings.PHONE_TOO_SHORT,
          dialog: false,
          type: "warning",
        });
      } else {
        parsed = PhoneNumber.number;
      }
    }

    const partialCustomerValue: Omit<
      Customer,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    > = {
      name: formatCustomerName({
        type: formValues.type,
        name: formValues.name,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      }),
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email:
        formValues.email === "" || formValues.email === null
          ? null
          : [formValues.email],
      phone:
        parsed === null || parsed === undefined ? null : [parsed.toString()],
      notes: formValues.notes,
      type: formValues.type,
      tags: handleTags(formValues.tags),
      customData:
        formValues.templateNotes !== "" && formValues.templateNotes != null
          ? { templateNotes: formValues.templateNotes }
          : {},
      isTaxExempt: formValues.isTaxExempt,
      doNotService: formValues.doNotService,
      notificationGroups: [],
      website: "",
      quickbooksID: "",
      deleted: false,
      customerLocations: {},
      defaultSendBookingConfirmationEmail:
        formValues.defaultSendBookingConfirmationEmail ?? false,
      defaultSendJobReminderEmail:
        formValues.defaultSendBookingConfirmationEmail ?? false,
    };

    if (
      siteKeyCustomizations.accounting?.currency === "CAD" ||
      siteKeyCustomizations.accounting?.currency === "cad"
    ) {
      partialCustomerValue.isTaxExemptGST = formValues.isTaxExemptGST ?? false;
      partialCustomerValue.isTaxExemptPST = formValues.isTaxExemptPST ?? false;
    }

    try {
      await handleSave(partialCustomerValue);
      // Close dialog if successful.
      closeAndReset();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  const errorMessage = (
    <ErrorMessage
      message={strings.ERR_CREATE_NEW_CUSTOMER}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className={props.className}
    >
      <>
        {/* Field: Type */}
        <div>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="type"
                text="Type"
                admin={true}
                required={true}
                className="capitalize"
                {...field}
              >
                {/* The empty string for value tricks validation into failing */}
                <option value="" disabled>
                  Select a type
                </option>
                {customerTypes.map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  );
                })}
              </BaseInputSelect>
            )}
          />
          {errors.type?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.type.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {watchType === "commercial" ? (
          /* Field: Name */
          <div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Business Name"
                  inputName="name"
                  admin={true}
                  required={true}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    handleSearchName();
                  }}
                />
              )}
            />
            {errors.name?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.name.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        ) : null}
        {/* Field: First Name */}
        <div>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text={
                  watchType === "commercial"
                    ? "Contact First Name"
                    : "First Name"
                }
                inputName="firstName"
                admin={true}
                required={true}
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  handleSearchName();
                }}
              />
            )}
          />
          {errors.firstName?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.firstName.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Last Name */}
        <div>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text={
                  watchType === "commercial" ? "Contact Last Name" : "Last Name"
                }
                inputName="lastName"
                admin={true}
                required={true}
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  handleSearchName();
                }}
              />
            )}
          />
          {errors.lastName?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.lastName.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Email */}
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Email"
                inputName="email"
                admin={true}
                required={false}
                {...field}
                value={field.value === null ? "" : field.value}
              />
            )}
          />
          {errors.email?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.email.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Phone */}
        <div>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Phone"
                inputName="phone"
                admin={true}
                required={false}
                {...field}
              />
            )}
          />
          {errors.phone?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.phone.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Notes */}
        <div>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Notes"
                inputName="notes"
                admin={true}
                required={false}
                {...field}
              />
            )}
          />
          {errors.notes?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.notes.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Tags */}
        <div>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Tags"
                inputName="tags"
                admin={true}
                required={false}
                placeholder="Insert your tags, separated by comma"
                {...field}
              />
            )}
          />
          {errors.tags?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.tags.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {/* Field: Template Notes */}
        {siteKeyCustomizations?.customerCustomizations?.templateNotes ===
        true ? (
          <div className="col-span-3">
            <Controller
              name="templateNotes"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Job Template Notes"
                  inputName="templateNotes"
                  admin={true}
                  required={false}
                  rows={3}
                  {...field}
                />
              )}
            />
            {errors.templateNotes?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.templateNotes.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        ) : null}
        {/* SWITCH GROUP */}
        <div className="col-span-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
          <div className="flex flex-col gap-2">
            {/* Field: Taxable */}
            {siteKeyCustomizations.accounting?.currency !== "CAD" &&
              siteKeyCustomizations.accounting?.currency !== "cad" && (
                <div>
                  <Controller
                    name="isTaxExempt"
                    control={control}
                    render={({ field }) => (
                      <StyledSwitchGroup
                        readableName={strings.TAX_EXEMPT}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        ref={field.ref}
                        checked={field.value}
                        id="isTaxExempt"
                        name={field.name}
                      />
                    )}
                  />
                </div>
              )}
            {(siteKeyCustomizations.accounting?.currency === "CAD" ||
              siteKeyCustomizations.accounting?.currency === "cad") && (
              <div>
                <Controller
                  name="isTaxExemptPST"
                  control={control}
                  render={({ field }) => (
                    <StyledSwitchGroup
                      readableName={strings.TAX_EXEMPT_PST}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      ref={field.ref}
                      checked={field.value ?? false}
                      id="isTaxExempt"
                      name={field.name}
                    />
                  )}
                />
              </div>
            )}
            {(siteKeyCustomizations.accounting?.currency === "CAD" ||
              siteKeyCustomizations.accounting?.currency === "cad") && (
              <div>
                <Controller
                  name="isTaxExemptGST"
                  control={control}
                  render={({ field }) => (
                    <StyledSwitchGroup
                      readableName={strings.TAX_EXEMPT_GST}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      ref={field.ref}
                      checked={field.value ?? false}
                      id="isTaxExempt"
                      name={field.name}
                    />
                  )}
                />
              </div>
            )}
            <div>
              <Controller
                name="doNotService"
                control={control}
                render={({ field }) => (
                  <StyledSwitchGroup
                    readableName={strings.DO_NOT_SERVICE}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    checked={field.value ?? false}
                    id="doNotService"
                    name={field.name}
                  />
                )}
              />
            </div>
          </div>
          {/* EMAIL CONFIRMATION - REMINDER */}
          <div className="flex flex-col gap-2">
            {siteKeyCustomizations.sendJobBookingConfirmationToCustomers && (
              <div>
                <Controller
                  name="defaultSendBookingConfirmationEmail"
                  control={control}
                  render={({ field }) => (
                    <StyledSwitchGroup
                      readableName={
                        strings.DEFAULT_SEND_BOOKING_CONFIRMATION_EMAIL
                      }
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      ref={field.ref}
                      checked={field.value ?? false}
                      id="defaultSendBookingConfirmationEmail"
                      name={field.name}
                    />
                  )}
                />
              </div>
            )}
            {siteKeyCustomizations.sendTaskReminderToCustomers && (
              <div>
                <Controller
                  name="defaultSendJobReminderEmail"
                  control={control}
                  render={({ field }) => (
                    <StyledSwitchGroup
                      readableName={strings.DEFAULT_SEND_24H_REMINDER_EMAIL}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      ref={field.ref}
                      checked={field.value ?? false}
                      id="defaultSendJobReminderEmail"
                      name={field.name}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
        {props.addMembershipsButton}
        {props.children
          ? props.children.AddNewCustomerLocationDialog(watchType)
          : null}
        {/* Action Buttons */}
        <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row sm:col-span-3 sm:col-start-1">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={closeAndReset}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </>
    </form>
  );
}
