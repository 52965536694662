import React from "react";
import styles from "../../assets/css/InputNumber.module.css";

type Ref = HTMLInputElement;

export type Props = {
  parentDivClasses?: string;
  text?: string;
  inputName?: string;
  required?: boolean;
} & React.ComponentPropsWithRef<"input">;

export const InputNumber = React.forwardRef<Ref, Props>(
  ({ parentDivClasses, text, inputName, ...props }, ref): JSX.Element => {
    return (
      <div className={`${parentDivClasses} relative`}>
        <input
          {...props}
          ref={ref}
          value={props.value}
          type="number"
          id={inputName}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          className={`${styles.noSpinner} peer block h-10 w-full flex-1 rounded border border-gray-400 px-4 text-gray-800 outline-none hover:border-primaryLight focus:ring-1 focus:ring-primaryLight`}
        />
        <label
          htmlFor={inputName}
          className="absolute -top-3.5 left-2 bg-white px-2 text-sm text-gray-500 peer-focus:text-gray-900"
        >
          {text}{" "}
          {props.required ? (
            <span className="text-base font-medium text-redFail">*</span>
          ) : (
            ""
          )}
        </label>
      </div>
    );
  },
);
