// Local
import { logger } from "../../../logging";
import {
  CraftTypeValues,
  getCraftTypeRecordString,
} from "../../../models/craft-types";
import {
  ExistingCustomField,
  CustomFieldManager,
} from "../../../models/custom-field";
import { ExistingSiteKey } from "../../../models/site-key";
import {
  TaskTypesValues,
  getTaskSpecificDetailsString,
} from "../../../models/task-types";
import { guardIsPlainObject } from "../../../utils";

/**
 * Given a siteKey doc, a target workType (aka craftType), and a target taskType,
 * returns those custom fields.
 *
 * Tested ✅
 */
export function getTaskCustomFieldList(args: {
  siteKey: ExistingSiteKey;
  targetWorkType: CraftTypeValues;
  targetTaskType: TaskTypesValues;
}): ExistingCustomField[] {
  const taskSpecificDetails = args.siteKey.customizations?.taskSpecificDetails;

  // convert the integer to the string that's used in the DB
  const workTypeString = getCraftTypeRecordString(args.targetWorkType);
  // convert the integer to the string that's used in the DB
  const taskTypeString = getTaskSpecificDetailsString(args.targetTaskType);

  let cfsForOneTaskType: ExistingCustomField[] = [];
  if (guardIsPlainObject(taskSpecificDetails)) {
    cfsForOneTaskType = Object.entries(taskSpecificDetails).flatMap(
      ([craftTypeString, taskTypeMap]) => {
        if (guardIsPlainObject(taskTypeMap)) {
          if (craftTypeString === workTypeString) {
            return Object.entries(taskTypeMap).flatMap(
              ([taskTypeStr, ddMap]) => {
                if (guardIsPlainObject(ddMap)) {
                  if (taskTypeStr === taskTypeString) {
                    return Object.entries(ddMap).flatMap(([ddID, ddData]) => {
                      try {
                        return CustomFieldManager.fromSiteCustomization({
                          fieldID: ddID,
                          craftRecordString: craftTypeString,
                          taskTypeString: taskTypeStr,
                          fieldData: ddData,
                        });
                      } catch (e) {
                        logger.error(
                          `Expected ${args.siteKey.id}.customizations.${craftTypeString}.${taskTypeStr}.${ddID} to parse, but got an error:`,
                          e,
                        );
                        return [];
                      }
                    });
                  } else {
                    // not target taskType
                    return [];
                  }
                } else {
                  logger.warn(
                    `Expected an object for the dynamicDetailMap for ${taskTypeStr}, got: ${typeof ddMap}`,
                  );
                  return [];
                }
              },
            );
          } else {
            // Not the target workType
            return [];
          }
        } else {
          logger.warn(
            `Expected an object for the dynamicDetailMap for ${craftTypeString}, got: ${typeof taskTypeMap}`,
          );
          return [];
        }
      },
    );
  } else {
    logger.warn(
      `Expected craftDetails to be an object, got ${typeof taskSpecificDetails}`,
    );
  }

  return cfsForOneTaskType;
}
