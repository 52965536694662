//Libs
import { useMutation } from "react-query";
import { useState, useEffect } from "react";
import { User } from "firebase/auth";

//Local
import InventoryObjectListPage from "./InventoryObjectListPage";
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import AddInventoryObjectDialog, {
  InventoryObjectState,
} from "../../components/inventory/AddInventoryObjectDialog";
import {
  ExistingInventoryObjectForTable,
  InventoryObjectManager,
  InventoryObject_CreateAPI,
  inventoryObjectStatus,
  ExistingInventoryObject,
} from "../../models/inventory-object";
import { useAuthStore } from "../../store/firebase-auth";
import {
  InventoryLocationQuantity,
  InventoryLocationQuantityManager,
} from "../../models/inventory-location-quantity";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { isValidCraftType } from "../../models/craft-types";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import { useSiteKeyDocStore } from "../../store/site-key-doc";

interface Props {
  siteKey: string;
}

export default function InventoryObjectListContainer({ siteKey }: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  const [addInventoryObjectOpen, setAddInventoryObjectOpen] =
    useState<boolean>(false);

  // inventoryLocations useState
  const [inventoryLocations, setInventoryLocations] = useState<
    ExistingInventoryLocation[]
  >([]);
  const [inventoryObjects, setInventoryObjects] = useState<
    ExistingInventoryObject[]
  >([]);

  useEffect(() => {
    const unsubscribe = DbRead.inventoryLocations.subscribeAll({
      siteKey,
      onChange: (locations) => {
        setInventoryLocations(locations);
      },
    });

    return unsubscribe;
  }, [siteKey]);

  useEffect(() => {
    const unsubscribe = DbRead.inventoryObjects.subscribeAll({
      siteKey,
      onChange: (objects) => {
        console.log(objects);
        setInventoryObjects(objects);
      },
    });

    return unsubscribe;
  }, [siteKey]);

  /* MUTATIONS */
  /* For adding inventory object to DB */
  const mutateAddInventoryObject = useMutation(
    async (args: { validInventoryObject: InventoryObject_CreateAPI }) =>
      DbWrite.inventoryObjects.create(args.validInventoryObject),
  );

  const inventoryObjectTableData: ExistingInventoryObjectForTable[] = [];
  inventoryObjects.forEach((inventoryObject) => {
    console.log(inventoryObject);
    if (Object.keys(inventoryObject.quantities).length === 0) {
      const obj = {
        id: inventoryObject.id,
        refPath: inventoryObject.refPath,
        title: inventoryObject.title,
        status: inventoryObject.status,
        inventoryLocationID: "",
        available: 0,
        reserved: 0,
        inUse: 0,
        awaitingPickup: 0,
        lowQuantityThreshold: 0,
      };
      inventoryObjectTableData.push(obj);
    } else {
      const quantityObjList: ExistingInventoryObjectForTable[] = Object.entries(
        inventoryObject.quantities,
      ).map(([key, value]) => {
        return {
          id: inventoryObject.id,
          refPath: inventoryObject.refPath,
          title: inventoryObject.title,
          status: inventoryObject.status,
          inventoryLocationID: key,
          available: value.available ?? 0,
          reserved: value.reserved ?? 0,
          inUse: value.inUse ?? 0,
          awaitingPickup: value.awaitingPickup ?? 0,
          lowQuantityThreshold: value.lowQuantityThreshold ?? 0,
        };
      });
      inventoryObjectTableData.push(...quantityObjList);
    }
  });

  /* FUNCTIONS */
  async function handleSaveNewInventoryObject(
    formValues: InventoryObjectState,
  ) {
    console.log("formValues", formValues);

    const newQuantities: Record<string, InventoryLocationQuantity> = {};
    const quantity: InventoryLocationQuantity = {
      available: formValues.available !== "" ? formValues.available : 0,
      awaitingPickup:
        formValues.awaitingPickup !== "" ? formValues.awaitingPickup : 0,
      inUse: formValues.inUse !== "" ? formValues.inUse : 0,
      lowQuantityThreshold:
        formValues.lowQuantityThreshold !== ""
          ? formValues.lowQuantityThreshold
          : 0,
      reserved: formValues.reserved !== "" ? formValues.reserved : 0,
    };

    const validatedQuantity = InventoryLocationQuantityManager.parse(quantity);

    if (formValues.inventoryLocation === "all") {
      inventoryLocations.forEach((inventoryLocation) => {
        newQuantities[inventoryLocation.id] = validatedQuantity;
      });
    } else {
      newQuantities[formValues.inventoryLocation] = validatedQuantity;
    }

    const workTypeEnums = siteKeyDoc?.validCraftTypes.filter(isValidCraftType);

    const newInventoryObject: InventoryObject_CreateAPI = {
      title: formValues.title,
      authorizedCompanies: userPermissions?.companyID
        ? [userPermissions.companyID]
        : [],
      craftTypes: workTypeEnums ?? [],
      status: inventoryObjectStatus.ACTIVE,
      quantities: newQuantities,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      deleted: false,
      siteKey: siteKey,
    };

    //Validate Inventory Object
    const validatedInventoryObject =
      InventoryObjectManager.parseCreate(newInventoryObject);

    //DB
    try {
      await mutateAddInventoryObject.mutateAsync({
        validInventoryObject: validatedInventoryObject,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(`${newInventoryObject.title}`),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(
        `An error occurred during handleSaveNewInventoryObject`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        type: "error",
        dialog: false,
      });
    }
  }

  /* COMPONENTS */
  const addInventoryObject = (
    <AddInventoryObjectDialog
      isDialogOpen={addInventoryObjectOpen}
      closeDialog={() => setAddInventoryObjectOpen(false)}
      handleSave={handleSaveNewInventoryObject}
      inventoryLocationList={inventoryLocations}
    />
  );

  return (
    <InventoryObjectListPage
      inventoryObjectTableData={inventoryObjectTableData}
      inventoryLocationList={inventoryLocations}
      onAddInventoryObject={() => setAddInventoryObjectOpen(true)}
    >
      {{
        AddInventoryObject: addInventoryObject,
      }}
    </InventoryObjectListPage>
  );
}
