//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction } from "react";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import CustomerInvoiceListTable from "../customers/CustomerInvoiceListTablePage";
import {
  ExistingStiltInvoice,
  StiltInvoiceStatusValues,
} from "../../models/invoice";
import { InvoiceActionTypes } from "../../Pages/Invoices/InvoiceListPage";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currency: string;
  handlePayment: (invoiceID: ExistingStiltInvoice["id"]) => void;
  invoiceList: ExistingStiltInvoice[];
  emailReceipt: (invoiceID: ExistingStiltInvoice["id"]) => void;
  handleGetPDF: (invoiceID: string) => Promise<void>;
  openInvoiceDialog: (invoiceID: string) => void;
  actionsLoading: boolean;
  invoiceStatus: (StiltInvoiceStatusValues | null)[];
  setStiltInvoiceStatusSelection: Dispatch<
    SetStateAction<StiltInvoiceStatusValues | null>
  >;
  handleInvoiceActionSelected: (
    invoiceActionType: InvoiceActionTypes,
    selectedRows: ExistingStiltInvoice[],
  ) => Promise<void>;
  getLocationTitle: (id: string) => string;
  siteKeyLocationList: ExistingSiteKeyLocation[];
  children: {
    ViewInvoiceDialog: React.ReactNode;
    HandlePaymentDialog: React.ReactNode;
  };
}

export default function InvoiceListForTaskDialog({
  isOpen,
  onClose,
  currency,
  handlePayment,
  invoiceList,
  handleGetPDF,
  emailReceipt,
  openInvoiceDialog,
  actionsLoading,
  invoiceStatus,
  setStiltInvoiceStatusSelection,
  handleInvoiceActionSelected,
  siteKeyLocationList,
  getLocationTitle,
  ...props
}: Props) {
  return (
    <BaseModal
      open={isOpen}
      closeModal={onClose}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.INVOICES}</h1>
          <button
            onClick={onClose}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left max-w-md md:max-w-4xl"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg ">
        <CustomerInvoiceListTable
          currency={currency}
          openHandlePaymentDialog={handlePayment}
          invoiceList={invoiceList}
          emailReceipt={emailReceipt}
          getPDF={handleGetPDF}
          openInvoiceDialog={openInvoiceDialog}
          actionsLoading={actionsLoading}
          invoiceStatus={invoiceStatus}
          setStiltInvoiceStatusSelection={setStiltInvoiceStatusSelection}
          invoiceActionSelected={handleInvoiceActionSelected}
          siteKeyLocationList={siteKeyLocationList}
          getLocationTitle={getLocationTitle}
        />
        <div className="flex flex-col justify-between gap-4 sm:flex-row-reverse">
          <BaseButtonSecondary className="w-full sm:max-w-xs" onClick={onClose}>
            Close
          </BaseButtonSecondary>
        </div>
      </div>
      {props.children.HandlePaymentDialog}
      {props.children.ViewInvoiceDialog}
    </BaseModal>
  );
}
