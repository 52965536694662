// Libs
import { z } from "zod";

/**
 * A combination of the SiteKeyUser doc and the SiteKeyUserPermissions doc. This
 * model is specifically for use with react-hook-form.
 */

// Schema
export const AddOrEditUserSchema = z
  .object({
    displayName: z
      .string()
      .min(1, { message: "Display Name is required" })
      .max(200),
    email: z.string().email("Invalid email").min(5).max(200),
    jobTitle: z.string().min(1, { message: "Job Title is required" }).max(200),
    department: z
      .string()
      .min(1, { message: "Department is required" })
      .max(200),
    phone: z.string().min(1, { message: "Phone is required" }).max(50),
    companyName: z
      .string()
      .min(1, { message: "A company is required" })
      .max(200),
    defaultLocationID: z.string().max(200).nullable(),
    // PERMISSIONS
    approved: z.boolean(),
    inactive: z.boolean().optional(),

    getsNewTaskNotifications: z.boolean(),
    canEditContractorDetails: z.boolean(),
    canCreateTasks: z.boolean(),
    canUpdateTasks: z.boolean(),
    canDeleteTasks: z.boolean(),
    canCreateCraftRecords: z.boolean(),
    canUpdateCraftRecords: z.boolean(),
    canDeleteCraftRecords: z.boolean(),
    isPlantPersonnel: z.boolean(),
    isSiteAdmin: z.boolean(),

    newTaskCreated: z.boolean(),
    allTaskStatusChanged: z.boolean(),

    // Compliance permissions
    complianceRequirements_create: z.boolean(),
    complianceRequirements_read: z.boolean(),
    complianceRequirements_delete: z.boolean(),
    complianceResponses_create: z.boolean(),
    complianceResponses_read: z.boolean(),
    complianceResponses_readAll: z.boolean(),
    complianceResponses_delete: z.boolean(),
    complianceResponses_review: z.boolean(),
    tasks_changeDate: z.boolean().optional(),
  })
  .refine(
    (user) => {
      if (user.approved) {
        return (
          typeof user.companyName === "string" && user.companyName.length > 0
        );
      } else {
        return true;
      }
    },
    { message: "Approved users must be assigned a company" },
  );

// Inferred type.
export type AddOrEditUserFormState = z.infer<typeof AddOrEditUserSchema>;
