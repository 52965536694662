//Libs
import {
  ColDef,
  ICellRendererParams,
  RowClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  Fragment,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

//Local
import { ExistingPriceBookItem } from "../../models/price-book-item";
import * as strings from "../../strings";
import currencyFormatter from "../../currency";
import ChipTag from "../../components/ChipTag";
import SearchBox from "../../components/SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";
import { ExistingPriceBookItemCategory } from "../../models/price-book-item-category";

export interface Props {
  /* DATA */
  priceBookItemCategoryList: ExistingPriceBookItemCategory[];
  /* FUNCTIONS */
  onEditPBItemCategory: (priceBookItem: ExistingPriceBookItemCategory) => void;
  addNewPriceBookItemCategoryButton: React.ReactNode;
}

export default function PriceBookItemCategoryListPage({
  priceBookItemCategoryList,
  onEditPBItemCategory,
  addNewPriceBookItemCategoryButton,
  ...props
}: Props) {
  return (
    <Fragment>
      <h1 className="mb-8 text-5xl font-semibold text-primary">
        {strings.PRICEBOOK_CATEGORIES}
      </h1>
      <div className={"ag-theme-alpine flex h-full min-h-[600px] flex-col"}>
        <PriceBookItemCategoryListTable
          priceBookItemCategoryList={priceBookItemCategoryList}
          addNewPriceBookItemCategoryButton={addNewPriceBookItemCategoryButton}
          onEditPBItemCategory={onEditPBItemCategory}
        />
      </div>
    </Fragment>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  flex: 1,
};

interface PriceBookItemCategoryListTableProps {
  priceBookItemCategoryList: Props["priceBookItemCategoryList"];
  addNewPriceBookItemCategoryButton: React.ReactNode;
  onEditPBItemCategory: Props["onEditPBItemCategory"];
}

/* TABLE COMPONENT */
const PriceBookItemCategoryListTable = memo(
  ({
    priceBookItemCategoryList,
    onEditPBItemCategory,
    ...props
  }: PriceBookItemCategoryListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const renderItem = (params: ICellRendererParams): JSX.Element => {
      return (
        <div>
          <span>{params.data.name}</span>
        </div>
      );
    };

    const renderActive = (params: ICellRendererParams): JSX.Element => {
      return (
        <div>
          <span>{params.data.active ? "Yes" : "No"}</span>
        </div>
      );
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Name",
        cellRenderer: renderItem,
        tooltipValueGetter: () => "Edit Category",
        autoHeight: true,
        minWidth: 400,
        flex: 2,
      },
      {
        headerName: "Active",
        cellRenderer: renderActive,
        tooltipValueGetter: () => "Edit Category",
        autoHeight: true,
        minWidth: 400,
        flex: 2,
      },
    ];

    return (
      <Fragment>
        <div className="flex flex-col justify-end gap-6 sm:flex-row sm:items-end">
          <div className="flex flex-col gap-6">
            {props.addNewPriceBookItemCategoryButton}
          </div>
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={priceBookItemCategoryList}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
          onRowClicked={(event: RowClickedEvent) =>
            onEditPBItemCategory(event.data)
          }
        />
      </Fragment>
    );
  },
  // (previous, next) => {
  //   const isPriceBookItemListTheSame = isEqual(
  //     previous.priceBookItemList,
  //     next.priceBookItemList
  //   );
  //
  //   return isPriceBookItemListTheSame;
  // }
);
