//Libs
import { DateTime } from "luxon";

/**
 * NOTE: Because this file exports more than a React component, I'm using a
 * dynamic import on the pdfmake library to prevent it from being bundled with
 * the rest of the code. This is because pdfmake is a large library and we don't
 * want to slow down the initial load time of the app. -Dan
 */
const lazyLoadPdfMake = import("pdfmake/build/pdfmake");

//Local
import { DbRead } from "../../database";
import { ExistingSiteKey } from "../../models/site-key";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import {
  ExistingStiltInvoice,
  getInvoiceSectionString,
  StiltLineItem,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { ExistingTask, getJobDescription } from "../../models/task";
import {
  ExistingCustomer,
  getBillingInfoFromCustomerAndLocations,
} from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { ExistingSignature } from "../../models/signature";
import {
  ExistingStiltPayment,
  UnauthedPaymentMade,
} from "../../models/stilt-payment";
import {
  convertDecimalToHoursMinutes,
  convertFSTimestampToLuxonDT,
} from "../../utils";
import currencyFormatter, { DollarCurrency } from "../../currency";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import { EstimateStatus, ExistingEstimate } from "../../models/estimate";
import { ExistingEstimateItem } from "../../models/estimate-item";
import { getEstimateTotals } from "../../assets/js/estimateFunctions";
import { ExistingStiltPhoto } from "../../models/stilt-photo";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { ExistingCraftRecord } from "../../models/craft-record";
import {
  convertToReadableTimestamp,
  convertToReadableTimestampDate,
} from "../../assets/js/convertToReadableTimestamp";
import { Timestamp } from "firebase/firestore";
import { phoneUtils } from "../../utils/phoneUtils";
import { getReadableTaskStatus, TaskStatus } from "../../models/task-status";
import { getCustomerBalanceString } from "../../utils/customerBalanceTag";
import {
  ExistingMembership,
  getReadableMembershipStatus,
} from "../../models/membership";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { ECFandAnswer } from "../WorkRecordAndTasks/DetailsAndEventsPanels";
import * as strings from "../../strings";
import { ExistingEvent } from "../../models/event";
import { getEventTypeString } from "../../models/event-types";
import { isValidCraftType } from "../../models/craft-types";
import { isValidTaskType } from "../../models/task-types";
import { getTaskCustomFieldList } from "../WorkRecordAndTasks/functions";
import { ExistingCustomerContact } from "../../models/customer-contact";
import { ExistingCustomField } from "../../models/custom-field";
import getPaymentLineText from "../../assets/js/getPaymentLineText";

const pdfMakeFonts = {
  // download default Roboto font from cdnjs.com
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

// so we can stub this ƒn in tests:
export const generatePDF = {
  invoice: generatePDFInvoiceDocument,
  estimate: generatePDFEstimateDocument,
  job: generatePDFJobDocument,
};

async function generatePDFJobDocument(
  siteKey: ExistingSiteKey,
  workRecord: ExistingCraftRecord,
  customerDoc: ExistingCustomer,
  fullAddress: string | null,
  currentLocation: ExistingCustomerLocation | null,
  estimateList: ExistingEstimate[],
  workRecordInvoiceList: ExistingStiltInvoice[],
  actualWorkRecordTasks: ExistingTask[],
  customerLocationMemberships: ExistingMembership[],
  membershipTemplateList: ExistingMembershipTemplate[],
  details: Record<string, any>,
  siteKeyCustomFields: ExistingCustomField[],
  userDisplayNamesMap: Record<string, string>,
  events: ExistingEvent[],
  customerContacts: ExistingCustomerContact[] | null,
) {
  const pdfContent: any[] = [];

  const merchantLogoURL =
    siteKey.customizations.accounting?.merchantLogoURL ?? null;

  const merchantName = siteKey.name;

  const header = jobHeaderSection({
    merchantLogoURL,
    merchantName,
    workRecordStatus: workRecord.open,
    workRecordTitle: workRecord.title,
    workRecordTSClosed: workRecord.timestampRecordClosed,
  });

  const body = jobBodySection({
    customerDoc,
    fullAddress,
    currentLocationNotes: currentLocation?.notes ?? null,
    estimateList: estimateList,
    workRecordInvoiceList,
    actualWorkRecordTasks,
    siteKeyDoc: siteKey,
    WRDescription: workRecord.description,
    customerLocationMemberships,
    membershipTemplateList,
    details,
    siteKeyCustomFields,
    userDisplayNamesMap,
    events,
    customerContacts,
  });

  pdfContent.push(header, body);

  const docDefinition: any = {
    content: pdfContent,
    pageMargins: [30, 30, 30, 50],
    defaultStyle: {
      columnGap: 20,
      font: "Roboto",
    },
    styles: {
      tableMargins: {
        margin: [0, 0, 0, 10],
      },
      topBottomMargin: {
        margin: [0, 5, 0, 5],
      },
      footer: {
        italics: true,
        fontSize: 9,
      },
    },
    images: {
      merchantLogoURL: merchantLogoURL,
    },
  };

  const { default: pdfMake } = await lazyLoadPdfMake;
  pdfMake.fonts = pdfMakeFonts;
  pdfMake.createPdf(docDefinition).open();
}

async function generatePDFEstimateDocument(
  siteKey: ExistingSiteKey,
  estimateIDs: string[],
) {
  const pdfContent: any[] = [];

  const merchantLogoURL =
    siteKey.customizations.accounting?.merchantLogoURL ?? null;

  let index = 0;
  for (const estimateID of estimateIDs) {
    //Retrieve data
    const basePromises = await Promise.all([
      DbRead.estimates.getByEstimateId(siteKey.id, estimateID),
      DbRead.signatures.get({ siteKey: siteKey.id, estimateID: estimateID }),
      DbRead.estimateItems.getByEstimateId(siteKey.id, estimateID),
    ]);
    const estimateDoc = basePromises[0];
    const estimateSignatures = basePromises[1];
    const estimateItems = basePromises[2];

    let workSignatures: ExistingSignature[] = [];
    if (estimateDoc.taskID) {
      workSignatures = await DbRead.signatures.get({
        siteKey: siteKey.id,
        taskID: estimateDoc.taskID,
      });
    }

    const signatures = [...estimateSignatures, ...workSignatures];
    signatures.sort(
      (a, b) => a.timestampCreated.toMillis() - b.timestampCreated.toMillis(),
    );

    const taskDoc = estimateDoc.taskID
      ? await DbRead.tasks.getDocByID(siteKey.id, estimateDoc.taskID)
      : null;
    const parentRecordDoc = estimateDoc.craftRecordID
      ? await DbRead.parentRecords.get(siteKey.id, estimateDoc.craftRecordID)
      : null;

    const readPromises = await Promise.all([
      DbRead.siteKeyLocations.get(siteKey.id, estimateDoc.locationID),
      DbRead.customers.get(siteKey.id, estimateDoc.customerID),
      DbRead.customerLocations.getSingle(
        siteKey.id,
        estimateDoc.customerLocationID,
      ),
      DbRead.payments.getAllByInvoiceID(siteKey.id, estimateDoc.id),
    ]);

    const siteKeyLocationDoc = readPromises[0];
    const customerDoc = readPromises[1];
    const customerLocationDoc = readPromises[2];
    const paymentDocs = readPromises[3];

    const estimateItemsPhotos: Record<string, any[]> = {};
    for (const item of estimateItems) {
      const photos: ExistingStiltPhoto[] =
        await DbRead.photos.getByEstimateItemID({
          estimateItemID: item.id,
          siteKey: siteKey.id,
        });

      const base64Photos = [];
      for (const photo of photos) {
        const imageBase64 = await getBase64ImageFromUrl(photo.photoURL);
        base64Photos.push({ base64: imageBase64 });
      }
      estimateItemsPhotos[item.id] = base64Photos;
    }

    //download the logo of the merchant

    const header = headerSection({
      siteDoc: siteKey,
      locationDoc: siteKeyLocationDoc,
      estimateDoc: estimateDoc,
      taskDoc,
      customerDoc,
      customerLocationDoc,
      merchantLogoURL,
      parentRecordDoc,
    });
    const body = estimateBodySection({
      estimateDoc,
      estimateItems,
      siteKeyDoc: siteKey,
      customerDoc,
      customerLocationDoc,
      signatures,
      paymentDocs,
      estimateItemsPhotos,
    });

    const pageBreak = { text: "", pageBreak: "after" };
    pdfContent.push(header, body);
    if (index < estimateIDs.length - 1) {
      pdfContent.push(pageBreak);
    }
    index += 1;
  }

  const docDefinition: any = {
    content: pdfContent,
    pageMargins: [30, 30, 30, 50],
    defaultStyle: {
      columnGap: 20,
      font: "Roboto",
    },
    styles: {
      tableMargins: {
        margin: [0, 0, 0, 20],
      },
      topBottomMargin: {
        margin: [0, 5, 0, 5],
      },
      footer: {
        italics: true,
        fontSize: 9,
      },
    },
    images: {
      merchantLogoURL: merchantLogoURL,
    },
  };

  const { default: pdfMake } = await lazyLoadPdfMake;
  pdfMake.fonts = pdfMakeFonts;
  pdfMake.createPdf(docDefinition).open();
}

async function generatePDFInvoiceDocument(
  siteKey: ExistingSiteKey,
  invoiceIDs: string[],
  displayPaymentLink: boolean,
  includeJobPhotos: boolean,
  userPermissions: ExistingSiteKeyUserPermissions | null,
) {
  const pdfContent: any[] = [];
  const merchantLogoURL =
    siteKey.customizations.accounting?.merchantLogoURL ?? null;

  let index = 0;
  for (const invoiceID of invoiceIDs) {
    //Retrieve data
    const basePromises = await Promise.all([
      DbRead.invoices.getSingle(siteKey.id, invoiceID),
      DbRead.signatures.get({ siteKey: siteKey.id, invoiceID }),
    ]);
    const invoiceDoc = basePromises[0];
    const invoiceSignatures = basePromises[1];

    let workSignatures: ExistingSignature[] = [];
    if (invoiceDoc.taskID) {
      workSignatures = await DbRead.signatures.get({
        siteKey: siteKey.id,
        taskID: invoiceDoc.taskID,
      });
    }
    let estimateSignatures: ExistingSignature[] = [];
    if (invoiceDoc.estimateID) {
      estimateSignatures = await DbRead.signatures.get({
        siteKey: siteKey.id,
        estimateID: invoiceDoc.estimateID,
      });
    }

    const signatures = [
      ...estimateSignatures,
      ...workSignatures,
      ...invoiceSignatures,
    ];
    signatures.sort(
      (a, b) => a.timestampCreated.toMillis() - b.timestampCreated.toMillis(),
    );

    let taskDoc: ExistingTask | null = null;
    let parentRecordDoc: ExistingCraftRecord | null = null;
    try {
      taskDoc = invoiceDoc.taskID
        ? await DbRead.tasks.getDocByID(siteKey.id, invoiceDoc.taskID)
        : null;
      parentRecordDoc = invoiceDoc.craftRecordID
        ? await DbRead.parentRecords.get(siteKey.id, invoiceDoc.craftRecordID)
        : null;
    } catch (error) {
      console.log("Error fetching task", error);
    }

    let uniqueLink: string | undefined;
    if (invoiceDoc.amountDue > 0) {
      if (displayPaymentLink) {
        uniqueLink = await generatePaymentUniqueLink(
          siteKey.id,
          invoiceDoc.id,
          "pdf",
        );
      }
    }

    const [siteKeyLocationDoc, customerDoc, customerLocationDoc, paymentDocs] =
      await Promise.all([
        DbRead.siteKeyLocations.get(siteKey.id, invoiceDoc.locationID),
        DbRead.customers.get(siteKey.id, invoiceDoc.customerID),
        DbRead.customerLocations.getSingle(
          siteKey.id,
          invoiceDoc.customerLocationID,
        ),
        DbRead.payments.getAllByInvoiceID(siteKey.id, invoiceDoc.id),
      ]);

    const base64Photos = [];
    if (includeJobPhotos && userPermissions && invoiceDoc.craftRecordID) {
      const jobPhotos = await DbRead.parentRecordPhotos.getAll({
        siteKey: siteKey.id,
        userPermissions: userPermissions,
        workRecordID: invoiceDoc.craftRecordID,
      });
      for (const photo of jobPhotos) {
        const imageBase64 = await getBase64ImageFromUrl(photo.photoURL);
        base64Photos.push({ base64: imageBase64 });
      }
    }

    const header = headerSection({
      siteDoc: siteKey,
      locationDoc: siteKeyLocationDoc,
      invoiceDoc,
      taskDoc,
      customerDoc,
      customerLocationDoc,
      merchantLogoURL,
      parentRecordDoc,
    });
    const body = invoiceBodySection({
      invoiceDoc,
      siteKeyDoc: siteKey,
      locationDoc: siteKeyLocationDoc,
      signatures,
      paymentDocs,
      uniqueLink,
    });

    const pageBreak = { text: "", pageBreak: "after" };
    pdfContent.push(header, body);
    if (index < invoiceIDs.length - 1) {
      pdfContent.push(pageBreak);
    }
    if (base64Photos.length > 0) {
      pdfContent.push([
        {
          ...generatePhotoGrid(base64Photos),
        },
      ]);
    }
    index += 1;
  }

  const docDefinition: any = {
    content: pdfContent,
    pageMargins: [30, 30, 30, 50],
    defaultStyle: {
      columnGap: 20,
      font: "Roboto",
    },
    styles: {
      tableMargins: {
        margin: [0, 0, 0, 20],
      },
      topBottomMargin: {
        margin: [0, 5, 0, 5],
      },
      footer: {
        italics: true,
        fontSize: 9,
      },
    },
    images: {
      merchantLogoURL: merchantLogoURL,
    },
  };

  const { default: pdfMake } = await lazyLoadPdfMake;
  pdfMake.fonts = pdfMakeFonts;

  pdfMake.createPdf(docDefinition).open();
}

interface JobHeaderArgs {
  workRecordTitle: ExistingCraftRecord["title"];
  workRecordStatus: ExistingCraftRecord["open"];
  workRecordTSClosed: ExistingCraftRecord["timestampRecordClosed"];
  merchantLogoURL: string | null;
  merchantName: string;
}

function jobHeaderSection({
  workRecordStatus,
  workRecordTSClosed,
  workRecordTitle,
  merchantLogoURL,
  merchantName,
}: JobHeaderArgs) {
  function getLogoBlock() {
    if (merchantLogoURL) {
      return {
        image: "merchantLogoURL",
        width: 150,
      };
    } else {
      return {
        text: merchantName,
        width: 150,
      };
    }
  }

  const data = [
    {
      layout: "noBorders",
      style: "tableMargins",
      table: {
        widths: ["*", "45%", "30%"],
        body: [
          [
            getLogoBlock(),
            formatWRStatus(workRecordStatus, workRecordTSClosed),
          ],
          [
            {
              text: workRecordTitle,
              style: {
                fontSize: 24,
                bold: true,
              },
              margin: [0, 10, 0, 0],
              alignment: "left",
              colSpan: 3,
            },
          ],
        ],
      },
    },
  ];

  return data;
}

interface HeaderArgs {
  siteDoc: ExistingSiteKey;
  locationDoc: ExistingSiteKeyLocation;
  invoiceDoc?: ExistingStiltInvoice;
  estimateDoc?: ExistingEstimate;
  taskDoc: ExistingTask | null;
  parentRecordDoc: ExistingCraftRecord | null;
  customerDoc: ExistingCustomer;
  customerLocationDoc: ExistingCustomerLocation;
  merchantLogoURL: string | null;
}

function headerSection({
  customerDoc,
  customerLocationDoc,
  invoiceDoc,
  estimateDoc,
  locationDoc,
  merchantLogoURL,
  siteDoc,
  taskDoc,
  parentRecordDoc,
}: HeaderArgs) {
  const sitePaymentTerms: Record<string, TemplatePaymentTerm> =
    siteDoc.customizations.paymentTerms;
  const merchantName = siteDoc.name;
  const merchantData = locationDoc.invoiceHeader;

  let invoiceData = null;
  let estimateNumber = null;

  if (invoiceDoc) {
    invoiceData = formatInvoiceData(
      invoiceDoc,
      taskDoc,
      parentRecordDoc,
      sitePaymentTerms,
      siteDoc.timezone,
    );
  }

  if (estimateDoc && estimateDoc.estimateNumber) {
    estimateNumber = `# ${estimateDoc.estimateNumber}`;
  }

  function getLogoBlock() {
    if (merchantLogoURL) {
      return {
        image: "merchantLogoURL",
        width: 150,
      };
    } else {
      return {
        text: merchantName,
        width: 150,
      };
    }
  }

  const data = [
    {
      layout: "noBorders",
      style: "tableMargins",
      table: {
        widths: ["*", "45%", "30%"],
        body: [
          [
            getLogoBlock(),
            {
              text: merchantData,
              style: {
                fontSize: 10,
                bold: true,
              },
              alignment: "center",
            },
            {
              text: invoiceData,
              fontSize: 10,
              alignment: "left",
            },
          ],
          [
            {
              text: estimateDoc && "Estimate ",
              style: {
                fontSize: 40,
                bold: true,
              },
              alignment: "left",
            },
            {},
            {
              text: estimateNumber,
              style: {
                fontSize: 14,
                bold: false,
              },
              alignment: "right",
              margin: [0, 5, 50, 5],
            },
          ],
        ],
      },
    },
    {
      layout: "noBorders",
      style: "tableMargins",
      table: {
        widths: ["50%", "50%"],
        body: [
          [
            {
              text: "Billing Address:",
              style: {
                fontSize: 10,
                bold: true,
              },
              alignment: "left",
            },
            {
              text: "Service Address:",
              style: {
                fontSize: 10,
                bold: true,
              },
              alignment: "left",
            },
          ],
          [
            {
              text: formatBillingAddress(customerDoc, customerLocationDoc),
              style: {
                fontSize: 10,
              },
              alignment: "left",
            },
            {
              text: formatJobAddress(customerLocationDoc, customerDoc),
              style: {
                fontSize: 10,
              },
              alignment: "left",
            },
          ],
        ],
      },
    },
  ];

  return data;
}

interface EstimateBodyArgs {
  estimateDoc: ExistingEstimate;
  estimateItems: ExistingEstimateItem[];
  siteKeyDoc: ExistingSiteKey;
  customerDoc: ExistingCustomer;
  customerLocationDoc: ExistingCustomerLocation;
  signatures: ExistingSignature[];
  paymentDocs: ExistingStiltPayment[];
  estimateItemsPhotos: Record<string, any[]>;
}

function estimateBodySection({
  estimateDoc,
  estimateItems,
  customerDoc,
  customerLocationDoc,
  signatures,
  siteKeyDoc,
  estimateItemsPhotos,
}: EstimateBodyArgs) {
  let currencyType = siteKeyDoc.customizations.accounting?.currency;
  if (typeof currencyType !== "string") {
    currencyType = "USD";
  }

  const {
    subtotal,
    discount,
    totalTaxAmount,
    totalEstimate,
    subtotalWithDiscount,
    totalTaxAmountPST,
    totalTaxAmountGST,
  } = getEstimateTotals(
    estimateItems,
    customerDoc,
    customerLocationDoc,
    estimateDoc.discount ?? 0,
    siteKeyDoc?.customizations.accounting?.currency ?? "USD",
  );

  const data = [
    {
      layout: "headerLineOnly",
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [
            {
              text: "Description of Work:",
              style: {
                fontSize: 12,
                bold: true,
              },
              alignment: "center",
            },
          ],
          [
            {
              text: estimateDoc.notes ?? "N/A",
              style: "topBottomMargin",
            },
          ],
        ],
      },
    },
    {
      table: {
        widths: ["60%", "40%"],
        style: "topBottomMargin",
        body: [
          ...getEstimateItems(estimateItems, currencyType, estimateItemsPhotos),
          ...getEstimateTotalsSection({
            discount,
            subTotal: subtotal,
            totalAmount: totalEstimate,
            totalTaxAmount: totalTaxAmount,
            subtotalWithDiscount,
            totalTaxAmountPST,
            totalTaxAmountGST,
          }),
          ...getSignaturesSection(signatures, siteKeyDoc.timezone),
        ],
      },
      layout: {
        defaultBorder: false,
      },
    },
  ];

  return data;
}

interface InvoiceBodyArgs {
  invoiceDoc: ExistingStiltInvoice;
  siteKeyDoc: ExistingSiteKey;
  locationDoc: ExistingSiteKeyLocation;
  signatures: ExistingSignature[];
  paymentDocs: ExistingStiltPayment[];
  uniqueLink?: string | undefined;
}

function invoiceBodySection({
  invoiceDoc,
  locationDoc,
  paymentDocs,
  signatures,
  siteKeyDoc,
  uniqueLink,
}: InvoiceBodyArgs) {
  let currencyType = siteKeyDoc.customizations.accounting?.currency;
  if (typeof currencyType !== "string") {
    currencyType = "USD";
  }

  const lineItems = invoiceDoc ? invoiceDoc.lineItems : [];
  const {
    subTotal,
    discount,
    totalTaxAmount,
    totalAmount,
    amountDue,
    totalTaxAmountPST,
    totalTaxAmountGST,
  } = invoiceDoc;

  let subTotalWithoutGlobalDiscount = 0;
  let allDiscountsApplied = 0;
  if (invoiceDoc) {
    invoiceDoc.lineItems.forEach((i) => {
      subTotalWithoutGlobalDiscount += i.subTotal;
      // Add in line discounts
      allDiscountsApplied += i.quantity * i.unitPrice - i.subTotal;
    });
    // Add in global discount
    allDiscountsApplied += subTotalWithoutGlobalDiscount - invoiceDoc.subTotal;
  }

  const data = [
    {
      layout: "headerLineOnly",
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [
            {
              text: "Description of Work:",
              style: {
                fontSize: 12,
                bold: true,
              },
              alignment: "center",
            },
          ],
          [
            {
              text: invoiceDoc?.note ?? "N/A",
              style: "topBottomMargin",
            },
          ],
        ],
      },
    },
    {
      table: {
        widths: ["60%", "40%"],
        style: "topBottomMargin",
        body: [
          ...getInvoiceLineItems(lineItems, currencyType),
          ...getTotalsSection({
            currencyType,
            discount,
            subTotal,
            totalAmount,
            totalTaxAmount,
            allDiscountsApplied,
            subTotalWithoutGlobalDiscount,
            totalTaxAmountPST,
            totalTaxAmountGST,
          }),
          ...getSignaturesSection(signatures, siteKeyDoc.timezone),
          ...getPaymentSection({
            paymentDocs,
            currencyType,
            uniqueLink,
            timezone: siteKeyDoc.timezone,
            amountDue,
            invoiceMessage: locationDoc.invoiceMessage,
            invoiceSignatureText: locationDoc.invoiceSignatureText,
          }),
        ],
      },
      layout: {
        defaultBorder: false,
      },
    },
  ];

  return data;
}

function formatInvoiceData(
  invoiceDoc: ExistingStiltInvoice,
  taskDoc: ExistingTask | null,
  parentRecordDoc: ExistingCraftRecord | null,
  sitePaymentTerms: Record<string, TemplatePaymentTerm>,
  timezone: string,
) {
  const invoiceNumber = invoiceDoc.invoiceNumber ?? "N/A";
  const issueDate = convertFSTimestampToLuxonDT(invoiceDoc.issueDate)
    .setZone(timezone)
    .toFormat("LL/dd/yy");

  const invoiceDataStructure = [
    { text: "Invoice #: ", bold: true },
    `${invoiceNumber} \n`,
    { text: "Invoice Date: ", bold: true },
    `${issueDate} \n`,
  ];
  if (invoiceDoc.poNumber) {
    invoiceDataStructure.push({ text: "Customer PO: ", bold: true });
    invoiceDataStructure.push(`${invoiceDoc.poNumber ?? "N/A"} \n`);
  }

  if (parentRecordDoc?.craftDetails.jobNumber) {
    invoiceDataStructure.push(
      { text: "Job #: ", bold: true },
      `${parentRecordDoc.craftDetails.jobNumber ?? "N/A"} \n`,
    );
  }

  invoiceDataStructure.push(
    { text: "Payment Terms: ", bold: true },
    `${
      getPaymentTermsTitle(invoiceDoc.paymentTerms, sitePaymentTerms) ?? "N/A"
    } \n`,
    { text: "Due Date: ", bold: true },
    `${
      invoiceDoc.dueDate
        ? convertFSTimestampToLuxonDT(invoiceDoc.dueDate)
            .setZone(timezone)
            .toFormat("LL/dd/yy")
        : "N/A"
    } \n`,
  );

  if (taskDoc && taskDoc.timestampTaskCompleted) {
    const completedDate = convertFSTimestampToLuxonDT(
      taskDoc.timestampTaskCompleted,
    )
      .setZone(timezone)
      .toFormat("LL/dd/yy");
    const data = [
      { text: "Completed Date: ", bold: true },
      `${completedDate} \n`,
    ];
    invoiceDataStructure.push(...data);
  }

  return invoiceDataStructure;
}

function formatBillingAddress(
  customerDoc: ExistingCustomer,
  customerLocation: ExistingCustomerLocation,
) {
  const billingInfo = getBillingInfoFromCustomerAndLocations(customerDoc, [
    customerLocation,
  ]);

  if (billingInfo.addressLine2 == "") {
    return `${billingInfo.name ?? ""} \n ${billingInfo.addressLine1 ?? ""} \n ${billingInfo.city ?? ""}, ${billingInfo.state ?? ""} ${billingInfo.zipCode ?? ""}`;
  } else {
    return `${billingInfo.name ?? ""} \n ${billingInfo.addressLine1 ?? ""} \n ${billingInfo.addressLine2 ?? ""} \n ${billingInfo.city ?? ""}, ${billingInfo.state} ${billingInfo.zipCode ?? ""}`;
  }
}

function formatJobAddress(
  customerLocationDoc: ExistingCustomerLocation,
  customerDoc: ExistingCustomer,
) {
  if (customerLocationDoc.addressLine2 == "") {
    return `${customerDoc.name ?? ""} \n ${customerLocationDoc.addressLine1 ?? ""} \n ${customerLocationDoc.city ?? ""}, ${customerLocationDoc.state ?? ""} ${customerLocationDoc.zipCode ?? ""}`;
  } else {
    return `${customerDoc.name ?? ""} \n ${customerLocationDoc.addressLine1 ?? ""} \n ${customerLocationDoc.addressLine2 ?? ""} \n ${customerLocationDoc.city ?? ""}, ${customerLocationDoc.state ?? ""} ${customerLocationDoc.zipCode ?? ""}`;
  }
}

function getEstimateItems(
  lineItems: ExistingEstimateItem[],
  currencyType: string,
  estimateItemsPhotos: Record<string, ExistingStiltPhoto[]>,
) {
  const formattedLineItems: any[] = [];

  for (const item of lineItems) {
    const photos = estimateItemsPhotos[item.id];
    const { description, title, quantity, unitPrice, discount } = item;

    const subTotal = unitPrice * quantity;

    let discountedLineItemPrice = 0;
    if (discount && discount > 0) {
      discountedLineItemPrice = calculateDiscountedPrice(
        discount,
        unitPrice * quantity,
      );
    }

    if (discount && discount > 0) {
      formattedLineItems.push([
        /* title */
        {
          border: [false, true, false, false],
          text: title,
          margin: [0, 5, 0, 0],
          bold: true,
        },
        /* not discounted price & discounted price */
        {
          border: [false, true, false, false],
          text: [
            {
              text: `${currencyFormatter(unitPrice * quantity, currencyType)} `,
              decoration: "lineThrough",
            },
            {
              text: ` ${currencyFormatter(
                discountedLineItemPrice,
                currencyType,
              )} `,
              bold: true,
            },
          ],
          alignment: "right",
          colSpan: 1,
        },
      ]);
    } else {
      formattedLineItems.push([
        /* title */
        {
          border: [false, true, false, false],
          text: title,
          bold: true,
        },
        /* not discounted price */
        {
          border: [false, true, false, false],
          text: `${currencyFormatter(subTotal, currencyType)} `,
          bold: true,
          alignment: "right",
        },
      ]);
    }
    /* description */
    formattedLineItems.push([
      {
        text: description,
        colSpan: 2,
      },
    ]);

    if (photos.length > 0) {
      formattedLineItems.push([
        {
          text: `x ${quantity} @${currencyFormatter(unitPrice, currencyType)} `,
          colSpan: 2,
        },
      ]);
      formattedLineItems.push([
        {
          colSpan: 2,
          ...generatePhotoGrid(photos),
        },
      ]);
    } else {
      formattedLineItems.push([
        {
          border: [false, false, false, true],
          borderColor: ["", "", "", "#9BA3AF"],
          margin: [0, 0, 0, 5],
          text: `x ${quantity} @${currencyFormatter(unitPrice, currencyType)} `,
          colSpan: 2,
        },
      ]);
    }
  }

  return formattedLineItems;
}

function getInvoiceLineItems(lineItems: StiltLineItem[], currencyType: string) {
  const formattedLineItems: any[] = [];

  lineItems.forEach((item) => {
    const {
      estimateItemDescription,
      estimateItemTitle,
      subTotal,
      quantity,
      unitPrice,
      discount,
    } = item;

    let discountedLineItemPrice = 0;
    if (discount > 0) {
      discountedLineItemPrice = calculateDiscountedPrice(
        discount,
        unitPrice * quantity,
      );
    }

    if (discount > 0) {
      formattedLineItems.push([
        {
          border: [false, true, false, false],
          text: estimateItemTitle,
          margin: [0, 5, 0, 0],
          bold: true,
        },
        {
          border: [false, true, false, false],
          text: [
            {
              text: `${currencyFormatter(unitPrice * quantity, currencyType)} `,
              decoration: "lineThrough",
            },
            {
              text: ` ${currencyFormatter(
                discountedLineItemPrice,
                currencyType,
              )} `,
              bold: true,
            },
          ],
          alignment: "right",
          colSpan: 1,
        },
      ]);
    } else {
      formattedLineItems.push([
        {
          border: [false, true, false, false],
          text: estimateItemTitle,
          bold: true,
        },
        {
          border: [false, true, false, false],
          text: `${currencyFormatter(subTotal, currencyType)} `,
          bold: true,
          alignment: "right",
        },
      ]);
    }
    formattedLineItems.push([
      {
        text: estimateItemDescription,
        colSpan: 2,
      },
    ]);
    formattedLineItems.push([
      {
        border: [false, false, false, true],
        borderColor: ["", "", "", "#9BA3AF"],
        margin: [0, 0, 0, 5],
        text: `x ${quantity} @${currencyFormatter(unitPrice, currencyType)} `,
        colSpan: 2,
      },
    ]);
  });

  return formattedLineItems;
}

function calculateDiscountedPrice(discount: number, price: number): number {
  const decimalDiscount = discount / 100;
  const discountAmount = price * decimalDiscount;
  return price - discountAmount;
}

interface EstimateTotalsSectionArgs {
  subTotal: string;
  discount: number;
  totalTaxAmount: string;
  totalAmount: string;
  subtotalWithDiscount: string;
  totalTaxAmountPST?: string;
  totalTaxAmountGST?: string;
}

function getEstimateTotalsSection({
  discount,
  subTotal,
  totalAmount,
  totalTaxAmount,
  subtotalWithDiscount,
  totalTaxAmountPST,
  totalTaxAmountGST,
}: EstimateTotalsSectionArgs) {
  const totalsSection: any[] = [];
  totalsSection.push([
    {
      border: [false, true, false, false],
      margin: [0, 5, 0, 0],
      text: [{ text: "Subtotal: ", bold: true }, `${subTotal} `],
      alignment: "right",
      colSpan: 2,
    },
  ]);

  if (discount > 0) {
    totalsSection.push([
      {
        text: [{ text: "Discount: ", bold: true }, `${discount}% `],
        alignment: "right",
        colSpan: 2,
      },
    ]);
    totalsSection.push([
      {
        text: [
          { text: "Subtotal with Discount: ", bold: true },
          `${subtotalWithDiscount} `,
        ],
        alignment: "right",
        colSpan: 2,
      },
    ]);
  }

  if (totalTaxAmountPST) {
    totalsSection.push([
      {
        text: [
          { text: "PST: ", italics: true, fontSize: 11 },
          { text: totalTaxAmountPST, italics: true, fontSize: 11 },
        ],
        alignment: "right",
        colSpan: 2,
      },
    ]);
  }

  if (totalTaxAmountGST) {
    totalsSection.push([
      {
        text: [
          { text: "GST: ", italics: true, fontSize: 11 },
          { text: totalTaxAmountGST, italics: true, fontSize: 11 },
        ],
        alignment: "right",
        colSpan: 2,
      },
    ]);
  }

  totalsSection.push([
    {
      text: [{ text: "Tax Total: ", bold: true }, `${totalTaxAmount} `],
      alignment: "right",
      colSpan: 2,
    },
  ]);

  totalsSection.push([
    {
      text: [
        { text: "Total Amount: ", bold: true, fontSize: 16 },
        {
          text: `${totalAmount} `,
          fontSize: 16,
        },
      ],
      alignment: "right",
      margin: [0, 0, 0, 5],
      colSpan: 2,
    },
  ]);

  return totalsSection;
}

interface TotalsSectionArgs {
  subTotal: number;
  discount: number;
  totalTaxAmount: number;
  totalAmount: number;
  currencyType: string;
  allDiscountsApplied: number;
  subTotalWithoutGlobalDiscount: number;
  totalTaxAmountPST?: number;
  totalTaxAmountGST?: number;
}

function getTotalsSection({
  currencyType,
  discount,
  subTotal,
  totalAmount,
  totalTaxAmount,
  allDiscountsApplied,
  subTotalWithoutGlobalDiscount,
  totalTaxAmountPST,
  totalTaxAmountGST,
}: TotalsSectionArgs) {
  const totalsSection: any[] = [];

  totalsSection.push([
    {
      border: [false, true, false, false],
      margin: [0, 5, 0, 0],
      text: [
        { text: "Subtotal: ", bold: true },
        `${currencyFormatter(subTotalWithoutGlobalDiscount, currencyType)} `,
      ],
      alignment: "right",
      colSpan: 2,
    },
  ]);

  if (discount > 0) {
    totalsSection.push(
      [
        {
          text: [{ text: "Discount: ", bold: true }, `${discount}% `],
          alignment: "right",
          colSpan: 2,
        },
      ],
      [
        {
          text: [
            { text: "Subtotal with Discount: ", bold: true },
            `${currencyFormatter(subTotal, currencyType)} `,
          ],
          alignment: "right",
          colSpan: 2,
        },
      ],
      [
        {
          text: [
            { text: "Total Discount Amount: ", italics: true, color: "green" },
            {
              text: `${currencyFormatter(allDiscountsApplied, currencyType)} `,
              italics: true,
              color: "green",
            },
          ],
          alignment: "right",
          colSpan: 2,
        },
      ],
    );
  }

  totalsSection.push([
    {
      text: [
        { text: "Tax Total: ", bold: true },
        `${currencyFormatter(totalTaxAmount, currencyType)} `,
      ],
      alignment: "right",
      colSpan: 2,
    },
  ]);

  if (totalTaxAmountPST) {
    totalsSection.push([
      {
        text: [
          { text: "PST: ", italics: true, fontSize: 11 },
          {
            text: `${currencyFormatter(totalTaxAmountPST, currencyType)} `,
            italics: true,
            fontSize: 11,
          },
        ],
        alignment: "right",
        colSpan: 2,
      },
    ]);
  }

  if (totalTaxAmountGST) {
    totalsSection.push([
      {
        text: [
          { text: "GST: ", italics: true, fontSize: 11 },
          {
            text: `${currencyFormatter(totalTaxAmountGST, currencyType)} `,
            italics: true,
            fontSize: 11,
          },
        ],
        alignment: "right",
        colSpan: 2,
      },
    ]);
  }

  totalsSection.push([
    {
      text: [
        { text: "Total Amount: ", bold: true, fontSize: 16 },
        {
          text: `${currencyFormatter(totalAmount, currencyType)} `,
          fontSize: 16,
        },
      ],
      alignment: "right",
      margin: [0, 0, 0, 5],
      colSpan: 2,
    },
  ]);

  return totalsSection;
}

function getSignaturesSection(
  signatures: ExistingSignature[],
  timezone: string,
) {
  const signaturesSection: any[] = [];

  if (signatures.length !== 0) {
    signaturesSection.push([
      {
        border: [false, true, false, false],
        margin: [0, 5, 0, 0],
        text: "",
        alignment: "right",
      },
      {
        border: [false, true, false, false],
        text: "",
        margin: [0, 0, 0, 0],
        alignment: "right",
      },
    ]);

    signatures.forEach((signature) => {
      const lux = convertFSTimestampToLuxonDT(signature.timestampCreated);
      const formattedDate = lux.setZone(timezone).toFormat("LL/dd/yy hh:mm:a");

      signaturesSection.push([
        {
          text: `${signature.note} on ${formattedDate} `,
          alignment: "right",
        },
        {
          image: `data:image/png;base64, ${signature.imageData} `,
          width: 70,
          alignment: "center",
        },
      ]);
    });
  }

  return signaturesSection;
}

interface PaymentSection {
  paymentDocs: ExistingStiltPayment[];
  currencyType: any;
  uniqueLink: string | undefined;
  timezone: string;
  amountDue: number;
  invoiceMessage?: string;
  invoiceSignatureText?: string;
}

function getPaymentSection({
  amountDue,
  currencyType,
  paymentDocs,
  timezone,
  uniqueLink,
  invoiceMessage,
  invoiceSignatureText,
}: PaymentSection) {
  const paymentSection: any[] = [];

  paymentDocs.sort(
    (a, b) =>
      a.timestampPaymentMade.toMillis() - b.timestampPaymentMade.toMillis(),
  );

  if (paymentDocs.length !== 0) {
    const unauthed: UnauthedPaymentMade = {
      amount: paymentDocs[0].amount,
      timestampPaymentMade: paymentDocs[0].timestampPaymentMade
        .toDate()
        .toISOString(),
      paymentMethod: paymentDocs[0].paymentMethod,
      checkNumber: paymentDocs[0].checkNumber,
      memo: null,
      cardType: paymentDocs[0].cardType,
      lastFour: paymentDocs[0].lastFour,
      nameOnCard: paymentDocs[0].nameOnCard,
      paymentType: paymentDocs[0].paymentType,
    };
    paymentSection.push([
      {
        border: [false, true, false, false],
        text: "Payments",
        alignment: "left",
        margin: [0, 5, 0, 0],
        bold: true,
      },
      {
        border: [false, true, false, false],
        margin: [0, 5, 0, 0],
        text: getPaymentLineText({
          payment: unauthed,
          currencyType: currencyType,
          timezone: timezone,
        }),
        alignment: "right",
      },
    ]);

    paymentDocs.slice(1).forEach((payment) => {
      const unauthed: UnauthedPaymentMade = {
        amount: payment.amount,
        timestampPaymentMade: payment.timestampPaymentMade
          .toDate()
          .toISOString(),
        paymentMethod: payment.paymentMethod,
        checkNumber: payment.checkNumber,
        memo: null,
        cardType: payment.cardType,
        lastFour: payment.lastFour,
        nameOnCard: payment.nameOnCard,
        paymentType: payment.paymentType,
      };
      paymentSection.push([
        {
          text: getPaymentLineText({
            payment: unauthed,
            currencyType: currencyType,
            timezone: timezone,
          }),
          alignment: "right",
          colSpan: 2,
        },
      ]);
    });
  } else {
    paymentSection.push([
      {
        border: [false, true, false, false],
        text: "Payments",
        alignment: "left",
        margin: [0, 5, 0, 0],
        bold: true,
        colSpan: 2,
      },
    ]);
  }

  if (uniqueLink && uniqueLink !== "") {
    paymentSection.push([
      {
        text: "",
        margin: [0, 0, 0, 5],
      },
      {
        text: "Go to Payment Page",
        link: uniqueLink,
        fillColor: "#e6ebf2",
        fontSize: 13,
        alignment: "center",
      },
    ]);
  }

  paymentSection.push([
    {
      text: "",
      margin: [0, 0, 0, 5],
      colSpan: 2,
    },
  ]);

  paymentSection.push(
    [
      {
        border: [false, true, false, false],
        margin: [0, 5, 0, 5],
        text: [
          { text: "Amount Due: ", bold: true },
          `${currencyFormatter(amountDue, currencyType)} `,
        ],
        style: {
          fontSize: 20,
        },
        alignment: "right",
        colSpan: 2,
      },
    ],
    [
      {
        border: [false, false, false, true],
        text: invoiceMessage,
        style: {
          fontSize: 9,
        },
        alignment: "center",
        colSpan: 2,
      },
    ],
    [
      {
        border: [false, false, false, false],
        text: invoiceSignatureText,
        style: {
          fontSize: 8,
        },
        alignment: "left",
        colSpan: 2,
      },
    ],
  );

  return paymentSection;
}

function getPaymentTermsTitle(
  invoicePaymentTerms: string | null,
  templatePaymentTerms: Record<string, TemplatePaymentTerm>,
): string {
  if (invoicePaymentTerms == null) {
    return "N/A";
  } else {
    const templateTerms = templatePaymentTerms[invoicePaymentTerms];
    return templateTerms.title;
  }
}

function generatePhotoGrid(photos: any[]) {
  const size = 3;
  let elements: Record<string, any>[] = [];

  for (const photo of photos) {
    if (photo.base64 !== "") {
      elements.push({
        image: photo.base64,
        width: 170,
      });
    }
  }

  let remainder = 0;
  if (elements.length < size) {
    remainder = size - elements.length;
  } else {
    remainder = size - (elements.length % size);
  }
  const fillArray = Array(remainder).fill({});
  elements = elements.concat(fillArray);
  const rows = new Array(Math.ceil(elements.length / size))
    .fill([{}, {}, {}])
    .map((_) => elements.splice(0, size));
  if (photos.length > 0) {
    return {
      style: "photoGrid",
      layout: "noBorders",
      fillColor: "#FFFFFF",
      table: {
        body: rows,
      },
    };
  } else {
    return [];
  }
}

async function getBase64ImageFromUrl(imageUrl: string) {
  const res = await fetch(imageUrl);
  const blob = await res.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        resolve(reader.result);
      },
      false,
    );

    reader.onerror = () => {
      return reject(reader.error);
    };
    reader.readAsDataURL(blob);
  });
}

function formatWRStatus(
  workRecordStatus: boolean,
  workRecordTSClosed: Timestamp | null,
) {
  if (workRecordStatus) {
    return {
      text: "Open",
      style: {
        fontSize: 16,
        bold: true,
      },
      alignment: "right",
      color: "green",
      margin: [0, 40, 30, 0],
      colSpan: 2,
    };
  } else {
    return {
      text: `Closed on ${convertToReadableTimestampDate(workRecordTSClosed)}`,
      style: {
        fontSize: 16,
        bold: true,
      },
      alignment: "right",
      color: "gray",
      margin: [0, 40, 30, 0],
      colSpan: 2,
    };
  }
}

interface JobBodyArgs {
  estimateList: ExistingEstimate[];
  workRecordInvoiceList: ExistingStiltInvoice[];
  customerDoc: ExistingCustomer;
  fullAddress: string | null;
  currentLocationNotes: ExistingCustomerLocation["notes"];
  actualWorkRecordTasks: ExistingTask[];
  siteKeyDoc: ExistingSiteKey;
  WRDescription: string | null;
  customerLocationMemberships: ExistingMembership[];
  membershipTemplateList: ExistingMembershipTemplate[];
  details: Record<string, any>;
  siteKeyCustomFields: ExistingCustomField[];
  userDisplayNamesMap: Record<string, string>;
  events: ExistingEvent[];
  customerContacts: ExistingCustomerContact[] | null;
}

function jobBodySection({
  customerDoc,
  fullAddress,
  currentLocationNotes,
  estimateList,
  workRecordInvoiceList,
  actualWorkRecordTasks,
  siteKeyDoc,
  WRDescription,
  customerLocationMemberships,
  membershipTemplateList,
  details,
  siteKeyCustomFields,
  userDisplayNamesMap,
  events,
  customerContacts,
}: JobBodyArgs) {
  const approvedEstimateList = [...estimateList].filter(
    (estimate) => estimate.status === EstimateStatus.APPROVED,
  );

  const completedTasks = [...actualWorkRecordTasks].filter(
    (task) => task.taskStatus === TaskStatus.COMPLETE,
  );

  const openTasks = [...actualWorkRecordTasks].filter(
    (task) => task.taskStatus < TaskStatus.COMPLETE,
  );

  const closedTasks = [...actualWorkRecordTasks].filter(
    (task) => task.taskStatus >= TaskStatus.COMPLETE,
  );

  let amountDue = 0;
  let amountPaid = 0;

  workRecordInvoiceList.forEach((invoice) => {
    if (invoice.status !== "canceled") {
      amountDue += invoice.amountDue;
      amountPaid += invoice.totalAmount - invoice.amountDue;
    }
  });

  const balanceTagsAndMembershipsStructure = formatBalanceTagsAndMemberships(
    customerDoc,
    siteKeyDoc,
    customerLocationMemberships,
    membershipTemplateList,
  );

  const customerStructure = formatCustomerInfo(
    customerDoc,
    fullAddress,
    currentLocationNotes,
  );

  const workStatusStructure = formatWorkStatus(
    estimateList.length,
    approvedEstimateList.length,
    workRecordInvoiceList,
    amountDue,
    amountPaid,
    openTasks,
    completedTasks,
  );

  const countOpenTasks = openTasks.length;
  const countClosedTasks = closedTasks.length;

  const data = [
    {
      table: {
        headerRows: 1,
        widths: ["60%", "*", "30%"],
        body: [
          [
            {
              text: balanceTagsAndMembershipsStructure,
              fontSize: 12,
              alignment: "left",
              border: [false, false, false, false],
              margin: [0, 0, 0, 10],
              colSpan: 3,
            },
          ],
          [
            {
              text: "Description of Work:",
              style: {
                fontSize: 14,
                bold: true,
              },
              alignment: "center",
              colSpan: 3,
              border: [false, true, false, false],
            },
          ],
          [
            {
              text: WRDescription ?? "N/A",
              fontSize: 12,
              margin: [0, 10],
              colSpan: 3,
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      table: {
        widths: ["60%", "*", "30%"],
        body: [
          [
            {
              text: customerStructure,
              fontSize: 12,
              alignment: "left",
              margin: [0, 10],
              border: [false, true, false, false],
            },
            {
              text: "",
              border: [false, true, false, false],
            },
            {
              text: workStatusStructure,
              fontSize: 12,
              alignment: "left",
              margin: [0, 10],
              border: [false, true, false, false],
            },
          ],
          [
            {
              text: "Job Details:",
              style: {
                fontSize: 14,
                bold: true,
              },
              alignment: "left",
              colSpan: 3,
              border: [false, true, false, false],
              margin: [0, 10, 0, 0],
            },
          ],
          [
            {
              text: formatDetails(
                details,
                siteKeyCustomFields,
                userDisplayNamesMap,
              ),
              fontSize: 12,
              alignment: "left",
              border: [false, false, false, false],
              colSpan: 3,
              margin: [0, 0, 0, 10],
            },
          ],
          [
            {
              text: "Job Events:",
              style: {
                fontSize: 14,
                bold: true,
              },
              alignment: "left",
              colSpan: 3,
              border: [false, true, false, false],
              margin: [0, 10, 0, 0],
            },
          ],
          [
            {
              ...formatEvents(events, userDisplayNamesMap),
              fontSize: 12,
              alignment: "left",
              colSpan: 3,
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      table: {
        widths: ["60%", "*", "30%"],
        body: [
          [
            {
              text: "Tasks",
              style: {
                fontSize: 16,
                bold: true,
              },
              alignment: "left",
              colSpan: 3,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
          ],
          [
            {
              text: `Open: ${countOpenTasks}`,
              style: {
                fontSize: 14,
                bold: true,
              },
              alignment: "left",
              colSpan: 3,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
          ],
          [
            {
              ...formatOpenTasks(
                countOpenTasks,
                openTasks,
                customerContacts,
                siteKeyDoc,
                userDisplayNamesMap,
                events,
              ),
              fontSize: 12,
              alignment: "left",
              colSpan: 3,
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: `Close: ${countClosedTasks}`,
              style: {
                fontSize: 14,
                bold: true,
              },
              alignment: "left",
              colSpan: 3,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
          ],
          [
            {
              ...formatClosedTasks(
                countClosedTasks,
                closedTasks,
                customerContacts,
                siteKeyDoc,
                userDisplayNamesMap,
                events,
              ),
              fontSize: 12,
              alignment: "left",
              colSpan: 3,
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
  ];

  return data;
}

function formatCustomerInfo(
  customerDoc: ExistingCustomer,
  fullAddress: string | null,
  currentLocationNotes: ExistingCustomerLocation["notes"] | null,
) {
  const customerStructure = [
    {
      text: "Customer Info:",
      style: {
        fontSize: 14,
        bold: true,
      },
      alignment: "left",
      border: [false, true, false, false],
    },
    ` \n`,
    {
      text: "Name:",
      style: {
        fontSize: 10,
        color: "gray",
      },
      alignment: "left",
    },
    ` \n`,
    {
      text: customerDoc.name,
    },
    ` \n`,
  ];

  if (typeof customerDoc.notes === "string" && customerDoc.notes !== "") {
    customerStructure.push(
      {
        text: "Notes:",
        style: {
          fontSize: 10,
          color: "gray",
        },
        alignment: "left",
      },
      ` \n`,
      {
        text: customerDoc.notes,
      },
      ` \n`,
    );
  }

  if (customerDoc.email) {
    customerStructure.push(
      {
        text: "Email:",
        style: {
          fontSize: 10,
          color: "gray",
        },
        alignment: "left",
      },
      ` \n`,
      {
        text: customerDoc.email.join(", "),
      },
      ` \n`,
    );
  }

  if (customerDoc.phone) {
    const customerPhone: string =
      customerDoc.phone && customerDoc.phone.length > 0
        ? phoneUtils.display(customerDoc.phone[0])
        : "";

    customerStructure.push(
      {
        text: "Phone:",
        style: {
          fontSize: 10,
          color: "gray",
        },
        alignment: "left",
      },
      ` \n`,
      {
        text: customerPhone,
      },
      ` \n`,
    );
  }

  if (fullAddress) {
    customerStructure.push(
      {
        text: "Full Address:",
        style: {
          fontSize: 10,
          color: "gray",
        },
        alignment: "left",
      },
      ` \n`,
      {
        text: fullAddress,
      },
      ` \n`,
    );
  }

  if (typeof currentLocationNotes === "string" && currentLocationNotes !== "") {
    customerStructure.push(
      {
        text: "Location Notes:",
        style: {
          fontSize: 10,
          color: "gray",
        },
        alignment: "left",
      },
      ` \n`,
      {
        text: currentLocationNotes,
      },
    );
  }

  return customerStructure;
}

function formatWorkStatus(
  estimateListLength: number,
  approvedEstimateListLength: number,
  workRecordInvoiceList: ExistingStiltInvoice[],
  amountDue: number,
  amountPaid: number,
  openTasks: ExistingTask[],
  completedTasks: ExistingTask[],
) {
  const estimatesCreated =
    estimateListLength === 0 || estimateListLength > 1
      ? `${estimateListLength} estimates created`
      : `${estimateListLength} estimate created`;

  const estimateApproved =
    approvedEstimateListLength === 0 || approvedEstimateListLength > 1
      ? `${approvedEstimateListLength} estimates approved`
      : `${approvedEstimateListLength} estimate approved`;

  const numOpenTasks = getJobDescription(openTasks, completedTasks);

  const numInvoices = getInvoiceSectionString(workRecordInvoiceList);

  const amountDueAndPaid = `${DollarCurrency.format(
    amountDue,
  )} due / ${DollarCurrency.format(amountPaid)} paid`;

  const workStatusStructure = [
    {
      text: "Work Status:",
      style: {
        fontSize: 14,
        bold: true,
      },
      alignment: "left",
    },
    ` \n`,
    {
      text: estimatesCreated,
    },
    ` \n`,
    {
      text: estimateApproved,
    },
    ` \n`,
    {
      text: numOpenTasks,
    },
    ` \n`,
    {
      text: numInvoices,
    },
    ` \n`,
    {
      text: amountDueAndPaid,
    },
  ];
  return workStatusStructure;
}

function formatBalanceTagsAndMemberships(
  customerDoc: ExistingCustomer,
  siteKeyDoc: ExistingSiteKey,
  customerLocationMemberships: ExistingMembership[],
  membershipTemplateList: ExistingMembershipTemplate[],
) {
  const balanceTagsAndMembershipsStructure: any[] = [];

  if (customerDoc.tags.length > 0) {
    balanceTagsAndMembershipsStructure.push(
      {
        text: "Customer Tags: ",
        style: {
          fontSize: 14,
          bold: true,
        },
        alignment: "left",
      },
      {
        text: `${customerDoc.tags.join(", ")}`,
        fontSize: 12,
      },
      ` \n`,
    );
  }

  if (typeof customerDoc.balance === "number" && customerDoc.balance !== 0) {
    const balanceString = getCustomerBalanceString(customerDoc, siteKeyDoc);

    if (!balanceString) return undefined;

    balanceTagsAndMembershipsStructure.push(
      {
        text: "Balance: ",
        style: {
          fontSize: 14,
          bold: true,
        },
        alignment: "left",
      },
      {
        text: balanceString,
        fontSize: 12,
      },
      ` \n`,
    );
  }

  if (customerLocationMemberships.length > 0) {
    balanceTagsAndMembershipsStructure.push(
      {
        text: "Memberships: ",
        style: {
          fontSize: 14,
          bold: true,
        },
        alignment: "left",
      },
      `\n`,
    );

    customerLocationMemberships.forEach((membership) => {
      const template = membershipTemplateList.find(
        (template) => template.id === membership.membershipTemplateID,
      );

      if (template) {
        const startDate = convertToReadableTimestampDate(
          membership.membershipStartDate,
        );
        const endDate = membership.membershipEndDate
          ? ` to ${convertToReadableTimestampDate(membership.membershipEndDate)}`
          : ` to - -`;

        balanceTagsAndMembershipsStructure.push(
          {
            text: `${template.title} - (${getReadableMembershipStatus(membership.status)}) - ${startDate} ${endDate}`,
            fontSize: 12,
          },
          `\n`,
        );
      }
    });
  }

  return balanceTagsAndMembershipsStructure;
}

function formatDetails(
  details: Record<string, any>,
  siteKeyCustomFields: ExistingCustomField[],
  userDisplayNamesMap: Record<string, string>,
) {
  const detailsStructure: any[] = [];

  const withAnswerList: ECFandAnswer[] = [];
  Object.entries(details).forEach(([detailID, answer]) => {
    const foundCF = siteKeyCustomFields.find((cf) => cf.id === detailID);

    if (foundCF) {
      // Convert timestamp fields to Luxon DateTimes. (Unless the value is null).
      // Can then convert it to a local timezone easily when we're displaying it.
      if (foundCF.fieldType === "timestamp" && answer != null) {
        const luxonAnswer = convertFSTimestampToLuxonDT(answer);
        withAnswerList.push({ ...foundCF, answer: luxonAnswer });
      } else {
        withAnswerList.push({ ...foundCF, answer });
      }
    }
  });

  const allAnswersAreNull = withAnswerList.every((cf) => cf.answer == null);

  if (withAnswerList.length === 0) {
    detailsStructure.push({
      text: `${strings.NO_SPECIFIC_DETAILS}`,
    });
  } else if (allAnswersAreNull) {
    detailsStructure.push({
      text: `${strings.NO_SPECIFIC_DETAILS}`,
    });
  } else {
    withAnswerList.forEach((cf) => {
      if (cf.answer == null) {
        return null;
      }

      if (cf.answer == cf.defaultValue) {
        return null;
      }

      if (typeof cf.answer === "string") {
        if (cf.answer.length === 0) {
          return null;
        }
      }

      const type = cf.fieldType;
      switch (type) {
        case "string":
        case "string-textarea":
        case "number":
          return detailsStructure.push(
            {
              text: `${cf.title}`,
              style: {
                fontSize: 10,
                color: "gray",
              },
              alignment: "left",
            },
            ` \n`,
            {
              text: `${cf.answer}`,
            },
            ` \n`,
          );
        case "selection":
          // in this case, `answer` is the selectionOptions KEY.
          // need to display the value.
          const value = cf.selectionOptions[cf.answer];
          return detailsStructure.push(
            {
              text: `${cf.title}`,
              style: {
                fontSize: 10,
                color: "gray",
              },
              alignment: "left",
            },
            ` \n`,
            {
              text: `${value}`,
            },
            ` \n`,
          );

        case "currency":
          return detailsStructure.push(
            {
              text: `${cf.title}`,
              style: {
                fontSize: 10,
                color: "gray",
              },
              alignment: "left",
            },
            ` \n`,
            {
              text: `$${cf.answer}`,
            },
            ` \n`,
          );

        case "bool":
          return detailsStructure.push(
            {
              text: `${cf.title}`,
              style: {
                fontSize: 10,
                color: "gray",
              },
              alignment: "left",
            },
            ` \n`,
            {
              text: `${cf.answer ? "Yes" : "No"}`,
            },
            ` \n`,
          );

        case "timestamp":
          if (cf.answer instanceof DateTime) {
            return detailsStructure.push(
              {
                text: `${cf.title}`,
                style: {
                  fontSize: 10,
                  color: "gray",
                },
                alignment: "left",
              },
              ` \n`,
              {
                text: `${cf.answer.toLocal().toFormat("LL/dd/yy hh:mm a")}`,
              },
              ` \n`,
            );
          } else {
            return null;
          }

        case "uid":
          return detailsStructure.push(
            {
              text: `${cf.title}`,
              style: {
                fontSize: 10,
                color: "gray",
              },
              alignment: "left",
            },
            ` \n`,
            {
              text: `${userDisplayNamesMap[cf.answer]}`,
            },
            ` \n`,
          );

        case "multiple-uid":
          if (cf.answer.length === 0) return null;
          const userList: string[] = cf.answer.map(
            (uid: string) => userDisplayNamesMap[uid],
          );

          return detailsStructure.push(
            {
              text: `${cf.title}`,
              style: {
                fontSize: 10,
                color: "gray",
              },
              alignment: "left",
            },
            ` \n`,
            {
              text: `${userList.join(", ")}`,
            },
            ` \n`,
          );

        case "string-array":
          if (cf.answer.length === 0) return null;

          return detailsStructure.push(
            {
              text: `${cf.title}`,
              style: {
                fontSize: 10,
                color: "gray",
              },
              alignment: "left",
            },
            ` \n`,
            {
              text: `${cf.answer.join(", ")}`,
            },
            ` \n`,
          );

        case "hours-minutes":
          if (typeof cf.answer !== "number") return null;
          const { hours, minutes } = convertDecimalToHoursMinutes(cf.answer);

          return detailsStructure.push(
            {
              text: `${cf.title}`,
              style: {
                fontSize: 10,
                color: "gray",
              },
              alignment: "left",
            },
            ` \n`,
            {
              text: `${hours}:${minutes}`,
            },
            ` \n`,
          );

        default:
          const exhaustivenessCheck: never = type;
          return exhaustivenessCheck;
      }
    });
  }

  return detailsStructure;
}

function formatEvents(
  events: ExistingEvent[],
  userDisplayNamesMap: Record<string, string>,
) {
  // const size = 3;

  const eventsStructure: any[] = [];

  events
    .sort(
      (a, b) => a.timestampCreated.toMillis() - b.timestampCreated.toMillis(),
    )
    .forEach((event) => {
      const displayName = userDisplayNamesMap[event.createdBy];

      eventsStructure.push([
        {
          text: `${getEventTypeString(event.eventType, displayName)}`,
          style: {
            fontSize: 12,
            bold: true,
          },
          alignment: "left",
        },
        {
          text: `${event.title}`,
          fontSize: 10,
        },
        {
          text: `${convertToReadableTimestamp(event.timestampCreated)}`,
          color: "gray",
          fontSize: 10,
        },
        {
          text: `${displayName}`,
          color: "gray",
          fontSize: 10,
        },
      ]);
    });

  // let remainder = 0;
  // if (eventsStructure.length < size) {
  //   remainder = size - eventsStructure.length;
  // } else {
  //   remainder = size - (eventsStructure.length % size);
  // }
  // const fillArray = Array(remainder).fill({
  //   text: "",
  //   border: [false, false, false, false],
  // });
  // eventsStructure = eventsStructure.concat(fillArray);
  // const rows = new Array(Math.ceil(eventsStructure.length / size))
  //   .fill([{}, {}, {}])
  //   .map((_) => eventsStructure.splice(0, size));

  if (events.length > 0) {
    return {
      fillColor: "#FFFFFF",
      table: {
        body: eventsStructure,
      },
    };
  } else {
    return [];
  }
}

function formatOpenTasks(
  countOpenTasks: number,
  openTasks: ExistingTask[],
  customerContacts: ExistingCustomerContact[] | null,
  siteKeyDoc: ExistingSiteKey,
  userDisplayNamesMap: Record<string, string>,
  events: ExistingEvent[],
) {
  const openTasksStructure: any[] = [];

  openTasks.forEach((task) => {
    let taskCustomFields: ExistingCustomField[] = [];
    const targetWorkType = task.craftType;
    const targetTaskType = task.taskType;
    if (isValidCraftType(targetWorkType) && isValidTaskType(targetTaskType)) {
      taskCustomFields = getTaskCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
        targetTaskType,
      });
    } else {
      taskCustomFields = [];
    }

    const primaryContactPhone = customerContacts?.find(
      (contact) =>
        contact.type !== "email" && contact.id === task.primaryContactPhone,
    )?.value;

    const eventList = events
      .filter((event) => event.taskID === task.id)
      .sort(
        (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
      );

    const singleOpenTask: any[] = [
      {
        text: `${task.title}`,
        style: {
          fontSize: 12,
          bold: true,
        },
        alignment: "left",
      },
      {
        text: `${getReadableTaskStatus(task.taskStatus)}`,
        fontSize: 10,
      },
    ];

    if (task.urgent) {
      singleOpenTask.push({
        text: `${strings.URGENT}`,
        color: "red",
      });
    }

    if (task.timestampScheduled) {
      singleOpenTask.push({
        text: `Scheduled for ${convertToReadableTimestamp(task.timestampScheduled)}`,
        color: "gray",
        fontSize: 10,
      });
    }

    if (primaryContactPhone) {
      singleOpenTask.push({
        text: `Primary Contact Phone: ${phoneUtils.display(primaryContactPhone)}`,
        color: "gray",
        fontSize: 10,
      });
    }

    if (task.description) {
      singleOpenTask.push({
        text: `${strings.ADDITIONAL_NOTES}: ${task.description}`,
        fontSize: 10,
      });
    }

    singleOpenTask.push(
      [
        {
          text: "Details:",
          style: {
            fontSize: 14,
            bold: true,
          },
          alignment: "left",
          border: [false, true, false, false],
          margin: [0, 10, 0, 0],
        },
      ],
      [
        {
          text: formatDetails(
            task.taskSpecificDetails,
            taskCustomFields,
            userDisplayNamesMap,
          ),
          fontSize: 12,
          alignment: "left",
          border: [false, false, false, false],
          margin: [0, 0, 0, 10],
        },
      ],
      [
        {
          text: "Events:",
          style: {
            fontSize: 14,
            bold: true,
          },
          alignment: "left",
          colSpan: 3,
          border: [false, true, false, false],
          margin: [0, 10, 0, 0],
        },
      ],
      [
        {
          ...formatEvents(eventList, userDisplayNamesMap),
          fontSize: 12,
          alignment: "left",
          colSpan: 3,
          border: [false, false, false, false],
        },
      ],
    );

    openTasksStructure.push([...singleOpenTask]);
  });

  if (countOpenTasks > 0) {
    return {
      fillColor: "#FFFFFF",
      table: {
        widths: ["*"],
        body: [[...openTasksStructure]],
      },
    };
  } else {
    return {
      text: `${strings.NO_OPEN_TASKS}`,
    };
  }
}

function formatClosedTasks(
  countClosedTasks: number,
  closedTasks: ExistingTask[],
  customerContacts: ExistingCustomerContact[] | null,
  siteKeyDoc: ExistingSiteKey,
  userDisplayNamesMap: Record<string, string>,
  events: ExistingEvent[],
) {
  const closedTasksStructure: any[] = [];

  closedTasks.forEach((task) => {
    let taskCustomFields: ExistingCustomField[] = [];
    const targetWorkType = task.craftType;
    const targetTaskType = task.taskType;
    if (isValidCraftType(targetWorkType) && isValidTaskType(targetTaskType)) {
      taskCustomFields = getTaskCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
        targetTaskType,
      });
    } else {
      taskCustomFields = [];
    }

    const primaryContactPhone = customerContacts?.find(
      (contact) =>
        contact.type !== "email" && contact.id === task.primaryContactPhone,
    )?.value;

    const eventList = events
      .filter((event) => event.taskID === task.id)
      .sort(
        (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
      );

    const singleClosedTask: any[] = [
      {
        text: `${task.title}`,
        style: {
          fontSize: 12,
          bold: true,
        },
        alignment: "left",
      },
      {
        text: `${getReadableTaskStatus(task.taskStatus)}`,
        fontSize: 10,
      },
    ];

    if (task.urgent) {
      singleClosedTask.push({
        text: `${strings.URGENT}`,
        color: "red",
      });
    }

    if (task.timestampScheduled) {
      singleClosedTask.push({
        text: `Scheduled for ${convertToReadableTimestamp(task.timestampScheduled)}`,
        color: "gray",
        fontSize: 10,
      });
    }

    if (task.timestampTaskCompleted) {
      singleClosedTask.push({
        text: `Completed on ${convertToReadableTimestamp(task.timestampTaskCompleted)}`,
        color: "gray",
        fontSize: 10,
      });
    }

    if (primaryContactPhone) {
      singleClosedTask.push({
        text: `Primary Contact Phone: ${phoneUtils.display(primaryContactPhone)}`,
        color: "gray",
        fontSize: 10,
      });
    }

    if (task.description) {
      singleClosedTask.push({
        text: `${strings.ADDITIONAL_NOTES}: ${task.description}`,
        fontSize: 10,
      });
    }

    singleClosedTask.push(
      [
        {
          text: "Details:",
          style: {
            fontSize: 14,
            bold: true,
          },
          alignment: "left",
          border: [false, true, false, false],
          margin: [0, 10, 0, 0],
        },
      ],
      [
        {
          text: formatDetails(
            task.taskSpecificDetails,
            taskCustomFields,
            userDisplayNamesMap,
          ),
          fontSize: 12,
          alignment: "left",
          border: [false, false, false, false],
          margin: [0, 0, 0, 10],
        },
      ],
      [
        {
          text: "Events:",
          style: {
            fontSize: 14,
            bold: true,
          },
          alignment: "left",
          colSpan: 3,
          border: [false, true, false, false],
          margin: [0, 10, 0, 0],
        },
      ],
      [
        {
          ...formatEvents(eventList, userDisplayNamesMap),
          fontSize: 12,
          alignment: "left",
          colSpan: 3,
          border: [false, false, false, false],
        },
      ],
    );

    closedTasksStructure.push([...singleClosedTask]);
  });

  if (countClosedTasks > 0) {
    return {
      fillColor: "#FFFFFF",
      table: {
        widths: ["*"],
        body: [[...closedTasksStructure]],
      },
    };
  } else {
    return {
      text: `${strings.NO_CLOSED_TASKS}`,
    };
  }
}
