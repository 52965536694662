//Libs
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

//Local

const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  padding: "6px",
};

type JobColors = {
  generalBg: string;
  iconBg: string;
};
export interface JobCardStructure {
  subDescription?: string;
  jobDescription: string;
  colors: JobColors;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, any>, "svg">> & {
    muiName: string;
  };
  onClick?: () => void;
}

interface Props {
  job: JobCardStructure;
  onClick?: () => void;
}

export default function JobCard(props: Props) {
  return (
    <div
      onClick={props.onClick}
      className={`grid w-56 grid-cols-[1fr,3fr] gap-4 rounded-md p-2 ${
        props.job.colors.generalBg
      } items-center ${props.onClick ? "cursor-pointer" : ""}`}
    >
      <span
        className={`rounded-md p-1 ${props.job.colors.iconBg} h-fit max-w-fit`}
      >
        <props.job.icon aria-hidden="true" style={styles} />
      </span>
      <div className="flex flex-col capitalize">
        <span className="whitespace-pre-wrap pr-1 font-bold text-gray-800">
          {props.job.jobDescription}
        </span>
        {props.job.subDescription && (
          <span className="w-full whitespace-pre-wrap text-sm text-gray-600">
            {props.job.subDescription}
          </span>
        )}
      </div>
    </div>
  );
}
