//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Fragment } from "react";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { ExistingCustomer } from "../../models/customer";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { CustomerDetails } from "../estimates/CustomerDetails";
import { BiRightArrowAlt } from "react-icons/bi";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedCustomer: ExistingCustomer | null;
  mergeToCustomer: ExistingCustomer;
  setSelectCustomerDialogOpen: () => void;
  actionButtons: React.ReactNode;
  children: {
    SelectCustomerDialog: React.ReactNode;
  };
}

export default function MergeCustomerDialog({
  isOpen,
  onClose,
  selectedCustomer,
  mergeToCustomer,
  actionButtons,
  ...props
}: Props) {
  const mergeCustomerHeader = (
    <div className="flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold capitalize">
        {strings.buttons.MERGE_CUSTOMER}
      </h1>
      <button onClick={() => onClose()}>
        <XMarkIcon aria-label="close company form" className="h-6 text-white" />
      </button>
    </div>
  );

  return (
    <Fragment>
      <BaseModal
        open={isOpen}
        closeModal={() => {}}
        allowOverflowY={true}
        title={mergeCustomerHeader}
        parentDivStyles="inline-block transform overflow-hidden  max-w-screen-md rounded-lg bg-white text-left shadow-xl transition-all"
      >
        <div className="flex flex-col space-y-8 p-8 text-lg">
          <div className="text-lg font-light text-gray-700">
            Select a customer to merge into this one.
          </div>
          <BaseButtonSecondary
            type="button"
            onClick={props.setSelectCustomerDialogOpen}
            className="w-full text-primary xs:w-auto"
          >
            <CheckCircle fontSize="small" className="mr-2" />
            {strings.SELECT_CUSTOMER}
          </BaseButtonSecondary>
          {selectedCustomer && (
            <Fragment>
              <div className="text-lg font-light text-gray-700">
                The following customer will be merged into{" "}
                <span className="text-xl font-extrabold text-primary">
                  {mergeToCustomer.name}
                </span>{" "}
                including all locations, tasks, estimates, invoices, and
                payments. Customer notes and location notes will not be merged.
                If you need to keep any customer or location notes, please copy
                those manually before merging.
              </div>
              <div className="flex flex-row items-center justify-between">
                <CustomerDetails
                  customer={selectedCustomer}
                  openEditCustomerDialog={null}
                  openCallDialog={null}
                />
                <BiRightArrowAlt className="h-9 w-9"></BiRightArrowAlt>
                <CustomerDetails
                  customer={mergeToCustomer}
                  openEditCustomerDialog={null}
                  openCallDialog={null}
                />
              </div>
            </Fragment>
          )}
          {actionButtons}
        </div>
        {props.children.SelectCustomerDialog}
      </BaseModal>
    </Fragment>
  );
}
