//Libs

//Local
import { ExistingCustomerLocation } from "../../../models/customer-location";
import { ExistingTaskWithCustomerLocation, Task } from "../../../models/task";
import { ExistingZone } from "../../../models/zone";

export function getDotColor(
  task: ExistingTaskWithCustomerLocation,
  zoneList: ExistingZone[],
): string {
  let dotColor = "";
  // let dotColor = "#eeeeee";

  if (zoneList.length === 0) return dotColor;
  zoneList.forEach((zone) => {
    if (zone.element === "background") return;

    if (zone.property === "zipCode") {
      const taskZipCode = task.customerLocation?.zipCode;

      if (typeof taskZipCode !== "string") return;

      if (zone.values.includes(taskZipCode)) {
        dotColor = zone.color;
      }
    } else {
      zone.values.forEach((zoneValue) => {
        const lowerCaseValue = zoneValue.toLowerCase();
        const lowerCaseTaskTitle = task.title.toLowerCase();
        if ((lowerCaseTaskTitle as string).includes(lowerCaseValue)) {
          dotColor = zone.color;
        }
      });
    }
  });
  return dotColor;
}

/** Get dot element for the current task. Displayed next to the show/hide calendar button */
export function getDotColorWIPTask(
  taskTitle: Task["title"],
  customerLocation: ExistingCustomerLocation | null,
  zoneList: ExistingZone[],
) {
  let dotColor = "";
  // let dotColor = `eeeeee`;

  if (zoneList.length === 0) return undefined;

  zoneList.forEach((zone) => {
    if (zone.element === "background") return;

    if (zone.property === "zipCode" && customerLocation) {
      const taskZipCode = customerLocation?.zipCode;

      if (typeof taskZipCode !== "string") return;

      if (zone.values.includes(taskZipCode)) {
        dotColor = zone.color;
      }
    } else {
      zone.values.forEach((zoneValue) => {
        const lowerCaseValue = zoneValue.toLowerCase();
        const lowerCaseTaskTitle = taskTitle.toLowerCase();
        if ((lowerCaseTaskTitle as string).includes(lowerCaseValue)) {
          dotColor = zone.color;
        }
      });
    }
  });

  return (
    <div
      className={`h-5 w-5 rounded-full`}
      style={{ backgroundColor: `${dotColor}` }}
    />
  );
}
