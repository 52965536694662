// Libs
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, MouseEventHandler, useRef } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import Gallery from "react-image-gallery";

// Separate imports allow for smaller bundle size.
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import FullscreenExitRoundedIcon from "@mui/icons-material/FullscreenExitRounded";

// Local
import { ExistingChecklistResponse } from "../models/checklist-response";

const ImageGallery = (Gallery as any).default ?? Gallery;

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  photoList: Array<{ original: string; thumbnail: string }>;
  itemMainText: ExistingChecklistResponse["mainText"];
  thumbnailIndex: number;
}

// #region Custom Buttons
const sharedButtonStyles =
  "absolute z-30 rounded-full bg-gray-700/70 text-gray-300 transition hover:bg-gray-800/90 focus:ring-primaryLight focus:ring-offset-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2";

function renderLeftArrow(
  onClick: MouseEventHandler<HTMLButtonElement>,
  disabled: boolean,
): JSX.Element {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${sharedButtonStyles} left-3 top-1/2 -translate-y-1/2`}
    >
      <ChevronLeftIcon aria-label="Previous Slide" className="h-12 w-12" />
    </button>
  );
}

function renderRightArrow(
  onClick: MouseEventHandler<HTMLButtonElement>,
  disabled: boolean,
): JSX.Element {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${sharedButtonStyles} right-3 top-1/2 -translate-y-1/2`}
    >
      <ChevronRightIcon aria-label="Next Slide" className="h-12 w-12" />
    </button>
  );
}

function renderFullscreenBtn(
  onClick: MouseEventHandler<HTMLButtonElement>,
  isFullscreen: boolean,
): JSX.Element {
  const ariaText = !isFullscreen ? "Open Fullscreen" : "Close Fullscreen";

  return (
    <button
      onClick={onClick}
      aria-label={ariaText}
      className={`${sharedButtonStyles} bottom-3 right-3 p-1`}
    >
      {!isFullscreen ? (
        <FullscreenRoundedIcon
          aria-label="expand to fullscreen"
          sx={{ fontSize: 40 }}
        />
      ) : (
        <FullscreenExitRoundedIcon
          aria-label="exit fullscreen mode"
          sx={{ fontSize: 40 }}
        />
      )}
    </button>
  );
}
// #endregion

export default function ResponseReducedGallery({
  isDialogOpen,
  closeDialog,
  photoList,
  itemMainText,
  thumbnailIndex,
}: Props) {
  const closeBtnRef = useRef(null);

  return (
    <Fragment>
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-20 overflow-y-auto"
          onClose={closeDialog}
          initialFocus={closeBtnRef}
        >
          <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-filter" />
          <div className="p-4 text-left sm:text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block max-h-fit w-full max-w-screen-xl transform overflow-x-hidden rounded-lg border border-gray-300 bg-white align-middle shadow-sm transition-all">
                <div className="relative">
                  <Dialog.Title
                    as="h3"
                    className="ml-3 mr-10 py-2 text-xl font-light sm:mx-10"
                  >
                    {itemMainText}
                  </Dialog.Title>
                  <button
                    ref={closeBtnRef}
                    className="absolute right-1 top-1 rounded-sm p-1 focus:outline-none focus:ring-2 focus:ring-primaryLight"
                  >
                    <XMarkIcon
                      aria-label="close response photo gallery"
                      className="h-7 w-7"
                      onClick={closeDialog}
                    />
                  </button>
                </div>
                <ImageGallery
                  items={photoList}
                  // infinite={false}
                  showPlayButton={false}
                  onErrorImageURL="Could not load image."
                  startIndex={thumbnailIndex}
                  showIndex={true}
                  renderLeftNav={renderLeftArrow}
                  renderRightNav={renderRightArrow}
                  renderFullscreenButton={renderFullscreenBtn}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
}
