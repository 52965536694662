import { CameraIcon } from "@heroicons/react/24/solid";
import React from "react";

const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

type PhotoProps = {
  showPhotoNotification: boolean;
} & React.ComponentPropsWithRef<"button">;

export const PhotoIconWithRef = React.memo(
  React.forwardRef<HTMLButtonElement, PhotoProps>(
    ({ showPhotoNotification, ...props }, ref) => {
      return (
        <button
          ref={ref}
          {...props}
          className="relative ml-1 rounded-full text-gray-700 hover:bg-primaryOpacity90"
        >
          <CameraIcon aria-label="photo button" style={styles} />
          {showPhotoNotification ? (
            <span className="absolute right-1 top-1.5 h-2.5 w-2.5 rounded-full bg-amber-600 " />
          ) : null}
        </button>
      );
    },
  ),
);
