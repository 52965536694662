//Libs
import { useState, useEffect } from "react";
import { User } from "firebase/auth";

//Local
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import InventoryTransactionListPage from "./InventoryTransactionListPage";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import { useSiteKeyCompaniesStore } from "../../store/site-key-companies";
import AddInventoryTransactionDialog from "../../components/inventory/AddInventoryTransactionDialog";
import {
  ExistingInventoryObject,
  ExistingInventoryObjectForTable,
} from "../../models/inventory-object";
import SelectInventoryObject from "../../components/inventory/SelectInventoryObject";
import {
  InventoryTransactionManager,
  OInventoryTransactionTypes,
  InventoryTransactionValues,
  InventoryTransaction,
  ExistingInventoryTransaction,
} from "../../models/inventory-transaction";
import DropdownInventoryTransactionTypes from "../../components/inventory/DropdownInventoryTransactionTypes";
import { useAuthStore } from "../../store/firebase-auth";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { ExistingInventoryLocation } from "../../models/inventory-location";

interface Props {
  hideTitle?: boolean;
  siteKey: string;
  parentRecordID: string | null;
  taskID: string | null;
  invoiceID: string | null;
}

export default function InventoryTransactionListContainer({
  siteKey,
  taskID,
  parentRecordID,
  invoiceID,
  hideTitle,
}: Props) {
  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );
  const listOfCompanies: ExistingSiteKeyCompany[] = useSiteKeyCompaniesStore(
    (state) => state.siteKeyCompanies,
  );
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  /* STATES */
  const [
    addInventoryTransactionDialogOpen,
    setAddInventoryTransactionDialogOpen,
  ] = useState(false);
  const [selectInventoryObjectDialogOpen, setSelectInventoryObjectDialogOpen] =
    useState(false);
  const [inventoryTransactionList, setInventoryTransactionList] = useState<
    InventoryTransaction[]
  >([]);
  const [isBusy, setIsBusy] = useState(false);

  // inventoryLocations useState
  const [inventoryLocations, setInventoryLocations] = useState<
    ExistingInventoryLocation[]
  >([]);
  const [inventoryObjects, setInventoryObjects] = useState<
    ExistingInventoryObject[]
  >([]);
  const [inventoryTransactions, setInventoryTransactions] = useState<
    ExistingInventoryTransaction[]
  >([]);

  useEffect(() => {
    async function getInventoryLocations() {
      const unsubscribe = DbRead.inventoryLocations.subscribeAll({
        siteKey,
        onChange: (locations) => setInventoryLocations(locations),
        onError: (e) => {
          logger.error("Error subscribing to inventory locations", e);
        },
      });
      return unsubscribe;
    }
    const unsubscribePromise = getInventoryLocations();
    return () => {
      unsubscribePromise.then((unsub) => unsub());
    };
  }, [siteKey]);

  useEffect(() => {
    async function getInventoryObjects() {
      const unsubscribe = DbRead.inventoryObjects.subscribeAll({
        siteKey,
        onChange: (objects) => setInventoryObjects(objects),
        onError: (e) => {
          logger.error("Error subscribing to inventory objects", e);
        },
      });
      return unsubscribe;
    }
    const unsubscribePromise = getInventoryObjects();
    return () => {
      unsubscribePromise.then((unsub) => unsub());
    };
  }, [siteKey]);

  useEffect(() => {
    async function getInventoryTransactions() {
      const unsubscribe = DbRead.inventoryTransactions.subscribe({
        siteKey,
        taskID: taskID,
        parentRecordID: parentRecordID,
        invoiceID: invoiceID,
        onChange: (objects) => {
          setInventoryTransactions(objects);
        },
      });
      return unsubscribe;
    }
    const unsubscribePromise = getInventoryTransactions();
    return () => {
      unsubscribePromise.then((unsub) => unsub());
    };
  }, [invoiceID, parentRecordID, siteKey, taskID]);

  const inventoryObjectTableData: ExistingInventoryObjectForTable[] = [];
  inventoryObjects.forEach((inventoryObject) => {
    if (Object.keys(inventoryObject.quantities).length === 0) {
      const obj = {
        id: inventoryObject.id,
        refPath: inventoryObject.refPath,
        title: inventoryObject.title,
        status: inventoryObject.status,
        inventoryLocationID: "",
        available: 0,
        reserved: 0,
        inUse: 0,
        awaitingPickup: 0,
        lowQuantityThreshold: 0,
      };
      inventoryObjectTableData.push(obj);
    } else {
      const quantityObjList: ExistingInventoryObjectForTable[] = Object.entries(
        inventoryObject.quantities,
      ).map(([key, value]) => {
        return {
          id: inventoryObject.id,
          refPath: inventoryObject.refPath,
          title: inventoryObject.title,
          status: inventoryObject.status,
          inventoryLocationID: key,
          available: value.available ?? 0,
          reserved: value.reserved ?? 0,
          inUse: value.inUse ?? 0,
          awaitingPickup: value.awaitingPickup ?? 0,
          lowQuantityThreshold: value.lowQuantityThreshold ?? 0,
        };
      });
      inventoryObjectTableData.push(...quantityObjList);
    }
  });

  /* FUNCTIONS */
  async function handleSaveNewInventoryTransaction() {
    if (!siteKeyDoc) return;
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (inventoryTransactionList.length === 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_INVENTORY_TRANSACTION,
        dialog: false,
        type: "error",
      });
      return;
    }

    setIsBusy(true);
    let validatedInventoryTransaction = [];
    const newInventoryTransactionList: InventoryTransaction[] =
      inventoryTransactionList.map((inventoryTransaction) => {
        const data = {
          ...inventoryTransaction, // remove title and inventoryLocationID?
          companyID: siteKeyDoc.managingCompanyID,
          parentRecordID: parentRecordID,
          taskID: taskID,
          createdBy: firebaseUser.uid,
        };
        if (invoiceID) {
          data.invoiceID = invoiceID;
        }
        return data;
      });
    validatedInventoryTransaction = newInventoryTransactionList.map(
      (inventoryTransaction) =>
        InventoryTransactionManager.parse(inventoryTransaction),
    );
    logger.info(
      "Validated inventory transaction list:",
      validatedInventoryTransaction,
    );

    //DB
    try {
      //TODO: add the mutation here
      console.log(
        "validatedInventoryTransaction",
        validatedInventoryTransaction,
      );
      await DbWrite.inventoryTransactions.createMultiple(
        siteKey,
        validatedInventoryTransaction,
      );
    } catch (error) {
      logger.error(
        `An error occurred during handleSaveNewInventoryTransaction`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
    setIsBusy(false);
  }

  function handleSaveInventoryTransaction(
    inventoryTransaction: InventoryTransaction[],
  ) {
    const uniqueInventoryTransactions = inventoryTransaction.reduce(
      (acc, item) => {
        !acc.find(
          (val) =>
            val.inventoryObjectID === item.inventoryObjectID &&
            val.inventoryLocationID === item.inventoryLocationID,
        ) && acc.push(item);
        return acc;
      },
      [] as InventoryTransaction[],
    );

    const filteredInventoryTransactions = uniqueInventoryTransactions.filter(
      (item) => item.value !== 0,
    );

    setInventoryTransactionList(filteredInventoryTransactions);
  }

  function handleTransactionType(
    inventoryTransactionType: InventoryTransactionValues,
    currentInventoryTransaction: InventoryTransaction,
  ) {
    const indexOfInventoryTransaction = inventoryTransactionList.findIndex(
      (inventoryTransaction) =>
        inventoryTransaction.inventoryObjectID ===
          currentInventoryTransaction.inventoryObjectID &&
        inventoryTransaction.inventoryLocationID ===
          currentInventoryTransaction.inventoryLocationID,
    );

    if (indexOfInventoryTransaction >= 0) {
      setInventoryTransactionList((oldInventoryTransactionList) => {
        oldInventoryTransactionList[indexOfInventoryTransaction].type =
          inventoryTransactionType;
        return [...oldInventoryTransactionList];
      });
    }
  }

  /* COMPONENTS */
  const selectInventoryObject = (
    <SelectInventoryObject
      inventoryObjectTableData={inventoryObjectTableData}
      inventoryLocationList={inventoryLocations}
      handleSaveInventoryTransaction={handleSaveInventoryTransaction}
      inventoryTransactionList={inventoryTransactionList}
      setInventoryTransactionList={setInventoryTransactionList}
      isDialogOpen={selectInventoryObjectDialogOpen}
      closeDialog={() => setSelectInventoryObjectDialogOpen(false)}
    />
  );

  const inventoryTransactionCardsSection =
    inventoryTransactionList != null
      ? inventoryTransactionList.map((inventoryTransaction, idx) => {
          const inventoryObject = inventoryObjects.find(
            (inventoryObject) =>
              inventoryObject.id === inventoryTransaction.inventoryObjectID,
          );
          const currentLocation = inventoryLocations.find(
            (inventoryLocation) =>
              inventoryLocation.id === inventoryTransaction.inventoryLocationID,
          );
          return (
            <div
              key={idx}
              className="grid grid-cols-2 rounded-md border px-4 py-3"
            >
              <div className="flex flex-col">
                <span className="text-greenPass">
                  {inventoryObject?.title ?? "--"}
                </span>
                {currentLocation ? (
                  <span className="text-sm text-cyan-500">
                    Location: {currentLocation?.title}
                  </span>
                ) : null}
              </div>
              <div className="justify-self-end">
                <div className="flex flex-row items-center justify-end space-x-4">
                  <span>Qty: {inventoryTransaction.value}</span>
                  <DropdownInventoryTransactionTypes
                    initialInventoryTransactionType={inventoryTransaction.type}
                    inventoryTransactionTypes={[
                      ...Object.values(OInventoryTransactionTypes),
                    ]}
                    onSelectionInventoryTransactionType={(
                      inventoryTransactionType,
                    ) =>
                      handleTransactionType(
                        inventoryTransactionType,
                        inventoryTransaction,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          );
        })
      : null;

  const actionButtons = (
    <div className="flex flex-col justify-between gap-4 sm:flex-row-reverse">
      <BaseButtonPrimary
        type="button"
        onClick={() => {
          setAddInventoryTransactionDialogOpen(false);
          handleSaveNewInventoryTransaction();
        }}
        className="w-full text-primary sm:max-w-xs"
        isBusy={isBusy}
        busyText={strings.buttons.BUSY_SAVING}
      >
        Save and close
      </BaseButtonPrimary>
      <BaseButtonSecondary
        className="w-full sm:max-w-xs"
        onClick={() => {
          setAddInventoryTransactionDialogOpen(false);
          setInventoryTransactionList([]);
        }}
      >
        Close
      </BaseButtonSecondary>
    </div>
  );

  const addInventoryTransaction = (
    <AddInventoryTransactionDialog
      isDialogOpen={addInventoryTransactionDialogOpen}
      closeDialog={() => setAddInventoryTransactionDialogOpen(false)}
      setSelectInventoryObjectDialogOpen={setSelectInventoryObjectDialogOpen}
      setInventoryTransactionList={setInventoryTransactionList}
    >
      {{
        SelectInventoryObject: selectInventoryObject,
        InventoryTransactionCardsSection: inventoryTransactionCardsSection,
        ActionButtons: actionButtons,
      }}
    </AddInventoryTransactionDialog>
  );

  // Display loading clipboard while customer list loads.
  if (!userDisplayNamesMap || !listOfCompanies) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <InventoryTransactionListPage
      hideTitle={hideTitle}
      inventoryTransactionList={inventoryTransactions.sort(
        (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
      )}
      inventoryObjectList={inventoryObjects}
      inventoryLocations={inventoryLocations}
      userDisplayNamesMap={userDisplayNamesMap}
      companiesList={listOfCompanies}
      onAddInventoryTransaction={() =>
        setAddInventoryTransactionDialogOpen(true)
      }
    >
      {{
        AddInventoryTransaction: addInventoryTransaction,
      }}
    </InventoryTransactionListPage>
  );
}
