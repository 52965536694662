//Libs
import create from "zustand";

//Local
import { ExistingSiteKeyCompany } from "../models/site-key-companies";
import { ExistingSiteKey } from "../models/site-key";
import { DbRead } from "../database";
import { NotFoundError } from "../error-classes";
import { logger as devLogger } from "../logging";
import { SiteKeyUserPermissions } from "../models/site-key-user-permissions";

//Define the property types.
interface SiteKeyCompaniesStore {
  siteKeyCompanies: ExistingSiteKeyCompany[];
  loading: boolean;
  fetch: (
    siteKeyDoc: ExistingSiteKey,
    userPermission: SiteKeyUserPermissions,
  ) => void;
  getCompanyName: (id: string) => string;
}

//Export a hook to access data globally and define initial values.
export const useSiteKeyCompaniesStore = create<SiteKeyCompaniesStore>(
  (set, get) => {
    return {
      siteKeyCompanies: [],
      loading: false,
      fetch: async (siteKeyDoc, userPermission) => {
        if (typeof siteKeyDoc.id !== "string") {
          return;
        }
        set({ loading: true });
        try {
          if (
            userPermission.permissions.isSiteAdmin ||
            userPermission.permissions.isPlantPersonnel
          ) {
            const allCompanies = await DbRead.siteKeyCompanies.adminGetAll(
              siteKeyDoc.id,
            );
            set({ siteKeyCompanies: allCompanies, loading: false });
          } else {
            if (typeof userPermission.companyID !== "string") {
              return;
            }
            const contractorCompanies =
              await DbRead.siteKeyCompanies.getCompaniesForContractor(
                siteKeyDoc.id,
                siteKeyDoc.managingCompanyID,
                userPermission.companyID,
              );
            set({ siteKeyCompanies: contractorCompanies, loading: false });
          }
        } catch (err) {
          set({ siteKeyCompanies: [], loading: false });
          if (err instanceof NotFoundError) {
            devLogger.error(err);
          } else {
            devLogger.error(err);
          }
        }
      },
      getCompanyName: (id: string) => {
        const companyDoc = get().siteKeyCompanies.find(
          (company) => company.id === id,
        );

        if (companyDoc !== undefined) {
          return companyDoc.name;
        } else {
          return "";
        }
      },
    };
  },
);
