//Libs
import React, { Fragment, RefObject, useCallback, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Formik,
  FormikProps,
  Field,
  ErrorMessage,
  FormikHelpers,
} from "formik";
import { z } from "zod";
import { DocumentData } from "firebase/firestore";
import { RowDragEvent } from "ag-grid-community";

//Local
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import BaseModal from "../components/BaseModal";
import ModalItem from "../components/ModalItem";
import FormLabel from "../components/FormLabel";
import BaseInputText from "../components/BaseInputText";
import BaseInputSelect from "../components/BaseInputSelect";
import BaseButtonSecondary from "../components/BaseButtonSecondary";
import {
  ChecklistItem,
  ChecklistItemManager,
  ExistingChecklistItem,
} from "../models/checklist-item";
import BaseInputTextArea from "../components/BaseInputTextArea";
import BaseInputTag from "../components/BaseInputTag";
import {
  isResponseType,
  ResponseTypes,
  responseTypes,
} from "../models/checklist-response-types";
import BaseInputNumber from "../components/BaseInputNumber";
import { ExistingCraftRecord } from "../models/craft-record";
import BaseInputCheckbox from "../components/BaseInputCheckbox";
import InputTextWithButton from "../components/InputTextWithButton ";
import { convertPassingNumberValues } from "../assets/js/PassingNumberValues";
import { handleTags } from "../assets/js/HandleTags";
import { logger as devLogger } from "../logging";
import ResponseHistoryDialog from "../components/ResponseHistoryDialog";
import { ExistingChecklistResponse } from "../models/checklist-response";
import TemplateDetailsInfoArea from "../components/TemplateDetailsInfoArea";
import ChecklistItemTable from "../components/tables/ChecklistItemTable";
import Breadcrumbs from "../components/Breadcrumbs";
import PageHeading from "../components/PageHeading";
import AddScheduleDialog, {
  Props as AddScheduleDialogProps,
} from "../components/AddScheduleDialog";
import * as strings from "../strings";

// Styles
import { HOME_URL, TEMPLATES_URL } from "../urls";
import { isStringArray } from "../utils";

interface Props {
  itemList: ExistingChecklistItem[];
  onAddItem: (data: DocumentData, checklistID: string) => Promise<void>;
  checklist?: ExistingCraftRecord;
  onUpdateItemOrder?: (itemList: string[]) => Promise<void>;
  responseList: ExistingChecklistResponse[];
  onViewResponseHistory?: (checklistItemID: string) => Promise<void>;
  isHistoryLoading?: boolean;
  onRemoveItem: (
    checklistItemID: string,
    craftRecordID?: string,
  ) => Promise<void>;
  onEditChecklist: (checklistID: string) => void;
  onViewChecklistTasks: (checklistID: string) => void;
  onEditItem: (checklistItemID: string, craftRecordID: string) => void;
  onDuplicateItem: (checklistItemID: string, craftRecordID: string) => void;
  actionQuickCreateChecklist: () => Promise<void>;
  userDisplayNamesMap: Record<string, string>;
  locationName: string;
  addScheduleDialogProps: Pick<
    AddScheduleDialogProps,
    | "serverJobList"
    | "getSystemTimezone"
    | "onFormSubmit"
    | "onDeleteRule"
    | "isFetchingJobs"
  >;
  handleRowDragEvent: (event: RowDragEvent) => void;
  toggleSaveItemOrderButton: (adjustClass: "hide" | "show") => void;
  saveItemOrderDivRef: RefObject<HTMLDivElement>;
  userCanUpdate: boolean;
}

export default function TemplateDetailsPage({
  itemList,
  onAddItem,
  checklist,
  onUpdateItemOrder,
  responseList,
  onViewResponseHistory,
  isHistoryLoading,
  onRemoveItem,
  onEditChecklist,
  onViewChecklistTasks,
  onEditItem,
  onDuplicateItem,
  userDisplayNamesMap,
  locationName,
  userCanUpdate = false,
  ...props
}: Props) {
  // INITIAL FORM VALUES
  const [formSelectionOptions, setFormSelectionOptions] = useState<
    Record<string, boolean>
  >({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showOptionSection, setShowOptionSection] = useState(false);
  const [isCreatingChecklist, setIsCreatingChecklist] = useState(false);
  const [mainTextForRHD, setMainTextForRHD] = useState("");
  const [isResponseDialogOpen, setIsResponseDialogOpen] = useState(false);
  const [saveAndCloseSubmitting, setSaveAndCloseSubmitting] = useState(false);
  const [saveAndAddAnotherSubmitting, setSaveAndAddAnotherSubmitting] =
    useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showAddScheduleDialog, setShowAddScheduleDialog] = useState(false);

  function closeResponseDialog() {
    setIsResponseDialogOpen(false);
  }

  // #region SECTION: onClick handlers for table icons
  const handleDeleteItem = useCallback(
    async function (
      checklistItemID: ExistingChecklistItem["id"],
    ): Promise<void> {
      if (typeof checklistItemID === "string") {
        await onRemoveItem(checklistItemID, checklist?.id);
      }
    },
    [checklist?.id, onRemoveItem],
  );

  const handleEditItem = useCallback(
    function (checklistItemID: ExistingChecklistItem["id"]): void {
      if (
        typeof checklistItemID === "string" &&
        typeof checklist?.id === "string"
      )
        onEditItem(checklistItemID, checklist.id);
    },
    [checklist?.id, onEditItem],
  );

  const handleDuplicateItem = useCallback(
    function (checklistItemID: ExistingChecklistItem["id"]): void {
      if (
        typeof checklistItemID === "string" &&
        typeof checklist?.id === "string"
      )
        onDuplicateItem(checklistItemID, checklist.id);
    },
    [checklist?.id, onEditItem],
  );

  const handleViewHistory = useCallback(
    async function (checklistItem: ExistingChecklistItem): Promise<void> {
      devLogger.debug(`${checklistItem.id} was clicked`);
      setIsResponseDialogOpen(true);
      setMainTextForRHD(checklistItem.mainText);

      if (
        typeof checklistItem.id === "string" &&
        onViewResponseHistory !== undefined
      ) {
        await onViewResponseHistory(checklistItem.id);
      }
    },
    [onViewResponseHistory],
  );
  // #endregion

  const toggleModal = useCallback(
    function () {
      setIsModalOpen(!isModalOpen);
    },
    [isModalOpen],
  );

  if (checklist == null) {
    return null;
  }

  // #region SECTION: Add (& Edit) Item related
  const passingOptions =
    getPassingOptionsFromFormSelectionOptions(formSelectionOptions);

  // We do this so we have both the runtime value and the type.
  const presets = ["presetYesNo"] as const;
  type Presets = (typeof presets)[number];

  // We do this so we have both the runtime value and the type.
  const presetsPlusResponseTypes = [...responseTypes, ...presets] as const;
  type PresetsPlusResponseTypes = (typeof presetsPlusResponseTypes)[number];

  // Typeguard
  function isPresetsPlusResponseTypes(
    value: unknown,
  ): value is PresetsPlusResponseTypes {
    if (presetsPlusResponseTypes.includes(value as any)) {
      return true;
    }
    return false;
  }

  /**
   * We need to translate the users type choice, which can be either a ResponseType
   * or a preset we have created, to the actual responseType values.
   */
  function convertPresetsToResponseTypes(
    value: PresetsPlusResponseTypes,
  ): ResponseTypes {
    const mapPresetsToResponseTypes: Record<Presets, ResponseTypes> = {
      presetYesNo: "selection",
    } as const;

    if (isResponseType(value)) {
      return value;
    }
    return mapPresetsToResponseTypes[value];
  }

  const AllItemsFormSchema = z.object({
    mainText: z.string().min(1).max(200),
    note: z.string().min(0).max(200),
    tags: z.string().min(0).max(200),
    required: z.boolean(),
    typeOrPreset: z.enum(presetsPlusResponseTypes),
    passingMax: z.union([z.number(), z.literal("")]),
    passingMin: z.union([z.number(), z.literal("")]),
    units: z.string().min(0).max(200),
    addOptions: z.string().nullable(),
    optionError: z.string().optional(),
  });

  type AllItemsFormState = z.infer<typeof AllItemsFormSchema>;

  const initialFormValues: AllItemsFormState = {
    mainText: "",
    note: "",
    tags: "",
    required: true,
    typeOrPreset: "string",
    passingMax: "",
    passingMin: "",
    units: "",
    addOptions: null,
  };

  /**
   * Convert the formSelectionOptions state object into
   * an array of strings for only the passing options. Values
   * that were true.
   */
  function getPassingOptionsFromFormSelectionOptions(
    options: Record<string, boolean>,
  ): string[] {
    const passingOptions: string[] = [];

    Object.keys(options).forEach((optionName) => {
      if (options[optionName] === true) {
        passingOptions.push(optionName);
      }
    });

    return passingOptions;
  }

  /**
   * Handle creating a new Checklist Item from the form data ready
   * to be written to Firestore.
   */
  function createNewChecklistItem(args: {
    formValues: AllItemsFormState;
  }): DocumentData {
    const localResponseType = convertPresetsToResponseTypes(
      args.formValues.typeOrPreset,
    );

    if (!isResponseType(localResponseType)) {
      devLogger.error(`Invalid responseType: ${localResponseType}`);
      throw new Error(`Invalid responseType: ${localResponseType}`);
    }

    let checklistItem: ChecklistItem = {
      mainText: args.formValues.mainText,
      note: args.formValues.note,
      responseType: localResponseType,
      required: args.formValues.required,
      tags: handleTags(args.formValues.tags),
      passingMax: convertPassingNumberValues(args.formValues.passingMax),
      passingMin: convertPassingNumberValues(args.formValues.passingMin),
      selectionOptions:
        localResponseType !== "selection"
          ? null
          : Object.keys(formSelectionOptions),
      passingOptions:
        localResponseType !== "selection"
          ? null
          : getPassingOptionsFromFormSelectionOptions(formSelectionOptions),
      units: args.formValues.units,
      craftRecordIDs: [],
      deleted: false,
    };

    // If an item is added for a specific checklist, we add the craftRecordID
    // to the checklist item.
    checklistItem = addCraftRecordIDToChecklistItem(
      checklistItem,
      checklist?.id,
    );

    // Validate data before sending to server.
    return ChecklistItemManager.parse(checklistItem);
  }

  /**
   * Handle for submission for Formik
   */
  async function handleSave(
    values: AllItemsFormState,
    actions: FormikHelpers<AllItemsFormState>,
  ): Promise<void> {
    const validatedData = createNewChecklistItem({
      formValues: values,
    });

    // Write to Firestore
    devLogger.debug("ABOUT TO WRITE TO DATABASE 🔥");
    // Call Firestore method to write data to database.
    if (checklist?.id !== undefined) {
      await onAddItem(validatedData, checklist.id);
    } else {
      return devLogger.debug("Error");
    }
    // TODO: ^ umm, what?!

    devLogger.debug("DONE 🔥");

    actions.setSubmitting(false);
    actions.resetForm();
    setFormSelectionOptions({}); // reset to initial value
    setShowOptionSection(false);
    setSaveAndCloseSubmitting(false);
    setSaveAndAddAnotherSubmitting(false);
  }

  // Make a shallow copy to avoid modifying props.
  let sortedItemList = [...itemList];

  const serverItemOrder = (checklist?.craftDetails as any)?.checklistItems;

  // Display the order of checklist items as specified in
  // craftRecord.craftDetails.checklistItems

  if (isStringArray(serverItemOrder)) {
    const newArray: ExistingChecklistItem[] = [];
    // Retrieve the checklist items in the order specified on the craft record
    serverItemOrder.forEach((targetItemID) => {
      const foundElement = itemList.find(
        (checklistItem) => checklistItem.id === targetItemID,
      );
      if (foundElement != null) {
        newArray.push(foundElement);
      }
    });
    // Add checklist items not yet in the serverItemOrder to the new array.
    itemList.forEach((item) => {
      if (
        Array.isArray(serverItemOrder) &&
        !serverItemOrder.includes(item.id)
      ) {
        newArray.push(item);
      }
    });

    // Update sortedItemList
    sortedItemList = newArray;
  }

  // /**
  //  * Check if the order of items has changed from the server. Used to highlight
  //  * the Save Item Order button.
  //  */
  // function checkItemOrderMatches(localItemOrder: string[], serverItemOrder: string[]): boolean {
  //   const orderMatches = isEqual(localItemOrder, serverItemOrder);
  //   if (orderMatches) {
  //     setLocalOrderEqualsServerOrder({localOrderEqualsServerOrder: true});
  //     return true
  //   } else {
  //     setLocalOrderEqualsServerOrder({localOrderEqualsServerOrder: false});
  //     return false
  //   }
  // }

  /**
   * If this component is being used to show a list of items for a specific
   * Checklist, we need to include that checklist's id in the `craftRecordIDs`
   * list when adding an item. If a checklist prop is present it will copy, update,
   * and return a new checklistItem.
   */
  function addCraftRecordIDToChecklistItem(
    checklistItem: ChecklistItem,
    craftRecordID: string | undefined,
  ): ChecklistItem {
    const copyChecklistItem = { ...checklistItem };
    const craftRecordIDList = checklistItem.craftRecordIDs;
    const isArray = Array.isArray(craftRecordIDList);

    if (typeof craftRecordID === "string") {
      const listDoesNotIncludeID =
        isArray && !craftRecordIDList.includes(craftRecordID);
      if (listDoesNotIncludeID) {
        copyChecklistItem.craftRecordIDs.push(craftRecordID);
      }
      return copyChecklistItem;
    }
    return copyChecklistItem;
  }

  function deleteOption(optionToRemove: string) {
    const convertToArray = Object.entries(formSelectionOptions);
    //convertToArray = ['key', 'value']
    const newFormSelectionOptions = convertToArray.filter(
      ([option]) => option !== optionToRemove,
    );
    setFormSelectionOptions(Object.fromEntries(newFormSelectionOptions));
  }

  function handleTempPassingOptionsCheckbox(property: string) {
    setFormSelectionOptions((prevValues) => ({
      ...prevValues,
      [property]: !prevValues[property],
    }));
  }

  function handleSelections(value: string) {
    switch (value) {
      case "selection":
        setShowOptionSection(true);
        if (
          Object.keys(formSelectionOptions).length === 0 ||
          (formSelectionOptions.hasOwnProperty("Yes") &&
            formSelectionOptions.hasOwnProperty("No"))
        ) {
          setFormSelectionOptions({});
        } else {
          return formSelectionOptions;
        }
        break;
      case "presetYesNo":
        setShowOptionSection(true);
        if (Object.keys(formSelectionOptions).length === 0) {
          setFormSelectionOptions({ Yes: false, No: false });
        } else {
          if (
            formSelectionOptions.hasOwnProperty("Yes") &&
            formSelectionOptions.hasOwnProperty("No")
          ) {
            return formSelectionOptions;
          } else {
            setFormSelectionOptions({ Yes: false, No: false });
          }
        }
        break;
      default:
        setShowOptionSection(false);
    }
  }

  devLogger.debug("formSelectionOptions", formSelectionOptions);

  /**
   * Determine which set of JSX inputs to render based on typeOrPreset.
   */
  function renderConditionalInputs(
    selectionOptionsJSX: JSX.Element,
    numberSectionJSX: JSX.Element,
    formikProps: FormikProps<AllItemsFormState>,
  ): React.ReactNode {
    if (showOptionSection) {
      // Chose 'selection' or 'yes/no'
      return selectionOptionsJSX;
    } else if (
      !showOptionSection &&
      ["integer", "float"].includes(formikProps.values.typeOrPreset)
    ) {
      // Chose 'integer' or 'float'
      return numberSectionJSX;
    } else {
      // Chose 'string'
      return null;
    }
  }
  // #endregion - Add (& Edit) Item related

  // #region SECTION: Some buttons
  const EditTemplateDetails = (): JSX.Element => {
    return (
      <div className={`space-x-4 ${userCanUpdate ? "" : "hidden"}`}>
        <BaseButtonSecondary
          className="w-64"
          type="button"
          onClick={() => {
            if (checklist !== undefined) {
              if (typeof checklist.id === "string") {
                onEditChecklist(checklist.id);
              }
            }
          }}
        >
          <EditIcon
            aria-label="edit template details"
            fontSize="small"
            className="mr-2"
          />
          {strings.buttons.EDIT_TEMPLATE_DETAILS}
        </BaseButtonSecondary>
      </div>
    );
  };

  const ScheduleChecklistTask = (): JSX.Element => {
    return (
      <div className="space-x-4">
        <BaseButtonSecondary
          className="w-64"
          type="button"
          onClick={() => setShowAddScheduleDialog(true)}
        >
          <CalendarMonthIcon
            aria-label="add checklist schedule"
            fontSize="small"
            className="mr-2"
          />
          {strings.buttons.SCHEDULE_CHECKLIST_TASK}
        </BaseButtonSecondary>
      </div>
    );
  };

  const ViewTasksButton = (): JSX.Element => {
    return (
      <BaseButtonSecondary
        className="w-64"
        type="button"
        onClick={() => {
          if (checklist !== undefined) {
            if (typeof checklist.id === "string") {
              onViewChecklistTasks(checklist.id);
            }
          }
        }}
      >
        <AssignmentIcon
          aria-label="view tasks for this template"
          fontSize="small"
          className="mr-2"
        />
        {strings.buttons.VIEW_TASKS_FOR_TEMPLATE}
      </BaseButtonSecondary>
    );
  };

  const QuickCreateChecklistButton = (): JSX.Element => {
    return (
      <BaseButtonPrimary
        className="w-64"
        type="button"
        isBusy={isCreatingChecklist}
        busyText={strings.buttons.BUSY_GENERATING}
        onClick={async () => {
          if (checklist !== undefined) {
            if (typeof checklist.id === "string") {
              devLogger.debug(
                `id is a string!: ${checklist.id} and ${checklist.title}`,
              );
              setIsCreatingChecklist(true);
              try {
                await props.actionQuickCreateChecklist();
              } catch (err) {
                devLogger.error(err);
                setIsCreatingChecklist(false);
              }
            } else {
              devLogger.debug("id was not a string");
            }
          }
        }}
      >
        <AddCircleIcon
          aria-label="generate checklist task"
          fontSize="small"
          className="mr-2"
        />
        {strings.buttons.GENERATE_CHECKLIST_TASK} {">"}
      </BaseButtonPrimary>
    );
  };
  // #endregion

  const home = { name: "Checklists", href: HOME_URL };
  const pages = [
    { name: "Templates", href: TEMPLATES_URL, current: false },
    {
      name: `${checklist?.title}`,
      href: `${TEMPLATES_URL}/${checklist?.id}`,
      current: true,
    },
  ];

  return (
    <>
      <Breadcrumbs home={home} pages={pages} />
      <div
        className={`mt-2 grid grid-cols-1 items-center justify-between space-y-2 rounded-md bg-gray-50 px-4 py-2 shadow-md sm:flex`}
      >
        <div>
          <PageHeading title={checklist.title} detailsInfoArea={false} />
          <TemplateDetailsInfoArea
            template={checklist}
            userDisplayNamesMap={userDisplayNamesMap}
            locationName={locationName}
            isTaskDetails={false}
          />
        </div>
        <div className="flex flex-col items-center space-y-2 sm:items-start">
          {QuickCreateChecklistButton()}
          {ViewTasksButton()}
          {EditTemplateDetails()}
          {ScheduleChecklistTask()}
        </div>
      </div>
      {/* TABLE */}

      <ChecklistItemTable
        itemList={sortedItemList}
        onDeleteItem={handleDeleteItem}
        onDuplicateItem={handleDuplicateItem}
        onHandleEditItem={handleEditItem}
        onViewHistory={handleViewHistory}
        onUpdateItemOrder={onUpdateItemOrder}
        toggleAddItemModal={toggleModal}
        handleRowDragEvent={props.handleRowDragEvent}
        toggleSaveItemOrderButton={props.toggleSaveItemOrderButton}
        saveItemOrderDivRef={props.saveItemOrderDivRef}
        userCanUpdate={userCanUpdate}
      />
      {/* #region SECTION: Formik + Add Item Modal. & conditional displays based on responseType choice. */}
      <BaseModal
        open={isModalOpen}
        closeModal={toggleModal}
        title="Add New Checklist Item"
      >
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleSave}
          // validateOnBlur
          validate={(values) => {
            const errors: Record<string, any> = {};
            const result = AllItemsFormSchema.safeParse(values);

            if (!result.success) {
              const zodError = result.error;
              setDisabled(true);
              return zodError.flatten().fieldErrors;
            } else {
              setDisabled(false);
            }

            return errors;
          }}
        >
          {(formikProps: FormikProps<AllItemsFormState>) => {
            const SelectionOptionsJSX: JSX.Element = (
              <Fragment>
                {/* Selection Options Input */}
                <FormLabel
                  htmlFor="addOptions"
                  label="Add Options"
                  className="mr-1 inline-block"
                />
                <Field
                  as={InputTextWithButton}
                  name="addOptions"
                  id="addOptions"
                  placeholder="Insert your option and click the button"
                  onButtonClick={(optionString: string) => {
                    setFormSelectionOptions((oldState) => {
                      const newState = { ...oldState };
                      newState[optionString] = false;
                      return newState;
                    });
                  }}
                />
                <ErrorMessage
                  name="addOptions"
                  component="div"
                  className="mt-2 text-xs text-red-400"
                />

                {/* Passing Options Select */}
                {Object.keys(formSelectionOptions).length !== 0 ? (
                  <div className="flex items-center py-4">
                    <span className="flex-1 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Options
                    </span>
                    <span className=" text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Considered Passing?
                    </span>
                  </div>
                ) : null}

                {/* Create list of checkboxes for each selection option */}
                <div id="checkbox-group"></div>
                <div
                  className="grid grid-cols-1 py-2"
                  role="group"
                  aria-labelledby="checkbox-group"
                >
                  {Object.keys(formSelectionOptions).map((name, key) => {
                    return (
                      <div
                        className="flex flex-row items-center py-2"
                        key={key}
                      >
                        <button
                          type="button"
                          className="flex-none"
                          onClick={() => deleteOption(name)}
                        >
                          <XMarkIcon
                            aria-label="delete option"
                            className="mr-2 h-5"
                          />
                        </button>
                        <FormLabel
                          htmlFor={name}
                          label={name}
                          removePadding={true}
                          className="flex-grow pr-2"
                        />
                        <Field
                          as={BaseInputCheckbox}
                          name="passingOptions"
                          id={name}
                          label=""
                          value={name}
                          checked={formSelectionOptions[name]}
                          onClick={() => {
                            handleTempPassingOptionsCheckbox(name);
                          }}
                        />
                      </div>
                    );
                  })}
                  {/* Show error messages based on custom logic.  */}
                  {convertPresetsToResponseTypes(
                    formikProps.values.typeOrPreset,
                  ) === "selection" &&
                    passingOptions.length === 0 && (
                      <div className="mt-2 text-xs text-red-400">
                        <>
                          {setDisabled(true)}
                          At least one option should be marked as passing.
                        </>
                      </div>
                    )}
                </div>
              </Fragment>
            );

            const NumberSectionJSX: JSX.Element = (
              <Fragment>
                {/* Passing Min Input */}
                <FormLabel htmlFor="passingMin" label="Passing Min" />
                <Field
                  as={BaseInputNumber}
                  name="passingMin"
                  id="passingMin"
                  className="my-2 flex max-w-lg rounded-md shadow-sm"
                />
                <ErrorMessage
                  name="passingMin"
                  component="div"
                  className="mt-2 text-xs text-red-400"
                />

                {/* Passing Max Input */}
                <FormLabel htmlFor="passingMax" label="Passing Max" />
                <Field
                  as={BaseInputNumber}
                  name="passingMax"
                  id="passingMax"
                  className="my-2 flex max-w-lg rounded-md shadow-sm"
                />
                <ErrorMessage
                  name="passingMax"
                  component="div"
                  className="mt-2 text-xs text-red-400"
                />

                {/* Units Input */}
                <FormLabel htmlFor="units" label="Units" />
                <Field
                  as={BaseInputText}
                  name="units"
                  id="units"
                  className="my-2 flex max-w-lg rounded-md shadow-sm"
                />
                <ErrorMessage
                  name="units"
                  component="div"
                  className="mt-2 text-xs text-red-400"
                />
              </Fragment>
            );

            const ContentJSX: JSX.Element = (
              <Fragment>
                {/* Main Text Input */}
                <FormLabel htmlFor="mainText" label="Main Text" />
                <Field
                  as={BaseInputText}
                  name="mainText"
                  id="mainText"
                  className="my-2 flex max-w-lg rounded-md shadow-sm"
                />
                <ErrorMessage
                  name="mainText"
                  component="div"
                  className="mt-2 text-xs text-red-400"
                />
                {/* Note Input */}
                <FormLabel htmlFor="note" label="Note" />
                <Field
                  as={BaseInputTextArea}
                  name="note"
                  id="note"
                  className="my-2 flex max-w-lg rounded-md shadow-sm"
                  rows={3}
                />
                <ErrorMessage
                  name="note"
                  component="div"
                  className="mt-2 text-xs text-red-400"
                />
                {/* Tags Input */}
                <FormLabel htmlFor="tags" label="Tags" />
                <Field
                  as={BaseInputTag}
                  name="tags"
                  id="tags"
                  className="my-2 flex max-w-lg rounded-md shadow-sm"
                  placeholder="Insert your tags, separated by comma"
                />
                <ErrorMessage
                  name="tags"
                  component="div"
                  className="mt-2 text-xs text-red-400"
                />
                {/* Required Input */}
                <div className="flex items-center py-4">
                  <FormLabel
                    htmlFor="required"
                    label="Required"
                    className="flex-1"
                    removePadding={true}
                  />
                  <Field
                    as={BaseInputCheckbox}
                    id="required"
                    label=""
                    name="required"
                    checked={formikProps.values.required === true}
                  />
                </div>
                {/* Response Type Select */}
                <FormLabel htmlFor="typeOrPreset" label="Response Type" />
                <Field
                  as={BaseInputSelect}
                  admin={false}
                  name="typeOrPreset"
                  className="my-2"
                  id="typeOrPreset"
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = event.target.value;

                    if (isPresetsPlusResponseTypes(value)) {
                      formikProps.values.typeOrPreset = value;
                    }

                    handleSelections(value);

                    // Blur the selection box, so renderConditionalInputs invokes immediately.
                    event.target.blur();
                  }}
                >
                  <option value="" disabled>
                    Please select one...
                  </option>
                  <option value="string">Text</option>
                  <option value="selection">Selection</option>
                  <option value="integer">Whole Number</option>
                  <option value="float">Decimal</option>
                  <option value="presetYesNo">Yes/No</option>
                </Field>
                {/* Render inputs that depend on what option was selected. */}
                {renderConditionalInputs(
                  SelectionOptionsJSX,
                  NumberSectionJSX,
                  formikProps,
                )}
              </Fragment>
            );

            return (
              <ModalItem>
                {{
                  content: ContentJSX,
                  actions: (
                    <Fragment>
                      <div className="flex gap-x-4">
                        <BaseButtonSecondary
                          className="h-12 w-full"
                          type="button"
                          disabled={
                            formikProps.isSubmitting ||
                            !formikProps.isValid ||
                            !formikProps.dirty ||
                            disabled
                          }
                          isBusy={saveAndCloseSubmitting}
                          busyText={strings.buttons.BUSY_SAVING}
                          onClick={async (event) => {
                            event.preventDefault();
                            setSaveAndCloseSubmitting(true);
                            await formikProps.submitForm();
                            toggleModal();
                          }}
                        >
                          {strings.buttons.SAVE_AND_CLOSE}
                        </BaseButtonSecondary>
                        <BaseButtonPrimary
                          className="h-12 w-full"
                          type="submit"
                          disabled={
                            formikProps.isSubmitting ||
                            !formikProps.isValid ||
                            !formikProps.dirty ||
                            disabled
                          }
                          isBusy={saveAndAddAnotherSubmitting}
                          busyText={strings.buttons.BUSY_SAVING}
                          onClick={async (event) => {
                            event.preventDefault();
                            setSaveAndAddAnotherSubmitting(true);
                            await formikProps.submitForm();
                          }}
                        >
                          {strings.buttons.SAVE_AND_ADD_ANOTHER}
                        </BaseButtonPrimary>
                      </div>
                    </Fragment>
                  ),
                }}
              </ModalItem>
            );
          }}
        </Formik>
      </BaseModal>
      {/* #endregion - Formik + Add Item Modal. & conditional displays based on responseType choice. */}
      <ResponseHistoryDialog
        incomingHistoryData={responseList}
        closeDialog={closeResponseDialog}
        isDialogOpen={isResponseDialogOpen}
        isHistoryLoading={isHistoryLoading}
        theMainText={mainTextForRHD}
      />
      <AddScheduleDialog
        showDialog={showAddScheduleDialog}
        onDialogClose={() => setShowAddScheduleDialog(false)}
        craftRecordID={checklist?.id as string}
        {...props.addScheduleDialogProps}
      />
    </>
  );
}
