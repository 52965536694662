//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import { Customer } from "../../models/customer";
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { ExistingSiteKey } from "../../models/site-key";
import AddNewCustomerForm from "./AddNewCustomerForm";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  siteKeyCustomizations: ExistingSiteKey["customizations"];
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSave: (
    formValues: Omit<
      Customer,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    >,
  ) => Promise<void>;
  addNewMembershipButton: React.ReactNode;
  resetMembershipStates: () => void;
  /* CHILDREN */
  children?: {
    AddNewCustomerLocationDialog: (customerType: Customer["type"]) => void;
  };
}

export default function AddNewCustomerDialog(props: Props) {
  const newCustomerHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {strings.NEW_CUSTOMER}
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={() => {}}
      open={props.isDialogOpen}
      title={newCustomerHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-md rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <AddNewCustomerForm
        siteKeyCustomizations={props.siteKeyCustomizations}
        className="flex flex-col space-y-8 p-8 text-lg"
        handleSave={props.handleSave}
        setAddCustomerFormOpen={props.closeDialog}
        addMembershipsButton={props.addNewMembershipButton}
        resetMembershipStates={props.resetMembershipStates}
      >
        {props.children
          ? {
              AddNewCustomerLocationDialog:
                props.children.AddNewCustomerLocationDialog,
            }
          : undefined}
      </AddNewCustomerForm>
    </BaseModal>
  );
}
