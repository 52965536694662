//Libs
import { Dispatch } from "react";

//Local
import BaseInputCheckbox from "../BaseInputCheckbox";
import {
  ExistingMembership,
  getReadableMembershipStatus,
} from "../../models/membership";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import { ExistingAsset } from "../../models/asset";

interface Props {
  assetsForCustomer: ExistingAsset[];
  assetIDs: string[] | undefined;
  setAssetIDs: Dispatch<React.SetStateAction<string[] | undefined>>;
}

export default function AssetEquipmentListCheckboxes({
  assetsForCustomer,
  assetIDs,
  setAssetIDs,
}: Props) {
  return (
    <div className="flex flex-col gap-2">
      {assetsForCustomer.length === 0 && (
        <div>No assets or equipment found for this customer</div>
      )}
      {assetsForCustomer.map((asset) => (
        <div key={asset.id} className="flex items-center gap-2">
          <BaseInputCheckbox
            label={`${asset.title}: ${asset.description}`}
            value={asset.id}
            defaultChecked={assetIDs?.includes(asset.id)}
            onChange={(event) => {
              const checked = event.target.checked;
              if (checked) {
                // Create set of previous IDs and then add the new ID
                const newIDs = new Set(assetIDs);
                setAssetIDs((_prev) => Array.from(newIDs.add(asset.id)));
              } else {
                setAssetIDs((_prev) =>
                  assetIDs?.filter((id) => id !== asset.id),
                );
              }
            }}
          />
        </div>
      ))}
    </div>
  );
}
