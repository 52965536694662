import React from "react";
import { Menu } from "@headlessui/react";

interface Props {
  text: string;
  icon: React.ReactNode;
}

export default function OverflowMenuItem({
  text,
  icon,
  ...rest
}: Props & React.ComponentPropsWithoutRef<"button">): JSX.Element {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          {...rest}
          className={`${
            active
              ? "font-bold text-blue-800 hover:bg-gray-50"
              : "text-gray-700"
          } group flex w-full items-center justify-start rounded-md px-4 py-2 text-sm`}
        >
          <span className="mr-2">{icon}</span>
          {text}
        </button>
      )}
    </Menu.Item>
  );
}
