// Libs
import create from "zustand";

// Local
import { DbRead } from "../database";
import { NotFoundError } from "../error-classes";
import { logger as devLogger } from "../logging";

// Define the property types.
interface UserDisplayNamesStore {
  userDisplayNames: Record<string, string>;
  loading: boolean;
  fetch: (siteKey: string) => void;
}

// Export a hook to access data globally and define initial values..
export const useUserDisplayNamesStore = create<UserDisplayNamesStore>((set) => {
  return {
    userDisplayNames: {},
    loading: false,
    fetch: async (siteKey: string) => {
      set({ loading: true });
      try {
        const userDisplayNamesDoc =
          await DbRead.aggregates.getUserDisplayNames(siteKey);
        set({ userDisplayNames: userDisplayNamesDoc, loading: false });
      } catch (err) {
        set({ userDisplayNames: {}, loading: false });
        if (err instanceof NotFoundError) {
          devLogger.error(err);
        } else {
          devLogger.error(err);
        }
      }
    },
  };
});
