//Libs

import { useNavigate } from "react-router-dom";
import { ExistingSiteKey } from "../../models/site-key";

//Local

interface AdminCardProps {
  site: ExistingSiteKey;
  onHandleView: (id: string) => void;
}

export default function SiteCard({ site, onHandleView }: AdminCardProps) {
  const navigate = useNavigate();

  return (
    <div className="grid items-center gap-2 space-y-2 rounded-2xl border border-gray-300 bg-white p-4 shadow-md hover:border-primary sm:flex sm:h-36 sm:justify-between">
      <div className="flex flex-col space-y-1">
        <p className="text-lg font-bold">{site.name}</p>
        <p className="text-xs font-medium"> {site.timezone} </p>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          className="rounded-2xl border border-primary px-6 py-1 font-bold uppercase text-primary hover:bg-primary hover:text-white"
          onClick={() =>
            site.id != null ? onHandleView(site.id) : navigate("/404")
          }
        >
          View
        </button>
      </div>
    </div>
  );
}
