//Libs
import { Fragment, useCallback, useEffect, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import { Listbox, Transition } from "@headlessui/react";

//Local
import { ExistingSiteKeyLocation } from "../models/site-key-location";
import * as strings from "../strings";
import SiteKeyLocationListTable from "./SiteKeyLocationListTable";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface Props {
  siteKeyLocationList: ExistingSiteKeyLocation[];
  selectedSiteKeyLocation: ExistingSiteKeyLocation["id"] | null;
  setSelectedSiteKeyLocation: (
    location: ExistingSiteKeyLocation["id"] | null,
  ) => void;
}

export function SiteKeyLocationSection(props: Props): JSX.Element {
  const [siteLocationTitle, setSiteLocationTitle] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (props.selectedSiteKeyLocation) {
      const locationTarget = props.siteKeyLocationList.find(
        (siteLocation) => siteLocation.id === props.selectedSiteKeyLocation,
      );
      if (locationTarget) {
        setSiteLocationTitle(locationTarget.title);
      }
    }
  }, [props.selectedSiteKeyLocation, props.siteKeyLocationList]);

  useEffect(() => {
    // If there's only one location, the user won't select anything in the dropdown,
    // therefore there will be no onChange event, and we need to set this manually.
    if (props.siteKeyLocationList?.length === 1) {
      props.setSelectedSiteKeyLocation(props.siteKeyLocationList[0].id);
    }
  }, [props]);

  const renderSelectCell = useCallback(
    function (params: ICellRendererParams) {
      return (
        <button
          onClick={() => props.setSelectedSiteKeyLocation(params.data.id)}
          className="mt-1 w-full max-w-[6rem] rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium uppercase text-gray-700 transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2 sm:w-24"
        >
          {strings.SELECT}
        </button>
      );
    },
    [props],
  );

  // If there's more than 4 locations for the site, want to display a table
  // instead of a dropdown.
  if (props.siteKeyLocationList.length > 4) {
    return (
      <Fragment>
        <h3 className="mb-2 text-xl text-primary">
          {!props.selectedSiteKeyLocation
            ? strings.SELECT_LOCATION
            : strings.SELECTED_LOCATION}
        </h3>
        {props.selectedSiteKeyLocation && siteLocationTitle && (
          <p>{siteLocationTitle}</p>
        )}
        <SiteKeyLocationListTable
          siteKeyLocationList={props.siteKeyLocationList}
          renderIconCell={renderSelectCell}
        />
      </Fragment>
    );
  }

  return (
    <div className="z-10">
      <div className="w-64 xs:w-96 md:w-fit md:min-w-[24rem]">
        <Listbox
          onChange={(event) => {
            // 'event' contains the id string. Find the corresponding object.
            const found = props.siteKeyLocationList.find(
              (location) => location.id === event,
            );
            props.setSelectedSiteKeyLocation(found?.id ?? null);
          }}
        >
          <div className="relative mt-1">
            <Listbox.Button className="relative h-10 w-full cursor-pointer rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight">
              <span className="block truncate">
                {getDropdownDisplayString(
                  props.siteKeyLocationList,
                  props.selectedSiteKeyLocation,
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {props.siteKeyLocationList.map((location) => (
                  <Listbox.Option
                    key={location.id}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active || selected
                          ? "bg-primaryOpacity90 text-primary"
                          : "text-gray-700"
                      }`
                    }
                    value={location.id}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {location.title}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
}

function getDropdownDisplayString(
  siteKeyLocationList: ExistingSiteKeyLocation[],
  selectedSiteKeyLocation: Props["selectedSiteKeyLocation"],
): string {
  if (siteKeyLocationList.length === 1) {
    return siteKeyLocationList[0].title ?? "";
  } else if (!selectedSiteKeyLocation) {
    return "Select...";
  } else {
    const found = siteKeyLocationList.find(
      (siteLocation) => siteLocation.id === selectedSiteKeyLocation,
    );
    return found?.title ?? "";
  }
}
