import React from "react";

type Ref = HTMLTextAreaElement;

type Props = {
  rows: number;
  value?: string;
  text?: string;
  inputName?: string;
  admin: boolean;
  className?: string;
} & React.ComponentPropsWithRef<"textarea">;

const BaseInputTextArea = React.forwardRef<Ref, Props>(
  (
    { rows, text, inputName, value, admin, children, className, ...props },
    ref,
  ): JSX.Element => {
    const adminStyle = admin
      ? "border-black p-4 text-gray-700 focus:ring"
      : "border-gray-300 p-2 focus:ring-1";
    return (
      <div className={`${className} relative`}>
        <textarea
          ref={ref}
          {...props}
          rows={rows}
          value={value}
          aria-labelledby={inputName}
          className={`block w-full min-w-0 flex-1 rounded border outline-none focus:border-primaryLight focus:ring-primaryLight sm:text-sm ${adminStyle}`}
          {...props}
        />
        <span className="absolute right-3 top-4 bg-white px-2">{children}</span>
        {admin === true ? (
          <label
            id={inputName}
            htmlFor={inputName}
            className="absolute -top-3 left-3 bg-white px-2"
          >
            {text}{" "}
            {props.required ? (
              <span className="text-lg font-medium text-redFail">*</span>
            ) : (
              ""
            )}
          </label>
        ) : (
          ""
        )}
      </div>
    );
  },
);

export default BaseInputTextArea;
