//Libs
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import { ExistingChecklistResponse } from "../models/checklist-response";
import ResponseCardBase, {
  ResponseCardBaseProps,
} from "../components/ResponseCardBase";
import BaseInputText from "./BaseInputText";
import { logger as devLogger } from "../logging";

interface Props {
  response: ExistingChecklistResponse;
  onSaveValue: (value: string | null, responseID: string) => Promise<void>;
  /**
   * Takes IconRow as children.
   */
  children: React.ReactNode;
}

export default function ResponseCardStringType(
  props: Props & Omit<ResponseCardBaseProps, "children">,
) {
  const ResponseStringSchema = z.object({
    responseStringValue: z.string().max(200),
  });
  type ResponseStringState = z.infer<typeof ResponseStringSchema>;

  async function onSubmit(data: ResponseStringState) {
    if (props.response.id == null) {
      devLogger.error(`ResponseID was not found. Value: ${props.response.id}`);
      return;
    }

    const valueToSend = data.responseStringValue.trim();

    if (valueToSend === "" && props.response.responseValue === null) {
      devLogger.debug("The value hasn't changed");
      return;
    }

    if (valueToSend === props.response.responseValue) {
      devLogger.debug("The value hasn't changed");
      return;
    }

    await props.onSaveValue(valueToSend, props.response.id);
    devLogger.debug("Response value was successfully recorded");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResponseStringState>({
    defaultValues: {
      responseStringValue:
        typeof props.response.responseValue === "string"
          ? props.response.responseValue
          : "",
    },
    resolver: zodResolver(ResponseStringSchema),
  });

  const inputForm = (
    <form
      autoComplete="off"
      onBlur={handleSubmit(onSubmit)}
      onKeyDown={(event) =>
        event.key === "Enter" ? event.preventDefault() : null
      }
      className="my-7 mb-2 block w-full sm:w-72"
    >
      <BaseInputText
        inputName={props.response.id}
        admin={false}
        {...register("responseStringValue")}
        id={props.response.id}
      />
      {errors.responseStringValue?.message && (
        <div className="ml-1 mt-2 text-xs text-red-400">
          {errors.responseStringValue?.message}
        </div>
      )}
    </form>
  );

  return (
    <Fragment>
      <ResponseCardBase response={props.response}>
        {{
          input: inputForm,
          iconRow: props.children,
        }}
      </ResponseCardBase>
    </Fragment>
  );
}
