// Libs
import { Control, FieldValues, Controller } from "react-hook-form";

// Local
import { ExistingCustomField } from "../../models/custom-field";
import { Json } from "../../models/json-type";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";

interface Props {
  customField: ExistingCustomField;
  control: Control<FieldValues, any>;
  defaultValue: Json | Date;
  isRequired: boolean;
}

export default function FieldTypeStringTextarea(props: Props) {
  return (
    <Controller
      defaultValue={props.defaultValue}
      name={props.customField.id}
      control={props.control}
      rules={{ required: props.isRequired }}
      render={({ field, fieldState }) => {
        return (
          <>
            <label htmlFor={props.customField.id + props.customField.title}>
              {props.customField.title}{" "}
              {props.isRequired && (
                <span className="text-lg font-medium text-redFail">*</span>
              )}
            </label>
            <textarea
              {...field}
              rows={2}
              id={props.customField.id + props.customField.title}
              className="mt-1 block w-full rounded-md border border-gray-400 px-3 py-2 text-base text-gray-800 transition-colors hover:border-gray-800 focus:outline-none focus:ring-1 focus:ring-primaryLight"
            />
            {fieldState.error && fieldState.error.type === "required" && (
              <div className="mt-2">
                <StyledMessage type="error">
                  {{ message: strings.REQUIRED }}
                </StyledMessage>
              </div>
            )}
          </>
        );
      }}
    />
  );
}
