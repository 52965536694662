//Libs
import { Fragment } from "react";

//Local

interface Props {
  children: {
    CraftRecordsMenu: React.ReactNode;
    TasksMenu: React.ReactNode;
  };
}

export default function CustomFieldSidebarMenu(props: Props) {
  return (
    <Fragment>
      <div className="absolute inset-y-0 left-0 w-96 -translate-x-full transform transition duration-200 ease-in-out sm:bg-gray-100 md:relative md:translate-x-0">
        <div className="w-full px-4 pt-4">
          {props.children.CraftRecordsMenu}
          {props.children.TasksMenu}
        </div>
      </div>
    </Fragment>
  );
}
