//Libs
import React, {
  useRef,
  useLayoutEffect,
  useCallback,
  Fragment,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridApi,
  ICellRendererParams,
  ITooltipParams,
  RowClickedEvent,
  ValueGetterFunc,
  ValueGetterParams,
} from "ag-grid-community";
import isEqual from "lodash/isEqual";

//Local
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import placeholder_avatar from "../../images/placeholder_avatar.svg";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import { RootUsersMapParams } from "../../models/root-user";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import SearchBox from "../SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";
import { phoneUtils } from "../../utils/phoneUtils";

// #region SECTION: Cell display related
const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  width: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface TableProps {
  usersList: Props["usersList"];
  calculateApproved: ValueGetterFunc;
  calculateInactive: ValueGetterFunc;
  calculateAppVersion: ValueGetterFunc;
  renderUserPhoto: (params: ICellRendererParams) => JSX.Element;
  calculateCompanyName: ValueGetterFunc;
  calculateAppLastOpenedTimestamp: ValueGetterFunc;
  calculateCurrentBundleID: ValueGetterFunc;
  goToEditPage: Props["goToEditPage"];
  renderDisplayName: (params: ICellRendererParams) => JSX.Element;
  userPermissionsMap: Props["usersPermissionsMap"];
  children: {
    addUserButton: JSX.Element;
  };
}

const TheTable = React.memo(
  ({
    usersList,
    calculateApproved,
    calculateInactive,
    calculateAppVersion,
    renderUserPhoto,
    calculateCompanyName,
    calculateAppLastOpenedTimestamp,
    calculateCurrentBundleID,
    renderDisplayName,
    userPermissionsMap,
    ...props
  }: TableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        const api: GridApi = gridRef.current.api;
        api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const renderPhone = (params: ICellRendererParams): JSX.Element => {
      const phone = params.data.phone ?? "--";
      return <div>{phoneUtils.display(phone)}</div>;
    };

    const columnDefs: ColDef[] = [
      {
        field: "userPhoto_URL",
        cellRenderer: renderUserPhoto,
        headerName: "User Photo",
        width: 95,
      },
      {
        field: "displayName",
        headerName: "User",
        cellRenderer: renderDisplayName,
        tooltipValueGetter: () => "Edit User",
      },
      {
        field: "email",
        headerName: "Email",
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        valueGetter: calculateCompanyName,
        headerName: "Company Name",
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        field: "jobTitle",
        headerName: "Job Title",
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        field: "department",
        headerName: "Department",
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        field: "phone",
        headerName: "Phone #",
        cellRenderer: renderPhone,
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        field: "appVersion",
        headerName: "App Version",
        valueGetter: calculateAppVersion,
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        field: "appLastOpened",
        headerName: "App Last Opened",
        valueGetter: calculateAppLastOpenedTimestamp,
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        field: "iosAndroid",
        headerName: "iOS/Android",
        valueGetter: calculateCurrentBundleID,
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        headerName: "Approved",
        valueGetter: calculateApproved,
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
      {
        headerName: "Inactive",
        valueGetter: calculateInactive,
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted ?? params.value,
      },
    ];

    return (
      <Fragment>
        <div className="flex justify-between gap-4">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full max-w-md sm:w-96"
          />
          {props.children.addUserButton}
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={usersList}
          animateRows={true}
          rowSelection="single"
          rowHeight={50}
          cacheQuickFilter={true}
          onRowClicked={(event: RowClickedEvent) =>
            props.goToEditPage(event.data.id)
          }
          columnDefs={columnDefs}
        />
      </Fragment>
    );
  },
  (previous, next) => {
    const isUsersListTheSame = isEqual(previous.usersList, next.usersList);
    // fixes a bug where it would not update the UI if click tabs between two filters where both have 0 results.
    const bothListAreEmpty =
      previous.usersList.length === 0 && next.usersList.length === 0;

    const isPermissionsMapTheSame = isEqual(
      previous.userPermissionsMap,
      next.userPermissionsMap,
    );

    return isUsersListTheSame && !bothListAreEmpty && isPermissionsMapTheSame;
  },
);

export interface Props {
  usersList: ExistingSiteKeyUserDoc[];
  usersPermissionsMap: Record<string, ExistingSiteKeyUserPermissions>;
  rootUsersMap: Record<string, RootUsersMapParams>;
  companiesList: ExistingSiteKeyCompany[];
  goToEditPage: (userID: string) => void;
  children: {
    Breadcrumbs: React.ReactNode;
    addUserButton: JSX.Element;
    filterTabs: JSX.Element;
  };
}

function ListAllUsersTable({
  usersList,
  usersPermissionsMap,
  companiesList,
  rootUsersMap,
  ...props
}: Props): JSX.Element {
  function showRightStringForCurrentBundleID(currentBundleID: string | null) {
    if (currentBundleID == null) return "";
    if (currentBundleID.includes("ios")) {
      return "iOS";
    } else if (currentBundleID.includes("android")) {
      return "Android";
    } else {
      return "";
    }
  }

  const calculateApproved: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const userPermission = usersPermissionsMap[params.data.id];
      return userPermission?.approved ? "Yes" : "No";
    },
    [usersPermissionsMap],
  );

  const calculateInactive: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const userPermission = usersPermissionsMap[params.data.id];
      return userPermission?.inactive ? "Yes" : "No";
    },
    [usersPermissionsMap],
  );

  const calculateAppVersion: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const rootUser = rootUsersMap[params.data.id];
      return rootUser?.currentAppVersion ? rootUser.currentAppVersion : "";
    },
    [rootUsersMap],
  );

  const calculateAppLastOpenedTimestamp: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const rootUser = rootUsersMap[params.data.id];
      return rootUser?.appLastOpenedTimestamp
        ? convertToReadableTimestamp(rootUser.appLastOpenedTimestamp)
        : "";
    },
    [rootUsersMap],
  );

  const calculateCurrentBundleID: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const rootUser = rootUsersMap[params.data.id];
      return showRightStringForCurrentBundleID(rootUser?.currentBundleID);
    },
    [rootUsersMap],
  );

  const renderUserPhoto = (params: ICellRendererParams): JSX.Element => {
    return (
      <div className="m-1 flex h-10 w-10 justify-center overflow-hidden rounded-full">
        <img
          src={
            params.data.userPhoto_URL
              ? params.data.userPhoto_URL
              : placeholder_avatar
          }
          alt="User avatar"
          className="w-full flex-shrink-0 object-cover"
        />
      </div>
    );
  };

  const calculateCompanyName: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const userPermission = usersPermissionsMap[params.data.id];
      const companyName = companiesList.find((company) => {
        return company.id === userPermission?.companyID;
      });
      return companyName?.name;
    },
    [usersPermissionsMap, companiesList],
  );

  const renderDisplayName = (params: ICellRendererParams): JSX.Element => {
    return <div className="capitalize text-primary">{params.value}</div>;
  };

  return (
    <div className={"ag-theme-alpine flex h-full min-h-[600px] flex-col"}>
      {props.children.Breadcrumbs}
      <div className="grid-cols-1xs my-8 grid justify-start space-y-4 sm:space-y-0">
        {props.children.filterTabs}
      </div>
      <TheTable
        usersList={usersList}
        calculateApproved={calculateApproved}
        calculateInactive={calculateInactive}
        renderUserPhoto={renderUserPhoto}
        calculateCompanyName={calculateCompanyName}
        renderDisplayName={renderDisplayName}
        userPermissionsMap={usersPermissionsMap}
        calculateAppVersion={calculateAppVersion}
        calculateAppLastOpenedTimestamp={calculateAppLastOpenedTimestamp}
        calculateCurrentBundleID={calculateCurrentBundleID}
        goToEditPage={props.goToEditPage}
      >
        {{
          addUserButton: props.children.addUserButton,
        }}
      </TheTable>
    </div>
  );
}

export default ListAllUsersTable;
