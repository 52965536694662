//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";

export interface ChatRoom {
  timestampCreated: Timestamp;
  imageUrl: string | null;
  lastMessages: string[];
  metadata: { [key: string]: any };
  name: string;
  type: string;
  timestampLastModified: Timestamp;
  userIds: string[];
}

export interface ExistingChatRoom extends ChatRoom {
  id: string;
  refPath: string;
}

export const ChatRoomManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingChatRoom object.
   */
  createFromFirestoreSnapshot: createChatRoomFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertChatRoomForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateChatRoom,
  /** Validate a ChatRoom doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateChatRoomWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingChatRoom object.
 */
function createChatRoomFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingChatRoom {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateChatRoomWithFallbacks(snapshotData),
  };
}

const chatRoomSchema = z.object({
  timestampCreated: z.instanceof(Timestamp),
  imageUrl: z.string().nullable(),
  lastMessages: z.array(z.string()),
  metadata: z.record(z.any()),
  name: z.string(),
  type: z.string(),
  timestampLastModified: z.instanceof(Timestamp),
  userIds: z.array(z.string()),
});

function validateChatRoom(data: DocumentData): ChatRoom {
  const parsedData = chatRoomSchema.parse(data);
  return parsedData;
}

function validateChatRoomWithFallbacks(value: unknown): ChatRoom {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = chatRoomSchema.parse(value);
  return result;
}

function convertChatRoomForFirestore(chatRoom: ChatRoom): DocumentData {
  const local = Object.assign({}, chatRoom);
  const { ...rest } = local;
  return dropUndefined(rest);
}

const chatMessageSchemaWithFallbacks = chatRoomSchema.extend({
  timestampCreated: z.instanceof(Timestamp),
  imageUrl: z.string().nullable(),
  lastMessages: z.array(z.string()),
  metadata: z.record(z.any()),
  name: z.string(),
  type: z.string(),
  timestampLastModified: z.instanceof(Timestamp),
  userIds: z.array(z.string()),
});
