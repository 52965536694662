//Libs
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import BaseButtonPrimary from "../../BaseButtonPrimary";
import BaseButtonSecondary from "../../BaseButtonSecondary";
import BaseInputText from "../../BaseInputText";
import StyledMessage from "../../StyledMessage";
import StyledSwitchGroup from "../../StyledSwitchGroup";
import * as strings from "../../../strings";
import {
  ComplianceItemManager,
  FloatComplianceItem,
} from "../../../models/compliance-item";
import { logger as devLogger } from "../../../logging";
import { ErrorMessage } from "../../ErrorMessage";
import {
  MaxField,
  MinField,
} from "../../admin/AddCustomFieldDialog/AddCustomFieldDialog";
import { ComplianceItemProps } from "./NewComplianceItemDialog";

const FloatSchema = z.object({
  text: z.string().min(1, { message: "Required" }).max(2000),
  required: z.boolean(),
  units: z.string().max(200),
  responseType: z.literal("float"),
  max: z.number().nullable(),
  min: z.number().nullable(),
  selectionOptions: z.null(),
  passingOptions: z.null(),
});

const floatComplianceDefaultValues: Partial<FloatItemInterface> = {
  text: "",
  required: false,
  units: "",
  responseType: "float",
  min: null,
  max: null,
  selectionOptions: null,
  passingOptions: null,
};

type FloatItemInterface = z.infer<typeof FloatSchema>;

export const FloatComplianceItemForm: React.FunctionComponent<
  ComplianceItemProps
> = (props) => {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<FloatItemInterface>({
    defaultValues: floatComplianceDefaultValues,
    resolver: zodResolver(FloatSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(floatComplianceDefaultValues);
  }, [reset]);

  const onSubmit: SubmitHandler<FloatItemInterface> = async (formValues) => {
    const values: Omit<FloatComplianceItem, "order"> = {
      text: formValues.text,
      required: formValues.required,
      units: formValues.units,
      responseType: "float",
      passingMin: formValues.min,
      passingMax: formValues.max,
      selectionOptions: null,
      passingOptions: null,
    };

    // Validate before write to DB
    const validatedValues = ComplianceItemManager.parsePartial(values);

    try {
      props.handleSaveComplianceItem(validatedValues);
      // Close the section if successful.
      onCloseComplianceItem();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  /* fn that handle all the states that needs to be reset when the section is closed */
  function onCloseComplianceItem() {
    props.closeDialog();
    reset();
  }

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <div className="flex flex-col text-lg">
      <form
        autoComplete="off"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        className="space-y-8"
      >
        {/* Field: Text */}
        <div>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Text"
                inputName="text"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {errors.text?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.text.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Field: Required */}
        <div>
          <Controller
            name="required"
            control={control}
            render={({ field }) => (
              <StyledSwitchGroup
                readableName="Required"
                onBlur={field.onBlur}
                onChange={field.onChange}
                ref={field.ref}
                checked={field.value}
                id="required"
                name={field.name}
              />
            )}
          />
        </div>

        {/* Field: Units */}
        <div>
          <Controller
            name="units"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Units"
                inputName="units"
                admin={true}
                required={false}
                {...field}
              />
            )}
          />
          {errors.units?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.units.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-4 xs:flex-row">
          {/* Field: Passing Min */}
          <div className="flex-grow">
            <MinField control={control} errors={errors} isStringBased={false} />
          </div>

          {/* Field: Passing Max */}
          <div className="flex-grow">
            <MaxField control={control} errors={errors} isStringBased={false} />
          </div>
        </div>

        <span className="-my-5 h-10 justify-self-center sm:col-span-2 lg:col-span-3">
          {displayError ? errorMessage : null}
        </span>

        {/* Action Buttons */}
        <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={onCloseComplianceItem}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
            data-testid="save item"
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </form>
    </div>
  );
};
