//Libs
import { useRef, useState } from "react";
import BaseButtonSecondary from "./BaseButtonSecondary";

//Local

interface Props {
  lightspeedActionSelected: (
    actionType: LightspeedActionTypes,
    refPaths: any[],
  ) => Promise<void>;
  actionsLoading: boolean;
  selectedRows: any[];
}

export const lightspeedActionTypes = ["pushTransactions"] as const;
// Create the types from the TS array 'membershipStatus'.
export type LightspeedActionTypes = (typeof lightspeedActionTypes)[number];

export default function LightspeedActionDropdown(props: Props) {
  const dropdown = useRef(null);
  const [isOpen, setOpen] = useState(false);

  async function handlePushTransactions() {
    setOpen(false);
    await props.lightspeedActionSelected(
      "pushTransactions",
      props.selectedRows,
    );
  }

  return (
    <div className="dropdown">
      <BaseButtonSecondary
        onClick={() => setOpen(!isOpen)}
        ref={dropdown}
        isBusy={props.actionsLoading}
        onBlur={() => setOpen(false)}
        className="w-44 "
      >
        <div className="w-full text-left">Actions</div>
        {props.selectedRows.length > 0 && (
          <div className="rounded-full bg-red-500 px-2 py-1 text-white">
            {`${props.selectedRows.length > 0 ? props.selectedRows.length.toString() : ""}`}
          </div>
        )}

        <svg
          className="ml-2 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </BaseButtonSecondary>

      <div
        id="dropdown"
        className={`relative z-10 w-44 divide-y divide-gray-100 rounded-md bg-white shadow ${
          isOpen ? "over" : "hidden"
        }`}
      >
        <ul className="absolute z-10 w-44 divide-y divide-gray-100 rounded-md bg-white shadow ">
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handlePushTransactions}
              className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
            >
              Push To Lightspeed...
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
