//Libs

//Local

interface Props {
  total: string;
  description: string;
}

export default function AllComplianceCountsCard(props: Props) {
  return (
    <div className="flex flex-col items-center justify-center rounded-md p-3 shadow-md xs:w-52">
      <span className="text-2xl font-bold sm:text-3xl lg:text-4xl">
        {props.total}
      </span>
      <span className="text-center text-base capitalize lg:text-xl">
        {props.description}
      </span>
    </div>
  );
}
