// Libs
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

// Local
import AddUserPage from "./AddUserPage";
import { AddOrEditUserFormState } from "../../models/add-or-edit-user";
import { logger as devLogger } from "../../logging";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import {
  SiteKeyUserDoc,
  SiteKeyUsersManager,
} from "../../models/site-key-users";
import {
  SiteKeyUserPermissions,
  SiteKeyUserPermissionsManager,
} from "../../models/site-key-user-permissions";
import { useSiteKeyCompaniesStore } from "../../store/site-key-companies";
import { DbWrite } from "../../database";
import { isWhiteLabel } from "../../white-label-check";
import { StyledMessageWithLink } from "../../components/StyledMessageWithLink";
import StyledMessage from "../../components/StyledMessage";
import { ADMIN_EDIT_USER_URL, whiteLabel } from "../../urls";
import * as strings from "../../strings";
import { isComplianceEnabled } from "../../assets/js/isComplianceEnabled";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";

interface Props {
  siteKey: string;
}

type DisplayMessageDataType = {
  newUser: boolean;
  type: "success" | "error";
  uid: string | null;
};

export default function AddUserContainer(props: Props) {
  const navigate = useNavigate();
  function goToListAllPage() {
    navigate(-1);
  }
  function handleEditExistingUser(uid: string): void {
    navigate(`${ADMIN_EDIT_USER_URL}/${uid}`);
  }

  const companyList: ExistingSiteKeyCompany[] = useSiteKeyCompaniesStore(
    (state) => state.siteKeyCompanies,
  );

  const siteKeyLocationList: ExistingSiteKeyLocation[] =
    useSiteKeyLocationsStore((state) => state.siteKeyLocationList);

  // For showing or hiding the compliance-related permissions.
  const complianceEnabled = useRef<boolean | null>(null);
  useEffect(() => {
    async function getComplianceEnabledStatus() {
      complianceEnabled.current = await isComplianceEnabled(props.siteKey);
    }
    getComplianceEnabledStatus();
  }, [props.siteKey]);

  const [displayMessageData, setDisplayMessageData] =
    useState<DisplayMessageDataType | null>(null);

  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [isDownloadLinkError, setIsDownloadLinkError] = useState(false);
  const [isAndroidSubmitting, setIsAndroidSubmitting] = useState(false);
  const [isIosSubmitting, setIsIosSubmitting] = useState(false);

  async function handleSaveUser(formValues: AddOrEditUserFormState) {
    devLogger.debug("formValues: ", formValues);

    // Separate SiteKeyUser data from SiteKeyUserPermissions data.
    const {
      // The 'companyName' value we're getting from formValues is really the
      // siteKeyCompany document ID - 'companyID'
      companyName: companyID,
      department,
      displayName,
      email,
      jobTitle,
      phone,
      ...permissionsData
    } = formValues;

    // Get the actual companyName, based on the company id.
    const companyName = companyList.find(
      (company) => company.id === companyID,
    )?.name;

    // Group SiteKeyUser data together.
    const siteKeyUser: SiteKeyUserDoc = {
      companyName: companyName ?? "",
      department,
      displayName,
      email,
      jobTitle,
      phone,
      userPhoto_URL: null,
    };

    // If the admin fills in and then clears out the optional `department` field,
    // the value we receive here will be an empty string. Replace it with null.
    if (siteKeyUser.department === "" || siteKeyUser.department === undefined) {
      siteKeyUser.department = null;
    }

    // Get the permissions data together.
    const userPermissions: SiteKeyUserPermissions = {
      approved: permissionsData.approved,
      companyID: companyID,
      defaultLocationID: permissionsData.defaultLocationID,
      customData: {},
      managementSubscriptions: {
        newTaskCreated: permissionsData.newTaskCreated,
        allTaskStatusChanged: permissionsData.allTaskStatusChanged,
        // Set permissions that aren't covered in the form to false.
        taskDeleted: false,
        craftRecordDeleted: false,
        scaffolds: false,
      },
      permissions: {
        getsNewTaskNotifications: permissionsData.getsNewTaskNotifications,
        canEditContractorDetails: permissionsData.canEditContractorDetails,
        canCreateTasks: permissionsData.canCreateTasks,
        canUpdateTasks: permissionsData.canUpdateTasks,
        canDeleteTasks: permissionsData.canDeleteTasks,
        canCreateCraftRecords: permissionsData.canCreateCraftRecords,
        canUpdateCraftRecords: permissionsData.canUpdateCraftRecords,
        canDeleteCraftRecords: permissionsData.canDeleteCraftRecords,
        isPlantPersonnel: permissionsData.isPlantPersonnel,
        isSiteAdmin: permissionsData.isSiteAdmin,
        complianceRequirements_create:
          permissionsData.complianceRequirements_create,
        complianceRequirements_read:
          permissionsData.complianceRequirements_read,
        complianceRequirements_delete:
          permissionsData.complianceRequirements_delete,
        complianceResponses_create: permissionsData.complianceResponses_create,
        complianceResponses_read: permissionsData.complianceResponses_read,
        complianceResponses_readAll:
          permissionsData.complianceResponses_readAll,
        complianceResponses_delete: permissionsData.complianceResponses_delete,
        complianceResponses_review: permissionsData.complianceResponses_review,
        tasks_changeDate: permissionsData.tasks_changeDate,
      },
    };

    // Validate against zod schemas.
    const validatedUserDoc = SiteKeyUsersManager.parse(siteKeyUser);
    const validatedPermissionsDoc =
      SiteKeyUserPermissionsManager.parse(userPermissions);

    // Get whiteLabel "status"
    if (isWhiteLabel(whiteLabel)) {
      // Call DB 🔥
      try {
        const response = await DbWrite.user.add(
          props.siteKey,
          whiteLabel,
          validatedUserDoc,
          validatedPermissionsDoc,
        );

        // Set a success message if adding the user was successful. If the status
        // code is 201, it means the user will receive an email. (It's a new user)
        if (response.status === 201) {
          setDisplayMessageData({ newUser: true, type: "success", uid: null });
        } else {
          setDisplayMessageData({ newUser: false, type: "success", uid: null });
        }
        return response.passphrase;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response && e.response.status === 418) {
            // If we're here, e.response.data.theResponse is the UID of the user
            // the admin tried to add.
            const uid = e.response.data.theResponse;
            setDisplayMessageData({ newUser: false, type: "error", uid: uid });
          } else {
            // Is an axios error, but not a 418.
            setDisplayMessageData({ newUser: false, type: "error", uid: null });
            devLogger.error(e);
          }
        } else {
          setDisplayMessageData({ newUser: false, type: "error", uid: null });
          devLogger.error("Not an axios error", e);
        }
        return null;
      }
    } else {
      throw new Error(`Unexpected white label.`);
    }
  }

  function clearMessage() {
    setDisplayMessageData(null);
  }

  // const isCompliance

  const successMessage =
    displayMessageData?.type === "success" ? (
      <DisplaySuccessMessage
        clearMessage={clearMessage}
        newUser={displayMessageData.newUser}
      />
    ) : null;

  const errorMessage =
    displayMessageData?.uid != null && displayMessageData.type === "error" ? (
      <DisplayErrorMsg
        uid={displayMessageData.uid}
        onEditExistingUser={handleEditExistingUser}
        clearMessage={clearMessage}
      />
    ) : null;

  async function handleAppDownloadClick(platform: string) {
    if (platform === "android") {
      setIsAndroidSubmitting(true);
    } else if (platform === "ios") {
      setIsIosSubmitting(true);
    } else {
      setIsDownloadLinkError(true);
      return;
    }
    try {
      const result = await DbWrite.user.sendAppDownloadLinkToDesktopUser({
        version: whiteLabel,
        platform,
      });

      setDownloadLink(result.downloadLink);
      setIsDownloadLinkError(result.isDownloadLinkError);
    } catch (error) {
      setIsDownloadLinkError(true);
    } finally {
      setIsAndroidSubmitting(false);
      setIsIosSubmitting(false);
    }
  }

  return (
    <AddUserPage
      goToListAllPage={goToListAllPage}
      companyList={companyList}
      siteKeyLocationList={siteKeyLocationList}
      onSaveUser={handleSaveUser}
      isComplianceEnabled={complianceEnabled.current}
      onAppDownload={handleAppDownloadClick}
      downloadLink={downloadLink}
      setDownloadLink={setDownloadLink}
      isDownloadLinkError={isDownloadLinkError}
      isAndroidSubmitting={isAndroidSubmitting}
      isIosSubmitting={isIosSubmitting}
    >
      {{ displayResponseMessage: successMessage || errorMessage }}
    </AddUserPage>
  );
}

function DisplayErrorMsg(props: {
  uid?: string;
  onEditExistingUser?: (uid: string) => void;
  clearMessage: () => void;
}): JSX.Element {
  const { uid, onEditExistingUser: onEdit } = props;
  if (uid && onEdit) {
    return (
      <div className="mx-auto inline-block">
        <StyledMessageWithLink
          beginningText="User already exists on this site. Did you mean to "
          endingText="?"
          linkText="edit this user"
          onLinkClick={() => onEdit(uid)}
          dismissible
          onDismiss={props.clearMessage}
          type="error"
        />
      </div>
    );
  } else {
    return (
      <div className="mx-auto inline-block">
        <StyledMessage type="error" dismissible onDismiss={props.clearMessage}>
          {{ message: strings.PROBLEM_ADDING_USER }}
        </StyledMessage>
      </div>
    );
  }
}

function DisplaySuccessMessage(props: {
  newUser?: boolean;
  clearMessage: () => void;
}): JSX.Element {
  let message: string;
  if (props.newUser) {
    message = strings.ADD_NEW_USER_SUCCESS;
  } else {
    message = strings.ADD_USER_SUCCESS;
  }
  return (
    <div className="mx-auto inline-block">
      <StyledMessage type="success" dismissible onDismiss={props.clearMessage}>
        {{ message: message }}
      </StyledMessage>
    </div>
  );
}
