// Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import AddIcon from "@mui/icons-material/Add";
import EventIcon from "@mui/icons-material/Event";

// Local
import * as strings from "../../strings";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseModal from "../BaseModal";

interface Props {
  open: boolean;
  onClose: () => void;
  onJobSelected: () => void;
  onEventSelected: () => void;
}

export default function ChooseCreateJobOrEventDialog(
  props: Props,
): JSX.Element {
  return (
    <BaseModal
      open={props.open}
      closeModal={props.onClose}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.SELECT_CREATION_CHOICE}</h1>
          <button
            onClick={props.onClose}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-4 p-8 text-lg sm:grid sm:grid-cols-2 sm:gap-8 sm:space-y-0 ">
        <BaseButtonSecondary
          className="flex gap-2 uppercase"
          onClick={props.onEventSelected}
        >
          <EventIcon />
          {strings.buttons.ADD_NEW_EVENT}
        </BaseButtonSecondary>
        <BaseButtonPrimary
          className="flex gap-2 uppercase"
          onClick={props.onJobSelected}
        >
          <AddIcon />
          {strings.CREATE_JOB}
        </BaseButtonPrimary>
      </div>
    </BaseModal>
  );
}
