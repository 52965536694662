// #region Imports
// Libs
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getDownloadURL,
  getStorage,
  ref,
  StorageError,
  uploadBytesResumable,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { Timestamp, DocumentData } from "firebase/firestore";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import TableChartIcon from "@mui/icons-material/TableChart";
import { User } from "firebase/auth";
import { useQuery, useMutation, useQueryClient } from "react-query";

// Local
import { DbRead, DbWrite } from "../../database";
import { ExistingTask } from "../../models/task";
import { TaskStatus } from "../../models/task-status";
import { ExistingChecklistResponse } from "../../models/checklist-response";
import {
  ChecklistPhoto,
  ChecklistPhotoManager,
  ExistingChecklistPhoto,
} from "../../models/checklist-photo";
import { logger as devLogger } from "../../logging";
import { ExistingCraftRecord } from "../../models/craft-record";
import { useAuthStore } from "../../store/firebase-auth";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { sortByOrderNum } from "../../utils";
import { sortResponsePhotos } from "../../assets/js/sortResponsePhotos";
import { createToastMessageID } from "../../utils";

// Components
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import TaskDetailsLayout from "../TaskDetailsLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import PageHeading from "../../components/PageHeading";
import TemplateDetailsInfoArea from "../../components/TemplateDetailsInfoArea";
import TaskDetailsInfoArea from "../../components/TaskDetailsInfoArea";
import ResponseHistoryDialog from "../../components/ResponseHistoryDialog";
import ResponseReducedGallery from "../../components/ResponseReducedGallery";
import ResponseTablePhotoDialog from "../../components/ResponseTablePhotoDialog";
import TaskStatusDialog from "../../components/TaskStatusDialog";
import ChecklistResponsesTable from "../../components/tables/ChecklistResponsesTable";
import ResponseCardsList from "../../components/ResponseCardsList";
import ResponseCardPhotoDialog from "../../components/ResponseCardPhotoDialog";
import ResponseCardCommentDialog from "../../components/ResponseCardCommentDialog";
import { responseCardsContent } from "./get-response-cards-content";
import {
  OnSaveValue,
  HandleViewHistory,
  HandleViewComments,
  HandleViewPhotos,
} from "./types";
import { useFilterModal } from "./use-filter-modal";
import { useAgreementButtons } from "./use-agreement-buttons";
import {
  HOME_URL,
  TASKS_URL,
  TASK_DETAILS_URL,
  TEMPLATES_URL,
} from "../../urls";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";
import { StyledTooltip } from "../../components/StyledTooltip";
import ButtonColored from "../../components/ButtonColored";
// #endregion Imports

// A reference to the storage service, used to create references in our storage
// bucket. (An instance of the Storage service.)
const storage = getStorage();

interface Props {
  siteKey: string;
}

export default function TaskDetailsContainer({ siteKey }: Props) {
  const navigate = useNavigate();
  const isMounted = useRef(true);

  type UrlParams = { id: string };
  const { id: taskID } = useParams<UrlParams>();
  if (typeof taskID !== "string") {
    throw new Error(`taskID was not a string: ${taskID}`);
  }

  const queryClient = useQueryClient();

  const [isInProgressTask, setIsInProgressTask] = useState<boolean>(false);
  const [isCompletedTask, setIsCompletedTask] = useState<boolean>(false);

  const [selectedResponse, setSelectedResponse] =
    useState<ExistingChecklistResponse>();

  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  // Retrieve the list of checklist responses by TaskID.
  const queryChecklistResponses = [
    "taskDetailsContainer_checklistResponses",
    siteKey,
    taskID,
  ];
  const { data: listOfResponses = [], isLoading: listOfResponsesIsLoading } =
    useQuery(queryChecklistResponses, () =>
      DbRead.checklistResponses.getAllByTaskID(siteKey, taskID),
    );

  // retrieve the checklistPhotos collection by TaskID
  const queryChecklistPhotos = [
    "taskDetailsContainer_checklistPhotos",
    siteKey,
    taskID,
  ];
  const {
    data: allResponsePhotosList = [],
    isLoading: allResponsePhotosListIsLoading,
  } = useQuery(queryChecklistPhotos, () =>
    DbRead.checklistPhotos.getAllByTaskID(siteKey, taskID),
  );

  // retrieve the task doc
  const queryTaskDoc = ["taskDetailsContainer_taskDoc", siteKey, taskID];
  const { data: taskDoc, isLoading: taskDocIsLoading } = useQuery(
    queryTaskDoc,
    () => DbRead.tasks.getDocByID(siteKey, taskID),
  );

  const sortedAllResponsePhotos = useMemo(
    () => sortResponsePhotos(allResponsePhotosList),
    [allResponsePhotosList],
  );

  const selectedResponsePhotos = useMemo(
    () =>
      sortedAllResponsePhotos.filter((photo) => {
        const photoBelongsToResponse =
          photo.checklistResponseID === selectedResponse?.id;
        return photoBelongsToResponse;
      }),
    [sortedAllResponsePhotos, selectedResponse?.id],
  );

  const selectedResponseThumbnailAndDocIdList = useMemo(() => {
    return selectedResponsePhotos
      .map((photoDoc) => {
        return { thumbnail: photoDoc.thumbnailURL, docID: photoDoc.id };
      })
      .filter(
        (object): object is { thumbnail: string; docID: string } =>
          typeof object.docID === "string" &&
          typeof object.thumbnail === "string",
      );
  }, [selectedResponsePhotos]);

  const [craftRecordDocument, setCraftRecordDocument] =
    useState<ExistingCraftRecord>();

  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  function goToTasks(checklistID: string) {
    navigate(`${TASKS_URL}/${checklistID}`);
  }

  function handleViewTemplate(checklistID: string) {
    navigate(`${TEMPLATES_URL}/${checklistID}`);
  }

  // We are assuming this component is used within RouteAuthRequired.
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;

  // Fetches site key locations and sets location title
  const getLocationTitle = useSiteKeyLocationsStore(
    (state) => state.getLocationTitle,
  );

  let locationName: string = "";
  if (craftRecordDocument !== undefined) {
    locationName = getLocationTitle(craftRecordDocument?.locationID);
  }

  // Fetches the craft record doc
  useEffect(() => {
    async function getCraftRecord() {
      if (typeof taskID !== "string") {
        throw new Error(`taskID was not a string: ${taskID}`);
      }
      if (taskDoc === undefined) return;

      // Parse the craftRecordID from the task document
      const craftRecPath = taskDoc.craftRecordID;
      const craftRecordID = craftRecPath.substring(craftRecPath.length - 20);

      try {
        // Get the craftRecord document.
        const craftRecordDoc: ExistingCraftRecord = await DbRead.checklist.get(
          siteKey,
          craftRecordID,
        );

        // Get the title and require approval of craftRecord document.
        if (isMounted.current) {
          setCraftRecordDocument(craftRecordDoc);
        }
      } catch (error) {
        devLogger.error("Craft Record not found: ", error);
        addMessage({
          id: createToastMessageID(),
          message: strings.errorTemplateNotFoundForTask(taskID),
          type: "error",
          action: () => navigate(-1),
          dialog: false,
          actionText: "Go back",
        });
      }
    }

    getCraftRecord();
  }, [addMessage, navigate, siteKey, taskDoc, taskID]);

  //MUTATIONS
  const mutateUpdateComment = useMutation(
    async (args: { commentsUpdate: DocumentData; responseID: string }) =>
      await DbWrite.checklistResponses.updateComment(
        args.commentsUpdate,
        siteKey,
        args.responseID,
      ),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(queryChecklistResponses);
      },
    },
  );

  const mutateUpdateResponseValue = useMutation(
    async (args: { responseValueUpdate: DocumentData; responseID: string }) =>
      await DbWrite.checklistResponses.updateResponseValue(
        args.responseValueUpdate,
        siteKey,
        args.responseID,
      ),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(queryChecklistResponses);
      },
    },
  );

  const mutateUpdateTaskStatusToCompleted = useMutation(
    async (args: { taskUpdate: DocumentData; taskID: string }) =>
      await DbWrite.tasks.update(args.taskUpdate, siteKey, args.taskID),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(queryTaskDoc);
      },
    },
  );

  const mutateUpdateTaskStatusToAwaiting = useMutation(
    async (args: { updateToAwaiting: DocumentData; taskID: string }) =>
      await DbWrite.tasks.update(args.updateToAwaiting, siteKey, args.taskID),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(queryTaskDoc);
      },
    },
  );

  const mutateUpdateTaskStatusToInProgress = useMutation(
    async (args: { updateToInProgress: DocumentData; taskID: string }) =>
      await DbWrite.tasks.update(args.updateToInProgress, siteKey, args.taskID),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(queryTaskDoc);
      },
    },
  );

  /* This function is to update the comments field */
  async function onSaveComment(
    value: string,
    response: ExistingChecklistResponse,
  ) {
    let commentsUpdate: DocumentData = {};

    if (response.timestamp === null) {
      commentsUpdate = {
        comments: value,
        timestamp: null,
        uid: firebaseUser.uid,
      };
    } else {
      commentsUpdate = {
        comments: value,
        timestamp: response.timestamp,
        uid: firebaseUser.uid,
      };
    }
    try {
      mutateUpdateComment.mutateAsync({
        commentsUpdate,
        responseID: response.id,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.SAVED_CHANGES,
        dialog: false,
        type: "success",
      });
    } catch (error) {
      devLogger.error(`Error on saving comment:`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.errorSavingComment(response.id),
        dialog: false,
        type: "error",
      });
    }
  }

  /* This function is to update the ResponseValue wrote in the input field */
  const onSaveValue: OnSaveValue = async (value, responseID) => {
    const timestamp = Timestamp.now();
    let responseValueUpdate: DocumentData = {};
    let valueToWrite: string | number | null = null;
    if (value === "") {
      valueToWrite = null;
    } else {
      valueToWrite = value;
    }

    responseValueUpdate = {
      responseValue: valueToWrite,
      timestamp: timestamp,
      uid: firebaseUser.uid,
    };
    try {
      await mutateUpdateResponseValue.mutateAsync({
        responseValueUpdate,
        responseID,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.SAVED_CHANGES,
        dialog: false,
        type: "success",
      });
    } catch (error) {
      devLogger.error(`Error on saving response value:`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.errorSavingResponseValue(responseID),
        dialog: false,
        type: "error",
      });
    }
  };

  /* This function is to mark the taskStatus as completed after clicking the yellow button */
  async function handleUpdateTaskStatusToCompleted() {
    if (typeof taskID !== "string") {
      throw new Error(`taskID was not a string: ${taskID}`);
    }
    setIsCompletedTask(true);
    const timestamp = Timestamp.now();
    const taskUpdate: DocumentData = {
      taskStatus: 90,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: timestamp,
      timestampTaskCompleted: timestamp,
    };
    try {
      await mutateUpdateTaskStatusToCompleted.mutateAsync({
        taskUpdate,
        taskID,
      });
      devLogger.debug(`TaskStatus is marked as 'Complete'`);
    } catch (error) {
      devLogger.error(`Error on updating task status to complete:`, error);
    } finally {
      setIsCompletedTask(false);
    }
  }

  /** Change taskStatus to IN_PROGRESS. (If currentTaskStatus is SCHEDULED, it will
   * be changed to AWAITING first.) Takes the user to the response cards.
   */
  async function handleUpdateTaskStatusToInProgress(
    currentTaskStatus: ExistingTask["taskStatus"],
  ): Promise<void> {
    if (typeof taskID !== "string") {
      throw new Error(`taskID was not a string: ${taskID}`);
    }
    setIsInProgressTask(true);
    // If currentTaskStatus is SCHEDULED, set it to AWAITING before setting it to IN_PROGRESS.
    if (currentTaskStatus === 20) {
      const updateToAwaiting: DocumentData = {
        taskStatus: 30,
        lastModifiedBy: firebaseUser.uid,
        timestampLastModified: Timestamp.now(),
      };

      try {
        await mutateUpdateTaskStatusToAwaiting.mutateAsync({
          updateToAwaiting,
          taskID,
        });
        devLogger.debug(`TaskStatus is marked as 'AWAITING'`);
      } catch (error) {
        devLogger.error(`Error on updating task status to awaiting`, error);
      } finally {
        setIsInProgressTask(false);
      }
    }

    // Always set the taskStatus to IN_PROGRESS.
    const updateToInProgress: DocumentData = {
      taskStatus: 40,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
      timestampTaskStarted: Timestamp.now(),
    };

    try {
      await mutateUpdateTaskStatusToInProgress.mutateAsync({
        updateToInProgress,
        taskID,
      });
      devLogger.debug(`TaskStatus is marked as 'IN_PROGRESS'`);
    } catch (error) {
      devLogger.error(`Error on updating task status to in progress`, error);
    } finally {
      setIsInProgressTask(false);
    }
  }

  /* This function is to update the taskStatus after clicking the submit button at the end of the ResponseCardsList;
  it checks if all the requiredResponses */
  async function onSubmitRCPage(
    checklistRequireApprovals: boolean,
    checklistID: string,
  ) {
    if (typeof taskID !== "string") {
      throw new Error(`taskID was not a string: ${taskID}`);
    }
    const timestamp = Timestamp.now();
    let taskUpdate: DocumentData = {};
    if (taskDoc?.taskStatus !== 90) {
      if (checklistRequireApprovals === true) {
        taskUpdate = {
          taskStatus: 8,
          lastModifiedBy: firebaseUser.uid,
          timestampLastModified: timestamp,
        };
        await DbWrite.tasks.update(taskUpdate, siteKey, taskID);
        goToTasks(checklistID);
        devLogger.debug(`TaskStatus is updated to "Awaiting for approval"`);
      } else {
        taskUpdate = {
          taskStatus: 90,
          lastModifiedBy: firebaseUser.uid,
          timestampTaskCompleted: timestamp,
          timestampLastModified: timestamp,
        };
        await DbWrite.tasks.update(taskUpdate, siteKey, taskID);
        goToTasks(checklistID);
        devLogger.debug(`TaskStatus is updated to "Complete"`);
      }
    } else {
      devLogger.error(`The task is already completed`);
    }
  }

  // #region SECTION: Image Upload Related
  function onImgUpload(
    file: File,
    ids: Record<string, string | undefined>,
    changeFileInformation: (value: string) => void,
    changeIsUploading: (value: boolean) => void,
  ): void {
    // Use a random string as the file's name
    const newFileName = `${uuidv4()}.${file.type.split("/")[1]}`;

    // Upload file to the path starting with 'siteKeys'
    const storageRef = ref(
      storage,
      `siteKeys/${siteKey}/checklistPhotos/` + newFileName,
    );

    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    const unsubscribe = uploadTask.on("state_changed", {
      // the 'next' callback could give progress reports, ie: '79% done'
      next: null,
      error: handleUploadError,
      complete: onUploadComplete,
    });

    // This only gets called if there is an error.
    function handleUploadError(error: StorageError) {
      switch (error.code) {
        case "storage/unauthorized":
          changeFileInformation(
            strings.UPLOAD_ERROR_USER_NOT_PERMITTED_TO_UPLOAD_PHOTO,
          );
          changeIsUploading(false);
          devLogger.error("Unauthorized user attempting to upload an image.");
          break;
        case "storage/quota-exceeded":
          changeFileInformation(strings.UPLOAD_ERROR_QUOTA_EXCEEDED);
          changeIsUploading(false);
          devLogger.error("Quota on Cloud Storage bucket has been exceeded.");
          break;
        case "storage/unknown":
          changeFileInformation(strings.GENERIC_ERROR_MESSAGE);
          changeIsUploading(false);
          devLogger.error(
            "Unknown error occurred, inspect error.serverResponse",
          );
          break;
        default:
          changeFileInformation(strings.UPLOAD_ERROR_UNABLE);
          changeIsUploading(false);
          devLogger.error("Unknown error occurred while uploading image.");
      }

      // If there has been an error during upload, unsubscribe from uploadTask.on().
      unsubscribe();
    }

    // Upload completed successfully.
    async function onUploadComplete() {
      try {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        const timestamp = Timestamp.now();

        if (
          typeof ids.checklistResponseID === "string" &&
          typeof ids.taskID === "string" &&
          typeof ids.craftRecordID === "string"
        ) {
          await afterUploadComplete({
            photoURL: downloadURL,
            timestampCreated: timestamp,
            checklistResponseID: ids.checklistResponseID,
            taskID: ids.taskID,
            craftRecordID: ids.craftRecordID,
            thumbnailURL: null,
            photoURL_reduced: null,
            createdBy: firebaseUser.uid,
            deleted: false,
          });
          changeFileInformation(strings.UPLOAD_SUCCESS_SERVER_PROCESSING);
          changeIsUploading(false);

          // Unsubscribe from uploadTask.on(), after upload is complete.
          unsubscribe();
        }
      } catch (err) {
        // Set the text to show the user.
        changeFileInformation(strings.UPLOAD_ERROR_UNABLE);
        changeIsUploading(false);
        devLogger.error("Couldn't get download URL -> ", err);
      }
    }
  }

  async function afterUploadComplete({
    photoURL,
    checklistResponseID,
    taskID,
    craftRecordID,
    thumbnailURL,
    photoURL_reduced,
    timestampCreated,
    createdBy,
    deleted,
  }: ChecklistPhoto) {
    const docData = {
      photoURL,
      checklistResponseID,
      taskID,
      craftRecordID,
      thumbnailURL,
      photoURL_reduced,
      timestampCreated,
      createdBy,
      deleted,
    };

    const photoDoc = ChecklistPhotoManager.parse(docData);
    await DbWrite.checklistPhotos.add(siteKey, photoDoc);
  }
  // #endregion

  async function handleDeletePhoto(docID: string): Promise<void> {
    await DbWrite.checklistPhotos.delete(siteKey, docID);
    devLogger.debug(`Photo doc ${docID} has been 'deleted'`);
  }

  /* function that calculate the total of the response answered and the percentage of the responses passing */
  const numResponsesCompleted = useMemo(() => {
    const RespCompleted = listOfResponses.filter(
      (response) => response.responseValue != null,
    );
    return RespCompleted.length;
  }, [listOfResponses]);

  const percentageResponsesPassing = useMemo(() => {
    const totalResponsesPassing = listOfResponses.filter(
      (response) => response.responsePassing === "yes",
    );
    const percentage =
      (100 * totalResponsesPassing.length) / numResponsesCompleted;
    return Math.round(percentage);
  }, [listOfResponses, numResponsesCompleted]);

  // RESPONSE HISTORY DIALOG
  // TODO: extract to hooook?

  const [isRHDOpen, setIsRHDOpen] = useState(false);
  function closeResponseHistoryDialog() {
    setIsRHDOpen(false);
  }
  const [historyResponseList, setHistoryResponseList] = useState<
    ExistingChecklistResponse[]
  >([]);

  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const handleViewHistory: HandleViewHistory = useCallback(
    async function (response: ExistingChecklistResponse) {
      setSelectedResponse(response);
      setIsHistoryLoading(true);
      setIsRHDOpen(true);
      const previousResponsesList = await DbRead.checklistResponses.getByItemID(
        siteKey,
        response.checklistItemID,
      );
      setHistoryResponseList(previousResponsesList);

      setIsHistoryLoading(false);
    },
    [siteKey],
  );

  // PHOTOS DIALOG
  // TODO: extract to hooook?
  const [showTablePhotoDialog, setShowTablePhotoDialog] = useState(false);
  const [showRCPhotoDialog, setShowRCPhotoDialog] = useState(false);
  const [showReducedPhotoGallery, setShowReducedPhotoGallery] = useState(false);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);

  // Sort documents by timestamp, so they appear in the order the user expects.
  // modifies in place
  allResponsePhotosList.sort((first, second) => {
    return (
      second.timestampCreated.toMillis() - first.timestampCreated.toMillis()
    );
  });

  //🦌🦌🦌🦌🦌🦌 dan is best
  function filterResponsePhotosForResponseTablePhotoDialog(
    input: ExistingChecklistPhoto[],
  ) {
    return input
      .map((photoDoc) => {
        return { thumbnail: photoDoc.thumbnailURL, docID: photoDoc.id };
      })
      .filter(
        (object): object is { thumbnail: string; docID: string } =>
          typeof object.docID === "string" &&
          typeof object.thumbnail === "string",
      );
  }

  function filterResponsePhotosForResponseReducedGallery(
    input: ExistingChecklistPhoto[],
  ) {
    return input
      .map((photoDoc) => {
        return {
          original: photoDoc.photoURL_reduced,
          thumbnail: photoDoc.thumbnailURL,
        };
      })
      .filter(
        (item): item is { original: string; thumbnail: string } =>
          item.original != null && item.thumbnail != null,
      );
  }

  function openReducedGallery(clickedIndex: number): void {
    // Close whichever dialog was open
    showTablePhotoDialog ?? setShowTablePhotoDialog(false);
    showRCPhotoDialog ?? setShowRCPhotoDialog(false);

    setShowReducedPhotoGallery(true);
    setThumbnailIndex(clickedIndex);
  }

  function closeReducedGallery(): void {
    setShowReducedPhotoGallery(false);

    // Open whichever dialog was open
    isResponseCardsView
      ? setShowRCPhotoDialog(true)
      : setShowTablePhotoDialog(true);
  }

  // TASK STATUS DIALOG
  const [showTaskStatusDialog, setShowTaskStatusDialog] = useState(false);

  // COMMENT DIALOG
  // TODO: extract to hooook?
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const handleViewComments: HandleViewComments = (
    response: ExistingChecklistResponse,
  ) => {
    setSelectedResponse(response);
    setShowCommentDialog(true);
  };

  // For changing between card list view and table view.
  const [isResponseCardsView, setIsResponseCardsView] = useState(false);
  useEffect(() => {
    setIsResponseCardsView(taskDoc?.taskStatus === TaskStatus.IN_PROGRESS);
  }, [taskDoc?.taskStatus]);

  function switchToTableView() {
    setIsResponseCardsView(false);
  }

  const handleViewPhotos: HandleViewPhotos = useCallback(
    function (response: ExistingChecklistResponse) {
      setSelectedResponse(response);

      isResponseCardsView
        ? setShowRCPhotoDialog(true)
        : setShowTablePhotoDialog(true);
    },
    [isResponseCardsView],
  );

  //FILTER RESPONSE CARDS
  const {
    FilterButton,
    FilterModal,
    showNotificationDot,
    filteredResponseList,
  } = useFilterModal(listOfResponses);

  const orderedResponseList = useMemo(
    () => sortByOrderNum(filteredResponseList),
    [filteredResponseList],
  );

  // SUBMIT & AGREEMENT BUTTONS
  const unansweredResponses = useMemo(
    () => countOfUnansweredResponses(orderedResponseList),
    [orderedResponseList],
  );

  // Hide submitForApprovalText Button if taskStatus is not IN_PROGRESS
  const disableButton = taskDoc?.taskStatus !== 40;

  const { agreementButtons, agreementMessage, showAgreement, SubmitButton } =
    useAgreementButtons({
      onSubmitCallback: onSubmitRCPage,
      unansweredResponses: unansweredResponses,
      craftRecordID: craftRecordDocument?.id,
      requireApprovals: craftRecordDocument?.craftDetails.requireApprovals,
    });

  // RENDER LOADING
  if (
    taskDocIsLoading ||
    !craftRecordDocument ||
    !taskDoc ||
    listOfResponsesIsLoading ||
    allResponsePhotosListIsLoading
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  //TASK STATUS BUTTON
  const taskDisableButton =
    taskDoc.taskStatus === TaskStatus.AWAITING_APPROVAL ? false : true;

  const TaskButton = (): JSX.Element => {
    if (taskDoc.taskStatus === TaskStatus.COMPLETE) {
      return (
        <ButtonColored
          className="w-full"
          disabled={taskDisableButton}
          kind="info"
        >
          Complete
        </ButtonColored>
      );
    } else if (taskDoc.taskStatus === TaskStatus.AWAITING_APPROVAL) {
      return (
        <ButtonColored
          className="w-full"
          disabled={taskDisableButton}
          kind="warning"
          isBusy={isCompletedTask}
          busyText={strings.buttons.BUSY_APPROVING}
          onClick={handleUpdateTaskStatusToCompleted}
        >
          Approve / Mark Completed
        </ButtonColored>
      );
    } else if (taskDoc.taskStatus === TaskStatus.IN_PROGRESS) {
      return (
        <ButtonColored className="w-full" disabled={true} kind="warning">
          In Progress
        </ButtonColored>
      );
    } else {
      // If taskStatus is SCHEDULED, AWAITING, or ON_HOLD (..or anything else)
      return (
        <ButtonColored
          className="w-full"
          kind="warning"
          isBusy={isInProgressTask}
          busyText={strings.buttons.BUSY_BEGINNING}
          onClick={() => handleUpdateTaskStatusToInProgress(taskDoc.taskStatus)}
        >
          Begin Task
        </ButtonColored>
      );
    }
  };

  // BREADCRUMBS
  const home = { name: "Checklists", href: HOME_URL, current: false };
  const pages = [
    { name: "Tasks", href: HOME_URL, current: false },
    {
      name: `${craftRecordDocument.title}`,
      href: `${TASK_DETAILS_URL}/${taskDoc.id}`,
      current: true,
    },
  ];

  // PAGE HEADING
  const pageHeading = (
    <>
      <PageHeading title={craftRecordDocument?.title} detailsInfoArea={true} />
      <BaseButtonPrimary
        className="my-2 w-1/2 border border-white md:mt-2 lg:w-1/4"
        onClick={() => {
          if (typeof craftRecordDocument.id === "string") {
            return handleViewTemplate(craftRecordDocument.id);
          } else {
            devLogger.warn("Expected craft record to have id property.");
            return undefined;
          }
        }}
      >
        {strings.buttons.VIEW_TEMPLATE}
      </BaseButtonPrimary>
    </>
  );

  // TEMPLATE INFO
  const templateInfo = (
    <TemplateDetailsInfoArea
      template={craftRecordDocument}
      userDisplayNamesMap={userDisplayNamesMap}
      locationName={locationName}
      isTaskDetails={true}
    />
  );

  // TASK INFO
  const taskInfo = (
    <TaskDetailsInfoArea
      task={taskDoc}
      userDisplayNamesMap={userDisplayNamesMap}
      responsesCompleted={numResponsesCompleted}
      totalResponses={filteredResponseList.length}
      percentageResponsesPassing={percentageResponsesPassing}
    >
      {{
        TaskButton: TaskButton(),
      }}
    </TaskDetailsInfoArea>
  );

  // RESPONSE CARDS LIST
  const ViewRCPageButton = (
    <StyledTooltip title="Fill out responses">
      <button
        className="text-primary"
        onClick={() => {
          if (taskDoc.id && taskDoc.taskStatus === 40) {
            setIsResponseCardsView(true);
          } else {
            devLogger.debug("taskStatus is not IN_PROGRESS");
            setShowTaskStatusDialog(true);
          }
        }}
      >
        <ViewAgendaIcon
          aria-label="view response card list"
          sx={{ fontSize: 40 }}
        />
      </button>
    </StyledTooltip>
  );

  const listOfResponsesButton = (
    <StyledTooltip title="View table of responses">
      <button
        className="text-primary"
        onClick={() => {
          if (taskDoc.id) {
            switchToTableView();
          } else {
            devLogger.warn("task id is undefined");
          }
        }}
      >
        <TableChartIcon
          aria-label="view response list table"
          sx={{ fontSize: 40 }}
        />
      </button>
    </StyledTooltip>
  );

  const responseCardsList = (
    <ResponseCardsList
      responsesCompleted={numResponsesCompleted}
      task={taskDoc}
      showNotification={showNotificationDot}
      showAgreement={showAgreement}
      agreementMessage={agreementMessage}
    >
      {{
        content: responseCardsContent({
          handleViewComments,
          handleViewHistory,
          handleViewPhotos,
          onSaveValue,
          photoList: sortedAllResponsePhotos,
          responseList: orderedResponseList,
        }),
        filterButton: FilterButton,
        agreementButtons: agreementButtons,
        submitButton: <SubmitButton disabled={disableButton} />,
        listOfResponsesButton: listOfResponsesButton,
      }}
    </ResponseCardsList>
  );

  // TABLE OF RESPONSES
  const responseTable = (
    <ChecklistResponsesTable
      responseList={orderedResponseList}
      responsePhotos={sortedAllResponsePhotos}
      onViewHistory={handleViewHistory}
      onViewPhotos={handleViewPhotos}
      showNotification={showNotificationDot}
    >
      {{
        viewRCPageButton: ViewRCPageButton,
        filterButton: FilterButton,
      }}
    </ChecklistResponsesTable>
  );

  // RENDER CONTENT
  return (
    <>
      <TaskDetailsLayout isResponseCardsView={isResponseCardsView}>
        {{
          breadcrumbs: <Breadcrumbs home={home} pages={pages} />,
          pageHeading: pageHeading,
          templateInfo: templateInfo,
          taskInfo: taskInfo,
          responseCardsList: responseCardsList,
          responseTable: responseTable,
        }}
      </TaskDetailsLayout>

      {/* DIALOGS */}
      {FilterModal}

      {selectedResponse && (
        <ResponseHistoryDialog
          incomingHistoryData={historyResponseList}
          isDialogOpen={isRHDOpen}
          isHistoryLoading={isHistoryLoading}
          theMainText={selectedResponse?.mainText ?? ""}
          closeDialog={closeResponseHistoryDialog}
          responseID={selectedResponse?.id}
        />
      )}
      {selectedResponse && (
        <ResponseTablePhotoDialog
          isDialogOpen={showTablePhotoDialog}
          closeDialog={() => setShowTablePhotoDialog(false)}
          thumbnailPhotosWithDocID={filterResponsePhotosForResponseTablePhotoDialog(
            selectedResponsePhotos,
          )}
          itemMainText={selectedResponse?.mainText ?? ""}
          openReducedGallery={openReducedGallery}
          onDeletePhoto={handleDeletePhoto}
        />
      )}
      {selectedResponse && (
        <ResponseReducedGallery
          isDialogOpen={showReducedPhotoGallery}
          closeDialog={closeReducedGallery}
          photoList={filterResponsePhotosForResponseReducedGallery(
            selectedResponsePhotos,
          )}
          itemMainText={selectedResponse.mainText ?? ""}
          thumbnailIndex={thumbnailIndex}
        />
      )}

      {selectedResponse && (
        <ResponseCardPhotoDialog
          isDialogOpen={showRCPhotoDialog}
          closeDialog={() => setShowRCPhotoDialog(false)}
          thumbnailPhotosWithDocID={selectedResponseThumbnailAndDocIdList}
          itemMainText={selectedResponse.mainText}
          uploadIDs={{
            checklistResponseID: selectedResponse.id,
            taskID: selectedResponse.taskID,
            craftRecordID: selectedResponse.craftRecordID,
          }}
          onImgUpload={onImgUpload}
          openReducedGallery={openReducedGallery}
          onDeletePhoto={handleDeletePhoto}
        />
      )}
      {selectedResponse && (
        <ResponseCardCommentDialog
          isDialogOpen={showCommentDialog}
          closeDialog={() => setShowCommentDialog(false)}
          response={selectedResponse}
          onSaveComment={onSaveComment}
        />
      )}

      <TaskStatusDialog
        isDialogOpen={showTaskStatusDialog}
        closeDialog={() => setShowTaskStatusDialog(false)}
        handleUpdateTaskStatus={handleUpdateTaskStatusToInProgress}
        currentTaskStatus={taskDoc.taskStatus}
      />
    </>
  );
}

/* this function is used to count the unanswered response for the agreement */
function countOfUnansweredResponses(responseList: ExistingChecklistResponse[]) {
  const RequiredResponses = responseList.filter(
    (response) => response.required !== false,
  );
  const RequiredResponsesAnswered = RequiredResponses.filter(
    (response) => response.responseValue != null,
  );
  const diffRequiredResponses =
    RequiredResponses.length - RequiredResponsesAnswered.length;

  return diffRequiredResponses;
}
