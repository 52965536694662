// Libs
import { Fragment } from "react";

// Local
import EscapeIframeNavButton from "./EscapeIframeNavButton";

type PagesProps = {
  name: string;
  current: boolean;
  /** If current = true, there's no need to pass in an href. Use null */
  href: string | null;
};

type HomeProps = {
  name: string;
  href: string;
};

interface Props {
  pages: PagesProps[];
  home: HomeProps;
  backButtonHref: string;
}

export default function EscapeIframeBreadcrumbs(props: Props) {
  return (
    <>
      <div className="hidden items-center sm:flex">
        <EscapeIframeNavButton href={props.backButtonHref} />
        <nav className="flex" aria-label="breadcrumbs">
          <ol className="flex rounded-md bg-white px-2 shadow">
            <li className="flex">
              <div className="flex items-center">
                <a
                  href={props.home.href}
                  // _PARENT is the special sauce. It targets the iframe's parent.
                  target="_PARENT"
                  className="px-4 py-2 text-sm font-medium capitalize text-gray-500 hover:text-gray-700"
                >
                  {props.home.name}
                </a>
              </div>
            </li>
            {props.pages.map((page) => (
              <li key={page.name} className="flex">
                <div className="flex items-center">
                  <svg
                    className="h-full w-6 flex-shrink-0 text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  {page.href ? (
                    <a
                      href={page.href}
                      target="_PARENT"
                      className={`px-4 py-2 text-sm font-medium capitalize ${
                        page.current
                          ? "text-primary"
                          : "text-gray-500 hover:text-gray-900"
                      }`}
                    >
                      {page.name}
                    </a>
                  ) : (
                    <span
                      className={`px-4 py-2 text-sm font-medium capitalize ${
                        page.current
                          ? "text-primary"
                          : "text-gray-500 hover:text-gray-900"
                      }`}
                    >
                      {page.name}
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>

      {/* For small width viewports */}
      <div className="sm:hidden">
        <EscapeIframeNavButton href={props.backButtonHref} />
        <nav className="mt-1.5 grid grid-cols-1" aria-label="breadcrumbs">
          <a
            href={props.home.href}
            target="_PARENT"
            className="mb-2 max-w-fit text-left font-medium capitalize"
          >
            {props.home.name} {`>`}
          </a>
          {props.pages.map((page, i) =>
            page.href ? (
              <a
                key={page.name}
                href={page.href}
                target="_PARENT"
                className="mb-2 text-left font-medium capitalize"
              >
                {page.name}
                {props.pages.length - 1 > i ? ` >` : ""}
              </a>
            ) : (
              <Fragment key={page.name}>
                <span className="mb-2 text-left font-medium capitalize">
                  {page.name}
                </span>
                {props.pages.length - 1 > i ? ` >` : ""}
              </Fragment>
            ),
          )}
        </nav>
      </div>
    </>
  );
}
