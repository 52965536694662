//Libs
import {
  CellContextMenuEvent,
  ColDef,
  GridApi,
  ICellRendererParams,
  ITooltipParams,
  RowClickedEvent,
  ValueFormatterParams,
  ValueGetterFunc,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import React from "react";
import isEqual from "lodash/isEqual";

//Local
import { ExistingTask } from "../../models/task";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { getReadableCraftType } from "../../models/craft-types";
import { getReadableTaskType } from "../../models/task-types";
import { getReadableTaskStatus } from "../../models/task-status";
import * as strings from "../../strings";
import SearchBox from "../SearchBox";
import TaskStatusCustomFilter from "../tables/TaskStatusCustomFilter";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { getTaskStatusPillClasses } from "../../assets/js/tasks";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import TaskTypeCustomFilter from "../tables/TaskTypeCustomFilter";

interface CustomerTaskListTableProps {
  tasksTableData: ExistingTask[];
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  goToWorkRecordAndTasksPage: (
    craftRecordID: ExistingTask["craftRecordID"],
  ) => void;
  children: {
    DropdownSelectionTasks: React.ReactNode;
  };
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  width: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

const CustomerTaskListTable = React.memo(
  ({
    tasksTableData,
    renderIconCell,
    goToWorkRecordAndTasksPage,
    ...props
  }: CustomerTaskListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        const api: GridApi = gridRef.current.api;
        api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    /* RENDER CELL COMPONENTS */

    const readableCraftType: ValueGetterFunc = (params) => {
      return getReadableCraftType(params.data.craftType);
    };

    const renderTaskType = (params: ICellRendererParams): JSX.Element => {
      return <div>{getReadableTaskType(params.data.taskType)}</div>;
    };

    const readableTaskStatus = (params: ICellRendererParams): JSX.Element => {
      return (
        <div className="flex items-center">
          <div
            className={`rounded-full px-2 py-1 text-xs font-medium ${getTaskStatusPillClasses(params.data.taskStatus)}`}
          >
            {getReadableTaskStatus(params.data.taskStatus)}
          </div>
        </div>
      );
    };

    const renderTimestampScheduled = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return (
        <div>
          {params.data.timestampScheduled != null
            ? convertToReadableTimestamp(params.data.timestampScheduled)
            : "Not scheduled"}
        </div>
      );
    };

    const renderScheduleFlags = (
      params: ICellRendererParams,
    ): JSX.Element | null => {
      if (params.data.urgent === true) {
        return (
          <div className="flex items-center">
            <div className="m-1 rounded-full bg-orange-400 px-2.5 pb-1 pt-0.5 text-xs font-medium text-white">
              {strings.URGENT}
            </div>
          </div>
        );
      } else if (params.data.nextOpportunity === true) {
        return (
          <div className="flex items-center">
            <div className="m-1 rounded-full bg-green-400 px-2.5 pb-1 pt-0.5 text-xs font-medium text-white">
              {strings.NEXT_OPPORTUNITY}
            </div>
          </div>
        );
      } else {
        return null;
      }
    };

    const renderTitle = (params: ICellRendererParams): JSX.Element => {
      return <div className="capitalize text-primary">{params.data.title}</div>;
    };

    const taskTableColumnsDefs: ColDef[] = [
      {
        field: "title",
        headerName: "Title",
        cellRenderer: renderTitle,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "taskStatus",
        headerName: "Task Status",
        filter: TaskStatusCustomFilter,
        cellRenderer: readableTaskStatus,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
      {
        field: "craftType",
        headerName: "Work Type",
        valueGetter: readableCraftType,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "taskType",
        headerName: "Task Type",
        filter: TaskTypeCustomFilter,
        cellRenderer: renderTaskType,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Schedule Flags",
        cellRenderer: renderScheduleFlags,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        valueGetter: (params) => getScheduleFlagsValue(params),
        tooltipValueGetter: (params) => getScheduleFlagsValue(params),
        comparator: (valueA, valueB) => {
          if (valueA === valueB) return 0;
          return valueA > valueB ? 1 : -1;
        },
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
      {
        field: "timestampScheduled",
        headerName: "Scheduled",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        cellRenderer: renderTimestampScheduled,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        cellRenderer: renderIconCell,
        colId: "actionColumnId",
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        suppressAutoSize: true,
        suppressSizeToFit: true,
        sortable: false,
        flex: 0,
      },
    ];

    return (
      <div className="ag-theme-alpine flex h-[300px] flex-col">
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox onInput={onFilterTextBoxChanged} />
          <div>{props.children.DropdownSelectionTasks}</div>
        </div>
        <AgGridReact
          reactiveCustomComponents
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={tasksTableData.sort((a, b) => {
            // if timestampScheduled is null, replace with timestampCreated, otherwise sort by timestampScheduled
            const aTimestamp = a.timestampScheduled ?? a.timestampCreated;
            const bTimestamp = b.timestampScheduled ?? b.timestampCreated;
            return aTimestamp < bTimestamp ? 1 : -1;
          })}
          animateRows={true}
          rowSelection="single"
          columnDefs={taskTableColumnsDefs}
          onRowClicked={(event: RowClickedEvent) => {
            goToWorkRecordAndTasksPage(event.data.craftRecordID.split("/")[3]);
          }}
          onCellContextMenu={(event: CellContextMenuEvent<any, any>) => {
            const craftRecordID = event.data.craftRecordID.split("/")[3];
            const url = `${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`;
            return window.open(url, "_blank");
          }}
        />
      </div>
    );
  },
  (previous, next) => {
    const isTaskListTheSame = isEqual(
      previous.tasksTableData,
      next.tasksTableData,
    );

    return isTaskListTheSame;
  },
);

export default CustomerTaskListTable;

export function getScheduleFlagsValue(
  params: ITooltipParams | ValueGetterParams,
): string {
  if (params.data.urgent) {
    return "urgent";
  } else if (params.data.nextOpportunity) {
    return "next opportunity";
  } else {
    return "";
  }
}
