//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import BaseModal from "./BaseModal";
import BaseButtonSecondary from "./BaseButtonSecondary";
import { buttons } from "../strings";
import BaseButtonPrimary from "./BaseButtonPrimary";

export interface OutboundCallDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDial: () => Promise<void>;
  isSubmitting: boolean;
  title: string;
  body: string;
}

export default function OutboundCallDialog(props: OutboundCallDialogProps) {
  return (
    <BaseModal
      open={props.isOpen}
      closeModal={() => {}}
      allowOverflowY={true}
      title={
        <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
          <h1 className="inline-flex items-center text-xl font-semibold ">
            {props.title}
          </h1>
          <button type="button" onClick={() => props.onClose()}>
            <XMarkIcon
              aria-label="confirm delete equipment"
              className="h-6 text-white"
            />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <div>{props.body}</div>
        <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase xs:w-52"
            onClick={props.onClose}
          >
            {buttons.NO}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            disabled={props.isSubmitting}
            isBusy={props.isSubmitting}
            onClick={() => props.handleConfirmDial()}
            className="w-full justify-center uppercase xs:w-52"
          >
            {buttons.YES}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
