//Libs
import { DateTime } from "luxon";

//Local
import {
  ExistingTask,
  ExistingTaskWithCustomerLocation,
} from "../../../models/task";
import { ExistingVehicle } from "../../../models/vehicle";
import { convertObjectToFSTimestamp } from "../../../utils/convertObjectToFSTimestamp";
import { CalendarEvent } from "../../../models/scheduling-types";
import { getBarColor } from "./getBarColor";
import { TaskStatus } from "../../../models/task-status";
import { getDotColor } from "./getDotColor";
import { getBackColor } from "./getBackColor";
import { ExistingZone } from "../../../models/zone";

interface Props {
  allocatedTasks: ExistingTaskWithCustomerLocation[];
  vehicleList: ExistingVehicle[];
  searchTerm: string;
  mode: string | null;
  inactiveUserIDs: string[];
  unapprovedUserIDs: string[];
  zoneList: ExistingZone[];
}

export function generateCalendarEvents({
  allocatedTasks,
  mode,
  searchTerm,
  vehicleList,
  inactiveUserIDs,
  unapprovedUserIDs,
  zoneList,
}: Props) {
  if (searchTerm !== "") {
    allocatedTasks = allocatedTasks.filter((task) => {
      return searchTerm
        .toLowerCase()
        .split(" ")
        .every((value) => task.title.toLowerCase().includes(value));
    });
  }
  const calendarEvents: CalendarEvent[] = [];

  allocatedTasks.forEach((task) => {
    const scheduledServiceWindowStart =
      task.taskSpecificDetails["scheduledServiceWindowStart"];
    const scheduledServiceWindowEnd =
      task.taskSpecificDetails["scheduledServiceWindowEnd"];

    const timestampScheduled = task.timestampScheduled;
    const scheduledWorkDuration =
      task.taskSpecificDetails["scheduledWorkDuration"];

    if (!scheduledServiceWindowStart && !scheduledServiceWindowEnd) {
      if (!scheduledWorkDuration && !timestampScheduled) {
        // No times to use, so this task won't get put on the board
        return;
      }
    }

    let startDate: DateTime | null = null;
    let endDate: DateTime | null = null;
    if (scheduledServiceWindowStart && scheduledServiceWindowEnd) {
      startDate = DateTime.fromMillis(
        convertObjectToFSTimestamp(scheduledServiceWindowStart).toMillis(),
      );
      endDate = DateTime.fromMillis(
        convertObjectToFSTimestamp(scheduledServiceWindowEnd).toMillis(),
      );
    } else if (
      typeof scheduledWorkDuration === "number" &&
      timestampScheduled
    ) {
      startDate = DateTime.fromMillis(timestampScheduled.toMillis());
      endDate = startDate.plus({ hour: scheduledWorkDuration });
    }

    if (!startDate || !endDate) {
      return;
    }

    const startDateString = startDate.toISO({
      includeOffset: false,
      suppressMilliseconds: true,
    });
    const endDateString = endDate.toISO({
      includeOffset: false,
      suppressMilliseconds: true,
    });

    let vehicleID = "Unassigned";
    if (mode === "vehicles") {
      const assignedVehicleID = task.taskSpecificDetails["assignedVehicleID"];
      const assignedVehicle = vehicleList.filter(
        (vehicle) => vehicle.id === assignedVehicleID,
      )[0];
      if (assignedVehicle) {
        vehicleID = assignedVehicle.id;
      }

      const barColor = getBarColor(task);
      const dotColor = getDotColor(task, zoneList);
      const backColor = getBackColor(task, zoneList);
      // console.log(DayPilot.guid());
      const event: CalendarEvent = {
        id: calendarEvents.length + 1,
        start: startDateString,
        end: endDateString,
        resource: vehicleID ?? "Unassigned",
        text: task.title,
        barColor: barColor,
        backColor: backColor,
        dotColor: dotColor,
        portable: task.taskSpecificDetails["portableJob"]
          ? (task.taskSpecificDetails["portableJob"] as boolean)
          : false,
        tags: {
          taskID: task.id,
          resourceID: vehicleID ?? "Unassigned",
          craftRecordRefPath: task.craftRecordID,
          task: task,
        },
        moveDisabled: isMoveDisabled(task.taskStatus),
        resizeDisabled: false,
      };
      calendarEvents.push(event);
    }

    if (mode === "technicians") {
      const assignedUsers = task.taskSpecificDetails["assignedTo"];
      if (
        assignedUsers !== null &&
        Array.isArray(assignedUsers) &&
        assignedUsers.length > 0
      ) {
        for (let userId of assignedUsers) {
          if (typeof userId === "string") {
            const barColor = getBarColor(task);
            const dotColor = getDotColor(task, zoneList);
            const backColor = getBackColor(task, zoneList);

            if (
              inactiveUserIDs.includes(userId) ||
              unapprovedUserIDs.includes(userId)
            ) {
              userId = "Unassigned";
            }
            const event: CalendarEvent = {
              id: calendarEvents.length + 1,
              start: startDateString,
              end: endDateString,
              resource: userId ?? "Unassigned",
              text: task.title,
              barColor: barColor,
              backColor: backColor,
              dotColor: dotColor,
              portable: task.taskSpecificDetails["portableJob"]
                ? (task.taskSpecificDetails["portableJob"] as boolean)
                : false,
              tags: {
                taskID: task.id,
                resourceID: userId ?? "Unassigned",
                craftRecordRefPath: task.craftRecordID,
                task: task,
              },
              moveDisabled: isMoveDisabled(task.taskStatus),
              resizeDisabled: false,
            };
            calendarEvents.push(event);
          }
        }
      } else {
        const barColor = getBarColor(task);
        const dotColor = getDotColor(task, zoneList);
        const backColor = getBackColor(task, zoneList);

        const event: CalendarEvent = {
          id: calendarEvents.length + 1,
          start: startDateString,
          end: endDateString,
          resource: "Unassigned",
          text: task.title,
          barColor: barColor,
          backColor: backColor,
          dotColor: dotColor,
          portable: task.taskSpecificDetails["portableJob"]
            ? (task.taskSpecificDetails["portableJob"] as boolean)
            : false,
          tags: {
            taskID: task.id,
            craftRecordRefPath: task.craftRecordID,
            task: task,
          },
          moveDisabled: isMoveDisabled(task.taskStatus),
          resizeDisabled: false,
        };
        calendarEvents.push(event);
      }
    }
  });

  return calendarEvents;
}

function isMoveDisabled(taskStatus: ExistingTask["taskStatus"]): boolean {
  switch (taskStatus) {
    case TaskStatus.AWAITING:
      return false;
    case TaskStatus.SCHEDULED:
      return false;
    default:
      return true;
  }
}
