import { logger } from "../../../logging";
import { ExistingSiteKey } from "../../../models/site-key";

export function awaitingEstimateEnabled(
  siteKey: ExistingSiteKey,
  craftType: number,
  taskType: number,
): boolean {
  try {
    if (siteKey.customizations == null) {
      return false;
    }
    if (!("awaitingEstimateEnabled" in siteKey.customizations)) {
      return false;
    }

    const _awaitingEstimateEnabled = siteKey.customizations[
      "awaitingEstimateEnabled"
    ] as Record<string, number>[];

    if (
      _awaitingEstimateEnabled == null ||
      _awaitingEstimateEnabled.length === 0
    ) {
      return false;
    }

    for (const index in _awaitingEstimateEnabled) {
      const craftTaskType = _awaitingEstimateEnabled[index] as Record<
        string,
        number
      >;

      if (
        craftTaskType["craftType"] === craftType &&
        craftTaskType["taskType"] === taskType
      ) {
        return true;
      }
    }
    return false;
  } catch (error) {
    logger.error(error);
    // And return false as the default
    return false;
  }
}
