// libs
import { useState, useEffect } from "react";
import { DateTime } from "luxon";

// local
import { DbRead, DbWrite } from "../../database";
import ReportsListLayout from "./ReportsListLayout";
import { ReportConfig, ReportData, ReportSpec } from "../../models/reports";
import { useStiltNav } from "../../navigation";
import { logger } from "../../logging";

interface Props {
  siteKey: string;
  userId: string;
}

export default function ReportsContainer(props: Props): JSX.Element {
  const [reportTypes, setReportTypes] = useState<Record<string, ReportSpec>>(
    {},
  );
  const [reportTypesLoading, setReportTypesLoading] = useState(true);
  const [reportConfigs, setReportConfigs] = useState<ReportConfig[]>([]);
  const [reportData, setReportData] = useState<ReportData[]>([]);

  const stiltNav = useStiltNav();
  function handleNavToReportSpecPage(
    reportType: string,
    reportSpec: ReportSpec,
    reportConfig?: ReportConfig,
  ) {
    stiltNav.reportSpecPage({
      reportType,
      reportSpec,
      reportConfig,
    });
  }

  useEffect(() => {
    const fetchReportTypes = async () => {
      setReportTypesLoading(true);
      const fetchedReportTypes = await DbRead.reports.getTypesWithSpec(
        props.siteKey,
      );
      setReportTypes(fetchedReportTypes);
      setReportTypesLoading(false);
    };
    fetchReportTypes();
  }, [props.siteKey]);

  useEffect(() => {
    async function getConfigs() {
      const unsubscribeConfigsByUID = DbRead.reports.subscribeConfigsByUID({
        siteKey: props.siteKey,
        uid: props.userId,
        onChange: setReportConfigs,
        onError: (e) =>
          logger.error(`Subscribe reportConfigs: ${e.message}`, e),
      });
      return unsubscribeConfigsByUID;
    }
    const promise = getConfigs();

    return () => {
      promise.then((unsub) => {
        if (unsub) unsub();
      });
    };
  }, [props.siteKey, props.userId]);

  useEffect(() => {
    async function getData() {
      const unsubscribeDataByUID = DbRead.reports.subscribeReportDataByUID({
        siteKey: props.siteKey,
        uid: props.userId,
        onChange: setReportData,
        onError: (e) => logger.error(`Subscribe reportData: ${e.message}`, e),
      });
      return unsubscribeDataByUID;
    }
    const promise = getData();

    return () => {
      promise.then((unsub) => {
        if (unsub) unsub();
      });
    };
  }, [props.siteKey, props.userId]);

  async function downloadReport(
    siteKeyID: string,
    reportConfigID: string,
  ): Promise<void> {
    const reportConfig = reportConfigs.find((r) => r.id === reportConfigID);
    if (!reportConfig) {
      // in the places where this function is called, the code
      // depends on this situation throwing an error
      throw new Error(`Report config not found for ID: ${reportConfigID}`);
    }
    await handleDownload(siteKeyID, reportConfig);
  }

  async function emailReport(
    siteKeyID: string,
    reportConfigID: string,
  ): Promise<void> {
    const reportConfig = reportConfigs.find((r) => r.id === reportConfigID);
    if (!reportConfig) {
      // in the places where this function is called, the code
      // depends on this situation throwing an error
      throw new Error(`Report config not found for ID: ${reportConfigID}`);
    }
    await handleEmail(siteKeyID, reportConfig);
  }

  return (
    <ReportsListLayout
      siteKey={props.siteKey}
      reportSpecs={reportTypes}
      savedReports={reportConfigs}
      generatedReports={reportData}
      reportTypesLoading={reportTypesLoading}
      downloadReport={downloadReport}
      emailReport={emailReport}
      deleteReport={deleteReport}
      handleNavToReportSpecPage={handleNavToReportSpecPage}
    />
  );
}

// SECTION: Helpers

async function handleDownload(
  siteKeyID: string,
  reportConfig: ReportConfig,
): Promise<void> {
  const reportData = await DbWrite.reports.generateReportData({
    siteKey: siteKeyID,
    configuration: reportConfig,
  });
  if (!reportData.downloadURL) {
    // in the places where this function is called, the code
    // depends on this situation throwing an error
    throw new Error("Missing reportData.downloadURL");
  }

  window.location.href = reportData.downloadURL;
  return;
}

async function handleEmail(
  siteKeyID: string,
  reportConfig: ReportConfig,
): Promise<void> {
  const reportData = await DbWrite.reports.generateReportData({
    siteKey: siteKeyID,
    configuration: reportConfig,
  });
  if (!reportData.id) {
    // in the places where this function is called, the code
    // depends on this situation throwing an error
    throw new Error("Missing reportData.id");
  }

  await DbWrite.reports.sendEmail(siteKeyID, reportData.id);
  return;
}

async function deleteReport(
  siteKeyID: string,
  reportDataID: string,
): Promise<void> {
  await DbWrite.reports.deleteData(reportDataID, siteKeyID);
}

export function reportsFormatDateTime(iso: string): string {
  const val = DateTime.fromISO(iso).toFormat("LL/dd/yy, h:mm a");
  return val === "Invalid DateTime" ? "[unknown]" : val;
}
