//Libs
import { useRef, useState } from "react";

//Local
import BaseButtonSecondary from "../BaseButtonSecondary";
import { InvoiceActionTypes } from "../../Pages/Invoices/InvoiceListPage";

interface Props {
  invoiceActionSelected: (
    actionType: InvoiceActionTypes,
    invoiceIDs: any[],
  ) => void;
  actionsLoading: boolean;
  selectedRows: any[];
}

export default function InvoiceActionDropdown(props: Props) {
  const dropdown = useRef(null);
  const [isOpen, setOpen] = useState(false);

  function handleGeneratePDF() {
    setOpen(false);
    props.invoiceActionSelected("generatePDF", props.selectedRows);
  }

  function handleEmailPaymentLinkAction() {
    props.invoiceActionSelected("sendEmailInvoice", props.selectedRows);
  }

  function handleApplyManualPayment() {
    props.invoiceActionSelected("applyManualPayment", props.selectedRows);
  }

  function handlePayViaCard() {
    props.invoiceActionSelected("payViaCard", props.selectedRows);
  }

  function handleConvertToPendingAction() {
    props.invoiceActionSelected("convertDraftToPending", props.selectedRows);
  }

  function handleConvertToPaidAction() {
    props.invoiceActionSelected(
      "convertZeroAmountInvoicesToPaid",
      props.selectedRows,
    );
  }

  return (
    <div className="dropdown">
      <BaseButtonSecondary
        onClick={() => setOpen(!isOpen)}
        ref={dropdown}
        isBusy={props.actionsLoading}
        onBlur={() => setOpen(false)}
        className="w-44 "
      >
        <div className="w-full text-left">Actions</div>
        {props.selectedRows.length > 0 && (
          <div className="rounded-full bg-red-500 px-2 py-1 text-white">
            {`${props.selectedRows.length > 0 ? props.selectedRows.length.toString() : ""}`}
          </div>
        )}

        <svg
          className="ml-2 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </BaseButtonSecondary>

      <div
        id="dropdown"
        className={`relative z-10 w-44 divide-y divide-gray-100 rounded-md bg-white shadow ${
          isOpen ? "over" : "hidden"
        }`}
      >
        <ul className="absolute z-10 w-44 divide-y divide-gray-100 rounded-md bg-white shadow ">
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleConvertToPendingAction}
              className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
            >
              Convert to Pending...
            </a>
          </li>
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleConvertToPaidAction}
              className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
            >
              Convert to Paid...
            </a>
          </li>
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleGeneratePDF}
              className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
            >
              Generate PDF
            </a>
          </li>
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleEmailPaymentLinkAction}
              className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
            >
              Email Invoice...
            </a>
          </li>
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleApplyManualPayment}
              className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
            >
              Apply Manual Payment...
            </a>
          </li>
          <li>
            <a
              key="i"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handlePayViaCard}
              className="block cursor-pointer px-4 py-4 hover:bg-gray-100"
            >
              Pay Via Card...
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
