import { Channel } from "./types";

/**
 * Creates a channel for node or browser console with a default id
 * of "console"
 */
export function createConsoleChannel(id = "console"): Channel {
  const newChannel: Channel = {
    id: id,
    methods: {
      debug(...data) {
        console.debug(...data);
      },
      info(...data) {
        console.info(...data);
      },
      warn(...data) {
        console.warn(...data);
      },
      error(...data) {
        console.error(...data);
      },
    },
  };

  return newChannel;
}
