// Libs
import create from "zustand";

// Local
import { logger } from "../logging";
import { ExistingStiltPhoneCall, StiltPhoneCallManager } from "../models/calls";
import { DbWrite } from "../database";

export type AlertStyle = "confirm" | "match";

interface IncomingCallsStore {
  /**
   * A way to mark a particular call Firestore document as the current call. This
   * is only tracked locally on the client and not persisted to the database.
   */
  currentCall: ExistingStiltPhoneCall | null;
  /**
   * Set the current call locally.
   */
  setCurrentCall: (call: ExistingStiltPhoneCall | null) => void;
  /**
   * Clear the current call locally.
   */
  clearCall: () => void;
  /**
   * A convenience method that wraps the more generate database update function.
   * Updates the customerID and agentID of the current call ID in the database.
   */
  updateCallCustomer: (args: {
    customerID: string;
    currentUserID: string;
  }) => Promise<void>;

  /**
   * Derived value indicating whether the call needs an existing customerID
   * confirmed/changed or it has no customerID and needs to be matched to a
   * customer.
   *
   * This affects the style of the alert message displayed to users.
   *
   * Subscribing to this value with zustand will cause a re-render when the
   * value changes.
   *
   * [Source](https://github.com/pmndrs/zustand/issues/132#issuecomment-678555466)
   *
   * Example
   *
   * ```tsx
   * const alertStyle = useIncomingCallsStore((state) => state.alertStyle());
   * ```
   *
   */
  alertStyle: () => AlertStyle;
  customerFullName: () => string;
}

// Export a hook to access data globally and define initial values.
export const useIncomingCallsStore = create<IncomingCallsStore>((set, get) => {
  return {
    currentCall: null,
    setCurrentCall: (call) => set({ currentCall: call }),
    clearCall: () => set({ currentCall: null }),
    updateCallCustomer: async (args) => {
      const call = get().currentCall;
      if (!call) {
        logger.error(
          "Expected a current call to update in the incoming-calls store.",
        );
        return;
      }

      const currentCallID = call.id;
      const callRefPath = call.refPath;
      const siteKey = callRefPath.split("/")[1];

      await DbWrite.calls.updateCustomerAndAgent({
        callDocID: currentCallID,
        siteKey: siteKey,
        customerID: args.customerID,
        // currentUserID: args.currentUserID,
      });

      set({ currentCall: null });
    },
    alertStyle: () => {
      const call = get().currentCall;
      if (!call) return "confirm"; // default to confirm style
      if (
        Array.isArray(call.possibleCustomerIDs) &&
        call.possibleCustomerIDs.length > 1
      ) {
        return "match";
      }
      return call.customerID ? "confirm" : "match";
    },
    customerFullName: () => {
      const call = get().currentCall;
      if (!call) return "";
      return StiltPhoneCallManager.extractFullNameFromCustomData(call) ?? "";
    },
  };
});
