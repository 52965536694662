//Libs
import React, { Fragment, useEffect, useState } from "react";
import {
  TrashIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";

//Local
import BaseInputTextArea from "../BaseInputTextArea";
import BaseInputNumber from "../BaseInputNumber";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import currencyFormatter from "../../currency";
import {
  getInvoiceLineItemTotal,
  getTemporaryItemTotal,
} from "../../assets/js/estimateFunctions";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import { SchedulingButton } from "../../Pages/Scheduling/SchedulingContainer";
import { EstimateItemMembershipData } from "../../models/membership";
import { Timestamp } from "firebase/firestore";
import { Listbox, Transition } from "@headlessui/react";
import { StiltLineItemFormData } from "../../models/stilt-payment";
import { DbRead } from "../../database";
import { ExistingCustomer } from "../../models/customer";

interface Props {
  //DATA
  lineItem: StiltLineItemFormData;
  currency: string;
  siteKey: string;
  totalTaxRate: number;
  globalDiscount: number;
  customer: ExistingCustomer;
  //FUNCTIONS
  onSearch: (searchTerm: string) => Promise<void>;
  handleEditItem: (updatedItem: StiltLineItemFormData) => void;
  handleDeleteItem: () => void;
}

export default function EditInvoiceItemLine({
  onSearch,
  handleEditItem,
  handleDeleteItem,
  currency,
  lineItem,
  siteKey,
  ...props
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const [title, setTitle] = useState<string | "">(lineItem.title ?? "");

  const [description, setDescription] = useState<string | "">(
    lineItem.description ?? "",
  );
  const [unitPrice, setUnitPrice] = useState<number | "">(lineItem.unitPrice);
  const [discount, setDiscount] = useState<number | "">(
    lineItem.discount ?? "",
  );
  const [quantity, setQuantity] = useState<number | "">(lineItem.quantity);

  const [estimateItemMembershipData, setEstimateItemMembershipData] =
    useState<EstimateItemMembershipData | null>(null);
  const [membershipTemplate, setMembershipTemplate] =
    useState<ExistingMembershipTemplate | null>(null);
  const [membershipTemplateList, isLoadingMembershipTemplateList] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);

  useEffect(() => {
    const membershipTemplate = membershipTemplateList.find(
      (mT) => mT.priceBookItemID === lineItem.priceBookItemID,
    );
    setMembershipTemplate(membershipTemplate ?? null);
    if (membershipTemplate) {
      setEstimateItemMembershipData({
        membershipTemplateID: membershipTemplate.id,
        isSameTask: true,
        selectedStartDate: Timestamp.now(),
        notes: null,
      });
    }
  }, [membershipTemplateList]);

  const onSubmit = async () => {
    const updatedItem: StiltLineItemFormData = {
      ...lineItem,
      toBeEdited: false,
      description: description,
      title: title,
      unitPrice: unitPrice === "" ? 0 : unitPrice,
      quantity: quantity === "" ? 0 : quantity,
      discount: discount === "" ? 0 : discount,
    };

    if (lineItem.priceBookItemID) {
      const pbItem = await DbRead.priceBookItems.get(
        siteKey,
        lineItem.priceBookItemID,
      );
      const {
        taxAmountItem,
        subtotalItemWithLineDiscount,
        subtotalItemWithLineAndGlobalDiscount,
      } = getInvoiceLineItemTotal({
        item: updatedItem,
        currency: currency,
        totalTaxRate: props.totalTaxRate,
        globalDiscount: props.globalDiscount,
        customer: props.customer,
        discountable: pbItem?.discountable ?? true,
        taxable: pbItem?.taxable ?? true,
      });
      updatedItem.subTotal = subtotalItemWithLineDiscount;
      updatedItem.taxAmount = taxAmountItem;
    }

    try {
      await handleEditItem(updatedItem);
      onSearch("");
    } catch (error) {
      logger.error(`An error occurred during handleEditEstimateItem`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  };

  return (
    <div className="flex flex-col items-center sm:grid sm:grid-cols-[5fr,1fr] lg:grid-cols-[8fr,1fr]">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-[2fr,1fr,1fr,1fr] ">
          <div
            className={`relative col-span-2 block w-full min-w-0 rounded border border-primary p-4 text-gray-700 focus:border-primaryLight focus:outline-none focus:ring focus:ring-primaryLight sm:text-sm lg:col-span-1`}
          >
            <div className="truncate">{lineItem.title}</div>
            <label className="absolute -top-3 left-3 bg-white px-2">
              Title
              <span className="text-lg font-medium text-redFail">*</span>
            </label>
          </div>

          {/* Field: Quantity */}
          <div>
            <BaseInputNumber
              text="Quantity"
              inputName="quantity"
              admin={true}
              required={true}
              value={quantity}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                if (displayError === true && numberValue > 0) {
                  setDisplayError(false);
                }
                setQuantity(isNaN(numberValue) ? "" : numberValue);
              }}
            />
          </div>
          {/* Field: Unit Price */}
          <div className="relative">
            <BaseInputNumber
              text="Unit Price"
              inputName="unitPrice"
              admin={true}
              required={true}
              value={unitPrice}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                if (displayError === true && numberValue > 0) {
                  setDisplayError(false);
                }
                setUnitPrice(isNaN(numberValue) ? "" : numberValue);
              }}
            />
          </div>

          {/* Field: Discount */}
          <div>
            {lineItem.discountable && (
              <BaseInputNumber
                text="Discount (%)"
                inputName="discount"
                admin={true}
                required={false}
                disabled={!lineItem.discountable}
                value={discount ?? 0}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  if (displayError === true && numberValue > 0) {
                    setDisplayError(false);
                  }
                  setDiscount(isNaN(numberValue) ? "" : numberValue);
                }}
              />
            )}
          </div>
        </div>

        {/* Field: Description */}
        <div>
          <BaseInputTextArea
            text="Description"
            inputName="description"
            admin={true}
            required={false}
            rows={3}
            value={description ?? ""}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
        {membershipTemplate !== null && (
          <div className="justify-end">
            <div className="width-auto flex items-start justify-start space-x-2">
              <div className="m-2 mb-4 flex max-w-fit items-end justify-end rounded-full bg-blue-200 p-2 text-sm font-medium capitalize text-gray-900">
                New Membership
              </div>
            </div>
            <div className="flex flex-row items-end justify-end space-x-2">
              <span>
                Does the membership start with the task on this current invoice,
                or will it start at a date in the future?
              </span>
              <div className="z-10">
                <div className="w-40">
                  <Listbox
                    onChange={(event) => {
                      // 'event' contains the id string
                      const isSameTask = event === "sameTask";
                      if (estimateItemMembershipData) {
                        setEstimateItemMembershipData({
                          ...estimateItemMembershipData,
                          isSameTask: isSameTask,
                        });
                      } else {
                        setEstimateItemMembershipData({
                          membershipTemplateID: membershipTemplate.id,
                          isSameTask: isSameTask,
                          selectedStartDate: Timestamp.now(),
                          notes: null,
                        });
                      }
                    }}
                  >
                    <div className="relative mt-1">
                      <Listbox.Button className="relative h-10 w-full cursor-pointer rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight">
                        <span className="block truncate">
                          {estimateItemMembershipData?.isSameTask === true
                            ? "Current Task"
                            : "Future Date"}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-700"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          <Listbox.Option
                            key="membershipCurrentTask"
                            className={({ active, selected }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active || selected
                                  ? "bg-primaryOpacity90 text-primary"
                                  : "text-gray-700"
                              }`
                            }
                            value="sameTask"
                          >
                            Current Task
                          </Listbox.Option>
                          <Listbox.Option
                            key="membershipFutureDate"
                            className={({ active, selected }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active || selected
                                  ? "bg-primaryOpacity90 text-primary"
                                  : "text-gray-700"
                              }`
                            }
                            value="futureDate"
                          >
                            Future Date
                          </Listbox.Option>
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
              <div className="flex w-fit flex-col items-end justify-end">
                {estimateItemMembershipData?.isSameTask === false ? (
                  <DatePicker
                    selected={estimateItemMembershipData?.selectedStartDate?.toDate()}
                    onChange={(date: Date) => {
                      if (estimateItemMembershipData) {
                        setEstimateItemMembershipData({
                          ...estimateItemMembershipData,
                          selectedStartDate: Timestamp.fromDate(date),
                        });
                      } else {
                        setEstimateItemMembershipData({
                          membershipTemplateID: membershipTemplate.id,
                          isSameTask: true,
                          selectedStartDate: Timestamp.fromDate(date),
                          notes: null,
                        });
                      }
                    }}
                    showTimeSelect={true}
                    customInput={<SchedulingButton />}
                    minDate={DateTime.now().minus({ year: 1 }).toJSDate()}
                  />
                ) : null}
              </div>
            </div>

            <div className="flex flex-col items-end justify-between space-x-2 md:flex-row">
              <BaseInputTextArea
                text="Membership Notes"
                inputName="membershipNotes"
                admin={true}
                required={false}
                rows={2}
                className="mt-6 w-full"
                value={estimateItemMembershipData?.notes ?? ""}
                onChange={(event) => {
                  if (estimateItemMembershipData) {
                    setEstimateItemMembershipData({
                      ...estimateItemMembershipData,
                      notes: event.target.value ?? "",
                    });
                  } else {
                    setEstimateItemMembershipData({
                      membershipTemplateID: membershipTemplate.id,
                      isSameTask: true,
                      selectedStartDate: Timestamp.now(),
                      notes: event.target.value ?? "",
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex flex-row items-center justify-between gap-4 sm:mt-0 sm:flex-col sm:items-end">
        <div className="text-lg font-bold">
          {currencyFormatter(
            getTemporaryItemTotal(
              quantity,
              unitPrice,
              lineItem.discountable ?? true,
              discount,
            ),
            currency,
          )}
        </div>
        <button
          className="mx-1 rounded-full hover:bg-red-50"
          onClick={() => handleDeleteItem()}
        >
          <TrashIcon
            aria-label="delete button"
            className="block h-10 w-10 cursor-pointer p-2 text-red-700"
          />
        </button>
        <button
          className="mx-1 rounded-full hover:bg-green-50"
          onClick={onSubmit}
        >
          <CheckIcon
            aria-label="confirm button"
            className="block h-10 w-10 cursor-pointer p-2 text-green-700"
          />
        </button>
      </div>
    </div>
  );
}
