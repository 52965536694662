import { DocumentSnapshot, Timestamp, DocumentData } from "firebase/firestore";
import { z } from "zod";
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

// siteKeys/{siteKeyID}/checklistPhotos/{checklistPhotoID}

// #region SECTION: Schemas / Interfaces
export interface ChecklistPhoto {
  checklistResponseID: string;
  taskID: string;
  craftRecordID: string;
  thumbnailURL: string | null;
  photoURL_reduced: string | null;
  photoURL: string;
  timestampCreated: Timestamp;
  createdBy: string;

  deleted?: boolean;
}

export interface ExistingChecklistPhoto extends ChecklistPhoto {
  id: string;
  refPath: string;
}

/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
const ChecklistPhotoSchema = z.object({
  checklistResponseID: z.string().min(1).max(200),
  taskID: z.string().min(1).max(200),
  craftRecordID: z.string().min(1).max(200),
  thumbnailURL: z.string().url().min(1).max(2000).nullable(),
  photoURL_reduced: z.string().url().min(1).max(2000).nullable(),
  photoURL: z.string().url().min(1).max(2000),
  timestampCreated: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),

  deleted: z.boolean().optional(),
});

/** For reading from the database */
const ChecklistPhotoSchemaWithFallbacks = z.object({
  checklistResponseID: z.string().min(1).max(200),
  taskID: z.string().min(1).max(200),
  craftRecordID: z.string().min(1).max(200),
  thumbnailURL: zFallback(
    z.string().url().min(1).max(2000).nullable(),
    null,
    "ChecklistPhotoSchemaWithFallbacks: 'thumbnailURL'",
  ),
  photoURL_reduced: zFallback(
    z.string().url().min(1).max(2000).nullable(),
    null,
    "ChecklistPhotoSchemaWithFallbacks: 'photoURL_reduced'",
  ),
  photoURL: z.string().url().min(1).max(2000),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "ChecklistPhotoSchemaWithFallbacks: 'timestampCreated'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "ChecklistPhotoSchemaWithFallbacks: 'createdBy'",
  ),
  deleted: zFallback(
    z.boolean().optional(),
    false,
    "ChecklistPhotoSchemaWithFallbacks: 'deleted'",
  ),
});
// #endregion

// #region SECTION: Functions
/**
 * Collection of functions that interact with ChecklistPhoto Documents
 */
export const ChecklistPhotoManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingChecklistPhoto object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createChecklistPhotoFromSnapshot,
  /** Drop `id` and `refPath` before saving to the database. Drop undefined values. */
  convertForFirestore: convertChecklistPhotoForFirestore,
  createFromJSON: createChecklistPhotoFromJSONObject,
  /** Validate a ChecklistPhoto doc. Use for writing to the database or reading from the user. */
  parse: validateChecklistPhoto,
  /** Validate a ChecklistPhoto doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateChecklistPhotoWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingChecklistPhoto object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createChecklistPhotoFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingChecklistPhoto {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateChecklistPhotoWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values. */
function convertChecklistPhotoForFirestore(
  record: ExistingChecklistPhoto,
): DocumentData {
  const local = Object.assign({}, record);
  const { id, refPath, ...rest } = local;
  return dropUndefined(rest);
}

function createChecklistPhotoFromJSONObject(
  jsonObj: Record<string, any>,
): ExistingChecklistPhoto {
  const timestampCreated: Timestamp = new Timestamp(
    jsonObj.timestampCreated._seconds,
    jsonObj.timestampCreated._nanoseconds,
  );

  return {
    id: jsonObj.id,
    refPath: jsonObj.refPath,
    checklistResponseID: jsonObj.checklistResponseID,
    taskID: jsonObj.taskID,
    craftRecordID: jsonObj.craftRecordID,
    thumbnailURL: jsonObj.thumbnailURL,
    photoURL_reduced: jsonObj.photoURL_reduced,
    photoURL: jsonObj.photoURL,
    timestampCreated: timestampCreated,
    createdBy: jsonObj.createdBy,
    deleted: jsonObj.deleted,
  };
}

/** Rejects if it doesn't pass validation. */
function validateChecklistPhoto(photoDoc: unknown): ChecklistPhoto {
  if (!guardIsPlainObject(photoDoc)) {
    throw new Error(`ChecklistPhoto is not an object: ${photoDoc}`);
  }
  return ChecklistPhotoSchema.parse(photoDoc);
}

/** For validating stuff coming in from the database. */
function validateChecklistPhotoWithFallbacks(
  photoDoc: unknown,
): ChecklistPhoto {
  if (!guardIsPlainObject(photoDoc)) {
    throw new Error(`ChecklistPhoto is not an object: ${photoDoc}`);
  }
  return ChecklistPhotoSchemaWithFallbacks.parse(photoDoc);
}
// #endregion
