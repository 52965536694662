// Local
import { createConsoleChannel } from "./channel-console";
import { createSentryChannel } from "./channel-sentry";
import { Logger } from "./logger";
import { LogLevel } from "./types";

export * from "./types";
export * from "./logger";
export * from "./channel-console";

const isEitherFirebaseProject =
  import.meta.env.VITE_APP_FIREBASE_PROJECT === "scaffoldtracker" ||
  import.meta.env.VITE_APP_FIREBASE_PROJECT === "stilt-dev";

const isDevEnv = import.meta.env.MODE === "development";

// Logging: Set up for different environment
const defaultLogger = new Logger();
if (process.env.NODE_ENV === "test") {
  // No logs during CI / Tests by default.
  defaultLogger.setLevel(LogLevel.SILENT);
} else if (isEitherFirebaseProject && !isDevEnv) {
  // On live servers we're running Sentry.io and console channels.
  defaultLogger.setDefaultLevel(LogLevel.DEBUG);
  defaultLogger.setLevel(LogLevel.DEBUG);

  defaultLogger.clearChannels();
  defaultLogger.addChannel(createConsoleChannel());
  defaultLogger.addChannel(createSentryChannel());
} else {
  // Everywhere else just the default console channel.
  // This is most likely a dev environment.
  defaultLogger.setDefaultLevel(LogLevel.INFO);
  defaultLogger.setLevel(LogLevel.INFO);
}

/**
 * The default logger that should be used in most situations.
 */
export const logger = defaultLogger;
