//Libs
import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";

//Local
import * as strings from "../../strings";
import { ExistingMembership } from "../../models/membership";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { DbRead, DbWrite } from "../../database";
import { useNavToCreateTask } from "../../navigation";
import { ExistingTask } from "../../models/task";

import { EventTypes } from "../../models/event-types";
import {
  convertToReadableTimestamp,
  convertToReadableTimestampDate,
} from "../../assets/js/convertToReadableTimestamp";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { ExistingStiltInvoice } from "../../models/invoice";
import { getReadableTaskStatus } from "../../models/task-status";
import { getTaskStatusPillClasses } from "../../assets/js/tasks";
import { DocumentData, Timestamp } from "firebase/firestore";
import { useAuthStore } from "../../store/firebase-auth";
import { User } from "firebase/auth";
import { logger } from "../../logging";
import { cloneDeep } from "lodash";
import MembershipDetailsPage from "./MembershipDetailsPage";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  getEventIcon,
  getEventIconColor,
} from "../../components/WorkRecordAndTasks/functions";
import LoadingSpinner from "../../components/LoadingSpinner";
import AddEditMembershipDialog from "../../components/Memberships/AddEditMembershipDialog";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { ExistingCraftRecord } from "../../models/craft-record";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { nextExpectedTaskDate } from "./MembershipsListPage";
import SendCustomEmailDialog from "../../components/customers/SendCustomEmailDialog";
import { getEmailList } from "../../utils/getEmailList";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import EmailIcon from "@mui/icons-material/Email";

interface Props {
  siteKey: string;
}

export default function MembershipDetailsContainer(props: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;

  // Extract membershipID from the URL
  type UrlParams = { membershipID: string };
  const data = useParams<UrlParams>();
  const membershipID = data.membershipID;
  if (typeof membershipID !== "string") {
    throw new Error(`membershipID was not a string: ${membershipID}`);
  }

  const navToCreateTask = useNavToCreateTask();
  const navigate = useNavigate();

  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [customerDoc, setCustomerDoc] = useState<ExistingCustomer | null>(null);
  const [customerLocationDoc, setCustomerLocationDoc] =
    useState<ExistingCustomerLocation | null>(null);

  const [membershipDoc, setMembershipDoc] = useState<ExistingMembership | null>(
    null,
  );
  const [membershipInvoice, setMembershipInvoice] =
    useState<ExistingStiltInvoice | null>(null);
  const [nextTaskText, setNextTaskText] = useState<string | null>(null);

  const [membershipTemplateList, isLoadingMembershipTemplateList] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);

  const [editMembershipDialogOpen, setEditMembershipDialogOpen] =
    useState<boolean>(false);

  const [tasksForCustomer, setTasksForCustomer] = useState<ExistingTask[]>([]);
  const [craftRecordsForCustomer, setCraftRecordsForCustomer] = useState<
    ExistingCraftRecord[]
  >([]);

  const [addMembershipLoading, setAddMembershipLoading] = useState<
    string | null
  >(null);

  const [membershipTasks, setMembershipTasks] = useState<ExistingTask[]>([]);
  const [nonMembershipTasks, setNonMembershipTasks] = useState<ExistingTask[]>(
    [],
  );
  const [sendCustomerEmailDialogOpen, setSendCustomEmailDialogOpen] =
    useState<boolean>(false);

  function goToWorkRecordAndTasksPage(
    craftRecordPath: ExistingTask["craftRecordID"],
  ) {
    const craftRecordID = craftRecordPath.split("/")[3];
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  useEffect(() => {
    function getMembership() {
      console.log("membershipID: ", membershipID);
      if (!membershipID) return;

      const unsubscribe = DbRead.memberships.subscribe({
        membershipDocID: membershipID,
        siteKey: props.siteKey,
        onChange: setMembershipDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getMembership();
    return () => unsubscribeFn && unsubscribeFn();
  }, [membershipID]);

  useEffect(() => {
    function getInvoiceForMembership() {
      if (!membershipDoc) return;

      const unsubscribe = DbRead.invoices.subscribeByMembershipID({
        membershipID: membershipDoc.id,
        siteKey: props.siteKey,
        onChange: setMembershipInvoice,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getInvoiceForMembership();
    return () => unsubscribeFn && unsubscribeFn();
  }, [membershipDoc]);

  useEffect(() => {
    function getCraftRecords() {
      if (!customerDoc) return;
      const unsubscribe = DbRead.parentRecords.subscribeAllByCustomerID({
        customerID: customerDoc.id,
        siteKey: props.siteKey,
        onChange: setCraftRecordsForCustomer,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCraftRecords();
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerDoc]);

  useEffect(() => {
    async function getTasks() {
      if (!membershipDoc || !userPermissions) return;
      const tasks = await DbRead.tasks.getSingleCustomerTasks({
        permissions: userPermissions,
        customerID: membershipDoc?.customerID,
        siteKey: props.siteKey,
      });
      const sortedTasks = tasks.sort((a, b) => {
        if (!!a.timestampScheduled && b.timestampScheduled) {
          return (
            b.timestampScheduled?.toMillis() - a.timestampScheduled.toMillis()
          );
        } else {
          return 0;
        }
      });
      setTasksForCustomer(sortedTasks);
    }

    getTasks();
  }, [userPermissions, membershipDoc]);

  useEffect(() => {
    function getCustomer() {
      if (!membershipDoc) return undefined;
      const unsubscribe = DbRead.customers.subscribe({
        customerID: membershipDoc.customerID,
        siteKey: props.siteKey,
        onChange: setCustomerDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomer();
    return () => unsubscribeFn && unsubscribeFn();
  }, [membershipDoc]);

  useEffect(() => {
    function getCustomerLocation() {
      if (!membershipDoc?.customerLocationID) return undefined;
      const unsubscribe = DbRead.customerLocations.subscribe({
        customerLocationID: membershipDoc.customerLocationID,
        siteKey: props.siteKey,
        onChange: setCustomerLocationDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerLocation();
    return () => unsubscribeFn && unsubscribeFn();
  }, [membershipDoc]);

  useEffect(() => {
    if (tasksForCustomer.length === 0) return;

    const membershipResult = getMembershipTasks(tasksForCustomer);
    const nonMembershipResult = getNonMembershipTasks(tasksForCustomer);

    setMembershipTasks(membershipResult);
    setNonMembershipTasks(nonMembershipResult);
  }, [tasksForCustomer]);

  useEffect(() => {
    if (!membershipDoc || !membershipTemplate) return;
    let nextTaskText = null;
    if (areAllTasksCreated()) {
      nextTaskText = `All tasks have been created for this membership`;
    } else if (membershipDoc?.nextScheduledTaskDate) {
      nextTaskText = `The next task is scheduled for 
          ${convertToReadableTimestampDate(membershipDoc?.nextScheduledTaskDate)}`;
    } else {
      nextTaskText = `The next task should be on ${nextExpectedTaskDate([membershipTemplate], membershipDoc)} but it hasn't been created yet.`;
      // const nextTaskDate = getDateOfWhenNextTaskShouldBeGenerated(
      //   membershipDoc,
      //   membershipTemplate,
      // );
      // if (nextTaskDate === null) {
      //   nextTaskText = ``;
      // } else {
      //   nextTaskText = `The next task should be scheduled around
      //   ${convertToReadableTimestampDate(nextTaskDate)}`;
      // }
    }

    setNextTaskText(nextTaskText);
  }, [tasksForCustomer]);

  function openEditMembershipDialog() {
    setEditMembershipDialogOpen(true);
  }

  if (!customerDoc || !membershipDoc || isLoadingMembershipTemplateList) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const membershipTemplate: ExistingMembershipTemplate | undefined =
    membershipTemplateList.find(
      (m) => m.id === membershipDoc?.membershipTemplateID,
    );

  function areAllTasksCreated() {
    const totalTasks = membershipDoc?.tasks
      ? Object.keys(membershipDoc.tasks).length
      : 0;
    const templateTasks = membershipTemplate?.taskGeneration
      ? membershipTemplate.taskGeneration.length
      : 0;
    if (totalTasks >= templateTasks) {
      return true;
    }
    return false;
  }

  function shouldShowTaskButton() {
    // if the total tasks in the membershipDoc.tasks map is the same as the number of tasks in the membershipTemplate.taskGeneration array, then we should not show the button
    if (areAllTasksCreated()) {
      return false;
    }
    if (membershipDoc?.status !== "active") {
      return false;
    }
    return true;
  }

  const createTaskBtn = shouldShowTaskButton() && membershipDoc && (
    <BaseButtonPrimary
      className="flex w-fit gap-2 uppercase"
      onClick={() => {
        navToCreateTask(
          null,
          membershipDoc.customerID,
          customerLocationDoc ?? undefined,
          undefined,
          {
            membershipTemplateID: membershipDoc.membershipTemplateID,
            membershipID: membershipDoc.id,
          },
        );
      }}
    >
      <AddIcon />
      {strings.SCHEDULE_NEXT_TASK}
    </BaseButtonPrimary>
  );

  const sendEmailBtn = membershipDoc && (
    <BaseButtonSecondary
      className="flex w-fit gap-2 uppercase"
      onClick={() => setSendCustomEmailDialogOpen(true)}
    >
      <EmailIcon />
      {strings.SEND_EMAIL}
    </BaseButtonSecondary>
  );

  const nextInvoiceText = membershipDoc?.nextInvoiceDate && (
    <div className="pt-2 text-right text-sm italic text-orange-800">
      The next invoice will be generated on{" "}
      {convertToReadableTimestampDate(membershipDoc?.nextInvoiceDate)}
    </div>
  );

  const nextTaskTextElement = nextTaskText && (
    <div className="pt-2 text-right text-sm italic text-orange-800">
      {nextTaskText}
    </div>
  );
  // const header = (
  //   <div className="mb-4 flex w-full items-center justify-start rounded-t-lg bg-primary p-8 text-left text-white ">
  //     <div className="flex w-full flex-col">
  //       <h1 className="inline-flex items-center text-xl font-semibold ">
  //         {membershipTemplate?.title ?? "Unknown Membership"}
  //       </h1>
  //       {membershipDoc && (
  //         <div
  //           className={`w-fit rounded-full px-2.5 pb-1 pt-1 text-sm font-medium ${customBg} capitalize`}
  //         >
  //           {getReadableMembershipStatus(membershipDoc.status)}
  //         </div>
  //       )}
  //     </div>
  //
  //     <button type="button" onClick={closeAndReset}>
  //       <XMarkIcon
  //         aria-label="close membership detail form"
  //         className="h-6 text-white"
  //       />
  //     </button>
  //   </div>
  // );

  async function addMembershipToTask(task: ExistingTask, membershipID: string) {
    setAddMembershipLoading(task.id);

    const clonedMembershipTasks = cloneDeep(membershipTasks);
    const clonedNonMembershipTasks = cloneDeep(nonMembershipTasks);

    const newMembershipIDs = task.membershipIDs
      ? [...task.membershipIDs, membershipID]
      : [membershipID];

    const updateTask: DocumentData = {
      membershipIDs: newMembershipIDs,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
    };
    try {
      await DbWrite.tasks.update(updateTask, props.siteKey, task.id);
      logger.info(`Task updated - `, task.id);

      clonedMembershipTasks.push(task);
      const nonMembershipResult = clonedNonMembershipTasks.filter(
        (nonMembershipTask) => task.id !== nonMembershipTask.id,
      );

      setMembershipTasks(clonedMembershipTasks);
      setNonMembershipTasks(nonMembershipResult);
    } catch (error) {
      logger.error(`An error occurred during addMembershipToTask`, error);
    }
    setAddMembershipLoading(null);
  }

  const leftCaretClasses =
    "before:absolute before:-left-3 before:top-1/2 before:-translate-y-1/2 before:border-y-[12px] before:border-r-[12px] before:border-y-transparent before:border-r-gray-200 after:absolute after:-left-[10.5px] after:top-1/2 after:-translate-y-1/2 after:border-y-[12px] after:border-r-[12px] after:border-y-transparent after:border-r-white";

  function taskHistoryCard(
    task: ExistingTask,
    craftRecord?: ExistingCraftRecord,
  ) {
    return (
      // NOTE: task panel's vertical bar will "disappear" without the isolate class.
      <div key={task.id} className="relative isolate px-1 py-1">
        {/* VERTICAL BAR */}
        <span className="block before:absolute before:bottom-0 before:left-7 before:top-0 before:-z-10 before:h-full before:w-0.5 before:bg-gray-200"></span>
        <div className="flex items-center gap-2">
          {/* ICON */}
          <span
            className={`rounded-full p-2 ${getEventIconColor(
              EventTypes.NEW_TASK_ADDED,
            )}`}
          >
            {getEventIcon(EventTypes.NEW_TASK_ADDED)}
          </span>

          {/* EVENT CARD */}
          <div
            className={`${leftCaretClasses} relative w-full rounded-md border border-gray-200 px-3.5 py-2 text-sm text-gray-400 shadow-md hover:bg-gray-100`}
          >
            <button
              onClick={() => goToWorkRecordAndTasksPage(task.craftRecordID)}
              className={`flex w-full flex-col`}
            >
              <div className="flex w-full flex-row justify-between space-x-2">
                <div
                  className={`rounded-full px-2 py-1 text-xs font-medium ${getTaskStatusPillClasses(task.taskStatus)}`}
                >
                  {getReadableTaskStatus(task.taskStatus)}
                </div>
                {task.membershipIDs && task.membershipIDs.length > 0 && (
                  <div className="rounded-lg bg-blue-200 px-2 py-1 text-xs font-medium  text-black">
                    {strings.MEMBERSHIP_TASK}
                  </div>
                )}
              </div>
              <p className="pt-1 text-left font-bold text-gray-900">
                {task.title}
              </p>
              <p className="py-1 text-left italic text-gray-700">
                {craftRecord?.description ?? ""}
              </p>
            </button>
            <div className="flex w-full flex-row items-center justify-between">
              <p>
                {convertToReadableTimestamp(
                  task.timestampTaskCompleted ??
                    task.timestampScheduled ??
                    task.timestampCreated,
                )}
              </p>
              {membershipDoc &&
                !task.membershipIDs?.includes(membershipDoc.id) && (
                  <button
                    onClick={() => addMembershipToTask(task, membershipDoc.id)}
                    className="my-1 flex w-fit justify-center rounded-lg bg-orange-200 px-2 py-1 text-xs font-medium text-black"
                  >
                    {addMembershipLoading === task.id ? (
                      <LoadingSpinner marginClass="mx-2" />
                    ) : (
                      strings.CONFIRM_TASK_FOR_MEMBERSHIP
                    )}
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getMembershipTasks(tasks: ExistingTask[]) {
    if (membershipDoc === null) {
      return [];
    }
    // Return list of tasks where the membershipIDs array includes the membershipDoc.id
    return tasks
      .filter((t) => t.membershipIDs?.includes(membershipDoc.id))
      .sort((a, b) => {
        if (!!a.timestampScheduled && b.timestampScheduled) {
          return (
            b.timestampScheduled?.toMillis() - a.timestampScheduled.toMillis()
          );
        } else {
          return 0;
        }
      });
  }

  function getNonMembershipTasks(tasks: ExistingTask[]) {
    if (membershipDoc === null) {
      return [];
    }
    // Return list of tasks where the membershipIDs array includes the membershipDoc.id
    return tasks
      .filter((t) => !t.membershipIDs?.includes(membershipDoc.id))
      .sort((a, b) => {
        if (!!a.timestampScheduled && b.timestampScheduled) {
          return (
            b.timestampScheduled?.toMillis() - a.timestampScheduled.toMillis()
          );
        } else {
          return 0;
        }
      });
  }

  const membershipTimeline = (
    <div className="flex flex-row space-x-2">
      <div className="ml-2 w-full overflow-y-auto">
        <span>Tasks in this Membership</span>
        {membershipTasks.map((task) => {
          const craftRecord = craftRecordsForCustomer.find((craftRecord) => {
            const justTheID = task.craftRecordID.split("/")[3];
            return craftRecord.id === justTheID;
          });
          return taskHistoryCard(task, craftRecord);
        })}
      </div>
      <div className="border-l-2"></div>
      <div className="ml-2 w-full overflow-y-auto">
        <span>All Other Tasks For Customer</span>
        {nonMembershipTasks.map((task) => {
          const craftRecord = craftRecordsForCustomer.find((craftRecord) => {
            const justTheID = task.craftRecordID.split("/")[3];
            return craftRecord.id === justTheID;
          });
          return taskHistoryCard(task, craftRecord);
        })}
      </div>
    </div>
  );

  const emailList = getEmailList(null, customerDoc);

  const sendCustomEmailDialog = (
    <SendCustomEmailDialog
      title={"Send An Email"}
      emailSubject={""}
      emailBody={""}
      closeDialog={() => setSendCustomEmailDialogOpen(false)}
      isDialogOpen={sendCustomerEmailDialogOpen}
      customerID={customerDoc.id}
      customerEmailList={emailList}
      siteKey={props.siteKey}
    />
  );

  if (!membershipDoc || !membershipTemplate) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const addEditMembershipDialog = (
    <AddEditMembershipDialog
      assetsEnabled={siteKeyDoc?.customizations?.assetsEnabled}
      membership={membershipDoc}
      membershipTemplate={membershipTemplate ?? null}
      isDialogOpen={editMembershipDialogOpen}
      customer={customerDoc ?? null}
      customerLocation={customerLocationDoc}
      customerLocationOptions={
        customerLocationDoc ? [customerLocationDoc] : null
      }
      locationID={membershipDoc?.locationID ?? null}
      siteKey={props.siteKey}
      closeDialog={() => setEditMembershipDialogOpen(false)}
      isSubmitting={false}
    />
  );

  return (
    <MembershipDetailsPage
      customerDoc={customerDoc}
      membershipDoc={membershipDoc}
      membershipInvoice={membershipInvoice}
      membershipTemplate={membershipTemplate}
      openEditMembershipDialog={openEditMembershipDialog}
      children={{
        membershipTimeline,
        sendEmailBtn,
        createTaskBtn,
        addEditMembershipDialog,
        nextTaskTextElement,
        nextInvoiceText,
        sendCustomEmailDialog,
      }}
    />
  );
}
