// Libs
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

// Local
import {
  dropUndefined,
  guardIsPlainObject,
  isValidISO8601,
  msgInvalidISO8601,
  zFallback,
} from "../../utils";
import { NotFoundError } from "../../error-classes";

// NOTE: `siteKeys/{siteKeyID}/reportConfigs/{reportConfigID}`

// #region Types / Guards
/** ReportConfig defines the user-specified options for a report type. */
export interface ReportConfig {
  id?: string;
  type: string; // is `reportTypes` on the backend
  user: string;
  subscribed: boolean;
  frequency?: FrequencyOptions;
  timeToGenerate?: TimeToGenerateOptions;
  configCustomizations?: { [p: string]: unknown };
  configName: string;
  timestampLastModified?: string;
  whiteLabel?: string;
}

/** Defining options for 'frequency' saved report config property. */
export const frequencyOptions = ["daily", "weekly", "monthly"] as const;
export type FrequencyOptions = (typeof frequencyOptions)[number];

export function isFrequencyOption(value: unknown): value is FrequencyOptions {
  return frequencyOptions.includes(value as any);
}

/** Defining options for 'timeToGenerate' saved report config property. */
export const timeToGenerateOptions = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
] as const;
export type TimeToGenerateOptions = (typeof timeToGenerateOptions)[number];

export function isTimeToGenerateOption(
  value: unknown,
): value is TimeToGenerateOptions {
  return timeToGenerateOptions.includes(value as any);
}
// #endregion Types / Guards

/** Utilities for interacting with ReportConfig objects. */
export const ReportConfigManager = {
  /** Used when writing to the database. @throws if `value` is not an object. */
  parse: validateReportConfig,
  /** Used when reading from the database. @throws if `value` is not an object. */
  parseWithFallbacks: validateReportConfigWithFallbacks,
  /**
   * Convert document snapshot into a ReportConfig object. Validates data using
   * the schema with fallbacks. @throws NotFoundError if document does not exist
   */
  fromSnapshot: createFromSnapshot,
  /** Does NOT validate data. Drops undefined, drops `id` property. */
  convertForFirestore: convertForFirestore,
};

// #region Functions
function validateReportConfig(value: unknown): ReportConfig {
  if (!guardIsPlainObject(value)) {
    throw new Error(`reportConfig not an object: ${value}`);
  }
  return reportConfigSchema.parse(value);
}

function validateReportConfigWithFallbacks(value: unknown): ReportConfig {
  if (!guardIsPlainObject(value)) {
    throw new Error(`reportConfig not an object: ${value}`);
  }
  return reportConfigSchemaFallbacks.parse(value);
}

function createFromSnapshot(snapshot: DocumentSnapshot): ReportConfig {
  if (!snapshot.exists) throw new NotFoundError(snapshot.ref.path);
  return {
    id: snapshot.id,
    ...validateReportConfigWithFallbacks(snapshot.data()),
  };
}

function convertForFirestore(data: ReportConfig): DocumentData {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = data;
  return dropUndefined(rest);
}
// #endregion Functions

// #region Schemas
/** For validating user input; for writing to the DB */
export const reportConfigSchema = z.object({
  // exported for use in report-data.ts
  id: z.string().min(1).max(200).optional(),
  type: z.string().min(1).max(200),
  user: z.string().min(1).max(200),
  subscribed: z.boolean(),
  frequency: z.enum(frequencyOptions).optional(),
  timeToGenerate: z
    .number()
    .min(0)
    .max(23)
    .refine(isTimeToGenerateOption)
    .optional(),
  configCustomizations: z.record(z.any()).optional(),
  configName: z.string().min(1).max(300),
  timestampLastModified: z
    .string()
    .refine(isValidISO8601, msgInvalidISO8601)
    .optional(),
  whiteLabel: z.string().min(1).max(200).optional(),
});

/** For reading from the database */
export const reportConfigSchemaFallbacks = z.object({
  // exported for use in report-data.ts
  id: zFallback(
    z.string().min(1).max(200).optional(),
    undefined,
    "reportConfig.id",
  ),
  type: z.string().min(1).max(200), // probably shouldn't provide a fallback for this one.
  user: zFallback(z.string().min(1).max(200), "Unknown", "reportConfig.user"),
  subscribed: zFallback(z.boolean(), false, "reportConfig.subscribed"),
  frequency: zFallback(
    z.enum(frequencyOptions).optional(),
    undefined,
    "reportConfig.frequency",
  ),
  timeToGenerate: zFallback(
    z.number().min(0).max(23).refine(isTimeToGenerateOption).optional(),
    7, // fallback to 7am (if we get a value like "gazz")
    "reportConfig.timeToGenerate",
  ),
  configCustomizations: zFallback(
    z.record(z.any()).optional(),
    undefined,
    "reportConfig.configCustomizations",
  ),
  configName: zFallback(
    z.string().min(1).max(300),
    "Unknown config name",
    "reportConfig.configName",
  ),
  timestampLastModified: zFallback(
    z.string().refine(isValidISO8601, msgInvalidISO8601).optional(),
    undefined,
    "reportConfig.timestampLastModified",
  ),
  whiteLabel: zFallback(
    z.string().min(1).max(200).optional(),
    "stilt",
    "reportConfig.whiteLabel",
  ),
});
// #endregion Schemas
