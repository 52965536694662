//TODO: Need to update component to allow checkbox to be controlled by TS
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import type { SvgIconComponent } from "@mui/icons-material";

export interface ListItemProps {
  icon: SvgIconComponent;
  heading: string;
  subheading: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAdmin: boolean;
  initialChecked?: boolean;
}

export function UserInfoCheckboxItem(props: ListItemProps) {
  const { initialChecked = false } = props;
  return (
    <ListItem>
      {
        <ListItemIcon>
          <props.icon className="text-primary" />
        </ListItemIcon>
      }
      <ListItemText primary={props.heading} secondary={props.subheading} />
      <Checkbox
        // edge="end"
        onChange={props.onChange}
        inputProps={{ "aria-label": "controlled" }}
        defaultChecked={initialChecked}
      />
    </ListItem>
  );
}
