// Local
import { getCraftTypeRecordString } from "../../../models/craft-types";
import { ExistingSiteKey } from "../../../models/site-key";
import { ExistingTask } from "../../../models/task";
import { TaskStatus } from "../../../models/task-status";
import { getTaskSpecificDetailsString } from "../../../models/task-types";

/** Checks if any task-specific details exist for this craft type, task type,
 * and task status */
export function checkTsdRequired(args: {
  task: ExistingTask;
  siteKeyDoc: ExistingSiteKey;
  previousTaskStatus: TaskStatus;
}): boolean {
  // If no status change, then no need to show prompt
  if (args.task.taskStatus === args.previousTaskStatus) {
    return false;
  }

  const taskSpecificDetails =
    args.siteKeyDoc.customizations["taskSpecificDetails"] ?? {};

  // Get craft record type and task type
  const taskTypeName = getTaskSpecificDetailsString(args.task.taskType);
  const craftTypeName = getCraftTypeRecordString(args.task.craftType);

  console.log("taskTypeName", taskTypeName);
  console.log("craftTypeName", craftTypeName);
  console.log("taskSpecificDetails", taskSpecificDetails);
  if (
    craftTypeName in taskSpecificDetails &&
    taskTypeName in taskSpecificDetails[craftTypeName]
  ) {
    const _taskTypeMap = taskSpecificDetails[craftTypeName][taskTypeName];
    for (const field in _taskTypeMap)
      if (_taskTypeMap[field].onTaskStatus?.includes(args.task.taskStatus)) {
        return true;
      }
  }
  return false;
}
