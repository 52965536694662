//Libs
import { useEffect, useMemo } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

//Local
import { ExistingNote, Note } from "../../models/note";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputTextArea from "../BaseInputTextArea";
import * as strings from "../../strings";
import StyledMessage from "../StyledMessage";
import BaseInputCheckbox from "../BaseInputCheckbox";

interface Props {
  noteToBeEdited: ExistingNote | null;
  onAddNote: (partialNote: Pick<Note, "note" | "pinned">) => Promise<void>;
  onEditNote: (partialNote: Pick<Note, "note" | "pinned">) => Promise<void>;
  closeAddEditNote: (bool: boolean) => void;
}

export const CustomerNoteFormSchema = z.object({
  note: z.string().min(1).max(10000),
  pinned: z.boolean(),
});

export type CustomerNoteFormState = z.infer<typeof CustomerNoteFormSchema>;

export default function AddEditCustomerNote({
  noteToBeEdited,
  onAddNote,
  onEditNote,
  closeAddEditNote,
}: Props) {
  const CustomerNoteFormDefaltValues: CustomerNoteFormState = useMemo(() => {
    if (noteToBeEdited) {
      return {
        note: noteToBeEdited.note,
        pinned: noteToBeEdited.pinned,
      };
    } else {
      return {
        note: "",
        pinned: false,
      };
    }
  }, [noteToBeEdited]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<CustomerNoteFormState>({
    defaultValues: CustomerNoteFormDefaltValues,
    resolver: zodResolver(CustomerNoteFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(CustomerNoteFormDefaltValues);
  }, [CustomerNoteFormDefaltValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    closeAddEditNote(false);
  }

  const onSubmit: SubmitHandler<CustomerNoteFormState> = async (formValues) => {
    const noteData: Pick<Note, "note" | "pinned"> = {
      note: formValues.note,
      pinned: formValues.pinned,
    };

    if (noteToBeEdited === null) {
      await onAddNote(noteData);
      // Close dialog if successful.
      closeAndReset();
    } else {
      await onEditNote(noteData);
      // Close dialog if successful.
      closeAndReset();
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col space-y-2 py-2"
    >
      <hr className="my-4 block w-full border border-gray-200" />
      <div>
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <BaseInputTextArea
              inputName="note"
              text="Note"
              admin={true}
              required={true}
              rows={4}
              {...field}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
        {errors.note?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.note.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      <Controller
        name="pinned"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <BaseInputCheckbox
            id="pinned"
            label="Pin Note"
            onChange={(e) => {
              field.onChange(e.target.checked);
            }}
            checked={field.value}
          />
        )}
      />

      {/* Action Buttons */}
      <BaseButtonPrimary
        type="submit"
        formNoValidate
        disabled={isSubmitting}
        isBusy={isSubmitting}
        busyText={strings.buttons.BUSY_SAVING}
        className="w-full text-primary"
      >
        {strings.buttons.SAVE}
      </BaseButtonPrimary>
      <BaseButtonSecondary
        type="button"
        onClick={closeAndReset}
        className="w-full text-primary"
      >
        {strings.buttons.CLOSE}
      </BaseButtonSecondary>
      <hr className="my-4 block w-full border border-gray-200" />
    </form>
  );
}
