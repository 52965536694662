// Libs
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";

// Local
import * as strings from "../../strings";
import LoadingSpinner from "../LoadingSpinner";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import { ExistingTask } from "../../models/task";
import { TaskStatus } from "../../models/task-status";

interface Props {
  task: ExistingTask;
  existingEstimatesForTask: boolean;

  // FUNCTIONS
  handleDeleteTask: (id: ExistingTask["id"]) => Promise<void>;
  /** RTD = Reschedule Task Dialog */
  handleOpenRTD: (id: ExistingTask["id"]) => Promise<void>;
  handleOpenEditTaskDialog: (id: ExistingTask["id"]) => void;
  handleCreateNewEstimate: (taskDoc: ExistingTask) => void;
  handleOpenEstimateListDialog?: (taskID: ExistingTask["id"]) => void;
  children: {
    changeTaskStatusButton: React.ReactNode;
  };
}

type BusyType = { type: "delete" | "RTD"; id: string };

export default function TaskCardActionButtons(props: Props): JSX.Element {
  const [isBusy, setIsBusy] = useState<BusyType | null>(null);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  return (
    <div className="mt-6 flex justify-between gap-4">
      {props.children.changeTaskStatusButton}

      <article className="flex w-14 flex-col items-center justify-start gap-1 text-center">
        <button
          className="rounded-full bg-gray-100 p-2 transition-colors hover:bg-gray-200"
          onClick={() => props.handleCreateNewEstimate(props.task)}
        >
          <AddIcon className="text-gray-800" />
        </button>

        <span className="text-xs text-gray-500">
          {strings.CREATE_NEW_ESTIMATE}
        </span>
      </article>

      {props.existingEstimatesForTask === true && (
        <article className="flex w-14 flex-col items-center justify-start gap-1 text-center">
          <button
            className="rounded-full bg-gray-100 p-2 transition-colors hover:bg-gray-200"
            onClick={() =>
              props.handleOpenEstimateListDialog
                ? props.handleOpenEstimateListDialog(props.task.id)
                : null
            }
          >
            <ReceiptIcon className="text-gray-800" />
          </button>

          <span className="text-xs text-gray-500">{strings.VIEW_ESTIMATE}</span>
        </article>
      )}

      <article className="flex w-14 flex-col items-center justify-start gap-1 text-center">
        <button
          className="rounded-full bg-gray-100 transition-colors hover:bg-gray-200"
          onClick={() => props.handleOpenEditTaskDialog(props.task.id)}
        >
          <PencilIcon className="w-10 p-2 text-gray-800" />
        </button>

        <span className="text-xs text-gray-500">{strings.EDIT_TASK}</span>
      </article>

      {![
        TaskStatus.AWAITING_REVIEW,
        TaskStatus.IN_PROGRESS,
        TaskStatus.IN_ROUTE,
        TaskStatus.AWAITING_PAYMENT,
        TaskStatus.ON_HOLD,
        TaskStatus.COMPLETE,
        TaskStatus.CANCELED,
      ].includes(props.task.taskStatus) ? (
        <article className="flex w-14 flex-col items-center justify-start gap-1 text-center">
          <button
            className={`rounded-full bg-gray-100 p-2 transition-colors ${isBusy?.id === props.task.id ? "cursor-auto" : "hover:bg-gray-200"}`}
            disabled={isBusy?.id === props.task.id}
            onClick={async () => {
              setIsBusy({ type: "RTD", id: props.task.id });
              try {
                await props.handleOpenRTD(props.task.id);
                setIsBusy(null);
              } catch (e) {
                logger.warn(e);
                logger.error(`Unable to open RTD @ ${props.task.refPath}`);
                addToastMessage({
                  id: createToastMessageID(),
                  message: strings.GENERIC_ERROR_MESSAGE,
                  dialog: false,
                  type: "error",
                });
                setIsBusy(null);
              }
            }}
          >
            {props.task.id === isBusy?.id && isBusy.type === "RTD" ? (
              <LoadingSpinner
                marginClass="m-0"
                sizeClass="h-5 w-5 sm:h-6 sm:w-6"
              />
            ) : (
              <EditCalendarIcon className="text-gray-800" />
            )}
          </button>

          <span className="text-xs text-gray-500">{strings.EDIT_DATE}</span>
        </article>
      ) : null}

      <article className="flex w-14 flex-col items-center justify-start gap-1 text-center">
        <button
          className={`rounded-full bg-red-50 transition-colors ${
            isBusy?.id === props.task.id ? "cursor-auto" : "hover:bg-red-100"
          }`}
          disabled={isBusy?.id === props.task.id}
          onClick={async () => {
            setIsBusy({ type: "delete", id: props.task.id });
            try {
              await props.handleDeleteTask(props.task.id);
              setIsBusy(null);
            } catch (e) {
              logger.warn(e);
              logger.error(`Unable to delete task @ ${props.task.refPath}`);
              addToastMessage({
                id: createToastMessageID(),
                message: strings.ERR_DELETE_TASK,
                dialog: false,
                type: "error",
              });
              setIsBusy(null);
            }
          }}
        >
          {props.task.id === isBusy?.id && isBusy.type === "delete" ? (
            <span className="block w-10 p-2">
              <LoadingSpinner
                marginClass="m-0"
                colorClass="text-red-700"
                sizeClass="h-5 w-5 sm:h-6 sm:w-6"
              />
            </span>
          ) : (
            <TrashIcon className="w-10 fill-current p-2 text-red-700" />
          )}
        </button>
        <span className="text-xs text-gray-500">{strings.DELETE_TASK}</span>
      </article>
    </div>
  );
}
