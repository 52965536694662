// Local
import { DbRead } from "../../../database";
import {
  CraftRecordPersistenceTypes,
  ExistingTask,
} from "../../../models/task";
import { TaskStatus } from "../../../models/task-status";

export async function checkCraftRecordPersistencePrompt(
  task: ExistingTask,
  previousTaskStatus: TaskStatus,
): Promise<boolean> {
  if (
    task.taskStatus === TaskStatus.COMPLETE &&
    task.taskStatus !== previousTaskStatus &&
    task.craftRecordPersistence === CraftRecordPersistenceTypes.PROMPT
  ) {
    const craftRecord = await DbRead.parentRecords.getByRefPath(
      task.craftRecordID,
    );
    if (craftRecord?.numOpenTasks === 1) {
      return true;
    }
  }
  return false;
}
