// Libs
import { Timestamp } from "firebase/firestore";

// Local
import BaseModal from "./BaseModal";
import * as strings from "../strings";
import { convertFSTimestampToLuxonDT } from "../utils";
import BaseButtonSecondary from "./BaseButtonSecondary";
import { FeedbackPlusExtraType } from "../Pages/Feedback/FeedbackListContainer";
import { ExistingFeedback } from "../models/feedback";

interface Props {
  // DIALOG BASICS
  isOpen: boolean;
  onClose: () => void;
  // DATA
  feedback: FeedbackPlusExtraType;
}

export default function ViewFeedbackDialog(props: Props): JSX.Element {
  const socialsTappedResponse =
    Object.keys(props.feedback.socialsTapped).length > 0;
  const feedbackDataResponse =
    Object.keys(props.feedback.feedbackData).length > 0;

  return (
    <BaseModal
      open={props.isOpen}
      closeModal={props.onClose}
      title={
        <div className="relative rounded-t-lg bg-primary p-6 text-xl font-semibold text-white lg:px-8">
          {strings.feedbackFromCustomer(props.feedback.customerName)}
        </div>
      }
      parentDivStyles="text-left max-w-2xl lg:max-w-4xl"
    >
      <div className="p-6 text-gray-900 md:p-8">
        {feedbackDataResponse && (
          <FeedbackData feedbackData={props.feedback.feedbackData} />
        )}

        {socialsTappedResponse && (
          <TappedSocials
            socialsTapped={props.feedback.socialsTapped}
            hasSectionAbove={feedbackDataResponse} // so we can add some margin-top if true
          />
        )}

        {(feedbackDataResponse || socialsTappedResponse) && (
          <hr className="my-8 block w-full border border-gray-200" />
        )}

        <div className="grid max-w-[500px] grid-cols-2 gap-6 lg:max-w-full lg:grid-cols-4">
          <article className="flex flex-col">
            <span>{props.feedback.countSentToCustomer}</span>
            <span className="text-sm font-semibold text-gray-600">
              {strings.NUM_EMAILS_SENT}
            </span>
          </article>

          <article className="flex flex-col">
            <span>
              {insertReadableDateTimeOrDashes(props.feedback.timestampLastSent)}
            </span>
            <span className="text-sm font-semibold text-gray-600">
              {strings.EMAIL_LAST_SENT}
            </span>
          </article>

          <article className="flex flex-col">
            <span>
              {insertReadableDateTimeOrDashes(
                props.feedback.timestampCustomerOpened,
              )}
            </span>
            <span className="text-sm font-semibold text-gray-600">
              {strings.TIME_OPENED}
            </span>
          </article>

          <article className="flex flex-col">
            <span>
              {insertReadableDateTimeOrDashes(props.feedback.timestampCreated)}
            </span>
            <span className="text-sm font-semibold text-gray-600">
              {strings.TIME_CREATED}
            </span>
          </article>
        </div>

        <div className="mt-8 flex justify-center">
          <BaseButtonSecondary
            type="button"
            className="w-full uppercase xs:w-52"
            onClick={props.onClose}
          >
            {strings.buttons.CLOSE}
          </BaseButtonSecondary>
        </div>
      </div>
    </BaseModal>
  );
}

// TODO: This needs to be revisited when feedbackData becomes dynamic
function FeedbackData(props: {
  feedbackData: ExistingFeedback["feedbackData"];
}): JSX.Element {
  // Responses
  const serviceRating = props.feedbackData["rateOurService"];
  const technicianRating = props.feedbackData["rateOurTechnician"];
  const additionalComments = props.feedbackData["additionalComments"];

  return (
    <ul className="space-y-2">
      <li>
        <span className="font-semibold text-gray-600">
          {strings.SERVICE_RATING}:{" "}
        </span>
        {serviceRating ? serviceRating : "--"}
      </li>
      <li>
        <span className="font-semibold text-gray-600">
          {strings.TECHNICIAN_RATING}:{" "}
        </span>
        {technicianRating ? technicianRating : "--"}
      </li>
      <li>
        <span className="font-semibold text-gray-600">
          {strings.ADDITIONAL_COMMENTS}:{" "}
        </span>
        {additionalComments ? additionalComments : "--"}
      </li>
    </ul>
  );
}

function TappedSocials(props: {
  socialsTapped: ExistingFeedback["socialsTapped"];
  hasSectionAbove: boolean;
}): JSX.Element {
  const moreThanOne = Object.keys(props.socialsTapped).length > 1;
  return (
    <>
      {props.hasSectionAbove && (
        <hr className="my-8 block w-full border border-gray-200" />
      )}
      <p className="mb-3 font-semibold text-gray-600">
        This customer has clicked the following links:
      </p>
      <ul className="space-y-2">
        {Object.entries(props.socialsTapped).map(([social, ts]) => (
          <li
            key={social}
            className={`${
              moreThanOne
                ? "grid max-w-[500px] grid-cols-2 gap-6"
                : "flex flex-wrap gap-2 xxs:gap-6"
            }`}
          >
            <span className="capitalize">{social}</span>
            <span>{insertReadableDateTime(ts)}</span>
          </li>
        ))}
      </ul>
    </>
  );
}

/**
 * Will return empty string if given arg is not a Timestamp.
 */
function insertReadableDateTime(ts: Timestamp): string {
  if (ts instanceof Timestamp) {
    const lux = convertFSTimestampToLuxonDT(ts);
    return lux.toLocal().toFormat("LL/dd/yy hh:mm a");
  } else {
    return "";
  }
}

function insertReadableDateTimeOrDashes(ts: Timestamp | null) {
  let lastSent = "";
  if (ts) {
    lastSent = insertReadableDateTime(ts);
  }
  if (lastSent.length === 0) {
    lastSent = "--";
  }
  return lastSent;
}
