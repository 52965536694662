//Libs
import { Timestamp } from "firebase/firestore";

//Local
import {
  getReadableStiltInvoiceStatus,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { convertFSTimestampToLuxonDT, phoneUtils } from "../../utils";
import { BillingInfo, ServiceAddress } from "../../models/customer";
import { StyledTooltip } from "../StyledTooltip";
import { ListIconWithRef } from "../TaskViewButton";
import { PersonIconWithRef } from "../PersonViewButton";
import { PDFIconWithSpinner } from "../PDFButton";

interface Props {
  amountDue: number;
  issueDate: string;
  poNumber: string | null;
  invoiceNumber: string | null;
  jobNumber: string | null;
  invoiceStatus: string | null;
  dueDate: string | null;
  merchantLogoURL: string | null;
  timestampTaskCompleted: Timestamp | null;
  billingInfo?: BillingInfo | null;
  serviceInfo?: ServiceAddress | null;
  invoicePaymentTerms: string | null;
  sitePaymentTerms?: Record<string, TemplatePaymentTerm>;
  craftRecordID: string | null;
  customerID: string | null;
  estimateID: string | null;
  customerLocationID: string | null;
  invoiceID: string | null;
  goToWorkRecordAndTasksPage?: (craftRecordID: string) => void;
  goToCustomerPage?: (customerID: string) => void;
  getPDF?: (invoiceID: string) => Promise<void>;
}

export default function InvoiceHeader(props: Props) {
  let customBg = "bg-primary text-primaryButtonText";

  const {
    craftRecordID,
    customerID,
    invoiceID,
    goToWorkRecordAndTasksPage,
    goToCustomerPage,
    getPDF,
  } = props;

  const displayingAtLeastOneIcon =
    (typeof craftRecordID === "string" &&
      goToWorkRecordAndTasksPage != undefined) ||
    (typeof customerID === "string" && goToCustomerPage != undefined) ||
    (typeof invoiceID === "string" && getPDF != undefined);

  switch (props.invoiceStatus) {
    case "paid":
      customBg = "bg-greenPass text-white";
      break;
    case "partiallyPaid":
      customBg = "bg-orangeError text-white";
      break;
    case null:
      customBg = "bg-gray-300 text-gray-800";
      break;
  }

  return (
    <>
      <div
        className={`mb-4 ${
          props.merchantLogoURL
            ? "md:flex md:flex-row md:items-start xl:mx-auto xl:max-w-5xl"
            : ""
        }`}
      >
        {props.merchantLogoURL && (
          <img
            src={props.merchantLogoURL}
            alt="merchant logo"
            className="mx-auto mb-10 max-h-[6rem] w-auto md:mb-0 md:ml-0 md:max-h-full md:max-w-[15rem]"
          />
        )}
        <div
          className={`${
            props.amountDue === 0
              ? "flex flex-col items-center justify-center gap-6"
              : ""
          } ${
            props.merchantLogoURL && props.amountDue === 0 ? "md:flex-row" : ""
          }`}
        >
          <ul className="pl-3">
            <li>
              Invoice #: <span>{props.invoiceNumber ?? ""}</span>
            </li>

            {props.poNumber && (
              <li>
                Customer PO: <span>{props.poNumber ?? ""}</span>
              </li>
            )}
            {props.jobNumber && (
              <li>
                Job #: <span>{props.jobNumber ?? ""}</span>
              </li>
            )}
            {props.sitePaymentTerms && props.invoicePaymentTerms && (
              <li>
                Payment Terms:{" "}
                <span>
                  {getPaymentTermsTitle(
                    props.invoicePaymentTerms,
                    props.sitePaymentTerms,
                  )}
                </span>
              </li>
            )}
            <li>
              Issue Date: <span>{props.issueDate}</span>
            </li>
            <li>
              Due Date: <span>{props.dueDate}</span>
            </li>
            {props.timestampTaskCompleted && (
              <li>
                Task Completion Date:{" "}
                <span>
                  {convertFSTimestampToLuxonDT(
                    props.timestampTaskCompleted,
                  ).toFormat("LL/dd/yy")}
                </span>
              </li>
            )}
            {props.invoiceStatus && (
              <li>
                <div
                  className={`m-1 w-fit rounded-full px-2.5 pb-1 pt-0.5 text-xs font-medium ${customBg} capitalize`}
                >
                  {getReadableStiltInvoiceStatus(props.invoiceStatus)}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>

      {/* ADDRESSES AND ICONS SECTION */}
      <div
        className={`mx-3 flex flex-row flex-wrap pt-4 xl:mx-auto xl:max-w-5xl ${displayingAtLeastOneIcon ? "justify-between gap-5" : "justify-between gap-5 md:justify-start md:gap-32 lg:gap-56"}`}
      >
        {/* BILLING ADDRESS */}
        {props.billingInfo && (
          <div>
            <h2 className="text-lg font-bold">Billing Address:</h2>
            <ul>
              <li>{props.billingInfo.name}</li>
              <li>{props.billingInfo.addressLine1}</li>
              {props.billingInfo.addressLine2 !== "" && (
                <li>{props.billingInfo.addressLine2}</li>
              )}
              <li>
                {props.billingInfo.city ?? ""}, {props.billingInfo.state ?? ""}{" "}
                {props.billingInfo.zipCode ?? ""}
              </li>
              <li>{props.billingInfo.email ?? ""}</li>
              <li>{phoneUtils.display(props.billingInfo.phone) ?? ""}</li>
            </ul>
          </div>
        )}
        {/* JOB ADDRESS */}
        {props.serviceInfo && (
          <div>
            <h2 className="text-lg font-bold">Service Address:</h2>
            <ul>
              <li>{props.serviceInfo.addressLine1}</li>
              {props.serviceInfo.addressLine2 !== "" && (
                <li>{props.serviceInfo.addressLine2}</li>
              )}
              <li>
                {props.serviceInfo.city ?? ""}, {props.serviceInfo.state ?? ""}{" "}
                {props.serviceInfo.zipCode ?? ""}
              </li>
            </ul>
          </div>
        )}

        {/* ICONS */}
        {displayingAtLeastOneIcon && (
          <div className="justify-self-end">
            {craftRecordID && goToWorkRecordAndTasksPage ? (
              <StyledTooltip title="View Job">
                <ListIconWithRef
                  ref={(ref) => {
                    if (!ref) return;

                    ref.onclick = (e) => {
                      e.stopPropagation();
                      goToWorkRecordAndTasksPage(craftRecordID);
                    };
                  }}
                />
              </StyledTooltip>
            ) : null}
            {goToCustomerPage && customerID && (
              <StyledTooltip title="View Customer">
                <PersonIconWithRef
                  ref={(ref) => {
                    if (!ref) return;

                    ref.onclick = (e) => {
                      e.stopPropagation();
                      goToCustomerPage(customerID);
                    };
                  }}
                />
              </StyledTooltip>
            )}
            {getPDF && invoiceID && (
              <StyledTooltip title="Download PDF">
                <PDFIconWithSpinner onCreate={async () => getPDF(invoiceID)} />
              </StyledTooltip>
            )}
          </div>
        )}
      </div>
    </>
  );
}

function getPaymentTermsTitle(
  invoicePaymentTerms: string | null,
  templatePaymentTerms: Record<string, TemplatePaymentTerm>,
): string {
  if (invoicePaymentTerms == null) {
    return "N/A";
  } else {
    const templateTerms = templatePaymentTerms[invoicePaymentTerms];
    return templateTerms.title;
  }
}
