// Local
import BaseModal from "./BaseModal";
import BaseButtonPrimary from "./BaseButtonPrimary";
import BaseButtonSecondary from "./BaseButtonSecondary";
import { TaskStatus } from "../models/task-status";
import * as strings from "../strings";

export default function TaskStatusDialog(props: {
  isDialogOpen: boolean;
  closeDialog: () => void;
  currentTaskStatus: TaskStatus;
  handleUpdateTaskStatus: (taskStatus: TaskStatus) => Promise<void>;
}) {
  const taskCompleted =
    props.currentTaskStatus === 8 || props.currentTaskStatus === 90;

  return (
    <BaseModal
      open={props.isDialogOpen}
      title={getTheTitle(props.currentTaskStatus)}
      closeModal={props.closeDialog}
    >
      <div>
        <p className="mb-4 text-left text-base font-normal">
          {displayMessage(props.currentTaskStatus)}
        </p>

        {/* BUTTONS */}
        <div className="mt-8 flex items-center gap-4">
          <BaseButtonSecondary
            className="w-full xs:text-base"
            type="button"
            onClick={props.closeDialog}
          >
            {taskCompleted ? strings.buttons.CLOSE : strings.buttons.CANCEL}
          </BaseButtonSecondary>

          {!taskCompleted ? (
            <BaseButtonPrimary
              className="w-full xs:text-base"
              type="button"
              onClick={() => {
                props.handleUpdateTaskStatus(props.currentTaskStatus);
                props.closeDialog();
              }}
            >
              {props.currentTaskStatus === 50
                ? strings.buttons.RESUME
                : strings.buttons.START}
            </BaseButtonPrimary>
          ) : null}
        </div>
      </div>
    </BaseModal>
  );
}

function getTheTitle(currentTaskStatus: TaskStatus): string {
  // SCHEDULED || AWAITING
  if (currentTaskStatus === 20 || currentTaskStatus === 30) {
    return "Begin Checklist Task";

    // AWAITING_APPROVAL
  } else if (currentTaskStatus === 8) {
    return "Awaiting Approval";

    // COMPLETE
  } else if (currentTaskStatus === 90) {
    return "Completed Task";

    // ON_HOLD
  } else if (currentTaskStatus === 50) {
    return "Resume Checklist Task";

    // AWAITING_ESTIMATE || AWAITING_SCHEDULE (if it's IN_PROGRESS, this dialog won't open)
  } else {
    return "Awaiting estimate or awaiting schedule. TODO: come back!";
  }
}

function displayMessage(currentTaskStatus: TaskStatus): string {
  // SCHEDULED || AWAITING
  if (currentTaskStatus === 20 || currentTaskStatus === 30) {
    return "This task has not yet been started. Would you like to start this task and move the status to In Progress?";

    // AWAITING_APPROVAL || COMPLETE
  } else if (currentTaskStatus === 8 || currentTaskStatus === 90) {
    return "This task has already been completed.";
    // no primary button. secondary button = 'Close'

    // ON_HOLD
  } else if (currentTaskStatus === 50) {
    return "This task is On Hold. Would you like to resume this task and move the status to In Progress?";
    // primary button = 'Resume'

    // AWAITING_ESTIMATE || AWAITING_SCHEDULE (if it's IN_PROGRESS, this dialog won't open)
  } else {
    return "Checklists shouldn't be awaiting estimate or awaiting schedule. TODO: come back!";
  }
}

// function DisplayButtons() {
//   <div className="mt-8 flex items-center gap-4">
//   <BaseButtonSecondary
//     className="w-full xs:text-base"
//     type="button"
//     onClick={props.closeDialog}
//   >
//     Cancel
//   </BaseButtonSecondary>

//   <BaseButtonPrimary
//     className="w-full xs:text-base"
//     type="button"
//     onClick={() => {
//       props.handleUpdateTaskStatus(props.currentTaskStatus);
//       props.closeDialog();
//     }}
//   >
//     Start
//   </BaseButtonPrimary>
// </div>
// }
