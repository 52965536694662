// Libs
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

// Local
import { CraftRecord } from "../../models/craft-record";

interface Props {
  workRecord: CraftRecord;
  customerFullAddress: string | null;

  // components
  children: {
    customerInfo: React.ReactNode;
    customerTagsPills: React.ReactNode;
    actionButtonRow: React.ReactNode;
    siteMapOrPhoto: React.ReactNode;
    detailsAndEventsPanels: React.ReactNode;
  };
}

export default function WorkRecordPanel(props: Props): JSX.Element {
  return (
    <div className="w-full rounded-md pb-6 shadow-elevation lg:max-w-screen-xs xl:max-w-xl">
      <div className="mb-4">{props.children.siteMapOrPhoto}</div>
      <div className="px-4 pb-4">
        {props.children.customerTagsPills}
        {props.workRecord.description && (
          <p className="ml-0.5 mt-2 flex rounded border border-current px-3 py-2 text-base text-gray-900 shadow">
            <TextSnippetIcon
              className="mr-2 mt-0.5"
              aria-hidden="true"
              style={{ height: "1.2rem", width: "1.2rem" }}
            />
            {props.workRecord.description}
          </p>
        )}
        {props.children.customerInfo}
        {props.children.actionButtonRow}
      </div>
      <div className="px-4">{props.children.detailsAndEventsPanels}</div>
    </div>
  );
}
