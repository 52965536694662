import { Control, FieldValues } from "react-hook-form";

// Local
import { ExistingCustomField } from "../../models/custom-field";
import FieldTypeBoolean from "./FieldTypeBoolean";
import FieldTypeCurrency from "./FieldTypeCurrency";
import FieldTypeHoursMinutes from "./FieldTypeHoursMinutes";
import FieldTypeMultipleUid from "./FieldTypeMultipleUid";
import FieldTypeNumber from "./FieldTypeNumber";
import FieldTypeSelectionCheckbox from "./FieldTypeSelectionCheckbox";
import FieldTypeSelectionDropdown from "./FieldTypeSelectionDropdown";
import FieldTypeSelectionRadio from "./FieldTypeSelectionRadio";
import FieldTypeString from "./FieldTypeString";
import FieldTypeStringTextarea from "./FieldTypeStringTextarea";
import FieldTypeStringArray from "./FieldTypeStringArray";
import FieldTypeTimestamp from "./FieldTypeTimestamp";
import { IMultipleUid_AssignUser } from "./MultipleUidDialog";
import { Json } from "../../models/json-type";
import { getSelectedUsers } from "./getSelectedUsers";

interface Props {
  userList: IMultipleUid_AssignUser[];
  handleSubmitMultipleUID: (args: Record<string, string[]>) => void;
  customField: ExistingCustomField;
  control: Control<FieldValues, any>;
  defaultValue: Json | Date;
  /**
   * So we can pass `false` within edit dialogs, where nothing is required.
   * (Otherwise, just passing customField.required)
   */
  isRequired: boolean;
}

export function Input(props: Props): JSX.Element | null {
  const fieldType = props.customField.fieldType;
  switch (fieldType) {
    case "string":
    case "uid": {
      return (
        <FieldTypeString
          customField={props.customField}
          control={props.control}
          defaultValue={props.defaultValue}
          isRequired={props.isRequired}
        />
      );
    }
    case "string-textarea": {
      return (
        <FieldTypeStringTextarea
          customField={props.customField}
          control={props.control}
          defaultValue={props.defaultValue}
          isRequired={props.isRequired}
        />
      );
    }
    case "number": {
      return (
        <FieldTypeNumber
          customField={props.customField}
          control={props.control}
          defaultValue={props.defaultValue}
          isRequired={props.isRequired}
        />
      );
    }
    case "selection": {
      if (props.customField.selectionOptions !== null) {
        if (Object.keys(props.customField.selectionOptions).length === 1) {
          return (
            <FieldTypeSelectionCheckbox
              customField={props.customField}
              control={props.control}
              defaultValue={props.defaultValue}
              isRequired={props.isRequired}
            />
          );
        } else if (
          Object.keys(props.customField.selectionOptions).length === 2
        ) {
          return (
            <FieldTypeSelectionRadio
              customField={props.customField}
              control={props.control}
              defaultValue={props.defaultValue}
              isRequired={props.isRequired}
            />
          );
        } else {
          return (
            <FieldTypeSelectionDropdown
              customField={props.customField}
              control={props.control}
              defaultValue={props.defaultValue}
              isRequired={props.isRequired}
            />
          );
        }
      } else {
        return null;
      }
    }
    case "bool": {
      return (
        <FieldTypeBoolean
          customField={props.customField}
          control={props.control}
          defaultValue={props.defaultValue}
          isRequired={props.isRequired}
        />
      );
    }
    case "currency": {
      return (
        <FieldTypeCurrency
          customField={props.customField}
          control={props.control}
          defaultValue={props.defaultValue}
          isRequired={props.isRequired}
        />
      );
    }
    case "multiple-uid": {
      if (props.defaultValue instanceof Date) {
        throw new Error(
          `Expected defaultValue to NOT be a Date for multiple-uid custom field. ${JSON.stringify(
            props.customField,
            null,
            2,
          )}`,
        );
      }
      // fresh userList for each multiple-uid input
      const xuserList = props.userList.map((obj) => Object.assign({}, obj));
      const users = getSelectedUsers(xuserList, props.defaultValue);
      const defaultValue = users
        .filter((user) => user.isAssigned)
        .map((user) => user.name)
        .join(", ");

      return (
        <FieldTypeMultipleUid
          userList={users}
          defaultValue={defaultValue}
          customField={props.customField}
          control={props.control}
          isRequired={props.isRequired}
          handleSubmitMultipleUID={props.handleSubmitMultipleUID}
        />
      );
    }
    case "timestamp": {
      return (
        <FieldTypeTimestamp
          customField={props.customField}
          control={props.control}
          defaultValue={props.defaultValue}
          isRequired={props.isRequired}
        />
      );
    }
    case "string-array": {
      return (
        <FieldTypeStringArray
          customField={props.customField}
          control={props.control}
          defaultValue={props.defaultValue}
          isRequired={props.isRequired}
        />
      );
    }
    case "hours-minutes": {
      return (
        <FieldTypeHoursMinutes
          customField={props.customField}
          control={props.control}
          defaultValue={props.defaultValue}
          isRequired={props.isRequired}
        />
      );
    }
    default: {
      const _exhaustivenessCheck: never = fieldType;
      return _exhaustivenessCheck;
    }
  }
}
