/**
 * @param hour 0 - 23
 * @returns hour between 1 & 12, plus AM or PM
 */
export function convertFromMilitaryTime(hour: number): [number, string] {
  if (hour >= 1 && hour <= 11) {
    return [hour, "AM"];
  } else if (hour === 0) {
    return [12, "AM"];
  } else if (hour === 12) {
    return [hour, "PM"];
  } else {
    const x = hour - 12;
    return [x, "PM"];
  }
}
