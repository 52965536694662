//Libs
import {
  Fragment,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  RowClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

//Local
import {
  ExistingCustomer,
  ExistingCustomerWithLocations,
} from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import * as strings from "../../strings";
import ChipTag from "../../components/ChipTag";
import { customerWithLocationsTableCell } from "../../components/customers/CustomerComponents";
import { phoneUtils } from "../../utils/phoneUtils";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

export interface Props {
  //DATA
  customerListWithLocations: ExistingCustomerWithLocations[];
  // SEARCH BOX
  searchBoxElement: React.ReactNode;
  //FUNCTIONS
  goToCustomerPage: (customerID: string) => void;
  createNewTaskButton: React.ReactNode;
  addNewCustomerButton: React.ReactNode;
  customerScheduleButton: React.ReactNode;
  //CHILDREN
  children: {
    AddNewCustomerForm: React.ReactNode;
  };
}

export default function CustomerListPage({
  customerListWithLocations,
  searchBoxElement,
  goToCustomerPage,
  customerScheduleButton,
  ...props
}: Props) {
  // const renderMembership = (
  //   params: ICellRendererParams
  // ): JSX.Element | null => {
  //   const membershipTitlesCount: ExistingCustomerWithLocations["membershipTitlesCount"] =
  //     params.data.membershipTitlesCount;
  //   if (Object.keys(membershipTitlesCount).length === 0) {
  //     return null;
  //   } else {
  //     return (
  //       <div className="flex items-center">
  //         {Object.entries(membershipTitlesCount).map(
  //           ([title, quantity], index) => {
  //             return (
  //               <div
  //                 key={index}
  //                 className={`m-1 rounded-full bg-sky-300 px-2.5 pt-0.5 pb-1 text-xs font-medium capitalize text-white`}
  //               >
  //                 {quantity}x {title}
  //               </div>
  //             );
  //           }
  //         )}
  //       </div>
  //     );
  //   }
  // };

  return (
    <div>
      <h1 className="text-5xl font-semibold text-primary">
        {strings.CUSTOMERS}
      </h1>
      {props.children.AddNewCustomerForm}
      <div className={"ag-theme-alpine my-4 flex h-[600px] flex-col pb-14"}>
        <CustomerListTable
          customerListWithLocations={customerListWithLocations}
          searchBoxElement={searchBoxElement}
          goToCustomerPage={goToCustomerPage}
          createNewTaskButton={props.createNewTaskButton}
          addNewCustomerButton={props.addNewCustomerButton}
          customerScheduleButton={customerScheduleButton}
        />
      </div>
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface CustomerListTableProps {
  customerListWithLocations: Props["customerListWithLocations"];
  searchBoxElement: Props["searchBoxElement"];
  customerScheduleButton: Props["customerScheduleButton"];
  createNewTaskButton: Props["createNewTaskButton"];
  addNewCustomerButton: Props["addNewCustomerButton"];
  goToCustomerPage: Props["goToCustomerPage"];
}

/* TABLE COMPONENT */
const CustomerListTable = memo(
  ({
    customerListWithLocations,
    searchBoxElement,
    customerScheduleButton,
    goToCustomerPage,
    ...props
  }: CustomerListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    function renderTagsCell(params: ICellRendererParams): JSX.Element | null {
      const tagsList: ExistingCustomer["tags"] = params.value;
      if (Array.isArray(tagsList)) {
        return (
          <div className="flex min-h-fit flex-wrap items-center">
            {tagsList.map((tag) => {
              if (typeof tag === "string") {
                const key = tag + params.node.data.id;
                return <ChipTag tag={tag} key={key} />;
              } else {
                return null;
              }
            })}
          </div>
        );
      } else {
        return null;
      }
    }

    const renderCustomer = (params: ICellRendererParams): JSX.Element => {
      return customerWithLocationsTableCell(params);
    };

    const renderPhone = (params: ICellRendererParams): JSX.Element => {
      const phone = params.valueFormatted ? params.valueFormatted : "--";
      return <div>{phoneUtils.display(phone)}</div>;
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Customer",
        cellRenderer: renderCustomer,
        tooltipValueGetter: () => "Show Customer",
        autoHeight: true,
        minWidth: 400,
        flex: 2,
      },
      {
        headerName: "Email",
        field: "email",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Phone #",
        field: "phone",
        cellRenderer: renderPhone,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      // {
      //   headerName: "Memberships",
      //   cellRenderer: renderMembership,
      //   field: "membershipTitlesCount",
      //   valueGetter: (params) => {
      //     const membershipTitles = Object.keys(
      //       params.data.membershipTitlesCount
      //     );
      //     const noDuplicate = [...new Set(membershipTitles)];
      //     return noDuplicate.join(",");
      //   },
      //   getQuickFilterText: (params: GetQuickFilterTextParams) => {
      //     return getMembershipStatusForSearchBox(params);
      //   },
      //   cellStyle: {
      //     height: "100%",
      //     display: "flex",
      //     alignItems: "center",
      //   },
      // },
      {
        headerName: "Tags",
        field: "tags",
        cellRenderer: renderTagsCell,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        autoHeight: true,
      },
      {
        headerName: "Notes",
        field: "notes",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <Fragment>
        <div className="flex flex-col justify-between gap-6 sm:flex-row sm:items-end">
          {searchBoxElement}

          <div className="flex flex-col gap-4 sm:flex-row sm:justify-end">
            <div className="flex items-center">{customerScheduleButton}</div>
            <div className="flex items-center">{props.createNewTaskButton}</div>
            <div className="flex items-center">
              {props.addNewCustomerButton}
            </div>
          </div>
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={customerListWithLocations}
          animateRows={true}
          rowSelection="single"
          rowHeight={50}
          cacheQuickFilter={true}
          columnDefs={columnDefs}
          onRowClicked={(event: RowClickedEvent) =>
            goToCustomerPage(event.data.id)
          }
        />
      </Fragment>
    );
  },
);

export function getAddressStringForFilter(params: GetQuickFilterTextParams) {
  const addresses = params.value.map(
    (location: ExistingCustomerLocation) => location.fullAddress,
  );
  return addresses.join(" | ");
}

export function getMembershipStatusForSearchBox(
  params: GetQuickFilterTextParams,
) {
  const membershipTitles = Object.keys(params.data.membershipTitlesCount);
  const noDuplicate = [...new Set(membershipTitles)];
  return noDuplicate.join(",");
}
