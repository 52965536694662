// Local
import WorkTypeIcon from "../../assets/js/WorkTypeIcon";
import { CraftTypes, getReadableCraftType } from "../../models/craft-types";

export default function WorkTypeCard(args: {
  availableCraftType: CraftTypes;
  onHandleView: () => void;
}) {
  return (
    <div
      className="flex h-32 max-w-md cursor-pointer flex-col items-center justify-between rounded-2xl border border-gray-300 bg-white p-4 shadow-md transition-colors hover:border-gray-800"
      onClick={args.onHandleView}
    >
      <WorkTypeIcon craftType={args.availableCraftType} />
      <p className="text-lg font-medium">
        {getReadableCraftType(args.availableCraftType)}
      </p>
    </div>
  );
}
