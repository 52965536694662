// Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

type DismissibleProps = {
  type: "success" | "warning" | "error" | "info";
  dismissible?: true;
  onDismiss: () => void;
  children: {
    message: React.ReactNode;
    icon?: React.ReactNode;
  };
};
type NotDismissibleProps = {
  type: "success" | "warning" | "error" | "info";
  dismissible?: false;
  children: {
    message: React.ReactNode;
    icon?: React.ReactNode;
  };
};
type Props = DismissibleProps | NotDismissibleProps;

/**
 * If dismissible is true, you have to pass in an onClick handler.
 * types: success, warning, error, info
 */
export default function StyledMessage(props: Props): JSX.Element {
  const typeStyles: Record<Props["type"], string> = {
    success: "bg-green-100 text-green-800",
    warning: "bg-orange-100 text-orange-800",
    error: "bg-red-100 text-red-900",
    info: "bg-blue-50 text-blue-700",
  };

  const iconColors = {
    success: "text-green-800",
    warning: "text-orange-800",
    error: "text-red-900",
    info: "text-blue-700",
  };

  if (!props.dismissible) {
    return (
      <p
        className={`${
          typeStyles[props.type]
        } inline-block rounded-md px-4 py-2 text-center`}
      >
        <span className="flex items-center gap-2">
          {props.children.icon && (
            <span className={`flex-shrink-0 ${iconColors[props.type]}`}>
              {props.children.icon}
            </span>
          )}
          {props.children.message}
        </span>
      </p>
    );
  } else {
    return (
      <div
        className={`${
          typeStyles[props.type]
        } flex items-center justify-between rounded-md px-4 py-2 text-center`}
      >
        <span className="flex items-center gap-2">
          {props.children.icon && props.children.icon}
          {props.children.message}
        </span>
        <XMarkIcon
          aria-label="dismiss message"
          className="ml-3 h-5 w-5 flex-shrink-0 cursor-pointer"
          onClick={props.onDismiss}
        />
      </div>
    );
  }
}
