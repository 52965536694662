// Format the price above to USD using the locale, style, and currency.
export const DollarCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const CanadianDollarCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "CAD",
});

export default function currencyFormatter(amount: number, currency: string) {
  if (currency === "cad" || currency === "CAD") {
    return CanadianDollarCurrency.format(amount);
  } else {
    return DollarCurrency.format(amount);
  }
}

export function formatAsPercent(value: number): string {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
}
