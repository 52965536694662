//Local
import FormBody from "./FormBody";
import { Form } from "formik";

interface Props {
  children: {
    content: React.ReactNode;
    actions: React.ReactNode;
  };
}

export default function ModalItem({ children, ...props }: Props) {
  return (
    <Form autoComplete="off">
      <FormBody>{children.content}</FormBody>
      <div className="pt-5">{children.actions}</div>
    </Form>
  );
}
