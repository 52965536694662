// Libs
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";

// Local
import * as strings from "../../../strings";
import {
  CustomFieldResponse,
  ExistingCustomField,
} from "../../../models/custom-field";
import { getCustomFieldsToDisplay } from "../../../Pages/Customers/CreateTaskForCustomerContainer";
import { IMultipleUid_AssignUser } from "../../CustomFields/MultipleUidDialog";
import { Input, getDefaultValuesForNewResponse } from "../../CustomFields";
import { ScheduleByPriorityType } from "./SchedulingSection";
import { useUserDisplayNamesStore } from "../../../store/user-display-names-map";
import { Json } from "../../../models/json-type";

interface Props {
  userList: IMultipleUid_AssignUser[];
  customFields: ExistingCustomField[];
  handleResponses: (args: {
    responses: CustomFieldResponse;
    craftRecordOrTask: ExistingCustomField["craftRecordOrTask"];
  }) => void;
  selectedWorkType: string | null;
  selectedTaskType: string | null;
  scheduleDateTime: Date;
  scheduledByPriority: ScheduleByPriorityType | null;
  hideWorkDetailsSection: boolean;
  templateNotes: string | null;
}

export function CustomFieldSection(props: Props): JSX.Element {
  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  const { workRecordCFs, taskCFs } = getCustomFieldsToDisplay({
    customFields: props.customFields,
    scheduleDate: props.scheduleDateTime,
    scheduledByPriority: props.scheduledByPriority,
  });

  const dvWorkRec: Record<string, Json | Date> = {};
  workRecordCFs.forEach((cf) => {
    const dv = getDefaultValuesForNewResponse({
      type: cf.fieldType,
      defaultValue: cf.defaultValue,
      userDisplayNamesMap,
      selectionOptions:
        cf.fieldType === "selection" ? cf.selectionOptions : null,
    });

    if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
      dvWorkRec[`${cf.id}_hoursValue`] = dv[0];
      dvWorkRec[`${cf.id}_minutesValue`] = dv[1];
    } else if (cf.id === "jobTemplateNotes") {
      dvWorkRec[cf.id] = props.templateNotes ? props.templateNotes : "";
    } else {
      dvWorkRec[cf.id] = dv;
    }
  });

  const dvTask: Record<string, Json | Date> = useMemo(() => ({}), []);
  taskCFs.forEach((cf) => {
    const dv = getDefaultValuesForNewResponse({
      type: cf.fieldType,
      defaultValue: cf.defaultValue,
      userDisplayNamesMap,
      selectionOptions:
        cf.fieldType === "selection" ? cf.selectionOptions : null,
    });

    if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
      dvTask[`${cf.id}_hoursValue`] = dv[0];
      dvTask[`${cf.id}_minutesValue`] = dv[1];
    } else {
      dvTask[cf.id] = dv;
    }
  });

  const methodsFormCD = useForm({
    shouldFocusError: false,
    defaultValues: dvWorkRec as Record<string, any>,
  });
  const methodsFormTSD = useForm({
    shouldFocusError: false,
    defaultValues: dvTask as Record<string, any>,
  });

  useEffect(() => {
    methodsFormTSD.reset(dvTask);
  }, [
    props.scheduledByPriority,
    props.selectedTaskType,
    props.selectedWorkType,
    methodsFormTSD,
    dvTask,
  ]);

  // #region -- early returns
  if (!props.selectedWorkType) {
    return (
      <div className="text-gray-700">
        {strings.selectBlankFirst("work type")}
      </div>
    );
  }
  if (!props.selectedTaskType) {
    return (
      <div className="text-gray-700">
        {strings.selectBlankFirst("task type")}
      </div>
    );
  }
  if (!props.selectedWorkType && !props.selectedTaskType) {
    return (
      <div className="text-gray-700">
        {strings.selectBlankFirst("work type and a task type")}
      </div>
    );
  }

  if (
    props.customFields.length === 0 ||
    (workRecordCFs.length === 0 && taskCFs.length === 0)
  ) {
    return (
      <h3 className="text-xl text-primary">
        {strings.NO_ADDITIONAL_FIELDS_TO_FILL_OUT}
      </h3>
    );
  }
  // #endregion

  const onSubmitCD: SubmitHandler<FieldValues> = (values) => {
    props.handleResponses({
      responses: values,
      craftRecordOrTask: "craftRecord",
    });
  };

  const onSubmitTSD: SubmitHandler<FieldValues> = (values) => {
    props.handleResponses({
      responses: values,
      craftRecordOrTask: "task",
    });
  };

  return (
    <div className="mt-9 flex flex-wrap justify-between gap-12 lg:gap-20">
      {!props.hideWorkDetailsSection && (
        <form
          onBlur={methodsFormCD.handleSubmit(onSubmitCD)}
          className="contents"
        >
          <div className="w-full md:flex-1">
            <h3 className="mb-10 text-2xl text-primary">
              {strings.WORK_RECORD}
            </h3>
            {workRecordCFs.length > 0 ? (
              <div className="space-y-6">
                {workRecordCFs.map((customField) => (
                  <span key={customField.id} className="block">
                    <Input
                      userList={props.userList}
                      customField={customField}
                      control={methodsFormCD.control}
                      defaultValue={dvWorkRec[customField.id]}
                      isRequired={customField.required}
                      handleSubmitMultipleUID={(data) => {
                        onSubmitCD(data);
                      }}
                    />
                  </span>
                ))}
              </div>
            ) : (
              <span className="text-gray-700">{strings.NONE_TO_DISPLAY}</span>
            )}
          </div>
        </form>
      )}

      {/* VERTICAL BAR (when the forms aren't flex-wrapping)
        - not applicable if we're working with an existing work record */}
      {!props.hideWorkDetailsSection && (
        <span className="block w-0.5 bg-gray-200"></span>
      )}

      <form
        onBlur={methodsFormTSD.handleSubmit(onSubmitTSD)}
        className="contents"
      >
        <div
          className={`w-full md:flex-1 ${
            props.hideWorkDetailsSection ? "max-w-md" : ""
          }`}
        >
          <h3 className="mb-10 text-2xl text-primary">{`${props.selectedTaskType} Task`}</h3>
          {taskCFs.length > 0 ? (
            <div className="space-y-12">
              {taskCFs.map((customField) => (
                <span key={customField.id} className="block">
                  <Input
                    userList={props.userList}
                    customField={customField}
                    control={methodsFormTSD.control}
                    defaultValue={dvTask[customField.id]}
                    isRequired={customField.required}
                    handleSubmitMultipleUID={(data) => {
                      onSubmitTSD(data);
                    }}
                  />
                </span>
              ))}
            </div>
          ) : (
            <span className="text-gray-700">{strings.NONE_TO_DISPLAY}</span>
          )}
        </div>
      </form>
    </div>
  );
}
