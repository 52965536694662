export const responseTypes = [
  "string",
  "selection",
  "integer",
  "float",
] as const;
export type ResponseTypes = (typeof responseTypes)[number];

export const responsePassingTypes = ["yes", "no", "error"] as const;
export type ResponsePassingTypes = (typeof responsePassingTypes)[number];

export function isResponseType(value: unknown): value is ResponseTypes {
  return responseTypes.includes(value as any);
}
