import { ExistingCustomer } from "../models/customer";
import currencyFormatter from "../currency";
import { ExistingSiteKey } from "../models/site-key";

export function getCustomerBalanceString(
  customerDoc: ExistingCustomer,
  siteKeyDoc: ExistingSiteKey | null,
) {
  const currency = siteKeyDoc?.customizations.accounting?.currency ?? "USD";
  if (!customerDoc?.balance || customerDoc?.balance === 0) {
    return (
      "Balance Due: " + currencyFormatter(customerDoc.balance ?? 0, currency)
    );
  }
  if (customerDoc.balance > 0) {
    return "Balance Due: " + currencyFormatter(customerDoc.balance, currency);
  }
  if (customerDoc.balance < 0) {
    return (
      "Credit: " + currencyFormatter(Math.abs(customerDoc.balance), currency)
    );
  }
}

export function getCustomerBalanceTagColor(customerDoc: ExistingCustomer) {
  if (!customerDoc?.balance || customerDoc?.balance === 0) {
    return "bg-primary";
  }
  if (customerDoc.balance > 0) {
    return "bg-red-500";
  }
  if (customerDoc.balance < 0) {
    return "bg-green-600";
  }
  return "bg-primary";
}

export function getCustomerBalanceTextColor(customerDoc: ExistingCustomer) {
  if (!customerDoc?.balance || customerDoc?.balance === 0) {
    return "text-primaryButtonText";
  }
  if (customerDoc.balance > 0) {
    return "text-white";
  }
  if (customerDoc.balance < 0) {
    return "text-white";
  }
  return "text-primaryButtonText";
}
