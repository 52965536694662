// Libs
import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { Fragment, forwardRef, useCallback, useRef } from "react";

// Local
import BaseInputCheckbox from "../BaseInputCheckbox";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { DocType } from "../../models/quickbook";

const QuickbookDocTypeCustomFilter = forwardRef(
  (
    {
      model,
      onModelChange,
      getValue,
    }: CustomFilterProps<any, any, { value: string[] }>,
    ref,
  ) => {
    const refInput = useRef<HTMLInputElement>(null);

    const doesFilterPass = useCallback(
      ({ node }: IDoesFilterPassParams) => {
        const nodeValue = getValue(node);
        if (model) {
          return model.value.includes(nodeValue);
        } else return false;
      },
      [model],
    );

    const afterGuiAttached = useCallback(() => {
      window.setTimeout(() => {
        refInput.current?.focus();
      });
    }, []);

    useGridFilter({
      doesFilterPass,
      afterGuiAttached,
    });

    const onChange = (value: string[] | null) => {
      if (value == null) {
        onModelChange(null);
      } else {
        onModelChange({ value });
      }
    };

    return (
      <Fragment>
        <form className="flex h-full max-h-96 w-fit flex-col gap-2 rounded-md p-3">
          {Object.values(DocType).map((documentType) => {
            return (
              <BaseInputCheckbox
                key={documentType}
                label={documentType}
                checked={
                  model != null ? model.value.includes(documentType) : false
                }
                onChange={(event) => {
                  const value = event.target.checked;
                  if (value === true) {
                    const modelCopy = model === null ? [] : [...model!.value];
                    onChange([...modelCopy, documentType]);
                  } else {
                    const modelCopy = [...model!.value];
                    const documentTypeIndex = modelCopy.findIndex(
                      (value) => documentType === value,
                    );
                    modelCopy.splice(documentTypeIndex, 1);
                    if (modelCopy.length === 0) {
                      onChange(null);
                    } else {
                      onChange(modelCopy);
                    }
                  }
                }}
                ref={refInput}
                className="form-control"
              />
            );
          })}
          <BaseButtonSecondary
            type="button"
            className="form-control"
            onClick={() => onChange(null)}
          >
            Reset
          </BaseButtonSecondary>
        </form>
      </Fragment>
    );
  },
);

export default QuickbookDocTypeCustomFilter;
