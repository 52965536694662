// Libs
import { DateTime } from "luxon";

import { Schedule } from "../rschedule";

/**
 * Returns the next DateTime (UTC) from the provided Schedule.
 */
export function getNextOccurrence(
  schedule: Schedule.JSON,
): DateTime | undefined {
  const output: DateTime[] = [];

  const rrule = Schedule.fromJSON(schedule);
  const now = DateTime.now();

  const nextOccurrences = rrule.occurrences({ take: 10, start: now }).toArray();

  nextOccurrences.forEach((adapter) => {
    // Returns a rSchedule DateTime instance which isn't the same.
    // const notLuxDT = adapter.toDateTime()

    // We can get Luxon DateTime instance
    const luxDT = adapter.date;
    output.push(luxDT.toUTC());
  });

  return output[0];
}
