// Libs
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Local
import { DbRead } from "../database";
import { ExistingTask } from "../models/task";
import { logger as devLogger } from "../logging";
import LoadingClipboardAnimation from "../components/LoadingClipBoardAnimation";
import { ExistingCraftRecord } from "../models/craft-record";
import TasksForTemplatePage from "./TasksForTemplatePage";
import { TaskStatus } from "../models/task-status";
import { useUserPermissionsStore } from "../store/user-permissions";
import { TASK_DETAILS_URL } from "../urls";

interface Props {
  siteKey: string;
}

export default function TasksForTemplateContainer({ siteKey }: Props) {
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [templateTitle, setTemplateTitle] = useState("");
  const [listOfTasks, setListOfTasks] = useState<ExistingTask[]>([]);
  const [taskStatusSelection, setTaskStatusSelection] = useState<number | null>(
    null,
  );

  const filteredTasksList = filterTasksByDropdownSelection(
    taskStatusSelection,
    listOfTasks,
  );

  type UrlParams = { id: string };
  const { id: checklistID } = useParams<UrlParams>();
  if (typeof checklistID !== "string") {
    throw new Error(`checklistID was not a string: ${checklistID}`);
  }

  const navigate = useNavigate();

  if (checklistID == null) {
    navigate("/404");
  }

  function handleGoToTaskDetails(taskID: string): void {
    navigate(`${TASK_DETAILS_URL}/${taskID}`);
  }

  // todo: handle a not found situation
  const checklistPath = `siteKeys/${siteKey}/parentRecords/${checklistID}`;

  // Handle fetching a list of example checklist items on the database when this
  // component loads.
  useEffect(() => {
    async function getTasksFromASingleChecklistAndItsTitle() {
      if (typeof checklistID !== "string") {
        throw new Error(`checklistID was not a string: ${checklistID}`);
      }

      if (userPermissions == null) {
        devLogger.debug(
          `${getTasksFromASingleChecklistAndItsTitle.name}: No user permissions data yet`,
        );
        return;
      }
      // Check that user hasn't navigated away from this page. If they haven't, then set a boolean value for loading.
      if (isMounted.current) setIsLoading(true);

      // Query the tasks of a single checklist via realtime updates. Set the list of tasks.
      const unsubscribe = DbRead.tasks.subscribeSingleChecklistTasks({
        siteKey: siteKey,
        checklistPath: checklistPath,
        permissions: userPermissions,
        onChange: setListOfTasks,
      });

      // Get the craftRecord document.
      // TODO: we should handle the permission denied case with a better error message to the user.
      const craftRecord: ExistingCraftRecord = await DbRead.checklist.get(
        siteKey,
        checklistID,
      );

      // Check that user is still on the same page. If yes, set the checklist title, and stop the loading process.
      if (isMounted.current) {
        setTemplateTitle(craftRecord.title);
        setIsLoading(false);
      }

      // Need to return the unsubscribe ƒn. It contains the removeListeners ƒn.
      return unsubscribe;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromises variable.
    const unsubscribePromises = getTasksFromASingleChecklistAndItsTitle();

    // We need to return a function for cleanup. (In this case, it's an anonymous ƒn.)
    return () => {
      devLogger.debug(
        "TasksForTemplateContainer's useEffect return ƒn just invoked.",
      );
      isMounted.current = false;

      // Because we're dealing with an async ƒn, we need to unpack its' return value (also a ƒn) before we invoke it.
      unsubscribePromises.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [siteKey, checklistID, checklistPath, userPermissions]);

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  } else {
    return (
      <TasksForTemplatePage
        checklistTitle={templateTitle}
        onGoToTaskDetails={handleGoToTaskDetails}
        checklistID={checklistID}
        onSelectionStatus={(taskStatus) => setTaskStatusSelection(taskStatus)}
        listOfTasks={filteredTasksList}
      />
    );
  }
}

function filterTasksByDropdownSelection(
  taskStatus: number | null,
  listOfTasks: ExistingTask[],
): ExistingTask[] {
  let filteredList: ExistingTask[] = [];
  switch (taskStatus) {
    case TaskStatus.COMPLETE:
      filteredList = listOfTasks.filter((task) => {
        const filtering = task.taskStatus === taskStatus;
        return filtering;
      });
      return filteredList;
    case TaskStatus.AWAITING:
      filteredList = listOfTasks.filter((task) => {
        const filtering = task.taskStatus === taskStatus;
        return filtering;
      });
      return filteredList;
    case TaskStatus.AWAITING_APPROVAL:
      filteredList = listOfTasks.filter((task) => {
        const filtering = task.taskStatus === taskStatus;
        return filtering;
      });
      return filteredList;
    case TaskStatus.AWAITING_SCHEDULE:
      filteredList = listOfTasks.filter((task) => {
        const filtering = task.taskStatus === taskStatus;
        return filtering;
      });
      return filteredList;
    case TaskStatus.IN_PROGRESS:
      filteredList = listOfTasks.filter((task) => {
        const filtering = task.taskStatus === taskStatus;
        return filtering;
      });
      return filteredList;
    case TaskStatus.ON_HOLD:
      filteredList = listOfTasks.filter((task) => {
        const filtering = task.taskStatus === taskStatus;
        return filtering;
      });
      return filteredList;
    case TaskStatus.SCHEDULED:
      filteredList = listOfTasks.filter((task) => {
        const filtering = task.taskStatus === taskStatus;
        return filtering;
      });
      return filteredList;
    default:
      filteredList = listOfTasks.filter((task) => {
        const filtering = task.taskStatus !== 90;
        return filtering;
      });
      return filteredList;
  }
}
