import InvoiceHeader from "../../components/Payments/InvoiceHeader";
import { StiltPaymentFormData } from "../../models/stilt-payment";

interface Props {
  payment: StiltPaymentFormData;

  // COMPONENTS
  children: {
    iFrame: React.ReactNode;
    invoiceSummary: React.ReactNode;
    payInFullButton: React.ReactNode;
    payPartialButton: React.ReactNode;
    // jobPhotos: React.ReactNode;
    emailComponent: React.ReactNode;
  };
}

export default function PaymentsPage(props: Props): JSX.Element {
  return (
    <div className="justify-center bg-paymentsBackground">
      <div className="mx-4 my-10 rounded-2xl bg-white px-4 py-10 shadow-lg sm:mx-8 sm:px-6 md:mx-10 md:px-10">
        <InvoiceHeader
          amountDue={props.payment.amountDue}
          issueDate={props.payment.issueDate}
          poNumber={props.payment.poNumber}
          jobNumber={props.payment.jobNumber}
          invoiceNumber={props.payment.invoiceNumber}
          dueDate={props.payment.dueDate}
          merchantLogoURL={props.payment.merchantLogoURL}
          timestampTaskCompleted={null}
          billingInfo={props.payment.billingInfo}
          serviceInfo={props.payment.serviceAddress}
          invoicePaymentTerms={null}
          invoiceStatus={null}
          craftRecordID={null}
          customerID={null}
          estimateID={null}
          customerLocationID={null}
          invoiceID={null}
        />
        {props.children.invoiceSummary}
        <div className="pb-8 md:flex md:flex-col md:items-end xl:mx-auto xl:max-w-5xl">
          {props.children.payInFullButton}
          {props.children.payPartialButton}
        </div>
        {/*{props.children.jobPhotos}*/}
        {props.children.emailComponent}
      </div>
      {props.children.iFrame}
    </div>
  );
}
