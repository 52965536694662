// Local
import { ExistingCustomField } from "../../../models/custom-field";
import { TaskStatusValues } from "../../../models/task-status";

/**
 * siteKeyCustomFields is expected to include only TASK custom fields.
 *
 * Returns task custom fields that are editable and whose onTaskStatus list
 * contains nextTaskStatus
 */
export function getEditableTSDForNextTaskStatus(args: {
  nextTaskStatus: TaskStatusValues | null;
  siteKeyCustomFields: ExistingCustomField[];
}): ExistingCustomField[] {
  const nextTaskStatus = args.nextTaskStatus;
  if (!nextTaskStatus) return [];

  const editableAndShouldDisplay = args.siteKeyCustomFields.reduce(
    (acc, curr) => {
      if (curr.editable) {
        if (curr.onTaskStatus?.includes(nextTaskStatus)) {
          acc.push(curr);
          return acc;
        }
      }
      return acc;
    },
    [] as ExistingCustomField[],
  );
  return editableAndShouldDisplay;
}
