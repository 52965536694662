//Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { useEffect, useMemo, useState } from "react";

//Local
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import { BillingInfo } from "../../models/customer";
import { ErrorMessage } from "../ErrorMessage";
import { logger as devLogger } from "../../logging";
import { splitAddressComponents } from "../../assets/js/splitAddressComponents";

const BillingAddressWithAutocompleteFormSchema = z.object({
  addressLine2: z.string().max(200).optional(),
  name: z.string().max(1000).optional(),
  email: z.string().max(1000).optional(),
  phone: z.string().max(1000).optional(),
});

export type BillingAddressWithAutocompleteFormState = z.infer<
  typeof BillingAddressWithAutocompleteFormSchema
>;

interface Props {
  /* DATA */
  billingInfo: BillingInfo;
  isSubmitting: boolean;
  geocoderResult?: google.maps.GeocoderResult;
  /* FUNCTIONS */
  handleUpdateBillingInfo: (billingInfo: BillingInfo) => void;
  setEditBillingInfoFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /* CHILDREN */
  children: {
    AddressField: React.ReactNode;
  };
}

export default function EditBillingInfoWithAutocompleteForm(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const defaultValues: BillingAddressWithAutocompleteFormState = useMemo(() => {
    return {
      addressLine2: props.billingInfo.addressLine2 ?? "",
      name: props.billingInfo.name ?? "",
      phone: props.billingInfo.phone ?? "",
      email: props.billingInfo.email ?? "",
    };
  }, [props.billingInfo]);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<Pick<BillingInfo, "addressLine2" | "name" | "phone" | "email">>({
    defaultValues: defaultValues,
    resolver: zodResolver(BillingAddressWithAutocompleteFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the section is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    props.setEditBillingInfoFormOpen(false);
  }

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );

  const onSubmit: SubmitHandler<
    Pick<BillingInfo, "addressLine2" | "name" | "phone" | "email">
  > = async (formValues) => {
    let billingInfo: BillingInfo;
    if (props.geocoderResult) {
      const addressLine1 =
        splitAddressComponents(props.geocoderResult).streetNumber +
        " " +
        splitAddressComponents(props.geocoderResult).street;
      /* sending all the values minus the ones that will be the same for every customerLocation object like the customerID */
      billingInfo = {
        addressLine1: addressLine1 !== " " ? addressLine1 : "",
        addressLine2: formValues.addressLine2,
        city: splitAddressComponents(props.geocoderResult).city,
        zipCode: splitAddressComponents(props.geocoderResult).zipCode,
        state: splitAddressComponents(props.geocoderResult).state,
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
      };
    } else {
      /* sending all the values minus the ones that will be the same for every customerLocation object like the customerID */
      billingInfo = {
        addressLine1: props.billingInfo.addressLine1,
        addressLine2: formValues.addressLine2,
        city: props.billingInfo.city,
        zipCode: props.billingInfo.zipCode,
        state: props.billingInfo.state,
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
      };
    }

    try {
      props.handleUpdateBillingInfo(billingInfo);
      // Close the section if successful.
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      className="space-y-8"
    >
      <div>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Name"
              inputName="name"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.name?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.name.message }}
            </StyledMessage>
          </div>
        )}
      </div>
      <div>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Phone"
              inputName="phone"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.phone?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.phone.message }}
            </StyledMessage>
          </div>
        )}
      </div>
      <div>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Email"
              inputName="email"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.email?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.email.message }}
            </StyledMessage>
          </div>
        )}
      </div>
      {/* Google Places Component for Full Address Field */}
      {props.children.AddressField}

      {/* Field: Address (cont.) */}
      <div>
        <Controller
          name="addressLine2"
          control={control}
          render={({ field }) => (
            <BaseInputText
              text="Address (cont.)"
              inputName="addressLine2"
              admin={true}
              required={false}
              {...field}
              value={field.value === null ? "" : field.value}
            />
          )}
        />
        {errors.addressLine2?.message && (
          <div className="mt-2 text-sm">
            <StyledMessage type="error">
              {{ message: errors.addressLine2.message }}
            </StyledMessage>
          </div>
        )}
      </div>

      <div className="mt-4">{displayError ? errorMessage : null}</div>

      {/* Action Buttons */}
      <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={closeAndReset}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          disabled={props.isSubmitting}
          isBusy={props.isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
          data-testid="save info"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
    </form>
  );
}
