export function showReadableCustomFieldDefaultValue(
  defaultValue: unknown,
): string {
  let readableValue: string = "";
  switch (typeof defaultValue) {
    case "number":
      readableValue = `${defaultValue}`;
      return readableValue;
    case "string":
      if (defaultValue === "") {
        readableValue = "N/A";
      } else {
        readableValue = defaultValue;
      }
      return readableValue;
    case "boolean":
      readableValue = defaultValue === true ? "Yes" : "No";
      return readableValue;
    default:
      if (Array.isArray(defaultValue) && defaultValue.length > 0) {
        readableValue = defaultValue.join(", ");
      } else {
        readableValue = "N/A";
      }
      return readableValue;
  }
}
