// Libs
import { z } from "zod";
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";

// Local
import { dropUndefined, guardIsPlainObject, zFallback } from "../utils";
import { NotFoundError } from "../error-classes";

// #region Types
/** Price Book Item Category */
export interface PriceBookItemCategory {
  /** The category name. */
  name: string;
  active: boolean;
  customData: Record<string, any>;
  account?: string;
  costOfSaleAccount?: string;

  lastModifiedBy: string;
  createdBy: string;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
}

interface FirestoreAttributes {
  id: string;
  refPath: string;
}

/** Existing Pricde Book Item Category */
export type ExistingPriceBookItemCategory = PriceBookItemCategory &
  FirestoreAttributes;

export type PBItemCategory_UpdateAPI = Omit<
  Partial<ExistingPriceBookItemCategory>,
  "timestampCreated" | "timestampLastModified"
>;

type withoutTimestamps = Omit<
  PriceBookItemCategory,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface PBItemCategory_CreateAPI extends withoutTimestamps {
  siteKey: string;
}

export type AddNewPBItemCategory = Omit<
  PriceBookItemCategory,
  "timestampCreated" | "timestampLastModified" | "createdBy" | "lastModifiedBy"
>;

// #endregion Types

// #region Functions
// SECTION:
/** Utilities for interacting with priceBookItemCategory objects. */
export const PriceBookItemCategoryManager = {
  /**
   * Convert document snapshot into a validated ExistingPriceBookItemCategory object.
   * Uses schema with fallbacks.
   * @throws NotFoundError if doc does not exist
   */
  fromSnapshot: createFromFirestoreSnapshot,
  /** Drop id and refPath, drop undefined values. Does not validate data. */
  convertUpdateForFirestore: convertUpdateForFirestore,
  /** Used when writing to the database. @throws if `value` is not an object. */
  parse: validatePriceBookItemCategory,
  parseCreate: validatePBItemCategory_Create,
  parseUpdate: validatePBItemCategory_Update,
  /** Used when reading from the database. @throws if `value` is not an object. */
  fallbacksParse: fallbacksValidatePriceBookItemCategory,
  /** Used when writing to the database. @throws if `value` is not an object. */
  parseExisting: validateExistingPriceBookItemCategory,
};

function fallbacksValidatePriceBookItemCategory(
  value: unknown,
): PriceBookItemCategory {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return fallbacksPriceBookItemCategorySchema.parse(value);
}

function validatePriceBookItemCategory(value: unknown): PriceBookItemCategory {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return priceBookItemCategorySchema.parse(value);
}

function validateExistingPriceBookItemCategory(
  value: unknown,
): ExistingPriceBookItemCategory {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return existingPriceBookItemCategorySchema.parse(value);
}

function validatePBItemCategory_Create(
  value: unknown,
): PBItemCategory_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return pbItemCategorySchema_CreateAPI.parse(value);
}

function validatePBItemCategory_Update(
  value: unknown,
): PBItemCategory_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return pbItemCategorySchema_UpdateAPI.parse(value);
}

function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingPriceBookItemCategory {
  if (!snapshot.exists) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...fallbacksValidatePriceBookItemCategory(snapshot.data()),
  };
}

function convertUpdateForFirestore(
  account: ExistingPriceBookItemCategory,
): DocumentData {
  const local = Object.assign({}, account);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  return dropUndefined(rest);
}
// #endregion Functions

// #region Zod schemas
// SECTION:

/** no timestamps */
const commonSchema = z.object({
  name: z.string().min(1).max(300),
  active: z.boolean(),
  account: z.string().min(1).max(200).optional(),
  costOfSaleAccount: z.string().min(1).max(200).optional(),
  lastModifiedBy: z.string().min(1).max(200),
  createdBy: z.string().min(1).max(200),
  customData: z.record(z.any()),
});

/** used when validating data to write to the database */
const priceBookItemCategorySchema = commonSchema.extend({
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
});

/** used when validating data to write to the database, for existing general ledger account objects */
const existingPriceBookItemCategorySchema = priceBookItemCategorySchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(500),
});

/** no timestamps */
const fallbacksCommonSchema = z.object({
  name: zFallback(
    z.string().min(1).max(300),
    "Unknown",
    "name - price-book-item-category.ts",
  ),
  active: zFallback(z.boolean(), true, "active - price-book-item-category.ts"),
  account: zFallback(
    z.string().min(1).max(200).optional(),
    undefined,
    "account - price-book-item-category.ts",
  ),
  costOfSaleAccount: zFallback(
    z.string().min(1).max(200).optional(),
    undefined,
    "costOfSaleAccount - price-book-item-category.ts",
  ),
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "Unknown",
    "lastModifiedBy - price-book-item-category.ts",
  ),
  createdBy: zFallback(
    z.string().min(1).max(200),
    "Unknown",
    "createdBy - price-book-item-category.ts",
  ),
  customData: zFallback(
    z.record(z.any()),
    {},
    "customData - price-book-item-category.ts",
  ),
});

/** used when validating data coming out of the database */
const fallbacksPriceBookItemCategorySchema = fallbacksCommonSchema.extend({
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "timestampCreated - gl-account.ts",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "timestampLastModified - gl-account.ts",
  ),
});
// #endregion Zod schemas

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = priceBookItemCategorySchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const pbItemCategorySchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const pbItemCategorySchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();
