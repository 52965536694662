//Libs
import React, { useRef, useLayoutEffect, useCallback, useState } from "react";
import { ICellRendererParams, ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

//Local
import { ExistingSiteKeyLocation } from "../models/site-key-location";
import SearchBox from "./SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface SiteKeyLocationListTableProps {
  siteKeyLocationList: ExistingSiteKeyLocation[];
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
}

const SiteKeyLocationListTable = React.memo(
  ({
    siteKeyLocationList,
    renderIconCell,
    ...props
  }: SiteKeyLocationListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const headerStyles =
      "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

    const commonColProps = {
      width: 150,
      flex: 1,
      resizable: true,
      filter: true,
      sortable: true,
      headerClass: headerStyles,
    };

    const siteKeyLocationColumnsDefs: ColDef[] = [
      {
        field: "title",
        headerName: "Title",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "department",
        headerName: "Department",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        suppressAutoSize: true,
        suppressSizeToFit: true,
        sortable: false,
        flex: 0,
      },
    ];

    return (
      <div className="ag-theme-alpine flex h-[300px] flex-col">
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox onInput={onFilterTextBoxChanged} />
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={siteKeyLocationList}
          animateRows={true}
          rowSelection="single"
          columnDefs={siteKeyLocationColumnsDefs}
        />
      </div>
    );
  },
);

export default SiteKeyLocationListTable;
