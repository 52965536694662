//Libs
import { TrashIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

//Local
import { ExistingComplianceRequirement } from "../../models/compliance-requirement";
import * as strings from "../../strings";
import LoadingSpinner from "../LoadingSpinner";
import { StyledTooltip } from "../StyledTooltip";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ComplianceSummary } from "../../models/compliance-types-and-statuses";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import { logger } from "../../logging";

interface Props {
  complianceSummaryList: ComplianceSummary[];
  showDeleteRequirementButton: boolean;
  handleDeleteRequirement: (
    id: ExistingComplianceRequirement["id"],
  ) => Promise<void>;
}

export default function ComplianceRequirementsAccordionList({
  complianceSummaryList,
  handleDeleteRequirement,
  showDeleteRequirementButton,
}: Props) {
  const addToastError = useToastMessageStore((state) => state.addToastMessage);

  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  return (
    <div className="max-w-2xl space-y-4 rounded-md sm:w-full sm:max-w-full">
      {complianceSummaryList.map((summary) => {
        return (
          <div key={summary.id}>
            <Accordion>
              <AccordionSummary>
                <div className="flex w-full items-center">
                  <div className="flex w-full flex-col text-xl capitalize text-primary lg:text-2xl">
                    {summary.title}
                    <span className="text-base text-gray-400 lg:text-lg">
                      {summary.description}
                    </span>
                  </div>
                  <span className="flex flex-wrap items-center justify-end text-2xl font-bold md:text-3xl">
                    {isNaN(summary.totalApproved)
                      ? 0
                      : summary.totalApproved === Number.POSITIVE_INFINITY
                        ? 100
                        : summary.totalApproved}
                    %
                    {showDeleteRequirementButton ? (
                      <button
                        className={`ml-4 rounded-full ${
                          isDeleting
                            ? "cursor-auto hover:bg-transparent"
                            : "hover:bg-primaryOpacity90"
                        }`}
                        disabled={isDeleting != null}
                        onClick={async () => {
                          setIsDeleting(summary.id);
                          try {
                            await handleDeleteRequirement(summary.id);
                          } catch (e) {
                            logger.error(
                              "error deleting compliance requirement",
                              `(id: ${summary.id})`,
                              e,
                            );
                            addToastError({
                              id: createToastMessageID(),
                              message:
                                strings.ERR_DELETE_COMPLIANCE_REQUIREMENT,
                              dialog: false,
                              type: "error",
                            });
                          } finally {
                            setIsDeleting(null);
                          }
                        }}
                      >
                        {summary.id === isDeleting ? (
                          <span className="block w-9 p-2 sm:w-10">
                            <LoadingSpinner marginClass="mx-2" />
                          </span>
                        ) : (
                          <StyledTooltip title={strings.DELETE_REQUIREMENT}>
                            <TrashIcon
                              aria-label="delete requirement"
                              className="w-9 flex-shrink-0 fill-current p-2 text-gray-700 sm:w-10"
                            />
                          </StyledTooltip>
                        )}
                      </button>
                    ) : null}
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails className="flex flex-col text-gray-700">
                <div className="lg:text-lg">
                  {summary.customFields.map((item) =>
                    Object.entries(item).map(([customFieldKey, value]) => {
                      return (
                        <div key={customFieldKey} className="mb-2">
                          <div className="flex items-start font-semibold">
                            {value.text}
                          </div>
                        </div>
                      );
                    }),
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
}
