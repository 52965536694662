//Libs
import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

//Local
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { TemporaryEstimateItem } from "../../models/estimate-item";
import { useToastMessageStore } from "../../store/toast-messages";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import currencyFormatter from "../../currency";
import { StiltLineItemFormData } from "../../models/stilt-payment";
import { getEstimateLineItemTotal } from "../../assets/js/estimateFunctions";
import { ExistingCustomer } from "../../models/customer";

interface Props {
  //DATA
  PBItemQueryResultList: ExistingPriceBookItem[];
  currency: string;
  customer: ExistingCustomer;
  globalDiscount: number;
  totalTaxRate: number;
  //FUNCTIONS
  onSearch: (searchTerm: string) => Promise<void>;
  handleAddItem: (newItem: StiltLineItemFormData) => void;
}

export default function AddNewInvoiceItemSelection({
  onSearch,
  PBItemQueryResultList,
  currency,
  handleAddItem,
  ...props
}: Props) {
  const [selectedPBItem, setSelectedPBItem] =
    useState<ExistingPriceBookItem | null>(null);

  const [displayError, setDisplayError] = useState<boolean>(false);
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const errorMessage = (
    <ErrorMessage
      message={strings.ERROR_SELECTING_PRICEBOOK_ITEM}
      clearMessage={() => setDisplayError(false)}
    />
  );

  function selectPBItem(selectedItem: ExistingPriceBookItem) {
    setSelectedPBItem(selectedItem);
    addNewItem(selectedItem);
  }

  function addNewItem(selectedItem: ExistingPriceBookItem) {
    // Temporarily create estimateItem to get the totals
    const newEstimateItem: TemporaryEstimateItem = {
      priceBookItemID: selectedItem.id,
      title: selectedItem.title,
      description: selectedItem.description,
      units: selectedItem.units,
      unitPrice: selectedItem.unitPrice,
      cost: selectedItem.cost,
      locationID: "",
      discountableForMemberships: selectedItem.discountableForMemberships,
      type: selectedItem.type ?? null,
      tags: selectedItem.tags,
      customData: selectedItem.customData,
      taxable: selectedItem.taxable,
      editable: selectedItem.editable,
      discountable: selectedItem.discountable,
      quantity: 1,
      discount: 0,
      deleted: false,
      toBeEdited: selectedItem.editable,
    };
    const {
      taxAmountItem,
      subtotalItemWithLineDiscount,
      subtotalItemWithLineAndGlobalDiscount,
    } = getEstimateLineItemTotal(
      newEstimateItem,
      currency,
      props.customer,
      props.globalDiscount,
      props.totalTaxRate,
    );
    const newInvoiceLineItem: StiltLineItemFormData = {
      subTotal: subtotalItemWithLineDiscount,
      taxAmount: taxAmountItem,
      priceBookItemID: selectedItem.id,
      title: selectedItem.title,
      description: selectedItem.description ?? "",
      unitPrice: selectedItem.unitPrice,
      discountable: selectedItem.discountable,
      quantity: 1,
      discount: 0,
      toBeEdited: selectedItem.editable,
      estimateItemID: null,
    };

    try {
      handleAddItem(newInvoiceLineItem);
      onSearch("");
      setSelectedPBItem(null);
    } catch (error) {
      logger.error(`An error occurred during addNewItem`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <Combobox value={selectedPBItem} onChange={selectPBItem}>
        <div className="relative mt-1">
          <div className="relative flex w-full cursor-default rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-300 sm:text-sm">
            <Combobox.Button className="w-full" as="div">
              <Combobox.Input
                className={`block w-full min-w-0 flex-1 rounded border border-black p-4 text-gray-700 focus:border-primaryLight focus:outline-none  focus:ring focus:ring-primaryLight sm:text-sm`}
                id="search-price-book-item"
                placeholder="Start typing to add items..."
                onChange={(event) => onSearch(event.target.value)}
                autoComplete="off"
                displayValue={(item: ExistingPriceBookItem) =>
                  item && item.title
                }
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => onSearch("")}
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {PBItemQueryResultList.length === 0 ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                PBItemQueryResultList.map((pbItem) => (
                  <Combobox.Option
                    key={pbItem.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-gray-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={pbItem}
                  >
                    {({ selected, active }) => (
                      <>
                        <div
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex flex-col">
                              <span className="block whitespace-normal text-base font-medium leading-5">
                                {pbItem.title}
                              </span>
                              <span className="block whitespace-normal text-sm leading-5 text-gray-400">
                                {pbItem.description}
                              </span>
                            </div>
                            <div>{` ${currencyFormatter(
                              pbItem.unitPrice,
                              currency,
                            )}/${pbItem.units}`}</div>
                          </div>
                        </div>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {displayError ? (
        <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
}
