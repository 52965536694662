//Libs
import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";

//Local
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { PencilIconWithRef } from "../../components/PencilEditButton";
import { StyledTooltip } from "../../components/StyledTooltip";
import SearchBox from "../../components/SearchBox";
import { phoneUtils } from "../../utils/phoneUtils";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface Props {
  //DATA
  locations: ExistingSiteKeyLocation[] | null;
  invoicesEnabled: boolean;
  //FUNCTIONS
  onEditLocation: (location: ExistingSiteKeyLocation) => void;
  addNewLocationButton: React.ReactNode;
  //CHILDREN
  children: {
    Breadcrumbs: React.ReactNode;
  };
}

export default function LocationListPage({
  locations,
  invoicesEnabled,
  onEditLocation,
  addNewLocationButton,
  ...props
}: Props) {
  return (
    <div>
      {props.children.Breadcrumbs}
      <div className={"ag-theme-alpine my-4 flex h-[600px] flex-col"}>
        <LocationListTable
          locations={locations}
          onEditLocation={onEditLocation}
          invoicesEnabled={invoicesEnabled}
          addNewLocationButton={addNewLocationButton}
        />
      </div>
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  width: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface LocationListTableProps {
  locations: Props["locations"];
  invoicesEnabled: Props["invoicesEnabled"];
  onEditLocation: Props["onEditLocation"];
  addNewLocationButton: Props["addNewLocationButton"];
}

/* TABLE COMPONENT */
const LocationListTable = React.memo(
  ({
    locations,
    onEditLocation,
    invoicesEnabled,
    ...props
  }: LocationListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const renderIconCell = useCallback(
      function (params: ICellRendererParams) {
        return (
          <div className="flex items-center">
            <StyledTooltip title="Edit">
              <PencilIconWithRef onClick={() => onEditLocation(params.data)} />
            </StyledTooltip>
          </div>
        );
      },
      [onEditLocation],
    );

    const renderPhone = (params: ICellRendererParams): JSX.Element => {
      const phone = params.data.phone ?? "--";
      return <div>{phoneUtils.display(phone)}</div>;
    };

    const columnDefs: ColDef[] = invoicesEnabled
      ? [
          {
            field: "title",
            headerName: "Location Name",
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            field: "department",
            headerName: "Department",
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            field: "invoiceTitle",
            headerName: "Invoice Title",
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            field: "invoiceHeader",
            headerName: "Invoice Header",
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            field: "invoiceMessage",
            headerName: "Invoice Message",
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            field: "email",
            headerName: "Email",
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            field: "phone",
            headerName: "Phone",
            cellRenderer: renderPhone,
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            cellRenderer: renderIconCell,
            suppressMovable: true,
            suppressHeaderMenuButton: true,
            suppressAutoSize: true,
            suppressSizeToFit: true,
            sortable: false,
            flex: 0,
          },
        ]
      : [
          {
            field: "title",
            headerName: "Location Name",
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            field: "department",
            headerName: "Department",
            tooltipValueGetter: (params) =>
              params.valueFormatted ?? params.value,
          },
          {
            cellRenderer: renderIconCell,
            suppressMovable: true,
            suppressHeaderMenuButton: true,
            suppressAutoSize: true,
            suppressSizeToFit: true,
            sortable: false,
            flex: 0,
          },
        ];

    return (
      <Fragment>
        <div className="flex flex-col justify-between gap-6 sm:flex-row sm:items-end">
          <SearchBox onInput={onFilterTextBoxChanged} />
          {props.addNewLocationButton}
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={locations}
          animateRows={true}
          rowSelection="single"
          rowHeight={50}
          cacheQuickFilter={true}
          columnDefs={columnDefs}
        />
      </Fragment>
    );
  },
  (previous, next) => {
    const isLocationsTheSame = isEqual(previous.locations, next.locations);

    return isLocationsTheSame;
  },
);
