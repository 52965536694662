// Libs
import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  ValueFormatterFunc,
  ValueGetterFunc,
} from "ag-grid-community";
import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";

// Local
import { ExistingCraftRecord } from "../../models/craft-record";
import { ExistingChecklistItem } from "../../models/checklist-item";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "../../assets/css/ag-grid-custom.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

// #region SECTION: Cell display related
const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

/**
 * These properties are added to every column. They can be overridden on a per
 * column basis.
 */
const commonColProps = {
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  tooltipValueGetter: (params: ITooltipParams) =>
    params.valueFormatted ?? params.value,
};

const calcItemCount: ValueGetterFunc = (params): number | null => {
  const itemsList: ExistingChecklistItem[] =
    params.data.craftDetails?.checklistItems;
  if (Array.isArray(itemsList)) {
    return itemsList.length;
  } else {
    return null;
  }
};

const getDateValue: ValueGetterFunc = (params) => {
  const timestampCreated = params.data.timestampRecordCreated;
  if (timestampCreated instanceof Timestamp) {
    return DateTime.fromSeconds(timestampCreated.seconds).toJSDate();
  } else {
    return null;
  }
};
const getDateDisplay: ValueFormatterFunc = (params): string => {
  const timestampCreated = params.data.timestampRecordCreated;
  return convertToReadableTimestampDate(timestampCreated);
};
// #endregion

interface Props {
  checklistList: ExistingCraftRecord[];
  extraButtons?: Array<() => JSX.Element>;
  handleViewItems: (checklistID: string) => void;
}

export default function ChecklistTemplatesTable({
  checklistList,
  extraButtons,
  handleViewItems,
}: Props): JSX.Element {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  const TitleCellRenderer = (params: ICellRendererParams): JSX.Element => {
    return (
      <button
        className="font-semibold text-primary hover:underline"
        onClick={() => {
          if (typeof params.data.id === "string")
            handleViewItems(params.data.id);
        }}
      >
        {params.value}
      </button>
    );
  };

  const columnDefs: ColDef[] = [
    {
      field: "title",
      headerName: "Template Name",
      cellRenderer: TitleCellRenderer,
    },
    {
      field: "description",
    },
    {
      headerName: "Number of items",
      valueGetter: calcItemCount,
      type: "numericColumn",
      headerClass: headerStyles,
      filter: "number",
      maxWidth: 200,
      tooltipValueGetter: (params: ITooltipParams) => {
        const value = params.value;
        if (value == null) return "Server was unable to count items";
        return value;
      },
    },
    {
      headerName: "Date Created",
      valueFormatter: getDateDisplay,
      valueGetter: getDateValue,
      filter: "agDateColumnFilter",
      maxWidth: 200,
    },
  ];

  // #region SECTION: Table
  return (
    <Fragment>
      {/* <div className="flex flex-col items-end justify-center sm:mt-6 sm:flex-row sm:items-center sm:justify-end"> 
       <div className="mr-1 sm:ml-3">
          <BaseButtonSecondary
            onClick={() => {
              if (gridRef.current) {
                gridRef.current.api?.sizeColumnsToFit();
              }
            }}
          >
            <AdjustmentsIcon className="mr-4 h-8" />
            Auto-fit Columns
          </BaseButtonSecondary>
        </div> 
        </div> */}

      {/* TABLE TITLE */}
      <div className="flex flex-col justify-center py-4 sm:mt-6 sm:flex-row sm:items-end sm:justify-between sm:py-0">
        <div className="flex items-center text-xl font-semibold text-gray-700">
          Table of Templates
          {/* <HelpButton /> */}
        </div>
        <div className="mt-4 sm:mt-0">
          {extraButtons?.map((ExtraButton, index) => (
            <ExtraButton key={index} />
          ))}
        </div>
      </div>
      {/* DATA TABLE */}
      <div className="ag-theme-alpine flex h-full min-h-[600px] flex-col">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={checklistList}
          animateRows={true}
          rowSelection="single"
          columnDefs={columnDefs}
        />
      </div>
    </Fragment>
  );
  // #endregion
}
