// Libs
import React, { Fragment } from "react";

// Local
import LoadingSpinner from "./LoadingSpinner";

type Ref = HTMLButtonElement;
type Props = {
  className?: string;
  isBusy?: boolean;
  busyText?: string;
  fontSize?: string;
  height?: string;
} & React.ComponentPropsWithRef<"button">;

const BaseButtonRounded = React.forwardRef<Ref, Props>(
  (
    { isBusy = false, busyText = "Processing...", className, ...props },
    ref,
  ): JSX.Element => {
    const LoadingElement: React.ReactNode = (
      <Fragment>
        <LoadingSpinner />
        {busyText}
      </Fragment>
    );
    return (
      <button
        {...props}
        ref={ref}
        disabled={isBusy || props.disabled}
        className={`${className ?? ""} ${
          props.disabled && !isBusy
            ? "bg-gray-100 text-gray-500"
            : "bg-primary text-primaryButtonText hover:bg-primaryDark hover:text-secondaryButtonText"
        } ${props.fontSize ? props.fontSize : "text-sm"} 
  ${props.height ? props.height : "h-10"} 
        inline-flex items-center justify-center rounded-3xl px-4 py-2 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2`}
      >
        {isBusy ? LoadingElement : props.children}
      </button>
    );
  },
);

export default BaseButtonRounded;
