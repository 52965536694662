/**
 * @returns `amount`, properly rounded to 2 decimal places (when appropriate).
 * (given 859.995, will return 860, not 860.00)
 */
// Tested ✅
export function getRoundedCurrency(amount: number): number {
  const result = Number(
    (+(Math.round(+(amount + "e" + 2)) + "e" + -2)).toFixed(2),
  );

  return isNaN(result) ? 0 : result;
}

/*
  apparently, rounding is tricky in JS... check out the answers --
  https://stackoverflow.com/questions/10015027
  */

/* 
  i asked chat gippity what the first two plus signs do (^ line 8) .. response:
  
  In the provided JavaScript code, the first two plus signs (+) are used for type 
  coercion, specifically to convert strings to numbers. Here's a breakdown of the code:
  
  1. (amount + "e" + 2): This part of the code first concatenates the variable amount 
  with the string "e" and the number 2. If amount is a number, this creates a string 
  like "123e2" (assuming amount is 123, for example).
  
  2. Math.round(+(amount + "e" + 2)): The +(amount + "e" + 2) part first converts 
  the string result from the previous step back into a number, by using the unary 
  plus operator (+). Then, Math.round is applied to round the resulting number to 
  the nearest integer.
  
  3. "e" + -2: This part concatenates the string "e" with the number -2, creating 
  a string like "e-2".
  
  4. +(Math.round(+(amount + "e" + 2)) + "e" + -2): The previous rounded number is 
  now being added to the string "e-2." The unary plus operator is used again to 
  convert the result into a number. If the result was 125.45, this would become 125.45.
  (alex note: LOL. i think it means that if the result was the string "125.45", it would become the number 125.45)
  
  5. .toFixed(2): Finally, the .toFixed(2) method is used to convert the number to 
  a string with exactly two decimal places. For example, 125.45 becomes "125.45".
  
  The purpose of these operations seems to be to format the number in scientific 
  notation with two decimal places, using the "e" notation (exponential notation) 
  and rounded to the nearest integer.
  */
