// Libs
import { z } from "zod";
import { DocumentSnapshot, Timestamp } from "firebase/firestore";

// Local
import {
  guardIsPlainObject,
  isValidISO8601,
  msgInvalidISO8601,
} from "../utils";
import { zFallback } from "../utils/zod-fallback";
import { NotFoundError } from "../error-classes";
import {
  BillingInfo,
  billingInfoSchema,
  ServiceAddress,
  serviceAddressSchema,
} from "./customer";
import {
  AccountingSyncStiltDoc,
  AccountingSyncStiltDocSchema,
  AccountingSyncStiltDocSchemaWithFallbacks,
} from "./accounting-sync";
import {
  ExistingStiltPhoto,
  existingStiltPhotoSchemaWithFallbacks,
} from "./stilt-photo";

export interface StiltLineItemFormData {
  title: string | null; // estimateItemTitle
  description: string; // estimateItemDescription
  quantity: number;
  discount: number;
  taxAmount: number;
  unitPrice: number;
  subTotal: number;
  discountable?: boolean;
  taxable?: boolean;
  priceBookItemID?: string;
  estimateItemID?: null | string;
  toBeEdited?: boolean;
}

export const paymentMethods = [
  "credit_card",
  "cash",
  "check",
  "ach",
  "eft",
  "tokenized_card",
  "tokenized_check",
  "finance_program",
  "gift_card",
  "other",
] as const;
export type PaymentMethodsValues = (typeof paymentMethods)[number];

export const cardTypes = [
  "Visa",
  "American Express",
  "Master Card",
  "Discover",
  "Other",
] as const;
export type CardTypesValues = (typeof cardTypes)[number];

export const paymentMethodsMap: Record<PaymentMethodsValues, string> = {
  credit_card: "credit card",
  cash: "cash",
  check: "check",
  ach: "ACH",
  eft: "EFT",
  finance_program: "finance program",
  gift_card: "gift card",
  other: "other",
  tokenized_card: "saved card",
  tokenized_check: "saved check",
};

export const paymentSources = ["manual", "payaHPP", "ziftHPP"] as const;
export type PaymentSourcesValues = (typeof paymentSources)[number];

export const paymentTypes = ["invoice", "refund"] as const;
export type PaymentTypesValues = (typeof paymentTypes)[number];

export interface StiltPayment {
  timestampCreated: Timestamp;
  timestampPaymentMade: Timestamp;
  amount: number;
  customerID: string;
  billToCustomerID: string; //No longer in use
  invoiceID: string;
  customData: { [key: string]: any };
  paymentMethod: PaymentMethodsValues;
  createdBy: string | null;
  paymentSource: PaymentSourcesValues;
  memo: string | null;
  checkNumber: string | null;
  cardType?: string | null;
  lastFour?: string | null;
  nameOnCard?: string | null;
  locationID: string;
  paymentType?: PaymentTypesValues;
  /** if paymentType is refund, then this will reference the original payment that is being refunded */
  referencePaymentID?: string;
  accountingSync?: AccountingSyncStiltDoc;
  deleted: boolean;
  multiPaymentID?: string;
}

type withoutTimestamps = Omit<
  StiltPayment,
  "timestampCreated" | "timestampPaymentMade"
>;

export interface StiltPayment_CreateAPI extends withoutTimestamps {
  siteKey: string;
  stringTimestampPaymentMade: string;
}

/**
 * Limited sub-set of data provided to unauthenticated viewers of a payment form
 */
export interface StiltPaymentFormData {
  merchantName: string | null; // company they're paying
  merchantLogoURL: string | null;
  name: string | null; // this is the company name if it's a commercial customer
  email: string | null;
  phone: string | null;
  invoiceNumber: string | null;
  poNumber: string | null;
  jobNumber: string | null;
  billingInfo?: BillingInfo | null;
  serviceAddress?: ServiceAddress | null;
  issueDate: string;
  dueDate: string;
  subTotal: number;
  totalAmount: number;
  amountDue: number;
  discount: number;
  totalTaxAmount: number;
  totalTaxAmountPST?: number;
  totalTaxAmountGST?: number;
  note: string | null;
  lineItems: StiltLineItemFormData[];
  paymentURL: string;
  paymentsMade: UnauthedPaymentMade[];
  currency: string;
  tipsEnabled: boolean;
  jobPhotos: ExistingStiltPhoto[];
}

export interface ExistingStiltPayment extends StiltPayment {
  id: string;
  refPath: string;
}

/** FOR THE API */
export interface CreateMultiPayment {
  invoiceID: string;
  /** this is the total payment amount. this property will not change
   * across the given CreateMultiPayment objects (of a single batch).
   * it must be equal to the sum of `amountDue` on the given invoices,
   * or the request will be rejected. */
  amount: number;

  /** ISO Datetime string */
  timestampCreated: string;
  /** ISO Datetime string */
  timestampPaymentMade: string;
  customerID: string;
  billToCustomerID: string;
  customData: { [key: string]: any };
  paymentMethod: PaymentMethodsValues;
  paymentSource: PaymentSourcesValues;
  createdBy: null | string;
  memo: null | string;
  checkNumber: null | string;
  cardType?: null | string;
  lastFour?: null | string;
  nameOnCard?: null | string;
  locationID: string;
  paymentType?: PaymentTypesValues;
}
/** FOR THE API */
export type CreateMultiPaymentParams = {
  payments: CreateMultiPayment[];
  siteKey: string;
};

const createMultiPaymentParamsSchema = z.object({
  payments: z.array(
    z.object({
      invoiceID: z.string().min(1).max(200),

      timestampCreated: z.string().refine(isValidISO8601, msgInvalidISO8601),
      timestampPaymentMade: z
        .string()
        .refine(isValidISO8601, msgInvalidISO8601),
      amount: z.number(),
      customerID: z.string(),
      billToCustomerID: z.string(),
      customData: z.record(z.any()),
      paymentMethod: z.enum(paymentMethods),
      paymentSource: z.enum(paymentSources),
      createdBy: z.string().nullable(),
      memo: z.string().nullable(),
      checkNumber: z.string().nullable(),
      cardType: z.string().nullable().optional(),
      lastFour: z.string().nullable().optional(),
      nameOnCard: z.string().nullable().optional(),
      locationID: z.string(),
      paymentType: z.enum(paymentTypes).optional(),
    }),
  ),
  siteKey: z.string().min(1).max(200),
});

function validateCreateMultiPayment(value: unknown): CreateMultiPaymentParams {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = createMultiPaymentParamsSchema.parse(value);
  return result;
}

export type APIPaymentSavedCard = {
  // uid: string; --- added server-side
  siteKeyID: string;
  invoiceID: string;
  amount: number;
  cardLastFour: number;
  cardExpiry: string;
};

const SchemaAPIPaymentSavedCard: z.ZodType<APIPaymentSavedCard> = z.object({
  // uid: z.string().min(1).max(200),
  siteKeyID: z.string().min(1).max(200),
  invoiceID: z.string().min(1).max(200),
  amount: z.number().positive({ message: "Amount must be greater than zero." }),
  cardLastFour: z.number().positive(),
  cardExpiry: z.string().min(1).max(20),
});

export type UnauthedPaymentMade = Pick<
  StiltPayment,
  | "amount"
  | "paymentMethod"
  | "paymentType"
  | "lastFour"
  | "nameOnCard"
  | "cardType"
  | "checkNumber"
  // timestampPaymentMade needs to be a string when sent to client via CF
  // memo HAS to be null for unauthenticated users
> & { timestampPaymentMade: string; memo: null };

/** Utilities for interacting with StiltPayment objects  */
export const StiltPaymentManager = {
  validateFormData: validateStiltPaymentFormData,
  createFromFirestoreSnapshot: createFromFirestoreSnapshot,
  parseCreate: validateStiltPayment_Create,
  parseCreateMultiPayment: validateCreateMultiPayment,
  /** Validate key/value pairs that the API needs in order to make a payment using a card on file. */
  parseCreateWithSavedCard: SchemaAPIPaymentSavedCard.parse,
} as const;

function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingStiltPayment {
  if (!snapshot.exists) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateStiltPaymentWithFallbacks(snapshotData),
  };
}

function validateStiltPaymentFormData(value: unknown): StiltPaymentFormData {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = stiltPaymentFormDataWithFallbacks.parse(value);
  return result;
}

function validateStiltPaymentWithFallbacks(value: unknown): StiltPayment {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = stiltPaymentSchemaWithFallbacks.parse(value);
  return result;
}

function validateStiltPayment_Create(value: unknown): StiltPayment_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return paymentSchema_CreateAPI.parse(value);
}

// #region SECTION: Schemas
// Used when validating data coming from the database.
const stiltPaymentSchemaWithFallbacks = z.object({
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "stiltPaymentSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampPaymentMade: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "stiltPaymentSchemaWithFallbacks: 'timestampPaymentMade'",
  ),
  amount: zFallback(z.number(), 0, "stiltPaymentSchemaWithFallbacks: 'amount'"),
  customerID: zFallback(
    z.string(),
    "unknown",
    "stiltPaymentSchemaWithFallbacks: 'customerID'",
  ),
  billToCustomerID: zFallback(
    z.string(),
    "unknown",
    "stiltPaymentSchemaWithFallbacks: 'billToCustomerID'",
  ),
  invoiceID: zFallback(
    z.string(),
    "unknown",
    "stiltPaymentSchemaWithFallbacks: 'invoiceID'",
  ),
  customData: z.record(z.any()),
  paymentMethod: zFallback(
    z.enum(paymentMethods),
    "cash",
    "CustomerSchemaWithFallbacks: 'paymentMethod'",
  ),
  paymentSource: zFallback(
    z.enum(paymentSources),
    "manual",
    "stiltPaymentSchemaWithFallbacks: 'paymentSource'",
  ),
  createdBy: zFallback(
    z.string().nullable(),
    null,
    "stiltPaymentSchemaWithFallbacks: 'createdBy'",
  ),
  memo: zFallback(
    z.string().nullable(),
    null,
    "stiltPaymentSchemaWithFallbacks: 'memo'",
  ),
  checkNumber: zFallback(
    z.string().nullable(),
    null,
    "stiltPaymentSchemaWithFallbacks: 'checkNumber'",
  ),
  lastFour: zFallback(
    z.string().nullable().optional(),
    null,
    "stiltPaymentSchemaWithFallbacks: 'lastFour'",
  ),
  cardType: zFallback(
    z.string().nullable().optional(),
    null,
    "stiltPaymentSchemaWithFallbacks: 'cardType'",
  ),
  nameOnCard: zFallback(
    z.string().nullable().optional(),
    null,
    "stiltPaymentSchemaWithFallbacks: 'nameOnCard'",
  ),
  locationID: zFallback(
    z.string(),
    "unknown",
    "stiltPaymentSchemaWithFallbacks: 'locationID'",
  ),
  paymentType: z.enum(paymentTypes).optional(),
  referencePaymentID: z.string().optional(),
  accountingSync: AccountingSyncStiltDocSchemaWithFallbacks.optional(),
  deleted: zFallback(
    z.boolean(),
    false,
    "stiltPaymentSchemaWithFallbacks: 'deleted'",
  ),
  multiPaymentID: zFallback(
    z.string().min(1).max(200).optional(),
    undefined,
    "stiltPaymentSchemaWithFallbacks: 'multiPaymentID'",
  ),
});

const unauthedPaymentMadeSchemaWithFallbacks: z.ZodType<UnauthedPaymentMade> =
  z.object({
    amount: z.number(),
    timestampPaymentMade: z.string().refine(isValidISO8601, msgInvalidISO8601),
    paymentMethod: z.enum(paymentMethods),
    paymentType: zFallback(
      z.enum(paymentTypes).optional(),
      undefined,
      "unauthed payment fallback: 'paymentType'",
    ),
    memo: zFallback(z.null(), null, "unauthed payment fallback: 'memo'"),
    lastFour: zFallback(
      z.string().nullable().optional(),
      null,
      "unauthed payment fallback: 'lastFour'",
    ),
    cardType: zFallback(
      z.string().nullable().optional(),
      null,
      "unauthed payment fallback: 'cardType'",
    ),
    nameOnCard: zFallback(
      z.string().nullable().optional(),
      null,
      "unauthed payment fallback: 'nameOnCard'",
    ),
    checkNumber: zFallback(
      z.string().nullable(),
      null,
      "unauthed payment fallback: 'checkNumber'",
    ),
  });

const stiltPaymentFormDataWithFallbacks: z.ZodType<StiltPaymentFormData> =
  z.object({
    name: z.string().min(1).max(200).nullable(),
    merchantName: z.string().min(1).max(200).nullable(),
    merchantLogoURL: zFallback(
      z.string().min(1).max(400).nullable(),
      null,
      "paymentFormData fallback: 'merchantLogoURL'",
    ),
    email: zFallback(
      z.string().min(0).max(200).nullable(),
      "",
      "paymentFormData fallback: 'email'",
    ),
    phone: zFallback(
      z.string().min(0).max(200).nullable(),
      "",
      "paymentFormData fallback: 'phone'",
    ),
    issueDate: zFallback(
      z.string().min(1).max(200),
      "",
      "paymentFormData fallback: 'issueDate'",
    ),
    dueDate: zFallback(
      z.string().min(1).max(200),
      "",
      "paymentFormData fallback: 'dueDate'",
    ),
    billingInfo: zFallback(
      billingInfoSchema.optional().nullable(),
      null,
      "paymentFormData fallback: 'billingInfo'",
    ),
    serviceAddress: zFallback(
      serviceAddressSchema.optional().nullable(),
      null,
      "paymentFormData fallback: 'serviceAddress'",
    ),
    invoiceNumber: zFallback(
      z.string().nullable(),
      "",
      "paymentFormData fallback: 'invoiceNumber'",
    ),
    poNumber: zFallback(
      z.string().nullable(),
      "",
      "paymentFormData fallback: 'poNumber'",
    ),
    jobNumber: zFallback(
      z.string().nullable(),
      null,
      "paymentFormData fallback: 'jobNumber'",
    ),
    subTotal: zFallback(z.number(), 0, "paymentFormData fallback: 'subTotal'"),
    totalAmount: zFallback(
      z.number(),
      0,
      "paymentFormData fallback: 'totalAmount'",
    ),
    discount: zFallback(z.number(), 0, "paymentFormData fallback: 'discount'"),
    amountDue: zFallback(
      z.number(),
      0,
      "paymentFormData fallback: 'amountDue'",
    ),
    totalTaxAmount: zFallback(
      z.number(),
      0,
      "paymentFormData fallback: 'totalTaxAmount'",
    ),
    totalTaxAmountPST: zFallback(
      z.number().optional(),
      undefined,
      "paymentFormData fallback: 'totalTaxAmountPST'",
    ),
    totalTaxAmountGST: zFallback(
      z.number().optional(),
      undefined,
      "paymentFormData fallback: 'totalTaxAmountGST'",
    ),
    note: zFallback(
      z.string().min(0).max(2000).nullable(),
      "",
      "paymentFormData fallback: 'note'",
    ),
    lineItems: z.array(
      z.object({
        title: z.string().min(1).max(2000).nullable(),
        description: z.string().min(0).max(4000),
        quantity: z.number(),
        discount: z.number(),
        unitPrice: z.number(),
        subTotal: z.number(),
        taxAmount: z.number(),
        priceBookItemID: z.string().optional(),
        estimateItemID: z.string().optional(),
        taxable: zFallback(
          z.boolean().optional(),
          false,
          "paymentFormData fallback: 'taxable'",
        ),
        discountable: z.boolean().optional(),
        toBeEdited: z.boolean().optional(),
      }),
    ),
    paymentURL: zFallback(
      z.string().min(1).max(600),
      "",
      "paymentFormData fallback: 'paymentURL'",
    ),
    currency: z.string().min(1).max(200),
    paymentsMade: zFallback(
      z.array(unauthedPaymentMadeSchemaWithFallbacks),
      [],
      "paymentFormData fallback: 'paymentsMade'",
    ),
    tipsEnabled: z.boolean(),
    jobPhotos: zFallback(
      z.array(existingStiltPhotoSchemaWithFallbacks),
      [],
      "paymentFormData fallback: 'jobPhotos'",
    ),
  });

const stiltPaymentSchema = z.object({
  timestampCreated: z.instanceof(Timestamp),
  timestampPaymentMade: z.instanceof(Timestamp),
  amount: z.number(),
  customerID: z.string(),
  billToCustomerID: z.string(),
  invoiceID: z.string(),
  customData: z.record(z.any()),
  paymentMethod: z.enum(paymentMethods),
  paymentSource: z.enum(paymentSources),
  createdBy: z.string().nullable(),
  memo: z.string().nullable(),
  checkNumber: z.string().nullable(),
  cardType: z.string().nullable().optional(),
  lastFour: z.string().nullable().optional(),
  nameOnCard: z.string().nullable().optional(),
  locationID: z.string(),
  paymentType: z.enum(paymentTypes).optional(),
  referencePaymentID: z.string().optional(),
  accountingSync: AccountingSyncStiltDocSchema.optional(),
  deleted: z.boolean(),
  multiPaymentID: z.string().min(1).max(200).optional(),
});

const withoutTimestampsSchema = stiltPaymentSchema.omit({
  timestampCreated: true,
  timestampPaymentMade: true,
});

// Used for interacting with the Create endpoint.
const paymentSchema_CreateAPI = withoutTimestampsSchema
  .extend({
    siteKey: z.string().min(1).max(400),
    stringTimestampPaymentMade: z.string().min(1).max(400),
  })
  .refine((payment) => {
    if (payment.paymentSource === "manual") {
      return payment.createdBy != null;
    } else {
      return true;
    }
  });

export function getReadablePaymentMethod(
  paymentMethod: PaymentMethodsValues,
): string {
  switch (paymentMethod) {
    case "credit_card":
      return "credit card";
    case "cash":
      return "cash";
    case "check":
      return "check";
    case "ach":
      return "ACH";
    case "eft":
      return "EFT";
    case "finance_program":
      return "finance program";
    case "gift_card":
      return "gift card";
    case "other":
      return "other";
    case "tokenized_card":
      return "saved card";
    case "tokenized_check":
      return "saved check";
    default:
      return "UNKNOWN";
  }
}
