//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { QRCodeSVG } from "qrcode.react";

//Local
import BaseModal from "../BaseModal";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  downloadLink: string | null;
}

export default function QRDialog(props: Props) {
  const qrHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        Scan with your camera to download the mobile app:
      </h1>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close password dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  {
    return props.downloadLink ? (
      <BaseModal
        closeModal={props.closeDialog}
        open={props.isDialogOpen}
        title={qrHeader}
        parentDivStyles="max-w-lg inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all"
      >
        <div className="my-6 flex justify-center">
          <QRCodeSVG
            value={props.downloadLink}
            size={256}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={true}
          />
        </div>
      </BaseModal>
    ) : (
      <p className="px-6 pb-8 text-red-600">
        We're sorry, but we couldn't process your request at this time. Please
        try again later. If the problem persists, contact our support team for
        assistance.
      </p>
    );
  }
}
