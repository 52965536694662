import React from "react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";

const alertTypes = ["success", "error", "info", "warning"] as const;
export type AlertType = (typeof alertTypes)[number];

/**
 * Classes for the different parts of the alert.
 */
type AlertTheme = {
  /**
   * Styles
   */
  background: string;
  title: string;
  message: string;
  icon: string;
  closeButton: string;
  actionDefault: string;
};

const alertTypeClasses: Record<AlertType, AlertTheme> = {
  success: {
    background: "bg-green-50",
    title: "text-green-800",
    message: "text-green-700",
    icon: "text-green-400",
    closeButton: "text-green-800",
    actionDefault: "text-green-800",
  },
  error: {
    background: "bg-red-50",
    title: "text-red-800",
    message: "text-red-700",
    icon: "text-red-400",
    closeButton: "text-red-800",
    actionDefault: "text-red-800",
  },
  info: {
    background: "bg-blue-50",
    title: "text-blue-800",
    message: "text-blue-700",
    icon: "text-blue-400",
    closeButton: "text-blue-800",
    actionDefault: "text-blue-800",
  },
  warning: {
    background: "bg-yellow-50",
    title: "text-yellow-800",
    message: "text-yellow-700",
    icon: "text-yellow-400",
    closeButton: "text-yellow-800",
    actionDefault: "text-yellow-800",
  },
};

type IconComponent = typeof CheckCircleIcon;

const alertTypeIcons: Record<AlertType, IconComponent> = {
  success: CheckCircleIcon,
  error: XCircleIcon,
  info: ExclamationCircleIcon,
  warning: ExclamationTriangleIcon,
};

type ActionsAlignment = "left" | "right";

type Props = {
  /**
   * Controls the colors and icons used to indicate the type of alert.
   */
  variant: AlertType;
  /**
   * The title of the alert. Pass JSX for more complex styles.
   *
   *
   */
  title: string | React.ReactNode;

  /**
   * The message to display in the alert.
   * If not provided, the alert will not have a message.
   * Pass JSX for more complex styles.
   */
  message?: string | React.ReactNode;
  /**
   * Row of buttons or actions to display at the bottom of the alert.
   *
   * These are generally buttons that will inherit text color based on
   * the alert type.
   */
  actions?: React.ReactNode;
  /**
   * Function to call when the alert is dismissed. If provided it will show
   *
   * a close icon in the upper right corner of the alert.
   */
  onDismiss?: () => void;
  /**
   * Controls the flexbox justify alignment of the actions row.
   *
   * Defaults to "right".
   */
  alignActions?: ActionsAlignment;
};

const actionsAligmentClassMap: Record<ActionsAlignment, string> = {
  left: "justify-start",
  right: "justify-end",
};
const ACTIONS_ALIGNMENT_DEFAULT: ActionsAlignment = "right";

/**
 * An updated version of the AlertWithActions component that uses the new props.
 */
export default function AlertV2(props: Props): JSX.Element {
  const theme = alertTypeClasses[props.variant];
  const AlertIcon = alertTypeIcons[props.variant];
  const alignActions = props.alignActions ?? ACTIONS_ALIGNMENT_DEFAULT;
  const actionsAligmentClasses = actionsAligmentClassMap[alignActions];

  return (
    <div className={`rounded-md p-4 ${theme.background}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertIcon
            aria-label="success"
            className={`h-5 w-5 ${theme.icon}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-grow">
          <h3
            className={`flex justify-between text-sm font-semibold ${theme.title}`}
          >
            {props.title}
            {props.onDismiss ? (
              <button
                type="button"
                onClick={props.onDismiss}
                aria-label="close"
                className={`${theme.closeButton}`}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5 flex-shrink-0 cursor-pointer" />
              </button>
            ) : null}
          </h3>
          {/* Alert Body */}
          {props.message && (
            <div className={`mt-4 text-sm ${theme.message}`}>
              <p>{props.message}</p>
            </div>
          )}
          {/* Action Row */}
          {props.actions && (
            <div
              className={`-ml-2 mt-2.5 flex gap-3 ${actionsAligmentClasses} ${theme.actionDefault}`}
            >
              {props.actions}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
