//Libs
import { Dispatch } from "react";

//Local
import BaseInputCheckbox from "../BaseInputCheckbox";
import { ExistingMembership } from "../../models/membership";
import {
  ExistingMembershipTemplate,
  TaskGeneration,
} from "../../models/membership-template";
import { getTaskStatusPillClasses } from "../../assets/js/tasks";
import { getReadableTaskStatus } from "../../models/task-status";
import { useNavigate } from "react-router-dom";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { StyledTooltip } from "../StyledTooltip";
import { Task } from "../../models/task";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import { logger } from "../../logging";

interface Props {
  membership: ExistingMembership;
  membershipTemplate: ExistingMembershipTemplate;
}

export default function MembershipTasksOverview({
  membership,
  membershipTemplate,
}: Props) {
  const navigate = useNavigate();

  function handleGoToWorkRecordAndTaskPage(craftRecordPath: string) {
    const craftRecordID = craftRecordPath.split("/")[3];
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  function getBgColor() {
    return "bg-blue-200";
  }

  const dateStringOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
  };

  const membershipTasksSortedByTimestampCreated = membership.tasks
    ? Object.values(membership.tasks).sort((a, b) => {
        if (a.timestampCreated && b.timestampCreated) {
          return a.timestampCreated.toMillis() - b.timestampCreated.toMillis();
        } else {
          return -1;
        }
      })
    : [];

  function getAdditionalTextorMembershipTask(task: Task): string {
    try {
      if (task.timestampTaskCompleted) {
        return (
          "on: " + convertToReadableTimestampDate(task.timestampTaskCompleted)
        );
      }
      if (task.timestampScheduled) {
        return (
          "for: " + convertToReadableTimestampDate(task.timestampScheduled)
        );
      }
      return (
        "Created on: " + convertToReadableTimestampDate(task.timestampCreated)
      );
    } catch (e) {
      logger.error("Error in getTooltipTitleForMembershipTask", e);
      return "";
    }
  }

  // Returns a div with a list of the expected tasks that this membershipTemplate has in its taskGeneration array, and matched up to the actual tasks in the membership
  return (
    <div className="flex w-72 flex-col gap-2">
      <div className="text-md font-extrabold">Expected Timeline</div>
      {membershipTemplate.taskGeneration.length === 0 && (
        <div className="text-sm italic ">
          No task rules found for this membership
        </div>
      )}
      {membership.membershipStartDate && (
        <div className="text-sm">
          Start -{" "}
          {membership.membershipStartDate
            .toDate()
            .toLocaleDateString("en-US", dateStringOptions)}
        </div>
      )}
      {membership.membershipStartDate &&
        membershipTemplate.taskGeneration.map((task, index) => (
          <div key={index} className="flex items-center gap-2">
            <div
              className={`h-6 w-6 ${getBgColor()} items-center justify-center rounded-full text-center text-sm`}
            >
              {index + 1}
            </div>
            <div className="text-sm">
              {getDateFromStartDateAndDaysAfterRenewal(membership, task)}
            </div>
            {membershipTasksSortedByTimestampCreated[index] && (
              <button
                onClick={() => {
                  if (
                    membershipTasksSortedByTimestampCreated[index].craftRecordID
                  ) {
                    return handleGoToWorkRecordAndTaskPage(
                      membershipTasksSortedByTimestampCreated[index]
                        .craftRecordID,
                    );
                  }
                }}
                className={`rounded-full px-2 py-1 text-xs font-medium ${getTaskStatusPillClasses(
                  membershipTasksSortedByTimestampCreated[index].taskStatus,
                )}`}
              >
                {getReadableTaskStatus(
                  membershipTasksSortedByTimestampCreated[index].taskStatus,
                )}{" "}
                {getAdditionalTextorMembershipTask(
                  membershipTasksSortedByTimestampCreated[index],
                )}
              </button>
            )}
            {/*<div className="text-sm">This task is complete</div>*/}
          </div>
        ))}
      {membership.membershipEndDate && (
        <div className="text-sm">
          End -{" "}
          {membership.membershipEndDate
            .toDate()
            .toLocaleDateString("en-US", dateStringOptions)}
        </div>
      )}
    </div>
  );

  // return (
  //   <div className="flex flex-col gap-2">
  //     {membershipsForCustomer.length === 0 && (
  //       <div>No memberships found for this customer</div>
  //     )}
  //     {membershipsForCustomer.map((membership) => (
  //       <div key={membership.id} className="flex items-center gap-2">
  //         <BaseInputCheckbox
  //           label={`${getReadableMembershipStatus(membership.status)}: ${convertToReadableTimestampDate(membership.membershipStartDate)} - ${convertToReadableTimestampDate(membership.membershipEndDate)}`}
  //           value={membership.id}
  //           defaultChecked={membershipIDs?.includes(membership.id)}
  //           onChange={(event) => {
  //             const checked = event.target.checked;
  //             if (checked) {
  //               // Create set of previous IDs and then add the new ID
  //               const newIDs = new Set(membershipIDs);
  //               setMembershipIDs((_prev) =>
  //                 Array.from(newIDs.add(membership.id)),
  //               );
  //             } else {
  //               setMembershipIDs((_prev) =>
  //                 membershipIDs?.filter((id) => id !== membership.id),
  //               );
  //             }
  //           }}
  //         />
  //       </div>
  //     ))}
  //   </div>
  // );
}

export function getDateFromStartDateAndDaysAfterRenewal(
  membership: ExistingMembership,
  taskToGenerate: TaskGeneration,
) {
  const dateStringOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
  };

  if (membership.membershipStartDate === null) {
    return "";
  }
  if (taskToGenerate.daysAfterRenewal === null) {
    return "";
  }
  const daysAfterRenewal = taskToGenerate.daysAfterRenewal;
  const startDate = new Date(membership.membershipStartDate.toDate());
  startDate.setDate(startDate.getDate() + daysAfterRenewal);
  // return start date formatted by mm/dd/yy
  return startDate.toLocaleDateString("en-US", dateStringOptions);
}

export function getDateFromStartDateAndDaysAfterRenewalyyyymmdd(
  membership: ExistingMembership,
  taskToGenerate: TaskGeneration,
) {
  if (membership.membershipStartDate === null) {
    return "";
  }
  if (taskToGenerate.daysAfterRenewal === null) {
    return "";
  }
  const daysAfterRenewal = taskToGenerate.daysAfterRenewal;
  const startDate = new Date(membership.membershipStartDate.toDate());
  startDate.setDate(startDate.getDate() + daysAfterRenewal);
  // formatted in yyyy-mm-dd
  return startDate.toISOString().split("T")[0];
  // return startDate.toLocaleDateString("en-US", dateStringOptions);
}
