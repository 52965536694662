//Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useMemo, useState } from "react";
import { PencilIcon } from "@heroicons/react/24/solid";

//Local
import BaseInputSelect from "../BaseInputSelect";
import BaseInputText from "../BaseInputText";
import {
  editSiteKeyDetailsSchema,
  EditSiteKeyDetailsState,
} from "../../models/site-key";
import StyledMessage from "../StyledMessage";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";

export interface SiteDetailsMapProps {
  siteName: string;
  timezone: string[];
  address: string;
  latitude?: number;
  longitude?: number;
  handleSave: (formValues: EditSiteKeyDetailsState) => Promise<void>;
}

export default function SiteDetails(
  args: SiteDetailsMapProps & {
    children: { DisplayResponseMessage: React.ReactNode };
  },
) {
  const theDefaultValues = useMemo(() => {
    return {
      name: args.siteName,
      timezone: args.timezone[0],
      address: args.address,
      latitude: args.latitude,
      longitude: args.longitude,
    };
  }, [args]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<EditSiteKeyDetailsState>({
    defaultValues: theDefaultValues,
    resolver: zodResolver(editSiteKeyDetailsSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(theDefaultValues);
  }, [reset, theDefaultValues]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onSave(formValues: EditSiteKeyDetailsState) {
    // Save button indicator
    setIsSubmitting(true);

    try {
      await args.handleSave(formValues);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSave)}>
      <div className="mt-4 flex flex-col xs:mt-0 xs:flex-row xs:items-center xs:gap-x-4 sm:items-end sm:justify-end sm:gap-x-8">
        <div className="mx-auto mb-1 inline-block h-12 xs:mb-0 xs:flex xs:items-center sm:mx-0 sm:items-end">
          {args.children.DisplayResponseMessage}
        </div>
        <div className="text-right sm:mt-4">
          <BaseButtonPrimary
            className="w-full xs:w-52"
            type="submit"
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
          >
            <PencilIcon aria-label="save" className="mr-4 h-6" />
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </div>

      <div className="mt-8 grid gap-6 xs:gap-8 sm:grid-cols-2 md:mt-10 lg:grid-cols-3">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Name"
                inputName="name"
                admin={true}
                {...field}
              />
            )}
          />
          {errors.name?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.name.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        <div>
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <BaseInputSelect
                text="Timezone"
                inputName="timezone"
                admin={true}
                id="timezone"
                {...field}
              >
                {args.timezone.map((zone) => (
                  <option key={zone} value={zone}>
                    {zone}
                  </option>
                ))}
              </BaseInputSelect>
            )}
          />
          {errors.timezone?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.timezone.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        <div className="sm:col-span-2 lg:col-span-1">
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Address"
                inputName="address"
                admin={true}
                {...field}
              />
            )}
          />
          {errors.address?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.address.message }}
              </StyledMessage>
            </div>
          )}
          <span
            className={`ml-1 mt-2 text-sm font-medium text-gray-500 ${
              args.latitude
                ? "block max-w-md xxs:mx-auto xxs:flex xxs:justify-around lg:block xl:flex"
                : "mx-auto flex justify-evenly"
            }`}
          >
            <p className="mr-2 xs:mr-0">Latitude: {args.latitude ?? "N/A"}</p>
            <p>Longitude: {args.longitude ?? "N/A"}</p>
          </span>
        </div>
      </div>
      <hr className="my-10 border border-dashed border-gray-300" />
    </form>
  );
}
