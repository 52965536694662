//Libs

//Local
import {
  getCraftTypeFromString,
  getCraftTypeStringFromReadableString,
} from "../models/craft-types";
import { ExistingSiteKey } from "../models/site-key";
import { getTaskTypeValueFromReadableStr } from "../models/task-types";

export function getCustomServiceWindowDuration(
  siteKeyDoc: ExistingSiteKey,
  selectedWorkType: string | null,
  selectedTaskType: string | null,
): number {
  const serviceWindowOverrides =
    siteKeyDoc.customizations.serviceWindowOverrides;

  /* if serviceWindowOverrides doesn't exists on customizations, return 2 as default */
  if (!serviceWindowOverrides) return 2;

  /* if serviceWindowOverrides exists, set the default value as first result before checks */
  let newDuration = serviceWindowOverrides.default;

  if (selectedWorkType == null) return newDuration;
  if (selectedTaskType == null) return newDuration;

  /* if the selectedWorkType & selectedTaskType exists, check if they are valid craft type & task type & return their enum value */
  const validCraftType = getCraftTypeFromString(
    getCraftTypeStringFromReadableString(selectedWorkType),
  );

  const validTaskType = getTaskTypeValueFromReadableStr(selectedTaskType);

  if (validCraftType && validTaskType) {
    /* if everything went well, get first the custom object related to the craft type value */
    const sWOverridesCraftTypeObject =
      serviceWindowOverrides[
        String(validCraftType) as keyof typeof serviceWindowOverrides
      ];

    /* then get the value related to the selected task type & assign it to the variable to update the result */
    newDuration =
      sWOverridesCraftTypeObject[
        String(validTaskType) as keyof typeof sWOverridesCraftTypeObject
      ];
  }

  return newDuration;
}
