//Libs
import React from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";

export const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

export const ReceiptIconWithRef = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithRef<"button">
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className="mr-1 rounded-full text-gray-700 hover:bg-primaryOpacity90"
    >
      <ReceiptIcon aria-label="view estimate button" style={styles} />
    </button>
  );
});
