export default function FormHeading(props: {
  label: string;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <div>
      <h3 className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left">
        {props.label}
      </h3>
      <p className="mt-1 max-w-2xl text-center text-sm text-gray-500 sm:text-left">
        {props.children}
      </p>
    </div>
  );
}
