// Libs
import { z } from "zod";
import { guardIsPlainObject } from "../utils";

// Local
import { checkPassingOptionsInSelectionOptions } from "./checklist-item";
import { ResponseTypes } from "./checklist-response-types";

//Tell us if the given value (complianceItem.responseType) is present in the complianceItemTypes array or not.
export function isComplianceItemType(
  value: unknown,
): value is ComplianceItemTypes {
  return complianceItemTypes.includes(value as any);
}

export const complianceItemTypes = [
  "float",
  "integer",
  "string",
  "selection",
] as const;
//Create the types from the TS array 'complianceResponseTypes'
export type ComplianceItemTypes = (typeof complianceItemTypes)[number];

/* User friendly compliance item types */
export function getReadableComplianceItemTypes(value: unknown): string {
  const readable: string | undefined =
    readableComplianceItemTypeMap[value as ComplianceItemTypes];
  if (!readable) return "UNKNOWN";
  return readable;
}
const readableComplianceItemTypeMap: Record<ComplianceItemTypes, string> = {
  float: "Decimal",
  integer: "Number",
  selection: "Selection",
  string: "Text",
};

// Very similar structure to ChecklistItem
export type ComplianceItemCommon = {
  text: string;
  required: boolean;
  responseType: ResponseTypes;

  passingMin: number | null;
  passingMax: number | null;

  units: string;

  selectionOptions: string[] | null;
  passingOptions: string[] | null;

  order: number;
};

export type StringComplianceItem = ComplianceItemCommon & {
  responseType: "string";
  passingMin: null;
  passingMax: null;
  selectionOptions: null;
  passingOptions: null;
};

export type IntegerComplianceItem = ComplianceItemCommon & {
  responseType: "integer";
  passingMin: number | null;
  passingMax: number | null;
  selectionOptions: null;
  passingOptions: null;
};

export type FloatComplianceItem = ComplianceItemCommon & {
  responseType: "float";
  passingMin: number | null;
  passingMax: number | null;
  selectionOptions: null;
  passingOptions: null;
};

export type SelectionComplianceItem = ComplianceItemCommon & {
  responseType: "selection";
  passingMin: null;
  passingMax: null;
  selectionOptions: string[] | null;
  passingOptions: string[] | null;
};

// This type is a composition of all of the types
export type ComplianceItemDocData =
  | StringComplianceItem
  | IntegerComplianceItem
  | FloatComplianceItem
  | SelectionComplianceItem;

// VALIDATION
function validateComplianceItem(
  complianceItem: unknown,
): ComplianceItemDocData {
  if (!guardIsPlainObject(complianceItem)) {
    throw new Error(`complianceItem not an object: ${complianceItem}`);
  }
  if (!isComplianceItemType(complianceItem.responseType)) {
    throw new Error(
      `complianceItem.responseType is not recognized: ${complianceItem.responseType}`,
    );
  }

  switch (complianceItem.responseType) {
    case "float": {
      const result = FloatItem_Schema.parse(complianceItem);
      return result as ComplianceItemDocData;
    }
    case "integer": {
      const result = IntegerItem_Schema.parse(complianceItem);
      return result as ComplianceItemDocData;
    }
    case "selection": {
      const result = SelectionItem_Schema.parse(complianceItem);
      return result as ComplianceItemDocData;
    }
    case "string": {
      const result = StringItem_Schema.parse(complianceItem);
      return result as ComplianceItemDocData;
    }
    default: {
      const _exhaustiveCheck: never = complianceItem.responseType;
      return _exhaustiveCheck;
    }
  }
}

// ZOD SCHEMAS
// These don't change based on a given responseType value
const ItemCommon_Schema = z.object({
  text: z.string().min(1, { message: "Required" }).max(2000),
  required: z.boolean(),
  units: z.string().max(200),
  order: z.number(),
});

// Stuff that has different requirements when responseType is 'string'.
export const StringItem_Schema = ItemCommon_Schema.extend({
  responseType: z.literal("string"),
  passingMax: z.null(),
  passingMin: z.null(),
  selectionOptions: z.null(),
  passingOptions: z.null(),
});

// Stuff that has different requirements when responseType is 'selection'.
export const SelectionItem_Schema = ItemCommon_Schema.extend({
  responseType: z.literal("selection"),
  passingMax: z.null(),
  passingMin: z.null(),
  selectionOptions: z.array(z.string().min(1).max(200)).nonempty(),
  passingOptions: z.array(z.string().min(1).max(200)).nonempty(),
}).refine(
  (item) =>
    checkPassingOptionsInSelectionOptions(
      item.selectionOptions,
      item.passingOptions,
    ),
  { message: "Passing option(s) must be included in selection options" },
);

// Stuff that has different requirements when responseType is 'integer'.
export const IntegerItem_Schema = ItemCommon_Schema.extend({
  responseType: z.literal("integer"),
  passingMax: z.number().int().nullable(),
  passingMin: z.number().int().nullable(),
  selectionOptions: z.null(),
  passingOptions: z.null(),
});

// Stuff that has different requirements when responseType is 'float'.
export const FloatItem_Schema = ItemCommon_Schema.extend({
  responseType: z.literal("float"),
  passingMax: z.number().nullable(),
  passingMin: z.number().nullable(),
  selectionOptions: z.null(),
  passingOptions: z.null(),
});

// Put these individual schemas into a union schema, so we can use
// it within the schemas for ComplianceResponse and ComplianceRequirement
export const ComplianceItem_UnionSchema = z.union([
  StringItem_Schema,
  SelectionItem_Schema,
  IntegerItem_Schema,
  FloatItem_Schema,
]);

export const ComplianceItemManager = {
  parse: validateComplianceItem,
  /**
   * Validate a compliance item that does not have an order property. For use *in*
   * the compliance item forms. The `order` property won't be present at that point
   */
  parsePartial: validateComplianceItemWithoutOrderProperty,
};

// SECTION: Schemas, types, and validation for the `order` property, which won't
// be present at the time of validating the compliance item.
// #region TYPES
export type ComplianceItemCommon_WithoutOrderProperty = Omit<
  ComplianceItemCommon,
  "order"
>;

export type StringComplianceItem_WithoutOrderProperty =
  ComplianceItemCommon_WithoutOrderProperty & {
    responseType: "string";
    passingMin: null;
    passingMax: null;
    selectionOptions: null;
    passingOptions: null;
  };

type IntegerComplianceItem_WithoutOrderProperty =
  ComplianceItemCommon_WithoutOrderProperty & {
    responseType: "integer";
    passingMin: number | null;
    passingMax: number | null;
    selectionOptions: null;
    passingOptions: null;
  };

type FloatComplianceItem_WithoutOrderProperty =
  ComplianceItemCommon_WithoutOrderProperty & {
    responseType: "float";
    passingMin: number | null;
    passingMax: number | null;
    selectionOptions: null;
    passingOptions: null;
  };

type SelectionComplianceItem_WithoutOrderProperty =
  ComplianceItemCommon_WithoutOrderProperty & {
    responseType: "selection";
    passingMin: null;
    passingMax: null;
    selectionOptions: string[] | null;
    passingOptions: string[] | null;
  };

type ComplianceItemDocData_WithoutOrderProperty =
  | StringComplianceItem_WithoutOrderProperty
  | IntegerComplianceItem_WithoutOrderProperty
  | FloatComplianceItem_WithoutOrderProperty
  | SelectionComplianceItem_WithoutOrderProperty;
// #endregion TYPES
// #region SCHEMAS
const ItemCommon_Schema_WithoutOrderProperty = ItemCommon_Schema.omit({
  order: true,
});

// Stuff that has different requirements when responseType is 'string'.
export const StringItem_Schema_WithoutOrderProperty =
  ItemCommon_Schema_WithoutOrderProperty.extend({
    responseType: z.literal("string"),
    passingMax: z.null(),
    passingMin: z.null(),
    selectionOptions: z.null(),
    passingOptions: z.null(),
  });

// Stuff that has different requirements when responseType is 'selection'.
const SelectionItem_Schema_WithoutOrderProperty =
  ItemCommon_Schema_WithoutOrderProperty.extend({
    responseType: z.literal("selection"),
    passingMax: z.null(),
    passingMin: z.null(),
    selectionOptions: z.array(z.string().min(1).max(200)).nonempty(),
    passingOptions: z.array(z.string().min(1).max(200)).nonempty(),
  }).refine(
    (item) =>
      checkPassingOptionsInSelectionOptions(
        item.selectionOptions,
        item.passingOptions,
      ),
    { message: "Passing option(s) must be included in selection options" },
  );

// Stuff that has different requirements when responseType is 'integer'.
const IntegerItem_Schema_WithoutOrderProperty =
  ItemCommon_Schema_WithoutOrderProperty.extend({
    responseType: z.literal("integer"),
    passingMax: z.number().int().nullable(),
    passingMin: z.number().int().nullable(),
    selectionOptions: z.null(),
    passingOptions: z.null(),
  });

// Stuff that has different requirements when responseType is 'float'.
const FloatItem_Schema_WithoutOrderProperty =
  ItemCommon_Schema_WithoutOrderProperty.extend({
    responseType: z.literal("float"),
    passingMax: z.number().nullable(),
    passingMin: z.number().nullable(),
    selectionOptions: z.null(),
    passingOptions: z.null(),
  });
// #endregion SCHEMAS

function validateComplianceItemWithoutOrderProperty(
  complianceItem: unknown,
): ComplianceItemDocData_WithoutOrderProperty {
  if (!guardIsPlainObject(complianceItem)) {
    throw new Error(`complianceItem not an object: ${complianceItem}`);
  }
  if (!isComplianceItemType(complianceItem.responseType)) {
    throw new Error(
      `complianceItem.responseType is not recognized: ${complianceItem.responseType}`,
    );
  }

  switch (complianceItem.responseType) {
    case "float": {
      const result =
        FloatItem_Schema_WithoutOrderProperty.parse(complianceItem);
      return result as ComplianceItemDocData_WithoutOrderProperty;
    }
    case "integer": {
      const result =
        IntegerItem_Schema_WithoutOrderProperty.parse(complianceItem);
      return result as ComplianceItemDocData_WithoutOrderProperty;
    }
    case "selection": {
      const result =
        SelectionItem_Schema_WithoutOrderProperty.parse(complianceItem);
      return result as ComplianceItemDocData_WithoutOrderProperty;
    }
    case "string": {
      const result =
        StringItem_Schema_WithoutOrderProperty.parse(complianceItem);
      return result as ComplianceItemDocData_WithoutOrderProperty;
    }
    default: {
      const _exhaustiveCheck: never = complianceItem.responseType;
      return _exhaustiveCheck;
    }
  }
}
