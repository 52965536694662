// Libs
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import DepartureBoardOutlinedIcon from "@mui/icons-material/DepartureBoardOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// Local
import { TaskStatus } from "../../../models/task-status";

const size = { width: "2rem", height: "2rem" };

export function getTaskStatusIcon(taskStatus: TaskStatus): JSX.Element {
  switch (taskStatus) {
    case TaskStatus.AWAITING_ESTIMATE:
      return <CalculateOutlinedIcon style={size} />;
    case TaskStatus.AWAITING_REVIEW:
      return <MoreTimeOutlinedIcon style={size} />;
    case TaskStatus.AWAITING_APPROVAL:
      return <VerifiedOutlinedIcon style={size} />;
    case TaskStatus.AWAITING_SCHEDULE:
      return <MoreTimeOutlinedIcon style={size} />;
    case TaskStatus.AWAITING_PARTS:
      return <VerifiedOutlinedIcon style={size} />;
    case TaskStatus.PARTS_ORDERED:
      return <VerifiedOutlinedIcon style={size} />;
    case TaskStatus.SCHEDULED:
      return <CalendarMonthIcon style={size} />;
    case TaskStatus.IN_ROUTE:
      return <DepartureBoardOutlinedIcon style={size} />;
    case TaskStatus.CANCELED:
      return <CancelOutlinedIcon style={size} />;
    case TaskStatus.AWAITING:
      return <HistoryToggleOffOutlinedIcon style={size} />;
    case TaskStatus.AWAITING_PAYMENT:
      return <PaidOutlinedIcon style={size} />;
    case TaskStatus.IN_PROGRESS:
      return <RotateRightOutlinedIcon style={size} />;
    case TaskStatus.COMPLETE:
      return <DoneAllOutlinedIcon style={size} />;
    case TaskStatus.ON_HOLD:
      return <PauseCircleOutlinedIcon style={size} />;
    default:
      const _exhaustivenessCheck: never = taskStatus;
      return _exhaustivenessCheck;
  }
}

// import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
// AWAITING
// import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
// ^ second choice

// import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
// AWAITING ESTIMATE (i dont like it)
