//Libs
import { SearchClient as TypesenseSearchClient } from "typesense";

//Local
import {
  CustomerManager,
  ExistingCustomerWithLocations,
} from "../models/customer";
import {
  ExistingPriceBookItem,
  PriceBookItemManager,
} from "../models/price-book-item";

export async function typesenseCustomersQuery(
  typesenseSearchKey: string = "",
  searchTerm: string,
) {
  let port = 443;
  let path = "/typesense-dev";
  if (import.meta.env.VITE_APP_FIREBASE_PROJECT === "scaffoldtracker") {
    port = 443;
    path = "/typesense";
  }
  const client = new TypesenseSearchClient({
    nodes: [
      {
        host: "external.stilt-web.com",
        port: port,
        protocol: "https",
        path: path,
      },
    ],
    apiKey: typesenseSearchKey,
    connectionTimeoutSeconds: 2,
  });

  const searchParameters = {
    q: searchTerm,
    query_by: "*",
    per_page: 250,
  };

  const customers: ExistingCustomerWithLocations[] = [];

  await client
    .collections("customers")
    .documents()
    .search(searchParameters, {})
    .then((result: any) => {
      if (result?.hits) {
        for (const hit of result.hits) {
          const customer: ExistingCustomerWithLocations =
            CustomerManager.fromTypesense(hit);
          if (!customer.deleted) customers.push(customer);
        }
      }
    });

  return customers;
}

export async function typesensePriceBookItemsQuery(
  typesenseSearchKey: string = "",
  searchTerm: string,
) {
  let port = 443;
  let path = "/typesense-dev";
  if (import.meta.env.VITE_APP_FIREBASE_PROJECT === "scaffoldtracker") {
    port = 443;
    path = "/typesense";
  }
  const client = new TypesenseSearchClient({
    nodes: [
      {
        host: "external.stilt-web.com",
        port: port,
        protocol: "https",
        path: path,
      },
    ],
    apiKey: typesenseSearchKey,
    connectionTimeoutSeconds: 2,
  });

  const searchParameters = {
    q: searchTerm,
    query_by: "*",
    per_page: 250,
  };

  const pbItems: ExistingPriceBookItem[] = [];

  await client
    .collections("priceBookItems")
    .documents()
    .search(searchParameters, {})
    .then((result: any) => {
      if (result?.hits) {
        for (const hit of result.hits) {
          const pbItem: ExistingPriceBookItem =
            PriceBookItemManager.fromTypesense(hit);
          pbItems.push(pbItem);
        }
      }
    });

  return pbItems;
}
