// Libs

// Local
import { ExistingTask } from "../models/task";
import ChecklistTasksTable from "../components/tables/ChecklistTasksTable";

interface Props {
  tasksList: ExistingTask[];
  onGoToTaskDetails: (taskID: string) => void;
  checklistTasksDateRangePicker: React.ReactNode;
}

export default function AllChecklistsTasksPage({
  onGoToTaskDetails,
  tasksList,
  checklistTasksDateRangePicker,
  ...props
}: Props) {
  return (
    <div className="flex h-full flex-col">
      {/* TABLE TITLE */}
      <div className="flex flex-col items-end justify-between space-y-2 sm:flex-row sm:space-y-0">
        <span className={"text-xl font-semibold text-gray-700"}>
          Table of Checklists from All Templates
        </span>
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <div className="w-full"></div>
          {checklistTasksDateRangePicker}
        </div>
      </div>
      <ChecklistTasksTable
        taskList={tasksList}
        onGoToTaskDetails={onGoToTaskDetails}
        showTaskTitles={true}
      />
    </div>
  );
}
