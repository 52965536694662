import Avatar from "@mui/material/Avatar";
import { MdLogout } from "react-icons/md";

// Local
import { StyledTooltip } from "../StyledTooltip";
import { signUserOut } from "../../init-firebase";

interface Props {
  name: string;
  company: string;
  avatarUrl: string;
  position: string;
}

export function UserInfoNav(props: Props) {
  return (
    <div className="flex items-center rounded-xl border border-black bg-primary p-4 text-white sm:pl-24">
      <Avatar src={props.avatarUrl} sx={{ width: "100px", height: "100px" }} />
      <div className="flex flex-col justify-center pl-4 sm:pl-11">
        <span className="text-2xl">{props.name}</span>
        <span>{props.company}</span>
        <span>{props.position}</span>
      </div>
      <StyledTooltip title="Sign out">
        <button className="ml-auto sm:mr-6" onClick={signUserOut}>
          <MdLogout className="h-7 w-7" />
        </button>
      </StyledTooltip>
    </div>
  );
}
