//Local
import BaseButtonSecondary from "./BaseButtonSecondary";
import ResponseThumbnailGallery from "./ResponseThumbnailGallery";
import { ExistingChecklistResponse } from "../models/checklist-response";
import BaseModal from "./BaseModal";
import * as strings from "../strings";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  thumbnailPhotosWithDocID: Array<{ thumbnail: string; docID: string }>;
  itemMainText: ExistingChecklistResponse["mainText"];
  openReducedGallery: (clickedIndex: number) => void;
  onDeletePhoto: (docID: string) => Promise<void>;
}

export default function ResponseTablePhotoDialog(props: Props) {
  return (
    <BaseModal
      open={props.isDialogOpen}
      closeModal={props.closeDialog}
      title={
        <div className="relative bg-primary px-4 py-2 text-xl font-light text-white">
          View Photos
        </div>
      }
      parentDivStyles="text-left max-w-sm sm:w-96"
    >
      <div className="flex flex-col justify-between px-4 pb-4 text-base">
        <p className="mb-1 mt-2 text-gray-900">Checklist Item:</p>
        <p className="mb-3 text-gray-500">{props.itemMainText}</p>

        {/* Photos */}
        <div className="grid max-h-56 grid-cols-2 overflow-auto">
          <ResponseThumbnailGallery
            thumbnailPhotosWithDocID={props.thumbnailPhotosWithDocID}
            onThumbnailClick={(i) => {
              props.openReducedGallery(i);
              props.closeDialog();
            }}
            onDeletePhoto={props.onDeletePhoto}
          />
        </div>

        {/* buttons */}
        <div className="mt-4 flex w-full items-center justify-between gap-4">
          <BaseButtonSecondary
            className="w-full justify-center uppercase"
            onClick={props.closeDialog}
          >
            {strings.buttons.CLOSE}
          </BaseButtonSecondary>
        </div>
      </div>
    </BaseModal>
  );
}
