//Libs
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import { ExistingComplianceResponse } from "../../models/compliance-response";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputTextArea from "../BaseInputTextArea";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { logger as devLogger } from "../../logging";
import StyledMessage from "../StyledMessage";

interface Props {
  response: ExistingComplianceResponse;
  closeDialog: () => void;
  isDialogOpen: boolean;
  handleSaveComment: (
    responseID: string,
    refPath: string,
    status: "approved" | "rejected",
    reviewComments: string,
  ) => Promise<void>;
}

export default function ReviewCommentDialog({
  isDialogOpen,
  response,
  closeDialog,
  handleSaveComment,
}: Props) {
  const ReviewCommentSchema = z.object({
    reviewComment: z
      .string()
      .min(1, { message: "A comment is required" })
      .max(200),
  });
  type ReviewCommentState = z.infer<typeof ReviewCommentSchema>;

  const { register, handleSubmit, reset, formState, getFieldState } =
    useForm<ReviewCommentState>({
      defaultValues: { reviewComment: "" },
      resolver: zodResolver(ReviewCommentSchema),
      mode: "onChange",
    });

  const { isSubmitting, errors } = formState;

  const commentChanged = getFieldState("reviewComment", formState).isDirty;

  async function onSubmit(data: ReviewCommentState) {
    if (response.id == null) {
      devLogger.error(`ResponseID was not found. Value: ${response.id}`);
      return;
    }

    if (commentChanged === false && data.reviewComment === "") {
      devLogger.debug(
        "A comment is required for rejected response: ",
        data.reviewComment,
      );
      return;
    }

    try {
      await handleSaveComment(
        response.id,
        response.refPath,
        "rejected",
        data.reviewComment,
      );
      devLogger.debug("Comment has successfully added!");
    } catch (error) {
      devLogger.error("Error on saving comment", error);
    } finally {
      closeDialog();
    }
  }

  useEffect(() => {
    reset({ reviewComment: "" });
  }, [reset]);

  return (
    <BaseModal
      open={isDialogOpen}
      closeModal={closeDialog}
      title={
        <div className="relative rounded-t-lg bg-primary p-6 text-xl font-semibold text-white">
          Comment: {response.title}
        </div>
      }
      parentDivStyles="text-left max-w-sm"
    >
      <div className="flex flex-col p-4 text-base">
        <p className="mb-4 text-gray-500">{response.description}</p>

        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <BaseInputTextArea
            admin={false}
            data-testid="testBaseInputTextArea"
            className="mt-3 max-w-lg"
            rows={4}
            {...register("reviewComment", {})}
          />
          {errors.reviewComment?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.reviewComment.message }}
              </StyledMessage>
            </div>
          )}

          {/* buttons */}
          <div className="mt-4 flex w-full items-center justify-between gap-4">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={() => {
                closeDialog();
              }}
            >
              {strings.buttons.CLOSE}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SUBMITTING}
              type="submit"
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SUBMIT}
            </BaseButtonPrimary>
          </div>
        </form>
      </div>
    </BaseModal>
  );
}
