//Libs

//Local
import { ExistingCustomerLocation } from "../../models/customer-location";

export function getCraftRecordTitle(
  customerName: string,
  customerLocation: ExistingCustomerLocation,
) {
  const addressLine1 =
    customerLocation.addressLine1 && customerLocation.addressLine1.length > 0
      ? customerLocation.addressLine1
      : null;
  const addressLine2 =
    customerLocation.addressLine2 && customerLocation.addressLine2.length > 0
      ? customerLocation.addressLine2
      : "";
  const city =
    customerLocation.city && customerLocation.city.length > 0
      ? customerLocation.city
      : "";
  const fullAddress =
    customerLocation.fullAddress && customerLocation.fullAddress.length > 0
      ? customerLocation.fullAddress
      : null;

  let address = fullAddress ?? "";
  if (addressLine1) {
    address = addressLine1 + " " + addressLine2 + " - " + city;
  }

  let title = customerName;

  if (
    customerLocation.locationName !== "" &&
    customerLocation.locationName !== null &&
    customerLocation.locationName !== undefined
  ) {
    title = customerLocation.locationName;
  }

  const craftRecordTitle =
    address === "" ? `${title}` : `${title} - ${address}`;

  return craftRecordTitle;
}
