// Libs
import { DateTime } from "luxon";

// Local
import { ExistingSiteKeyUserPermissions } from "../../../models/site-key-user-permissions";
import { ExistingTask } from "../../../models/task";
import { TaskStatus } from "../../../models/task-status";
import { SiteKey } from "../../../models/site-key";

export function getNextValidTaskStatus(
  task: ExistingTask,
  siteKeyUserPermissionsDoc: ExistingSiteKeyUserPermissions,
  siteKeyData: SiteKey,
): TaskStatus[] {
  // awaitingReviewOverrideOnTaskCompletion requires all tasks to go to
  // AWAITING_REVIEW instead of complete. This is used for when contractors want
  // someone (like a CSR) to review the job before fully completing it
  if (
    siteKeyData?.customizations.awaitingReviewOverrideOnTaskCompletion === true
  ) {
    if (siteKeyData.validTaskStatusCodes.includes(TaskStatus.AWAITING_REVIEW)) {
      if (task.taskStatus === TaskStatus.IN_PROGRESS) {
        return [
          TaskStatus.AWAITING,
          TaskStatus.ON_HOLD,
          TaskStatus.AWAITING_REVIEW,
        ];
      }
    }
    if (
      task.taskStatus === TaskStatus.AWAITING_REVIEW &&
      !siteKeyUserPermissionsDoc.permissions.isSiteAdmin
    ) {
      return [];
    }
    if (
      task.taskStatus === TaskStatus.AWAITING_REVIEW &&
      !siteKeyUserPermissionsDoc.permissions.isSiteAdmin
    ) {
      return [TaskStatus.COMPLETE];
    }
  }

  switch (task.taskStatus) {
    case TaskStatus.AWAITING_ESTIMATE:
      return [TaskStatus.AWAITING_APPROVAL];
    case TaskStatus.AWAITING_REVIEW:
      return [TaskStatus.COMPLETE];
    case TaskStatus.AWAITING_APPROVAL:
      if (siteKeyUserPermissionsDoc.permissions.isPlantPersonnel === false) {
        return [];
      }
      if (task.nextOpportunity === true || task.urgent === true) {
        return [TaskStatus.AWAITING];
      }
      if (task.timestampScheduled === null) {
        return [TaskStatus.AWAITING_SCHEDULE];
      }
      if (
        DateTime.fromMillis(task.timestampScheduled.seconds * 1000).valueOf() <=
        DateTime.local().endOf("day").valueOf()
      ) {
        return [TaskStatus.AWAITING];
      }
      if (
        DateTime.fromMillis(task.timestampScheduled.seconds * 1000).valueOf() >
        DateTime.local().endOf("day").valueOf()
      ) {
        return [TaskStatus.SCHEDULED];
      }
      return [];
    case TaskStatus.AWAITING_SCHEDULE:
      return [];
    case TaskStatus.AWAITING_PARTS:
      return [TaskStatus.AWAITING_SCHEDULE, TaskStatus.PARTS_ORDERED];
    case TaskStatus.PARTS_ORDERED:
      return [TaskStatus.AWAITING_SCHEDULE];
    case TaskStatus.SCHEDULED:
      return [];
    case TaskStatus.AWAITING:
      return [TaskStatus.IN_PROGRESS];
    case TaskStatus.IN_ROUTE:
      return [TaskStatus.IN_PROGRESS];
    case TaskStatus.IN_PROGRESS:
      return [TaskStatus.COMPLETE, TaskStatus.ON_HOLD, TaskStatus.AWAITING];
    case TaskStatus.ON_HOLD:
      return [TaskStatus.IN_PROGRESS];
    case TaskStatus.COMPLETE:
      return [TaskStatus.CANCELED, TaskStatus.AWAITING];
    case TaskStatus.CANCELED:
      return [TaskStatus.AWAITING];
    default:
      return [];
  }
}
