// Libs
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

// Local
import { guardIsPlainObject } from "./isPlainObject";

// Tested ✅
/** Given a Firestore Timestamp, returns a Luxon DateTime object. */
export function convertFSTimestampToLuxonDT(ts: unknown): DateTime {
  const isFSTimestamp = ts instanceof Timestamp;

  // Could receive an object that has seconds and nanoseconds, but technically is
  // not a Firestore Timestamp object. Need to handle that case first.
  if (!isFSTimestamp && guardIsPlainObject(ts)) {
    if (
      typeof ts["seconds"] === "number" &&
      typeof ts["nanoseconds"] === "number"
    ) {
      ts = new Timestamp(ts["seconds"], ts["nanoseconds"]);
    }
  }

  if (!(ts instanceof Timestamp)) {
    throw new Error(`Input was not a Firestore Timestamp. Received: ${ts}`);
  }
  // Timestamp -> milliseconds -> Luxon DT
  const ms = ts.toMillis();
  const dt = DateTime.fromMillis(ms).toUTC();
  if (!dt.isValid) {
    throw new Error(
      `Conversion to Luxon DT object failed. Explanation: ${dt.invalidExplanation}`,
    );
  }
  return dt;
}
