// Libs
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

interface Props {
  title: string;
  backButtonHref: string;
}

function BackButton({ href }: { href: string }): JSX.Element {
  return (
    <a
      href={href}
      className="my-2 mr-5 inline-flex max-w-fit items-center justify-start rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
    >
      <ArrowLeftIcon
        aria-label="go back button"
        className="mx-5 my-2 h-8 text-primary"
      />
    </a>
  );
}

export default function ReportsPageHeader({ title, backButtonHref }: Props) {
  return (
    <div className="mb-8 flex items-center">
      <BackButton href={backButtonHref} />
      <h1 className="text-3xl font-semibold">{title}</h1>
    </div>
  );
}
