import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import type { SvgIconComponent } from "@mui/icons-material";

export interface ListItemProps {
  icon: SvgIconComponent;
  heading: string;
  subheading: string;
  onClick: () => void;
  isAdmin: boolean;
}

export function UserInfoListItem(props: ListItemProps) {
  return (
    <ListItem onClick={props.onClick} disablePadding>
      <ListItemButton>
        {
          <ListItemIcon>
            <props.icon className="text-primary" />
          </ListItemIcon>
        }
        <ListItemText primary={props.heading} secondary={props.subheading} />
      </ListItemButton>
    </ListItem>
  );
}
