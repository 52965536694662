//Libs
import { DocumentData } from "firebase/firestore";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Wrapper } from "@googlemaps/react-wrapper";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local
import AddNewLocationDialog, {
  LocationState,
} from "../../components/admin/AddNewLocationDialog";
import EscapeIframeBreadcrumbs from "../../components/EscapeIframeBreadcrumbs";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { DbRead, DbWrite } from "../../database";
import {
  SiteKeyLocation,
  ExistingSiteKeyLocation,
  SiteKeyLocationManager,
} from "../../models/site-key-location";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import LocationListPage from "./LocationsListPage";
import { logger as devLogger } from "../../logging";
import SiteMap from "../../components/SiteMap";
import Marker from "../../components/admin/Marker";
import EditLocationDialog from "../../components/admin/EditLocationDialog";
import { diffObjects } from "../../assets/js/object-diff";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { BASE_APP_URL } from "../../urls";
import { StyledTooltip } from "../../components/StyledTooltip";
import { OTaskStatus } from "../../models/task-status";

interface Props {
  siteKey: string;
}

export default function LocationListContainer({ siteKey }: Props) {
  const [addLocationDialogOpen, setAddLocationDialogOpen] = useState(false);
  const [editLocationDialogOpen, setEditLocationDialogOpen] = useState(false);
  const [position, setPosition] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [zoom, setZoom] = useState<number>(10);
  const [locationDoc, setLocationDoc] =
    useState<ExistingSiteKeyLocation | null>(null);
  const [sortedLocationList, setSortedLocationList] = useState<
    ExistingSiteKeyLocation[] | null
  >(null);

  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const [fetchSiteKeyLocations, listOfLocations] = useSiteKeyLocationsStore(
    (state) => [state.fetch, state.siteKeyLocationList],
  );

  const getSortedLocationList = useSiteKeyLocationsStore(
    (state) => state.getSortedLocationList,
  );

  useEffect(() => {
    setSortedLocationList(getSortedLocationList());
  }, [getSortedLocationList, listOfLocations]);

  //Query to get the site key doc
  const { data: siteKeyDoc } = useQuery(
    "locationsListContainer_siteKeyDoc",
    () => DbRead.siteKey.get(siteKey),
  );

  useEffect(() => {
    if (siteKeyDoc && !addLocationDialogOpen) {
      setPosition({
        lat: siteKeyDoc.latitude ?? 0,
        lng: siteKeyDoc.longitude ?? 0,
      });
      setZoom(10);
    }

    if (locationDoc && editLocationDialogOpen) {
      setPosition({
        lat: locationDoc.latitude,
        lng: locationDoc.longitude,
      });
      setZoom(10);
    }
  }, [addLocationDialogOpen, editLocationDialogOpen, locationDoc, siteKeyDoc]);

  /* Mutation for add a new site key location to the db */
  const mutateAddNewLocation = useMutation(
    async (readyForDatabase: DocumentData) => {
      return await DbWrite.siteKeyLocations.adminAddNew(
        siteKey,
        readyForDatabase,
      );
    },
    {
      onSuccess: () => {
        return fetchSiteKeyLocations(siteKey);
      },
    },
  );

  /* Mutation for edit the site key location to the db  */
  const mutateEditLocation = useMutation(
    async (args: {
      locationID: string;
      editSiteKeyLocation: Record<string, any>;
    }) => {
      return await DbWrite.siteKeyLocations.adminEditLocation(
        siteKey,
        args.locationID,
        args.editSiteKeyLocation,
      );
    },
    {
      onSuccess: () => {
        return fetchSiteKeyLocations(siteKey);
      },
    },
  );

  function openEditLocationDialog(location: ExistingSiteKeyLocation) {
    setEditLocationDialogOpen(true);
    setLocationDoc(location);
  }

  async function handleEditLocation(formValues: LocationState) {
    if (locationDoc && locationDoc.id) {
      try {
        const formValuesForSiteKeyLocation: Omit<
          ExistingSiteKeyLocation,
          "id" | "refPath"
        > = {
          ...formValues,
          department: formValues.department.trim(),
          latitude: position.lat,
          longitude: position.lng,
          title: formValues.title.trim(),
        };

        const initialValuesForSiteKeyLocation: Omit<
          ExistingSiteKeyLocation,
          "id" | "refPath"
        > = {
          ...locationDoc,
        };

        const diffSiteKeyLocationValues: DocumentData = diffObjects(
          initialValuesForSiteKeyLocation,
          formValuesForSiteKeyLocation,
        ).diff;

        /* Check if there isn't any change */
        if (Object.keys(diffSiteKeyLocationValues).length === 0) {
          devLogger.debug("No values have changed");
          return;
        }

        /* Validate value from the form */
        const validateEditSiteKeyLocation = SiteKeyLocationManager.parseEdit(
          diffSiteKeyLocationValues,
        );

        await mutateEditLocation.mutateAsync({
          locationID: locationDoc.id,
          editSiteKeyLocation: validateEditSiteKeyLocation,
        });
        devLogger.debug("Location has been updated successfully");
        addMessage({
          id: createToastMessageID(),
          message: strings.successfulUpdate(formValues.title),
          dialog: false,
          type: "success",
        });
      } catch (err) {
        devLogger.error(
          "An error occurred during handleEditLocation for location id",
          locationDoc?.id,
        );
        addMessage({
          id: createToastMessageID(),
          message: strings.UNEXPECTED_ERROR,
          dialog: false,
          type: "error",
        });
      }
    }
  }

  async function handleSaveNewLocation(formValues: LocationState) {
    try {
      const output: SiteKeyLocation = {
        ...formValues,
        latitude: position.lat,
        longitude: position.lng,
      };

      /* validate values from the form */
      const readyForDatabase = SiteKeyLocationManager.parse(output);

      await mutateAddNewLocation.mutateAsync(readyForDatabase);

      //reset the position of the marker to the initial one (company lat & long)
      setPosition({
        lat: siteKeyDoc?.latitude ?? 0,
        lng: siteKeyDoc?.longitude ?? 0,
      });
      //reset the zoom of the map to the default 10;
      setZoom(10);

      devLogger.debug("Success! The new location is added!");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(formValues.title),
        dialog: false,
        type: "success",
      });
    } catch (err) {
      devLogger.error("An error occurred while adding new location to db", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  /* Breadcrumbs */
  const vueAdminPanelLink = BASE_APP_URL + "/user-info";
  const home = {
    name: strings.ADMIN,
    href: vueAdminPanelLink,
  };
  const pages = [
    {
      name: strings.LOCATIONS,
      href: null,
      current: true,
    },
  ];
  const breadcrumbs = (
    <EscapeIframeBreadcrumbs
      home={home}
      pages={pages}
      backButtonHref={vueAdminPanelLink}
    />
  );

  /* Departments List */
  const uniqueDepartmentValues = useMemo(() => {
    const departments: string[] = [];
    listOfLocations.forEach((location) => {
      departments.push(location.department);
    });
    return [...new Set(departments)].sort();
  }, [listOfLocations]);

  // Loading indicator.
  if (!siteKeyDoc && !sortedLocationList) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  /* Map */
  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;
  const locationMap = (
    <div className="h-96 overflow-hidden rounded-md">
      <Wrapper apiKey={apiKey ?? ""} libraries={["places"]}>
        <SiteMap center={position} zoom={zoom}>
          <Marker
            position={position}
            getNewPosition={(currentPosition: google.maps.LatLngLiteral) =>
              setPosition(currentPosition)
            }
            getNewZoom={(currentZoom: number) => setZoom(currentZoom)}
          />
        </SiteMap>
      </Wrapper>
    </div>
  );

  const addNewLocationButton = (
    <StyledTooltip title={`${strings.ADD_NEW_LOCATION}`}>
      <button
        type="button"
        onClick={() => setAddLocationDialogOpen(true)}
        className="text-primary"
      >
        <AddBoxIcon aria-label="Add Location" sx={{ fontSize: 45 }} />
      </button>
    </StyledTooltip>
  );

  const addOrEditLocationDialog =
    addLocationDialogOpen === true ? (
      <AddNewLocationDialog
        departments={uniqueDepartmentValues}
        invoicesEnabled={siteKeyDoc?.customizations.invoicesEnabled}
        isDialogOpen={addLocationDialogOpen}
        closeDialog={() => setAddLocationDialogOpen(false)}
        handleSave={handleSaveNewLocation}
      >
        {{
          Map: locationMap,
        }}
      </AddNewLocationDialog>
    ) : locationDoc && editLocationDialogOpen ? (
      <EditLocationDialog
        locationDoc={locationDoc}
        invoicesEnabled={siteKeyDoc?.customizations.invoicesEnabled}
        estimatesEnabled={siteKeyDoc?.customizations.estimatesEnabled}
        departments={uniqueDepartmentValues}
        isDialogOpen={editLocationDialogOpen}
        closeDialog={() => setEditLocationDialogOpen(false)}
        handleEdit={handleEditLocation}
        inRouteEnabled={
          siteKeyDoc?.validTaskStatusCodes.includes(OTaskStatus.IN_ROUTE) ??
          false
        }
      >
        {{
          Map: locationMap,
        }}
      </EditLocationDialog>
    ) : null;

  return (
    <Fragment>
      <LocationListPage
        locations={sortedLocationList}
        onEditLocation={openEditLocationDialog}
        invoicesEnabled={siteKeyDoc?.customizations.invoicesEnabled}
        addNewLocationButton={addNewLocationButton}
      >
        {{
          Breadcrumbs: breadcrumbs,
        }}
      </LocationListPage>
      {addOrEditLocationDialog}
    </Fragment>
  );
}
