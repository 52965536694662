//Libs

//Local

interface CalendarProps {
  // totalDistance: number;
  children: {
    DatePicker: React.ReactNode;
    SelectColumn: React.ReactNode;
    LocationsColumn: React.ReactNode;
    // OptimizeBtn: React.ReactNode;
    NewEventBtn: React.ReactNode;
    ZonesBtn: React.ReactNode;
    HideResourcesBtn: React.ReactNode;
    CreateTaskBtn: React.ReactNode;
    TableOrientationBtn: React.ReactNode;
    // SaveAllocateVehiclesBtn: React.ReactNode;
    SearchBox: React.ReactNode;
    Totals?: React.ReactNode;
    // AssignBtn: React.ReactNode;
    DayPilotCalendar: React.ReactNode;
  };
}

export default function SchedulingPage(props: CalendarProps) {
  return (
    <div className="flex h-full flex-col space-y-2">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center gap-2">
          {props.children.DatePicker}
          {props.children.SelectColumn}
          {props.children.LocationsColumn}
          {/*{props.children.OptimizeBtn}*/}
          <span className="mr-auto font-black"></span>
          {props.children.ZonesBtn}
          {props.children.HideResourcesBtn}
          {props.children.NewEventBtn}
          {/*{props.children.SaveAllocateVehiclesBtn}*/}
          {props.children.CreateTaskBtn}
        </div>
        <div className="flex items-center gap-2">
          {props.children.SearchBox}
          {props.children.Totals}
          {props.children.TableOrientationBtn}
          {/*{props.children.AssignBtn}*/}
        </div>
      </div>
      <div className="flex-grow">{props.children.DayPilotCalendar}</div>
    </div>
  );
}
