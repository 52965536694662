//Libs
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import {
  ComplianceItemCommon_WithoutOrderProperty,
  complianceItemTypes,
  ComplianceItemTypes,
  getReadableComplianceItemTypes,
  isComplianceItemType,
} from "../../../models/compliance-item";
import BaseModal from "../../BaseModal";
import BaseInputSelect from "../../BaseInputSelect";
import { StringComplianceItemForm } from "./StringComplianceItemForm";
import { IntegerComplianceItemForm } from "./IntegerComplianceItemForm";
import { FloatComplianceItemForm } from "./FloatComplianceItemForm";
import { SelectionComplianceItemForm } from "./SelectionComplianceItemForm";

// import ResizeObserver from 'resize-observer-polyfill'
// global.ResizeObserver = ResizeObserver

export interface ComplianceItemProps {
  isDialogOpen: boolean;
  closeDialog: () => void;
  handleSaveComplianceItem: (
    newComplianceItem: ComplianceItemCommon_WithoutOrderProperty,
  ) => void;
}

export default function NewComplianceItemDialog(props: ComplianceItemProps) {
  const [selectedFieldType, setSelectedFieldType] =
    useState<ComplianceItemTypes | null>(null);

  let SelectedForm: null | React.FunctionComponent<ComplianceItemProps> = null;
  if (selectedFieldType) {
    SelectedForm = formMap[selectedFieldType];
  }

  const newComplianceItemHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        New Attribute
      </h1>
      <button type="button" onClick={props.closeDialog}>
        <XMarkIcon
          aria-label="close new attribute form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={newComplianceItemHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="space-y-8 p-4">
        <BaseInputSelect
          text="Attribute Type"
          inputName="attributeType"
          admin={true}
          id="attributeType"
          onChange={(event) => {
            if (isComplianceItemType(event.target.value)) {
              setSelectedFieldType(event.target.value);
            }
          }}
        >
          <option value="" disabled>
            Select
          </option>
          {complianceItemTypes.map((type) => (
            <option key={type} value={type}>
              {getReadableComplianceItemTypes(type)}
            </option>
          ))}
        </BaseInputSelect>
        {SelectedForm && (
          <SelectedForm
            closeDialog={props.closeDialog}
            handleSaveComplianceItem={props.handleSaveComplianceItem}
            isDialogOpen={props.isDialogOpen}
          />
        )}
      </div>
    </BaseModal>
  );
}

const formMap: Record<
  ComplianceItemTypes,
  React.FunctionComponent<ComplianceItemProps>
> = {
  string: StringComplianceItemForm,
  integer: IntegerComplianceItemForm,
  float: FloatComplianceItemForm,
  selection: SelectionComplianceItemForm,
};
