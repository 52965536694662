//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { guardIsPlainObject } from "../utils";
import { ExistingEstimate } from "./estimate";
import { DbRead } from "../database";

export interface EstimateItem {
  // Original fields snapshotted from the PriceBookItem
  priceBookItemID: string;
  title: string;
  description: string | null;
  units: string;
  unitPrice: number; //float
  cost: number | null; //float
  locationID: string;
  discountableForMemberships: boolean;
  type: string | null;
  tags: string[];
  customData: { [key: string]: any };
  taxable: boolean;
  discountable: boolean;
  editable: boolean;
  discount: number | null;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;

  // New Fields
  estimateID: string;
  quantity: number; //float
  deleted: boolean;
}

export interface ExistingEstimateItem extends EstimateItem {
  id: string;
  refPath: string;
}

export interface TemporaryEstimateItem
  extends Omit<
    EstimateItem,
    | "timestampCreated"
    | "timestampLastModified"
    | "createdBy"
    | "lastModifiedBy"
    | "estimateID"
  > {
  id?: string;
  refPath?: string;
  toBeEdited?: boolean;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  EstimateItem,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface EstimateItem_CreateAPI extends withoutTimestamps {
  siteKey: string;
  id?: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type EstimateItem_UpdateAPI = Omit<
  Partial<ExistingEstimateItem>,
  "timestampCreated" | "timestampLastModified"
> & { id: string };

// #region SECTION: Functions
/** Utilities for interacting with EstimateItem objects  */
export const EstimateItemManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingEstimateItem object.
   */
  createFromFirestoreSnapshot: createEstimateItemFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateEstimateItem,
  /** Validate a EstimateItem doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateEstimateItemWithFallbacks,
  /** Validate a new estimate item. For the create endpoint. */
  parseCreate: validateEstimateItem_Create,
  /** Validate an existing estimate item. For the update endpoint. */
  parseUpdate: validateEstimateItem_Update,
};

/**
 * Convert the Document Snapshot into a validated ExistingPriceBookItem object.
 */
function createEstimateItemFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingEstimateItem {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateEstimateItemWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateEstimateItemWithFallbacks(value: unknown): EstimateItem {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = estimateItemSchemaWithFallbacks.parse(value);
  return result;
}

function validateEstimateItem(value: unknown): EstimateItem {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = refinedEstimateItemSchema.parse(value);
  return result;
}

function validateEstimateItem_Create(value: unknown): EstimateItem_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return estimateItemSchema_CreateAPI.parse(value);
}

function validateEstimateItem_Update(value: unknown): EstimateItem_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return estimateItemSchema_UpdateAPI.parse(value);
}
// #endregion

// #region SECTION: Schemas
// Used when validating data coming from the database.
const estimateItemSchemaWithFallbacks = z.object({
  priceBookItemID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateItemSchemaWithFallbacks: 'priceBookItemID'",
  ),
  title: zFallback(
    z.string().min(1).max(2000),
    "unknown",
    "estimateItemSchemaWithFallbacks: 'title'",
  ),
  description: zFallback(
    z.string().max(4000).nullable(),
    null,
    "estimateItemSchemaWithFallbacks: 'description'",
  ),
  units: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateItemSchemaWithFallbacks: 'units'",
  ),
  unitPrice: z.number(), //no fallback because the price must be present
  cost: zFallback(
    z.number().nullable(),
    null,
    "priceBookItemSchemaWithFallbacks: 'cost'",
  ),
  locationID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "priceBookItemSchemaWithFallbacks: 'locationID'",
  ),
  discountableForMemberships: zFallback(
    z.boolean(),
    false,
    "priceBookItemSchemaWithFallbacks: 'discountableForMemberships'",
  ),
  type: zFallback(
    z.string().min(0).max(200).nullable(),
    null,
    "estimateItemSchemaWithFallbacks: 'type'",
  ),
  tags: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "estimateItemSchemaWithFallbacks: 'tags'",
  ),
  customData: zFallback(
    z.record(z.any()),
    {},
    "estimateItemSchemaWithFallbacks: 'customData'",
  ),
  taxable: zFallback(
    z.boolean(),
    false,
    "estimateItemSchemaWithFallbacks: 'taxable'",
  ),
  editable: zFallback(
    z.boolean(),
    false,
    "estimateItemSchemaWithFallbacks: 'editable'",
  ),
  discountable: zFallback(
    z.boolean(),
    false,
    "estimateItemSchemaWithFallbacks: 'discountable'",
  ),
  discount: zFallback(
    z.number().nullable(),
    null,
    "estimateSchemaWithFallbacks: 'discount'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "estimateItemSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "estimateItemSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateItemSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateItemSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  estimateID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateItemSchemaWithFallbacks: 'estimateID'",
  ),
  quantity: zFallback(
    z.number(),
    0,
    "estimateItemSchemaWithFallbacks: 'quantity'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "estimateItemSchemaWithFallbacks: 'deleted'",
  ),
});

// Used when writing to the DB or reading from the user.
const estimateItemSchema = z.object({
  priceBookItemID: z.string().min(1).max(200),
  title: z.string().min(1).max(2000),
  description: z.string().max(4000).nullable(),
  units: z.string().min(1).max(200),
  unitPrice: z.number(),
  cost: z.number().nullable(),
  locationID: z.string().min(1).max(200),
  discountableForMemberships: z.boolean(),
  type: z.string().min(0).max(200).nullable(),
  tags: z.array(z.string().min(1).max(200)),
  customData: z.record(z.any()),
  taxable: z.boolean(),
  editable: z.boolean(),
  discountable: z.boolean(),
  discount: z.number().nullable(),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  estimateID: z.string().min(1).max(200),
  quantity: z.number(),
  deleted: z.boolean(),
});

/* needed to divide the refine from the base schema, because omit 
doesn't exists on schemas with refine/superRefine */
const refinedEstimateItemSchema = estimateItemSchema.refine(
  (estimate) => {
    if (estimate.discountable === false) {
      return estimate.discount === null;
    } else {
      return true;
    }
  },
  { message: "Cannot add a discount if the items isn't discountable" },
);

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = estimateItemSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const estimateItemSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const estimateItemSchema_UpdateAPI = withoutTimestampsSchema.partial().extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
});

export const existingEstimateItemSchema = estimateItemSchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
});

export async function getEstimateItemList(
  estimateList: ExistingEstimate[],
  siteKey: string,
): Promise<ExistingEstimateItem[]> {
  const estimateItemList: ExistingEstimateItem[] = [];

  const readPromises = estimateList.map(async (estimate) => {
    const estimateItemDocs = await DbRead.estimateItems.getByEstimateId(
      siteKey,
      estimate.id,
    );
    estimateItemList.push(...estimateItemDocs);
  });
  await Promise.all(readPromises);
  return estimateItemList;
}
