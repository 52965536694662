//Libs
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Toast from "./Toast";
import { useToastMessageStore } from "../store/toast-messages";

function displayDefaultBaseModal(
  title: string,
  closeModal: () => void,
): JSX.Element {
  return (
    <div className="mb-4 flex items-center justify-between ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {title}
      </h1>
      <button onClick={closeModal}>
        <XMarkIcon aria-label="close modal" className="h-6 text-gray-500" />
      </button>
    </div>
  );
}

export default function BaseModal(props: {
  open: boolean;
  title: React.ReactElement | string;
  parentDivStyles?: string;
  /** if your dialog contains stuff that might overflow (i.e. a dropdown with an
   * unknown number of options), set this to true. */
  allowOverflowY?: boolean;
  closeModal: () => void;
  children: React.ReactNode;
}): JSX.Element {
  const { parentDivStyles = "p-6 py-8 max-w-md text-left" } = props;
  const toastMessageList = useToastMessageStore((state) => state.messagesList);

  const filterToastMessageList = toastMessageList.filter(
    (message) => message.dialog === true,
  );

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        onClose={props.closeModal}
        className="fixed inset-0 z-50 overflow-y-auto "
      >
        <div className="min-h-screen px-1 text-center xxs:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm" />
          </Transition.Child>
          <Toast toastList={filterToastMessageList} dialog={true} />
          <span
            className="inline-block h-screen align-middle "
            aria-hidden="true"
          ></span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`${
                props.allowOverflowY ? "overflow-y-auto" : "overflow-hidden"
              } inline-block w-full transform rounded-2xl border-2 border-gray-300 bg-white align-middle shadow-xl transition-all ${parentDivStyles} `}
            >
              <Dialog.Title>
                {typeof props.title === "string"
                  ? displayDefaultBaseModal(props.title, props.closeModal)
                  : props.title}
              </Dialog.Title>
              {props.children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
