import { DocumentSnapshot, Timestamp, DocumentData } from "firebase/firestore";
import { z } from "zod";
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";

// siteKeys/{siteKeyID}/photos/{photoID}

// #region SECTION: Schemas / Interfaces
export interface StiltPhoto {
  photoURL: string;
  photoURL_reduced: string;
  photoURL_thumb: string;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
  deleted: boolean;
  notes?: string;
  parentRecordID?: string;
  taskID?: string;
  assetID?: string;
  checklistResponseID?: string;
  customerID?: string;
  customerLocationID?: string;
  estimateID?: string;
  estimateItemID?: string;
}

export interface ExistingStiltPhoto extends StiltPhoto {
  id: string;
  refPath: string;
}

/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
const StiltPhotoSchema = z.object({
  photoURL: z.string().min(0).max(2000),
  photoURL_reduced: z.string().min(0).max(2000),
  photoURL_thumb: z.string().min(0).max(2000),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(0).max(200),
  lastModifiedBy: z.string().min(0).max(500),
  deleted: z.boolean(),
  notes: z.string().min(0).max(500).optional(),
  parentRecordID: z.string().min(0).max(500).optional(),
  taskID: z.string().min(0).max(500).optional(),
  assetID: z.string().min(0).max(500).optional(),
  checklistResponseID: z.string().min(0).max(500).optional(),
  customerID: z.string().min(0).max(500).optional(),
  customerLocationID: z.string().min(0).max(500).optional(),
  estimateID: z.string().min(0).max(500).optional(),
  estimateItemID: z.string().min(0).max(500).optional(),
});

/** For reading from the database */
const StiltPhotoSchemaWithFallbacks = z.object({
  photoURL: zFallback(
    z.string().url().min(1).max(2000),
    "",
    "StiltPhotoSchemaWithFallbacks: `photoURL`",
  ),
  photoURL_reduced: zFallback(
    z.string().url().min(1).max(2000),
    "",
    "StiltPhotoSchemaWithFallbacks: `photoURL_reduced`",
  ),
  photoURL_thumb: zFallback(
    z.string().url().min(1).max(2000),
    "",
    "StiltPhotoSchemaWithFallbacks: `photoURL_thumb`",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "StiltPhotoSchemaWithFallbacks: `timestampCreated`",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "StiltPhotoSchemaWithFallbacks: `timestampLastModified`",
  ),
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "StiltPhotoSchemaWithFallbacks: `createdBy`",
  ),
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "StiltPhotoSchemaWithFallbacks: `lastModifiedBy`",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "StiltPhotoSchemaWithFallbacks: `deleted`",
  ),
  notes: zFallback(
    z.string().min(0).max(500).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `notes`",
  ),
  parentRecordID: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `parentRecordID`",
  ),
  taskID: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `taskID`",
  ),
  assetID: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `assetID`",
  ),
  checklistResponseID: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `checklistResponseID`",
  ),
  customerID: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `customerID`",
  ),
  customerLocationID: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `customerLocationID`",
  ),
  estimateID: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `estimateID`",
  ),
  estimateItemID: zFallback(
    z.string().min(0).max(200).optional(),
    undefined,
    "StiltPhotoSchemaWithFallbacks: `estimateItemID`",
  ),
});

export const existingStiltPhotoSchema = StiltPhotoSchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
});

export const existingStiltPhotoSchemaWithFallbacks =
  StiltPhotoSchemaWithFallbacks.extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  });
// #endregion

// #region SECTION: Functions
/**
 * Collection of functions that interact with Stilt Photo Documents
 */
export const StiltPhotoManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingStiltPhoto object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createStiltPhotoFromSnapshot,
  /** Drop `id` and `refPath` before saving to the database. Drop undefined values. */
  convertForFirestore: convertStiltPhotoForFirestore,
  /** Validate a StiltPhoto doc. Use for writing to the database or reading from the user. */
  parse: validateStiltPhoto,
  /** Validate a StiltPhoto doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateStiltPhotoWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingStiltPhoto object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createStiltPhotoFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingStiltPhoto {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateStiltPhotoWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values. */
function convertStiltPhotoForFirestore(
  record: ExistingStiltPhoto,
): DocumentData {
  const local = Object.assign({}, record);
  const { id, refPath, ...rest } = local;
  return dropUndefined(rest);
}

/** Rejects if it doesn't pass validation. */
function validateStiltPhoto(photoDoc: unknown): StiltPhoto {
  if (!guardIsPlainObject(photoDoc)) {
    throw new Error(`StiltPhoto is not an object: ${photoDoc}`);
  }
  return StiltPhotoSchema.parse(photoDoc);
}

/** For validating stuff coming in from the database. */
function validateStiltPhotoWithFallbacks(photoDoc: unknown): StiltPhoto {
  if (!guardIsPlainObject(photoDoc)) {
    throw new Error(`StiltPhoto is not an object: ${photoDoc}`);
  }
  return StiltPhotoSchemaWithFallbacks.parse(photoDoc);
}

// #endregion
