//Libs
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import BaseButtonPrimary from "../../BaseButtonPrimary";
import BaseButtonSecondary from "../../BaseButtonSecondary";
import BaseInputText from "../../BaseInputText";
import StyledMessage from "../../StyledMessage";
import StyledSwitchGroup from "../../StyledSwitchGroup";
import * as strings from "../../../strings";
import {
  ComplianceItemManager,
  SelectionComplianceItem,
} from "../../../models/compliance-item";
import InputTextWithButton from "../../InputTextWithButton ";
import BaseInputCheckbox from "../../BaseInputCheckbox";
import FormLabel from "../../FormLabel";
import { logger as devLogger } from "../../../logging";
import { ErrorMessage } from "../../ErrorMessage";
import { ComplianceItemProps } from "./NewComplianceItemDialog";

const selectionSchema = z.object({
  text: z.string().min(1, { message: "Required" }).max(2000),
  required: z.boolean(),
  units: z.string().max(200),
  responseType: z.literal("selection"),
  passingMax: z.null(),
  passingMin: z.null(),
  addOptions: z.string().nullable(),
  passingOptions: z.string().array(),
});

const selectionComplianceDefaultValues: SelectionItemInterface = {
  text: "",
  required: false,
  units: "",
  responseType: "selection",
  passingMin: null,
  passingMax: null,
  addOptions: null,
  passingOptions: [],
};

type SelectionItemInterface = z.infer<typeof selectionSchema>;

export const SelectionComplianceItemForm: React.FunctionComponent<
  ComplianceItemProps
> = (props) => {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const [formSelectionOptions, setFormSelectionOptions] = useState<
    Record<string, boolean>
  >({});
  const passingOptions =
    getPassingOptionsFromFormSelectionOptions(formSelectionOptions);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<SelectionItemInterface>({
    defaultValues: selectionComplianceDefaultValues,
    resolver: zodResolver(selectionSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(selectionComplianceDefaultValues);
  }, [reset]);

  const onSubmit: SubmitHandler<SelectionItemInterface> = async (
    formValues,
  ) => {
    const values: Omit<SelectionComplianceItem, "order"> = {
      text: formValues.text,
      required: formValues.required,
      units: formValues.units,
      responseType: "selection",
      passingMin: null,
      passingMax: null,
      selectionOptions: Object.keys(formSelectionOptions),
      passingOptions: passingOptions,
    };

    // Validate before write to DB
    const validatedValues = ComplianceItemManager.parsePartial(values);

    try {
      props.handleSaveComplianceItem(validatedValues);
      // Close the section if successful.
      onCloseComplianceItem();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  /* fn that handle all the states that needs to be reset when the section is closed */
  function onCloseComplianceItem() {
    props.closeDialog();
    reset();
  }

  const errorMessage = (
    <ErrorMessage
      message="Something went wrong."
      clearMessage={() => setDisplayError(false)}
    />
  );
  /**
   * Convert the formSelectionOptions state object into
   * an array of strings for only the passing options. Values
   * that were true.
   */
  function getPassingOptionsFromFormSelectionOptions(
    options: Record<string, boolean>,
  ): string[] {
    const passingOptions: string[] = [];

    Object.keys(options).forEach((optionName) => {
      if (options[optionName] === true) {
        passingOptions.push(optionName);
      }
    });

    return passingOptions;
  }

  //this function it's called when clicking on the X of the passingOptions that we want to delete
  function deleteOption(optionToRemove: string) {
    const convertToArray = Object.entries(formSelectionOptions);
    //convertToArray = ['key', 'value']
    const newFormSelectionOptions = convertToArray.filter(
      ([option]) => option !== optionToRemove,
    );
    setFormSelectionOptions(Object.fromEntries(newFormSelectionOptions));
  }

  function handleTempPassingOptionsCheckbox(property: string) {
    setFormSelectionOptions((prevValues) => ({
      ...prevValues,
      [property]: !prevValues[property],
    }));
  }

  return (
    <div className="flex flex-col text-lg">
      <form
        autoComplete="off"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        className="space-y-8"
      >
        {/* Field: Text */}
        <div>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Text"
                inputName="text"
                admin={true}
                required={true}
                {...field}
              />
            )}
          />
          {errors.text?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.text.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Field: Required */}
        <div>
          <Controller
            name="required"
            control={control}
            render={({ field }) => (
              <StyledSwitchGroup
                readableName="Required"
                onBlur={field.onBlur}
                onChange={field.onChange}
                ref={field.ref}
                checked={field.value}
                id="required"
                name={field.name}
              />
            )}
          />
        </div>

        {/* Field: Units */}
        <div>
          <Controller
            name="units"
            control={control}
            render={({ field }) => (
              <BaseInputText
                text="Units"
                inputName="units"
                admin={true}
                required={false}
                {...field}
              />
            )}
          />
          {errors.units?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.units.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* Field: Selection Options */}
        <div>
          <FormLabel
            htmlFor="addOptions"
            label="Add options *"
            removePadding={true}
            className="flex-grow pb-2"
          />
          <Controller
            name="addOptions"
            control={control}
            render={({ field }) => (
              <InputTextWithButton
                id="addOptions"
                placeholder="Insert your option and click the button"
                onButtonClick={(optionString: string) => {
                  setFormSelectionOptions((oldState) => {
                    const newState = { ...oldState };
                    newState[optionString] = false;
                    return newState;
                  });
                }}
                {...field}
                value={field.value != null ? field.value : ""}
              />
            )}
          />
          {errors.addOptions?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.addOptions.message }}
              </StyledMessage>
            </div>
          )}

          {/* Passing Options Select */}
          {Object.keys(formSelectionOptions).length !== 0 ? (
            <div className="flex items-center py-4">
              <span className="flex-1 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Options
              </span>
              <span className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Considered Passing?
              </span>
            </div>
          ) : null}

          {/* Create list of checkboxes for each selection option */}
          <div id="checkbox-group"></div>
          <div
            className="grid grid-cols-1 py-2"
            role="group"
            aria-labelledby="checkbox-group"
          >
            {Object.keys(formSelectionOptions).map((name, key) => {
              return (
                <div className="flex flex-row items-center py-2" key={key}>
                  <button
                    type="button"
                    className="flex-none"
                    onClick={() => deleteOption(name)}
                  >
                    <XMarkIcon
                      aria-label="delete option"
                      className="mr-2 h-5"
                    />
                  </button>
                  <FormLabel
                    htmlFor={name}
                    label={name}
                    removePadding={true}
                    className="flex-grow pr-2"
                  />
                  <BaseInputCheckbox
                    name="passingOptions"
                    responseStyle
                    id={name}
                    label=""
                    value={name}
                    checked={formSelectionOptions[name]}
                    onChange={() => {
                      handleTempPassingOptionsCheckbox(name);
                    }}
                  />
                </div>
              );
            })}

            {/* Show error messages based on custom logic.  */}
            {Object.keys(formSelectionOptions).length !== 0 &&
              passingOptions.length === 0 && (
                <div className="mt-2 text-xs text-red-400">
                  At least one option should be marked as passing.
                </div>
              )}
          </div>
        </div>

        <span className="-my-5 h-10 justify-self-center sm:col-span-2 lg:col-span-3">
          {displayError ? errorMessage : null}
        </span>

        {/* Action Buttons */}
        <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={onCloseComplianceItem}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting || passingOptions.length === 0}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
            data-testid="save item"
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </form>
    </div>
  );
};
