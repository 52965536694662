//Libs
import { Dispatch, Fragment, useState, useRef } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { z } from "zod";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

//Local

interface Props {
  sendEmailButton: React.ReactNode;
  includeJobPhotosSwitch?: React.ReactNode;
  emailList: string[];
  selectedEmailList: string[];
  setSelectedEmailList: Dispatch<React.SetStateAction<string[]>>;
  setErrorString: Dispatch<React.SetStateAction<string | null>>;
}

export default function MultipleEmailSelector({
  sendEmailButton,
  emailList,
  selectedEmailList,
  includeJobPhotosSwitch,
  setSelectedEmailList,
  setErrorString,
}: Props) {
  const [query, setQuery] = useState("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const filteredEmailList =
    query === ""
      ? emailList
      : emailList.filter((email) =>
          email
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  function handleUnselectEmail(emailToBeDeleted: string) {
    const filteredList = selectedEmailList.filter(
      (selectedEmail) => selectedEmail !== emailToBeDeleted,
    );
    setSelectedEmailList(filteredList);
  }

  function isValid(email: string) {
    if (!isEmail(email)) {
      setErrorString(`${email} is not a valid email address.`);
      return false;
    }
    if (isInList(email)) {
      setErrorString(`${email} has already been added.`);
      return false;
    }
    return true;
  }

  function isEmail(email: string): boolean {
    const safeParseResult = z.string().email().safeParse(email);

    return safeParseResult.success;
  }

  function isInList(email: string) {
    return selectedEmailList.includes(email);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();

      const emailArray = (event.target as HTMLInputElement).value.split(",");
      if (emailArray) {
        const selectedEmailListCopy = [...selectedEmailList];

        for (const email of emailArray) {
          const value = email.trim();
          if (value === "") continue;
          if (isValid(value)) selectedEmailListCopy.push(value);
        }
        setSelectedEmailList(selectedEmailListCopy);
        setQuery("");
      }
    }
  }

  return (
    <div className={`flex w-full flex-col gap-4`}>
      {/* input */}
      <div className="flex flex-col-reverse items-end justify-end gap-1 xs:flex-row xs:items-center xs:gap-4">
        {/* show list */}
        {selectedEmailList.length !== 0 && (
          <div className="flex flex-wrap justify-end">
            {selectedEmailList.map((email) => {
              return (
                <div
                  key={email}
                  className="m-1 flex w-fit justify-between gap-2 rounded-md bg-primary px-2.5 py-1 text-sm font-medium text-primaryButtonText"
                >
                  {email}
                  <button onClick={() => handleUnselectEmail(email)}>x</button>
                </div>
              );
            })}
          </div>
        )}
        <span className="flex-shrink-0">{sendEmailButton}</span>
      </div>
      <div className="flex flex-col items-end gap-2 md:flex-row md:items-center md:justify-end md:gap-4">
        {includeJobPhotosSwitch}

        <Combobox
          value={selectedEmailList}
          onChange={(value) => setSelectedEmailList(value)}
          multiple
        >
          <div className="relative mt-1 w-full max-w-[500px] flex-grow">
            <div className="relative">
              <Combobox.Input
                className={`block w-full max-w-[500px] rounded border border-black p-4 text-sm text-gray-700 outline-none focus:border-primaryLight focus:ring focus:ring-primaryLight`}
                onChange={(event) => setQuery(event.target.value)}
                onKeyDown={(event) => {
                  handleKeyDown(event);
                }}
                value={query}
                onClick={() => setQuery("")}
                autoComplete="off"
                id="emailSelector"
              />
              <label
                htmlFor="emailSelector"
                className="absolute -top-3 left-3 bg-white px-2"
              >
                Add Email Address
              </label>
              <Combobox.Button
                className="absolute inset-y-0 right-0 flex items-center pr-2"
                ref={buttonRef}
              >
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              // afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute isolate z-[100] mt-1 max-h-60 w-full max-w-[500px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filteredEmailList.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredEmailList.map((email) => (
                    <Combobox.Option
                      key={email}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-gray-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={email}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {email}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-gray-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
}
