// Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

// Local
import {
  guardIsPlainObject,
  isValidISO8601,
  msgInvalidISO8601,
} from "../utils";
import { NotFoundError } from "../error-classes";

// #region SECTION: Interfaces & types
export interface Feedback {
  customerID: string; // FK
  taskID: string; // FK
  craftRecordID: string; // FK
  /** Ideally, this is not null */
  customerEmail: string | null;
  countSentToCustomer: number;
  customerPleased: boolean | null;
  socialsTapped: Record<string, Timestamp>; // {"yelp": Timestamp, "google": Timestamp}
  feedbackData: Record<string, number | string | null>; // dynamic. example: {"rateOurService": 5, "rateOurTechnician": 1, "additionalComments": "He kicked my dog"}

  timestampLastSent: Timestamp | null;
  timestampCustomerOpened: Timestamp | null;

  // Usual fields
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
}

interface FirestoreAttributes {
  id: string;
  refPath: string;
}

export type ExistingFeedback = Feedback & FirestoreAttributes;

/**
 * Limited subset of data for an unauthenticated route (/review-request)
 */
export interface FeedbackFormData {
  feedbackID: string;
  siteKeyID: string;
  /** @example { yelp: "https://yelp.com/urlFromSiteKeyDoc" } */
  reviewLinks: Record<string, string>;
  merchantName: string;
  merchantLogoURL: string | null;
  /**
   * ISO Datetime string.
   *
   * Shouldn't be null, but that's not out of the realm of possibilities.
   */
  datetimeCompleted: string | null;
}

// *********************
// FOR THE API
// *********************

/**
 * Contains only the Feedback fields that are allowed to be updated. This interface
 * is to be used for updating a feedback doc when sending data to the server via
 * CF - therefore it uses ISO datetime strings instead of Timestamps.
 */
export interface FeedbackUpdate {
  // customerEmail: string | null;
  countSentToCustomer: number;
  customerPleased: boolean;
  /** ISO datetime string */
  socialsTapped: Record<string, string>;
  feedbackData: Record<string, number | string | null>;

  /** ISO datetime string */
  timestampLastSent: string | null;
  /** ISO datetime string */
  timestampCustomerOpened: string | null;
  /** ISO datetime string */
  timestampLastModified: string;
}
// #endregion Interfaces & types

// #region SECTION: Functions
/** Utilities for interacting with Feedback objects. */
export const FeedbackManager = {
  parse: validateFeedbackDoc,
  parseFormData: validateFeedbackFormData,
  parseUpdate: validateFeedbackDoc_Update,
  createFromSnapshot,
};

/** Throws if `value` is not an object */
function validateFeedbackDoc(value: unknown): Feedback {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return feedbackSchema.parse(value);
}

/** Throws if `value` is not an object */
function validateFeedbackDoc_Update(value: unknown): Partial<FeedbackUpdate> {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return updateSchema.parse(value);
}

/** Throws if `value` is not an object */
function validateFeedbackFormData(value: unknown): FeedbackFormData {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return formSchema.parse(value);
}

/** @throws NotFoundError if doc does not exist */
function createFromSnapshot(snapshot: DocumentSnapshot): ExistingFeedback {
  if (!snapshot.exists) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateFeedbackDoc(snapshot.data()),
  };
}
// #endregion Functions

// #region SECTION: Zod schemas
/** no timestamps */
const commonSchema = z.object({
  customerID: z.string().min(1).max(200),
  taskID: z.string().min(1).max(200),
  craftRecordID: z.string().min(1).max(200),
  customerEmail: z.string().min(1).max(200).nullable(),
  countSentToCustomer: z.number(),
  feedbackData: z.record(z.string().or(z.number()).or(z.null())),
});

/** used when validating data coming out of the database */
const feedbackSchema = commonSchema.extend({
  customerPleased: z.boolean().nullable(),
  socialsTapped: z.record(z.instanceof(Timestamp)),
  timestampLastSent: z.instanceof(Timestamp).nullable(),
  timestampCustomerOpened: z.instanceof(Timestamp).nullable(),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
});

/**
 * used when validating data that's going to the server via CF. expects only
 * the values that are on a Feedback doc (i.e. no siteKeyID)
 */
const updateSchema = commonSchema
  .extend({
    customerPleased: z.boolean(),
    socialsTapped: z.record(
      z.string().refine(isValidISO8601, msgInvalidISO8601),
    ),
    timestampLastSent: z
      .string()
      .refine(isValidISO8601, msgInvalidISO8601)
      .nullable(),
    timestampCustomerOpened: z
      .string()
      .refine(isValidISO8601, msgInvalidISO8601)
      .nullable(),
    // timestampCreated: z.string().refine(isValidISO8601, msgInvalidISO8601),
    timestampLastModified: z.string().refine(isValidISO8601, msgInvalidISO8601),
  })
  .partial();

const formSchema = z.object({
  feedbackID: z.string().min(1).max(200),
  siteKeyID: z.string().min(1).max(200),
  reviewLinks: z.record(z.string()),
  merchantName: z.string().min(1).max(400),
  merchantLogoURL: z.string().min(1).max(800).nullable(),
  datetimeCompleted: z
    .string()
    .refine(isValidISO8601, msgInvalidISO8601)
    .nullable(),
});
// #endregion Zod schemas
