//Libs
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GetQuickFilterTextParams,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import isEqual from "lodash/isEqual";

//Local
import { ExistingStiltInvoice } from "../../models/invoice";
import * as strings from "../../strings";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import { StyledTooltip } from "../../components/StyledTooltip";
import { PersonIconWithRef } from "../../components/PersonViewButton";
import SearchBox from "../../components/SearchBox";
import { PlusIconWithRef } from "../../components/PlusAddButton";
import { useNavToCreateTask } from "../../navigation";
import { ExistingAsset } from "../../models/asset";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface Props {
  /* DATA */
  assets: ExistingAsset[];
  // dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  /* FUNCTIONS */
  goToCustomerPage: (customerID: ExistingStiltInvoice["customerID"]) => void;
  goToWorkRecordAndTasksPage: (
    craftRecordID: ExistingStiltInvoice["craftRecordID"],
  ) => void;
  // emailReceipt: (invoiceID: ExistingStiltInvoice["id"]) => Promise<void>;
  handleOpenViewAssetDetailsDialog: (asset: ExistingAsset) => void;
  CreateAssetEquipmentButton: React.ReactNode;
  /* CHILDREN */
  children: {
    // DropdownSelectionInvoiceList: React.ReactNode;
    // InvoiceDetailsBox: React.ReactNode;
    // InvoiceDateRangePicker: React.ReactNode;
  };
}

export default function AssetsEquipmentPage(props: Props) {
  const navToCreateTask = useNavToCreateTask();

  const renderTimestamp = (params: ICellRendererParams): JSX.Element => {
    if (!params.value) {
      return <div></div>;
    }
    return <div>{convertToReadableTimestampDate(params.value)}</div>;
  };

  const renderText = (params: ICellRendererParams): JSX.Element => {
    if (!params.value) {
      return <div></div>;
    }
    return <div>{params.value ?? ""}</div>;
  };

  const renderIconCell = useCallback(
    function (params: ICellRendererParams) {
      return (
        <div className="flex items-center">
          <StyledTooltip title="Create Task">
            <PlusIconWithRef
              ref={(ref) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation();
                  navToCreateTask(
                    null,
                    params.data.customerID,
                    params.data.customerLocationID,
                    undefined,
                    undefined,
                  );
                };
              }}
            />
          </StyledTooltip>
          <StyledTooltip title="View Customer">
            <PersonIconWithRef
              ref={(ref) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation();
                  props.goToCustomerPage(params.data.customerID);
                };
              }}
            />
          </StyledTooltip>
        </div>
      );
    },
    [props],
  );

  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value,
    );
  }, []);

  return (
    <div className="space-y-8">
      <h1 className="text-5xl font-semibold text-primary">
        {strings.ASSETS_EQUIPMENT}
      </h1>
      {/*{props.children.InvoiceDetailsBox}*/}
      <div className={"ag-theme-alpine isolate h-[600px] pb-14"}>
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full sm:max-w-sm"
          />
          <div className="flex flex-col gap-4">
            <div>{props.CreateAssetEquipmentButton}</div>
          </div>
          {/*{props.children.InvoiceDateRangePicker}*/}
          {/*<div>{props.children.DropdownSelectionInvoiceList}</div>*/}
        </div>
        <AssetsTable
          assets={props.assets}
          // dateRange={props.dateRange}
          renderTimestamp={renderTimestamp}
          renderText={renderText}
          renderIconCell={renderIconCell}
          handleOpenViewAssetDetailsDialog={
            props.handleOpenViewAssetDetailsDialog
          }
          gridRef={gridRef}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}

/* Stuff for Table Component */
const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface AssetsTableProps {
  assets: Props["assets"];
  // dateRange: Props["dateRange"];
  renderTimestamp: (params: ICellRendererParams) => JSX.Element;
  renderText: (params: ICellRendererParams) => JSX.Element;
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  handleOpenViewAssetDetailsDialog: (asset: ExistingAsset) => void;
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
}

/* TABLE COMPONENT */
const AssetsTable = React.memo(
  ({
    assets,
    // dateRange,
    renderTimestamp,
    renderText,
    renderIconCell,
    handleOpenViewAssetDetailsDialog,
    gridRef,
    onGridReady,
    ...props
  }: AssetsTableProps) => {
    const columnDefs: ColDef[] = [
      {
        headerName: "Title",
        field: "title",
        width: 200,
        cellRenderer: renderText,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.value;
        },
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        cellRenderer: renderText,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      // {
      //   field: "tasksCompleted",
      //   headerName: "Tasks Completed",
      //   width: 200,
      //   cellRenderer: renderTasksCompleted,
      //   tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      //   onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      // },
      {
        headerName: "Manufacturer",
        field: "manufacturer",
        cellRenderer: renderText,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        headerName: "Equipment Type",
        field: "equipmentType",
        cellRenderer: renderText,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        headerName: "Serial Number",
        field: "serialNumber",
        cellRenderer: renderText,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        headerName: "Model Number",
        field: "modelNumber",
        cellRenderer: renderText,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        headerName: "Installation Date",
        field: "installationDate",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        headerName: "Mfg Warranty Start Date",
        field: "manufacturerWarrantyStart",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        headerName: "Mfg Warranty End Date",
        field: "manufacturerWarrantyEnd",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        headerName: "Service Warranty Start Date",
        field: "serviceWarrantyStart",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        headerName: "Service Warranty End Date",
        field: "serviceWarrantyEnd",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) => handleOpenViewAssetDetailsDialog(ev.data),
      },
      {
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        minWidth: 250,
        flex: 0,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
    ];

    assets.sort((a, b) => {
      if (!a.installationDate) {
        return 1;
      }
      if (!b.installationDate) {
        return -1;
      }
      return a.installationDate.toMillis() - b.installationDate.toMillis();
    });

    return (
      <AgGridReact
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="-z-10 mt-5 shadow"
        rowData={assets}
        animateRows={true}
        rowSelection="single"
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
      />
    );
  },
  (previous, next) => {
    const isAssetListTheSame = isEqual(previous.assets, next.assets);

    return isAssetListTheSame;
  },
);
