//Local
import { DbWrite } from "../../database";
import { logger } from "../../logging";
import { isWhiteLabel } from "../../white-label-check";
import { whiteLabel } from "../../urls";
import { ExistingStiltInvoice } from "../../models/invoice";
import {
  CreatePaymentUniqueLink,
  CreateUniqueLinkManager,
  Platform,
} from "../../models/unique-link";

/** Get a unique link for paying the given invoice via HPP. Contains try/catch. */
export async function generatePaymentUniqueLink(
  siteKey: string,
  invoiceID: ExistingStiltInvoice["id"],
  platform: Platform,
): Promise<string | undefined> {
  if (!isWhiteLabel(whiteLabel)) {
    logger.error(`Unexpected white label: ${whiteLabel}`);
    return undefined;
  }

  const data: CreatePaymentUniqueLink = {
    siteKey: siteKey,
    invoiceDocumentID: invoiceID,
    email: null,
    linkExpires: null,
    phone: null,
    whiteLabelURL: null,
    platform: platform,
    version: whiteLabel,
  };

  try {
    const valid = CreateUniqueLinkManager.parsePayment(data);
    const paymentLink = await DbWrite.invoices.generatePaymentLink(valid);
    return paymentLink;
  } catch (error) {
    logger.error(`generatePaymentUniqueLink`, error);
    return undefined;
  }
}
