import currencyFormatter from "../../currency";
import {
  UnauthedPaymentMade,
  paymentMethodsMap,
} from "../../models/stilt-payment";
import {
  convertISOToFSTimestamp,
  convertFSTimestampToLuxonDT,
} from "../../utils";

/**
 * This function is used to retrieve the payment line text with no formatting applied.
 * It is used when sending an invoice/receipt to the end-customer via email/PDF.
 *
 * No memo or tracking data will be returned by this function.
 */
export default function getPaymentLineText({
  payment,
  currencyType,
  timezone,
}: {
  payment: UnauthedPaymentMade;
  currencyType: string;
  timezone: string;
}): string {
  const ts = convertISOToFSTimestamp(payment.timestampPaymentMade);
  const lux = convertFSTimestampToLuxonDT(ts);
  const formattedDate = lux.setZone(timezone).toFormat("LL/dd/yy hh:mm a");

  // REFUNDS -----
  if (payment.paymentType === "refund") {
    let refundStr = `${currencyFormatter(
      -payment.amount,
      currencyType,
    )} refunded via ${paymentMethodsMap[payment.paymentMethod]}`;
    if (
      payment.paymentMethod === "credit_card" ||
      payment.paymentMethod === "tokenized_card"
    ) {
      if (payment.lastFour) refundStr += ` (${payment.lastFour})`;
    }
    if (
      payment.paymentMethod === "check" ||
      payment.paymentMethod === "tokenized_check"
    ) {
      if (payment.checkNumber) refundStr += ` (${payment.checkNumber})`;
    }

    refundStr += ` on ${formattedDate}`;
    return refundStr;
  }

  // PAYMENTS -----
  let paymentStr = `${currencyFormatter(payment.amount, currencyType)} paid via ${
    paymentMethodsMap[payment.paymentMethod]
  } on ${formattedDate}`;

  // CARD
  if (
    payment.paymentMethod === "credit_card" ||
    payment.paymentMethod === "tokenized_card"
  ) {
    paymentStr += "\n";
    if (payment.nameOnCard) paymentStr += payment.nameOnCard;
    if (payment.cardType) paymentStr += " - " + payment.cardType;
    if (payment.lastFour) paymentStr += " - " + payment.lastFour;
  }

  // CHECK
  if (
    payment.paymentMethod == "check" ||
    payment.paymentMethod == "tokenized_check"
  ) {
    if (payment.checkNumber) paymentStr += "\nCheck #: " + payment.checkNumber;
  }
  return paymentStr;
}
