// Libs
import { Control, Controller, FieldValues } from "react-hook-form";

// Local
import { SelectionCustomField } from "../../models/custom-field";
import BaseInputRadio from "../BaseInputRadio";
import { Json } from "../../models/json-type";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";

interface Props {
  customField: SelectionCustomField & { id: string };
  control: Control<FieldValues, any>;
  defaultValue: Json | Date;
  isRequired: boolean;
}

export default function FieldTypeSelectionRadio(props: Props): JSX.Element {
  return (
    <Controller
      defaultValue={props.defaultValue}
      name={props.customField.id}
      control={props.control}
      rules={{ required: props.isRequired }}
      render={({ field, fieldState }) => {
        return (
          <>
            <label
              htmlFor={`radio-selection-for-${props.customField.title}`}
              className="inline-block"
            >
              {props.customField.title}{" "}
              {props.isRequired && (
                <span className="text-lg font-medium text-redFail">*</span>
              )}
            </label>
            <div
              id={`radio-selection-for-${props.customField.title}`}
              className="mt-1 grid w-full grid-cols-2"
            >
              {Object.entries(props.customField.selectionOptions).map(
                ([key, val]) => (
                  <BaseInputRadio
                    key={key + val}
                    id={key + val}
                    label={val}
                    {...field}
                    defaultChecked={val === field.value}
                  />
                ),
              )}
            </div>
            {fieldState.error && fieldState.error.type === "required" && (
              <div className="mt-2">
                <StyledMessage type="error">
                  {{ message: strings.REQUIRED }}
                </StyledMessage>
              </div>
            )}
          </>
        );
      }}
    />
  );
}
