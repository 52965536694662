//Libs

//Local
import placeholder_avatar from "../../images/placeholder_avatar.svg";
import { ComplianceCompany } from "../../models/compliance-types-and-statuses";
import { getCompanyStatusIcon } from "../../assets/js/getStatusIconForCompliance";

interface Props {
  company: ComplianceCompany;
  selectedCompanyID: string | undefined;
  setSelectedCompanyID: (companyID: string) => void;
}

export default function CompanyCard({
  company,
  selectedCompanyID,
  setSelectedCompanyID,
}: Props) {
  return (
    <button
      onClick={() => setSelectedCompanyID(company.siteKeyCompanyID)}
      className={`flex w-full justify-between border-y p-2 sm:px-3 md:max-w-full ${
        selectedCompanyID === company.siteKeyCompanyID ? "bg-gray-100" : ""
      }`}
    >
      <div className="mr-1 flex items-center space-x-2 sm:space-x-4">
        <img
          src={company.logo ? company.logo : placeholder_avatar}
          alt="company logo"
          className="h-10 w-10 flex-shrink-0 rounded-full"
        />
        <div className="text-left">
          <p className="text-lg leading-6 lg:text-xl">{company.name}</p>
          <p className="mt-0.5 text-sm text-gray-500 lg:text-base">
            {company.address}
          </p>
        </div>
      </div>
      <div className="h-7 w-7 flex-shrink-0 self-center">
        {getCompanyStatusIcon(company.status)}
      </div>
    </button>
  );
}
