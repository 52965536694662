//Libs
import React from "react";

export const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

export const PencilIconWithRef = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithRef<"button">
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className="mr-1 rounded-full text-gray-700 hover:bg-primaryOpacity90"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={styles}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
      </svg>
    </button>
  );
});
