// Libs
import React from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import * as sentry from "@sentry/react";

// Local
import App from "./App";
import "./tailwind.css";
import "./assets/css/theme.css";

const queryClient = new QueryClient();

const isDevEnvironment =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";

let ENABLE_SENTRY = true;
if (import.meta.env.STORYBOOK === "true" || import.meta.env.DEV) {
  ENABLE_SENTRY = false;
}

// const currentVersion = import.meta.env.PACKAGE_VERSION;
const gitCommit = import.meta.env.VITE_GIT_COMMIT; // we're not currently bumping the version number
// Sentry.io SDK configuration should happen as early as possible in the
// application's lifecycle.
if (ENABLE_SENTRY) {
  sentry.init({
    dsn: "https://f9597940da684b40851161c9d6037ff1@o351752.ingest.sentry.io/4503931432861696",

    integrations: [
      sentry.browserTracingIntegration(),
      sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracePropagationTargets: [
      "us-central1-stilt-dev.cloudfunctions.net",
      "us-central1-scaffoldtracker.cloudfunctions.net",
      "stilt-web.com",
      "go.stilt-web.com",
      "aimpoint.web.app",
      "jovial-elion-a0d1d6.netlify.app",
      "localhost",
      /^\//,
    ],

    environment: isDevEnvironment ? "dev@stilt-react" : "prod@stilt-react",
    release: `stilt-react@${gitCommit}`,

    // https://docs.sentry.io/platforms/javascript/guides/react/performance/#verify
    tracesSampleRate: 0.2,
  });
}
// Now all unhandled exceptions will automatically be captured by Sentry.

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
