// Libs
import { PlusCircleIcon } from "@heroicons/react/24/solid";

// Local
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import { ExistingCraftRecord } from "../models/craft-record";
import ChecklistTemplatesTable from "../components/tables/ChecklistTemplatesTable";
import * as strings from "../strings";

// Styles

interface Props {
  checklistList: ExistingCraftRecord[];
  onAddNewClick: () => void;
  onViewItems: (checklistID: string) => void;
}

export default function ListTemplatesPage({
  checklistList,
  onAddNewClick,
  onViewItems,
  ...props
}: Props) {
  const AddTemplateButton = (): JSX.Element => {
    return (
      <BaseButtonPrimary type="button" onClick={onAddNewClick}>
        <PlusCircleIcon aria-label="create new template" className="mr-4 h-5" />
        {strings.buttons.CREATE_NEW_TEMPLATE}
      </BaseButtonPrimary>
    );
  };

  const extraButtons: Array<() => JSX.Element> = [AddTemplateButton];

  return (
    <div className="flex h-full flex-col">
      <ChecklistTemplatesTable
        checklistList={checklistList}
        extraButtons={extraButtons}
        handleViewItems={onViewItems}
      />
    </div>
  );
}
