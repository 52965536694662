//Libs
import { Fragment, useEffect, useState } from "react";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { DateTime } from "luxon";
import { User } from "firebase/auth";
import { DocumentData } from "firebase/firestore";
import DatePicker from "react-datepicker";

//Local
import QuickbooksPage from "./QuickbooksPage";
import { DbRead, DbWrite } from "../../database";
import {
  DocType,
  ExistingCustomerAccountingSyncTableData,
  ExistingPBItemAccountingSyncTableData,
  ExistingStiltInvoiceAccountingSyncTableData,
  ExistingStiltPaymentAccountingSyncTableData,
  TableData,
} from "../../models/quickbook";
import {
  ExistingStiltInvoice,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { NewManualPayment } from "../../components/Invoices/RecordManualPaymentDialog";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import { SchedulingButton } from "../Scheduling/SchedulingContainer";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import { logger } from "../../logging";
import {
  APIPaymentSavedCard,
  paymentMethods,
  StiltPayment_CreateAPI,
  StiltPaymentManager,
} from "../../models/stilt-payment";
import { useAuthStore } from "../../store/firebase-auth";
import { diffObjects } from "../../assets/js/object-diff";
import BaseInputSelect from "../../components/BaseInputSelect";
import { useNavigate } from "react-router-dom";
import { CUSTOMERS_URL } from "../../urls";
import {
  AddNewPBItem,
  ExistingPriceBookItem,
  PBItem_CreateAPI,
  PBItem_UpdateAPI,
  PriceBookItemManager,
} from "../../models/price-book-item";
import AddEditPriceBookItemDialog from "../../components/estimates/AddEditPriceBookItemDialog";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { ExistingCustomer } from "../../models/customer";
import { ExistingPriceBookItemCategory } from "../../models/price-book-item-category";
import { StyledTooltip } from "../../components/StyledTooltip";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import { getEmailList } from "../../utils/getEmailList";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import ButtonConnectQB from "../../components/ButtonConnectQB";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { SiQuickbooks } from "react-icons/si";
import ButtonColored from "../../components/ButtonColored";

type ActionTypes = "connect" | "reconnect" | "disconnect" | "resync";

interface Props {
  siteKey: string;
}

export default function QuickbooksContainer({ siteKey }: Props) {
  const queryClient = useQueryClient();
  /* STORES */
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const siteKeyLocationList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );
  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);

  /* NAVIGATE */
  const navigate = useNavigate();
  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  function handleGoToCustomerPage(customerId: string) {
    navigate(`${CUSTOMERS_URL}/${customerId}`);
  }

  /* STATES */
  const [customers, setCustomers] = useState<
    ExistingCustomerAccountingSyncTableData[]
  >([]);
  const [pricebookItems, setPricebookItems] = useState<
    ExistingPBItemAccountingSyncTableData[]
  >([]);
  const [invoices, setInvoices] = useState<
    ExistingStiltInvoiceAccountingSyncTableData[]
  >([]);
  const [payments, setPayments] = useState<
    ExistingStiltPaymentAccountingSyncTableData[]
  >([]);
  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] = useState(false);
  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);
  const [isInvoiceDialogOpen, setIsInvoiceDialogOpen] = useState(false);
  const [stiltInvoiceID, setStiltInvoiceID] = useState<
    ExistingStiltInvoice["id"] | null
  >(null);
  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [showDataThatWontSync, setShowDataThatWontSync] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
    DateTime.now(),
  );
  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);
  const [addEditPBItemDialogOpen, setAddEditPBItemDialogOpen] =
    useState<boolean>(false);
  const [pBItemDoc, setPBItemDoc] = useState<ExistingPriceBookItem | null>(
    null,
  );
  const [priceBookItemCategories, setPriceBookItemCategories] = useState<
    ExistingPriceBookItemCategory[]
  >([]);
  const [currentCustomer, setCurrentCustomer] =
    useState<ExistingCustomer | null>(null);
  const [isBusy, setIsBusy] = useState<ActionTypes | null>(null);

  let tableData: TableData[] = [
    ...customers,
    ...pricebookItems,
    ...invoices,
    ...payments,
  ];

  if (!showDataThatWontSync) {
    tableData = tableData.filter((data) => {
      const filterTheseMessagesOut = [
        "because it has been canceled",
        "because it is a draft",
        "because it has no line items",
      ];
      return !filterTheseMessagesOut.some((message) =>
        data.accountingSync?.statusMessage?.includes(message),
      );
    });
  }

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  const selectedInvoice = invoices.find(
    (invoice) => invoice.id === stiltInvoiceID,
  );

  /* USE EFFECT */

  useEffect(() => {
    if (siteKey == null) {
      logger.error("siteKey is null");
      return undefined;
    }
    function getCategories() {
      // Subscribe to all priceBookItemCategories
      const unsubscribe = DbRead.priceBookItemCategories.subscribeAll({
        siteKey: siteKey,
        onChange: setPriceBookItemCategories,
        onError: (error) => {
          logger.error(
            "Error while subscribing to priceBookItemCategories",
            error,
          );
        },
      });
      return unsubscribe;
    }
    const func = getCategories();
    return () => func && func();
  }, [siteKey]);

  useEffect(() => {
    function getCustomers() {
      const unsubscribe = DbRead.customers.subscribeAllByAccountingSync({
        siteKey: siteKey,
        onChange: setCustomers,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomers();
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey]);

  useEffect(() => {
    function getPricebookItems() {
      const unsubscribe = DbRead.priceBookItems.subscribeAllByAccountingSync({
        siteKey: siteKey,
        onChange: setPricebookItems,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getPricebookItems();
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey]);

  useEffect(() => {
    function getInvoices() {
      const unsubscribe = DbRead.invoices.subscribeAllByAccountingSync({
        siteKey: siteKey,
        onChange: setInvoices,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getInvoices();
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey]);

  useEffect(() => {
    function getPayments() {
      const unsubscribe = DbRead.payments.subscribeAllByAccountingSync({
        siteKey: siteKey,
        onChange: setPayments,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getPayments();
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey]);

  // Retrieve customer from selected invoice. For paying with a saved card.
  useEffect(() => {
    async function getCustomer() {
      if (!selectedInvoice) return;

      const customer = await DbRead.customers.get(
        siteKey,
        selectedInvoice.customerID,
      );
      setCurrentCustomer(customer);
    }
    getCustomer();
  }, [selectedInvoice, siteKey]);

  //get all the invoices related to the existing payments
  const paymentInvoicesQueryResult = useQueries(
    payments.map((payment) => {
      return {
        queryKey: ["quickbooks_invoices", siteKey, payment.invoiceID],
        queryFn: async () =>
          await DbRead.invoices.getSingle(siteKey, payment.invoiceID),
      };
    }),
  );
  // remove null results
  const paymentInvoiceList = paymentInvoicesQueryResult.map((invoice) => {
    if (invoice.data != null) {
      return invoice.data;
    } else {
      return [];
    }
  });
  const paymentsInvoices: ExistingStiltInvoice[] = paymentInvoiceList.flat(1);

  const { waitingOnCustomerIDs, waitingOnInvoiceIDs, waitingOnPBItemIDs } =
    getWaitingOnDocs(tableData, paymentsInvoices);

  //get all the invoices related to the existing payments
  const waitingOncustomerQueryResults = useQueries(
    waitingOnCustomerIDs
      ? waitingOnCustomerIDs.map((customerID) => {
          return {
            queryKey: ["quickbooks_waitingOnCustomers", siteKey, customerID],
            queryFn: async () =>
              await DbRead.customers.get(siteKey, customerID),
          };
        })
      : [],
  );
  // remove null results
  const customerList = waitingOncustomerQueryResults.map((customer) => {
    if (customer.data != null) {
      return customer.data;
    } else {
      return [];
    }
  });
  const waitingOnCustomerList: ExistingCustomer[] = customerList.flat(1);

  //get all the invoices related to the existing payments
  const waitingOnInvoiceQueryResults = useQueries(
    waitingOnInvoiceIDs
      ? waitingOnInvoiceIDs.map((invoiceID) => {
          return {
            queryKey: ["quickbooks_waitingOnInvoices", siteKey, invoiceID],
            queryFn: async () =>
              await DbRead.invoices.getSingle(siteKey, invoiceID),
          };
        })
      : [],
  );
  // remove null results
  const invoiceList = waitingOnInvoiceQueryResults.map((invoice) => {
    if (invoice.data != null) {
      return invoice.data;
    } else {
      return [];
    }
  });
  const waitingOnInvoiceList: ExistingStiltInvoice[] = invoiceList.flat(1);

  //get all the pbItems related to the waitingOnPBItemIDs
  const waitingOnPbItemQueryResults = useQueries(
    waitingOnPBItemIDs
      ? waitingOnPBItemIDs.map((pbItemID) => {
          return {
            queryKey: ["quickbooks_waitingOnPBItems", siteKey, pbItemID],
            queryFn: async () =>
              await DbRead.priceBookItems.get(siteKey, pbItemID),
          };
        })
      : [],
  );
  // remove null results
  const pbItemList = waitingOnPbItemQueryResults.map((pbItem) => {
    if (pbItem.data != null) {
      return pbItem.data;
    } else {
      return [];
    }
  });
  const waitingOnPbItemList: ExistingPriceBookItem[] = pbItemList.flat(1);

  /* MUTATIONS */
  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
    {
      onSuccess: async () =>
        await queryClient.invalidateQueries("quickbooks_invoices"),
    },
  );

  const mutateEditPBItem = useMutation(
    async (args: { editPBItem: PBItem_UpdateAPI }) => {
      await DbWrite.priceBookItems.update(args.editPBItem);
    },
  );

  const mutateAddPBItem = useMutation(
    async (args: { validPBItem: PBItem_CreateAPI }) => {
      await DbWrite.priceBookItems.create(args.validPBItem);
    },
  );

  /* FUNCTIONS */
  function openEditPBItemDialog(priceBookItem: ExistingPriceBookItem) {
    setPBItemDoc(priceBookItem);
    setAddEditPBItemDialogOpen(true);
  }

  function openViewInvoiceDialog(invoiceID: string): void {
    if (!siteKeyDoc) return;

    setViewInvoiceDialogProps({
      siteKeyID: siteKey,
      invoiceID,
      merchantName: siteKeyDoc.name,
      merchantLogoURL:
        siteKeyDoc.customizations.accounting?.merchantLogoURL ?? null,
    });
    setIsInvoiceDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  function closeViewInvoiceDialog(): void {
    setIsInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    // try/catch is in the InvoiceSummary component; don't want it here
    return DbWrite.payments.issueRefund(siteKey, paymentID, refundAmount);
  }

  function onClickHandlePaymentButton(invoiceID: ExistingStiltInvoice["id"]) {
    setIsHandlePaymentDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  async function handleEditInvoiceDialogOpen() {
    if (selectedInvoice) {
      setEditInvoiceDialogOpen(true);
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
      return;
    }
    if (email.length === 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "email",
    );
    if (!paymentLink) {
      logger.error("emailInvoice: missing invoice link");
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_GEN_INVOICE_LINK,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
      setStiltInvoiceID(null);
      closeViewInvoiceDialog();
    } catch (e) {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
      logger.error("error emailing customer invoice", e);
      setStiltInvoiceID(null);
    }
  }

  async function emailReceipt(emailList: string[]): Promise<void> {
    if (!selectedInvoice) return;
    if (emailList.length === 0) {
      throw Error("emailReceipt given empty email list");
    }
    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: siteKey,
        invoiceID: selectedInvoice.id,
        customerEmailList: emailList,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      // logger.error("emailReceipt failure", e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT,
        dialog: false,
        type: "error",
      });
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!selectedInvoice) return;
    if (selectedInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await emailReceipt(emails);
    }
  }

  async function handleDeleteInvoice(
    siteKeyID: string,
    invoiceID: string,
  ): Promise<void> {
    return DbWrite.invoices.delete(siteKeyID, invoiceID);
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (!stiltInvoiceID) return;
    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "web",
    );
    if (paymentLink) {
      goToPaymentPage(paymentLink);
    } else {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERROR_PAYMENT_LINK,
        type: "error",
      });
    }
  }

  async function applyManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: isInvoiceDialogOpen,
        type: "error",
      });
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: selectedDateTime.toISO(),
      customerID: selectedInvoice.customerID,
      billToCustomerID: selectedInvoice.billToCustomerID,
      locationID: selectedInvoice.locationID,
      invoiceID: stiltInvoiceID,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: siteKey,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    logger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      setStiltInvoiceID(null);
      if (
        validPaymentData.amount === selectedInvoice.amountDue &&
        selectedInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (selectedInvoice.email !== null) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: isInvoiceDialogOpen,
            type: "success",
          });
        } else {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: isInvoiceDialogOpen,
            type: "info",
          });
        }
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: isInvoiceDialogOpen,
          type: "success",
        });
      }
    } catch (err) {
      logger.error("handleRecordManualPayment", err);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: isInvoiceDialogOpen,
        type: "error",
      });
      return;
    }
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!selectedInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      selectedInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: selectedInvoice.id,
      refPath: selectedInvoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    logger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      logger.debug("Invoice has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.successfulUpdate(
          `Payment terms for Invoice #${selectedInvoice.invoiceNumber}`,
        ),
        type: "success",
      });
      setIsInvoiceDialogOpen(false);
    } catch (error) {
      logger.error(`An error occurred during handleSaveUpdatedInvoice`, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }

    setStiltInvoiceID(null);
  }

  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function handleSaveNewPBItem(formValues: AddNewPBItem) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    const newPBItem: PBItem_CreateAPI = {
      ...formValues,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKey,
    };

    //Validate
    const validatedPBItem = PriceBookItemManager.parseCreate(newPBItem);
    logger.info("Validated Price Book Item:", validatedPBItem);

    //DB
    try {
      await mutateAddPBItem.mutateAsync({
        validPBItem: validatedPBItem,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(validatedPBItem.title),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewPBItem`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleEditPBItem(
    updatePBItem: Partial<ExistingPriceBookItem>,
  ) {
    if (pBItemDoc == null) {
      return;
    }

    updatePBItem["lastModifiedBy"] = firebaseUser.uid;

    const diffPBItemValues: DocumentData = diffObjects(
      pBItemDoc,
      updatePBItem,
    ).diff;

    if (Object.keys(diffPBItemValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    diffPBItemValues["id"] = pBItemDoc.id;
    diffPBItemValues["refPath"] = pBItemDoc.refPath;

    /* validate values from the form */
    const validateEditPBItem =
      PriceBookItemManager.parseUpdate(diffPBItemValues);

    try {
      await mutateEditPBItem.mutateAsync({
        editPBItem: validateEditPBItem,
      });
      logger.debug("Customer has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(pBItemDoc.title),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleEditPBItem`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleDeletePBItem(pbItemID: string) {
    if (pBItemDoc == null) {
      return;
    }

    try {
      await DbWrite.priceBookItems.delete(siteKey, pbItemID);
      logger.debug("Pricebook item deleted successfully.");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulDelete(pBItemDoc.title),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleEditPBItem`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKey,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  /* COMPONENTS */
  const addEditPBItemDialog = userPermissions && (
    <AddEditPriceBookItemDialog
      isDialogOpen={addEditPBItemDialogOpen}
      siteKeyLocationList={siteKeyLocationList}
      closeDialog={() => {
        setAddEditPBItemDialogOpen(false);
        setPBItemDoc(null);
      }}
      handleSaveNewPBItem={handleSaveNewPBItem}
      handleEditPBItem={handleEditPBItem}
      userPermissions={userPermissions}
      handleDeletePBItem={handleDeletePBItem}
      priceBookCategories={priceBookItemCategories}
      inventoryEnabled={siteKeyDoc?.customizations.inventoryEnabled ?? false}
      allowEditingAccountNumbers={
        !siteKeyDoc?.customizations?.accounting?.codatConnectionID
      }
      allowEditingCommissions={siteKeyDoc?.customizations.commissions}
      priceBookItemDoc={pBItemDoc}
    />
  );

  const dueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="flex items-center gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <DatePicker
        selected={
          selectedIssueDate
            ? selectedIssueDate.toJSDate()
            : DateTime.now().toJSDate()
        }
        onChange={(date: Date) => {
          const luxonDate = DateTime.fromJSDate(date);
          setSelectedIssueDate(luxonDate);
          setPaymentTerms(null);
        }}
        customInput={<SchedulingButton />}
      />
    </div>
  );

  const showDataWontSyncButton = (
    <StyledTooltip
      title={
        "Toggle this to show records that will never sync to QuickBooks. Common records that won't sync are draft invoices, canceled invoices, and invoices with no line items."
      }
      placement="top-end"
    >
      <span className="ml-auto">
        <StyledSwitchGroup
          checked={showDataThatWontSync}
          name="showDataThatWontSync"
          readableName={`${showDataThatWontSync ? "Hide" : "Show"} data not set to sync`}
          onChange={() => {
            setShowDataThatWontSync(!showDataThatWontSync);
          }}
          onBlur={() => {}}
        />
      </span>
    </StyledTooltip>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = selectedInvoice && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={selectedInvoice}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  const datePicker = selectedInvoice && (
    <DatePicker
      selected={selectedDateTime.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setSelectedDateTime(luxonDate);
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    siteKeyDoc &&
    userPermissions &&
    selectedInvoice &&
    currentCustomer && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        closeDialog={() => setIsHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={currentCustomer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, currentCustomer)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  const viewInvoiceDialog = isInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        isOpen={isInvoiceDialogOpen}
        onClose={closeViewInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        handlePayment={onClickHandlePaymentButton}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        editInvoice={handleEditInvoiceDialogOpen}
        sendEmail={emailInvoice}
        deleteInvoice={handleDeleteInvoice}
        userDisplayNamesMap={userDisplayNamesMap}
        handleDeletePayment={handleDeletePayment}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  async function handleClickConnect(): Promise<void> {
    let authURI: string;
    try {
      authURI = await DbWrite.qbo.connect(siteKey);
    } catch (e) {
      logger.error("QBO connection failure: rejection in getAuthURI", e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.QB_CANT_CONNECT,
        dialog: false,
        type: "error",
      });
      return;
    } finally {
      setIsBusy(null);
    }

    // Launch Popup using the JS window Object
    const parameters = `location=1,width=800,height=650,left=${(screen.width - 800) / 2},top=${(screen.height - 650) / 2}`;
    window.open(authURI, "connectPopup", parameters);
  }

  const connectButton = (
    <ButtonConnectQB
      isBusy={isBusy === "connect"}
      onClick={async () => {
        setIsBusy("connect");
        await handleClickConnect();
      }}
      type="button"
    />
  );

  const reconnectButton = (
    <StyledTooltip
      title={`If you see error messages such as "unable to refresh access token," try reconnecting. Disconnecting first is not necessary.`}
    >
      <BaseButtonSecondary
        isBusy={isBusy === "reconnect"}
        busyText="Reconnect"
        onClick={async () => {
          setIsBusy("reconnect");
          await handleClickConnect();
        }}
        type="button"
      >
        <SiQuickbooks />
        <span className="ml-2">{strings.RECONNECT}</span>
      </BaseButtonSecondary>
    </StyledTooltip>
  );

  const disconnectButton = (
    <StyledTooltip title="Disconnecting will stop Stilt from pushing more data to QuickBooks. No data will be lost. Stilt will continue syncing your data when the connection is established again.">
      <ButtonColored
        kind="danger"
        isBusy={isBusy === "disconnect"}
        busyText="Disconnect"
        onClick={async () => {
          setIsBusy("disconnect");
          try {
            await DbWrite.qbo.disconnect(siteKey);
            addToastMessage({
              id: createToastMessageID(),
              message: strings.DISCONNECTED,
              dialog: false,
              type: "success",
            });
          } catch (e) {
            logger.error("QBO disconnect failure", e);
            addToastMessage({
              id: createToastMessageID(),
              message: strings.QB_CANT_DISCONNECT,
              dialog: false,
              type: "error",
            });
            return;
          } finally {
            setIsBusy(null);
          }
        }}
      >
        <SiQuickbooks />
        <span className="ml-2">{strings.DISCONNECT}</span>
      </ButtonColored>
    </StyledTooltip>
  );

  const connectionButtons = siteKeyDoc &&
    userPermissions &&
    userPermissions.permissions.isSiteAdmin && (
      <>
        {typeof siteKeyDoc.customizations.accounting?.qboRealmID === "string" &&
        siteKeyDoc.customizations.accounting.qboRealmID.length > 0 ? (
          <div className="flex flex-wrap gap-4">
            {reconnectButton}
            {disconnectButton}
          </div>
        ) : (
          connectButton
        )}
      </>
    );

  /* RENDER LOADING */
  if (!siteKeyDoc || siteKeyDocIsLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  /* RENDER CONTENT */
  return (
    <Fragment>
      <QuickbooksPage
        tableData={tableData}
        paymentsInvoices={[...paymentsInvoices, ...waitingOnInvoiceList]}
        openInvoiceDialog={openViewInvoiceDialog}
        goToCustomerPage={handleGoToCustomerPage}
        onEditPBItem={openEditPBItemDialog}
        waitingOnCustomerList={waitingOnCustomerList}
        waitingOnPbItemList={waitingOnPbItemList}
      >
        {{
          showDataWontSyncButton: showDataWontSyncButton,
          connectionButtons: connectionButtons,
        }}
      </QuickbooksPage>
      {viewInvoiceDialog}
      {addEditPBItemDialog}
    </Fragment>
  );
}

function getWaitingOnDocs(
  tableData: TableData[],
  paymentsInvoices: ExistingStiltInvoice[],
) {
  const waitingOnCustomerIDs: string[] = [];
  const waitingOnInvoiceIDs: string[] = [];
  const waitingOnPBItemIDs: string[] = [];

  for (const data of tableData) {
    if (!data.accountingSync || !data.accountingSync.waitingOn) continue;

    const waitingOnSplitted = data.accountingSync.waitingOn.split("/");
    const collection = waitingOnSplitted[2] as DocType;
    const id = waitingOnSplitted[3];

    switch (collection) {
      case DocType.CUSTOMERS:
        waitingOnCustomerIDs.push(id);
        break;
      case DocType.PRICEBOOKITEMS:
        waitingOnPBItemIDs.push(id);
        break;
      case DocType.INVOICES:
        const paymentInvoice = paymentsInvoices.find(
          (invoice) => invoice.id === id,
        );
        if (paymentInvoice) continue;
        waitingOnInvoiceIDs.push(id);
        break;
      case DocType.PAYMENTS:
        continue;
      default:
        const _exhaustivenessCheck: never = collection;
        return _exhaustivenessCheck;
    }
  }

  return {
    waitingOnCustomerIDs,
    waitingOnInvoiceIDs,
    waitingOnPBItemIDs,
  };
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}
