//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { dropUndefined, guardIsPlainObject } from "../utils";

export interface Asset {
  title: string;
  description?: string | null;
  customID?: string | null;
  locationID: string | null;
  latitude: number | null;
  longitude: number | null;
  qrCode?: string | null;
  thumbnailURL?: string | null;
  manufacturer?: string;
  equipmentType?: string;
  serialNumber?: string;
  modelNumber?: string;
  installationDate?: Timestamp;
  manufacturerWarrantyStart?: Timestamp;
  manufacturerWarrantyEnd?: Timestamp;
  serviceWarrantyStart?: Timestamp;
  serviceWarrantyEnd?: Timestamp;
  customerID?: string;
  customerLocationID?: string;
}

export interface ExistingAsset extends Asset {
  id: string;
  refPath: string;
}

export const AssetManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingAsset object.
   */
  createFromFirestoreSnapshot: createAssetFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertAssetForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateAsset,
  /** Validate a Asset doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateAssetWithFallbacks,
};

/**
 * Convert the Document Snapshot into a validated ExistingPriceBookItem object.
 */
function createAssetFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingAsset {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateAssetWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertAssetForFirestore(asset: Asset): DocumentData {
  const localItem = Object.assign({}, asset);
  const { ...rest } = localItem;
  const result = dropUndefined(rest);
  return result;
}

/* Zod validation schemas */
function validateAssetWithFallbacks(value: unknown): Asset {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = assetSchemaWithFallbacks.parse(value);
  return result;
}

function validateAsset(value: unknown): Asset {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = assetSchema.parse(value);
  return result;
}

const assetSchema = z.object({
  title: z.string().min(0).max(200),
  description: z.string().min(0).max(500).nullable().optional(),
  customID: z.string().min(0).max(200).nullable().optional(),
  locationID: z.string().min(1).max(200).nullable(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  qrCode: z.string().min(1).max(7089).nullable().optional(),
  thumbnailURL: z.string().min(1).max(1000).nullable().optional(),
  manufacturer: z.string().min(0).max(400).optional(),
  equipmentType: z.string().min(0).max(400).optional(),
  serialNumber: z.string().min(0).max(400).optional(),
  modelNumber: z.string().min(0).max(400).optional(),
  installationDate: z.instanceof(Timestamp).optional(),
  manufacturerWarrantyStart: z.instanceof(Timestamp).optional(),
  manufacturerWarrantyEnd: z.instanceof(Timestamp).optional(),
  serviceWarrantyStart: z.instanceof(Timestamp).optional(),
  serviceWarrantyEnd: z.instanceof(Timestamp).optional(),
  customerID: z.string().min(0).max(400).optional(),
  customerLocationID: z.string().min(0).max(400).optional(),
});

const assetSchemaWithFallbacks = z.object({
  title: zFallback(
    z.string().min(0).max(200),
    "unknown",
    "assetSchemaWithFallbacks: 'title'",
  ),
  description: zFallback(
    z.string().min(0).max(200).nullable().optional(),
    null,
    "assetSchemaWithFallbacks: 'description'",
  ),
  customID: zFallback(
    z.string().min(1).max(200).nullable().optional(),
    null,
    "assetSchemaWithFallbacks: 'customID'",
  ),
  locationID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "assetSchemaWithFallbacks: 'locationID'",
  ),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  qrCode: zFallback(
    z.string().min(1).max(200).nullable().optional(),
    null,
    "assetSchemaWithFallbacks: 'qrCode'",
  ),
  thumbnailURL: zFallback(
    z.string().min(1).max(200).nullable().optional(),
    null,
    "assetSchemaWithFallbacks: 'thumbnailURL'",
  ),
  manufacturer: zFallback(
    z.string().min(0).max(400).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'manufacturer'",
  ),
  equipmentType: zFallback(
    z.string().min(0).max(400).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'equipmentType'",
  ),
  serialNumber: zFallback(
    z.string().min(0).max(400).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'serialNumber'",
  ),
  modelNumber: zFallback(
    z.string().min(0).max(400).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'modelNumber'",
  ),
  installationDate: zFallback(
    z.instanceof(Timestamp).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'installationDate'",
  ),
  manufacturerWarrantyStart: zFallback(
    z.instanceof(Timestamp).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'manufacturerWarrantyStart'",
  ),
  manufacturerWarrantyEnd: zFallback(
    z.instanceof(Timestamp).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'manufacturerWarrantyEnd'",
  ),
  serviceWarrantyStart: zFallback(
    z.instanceof(Timestamp).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'serviceWarrantyStart'",
  ),
  serviceWarrantyEnd: zFallback(
    z.instanceof(Timestamp).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'serviceWarrantyEnd'",
  ),
  customerID: zFallback(
    z.string().min(0).max(400).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'customerID'",
  ),
  customerLocationID: zFallback(
    z.string().min(0).max(400).optional(),
    undefined,
    "assetSchemaWithFallbacks: 'customerLocationID'",
  ),
});
