// Libs
import { Navigate, useLocation } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Local
import { useAuthStore } from "../store/firebase-auth";
import LoadingClipboardAnimation from "./LoadingClipBoardAnimation";
import { LOGIN_URL } from "../urls";

interface Props {
  children: JSX.Element;
}

/**
 * Redirect to the login page if there is not authenticated firebase user.
 * Otherwise render children.
 */
export const RouteAuthRequired = ({ children }: Props): JSX.Element => {
  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const [loadingUser, setLoadingUser] = useState(true);

  useLayoutEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoadingUser(false);
      } else {
        setLoadingUser(false);
      }
    });
    return unsubscribe;
  }, [firebaseUser]);

  const location = useLocation();

  if (!loadingUser && firebaseUser === null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={LOGIN_URL} state={{ from: location }} replace />;
  } else if (loadingUser && firebaseUser === null) {
    return (
      <div className="flex h-full flex-auto flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  } else {
    return children;
  }
};
