// Local
import {
  ExistingCustomField,
  CustomFieldManager,
} from "../../models/custom-field";
import { ExistingSiteKey } from "../../models/site-key";
import { guardIsPlainObject } from "../../utils";
import { logger } from "../../logging";

/**
 * Convert customizations from the given site key into custom fields.
 *
 * Tested ✅
 */
export default function convertCustomizationsToCustomFields(props: {
  siteKeyDoc: ExistingSiteKey;
}): ExistingCustomField[] {
  const craftDetails = props.siteKeyDoc.customizations?.craftDetails;
  const taskSpecificDetails =
    props.siteKeyDoc.customizations?.taskSpecificDetails;

  let craftCustomFields: ExistingCustomField[] = [];
  if (guardIsPlainObject(craftDetails)) {
    craftCustomFields = Object.entries(craftDetails).flatMap(
      ([craftTypeString, dynamicDetailMap]) => {
        if (guardIsPlainObject(dynamicDetailMap)) {
          return Object.entries(dynamicDetailMap).flatMap(
            ([dynamicDetailID, ddData]) => {
              try {
                return CustomFieldManager.fromSiteCustomization({
                  fieldID: dynamicDetailID,
                  craftRecordString: craftTypeString,
                  fieldData: ddData,
                });
              } catch (e) {
                logger.error(
                  `Expected ${props.siteKeyDoc.id}.customizations.${craftTypeString}.${dynamicDetailID} to parse, but got an error:`,
                  e,
                );
                return [];
              }
            },
          );
        } else {
          logger.warn(
            `Expected an object for the dynamicDetailMap for ${craftTypeString}, got: ${typeof dynamicDetailMap}`,
          );
          return [];
        }
      },
    );
  } else {
    logger.warn(
      `Expected craftDetails to be an object, got ${typeof craftDetails}`,
    );
  }

  let taskCustomFields: ExistingCustomField[] = [];
  if (guardIsPlainObject(taskSpecificDetails)) {
    taskCustomFields = Object.entries(taskSpecificDetails).flatMap(
      ([craftTypeString, taskTypeMap]) => {
        if (guardIsPlainObject(taskTypeMap)) {
          return Object.entries(taskTypeMap).flatMap(
            ([taskTypeString, dynamicDetailMap]) => {
              if (guardIsPlainObject(dynamicDetailMap)) {
                return Object.entries(dynamicDetailMap).flatMap(
                  ([dynamicDetailID, ddData]) => {
                    try {
                      return CustomFieldManager.fromSiteCustomization({
                        fieldID: dynamicDetailID,
                        taskTypeString: taskTypeString,
                        craftRecordString: craftTypeString,
                        fieldData: ddData,
                      });
                    } catch (e) {
                      logger.error(
                        `Expected ${props.siteKeyDoc.id}.customizations.${craftTypeString}.${taskTypeString}.${dynamicDetailID} to parse, but got an error:`,
                        e,
                      );
                      return [];
                    }
                  },
                );
              } else {
                logger.warn(
                  `Expected an object for the dynamicDetailMap for ${craftTypeString}.${taskTypeString}, got: ${typeof dynamicDetailMap}`,
                );
                return [];
              }
            },
          );
        } else {
          logger.warn(
            `Expected an object for the taskTypeMap for ${craftTypeString}, got: ${typeof taskTypeMap}`,
          );
          return [];
        }
      },
    );
  } else {
    logger.warn(
      `Expected taskSpecificDetails to be an object, got ${typeof taskSpecificDetails}`,
    );
  }

  return [...craftCustomFields, ...taskCustomFields];
}
