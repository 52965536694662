//Libs
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import * as MdIcons from "react-icons/md";
import { LuClock4 } from "react-icons/lu";
import { BiCalendarEvent } from "react-icons/bi";
import { HiBellAlert } from "react-icons/hi2";
import { RiCheckDoubleLine } from "react-icons/ri";

//Local
import * as urls from "../urls";
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import * as strings from "../strings";
import { useNavToCreateTask } from "../navigation";
import { useSiteKeyDocStore } from "../store/site-key-doc";
import { OTaskStatus } from "../models/task-status";
import { OTaskTypes } from "../models/task-types";
import { useEffect, useState } from "react";
import { logger as devLogger } from "../logging";
import { DbRead } from "../database";
import { convertLuxonDTToFSTimestamp } from "../utils";
import { ExistingTask, ExistingTaskWithCustomerLocation } from "../models/task";
import { useUserPermissionsStore } from "../store/user-permissions";

type TaskCategoryProps = {
  path: string;
  icon: React.ElementType;
  count?: number;
  title: string;
};

const Dashboard = () => {
  // const [counts, setCounts] = useState({
  //   allTask: 0,
  //   inProgress: 0,
  //   urgent: 0,
  //   scheduled: 0,
  //   inspection: 0,
  //   backlog: 0,
  // });

  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const navToCreateTask = useNavToCreateTask();

  const [inProgressTaskList, setInProgressTaskList] = useState<ExistingTask[]>(
    [],
  );
  const [awaitingPartsTaskList, setAwaitingPartsTaskList] = useState<
    ExistingTask[]
  >([]);

  useEffect(() => {
    function getInProgressTasks() {
      if (!siteKeyDoc) {
        return;
      }
      if (!userPermissions) {
        return;
      }
      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubInProgress = DbRead.tasks.subscribeByTaskStatus({
        siteKey: siteKeyDoc.id,
        permissions: userPermissions,
        taskStatus: [OTaskStatus.IN_PROGRESS],
        startDate: null,
        endDate: null,
        dateParam: null,
        onChange: setInProgressTaskList,
        onError: (error) =>
          devLogger.error(
            `Error in ${getInProgressTasks.name}: ${error.message}`,
          ),
      });
      return unsubInProgress;
    }

    const unsubscribe = getInProgressTasks();
  }, [siteKeyDoc, userPermissions]);

  useEffect(() => {
    function getAwaitingPartsTasks() {
      if (!siteKeyDoc) {
        return;
      }
      if (!userPermissions) {
        return;
      }
      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubInProgress = DbRead.tasks.subscribeByTaskStatus({
        siteKey: siteKeyDoc.id,
        permissions: userPermissions,
        taskStatus: [OTaskStatus.AWAITING_PARTS, OTaskStatus.PARTS_ORDERED],
        startDate: null,
        endDate: null,
        dateParam: null,
        onChange: setAwaitingPartsTaskList,
        onError: (error) =>
          devLogger.error(
            `Error in ${getAwaitingPartsTasks.name}: ${error.message}`,
          ),
      });
      return unsubInProgress;
    }

    const unsubscribe = getAwaitingPartsTasks();
  }, [siteKeyDoc, userPermissions]);

  const TaskCategory = (props: TaskCategoryProps) => (
    <div className="px-2 py-2 pb-4 sm:col-span-6 lg:col-span-3">
      <div className="w-xs relative mx-auto">
        <IconContext.Provider value={{ color: "#757575" }}>
          <Link
            to={props.path}
            className="flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white py-6 shadow-md hover:bg-gray-100"
          >
            {props.icon && (
              <props.icon className="mb-2 inline-block h-16 w-16" />
            )}
            {props.count !== undefined && props.count !== 0 && (
              <span className="text-md absolute right-0 top-0 mr-2 inline-flex items-center justify-center rounded-full bg-red-500 px-2 py-1 font-bold text-white">
                {props.count}
              </span>
            )}
            <hr className="border-1 my-6 w-full border-gray-200"></hr>
            <h5 className="my-2 px-6 text-center text-lg font-bold tracking-tight text-gray-900">
              {props.title}
            </h5>
          </Link>
        </IconContext.Provider>
      </div>
    </div>
  );

  console.log(awaitingPartsTaskList);

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-12">
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/All`}
          icon={MdIcons.MdFormatListBulleted}
          // count={counts.allTask}
          title="All Open Tasks"
        />
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/Scheduled`}
          icon={BiCalendarEvent}
          // count={counts.scheduled}
          title="Scheduled"
        />
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/In Progress`}
          icon={LuClock4}
          count={inProgressTaskList.length}
          title="In Progress"
        />
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/Urgent`}
          icon={HiBellAlert}
          // count={3}
          title="Urgent"
        />
        {siteKeyDoc?.validTaskTypes.includes(OTaskTypes.INSPECTION) && (
          <TaskCategory
            path={`${urls.TASK_LIST_URL}/Inspection`}
            icon={MdIcons.MdCheckCircle}
            // count={3}
            title="Inspections"
          />
        )}
        {siteKeyDoc?.validTaskStatusCodes.includes(
          OTaskStatus.AWAITING_REVIEW,
        ) && (
          <TaskCategory
            key={awaitingPartsTaskList.length}
            path={`${urls.TASK_LIST_URL}/Awaiting Review`}
            icon={MdIcons.MdOutlineTimelapse}
            // count={counts.backlog}
            title="Awaiting Review"
          />
        )}
        {siteKeyDoc?.validTaskStatusCodes.includes(
          OTaskStatus.AWAITING_PARTS,
        ) && (
          <TaskCategory
            key={awaitingPartsTaskList.length}
            path={`${urls.TASK_LIST_URL}/Awaiting Parts`}
            icon={MdIcons.MdOutlineTimelapse}
            count={awaitingPartsTaskList.length}
            title="Awaiting Parts"
          />
        )}
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/Backlog`}
          icon={MdIcons.MdOutlineTimelapse}
          // count={counts.backlog}
          title="Backlog"
        />
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/Completed`}
          icon={RiCheckDoubleLine}
          title="Completed"
        />
        {/* <TaskCategory
          path="/"
          icon={RiCheckDoubleLine}
          title="Map"
        /> */}
      </div>

      <div className="mt-8 flex justify-center">
        <BaseButtonPrimary
          type="button"
          onClick={() => navToCreateTask()}
          className="px-12 py-6 text-xl font-medium uppercase tracking-wider text-primary"
        >
          {strings.CREATE_JOB}
        </BaseButtonPrimary>
      </div>
    </div>
  );
};

export default Dashboard;
