// Libs
import React, { Fragment } from "react";

// Local
import LoadingSpinner from "./LoadingSpinner";

type Ref = HTMLButtonElement;
type Props = {
  className?: string;
  isBusy?: boolean;
  /** default is "Processing..." */
  busyText?: string;
  /** default is text-sm */
  fontSize?: string;
  /** default is h-10 */
  height?: string;
  /** if provided, `busyText` is ignored. */
  overrideLoadingElement?: React.ReactNode;
} & React.ComponentPropsWithRef<"button">;

const BaseButtonPrimary = React.forwardRef<Ref, Props>(
  (
    {
      isBusy = false,
      busyText = "Processing...",
      className,
      overrideLoadingElement,
      ...props
    },
    ref,
  ): JSX.Element => {
    const LoadingElement: React.ReactNode = overrideLoadingElement ?? (
      <Fragment>
        <LoadingSpinner />
        {busyText}
      </Fragment>
    );
    return (
      <button
        {...props}
        ref={ref}
        disabled={isBusy || props.disabled}
        className={`${className ?? ""} ${
          props.disabled && !isBusy
            ? "bg-gray-100 text-gray-500"
            : "bg-primary text-primaryButtonText hover:bg-primaryDark hover:text-secondaryButtonText"
        } ${props.fontSize ? props.fontSize : "text-sm"} 
  ${props.height ? props.height : "h-10"} 
        inline-flex items-center justify-center rounded-md px-4 py-2 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2`}
      >
        {isBusy ? LoadingElement : props.children}
      </button>
    );
  },
);

export default BaseButtonPrimary;
