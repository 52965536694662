//Libs

//Local
import { MembershipDiscount } from "../../../models/membership-template";

export function getTotalDiscounts(
  customer: MembershipDiscount[],
  location: MembershipDiscount[],
): number | null {
  let total: number = 0;

  if (customer.length !== 0) {
    for (const membership of customer) {
      if (membership.value === true) {
        total += membership.discount;
      }
    }
  }

  if (location.length !== 0) {
    for (const membership of location) {
      if (membership.value === true) {
        total += membership.discount;
      }
    }
  }

  return total !== 0 ? Math.round(total * 100) / 100 : null;
}
