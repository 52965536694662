//Libs
import { PencilIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Controller } from "react-hook-form";

//Local
import {
  CraftTypeValues,
  getCraftTypeFromString,
  getCraftTypeStringFromReadableString,
  getReadableCraftType,
  getValidTaskTypes,
  isValidCraftType,
} from "../../../models/craft-types";
import { OTaskStatus, TaskStatusValues } from "../../../models/task-status";
import {
  TaskTypesValues,
  getReadableTaskType,
  getTaskTypeValueFromReadableStr,
} from "../../../models/task-types";
import BaseModal from "../../BaseModal";
import BaseInputSelect from "../../BaseInputSelect";
import {
  CustomFieldDocData,
  CustomFieldTypes,
  CustomFieldTypesExcludingStrArr,
  customFieldTypes,
  customFieldTypesExcludingStrArr,
  getFieldTypeFromString,
  getReadableCustomFieldType,
} from "../../../models/custom-field";
import BaseButtonPrimary from "../../BaseButtonPrimary";
import BaseButtonSecondary from "../../BaseButtonSecondary";
import * as strings from "../../../strings";
import { StringCreateSiteCustomFieldForm } from "./StringCreateSiteCustomFieldForm";
import { BooleanCreateSiteCustomFieldForm } from "./BooleanCreateSiteCustomFieldForm";
import { NumberCreateSiteCustomFieldForm } from "./NumberCreateSiteCustomFieldForm";
import { TimestampCreateSiteCustomFieldForm } from "./TimestampCreateSiteCustomFieldForm";
import { SelectionCreateSiteCustomFieldForm } from "./SelectionCreateSiteCustomFieldForm";
import { UidCreateSiteCustomFieldForm } from "./UidCreateSiteCustomFieldForm";
import { MultipleUidCreateSiteCustomFieldForm } from "./MultipleUidCreateSiteCustomFieldForm";
import { CurrencyCreateSiteCustomFieldForm } from "./CurrencyCreateSiteCustomFieldForm";
import { z } from "zod";
import StyledSwitchGroup from "../../StyledSwitchGroup";
import { FormFieldInterface } from "../../admin/AddCustomFieldDialog/AddCustomFieldDialog";
import { HoursMinutesCreateSiteCustomFieldForm } from "./HoursMinutesCreateSiteCustomFieldForm";
import { StringTextareaCreateSiteCustomFieldForm } from "./StringTextareaCreateSiteCustomFieldForm";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  workType: CraftTypeValues[];
  taskTypes: TaskTypesValues[];
  handleSave: (data: CustomFieldDocData) => void;
  taskStatusList: TaskStatusValues[];
}

const CommonSchema_CSCF = z.object({
  title: z.string({ required_error: "Title is required" }).min(1).max(200),
  editable: z.boolean(),
  required: z.boolean(),
  hideOnCraftRecordCreation: z.boolean(),
  onTaskStatus: z
    .array(z.nativeEnum(OTaskStatus).or(z.literal(undefined)))
    .optional(),
});

export const BooleanSchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z
    .enum(["Yes", "No", ""])
    .refine((val) => (val === "" ? false : true), {
      message: "Default Value is required",
    }),
});

export const NumberSchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z.number().nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const StringSchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z.string(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const StringTextareaSchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z.string(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const TimestampSchema_CSCF = CommonSchema_CSCF;

export const SelectionSchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z.string(),
  selectionOptions: z.array(z.record(z.string(), z.string().min(1))),
});

export const UidSchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z.string().optional(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const MultipleUidSchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z.array(z.record(z.string())).optional().nullable(),
});

export const CurrencySchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z.number().nullable(),
});

export const StringArraySchema_CSCF = CommonSchema_CSCF.extend({
  defaultValue: z.array(z.record(z.string())).optional().nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const HoursMinutesSchema_CSCF = CommonSchema_CSCF.extend({
  defaultValueHours: z.number().nonnegative().nullable(),
  defaultValueMinutes: z
    .number()
    .nonnegative()
    .max(59, { message: strings.ERR_MINUTES_BTWN_0_59 })
    .nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export default function AddCreateSiteCustomFieldDialog(props: Props) {
  function onCloseDialog() {
    props.closeDialog();
    setSelectedFieldType(null);
    setSelectedWorkType(undefined);
    setSelectedTaskType(undefined);
  }

  //#region SECTION: Styling of the header
  const parentDivStyles = "pb-7 w-full lg:w-8/12 lg:max-w-4xl text-left";
  const title = (
    <div className="flex items-center justify-between bg-primary px-5 py-6 sm:px-10">
      <h1 className="text-xl font-bold text-white sm:text-3xl">
        Add New Custom Field
      </h1>
      <XMarkIcon
        aria-label={`close add custom field dialog`}
        onClick={onCloseDialog}
        className="h-6 w-6 cursor-pointer text-white"
      />
    </div>
  );
  //#endregion

  // Typeguards
  function isValidCustomFieldType(val: unknown): val is CustomFieldTypes {
    return customFieldTypes.includes(val as any);
  }

  function isValidTasktype(val: unknown): val is TaskTypesValues {
    return taskTypeList.includes(val as any);
  }

  const [selectedWorkType, setSelectedWorkType] = useState<
    CraftTypeValues | undefined
  >(undefined);

  const taskTypeList = getValidTaskTypes(selectedWorkType);

  const [selectedTaskType, setSelectedTaskType] = useState<
    TaskTypesValues | undefined
  >(undefined);

  // Display the relevant form after the user has selected a field type.
  const [selectedFieldType, setSelectedFieldType] =
    useState<null | CustomFieldTypesExcludingStrArr>(null);

  let SelectedForm: null | React.FunctionComponent<CreateSiteCustomFieldFormInterface> =
    null;
  if (selectedFieldType) {
    SelectedForm = formMap[selectedFieldType];
  }

  const stringWorkTypeList = props.workType.map((type) =>
    getReadableCraftType(type),
  );
  const sortStringWTList = stringWorkTypeList.sort((a, b) =>
    a.localeCompare(b),
  );

  const stringTaskTypeList = taskTypeList.map((type) =>
    getReadableTaskType(type),
  );
  const sortStringTTList = stringTaskTypeList.sort((a, b) =>
    a.localeCompare(b),
  );

  const stringFieldTypeList = customFieldTypesExcludingStrArr.map((type) =>
    getReadableCustomFieldType(type),
  );
  const sortStringFTList = stringFieldTypeList.sort((a, b) =>
    a.localeCompare(b),
  );

  return (
    <BaseModal
      open={props.isDialogOpen}
      title={title}
      closeModal={onCloseDialog}
      parentDivStyles={parentDivStyles}
    >
      <div className="mt-8 px-4 md:mt-10 md:px-6">
        <div className="grid gap-4 sm:grid-cols-2">
          <BaseInputSelect
            text="Work Type"
            inputName="workType"
            admin={true}
            id="workType"
            onChange={(event) => {
              const craftTypeString = getCraftTypeStringFromReadableString(
                event.target.value,
              );
              const value = getCraftTypeFromString(craftTypeString);
              if (isValidCraftType(value)) {
                setSelectedWorkType(value);
              }
            }}
          >
            <option value="" disabled>
              Select
            </option>
            {sortStringWTList.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </BaseInputSelect>
          {/* TASK TYPE INPUT (Dropdown) */}
          <BaseInputSelect
            text="Task Type"
            inputName="taskType"
            admin={true}
            id="taskType"
            onChange={(event) => {
              const value = getTaskTypeValueFromReadableStr(event.target.value);
              if (isValidTasktype(value)) {
                setSelectedTaskType(value);
              }
            }}
          >
            {/* Because of the onChange logic, use -2 instead of an empty string */}
            <option value={-2} disabled>
              Select
            </option>
            <option value={0}>None</option>
            {sortStringTTList.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </BaseInputSelect>
        </div>
        <div className="grid gap-x-4 gap-y-9 sm:grid-cols-2 md:gap-x-7">
          <hr className="mt-9 w-full border border-dashed border-gray-300 sm:col-span-2" />
          {/* FIELD TYPE INPUT (Dropdown) */}
          <BaseInputSelect
            text="Field Type"
            inputName="fieldType"
            admin={true}
            id="fieldType"
            onChange={(event) => {
              const value = getFieldTypeFromString(event.target.value);
              if (isValidCustomFieldType(value) && value !== "string-array") {
                setSelectedFieldType(value);
              }
            }}
          >
            <option value="" disabled>
              Select
            </option>
            {sortStringFTList.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </BaseInputSelect>
          {SelectedForm && (
            <SelectedForm
              workType={selectedWorkType}
              taskType={selectedTaskType}
              handleSave={props.handleSave}
              closeDialog={onCloseDialog}
              taskStatusList={props.taskStatusList}
            />
          )}
        </div>
      </div>
    </BaseModal>
  );
}

// Interface for the individual forms.
export interface CreateSiteCustomFieldFormInterface {
  workType: CraftTypeValues | undefined;
  taskType: TaskTypesValues | undefined;
  handleSave: (data: CustomFieldDocData) => void;
  closeDialog: () => void;
  taskStatusList: TaskStatusValues[];
}

const formMap: Record<
  CustomFieldTypesExcludingStrArr,
  React.FunctionComponent<CreateSiteCustomFieldFormInterface>
> = {
  string: StringCreateSiteCustomFieldForm,
  "string-textarea": StringTextareaCreateSiteCustomFieldForm,
  bool: BooleanCreateSiteCustomFieldForm,
  number: NumberCreateSiteCustomFieldForm,
  timestamp: TimestampCreateSiteCustomFieldForm,
  selection: SelectionCreateSiteCustomFieldForm,
  uid: UidCreateSiteCustomFieldForm,
  "multiple-uid": MultipleUidCreateSiteCustomFieldForm,
  currency: CurrencyCreateSiteCustomFieldForm,
  "hours-minutes": HoursMinutesCreateSiteCustomFieldForm,
};

export function ActionButtons(props: { onCancel: () => void }): JSX.Element {
  return (
    <div className="text-right xs:flex xs:flex-row-reverse xs:justify-between sm:col-span-2 sm:justify-start">
      <BaseButtonPrimary className="w-full xs:w-52" type="submit">
        <PencilIcon aria-label="save custom field" className="mr-4 h-6" />
        {strings.buttons.SAVE}
      </BaseButtonPrimary>
      <BaseButtonSecondary
        onClick={props.onCancel}
        className="mt-6 w-full xs:mt-0 xs:w-52 sm:mr-6"
        type="button"
      >
        <XCircleIcon aria-label="cancel custom field" className="mr-4 h-6" />
        {strings.buttons.CANCEL}
      </BaseButtonSecondary>
    </div>
  );
}

export function RequiredField(
  props: Pick<FormFieldInterface, "control">,
): JSX.Element {
  return (
    <div className="mx-0.5 flex items-center justify-between">
      <Controller
        name="required"
        control={props.control}
        render={({ field }) => (
          <StyledSwitchGroup
            id="Required"
            readableName="Required"
            checked={field.value}
            {...field}
          />
        )}
      />
    </div>
  );
}
